import React, { PureComponent } from "react";
import axios from "axios";
import helper from "./../utils/helper";
import { protectedResources } from "./../authConfig";
import { MsalContext } from "@azure/msal-react";

const API_BASE_URL = helper.getAPIBaseURI();

const ATAT_OPTION_BUTTON_LIST = [
  { label: "All", value: "all" },
  { label: "1 Month", value: "one_month" },
  { label: "2 Months", value: "two_months" },
  { label: "3 Months", value: "three_months" },
  { label: "6 Months", value: "six_months" },
];

export default class ATATOfOrders extends PureComponent {
  static contextType = MsalContext;

  constructor(props) {
    super(props);

    this.state = { atatRange: "all" };
  }

  componentDidMount() {
    this.getATATDataAndUpdateDisplay();
  }

  // event handlers
  updateATATRange = (e) => {
    const selectedATATRange = e.target.value;
    this.getATATDataAndUpdateDisplay(selectedATATRange)
    this.setState({ atatRange: selectedATATRange });
  }

  // generic methods
  getATATDataAndUpdateDisplay = async (atatRange = "all") => {
    
    const accessToken = await helper.getAccessToken(this.context)

    const { data } = await axios({
      url: `${API_BASE_URL}/orders/averageTurnAroundTime?filterRange=${atatRange}`,
      method: "GET",
      headers: {
        "Content-type": "Application/json",
        Authorization: `Bearer ${accessToken}`,
      }
    });

    this.setState({ atatData: data.averageTAT });
  }

  formatATATDataForDisplay = () => {
    const { atatData } = this.state;
    if (!atatData) return "N/A";
    const atatString = String(atatData);
    let [fullDayStr, fractionOfDayStr] = atatString.split(".");

    fullDayStr = fullDayStr.length > 1 ? fullDayStr : `0${fullDayStr}`;
    fractionOfDayStr = fractionOfDayStr ?
      (fractionOfDayStr.length > 1) ? fractionOfDayStr : `${fractionOfDayStr}0`
      : undefined;

    return `${fullDayStr}${fractionOfDayStr ? `.${fractionOfDayStr}` : ""}`;
  }

  // helper methods for rendering
  renderTitleAndOptionButtons = () => {
    return (
      <>
        <div className="row graph-title">
          <div className="col-md-12">
            <strong>ATAT Of Orders</strong>
          </div>
        </div>
        <div className="row graph-options-container">
          <div className="col-md-12 d-flex flex-row flex-wrap">
            {ATAT_OPTION_BUTTON_LIST.map((button) => {
              const btnClassNames = button.value === this.state.atatRange ? "graph-button selected" : "graph-button";
              return <button className={btnClassNames} value={button.value} onClick={this.updateATATRange}>{button.label}</button>;
            })}
          </div>
        </div>
      </>
    );
  }

  renderATATDetails = () => {
    return (
      <div style={{ flexGrow: "1" }} className="atat-value-display-box">
        <div className="atat-inner">
          <p>{this.formatATATDataForDisplay()}</p>
          <p>days</p>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column" }}>
        {this.renderTitleAndOptionButtons()}
        {this.renderATATDetails()}
      </div>
    );
  }
}
