import React from "react";
import helper from "../../utils/helper";
import Select from "react-select";
import DatePicker from "react-datepicker";

const PatientInformation = (props) => {
  const { me } = props;

  var tempOthrEthnicity = props.state.patinfo_OtherEthnicitycity.replace(
    "Other",
    ""
  );
  props.me.setState({ patinfo_OtherEthnicitycity: tempOthrEthnicity });
  return (
    <div>
      <div id={`${props.state.frmprefix}PatientContainer`} class="body card">
        <div class="row clearfix">
          <div class="col-md-6">
            <div class="form-group required">
              <label
                class="form-label"
                for={`${props.state.frmprefix}patinfo_firstname`}
              >
                First Name
              </label>
              <div class="form-line">
                <input
                  type="text"
                  id={`patinfo_firstname`}
                  class="form-control required"
                  placeholder="First Name"
                  value={props.state.patinfo_firstname}
                  onChange={(val) => {
                    props.me.setState({ patinfo_firstname: val.target.value });
                  }}
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group required">
              <label
                class="form-label"
                for={`${props.state.frmprefix}patinfo_lastname`}
              >
                Last Name
              </label>
              <div class="form-line">
                <input
                  type="text"
                  id={`patinfo_lastname`}
                  class="form-control required"
                  placeholder="Last Name"
                  value={props.state.patinfo_lastname}
                  onChange={(val) => {
                    props.me.setState({ patinfo_lastname: val.target.value });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row clearfix">
          <div class="col-md-6">
            <div class="form-group required">
              <label
                class="form-label"
                for={`${props.state.frmprefix}patinfo_gender`}
              >
                Gender
              </label>
              <div class="demo-radio-button">
                <input
                  type="radio"
                  id={`${props.state.frmprefix}patinfo_gender`}
                  name="patinfo_gender_"
                  value="male"
                  class=" form-control"
                  checked={props.state.patinfo_gender == "male" ? true : false}
                  onChange={(val) => {
                    props.me.setState({ patinfo_gender: val.target.value });
                  }}
                />
                <label
                  class="form-label gender"
                  for={`${props.state.frmprefix}patinfo_gender`}
                >
                  Male &nbsp;{" "}
                </label>
                <input
                  type="radio"
                  id={`${props.state.frmprefix}patinfo_gender2`}
                  name="patinfo_gender_"
                  value="female"
                  class=" form-control"
                  checked={
                    props.state.patinfo_gender == "female" ? true : false
                  }
                  onChange={(val) => {
                    props.me.setState({ patinfo_gender: val.target.value });
                  }}
                />
                <label
                  class="form-label gender"
                  for={`${props.state.frmprefix}patinfo_gender2`}
                >
                  Female &nbsp;{" "}
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group required">
              <label
                class="form-label"
                for={`${props.state.frmprefix}patinfo_address`}
              >
                Address
              </label>
              <div class="form-line">
                <input
                  type="text"
                  id={`patinfo_address`}
                  class="form-control required"
                  placeholder="Address"
                  value={props.state.patinfo_address}
                  onChange={(val) => {
                    props.me.setState({ patinfo_address: val.target.value });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row clearfix">
          <div class="col-md-4">
            <div class="form-group required">
              <label
                class="form-label"
                for={`${props.state.frmprefix}patinfo_city`}
              >
                City
              </label>
              <div class="form-line">
                <input
                  type="text"
                  id={`patinfo_city`}
                  class="form-control required"
                  placeholder="City"
                  value={props.state.patinfo_city}
                  onChange={(val) => {
                    props.me.setState({ patinfo_city: val.target.value });
                  }}
                />
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group required">
              <label class="form-label" for="state">
                State
              </label>
              <div class="form-line">
                <Select
                  class="form-control"
                  id={`patinfo_State`}
                  // defaultValue={"Choose State"}
                  value={props.state.patinfo_State}
                  label="State:"
                  options={helper.usStates}
                  styles={helper.colourStyles}
                  onChange={(val) => {
                    props.me.setState({
                      patinfo_State: val,
                      patinfo_State_Save_value: val.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group required">
              <label
                class="form-label"
                for={`${props.state.frmprefix}patinfo_zipcode`}
              >
                Zip Code
              </label>
              <div class="form-line">
                <input
                  type="text"
                  //    ref={(ref) => (state.patinfo_zipcode = ref)}
                  id={`patinfo_zipcode`}
                  class="form-control required"
                  placeholder="Zip Code"
                  value={props.state.patinfo_zipcode}
                  onChange={(val) => {
                    props.me.setState({ patinfo_zipcode: val.target.value });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row clearfix">
          <div class="col-md-4">
            <div class="form-group required">
              <label
                class="form-label"
                for={`${props.state.frmprefix}patinfo_DOB`}
              >
                DOB (mm/dd/yyyy)
              </label>
              <div class="form-line">
                <DatePicker
                  id={`patinfo_dob`}
                  className={"form-control required"}
                  selected={props.state.patinfo_dob}
                  onChange={(val) => {
                    props.me.setState({ patinfo_dob: val });
                  }}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group required">
              <label class="form-label" for="PhoneNumber">
                Phone Number
              </label>
              <div class="form-line">
                {/* <PhoneNumberInput prefix={`patinfo_`} /> */}
                <input
                  type="text"
                  id={`patinfo_PhoneNumber`}
                  class="form-control required"
                  placeholder="Phone Number"
                  value={props.state.patinfo_PhoneNumber}
                  onChange={(val) => {
                    props.me.setState({
                      patinfo_PhoneNumber: val.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group required">
              <label
                class="form-label"
                for={`${props.state.frmprefix}patinfo_emailaddress`}
              >
                Email Address
              </label>
              <div class="form-line">
                <input
                  type="email"
                  //ref={(ref) => (me.patinfo_emailaddress = ref)}
                  id={`patinfo_emailaddress`}
                  class="form-control required"
                  placeholder="Email Address"
                  value={props.state.patinfo_emailaddress}
                  onChange={(val) => {
                    props.me.setState({
                      patinfo_emailaddress: val.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row clearfix">
          <div class="col-md-12">
            <div class="form-group required">
              <label class="form-label" for="Ethnicity">
                Ethnicity
              </label>
              <div id={`pat_ethnicity_container`} class="demo-checkbox">
                <input
                  type="radio"
                  data-val="Caucasian"
                  id={`patinfo_Caucasian`}
                  name="patinfo_ethnicity"
                  class="filled-in"
                  checked={props.state.patinfo_ethnicity === "Caucasian"}
                  onChange={(val) => {
                    console.log("hiraaaaaaaaaaaaaaaaa");
                    props.me.setState({
                      patinfo_ethnicity: val.target.checked ? "Caucasian" : "",
                    });
                    console.log(props.state.patinfo_ethnicity);
                  }}
                />
                <label for={`patinfo_Caucasian`}>
                  Caucasian &nbsp; &nbsp;{" "}
                </label>
                <input
                  type="radio"
                  data-val="Ashkenazi Jewish"
                  id={`patinfo_AshkenaziJewish`}
                  name="patinfo_ethnicity"
                  class="filled-in"
                  checked={props.state.patinfo_ethnicity === "Ashkenazi Jewish"}
                  onChange={(val) => {
                    console.log("hiraaaaaaaaaaaaaaaaa");
                    props.me.setState({
                      patinfo_ethnicity: val.target.checked
                        ? "Ashkenazi Jewish"
                        : "",
                    });
                    console.log(props.state.patinfo_ethnicity);
                  }}
                />
                <label for={`patinfo_AshkenaziJewish`}>
                  Ashkenazi Jewish &nbsp; &nbsp;{" "}
                </label>
                <input
                  type="radio"
                  data-val="Sephardic Jewish"
                  id={`patinfo_SephardicJewish`}
                  name="patinfo_ethnicity"
                  class="filled-in"
                  checked={props.state.patinfo_ethnicity === "Sephardic Jewish"}
                  onChange={(val) => {
                    console.log("hiraaaaaaaaaaaaaaaaa");
                    props.me.setState({
                      patinfo_ethnicity: val.target.checked
                        ? "Sephardic Jewish"
                        : "",
                    });
                    console.log(props.state.patinfo_ethnicity);
                  }}
                />
                <label for={`patinfo_SephardicJewish`}>
                  Sephardic Jewish &nbsp; &nbsp;{" "}
                </label>
                <input
                  type="radio"
                  data-val="Asian"
                  id={`patinfo_Asian`}
                  name="patinfo_ethnicity"
                  class="filled-in"
                  checked={props.state.patinfo_ethnicity === "Asian"}
                  onChange={(val) => {
                    console.log("hiraaaaaaaaaaaaaaaaa");
                    props.me.setState({
                      patinfo_ethnicity: val.target.checked ? "Asian" : "",
                    });
                    console.log(props.state.patinfo_ethnicity);
                  }}
                />
                <label for={`patinfo_Asian`}>Asian &nbsp; &nbsp; </label>
                <input
                  type="radio"
                  data-val="Hispanic"
                  id={`patinfo_HIspanic`}
                  name="patinfo_ethnicity"
                  class="filled-in"
                  checked={props.state.patinfo_ethnicity === "Hispanic"}
                  onChange={(val) => {
                    console.log("hiraaaaaaaaaaaaaaaaa");
                    props.me.setState({
                      patinfo_ethnicity: val.target.checked ? "Hispanic" : "",
                    });
                    console.log(props.state.patinfo_ethnicity);
                  }}
                />
                <label for={`patinfo_HIspanic`}>Hispanic &nbsp; &nbsp; </label>
                <input
                  type="radio"
                  data-val="Native American"
                  id={`patinfo_NativeAmerican`}
                  name="patinfo_ethnicity"
                  class="filled-in"
                  checked={props.state.patinfo_ethnicity === "Native American"}
                  onChange={(val) => {
                    console.log("hiraaaaaaaaaaaaaaaaa");
                    props.me.setState({
                      patinfo_ethnicity: val.target.checked
                        ? "Native American"
                        : "",
                    });
                    console.log(props.state.patinfo_ethnicity);
                  }}
                />
                <label for={`patinfo_NativeAmerican`}>
                  Native American &nbsp; &nbsp;{" "}
                </label>
                <input
                  type="radio"
                  data-val="African American"
                  id={`patinfo_AfricanAmerican`}
                  name="patinfo_ethnicity"
                  class="filled-in"
                  checked={props.state.patinfo_ethnicity === "African American"}
                  onChange={(val) => {
                    console.log("hiraaaaaaaaaaaaaaaaa");
                    props.me.setState({
                      patinfo_ethnicity: val.target.checked
                        ? "African American"
                        : "",
                    });
                    console.log(props.state.patinfo_ethnicity);
                  }}
                />
                <label for={`patinfo_AfricanAmerican`}>
                  African American &nbsp; &nbsp;{" "}
                </label>
                <input
                  type="radio"
                  data-val="N/A"
                  id={`patinfo_na`}
                  name="patinfo_ethnicity"
                  class="filled-in"
                  checked={props.state.patinfo_ethnicity === "N/A"}
                  onChange={(val) => {
                    console.log("hiraaaaaaaaaaaaaaaaa");
                    props.me.setState({
                      patinfo_ethnicity: val.target.checked ? "N/A" : "",
                    });
                    console.log(props.state.patinfo_ethnicity);
                  }}
                />
                <label for={`patinfo_na`}>N/A &nbsp; &nbsp; </label>
                <input
                  type="radio"
                  data-val="Other"
                  id={`patinfo_Other`}
                  name="patinfo_ethnicity"
                  class="filled-in"
                  checked={props.state.patinfo_ethnicity === "Other"}
                  onChange={(val) => {
                    props.me.setState({
                      patinfo_ethnicity: val.target.checked ? "Other" : "",
                    });

                    console.log(props.state.patinfo_ethnicity);
                  }}
                />
                <label for={`patinfo_Other`}>Other: &nbsp; &nbsp; </label>
              </div>
              {props.state.patinfo_ethnicity.includes("Other") && (
                <div class="form-line" id={`patinfo_Othercity_div`}>
                  <input
                    type="text"
                    ref={(ref) => (props.me.patinfo_OtherEthnicitycity = ref)}
                    id={`patinfo_OtherEthnicitycity`}
                    name="patinfo_OtherEthnicitycity"
                    class="form-control"
                    placeholder="Other"
                    value={props.state.patinfo_OtherEthnicitycity}
                    onChange={(val) => {
                      tempOthrEthnicity = val.target.value;
                      props.me.setState({
                        patinfo_OtherEthnicitycity: tempOthrEthnicity,
                      });
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientInformation;
