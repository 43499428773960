import React, { PureComponent, useState, useContext, createRef } from "react";

import PropTypes from 'prop-types';
import $ from 'jquery';


//includes all forms here
import ASPGENHereditaryBreastOvarianCancerForm from "./ASPGENHereditaryBreastOvarianCancerForm";
//import PhysicianInformationHBOCForm from "./PhysicianInformationHBOCForm";
//import PatientInformationHBOCForm from "./PatientInformationHBOCForm";
import BillingInformationHBOCForm from "./BillingInformationHBOCForm";
// import DiagnosisCodes_ICD10CodesHBOCForm from "./DiagnosisCodes_ICD10CodesHBOCForm";
import DiagnosisCodes_ICD10CodesForm from "./DiagnosisCodes_ICD10CodesForm";
//import HBOCClicnicalInfoForm from "./HBOCClicnicalInfoForm";
import HBOCFamilyHistoryInfoForm from "./HBOCFamilyHistoryInfoForm";
// import KitShippingInfoHBOCForm from "./KitShippingInfoHBOCForm";
import KitShippingInfoForm from "./KitShippingInfoForm"
// import NotesCertifyHBOCForm from "./NotesCertifyHBOCForm";
import NotesCertifyForm from "./NotesCertifyForm";
import Toast from './../portalComponents/toast/Toast';
import PhysicianInformationForm from "./PhysicianInformationForm";
import PatientInformationForm from "./PatientInformationForm";
import helper from './../utils/helperPortal';
import { AppContext } from "./../context";

import { createPhysicianApi } from "./../context/actions/physiciansActions";
import { createPatientApi, createPatients, createOva1OrderApi } from "./../context/actions/patientActions";
import BillingInformationForm from "./BillingInformationForm";
import { message } from "antd";

const api_base_url = helper.getAPIBaseURI();

const GenetiXHBOCForm = () => {
  let sigPad = createRef(null);

  let selectedPhysicianLegacyInfo = null;

  const hboc_prefix = "hboc_";
  const patientPhoneNo_prefix = "HbocPatient_";

  const physicianAddressContext = useContext(AppContext);

  const { patientDOB } = useContext(AppContext);
  const { physicianAddresses, selectedPhysicianAddress, physicianPracticeLocation, selectedPhysicianPracticeLocation } = physicianAddressContext;

  let toastProperties = {};
  const [toastList, setToastList] = useState([]);
  const [toastMessage, setToastMessage] = useState("");

  const [patientDOBLocal, setPatientDOBLocal] = useState("");
  const [patientState, setPatientState] = useState("");
  const [billinfoHBOC_dobLocal, setBillinfoHBOC_dobLocal] = useState("");
  const [physicianState, setPhysicianState] = useState("");

  const [trimmedDataURL, setTrimmedDataURL] = useState(null);

  const [selectedPhysicianLegacyInfoState, setSelectedPhysicianLegacyInfoState] = useState(null); //need-1

  const showToast = (type) => {

    const id = Math.floor((Math.random() * 101) + 1);

    switch (type) {
      case 'success':
        toastProperties = {
          id,
          title: 'Success',
          description: 'This is a success toast component',
          backgroundColor: '#5cb85c',
          icon: "/assets/toast/check.svg"
        }
        break;
      case 'danger':
        toastProperties = {
          id,
          title: 'Danger',
          description: 'This is a error toast component',
          backgroundColor: '#d9534f',
          icon: "/assets/toast/error.svg"
        }
        break;
      case 'info':
        toastProperties = {
          id,
          title: 'Info',
          description: 'This is an info toast component',
          backgroundColor: '#5bc0de',
          icon: "/assets/toast/info.svg"
        }
        break;
      case 'warning':
        toastProperties = {
          id,
          title: 'Warning',
          description: 'This is a warning toast component',
          backgroundColor: '#f0ad4e',
          icon: "/assets/toast/warning.svg"
        }
        break;

      default:
        setToastList([]);
    }

    setToastList([toastProperties]);
  }
  const loadInfo = () => {

  };
  React.useEffect(() => {
    loadInfo();
  });

  $(document).ready(() => {

    $("input.form-control").keyup(function (event) {
      console.log("event.keyCode: ", event.keyCode);
      if (event.keyCode == 13) {
        let textboxes = $("input.form-control");
        let currentBoxNumber = textboxes.index(this);
        if (textboxes[currentBoxNumber + 1] != null) {
          let nextBox = textboxes[currentBoxNumber + 1];
          nextBox.focus();
          nextBox.select();
        }
        event.preventDefault();
        return false;
      }

      return true;
    });


    //ASPGENHereditaryBreastOvarianCancerForm
    const btnASPGENHereditaryBreastOvarianCancerFormNextfunc = () => {

      let inputs = $('#ASPGENHereditaryBreastOvarianCancerForm').find(':input');

      let isGood = false;
      for (let i = 0; i < inputs.length; ++i) {
        let $elem = $(inputs[i]);

        const _type = $elem.attr('type');
        switch (_type) {
          case 'text':
            if ($elem.val().length > 0) {
              isGood = true;
            }
            break;
          case 'number':
            if ($elem.val().length > 0) {
              isGood = true;
            }
            break;
          case 'email':
            if ($elem.val().length > 0) {
              isGood = true;
            }
            break;
          case 'checkbox':
            if ($elem.is(':checked')) {
              isGood = true;
            }
            break;

          case 'radio':
            if ($elem.is(':checked')) {
              isGood = true;
            }
            break;

          default:
            isGood = false;
            break;
        }

        if (isGood) {
          break;
        }
      }

      if (isGood) {
        return true;
      }
      else {
        message.warning(`ASPIRA GENETIX Hereditary. 
        Breast and Ovarian Cancer panel is required`);
        return false;
      }
    }

    //PhysicianInformationHBOCForm Validation
    const gthbocPhysicianInfoFormNextFunc = () => {
      const phyinfo_Organization_account = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}phyinfo_Organization_account`).val());
      $(`#${hboc_prefix}phyinfo_Organization_account`).val(phyinfo_Organization_account);

      const phyinfo_first_name = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}phyinfo_first_name`).val());
      $(`#${hboc_prefix}phyinfo_first_name`).val(phyinfo_first_name);

      const phyinfo_last_name = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}phyinfo_last_name`).val());
      $(`#${hboc_prefix}phyinfo_last_name`).val(phyinfo_last_name);

      const phyinfo_npi = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}phyinfo_npi`).val());
      $(`#${hboc_prefix}phyinfo_npi`).val(phyinfo_npi);

      const phyinfo_email = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}phyinfo_email`).val());
      $(`#${hboc_prefix}phyinfo_email`).val(phyinfo_email);

      const phyinfo_address = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}phyinfoC_address`).val());
      $(`#${hboc_prefix}phyinfoC_address`).val(phyinfo_address);

      const phyinfo_practiceLocation = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}phyinfoC_practiceLocation`).val());
      $(`#${hboc_prefix}phyinfoC_practiceLocation`).val(phyinfo_practiceLocation);

      const phyinfo_city = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}phyinfoC_city`).val());
      $(`#${hboc_prefix}phyinfoC_city`).val(phyinfo_city);

      const phyinfo_state = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}phyinfoC_state`).val());
      $(`#${hboc_prefix}phyinfoC_state`).val(phyinfo_state);

      const phyinfo_zipCode = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}phyinfoC_zipCode`).val());
      $(`#${hboc_prefix}phyinfoC_zipCode`).val(phyinfo_zipCode);

      const ph_areacode = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}phyinfoC_ph_areacode`).val());
      $(`#${hboc_prefix}phyinfoC_ph_areacode`).val(ph_areacode);

      const ph_prefix = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}phyinfoC_ph_prefix`).val());
      $(`#${hboc_prefix}phyinfoC_ph_prefix`).val(ph_prefix);

      const ph_suffix = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}phyinfoC_ph_suffix`).val());
      $(`#${hboc_prefix}phyinfoC_ph_suffix`).val(ph_suffix);

      const phyinfo_fax = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}phyinfoC_fax`).val());
      $(`#${hboc_prefix}phyinfoC_fax`).val(phyinfo_fax);

      const phyinfo_Faxcopyto = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}phyinfoC_Faxcopyto`).val());
      $(`#${hboc_prefix}phyinfoC_Faxcopyto`).val(phyinfo_Faxcopyto);

      const phyinfo_confirmation = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}phyinfoC_confirmation`).val());
      $(`#${hboc_prefix}phyinfoC_confirmation`).val(phyinfo_confirmation);

      const phyinfo_no_npi = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}phyinfo_no_npi`).val());
      $(`#${hboc_prefix}phyinfo_no_npi`).val(phyinfo_no_npi);

      //ova1_phyinfo_phone_ph_suffix
      const phyinfo_account_number = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}phyinfo_account_number`).val());
      $(`#${hboc_prefix}phyinfo_account_number`).val(phyinfo_account_number);

      const phone_ph_areacode = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}phyinfo_phone_ph_areacode`).val());
      $(`#${hboc_prefix}phyinfo_phone_ph_areacode`).val(phone_ph_areacode);

      const phone_ph_prefix = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}phyinfo_phone_ph_prefix`).val());
      $(`#${hboc_prefix}phyinfo_phone_ph_prefix`).val(phone_ph_prefix);

      const phone_ph_suffix = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}phyinfo_phone_ph_suffix`).val());
      $(`#${hboc_prefix}phyinfo_phone_ph_suffix`).val(phone_ph_suffix);

      const hostname = $(`#${hboc_prefix}hostname`).val();

      let isGood = true;






      if (hostname == "lab.aspirawh.com" || hostname == 'localhost'
        || hostname == 'dev.aspirawh.cloud' || hostname === "order.dev-aspiralab.com" || hostname === "dev.aspirawh.cloud"
      ) { //yes it is lab.aspirawh.com

        if ($(`#${hboc_prefix}ordered_before1`).is(":not(:checked)")
          && $(`#${hboc_prefix}ordered_before2`).is(":not(:checked)")) {

          message.warning(`Have you ordered from us before is required.`);

          isGood = false;
        }
        else if ($(`#${hboc_prefix}ordered_before1`).is(":checked")) { //already Order Placed

          let _selectedNPI = "", _selectedClientId = "";


          //need - 5
          selectedPhysicianLegacyInfo = selectedPhysicianLegacyInfoState;

          // selectedPhysicianLegacyInfo = localStorage.getItem(
          //   "selectedPhysicianLegacyInfo"
          // );

          if (
            typeof selectedPhysicianLegacyInfo !== "undefined" &&
            selectedPhysicianLegacyInfo
          ) {

            //need - 6
            // selectedPhysicianLegacyInfo = JSON.parse(
            //   selectedPhysicianLegacyInfo
            // );

            _selectedNPI = selectedPhysicianLegacyInfo.selectedNPI;
            _selectedClientId = selectedPhysicianLegacyInfo.selectedClientId;
          }
          // selectedPhysicianLegacyInfo = localStorage.getItem("selectedPhysicianLegacyInfo");
          // if (typeof selectedPhysicianLegacyInfo !== 'undefined' && selectedPhysicianLegacyInfo) {
          //   selectedPhysicianLegacyInfo = JSON.parse(selectedPhysicianLegacyInfo);

          //   _selectedNPI = selectedPhysicianLegacyInfo.selectedNPI;
          //   _selectedClientId = selectedPhysicianLegacyInfo.selectedClientId;
          // }

          if (phyinfo_account_number.length < 1) {

            message.warning(`Physician Account Number is required field.`);


            $(`#${hboc_prefix}phyinfo_account_number`).focus();

            isGood = false;
          }
          else {
            if (_selectedNPI.length < 1) {

              message.warning(`Physician selection is required.`);


              // $(`#${gTcs_prefix}phyinfo_account_number`).focus();

              isGood = false;
            }
          }

        }
        else if ($(`#${hboc_prefix}ordered_before2`).is(":checked")) { //Not Order Placed yet

          if (phyinfo_first_name.length < 1) {

            message.warning(`Physician first name is required field.`);


            $(`#${hboc_prefix}phyinfo_first_name`).focus();

            isGood = false;
          }
          else if (phyinfo_last_name.length < 1) {

            message.warning(`Physician last name is required field.`);


            $(`#${hboc_prefix}phyinfo_last_name`).focus();

            isGood = false;
          }
          else if (phyinfo_npi.length < 1) {

            message.warning(`Physician npi is required field.`);


            $(`#${hboc_prefix}phyinfo_npi`).focus();

            isGood = false;
          }
          else if (phyinfo_email.length < 1) {

            message.warning(`Physician email address is required field.`);


            $(`#${hboc_prefix}phyinfo_email`).focus();

            isGood = false;
          }
          else if (!helper.isEmail(phyinfo_email)) {
            message.warning(`Please enter a valid email address.`);


            $(`#${hboc_prefix}phyinfo_email`).focus();

            isGood = false;
          } else if (phone_ph_areacode.length < 1) {

            message.warning(`Physician phone is required field.`);

            $(`#${hboc_prefix}phyinfo_phone_ph_areacode`).focus();

            isGood = false;
          } else if (phone_ph_prefix.length < 1) {

            message.warning(`Physician phone is required field.`);

            $(`#${hboc_prefix}phyinfo_phone_ph_prefix`).focus();

            isGood = false;
          } else if (phone_ph_suffix.length < 1) {

            message.warning(`Physician phone is required field.`);

            $(`#${hboc_prefix}phyinfo_phone_ph_suffix`).focus();

            isGood = false;
          } else if (phyinfo_no_npi === '1' && phyinfo_address.length < 1) {

            message.warning(`Physician address is required field.`);

            $(`#${hboc_prefix}phyinfoC_address`).focus();

            isGood = false;
          } else if (phyinfo_no_npi === '1' && phyinfo_practiceLocation.length < 1) {

            message.warning(`Physician Practice Location is required field.`);

            $(`#${hboc_prefix}phyinfoC_practiceLocation`).focus();

            isGood = false;
          } else if (phyinfo_no_npi === '1' && ph_areacode.length < 1) {

            message.warning(`Physician phone is required field.`);

            $(`#${hboc_prefix}phyinfoC_ph_areacode`).focus();

            isGood = false;
          } else if (phyinfo_no_npi === '1' && ph_prefix.length < 1) {

            message.warning(`Physician phone is required field.`);

            $(`#${hboc_prefix}phyinfoC_ph_prefix`).focus();

            isGood = false;
          } else if (phyinfo_no_npi === '1' && ph_suffix.length < 1) {

            message.warning(`Physician phone is required field.`);

            $(`#${hboc_prefix}phyinfoC_ph_suffix`).focus();

            isGood = false;
          }
        }
        else {

          isGood = true;
        }

      }
      else {
        if (phyinfo_first_name.length < 1) {

          message.warning(`Physician first name is required field.`);


          $(`#${hboc_prefix}phyinfo_first_name`).focus();

          isGood = false;
        }
        else if (phyinfo_last_name.length < 1) {

          message.warning(`Physician last name is required field.`);


          $(`#${hboc_prefix}phyinfo_last_name`).focus();

          isGood = false;
        }
        else if (phyinfo_npi.length < 1) {

          message.warning(`Physician npi is required field.`);


          $(`#${hboc_prefix}phyinfo_npi`).focus();

          isGood = false;
        }
        else if (phyinfo_email.length < 1) {

          message.warning(`Physician email address is required field.`);


          $(`#${hboc_prefix}phyinfo_email`).focus();

          isGood = false;
        }
        else if (!helper.isEmail(phyinfo_email)) {
          message.warning(`Please enter a valid email address.`);


          $(`#${hboc_prefix}phyinfo_email`).focus();

          isGood = false;
        } else if (phone_ph_areacode.length < 1) {

          message.warning(`Physician phone is required field.`);

          $(`#${hboc_prefix}phyinfo_phone_ph_areacode`).focus();

          isGood = false;
        } else if (phone_ph_prefix.length < 1) {

          message.warning(`Physician phone is required field.`);

          $(`#${hboc_prefix}phyinfo_phone_ph_prefix`).focus();

          isGood = false;
        } else if (phone_ph_suffix.length < 1) {

          message.warning(`Physician phone is required field.`);

          $(`#${hboc_prefix}phyinfo_phone_ph_suffix`).focus();

          isGood = false;
        } else if (phyinfo_no_npi === '1' && phyinfo_address.length < 1) {

          message.warning(`Physician address is required field.`);

          $(`#${hboc_prefix}phyinfoC_address`).focus();

          isGood = false;
        } else if (phyinfo_no_npi === '1' && phyinfo_practiceLocation.length < 1) {

          message.warning(`Physician Practice Location is required field.`);

          $(`#${hboc_prefix}phyinfoC_practiceLocation`).focus();

          isGood = false;
        } else if (phyinfo_no_npi === '1' && ph_areacode.length < 1) {

          message.warning(`Physician phone is required field.`);

          $(`#${hboc_prefix}phyinfoC_ph_areacode`).focus();

          isGood = false;
        } else if (phyinfo_no_npi === '1' && ph_prefix.length < 1) {

          message.warning(`Physician phone is required field.`);

          $(`#${hboc_prefix}phyinfoC_ph_prefix`).focus();

          isGood = false;
        } else if (phyinfo_no_npi === '1' && ph_suffix.length < 1) {

          message.warning(`Physician phone is required field.`);

          $(`#${hboc_prefix}phyinfoC_ph_suffix`).focus();

          isGood = false;
        } else {
          isGood = true;
        }
      }

      return isGood;
    }

    //gthbocPatientInfoFormNextFunc validation
    const gthbocPatientInfoFormNextFunc = () => {

      const patinfo_firstname = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}patinfo_firstname`).val());
      $(`#${hboc_prefix}patinfo_firstname`).val(patinfo_firstname);

      const patinfo_lastname = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}patinfo_lastname`).val());
      $(`#${hboc_prefix}patinfo_lastname`).val();

      const patinfo_address1 = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}patinfo_address1`).val());
      $(`#${hboc_prefix}patinfo_address1`).val();

      const patinfo_city = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}patinfo_city`).val());
      $(`#${hboc_prefix}patinfo_city`).val();

      const patinfo_zipcode = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}patinfo_zipcode`).val());
      $(`#${hboc_prefix}patinfo_zipcode`).val(patinfo_zipcode);

      const ova1_patinfo_ph_areacode = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}patinfo_ph_areacode`).val());
      $(`#${hboc_prefix}patinfo_ph_areacode`).val(ova1_patinfo_ph_areacode);

      const ova1_patinfo_ph_prefix = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}patinfo_ph_prefix`).val());
      $(`#${hboc_prefix}patinfo_ph_prefix`).val(ova1_patinfo_ph_prefix);

      const ova1_patinfo_ph_suffix = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}patinfo_ph_suffix`).val());
      $(`#${hboc_prefix}patinfo_ph_suffix`).val(ova1_patinfo_ph_suffix);

      const patinfo_emailaddress = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}patinfo_emailaddress`).val());
      $(`#${hboc_prefix}patinfo_emailaddress`).val(patinfo_emailaddress);

      const patinfo_OtherEthnicitycity = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}patinfo_OtherEthnicitycity`).val());
      $(`#${hboc_prefix}patinfo_OtherEthnicitycity`).val(patinfo_OtherEthnicitycity);

      const hostname = $(`#${hboc_prefix}hostname`).val();

      let isGood = true;

      if (hostname != "lab.aspirawh.com" && hostname != 'dev.aspirawh.cloud' && hostname !== "order.dev-aspiralab.com") { // not lab

        if (patinfo_firstname.length < 1) {
          message.warning(`Patient first name is required.`);

          $(`#${hboc_prefix}patinfo_firstname`).focus();

          isGood = false;

        } else if (patinfo_lastname.length < 1) {
          message.warning(`Patient last name is required.`);

          $(`#${hboc_prefix}patinfo_lastname`).focus();

          isGood = false;

        } else if ($(`#${hboc_prefix}patinfo_gender`).is(":not(:checked)") && $(`#${hboc_prefix}patinfo_gender2`).is(":not(:checked)")) {

          message.warning(`Patient gender is required.`);


          isGood = false;

        } else if (patinfo_address1.length < 1) {
          message.warning(`Patient address 1 is required.`);

          $(`#${hboc_prefix}patinfo_address1`).focus();

          isGood = false;
        } else if (patinfo_city.length < 1) {
          message.warning(`Patient city is required.`);

          $(`#${hboc_prefix}patinfo_city`).focus();

          isGood = false;
        } else if (patinfo_zipcode.length < 1) {
          message.warning(`Patient zipcode is required.`);

          $(`#${hboc_prefix}patinfo_zipcode`).focus();

          isGood = false;
        } else if (ova1_patinfo_ph_areacode.length < 1) {
          message.warning(`Patient phone is required.`);

          $(`#${hboc_prefix}patinfo_ph_areacode`).focus();

          isGood = false;
        } else if (ova1_patinfo_ph_prefix.length < 1) {
          message.warning(`Patient phone is required.`);

          $(`#${hboc_prefix}patinfo_ph_prefix`).focus();

          isGood = false;
        } else if (ova1_patinfo_ph_suffix.length < 1) {
          message.warning(`Patient phone is required.`);

          $(`#${hboc_prefix}patinfo_ph_suffix`).focus();

          isGood = false;
        } else if (patinfo_emailaddress.length < 1) {

          message.warning(`Patient email is required.`);


          $(`#${hboc_prefix}patinfo_emailaddress`).focus();

          isGood = false;
        }
        else if (!helper.isEmail(patinfo_emailaddress)) {
          message.warning(`Please enter a valid email address.`);

          $(`#${hboc_prefix}patinfo_emailaddress`).focus();

          isGood = false;
        }

        // else if ($(`#${hboc_prefix}patinfo_Caucasian`).is(":not(:checked)")
        //   && $(`#${hboc_prefix}patinfo_AshkenaziJewish`).is(":not(:checked)")
        //   && $(`#${hboc_prefix}patinfo_SephardicJewish`).is(":not(:checked)")
        //   && $(`#${hboc_prefix}patinfo_Asian`).is(":not(:checked)")
        //   && $(`#${hboc_prefix}patinfo_HIspanic`).is(":not(:checked)")
        //   && $(`#${hboc_prefix}patinfo_NativeAmerican`).is(":not(:checked)")
        //   && $(`#${hboc_prefix}patinfo_AfricanAmerican`).is(":not(:checked)")
        //   && $(`#${hboc_prefix}patinfo_Other`).is(":not(:checked)")) {

        //   message.warning(`Please select patient ethnicity.`);
        //   

        //   isGood = false;
        // } 

        else if (!($(`#${hboc_prefix}patinfo_Other`).is(":not(:checked)")) && (patinfo_OtherEthnicitycity.length < 1)) {

          message.warning(`Please fill up all required fields.`);

          $(`#${hboc_prefix}patinfo_OtherEthnicitycity`).focus();

          isGood = false;
        } else {

          isGood = true;
        }

      } else {  //yes it is lab.aspirawh.com

        if (patinfo_firstname.length < 1) {

          message.warning(`Patient first name is required.`);

          $(`#${hboc_prefix}patinfo_firstname`).focus();

          isGood = false;

        } else if (patinfo_lastname.length < 1) {

          message.warning(`Patient last name is required.`);

          $(`#${hboc_prefix}patinfo_lastname`).focus();

          isGood = false;

        } else if (!patientDOBLocal || patientDOBLocal.length < 1) {
          isGood = false;
          message.warning(`Patient DOB is required.`);
        }
        else {

          isGood = true;
        }

      }

      return isGood;
    }

    //BillingInformationHBOCForm Validation
    const hOBOCBillingInfoFormNextFunc = () => {

      let isGood = true;

      if (window.location.hostname == 'lab.aspirawh.com'
        || window.location.hostname == 'dev.aspirawh.cloud'
        || window.location.hostname === "order.dev-aspiralab.com"
      ) {
        return isGood;
      }

      const billinfo_PrimaryInsuranceCarrier = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}billinfo_PrimaryInsuranceCarrier`).val());
      $(`#${hboc_prefix}billinfo_PrimaryInsuranceCarrier`).val(billinfo_PrimaryInsuranceCarrier);

      // const billinfo_planId = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}billinfo_planId`).val());
      // $(`#${hboc_prefix}billinfo_planId`).val(billinfo_planId);

      const billinfo_MemberID = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}billinfo_MemberID`).val());
      $(`#${hboc_prefix}billinfo_MemberID`).val(billinfo_MemberID);

      const billinfo_GroupID = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}billinfo_GroupID`).val());
      $(`#${hboc_prefix}billinfo_GroupID`).val(billinfo_GroupID);

      const billinfo_SecondaryInsuranceCarrier = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}billinfo_SecondaryInsuranceCarrier`).val());
      $(`#${hboc_prefix}billinfo_SecondaryInsuranceCarrier`).val(billinfo_SecondaryInsuranceCarrier);

      // const SecondaryplanId = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}SecondaryplanId`).val());
      // $(`#${hboc_prefix}SecondaryplanId`).val(SecondaryplanId);

      const billinfo_SecondaryMemberID = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}billinfo_SecondaryMemberID`).val());
      $(`#${hboc_prefix}billinfo_SecondaryMemberID`).val(billinfo_SecondaryMemberID);

      const billinfo_SecondaryGroupID = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}billinfo_SecondaryGroupID`).val());
      $(`#${hboc_prefix}billinfo_SecondaryGroupID`).val(billinfo_SecondaryGroupID);

      const billinfo_TertiaryInsuranceCarrier = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}billinfo_TertiaryInsuranceCarrier`).val());
      $(`#${hboc_prefix}billinfo_TertiaryInsuranceCarrier`).val(billinfo_TertiaryInsuranceCarrier);

      const billinfo_TertiaryMemberID = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}billinfo_TertiaryMemberID`).val());
      $(`#${hboc_prefix}billinfo_TertiaryMemberID`).val(billinfo_TertiaryMemberID);

      const billinfo_TertiaryGroupID = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}billinfo_TertiaryGroupID`).val());
      $(`#${hboc_prefix}billinfo_TertiaryGroupID`).val(billinfo_TertiaryGroupID);

      const billinfo_FirstNameofInsured = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}billinfo_FirstNameofInsured`).val());
      $(`#${hboc_prefix}billinfo_FirstNameofInsured`).val(billinfo_FirstNameofInsured);
      const billinfo_LastNameofInsured = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}billinfo_LastNameofInsured`).val());
      $(`#${hboc_prefix}billinfo_LastNameofInsured`).val(billinfo_LastNameofInsured);

      const billinfo_RelationshipInsured = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}billinfo_RelationshipInsured`).val());
      $(`#${hboc_prefix}billinfo_RelationshipInsured`).val(billinfo_RelationshipInsured);

      if ($(`#${hboc_prefix}billinfo_PrivateInsurance`).is(":not(:checked)")
        && $(`#${hboc_prefix}billinfo_Medicare`).is(":not(:checked)")
        && $(`#${hboc_prefix}billinfo_PatientSelfPay`).is(":not(:checked)")
        && $(`#${hboc_prefix}billinfo_Medicaid`).is(":not(:checked)")
        && $(`#${hboc_prefix}billinfo_OrderingFacility`).is(":not(:checked)")) {

        message.warning(`Please select Billing Information.`);


        isGood = false;

      } else if ($(`#${hboc_prefix}billinfo_PrivateInsurance`).is(":checked") || $(`#${hboc_prefix}billinfo_Medicare`).is(":checked") || $(`#${hboc_prefix}billinfo_Medicaid`).is(":checked")) {

        if (billinfo_PrimaryInsuranceCarrier.length < 1) {

          message.warning(`Primary Insurance Carrier is required field.`);


          $(`#${hboc_prefix}billinfo_PrimaryInsuranceCarrier`).focus();
          isGood = false;
        } else if (billinfo_MemberID.length < 1) {

          message.warning(`Primary Insurance Carrier Member Id is required field.`);


          $(`#${hboc_prefix}billinfo_MemberID`).focus();
          isGood = false;
        } else if (billinfo_GroupID.length < 1) {

          message.warning(`Primary Insurance Carrier Group Id is required field.`);


          $(`#${hboc_prefix}billinfo_GroupID`).focus();
          isGood = false;
        }
        else if (billinfo_FirstNameofInsured.length < 1) {
          message.warning(`First Name of Insured is required field.`);


          $(`#${hboc_prefix}billinfo_FirstNameofInsured`).focus();

          isGood = false;
        }
        else if (billinfo_LastNameofInsured.length < 1) {
          message.warning(`Last Name of Insured is required field.`);


          $(`#${hboc_prefix}billinfo_LastNameofInsured`).focus();

          isGood = false;
        }
        else if (billinfo_RelationshipInsured.length < 1) {

          message.warning(`Relationship to insured is required field.`);


          $(`#${hboc_prefix}billinfo_RelationshipInsured`).focus();

          isGood = false;
        }
      }
      else {

        isGood = true;
      }

      return isGood;
    }
    //DiagnosisCodes_ICD10CodesHBOCForm Validation
    const btnDiagICD10CodesGCSFormNextFunc = () => {


      // let isGood = false;

      // // const OtherICD10Codes = helper.removeWhiteSpaceTrim($(`#DiagnosisCodes_ICD10CodesHBOCForm #OtherICD10Codes1`).val());
      // // $(`#${hboc_prefix}OtherICD10Codes`).val(OtherICD10Codes);

      // let inputs = $(`#DiagnosisCodes_ICD10CodesHBOCForm`).find(":input");

      // for (let i = 0; i < inputs.length; ++i) {
      //   let elem = inputs[i];
      //   let _type = $(elem).attr('type');
      //   if (_type === "checkbox" && $(elem).is(":checked")) {

      //     isGood = true;
      //     break;

      //   }
      // }

      // if (!isGood) {
      //   message.warning(`Please select Diagnosis Codes | ICD-10 Codes.`);
      //   
      // }

      // return isGood;

      return true;
    }

    const btnHBOCFamilyHistoryInfoFormNextFunc = () => {

      //DEBUG
      // let hboc1_q08_1dataValueanswers = [];
      // let hboc1_q08_1dataValueanswer = {
      //   "side": "",
      //   "relative": "",
      //   "age": ""
      // };

      // const is_checked = $("#pfHistory_FBrOvPanAnyAge").is(":checked");
      // const vals = $("#hboc1_q08_1_pfHistory_AnyAgeValues").val();

      // console.log("is_checked: ", is_checked, ", vals: ", vals);
      // if (is_checked && vals.length > 0) {

      //   const hboc1_q08_1_pfHistory_AnyAgeValues = vals.split(",");
      //   let j = 0;
      //   for (let i = 0; i < (hboc1_q08_1_pfHistory_AnyAgeValues.length / 3); i++) {
      //     //alert("side: "+ hboc1_q08_1_pfHistory_AnyAgeValues[j]+" relative: "+ hboc1_q08_1_pfHistory_AnyAgeValues[j+1]+" age: "+ hboc1_q08_1_pfHistory_AnyAgeValues[j+2]);
      //     hboc1_q08_1dataValueanswer = {
      //       side: hboc1_q08_1_pfHistory_AnyAgeValues[j],
      //       relative: hboc1_q08_1_pfHistory_AnyAgeValues[j + 1],
      //       age: hboc1_q08_1_pfHistory_AnyAgeValues[j + 2]
      //     };

      //     hboc1_q08_1dataValueanswers.push(hboc1_q08_1dataValueanswer);
      //     j = j + 3;
      //   }
      // }

      // let qnA = {};
      // if (is_checked) {
      //   qnA = {
      //     'questionCode': "hboc1_q08",
      //     'answer': true,
      //     'hBOCSubQuestions': [
      //       {
      //         "questionCode": "hboc1_q08_1",
      //         "answer": hboc1_q08_1dataValueanswers,
      //       }
      //     ]
      //   }
      // } else {
      //   qnA = {
      //     'questionCode': "hboc1_q08",
      //     'answer': false,
      //     'hBOCSubQuestions': [
      //       {
      //         "questionCode": "hboc1_q08_1",
      //         "answer": []
      //       }
      //     ]
      //   }
      // }

      // console.log("qnA: ", qnA);
      //END DEBUG


      let inputs = $('#HBOCFamilyHistoryInfoForm').find(':input');

      let isGood = false;
      for (let i = 0; i < inputs.length; ++i) {
        let $elem = $(inputs[i]);

        const _type = $elem.attr('type');
        switch (_type) {
          case 'text':
            if ($elem.val().length > 0) {
              isGood = true;
            }
            break;
          case 'number':
            if ($elem.val().length > 0) {
              isGood = true;
            }
            break;
          case 'email':
            if ($elem.val().length > 0) {
              isGood = true;
            }
            break;
          case 'checkbox':
            if ($elem.is(':checked')) {
              isGood = true;
            }
            break;

          case 'radio':
            if ($elem.is(':checked')) {
              isGood = true;
            }
            break;

          default:
            isGood = false;
            break;
        }

        if (isGood) {
          break;
        }
      }

      if (!isGood) {
        message.warning(`Please select Family History.`);
      }

      return isGood;
    }

    //KitShippingInfoHBOCForm Validation
    const hBOCKitShippingInfoFormNextFunc = () => {
      let isGood = true;

      // const kitShip4_PatOthersInfo = helper.removeWhiteSpaceTrim($(`#${hboc_prefix}kitShip_PatOthers`).val());
      // $(`#${hboc_prefix}kitShip_PatOthers`).val(kitShip4_PatOthersInfo);

      // if ($(`#${hboc_prefix}kitShip_PatMobPhlebotomy`).is(":not(:checked)")
      //   && $(`#${hboc_prefix}kitShip_PatblooddrawQuest`).is(":not(:checked)")
      //   && $(`#${hboc_prefix}kitShip_PatOthers`).is(":not(:checked)")) {

      //   message.warning(`Please select Kit shipping options.`);
      //   

      //   isGood = false;

      // } else if ($(`#${hboc_prefix}kitShip_PatOthers`).is(":checked") && (kitShip4_PatOthersInfo.length < 1)) {

      //   message.warning(`Kit shipping other options is required field.`);
      //   
      //   $(`#${hboc_prefix}kitShip_PatOthers`).focus();

      //   isGood = false;
      // }
      // else {
      //   isGood = true;
      // }
      return isGood;
    }


    //ASPGENHereditaryBreastOvarianCancerForm
    $('#btnASPGENHereditaryBreastOvarianCancerFormNext').off('click').on('click', function (event) {
      event.preventDefault();

      if (btnASPGENHereditaryBreastOvarianCancerFormNextfunc()) {
        $('div#ASPGENHereditaryBreastOvarianCancerForm').removeClass(' show');
        $('div#PhysicianInformationHBOCForm').addClass(' show');
        $('#ASPGENHereditaryBreastOvarianCancerCheckMark').removeClass('d-none');

        //go to top
        document.body.scrollTop = 260;
        document.documentElement.scrollTop = 260;
      }

    });

    //PhysicianInformationHBOCForm
    $(`#${hboc_prefix}btnPhysicianInformationFormNext`)
      .off('click') // need - 9
      .on('click', function (event) {
        event.preventDefault();

        if (gthbocPhysicianInfoFormNextFunc()) {

          $('div#PhysicianInformationHBOCForm').removeClass(' show')
          $('div#PatientInformationHBOCForm').addClass(' show')
          $('#PhysicianInformationHBOCFormCheckMark').removeClass('d-none');

          //go to top
          document.body.scrollTop = 306;
          document.documentElement.scrollTop = 306;
        }
      });

    //PatientInformationHBOCForm
    $(`#${hboc_prefix}btnPatientInformationFormNext`).off('click').on('click', function (event) {
      event.preventDefault();

      if (gthbocPatientInfoFormNextFunc()) {
        $('div#PatientInformationHBOCForm').removeClass(' show')
        $('div#BillingInformationHBOCForm').addClass(' show')
        $('#PatientInformationHBOCFormCheckMark').removeClass('d-none');

        //go to top
        document.body.scrollTop = 356;
        document.documentElement.scrollTop = 356;
      }
    });

    //BillingInformationHBOCForm
    $(`#${hboc_prefix}btnBillingInformationFormNext`).off('click').on('click', function (event) {
      event.preventDefault();

      if (hOBOCBillingInfoFormNextFunc()) {
        $('div#BillingInformationHBOCForm').removeClass(' show')
        $('div#DiagnosisCodes_ICD10CodesHBOCForm').addClass(' show')
        $('#BillingInformationHBOCFormCheckMark').removeClass('d-none');

        //go to top
        document.body.scrollTop = 406;
        document.documentElement.scrollTop = 406;
      }

    });

    //DiagnosisCodes_ICD10CodesHBOCForm
    $(`#${hboc_prefix}btnDiagnosisCodes_ICD10CodesFormNext`).off('click').on('click', function (event) {
      event.preventDefault();

      if (btnDiagICD10CodesGCSFormNextFunc()) {

        $('div#DiagnosisCodes_ICD10CodesHBOCForm').removeClass(' show')
        $('div#HBOCFamilyHistoryInfoForm').addClass(' show')
        $('#DiagnosisCodes_ICD10CodesHBOCFormCheckMark').removeClass('d-none');

        //go to top
        document.body.scrollTop = 456;
        document.documentElement.scrollTop = 456;
      }


    });

    //HBOCFamilyHistoryInfoForm
    $('#btnHBOCFamilyHistoryInfoFormNext').off('click').on('click', function (event) {
      event.preventDefault();

      if (btnHBOCFamilyHistoryInfoFormNextFunc()) {
        $('div#HBOCFamilyHistoryInfoForm').removeClass(' show')
        $('div#KitShippingInfoHBOCForm').addClass(' show')
        $('#HBOCFamilyHistoryInfoFormCheckMark').removeClass('d-none');

        //go to top
        document.body.scrollTop = 506;
        document.documentElement.scrollTop = 506;
      }

    });

    //KitShippingInfoHBOCForm
    $(`#${hboc_prefix}btnKitShippingInfoFormNext`).off('click').on('click', function (event) {
      event.preventDefault();

      if (hBOCKitShippingInfoFormNextFunc()) {

        $('div#KitShippingInfoHBOCForm').removeClass(' show')
        $('div#NotesCertifyHBOCForm').addClass(' show')
        $('#KitShippingInfoHBOCFormCheckMark').removeClass('d-none');

        //go to top
        document.body.scrollTop = 556;
        document.documentElement.scrollTop = 556;
      }

    });


    //genetix Hboc Order Submit
    $(`#${hboc_prefix}btnOrderSubmit`).off('click').on('click', async () => {
      const $this = $(this);

      if (!btnASPGENHereditaryBreastOvarianCancerFormNextfunc()) {
        //return;
        $('div#ASPGENHereditaryBreastOvarianCancerForm').addClass(' show');
        $('div#NotesCertifyHBOCForm').removeClass(' show');

        //go to top
        document.body.scrollTop = 206;
        document.documentElement.scrollTop = 206;

      } else if (!gthbocPhysicianInfoFormNextFunc()) {

        $('div#PhysicianInformationHBOCForm').addClass(' show')
        $('div#NotesCertifyHBOCForm').removeClass(' show');
        //go to top
        document.body.scrollTop = 260;
        document.documentElement.scrollTop = 260;

      } else if (!gthbocPatientInfoFormNextFunc()) {

        $('div#NotesCertifyHBOCForm').removeClass(' show')
        $('div#PatientInformationHBOCForm').addClass(' show')
        //go to top
        document.body.scrollTop = 306;
        document.documentElement.scrollTop = 306;

      } else if (!hOBOCBillingInfoFormNextFunc()) {

        $('div#NotesCertifyHBOCForm').removeClass(' show')
        $('div#BillingInformationHBOCForm').addClass(' show')
        //go to top
        document.body.scrollTop = 356;
        document.documentElement.scrollTop = 356;

      } else if (!btnDiagICD10CodesGCSFormNextFunc()) {

        $('div#NotesCertifyHBOCForm').removeClass(' show')
        $('div#DiagnosisCodes_ICD10CodesHBOCForm').addClass(' show')
        //go to top
        document.body.scrollTop = 406;
        document.documentElement.scrollTop = 406;

      } else if (!btnHBOCFamilyHistoryInfoFormNextFunc()) {

        $('div#NotesCertifyHBOCForm').removeClass(' show')
        $('div#HBOCFamilyHistoryInfoForm').addClass(' show')
        //go to top
        document.body.scrollTop = 456;
        document.documentElement.scrollTop = 456;

      } else if (!hBOCKitShippingInfoFormNextFunc()) {

        $('div#NotesCertifyHBOCForm').removeClass(' show')
        $('div#KitShippingInfoHBOCForm').addClass(' show')

        //go to top
        document.body.scrollTop = 506;
        document.documentElement.scrollTop = 506;

      } else { //go to process order

        $this.html($this.data('loading-text'));
        $(`#${hboc_prefix}btnOrderSubmit`).prop("disabled", true);
        const hbocOvarianCancerFormPanel = [];
        $(`#ASPGENHereditaryBreastOvarianCancerForm :input`).each((i, inp) => {

          if ($(inp).is(':checked')) {
            hbocOvarianCancerFormPanel.push({
              code: $(inp).data('val'),
              qBenchId: $(inp).data('qbenchid'),
            });
          }

        });


        //Physician Information Carrier Form

        //phyinfo_account_number
        let phyinfo_ordered_before = '';
        if ($(`#${hboc_prefix}ordered_before1`).is(":checked")) {
          phyinfo_ordered_before = $(`#${hboc_prefix}ordered_before1`).data('val');
        }
        if ($(`#${hboc_prefix}ordered_before2`).is(":checked")) {
          phyinfo_ordered_before = $(`#${hboc_prefix}ordered_before2`).data('val');
        }

        const phyinfo_account_number = $(`#${hboc_prefix}phyinfo_account_number`).val();
        const phyinfo_phyinfo_phone = $(`#${hboc_prefix}phyinfo_phone_ph_areacode`).val() + ' ' + $(`#${hboc_prefix}phyinfo_phone_ph_prefix`).val() + $(`#${hboc_prefix}phyinfo_phone_ph_suffix`).val();

        let physician = {};
        let legacyCustomerQbenchId = {};
        let _selectedClientId = "";

        let $ordered_beforeYes = $(`#${hboc_prefix}ordered_before1`);
        if (
          typeof $ordered_beforeYes !== 'undefined' && $ordered_beforeYes
          && $ordered_beforeYes.is(":checked")) {

          let physicianRet = await helper.createPhysicianFromClientId(
            selectedPhysicianLegacyInfo
          );

          physician = physicianRet.physician;
          legacyCustomerQbenchId = physicianRet.legacyCustomerQbenchId;

        }
        else {

          const phyinfo_Organization_account = $('#hboc_phyinfo_Organization_account').val();
          const phyinfo_first_name = $('#hboc_phyinfo_first_name').val();
          const phyinfo_last_name = $('#hboc_phyinfo_last_name').val();
          const phyinfo_npi = $('#hboc_phyinfo_npi').val();
          const phyinfo_email = $('#hboc_phyinfo_email').val();


          const phyinfo_address = $('#hboc_phyinfoC_address').val();
          const phyinfo_city = $('#hboc_phyinfoC_city').val();
          const phyinfo_state = physicianState;
          const phyinfo_zipCode = $('#hboc_phyinfoC_zipCode').val();
          const phyinfo_phone = $('#hboc_phyinfoC_ph_areacode').val() + ' ' + $('#hboc_phyinfoC_ph_prefix').val() + $('#hboc_phyinfoC_ph_suffix').val()
          const phyinfo_fax = $('#hboc_phyinfoC_fax').val();
          const phyinfo_Faxcopyto = $('#hboc_phyinfoC_Faxcopyto').val();
          const phyinfo_confirmation = $('#hboc_phyinfoC_confirmation').val();

          const phyinfoC_practiceLocation = $('#hboc_phyinfoC_practiceLocation').val();


          const addresses = [];
          const practiceLocation = [];

          const licenses = [];

          console.log("physicianAddresses: ", physicianAddresses)
          // if (typeof physicianAddresses == "undefined" || physicianAddresses.length < 1) {
          //   return;
          // }

          let data = {
            organizationAccount: phyinfo_Organization_account,
            firstName: phyinfo_first_name,
            lastName: phyinfo_last_name,
            npiNumber: phyinfo_npi,
            email: phyinfo_email,
            addresses: [],
            address_type: "Primary",
            practiceLocation: {
            },
            licenses: licenses,
            registeredUser: "",
            phoneNo: phyinfo_phyinfo_phone,
          }
          if (physicianAddresses && physicianAddresses.length < 1) {

            addresses.push({
              locationName: "USA",
              address_1: phyinfo_address,
              address_2: "",
              addressType: "Primary",
              city: phyinfo_city,
              country: "USA",
              state: phyinfo_state,
              zipCode: phyinfo_zipCode,
              phoneNo: phyinfo_phone,
              fax: phyinfo_fax,
              fax_copyto: phyinfo_Faxcopyto,

            });

            data.addresses = addresses;
            data.practiceLocationPlain = phyinfoC_practiceLocation;
          }
          else {

            physicianAddresses.map((a) => {
              addresses.push({
                locationName: "USA",
                address_1: a.address_1,
                address_2: a.address_1,
                addressType: a.address_type,
                city: a.city,
                country: a.country_name,
                state: a.state,
                zipCode: a.postal_code,
                phoneNo: a.telephone_number,
              });
            });

            data.addresses = addresses;
            data.practiceLocationPlain = phyinfoC_practiceLocation;
          }


          // console.log("physicianAddresses: ", physicianAddresses);
          console.log("data: ", data);


          let physicianRet = await createPhysicianApi(data);
          console.log("physicianRet: ", physicianRet);
          physician = physicianRet.physician;
          legacyCustomerQbenchId = physicianRet.legacyCustomerQbenchId;
        }
        console.log("physician: ", physician);

        if (physician.isok) {
          $this.html('Done');
          $(`#${hboc_prefix}btnOrderSubmit`).prop("disabled", true);
          message.success(`Physician Created Successfully. Creating Patient`);


          const patinfo_firstname = $(`#${hboc_prefix}patinfo_firstname`).val();
          const patinfo_lastname = $(`#${hboc_prefix}patinfo_lastname`).val();
          const patinfo_address1 = $(`#${hboc_prefix}patinfo_address1`).val();
          const patinfo_address2 = $(`#${hboc_prefix}patinfo_address2`).val();
          const patinfo_city = $(`#${hboc_prefix}patinfo_city`).val();
          const patinfo_state = patientState;
          const patinfo_zipcode = $(`#${hboc_prefix}patinfo_zipcode`).val();

          const patinfo_PhoneNumber =
            $("#hboc_patinfo_ph_areacode").val() +
            $("#hboc_patinfo_ph_prefix").val() +
            $("#hboc_patinfo_ph_suffix").val();
          //const patinfo_PhoneNumber = $("#" + patientPhoneNo_prefix + "ph_areacode").val() + $("#" + patientPhoneNo_prefix + "ph_prefix").val() + $("#" + patientPhoneNo_prefix + "ph_suffix").val()
          const patinfo_emailaddress = $(`#${hboc_prefix}patinfo_emailaddress`).val();
          const patinfo_DOB = patientDOBLocal;

          const ethnicity = [];

          if ($(`#${hboc_prefix}patinfo_Caucasian`).is(":checked")) {
            ethnicity.push($(`#${hboc_prefix}patinfo_Caucasian`).data('val'));
          }
          if ($(`#${hboc_prefix}patinfo_AshkenaziJewish`).is(":checked")) {
            ethnicity.push($(`#${hboc_prefix}patinfo_AshkenaziJewish`).data('val'));
          }
          if ($(`#${hboc_prefix}patinfo_SephardicJewish`).is(":checked")) {
            ethnicity.push($(`#${hboc_prefix}patinfo_SephardicJewish`).data('val'));
          }
          if ($(`#${hboc_prefix}patinfo_Asian`).is(":checked")) {
            ethnicity.push($(`#${hboc_prefix}patinfo_Asian`).data('val'));
          }
          if ($(`#${hboc_prefix}patinfo_HIspanic`).is(":checked")) {
            ethnicity.push($(`#${hboc_prefix}patinfo_HIspanic`).data('val'));
          }
          if ($(`#${hboc_prefix}patinfo_NativeAmerican`).is(":checked")) {
            ethnicity.push($(`#${hboc_prefix}patinfo_NativeAmerican`).data('val'));
          }
          if ($(`#${hboc_prefix}patinfo_AfricanAmerican`).is(":checked")) {
            ethnicity.push($(`#${hboc_prefix}patinfo_AfricanAmerican`).data('val'));
          }
          if ($(`#${hboc_prefix}patinfo_na`).is(":checked")) {
            ethnicity.push($(`#${hboc_prefix}patinfo_na`).data("val"));
          }
          if ($(`#${hboc_prefix}patinfo_Other`).is(":checked")) {
            ethnicity.push($(`#${hboc_prefix}patinfo_Other`).data('val'));
            ethnicity.push($(`#${hboc_prefix}patinfo_OtherEthnicitycity`).val());
          }

          if (ethnicity.length == 0) {
            // array empty or does not exist
            ethnicity.push("N/A");
          }

          let gender = "";

          if ($(`#${hboc_prefix}patinfo_gender`).is(":checked")) {
            gender = 'male';
          } else {
            gender = 'female';
          }

          let data = {
            email: patinfo_emailaddress,
            firstName: patinfo_firstname,
            lastName: patinfo_lastname,
            address: {
              locationName: "USA",
              address_1: patinfo_address1,
              address_2: patinfo_address2,
              addressType: "",
              city: patinfo_city,
              country: "USA",
              state: patinfo_state,
              zipCode: patinfo_zipcode,
            },
            dob: patinfo_DOB,
            phoneNo: patinfo_PhoneNumber,

            ethnicity: ethnicity.join(","),
            registeredUser: "",

            gender: gender,

          }

          console.log("Before Submit data: ", data);

          $this.html("<img style='height: 30px;' src='/assets/images/Spinner-1s-200px.svg' /> Creating Patient")
          $(`#${hboc_prefix}btnOrderSubmit`).prop("disabled", true);


          let patient = await createPatientApi(data);
          console.log("patient: ", patient);

          //Patient Information Carrier Form end

          if (patient.isok) {

            //Billing Information Carrier Form
            let billing_info = {
              primaryInsurance: {},
              secondaryInsurance: {},
              tertiaryInsurance: {},
              insuredInfo: {
                nameOfInsured: {
                  firstName: "",
                  lastName: "",
                },
                dob: "",
                relationshipInsured: "",
              },
            };

            var sections = $("div#BillingInformationHBOCForm #billing_info_container").find(":input");

            sections.map((i, input) => {
              const $input = $(input);
              // console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));
              // console.log("$input: ", $input);

              let _id = $input.attr('id');

              if (typeof _id == 'undefined' || _id.length < 1) {
                _id = "billinfoHBOC_DOB";
              }
              const _type = $input.attr('type');
              let obj_id = _id.replace(`${hboc_prefix}billinfo_`, "");
              obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)
              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  billing_info[obj_id] = true;
                }
                else {
                  billing_info[obj_id] = false;
                }
              }
              else {
                billing_info[obj_id] = $input.val();
              }
            });

            sections = $("div#BillingInformationHBOCForm #primary_insurance_container").find(":input");
            sections.map((input, i) => {
              const $input = $(sections[input]);
              // console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));
              // console.log("$input: ", $input);

              let _id = $input.attr('id');

              if (typeof _id == 'undefined' || _id.length < 1) {
                _id = "billinfoC_dob";
              }
              const _type = $input.attr('type');
              let obj_id = _id.replace(`${hboc_prefix}billinfo_`, "");
              obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)
              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  billing_info['primaryInsurance'][obj_id] = true;
                }
                else {
                  billing_info['primaryInsurance'][obj_id] = false;
                }
              }
              else {
                billing_info['primaryInsurance'][obj_id] = $input.val();
              }

            });

            sections = $("div#BillingInformationHBOCForm #secondary_insurance_container").find(":input");
            sections.map((input, i) => {
              const $input = $(sections[input]);
              // console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));
              // console.log("$input: ", $input);

              let _id = $input.attr('id');

              if (typeof _id == 'undefined' || _id.length < 1) {
                _id = "billinfoC_dob";
              }
              const _type = $input.attr('type');
              let obj_id = _id.replace(`${hboc_prefix}billinfo_`, "");
              obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)
              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  billing_info['secondaryInsurance'][obj_id] = true;
                }
                else {
                  billing_info['secondaryInsurance'][obj_id] = false;
                }
              }
              else {
                billing_info['secondaryInsurance'][obj_id] = $input.val();
              }

            });

            sections = $("div#BillingInformationHBOCForm #tertiary_insurance_container").find(":input");
            sections.map((input, i) => {
              const $input = $(sections[input]);
              // console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));
              // console.log("$input: ", $input);

              let _id = $input.attr('id');

              if (typeof _id == 'undefined' || _id.length < 1) {
                _id = "billinfoC_dob";
              }
              const _type = $input.attr('type');
              let obj_id = _id.replace(`${hboc_prefix}billinfo_`, "");
              obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)
              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  billing_info['tertiaryInsurance'][obj_id] = true;
                }
                else {
                  billing_info['tertiaryInsurance'][obj_id] = false;
                }
              }
              else {
                billing_info['tertiaryInsurance'][obj_id] = $input.val();
              }
            });

            billing_info["insuredInfo"]["nameOfInsured"]["firstName"] =
              $(`#${hboc_prefix}billinfo_FirstNameofInsured`).val();
            billing_info["insuredInfo"]["nameOfInsured"]["lastName"] =
              $(`#${hboc_prefix}billinfo_LastNameofInsured`).val();

            // sections = $("div#BillingInformationHBOCForm #insured_info").find(":input");
            // sections.map((input, i) => {
            //   const $input = $(sections[input]);
            //   // console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));
            //   // console.log("$input: ", $input);

            //   let _id = $input.attr('id');

            //   if (typeof _id == 'undefined' || _id.length < 1) {
            //     _id = "billinfoHBOC_DOB";
            //   }
            //   else {
            //     const _type = $input.attr('type');
            //     let obj_id = _id.replace(`${hboc_prefix}billinfo_`, "");
            //     obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)
            //     if (_type == 'checkbox') {
            //       if ($input.is(':checked')) {
            //         billing_info['insuredInfo'][obj_id] = true;
            //       }
            //       else {
            //         billing_info['insuredInfo'][obj_id] = false;
            //       }
            //     }
            //     else {
            //       billing_info['insuredInfo'][obj_id] = $input.val();
            //     }
            //   }
            // });
            billing_info['insuredInfo']["dob"] = billinfoHBOC_dobLocal;
            billing_info['insuredInfo']["relationshipInsured"] = $(`#${hboc_prefix}billinfo_RelationshipInsured`).val();
            console.log("billing_info: ", billing_info);
            //Billing Information Carrier Form end

            // //icd10Codes
            const icd10Codes = [];
            const icd10CodesOther = [];
            let icd10Code = {
              'codes': [],
              'otherCodes': [],
            };

            sections = $(`#DiagnosisCodes_ICD10CodesHBOCForm #hboc_icd_codes`).find(":input");
            sections.map((i, input) => {
              const $input = $(input);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));

              // let obj_id = $input.attr('id');

              const _type = $input.attr('type');
              // obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)

              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  icd10Codes.push($input.data('val'));
                }
              }
              else if (_type == 'text') {
                icd10CodesOther.push($("#hboc_OtherICD10Codes").val());
              }

            });

            //sam
            icd10CodesOther.push($("#hboc_OtherICD10Codes").val());

            icd10Code = {
              'codes': icd10Codes,
              'otherCodes': icd10CodesOther,
            }

            //Diagnosis Codes | ICD-10 Codes end

            //clinicalData -> SAM
            //HBOCFamilyHistoryInfoForm
            //follow order.ts from vrml-order-api project and then mongodb->model
            //follow mongodb->model->process_done->hboc_question_core.json (question structure dekhar jonno)
            //Question and answer dekhba ekhane: mongodb->model->hboc-question-example.json

            let qnA = {
              questionCode: "",
              answer: "",
              hBOCSubQuestions: []
            };

            let clinicalData = [];

            //hboc1_q01
            if ($("#pfHistory_noCancer").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q01",
                'answer': true,
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q01",
                'answer': false,
              }
            }

            clinicalData.push(qnA);

            //hboc1_q02
            if ($("#pfHistory_yesCancer").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q02",
                'answer': true,
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q02",
                'answer': false,
              }
            }

            clinicalData.push(qnA);

            //hboc1_q03        
            if ($("#pfHistory_previousCancer").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q03",
                'answer': true,
                'subQuestion': [
                  {
                    'questionCode': "hboc1_q03_1",
                    'answer': $("#pfHistory_prevCanDefine").val(),
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q03",
                'answer': false,
                'subQuestion': [
                  {
                    'questionCode': "hboc1_q03_1",
                    'answer': '',
                  }
                ]
              }
            }

            clinicalData.push(qnA);

            //hboc1_q04
            if ($("#pfHistory_SomaticMutationProf").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q04",
                'answer': true,
                'subQuestion': [
                  {
                    'questionCode': "hboc1_q04_1",
                    'answer': $("#pfHistory_SoMutaProfDefine").val(),
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q04",
                'answer': false,
                'subQuestion': [
                  {
                    'questionCode': "hboc1_q04_1",
                    'answer': '',
                  }
                ]
              }
            }
            clinicalData.push(qnA);

            //hboc1_q05
            const hboc1_q05_2 = [];
            if ($("#pfHistory_Familial_Inherited").is(":checked")) {
              hboc1_q05_2.push($("#pfHistory_Familial_Inherited").data('val'));
            }
            if ($("#pfHistory_Newly_identified").is(":checked")) {
              hboc1_q05_2.push($("#pfHistory_Newly_identified").data('val'));
            }

            if ($("#pfHistory_PrevGermltesting").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q05",
                'answer': true,
                'subQuestion': [
                  {
                    'questionCode': "hboc1_q05_1",
                    'answer': $("#pfHistory_PrevGermltestDefine").val(),
                  },
                  {
                    "questionCode": "hboc1_q05_2",
                    "answer": hboc1_q05_2.join(),
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q05",
                'answer': false,
                'subQuestion': [
                  {
                    'questionCode': "hboc1_q05_1",
                    'answer': '',
                  }
                  ,
                  {
                    "questionCode": "hboc1_q05_2",
                    "answer": hboc1_q05_2.join(),
                  }
                ]
              }
            }
            clinicalData.push(qnA);

            //hboc1_q06 Does the Patient have a history of
            //hboc1_q06_1
            const hboc1_q06_1 = [];
            if ($("#pfHistory_BrOvPanAnyAgeCan1").is(":checked")) {
              hboc1_q06_1.push($("#pfHistory_BrOvPanAnyAgeCan1").data('val'));
            }
            if ($("#pfHistory_BrOvPanAnyAgeCan2").is(":checked")) {
              hboc1_q06_1.push($("#pfHistory_BrOvPanAnyAgeCan2").data('val'));
            }
            if ($("#pfHistory_BrOvPanAnyAgeCan3").is(":checked")) {
              hboc1_q06_1.push($("#pfHistory_BrOvPanAnyAgeCan3").data('val'));
            }

            if ($("#pfHistory_BrOvPanAnyAge").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q06",
                'answer': true,
                'subQuestion': [
                  {
                    'questionCode': "hboc1_q06_1",
                    'answer': hboc1_q06_1.join(),
                  },
                  {
                    "questionCode": "hboc1_q06_2",
                    "answer": $("#pfHistory_BrOvPanAnyAgeInfo").val(),
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q06",
                'answer': false,
                'subQuestion': [
                  {
                    'questionCode': "hboc1_q06_1",
                    'answer': '',
                  }
                  ,
                  {
                    "questionCode": "hboc1_q06_2",
                    "options": '',
                  }
                ]
              }
            }
            clinicalData.push(qnA);

            //hboc1_q07  Colorectal or uterine cancer at 64 or younger?
            //hboc1_q07_1
            const hboc1_q07_1 = [];
            if ($("#pfHistory_ColorUterineAnyAgeCan1").is(":checked")) {
              hboc1_q07_1.push($("#pfHistory_ColorUterineAnyAgeCan1").data('val'));
            }
            if ($("#pfHistory_ColorUterineAnyAgeCan2").is(":checked")) {
              hboc1_q07_1.push($("#pfHistory_ColorUterineAnyAgeCan2").data('val'));
            }


            if ($("#pfHistory_ColorUterineAnyAge").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q07",
                'answer': true,
                'subQuestion': [
                  {
                    'questionCode': "hboc1_q07_1",
                    'answer': hboc1_q07_1.join(),
                  },
                  {
                    "questionCode": "hboc1_q07_2",
                    "answer": $("#pfHistory_ColorUterineAnyAgeInfo").val(),
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q07",
                'answer': false,
                'subQuestion': [
                  {
                    'questionCode': "hboc1_q07_1",
                    'answer': '',
                  }
                  ,
                  {
                    "questionCode": "hboc1_q07_2",
                    "answer": '',
                  }
                ]
              }
            }
            clinicalData.push(qnA);

            //hboc1_q08  One breast cancer in one relative at any age  
            //hboc1_q08_1
            let hboc1_q08_1dataValueanswers = [];
            let hboc1_q08_1dataValueanswer = {
              "side": "",
              "relative": "",
              "age": ""
            };

            if ($("#pfHistory_FBrOvPanAnyAge").is(":checked") && $("#hboc1_q08_1_pfHistory_AnyAgeValues").val().length > 0) {

              const hboc1_q08_1_pfHistory_AnyAgeValues = $("#hboc1_q08_1_pfHistory_AnyAgeValues").val().split(",");
              let j = 0;
              for (let i = 0; i < (hboc1_q08_1_pfHistory_AnyAgeValues.length / 3); i++) {
                //alert("side: "+ hboc1_q08_1_pfHistory_AnyAgeValues[j]+" relative: "+ hboc1_q08_1_pfHistory_AnyAgeValues[j+1]+" age: "+ hboc1_q08_1_pfHistory_AnyAgeValues[j+2]);
                hboc1_q08_1dataValueanswer = {
                  side: hboc1_q08_1_pfHistory_AnyAgeValues[j],
                  relative: hboc1_q08_1_pfHistory_AnyAgeValues[j + 1],
                  age: hboc1_q08_1_pfHistory_AnyAgeValues[j + 2]
                };

                hboc1_q08_1dataValueanswers.push(hboc1_q08_1dataValueanswer);
                j = j + 3;
              }
            }

            if ($("#pfHistory_FBrOvPanAnyAge").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q08",
                'answer': true,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q08_1",
                    "answer": hboc1_q08_1dataValueanswers
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q08",
                'answer': false,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q08_1",
                    "answer": []
                  }
                ]
              }
            }
            clinicalData.push(qnA);


            //hboc1_q09  Two breast cancers (bilateral) in one relative at any age 
            //hboc1_q09_1
            let hboc1_q09_1dataValueanswers = [];
            let hboc1_q09_1dataValueanswer = {
              "side": "",
              "relative": "",
              "age": ""
            };

            if ($("#pfHistory_FTwoBreasCanAge").is(":checked") && $("#hboc1_q09_1_pfHistory_AnyAgeValues").val().length > 0) {

              const hboc1_q09_1_pfHistory_AnyAgeValues = $("#hboc1_q09_1_pfHistory_AnyAgeValues").val().split(",");
              let j = 0;
              console.log("hboc1_q09_1_pfHistory_AnyAgeValues: ", hboc1_q09_1_pfHistory_AnyAgeValues);
              for (let i = 0; i < (hboc1_q09_1_pfHistory_AnyAgeValues.length / 3); i++) {

                hboc1_q09_1dataValueanswer = {
                  side: hboc1_q09_1_pfHistory_AnyAgeValues[j],
                  relative: hboc1_q09_1_pfHistory_AnyAgeValues[j + 1],
                  age: hboc1_q09_1_pfHistory_AnyAgeValues[j + 2]
                };

                hboc1_q09_1dataValueanswers.push(hboc1_q09_1dataValueanswer);
                j = j + 3;
              }
            }

            if ($("#pfHistory_FTwoBreasCanAge").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q09",
                'answer': true,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q09_1",
                    "answer": hboc1_q09_1dataValueanswers
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q09",
                'answer': false,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q09_1",
                    "answer": []
                  }
                ]
              }
            }
            clinicalData.push(qnA);


            //hboc1_q010  Three breast cancers in relatives on the same  side of the family at any age  
            //hboc1_q010_1
            let hboc1_q010_1dataValueanswers = [];
            let hboc1_q010_1dataValueanswer = {
              "side": "",
              "relative": "",
              "age": ""
            };

            if ($("#pfHistory_FThrBreasCanAge").is(":checked") && $("#hboc1_q010_1_pfHistory_AnyAgeValues").val().length > 0) {

              const hboc1_q010_1_pfHistory_AnyAgeValues = $("#hboc1_q010_1_pfHistory_AnyAgeValues").val().split(",");
              let j = 0;
              for (let i = 0; i < (hboc1_q010_1_pfHistory_AnyAgeValues.length / 3); i++) {

                hboc1_q010_1dataValueanswer = {
                  side: hboc1_q010_1_pfHistory_AnyAgeValues[j],
                  relative: hboc1_q010_1_pfHistory_AnyAgeValues[j + 1],
                  age: hboc1_q010_1_pfHistory_AnyAgeValues[j + 2]
                };

                hboc1_q010_1dataValueanswers.push(hboc1_q010_1dataValueanswer);
                j = j + 3;
              }
            }

            if ($("#pfHistory_FThrBreasCanAge").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q010",
                'answer': true,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q010_1",
                    "answer": hboc1_q010_1dataValueanswers
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q010",
                'answer': false,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q010_1",
                    "answer": []
                  }
                ]
              }
            }
            clinicalData.push(qnA);

            //hboc1_q011 Ovarian cancer at any age    
            //hboc1_q011_1
            let hboc1_q011_1dataValueanswers = [];
            let hboc1_q011_1dataValueanswer = {
              "side": "",
              "relative": "",
              "age": ""
            };

            if ($("#pfHistory_OvarianCanAge").is(":checked") && $("#hboc1_q011_1_pfHistory_AnyAgeValues").val().length > 0) {

              const hboc1_q011_1_pfHistory_AnyAgeValues = $("#hboc1_q011_1_pfHistory_AnyAgeValues").val().split(",");
              let j = 0;
              for (let i = 0; i < (hboc1_q011_1_pfHistory_AnyAgeValues.length / 3); i++) {

                hboc1_q011_1dataValueanswer = {
                  side: hboc1_q011_1_pfHistory_AnyAgeValues[j],
                  relative: hboc1_q011_1_pfHistory_AnyAgeValues[j + 1],
                  age: hboc1_q011_1_pfHistory_AnyAgeValues[j + 2]
                };

                hboc1_q011_1dataValueanswers.push(hboc1_q011_1dataValueanswer);
                j = j + 3;
              }
            }

            if ($("#pfHistory_OvarianCanAge").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q011",
                'answer': true,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q011_1",
                    "answer": hboc1_q011_1dataValueanswers
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q011",
                'answer': false,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q011_1",
                    "answer": []
                  }
                ]
              }
            }
            clinicalData.push(qnA);

            //hboc1_q012 Pancreatic cancer at any age
            //hboc1_q012_1
            let hboc1_q012_1dataValueanswers = [];
            let hboc1_q012_1dataValueanswer = {
              "side": "",
              "relative": "",
              "age": ""
            };

            if ($("#pfHistory_PancreaticCanAge").is(":checked") && $("#hboc1_q012_1_pfHistory_AnyAgeValues").val().length > 0) {

              const hboc1_q012_1_pfHistory_AnyAgeValues = $("#hboc1_q012_1_pfHistory_AnyAgeValues").val().split(",");
              let j = 0;
              for (let i = 0; i < (hboc1_q012_1_pfHistory_AnyAgeValues.length / 3); i++) {

                hboc1_q012_1dataValueanswer = {
                  side: hboc1_q012_1_pfHistory_AnyAgeValues[j],
                  relative: hboc1_q012_1_pfHistory_AnyAgeValues[j + 1],
                  age: hboc1_q012_1_pfHistory_AnyAgeValues[j + 2]
                };

                hboc1_q012_1dataValueanswers.push(hboc1_q012_1dataValueanswer);
                j = j + 3;
              }
            }

            if ($("#pfHistory_PancreaticCanAge").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q012",
                'answer': true,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q012_1",
                    "answer": hboc1_q012_1dataValueanswers
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q012",
                'answer': false,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q012_1",
                    "answer": []
                  }
                ]
              }
            }
            clinicalData.push(qnA);

            //hboc1_q013  Male breast cancer at any age        
            //hboc1_q013_1
            let hboc1_q013_1dataValueanswers = [];
            let hboc1_q013_1dataValueanswer = {
              "side": "",
              "relative": "",
              "age": ""
            };

            if ($("#pfHistory_MaleBreastCanAge").is(":checked") && $("#hboc1_q013_1_pfHistory_AnyAgeValues").val().length > 0) {

              const hboc1_q013_1_pfHistory_AnyAgeValues = $("#hboc1_q013_1_pfHistory_AnyAgeValues").val().split(",");
              let j = 0;
              for (let i = 0; i < (hboc1_q013_1_pfHistory_AnyAgeValues.length / 3); i++) {

                hboc1_q013_1dataValueanswer = {
                  side: hboc1_q013_1_pfHistory_AnyAgeValues[j],
                  relative: hboc1_q013_1_pfHistory_AnyAgeValues[j + 1],
                  age: hboc1_q013_1_pfHistory_AnyAgeValues[j + 2]
                };

                hboc1_q013_1dataValueanswers.push(hboc1_q013_1dataValueanswer);
                j = j + 3;
              }
            }

            if ($("#pfHistory_MaleBreastCanAge").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q013",
                'answer': true,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q013_1",
                    "answer": hboc1_q013_1dataValueanswers
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q013",
                'answer': false,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q013_1",
                    "answer": []
                  }
                ]
              }
            }
            clinicalData.push(qnA);

            //hboc1_q014 Metastatic prostate cancer at any age?
            //hboc1_q014_1
            let hboc1_q014_1dataValueanswers = [];
            let hboc1_q014_1dataValueanswer = {
              "side": "",
              "relative": "",
              "age": ""
            };

            if ($("#pfHistory_MetaProstateCanAge").is(":checked") && $("#hboc1_q014_1_pfHistory_AnyAgeValues").val().length > 0) {

              const hboc1_q014_1_pfHistory_AnyAgeValues = $("#hboc1_q014_1_pfHistory_AnyAgeValues").val().split(",");
              let j = 0;
              for (let i = 0; i < (hboc1_q014_1_pfHistory_AnyAgeValues.length / 3); i++) {

                hboc1_q014_1dataValueanswer = {
                  side: hboc1_q014_1_pfHistory_AnyAgeValues[j],
                  relative: hboc1_q014_1_pfHistory_AnyAgeValues[j + 1],
                  age: hboc1_q014_1_pfHistory_AnyAgeValues[j + 2]
                };

                hboc1_q014_1dataValueanswers.push(hboc1_q014_1dataValueanswer);
                j = j + 3;
              }
            }

            if ($("#pfHistory_MetaProstateCanAge").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q014",
                'answer': true,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q014_1",
                    "answer": hboc1_q014_1dataValueanswers
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q014",
                'answer': false,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q014_1",
                    "answer": []
                  }
                ]
              }
            }
            clinicalData.push(qnA);

            //hboc1_q015 Colon cancer at 49 or younger
            //hboc1_q015_1
            let hboc1_q015_1dataValueanswers = [];
            let hboc1_q015_1dataValueanswer = {
              "side": "",
              "relative": "",
              "age": ""
            };

            if ($("#pfHistory_ColorCanAge").is(":checked") && $("#hboc1_q015_1_pfHistory_AnyAgeValues").val().length > 0) {

              const hboc1_q015_1_pfHistory_AnyAgeValues = $("#hboc1_q015_1_pfHistory_AnyAgeValues").val().split(",");
              let j = 0;
              for (let i = 0; i < (hboc1_q015_1_pfHistory_AnyAgeValues.length / 3); i++) {

                hboc1_q015_1dataValueanswer = {
                  side: hboc1_q015_1_pfHistory_AnyAgeValues[j],
                  relative: hboc1_q015_1_pfHistory_AnyAgeValues[j + 1],
                  age: hboc1_q015_1_pfHistory_AnyAgeValues[j + 2]
                };

                hboc1_q015_1dataValueanswers.push(hboc1_q015_1dataValueanswer);
                j = j + 3;
              }
            }

            if ($("#pfHistory_ColorCanAge").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q015",
                'answer': true,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q015_1",
                    "answer": hboc1_q015_1dataValueanswers
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q015",
                'answer': false,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q015_1",
                    "answer": []
                  }
                ]
              }
            }
            clinicalData.push(qnA);

            //hboc1_q016 Uterine cancer at 49 or younger?
            //hboc1_q016_1
            let hboc1_q016_1dataValueanswers = [];
            let hboc1_q016_1dataValueanswer = {
              "side": "",
              "relative": "",
              "age": ""
            };

            if ($("#pfHistory_UterineCanAge").is(":checked") && $("#hboc1_q016_1_pfHistory_AnyAgeValues").val().length > 0) {

              const hboc1_q016_1_pfHistory_AnyAgeValues = $("#hboc1_q016_1_pfHistory_AnyAgeValues").val().split(",");
              let j = 0;
              for (let i = 0; i < (hboc1_q016_1_pfHistory_AnyAgeValues.length / 3); i++) {

                hboc1_q016_1dataValueanswer = {
                  side: hboc1_q016_1_pfHistory_AnyAgeValues[j],
                  relative: hboc1_q016_1_pfHistory_AnyAgeValues[j + 1],
                  age: hboc1_q016_1_pfHistory_AnyAgeValues[j + 2]
                };

                hboc1_q016_1dataValueanswers.push(hboc1_q016_1dataValueanswer);
                j = j + 3;
              }
            }

            if ($("#pfHistory_UterineCanAge").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q016",
                'answer': true,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q016_1",
                    "answer": hboc1_q016_1dataValueanswers
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q016",
                'answer': false,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q016_1",
                    "answer": []
                  }
                ]
              }
            }
            clinicalData.push(qnA);


            //hboc1_q017 Ashkenazi Jewish ancestry with breast cancer at any age?
            //hboc1_q017_1
            let hboc1_q017_1dataValueanswers = [];
            let hboc1_q017_1dataValueanswer = {
              "side": "",
              "relative": "",
              "age": ""
            };

            if ($("#pfHistory_AshJewishAncesCanAge").is(":checked") && $("#hboc1_q017_1_pfHistory_AnyAgeValues").val().length > 0) {

              const hboc1_q017_1_pfHistory_AnyAgeValues = $("#hboc1_q017_1_pfHistory_AnyAgeValues").val().split(",");
              let j = 0;
              for (let i = 0; i < (hboc1_q017_1_pfHistory_AnyAgeValues.length / 3); i++) {

                hboc1_q017_1dataValueanswer = {
                  side: hboc1_q017_1_pfHistory_AnyAgeValues[j],
                  relative: hboc1_q017_1_pfHistory_AnyAgeValues[j + 1],
                  age: hboc1_q017_1_pfHistory_AnyAgeValues[j + 2]
                };

                hboc1_q017_1dataValueanswers.push(hboc1_q017_1dataValueanswer);
                j = j + 3;
              }
            }
            if ($("#pfHistory_AshJewishAncesCanAge").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q017",
                'answer': true,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q017_1",
                    "answer": hboc1_q017_1dataValueanswers
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q017",
                'answer': false,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q017_1",
                    "answer": []
                  }
                ]
              }
            }
            clinicalData.push(qnA);

            //hboc1_q018 Does the physician have a family history of other cancers?
            if ($("#pfHistory_otherCan").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q018",
                'answer': true,
                'subQuestion': [
                  {
                    'questionCode': "hboc1_q018_1",
                    'answer': $("#pfHistory_otherCan_info").val(),
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q018",
                'answer': false,
                'subQuestion': [
                  {
                    'questionCode': "hboc1_q018_1",
                    'answer': '',
                  }
                ]
              }
            }
            clinicalData.push(qnA);

            //hboc1_q019 Has anyone in the physician's family had genetic testing for hereditary cancer?
            if ($("#pfHistory_geHerediraryCan").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q019",
                'answer': true,
                'subQuestion': [
                  {
                    'questionCode': "hboc1_q019_1",
                    'answer': $("#pfHistory_geHerediraryCan_info").val(),
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q019",
                'answer': false,
                'subQuestion': [
                  {
                    'questionCode': "hboc1_q019_1",
                    'answer': '',
                  }
                ]
              }
            }
            clinicalData.push(qnA);

            //hboc1_q020 Other Clinical History        
            qnA = {
              'questionCode': "hboc1_q020",
              'answer': $("#pfHistory_OtherClinicalHistory").val(),
            }
            clinicalData.push(qnA);

            //hboc1_q021 Allogeneic bone marrow or peripheral stem cell transplant
            if ($("#pfHistory_PCurTreat_boneMar").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q021",
                'answer': true,
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q021",
                'answer': false,
              }
            }

            clinicalData.push(qnA);

            //hboc1_q022 Chemotherapy
            if ($("#pfHistory_PCurTreat_Chemo").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q022",
                'answer': true,
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q022",
                'answer': false,
              }
            }

            clinicalData.push(qnA);

            //hboc1_q023 Radiation
            if ($("#pfHistory_PCurTreat_Radia").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q023",
                'answer': true,
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q023",
                'answer': false,
              }
            }

            clinicalData.push(qnA);

            //hboc1_q024 Surgery
            if ($("#pfHistory_PCurTreat_Surgery").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q024",
                'answer': true,
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q024",
                'answer': false,
              }
            }

            clinicalData.push(qnA);

            //hboc1_q025 previous or current treatment          
            qnA = {
              'questionCode': "hboc1_q025",
              'answer': $("#pfHistory_PCurTreat_SurgDe").val(),
            }
            clinicalData.push(qnA);

            //hboc1_q026 DNA analysis from blood samples of individuals who have undergone stem cell transplants, bone marrow transplants, or chemotheray may not reflect the germline genotype. Similarly, DNA analysis from blood samples of individuals with hematologic malignancy may not distinguish between somatic and germline variants
            // qnA = {
            //   'questionCode': "hboc1_q026",
            //   'answer': $("#pfHistory_PCurTreat_SurgDe2").val(),
            // }
            // clinicalData.push(qnA);
            //clinicalData

            //Kit Shipping Info 
            let kitInformation = {
              drawType: [],
              otherInfo: "",
            };

            // sections = $("div#KitShippingInfoHBOCForm #draw_type").find(":input");
            // sections.map((i, input) => {
            //   const $input = $(input);
            //   console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));

            //   let obj_id = $input.attr('id');

            //   const _type = $input.attr('type');
            //   // obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)

            //   if (_type == 'checkbox') {
            //     if ($input.is(':checked')) {
            //       kitInformation.drawType.push($input.data('val'));
            //     }
            //   }
            // });
            // kitInformation.otherInfo = $(`#KitShippingInfoHBOCForm #${hboc_prefix}kitShip_PatOthersInfo`).val();
            //Kit Shipping Info end

            let notes = $(`#NotesCertifyHBOCForm #${hboc_prefix}kitShip_notes`).val();
            let certifytypes = [];

            sections = $(`#NotesCertifyHBOCForm #${hboc_prefix}certifytype`).find(":input");
            sections.map((input, i) => {
              const $input = $(sections[input]);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));

              let obj_id = $input.attr('id');

              const _type = $input.attr('type');
              // obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)

              if (_type == 'radio') {
                if ($input.is(':checked')) {

                  certifytypes.push($input.data('val'));

                }
              }
            });

            var tests = [
              {
                testName: "GenetixHBOC1",
                testCode: "GenetixHBOC1",
                icd10Code: icd10Code,
                kitInformation: kitInformation,
                notes: notes,
                clinicalData: clinicalData,
                genetixCSPanel: hbocOvarianCancerFormPanel,

              }
            ];

            const covid_checked = $("#product_4").is(":checked");
            if (covid_checked) {

              const icd10CodesCOVD = [];
              let icd10CodeCOVD = {
                'codes': [],
                'otherCodes': "",
              };


              sections = $("#DiagnosisCodes_ICD10CodesHBOCForm #covid_icd_codes").find(":input");
              sections.map((i, input) => {
                const $input = $(input);
                console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));

                // let obj_id = $input.attr('id');

                const _type = $input.attr('type');
                // obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)

                if (_type == 'checkbox') {
                  if ($input.is(':checked')) {
                    icd10CodesCOVD.push($input.data('val'));
                  }
                }

              });


              icd10CodeCOVD = {
                'codes': icd10CodesCOVD,
                'otherCodes': "",
              }

              var covid_tests = {
                testName: "COVD",
                testCode: "COVD",
                icd10Code: icd10CodeCOVD,
              };

              tests.push(covid_tests);
            }

            console.log("sigPad: ", sigPad);
            let dataString = "";
            if (sigPad) {
              if (typeof sigPad.current !== 'undefined' && sigPad.current == null) {

              }
              else {
                dataString = sigPad.getTrimmedCanvas().toDataURL('image/png');
              }
            }
            console.log("physician before submitting to place order: ", physician);
            if (typeof patient.patient !== "undefined" && patient.patient) {
              patient = patient.patient;
            }
            if (typeof physician.physician !== "undefined" && physician.physician) {
              physician = physician.physician;
            }

            // get the user response related to user's consent about the use of his/her data for research purpose
            const researchConsent = $(`#${hboc_prefix}researchConsent`).is(":checked") ? "Yes" : "No";
            const researchUseOnly = $(`#${hboc_prefix}researchUseOnly`).is(":checked") ? "Yes" : "No";

            // check if the order is a test order
            const isTestOrder = helper.isAccessingFromAspiraLab() ? $(`#${hboc_prefix}testOrder`).is(":checked") : false;

            data = {
              orderId: (Math.floor(100000 + Math.random() * 900000)).toString(),
              patientId: patient._id.toString(),
              physicianId: physician._id.toString(),
              billingInfo: billing_info,
              tests: tests,
              certifyTypes: certifytypes,
              physicianSignature: dataString,
              qbenchCustomerId: legacyCustomerQbenchId,
              legacyClientId: selectedPhysicianLegacyInfo ? selectedPhysicianLegacyInfo.selectedClientId : "",
              researchConsent,
              researchUseOnly,
              isTestOrder
            }

            $this.html("<img style='height: 30px;' src='/assets/images/Spinner-1s-200px.svg' /> Creating Order")
            $(`#${hboc_prefix}btnOrderSubmit`).prop("disabled", true);
            let ret = await createOva1OrderApi(data);
            if (ret.isok) {
              $this.html('Done');
              $(`#${hboc_prefix}btnOrderSubmit`).prop("disabled", false);
              message.success(`Order Created Successfully.`);

              //need - 7
              //localStorage.removeItem('selectedPhysicianLegacyInfo');
              if (process.env.REACT_APP_ENV == "DEV") {
                console.log("intentionally not redirecting to pdf page");
              } else {
                setTimeout(() => {
                  window.location.href = ret.redirectUrl;
                }, 500);
              }

            }

          } //end of patient.isok
        } //end of Physician isok

      } //end validation if

    }) //end order submit


  }) //end document ready


  const patientDOBFunc = (_dob) => {
    setPatientDOBLocal(_dob);
  };
  const billinfoHBOC_dobLocalFunc = (_dob) => {
    setBillinfoHBOC_dobLocal(_dob);
  };
  const onChangePatientState = (_state) => {
    console.log("genetixHBOC->_state: ", _state);
    setPatientState(_state);
  }

  const onChangePhysicianStateLocal = (_state) => {
    setPhysicianState(_state);
  }

  const selectedPhysicianLegacyInfoFunc = (selectedPhysician) => { //need - 2
    setSelectedPhysicianLegacyInfoState(selectedPhysician);
  }
  return (
    <>
      <div class="body">
        <div class="row clearfix">
          <div class="col-md-12 col-lg-12">
            <div class="panel-group" id="accordion_10" role="tablist" aria-multiselectable="true">
              <Toast
                toastList={toastList}
                position={"top-center"}
                autoDelete={true}
                dismissTime={3000}
                description={toastMessage}

              />

              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingEightteen_10">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_10" href="#ASPGENHereditaryBreastOvarianCancerForm" aria-expanded="false" aria-controls="collapseTwo_10">
                      ASPIRA GENETIX Hereditary Breast and Ovarian Cancer <span id="ASPGENHereditaryBreastOvarianCancerCheckMark" className="d-none">&#10004;</span>
                    </a>
                  </h4>
                </div>
                <div id="ASPGENHereditaryBreastOvarianCancerForm" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingEightteen_10">
                  <ASPGENHereditaryBreastOvarianCancerForm />
                </div>
              </div>

              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingNineteen_10">
                  <h4 class="panel-title">
                    <a role="button" data-toggle="collapse" data-parent="#accordion_10" href="#PhysicianInformationHBOCForm" aria-expanded="true" aria-controls="collapseOne_10">
                      Physician Information <span id="PhysicianInformationHBOCFormCheckMark" className="d-none">&#10004;</span>
                    </a>
                  </h4>
                </div>
                <div id="PhysicianInformationHBOCForm" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingNineteen_10">
                  {/* <PhysicianInformationHBOCForm /> */}
                  <PhysicianInformationForm prefix={`${hboc_prefix}`}
                    onChangePhysicianState={onChangePhysicianStateLocal}
                    selectedPhysicianLegacyInfo={selectedPhysicianLegacyInfoFunc} //need - 3
                  />
                </div>
              </div>
              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingTwenty_10">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_10" href="#PatientInformationHBOCForm" aria-expanded="false" aria-controls="collapseTwo_10">
                      Patient Information <span id="PatientInformationHBOCFormCheckMark" className="d-none">&#10004;</span>
                    </a>
                  </h4>
                </div>
                <div id="PatientInformationHBOCForm" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwenty_10">
                  <PatientInformationForm
                    prefix={`${hboc_prefix}`}
                    phoneNoPrefix={`${patientPhoneNo_prefix}`}
                    dobFunc={patientDOBFunc}
                    onChangeState={onChangePatientState} />
                </div>
              </div>
              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingTwentyOne_10">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_10" href="#BillingInformationHBOCForm" aria-expanded="false" aria-controls="collapseTwo_10">
                      Billing Information <span id="BillingInformationHBOCFormCheckMark" className="d-none">&#10004;</span>
                    </a>
                  </h4>
                </div>
                <div id="BillingInformationHBOCForm" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwentyOne_10">
                  <BillingInformationForm prefix={`${hboc_prefix}`} billinfo_dobFunc={billinfoHBOC_dobLocalFunc} />
                </div>
              </div>
              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingTwentyTwo_10">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_10" href="#DiagnosisCodes_ICD10CodesHBOCForm" aria-expanded="false" aria-controls="collapseTwo_10">
                      Diagnosis Codes | ICD-10 Codes <span id="DiagnosisCodes_ICD10CodesHBOCFormCheckMark" className="d-none">&#10004;</span>
                    </a>
                  </h4>
                </div>
                <div id="DiagnosisCodes_ICD10CodesHBOCForm" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwentyTwo_10">
                  <DiagnosisCodes_ICD10CodesForm prefix={`${hboc_prefix}`} />
                </div>
              </div>
              {/* <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingTwentyThree_10">
                  <h4 class="panel-title">
                      <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_10" href="#HBOCClicnicalInfoForm" aria-expanded="false" aria-controls="collapseTwo_10"> 
                      HBOC Clinical Info
                      </a> 
                  </h4>
                </div>
                <div id="HBOCClicnicalInfoForm" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwentyThree_10">
                  <HBOCClicnicalInfoForm/> 
                </div>                     
              </div> */}
              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingTwentyFour_10">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_10" href="#HBOCFamilyHistoryInfoForm" aria-expanded="false" aria-controls="collapseTwo_10">
                      Personal/Family History -- Genetix HBOC <span id="HBOCFamilyHistoryInfoFormCheckMark" className="d-none">&#10004;</span>
                    </a>
                  </h4>
                </div>
                <div id="HBOCFamilyHistoryInfoForm" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwentyFour_10">
                  <HBOCFamilyHistoryInfoForm />
                </div>
              </div>
              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingTwentyFive_10">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_10" href="#KitShippingInfoHBOCForm" aria-expanded="false" aria-controls="collapseTwo_10">
                      Kit Shipping Information <span id="KitShippingInfoHBOCFormCheckMark" className="d-none">&#10004;</span>
                    </a>
                  </h4>
                </div>
                <div id="KitShippingInfoHBOCForm" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwentyFive_10">
                  <KitShippingInfoForm prefix={hboc_prefix} />
                </div>
              </div>

              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingTwentySix_10">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_10" href="#NotesCertifyHBOCForm" aria-expanded="false" aria-controls="collapseTwo_10">
                      Notes & Certify
                    </a>
                  </h4>
                </div>
                <div id="NotesCertifyHBOCForm" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwentySix_10">
                  <NotesCertifyForm prefix={`${hboc_prefix}`} sigCollect={(_sigPad) => {
                    sigPad = _sigPad;
                  }} />
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default GenetiXHBOCForm;
