import React, { PureComponent } from "react";

//include template related files
import PageLoader from "./layout/PageLoader";
import Search from "./layout/Search";
import TopBar from "./layout/TopBar";
import LeftSidebar from "./layout/LeftSidebar";
import RightSidebar from "./layout/RightSidebar";
//import FooterContent from "./layout/FooterContent";
import ResetPasswordContent from "./components/ResetPasswordContent";

import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';

//require("dotenv").config();

export default class ResetPassword extends PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      timeout: process.env.REACT_APP_LOGIN_TIMEOUT,
      showModal: false,
      userLoggedIn: false,
      isTimedOut: false,
      user: null,
    }

  }

  componentDidMount() {
    let me = this;

    const user = JSON.parse(localStorage.getItem('user'));
    this.setState({
      user: user,
    }, () => {

      console.log("Home->user: ", this.state.user);
    });
  }
  render() {
    return (
      <>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={this.state.timeout} />
        <div>
          {/*  Page Loader  */}
          <PageLoader />
          {/*  Overlay For Sidebars  */}
          <div class="overlay"></div>

          {/* Search */}
          <Search />

          {/* #Top Bar   */}
          <TopBar />

          {/* Left Sidebar */}
          {this.state.user && this.state.user.resetPasswordRequired == false &&
            <LeftSidebar />
          }

          {/* Right Sidebar */}
          <RightSidebar />

          {/* Main Content Area */}
          <section class="content home">
            <div class="container-fluid">
              <ResetPasswordContent user={this.state.user} />
              {/* Footer Content  */}
              {/* <FooterContent/> */}
            </div>{/* container-fluid */}
          </section>

        </div>
      </>
    );
  }
}
ResetPassword.propTypes = {
  match: PropTypes.any.isRequired,
  history: PropTypes.func.isRequired
}

