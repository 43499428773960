import $ from 'jquery';
import { useLocation } from "react-router-dom";
import axios from 'axios';
import FileSaver from 'file-saver';

// const dotenv = require('dotenv');
// dotenv.config();
const helper = {
    sleep: (ms) => {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        });
    },
    printPDF: async (orderId) => {
        const url_base = helper.getAPIBaseURI();
        let opt = {
            url: `${url_base}/orders/printpdf/${orderId}`,
            method: "GET",
            data: {},
            responseType: 'arraybuffer',
            headers: {
                Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFsYW1yZXpvYW51bEBnbWFpbC5jb20iLCJpYXQiOjE1OTIzODQ0NjR9.Vlqn6clmjUMKFdtMd2NOxrJbu8n0PpPSoH_eGXVkSEM`,
                Accept: 'application/pdf',
            }

        };
        console.log("printing PDF with url: ", opt);
        let response = await axios(opt);
        console.log("printing return data: ", response.data);
        FileSaver.saveAs(
            new Blob([response.data], { type: 'application/pdf' }),
            `${orderId}.pdf`
        );
    },
    createPhysicianFromClientId: async (selectedPhysicianLegacyInfo) => {
        // return async dispatch => {
        //     dispatch(createPhysicians())
        return new Promise(async (resolve, reject) => {
            try {
                const api_base_url = helper.getAPIBaseURI();
                let _selectedNPI = selectedPhysicianLegacyInfo.selectedNPI;
                let _selectedClientId = selectedPhysicianLegacyInfo.selectedClientId

                let data = {
                    npi: _selectedNPI,
                    clientId: _selectedClientId,
                    from: "clientid",
                };
                let opt = {
                    url: `${api_base_url}/physicians`,
                    method: "POST",
                    data: data,
                    headers: {
                        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFsYW1yZXpvYW51bEBnbWFpbC5jb20iLCJpYXQiOjE1OTIzODQ0NjR9.Vlqn6clmjUMKFdtMd2NOxrJbu8n0PpPSoH_eGXVkSEM`
                    }

                };
                console.log("creating physician with url: ", opt);
                let ret = await axios(opt);
                console.log("ret.data: ", ret.data);


                let retdata = ret.data;

                retdata.physician.isok = true;
                resolve(retdata);
                // dispatch(createPhysiciansSuccess(retdata));
            } catch (error) {
                return error;
                // dispatch(createPhysiciansFailure());
            }
        });
    },
    // years: range(1990, getYear(new Date()) + 1, 1),
    numberAndDecimal: (e) => {
        var val = e.value;
        var re = /^([0-9]+[\.]?[0-9]?[0-9]?|[0-9]+)$/g;
        var re1 = /^([0-9]+[\.]?[0-9]?[0-9]?|[0-9]+)/g;
        if (re.test(val)) {
            //do something here

        } else {
            val = re1.exec(val);
            if (val) {
                e.value = val[0];
            } else {
                e.value = "";
            }
        }
    },
    //Trim
    removeWhiteSpaceTrim: (str) => {

        if (str) {
            const strr = str.replace(/\s+/g, " ").trim();

            return strr;
        }
        else {
            return str;
        }
    },
    //valiid email
    isEmail: (email) => {
        var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        return regex.test(email);
    },
    resetForm: (_type, elem) => {
        if (_type === 'text') {
            $(elem).val('');
        }
        else if (_type === 'email') {
            $(elem).val('');
        }
        else if (_type === 'number') {
            $(elem).val('');
        }
        else if (_type === 'checkbox') {
            $(elem).prop('checked', false);
            $(elem).prop('disabled', false);
        }
        else if (_type === 'radio') {
            $(elem).prop('checked', false);
        }
    },
    months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ],
    colourStyles: {
        control: base => ({
            ...base,
            border: 0,
            // This line disable the blue border
            boxShadow: "none",
            background: "white",
            color: "black",
        }),
        option: (base, state) => ({
            ...base,
            // backgroundColor: state.isSelected ? "red" : "green",
            // ':active': {
            //     backgroundColor: state.isSelected ? "cyan": "yellow"
            // }
        })
        // option: (styles, { data, isDisabled, isFocused, isSelected }) => {

        //     return {
        //         ...styles,
        //         backgroundColor: isDisabled ? 'red' : "white",
        //         color: 'darkgray',
        //         cursor: isDisabled ? 'not-allowed' : 'default',
        //     };
        // },
    },
    usStates: [
        { code: "AL", label: "Alabama", value: "Alabama" },
        { code: "AK", label: "Alaska", value: "Alaska" },
        { code: "AZ", label: "Arizona", value: "Arizona" },
        { code: "AR", label: "Arkansas", value: "Arkansas" },
        { code: "CA", label: "California", value: "California" },
        { code: "CO", label: "Colorado", value: "Colorado" },
        { code: "CT", label: "Connecticut", value: "Connecticut" },
        { code: "DE", label: "Delaware", value: "Delaware" },
        { code: "DC", label: "District Of Columbia", value: "District Of Columbia" },
        { code: "FL", label: "Florida", value: "Florida" },
        { code: "GA", label: "Georgia", value: "Georgia" },
        { code: "HI", label: "Hawaii", value: "Hawaii" },
        { code: "ID", label: "Idaho", value: "Idaho" },
        { code: "IL", label: "Illinois", value: "Illinois" },
        { code: "IN", label: "Indiana", value: "Indiana" },
        { code: "IA", label: "Iowa", value: "Iowa" },
        { code: "KS", label: "Kansas", value: "Kansas" },
        { code: "KY", label: "Kentucky", value: "Kentucky" },
        { code: "LA", label: "Louisiana", value: "Louisiana" },
        { code: "ME", label: "Maine", value: "Maine" },
        { code: "MD", label: "Maryland", value: "Maryland" },
        { code: "MA", label: "Massachusetts", value: "Massachusetts" },
        { code: "MI", label: "Michigan", value: "Michigan" },
        { code: "MN", label: "Minnesota", value: "Minnesota" },
        { code: "MS", label: "Mississippi", value: "Mississippi" },
        { code: "MO", label: "Missouri", value: "Missouri" },
        { code: "MT", label: "Montana", value: "Montana" },
        { code: "NE", label: "Nebraska", value: "Nebraska" },
        { code: "NV", label: "Nevada", value: "Nevada" },
        { code: "NH", label: "New Hampshire", value: "New Hampshire" },
        { code: "NJ", label: "New Jersey", value: "New Jersey" },
        { code: "NM", label: "New Mexico", value: "New Mexico" },
        { code: "NY", label: "New York", value: "New York" },
        { code: "NC", label: "North Carolina", value: "North Carolina" },
        { code: "ND", label: "North Dakota", value: "North Dakota" },
        { code: "OH", label: "Ohio", value: "Ohio" },
        { code: "OK", label: "Oklahoma", value: "Oklahoma" },
        { code: "OR", label: "Oregon", value: "Oregon" },
        { code: "PA", label: "Pennsylvania", value: "Pennsylvania" },
        { code: "RI", label: "Rhode Island", value: "Rhode Island" },
        { code: "SC", label: "South Carolina", value: "South Carolina" },
        { code: "SD", label: "South Dakota", value: "South Dakota" },
        { code: "TN", label: "Tennessee", value: "Tennessee" },
        { code: "TX", label: "Texas", value: "Texas" },
        { code: "UT", label: "Utah", value: "Utah" },
        { code: "VT", label: "Vermont", value: "Vermont" },
        { code: "VA", label: "Virginia", value: "Virginia" },
        { code: "WA", label: "Washington", value: "Washington" },
        { code: "WV", label: "West Virginia", value: "West Virginia" },
        { code: "WI", label: "Wisconsin", value: "Wisconsin" },
        { code: "WY", label: "Wyoming", value: "Wyoming" },
    ],
    useQuery: function () {

        // return new URLSearchParams(useLocation().search);
    },
    getAPIBaseURI: function () {
        if (process.env.REACT_APP_ENV === "PROD") {
            return `${process.env.REACT_APP_PROVIDER_API_BASE_URL_PROD}`;
        }
        else if (process.env.REACT_APP_ENV === "STAGING") {
            return `${process.env.REACT_APP_PROVIDER_API_BASE_URL_STAGING}`;
        }
        else {
            return `${process.env.REACT_APP_PROVIDER_API_BASE_URL_DEV}`;
        }
    },

    getAssaysByType: async (_type) => {
        try {
            let url_base = helper.getAPIBaseURI();
            let opt = {
                url: `${url_base}/assays/bytype/${_type}`,
                method: "GET",
                // data: data,
                headers: {
                    Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFsYW1yZXpvYW51bEBnbWFpbC5jb20iLCJpYXQiOjE1OTIzODQ0NjR9.Vlqn6clmjUMKFdtMd2NOxrJbu8n0PpPSoH_eGXVkSEM`
                }

            };
            console.log("Getting Assays with url: ", opt);
            let ret = await axios(opt);
            console.log("ret.data: ", ret.data);

            const retdata = ret.data;

            return retdata;

        } catch (error) {
            return error;

        }
    },

    isAccessingFromAspiraLab: () => {
        console.log("isAccessingFromAspiraLab->window.location.hostname: ", window.location.hostname);
        return window.location.hostname === "lab.aspirawh.com" || window.location.hostname === "localhost" || window.location.hostname === 'dev.aspirawh.cloud' || window.location.hostname === 'order.dev-aspiralab.com';
    },

}
export default helper;