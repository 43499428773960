import React, { createRef, useState } from "react";

import axios from "axios";
import Select from 'react-select';
import { protectedResources } from "./../authConfig";
import { MsalContext } from "@azure/msal-react";
// import $ from 'jquery';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

import moment from 'moment';

import Toast from '../mycomponents/toast/Toast';
import helper from './../utils/helper';
import { message } from 'antd';

const $ = require('jquery');
$.DataTable = require('datatables.net');

const API_BASE_URL = helper.getAPIBaseURI();

let me = null;

export default class AddEditNewClient extends React.PureComponent {
	static contextType = MsalContext;

	constructor(props) {
		super(props);

		// state
		this.state = {
			formInputData: {
				clientId: "",
				clientName: "",
				physicians: [],
			},
			isClientIdUnique: false,

			inactive: false,
			state: "",
			returntolist: false,
			updatedAt: Date.now(),
		}

		// refs
		this.clientIdInputField = React.createRef();
		this.clientNameInputField = React.createRef();
		this.addressLine1InputField = React.createRef();
		this.addressLine2InputField = React.createRef();
		this.addressLine3InputField = React.createRef();
		this.cityInputField = React.createRef();
		this.stateInputField = React.createRef();
		this.zipInputField = React.createRef();
		this.emailInputField = React.createRef();
		this.phoneInputField = React.createRef();
		this.afterHourPhoneInputField = React.createRef();
		this.contactInputField = React.createRef();
		this.faxInputField = React.createRef();
		this.serviceRepInputField = React.createRef();
		this.salesAreaInputField = React.createRef();
		this.specialtyInputField = React.createRef();
		this.inactiveInputField = React.createRef();

		this.physicianFirstNameInputField = React.createRef();
		this.physicianLastNameInputField = React.createRef();
		this.physicianNPI = React.createRef();


		this._id = null;
	}

	async componentDidMount() {
		console.log(window.location.search);
		const params = new URLSearchParams(window.location.search);
		this._id = params.get("id");

		let accessToken = await helper.getAccessToken(this.context)

		console.log("this._id: ", this._id);
		if (this._id) {
			const user = JSON.parse(localStorage.getItem("user"));

			let response = await axios({
				url: `${API_BASE_URL}/legacy/${this._id}`,
				method: "GET",
				headers: {
					Authorization: `Bearer ${accessToken}`,
					"Content-type": "Application/json",
				},
			});

			response.data = response.data.clients[0];
			console.log("response.data: ", response.data);

			this.clientIdInputField.value = response.data.clientId;
			this.clientNameInputField.value = response.data.name;
			this.addressLine1InputField.value = response.data.addressLine1;
			this.addressLine2InputField.value = response.data.addressLine2;
			this.addressLine3InputField.value = response.data.addressLine3;
			this.cityInputField.value = response.data.city;
			this.stateInputField.value = response.data.state;
			this.zipInputField.value = response.data.zip;
			this.emailInputField.value = response.data.emailAddress;
			this.phoneInputField.value = response.data.officePhone;
			this.afterHourPhoneInputField.value = response.data.afterHoursPhone;
			this.contactInputField.value = response.data.contact;
			this.faxInputField.value = response.data.fax;
			this.serviceRepInputField.value = response.data.serviceRep;
			this.salesAreaInputField.value = response.data.salesArea;
			this.specialtyInputField.value = response.data.specialty;


			let state = helper.usStates.filter((x) => x.code == response.data.state);
			if (state.length > 0) {
				state = state[0];
			}
			else {
				state = {
					code: response.data.state,
					label: response.data.state,
					value: response.data.state,
				};
			}
			this.setState({
				inactive: response.data.inactive,
				state: state,
				isClientIdUnique: true,
				formInputData: {
					clientId: response.data.clientId,
					clientName: response.data.name,
					physicians: response.data.physicians ?? [],
				},
				updatedAt: response.data.updatedAt,
			}, () => {
				this.handlePhysicianData();
			});
		}
		else {
			const { clientId, clientName } = this.state.formInputData;
			this.clientIdInputField.value = clientId;
		}
	}
	// event handlers
	handleInputChange = (e) => {
		const inputField = e.target.name;
		const inputData = e.target.value;
		const newFormInputData = { ...this.state.formInputData };
		newFormInputData[inputField] = inputData;
		this.setState({ formInputData: newFormInputData });
	}

	handleStateInfoChange = (stateInfo) => {
		const { code } = stateInfo;
		const newFormInputData = { ...this.state.formInputData };
		newFormInputData.state = code;
		this.setState({ formInputData: newFormInputData });
	}

	handlePhysicianData = () => {
		console.log("\n===");
		console.log("inside handlePhysicianData()");
		console.log("FirstName: ", this.physicianFirstNameInputField.value);
		console.log("LastName: ", this.physicianLastNameInputField.value);
		console.log("NPI: ", this.physicianNPI.value);
		console.log("===\n");

		const newFormInputData = { ...this.state.formInputData };

		const physicianInfo = {
			firstName: this.physicianFirstNameInputField.value,
			lastName: this.physicianLastNameInputField.value,
			npi: this.physicianNPI.value,
		};

		const hasEnteredPhysicianDetails =
			physicianInfo.firstName.length > 0 && physicianInfo.lastName.length > 0 && physicianInfo.npi.length > 0;

		if (hasEnteredPhysicianDetails) {
			newFormInputData.physicians.push(physicianInfo);
			this.setState({ formInputData: newFormInputData });
		}

		// show list of physicians added by user
		const physiciansListTableId = "physicians-list";
		const $el = $(`#${physiciansListTableId}`);
		if ($.fn.DataTable.isDataTable(`#${physiciansListTableId}`))
			$el.DataTable().destroy();

		let table = $el.DataTable({
			data: newFormInputData.physicians,
			"bPaginate": false,
			"ordering": false,
			columns: [
				// {
				// 	title: "Edit",
				// 	name: 'edit',
				// 	data: null,
				// 	render: (data, type) => {

				// 		if (type == 'display') {
				// 			return `<td><input tool-tip="Edit ${data.npi}" style="cursor: pointer;" type='button' class='editPhysician' data-fn="${data.firstName}" data-ln="${data.lastName}" data-npi="${data.npi}" value="edit" /></td>`;
				// 		}

				// 		return data;
				// 	},
				// },
				{ title: "First Name", data: "firstName" },
				{ title: "Last Name", data: "lastName" },
				{ title: "NPI number", data: "npi" },
			],
		});

		$el.on("click", "tr input.editPhysician", function () {

			let npi = $(this).data('npi');
			let fn = $(this).data('fn');
			let ln = $(this).data('ln');


			this.physicianFirstNameInputField.value = fn;
			this.physicianLastNameInputField.value = ln;
			this.physicianNPI.value = npi;
		});

		// reset physician info related input fields
		this.physicianFirstNameInputField.value = "";
		this.physicianLastNameInputField.value = "";
		this.physicianNPI.value = "";
	}

	handleSubmit = async (e) => {
		e.preventDefault();
		console.log("\n===");
		console.log("New client form submitted!");
		console.log("form data: ", this.state.formInputData);
		console.log("====\n");

		let formInputData = this.state.formInputData;

		if (this._id) {
			formInputData._id = this._id;
			formInputData.clientId = this.clientIdInputField.value;
			formInputData.clientName = this.clientNameInputField.value;
			formInputData.addressLine1 = this.addressLine1InputField.value;
			formInputData.addressLine2 = this.addressLine2InputField.value;
			formInputData.addressLine3 = this.addressLine3InputField.value;
			formInputData.city = this.cityInputField.value;
			formInputData.state = this.state.state.code;
			formInputData.zip = this.zipInputField.value;
			formInputData.emailAddress = this.emailInputField.value;
			formInputData.officePhone = this.phoneInputField.value;
			formInputData.afterHoursPhone = this.afterHourPhoneInputField.value;
			formInputData.contact = this.contactInputField.value;
			formInputData.fax = this.faxInputField.value;
			formInputData.serviceRep = this.serviceRepInputField.value;
			formInputData.salesArea = this.salesAreaInputField.value;
			formInputData.specialty = this.specialtyInputField.value;
			formInputData.updatedAt = this.state.updatedAt;

		}

		formInputData.isActive = this.state.inactive ? "no" : "yes";

		const accessToken = await helper.getAccessToken(this.context)

		const user = JSON.parse(localStorage.getItem("user"));
		const option = {
			url: `${API_BASE_URL}/legacy`,
			method: this._id ? 'PUT' : 'POST',
			data: formInputData,
			headers: {
				Authorization: `Bearer ${accessToken}`
			}
		};

		const hide = message.loading(this._id ? "Updating client..." : "Creating new client...", 0);
		try {
			const response = await axios(option);
			setTimeout(hide, 0);

			if (response.status == 201) {
				message.success(this._id ? "Client edited successfully" : "New client created successfully!", 5);

				if (this.state.returntolist) {

					window.location.href = "/clientlist";
				}
			}
			else
				message.error("Client Add/Update failed!", 5);
		} catch (error) {
			setTimeout(hide, 0);
			message.error("Client add/update failed!", 5);
			console.log("handleSubmit -> error", error);
		}
	}

	// generic methods
	checkForUniquenessOfInputedClientId = async () => {
		if (this._id) {
			this.setState({ isClientIdUnique: true });
		}
		else {

			const accessToken = await helper.getAccessToken(this.context)

			const user = JSON.parse(localStorage.getItem("user"));
			const clientId = this.state.formInputData.clientId;
			const option = {
				url: `${API_BASE_URL}/legacy/islegacycustomer/${clientId}`,
				method: 'GET',
				headers: {
					Authorization: `Bearer ${accessToken}`
				}
			};

			try {
				const { data } = await axios(option);
				if (data.isLegacyCustomer) {
					message.error(`A client with the id: ${clientId} already exists!`, 5);
					this.setState({ isClientIdUnique: false });
				} else
					this.setState({ isClientIdUnique: true });
			} catch (error) {
				console.log("axios request error -> checkForUniquenessOfInputedClientId()", error);
			}
		}
	}

	// helper methods for rendering
	renderBasicClientInfoInputFields = () => {
		const { clientId, clientName } = this.state.formInputData;

		return (
			<div className="row clearfix">
				<div className="col-md-6">
					<div className="form-group required">
						<label htmlFor="clientId" className="form-label">Client Id</label>
						<div className="form-line">
							<input
								ref={(_ref) => (this.clientIdInputField = _ref)}
								type="text"
								id="clientId"
								name="clientId"
								className="form-control required"
								placeholder="example: PUK83950"
								onChange={this.handleInputChange.bind(this)}
								onBlur={this.checkForUniquenessOfInputedClientId.bind(this)}
							/>
						</div>
					</div>
				</div>
				<div className="col-md-6">
					<div className="form-group required">
						<label htmlFor="clientName" className="form-label">Client Name</label>
						<div className="form-line">
							<input
								ref={(_ref) => (this.clientNameInputField = _ref)}
								type="text"
								id="clientName"
								name="clientName"
								className="form-control required"
								placeholder="example: PUKWANA WOMEN'S CENTER"
								onChange={this.handleInputChange.bind(this)}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderFirstRowOfAddressInputFields = () => {
		return (
			<div class="row clearfix">
				<div class="col-md-6" >
					<div class="form-group required">
						<label htmlFor="addressLine1" className="form-label">Address Line 1</label>
						<div className="form-line">
							<input
								ref={(_ref) => this.addressLine1InputField = _ref}
								type="text"
								id="addressLine1"
								name="addressLine1"
								className="form-control required"
								onChange={this.handleInputChange.bind(this)}
							/>
						</div>
					</div>
				</div>
				<div class="col-md-6" >
					<div class="form-group required">
						<label htmlFor="addressLine2" className="form-label">Address Line 2</label>
						<div className="form-line">
							<input
								ref={(_ref) => (this.addressLine2InputField = _ref)}
								type="text"
								id="addressLine2"
								name="addressLine2"
								className="form-control required"
								onChange={this.handleInputChange.bind(this)}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderSecondRowOfAddressInputFields = () => {
		return (
			<div class="row clearfix">
				<div class="col-md-6" >
					<div class="form-group required">
						<label htmlFor="addressLine3" className="form-label">Address Line 3</label>
						<div className="form-line">
							<input
								ref={(_ref) => (this.addressLine3InputField = _ref)}
								type="text"
								id="addressLine3"
								name="addressLine3"
								className="form-control required"
								onChange={this.handleInputChange.bind(this)}
							/>
						</div>
					</div>
				</div>
				<div class="col-md-6" >
					<div class="form-group required">
						<label htmlFor="city" className="form-label">City</label>
						<div className="form-line">
							<input
								ref={(_ref) => (this.cityInputField = _ref)}
								type="text"
								id="city"
								name="city"
								className="form-control required"
								onChange={this.handleInputChange.bind(this)}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderThirdRowOfAddressInputFields = () => {
		return (
			<div class="row clearfix">
				<div class="col-md-6" >
					<div class="form-group required">
						<label htmlFor="state" className="form-label">State</label>
						<div className="form-line">
							<Select
								ref={(_ref) => (this.stateInputField = _ref)}
								value={this.state.state}
								class="form-control"
								id="state"
								name="state"
								label="State:"
								options={helper.usStates}
								styles={helper.colourStyles}
								onChange={(val) => {
									console.log("state Info: ", val);
									this.handleStateInfoChange(val);


									this.setState({ state: val });

								}}
							/>
						</div>
					</div>
				</div>
				<div class="col-md-6" >
					<div class="form-group required">
						<label htmlFor="zip" className="form-label">Zip Code</label>
						<div className="form-line">
							<input
								ref={(_ref) => (this.zipInputField = _ref)}
								type="text"
								id="zip"
								name="zip"
								className="form-control required"
								onChange={this.handleInputChange.bind(this)}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderAddressInputFields = () => {
		return (
			<>
				{this.renderFirstRowOfAddressInputFields()}
				{this.renderSecondRowOfAddressInputFields()}
				{this.renderThirdRowOfAddressInputFields()}
			</>
		);
	}

	renderFirstRowOfContactInputFields = () => {
		return (
			<div class="row clearfix">
				<div class="col-md-6" >
					<div class="form-group required">
						<label htmlFor="emailAddress" className="form-label">Email</label>
						<div className="form-line">
							<input
								ref={(_ref) => (this.emailInputField = _ref)}
								type="text"
								id="emailAddress"
								name="emailAddress"
								className="form-control required"
								onChange={this.handleInputChange.bind(this)}
							/>
						</div>
					</div>
				</div>
				<div class="col-md-6" >
					<div class="form-group required">
						<label htmlFor="officePhone" className="form-label">Office Phone</label>
						<div className="form-line">
							<input
								ref={(_ref) => (this.phoneInputField = _ref)}
								type="text"
								id="officePhone"
								name="officePhone"
								className="form-control required"
								onChange={this.handleInputChange.bind(this)}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderSecondRowOfContactInputFields = () => {
		return (
			<div class="row clearfix">
				<div class="col-md-6" >
					<div class="form-group required">
						<label htmlFor="afterHoursPhone" className="form-label">After Hours Phone</label>
						<div className="form-line">
							<input
								ref={(_ref) => (this.afterHourPhoneInputField = _ref)}
								type="text"
								id="afterHoursPhone"
								name="afterHoursPhone"
								className="form-control required"
								onChange={this.handleInputChange.bind(this)}
							/>
						</div>
					</div>
				</div>
				<div class="col-md-6" >
					<div class="form-group required">
						<label htmlFor="contact" className="form-label">Contact</label>
						<div className="form-line">
							<input
								ref={(_ref) => (this.contactInputField = _ref)}
								type="text"
								id="contact"
								name="contact"
								className="form-control required"
								onChange={this.handleInputChange.bind(this)}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderContactRelatedInputFields = () => {
		return (
			<>
				{this.renderFirstRowOfContactInputFields()}
				{this.renderSecondRowOfContactInputFields()}
			</>
		);
	}

	renderFaxInfoRelatedInputFields = () => {
		return (
			<div class="row clearfix">
				<div class="col-md-6" >
					<div class="form-group required">
						<label htmlFor="fax" className="form-label">Fax</label>
						<div className="form-line">
							<input
								ref={(_ref) => (this.faxInputField = _ref)}
								type="text"
								id="fax"
								name="fax"
								className="form-control required"
								onChange={this.handleInputChange.bind(this)}
							/>
						</div>
					</div>
				</div>
				<div class="col-md-6" >
					<div class="form-group required">
						<label htmlFor="serviceRep" className="form-label">Service Rep</label>
						<div className="form-line">
							<input
								ref={(_ref) => (this.serviceRepInputField = _ref)}
								type="text"
								id="serviceRep"
								name="serviceRep"
								className="form-control required"
								onChange={this.handleInputChange.bind(this)}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderBasicBusinessRelatedInputFields = () => {
		return (
			<div class="row clearfix">
				<div class="col-md-6" >
					<div class="form-group required">
						<label htmlFor="salesArea" className="form-label">Sales Area</label>
						<div className="form-line">
							<input
								ref={(_ref) => (this.salesAreaInputField = _ref)}
								type="text"
								id="salesArea"
								name="salesArea"
								className="form-control required"
								onChange={this.handleInputChange.bind(this)}
							/>
						</div>
					</div>
				</div>
				<div class="col-md-6" >
					<div class="form-group required">
						<label htmlFor="specialty" className="form-label">Specialty</label>
						<div className="form-line">
							<input
								ref={(_ref) => (this.specialtyInputField = _ref)}
								type="text"
								id="specialty"
								name="specialty"
								className="form-control required"
								onChange={this.handleInputChange.bind(this)}
							/>
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderRadioInputForInactiveStatus = () => {
		return (
			<div class="row clearfix">
				<div class="col-md-6">
					<div class="form-group required">
						<label class="form-label" htmlFor={`inactive`}>Inactive?</label>
						<div class="demo-radio-button">

							<input
								checked={this.state.inactive}
								type="radio"
								id="inactive"
								name="inactive"
								value="yes"
								className=" form-control"
								onChange={(e) => {
									this.handleInputChange.bind(this);
									this.handleInputChange(e);

									this.setState({
										inactive: e.target.value == 'yes' ? true : false,
									});
								}}
							/>
							<label class="form-label gender" htmlFor={`inactive`}>Yes &nbsp; </label>
							<input
								checked={!this.state.inactive}
								type="radio"
								id="inactive2"
								name="inactive"
								value="no"
								className=" form-control"
								onChange={(e) => {
									this.handleInputChange.bind(this);
									this.handleInputChange(e);

									this.setState({
										inactive: e.target.value == 'no' ? false : true,
									});
								}}
							/>
							<label class="form-label gender" for={`inactive2`}>No &nbsp; </label>
						</div>
					</div>
				</div>
			</div>
		);
	}

	renderPhysicianInfoInputFields = () => {
		return (
			<>
				<div class="row clearfix">
					<div class="col-md-12">
						<h5>Add Physician</h5>
					</div>
				</div>

				<div class="row clearfix">
					<div class="col-md-3" >
						<div class="form-group required">
							<label class="form-label" htmlFor="physicianFirstName">First Name</label>
							<div class="form-line">
								<input
									ref={(_ref) => (this.physicianFirstNameInputField = _ref)}
									type="text"
									id="physicianFirstName"
									name="physicianFirstName"
									class="form-control required"
									placeholder="First Name"
								/>
							</div>
						</div>
					</div>

					<div class="col-md-3">
						<div class="form-group required">
							<label class="form-label" htmlFor="physicianLastName">Last Name </label>
							<div class="form-line">
								<input
									ref={(_ref) => (this.physicianLastNameInputField = _ref)}
									type="text"
									id="physicianLastName"
									name="physicianLastName"
									class="form-control required"
									placeholder="Last Name"
								/>
							</div>
						</div>
					</div>
					<div class="col-md-3">
						<div class="form-group required">
							<label class="form-label" htmlFor="physicianNPI">NPI</label>
							<div class="form-line">
								<input
									ref={(_ref) => (this.physicianNPI = _ref)}
									type="text"
									id='physicianNPI'
									class="form-control required"
									placeholder="NPI"
								/>
							</div>
						</div>
					</div>
					<div class="col-md-3 text-center">
						<button
							type="button"
							class="btn btn-default waves-effect m-r-20"
							onClick={this.handlePhysicianData.bind(this)}
						>
							Add
						</button>
					</div>
				</div>

				<div class="row clearfix">
					<div class="col-md-12">
						<table id="physicians-list" className="display" width="100%" />
					</div>
				</div>
			</>
		);
	}

	renderSubmitButton = () => {
		return (
			<div class="row clearfix">
				<div className="col-sm-12">
					<div class="col-sm-6">
						<div class="col-md-12">
							<div class="form-group">
								<button
									id={`btnOrderSubmit1`}
									placeholder="Submit"
									type="submit"
									className="btn btn-primary text-white"
									disabled={!this.state.isClientIdUnique}
									onClick={() => {
										this.setState({ returntolist: true });
									}}
								>
									Save & Return to list
								</button>
							</div>
						</div>
					</div>
					<div class="col-sm-6">
						<div class="col-md-12">
							<div class="form-group">
								<button
									id={`btnOrderSubmit2`}
									placeholder="Submit"
									type="submit"
									className="btn btn-primary text-white"
									disabled={!this.state.isClientIdUnique}
									onClick={() => {
										this.setState({ returntolist: false });
									}}
								>
									Save client
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}

	render() {
		return (
			<div>
				<div className="block-header">
					<h2>New Client</h2>
				</div>
				<div className="panel-group card" id="accordion_105" role="tablist" aria-multiselectable="true">
					<form className="body card" onSubmit={this.handleSubmit.bind(this)}>
						{this.renderBasicClientInfoInputFields()}
						{this.renderAddressInputFields()}
						{this.renderContactRelatedInputFields()}
						{this.renderFaxInfoRelatedInputFields()}
						{this.renderBasicBusinessRelatedInputFields()}
						{this.renderRadioInputForInactiveStatus()}
						{this.renderPhysicianInfoInputFields()}
						{this.renderSubmitButton()}
					</form>
				</div>
			</div>
		);
	}
}

