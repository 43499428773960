import React from "react";
import "./modal.css";
import PropTypes from "prop-types";

export default class Modal extends React.Component {
    onClose = e => {
        this.props.onClose && this.props.onClose(e);
    };
    render() {
        console.log("Modal->this.props.show: ", this.props.show);
        if (!this.props.show) {
            return null;
        }
        return (
            <div>
                <div id="overlayExtended"></div>
                <div class="modalExtended" id="modal">
                    <span>{this.props.header}</span>
                    <div class="content">{this.props.children}</div>
                    {/* <div class="actions">
                        <button class="toggle-button" onClick={this.onClose}>
                            close
                        </button>
                    </div> */}
                </div>
            </div>
        );
    }
}
Modal.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired
};