import React, { useState, useEffect } from "react";
//import dotenv from "dotenv";
import axios from "axios";
import ReactDOM from 'react-dom';
import { Redirect, useHistory } from "react-router-dom";
import { protectedResources } from "./../authConfig";
import { MsalContext } from "@azure/msal-react";

import PropTypes from 'prop-types'

import DeleteModal from "./../mycomponents/modal";
import ProgressBar from "./../mycomponents/progressbar";

import helper from './../utils/helper';

import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-bs/css/dataTables.bootstrap.css';
const $ = require('jquery');
$.DataTable = require('datatables.net');


//dotenv.config();

const API_BASE_URL = helper.getAPIBaseURI();
let me = null;
export default class PatientsContent extends React.PureComponent {
    static contextType = MsalContext;

    static contextTypes = {
        router: PropTypes.object
    }

    constructor(props) {
        super(props);

        console.log("constructor->props: ", this.props);


        this.state = {
            patients: [],
            loading: true,
            showTimer: true,
            showDeleteModal: false,
            deletePatientId: null,
            deletePatientName: "",
            showProgressBar: false,
        };


        this.patientDataTable = React.createRef();

        this.handleButtonPress = this.handleButtonPress.bind(this)
        this.handleButtonRelease = this.handleButtonRelease.bind(this)
    }
    handleButtonPress() {
        this.buttonPressTimer = setTimeout(() => this.setState({ showProgressBar: true }), 1000);
    }

    handleButtonRelease() {
        clearTimeout(this.buttonPressTimer);
    }
    callDataTable(datatotables) {
        // let history = useHistory();

        // first time, el will be null
        // see: https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs

        console.log("this.patientDataTable: ", this.patientDataTable);

        me = this;
        if (!this.patientDataTable) return;

        console.log("datatotables: ", datatotables);

        let $el = $(this.patientDataTable);
        //  console.log(this.$el.DataTable);
        let table = $el.DataTable({
            pageLength: 25,
            data: datatotables,
            columns: [
                { title: "Patient ID", data: "patientID", name: "patientID" },
                { title: "First Name", data: "firstName", name: "firstName" },
                { title: "Last Name", data: "lastName", name: "lastName" },
                { title: "Age(Year)", data: "age", name: "age" },
                // { title: "Address", data: "address", "name": "address", width: 150 },
                {
                    title: "Address", data: null, "name": "Address", width: 150,
                    render: function (data, type) {
                        if (type === "display") {
                            if (typeof data.address == 'object') {
                                return `${data.address.address_1}, ${typeof data.address.address_2 != 'undefined' && data.address.address_2.length > 0 ? data.address.address_2 : ""}, ${data.address.city}, ${data.address.state}, ${data.address.zipCode}`;
                            }
                            else {

                                let _address = data.address;

                                // if (typeof data.address == 'string' && data.address != '[object Object]') {
                                //     _address = JSON.parse(data.address);

                                //     const _retAddress = `${_address.address_1}, ${_address.address_2}, ${_address.city}, ${_address.state}, ${_address.zipCode}`;
                                //     console.log("_retAddress: ", _retAddress)
                                //     return _retAddress;
                                // }
                                console.log("_address: ", _address)
                                return _address;
                            }
                        }
                        return data;
                    }
                },
                { title: "Phone Number", data: "phoneNo", "name": "phoneNo" },
                {
                    title: "Status", data: null, "name": "status",
                    render: function (data, type) {
                        if (type === "display") {
                            if (data.status === "Approved") {
                                return `<td>
                                <span class="label label-success">${data.status}</td>
                            </td> `;
                            }
                            else if (data.status === "Pending") {
                                return `<td>
                                <span class="label label-default">Pending</span>
                            </td> `;
                            }
                            else if (data.status === "Denied") {
                                return `<td>
                                <span class="label label-danger">${data.status}</td>
                            </td> `;
                            }
                            else if (data.status === "Locked") {
                                return `<td>
                                <span class="label label-danger">${data.status}</td>
                            </td> `;
                            }

                        }
                        return data;
                    }
                },
                {
                    title: "",
                    name: "",
                    data: null,
                    sortable: false,
                    render: function (data, type) {
                        if (type === "display") {
                            console.log("data: ", data);

                            let lockIcon = `<img src="/icons/icons8-lock-50.svg" />`;
                            if (data.isLocked) {
                                lockIcon = `<img src="/icons/icons8-padlock-50.svg" />`;
                            }



                            return `<td>
                                    <a class="rowOption editClass" data-id="${data._id}"><img src="/icons/icons8-edit-50.svg" /></a>
                                    <a data-id="${data._id}" data-name="${data.firstName} ${data.lastName}" id="option-delete" class="rowOption deleteClass"><img src="/icons/icons8-delete-trash-50.svg" /></a>
                                    <a id="option-lock" class="rowOption lockClass">${lockIcon}</a>
                                </td> `;

                        }
                        return data;
                    }
                }
            ]
        });

        $el.on('click', 'tr a.deleteClass', function () {
            let item_id = ($(this).data("id"));
            let patient_name = ($(this).data("name"));
            // console.log("data: ", $(this).data());//.parent("tr").data());
            let yes = false;
            me.setState({
                showDeleteModal: true,
                deletePatientId: item_id,
                deletePatientName: patient_name,
            })

        });


        $el.on('click', 'tr a.editClass', function () {
            let item_id = ($(this).data("id"));

            let editHref = `/editpatient?id=${item_id}`;

            console.log("editHref: ", editHref);
            // console.log("me.props: ", me.props);
            // me.props.history.push(editHref);
            // return <Redirect to={editHref} />

            // me.context.router.history.push(editHref);


            window.location.href = editHref;
        });
    }

    async componentDidMount() {

        const user = JSON.parse(localStorage.getItem("user"));
        console.log("user.token: ", user.token);

        const accessToken = await helper.getAccessToken(this.context)

        axios({
            url: `${API_BASE_URL}/patients`,
            method: "GET",
            headers: {
                "Content-type": "Application/json",
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(({ data }) => {
                console.log("data.patients: ", data.patients);
                // setPatients(data.patients);

                this.setState({ loading: false }, () => {

                    this.callDataTable(data.patients);

                });
            })
            .catch((error) => {
                console.log("PatientContent->componentDidMount->error: ", error);
            });
    }

    showModal = e => {
        this.setState({
            showDeleteModal: !this.state.showDeleteModal
        });
    };
    render() {

        if (!this.state.loading) {
            return (
                <div>
                    <div class="block-header">
                        <h2>Patients</h2>
                        {/* <ul class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/Orders">Orders</a></li>
                            <li class="breadcrumb-item active">View All Patients</li>
                        </ul> */}
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12 col-xs-12">
                            <div class="card patients-list">
                                <div class="body">
                                    <div class="table-responsive">
                                        <table id="patients_table" className="display" width="100%" ref={el => (this.patientDataTable = el)} />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <DeleteModal onClose={this.showModal} show={this.state.showDeleteModal}>
                        <div>
                            <span>Hold delete button for 3 seconds in order to confirm delete Patient {this.state.deletePatientName}</span>
                        </div>
                        <div class="delete-buttons">
                            <button class="btn waves-effect btn-delete"
                                onTouchStart={this.handleButtonPress}
                                onTouchEnd={this.handleButtonRelease}
                                onMouseDown={this.handleButtonPress}
                                onMouseUp={this.handleButtonRelease}
                                onMouseLeave={this.handleButtonRelease}
                            // onClick={this.deletePatient}
                            >
                                Delete
                            </button>
                            <button class="btn btn-raised g-bg-blue waves-effect" onClick={this.showModal}>
                                Cancel
                            </button>
                        </div>
                        <div>
                            {this.state.showProgressBar && <ProgressBar entity={"patient"} id={this.state.deletePatientId} name={this.state.deletePatientName} />}
                        </div>
                    </DeleteModal>

                </div>

            );

        }
        else {
            return <div>
                <div class="block-header">
                    <h2>Patients</h2>
                    {/* <ul class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/orders">Orders</a></li>
                        <li class="breadcrumb-item active">View All Patients</li>
                    </ul> */}
                </div>
                <div class="row clearfix">
                    <div class="col-md-12 col-xs-12">
                        <div class="card patients-list">
                            <div class="body">

                            </div>
                        </div>
                    </div>
                </div>
            </div>;
        }

    }
}
