import React, { useState, useEffect } from "react";
//import dotenv from "dotenv";
import axios from "axios";
import { message } from 'antd';
import { protectedResources } from "./../authConfig";
import { MsalContext } from "@azure/msal-react";

import DeleteModal from "../mycomponents/modal";
import ProgressBar from "../mycomponents/progressbar";

import DatePicker from 'react-datepicker';
import helper from "../utils/helper";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net-bs/css/dataTables.bootstrap.css";
import moment from "moment";

import Spinner from "./../Spinner/Spinner";
import TextArea from "antd/lib/input/TextArea";

const $ = require("jquery");
$.DataTable = require("datatables.net");
// require( 'datatables.net-bs' )( window, $ );

//dotenv.config();

const API_BASE_URL = helper.getAPIBaseURI();
let me = null;
export default class ReportReviewContent extends React.PureComponent {
  static contextType = MsalContext;

  constructor(props) {
    super(props);

    this.state = {
      orders: [],
      loading: true,
      showLoader: false,

      showOrderModal: false,
      deleteOrderId: null,
      showProgressBar: false,

      startDate: "",
      endDate: "",

      showReportContent: false,
      reportSignedUrl: "",
      selectedOrderId: "",
      clinicalNotes: "",
      isSelectedReportPositive: false,

      toastMessage: "",
      list: [],
      toastList: [],

      //For PDF
      numPages: 0,
      pageNumber: 0,

      userRole: "",
      labReviewerName: "",
      clinicalReviewerName: "",
      isOvaReport: false,

      originalData: [],
      filteredData: [],
      orderId: "",
    };

    this.orderDataTable = React.createRef();

    this.handleButtonPress = this.handleButtonPress.bind(this);
    this.handleButtonRelease = this.handleButtonRelease.bind(this);

    me = this;

    this.$el = null;

    this.$ova1PlusCheckBox = null;
    this.$genetixCheckBox = null;
    this.$ovaWatchCheckBox = null;
  }

  showToast(type) {
    const id = Math.floor(Math.random() * 101 + 1);

    switch (type) {
      case "success":
        this.toastProperties = {
          id,
          title: "Success",
          description: "This is a success toast component",
          backgroundColor: "#5cb85c",
          icon: "/assets/assets/check.svg",
        };
        break;
      case "danger":
        this.toastProperties = {
          id,
          title: "Danger",
          description: "This is a error toast component",
          backgroundColor: "#d9534f",
          icon: "/assets/assets/error.svg",
        };
        break;
      case "info":
        this.toastProperties = {
          id,
          title: "Info",
          description: "This is an info toast component",
          backgroundColor: "#5bc0de",
          icon: "/assets/assets/info.svg",
        };
        break;
      case "warning":
        this.toastProperties = {
          id,
          title: "Warning",
          description: "This is a warning toast component",
          backgroundColor: "#f0ad4e",
          icon: "/assets/assets/warning.svg",
        };
        break;

      default:
        this.setState({ toastList: [] });
    }

    // this.setState([...this.state.list, this.toastProperties]);
    this.setState({ list: [this.toastProperties] });
  }
  handleButtonPress() {
    this.buttonPressTimer = setTimeout(
      () => this.setState({ showProgressBar: true }),
      1000
    );
  }

  handleButtonRelease() {
    clearTimeout(this.buttonPressTimer);
  }
  callDataTable(datatotables) {
    const me = this;

    const user = JSON.parse(localStorage.getItem("user"));

    // first time, el will be null
    // see: https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs

    console.log("this.orderDataTable: ", this.orderDataTable);

    if (!this.orderDataTable) return;

    console.log("datatotables: ", datatotables);

    if (this.$el) {
      this.$el.DataTable().clear().destroy();
    }

    this.$el = $(this.orderDataTable);

    this.$el.DataTable({
      pageLength: 25,
      order: [],
      data: datatotables,
      columns: [
        {
          title: "QBench Order ID",
          data: "orderId",
          name: "orderId",
          // width: "10px",
          sortable: true,
        },
        {
          title: "Report ID",
          data: null,
          name: "baylorReportId",
          // width: "10px",
          sortable: true,
          render: function (data, type) {

            if (type === 'display') {


              if ((typeof data.galenReportId === 'undefined' || !data.galenReportId) && (typeof data.ova1ReportId === 'undefined' || !data.ova1ReportId)) {
                return `<td>
                                <span>${data.baylorReportId}</td>
                            </td> `;
              }
              else {
                if (data.ova1ReportId) {
                  return `<td>
                  <span>${data.ova1ReportId}</td>
              </td> `;
                }
                else {
                  return `<td>
                                <span>${data.galenReportId}</td>
                            </td> `;
                }
              }
            }

            return data;
          },
        },
        {
          title: "Report Status",
          data: "reportStatus",
          name: "reportStatus",
          width: "50px",
        },

        {
          title: "Report Conclusion",
          data: null,
          name: "reportConclusion",
          // width: "150px",
          render: function (data, type) {

            if (type == 'display') {
              if (typeof data.reportConclusion == 'undefined' || !data.reportConclusion) {
                return "";
              }
              else {
                let imgPath = "";
                if (data.reportConclusion == 'Positive' || data.reportConclusion == 'positive') {
                  imgPath = `/icons/Positive.png`;
                }
                else if (data.reportConclusion == 'VUS') {
                  imgPath = `/icons/VUS.png`;
                }
                else if (data.reportConclusion == "Cancelled" ||
                  data.reportConclusion == "cancelled") {
                  imgPath = `/icons/cancelled.png`;
                }
                else {
                  imgPath = `/icons/Negative.png`;
                }
                return `<td>
                                
                <img style="width: 40; height: 40;" class="positive_negative_image" src="${imgPath}" />
                </td>
            </td>`;

              }
            }
            return data;
          },
        },

        {
          title: "",
          name: "",
          data: null,
          sortable: false,
          render: function (data, type) {
            if (type === "display") {
              console.log("uploadDownloadClass->data: ", data);

              let reportConclusion = '';
              if (typeof data.baylorReportConclusion !== 'undefined' && data.baylorReportConclusion) {
                reportConclusion = data.baylorReportConclusion;
              }
              else if (typeof data.galenReportConclusion !== 'undefined' && data.galenReportConclusion) {
                reportConclusion = data.galenReportConclusion;
              }
              return `<td>
                                    <a style="cursor: pointer;" class="ViewReportClass" data-reportconclusion="${reportConclusion}" data-orderid="${data.orderId}" data-reportid="${data.baylorReportId}" data-galenreportid="${data.galenReportId}" data-ova1reportid="${data.ova1ReportId}">Review</a>
                                </td> `;
            }
            return data;
          },
        },
      ],

      "initComplete": function (settings, json) {

        console.log("settings: ", settings);
        console.log("json: ", json);

        var table = me.$el.DataTable();


        if (me.state.orderId && me.state.orderId.length > 0) {
          table.search(me.state.orderId).draw();
          localStorage.removeItem('redirect');
          localStorage.removeItem("orderId");
        }


      },

    });


    this.$el.off().on("click", "tr a.ViewReportClass", function () {
      let custom_formatted_id = $(this).data("orderid");
      let report_id = $(this).data("reportid");
      let galen_report_id = $(this).data("galenreportid");
      let ova1ReportId = $(this).data("ova1reportid");

      let isPositiveReport = $(this).data("reportconclusion").toLowerCase() === "positive";

      document.body.classList.add('busy-cursor');

      console.log("report_id: ", report_id, "custom_formatted_id: ", custom_formatted_id);
      me.setState(
        { showLoader: true, loading: false, },
        async () => {

          const accessToken = await helper.getAccessToken(me.context);


          if (report_id) {
            axios({
              url: `${API_BASE_URL}/baylor/reports/${custom_formatted_id}/signedUrl`,
              method: "GET",
              headers: {
                "Content-type": "Application/json",
                Authorization: `Bearer ${accessToken}`,
              },
              // data: _payload,
            }).then(async ({ data }) => {
              console.log("data: ", data);

              document.body.classList.remove('busy-cursor');

              // get lab/clinical reviewer details who reviewed this report before this user
              let response = await axios({
                url: `${API_BASE_URL}/orders/${custom_formatted_id}/clinical reviewer`,
                method: "GET",
                headers: {
                  "Content-type": "Application/json",
                  Authorization: `Bearer ${accessToken}`,
                }
              });
              const clinicalReviewerName = response.data;

              response = await axios({
                url: `${API_BASE_URL}/orders/${custom_formatted_id}/lab reviewer`,
                method: "GET",
                headers: {
                  "Content-type": "Application/json",
                  Authorization: `Bearer ${accessToken}`,
                }
              });
              const labReviewerName = response.data;

              me.setState(
                {
                  showReportContent: true,
                  reportSignedUrl: data,
                  selectedOrderId: custom_formatted_id,
                  clinicalNotes: "",
                  isSelectedReportPositive: isPositiveReport,
                  loading: false,
                  showLoader: false,
                  toastMessage: `Successfully Viewed the Report, SEE BOTTOM`,
                  clinicalReviewerName,
                  labReviewerName,
                  isOvaReport: false,
                },
                () => {
                  //me.showToast("success");
                  message.success(me.state.toastMessage);
                }
              );
            }).catch((error) => {
              console.log("error on getting signedurl: ", error);

              document.body.classList.remove('busy-cursor');

              me.setState(
                {
                  loading: false,
                  showLoader: false,
                  toastMessage: `Viewing Report error: ${error}`,
                },
                () => {
                  //me.showToast("success");
                  message.success(me.state.toastMessage);
                }
              );

            })
          }
          else if (galen_report_id) {
            axios({
              url: `${API_BASE_URL}/galen/reports/${custom_formatted_id}/signedUrl`,
              method: "GET",
              headers: {
                "Content-type": "Application/json",
                Authorization: `Bearer ${accessToken}`,
              },
              // data: _payload,
            }).then(async ({ data }) => {
              console.log("data: ", data);

              document.body.classList.remove('busy-cursor');

              // get lab/clinical reviewer details who reviewed this report before this user
              let response = await axios({
                url: `${API_BASE_URL}/orders/${custom_formatted_id}/clinical reviewer`,
                method: "GET",
                headers: {
                  "Content-type": "Application/json",
                  Authorization: `Bearer ${accessToken}`,
                }
              });
              const clinicalReviewerName = response.data;

              response = await axios({
                url: `${API_BASE_URL}/orders/${custom_formatted_id}/lab reviewer`,
                method: "GET",
                headers: {
                  "Content-type": "Application/json",
                  Authorization: `Bearer ${accessToken}`,
                }
              });
              const labReviewerName = response.data;

              me.setState(
                {
                  showReportContent: true,
                  reportSignedUrl: data,
                  selectedOrderId: custom_formatted_id,
                  clinicalNotes: "",
                  isSelectedReportPositive: isPositiveReport,
                  loading: false,
                  showLoader: false,
                  toastMessage: `Successfully Viewed the Report, SEE BOTTOM`,
                  clinicalReviewerName,
                  labReviewerName,
                  isOvaReport: false,
                },
                () => {
                  //me.showToast("success");
                  message.success(me.state.toastMessage);
                }
              );
            }).catch((error) => {
              document.body.classList.remove('busy-cursor');

              me.setState(
                {
                  loading: false,
                  showLoader: false,
                  toastMessage: `Viewing Report error: ${error}`,
                },
                () => {
                  //me.showToast("success");
                  message.success(me.state.toastMessage);
                }
              );

            })
          }
          else if (ova1ReportId) {
            axios({
              url: `${API_BASE_URL}/ova1/reports/${custom_formatted_id}/signedUrl`,
              method: "GET",
              headers: {
                "Content-type": "Application/json",
                Authorization: `Bearer ${accessToken}`,
              },
              // data: _payload,
            }).then(async ({ data }) => {
              console.log("data: ", data);

              document.body.classList.remove('busy-cursor');

              // get lab/clinical reviewer details who reviewed this report before this user
              let response = await axios({
                url: `${API_BASE_URL}/orders/${custom_formatted_id}/clinical reviewer`,
                method: "GET",
                headers: {
                  "Content-type": "Application/json",
                  Authorization: `Bearer ${accessToken}`,
                }
              });
              const clinicalReviewerName = response.data;

              response = await axios({
                url: `${API_BASE_URL}/orders/${custom_formatted_id}/lab reviewer`,
                method: "GET",
                headers: {
                  "Content-type": "Application/json",
                  Authorization: `Bearer ${accessToken}`,
                }
              });
              const labReviewerName = response.data;

              me.setState(
                {
                  showReportContent: true,
                  reportSignedUrl: data,
                  selectedOrderId: custom_formatted_id,
                  clinicalNotes: "",
                  isSelectedReportPositive: isPositiveReport,
                  loading: false,
                  showLoader: false,
                  toastMessage: `Successfully Viewed the Report, SEE BOTTOM`,
                  clinicalReviewerName,
                  labReviewerName,
                  isOvaReport: true,
                },
                () => {
                  //me.showToast("success");
                  message.success(me.state.toastMessage);
                }
              );
            }).catch((error) => {
              document.body.classList.remove('busy-cursor');

              me.setState(
                {
                  loading: false,
                  showLoader: false,
                  toastMessage: `Viewing Report error: ${error}`,
                },
                () => {
                  //me.showToast("success");
                  message.success(me.state.toastMessage);
                }
              );

            })
          }
        });
    });
  }
  async componentDidMount() {
    const me = this;



    let user = JSON.parse(localStorage.getItem("user"));

    console.log("ReportReviewContent->user: ", user);
    if (user) {
      console.log("user found in localStorage!");
    }
    else {
      window.location.replace('/');
    }
    console.log("user.token: ", user.token);

    console.log("\n====");
    console.log("Logged in user: ", user);
    console.log("====\n");


    const accessToken = await helper.getAccessToken(this.context);
    if (process.env.REACT_APP_ENV !== 'DEV') {
      if (!user) {

        ({ data: { user } } = await axios.get(`${API_BASE_URL}/users/current`, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        }));

        localStorage.setItem("user", JSON.stringify(user));
      }


    }


    let _url = `${API_BASE_URL}/orders/reportReview`;

    document.body.classList.add('busy-cursor');
    this.setState({
      loading: true,
      userRole: user.role.name,
    }, async () => {


      axios({
        url: _url,
        method: "GET",
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }).then(({ data }) => {
        console.log("data: ", data);
        let orderId = helper.getOrderIdFromQueryString("orderId");

        this.setState({ loading: false, originalData: data, filteredData: data, orderId }, () => {

          this.$ova1PlusCheckBox.checked = true;
          this.$genetixCheckBox.checked = true;
          this.$ovaWatchCheckBox.checked = true;

          this.callDataTable(data);

          document.body.classList.remove('busy-cursor');


        });
      });
    });
  }

  showModal = (e) => {
    this.setState({
      showOrderModal: !this.state.showOrderModal,
    });
  };

  async handleReviewed(reviewTypeStr) {
    const me = this;
    this.setState({
      loading: true,
    }, async () => {
      const accessToken = await helper.getAccessToken(this.context)

      let _url = `${API_BASE_URL}/baylor/report-reviewed/${reviewTypeStr}/${this.state.selectedOrderId}`;
      axios({
        url: _url,
        method: "POST",
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          reviewNote: this.state.clinicalNotes
        }
      }).then(({ data }) => {
        console.log("data: ", data);

        this.setState({ loading: false }, () => {

          document.body.classList.remove('busy-cursor');

          // window.location.reload();
          window.location.href = window.location.href.split('?')[0];
        });
      });
    });
  }

  handlePutReportOnHold = async () => {
    const me = this;


    this.setState({ loading: true });

    const accessToken = await helper.getAccessToken(this.context)

    const url = `${API_BASE_URL}/baylor/reports-on-hold/${this.state.selectedOrderId}`;
    await axios({
      url,
      method: "PUT",
      headers: {
        "Content-type": "Application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });
    this.setState({ loading: false });
    // window.location.reload();
    window.location.href = window.location.href.split('?')[0];
  }


  //For PDF
  /*To Prevent right click on screen*/
  // window.document.addEventListener("contextmenu", (event) => {
  //   event.preventDefault();
  // });

  /*When document gets loaded successfully*/
  onDocumentLoadSuccess({ numPages }) {
    this.setState({ numPages: numPages, pageNumber: 1, });

  }

  changePage(offset) {
    this.setState({ pageNumber: (this.state.pageNumber + offset) });
  }

  previousPage() {
    this.changePage(-1);
  }

  nextPage() {
    this.changePage(1);
  }

  loggedInUserHasRole = (roleStr) => {
    const user = JSON.parse(localStorage.getItem("user"));
    const allRolesNameArr = user.allRoles.map(role => role.name.toLowerCase());

    console.log("loggedInUserHasRole->allRolesNameArr: ", allRolesNameArr);
    return (allRolesNameArr.includes(roleStr.toLowerCase()) || allRolesNameArr.includes('portal_admin'));
  }

  // helper methods for rendering
  renderLabReviewButton = () => {
    const { labReviewerName } = this.state;
    const buttonLabel = labReviewerName ? `Lab Reviewed By ${labReviewerName}` : "Lab Reviewed";
    const hasLabReviewerRole = this.loggedInUserHasRole("Portal_Lab_Reviewer");
    return (
      <div className="form-group btn-report-review">
        <button
          id="btnSearchSubmit"
          name="submit"
          className="btn btn-primary text-white"
          disabled={!hasLabReviewerRole || labReviewerName}
          onClick={() => this.handleReviewed("lab reviewer")}
        >
          {buttonLabel}
        </button>
      </div>
    );
  }

  renderClinicalReviewedButtton = () => {
    const { clinicalReviewerName } = this.state;
    const buttonLabel = clinicalReviewerName ? `Clinical Reviewed By ${clinicalReviewerName}` : "Clinical Reviewed";
    const hasClinicalReviewerRole = this.loggedInUserHasRole("clinical reviewer");
    console.log("hasClinicalReviewerRole: ", hasClinicalReviewerRole);
    return (
      <div className="form-group btn-report-review">
        <button
          id="btnSearchSubmit"
          name="submit"
          className="btn btn-primary text-white"
          disabled={!hasClinicalReviewerRole || clinicalReviewerName}
          onClick={() => this.handleReviewed("clinical reviewer")}
        >
          {buttonLabel}
        </button>
      </div>
    );
  }

  renderPutReportOnHoldButton = () => {
    const buttonLabel = "Put report on hold";
    const hasLabReviewerRole = this.loggedInUserHasRole("lab reviewer");
    const hasClinicalReviewerRole = this.loggedInUserHasRole("clinical reviewer");

    return (
      <div className="form-group btn-report-review">
        <button
          id="btnSearchSubmit"
          name="submit"
          className="btn btn-primary text-white"
          disabled={!(hasLabReviewerRole || hasClinicalReviewerRole)}
          onClick={this.handlePutReportOnHold}
        >
          {buttonLabel}
        </button>
      </div>
    );
  }

  renderClinicalNotesInputSection = () => {
    const isClinicalReviewer = this.loggedInUserHasRole("clinical reviewer");
    const isPositiveReport = this.state.isSelectedReportPositive;

    if (isClinicalReviewer && isPositiveReport) {
      return (
        <div className="btn-report-review" style={{ width: "55%" }}>
          <TextArea
            placeholder={"Add some notes..."}
            value={this.state.clinicalNotes}
            onChange={(e) => this.setState({ clinicalNotes: e.target.value })}
            rows={5}
          />
        </div>
      );
    }

    return null;
  }

  renderReportReviewButtons = () => {
    return (
      <>
        <div id="ignoreMeInPDF" class="row clearfix d-flex flex-row">
          {this.renderLabReviewButton()}
          {!this.state.isOvaReport && this.renderClinicalReviewedButtton()}
          {this.renderPutReportOnHoldButton()}
        </div>
        <div id="ignoreMeInPDF" class="row clearfix d-flex flex-row">
          {this.renderClinicalNotesInputSection()}
        </div>
      </>
    );
  }

  handleFilterCheckboxesCheckUncheck = () => {

    let printData = [], dataOva1Plus = [], dataGenetix = [], dataOvaWatch = [];
    if (this.$ova1PlusCheckBox.checked) {
      dataOva1Plus = this.state.originalData.filter((x) => x.testCode.toLowerCase() === 'ova1' || x.testCode.toLowerCase() === 'vds-125');

      // printData = dataOva1Plus;
    }

    if (this.$genetixCheckBox.checked) {
      dataGenetix = this.state.originalData.filter((x) => x.testCode.toLowerCase().includes('genetix'));

      // printData = printData.concat(dataGenetix).unique();;
    }
    if (this.$ovaWatchCheckBox.checked) {
      dataOvaWatch = this.state.originalData.filter((x) => x.testCode.toLowerCase() === 'ovawatch' || x.testCode.toLowerCase() === 'ovasight' || x.testCode.toLowerCase() === 'vds-200');

      // printData = printData.concat(dataOvaWatch).unique();;
    }

    printData = dataOva1Plus;
    printData = printData.concat(dataGenetix);
    printData = printData.concat(dataOvaWatch);

    this.callDataTable(printData);

  }
  renderFilterCheckboxes = () => {
    return <>
      <label class="form-label" for={`filterCheckBoxes`}>Filter By</label>
      <div id={`ova1_q02`} class="demo-checkbox">
        <input onChange={(e) => this.handleFilterCheckboxesCheckUncheck()} ref={(_ref) => this.$ova1PlusCheckBox = _ref} data-val="Low Risk" type="checkbox" id={`clinicinfo_lowrisk`} name="clinicinfo_lowrisk" class=" filled-in" />
        <label for={`clinicinfo_lowrisk`}>Ova1Plus &nbsp; </label>
        <input onChange={(e) => this.handleFilterCheckboxesCheckUncheck()} ref={(_ref) => this.$genetixCheckBox = _ref} data-val="High Risk" type="checkbox" id={`clinicinfo_highrisk`} name="clinicinfo_highrisk" class=" filled-in" />
        <label for={`clinicinfo_highrisk`}>Genetix &nbsp; </label>
        <input onChange={(e) => this.handleFilterCheckboxesCheckUncheck()} ref={(_ref) => this.$ovaWatchCheckBox = _ref} data-val="Not Definitive" type="checkbox" id={`clinicinfo_NotDefinitive`} name="clinicinfo_NotDefinitive" class=" filled-in" />
        <label for={`clinicinfo_NotDefinitive`}>OvaWatch &nbsp; </label>
      </div>
    </>
  }
  render() {

    if (!this.state.loading) {
      return (
        <div>
          <div class="block-header">
            <h2>{this.state._pageTitle}</h2>

          </div>
          <div class="row clearfix">
            <div class="col-md-12 col-xs-12">
              <div class="card orders-list">
                <div class="body">
                  {this.renderFilterCheckboxes()}
                </div>
              </div>
            </div>
          </div>
          <div class="row clearfix">
            <div class="col-md-12 col-xs-12">
              <div class="card orders-list">
                <div class="body">
                  <div class="table-responsive">
                    <table
                      className="display"
                      width="100%"
                      ref={(el) => (this.orderDataTable = el)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.showLoader && <div>
            <Spinner />
          </div>}
          {
            this.state.showReportContent && <>
              <div>
                
                {this.renderReportReviewButtons()}

                
                <div class="row clearfix">
                  <div class="col-md-12 col-xs-12">


                    
                    <hr />
                    <object
                      data={`${this.state.reportSignedUrl}`}
                      type="application/pdf"
                      width="100%"
                      height="678"
                    >

                      <iframe
                        src={`${this.state.reportSignedUrl}`}
                        width="100%"
                        height="678"
                      >
                        <p>This browser does not support PDF!</p>
                      </iframe>

                    </object>
                  </div>
                </div>
              </div>
            </>
          }
        </div>
      );
    } else {
      return (
        <div>
          <div class="block-header">
            <h2>Orders</h2>
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/orders">Orders</a>
              </li>
              <li class="breadcrumb-item active">View All Orders</li>
            </ul>
          </div>
          <div class="row clearfix">
            <div class="col-md-12 col-xs-12">
              <div class="card orders-list">
                <div class="body">
                  <Spinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
