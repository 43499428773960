import React, { PureComponent } from "react";

import PropTypes from 'prop-types';
import $ from 'jquery';
import helper from './../utils/helperPortal';

//import DatePicker from 'react-datepicker';

export default class HBOCFamilyHistoryInfoForm2 extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {

        }

    }

    componentDidMount() {
        $(document).ready(() => {
            $('#btnHBOCFamilyHistoryInfoForm2Reset').click(() => {
                $('#ova1GenetixHBOCFamilyHistoryFormContainer').find(':input').map((i, elem) => {

                    const _type = $(elem).attr('type');
                    console.log("_type: ", _type);
                    helper.resetForm(_type, elem);
                })
            })
        })
    }

    render() {


        const noPreviousPersonalCancerHistoryClick2 = () => {
            var pfHistory2_noCancer = document.getElementById("pfHistory2_noCancer");
            
            if (pfHistory2_noCancer.checked == true) {
                
                $('#personalHistoryArea2').find('input').map((i, elem) => {
                    $(elem).prop('checked', false);
                    $(elem).prop('disabled', true);
                }) 
                previousCancerHistoryClick2(); 
                SomaticMutationProfClick2(); 
                PrevGermltestingClick2(); 

                document.getElementById("pfHistory2_BrOvPanAnyAge2").checked = true;
                pfHistory2_BrOvPanAnyAge2Click();

                document.getElementById("pfHistory2_ColorUterineAnyAge2").checked = true;
                pfHistory2_ColorUterineAnyAge2Click(); 
                         
            }else {
                $('#personalHistoryArea2').find('input').map((i, elem) => {
                    $(elem).prop('checked', false);
                    $(elem).prop('disabled', false);
                }) 
            }
        };

        const previousCancerHistoryClick2 = () => {

            var pfHistory2_previousCancer = document.getElementById("pfHistory2_previousCancer");

            if (pfHistory2_previousCancer.checked == true) {
                $("#pfHistory2_prevCanDefine_div").removeClass(" hideFormContent");
                $("#pfHistory2_prevCanDefine_div").addClass(" showFormContent");
                $("#pfHistory2_prevCanDefine").addClass(" required");
            } else {
                $("#pfHistory2_prevCanDefine_div").addClass(" hideFormContent");
                $("#pfHistory2_prevCanDefine_div").removeClass(" showFormContent");
                $("#pfHistory2_prevCanDefine").removeClass(" required");
            }
        };

        const SomaticMutationProfClick2 = () => {

            var pfHistory2_SomaticMutationProf = document.getElementById("pfHistory2_SomaticMutationProf");

            if (pfHistory2_SomaticMutationProf.checked == true) {

                $("#pfHistory2_SoMutaProfDefine_div").removeClass(" hideFormContent");
                $("#pfHistory2_SoMutaProfDefine_div").addClass(" showFormContent");
                $("#pfHistory2_SoMutaProfDefine").addClass(" required");
            } else {
                $("#pfHistory2_SoMutaProfDefine_div").removeClass(" showFormContent");
                $("#pfHistory2_SoMutaProfDefine_div").addClass(" hideFormContent");
                $("#pfHistory2_SoMutaProfDefine").removeClass(" required");
            }
        };

        const PrevGermltestingClick2 = () => {

            var pfHistory2_PrevGermltesting = document.getElementById("pfHistory2_PrevGermltesting");

            if (pfHistory2_PrevGermltesting.checked == true) {
                $("#pfHistory2_PrevGermltestDefine_div").removeClass(" hideFormContent");
                $("#pfHistory2_PrevGermltestDefine_div").addClass(" showFormContent");
                $("#pfHistory2_PrevGermltestDefine").addClass(" required");
            } else {
                $("#pfHistory2_PrevGermltestDefine_div").removeClass(" showFormContent");
                $("#pfHistory2_PrevGermltestDefine_div").addClass(" hideFormContent");
                $("#pfHistory2_PrevGermltestDefine").removeClass(" required");
            }
        };

        const pfHistory2_BrOvPanAnyAgeClick = () => {

            var pfHistory2_BrOvPanAnyAge = document.getElementById("pfHistory2_BrOvPanAnyAge");

            if (pfHistory2_BrOvPanAnyAge.checked == true) {
                $("#pfHistory2_BrOvPanAnyAge_Div").removeClass(" hideFormContent");
                $("#pfHistory2_BrOvPanAnyAge_Div").addClass(" showFormContent");
            }
        };

        const pfHistory2_BrOvPanAnyAge2Click = () => {

            var pfHistory2_BrOvPanAnyAge2 = document.getElementById("pfHistory2_BrOvPanAnyAge2");

            if (pfHistory2_BrOvPanAnyAge2.checked == true) {
                $("#pfHistory2_BrOvPanAnyAge_Div").addClass(" hideFormContent");
                $("#pfHistory2_BrOvPanAnyAge_Div").removeClass(" showFormContent");
            }
        };

        //Colorectal or uterine cancer at 64 or younger
        const pfHistory2_ColorUterineAnyAgeClick = () => {

            var pfHistory2_ColorUterineAnyAge = document.getElementById("pfHistory2_ColorUterineAnyAge");

            if (pfHistory2_ColorUterineAnyAge.checked == true) {
                $("#pfHistory2_ColorUterineAnyAge_Div").removeClass(" hideFormContent");
                $("#pfHistory2_ColorUterineAnyAge_Div").addClass(" showFormContent");
            }
        };

        const pfHistory2_ColorUterineAnyAge2Click = () => {

            var pfHistory2_ColorUterineAnyAge2 = document.getElementById("pfHistory2_ColorUterineAnyAge2");

            if (pfHistory2_ColorUterineAnyAge2.checked == true) {
                $("#pfHistory2_ColorUterineAnyAge_Div").addClass(" hideFormContent");
                $("#pfHistory2_ColorUterineAnyAge_Div").removeClass(" showFormContent");
            }
        };

        //Breast, ovarian or pancreative cancer        
        const pfHistory2_FBrOvPanAnyAgeClick = () => {

            var pfHistory2_FBrOvPanAnyAge = document.getElementById("pfHistory2_FBrOvPanAnyAge");

            if (pfHistory2_FBrOvPanAnyAge.checked == true) {
                $("#pfHistory2_FBrOvPanAnyAge_Div").removeClass(" hideFormContent");
                $("#pfHistory2_FBrOvPanAnyAge_Div").addClass(" showFormContent");
            }
        };

        const pfHistory2_FBrOvPanAnyAge2Click = () => {

            var pfHistory2_FBrOvPanAnyAge2 = document.getElementById("pfHistory2_FBrOvPanAnyAge2");

            if (pfHistory2_FBrOvPanAnyAge2.checked == true) {
                $("#pfHistory2_FBrOvPanAnyAge_Div").addClass(" hideFormContent");
                $("#pfHistory2_FBrOvPanAnyAge_Div").removeClass(" showFormContent");
            }
        };

        //Two breast cancers (bilateral) in one relative        
        const pfHistory2_FTwoBreasCanAgeClick = () => {

            var pfHistory2_FTwoBreasCanAge = document.getElementById("pfHistory2_FTwoBreasCanAge");

            if (pfHistory2_FTwoBreasCanAge.checked == true) {
                $("#pfHistory2_FTwoBreasCanAge_Div").removeClass(" hideFormContent");
                $("#pfHistory2_FTwoBreasCanAge_Div").addClass(" showFormContent");
            }
        };

        const pfHistory2_FTwoBreasCanAge2Click = () => {

            var pfHistory2_FTwoBreasCanAge2 = document.getElementById("pfHistory2_FTwoBreasCanAge2");

            if (pfHistory2_FTwoBreasCanAge2.checked == true) {
                $("#pfHistory2_FTwoBreasCanAge_Div").addClass(" hideFormContent");
                $("#pfHistory2_FTwoBreasCanAge_Div").removeClass(" showFormContent");
            }
        };

        //Three breast cancers in relatives on the same 
        const pfHistory2_FThrBreasCanAgeClick = () => {

            var pfHistory2_FThrBreasCanAge = document.getElementById("pfHistory2_FThrBreasCanAge");

            if (pfHistory2_FThrBreasCanAge.checked == true) {
                $("#pfHistory2_FThrBreasCanAge_Div").removeClass(" hideFormContent");
                $("#pfHistory2_FThrBreasCanAge_Div").addClass(" showFormContent");
            }
        };

        const pfHistory2_FThrBreasCanAge2Click = () => {

            var pfHistory2_FThrBreasCanAge2 = document.getElementById("pfHistory2_FThrBreasCanAge2");

            if (pfHistory2_FThrBreasCanAge2.checked == true) {
                $("#pfHistory2_FThrBreasCanAge_Div").addClass(" hideFormContent");
                $("#pfHistory2_FThrBreasCanAge_Div").removeClass(" showFormContent");
            }
        };

        //Ovarian cancer at any age
        const pfHistory2_OvarianCanAgeClick = () => {

            var pfHistory2_OvarianCanAge = document.getElementById("pfHistory2_OvarianCanAge");

            if (pfHistory2_OvarianCanAge.checked == true) {
                $("#pfHistory2_OvarianCanAge_Div").removeClass(" hideFormContent");
                $("#pfHistory2_OvarianCanAge_Div").addClass(" showFormContent");
            }
        };

        const pfHistory2_OvarianCanAge2Click = () => {

            var pfHistory2_OvarianCanAge2 = document.getElementById("pfHistory2_OvarianCanAge2");

            if (pfHistory2_OvarianCanAge2.checked == true) {
                $("#pfHistory2_OvarianCanAge_Div").addClass(" hideFormContent");
                $("#pfHistory2_OvarianCanAge_Div").removeClass(" showFormContent");
            }
        };

        //Pancreatic cancer at any age
        const pfHistory2_PancreaticCanAgeClick = () => {

            var pfHistory2_PancreaticCanAge = document.getElementById("pfHistory2_PancreaticCanAge");

            if (pfHistory2_PancreaticCanAge.checked == true) {
                $("#pfHistory2_PancreaticCanAge_Div").removeClass(" hideFormContent");
                $("#pfHistory2_PancreaticCanAge_Div").addClass(" showFormContent");
            }
        };

        const pfHistory2_PancreaticCanAge2Click = () => {

            var pfHistory2_PancreaticCanAge2 = document.getElementById("pfHistory2_PancreaticCanAge2");

            if (pfHistory2_PancreaticCanAge2.checked == true) {
                $("#pfHistory2_PancreaticCanAge_Div").addClass(" hideFormContent");
                $("#pfHistory2_PancreaticCanAge_Div").removeClass(" showFormContent");
            }
        };

        //Male breast cancer at any age
        const pfHistory2_MaleBreastCanAgeClick = () => {

            var pfHistory2_MaleBreastCanAge = document.getElementById("pfHistory2_MaleBreastCanAge");

            if (pfHistory2_MaleBreastCanAge.checked == true) {
                $("#pfHistory2_MaleBreastCanAge_Div").removeClass(" hideFormContent");
                $("#pfHistory2_MaleBreastCanAge_Div").addClass(" showFormContent");
            }
        };

        const pfHistory2_MaleBreastCanAge2Click = () => {

            var pfHistory2_MaleBreastCanAge2 = document.getElementById("pfHistory2_MaleBreastCanAge2");

            if (pfHistory2_MaleBreastCanAge2.checked == true) {
                $("#pfHistory2_MaleBreastCanAge_Div").addClass(" hideFormContent");
                $("#pfHistory2_MaleBreastCanAge_Div").removeClass(" showFormContent");
            }
        };

        //Metastatic prostate cancer at any age
        const pfHistory2_MetaProstateCanAgeClick = () => {

            var pfHistory2_MetaProstateCanAge = document.getElementById("pfHistory2_MetaProstateCanAge");

            if (pfHistory2_MetaProstateCanAge.checked == true) {
                $("#pfHistory2_MetaProstateCanAge_Div").removeClass(" hideFormContent");
                $("#pfHistory2_MetaProstateCanAge_Div").addClass(" showFormContent");
            }
        };

        const pfHistory2_MetaProstateCanAge2Click = () => {

            var pfHistory2_MetaProstateCanAge2 = document.getElementById("pfHistory2_MetaProstateCanAge2");

            if (pfHistory2_MetaProstateCanAge2.checked == true) {
                $("#pfHistory2_MetaProstateCanAge_Div").addClass(" hideFormContent");
                $("#pfHistory2_MetaProstateCanAge_Div").removeClass(" showFormContent");
            }
        };

        //Colon cancer at 49 or younger
        const pfHistory2_ColorCanAgeClick = () => {

            var pfHistory2_ColorCanAge = document.getElementById("pfHistory2_ColorCanAge");

            if (pfHistory2_ColorCanAge.checked == true) {
                $("#pfHistory2_ColorCanAge_Div").removeClass(" hideFormContent");
                $("#pfHistory2_ColorCanAge_Div").addClass(" showFormContent");
            }
        };

        const pfHistory2_ColorCanAge2Click = () => {

            var pfHistory2_ColorCanAge2 = document.getElementById("pfHistory2_ColorCanAge2");

            if (pfHistory2_ColorCanAge2.checked == true) {
                $("#pfHistory2_ColorCanAge_Div").addClass(" hideFormContent");
                $("#pfHistory2_ColorCanAge_Div").removeClass(" showFormContent");
            }
        };

        //Uterine cancer at 49 or younger
        const pfHistory2_UterineCanAgeClick = () => {

            var pfHistory2_UterineCanAge = document.getElementById("pfHistory2_UterineCanAge");

            if (pfHistory2_UterineCanAge.checked == true) {
                $("#pfHistory2_UterineCanAge_Div").removeClass(" hideFormContent");
                $("#pfHistory2_UterineCanAge_Div").addClass(" showFormContent");
            }
        };

        const pfHistory2_UterineCanAge2Click = () => {

            var pfHistory2_UterineCanAge2 = document.getElementById("pfHistory2_UterineCanAge2");

            if (pfHistory2_UterineCanAge2.checked == true) {
                $("#pfHistory2_UterineCanAge_Div").addClass(" hideFormContent");
                $("#pfHistory2_UterineCanAge_Div").removeClass(" showFormContent");
            }
        };

        //Ashkenazi Jewish ancestry with breast cancer
        const pfHistory2_AshJewishAncesCanAgeClick = () => {

            var pfHistory2_AshJewishAncesCanAge = document.getElementById("pfHistory2_AshJewishAncesCanAge");

            if (pfHistory2_AshJewishAncesCanAge.checked == true) {
                $("#pfHistory2_AshJewishAncesCanAge_Div").removeClass(" hideFormContent");
                $("#pfHistory2_AshJewishAncesCanAge_Div").addClass(" showFormContent");
            }
        };

        const pfHistory2_AshJewishAncesCanAge2Click = () => {

            var pfHistory2_AshJewishAncesCanAge2 = document.getElementById("pfHistory2_AshJewishAncesCanAge2");

            if (pfHistory2_AshJewishAncesCanAge2.checked == true) {
                $("#pfHistory2_AshJewishAncesCanAge_Div").addClass(" hideFormContent");
                $("#pfHistory2_AshJewishAncesCanAge_Div").removeClass(" showFormContent");
            }
        };

        //Does the patient have a family history of other cancers?
        const pfHistory2_otherCanClick = () => {

            var pfHistory2_otherCan = document.getElementById("pfHistory2_otherCan");

            if (pfHistory2_otherCan.checked == true) {
                $("#pfHistory2_otherCan_Div").removeClass(" hideFormContent");
                $("#pfHistory2_otherCan_Div").addClass(" showFormContent");
            }
        };

        const pfHistory2_otherCan2Click = () => {

            var pfHistory2_otherCan2 = document.getElementById("pfHistory2_otherCan2");

            if (pfHistory2_otherCan2.checked == true) {
                $("#pfHistory2_otherCan_Div").addClass(" hideFormContent");
                $("#pfHistory2_otherCan_Div").removeClass(" showFormContent");
            }
        };

        //Has anyone in the patient's family had genetic testing for hereditary cancer?
        const pfHistory2_geHerediraryCanClick = () => {

            var pfHistory2_geHerediraryCan = document.getElementById("pfHistory2_geHerediraryCan");

            if (pfHistory2_geHerediraryCan.checked == true) {
                $("#pfHistory2_geHerediraryCan_Div").removeClass(" hideFormContent");
                $("#pfHistory2_geHerediraryCan_Div").addClass(" showFormContent");
            }
        };

        const pfHistory2_geHerediraryCan2Click = () => {

            var pfHistory2_geHerediraryCan2 = document.getElementById("pfHistory2_geHerediraryCan2");

            if (pfHistory2_geHerediraryCan2.checked == true) {
                $("#pfHistory2_geHerediraryCan_Div").addClass(" hideFormContent");
                $("#pfHistory2_geHerediraryCan_Div").removeClass(" showFormContent");
            }
        };

        //btnpfHistoryAddFamilyHistory 
        const btnpfHistoryAddFamilyHistory2 = () => {

            const pfHistory_AnyAge_dataid = $("#pfHistory2_AnyAge_dataid").val();

            const _showResult2 = pfHistory_AnyAge_dataid + '_showResult2';
            const _pfHistory_AnyAgeValues = pfHistory_AnyAge_dataid + '_pfHistory2_AnyAgeValues';
            const _pfHistory_AnyAgeValuesJustShow = pfHistory_AnyAge_dataid + '_pfHistory2_AnyAgeValuesJustShow';

            const pfHistory_AnyAge_relative = $("#pfHistory2_AnyAge_relative").val();
            const pfHistory_AnyAge_age = $("#pfHistory2_AnyAge_age").val();

            const strr = pfHistory_AnyAge_age.replace(/\s+/g, " ").trim();
            $("#pfHistory2_AnyAge_age").val(strr);

            //take existing value
            const _pfHistory_AnyAgeValuesExisting = $("#" + _pfHistory_AnyAgeValues).val();
            const _pfHistory_AnyAgeValuesJustShowExisting = $("#" + _pfHistory_AnyAgeValuesJustShow).val();

            let dataSide = '';
            let dataSideJustShow = '';

            let dataValueanswers = [];
            let dataValueanswer = {
                "side": "",
                "relative": "",
                "age": ""
            };

            let sideValue = '';


            if ($("#pfHistory2_AnyAge_Mothside").is(":not(:checked)") && $("#pfHistory2_AnyAge_Fathside").is(":not(:checked)")) {

                alert("please select family side");
            } else if (pfHistory_AnyAge_relative.length < 1) {
                alert("please select family relative");

            } else if (strr.length < 1) {
                alert("please enter age");
                $('#pfHistory2_AnyAge_age').focus();
            } else {

                if ($("#pfHistory2_AnyAge_Mothside").is(":not(:checked)")) {
                    sideValue = 'Father';
                } else {
                    sideValue = 'Mother';
                }

                if (_pfHistory_AnyAgeValuesExisting.length < 1) {
                    dataSide = sideValue + "," + pfHistory_AnyAge_relative + "," + strr;
                    dataSideJustShow = "side: " + sideValue + " relative: " + pfHistory_AnyAge_relative + " age: " + strr;
                } else {
                    dataSide = _pfHistory_AnyAgeValuesExisting + "," + sideValue + "," + pfHistory_AnyAge_relative + "," + strr;
                    dataSideJustShow = _pfHistory_AnyAgeValuesJustShowExisting + ", side: " + sideValue + " relative: " + pfHistory_AnyAge_relative + " age: " + strr;
                }

                dataValueanswer = {
                    side: sideValue,
                    relative: pfHistory_AnyAge_relative,
                    age: strr
                };

                dataValueanswers.push(dataValueanswer);

                //alert(dataValueanswer);
                //set results
                $("#" + _showResult2).html(dataSideJustShow);
                $("#" + _pfHistory_AnyAgeValuesJustShow).val(dataSideJustShow);
                $("#" + _pfHistory_AnyAgeValues).val(dataSide);

                //reset fields
                document.getElementById("pfHistory2_AnyAge_Mothside").checked = false;
                document.getElementById("pfHistory2_AnyAge_Fathside").checked = false;
                $("#pfHistory2_AnyAge_relative").val("");
                $('#pfHistory2_AnyAge_age').val("");

                $("#defaultModal2 .mclose").click();
            }

        };

        const btnpfHistoryAddFamilyHistory2Click = (dataid) => {
            //alert(dataid);
            $("#pfHistory2_AnyAge_dataid").val(dataid);
        };

        return (
            <>
                <div id={`ova1GenetixHBOCFamilyHistoryFormContainer`} class="panel-body">
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="form-group custom-margin-bottom">
                                <h4>Personal History</h4>
                                <label class="form-label" for="address">Personal History (check all that apply)</label>
                                <div class="demo-checkbox" id="hboc1_q01">
                                    <input type="checkbox" id="pfHistory2_noCancer" name="pfHistory2_noCancer" class="filled-in" onClick={() => {
                                        noPreviousPersonalCancerHistoryClick2()
                                    }} />
                                    <label for="pfHistory2_noCancer">No personal history of cancer &nbsp; </label>
                                    {/* hboc1_q02 */}
                                    {/* <input type="checkbox" id="pfHistory2_yesCancer" name="pfHistory2_yesCancer" class="filled-in" />
                                    <label for="pfHistory2_yesCancer">Family history of breast, ovarian or endometrial cancer &nbsp; </label> */}
                                </div>
                            </div>
                        </div>
                        
                        <div class="col-md-12">
                            <div class="form-group " id="personalHistoryArea2">

                                <div class="row clearfix">
                                    <div class="col-md-12">
                                        <div class="form-group ">
                                            <label class="form-label" for="address">Does the Patient have a history of:</label>
                                            <div class="demo-radio-button">
                                                <div class="row clearfix custom-min-height2">
                                                    <div class="col-md-5">
                                                        <label>Breast, ovarian or pancreative cancer at any age?</label>
                                                        <input type="radio" id="pfHistory2_BrOvPanAnyAge" name="pfHistory2_BrOvPanAnyAge_" value="yes" class="form-control" onClick={() => {
                                                            pfHistory2_BrOvPanAnyAgeClick()
                                                        }} />
                                                        <label class="form-label" for="pfHistory2_BrOvPanAnyAge">Yes</label>
                                                        <input type="radio" id="pfHistory2_BrOvPanAnyAge2" name="pfHistory2_BrOvPanAnyAge_" value="no" class="form-control" onClick={() => {
                                                            pfHistory2_BrOvPanAnyAge2Click()
                                                        }} />
                                                        <label class="form-label" for="pfHistory2_BrOvPanAnyAge2">No</label>
                                                    </div>
                                                    <div class="col-md-7">
                                                        <div className=" hideFormContent" id="pfHistory2_BrOvPanAnyAge_Div">
                                                            <div class="row clearfix">
                                                                <div class="col-md-2 text-right">
                                                                    <label>Which cancer?</label>
                                                                </div>
                                                                <div class="col-md-5">
                                                                    <div class="form-line">
                                                                        <input type="text" id="pfHistory2_BrOvPanAnyAgeCan" class="form-control " placeholder="Which cancer?" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-3 text-right">
                                                                    <label>Age at diagnosis</label>
                                                                </div>
                                                                <div class="col-md-2">
                                                                    <div class="form-line">
                                                                        <input type="text" id="pfHistory2_BrOvPanAnyAgeInfo" class="form-control " placeholder="Age" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="demo-radio-button">
                                                <div class="row clearfix custom-min-height2">
                                                    <div class="col-md-5">
                                                        <label>Colorectal or uterine cancer at 64 or younger?</label>
                                                        <input type="radio" id="pfHistory2_ColorUterineAnyAge" name="pfHistory2_ColorUterineAnyAge_" value="yes" class="form-control" onClick={() => {
                                                            pfHistory2_ColorUterineAnyAgeClick()
                                                        }} />
                                                        <label class="form-label" for="pfHistory2_ColorUterineAnyAge">Yes</label>
                                                        <input type="radio" id="pfHistory2_ColorUterineAnyAge2" name="pfHistory2_ColorUterineAnyAge_" value="no" class="form-control" onClick={() => {
                                                            pfHistory2_ColorUterineAnyAge2Click()
                                                        }} />
                                                        <label class="form-label" for="pfHistory2_ColorUterineAnyAge2">No</label>
                                                    </div>
                                                    <div class="col-md-7">
                                                        <div className=" hideFormContent" id="pfHistory2_ColorUterineAnyAge_Div">
                                                            <div class="row clearfix">
                                                                <div class="col-md-2 text-right">
                                                                    <label>Which cancer?</label>
                                                                </div>
                                                                <div class="col-md-5">
                                                                    <div class="form-line">
                                                                        <input type="text" id="pfHistory2_ColorUterineAnyAgeCan" class="form-control " placeholder="Which cancer?" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-3 text-right">
                                                                    <label>Age at diagnosis</label>
                                                                </div>
                                                                <div class="col-md-2">
                                                                    <div class="form-line">
                                                                        <input type="text" id="pfHistory2_ColorUterineAnyAgeInfo" class="form-control " placeholder="Age" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="demo-checkbox">
                                    <div class="row clearfix custom-min-height">
                                        <div class="col-md-4">
                                            <input type="checkbox" id="pfHistory2_previousCancer" name="pfHistory2_previousCancer" class="filled-in" onClick={() => {
                                                previousCancerHistoryClick2()
                                            }} />
                                            <label for="pfHistory2_previousCancer">Previous cancer diagnosis? If yes, Define:</label>
                                        </div>
                                        <div class="col-md-8">
                                            <div class="form-line hideFormContent" id="pfHistory2_prevCanDefine_div">
                                                <input type="text" id="pfHistory2_prevCanDefine" class="form-control " placeholder="Define" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="demo-checkbox">
                                    <div class="row clearfix custom-min-height">
                                        <div class="col-md-5">
                                            <input type="checkbox" id="pfHistory2_SomaticMutationProf" name="pfHistory2_SomaticMutationProf" class="filled-in" onClick={() => {
                                                SomaticMutationProfClick2()
                                            }} />
                                            <label for="pfHistory2_SomaticMutationProf">Somatic mutation profiling? If yes, Define: (gene, variant identified) &nbsp; </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-line hideFormContent" id="pfHistory2_SoMutaProfDefine_div">
                                                <input type="text" id="pfHistory2_SoMutaProfDefine" class="form-control " placeholder="Define" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="demo-checkbox">
                                    <div class="row clearfix custom-min-height">
                                        <div class="col-md-5">
                                            <input type="checkbox" id="pfHistory2_PrevGermltesting" name="pfHistory2_PrevGermltesting" class="filled-in" onClick={() => {
                                                PrevGermltestingClick2()
                                            }} />
                                            <label for="pfHistory2_PrevGermltesting">Previous germline testing? If yes, Define:(gene/varient detected) &nbsp; </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-line hideFormContent" id="pfHistory2_PrevGermltestDefine_div">
                                                <input type="text" id="pfHistory2_PrevGermltestDefine" class="form-control " placeholder="Define" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="demo-checkbox">
                                    <label for="">If yes, is the variant:&nbsp; </label>
                                    <input type="checkbox" id="pfHistory2_Familial_Inherited" name="pfHistory2_Familial_Inherited" class="filled-in" />
                                    <label for="pfHistory2_Familial_Inherited">Familial/Inherited or &nbsp; </label>
                                    <input type="checkbox" id="pfHistory2_Newly_identified" name="pfHistory2_Newly_identified" class="filled-in" />
                                    <label for="pfHistory2_Newly_identified">Newly-identified/De Novo &nbsp; </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="form-group custom-margin-bottom">
                                <h4>Family History</h4>
                                <div class="demo-checkbox" id="hboc1_q02">                                    
                                    {/* hboc1_q02 */}
                                    <input type="checkbox" id="pfHistory2_yesCancer" name="pfHistory2_yesCancer" class="filled-in"/>
                                    <label for="pfHistory2_yesCancer">Family history of breast, ovarian or endometrial cancer &nbsp; </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <p>(Close relatives include: parent, sibling, child, uncle, aunt, great uncle, great aunt, nephew, niece, grandparent, grandchild or half-sibling)</p>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="form-group ">
                                <label class="form-label" for="address">Does the Patient have a family history of:</label>
                                <div class="demo-radio-button">
                                    <div class="row clearfix custom-min-height">
                                        <div class="col-md-4">
                                            <label>Breast, ovarian or pancreative cancer at any age?</label>
                                        </div>
                                        <div class="col-md-2">
                                            <input type="radio" id="pfHistory2_FBrOvPanAnyAge" name="pfHistory2_FBrOvPanAnyAge_" value="yes" class="form-control" onClick={() => {
                                                pfHistory2_FBrOvPanAnyAgeClick()
                                            }} />
                                            <label class="form-label" for="pfHistory2_FBrOvPanAnyAge">Yes</label>
                                            <input type="radio" id="pfHistory2_FBrOvPanAnyAge2" name="pfHistory2_FBrOvPanAnyAge_" value="no" class="form-control" onClick={() => {
                                                pfHistory2_FBrOvPanAnyAge2Click()
                                            }} />
                                            <label class="form-label" for="pfHistory2_FBrOvPanAnyAge2">No</label>
                                        </div>
                                        <div class="col-md-6">
                                            <div className=" hideFormContent" id="pfHistory2_FBrOvPanAnyAge_Div">
                                                <div class="row clearfix">
                                                    <div class="col-md-3">
                                                        <button type="button" class="btn btn-default waves-effect m-r-20" data-toggle="modal" data-val="subQuestion-hboc1_q08_1" data-target="#defaultModal2" onClick={() => {
                                                            btnpfHistoryAddFamilyHistory2Click("hboc1_q08_1")
                                                        }}>add Info</button>

                                                    </div>
                                                    <div class="col-md-9">
                                                        <div id="hboc1_q08_1_showResult2"></div>
                                                        <input type="hidden" id="hboc1_q08_1_pfHistory2_AnyAgeValuesJustShow" class="form-control " />
                                                        <input type="hidden" id="hboc1_q08_1_pfHistory2_AnyAgeValues" class="form-control " />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="demo-radio-button">
                                    <div class="row clearfix custom-min-height">
                                        <div class="col-md-4">
                                            <label>Two breast cancers (bilateral) in one relative at any age</label>
                                        </div>
                                        <div class="col-md-2">
                                            <input type="radio" id="pfHistory2_FTwoBreasCanAge" name="pfHistory2_FTwoBreasCanAge_" value="yes" class="form-control" onClick={() => {
                                                pfHistory2_FTwoBreasCanAgeClick()
                                            }} />
                                            <label class="form-label" for="pfHistory2_FTwoBreasCanAge">Yes</label>
                                            <input type="radio" id="pfHistory2_FTwoBreasCanAge2" name="pfHistory2_FTwoBreasCanAge_" value="no" class="form-control" onClick={() => {
                                                pfHistory2_FTwoBreasCanAge2Click()
                                            }} />
                                            <label class="form-label" for="pfHistory2_FTwoBreasCanAge2">No</label>
                                        </div>
                                        <div class="col-md-6">
                                            <div className=" hideFormContent" id="pfHistory2_FTwoBreasCanAge_Div">
                                                <div class="row clearfix">
                                                    <div class="col-md-3">
                                                        <button type="button" class="btn btn-default waves-effect m-r-20" data-toggle="modal" data-target="#defaultModal2" onClick={() => {
                                                            btnpfHistoryAddFamilyHistory2Click("hboc1_q09_1")
                                                        }}>add Info</button>
                                                    </div>
                                                    <div class="col-md-9">
                                                        <div id="hboc1_q09_1_showResult2"></div>
                                                        <input type="hidden" id="hboc1_q09_1_pfHistory2_AnyAgeValuesJustShow" class="form-control " />
                                                        <input type="hidden" id="hboc1_q09_1_pfHistory2_AnyAgeValues" class="form-control " />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div class="demo-radio-button">
                                    <div class="row clearfix custom-min-height">
                                        <div class="col-md-4">
                                            <label>Three breast cancers in relatives on the same  side of the family at any age</label>
                                        </div>
                                        <div class="col-md-2">
                                            <input type="radio" id="pfHistory2_FThrBreasCanAge" name="pfHistory2_FThrBreasCanAge_" value="yes" class="form-control" onClick={() => {
                                                pfHistory2_FThrBreasCanAgeClick()
                                            }} />
                                            <label class="form-label" for="pfHistory2_FThrBreasCanAge">Yes</label>
                                            <input type="radio" id="pfHistory2_FThrBreasCanAge2" name="pfHistory2_FThrBreasCanAge_" value="no" class="form-control" onClick={() => {
                                                pfHistory2_FThrBreasCanAge2Click()
                                            }} />
                                            <label class="form-label" for="pfHistory2_FThrBreasCanAge2">No</label>
                                        </div>
                                        <div class="col-md-6">
                                            <div className=" hideFormContent" id="pfHistory2_FThrBreasCanAge_Div">
                                                <div class="row clearfix">
                                                    <div class="col-md-3">
                                                        <button type="button" class="btn btn-default waves-effect m-r-20" data-toggle="modal" data-val="subQuestion-hboc1_q010_1" data-target="#defaultModal2" onClick={() => {
                                                            btnpfHistoryAddFamilyHistory2Click("hboc1_q010_1")
                                                        }}>add Info</button>

                                                    </div>
                                                    <div class="col-md-9">
                                                        <div id="hboc1_q010_1_showResult2"></div>
                                                        <input type="hidden" id="hboc1_q010_1_pfHistory2_AnyAgeValuesJustShow" class="form-control " />
                                                        <input type="hidden" id="hboc1_q010_1_pfHistory2_AnyAgeValues" class="form-control " />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="demo-radio-button">
                                    <div class="row clearfix custom-min-height">
                                        <div class="col-md-4">
                                            <label>Ovarian cancer at any age</label>
                                        </div>
                                        <div class="col-md-2">
                                            <input type="radio" id="pfHistory2_OvarianCanAge" name="pfHistory2_OvarianCanAge_" value="yes" class="form-control" onClick={() => {
                                                pfHistory2_OvarianCanAgeClick()
                                            }} />
                                            <label class="form-label" for="pfHistory2_OvarianCanAge">Yes</label>
                                            <input type="radio" id="pfHistory2_OvarianCanAge2" name="pfHistory2_OvarianCanAge_" value="no" class="form-control" onClick={() => {
                                                pfHistory2_OvarianCanAge2Click()
                                            }} />
                                            <label class="form-label" for="pfHistory2_OvarianCanAge2">No</label>
                                        </div>
                                        <div class="col-md-6">
                                            <div className=" hideFormContent" id="pfHistory2_OvarianCanAge_Div">
                                                <div class="row clearfix">
                                                    <div class="col-md-3">
                                                        <button type="button" class="btn btn-default waves-effect m-r-20" data-toggle="modal" data-val="subQuestion-hboc1_q010_1" data-target="#defaultModal2" onClick={() => {
                                                            btnpfHistoryAddFamilyHistory2Click("hboc1_q011_1")
                                                        }}>add Info</button>

                                                    </div>
                                                    <div class="col-md-9">
                                                        <div id="hboc1_q011_1_showResult2"></div>
                                                        <input type="hidden" id="hboc1_q011_1_pfHistory2_AnyAgeValuesJustShow" class="form-control " />
                                                        <input type="hidden" id="hboc1_q011_1_pfHistory2_AnyAgeValues" class="form-control " />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="demo-radio-button">
                                    <div class="row clearfix custom-min-height">
                                        <div class="col-md-4">
                                            <label>Pancreatic cancer at any age</label>
                                        </div>
                                        <div class="col-md-2">
                                            <input type="radio" id="pfHistory2_PancreaticCanAge" name="pfHistory2_PancreaticCanAge_" value="yes" class="form-control" onClick={() => {
                                                pfHistory2_PancreaticCanAgeClick()
                                            }} />
                                            <label class="form-label" for="pfHistory2_PancreaticCanAge">Yes</label>
                                            <input type="radio" id="pfHistory2_PancreaticCanAge2" name="pfHistory2_PancreaticCanAge_" value="no" class="form-control" onClick={() => {
                                                pfHistory2_PancreaticCanAge2Click()
                                            }} />
                                            <label class="form-label" for="pfHistory2_PancreaticCanAge2">No</label>
                                        </div>
                                        <div class="col-md-6">
                                            <div className=" hideFormContent" id="pfHistory2_PancreaticCanAge_Div">
                                                <div class="row clearfix">
                                                    <div class="col-md-3">
                                                        <button type="button" class="btn btn-default waves-effect m-r-20" data-toggle="modal" data-val="subQuestion-hboc1_q010_1" data-target="#defaultModal2" onClick={() => {
                                                            btnpfHistoryAddFamilyHistory2Click("hboc1_q012_1")
                                                        }}>add Info</button>

                                                    </div>
                                                    <div class="col-md-9">
                                                        <div id="hboc1_q012_1_showResult2"></div>
                                                        <input type="hidden" id="hboc1_q012_1_pfHistory2_AnyAgeValuesJustShow" class="form-control " />
                                                        <input type="hidden" id="hboc1_q012_1_pfHistory2_AnyAgeValues" class="form-control " />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="demo-radio-button">
                                    <div class="row clearfix custom-min-height">
                                        <div class="col-md-4">
                                            <label>Male breast cancer at any age</label>
                                        </div>
                                        <div class="col-md-2">
                                            <input type="radio" id="pfHistory2_MaleBreastCanAge" name="pfHistory2_MaleBreastCanAge_" value="yes" class="form-control" onClick={() => {
                                                pfHistory2_MaleBreastCanAgeClick()
                                            }} />
                                            <label class="form-label" for="pfHistory2_MaleBreastCanAge">Yes</label>
                                            <input type="radio" id="pfHistory2_MaleBreastCanAge2" name="pfHistory2_MaleBreastCanAge_" value="no" class="form-control" onClick={() => {
                                                pfHistory2_MaleBreastCanAge2Click()
                                            }} />
                                            <label class="form-label" for="pfHistory2_MaleBreastCanAge2">No</label>
                                        </div>
                                        <div class="col-md-6">
                                            <div className=" hideFormContent" id="pfHistory2_MaleBreastCanAge_Div">
                                                <div class="row clearfix">
                                                    <div class="col-md-3">
                                                        <button type="button" class="btn btn-default waves-effect m-r-20" data-toggle="modal" data-val="subQuestion-hboc1_q010_1" data-target="#defaultModal2" onClick={() => {
                                                            btnpfHistoryAddFamilyHistory2Click("hboc1_q013_1")
                                                        }}>add Info</button>

                                                    </div>
                                                    <div class="col-md-9">
                                                        <div id="hboc1_q013_1_showResult2"></div>
                                                        <input type="hidden" id="hboc1_q013_1_pfHistory2_AnyAgeValuesJustShow" class="form-control " />
                                                        <input type="hidden" id="hboc1_q013_1_pfHistory2_AnyAgeValues" class="form-control " />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="demo-radio-button">
                                    <div class="row clearfix custom-min-height">
                                        <div class="col-md-4">
                                            <label>Metastatic prostate cancer at any age</label>
                                        </div>
                                        <div class="col-md-2">
                                            <input type="radio" id="pfHistory2_MetaProstateCanAge" name="pfHistory2_MetaProstateCanAge_" value="yes" class="form-control" onClick={() => {
                                                pfHistory2_MetaProstateCanAgeClick()
                                            }} />
                                            <label class="form-label" for="pfHistory2_MetaProstateCanAge">Yes</label>
                                            <input type="radio" id="pfHistory2_MetaProstateCanAge2" name="pfHistory2_MetaProstateCanAge_" value="no" class="form-control" onClick={() => {
                                                pfHistory2_MetaProstateCanAge2Click()
                                            }} />
                                            <label class="form-label" for="pfHistory2_MetaProstateCanAge2">No</label>
                                        </div>
                                        <div class="col-md-6">
                                            <div className=" hideFormContent" id="pfHistory2_MetaProstateCanAge_Div">
                                                <div class="row clearfix">
                                                    <div class="col-md-3">
                                                        <button type="button" class="btn btn-default waves-effect m-r-20" data-toggle="modal" data-val="subQuestion-hboc1_q010_1" data-target="#defaultModal2" onClick={() => {
                                                            btnpfHistoryAddFamilyHistory2Click("hboc1_q014_1")
                                                        }}>add Info</button>

                                                    </div>
                                                    <div class="col-md-9">
                                                        <div id="hboc1_q014_1_showResult2"></div>
                                                        <input type="hidden" id="hboc1_q014_1_pfHistory2_AnyAgeValuesJustShow" class="form-control " />
                                                        <input type="hidden" id="hboc1_q014_1_pfHistory2_AnyAgeValues" class="form-control " />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="demo-radio-button">
                                    <div class="row clearfix custom-min-height">
                                        <div class="col-md-4">
                                            <label>Colon cancer at 49 or younger</label>
                                        </div>
                                        <div class="col-md-2">
                                            <input type="radio" id="pfHistory2_ColorCanAge" name="pfHistory2_ColorCanAge_" value="yes" class="form-control" onClick={() => {
                                                pfHistory2_ColorCanAgeClick()
                                            }} />
                                            <label class="form-label" for="pfHistory2_ColorCanAge">Yes</label>
                                            <input type="radio" id="pfHistory2_ColorCanAge2" name="pfHistory2_ColorCanAge_" value="no" class="form-control" onClick={() => {
                                                pfHistory2_ColorCanAge2Click()
                                            }} />
                                            <label class="form-label" for="pfHistory2_ColorCanAge2">No</label>
                                        </div>
                                        <div class="col-md-6">
                                            <div className=" hideFormContent" id="pfHistory2_ColorCanAge_Div">
                                                <div class="row clearfix">
                                                    <div class="col-md-3">
                                                        <button type="button" class="btn btn-default waves-effect m-r-20" data-toggle="modal" data-val="subQuestion-hboc1_q010_1" data-target="#defaultModal2" onClick={() => {
                                                            btnpfHistoryAddFamilyHistory2Click("hboc1_q015_1")
                                                        }}>add Info</button>

                                                    </div>
                                                    <div class="col-md-9">
                                                        <div id="hboc1_q015_1_showResult2"></div>
                                                        <input type="hidden" id="hboc1_q015_1_pfHistory2_AnyAgeValuesJustShow" class="form-control " />
                                                        <input type="hidden" id="hboc1_q015_1_pfHistory2_AnyAgeValues" class="form-control " />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="demo-radio-button">
                                    <div class="row clearfix custom-min-height">
                                        <div class="col-md-4">
                                            <label>Uterine cancer at 49 or younger</label>
                                        </div>
                                        <div class="col-md-2">
                                            <input type="radio" id="pfHistory2_UterineCanAge" name="pfHistory2_UterineCanAge_" value="yes" class="form-control" onClick={() => {
                                                pfHistory2_UterineCanAgeClick()
                                            }} />
                                            <label class="form-label" for="pfHistory2_UterineCanAge">Yes</label>
                                            <input type="radio" id="pfHistory2_UterineCanAge2" name="pfHistory2_UterineCanAge_" value="no" class="form-control" onClick={() => {
                                                pfHistory2_UterineCanAge2Click()
                                            }} />
                                            <label class="form-label" for="pfHistory2_UterineCanAge2">No</label>
                                        </div>
                                        <div class="col-md-6">
                                            <div className=" hideFormContent" id="pfHistory2_UterineCanAge_Div">
                                                <div class="row clearfix">
                                                    <div class="col-md-3">
                                                        <button type="button" class="btn btn-default waves-effect m-r-20" data-toggle="modal" data-val="subQuestion-hboc1_q010_1" data-target="#defaultModal2" onClick={() => {
                                                            btnpfHistoryAddFamilyHistory2Click("hboc1_q016_1")
                                                        }}>add Info</button>

                                                    </div>
                                                    <div class="col-md-9">
                                                        <div id="hboc1_q016_1_showResult2"></div>
                                                        <input type="hidden" id="hboc1_q016_1_pfHistory2_AnyAgeValuesJustShow" class="form-control " />
                                                        <input type="hidden" id="hboc1_q016_1_pfHistory2_AnyAgeValues" class="form-control " />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="demo-radio-button">
                                    <div class="row clearfix custom-min-height">
                                        <div class="col-md-4">
                                            <label>Ashkenazi Jewish ancestry with breast cancer at any age</label>
                                        </div>
                                        <div class="col-md-2">
                                            <input type="radio" id="pfHistory2_AshJewishAncesCanAge" name="pfHistory2_AshJewishAncesCanAge_" value="yes" class="form-control" onClick={() => {
                                                pfHistory2_AshJewishAncesCanAgeClick()
                                            }} />
                                            <label class="form-label" for="pfHistory2_AshJewishAncesCanAge">Yes</label>
                                            <input type="radio" id="pfHistory2_AshJewishAncesCanAge2" name="pfHistory2_AshJewishAncesCanAge_" value="no" class="form-control" onClick={() => {
                                                pfHistory2_AshJewishAncesCanAge2Click()
                                            }} />
                                            <label class="form-label" for="pfHistory2_AshJewishAncesCanAge2">No</label>
                                        </div>
                                        <div class="col-md-6">
                                            <div className=" hideFormContent" id="pfHistory2_AshJewishAncesCanAge_Div">
                                                <div class="row clearfix">
                                                    <div class="col-md-3">
                                                        <button type="button" class="btn btn-default waves-effect m-r-20" data-toggle="modal" data-val="subQuestion-hboc1_q010_1" data-target="#defaultModal2" onClick={() => {
                                                            btnpfHistoryAddFamilyHistory2Click("hboc1_q017_1")
                                                        }}>add Info</button>

                                                    </div>
                                                    <div class="col-md-9">
                                                        <div id="hboc1_q017_1_showResult2"></div>
                                                        <input type="hidden" id="hboc1_q017_1_pfHistory2_AnyAgeValuesJustShow" class="form-control " />
                                                        <input type="hidden" id="hboc1_q017_1_pfHistory2_AnyAgeValues" class="form-control " />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="demo-radio-button" id="hboc1_q018">
                                    <div class="row clearfix custom-min-height">
                                        <div class="col-md-4">
                                            <label>Does the Patient have a family history of other cancers?</label>
                                        </div>
                                        <div class="col-md-3">
                                            <input type="radio" id="pfHistory2_otherCan" name="pfHistory2_otherCan_" value="yes" class="form-control" onClick={() => {
                                                pfHistory2_otherCanClick()
                                            }} />
                                            <label class="form-label" for="pfHistory2_otherCan">Yes</label>
                                            <input type="radio" id="pfHistory2_otherCan2" name="pfHistory2_otherCan_" value="no" class="form-control" onClick={() => {
                                                pfHistory2_otherCan2Click()
                                            }} />
                                            <label class="form-label" for="pfHistory2_otherCan2">No If yes, please list:</label>
                                        </div>
                                        <div class="col-md-5">
                                            <div className=" hideFormContent" id="pfHistory2_otherCan_Div">
                                                <div class="row clearfix">
                                                    <div class="col-md-12">
                                                        <div class="form-line">
                                                            <input type="text" id="pfHistory2_otherCan_info" class="form-control " placeholder="please list" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="demo-radio-button" id="hboc1_q019">
                                    <div class="row clearfix custom-min-height">
                                        <div class="col-md-4">
                                            <label>Has anyone in the Patient's family had genetic testing for hereditary cancer?</label>
                                        </div>
                                        <div class="col-md-5">
                                            <input type="radio" id="pfHistory2_geHerediraryCan" name="pfHistory2_geHerediraryCan_" value="yes" class="form-control" onClick={() => {
                                                pfHistory2_geHerediraryCanClick()
                                            }} />
                                            <label class="form-label" for="pfHistory2_geHerediraryCan">Yes</label>
                                            <input type="radio" id="pfHistory2_geHerediraryCan2" name="pfHistory2_geHerediraryCan_" value="no" class="form-control" onClick={() => {
                                                pfHistory2_geHerediraryCan2Click()
                                            }} />
                                            <label class="form-label" for="pfHistory2_geHerediraryCan2">No If yes, please list: Who, what genes and results (if known):</label>
                                        </div>
                                        <div class="col-md-3">
                                            <div className=" hideFormContent" id="pfHistory2_geHerediraryCan_Div">
                                                <div class="row clearfix">
                                                    <div class="col-md-12">
                                                        <div class="form-line">
                                                            <input type="text" id="pfHistory2_geHerediraryCan_info" class="form-control " placeholder="please list" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="demo-radio-button" id="hboc1_q020">
                                    <div class="row clearfix">
                                        <div class="col-md-12">
                                            <label>Other Clinical History:</label>
                                            <div class="form-line">
                                                <input type="text" id="pfHistory2_OtherClinicalHistory" class="form-control " placeholder="Other Clinical History" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="form-group ">
                                <label class="form-label" for="address">Previous or current treatment</label>
                                <div class="demo-checkbox" id="hboc1_q021-hboc1_q024">
                                    <input type="checkbox" id="pfHistory2_PCurTreat_boneMar" name="pfHistory2_PCurTreat_boneMar" class="filled-in" />
                                    <label for="pfHistory2_PCurTreat_boneMar"> Allogeneic bone marrow or peripheral stem cell transplant <sup>*</sup> &nbsp; </label>
                                    <input type="checkbox" id="pfHistory2_PCurTreat_Chemo" name="pfHistory2_PCurTreat_Chemo" class="filled-in" />
                                    <label for="pfHistory2_PCurTreat_Chemo"> Chemotherapy<sup>*</sup> &nbsp; </label>
                                    <input type="checkbox" id="pfHistory2_PCurTreat_Radia" name="pfHistory2_PCurTreat_Radia" class="filled-in" />
                                    <label for="pfHistory2_PCurTreat_Radia"> Radiation &nbsp; </label>
                                    <input type="checkbox" id="pfHistory2_PCurTreat_Surgery" name="pfHistory2_PCurTreat_Surgery" class="filled-in" />
                                    <label for="pfHistory2_PCurTreat_Surgery"> Surgery, &nbsp; </label>
                                </div>
                                <div class="form-line">
                                    <input type="text" id="pfHistory2_PCurTreat_SurgDe1" class="form-control " placeholder="Specify" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <p><sup>*</sup>DNA analysis from blood samples of individuals who have undergone stem cell transplants, bone marrow transplants, or chemotheray may not reflect the germline genotype. Similarly, DNA analysis from blood samples of individuals with hematologic malignancy may not distinguish between somatic and germline variants</p>
                        </div>
                    </div>
                    {/* <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="form-group ">
                                <label class="form-label" for="address">Previous or current treatment</label>
                                <div class="form-line">
                                    <input type="text" id="pfHistory2_PCurTreat_SurgDe2" class="form-control " placeholder="Specify" />
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div class="row clearfix">
                        <div class="col-md-12 text-right">
                            <button id={`btnHBOCFamilyHistoryInfoForm2Reset`} class="btn btn-light">Reset Form</button>
                            <button id="btnHBOCFamilyHistoryInfoForm2Next" class="btn btn-light">Next</button>
                        </div>
                    </div>
                </div>

                {/* <!-- Default Size --> */}
                <div class="modal fade" id="defaultModal2" tabindex="-1" role="dialog">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title" id="defaultModalLabel">Does the Patient have a family history of</h4>
                            </div>
                            <div class="modal-body">
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                        <div class="demo-radio-button modalcss">
                                            <input type="radio" id="pfHistory2_AnyAge_Mothside" name="pfHistory2_AnyAge_relative" value="yes" class="form-control" />
                                            <label class="form-label" for="pfHistory2_AnyAge_Mothside">Mother's side</label>
                                            <input type="radio" id="pfHistory2_AnyAge_Fathside" name="pfHistory2_AnyAge_relative" value="no" class="form-control" />
                                            <label class="form-label" for="pfHistory2_AnyAge_Fathside">Father's side</label>
                                        </div>
                                    </div>

                                    <div class="col-md-3">
                                        <div class="form-line">
                                            <select class="form-control show-tick" id="pfHistory2_AnyAge_relative">
                                                <option value="">-- Relative --</option>
                                                <option value="parent">parent</option>
                                                <option value="sibling">sibling</option>
                                                <option value="child">child</option>
                                                <option value="uncle">uncle</option>
                                                <option value="aunt">aunt</option>
                                                <option value="great uncle">great uncle</option>
                                                <option value="great aunt">great aunt</option>
                                                <option value="nephew">nephew</option>
                                                <option value="niece">niece</option>
                                                <option value="grandparent">grandparent</option>
                                                <option value="grandchild">grandchild</option>
                                                <option value="half-sibling">half-sibling</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-line">
                                            <input type="text" id="pfHistory2_AnyAge_age" class="form-control " placeholder="Age" />
                                            <input type="hidden" id="pfHistory2_AnyAge_dataid" class="form-control " />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn waves-effect btn-primary text-white" onClick={() => {
                                    btnpfHistoryAddFamilyHistory2()
                                }}>Add</button>&nbsp; &nbsp;
                                <button type="button" class="btn waves-effect btn-danger text-white mclose" data-dismiss="modal">CLOSE</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
