import React, { PureComponent } from "react";
import axios from "axios";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import helper from "./../utils/helper";
import { protectedResources } from "./../authConfig";
import { MsalContext } from "@azure/msal-react";

const API_BASE_URL = helper.getAPIBaseURI();

const ORDERS_BAR_CHART_BUTTON_LIST = [
  { label: "Today", value: "today" },
  { label: "3 Days", value: "3_days" },
  { label: "Week", value: "week" },
  { label: "2 Weeks", value: "2_weeks" },
  { label: "Month", value: "month" },
];

export default class NewOrdersBarChart extends PureComponent {
  static contextType = MsalContext;

  constructor(props) {
    super(props);

    this.state = {

      selectedDataRange: "today",
      chartOptions: {
        credits: {
          enabled: false,
        },
        chart: { type: "column" },
        title: { text: undefined },
        yAxis: {
          title: { text: "Count" },
        },
      }
    }
  }

  componentDidMount() {
    this.getOrdersCountAndUpdateTheBarChartData();
  }

  // event handlers
  updateGraphDataRange = (e) => {
    const rangeSelectedByUser = e.target.value;
    this.setState({ selectedDataRange: rangeSelectedByUser });
    this.getOrdersCountAndUpdateTheBarChartData(rangeSelectedByUser);
  }

  // generic methods
  getButtonDisplayLabelByValue = (buttonValue) => {
    for (let button of ORDERS_BAR_CHART_BUTTON_LIST) {
      if (button.value === buttonValue) return button.label;
    }
    return "";
  }

  getOrdersCountAndUpdateTheBarChartData = async (selectedDataRange = "today") => {

    const accessToken = await helper.getAccessToken(this.context)

    const { data } = await axios({
      url: `${API_BASE_URL}/orders/newOrdersCount?filterRange=${selectedDataRange}`,
      method: "GET",
      headers: {
        "Content-type": "Application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    const { numberOfOrders } = data;
    const displayLabelOfSelectedRange = this.getButtonDisplayLabelByValue(selectedDataRange);

    const series = [
      {
        name: "Orders",
        data: [numberOfOrders],
      }
    ];
    const xAxis = {
      categories: [displayLabelOfSelectedRange],
    };

    this.setState({ chartOptions: { xAxis, series } });
  }

  // helper methods for rendering
  renderChartTitleAndOptions = () => {
    const { selectedDataRange } = this.state;

    return (
      <>
        <div className="row graph-title">
          <div className="col-md-12">
            <strong>New Orders</strong>
          </div>
        </div>
        <div className="row graph-options-container">
          <div className="col-md-12 d-flex flex-row flex-wrap">
            {ORDERS_BAR_CHART_BUTTON_LIST.map(button => {
              const btnClassNames = selectedDataRange === button.value ? "graph-button selected" : "graph-button";
              return <button className={btnClassNames} value={button.value} onClick={this.updateGraphDataRange}>{button.label}</button>;
            })}
          </div>
        </div>
      </>
    );
  }

  render() {
    return (
      <div style={{ width: "100%", height: "100%" }}>
        {this.renderChartTitleAndOptions()}
        <HighchartsReact highcharts={Highcharts} options={this.state.chartOptions} />
      </div>
    );
  }
}
