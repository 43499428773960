import React, { PureComponent, useState, useContext } from "react";

import PropTypes from 'prop-types';

import $ from 'jquery';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import helper from "../utils/helperPortal";

export default class ASPiRAGENETIXCarrierScreeningForm extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            duedate_pregnant: ""
        }

    }

    onChangeDuedate_pregnant = date => {
        this.setState({ duedate_pregnant: date });

        if (date) {
            console.log("duedate_pregnant->date.toString(): ", date.toString());

            let dateStr = moment(date).format('YYYY-MM-DD');

            $("#duedate_pregnant_hidden").val(dateStr);
        }
    };

    componentDidMount() {
        $(document).ready(async () => {

            //Assays mapping dynamically
            let _assays = await helper.getAssaysByType('GENETIX_CS');
            console.log("GENETIX_CS _assays: ", _assays);

            let content = ``;
            _assays.assays.map((a, i) => {
                content += `<div class="col-md-4">`;
                content += `<input data-qbenchid="${a.qBenchId}" data-val="${a.name}" type="radio" name="gtcs_assay_" id="gtcs_assay_${i}" class="form-control" />
                <label for="gtcs_assay_${i}">${a.name}  &nbsp; </label></div>`;
            });

            this.setState({ assays: content }, () => {
                $('#printHere_CS').html(content);
            });

            $('#btnASPiRAGENETIXCarrierScreeningFormReset').click(() => {
                $(`#ASPiRAGENETIXCarrierScreeningForm`).find(`:input`).map((i, elem) => {
                    const _type = $(elem).attr('type');
                    helper.resetForm(_type, elem);
                });
            })
        })
    }

    render() {

        return (
            <>
                <div class="panel-body">
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="form-group required">
                                <label class="form-label" for="address">ASPiRA GENETIX Carrier Screening (select panel)</label>
                                <div class="demo-radio-button">
                                    <div id="printHere_CS" class="row clearfix"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <p>Male partner testing will not include X-linked genes.</p>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="form-group ">
                                <label class="form-label" for="address">Indications for testing: check all that apply</label>
                                <div class="row clearfix">
                                    <div class="col-md-5">
                                        <div class="demo-checkbox">
                                            <input type="checkbox" data-val="Patient Screening" id="IndicationsTest_PatientScreening" name="IndicationsTest_PatientScreening" class="filled-in" />
                                            <label for="IndicationsTest_PatientScreening">Patient Screening &nbsp; &nbsp; </label>
                                            <input type="checkbox" data-val="Pregnancy" id="IndicationsTest_Pregnancy" name="IndicationsTest_Pregnancy" class="filled-in" />
                                            <label for="IndicationsTest_Pregnancy">Pregnancy &nbsp; &nbsp; </label>
                                            <input type="checkbox" data-val="Infertility" id="IndicationsTest_Infertility" name="IndicationsTest_Infertility" class="filled-in" />
                                            <label for="IndicationsTest_Infertility">Infertility &nbsp;  &nbsp; </label>
                                            <input type="checkbox" data-val="Other" id="IndicationsTest_other" name="IndicationsTest_other" class="filled-in" />
                                            <label for="IndicationsTest_other">Other: </label>
                                        </div>
                                    </div>
                                    <div class="col-md-7">
                                        <div class="form-line">
                                            <input type="text" id="IndicationsTest_otherValue" class="form-control " placeholder="" />
                                        </div>
                                    </div>
                                </div>
                                <div class="demo-checkbox">
                                    <input type="checkbox" data-val="Partner Screening" id="IndicationsTest_PartnerScreening" name="IndicationsTest_PartnerScreening" class="filled-in" />
                                    <label for="IndicationsTest_PartnerScreening">Partner Screening &nbsp; &nbsp; </label>
                                    <input type="checkbox" data-val="Family History" id="IndicationsTest_FamilyHistory" name="IndicationsTest_FamilyHistory" class="filled-in" />
                                    <label for="IndicationsTest_FamilyHistory">Family History &nbsp; &nbsp; </label>
                                    <input type="checkbox" data-val="Egg/Sperm Donor" id="IndicationsTest_EggSperm_Donor" name="IndicationsTest_EggSperm_Donor" class="filled-in" />
                                    <label for="IndicationsTest_EggSperm_Donor">Egg/Sperm Donor &nbsp;  &nbsp; </label>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-label" for="DOB">Due date if pregnant </label>
                                <div class="form-line">
                                    <DatePicker
                                        id={`duedate_pregnant`}
                                        className={"form-control"}
                                        selected={this.state.duedate_pregnant}
                                        onChange={this.onChangeDuedate_pregnant}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />

                                    <input type="hidden" id="duedate_pregnant_hidden" value={moment(this.state.duedate_pregnant).format("YYYY-MM-DD")} />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-label" for="NameofInsured">Ultrasound findings / Clinical testing </label>
                                <div class="form-line">
                                    <input type="text" id="ultrasound_findings" class="form-control " placeholder=" Ultrasound findings / Clinical testing" />
                                </div>
                            </div>
                        </div>

                    </div>

                    <div class="row clearfix">
                        <div class="col-md-12 text-right">
                            <button id={`btnASPiRAGENETIXCarrierScreeningFormReset`} class="btn btn-light">Reset Form</button>
                            <button id="btnASPiRAGENETIXCarrierScreeningFormNext" class="btn btn-light">Next</button>
                        </div>
                    </div>

                </div>
            </>
        );
    }

}
