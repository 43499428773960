import React, { PureComponent } from "react";

import PropTypes from 'prop-types';
import $ from 'jquery';
import helper from './../utils/helperPortal';

export default class KitShippingInfoHBOCForm2 extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
        }

    }

    componentDidMount() {
        $(document).ready(() => {
            $('#btnKitShippingInfoHBOCForm2Reset').click(() => {
                $('#HBOCKitShippingInfoContainer').find(':input').map((i, elem) => {
                    const _type = $(elem).attr('type');
                    helper.resetForm(_type, elem);
                })
            })
        });
    }
    render() {

        return (
            <>
                <div id={`HBOCKitShippingInfoContainer`} class="panel-body">
                    <div class="row clearfix">
                        <div class="col-md-7">
                            <div class="form-group required">
                                <label class="form-label" for="address">Kit Shipping Info</label>
                                <div class="demo-checkbox" id="draw_type" >
                                    <input type="checkbox" data-val="My physician needs mobile phlebotomy" id="kitShipHBOC2_PatMobPhlebotomy" name="kitShipHBOC2_PatMobPhlebotomy" class="filled-in" />
                                    <label for="kitShipHBOC2_PatMobPhlebotomy">My physician needs mobile phlebotomy &nbsp; </label>
                                    <input type="checkbox" data-val="Ship physician saliva collection kit" id="kitShipHBOC2_salivaCollkit" name="kitShipHBOC2_salivaCollkit" class="filled-in" />
                                    <label for="kitShipHBOC2_salivaCollkit">Ship physician saliva collection kit &nbsp; </label>
                                    <input type="checkbox" data-val="Other" id="kitShipHBOC2_PatOthers" name="kitShipHBOC2_PatOthers" class="filled-in" />
                                    <label for="kitShipHBOC2_PatOthers">Other &nbsp; </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group ">
                                <label class="form-label" for="address">&nbsp;</label>
                                <div class="form-line">
                                    <input type="text" id="kitShipHBOC2_PatOthersInfo" class="form-control " placeholder="Others info" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12 text-right">
                            <button id={`btnKitShippingInfoHBOCForm2Reset`} class="btn btn-light">Reset Form</button>
                            <button id="btnKitShippingInfoHBOCForm2Next" class="btn btn-light">Next</button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
