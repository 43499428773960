import React, { PureComponent } from "react";

import PropTypes from 'prop-types';
import $ from 'jquery';
//import DatePicker from 'react-datepicker';
import helper from './../utils/helperPortal';
export default class KitShippingInfoForm extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {

        }

    }

    //onChangeBillingDoB = date => this.setState({ billinfo_dob: date }); 

    componentDidMount() {
        $(document).ready(() => {

            $(`#${this.props.prefix}btnKitShippingInfoFormReset`).click(() => {
                $(`#${this.props.prefix}kitShippingInfoFormContainer`).find(':input').map((i, elem) => {
                    const _type = $(elem).attr('type');
                    console.log("_type: ", _type);
                    helper.resetForm(_type, elem);
                });

            });
        });
    }
    render() {

        return (
            <>
                <div id={`${this.props.prefix}kitShippingInfoFormContainer`} class="panel-body">
                    {/* <div class="row clearfix">
                        <div class="col-md-7">
                            <div class="form-group required">
                                <label class="form-label" for="address">Kit Shipping Info</label>
                                <div id="draw_type" class="demo-checkbox">
                                    <input data-val="My physician needs mobile phlebotomy" type="checkbox" id={`${this.props.prefix}kitShip_PatMobPhlebotomy`} name={`${this.props.prefix}kitShip_PatMobPhlebotomy`} class="filled-in" />
                                    <label for={`${this.props.prefix}kitShip_PatMobPhlebotomy`}>My physician needs mobile phlebotomy &nbsp; </label>
                                    <input data-val="Patient will get blood draw at Quest PSC" type="checkbox" id={`${this.props.prefix}kitShip_PatblooddrawQuest`} name={`${this.props.prefix}kitShip_PatblooddrawQuest`} class="filled-in" />
                                    <label for={`${this.props.prefix}kitShip_PatblooddrawQuest`}>Patient will get blood draw at Quest PSC &nbsp; </label>
                                    <input data-val="Other" type="checkbox" id={`${this.props.prefix}kitShip_PatOthers`} name="kitShip_PatOthers" class="filled-in" />
                                    <label for={`${this.props.prefix}kitShip_PatOthers`}>Other &nbsp; </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group ">
                                <label class="form-label" for="address">&nbsp;</label>
                                <div class="form-line">
                                    <input type="text" id={`${this.props.prefix}kitShip_PatOthersInfo`} class="form-control " placeholder=" Other info" />
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <p>Label the EDTA tube with patient name and DOB. Place the EDTA tube into the biohazard bag. (If Saliva sample, place Saliva samples directly into the biohazard bag.) Print this form (after you submit) and fold into quarters and place in the side pocket of the biohazard bag. Remove the lid from the shipping box. Place the biohazard bag with the EDTA or saliva tube in the box. Close kit box and place into the FedEx Bag. Affix the shipping label to the outside of the FedEx Bag in the designated area. Contact FedEx online or call 1-800-463-3339 to schedule a pick up. Kits may also be dropped off at a FedEx shipping location.</p>
                        </div>
                    </div>    
                    
                    <div class="row clearfix">
                        <div class="col-md-12 text-right">
                            <button id={`${this.props.prefix}btnKitShippingInfoFormReset`} class="btn btn-light">Reset Form</button>
                            <button id={`${this.props.prefix}btnKitShippingInfoFormNext`} class="btn btn-light">Next</button>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}
