import { message } from "antd";
import axios from "axios";
import React from "react";
import helper from "../../utils/helper";

export default class RetrieveReport extends React.PureComponent {

  constructor(props) {
    super(props);

    this.API_BASE_URL = helper.getAPIBaseURI();
  }

  render() {
    return (
      <div id="" class="row clearfix" style={{ marginLeft: "20px", height: "50px" }}>
        <div class="col-md-12">
          <div class="form-group">
            <button
              id={`${this.props.prefix}btnRetrieveUpdatedReport`}
              onClick={() => {
                console.log("RETRIEVE_REPORT:" + this.props.token);
                console.log("RETRIEVE_REPORT:" + this.props.orderId);
                console.log("RETRIEVE_REPORT:" + this.props.reportId);
                console.log("RETRIEVE_REPORTDATE:" + this.props.reportDate);

                this.props.me.setState(
                  { loading: false, showLoader: true },
                  async () => {
                    if (this.props.reportId) {
                      const _payload = {
                        orderId: this.props.orderId,
                        reportId: this.props.reportId,
                        reportCompletionDateString: this.props.reportDate,
                      };
                      axios({
                        url: `${this.API_BASE_URL}/baylor/retrieveReport`,
                        method: "POST",
                        headers: {
                          "Content-type": "Application/json",
                          Authorization: `Bearer ${this.props.token}`,
                        },
                        data: _payload,
                      }).then(({ data }) => {
                        console.log("data: ", data);
                        document.body.classList.remove("busy-cursor");

                        this.props.me.setState(
                          {
                            loading: false,
                            showLoader: false,
                            toastMessage: `Successfully Retrieved the Report`,
                          },
                          () => {
                            //me.showToast("success");
                            message.success(this.props.me.state.toastMessage);
                          }
                        );
                      })
                        .catch((error) => {
                          this.props.me.setState(
                            {
                              loading: false,
                              showLoader: false,
                              toastMessage: `Failed to Retrieve Report: ${error.message}`,
                            },
                            () => {
                              //me.showToast("success");
                              message.error(this.props.me.state.toastMessage);
                            }
                          );
                        })
                    } else {
                      console.log("Report is not retrived");
                    }
                  }
                );
              }}
              placeholder="Retrieve Updated Report"
              name="Submit"
              className="btn btn-primary text-white"
            >
              Retrieve Updated Report
            </button>
          </div>
        </div>
      </div >
    );
  }
}
