import React, { PureComponent } from "react";

import PropTypes from 'prop-types';
//import $ from 'jquery';
import DatePicker from 'react-datepicker';

export default class BillingInformationHBOCForm2 extends PureComponent {

    constructor(props) {
        super(props)
    
        this.state = {

        }          
        
    }
    
    onChangeBillingHBOCDoB2 = date => this.setState({ billinfoHBOC_dob2: date }); 

    render() {

        return (
            <>
               <div class="panel-body">
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="form-group required">
                                <label class="form-label" for="BillingInformation2">Billing Information</label>
                                <div class="demo-checkbox">                
                                    <input type="checkbox" id="billinfoHBOC_PrivateInsurance2" name="billinfoHBOC_PrivateInsurance2" class="filled-in" />
                                    <label for="billinfoHBOC_PrivateInsurance2">Private Insurance &nbsp; </label> 
                                    <input type="checkbox" id="billinfoHBOC_Medicare2" name="billinfoHBOC_Medicare2" class="filled-in"  />
                                    <label for="billinfoHBOC_Medicare2">Medicare<sup>1</sup> &nbsp; </label>
                                    <input type="checkbox" id="billinfoHBOC_PatientSelfPay2" name="billinfoHBOC_PatientSelfPay2" class="filled-in" />
                                    <label for="billinfoHBOC_PatientSelfPay2">Patient Self-Pay &nbsp; </label> 
                                    <input type="checkbox" id="billinfoHBOC_Medicaid2" name="billinfoHBOC_Medicaid2" class="filled-in"  />
                                    <label for="billinfoHBOC_Medicaid2">Medicaid &nbsp; </label>  
                                    <input type="checkbox" id="billinfoHBOC_OrderingFacility2" name="billinfoHBOC_OrderingFacility2" class="filled-in"  />
                                    <label for="billinfoHBOC_OrderingFacility2">Ordering Facility (Client Bill) &nbsp; </label>                                               
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <p><sup>1</sup>By ordering this test for your Medicare physician, you are certifying that the physician has met the requirements for use <br/>i.e has an ovarian mass, has surgery planned and is over 18 years of age</p>
                            <p>Insurance Information</p>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for="PrimaryInsuranceCarrier">Primary Insurance Carrier </label>
                                <div class="form-line">                                
                                    <input type="text" id="billinfoHBOC_PrimaryInsuranceCarrier2" class="form-control required" placeholder="Primary Insurance Carrier"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for="MemberID">Member ID# </label>
                                <div class="form-line">                                
                                    <input type="text" id="billinfoHBOC_MemberID2" class="form-control required" placeholder="Member ID"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for="GroupID">Group ID# </label>
                                <div class="form-line">                                
                                    <input type="text" id="billinfoHBOC_GroupID2" class="form-control required" placeholder="Group ID#"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for="SecondaryInsuranceCarrier">Secondary Insurance Carrier </label>
                                <div class="form-line">                                
                                    <input type="text" id="billinfoHBOC_SecondaryInsuranceCarrier2" class="form-control required" placeholder="Secondary Insurance Carrier"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for="SecondaryMemberID">Member ID# </label>
                                <div class="form-line">                                
                                    <input type="text" id="billinfoHBOC_SecondaryMemberID2" class="form-control required" placeholder="Member ID"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for="SecondaryGroupID">Group ID# </label>
                                <div class="form-line">                                
                                    <input type="text" id="billinfoHBOC_SecondaryGroupID2" class="form-control required" placeholder="Group ID#"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for="TertiaryInsuranceCarrier">Tertiary Insurance Carrier </label>
                                <div class="form-line">                                
                                    <input type="text" id="billinfoHBOC_TertiaryInsuranceCarrier2" class="form-control required" placeholder="Tertiary Insurance Carrier"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for="TertiaryMemberID">Member ID# </label>
                                <div class="form-line">                                
                                    <input type="text" id="billinfoHBOC_TertiaryMemberID2" class="form-control required" placeholder="Member ID"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for="TertiaryGroupID">Group ID# </label>
                                <div class="form-line">                                
                                    <input type="text" id="billinfoHBOC_TertiaryGroupID2" class="form-control required" placeholder="Group ID#"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for="NameofInsured">Name of Insured </label>
                                <div class="form-line">                                
                                    <input type="text" id="billinfoHBOC_NameofInsured2" class="form-control required" placeholder="Name of Insured"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for="DOB">DOB (mm/dd/yyyy) </label>
                                <div class="form-line">
                                <DatePicker
                                        id={`billinfoHBOC_dob2`}
                                        className={"form-control required"}
                                        selected={this.state.billinfoHBOC_dob2}
                                        onChange={this.onChangeBillingHBOCDoB2}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />                                
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for="Relationshiptoinsured2">Relationship to insured</label>
                                <div class="form-line">                                
                                    <input type="text" id="billinfoHBOC_RelationshipInsured2" class="form-control required" placeholder="Relationship to insured"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12 text-right">
                            <button id="btnBillingInformationHBOCForm2Next" class="btn btn-light">Next</button>
                            <button style={{ display: 'none' }} id="btnDiagCodeHBOCForm2Next" data-toggle="collapse" data-target="#DiagnosisCodes_ICD10CodesForm2" class="hide">Next</button>
                            <button style={{ display: 'none' }} id="btnBillingInfoHBOCForm2Next" data-toggle="collapse" data-target="#BillingInformationHBOCForm2" class="hide">Next</button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
