import React, { useState, useEffect } from 'react';
import helper from "../../utils/helper";
import Select from "react-select";
import DatePicker from "react-datepicker";

const Clinical_Information = ({
  me,
  state: {
    clinicinfo_DoLastMentrualPeriod,
    // questionCode - ova1_q01
    clinicinfo_Menopausal_status,

    // questionCode - ova1_q02
    ultrasound_results_q02,

    // questionCode - ova1_q04
    clinicinfo_MassSize_longdime,
    clinicinfo_MassSize_Height,
    clinicinfo_MassSize_Height_unit,
    clinicinfo_MassSize_Weight,
    clinicinfo_MassSize_Weight_unit,

    // questionCode - ova1_q05
    clinicinfo_pain_abdomen_pelvis,
    days_per_month_q05_1,
    how_long_month_q05_2,

    // questionCode - ova1_q06
    clinicinfo_fullQuickly_unableToe,
    days_per_month_q06_1,
    how_long_month_q06_2,

    // questionCode - ova1_q07
    clinicinfo_Expabdominalbloat_IncrSize,
    days_per_month_q07_1,
    how_long_month_q07_2
  }
}) => {

  useEffect(() => {
    console.log('sm ultrasound_results_q02: ', ultrasound_results_q02)
  }, [ultrasound_results_q02])

  /* if(props.type==='HBOC') {

  }
   */
  return (
    <div>
      <div id={`clinicalInfoFormContainer`} class="body card">
        <div class="row clearfix">
          <div class="col-md-6">
            <div class="form-group required">
              <label class="form-label" for={`MenopausalStatus`}>
                Menopausal Status
              </label>
              <div id={`ova1_q01`} class="demo-radio-button">
                <input
                  type="radio"
                  id={`clinicinfo_Menopausal_status`}
                  name="clinicinfo_Menopausal_status_"
                  value="Pre-Menopausal"
                  class=" form-control"
                  checked={clinicinfo_Menopausal_status === 'Pre'}
                  onClick={() => me.setState({ clinicinfo_Menopausal_status: 'Pre' })}
                  /*  onChange={(val) => { 
                    props.me.setState({  patinfo_State: val,
                      patinfo_State_Save_value:val.value, });
                  }} */
                />
                <label
                  class="form-label menopausalStatus"
                  for={`clinicinfo_Menopausal_status`}
                >
                  Pre-Menopausal &nbsp;{" "}
                </label>
                <input
                  type="radio"
                  id={`clinicinfo_Menopausal_status2`}
                  name="clinicinfo_Menopausal_status_"
                  value="Post-Menopausal"
                  class=" form-control"
                  checked={clinicinfo_Menopausal_status === 'Post'}
                  onClick={() => me.setState({ clinicinfo_Menopausal_status: 'Post' })}
                />
                <label
                  class="form-label menopausalStatus"
                  for={`clinicinfo_Menopausal_status2`}
                >
                  Post-Menopausal &nbsp;{" "}
                </label>
                <input
                  type="radio"
                  id={`clinicinfo_Menopausal_status3`}
                  name="clinicinfo_Menopausal_status_"
                  value="Unknown"
                  class=" form-control"
                  checked={clinicinfo_Menopausal_status === 'Unknown'}
                  onClick={() => me.setState({ clinicinfo_Menopausal_status: 'Unknown' })}
                />
                <label
                  class="form-label menopausalStatus"
                  for={`clinicinfo_Menopausal_status3`}
                >
                  Unknown &nbsp;{" "}
                </label>
                <input
                  type="radio"
                  id={`clinicinfo_Menopausal_status4`}
                  name="clinicinfo_Menopausal_status_"
                  value="Not Given"
                  class=" form-control"
                  checked={clinicinfo_Menopausal_status === 'Not Given'}
                  onClick={() => me.setState({ clinicinfo_Menopausal_status: 'Not Given' })}
                />
                <label
                  class="form-label menopausalStatus"
                  for={`clinicinfo_Menopausal_status4`}
                >
                  Not Given &nbsp;{" "}
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group required">
              <label class="form-label" for={`UltrasoundResults`}>
                Ultrasound Results
              </label>
              <div id={`ova1_q02`} class="demo-checkbox">
                <input
                  data-val="Low Risk"
                  type="checkbox"
                  id={`clinicinfo_lowrisk`}
                  name="clinicinfo_lowrisk"
                  class=" filled-in"
                  checked={ultrasound_results_q02 === 'Low Risk'}
                  onClick={() => me.setState({ ultrasound_results_q02: 'Low Risk' })}
                />
                <label for={`clinicinfo_lowrisk`}>Low Risk &nbsp; </label>
                <input
                  data-val="High Risk"
                  type="checkbox"
                  id={`clinicinfo_highrisk`}
                  name="clinicinfo_highrisk"
                  class=" filled-in"
                  checked={ultrasound_results_q02 === 'High Risk'}
                  onClick={() => me.setState({ ultrasound_results_q02: 'High Risk' })}
                />
                <label for={`clinicinfo_highrisk`}>High Risk &nbsp; </label>
                <input
                  data-val="Not Definitive"
                  type="checkbox"
                  id={`clinicinfo_NotDefinitive`}
                  name="clinicinfo_NotDefinitive"
                  class=" filled-in"
                  checked={ultrasound_results_q02 === 'Not Definitive'}
                  onClick={() => me.setState({ ultrasound_results_q02: 'Not Definitive' })}
                />
                <label for={`clinicinfo_NotDefinitive`}>
                  Not Definitive &nbsp;{" "}
                </label>
                <input
                  data-val="Not Given"
                  type="checkbox"
                  id={`clinicinfo_NotGiven`}
                  name="clinicinfo_NotGiven"
                  class=" filled-in"
                  checked={ultrasound_results_q02 === 'Not Given'}
                  onClick={() => me.setState({ ultrasound_results_q02: 'Not Given' })}
                />
                <label for={`clinicinfo_NotGiven`}>Not Given &nbsp; </label>
              </div>
            </div>
          </div>
        </div>

        <div id={`ova1_q03`} class="row clearfix">
          <div class="col-md-12">
            <div class="form-group required">
              <label class="form-label" for={`dob`}>
                Date of last menstrual period{" "}
              </label>
              <div class="form-line">
                <DatePicker
                  id={`clinicinfo_DoLastMentrualPeriod`}
                  className={"form-control required"}
                  selected={clinicinfo_DoLastMentrualPeriod}
                  onChange={(val) => me.setState({ clinicinfo_DoLastMentrualPeriod: val })}
                  onYearChange={(val) => me.setState({ clinicinfo_DoLastMentrualPeriod: val })}
                  onMonthChange={(val) => me.setState({ clinicinfo_DoLastMentrualPeriod: val })}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="row clearfix">
          <div id={`ova1_q04`} class="col-md-4">
            <div class="form-group ">
              <label class="form-label" for={`masssize_long`}>
                Size of mass(longest dimension)
              </label>
              <div class="form-line">
                <input
                  type="number"
                  id={`clinicinfo_MassSize_longdime`}
                  class="form-control"
                  placeholder=" longest dimension"
                  value={clinicinfo_MassSize_longdime}
                  onChange={({ target: { value } }) => {
                    if (parseInt(value) > 0) {
                      me.setState({ clinicinfo_MassSize_longdime: value })
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div id={`ova1_q04_1`} class="col-md-2">
            <div class="form-group ">
              <label class="form-label" for={`masssize_height`}>
                Height{" "}
              </label>
              <div class="form-line">
                <input
                  type="number"
                  id={`clinicinfo_MassSize_Height`}
                  class="form-control"
                  placeholder=" Height"
                  value={clinicinfo_MassSize_Height}
                  onChange={({ target: { value } }) => {
                    if (parseInt(value) > 0) {
                      me.setState({ clinicinfo_MassSize_Height: value })
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div id={`ova1_q04_12`} class="col-md-2">
            <div class="form-group ">
              <label class="form-label" for={`masssize_height`}>
                &nbsp;{" "}
              </label>
              <div class="form-line">
                <select
                  class="form-control show-tick"
                  id={`clinicinfo_MassSize_Height_unit`}
                  value={clinicinfo_MassSize_Height_unit}
                  onChange={({ target: { value } }) => me.setState({ clinicinfo_MassSize_Height_unit: value})}
                >
                  <option value="">-- select --</option>
                  <option value="inches"> Inches</option>
                  <option value="millimeters">Millimeters</option>
                </select>
              </div>
            </div>
          </div>
          <div id={`ova1_q04_2`} class="col-md-2">
            <div class="form-group ">
              <label class="form-label" for={`masssize_weight`}>
                Weight
              </label>
              <div class="form-line">
                <input
                  type="number"
                  id={`clinicinfo_MassSize_Weight`}
                  class="form-control"
                  placeholder=" Weight"
                  value={clinicinfo_MassSize_Weight}
                  onChange={({ target: { value } }) => {
                    if (parseInt(value) > 0) {
                      me.setState({ clinicinfo_MassSize_Weight: value })
                    }
                  }}
                />
              </div>
            </div>
          </div>
          <div id={`ova1_q04_22`} class="col-md-2">
            <div class="form-group ">
              <label class="form-label" for={`masssize_weight`}>
                &nbsp;{" "}
              </label>
              <div class="form-line">
                <select
                  class="form-control show-tick"
                  id={`clinicinfo_MassSize_Weight_unit`}
                  value={clinicinfo_MassSize_Weight_unit}
                  onChange={({ target: { value } }) => me.setState({ clinicinfo_MassSize_Weight_unit: value})}
                >
                  <option value="">-- select --</option>
                  <option value="ounces"> Ounces</option>
                  <option value="grams">Grams</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div id={`ova1_q05`} class="row clearfix black-label">
          <div class="col-md-12">
            <div class="demo-radio-button">
              <label>
                Does the patient have pain in the abdomen or pelvis?
              </label>
              <input
                type="radio"
                id={`clinicinfo_pain_abdomen_pelvis`}
                name="clinicinfo_pain_abdomen_pelvis_"
                value="yes"
                class="form-control"
                onClick={() => me.setState({ clinicinfo_pain_abdomen_pelvis: true })}
                checked={clinicinfo_pain_abdomen_pelvis}
              />
              <label
                class="form-label"
                for={`clinicinfo_pain_abdomen_pelvis`}
              >
                Yes
              </label>
              <input
                type="radio"
                id={`clinicinfo_pain_abdomen_pelvis2`}
                name="clinicinfo_pain_abdomen_pelvis_"
                value="no"
                class=" form-control"
                onClick={() => me.setState({ clinicinfo_pain_abdomen_pelvis: false })}
                checked={!clinicinfo_pain_abdomen_pelvis}
              />
              <label
                class="form-label"
                for={`clinicinfo_pain_abdomen_pelvis2`}
              >
                No
              </label>
            </div>
          </div>
        </div>

        <div
          id={`PatienAbdomenPainArea`}
          className={clinicinfo_pain_abdomen_pelvis ? 'showPatienAbdomenPainArea' : 'hidePatienAbdomenPainArea'}
        >
          <div class="row clearfix ">
            <div class="col-md-5">
              <label class="form-label" for={``}>
                How many days per month?
              </label>
              <div id={`ova1_q05_1`} class="demo-radio-button">
                <input
                  type="radio"
                  id={`clinicinfo_painAbdomenPelvis_Day_1`}
                  name={"clinicinfo_painAbdomenPelvis_Day_"}
                  value="0-5 days"
                  class=" form-control"
                  checked={days_per_month_q05_1 === '0-5 days'}
                  onClick={(e) => me.setState({ days_per_month_q05_1: '0-5 days' })}
                />
                <label
                  class="form-label"
                  for={`clinicinfo_painAbdomenPelvis_Day_1`}
                >
                  0-5 days &nbsp;{" "}
                </label>
                <input
                  type="radio"
                  id={`clinicinfo_painAbdomenPelvis_Day_2`}
                  name={"clinicinfo_painAbdomenPelvis_Day_"}
                  value="6-12 days"
                  class=" form-control"
                  checked={days_per_month_q05_1 === '6-12 days'}
                  onClick={(e) => me.setState({ days_per_month_q05_1: '6-12 days' })}
                />
                <label
                  class="form-label"
                  for={`clinicinfo_painAbdomenPelvis_Day_2`}
                >
                  6-12 days &nbsp;{" "}
                </label>
                <input
                  type="radio"
                  id={`clinicinfo_painAbdomenPelvis_Day_3`}
                  name={"clinicinfo_painAbdomenPelvis_Day_"}
                  value="> 12 days"
                  class=" form-control"
                  checked={days_per_month_q05_1 === '> 12 days'}
                  onClick={(e) => me.setState({ days_per_month_q05_1: '> 12 days' })}
                />
                <label
                  class="form-label"
                  for={`clinicinfo_painAbdomenPelvis_Day_3`}
                >
                  &gt; 12 days &nbsp;{" "}
                </label>
              </div>
            </div>
            <div class="col-md-7">
              <label class="form-label" for={``}>
                For how long?
              </label>
              <div id={`ova1_q05_2`} class="demo-radio-button">
                <input
                  type="radio"
                  id={`clinicinfo_painAbdomenPelvis_long_1`}
                  name={"clinicinfo_painAbdomenPelvis_long_"}
                  value="< 1 month"
                  class=" form-control"
                  checked={how_long_month_q05_2 === '< 1 month'}
                  onClick={() => me.setState({ how_long_month_q05_2: '< 1 month' })}
                />
                <label
                  class="form-label"
                  for={`clinicinfo_painAbdomenPelvis_long_1`}
                >
                  &lt; 1 month &nbsp;{" "}
                </label>
                <input
                  type="radio"
                  id={`clinicinfo_painAbdomenPelvis_long_2`}
                  name={"clinicinfo_painAbdomenPelvis_long_"}
                  value="1-6 months"
                  class=" form-control"
                  checked={how_long_month_q05_2 === '1-6 months'}
                  onClick={() => me.setState({ how_long_month_q05_2: '1-6 months' })}
                />
                <label
                  class="form-label"
                  for={`clinicinfo_painAbdomenPelvis_long_2`}
                >
                  1-6 months &nbsp;{" "}
                </label>
                <input
                  type="radio"
                  id={`clinicinfo_painAbdomenPelvis_long_3`}
                  name={"clinicinfo_painAbdomenPelvis_long_"}
                  value="7-12 months"
                  class=" form-control"
                  checked={how_long_month_q05_2 === '7-12 months'}
                  onClick={() => me.setState({ how_long_month_q05_2: '7-12 months' })}
                />
                <label
                  class="form-label"
                  for={`clinicinfo_painAbdomenPelvis_long_3`}
                >
                  7-12 months &nbsp;{" "}
                </label>
                <input
                  type="radio"
                  id={`clinicinfo_painAbdomenPelvis_long_4`}
                  name={"clinicinfo_painAbdomenPelvis_long_"}
                  value="> 12 months"
                  class=" form-control"
                  checked={how_long_month_q05_2 === '> 12 months'}
                  onClick={() => me.setState({ how_long_month_q05_2: '> 12 months' })}
                />
                <label
                  class="form-label"
                  for={`clinicinfo_painAbdomenPelvis_long_4`}
                >
                  &gt; 12 months &nbsp;{" "}
                </label>
              </div>
            </div>
          </div>
        </div>

        <br></br>
        <div id={`ova1_q06`} class="row clearfix black-label">
          <div class="col-md-12">
            <div class="demo-radio-button">
              <label>
                Does the patient feel full quickly or unable to eat
                normally?
              </label>
              <input
                type="radio"
                id={`clinicinfo_fullQuickly_unableToe`}
                name="clinicinfo_fullQuickly_unableToe_"
                value="yes"
                class=" form-control"
                onClick={() => me.setState({ clinicinfo_fullQuickly_unableToe: true })}
                checked={clinicinfo_fullQuickly_unableToe}
              />
              <label
                class="form-label"
                for={`clinicinfo_fullQuickly_unableToe`}
              >
                Yes
              </label>
              <input
                type="radio"
                id={`clinicinfo_fullQuickly_unableToe2`}
                name="clinicinfo_fullQuickly_unableToe_"
                value="no"
                class=" form-control"
                onClick={() => me.setState({ clinicinfo_fullQuickly_unableToe: false })}
                checked={!clinicinfo_fullQuickly_unableToe}
              />
              <label
                class="form-label"
                for={`clinicinfo_fullQuickly_unableToe2`}
              >
                No
              </label>
            </div>
          </div>
        </div>

        <div
          id={`PatienfullQuicklyUnableToeArea`}
          className={clinicinfo_fullQuickly_unableToe ? 'showPatienAbdomenPainArea' : 'hidePatienAbdomenPainArea'}
        >
          <div class="row clearfix">
            <div class="col-md-5">
              <label class="form-label" for={``}>
                How many days per month?
              </label>
              <div id={`ova1_q06_1`} class="demo-radio-button">
                <input
                  type="radio"
                  id={`clinicinfo_fullQuicklyUnableToe_Day_1`}
                  name={"clinicinfo_fullQuicklyUnableToe_Day_"}
                  value="0-5 days"
                  class=" form-control"
                  checked={days_per_month_q06_1 === '0-5 days'}
                  onClick={() => me.setState({ days_per_month_q06_1: '0-5 days'})}
                />
                <label
                  class="form-label"
                  for={`clinicinfo_fullQuicklyUnableToe_Day_1`}
                >
                  0-5 days &nbsp;{" "}
                </label>
                <input
                  type="radio"
                  id={`clinicinfo_fullQuicklyUnableToe_Day_2`}
                  name={"clinicinfo_fullQuicklyUnableToe_Day_"}
                  value="6-12 days"
                  class=" form-control"
                  checked={days_per_month_q06_1 === '6-12 days'}
                  onClick={() => me.setState({ days_per_month_q06_1: '6-12 days'})}
                />
                <label
                  class="form-label"
                  for={`clinicinfo_fullQuicklyUnableToe_Day_2`}
                >
                  6-12 days &nbsp;{" "}
                </label>
                <input
                  type="radio"
                  id={`clinicinfo_fullQuicklyUnableToe_Day_3`}
                  name={"clinicinfo_fullQuicklyUnableToe_Day_"}
                  value="> 12 days"
                  class=" form-control"
                  checked={days_per_month_q06_1 === '> 12 days'}
                  onClick={() => me.setState({ days_per_month_q06_1: '> 12 days'})}
                />
                <label
                  class="form-label"
                  for={`clinicinfo_fullQuicklyUnableToe_Day_3`}
                >
                  &gt; 12 days &nbsp;{" "}
                </label>
              </div>
            </div>
            <div class="col-md-7">
              <label class="form-label" for={``}>
                For how long?
              </label>
              <div id={`ova1_q06_2`} class="demo-radio-button">
                <input
                  type="radio"
                  id={`clinicinfo_fullQuicklyUnableToe_long_1`}
                  name={"clinicinfo_fullQuicklyUnableToe_long_"}
                  value="< 1 month"
                  class=" form-control"
                  checked={how_long_month_q06_2 === '< 1 month'}
                  onClick={() => me.setState({ how_long_month_q06_2: '< 1 month'})}
                />
                <label
                  class="form-label"
                  for={`clinicinfo_fullQuicklyUnableToe_long_1`}
                >
                  &lt; 1 month &nbsp;{" "}
                </label>
                <input
                  type="radio"
                  id={`clinicinfo_fullQuicklyUnableToe_long_2`}
                  name={"clinicinfo_fullQuicklyUnableToe_long_"}
                  value="1-6 months"
                  class=" form-control"
                  checked={how_long_month_q06_2 === '1-6 months'}
                  onClick={() => me.setState({ how_long_month_q06_2: '1-6 months'})}
                />
                <label
                  class="form-label"
                  for={`clinicinfo_fullQuicklyUnableToe_long_2`}
                >
                  1-6 months &nbsp;{" "}
                </label>
                <input
                  type="radio"
                  id={`clinicinfo_fullQuicklyUnableToe_long_3`}
                  name={"clinicinfo_fullQuicklyUnableToe_long_"}
                  value="7-12 months"
                  class=" form-control"
                  checked={how_long_month_q06_2 === '7-12 months'}
                  onClick={() => me.setState({ how_long_month_q06_2: '7-12 months'})}
                />
                <label
                  class="form-label"
                  for={`clinicinfo_fullQuicklyUnableToe_long_3`}
                >
                  7-12 months &nbsp;{" "}
                </label>
                <input
                  type="radio"
                  id={`clinicinfo_fullQuicklyUnableToe_long_4`}
                  name={"clinicinfo_fullQuicklyUnableToe_long_"}
                  value="> 12 months"
                  class=" form-control"
                  checked={how_long_month_q06_2 === '> 12 months'}
                  onClick={() => me.setState({ how_long_month_q06_2: '> 12 months'})}
                />
                <label
                  class="form-label"
                  for={`clinicinfo_fullQuicklyUnableToe_long_4`}
                >
                  &gt; 12 months &nbsp;{" "}
                </label>
              </div>
            </div>
          </div>
        </div>

        <br></br>
        <div id={`ova1_q07`} class="row clearfix black-label">
          <div class="col-md-12">
            <div class="demo-radio-button">
              <label>
                Does the patient experience abdominal bloating or an
                increased abdominal size?
              </label>
              <input
                type="radio"
                id={`clinicinfo_Expabdominalbloat_IncrSize`}
                name="clinicinfo_Expabdominalbloat_IncrSize_"
                value="yes"
                class=" form-control"
                onClick={() => me.setState({ clinicinfo_Expabdominalbloat_IncrSize: true })}
                checked={clinicinfo_Expabdominalbloat_IncrSize}
              />
              <label
                class="form-label"
                for={`clinicinfo_Expabdominalbloat_IncrSize`}
              >
                Yes
              </label>
              <input
                type="radio"
                id={`clinicinfo_Expabdominalbloat_IncrSize2`}
                name="clinicinfo_Expabdominalbloat_IncrSize_"
                value="no"
                class=" form-control"
                onClick={() => me.setState({ clinicinfo_Expabdominalbloat_IncrSize: false })}
                checked={!clinicinfo_Expabdominalbloat_IncrSize}
              />
              <label
                class="form-label"
                for={`clinicinfo_Expabdominalbloat_IncrSize2`}
              >
                No
              </label>
            </div>
          </div>
        </div>
        <div
        id={`PatienExpabdominalbloatArea`}
        className={clinicinfo_Expabdominalbloat_IncrSize ? 'showPatienAbdomenPainArea' : 'hidePatienAbdomenPainArea'}
        >
          <div class="row clearfix">
            <div id={`ova1_q07_1`} class="col-md-5">
              <label class="form-label" for={``}>
                How many days per month?
              </label>
              <div class="demo-radio-button">
                <input
                  type="radio"
                  id={`clinicinfo_ExpabdominalbloatIncrSize_Day_1`}
                  name={"clinicinfo_ExpabdominalbloatIncrSize_Day_"}
                  value="0-5 days"
                  class=" form-control"
                  checked={days_per_month_q07_1 === '0-5 days'}
                  onClick={() => me.setState({ days_per_month_q07_1: '0-5 days' })}
                />
                <label
                  class="form-label"
                  for={`clinicinfo_ExpabdominalbloatIncrSize_Day_1`}
                >
                  0-5 days &nbsp;{" "}
                </label>
                <input
                  type="radio"
                  id={`clinicinfo_ExpabdominalbloatIncrSize_Day_2`}
                  name={"clinicinfo_ExpabdominalbloatIncrSize_Day_"}
                  value="6-12 days"
                  class=" form-control"
                  checked={days_per_month_q07_1 === '6-12 days'}
                  onClick={() => me.setState({ days_per_month_q07_1: '6-12 days' })}
                />
                <label
                  class="form-label"
                  for={`clinicinfo_ExpabdominalbloatIncrSize_Day_2`}
                >
                  6-12 days &nbsp;{" "}
                </label>
                <input
                  type="radio"
                  id={`clinicinfo_ExpabdominalbloatIncrSize_Day_3`}
                  name={"clinicinfo_ExpabdominalbloatIncrSize_Day_"}
                  value="> 12 days"
                  class=" form-control"
                  checked={days_per_month_q07_1 === '> 12 days'}
                  onClick={() => me.setState({ days_per_month_q07_1: '> 12 days' })}
                />
                <label
                  class="form-label"
                  for={`clinicinfo_ExpabdominalbloatIncrSize_Day_3`}
                >
                  &gt; 12 days &nbsp;{" "}
                </label>
              </div>
            </div>
            <div class="col-md-7">
              <label class="form-label" for={``}>
                For how long?
              </label>
              <div id={`ova1_q07_2`} class="demo-radio-button">
                <input
                  type="radio"
                  id={`clinicinfo_ExpabdominalbloatIncrSize_long_1`}
                  name={"clinicinfo_ExpabdominalbloatIncrSize_long_"}
                  value="< 1 month"
                  class=" form-control"
                  checked={how_long_month_q07_2 === '< 1 month'}
                  onClick={() => me.setState({ how_long_month_q07_2: '< 1 month'})}
                />
                <label
                  class="form-label"
                  for={`clinicinfo_ExpabdominalbloatIncrSize_long_1`}
                >
                  &lt; 1 month &nbsp;{" "}
                </label>
                <input
                  type="radio"
                  id={`clinicinfo_ExpabdominalbloatIncrSize_long_2`}
                  name={"clinicinfo_ExpabdominalbloatIncrSize_long_"}
                  value="1-6 months"
                  class=" form-control"
                  checked={how_long_month_q07_2 === '1-6 months'}
                  onClick={() => me.setState({ how_long_month_q07_2: '1-6 months'})}
                />
                <label
                  class="form-label"
                  for={`clinicinfo_ExpabdominalbloatIncrSize_long_2`}
                >
                  1-6 months &nbsp;{" "}
                </label>
                <input
                  type="radio"
                  id={`clinicinfo_ExpabdominalbloatIncrSize_long_3`}
                  name={"clinicinfo_ExpabdominalbloatIncrSize_long_"}
                  value="7-12 months"
                  class=" form-control"
                  checked={how_long_month_q07_2 === '7-12 months'}
                  onClick={() => me.setState({ how_long_month_q07_2: '7-12 months'})}
                />
                <label
                  class="form-label"
                  for={`clinicinfo_ExpabdominalbloatIncrSize_long_3`}
                >
                  7-12 months &nbsp;{" "}
                </label>
                <input
                  type="radio"
                  id={`clinicinfo_ExpabdominalbloatIncrSize_long_4`}
                  name={"clinicinfo_ExpabdominalbloatIncrSize_long_"}
                  value="> 12 months"
                  class=" form-control"
                  checked={how_long_month_q07_2 === '> 12 months'}
                  onClick={() => me.setState({ how_long_month_q07_2: '> 12 months'})}
                />
                <label
                  class="form-label"
                  for={`clinicinfo_ExpabdominalbloatIncrSize_long_4`}
                >
                  &gt; 12 months &nbsp;{" "}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Clinical_Information


