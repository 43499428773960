import React, { createRef, useState, useEffect, useContext } from "react";
import $ from "jquery";

import PropTypes from "prop-types";

//includes all forms here
import ASPiRAGENETIXCarrierScreeningForm from "./ASPiRAGENETIXCarrierScreeningForm";
// import PhysicianInformationCarrierForm from "./PhysicianInformationForm";
import PhysicianInformationForm from "./PhysicianInformationForm";
//import PatientInformationCarrierForm from "./PatientInformationCarrierForm";
import PatientInformationForm from "./PatientInformationForm";
// import BillingInformationCarrierForm from "./BillingInformationCarrierForm";
import BillingInformationForm from "./BillingInformationForm";
// import DiagnosisCodes_ICD10CodesCarrierForm from "./DiagnosisCodes_ICD10CodesCarrierForm";
import DiagnosisCodes_ICD10CodesForm from "./DiagnosisCodes_ICD10CodesForm";

import FamilyHistoryForm from "./FamilyHistoryForm";
// import KitShippingInfoCarrierForm from "./KitShippingInfoCarrierForm";
import KitShippingInfoForm from "./KitShippingInfoForm";
// import NotesCertifyCarrierForm from "./NotesCertifyCarrierForm";
import NotesCertifyForm from "./NotesCertifyForm";
import Toast from "./../portalComponents/toast/Toast";

import axios from "axios";
import helper from "./../utils/helperPortal";
import { message } from "antd";

import { Card, Accordion, Button } from "react-bootstrap";

import { createPhysicianApi } from "./../context/actions/physiciansActions";
import {
  createPatientApi,
  createOva1OrderApi,
} from "./../context/actions/patientActions";

import { AppContext } from "./../context";
import { prefix } from "@fortawesome/free-solid-svg-icons";
// import BillingInformationForm from "./BillingInformationForm";
const api_base_url = helper.getAPIBaseURI();

const GenetiXCarrierScreeningForm = () => {
  let sigPad = createRef(null);

  let selectedPhysicianLegacyInfo = null;

  const gTcs_prefix = "gtcs_";
  const patientPhoneNo_prefix = "patinfoC_";
  const physicianAddressContext = useContext(AppContext);

  const { patientDOB } = useContext(AppContext);
  const {
    physicianAddresses,
    selectedPhysicianAddress,
    physicianPracticeLocation,
    selectedPhysicianPracticeLocation,
  } = physicianAddressContext;

  let toastProperties = {};
  const [toastList, setToastList] = useState([]);
  const [toastMessage, setToastMessage] = useState("");

  const [patientState, setPatientState] = useState("");
  const [patientDOBLocal, setPatientDOBLocal] = useState("");

  const [billinfoC_dob, setBillInfoCDOB] = useState("");

  const [physicianState, setPhysicianState] = useState("");

  const [selectedPhysicianLegacyInfoState, setSelectedPhysicianLegacyInfoState] = useState(null); //need-1

  const showToast = (type) => {
    const id = Math.floor(Math.random() * 101 + 1);

    switch (type) {
      case "success":
        toastProperties = {
          id,
          title: "Success",
          description: "This is a success toast component",
          backgroundColor: "#5cb85c",
          icon: "/assets/toast/check.svg",
        };
        break;
      case "danger":
        toastProperties = {
          id,
          title: "Danger",
          description: "This is a error toast component",
          backgroundColor: "#d9534f",
          icon: "/assets/toast/error.svg",
        };
        break;
      case "info":
        toastProperties = {
          id,
          title: "Info",
          description: "This is an info toast component",
          backgroundColor: "#5bc0de",
          icon: "/assets/toast/info.svg",
        };
        break;
      case "warning":
        toastProperties = {
          id,
          title: "Warning",
          description: "This is a warning toast component",
          backgroundColor: "#f0ad4e",
          icon: "/assets/toast/warning.svg",
        };
        break;

      default:
        setToastList([]);
    }

    setToastList([toastProperties]);
  };
  const loadInfo = () => { };
  React.useEffect(() => {
    loadInfo();
  });

  const onChangeState = (_state) => {
    setPatientState(_state);
  };
  $(document).ready(() => {
    $("input.form-control").keyup(function (event) {
      console.log("event.keyCode: ", event.keyCode);
      if (event.keyCode == 13) {
        let textboxes = $("input.form-control");
        let currentBoxNumber = textboxes.index(this);
        if (textboxes[currentBoxNumber + 1] != null) {
          let nextBox = textboxes[currentBoxNumber + 1];
          nextBox.focus();
          nextBox.select();
        }
        event.preventDefault();
        return false;
      }

      return true;
    });

    const btnAspiraGenetixCSFormNextFunc = () => {
      let isGood = true;

      const ultrasound_findings = helper.removeWhiteSpaceTrim(
        $("#ultrasound_findings").val()
      );
      $("#ultrasound_findings").val(ultrasound_findings);

      let isGood1 = false;
      let inputs = $(`#ASPiRAGENETIXCarrierScreeningForm #printHere_CS`).find(
        ":input"
      );

      for (let i = 0; i < inputs.length; ++i) {
        let elem = inputs[i];
        let _type = $(elem).attr("type");
        if (_type === "radio" && $(elem).is(":checked")) {
          isGood1 = true;
          break;
        }
      }

      if (!isGood1) {
        message.warning(`Please select Carrier Screening.`);

        isGood = false;
      }
      // else if (ultrasound_findings.length < 1) {
      //   message.warning(`Please fill up all required fields.`);
      //   

      //   $('#ultrasound_findings').focus();
      //   isGood = false;
      // }
      else {
        isGood = true;
      }

      return isGood;
    };

    //PhysicianInformationForm btnPhysicianInformationFormNext

    const btnPhysicianInfoFormNextFunc = () => {
      const phyinfo_Organization_account = helper.removeWhiteSpaceTrim(
        $(`#${gTcs_prefix}phyinfo_Organization_account`).val()
      );
      $(`#${gTcs_prefix}phyinfo_Organization_account`).val(
        phyinfo_Organization_account
      );

      const phyinfo_first_name = helper.removeWhiteSpaceTrim(
        $(`#${gTcs_prefix}phyinfo_first_name`).val()
      );
      $(`#${gTcs_prefix}phyinfo_first_name`).val(phyinfo_first_name);

      const phyinfo_last_name = helper.removeWhiteSpaceTrim(
        $(`#${gTcs_prefix}phyinfo_last_name`).val()
      );
      $(`#${gTcs_prefix}phyinfo_last_name`).val(phyinfo_last_name);

      const phyinfo_npi = helper.removeWhiteSpaceTrim(
        $(`#${gTcs_prefix}phyinfo_npi`).val()
      );
      $(`#${gTcs_prefix}phyinfo_npi`).val(phyinfo_npi);

      const phyinfo_email = helper.removeWhiteSpaceTrim(
        $(`#${gTcs_prefix}phyinfo_email`).val()
      );
      $(`#${gTcs_prefix}phyinfo_email`).val(phyinfo_email);

      const phyinfo_address = helper.removeWhiteSpaceTrim(
        $(`#${gTcs_prefix}phyinfoC_address`).val()
      );
      $(`#${gTcs_prefix}phyinfoC_address`).val(phyinfo_address);

      const phyinfo_practiceLocation = helper.removeWhiteSpaceTrim(
        $(`#${gTcs_prefix}phyinfoC_practiceLocation`).val()
      );
      $(`#${gTcs_prefix}phyinfoC_practiceLocation`).val(
        phyinfo_practiceLocation
      );

      const phyinfo_city = helper.removeWhiteSpaceTrim(
        $(`#${gTcs_prefix}phyinfoC_city`).val()
      );
      $(`#${gTcs_prefix}phyinfoC_city`).val(phyinfo_city);

      const phyinfo_state = helper.removeWhiteSpaceTrim(
        $(`#${gTcs_prefix}phyinfoC_state`).val()
      );
      $(`#${gTcs_prefix}phyinfoC_state`).val(phyinfo_state);

      const phyinfo_zipCode = helper.removeWhiteSpaceTrim(
        $(`#${gTcs_prefix}phyinfoC_zipCode`).val()
      );
      $(`#${gTcs_prefix}phyinfoC_zipCode`).val(phyinfo_zipCode);

      const ph_areacode = helper.removeWhiteSpaceTrim(
        $(`#${gTcs_prefix}phyinfoC_ph_areacode`).val()
      );
      $(`#${gTcs_prefix}phyinfoC_ph_areacode`).val(ph_areacode);

      const ph_prefix = helper.removeWhiteSpaceTrim(
        $(`#${gTcs_prefix}phyinfoC_ph_prefix`).val()
      );
      $(`#${gTcs_prefix}phyinfoC_ph_prefix`).val(ph_prefix);

      const ph_suffix = helper.removeWhiteSpaceTrim(
        $(`#${gTcs_prefix}phyinfoC_ph_suffix`).val()
      );
      $(`#${gTcs_prefix}phyinfoC_ph_suffix`).val(ph_suffix);

      const phyinfo_fax = helper.removeWhiteSpaceTrim(
        $(`#${gTcs_prefix}phyinfoC_fax`).val()
      );
      $(`#${gTcs_prefix}phyinfoC_fax`).val(phyinfo_fax);

      const phyinfo_Faxcopyto = helper.removeWhiteSpaceTrim(
        $(`#${gTcs_prefix}phyinfoC_Faxcopyto`).val()
      );
      $(`#${gTcs_prefix}phyinfoC_Faxcopyto`).val(phyinfo_Faxcopyto);

      const phyinfo_confirmation = helper.removeWhiteSpaceTrim(
        $(`#${gTcs_prefix}phyinfoC_confirmation`).val()
      );
      $(`#${gTcs_prefix}phyinfoC_confirmation`).val(phyinfo_confirmation);

      const phyinfo_no_npi = helper.removeWhiteSpaceTrim(
        $(`#${gTcs_prefix}phyinfo_no_npi`).val()
      );
      $(`#${gTcs_prefix}phyinfo_no_npi`).val(phyinfo_no_npi);

      //ova1_phyinfo_phone_ph_suffix
      const phyinfo_account_number = helper.removeWhiteSpaceTrim(
        $(`#${gTcs_prefix}phyinfo_account_number`).val()
      );
      $(`#${gTcs_prefix}phyinfo_account_number`).val(phyinfo_account_number);

      const phone_ph_areacode = helper.removeWhiteSpaceTrim(
        $(`#${gTcs_prefix}phyinfo_phone_ph_areacode`).val()
      );
      $(`#${gTcs_prefix}phyinfo_phone_ph_areacode`).val(phone_ph_areacode);

      const phone_ph_prefix = helper.removeWhiteSpaceTrim(
        $(`#${gTcs_prefix}phyinfo_phone_ph_prefix`).val()
      );
      $(`#${gTcs_prefix}phyinfo_phone_ph_prefix`).val(phone_ph_prefix);

      const phone_ph_suffix = helper.removeWhiteSpaceTrim(
        $(`#${gTcs_prefix}phyinfo_phone_ph_suffix`).val()
      );
      $(`#${gTcs_prefix}phyinfo_phone_ph_suffix`).val(phone_ph_suffix);

      const hostname = $(`#${gTcs_prefix}hostname`).val();

      let isGood = true;

      if (hostname == "lab.aspirawh.com" || hostname == "localhost" || hostname == "dev.aspirawh.cloud" || hostname === "order.dev-aspiralab.com" || hostname === "dev.aspirawh.cloud") {
        //yes it is lab.aspirawh.com

        if (
          $(`#${gTcs_prefix}ordered_before1`).is(":not(:checked)") &&
          $(`#${gTcs_prefix}ordered_before2`).is(":not(:checked)")
        ) {
          message.warning(`Have you ordered from us before is required.`);

          isGood = false;
        } else if ($(`#${gTcs_prefix}ordered_before1`).is(":checked")) {
          //already Order Placed

          let _selectedNPI = "", _selectedClientId = "";

          //need - 5
          selectedPhysicianLegacyInfo = selectedPhysicianLegacyInfoState;

          // selectedPhysicianLegacyInfo = localStorage.getItem(
          //   "selectedPhysicianLegacyInfo"
          // );

          console.log("Genetix->selectedPhysicianLegacyInfo: ", selectedPhysicianLegacyInfo);
          if (
            typeof selectedPhysicianLegacyInfo !== "undefined" &&
            selectedPhysicianLegacyInfo
          ) {

            //need - 6
            // selectedPhysicianLegacyInfo = JSON.parse(
            //   selectedPhysicianLegacyInfo
            // );

            _selectedNPI = selectedPhysicianLegacyInfo.selectedNPI;
            _selectedClientId = selectedPhysicianLegacyInfo.selectedClientId;
          }

          if (phyinfo_account_number.length < 1) {
            message.warning(`Physician Account Number is required field.`);


            $(`#${gTcs_prefix}phyinfo_account_number`).focus();

            isGood = false;
          } else {
            // alert(_selectedNPI);
            if (_selectedNPI.toString().length < 1) {
              message.warning(`Physician selection is required.`);


              // $(`#${gTcs_prefix}phyinfo_account_number`).focus();

              isGood = false;
            }
          }
        } else if ($(`#${gTcs_prefix}ordered_before2`).is(":checked")) {
          //Not Order Placed yet

          if (phyinfo_first_name.length < 1) {
            message.warning(`Physician first name is required field.`);


            $(`#${gTcs_prefix}phyinfo_first_name`).focus();

            isGood = false;
          } else if (phyinfo_last_name.length < 1) {
            message.warning(`Physician last name is required field.`);


            $(`#${gTcs_prefix}phyinfo_last_name`).focus();

            isGood = false;
          } else if (phyinfo_npi.length < 1) {
            message.warning(`Physician npi is required field.`);


            $(`#${gTcs_prefix}phyinfo_npi`).focus();

            isGood = false;
          } else if (phyinfo_email.length < 1) {
            message.warning(`Physician email address is required field.`);


            $(`#${gTcs_prefix}phyinfo_email`).focus();

            isGood = false;
          } else if (!helper.isEmail(phyinfo_email)) {
            message.warning(`Please enter a valid email address.`);


            $(`#${gTcs_prefix}phyinfo_email`).focus();

            isGood = false;
          } else if (phone_ph_areacode.length < 1) {
            message.warning(`Physician phone is required field.`);

            $(`#${gTcs_prefix}phyinfo_phone_ph_areacode`).focus();

            isGood = false;
          } else if (phone_ph_prefix.length < 1) {
            message.warning(`Physician phone is required field.`);

            $(`#${gTcs_prefix}phyinfo_phone_ph_prefix`).focus();

            isGood = false;
          } else if (phone_ph_suffix.length < 1) {
            message.warning(`Physician phone is required field.`);

            $(`#${gTcs_prefix}phyinfo_phone_ph_suffix`).focus();

            isGood = false;
          } else if (phyinfo_no_npi === "1" && phyinfo_address.length < 1) {
            message.warning(`Physician address is required field.`);

            $(`#${gTcs_prefix}phyinfoC_address`).focus();

            isGood = false;
          } else if (
            phyinfo_no_npi === "1" &&
            phyinfo_practiceLocation.length < 1
          ) {
            message.warning(`Physician Practice Location is required field.`);

            $(`#${gTcs_prefix}phyinfoC_practiceLocation`).focus();

            isGood = false;
          } else if (phyinfo_no_npi === "1" && ph_areacode.length < 1) {
            message.warning(`Physician phone is required field.`);

            $(`#${gTcs_prefix}phyinfoC_ph_areacode`).focus();

            isGood = false;
          } else if (phyinfo_no_npi === "1" && ph_prefix.length < 1) {
            message.warning(`Physician phone is required field.`);

            $(`#${gTcs_prefix}phyinfoC_ph_prefix`).focus();

            isGood = false;
          } else if (phyinfo_no_npi === "1" && ph_suffix.length < 1) {
            message.warning(`Physician phone is required field.`);

            $(`#${gTcs_prefix}phyinfoC_ph_suffix`).focus();

            isGood = false;
          }
        } else {
          isGood = true;
        }
      }
      else {
        // not lab

        if (phyinfo_first_name.length < 1) {
          message.warning(`Physician first name is required field.`);


          $(`#${gTcs_prefix}phyinfo_first_name`).focus();

          isGood = false;
        } else if (phyinfo_last_name.length < 1) {
          message.warning(`Physician last name is required field.`);


          $(`#${gTcs_prefix}phyinfo_last_name`).focus();

          isGood = false;
        } else if (phyinfo_npi.length < 1) {
          message.warning(`Physician npi is required field.`);


          $(`#${gTcs_prefix}phyinfo_npi`).focus();

          isGood = false;
        } else if (phyinfo_email.length < 1) {
          message.warning(`Physician email address is required field.`);


          $(`#${gTcs_prefix}phyinfo_email`).focus();

          isGood = false;
        } else if (!helper.isEmail(phyinfo_email)) {
          message.warning(`Please enter a valid email address.`);


          $(`#${gTcs_prefix}phyinfo_email`).focus();

          isGood = false;
        } else if (phone_ph_areacode.length < 1) {
          message.warning(`Physician phone is required field.`);

          $(`#${gTcs_prefix}phyinfo_phone_ph_areacode`).focus();

          isGood = false;
        } else if (phone_ph_prefix.length < 1) {
          message.warning(`Physician phone is required field.`);

          $(`#${gTcs_prefix}phyinfo_phone_ph_prefix`).focus();

          isGood = false;
        } else if (phone_ph_suffix.length < 1) {
          message.warning(`Physician phone is required field.`);

          $(`#${gTcs_prefix}phyinfo_phone_ph_suffix`).focus();

          isGood = false;
        } else if (phyinfo_no_npi === "1" && phyinfo_address.length < 1) {
          message.warning(`Physician address is required field.`);

          $(`#${gTcs_prefix}phyinfoC_address`).focus();

          isGood = false;
        } else if (
          phyinfo_no_npi === "1" &&
          phyinfo_practiceLocation.length < 1
        ) {
          message.warning(`Physician Practice Location is required field.`);

          $(`#${gTcs_prefix}phyinfoC_practiceLocation`).focus();

          isGood = false;
        } else if (phyinfo_no_npi === "1" && ph_areacode.length < 1) {
          message.warning(`Physician phone is required field.`);

          $(`#${gTcs_prefix}phyinfoC_ph_areacode`).focus();

          isGood = false;
        } else if (phyinfo_no_npi === "1" && ph_prefix.length < 1) {
          message.warning(`Physician phone is required field.`);

          $(`#${gTcs_prefix}phyinfoC_ph_prefix`).focus();

          isGood = false;
        } else if (phyinfo_no_npi === "1" && ph_suffix.length < 1) {
          message.warning(`Physician phone is required field.`);

          $(`#${gTcs_prefix}phyinfoC_ph_suffix`).focus();

          isGood = false;
        } else {
          isGood = true;
        }
      }

      return isGood;
    };

    const btnPatientInfoFormNextFunc = () => {
      let isGood = true;

      const patinfo_firstname = helper.removeWhiteSpaceTrim(
        $("#gtcs_patinfo_firstname").val()
      );
      $("#gtcs_patinfo_firstname").val(patinfo_firstname);

      const patinfo_lastname = helper.removeWhiteSpaceTrim(
        $("#gtcs_patinfo_lastname").val()
      );
      $("#gtcs_patinfo_lastname").val(patinfo_lastname);

      const patinfo_address1 = helper.removeWhiteSpaceTrim(
        $("#gtcs_patinfo_address1").val()
      );
      $("#gtcs_patinfo_address1").val(patinfo_address1);

      const patinfo_city = helper.removeWhiteSpaceTrim(
        $("#gtcs_patinfo_city").val()
      );
      $("#gtcs_patinfo_city").val(patinfo_city);

      //const genetixCS_patinfoC_state = helper.removeWhiteSpaceTrim($('#genetixCS_patinfoC_state').val()); //patientState;
      //$('#genetixCS_patinfoC_state').val(genetixCS_patinfoC_state);

      const patinfo_zipcode = helper.removeWhiteSpaceTrim(
        $("#gtcs_patinfo_zipcode").val()
      );
      $("#gtcs_patinfo_zipcode").val(patinfo_zipcode);

      //alert($('#patinfoC_dob').val())
      // const patinfoC_dob_hidden = helper.removeWhiteSpaceTrim($('#patinfoC_dob_hidden').val());
      // $('#patinfoC_dob_hidden').val(patinfoC_dob_hidden);

      const ph_areacode = helper.removeWhiteSpaceTrim(
        $("#gtcs_patinfo_ph_areacode").val()
      );
      $("#gtcs_patinfo_ph_areacode").val(ph_areacode);

      const ph_prefix = helper.removeWhiteSpaceTrim(
        $("#gtcs_patinfo_ph_prefix").val()
      );
      $("#gtcs_patinfo_ph_prefix").val(ph_prefix);

      const ph_suffix = helper.removeWhiteSpaceTrim(
        $("#gtcs_patinfo_ph_suffix").val()
      );
      $("#gtcs_patinfo_ph_suffix").val(ph_suffix);

      const patinfo_emailaddress = helper.removeWhiteSpaceTrim(
        $("#gtcs_patinfo_emailaddress").val()
      );
      $("#gtcs_patinfo_emailaddress").val(patinfo_emailaddress);

      const patinfo_Othercity = helper.removeWhiteSpaceTrim(
        $("#gtcs_patinfo_OtherEthnicitycity").val()
      );
      $("#gtcs_patinfo_OtherEthnicitycity").val(patinfo_Othercity);

      //console.log("btnBillingInfoCarrierFormNext1->patientState: ", genetixCS_patinfoC_state);

      const hostname = $(`#${gTcs_prefix}hostname`).val();

      if (hostname !== "lab.aspirawh.com" && hostname !== 'dev.aspirawh.cloud' && hostname !== "order.dev-aspiralab.com") {
        // not lab

        if (patinfo_firstname.length < 1) {
          message.warning(`Patient first name is required field.`);

          $("#gtcs_patinfo_firstname").focus();

          isGood = false;
        } else if (patinfo_lastname.length < 1) {
          message.warning(`Patient last name is required field.`);


          $("#gtcs_patinfo_lastname").focus();

          isGood = false;
        } else if (
          $(`#${gTcs_prefix}patinfo_gender`).is(":not(:checked)") &&
          $(`#${gTcs_prefix}patinfo_gender2`).is(":not(:checked)")
        ) {
          message.warning(`Patient gender is required.`);


          isGood = false;
        } else if (patinfo_address1.length < 1) {
          message.warning(`Patient address 1 is required field.`);


          $("#gtcs_patinfo_address1").focus();

          isGood = false;
        } else if (patinfo_city.length < 1) {
          message.warning(`Patient city is required field.`);


          $("#gtcs_patinfo_city").focus();

          isGood = false;
        } else if (patinfo_zipcode.length < 1) {
          message.warning(`Patient zipcode is required field.`);


          $("#gtcs_patinfo_zipcode").focus();

          isGood = false;
        } else if (ph_areacode.length < 1) {
          message.warning(`Patient phone number is required field.`);


          $("#gtcs_patinfo_ph_areacode").focus();

          isGood = false;
        } else if (ph_prefix.length < 1) {
          message.warning(`Patient phone number is required field.`);


          $("#gtcs_patinfo_ph_prefix").focus();

          isGood = false;
        } else if (ph_suffix.length < 1) {
          message.warning(`Patient phone number is required field.`);


          $("#gtcs_patinfo_ph_suffix").focus();

          isGood = false;
        } else if (patinfo_emailaddress.length < 1) {
          message.warning(`Patient email address is required field.`);


          $("#gtcs_patinfo_emailaddress").focus();

          isGood = false;
        } else if (!helper.isEmail(patinfo_emailaddress)) {
          message.warning(`Please enter a valid email address.`);


          $("#gtcs_patinfo_emailaddress").focus();

          isGood = false;
        }
        // else if (
        //   $("#gtcs_patinfo_Caucasian").is(":not(:checked)") &&
        //   $("#gtcs_patinfo_AshkenaziJewish").is(":not(:checked)") &&
        //   $("#gtcs_patinfo_SephardicJewish").is(":not(:checked)") &&
        //   $("#gtcs_patinfo_Asian").is(":not(:checked)") &&
        //   $("#gtcs_patinfo_HIspanic").is(":not(:checked)") &&
        //   $("#gtcs_patinfo_NativeAmerican").is(":not(:checked)") &&
        //   $("#gtcs_patinfo_AfricanAmerican").is(":not(:checked)") &&
        //   $("#gtcs_patinfo_Other").is(":not(:checked)")
        // ) {
        //   message.warning(`Please select patient's ethnicity.`);
        //   

        //   isGood = false;
        // } 

        else if (
          !$("#gtcs_patinfo_Other").is(":not(:checked)") &&
          patinfo_Othercity.length < 1
        ) {
          message.warning(`Patient's ethnicity is required field.`);

          $("#gtcs_patinfo_OtherEthnicitycity").focus();

          isGood = false;
        } else {
          isGood = true;
        }
      } else {
        //yes it is lab.aspirawh.com

        if (patinfo_firstname.length < 1) {
          message.warning(`Patient first name is required field.`);

          $("#gtcs_patinfo_firstname").focus();

          isGood = false;
        } else if (patinfo_lastname.length < 1) {
          message.warning(`Patient last name is required field.`);


          $("#gtcs_patinfo_lastname").focus();

          isGood = false;
        } else if (!patientDOBLocal || patientDOBLocal.length < 1) {
          isGood = false;
          message.warning(`Patient DOB is required.`);
        }
        else {
          isGood = true;
        }
      }

      return isGood;
    };

    //Billing form BillingInformationCarrierForm
    const billingInfoCarrierFormNextFunc = () => {
      let isGood = true;

      if (window.location.hostname === "lab.aspirawh.com" || window.location.hostname === 'dev.aspirawh.cloud' || window.location.hostname === "order.dev-aspiralab.com") {
        return isGood;
      }

      const billinfo_PrimaryInsuranceCarrier = helper.removeWhiteSpaceTrim(
        $(`#${gTcs_prefix}billinfo_PrimaryInsuranceCarrier`).val()
      );
      $(`#${gTcs_prefix}billinfo_PrimaryInsuranceCarrier`).val(
        billinfo_PrimaryInsuranceCarrier
      );

      // const billinfo_planId = helper.removeWhiteSpaceTrim(
      //   $(`#${gTcs_prefix}billinfo_planId`).val()
      // );
      // $(`#${gTcs_prefix}billinfo_planId`).val(billinfo_planId);

      const billinfo_MemberID = helper.removeWhiteSpaceTrim(
        $(`#${gTcs_prefix}billinfo_MemberID`).val()
      );
      $(`#${gTcs_prefix}billinfo_MemberID`).val(billinfo_MemberID);

      const billinfo_GroupID = helper.removeWhiteSpaceTrim(
        $(`#${gTcs_prefix}billinfo_GroupID`).val()
      );
      $(`#${gTcs_prefix}billinfo_GroupID`).val(billinfo_GroupID);

      const billinfo_SecondaryInsuranceCarrier = helper.removeWhiteSpaceTrim(
        $(`#${gTcs_prefix}billinfo_SecondaryInsuranceCarrier`).val()
      );
      $(`#${gTcs_prefix}billinfo_SecondaryInsuranceCarrier`).val(
        billinfo_SecondaryInsuranceCarrier
      );

      // const SecondaryplanId = helper.removeWhiteSpaceTrim(
      //   $(`#${gTcs_prefix}SecondaryplanId`).val()
      // );
      // $(`#${gTcs_prefix}SecondaryplanId`).val(SecondaryplanId);

      const billinfo_SecondaryMemberID = helper.removeWhiteSpaceTrim(
        $(`#${gTcs_prefix}billinfo_SecondaryMemberID`).val()
      );
      $(`#${gTcs_prefix}billinfo_SecondaryMemberID`).val(
        billinfo_SecondaryMemberID
      );

      const billinfo_SecondaryGroupID = helper.removeWhiteSpaceTrim(
        $(`#${gTcs_prefix}billinfo_SecondaryGroupID`).val()
      );
      $(`#${gTcs_prefix}billinfo_SecondaryGroupID`).val(
        billinfo_SecondaryGroupID
      );

      const billinfo_TertiaryInsuranceCarrier = helper.removeWhiteSpaceTrim(
        $(`#${gTcs_prefix}billinfo_TertiaryInsuranceCarrier`).val()
      );
      $(`#${gTcs_prefix}billinfo_TertiaryInsuranceCarrier`).val(
        billinfo_TertiaryInsuranceCarrier
      );

      const billinfo_TertiaryMemberID = helper.removeWhiteSpaceTrim(
        $(`#${gTcs_prefix}billinfo_TertiaryMemberID`).val()
      );
      $(`#${gTcs_prefix}billinfo_TertiaryMemberID`).val(
        billinfo_TertiaryMemberID
      );

      const billinfo_TertiaryGroupID = helper.removeWhiteSpaceTrim(
        $(`#${gTcs_prefix}billinfo_TertiaryGroupID`).val()
      );
      $(`#${gTcs_prefix}billinfo_TertiaryGroupID`).val(
        billinfo_TertiaryGroupID
      );

      const billinfo_FirstNameofInsured = helper.removeWhiteSpaceTrim($(`#${gTcs_prefix}billinfo_FirstNameofInsured`).val());
      $(`#${gTcs_prefix}billinfo_FirstNameofInsured`).val(billinfo_FirstNameofInsured);
      const billinfo_LastNameofInsured = helper.removeWhiteSpaceTrim($(`#${gTcs_prefix}billinfo_LastNameofInsured`).val());
      $(`#${gTcs_prefix}billinfo_LastNameofInsured`).val(billinfo_LastNameofInsured);


      const billinfo_RelationshipInsured = helper.removeWhiteSpaceTrim(
        $(`#${gTcs_prefix}billinfo_RelationshipInsured`).val()
      );
      $(`#${gTcs_prefix}billinfo_RelationshipInsured`).val(
        billinfo_RelationshipInsured
      );

      if (
        $(`#${gTcs_prefix}billinfo_PrivateInsurance`).is(":not(:checked)") &&
        $(`#${gTcs_prefix}billinfo_Medicare`).is(":not(:checked)") &&
        $(`#${gTcs_prefix}billinfo_PatientSelfPay`).is(":not(:checked)") &&
        $(`#${gTcs_prefix}billinfo_Medicaid`).is(":not(:checked)") &&
        $(`#${gTcs_prefix}billinfo_FreeTrial`).is(":not(:checked)") &&
        $(`#${gTcs_prefix}billinfo_OrderingFacility`).is(":not(:checked)")
      ) {
        message.warning(`Please select Billing Information.`);


        isGood = false;
      } else if (
        $(`#${gTcs_prefix}billinfo_PrivateInsurance`).is(":checked") ||
        $(`#${gTcs_prefix}billinfo_Medicare`).is(":checked") ||
        $(`#${gTcs_prefix}billinfo_Medicaid`).is(":checked")
      ) {
        if (billinfo_PrimaryInsuranceCarrier.length < 1) {
          message.warning(`Primary Insurance Carrier is required field.`);


          $(`#${gTcs_prefix}billinfo_PrimaryInsuranceCarrier`).focus();
          isGood = false;
        } else if (billinfo_MemberID.length < 1) {
          message.warning(
            `Primary Insurance Carrier Member Id is required field.`
          );


          $(`#${gTcs_prefix}billinfo_MemberID`).focus();
          isGood = false;
        } else if (billinfo_GroupID.length < 1) {
          message.warning(
            `Primary Insurance Carrier Group Id is required field.`
          );


          $(`#${gTcs_prefix}billinfo_GroupID`).focus();
          isGood = false;
        }
        else if (billinfo_FirstNameofInsured.length < 1) {
          message.warning(`First Name of Insured is required field.`);


          $(`#${gTcs_prefix}billinfo_FirstNameofInsured`).focus();

          isGood = false;
        }
        else if (billinfo_LastNameofInsured.length < 1) {
          message.warning(`Last Name of Insured is required field.`);


          $(`#${gTcs_prefix}billinfo_LastNameofInsured`).focus();

          isGood = false;
        }
        else if (billinfo_RelationshipInsured.length < 1) {
          message.warning(`Relationship to insured is required field.`);


          $(`#${gTcs_prefix}billinfo_RelationshipInsured`).focus();

          isGood = false;
        }
      } else {
        isGood = true;
      }

      return isGood;
    };

    //DiagnosisCodes_ICD10CodesCarrierForm
    const btnDiagICD10CodesGCSFormNextFunc = () => {
      // let isGood = false;

      // let elems = $(`#${gTcs_prefix}DiagICD10Container #gtcs_icd_codes`).find(':input');

      // for (let i = 0; i < elems.length; ++i) {

      //   let $elem = $(elems[i]);

      //   if ($elem.is(':checked')) {
      //     isGood = true;
      //     break;
      //   }

      // }

      // if (!isGood) {
      //   message.warning(`Please select Diagnostics and ICD10 Codes.`);
      //   
      // }

      // return isGood;

      //According to latest logic, it's not required anymore
      return true;
    };

    //KitShippingInfoCarrierForm
    const btnKitShippingInfoCarrierFormNextFunc = () => {
      let isGood = true;

      // const kitShip4_PatOthersInfo = helper.removeWhiteSpaceTrim($(`#${gTcs_prefix}kitShip_PatOthers`).val());
      // $(`#${gTcs_prefix}kitShip_PatOthers`).val(kitShip4_PatOthersInfo);

      // if ($(`${gTcs_prefix}kitShip_PatMobPhlebotomy`).is(":not(:checked)")
      //   && $(`${gTcs_prefix}kitShip_PatblooddrawQuest`).is(":not(:checked)")
      //   && $(`${gTcs_prefix}kitShip_PatOthers`).is(":not(:checked)")) {

      //   message.warning(`Please select Kit shipping options.`);
      //   

      //   isGood = false;

      // } else if ($(`${gTcs_prefix}kitShip_PatOthers`).is(":checked") && (kitShip4_PatOthersInfo.length < 1)) {

      //   message.warning(`Kit shipping other options is required field.`);
      //   
      //   $(`#${gTcs_prefix}kitShip_PatOthers`).focus();

      //   isGood = false;
      // }
      // else {
      //   isGood = true;
      // }
      return isGood;
    };

    //ASPiRAGENETIXCarrierScreeningForm
    $("#btnASPiRAGENETIXCarrierScreeningFormNext").off().on("click", function (
      event
    ) {
      event.preventDefault();

      if (btnAspiraGenetixCSFormNextFunc()) {
        $("div#PhysicianInformationCarrierForm").addClass(" show");
        $("div#ASPiRAGENETIXCarrierScreeningForm").removeClass(" show");
        $("#ASPiRAGENETIXCarrierScreeningFormCheckMark").removeClass("d-none");

        //go to top
        document.body.scrollTop = 260;
        document.documentElement.scrollTop = 260;
      }
    });

    //PhysicianInformationCarrierForm
    $(`#${gTcs_prefix}btnPhysicianInformationFormNext`)
      .off('click') // need - 9
      .on("click", function (
        event
      ) {
        event.preventDefault();

        if (btnPhysicianInfoFormNextFunc()) {
          $("div#PatientInformationCarrierForm").addClass(" show");
          $("div#PhysicianInformationCarrierForm").removeClass(" show");

          $("#PhysicianInformationCarrierFormCheckMark").removeClass("d-none");

          //go to top
          document.body.scrollTop = 306;
          document.documentElement.scrollTop = 306;
        }
      });

    //PatientInformationCarrierForm
    $(`#${gTcs_prefix}btnPatientInformationFormNext`).off().on("click", function (
      event
    ) {
      event.preventDefault();

      if (btnPatientInfoFormNextFunc()) {
        $("div#BillingInformationCarrierForm").addClass(" show");
        $("div#PatientInformationCarrierForm").removeClass(" show");
        $("#PatientInformationCarrierFormCheckMark").removeClass("d-none");

        //go to top
        document.body.scrollTop = 356;
        document.documentElement.scrollTop = 356;
      }
    });

    //BillingInformationCarrierForm
    $(`#${gTcs_prefix}btnBillingInformationFormNext`).off().on("click", function (
      event
    ) {
      event.preventDefault();

      if (billingInfoCarrierFormNextFunc()) {
        $("div#DiagnosisCodes_ICD10CodesCarrierForm").addClass(" show");
        $("div#BillingInformationCarrierForm").removeClass(" show");
        $("#BillingInformationCarrierFormCheckMark").removeClass("d-none");

        //go to top
        document.body.scrollTop = 406;
        document.documentElement.scrollTop = 406;
      }
    });

    //DiagnosisCodes_ICD10CodesCarrierForm
    $(`#${gTcs_prefix}btnDiagnosisCodes_ICD10CodesFormNext`).on(
      "click",
      function (event) {
        event.preventDefault();

        if (btnDiagICD10CodesGCSFormNextFunc()) {
          $("div#FamilyHistoryForm").addClass(" show");
          $("div#DiagnosisCodes_ICD10CodesCarrierForm").removeClass(" show");
          $("#DiagnosisCodes_ICD10CodesCarrierFormCheckMark").removeClass(
            "d-none"
          );

          //go to top
          document.body.scrollTop = 456;
          document.documentElement.scrollTop = 456;
        }
      }
    );

    //FamilyHistoryForm
    $("#btnFamilyHistoryFormNext").on("click", function (event) {
      event.preventDefault();

      $("div#KitShippingInfoCarrierForm").addClass(" show");
      $("div#FamilyHistoryForm").removeClass(" show");
      $("#FamilyHistoryFormCheckMark").removeClass("d-none");

      //go to top
      document.body.scrollTop = 506;
      document.documentElement.scrollTop = 506;
    });

    $(`#${gTcs_prefix}btnKitShippingInfoFormNext`).on("click", function (
      event
    ) {
      event.preventDefault();

      if (btnKitShippingInfoCarrierFormNextFunc()) {
        $("div#NotesCertifyCarrierForm").addClass(" show");
        $("div#KitShippingInfoCarrierForm").removeClass(" show");

        $("#KitShippingInfoCarrierFormCheckMark").removeClass("d-none");

        //go to top
        document.body.scrollTop = 556;
        document.documentElement.scrollTop = 556;
      }
    });

    //final form submit
    $(`#${gTcs_prefix}btnOrderSubmit`)
      .off("click")
      .on("click", async function () {
        var $this = $(this);
        $this.html($this.data("loading-text"));

        if (!btnAspiraGenetixCSFormNextFunc()) {
          $("div#ASPiRAGENETIXCarrierScreeningForm").addClass(" show");
          $("div#NotesCertifyCarrierForm").removeClass(" show");

          //go to top
          document.body.scrollTop = 206;
          document.documentElement.scrollTop = 206;
        } else if (!btnPhysicianInfoFormNextFunc()) {
          $("div#PhysicianInformationCarrierForm").addClass(" show");
          $("div#NotesCertifyCarrierForm").removeClass(" show");

          //go to top
          document.body.scrollTop = 260;
          document.documentElement.scrollTop = 260;
        } else if (!btnPatientInfoFormNextFunc()) {
          $("div#PatientInformationCarrierForm").addClass(" show");
          $("div#NotesCertifyCarrierForm").removeClass(" show");
          //go to top
          document.body.scrollTop = 306;
          document.documentElement.scrollTop = 306;
        } else if (!billingInfoCarrierFormNextFunc()) {
          $("div#BillingInformationCarrierForm").addClass(" show");
          $("div#NotesCertifyCarrierForm").removeClass(" show");
          //go to top
          document.body.scrollTop = 356;
          document.documentElement.scrollTop = 356;
        } else if (!btnDiagICD10CodesGCSFormNextFunc()) {
          $("div#DiagnosisCodes_ICD10CodesCarrierForm").addClass(" show");
          $("div#NotesCertifyCarrierForm").removeClass(" show");
          //go to top
          document.body.scrollTop = 406;
          document.documentElement.scrollTop = 406;
        } else if (!btnKitShippingInfoCarrierFormNextFunc()) {
          $("div#KitShippingInfoCarrierForm").addClass(" show");
          $("div#NotesCertifyCarrierForm").removeClass(" show");
          //go to top
          document.body.scrollTop = 456;
          document.documentElement.scrollTop = 456;
        }

        if (
          $("#kitShipCarr_healthcareProvider").is(":not(:checked)") &&
          $("#kitShipCarr_employeehealthcareProvider").is(":not(:checked)") &&
          $("#kitShipCarr_partnerhealthcareProvider").is(":not(:checked)")
        ) {
          message.warning(`Please select certify options.`);


          //go to top
          document.body.scrollTop = 506;
          document.documentElement.scrollTop = 506;
        } else {
          // Notes & Certify Validation

          //ASPiRA GENETIX Carrier Screening Form data

          $(`#${gTcs_prefix}btnOrderSubmit`).prop("disabled", true);
          const aSPiRAGENETIXCarrierScreening = [];

          sections = $(`#ASPiRAGENETIXCarrierScreeningForm #printHere_CS`).find(
            ":input"
          );
          sections.map((input, i) => {
            const $input = $(sections[input]);
            console.log(
              "$input.attr('id'): ",
              $input.attr("id"),
              ", $input.attr('type'): ",
              $input.attr("type")
            );

            const _type = $input.attr("type");

            if (_type == "radio") {
              if ($input.is(":checked")) {
                aSPiRAGENETIXCarrierScreening.push({
                  code: $input.data("val"),
                  qBenchId: $input.data("qbenchid"),
                });

                //aSPiRAGENETIXCarrierScreening.push($input.data('val'));
              }
            }
          });

          const indicationsfortesting = [];

          if ($("#IndicationsTest_PatientScreening").is(":checked")) {
            indicationsfortesting.push(
              $("#IndicationsTest_PatientScreening").data("val")
            );
          }
          if ($("#IndicationsTest_Pregnancy").is(":checked")) {
            indicationsfortesting.push(
              $("#IndicationsTest_Pregnancy").data("val")
            );
          }
          if ($("#IndicationsTest_Infertility").is(":checked")) {
            indicationsfortesting.push(
              $("#IndicationsTest_Infertility").data("val")
            );
          }
          if ($("#IndicationsTest_other").is(":checked")) {
            indicationsfortesting.push($("#IndicationsTest_other").data("val"));
          }

          if ($("#IndicationsTest_PartnerScreening").is(":checked")) {
            indicationsfortesting.push(
              $("#IndicationsTest_PartnerScreening").data("val")
            );
          }
          if ($("#IndicationsTest_FamilyHistory").is(":checked")) {
            indicationsfortesting.push(
              $("#IndicationsTest_FamilyHistory").data("val")
            );
          }
          if ($("#IndicationsTest_EggSperm_Donor").is(":checked")) {
            indicationsfortesting.push(
              $("#IndicationsTest_EggSperm_Donor").data("val")
            );
          }

          const duedate_pregnant = $("#duedate_pregnant_hidden").val();
          const ultrasound_findings = $("#ultrasound_findings").val();

          let aSPiRAGENETIXCarrierScreening_data = {
            genetixCSPanel: aSPiRAGENETIXCarrierScreening,
            indicationsfortesting: {
              testing: indicationsfortesting,
              other: $("#IndicationsTest_otherValue").val(),
            },
            duedate_pregnant: duedate_pregnant,
            ultrasound_findings: ultrasound_findings,
          };

          //ASPiRA GENETIX Carrier Screening Form data end

          //Physician Information Carrier Form

          //phyinfo_account_number
          let phyinfo_ordered_before = "";
          let _selectedNPI = "";
          let _selectedClientId = "";
          let physician = {};
          let legacyCustomerQbenchId = "";

          let $ordered_beforeYes = $(`#${gTcs_prefix}ordered_before1`);
          if (
            typeof $ordered_beforeYes !== "undefined" &&
            $ordered_beforeYes &&
            $ordered_beforeYes.is(":checked")
          ) {
            let physicianRet = await helper.createPhysicianFromClientId(
              selectedPhysicianLegacyInfo
            );

            physician = physicianRet.physician;
            legacyCustomerQbenchId = physicianRet.legacyCustomerQbenchId;
          } else {
            const phyinfo_phyinfo_phone =
              $(`#${gTcs_prefix}phyinfo_phone_ph_areacode`).val() +
              " " +
              $(`#${gTcs_prefix}phyinfo_phone_ph_prefix`).val() +
              $(`#${gTcs_prefix}phyinfo_phone_ph_suffix`).val();

            const phyinfo_Organization_account = $(
              "#gtcs_phyinfo_Organization_account"
            ).val();
            const phyinfo_first_name = $("#gtcs_phyinfo_first_name").val();
            const phyinfo_last_name = $("#gtcs_phyinfo_last_name").val();
            const phyinfo_npi = $("#gtcs_phyinfo_npi").val();
            const phyinfo_email = $("#gtcs_phyinfo_email").val();

            const phyinfo_address = $("#gtcs_phyinfoC_address").val();
            const phyinfo_city = $("#gtcs_phyinfoC_city").val();
            const phyinfo_state = physicianState;
            const phyinfo_zipCode = $("#gtcs_phyinfoC_zipCode").val();
            const phyinfo_phone =
              $("#gtcs_phyinfoC_ph_areacode").val() +
              " " +
              $("#gtcs_phyinfoC_ph_prefix").val() +
              $("#gtcs_phyinfoC_ph_suffix").val();
            const phyinfo_fax = $("#gtcs_phyinfoC_fax").val();
            const phyinfo_Faxcopyto = $("#gtcs_phyinfoC_Faxcopyto").val();
            const phyinfo_confirmation = $("#gtcs_phyinfoC_confirmation").val();

            const phyinfoC_practiceLocation = $(
              "#gtcs_phyinfoC_practiceLocation"
            ).val();

            const addresses = [];
            const practiceLocation = [];

            const licenses = [];

            console.log("physicianAddresses: ", physicianAddresses);
            // if (typeof physicianAddresses == "undefined" || physicianAddresses.length < 1) {
            //   return;
            // }

            let data = {
              organizationAccount: phyinfo_Organization_account,
              firstName: phyinfo_first_name,
              lastName: phyinfo_last_name,
              npiNumber: phyinfo_npi,
              email: phyinfo_email,
              addresses: [],
              address_type: "Primary",
              practiceLocation: {},
              licenses: licenses,
              registeredUser: "",
              phoneNo: phyinfo_phyinfo_phone,
            };
            if (physicianAddresses && physicianAddresses.length < 1) {
              addresses.push({
                locationName: "USA",
                address_1: phyinfo_address,
                address_2: "",
                addressType: "Primary",
                city: phyinfo_city,
                country: "USA",
                state: phyinfo_state,
                zipCode: phyinfo_zipCode,
                phoneNo: phyinfo_phone,
                fax: phyinfo_fax,
                fax_copyto: phyinfo_Faxcopyto,
              });

              data.addresses = addresses;
              data.practiceLocationPlain = phyinfoC_practiceLocation;
            } else {
              physicianAddresses.map((a) => {
                addresses.push({
                  locationName: "USA",
                  address_1: a.address_1,
                  address_2: a.address_1,
                  addressType: a.address_type,
                  city: a.city,
                  country: a.country_name,
                  state: a.state,
                  zipCode: a.postal_code,
                  phoneNo: a.telephone_number,
                });
              });

              data.addresses = addresses;
              data.practiceLocationPlain = phyinfoC_practiceLocation;
            }

            // console.log("physicianAddresses: ", physicianAddresses);
            console.log("data: ", data);

            let physicianRet = await createPhysicianApi(data);
            console.log("else->physicianRet: ", physicianRet);
            physician = physicianRet.physician;
            legacyCustomerQbenchId = physicianRet.legacyCustomerQbenchId;
          }
          //Physician Information Carrier Form end
          console.log("physician itself: ", physician);
          if (physician.isok) {
            $this.html("Done");
            $(`#${gTcs_prefix}btnOrderSubmit`).prop("disabled", true);
            message.success(`Physician Created Successfully. Creating Patient`);

            //Patient Information Carrier Form

            const patinfo_firstname = $("#gtcs_patinfo_firstname").val();
            const patinfo_lastname = $("#gtcs_patinfo_lastname").val();
            const patinfo_address1 = $("#gtcs_patinfo_address1").val();
            const patinfo_address2 = $("#gtcs_patinfo_address2").val();
            const patinfo_city = $("#gtcs_patinfo_city").val();
            const genetixCS_patinfoC_state = patientState;
            const patinfo_zipcode = $("#gtcs_patinfo_zipcode").val();
            const patinfo_DOB = patientDOBLocal;
            const patinfo_PhoneNumber =
              $("#gtcs_patinfo_ph_areacode").val() +
              $("#gtcs_patinfo_ph_prefix").val() +
              $("#gtcs_patinfo_ph_suffix").val();
            const patinfoC_emailaddress = $("#gtcs_patinfo_emailaddress").val();

            const ethnicity = [];

            if ($("#gtcs_patinfo_Caucasian").is(":checked")) {
              ethnicity.push($("#gtcs_patinfo_Caucasian").data("val"));
            }
            if ($("#gtcs_patinfo_AshkenaziJewish").is(":checked")) {
              ethnicity.push($("#gtcs_patinfo_AshkenaziJewish").data("val"));
            }
            if ($("#gtcs_patinfo_SephardicJewish").is(":checked")) {
              ethnicity.push($("#gtcs_patinfo_SephardicJewish").data("val"));
            }
            if ($("#gtcs_patinfo_Asian").is(":checked")) {
              ethnicity.push($("#gtcs_patinfo_Asian").data("val"));
            }
            if ($("#gtcs_patinfo_HIspanic").is(":checked")) {
              ethnicity.push($("#gtcs_patinfo_HIspanic").data("val"));
            }
            if ($("#gtcs_patinfo_NativeAmerican").is(":checked")) {
              ethnicity.push($("#gtcs_patinfo_NativeAmerican").data("val"));
            }
            if ($("#gtcs_patinfo_AfricanAmerican").is(":checked")) {
              ethnicity.push($("#gtcs_patinfo_AfricanAmerican").data("val"));
            }
            if ($("#gtcs_patinfo_na").is(":checked")) {
              ethnicity.push($("#gtcs_patinfo_na").data("val"));
            }
            if ($("#gtcs_patinfo_Other").is(":checked")) {
              ethnicity.push($("#gtcs_patinfo_Other").data("val"));
              ethnicity.push($("#gtcs_patinfo_OtherEthnicitycity").val());
            }

            if (ethnicity.length == 0) {
              // array empty or does not exist
              ethnicity.push("N/A");
            }

            let gender = "";

            if ($(`#${gTcs_prefix}patinfo_gender`).is(":checked")) {
              gender = "male";
            } else {
              gender = "female";
            }


            let data = {
              email: patinfoC_emailaddress,
              firstName: patinfo_firstname,
              lastName: patinfo_lastname,
              address: {
                locationName: "USA",
                address_1: patinfo_address1,
                address_2: patinfo_address2,
                addressType: "",
                city: patinfo_city,
                country: "USA",
                state: genetixCS_patinfoC_state,
                zipCode: patinfo_zipcode,
              },
              dob: patinfo_DOB,
              phoneNo: patinfo_PhoneNumber,

              ethnicity: ethnicity.join(","),
              registeredUser: "",
              gender: gender,
            };

            console.log("Before Submit data: ", data);

            $this.html(
              "<img style='height: 30px;' src='/assets/images/Spinner-1s-200px.svg' /> Creating Patient"
            );
            $(`#${gTcs_prefix}btnOrderSubmit`).prop("disabled", true);

            await helper.sleep(2000);
            let patient = await createPatientApi(data);
            console.log("patient: ", patient);

            //Patient Information Carrier Form end

            if (patient.isok) {
              //Billing Information Carrier Form
              let billing_info = {
                primaryInsurance: {},
                secondaryInsurance: {},
                tertiaryInsurance: {},
                insuredInfo: {
                  nameOfInsured: {
                    firstName: "",
                    lastName: "",
                  },
                  dob: "",
                  relationshipInsured: "",
                },
              };
              var sections = $(
                "div#BillingInformationCarrierForm #billing_info_container"
              ).find(":input");

              sections.map((input, i) => {
                const $input = $(sections[input]);
                console.log(
                  "$input.attr('id'): ",
                  $input.attr("id"),
                  ", $input.attr('type'): ",
                  $input.attr("type")
                );
                console.log("$input: ", $input);

                let _id = $input.attr("id");

                if (typeof _id == "undefined" || _id.length < 1) {
                  _id = `${gTcs_prefix}billinfo_DOB`;
                }
                const _type = $input.attr("type");
                let obj_id = _id.replace(`${gTcs_prefix}billinfo_`, "");
                obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1);
                if (_type == "checkbox") {
                  if ($input.is(":checked")) {
                    billing_info[obj_id] = true;
                  } else {
                    billing_info[obj_id] = false;
                  }
                } else {

                  billing_info[obj_id] = $input.val();
                }
              });

              sections = $(
                "div#BillingInformationCarrierForm #primary_insurance_container"
              ).find(":input");
              sections.map((input, i) => {
                const $input = $(sections[input]);
                console.log(
                  "$input.attr('id'): ",
                  $input.attr("id"),
                  ", $input.attr('type'): ",
                  $input.attr("type")
                );
                console.log("$input: ", $input);

                let _id = $input.attr("id");

                if (typeof _id == "undefined" || _id.length < 1) {
                  _id = `${gTcs_prefix}billinfo_DOB`;
                }
                const _type = $input.attr("type");
                let obj_id = _id.replace(`${gTcs_prefix}billinfo_`, "");
                obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1);
                if (_type == "checkbox") {
                  if ($input.is(":checked")) {
                    billing_info["primaryInsurance"][obj_id] = true;
                  } else {
                    billing_info["primaryInsurance"][obj_id] = false;
                  }
                } else {
                  billing_info["primaryInsurance"][obj_id] = $input.val();
                }
              });

              sections = $(
                "div#BillingInformationCarrierForm #secondary_insurance_container"
              ).find(":input");
              sections.map((input, i) => {
                const $input = $(sections[input]);
                console.log(
                  "$input.attr('id'): ",
                  $input.attr("id"),
                  ", $input.attr('type'): ",
                  $input.attr("type")
                );
                console.log("$input: ", $input);

                let _id = $input.attr("id");

                if (typeof _id == "undefined" || _id.length < 1) {
                  _id = `${gTcs_prefix}billinfo_DOB`;
                }
                const _type = $input.attr("type");
                let obj_id = _id.replace(`${gTcs_prefix}billinfo_`, "");
                obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1);
                if (_type == "checkbox") {
                  if ($input.is(":checked")) {
                    billing_info["secondaryInsurance"][obj_id] = true;
                  } else {
                    billing_info["secondaryInsurance"][obj_id] = false;
                  }
                } else {
                  billing_info["secondaryInsurance"][obj_id] = $input.val();
                }
              });

              sections = $(
                "div#BillingInformationCarrierForm #tertiary_insurance_container"
              ).find(":input");
              sections.map((input, i) => {
                const $input = $(sections[input]);
                console.log(
                  "$input.attr('id'): ",
                  $input.attr("id"),
                  ", $input.attr('type'): ",
                  $input.attr("type")
                );
                console.log("$input: ", $input);

                let _id = $input.attr("id");

                if (typeof _id == "undefined" || _id.length < 1) {
                  _id = `${gTcs_prefix}billinfo_DOB`;
                }
                const _type = $input.attr("type");
                let obj_id = _id.replace(`${gTcs_prefix}billinfo_`, "");
                obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1);
                if (_type == "checkbox") {
                  if ($input.is(":checked")) {
                    billing_info["tertiaryInsurance"][obj_id] = true;
                  } else {
                    billing_info["tertiaryInsurance"][obj_id] = false;
                  }
                } else {
                  billing_info["tertiaryInsurance"][obj_id] = $input.val();
                }
              });

              billing_info["insuredInfo"]["nameOfInsured"]["firstName"] =
                $(`#${gTcs_prefix}billinfo_FirstNameofInsured`).val();
              billing_info["insuredInfo"]["nameOfInsured"]["lastName"] =
                $(`#${gTcs_prefix}billinfo_LastNameofInsured`).val();

              // sections = $(
              //   "div#BillingInformationCarrierForm #insured_info"
              // ).find(":input");
              // sections.map((input, i) => {
              //   const $input = $(sections[input]);
              //   console.log(
              //     "$input.attr('id'): ",
              //     $input.attr("id"),
              //     ", $input.attr('type'): ",
              //     $input.attr("type")
              //   );
              //   console.log("$input: ", $input);

              //   let _id = $input.attr("id");

              //   if (typeof _id == "undefined" || _id.length < 1) {
              //     _id = `${gTcs_prefix}billinfo_DOB`;
              //   }
              //   const _type = $input.attr("type");
              //   let obj_id = _id.replace(`${gTcs_prefix}billinfo_`, "");
              //   obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1);
              //   if (_type == "checkbox") {
              //     if ($input.is(":checked")) {
              //       billing_info["insuredInfo"][obj_id] = true;
              //     } else {
              //       billing_info["insuredInfo"][obj_id] = false;
              //     }
              //   } else {
              //     billing_info["insuredInfo"][obj_id] = $input.val();
              //   }
              // });

              billing_info['insuredInfo']["dob"] = billinfoC_dob;
              billing_info['insuredInfo']["relationshipInsured"] = $(`#${gTcs_prefix}billinfo_RelationshipInsured`).val();

              console.log("billing_info: ", billing_info);
              //Billing Information Carrier Form end

              let qnA = {
                questionCode: "",
                answer: "",
                subQuestion: [],
              };

              //Clicncal Info
              let clinicalData = [];

              //Diagnosis Codes | ICD-10 Codes
              const icd10Codes = [];
              const icd10CodesOther = [];
              let icd10Code = {
                codes: [],
                otherCodes: [],
              };

              sections = $("div#DiagnosisCodes_ICD10CodesCarrierForm").find(
                ":input"
              );
              sections.map((input, i) => {
                const $input = $(sections[input]);
                console.log(
                  "$input.attr('id'): ",
                  $input.attr("id"),
                  ", $input.attr('type'): ",
                  $input.attr("type")
                );

                // let obj_id = $input.attr('id');

                const _type = $input.attr("type");
                // obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)

                if (_type == "checkbox") {
                  if ($input.is(":checked")) {
                    icd10Codes.push($input.data("val"));
                  }
                } else if (_type == "text") {
                  icd10CodesOther.push($input.val());
                }
              });

              icd10Code = {
                codes: icd10Codes,
                otherCodes: icd10CodesOther,
              };

              //Diagnosis Codes | ICD-10 Codes end

              let ova1PlusBiomarkers = {
                GCP: [],
                MCP: [],
                other: "",
              };

              //Family History Form
              let familyHistories = [];
              let i = 0;
              $("div.duplicator").each(() => {
                let familyHistory = {
                  familyMemberName: "",
                  relationToPatient: "",
                  sex: "",
                  diagnosis: "",
                  ageOfOnset: "",
                  dateOfBirth: "",
                };

                let famHist_GenSex = "";
                sections = $("div#famHist_GenSex_container_" + i).find(
                  ":input"
                );
                sections.map((input, i) => {
                  const $input = $(sections[input]);

                  const _type = $input.attr("type");

                  if (_type == "radio") {
                    if ($input.is(":checked")) {
                      famHist_GenSex = $input.data("val");
                    }
                  }
                });

                // familyMemberName: String,
                //   relationToPatient: String,
                //     sex: String,
                //       diagnosis: String,
                //         ageOfOnset: Number,
                //           dateOfBirth: Date
                familyHistory = {
                  familyMemberName: $(`#duplicator_${i}`)
                    .find(`#famHist_familyMemName1_${i}`)
                    .val(),
                  relationToPatient: $(`#duplicator_${i}`)
                    .find(`#famHist_RelationPatient1_${i}`)
                    .val(),
                  sex: famHist_GenSex,
                  diagnosis: $(`#duplicator_${i}`)
                    .find(`#famHist_DiagSymptoms1_${i}`)
                    .val(),
                  ageOfOnset: $(`#duplicator_${i}`)
                    .find(`#famHist_AgeofOnset1_${i}`)
                    .val(),
                  dateOfBirth: $(`#duplicator_${i}`)
                    .find(`#FamilyHistoryForm_dob_${i}`)
                    .val(),
                };
                familyHistories.push(familyHistory);

                ++i;
              });

              console.log("familyHistories: ", familyHistories);

              clinicalData = familyHistories;
              //Family History Form end

              //Kit Shipping Info
              let kitInformation = {
                drawType: [],
                otherInfo: "",
              };

              // sections = $("div#KitShippingInfoCarrierForm #draw_type").find(":input");
              // sections.map((input, i) => {
              //   const $input = $(sections[input]);
              //   console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));

              //   let obj_id = $input.attr('id');

              //   const _type = $input.attr('type');
              //   // obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)

              //   if (_type == 'checkbox') {
              //     if ($input.is(':checked')) {
              //       if (obj_id == `${gTcs_prefix}kitShip_PatOthersInfo`) {
              //         kitInformation.otherInfo = $(`#${gTcs_prefix}kitShip_PatOthersInfo`).val();
              //       }
              //       else {
              //         kitInformation.drawType.push($input.data('val'));
              //       }
              //     }
              //   }
              // });

              // kitInformation.otherInfo = $(`#${gTcs_prefix}kitShip_PatOthersInfo`).val();

              //Kit Shipping Info end

              let certifytypes = [];

              sections = $(
                `#NotesCertifyCarrierForm #${gTcs_prefix}certifytype`
              ).find(":input");
              sections.map((i, input) => {
                const $input = $(input);
                console.log(
                  "$input.attr('id'): ",
                  $input.attr("id"),
                  ", $input.attr('type'): ",
                  $input.attr("type")
                );

                let obj_id = $input.attr("id");

                const _type = $input.attr("type");
                // obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)

                if (_type == "radio") {
                  if ($input.is(":checked")) {
                    certifytypes.push($input.data("val"));
                  }
                }
              });

              let notes = $(
                `#NotesCertifyCarrierForm #${gTcs_prefix}kitShip_notes`
              ).val();
              var tests = [
                {
                  testName: "GenetiXCarrierScreening",
                  testCode: "GenetiXCarrierScreening",
                  icd10Code: icd10Code,
                  kitInformation: kitInformation,
                  notes: notes,
                  ova1PlusBiomarkers: ova1PlusBiomarkers,
                  clinicalData: clinicalData,

                  genetixCSPanel:
                    aSPiRAGENETIXCarrierScreening_data.genetixCSPanel,
                  genetixCSIndicationOfTesting:
                    aSPiRAGENETIXCarrierScreening_data.indicationsfortesting,
                  dueDate: aSPiRAGENETIXCarrierScreening_data.duedate_pregnant,
                  ultrasoundsFindings:
                    aSPiRAGENETIXCarrierScreening_data.ultrasound_findings,
                },
              ];

              const covid_checked = $("#product_4").is(":checked");
              if (covid_checked) {
                const icd10CodesCOVD = [];
                let icd10CodeCOVD = {
                  codes: [],
                  otherCodes: "",
                };

                sections = $(
                  "#DiagnosisCodes_ICD10CodesCarrierForm #covid_icd_codes"
                ).find(":input");
                sections.map((i, input) => {
                  const $input = $(input);
                  console.log(
                    "$input.attr('id'): ",
                    $input.attr("id"),
                    ", $input.attr('type'): ",
                    $input.attr("type")
                  );

                  // let obj_id = $input.attr('id');

                  const _type = $input.attr("type");
                  // obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)

                  if (_type == "checkbox") {
                    if ($input.is(":checked")) {
                      icd10CodesCOVD.push($input.data("val"));
                    }
                  }
                });

                icd10CodeCOVD = {
                  codes: icd10CodesCOVD,
                  otherCodes: "",
                };

                var covid_tests = {
                  testName: "COVD",
                  testCode: "COVD",
                  icd10Code: icd10CodeCOVD,
                };

                tests.push(covid_tests);
              }

              console.log("sigPad: ", sigPad);
              let dataString = "";
              if (sigPad) {
                if (
                  typeof sigPad.current !== "undefined" &&
                  sigPad.current == null
                ) {
                } else {
                  dataString = sigPad.getTrimmedCanvas().toDataURL("image/png");
                }
              }

              console.log("physician before submitting to place order: ", physician);
              if (typeof patient.patient !== "undefined" && patient.patient) {
                patient = patient.patient;
              }
              if (typeof physician.physician !== "undefined" && physician.physician) {
                physician = physician.physician;
              }

              // get the user response related to user's consent about the use of his/her data for research purpose
              const researchConsent = $(`#${gTcs_prefix}researchConsent`).is(":checked") ? "Yes" : "No";
              const researchUseOnly = $(`#${gTcs_prefix}researchUseOnly`).is(":checked") ? "Yes" : "No";

              // check if the order is a test order
              const isTestOrder = helper.isAccessingFromAspiraLab() ? $(`#${gTcs_prefix}testOrder`).is(":checked") : false;

              data = {
                orderId: (Math.floor(100000 + Math.random() * 900000)).toString(),
                patientId: patient._id.toString(),
                physicianId: physician._id.toString(),
                billingInfo: billing_info,
                tests: tests,
                certifyTypes: certifytypes,
                physicianSignature: dataString,
                qbenchCustomerId: legacyCustomerQbenchId,
                legacyClientId: selectedPhysicianLegacyInfo ? selectedPhysicianLegacyInfo.selectedClientId : "",
                researchConsent,
                researchUseOnly,
                isTestOrder
              };

              await helper.sleep(2000);
              let ret = await createOva1OrderApi(data);
              if (ret.isok) {
                $this.html("Done");
                $(`#${gTcs_prefix}btnOrderSubmit`).prop("disabled", false);
                message.success(`GtCS Order Created Successfully.`);

                //need - 7
                // localStorage.removeItem('selectedPhysicianLegacyInfo');

                if (process.env.REACT_APP_ENV == "DEV") {
                  console.log("intentionally not redirecting to pdf page");
                } else {
                  setTimeout(() => {


                    window.location.href = ret.redirectUrl;
                  }, 500);
                }
              }

            } //end of patient.isok
          } //end of physician.isok
        } //end of notes and certify
      });

    //final form submit end
  });

  const onChangePhysicianStateLocal = (_state) => {
    setPhysicianState(_state);
  };

  const patientDOBFunc = (_dob) => {
    setPatientDOBLocal(_dob);
  };

  const onChangeBillingInfoDOBLocal = (_date) => {
    setBillInfoCDOB(_date);
  };

  const selectedPhysicianLegacyInfoFunc = (selectedPhysician) => { //need - 2
    console.log("selectedPhysicianLegacyInfoFunc->selectedPhysician: ", selectedPhysician);
    setSelectedPhysicianLegacyInfoState(selectedPhysician);
  }
  return (
    <>
      <input type="hidden" id="patinfoC_dob_hidden" value="" />
      <input type="hidden" id="billinfoC_dob_hidden" value="" />

      <div class="body">
        <div class="row clearfix">
          <div class="col-md-12 col-lg-12">
            <div
              class="panel-group"
              id="accordion_10"
              role="tablist"
              aria-multiselectable="true"
            >
              <Toast
                toastList={toastList}
                position={"top-center"}
                autoDelete={true}
                dismissTime={3000}
                description={toastMessage}
              />
              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingTen_10">
                  <h4 class="panel-title">
                    <a
                      class="collapsed"
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion_10"
                      href="#ASPiRAGENETIXCarrierScreeningForm"
                      aria-expanded="false"
                      aria-controls="collapseTwo_10"
                    >
                      ASPiRA GENETIX Carrier Screening{" "}
                      <span
                        id="ASPiRAGENETIXCarrierScreeningFormCheckMark"
                        className="d-none"
                      >
                        &#10004;
                      </span>
                    </a>
                  </h4>
                </div>
                <div
                  id="ASPiRAGENETIXCarrierScreeningForm"
                  class="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingTen_10"
                >
                  <ASPiRAGENETIXCarrierScreeningForm />
                </div>
              </div>
              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingEleven_10">
                  <h4 class="panel-title">
                    <a
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion_10"
                      href="#PhysicianInformationCarrierForm"
                      aria-expanded="true"
                      aria-controls="collapseOne_10"
                    >
                      Physician Information{" "}
                      <span
                        id="PhysicianInformationCarrierFormCheckMark"
                        className="d-none"
                      >
                        &#10004;
                      </span>
                    </a>
                  </h4>
                </div>
                <div
                  id="PhysicianInformationCarrierForm"
                  class="panel-collapse collapse in"
                  role="tabpanel"
                  aria-labelledby="headingEleven_10"
                >
                  {/* <PhysicianInformationCarrierForm prefix={`genetixCS_`} /> */}
                  <PhysicianInformationForm
                    prefix={gTcs_prefix}
                    onChangePhysicianState={onChangePhysicianStateLocal}
                    selectedPhysicianLegacyInfo={selectedPhysicianLegacyInfoFunc} //need - 3
                  />
                </div>
              </div>
              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingTwelve_10">
                  <h4 class="panel-title">
                    <a
                      class="collapsed"
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion_10"
                      href="#PatientInformationCarrierForm"
                      aria-expanded="false"
                      aria-controls="collapseTwo_10"
                    >
                      Patient Information{" "}
                      <span
                        id="PatientInformationCarrierFormCheckMark"
                        className="d-none"
                      >
                        &#10004;
                      </span>
                    </a>
                  </h4>
                </div>
                <div
                  id="PatientInformationCarrierForm"
                  class="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingTwelve_10"
                >
                  {/* <PatientInformationCarrierForm onChangeState={onChangeState} /> */}
                  <PatientInformationForm
                    prefix={`${gTcs_prefix}`}
                    phoneNoPrefix={`${patientPhoneNo_prefix}`}
                    dobFunc={patientDOBFunc}
                    onChangeState={onChangeState}
                  />
                </div>
              </div>
              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingThirteen_10">
                  <h4 class="panel-title">
                    <a
                      class="collapsed"
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion_10"
                      href="#BillingInformationCarrierForm"
                      aria-expanded="false"
                      aria-controls="collapseTwo_10"
                    >
                      Billing Information{" "}
                      <span
                        id="BillingInformationCarrierFormCheckMark"
                        className="d-none"
                      >
                        &#10004;
                      </span>
                    </a>
                  </h4>
                </div>
                <div
                  id="BillingInformationCarrierForm"
                  class="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingThirteen_10"
                >
                  <BillingInformationForm prefix={`${gTcs_prefix}`} billinfo_dobFunc={onChangeBillingInfoDOBLocal} />
                </div>
              </div>
              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingFourteen_10">
                  <h4 class="panel-title">
                    <a
                      class="collapsed"
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion_10"
                      href="#DiagnosisCodes_ICD10CodesCarrierForm"
                      aria-expanded="false"
                      aria-controls="collapseTwo_10"
                    >
                      Diagnosis Codes | ICD-10 Codes{" "}
                      <span
                        id="DiagnosisCodes_ICD10CodesCarrierFormCheckMark"
                        className="d-none"
                      >
                        &#10004;
                      </span>
                    </a>
                  </h4>
                </div>
                <div
                  id="DiagnosisCodes_ICD10CodesCarrierForm"
                  class="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingFourteen_10"
                >
                  <DiagnosisCodes_ICD10CodesForm prefix={`${gTcs_prefix}`} />
                </div>
              </div>

              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingFifteen_10">
                  <h4 class="panel-title">
                    <a
                      class="collapsed"
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion_10"
                      href="#FamilyHistoryForm"
                      aria-expanded="false"
                      aria-controls="collapseTwo_10"
                    >
                      Family History{" "}
                      <span id="FamilyHistoryFormCheckMark" className="d-none">
                        &#10004;
                      </span>
                    </a>
                  </h4>
                </div>
                <div
                  id="FamilyHistoryForm"
                  class="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingFifteen_10"
                >
                  <FamilyHistoryForm />
                </div>
              </div>

              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingSixteen_10">
                  <h4 class="panel-title">
                    <a
                      class="collapsed"
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion_10"
                      href="#KitShippingInfoCarrierForm"
                      aria-expanded="false"
                      aria-controls="collapseTwo_10"
                    >
                      Kit Shipping Information{" "}
                      <span
                        id="KitShippingInfoCarrierFormCheckMark"
                        className="d-none"
                      >
                        &#10004;
                      </span>
                    </a>
                  </h4>
                </div>
                <div
                  id="KitShippingInfoCarrierForm"
                  class="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingSixteen_10"
                >
                  <KitShippingInfoForm prefix={gTcs_prefix} />
                </div>
              </div>

              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingSeventeen_10">
                  <h4 class="panel-title">
                    <a
                      class="collapsed"
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion_10"
                      href="#NotesCertifyCarrierForm"
                      aria-expanded="false"
                      aria-controls="collapseTwo_10"
                    >
                      Notes & Certify
                    </a>
                  </h4>
                </div>
                <div
                  id="NotesCertifyCarrierForm"
                  class="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingSeventeen_10"
                >
                  <NotesCertifyForm
                    prefix={`${gTcs_prefix}`}
                    sigCollect={(_sigPad) => {
                      sigPad = _sigPad;
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GenetiXCarrierScreeningForm;
