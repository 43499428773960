import React, { PureComponent } from "react";

import PropTypes from 'prop-types';
import $ from 'jquery';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import helper from './../utils/helperPortal';

export default class ClinicalInformationForm extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            clinicinfo_DoLastMentrualPeriod: null,
        }

    }

    onChangeDoLastMentrualPeriod = date => {
        this.setState({ clinicinfo_DoLastMentrualPeriod: date });

        console.log("onChangeDoLastMentrualPeriod->date.toString(): ", date.toString());

        let dateStr = moment(date).format('YYYY-MM-DD');

        $(`#${this.props.prefix}clinicinfo_DoLastMentrualPeriod_hidden`).val(dateStr);

        if (this.props.onChangeLastMentrualPeriodDate) {
            this.props.onChangeLastMentrualPeriodDate(dateStr);
        }
    };

    render() {

        const ShowPatienAbdomenPainAreaClick = () => {

            var clinicinfo_pain_abdomen_pelvis = document.getElementById(`${this.props.prefix}clinicinfo_pain_abdomen_pelvis`);

            if (clinicinfo_pain_abdomen_pelvis.checked == true) {
                $(`#${this.props.prefix}PatienAbdomenPainArea`).addClass(" showPatienAbdomenPainArea");
                $(`#${this.props.prefix}PatienAbdomenPainArea`).removeClass(" hidePatienAbdomenPainArea");
            }
        };

        const HidePatienAbdomenPainAreaClick = () => {

            var clinicinfo_pain_abdomen_pelvis2 = document.getElementById(`${this.props.prefix}clinicinfo_pain_abdomen_pelvis2`);

            if (clinicinfo_pain_abdomen_pelvis2.checked == true) {
                $(`#${this.props.prefix}PatienAbdomenPainArea`).removeClass(" showPatienAbdomenPainArea");
                $(`#${this.props.prefix}PatienAbdomenPainArea`).addClass(" hidePatienAbdomenPainArea");
            }
        };

        const ShowPatienfullQuicklyUnableToeAreaClick = () => {

            var clinicinfo_fullQuickly_unableToe = document.getElementById(`${this.props.prefix}clinicinfo_fullQuickly_unableToe`);

            if (clinicinfo_fullQuickly_unableToe.checked == true) {
                $(`#${this.props.prefix}PatienfullQuicklyUnableToeArea`).addClass(" showPatienAbdomenPainArea");
                $(`#${this.props.prefix}PatienfullQuicklyUnableToeArea`).removeClass(" hidePatienAbdomenPainArea");
            }
        };

        const HidePatienfullQuicklyUnableToeAreaClick = () => {

            var clinicinfo_fullQuickly_unableToe2 = document.getElementById(`${this.props.prefix}clinicinfo_fullQuickly_unableToe2`);

            if (clinicinfo_fullQuickly_unableToe2.checked == true) {
                $(`#${this.props.prefix}PatienfullQuicklyUnableToeArea`).removeClass(" showPatienAbdomenPainArea");
                $(`#${this.props.prefix}PatienfullQuicklyUnableToeArea`).addClass(" hidePatienAbdomenPainArea");
            }
        };

        const ShowPatienExpabdominalbloatAreaClick = () => {

            var clinicinfo_Expabdominalbloat_IncrSize = document.getElementById(`${this.props.prefix}clinicinfo_Expabdominalbloat_IncrSize`);

            if (clinicinfo_Expabdominalbloat_IncrSize.checked == true) {
                $(`#${this.props.prefix}PatienExpabdominalbloatArea`).addClass(" showPatienAbdomenPainArea");
                $(`#${this.props.prefix}PatienExpabdominalbloatArea`).removeClass(" hidePatienAbdomenPainArea");
            }
        };

        const HidePatienExpabdominalbloatAreaClick = () => {

            var clinicinfo_Expabdominalbloat_IncrSize2 = document.getElementById(`${this.props.prefix}clinicinfo_Expabdominalbloat_IncrSize2`);

            if (clinicinfo_Expabdominalbloat_IncrSize2.checked == true) {
                $(`#${this.props.prefix}PatienExpabdominalbloatArea`).removeClass(" showPatienAbdomenPainArea");
                $(`#${this.props.prefix}PatienExpabdominalbloatArea`).addClass(" hidePatienAbdomenPainArea");
            }
        };


        $(document).ready(() => {

            $(`#${this.props.prefix}btnClinicalInfoFormReset`).click(() => {
                $(`#${this.props.prefix}clinicalInfoFormContainer`).find(':input').map((i, elem) => {
                    const _type = $(elem).attr('type');
                    console.log("_type: ", _type);
                    helper.resetForm(_type, elem);
                });

                console.log("this.state.clinicinfo_DoLastMentrualPeriod: ", this.state.clinicinfo_DoLastMentrualPeriod);
                if (this.state.clinicinfo_DoLastMentrualPeriod) {
                    this.setState({ clinicinfo_DoLastMentrualPeriod: null })
                }
            });

            $(`#${this.props.prefix}clinicinfo_MassSize_longdime`).on("input", function (evt) {
                var self = $(this);
                self.val(self.val().replace(/[^0-9\.]/g, ''));
                if ((evt.which != 46 || self.val().indexOf('.') != -1) && (evt.which < 48 || evt.which > 57)) {
                    evt.preventDefault();
                }
            });

            $(`#${this.props.prefix}clinicinfo_MassSize_Height`).on("input", function (evt) {
                var self = $(this);
                self.val(self.val().replace(/[^0-9\.]/g, ''));
                if ((evt.which != 46 || self.val().indexOf('.') != -1) && (evt.which < 48 || evt.which > 57)) {
                    evt.preventDefault();
                }
            });

            $(`#${this.props.prefix}clinicinfo_MassSize_Weight`).on("input", function (evt) {
                var self = $(this);
                self.val(self.val().replace(/[^0-9\.]/g, ''));
                if ((evt.which != 46 || self.val().indexOf('.') != -1) && (evt.which < 48 || evt.which > 57)) {
                    evt.preventDefault();
                }
            });

            $(`#${this.props.prefix}clinicinfo_lowrisk`).change(() => {
                if($(`#${this.props.prefix}clinicinfo_lowrisk`).is(":checked")){
                    $(`#${this.props.prefix}clinicinfo_highrisk`).prop("checked", false);
                    $(`#${this.props.prefix}clinicinfo_NotDefinitive`).prop("checked", false);
                }
            });

            $(`#${this.props.prefix}clinicinfo_highrisk`).change(() => {
                if($(`#${this.props.prefix}clinicinfo_highrisk`).is(":checked")){
                    $(`#${this.props.prefix}clinicinfo_lowrisk`).prop("checked", false);
                    $(`#${this.props.prefix}clinicinfo_NotDefinitive`).prop("checked", false);
                }
            });

            $(`#${this.props.prefix}clinicinfo_NotDefinitive`).change(() => {
                if($(`#${this.props.prefix}clinicinfo_NotDefinitive`).is(":checked")){
                    $(`#${this.props.prefix}clinicinfo_lowrisk`).prop("checked", false);
                    $(`#${this.props.prefix}clinicinfo_highrisk`).prop("checked", false);
                }
            });

        });

        return (
            <>
                <div id={`${this.props.prefix}clinicalInfoFormContainer`} class="panel-body">
                    <div class="row clearfix">
                        <div class="col-md-6">
                            <div class="form-group required">
                                <label class="form-label" for={`${this.props.prefix}MenopausalStatus`}>Menopausal Status</label>
                                <div id={`${this.props.prefix}ova1_q01`} class="demo-radio-button">
                                    <input type="radio" id={`${this.props.prefix}clinicinfo_Menopausal_status`} name="clinicinfo_Menopausal_status_" value="Pre-Menopausal" class=" form-control" />
                                    <label class="form-label menopausalStatus" for={`${this.props.prefix}clinicinfo_Menopausal_status`}>Pre-Menopausal &nbsp; </label>
                                    <input type="radio" id={`${this.props.prefix}clinicinfo_Menopausal_status2`} name="clinicinfo_Menopausal_status_" value="Post-Menopausal" class=" form-control" />
                                    <label class="form-label menopausalStatus" for={`${this.props.prefix}clinicinfo_Menopausal_status2`}>Post-Menopausal &nbsp; </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group required">
                                <label class="form-label" for={`${this.props.prefix}UltrasoundResults`}>Ultrasound Results</label>
                                <div id={`${this.props.prefix}ova1_q02`} class="demo-checkbox">
                                    <input data-val="Low Risk" type="checkbox" id={`${this.props.prefix}clinicinfo_lowrisk`} name="clinicinfo_lowrisk" class=" filled-in" />
                                    <label for={`${this.props.prefix}clinicinfo_lowrisk`}>Low Risk &nbsp; </label>
                                    <input data-val="High Risk" type="checkbox" id={`${this.props.prefix}clinicinfo_highrisk`} name="clinicinfo_highrisk" class=" filled-in" />
                                    <label for={`${this.props.prefix}clinicinfo_highrisk`}>High Risk &nbsp; </label>
                                    <input data-val="Not Definitive" type="checkbox" id={`${this.props.prefix}clinicinfo_NotDefinitive`} name="clinicinfo_NotDefinitive" class=" filled-in" />
                                    <label for={`${this.props.prefix}clinicinfo_NotDefinitive`}>Not Definitive &nbsp; </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id={`${this.props.prefix}ova1_q03`} class="row clearfix">
                        <div class="col-md-12">
                            <div class="form-group required">
                                <label class="form-label" for={`${this.props.prefix}dob`}>Date of last menstrual period </label>
                                <div class="form-line">
                                    <DatePicker
                                        id={`${this.props.prefix}clinicinfo_DoLastMentrualPeriod`}
                                        className={"form-control required"}
                                        selected={this.state.clinicinfo_DoLastMentrualPeriod}
                                        onChange={this.onChangeDoLastMentrualPeriod}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div id={`${this.props.prefix}ova1_q04`} class="col-md-4">
                            <div class="form-group ">
                                <label class="form-label" for={`${this.props.prefix}masssize_long`}>Size of mass(longest dimension) </label>
                                <div class="form-line">
                                    <input type="text" id={`${this.props.prefix}clinicinfo_MassSize_longdime`} class="form-control" placeholder=" longest dimension" />
                                </div>
                            </div>
                        </div>
                        <div id={`${this.props.prefix}ova1_q04_1`} class="col-md-2">
                            <div class="form-group ">
                                <label class="form-label" for={`${this.props.prefix}masssize_height`}>Height </label>
                                <div class="form-line">
                                    <input type="text" id={`${this.props.prefix}clinicinfo_MassSize_Height`} class="form-control" placeholder=" Height" />
                                </div>
                            </div>
                        </div>
                        <div id={`${this.props.prefix}ova1_q04_12`} class="col-md-2">
                            <div class="form-group ">
                                <label class="form-label" for={`${this.props.prefix}masssize_height`}>&nbsp; </label>
                                <div class="form-line">
                                    <select class="form-control show-tick" id={`${this.props.prefix}clinicinfo_MassSize_Height_unit`}>
                                        <option value="">-- select --</option>
                                        <option value="inches"> Inches</option>
                                        <option value="millimeters">Millimeters</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div id={`${this.props.prefix}ova1_q04_2`} class="col-md-2">
                            <div class="form-group ">
                                <label class="form-label" for={`${this.props.prefix}masssize_weight`}>Weight </label>
                                <div class="form-line">
                                    <input type="text" id={`${this.props.prefix}clinicinfo_MassSize_Weight`} class="form-control" placeholder=" Weight" />
                                </div>
                            </div>
                        </div>
                        <div id={`${this.props.prefix}ova1_q04_22`} class="col-md-2">
                            <div class="form-group ">
                                <label class="form-label" for={`${this.props.prefix}masssize_weight`}>&nbsp; </label>
                                <div class="form-line">
                                    <select class="form-control show-tick" id={`${this.props.prefix}clinicinfo_MassSize_Weight_unit`}>
                                        <option value="">-- select --</option>
                                        <option value="ounces"> Ounces</option>
                                        <option value="grams">Grams</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id={`${this.props.prefix} ova1_q05`} class="row clearfix">
                        <div class="col-md-12">
                            <div class="demo-radio-button">
                                <label>Does the patient have pain in the abdomen or pelvis?</label>
                                <input type="radio" id={`${this.props.prefix}clinicinfo_pain_abdomen_pelvis`} name="clinicinfo_pain_abdomen_pelvis_" value="yes" class="form-control" onClick={() => {
                                    ShowPatienAbdomenPainAreaClick()
                                }} />
                                <label class="form-label" for={`${this.props.prefix}clinicinfo_pain_abdomen_pelvis`}>Yes</label>
                                <input type="radio" id={`${this.props.prefix}clinicinfo_pain_abdomen_pelvis2`} name="clinicinfo_pain_abdomen_pelvis_" value="no" class=" form-control" onClick={() => {
                                    HidePatienAbdomenPainAreaClick()
                                }} />
                                <label class="form-label" for={`${this.props.prefix}clinicinfo_pain_abdomen_pelvis2`}>No</label>
                            </div>
                        </div>
                    </div>
                    <div id={`${this.props.prefix}PatienAbdomenPainArea`} class=" hidePatienAbdomenPainArea">
                        <div class="row clearfix " >
                            <div class="col-md-6">
                                <label class="form-label" for={`${this.props.prefix}`}>How many days per month?</label>
                                <div id={`${this.props.prefix}ova1_q05_1`} class="demo-radio-button">
                                    <input type="radio" id={`${this.props.prefix}clinicinfo_painAbdomenPelvis_Day_1`} name={this.props.prefix === "ovaWatch_" ? `${this.props.prefix}clinicinfo_painAbdomenPelvis_Day_` : "clinicinfo_painAbdomenPelvis_Day_"} value="0-5 days" class=" form-control" />
                                    <label class="form-label" for={`${this.props.prefix}clinicinfo_painAbdomenPelvis_Day_1`}>0-5 days &nbsp; </label>
                                    <input type="radio" id={`${this.props.prefix}clinicinfo_painAbdomenPelvis_Day_2`} name={this.props.prefix === "ovaWatch_" ? `${this.props.prefix}clinicinfo_painAbdomenPelvis_Day_` : "clinicinfo_painAbdomenPelvis_Day_"} value="6-12 days" class=" form-control" />
                                    <label class="form-label" for={`${this.props.prefix}clinicinfo_painAbdomenPelvis_Day_2`}>6-12 days &nbsp; </label>
                                    <input type="radio" id={`${this.props.prefix}clinicinfo_painAbdomenPelvis_Day_3`} name={this.props.prefix === "ovaWatch_" ? `${this.props.prefix}clinicinfo_painAbdomenPelvis_Day_` : "clinicinfo_painAbdomenPelvis_Day_"} value="> 12 days" class=" form-control" />
                                    <label class="form-label" for={`${this.props.prefix}clinicinfo_painAbdomenPelvis_Day_3`}>&gt; 12 days &nbsp; </label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="form-label" for={`${this.props.prefix}`}>For how long?</label>
                                <div id={`${this.props.prefix}ova1_q05_2`} class="demo-radio-button">
                                    <input type="radio" id={`${this.props.prefix}clinicinfo_painAbdomenPelvis_long_1`} name={this.props.prefix === "ovaWatch_" ? `${this.props.prefix}clinicinfo_painAbdomenPelvis_long_`: "clinicinfo_painAbdomenPelvis_long_"} value="< 1 month" class=" form-control" />
                                    <label class="form-label" for={`${this.props.prefix}clinicinfo_painAbdomenPelvis_long_1`}>&lt; 1 month &nbsp; </label>
                                    <input type="radio" id={`${this.props.prefix}clinicinfo_painAbdomenPelvis_long_2`} name={this.props.prefix === "ovaWatch_" ? `${this.props.prefix}clinicinfo_painAbdomenPelvis_long_`: "clinicinfo_painAbdomenPelvis_long_"} value="1-6 months" class=" form-control" />
                                    <label class="form-label" for={`${this.props.prefix}clinicinfo_painAbdomenPelvis_long_2`}>1-6 months &nbsp; </label>
                                    <input type="radio" id={`${this.props.prefix}clinicinfo_painAbdomenPelvis_long_3`} name={this.props.prefix === "ovaWatch_" ? `${this.props.prefix}clinicinfo_painAbdomenPelvis_long_`: "clinicinfo_painAbdomenPelvis_long_"} value="7-12 months" class=" form-control" />
                                    <label class="form-label" for={`${this.props.prefix}clinicinfo_painAbdomenPelvis_long_3`}>7-12 months &nbsp; </label>
                                    <input type="radio" id={`${this.props.prefix}clinicinfo_painAbdomenPelvis_long_4`} name={this.props.prefix === "ovaWatch_" ? `${this.props.prefix}clinicinfo_painAbdomenPelvis_long_`: "clinicinfo_painAbdomenPelvis_long_"} value="> 12 months" class=" form-control" />
                                    <label class="form-label" for={`${this.props.prefix}clinicinfo_painAbdomenPelvis_long_4`}>&gt; 12 months &nbsp; </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id={`${this.props.prefix}ova1_q06`} class="row clearfix">
                        <div class="col-md-12">
                            <div class="demo-radio-button">
                                <label>Does the patient feel full quickly or unable to eat normally?</label>
                                <input type="radio" id={`${this.props.prefix}clinicinfo_fullQuickly_unableToe`} name="clinicinfo_fullQuickly_unableToe_" value="yes" class=" form-control" onClick={() => {
                                    ShowPatienfullQuicklyUnableToeAreaClick()
                                }} />
                                <label class="form-label" for={`${this.props.prefix}clinicinfo_fullQuickly_unableToe`}>Yes</label>
                                <input type="radio" id={`${this.props.prefix}clinicinfo_fullQuickly_unableToe2`} name="clinicinfo_fullQuickly_unableToe_" value="no" class=" form-control" onClick={() => {
                                    HidePatienfullQuicklyUnableToeAreaClick()
                                }} />
                                <label class="form-label" for={`${this.props.prefix}clinicinfo_fullQuickly_unableToe2`}>No</label>
                            </div>
                        </div>
                    </div>
                    <div id={`${this.props.prefix}PatienfullQuicklyUnableToeArea`} class=" hidePatienAbdomenPainArea">
                        <div class="row clearfix" >
                            <div class="col-md-6">
                                <label class="form-label" for={`${this.props.prefix}`}>How many days per month?</label>
                                <div id={`${this.props.prefix}ova1_q06_1`} class="demo-radio-button">
                                    <input type="radio" id={`${this.props.prefix}clinicinfo_fullQuicklyUnableToe_Day_1`} name={this.props.prefix === "ovaWatch_" ? `${this.props.prefix}clinicinfo_fullQuicklyUnableToe_Day_`: "clinicinfo_fullQuicklyUnableToe_Day_"} value="0-5 days" class=" form-control" />
                                    <label class="form-label" for={`${this.props.prefix}clinicinfo_fullQuicklyUnableToe_Day_1`}>0-5 days &nbsp; </label>
                                    <input type="radio" id={`${this.props.prefix}clinicinfo_fullQuicklyUnableToe_Day_2`} name={this.props.prefix === "ovaWatch_" ? `${this.props.prefix}clinicinfo_fullQuicklyUnableToe_Day_`: "clinicinfo_fullQuicklyUnableToe_Day_"} value="6-12 days" class=" form-control" />
                                    <label class="form-label" for={`${this.props.prefix}clinicinfo_fullQuicklyUnableToe_Day_2`}>6-12 days &nbsp; </label>
                                    <input type="radio" id={`${this.props.prefix}clinicinfo_fullQuicklyUnableToe_Day_3`} name={this.props.prefix === "ovaWatch_" ? `${this.props.prefix}clinicinfo_fullQuicklyUnableToe_Day_`: "clinicinfo_fullQuicklyUnableToe_Day_"} value="> 12 days" class=" form-control" />
                                    <label class="form-label" for={`${this.props.prefix}clinicinfo_fullQuicklyUnableToe_Day_3`}>&gt; 12 days &nbsp; </label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="form-label" for={`${this.props.prefix}`}>For how long?</label>
                                <div id={`${this.props.prefix}ova1_q06_2`} class="demo-radio-button">
                                    <input type="radio" id={`${this.props.prefix}clinicinfo_fullQuicklyUnableToe_long_1`} name={this.props.prefix === "ovaWatch_" ? `${this.props.prefix}clinicinfo_fullQuicklyUnableToe_long_`: "clinicinfo_fullQuicklyUnableToe_long_"} value="< 1 month" class=" form-control" />
                                    <label class="form-label" for={`${this.props.prefix}clinicinfo_fullQuicklyUnableToe_long_1`}>&lt; 1 month &nbsp; </label>
                                    <input type="radio" id={`${this.props.prefix}clinicinfo_fullQuicklyUnableToe_long_2`} name={this.props.prefix === "ovaWatch_" ? `${this.props.prefix}clinicinfo_fullQuicklyUnableToe_long_`: "clinicinfo_fullQuicklyUnableToe_long_"} value="1-6 months" class=" form-control" />
                                    <label class="form-label" for={`${this.props.prefix}clinicinfo_fullQuicklyUnableToe_long_2`}>1-6 months &nbsp; </label>
                                    <input type="radio" id={`${this.props.prefix}clinicinfo_fullQuicklyUnableToe_long_3`} name={this.props.prefix === "ovaWatch_" ? `${this.props.prefix}clinicinfo_fullQuicklyUnableToe_long_`: "clinicinfo_fullQuicklyUnableToe_long_"} value="7-12 months" class=" form-control" />
                                    <label class="form-label" for={`${this.props.prefix}clinicinfo_fullQuicklyUnableToe_long_3`}>7-12 months &nbsp; </label>
                                    <input type="radio" id={`${this.props.prefix}clinicinfo_fullQuicklyUnableToe_long_4`} name={this.props.prefix === "ovaWatch_" ? `${this.props.prefix}clinicinfo_fullQuicklyUnableToe_long_`: "clinicinfo_fullQuicklyUnableToe_long_"} value="> 12 months" class=" form-control" />
                                    <label class="form-label" for={`${this.props.prefix}clinicinfo_fullQuicklyUnableToe_long_4`}>&gt; 12 months &nbsp; </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id={`${this.props.prefix}ova1_q07`} class="row clearfix">
                        <div class="col-md-12">
                            <div class="demo-radio-button">
                                <label>Does the patient experience abdominal bloating or an increased abdominal size?</label>
                                <input type="radio" id={`${this.props.prefix}clinicinfo_Expabdominalbloat_IncrSize`} name="clinicinfo_Expabdominalbloat_IncrSize_" value="yes" class=" form-control" onClick={() => {
                                    ShowPatienExpabdominalbloatAreaClick()
                                }} />
                                <label class="form-label" for={`${this.props.prefix}clinicinfo_Expabdominalbloat_IncrSize`}>Yes</label>
                                <input type="radio" id={`${this.props.prefix}clinicinfo_Expabdominalbloat_IncrSize2`} name="clinicinfo_Expabdominalbloat_IncrSize_" value="no" class=" form-control" onClick={() => {
                                    HidePatienExpabdominalbloatAreaClick()
                                }} />
                                <label class="form-label" for={`${this.props.prefix}clinicinfo_Expabdominalbloat_IncrSize2`}>No</label>
                            </div>
                        </div>
                    </div>
                    <div id={`${this.props.prefix}PatienExpabdominalbloatArea`} class=" hidePatienAbdomenPainArea">
                        <div class="row clearfix" >
                            <div id={`${this.props.prefix}ova1_q07_1`} class="col-md-6">
                                <label class="form-label" for={`${this.props.prefix}`}>How many days per month?</label>
                                <div class="demo-radio-button">
                                    <input type="radio" id={`${this.props.prefix}clinicinfo_ExpabdominalbloatIncrSize_Day_1`} name={this.props.prefix === "ovaWatch_" ? `${this.props.prefix}clinicinfo_ExpabdominalbloatIncrSize_Day_`: "clinicinfo_ExpabdominalbloatIncrSize_Day_"} value="0-5 days" class=" form-control" />
                                    <label class="form-label" for={`${this.props.prefix}clinicinfo_ExpabdominalbloatIncrSize_Day_1`}>0-5 days &nbsp; </label>
                                    <input type="radio" id={`${this.props.prefix}clinicinfo_ExpabdominalbloatIncrSize_Day_2`} name={this.props.prefix === "ovaWatch_" ? `${this.props.prefix}clinicinfo_ExpabdominalbloatIncrSize_Day_`: "clinicinfo_ExpabdominalbloatIncrSize_Day_"} value="6-12 days" class=" form-control" />
                                    <label class="form-label" for={`${this.props.prefix}clinicinfo_ExpabdominalbloatIncrSize_Day_2`}>6-12 days &nbsp; </label>
                                    <input type="radio" id={`${this.props.prefix}clinicinfo_ExpabdominalbloatIncrSize_Day_3`} name={this.props.prefix === "ovaWatch_" ? `${this.props.prefix}clinicinfo_ExpabdominalbloatIncrSize_Day_`: "clinicinfo_ExpabdominalbloatIncrSize_Day_"} value="> 12 days" class=" form-control" />
                                    <label class="form-label" for={`${this.props.prefix}clinicinfo_ExpabdominalbloatIncrSize_Day_3`}>&gt; 12 days &nbsp; </label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="form-label" for={`${this.props.prefix}`}>For how long?</label>
                                <div id={`${this.props.prefix}ova1_q07_2`} class="demo-radio-button">
                                    <input type="radio" id={`${this.props.prefix}clinicinfo_ExpabdominalbloatIncrSize_long_1`} name={this.props.prefix === "ovaWatch_" ? `${this.props.prefix}clinicinfo_ExpabdominalbloatIncrSize_long_`: "clinicinfo_ExpabdominalbloatIncrSize_long_"} value="< 1 month" class=" form-control" />
                                    <label class="form-label" for={`${this.props.prefix}clinicinfo_ExpabdominalbloatIncrSize_long_1`}>&lt; 1 month &nbsp; </label>
                                    <input type="radio" id={`${this.props.prefix}clinicinfo_ExpabdominalbloatIncrSize_long_2`} name={this.props.prefix === "ovaWatch_" ? `${this.props.prefix}clinicinfo_ExpabdominalbloatIncrSize_long_`: "clinicinfo_ExpabdominalbloatIncrSize_long_"} value="1-6 months" class=" form-control" />
                                    <label class="form-label" for={`${this.props.prefix}clinicinfo_ExpabdominalbloatIncrSize_long_2`}>1-6 months &nbsp; </label>
                                    <input type="radio" id={`${this.props.prefix}clinicinfo_ExpabdominalbloatIncrSize_long_3`} name={this.props.prefix === "ovaWatch_" ? `${this.props.prefix}clinicinfo_ExpabdominalbloatIncrSize_long_`: "clinicinfo_ExpabdominalbloatIncrSize_long_"} value="7-12 months" class=" form-control" />
                                    <label class="form-label" for={`${this.props.prefix}clinicinfo_ExpabdominalbloatIncrSize_long_3`}>7-12 months &nbsp; </label>
                                    <input type="radio" id={`${this.props.prefix}clinicinfo_ExpabdominalbloatIncrSize_long_4`} name={this.props.prefix === "ovaWatch_" ? `${this.props.prefix}clinicinfo_ExpabdominalbloatIncrSize_long_`: "clinicinfo_ExpabdominalbloatIncrSize_long_"} value="> 12 months" class=" form-control" />
                                    <label class="form-label" for={`${this.props.prefix}clinicinfo_ExpabdominalbloatIncrSize_long_4`}>&gt; 12 months &nbsp; </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row clearfix">
                        <div class="col-md-12 text-right">
                            <button id={`${this.props.prefix}btnClinicalInfoFormReset`} class="btn btn-light">Reset Form</button>
                            <button id={`${this.props.prefix}btnClinicalInformationFormNext`} class="btn btn-light">Next</button>
                        </div>
                    </div>


                </div>
            </>
        );
    }
}
