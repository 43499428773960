import { MsalContext } from "@azure/msal-react";
//import dotenv from "dotenv";
import axios from "axios";
import "datatables.net-bs/css/dataTables.bootstrap.css";
import "datatables.net-dt/css/jquery.dataTables.css";
import moment from "moment";
import React from "react";
import helper from "../utils/helper";
// import jsPDF from "jspdf";
// import ReactDOMServer from "react-dom/server";
import { protectedResources } from "./../authConfig";
import DeleteModal from "./../mycomponents/modal";
import ProgressBar from "./../mycomponents/progressbar";
import Spinner from "./../Spinner/Spinner";

const $ = require("jquery");
$.DataTable = require("datatables.net");

const API_BASE_URL = helper.getAPIBaseURI();

// require( 'datatables.net-bs' )( window, $ );

//dotenv.config();

let me = null;
export default class OrdersContent extends React.PureComponent {
  static contextType = MsalContext;

  constructor(props) {
    super(props);

    this.state = {
      orders: [],
      loading: true,
      loading1: true,

      showOrderModal: false,
      deleteOrderId: null,
      showProgressBar: false,
      originalData: [],
      filteredData: [],
      serverSideRendering: process.env.REACT_APP_SERVER_SIDE_RENDERING_ENABLED,

      searchText: null,
      lastEvaluatedKey: null,
    };

    this.orderDataTable = React.createRef();

    this.handleButtonPress = this.handleButtonPress.bind(this);
    this.handleButtonRelease = this.handleButtonRelease.bind(this);

    me = this;

    this.$el = null;

    this.$ova1PlusCheckBox = null;
    this.$genetixCheckBox = null;
    this.$ovaWatchCheckBox = null;

    this.inpSearchRef = React.createRef();
  }
  handleButtonPress() {
    this.buttonPressTimer = setTimeout(
      () => this.setState({ showProgressBar: true }),
      1000
    );
  }

  handleButtonRelease() {
    clearTimeout(this.buttonPressTimer);
  }

  async callDataTable(datatotables, type = null) {
    // first time, el will be null
    // see: https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs

    console.log("this.orderDataTable: ", this.orderDataTable);

    if (!this.orderDataTable) return;

    let columnsForAllAccessionsTable = [
      {
        title: "DOB",
        data: "Patient.dob",
        name: "DOB",
        sortable: true,
        visible: false,
      },
      {
        title: "Accession",
        data: null,
        name: "orderId",
        width: "10px",
        sortable: true,
        render: (data, type) => {
          if (type === "display") {
            console.log("-->data: ", data);
            return `<a target="_blank" href="/new-accessions-pdf?orderId=${data.orderId}" class="">${data.orderId}</a>`;
          }

          return data;
        },
      },
      {
        title: "Patient Last Name",
        data: "Patient.lastName",
        name: "lastName",
        width: "15px",
      },
      {
        title: "Patient First Name",
        data: "Patient.firstName",
        name: "firstName",
        width: "15px",
      },
      {
        title: "Test Code",
        data: "xifinTestCode",
        name: "xifinTestCodeInvisible",
        width: "150px",
        searchable: true,
        visible: false,
        //   render: (data, type) => {
        //     let testName = "";

        //     // if(data.tests.length===1) {
        //     //     testName=data.tests[0].testName;
        //     // }
        //     // else if(data.tests.)

        //     data.tests.map((item, i) => {
        //       if (typeof item.testName != "undefined") {
        //         //   if (testName == "") {
        //         //     testName += item.testName;
        //         //   } else {
        //         //     testName += "+" + item.testName;
        //         //   }

        //         let testNameLowerCase = item.testName.toLowerCase();
        //         testName = testNameLowerCase === "genetixhboc1" || testNameLowerCase === 'genetixcarrierscreening'
        //           ? item.genetixCSPanel[0] : item.testName
        //       }

        //     });

        //     //testName = testName.substring(0, testName.length - 1);
        //     if (type === "display") {
        //       return `<td>
        //                       <span class="">${testName}</td>
        //                   </td> `;
        //     }
        //     return data;
        //   },
      },
      {
        title: "Test Code",
        data: null,
        name: "xifinTestCode",
        width: "100px",
        searchable: false,
        sortable: false,
        render: (data, type) => {
          let testName = data.orderedTest;
          //testName = testName.substring(0, testName.length - 1);
          if (type === "display") {
            let id = `testCode_${index++}`;
            var html = `<td>
                              <p data-tip="${testName}" id="${id}" tabindex="${index}" class="mtooltip tool">${data.xifinTestCode}</p>
                          </td> `;

            return html;
          }
          return data;
        },
      },
      {
        title: "Client Code",
        data: "legacyClientId",
        name: "legacyClientId",
        width: "50px",
        sortable: true,
      },
      {
        title: "Client Name",
        data: "LegacyClient.name",
        name: "clientName",
        width: "150px",
        sortable: true,
      },
      {
        title: "Accession Date",
        data: null,
        name: "accessionDate",
        width: "80px",
        render: (data, type) => {
          if (type === "display") {
            return moment.utc(data.accessionDate).format("MM/DD/YYYY");
          }

          return data;
        },
      },
      {
        title: "Release Date",
        data: null,
        name: "releaseDate",
        width: "80px",
        render: (data, type) => {
          if (type === "display") {
            return moment.utc(data.finalReportDate).format("MM/DD/YYYY");
          }

          return data;
        },
      },
      {
        title: "PTP Hold",
        data: "ptpHold",
        name: "ptpHold",
        width: "80px",
        sortable: true,
        searchable: true,
      },
      {
        title: "Comments",
        data: null,
        name: "comments",
        width: "80px",
        render: (data, type) => {
          if (type === "display") {
            return "";
          }

          return data;
        },
      },
      {
        title: "",
        name: "",
        data: null,
        sortable: false,
        render: function (data, type) {
          if (type === "display") {
            // console.log("pdfclass->data: ", data);

            let lockIcon = `<img src="/icons/icons8-lock-50.svg" />`;
            if (data.isLocked) {
              lockIcon = `<img src="/icons/icons8-padlock-50.svg" />`;
            }

            let testCode_page = "";

            data.tests.map((item, i) => {
              if (typeof item.testCode != "undefined") {
                if (testCode_page === "") {
                  testCode_page += item.testCode;
                } else {
                  if (item.testName !== "COVD")
                    testCode_page += "_" + item.testCode;
                }
              }
            });

            return `<td>
                                <a class="rowOption pdfClass" data-orderid="${data.orderId}" data-id="${data._id}" data-val="${testCode_page}"><img style="width: 40; height: 40;" src="/icons/PDF_file_icon.svg" /></a>
                                <a class="rowOption editClass" data-id="${data._id}" data-val="${testCode_page}"><img src="/icons/icons8-edit-50.svg" /></a>
                            </td> `;
          }
          return data;
        },
      },
    ];

    var index = 0;
    let columnsForOtherTables = [
      {
        title: "DOB",
        data: "Patient.dob",
        name: "DOB",
        sortable: true,
        visible: false,
        searchable: true,
      },
      {
        title: "QBench Order ID",
        data: "orderId",
        name: "orderId",
        width: "10px",
        sortable: true,
      },
      // { title: "Patient ID", data: "Patient._id", name: "patientId" },
      {
        title: "Patient First Name",
        data: "Patient.firstName",
        name: "firstName",
        width: "15px",
      },
      {
        title: "Patient Last Name",
        data: "Patient.lastName",
        name: "lastName",
        width: "15px",
      },
      {
        title: "Test Code",
        data: "xifinTestCode",
        name: "xifinTestCodeInvisible",
        width: "100px",
        searchable: true,
        visible: false,
      },
      {
        title: "Test Code",
        data: null,
        name: "xifinTestCode",
        width: "100px",
        searchable: false,
        sortable: false,
        render: (data, type) => {
          let testName = data.orderedTest;
          //testName = testName.substring(0, testName.length - 1);
          if (type === "display") {
            let id = `testCode_${index++}`;
            var html = `<td>
                              <p data-tip="${testName}" id="${id}" tabindex="${index}" class="mtooltip tool">${data.xifinTestCode}</p>
                          </td> `;

            return html;
          }
          return data;
        },
      },
      {
        title: "Client Id",
        data: "legacyClientId",
        name: "legacyClientId",
        width: "50px",
        sortable: true,
      },
      {
        title: "Benefits Reviewer",
        data: "billingAssignedName",
        name: "billingAssignedName",
        width: "100px",
        sortable: true,
      },
      {
        title: "Status",
        data: null,
        name: "status",
        width: "7vw",
        sortable: false,
        render: function (data, type) {
          if (type === "display") {
            if (
              data.status === "Approved" ||
              data.status == "Order Authorized"
            ) {
              return `<td>
                            <span class="status_font label label-success">${data.status}</td>
                        </td> `;
            } else if (
              data.status === "Pending" ||
              data.status == "WAITING BILLING"
            ) {
              return `<td>
                            <span class="status_font label label-default">${data.status}</span>
                        </td> `;
            } else if (data.status === "Denied") {
              return `<td>
                            <span class="status_font label label-danger">${data.status}</td>
                        </td> `;
            } else {
              return `<td>
                            <span class="status_font label">${data.status}</td>
                        </td> `;
            }
          }
          return data;
        },
      },
      {
        title: "Baylor Status",
        data: "baylorStatus",
        name: "baylorStatus",
        width: "50px",
      },
      {
        title: "Report Status",
        data: "reportStatus",
        name: "reportStatus",
        width: "50px",
      },
      {
        title: "Xifin Status",
        data: "xifinStatus",
        name: "xifinStatus",
        width: "50px",
      },

      {
        title: "",
        name: "",
        data: null,
        sortable: false,
        render: function (data, type) {
          if (type === "display") {
            console.log("pdfclass->data: ", data);

            let lockIcon = `<img src="/icons/icons8-lock-50.svg" />`;
            if (data.isLocked) {
              lockIcon = `<img src="/icons/icons8-padlock-50.svg" />`;
            }

            let testCode_page = "";
            let dropDwon = "myDropdown";
            data.tests.map((item, i) => {
              if (typeof item.testCode != "undefined") {
                if (testCode_page === "") {
                  testCode_page += item.testCode;
                } else {
                  if (item.testName !== "COVD")
                    testCode_page += "_" + item.testCode;
                }
              }
            });

            // return `<td>
            // <div><img style="width: 10; height: 10;" height:20 width:20 src="/icons/down-arrow.png" /></div>
            // </td>`;

            return `<td class="dropDown">  
                              <div><img class="dropbtn" id="${data.orderId
              }" style="width: 10; height: 10;" src="/icons/down-arrow.png"/></div>
                              <div class="dropdown-content" id="${dropDwon + data.orderId
              }">
                                <a class="rowOption pdfClass" data-orderid="${data.orderId
              }" data-id="${data._id
              }" data-val="${testCode_page}"><img style="width: 40; height: 40;" src="/icons/PDF_file_icon.svg" />View TRF</a>
                                <a class="rowOption editClass" data-id="${data._id
              }" data-val="${testCode_page}"><img src="/icons/icons8-edit-50.svg" />Edit Order</a>
                                <a data-id="${data._id
              }" id="option-delete" class="rowOption deleteClass"><img src="/icons/icons8-delete-trash-50.svg" />Delete Order</a>
                                <a id="option-lock" class="rowOption lockClass">${lockIcon}Lock Order</a>
                              </div> 
                            </td> `;
            // return `<td class="dropDown">
            //                   <div><img class="dropbtn" id="${data.orderId
            //   }" style="width: 10; height: 10;" src="/icons/down-arrow.png"/></div>
            //                   <div class="dropdown-content" id="${dropDwon + data.orderId
            //   }">
            //                     <a class="rowOption pdfClass" data-orderid="${data.orderId
            //   }" data-id="${data._id
            //   }" data-val="${testCode_page}"><img style="width: 40; height: 40;" src="/icons/PDF_file_icon.svg" />View TRF</a>
            //                     <a class="rowOption editClass" data-id="${data._id
            //   }" data-val="${testCode_page}"><img src="/icons/icons8-edit-50.svg" />Edit Order</a>
            //                     <a data-id="${data._id
            //   }" id="option-delete" class="rowOption deleteClass"><img src="/icons/icons8-delete-trash-50.svg" /></a>
            //                     <a id="option-lock" class="rowOption lockClass">${lockIcon}</a>
            //                   </div>
            //                 </td> `;
          }

          return data;
        },
      },
    ];

    if (this.$el) {
      this.$el.DataTable().clear().destroy();
    }

    this.$el = $(this.orderDataTable);
    const user = JSON.parse(localStorage.getItem("user"));

    if (this.state.serverSideRendering === "true") {
      const accessToken = await helper.getAccessToken(this.context);

      var ajax = {
        url: `${API_BASE_URL}/orders/paging`,
        type: "POST",

        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          type: type,
          userId: user.id,
        },
      };
      this.$el = $(`#orderDataTable`).DataTable({
        lengthMenu: [25, 50, 100],
        pageLength: 25,

        responsive: false,
        processing: true,

        ajax: ajax,
        serverSide: true,

        deferRender: true,

        columns:
          // [{
          //   name: "",
          //   data: "",
          //   createdCell: function (cell, cellData, rowData, row, col) {

          //   }
          // }],
          window.location.href.includes("new-accessions")
            ? columnsForAllAccessionsTable
            : columnsForOtherTables,
        order: [],

        paging: true,
        autoWidth: false,
      });
    } else {
      console.log("datatotables: ", datatotables);

      this.$el.DataTable({
        searchPanes: {
          controls: false,
        },
        pageLength: 25,
        order: [],
        data: datatotables,
        columns: window.location.href.includes("new-accessions")
          ? columnsForAllAccessionsTable
          : columnsForOtherTables,
      });
    }

    // function  myFunction() {
    //     document.getElementById("myDropdown").classList.toggle("show");
    //   }
    this.$el.on("click", ".dropbtn", async function (event) {
      console.log("menu show----------------------");
      var myClass = $(this).attr("class");
      var myId = $(this).attr("id");
      console.log(myClass + "_++++-" + myId);
      if (event.target.matches(".dropbtn")) {
        console.log("class called------");
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains("show")) {
            console.log("show is here---");
            openDropdown.classList.remove("show");
            console.log("removed");
            // openDropdown.classList.add("show");
          } else {
            const finalId = "myDropdown" + myId;
            console.log("showed--------", finalId);
            document.getElementById(finalId).classList.toggle("show");
          }
        }
      }
      // document.getElementById("myDropdown" + myId).classList.toggle("show");
    });

    this.$el.on("click", async function (event) {
      console.log("calling by event------------");
      var myClass = $(this).attr("class");
      var myId = $(this).attr("id");
      console.log(myClass + "----" + myId);
      console.log(event.target);
      if (!event.target.matches(".dropbtn")) {
        var dropdowns = document.getElementsByClassName("dropdown-content");
        var i;
        for (i = 0; i < dropdowns.length; i++) {
          var openDropdown = dropdowns[i];
          if (openDropdown.classList.contains("show")) {
            openDropdown.classList.remove("show");
          }
        }
      }
    });
    this.$el.on("click", "tr a.pdfClass", async function () {
      let item_id = $(this).data("id");
      let custom_formatted_id = $(this).data("orderid");

      console.log(
        "item_id: ",
        item_id,
        ", custom_formatted_id: ",
        custom_formatted_id
      );
      if (process.env.REACT_APP_ENV === 'DEV') {
        const user = JSON.parse(localStorage.getItem("user"));
        helper.printPDF(item_id, custom_formatted_id, user.token);
      }
      else {
        const accessToken = await helper.getAccessToken(me.context);

        helper.printPDF(item_id, custom_formatted_id, accessToken);
      }

      // const accessToken = await helper.getAccessToken(me.context);
      // let orderData = await helper.getOrderFromServerById(item_id, accessToken);
      // console.log("orderData: ", orderData);
      // let exactHtmlUrl = "";
      // if (process.env.REACT_APP_ENV === "DEV") {
      //   exactHtmlUrl = await helper.getTRFHtmlUrl(orderData);
      // } else {
      //   exactHtmlUrl = await helper.getTRFHtmlUrl(orderData);
      // }

      // if (exactHtmlUrl) {
      //   window.open(exactHtmlUrl);
      //   // let htmlRawData = await helper.getTRFHtmlFromUrl(exactHtmlUrl, accessToken);

      //   // let element = htmlRawData;
      //   // const doc = new jsPDF("p", "pt", "letter");
      //   // doc.html(ReactDOMServer.renderToString(element), {
      //   //   callback: function (doc) {
      //   //     doc.save(`trf-${custom_formatted_id}.pdf`);
      //   //   }
      //   // });
      // }
    });
    this.$el.on("click", "tr a.deleteClass", function () {
      let item_id = $(this).data("id");

      me.setState({
        showOrderModal: true,
        deleteOrderId: item_id,
      });
    });

    this.$el.on("click", "tr a.editClass", function () {
      let item_id = $(this).data("id");
      let item_prefix = $(this).data("val");
      let editHref = "";

      if (
        item_prefix.toLowerCase() === "ova1" ||
        item_prefix.toLowerCase() === "vds-125"
      ) {
        editHref = `/editorder_ova1?id=${item_id}&returnurl=${window.location.href}`;
      } else if (
        item_prefix.toLowerCase().includes("ovawatch") ||
        item_prefix.toLowerCase().includes("ovasight") ||
        item_prefix.toLowerCase() === "vds-200"
      ) {
        item_prefix = "OvaWatch";
        editHref = `/editorder_${item_prefix}?id=${item_id}&returnurl=${window.location.href}`;
      } else {
        editHref = `/editorder_${item_prefix}?id=${item_id}&returnurl=${window.location.href}`;
      }

      console.log("editHref: ", editHref);
      // console.log("me.props: ", me.props);
      // me.props.history.push(editHref);
      // return <Redirect to={editHref} />

      // me.context.router.history.push(editHref);

      window.location.href = editHref;
    });

    //   $('.dataTables_filter input', me.$el.DataTable().table().container())
    //     .off('.DT')
    //     .on('keyup.DT cut.DT paste.DT input.DT search.DT', function (e) {
    //       let dt = me.$el.DataTable();
    //       // If the length is 3 or more characters, or the user pressed ENTER, search
    //       console.log("searching for: ", this.value);
    //       if (this.value.length >= 3 || e.keyCode == 13) {
    //         // Call the API search function

    //         me.setState({ searchText: this.value, lastEvaluatedKey: null }, async () => {
    //           await me.handlePaging(dt);

    //         });
    //         // dt.search(this.value).draw();
    //       }

    //       // Ensure we clear the search if they backspace far enough
    //       if (this.value === "") {
    //         // dt.search("").draw();
    //         me.setState({ searchText: "" }, async () => {
    //           await me.handlePaging(dt);
    //           // dt.search("").draw();
    //         });
    //       }
    //     });
  }
  async componentDidMount() {
    await this.handlePaging();
  }

  showModal = (e) => {
    this.setState({
      showOrderModal: !this.state.showOrderModal,
    });
  };

  handleFilterCheckboxesCheckUncheck = () => {
    console.log("\n---");
    console.log("original data: ", this.state.originalData);
    console.log("---\n");
    // return;

    let printData = [],
      dataOva1Plus = [],
      dataGenetix = [],
      dataOvaWatch = [];
    if (this.$ova1PlusCheckBox.checked) {
      dataOva1Plus = this.state.originalData.filter(
        (x) =>
          x.tests[0].testCode.toLowerCase() === "ova1" ||
          x.tests[0].testCode.toLowerCase() === "vds-125"
      );

      // printData = dataOva1Plus;
    }

    if (this.$genetixCheckBox.checked) {
      dataGenetix = this.state.originalData.filter((x) =>
        x.tests[0].testCode.toLowerCase().includes("genetix")
      );

      // printData = printData.concat(dataGenetix).unique();;
    }
    if (this.$ovaWatchCheckBox.checked) {
      dataOvaWatch = this.state.originalData.filter(
        (x) =>
          x.tests[0].testCode.toLowerCase() === "ovawatch" ||
          x.tests[0].testCode.toLowerCase() === "ovasight" ||
          x.tests[0].testCode.toLowerCase() === "vds-200"
      );

      // printData = printData.concat(dataOvaWatch).unique();;
    }

    printData = dataOva1Plus;
    printData = printData.concat(dataGenetix);
    printData = printData.concat(dataOvaWatch);

    this.callDataTable(printData);
  };

  renderFilterCheckboxes = () => {
    return (
      <>
        <label class="form-label" for={`filterCheckBoxes`}>
          Filter By
        </label>
        <div id={`ova1_q02`} class="demo-checkbox">
          <input
            defaultChecked
            onChange={(e) => this.handleFilterCheckboxesCheckUncheck()}
            ref={(_ref) => (this.$ova1PlusCheckBox = _ref)}
            data-val="Low Risk"
            type="checkbox"
            id={`clinicinfo_lowrisk`}
            name="clinicinfo_lowrisk"
            class=" filled-in"
          />
          <label for={`clinicinfo_lowrisk`}>Ova1Plus &nbsp; </label>
          <input
            defaultChecked
            onChange={(e) => this.handleFilterCheckboxesCheckUncheck()}
            ref={(_ref) => (this.$genetixCheckBox = _ref)}
            data-val="High Risk"
            type="checkbox"
            id={`clinicinfo_highrisk`}
            name="clinicinfo_highrisk"
            class=" filled-in"
          />
          <label for={`clinicinfo_highrisk`}>Genetix &nbsp; </label>
          <input
            defaultChecked
            onChange={(e) => this.handleFilterCheckboxesCheckUncheck()}
            ref={(_ref) => (this.$ovaWatchCheckBox = _ref)}
            data-val="Not Definitive"
            type="checkbox"
            id={`clinicinfo_NotDefinitive`}
            name="clinicinfo_NotDefinitive"
            class=" filled-in"
          />
          <label for={`clinicinfo_NotDefinitive`}>OvaWatch &nbsp; </label>
        </div>
      </>
    );
  };

  handlePaging = async (dt = null) => {
    let user = JSON.parse(localStorage.getItem("user"));
    console.log("OrdersContent.js->user: ", user);
    let token = {};
    if (process.env.REACT_APP_ENV !== "DEV") {
      if (!user) {
        const token = await this.context.instance.acquireTokenSilent({
          scopes: protectedResources.apiOrders.scopes,
          account: this.context.accounts[0],
        });

        ({
          data: { user },
        } = await axios.get(`${API_BASE_URL}/users/current`, {
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          },
        }));

        localStorage.setItem("user", JSON.stringify(user));
      }

      token = await this.context.instance.acquireTokenSilent({
        scopes: protectedResources.apiOrders.scopes,
        account: this.context.accounts[0],
      });
    }

    let _ordertype = "all";
    let _pageTitle = "Orders";

    if (window.location.href.includes("new-accessions")) {
      _ordertype = "new_accessions";
      _pageTitle = "New Accessions";
    } else if (window.location.href.includes("billing-ready")) {
      _ordertype = "billing_ready";
      _pageTitle = "Billing Ready Orders";
    } else if (window.location.href.includes("waiting-cmt-auth")) {
      _ordertype = "waiting_cmt_auth";
      _pageTitle = "Billing waiting cmt auth Orders";
    } else if (window.location.href.includes("billing-review")) {
      _ordertype = "billing_review";
      _pageTitle = "Billing Review Orders";
    } else if (window.location.href.includes("benefits-review")) {
      _ordertype = "benefits_review";
      _pageTitle = "Benefits Review Orders";
    } else if (window.location.href.includes("internal-auth")) {
      _ordertype = "internal_auth";
      _pageTitle = "Internally Authorized Orders";
    } else if (
      window.location.href.includes("resulted-report") ||
      window.location.href.includes("resulted_report")
    ) {
      _ordertype = "resulted_report";
      _pageTitle = "Final Resulted Report Orders";
    } else if (window.location.href.includes("research-orders-only")) {
      this.setState({ loading: false });
      _ordertype = "research_orders_only";
      _pageTitle = "Research Orders Only";
    } else if (window.location.href.includes("research-orders")) {
      _ordertype = "research_orders";
      _pageTitle = "Research Orders - RUO & Consent for Research";
    } else if (window.location.href.includes("my-accessions")) {
      //ali
      _ordertype = "my_accessions";
      _pageTitle = "My Acessions";
    }

    this.setState({ _pageTitle: _pageTitle });

    console.log(
      "this.state.serverSideRendering: ",
      this.state.serverSideRendering
    );
    if (this.state.serverSideRendering === "true") {
      this.setState({ loading: false }, () => {
        this.callDataTable(null, _ordertype);
      });
    } else {
      this.setState({ loading: true }, () => {
        axios({
          url: `${API_BASE_URL}/orders?type=${_ordertype}&userId=${user._id
            }&lastEvaluatedKey=${this.state.lastEvaluatedKey
              ? this.state.lastEvaluatedKey.toString()
              : null
            }&pageSize=25&searchText=${this.state.searchText}`,
          method: "GET",
          headers: {
            "Content-type": "Application/json",
            Authorization: `Bearer ${process.env.REACT_APP_ENV === "DEV"
                ? user.token
                : token.accessToken
              }`,
          },
        }).then(({ data }) => {
          console.log("data: ", data);
          // setOrders(data.orders);

          this.setState(
            {
              loading: false,
              originalData: data.orders,
              filteredData: data.orders,
              lastEvaluatedKey: data.lastEvaluatedKey,
            },
            () => {
              this.callDataTable(data.orders);

              // if (dt) {
              //   dt.search(this.state.searchText).draw();
              // }
            }
          );
        });
      });
    }
  };

  render() {
    if (!this.state.loading) {
      return (
        <div>
          <div class="block-header">
            <h2>{this.state._pageTitle}</h2>
          </div>
          <div class="row clearfix">
            <div class="col-md-12 col-xs-12">
              <div class="card orders-list">
                <div class="body">
                  <button
                    className="btn btn-raised g-bg-blue waves-effect"
                    onClick={() => {
                      this.handlePaging();
                    }}
                  >
                    Load more
                  </button>

                  <input
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        console.log("this.inpSearchRef: ", this.inpSearchRef);
                        this.setState(
                          {
                            searchText: this.inpSearchRef.value,
                            lastEvaluatedKey: null,
                          },
                          async () => {
                            await this.handlePaging();

                            this.inpSearchRef.value = this.state.searchText;
                          }
                        );
                      }
                    }}
                    style={{ marginLeft: "20px" }}
                    className=""
                    ref={(ref) => (this.inpSearchRef = ref)}
                    type="text"
                    id="inpSearch"
                    name="inpSearch"
                    value={this.state.searchText || ""}
                    onChange={() =>
                      this.setState({ searchText: this.inpSearchRef.value })
                    }
                  ></input>
                  <button
                    className="btn btn-raised g-bg-blue waves-effect"
                    onClick={() => {
                      console.log("this.inpSearchRef: ", this.inpSearchRef);
                      this.setState(
                        {
                          searchText: this.inpSearchRef.value,
                          lastEvaluatedKey: null,
                        },
                        async () => {
                          await this.handlePaging();

                          this.inpSearchRef.value = this.state.searchText;
                        }
                      );
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row clearfix">
            <div class="col-md-12 col-xs-12">
              <div class="card orders-list">
                <div class="body">
                  <div class="table-responsive">
                    <table
                      id="orderDataTable"
                      itemID="orderDataTable"
                      className="display"
                      width="100%"
                      ref={(el) => (this.orderDataTable = el)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DeleteModal
            onClose={this.showModal}
            show={this.state.showOrderModal}
          >
            <div>
              <span>
                Hold delete button for 3 seconds in order to confirm delete the
                Order
                {/* Patient {this.state.deletePatientName} */}
              </span>
            </div>
            <div class="delete-buttons">
              <button
                class="btn waves-effect btn-delete"
                onTouchStart={this.handleButtonPress}
                onTouchEnd={this.handleButtonRelease}
                onMouseDown={this.handleButtonPress}
                onMouseUp={this.handleButtonRelease}
                onMouseLeave={this.handleButtonRelease}
              // onClick={this.deletePatient}
              >
                Delete
              </button>
              <button
                class="btn btn-raised g-bg-blue waves-effect"
                onClick={this.showModal}
              >
                Cancel
              </button>
            </div>
            <div>
              {this.state.showProgressBar && (
                <ProgressBar entity={"order"} id={this.state.deleteOrderId} />
              )}
            </div>
          </DeleteModal>
        </div>
      );
    } else {
      return (
        <div>
          <div class="block-header">
            <h2>Orders</h2>
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/orders">Orders</a>
              </li>
              <li class="breadcrumb-item active">View All Orders</li>
            </ul>
          </div>
          <div class="row clearfix">
            <div class="col-md-12 col-xs-12">
              <div class="card orders-list">
                <div class="body">
                  <Spinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
