import helper from "./../../utils/helperPortal";
import axios from "axios";
import $ from "jquery";

const url_base = helper.getAPIBaseURI();

export const CREATE_PHYSICIANS = 'CREATE_PHYSICIANS'
export const CREATE_PHYSICIANS_SUCCESS = 'CREATE_PHYSICIANS_SUCCESS'
export const CREATE_PHYSICIANS_FAILURE = 'CREATE_PHYSICIANS_FAILURE'

export const PHYSICIANS_PRIMARY_ADDRESS = 'PHYSICIANS_PRIMARY_ADDRESS'

export const createPhysicians = () => ({ type: CREATE_PHYSICIANS })
export const createPhysiciansSuccess = physicians => ({
    type: CREATE_PHYSICIANS_SUCCESS,
    payload: physicians,
})
export const createPhysiciansFailure = () => ({ type: CREATE_PHYSICIANS_FAILURE })

export async function createPhysicianApi(data) {
    // return async dispatch => {
    //     dispatch(createPhysicians())

    try {
        let opt = {
            url: `${url_base}/physicians`,
            method: "POST",
            data: data,
            headers: {
                Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFsYW1yZXpvYW51bEBnbWFpbC5jb20iLCJpYXQiOjE1OTIzODQ0NjR9.Vlqn6clmjUMKFdtMd2NOxrJbu8n0PpPSoH_eGXVkSEM`
            }

        };
        console.log("creating physician with url: ", opt);
        let ret = await axios(opt);
        console.log("ret.data: ", ret.data);


        let retdata = ret.data;

        retdata.physician.isok = true;
        return retdata;
        // dispatch(createPhysiciansSuccess(retdata));
    } catch (error) {
        return error;
        // dispatch(createPhysiciansFailure());
    }
}
// }
// export function fetchPhysicians() {
//   return async dispatch => {
//     dispatch(createPhysicians())

//     try {
//       const response = await fetch('https://jsonplaceholder.typicode.com/physicians')
//       const data = await response.json()

//       dispatch(createPhysiciansSuccess(data))
//     } catch (error) {
//       dispatch(createPhysiciansFailure())
//     }
//   }
// }
