import React from "react";

export default function Search() {
    return (
        <div class="search-bar">
            <div class="search-icon"> <i class="material-icons">search</i> </div>
            <input type="text" placeholder="Explor adminX..."/>
            <div class="close-search"> <i class="material-icons">close</i> </div>
        </div>
    );
  }
  
