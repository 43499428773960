import React, { PureComponent, useState } from "react";

import PropTypes from 'prop-types';
import $ from 'jquery';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import helper from './../utils/helperPortal';

const BillingInformationForm = ({ prefix, billinfo_dobFunc }) => {
    const [billinfo_dob, setBillingInfo_DOB] = useState(null);

    const onChangeBillingDoB = date => {
        setBillingInfo_DOB(date);

        const dateStr = moment(date).format('YYYY-MM-DD');
        $(`#${prefix}billinginfo_DOB`).val(dateStr);

        if (billinfo_dobFunc) {
            billinfo_dobFunc(dateStr);
        }
    };

    const showInsuranceTitle = () => {
        $(`#${prefix}title_insurance_container`).removeClass(" hideFormContent");
        $(`#${prefix}title_insurance_container`).addClass(" showFormContent");
    }

    const hideInsuranceTitle = () => {
        $(`#${prefix}title_insurance_container`).addClass(" hideFormContent");
        $(`#${prefix}title_insurance_container`).removeClass(" showFormContent");
    }

    const showPrimaryAreaClick = () => {
        $(`#${prefix}primary_insurance_container`).removeClass(" hideFormContent");
        $(`#${prefix}primary_insurance_container`).addClass(" showFormContent");
    };
    const hidePrimaryAreaClick = () => {
        $(`#${prefix}primary_insurance_container`).addClass(" hideFormContent");
        $(`#${prefix}primary_insurance_container`).removeClass(" showFormContent");
    };

    const showSecondaryAreaClick = () => {
        $(`#${prefix}secondary_insurance_container`).removeClass(" hideFormContent");
        $(`#${prefix}secondary_insurance_container`).addClass(" showFormContent");
    };
    const hideSecondaryAreaClick = () => {
        $(`#${prefix}secondary_insurance_container`).addClass(" hideFormContent");
        $(`#${prefix}secondary_insurance_container`).removeClass(" showFormContent");
    };

    const showTertiaryAreaClick = () => {
        $(`#${prefix}tertiary_insurance_container`).removeClass(" hideFormContent");
        $(`#${prefix}tertiary_insurance_container`).addClass(" showFormContent");
    };
    const hideTertiaryAreaClick = () => {
        $(`#${prefix}tertiary_insurance_container`).addClass(" hideFormContent");
        $(`#${prefix}tertiary_insurance_container`).removeClass(" showFormContent");
    };

    const showInsuranceInsured = () => {
        $(`#${prefix}insured_info_container`).removeClass(" hideFormContent");
        $(`#${prefix}insured_info_container`).addClass(" showFormContent");
    }

    const hideInsuranceInsured = () => {
        $(`#${prefix}insured_info_container`).addClass(" hideFormContent");
        $(`#${prefix}insured_info_container`).removeClass(" showFormContent");
    }

    const BillingInformationChechAll = () => {
        var billinfo_PrivateInsurance = document.getElementById(`${prefix}billinfo_PrivateInsurance`);
        var billinfo_Medicare = document.getElementById(`${prefix}billinfo_Medicare`);
        var billinfo_PatientSelfPay = document.getElementById(`${prefix}billinfo_PatientSelfPay`);
        var billinfo_Medicaid = document.getElementById(`${prefix}billinfo_Medicaid`);
        var billinfo_OrderingFacility = document.getElementById(`${prefix}billinfo_OrderingFacility`);

        if (billinfo_PrivateInsurance.checked == true) {
            showInsuranceTitle();
            showPrimaryAreaClick();
            showSecondaryAreaClick();
            showTertiaryAreaClick();
            showInsuranceInsured();

        } else if (billinfo_Medicare.checked == true) {
            showInsuranceTitle();
            showPrimaryAreaClick();
            hideSecondaryAreaClick();
            hideTertiaryAreaClick();
            showInsuranceInsured();
        } else if (billinfo_Medicaid.checked == true) {
            showInsuranceTitle();
            showPrimaryAreaClick();
            hideSecondaryAreaClick();
            hideTertiaryAreaClick();
            showInsuranceInsured();
        } else if (billinfo_PatientSelfPay.checked == true) {
            hidePrimaryAreaClick();
            hideSecondaryAreaClick();
            hideTertiaryAreaClick();
            hideInsuranceTitle();
            hideInsuranceInsured();
        } else {
            hideInsuranceTitle();
            hidePrimaryAreaClick();
            hideSecondaryAreaClick();
            hideTertiaryAreaClick();
            hideInsuranceInsured();
        }
    };

    const BillingInformationPrivateInsuranceClick = () => {

        var billinfo_PrivateInsurance = document.getElementById(`${prefix}billinfo_PrivateInsurance`);
        document.getElementById(`${prefix}billinfo_PatientSelfPay`).checked = false;
        document.getElementById(`${prefix}billinfo_Medicaid`).checked = false;
        document.getElementById(`${prefix}billinfo_FreeTrial`).checked = false;
        document.getElementById(`${prefix}billinfo_OrderingFacility`).checked = false;

        if (billinfo_PrivateInsurance.checked == true) {
            showPrimaryAreaClick();
            showSecondaryAreaClick();
            showTertiaryAreaClick();
        } else {
            hidePrimaryAreaClick();
            hideSecondaryAreaClick();
            hideTertiaryAreaClick();
        }

        BillingInformationChechAll();
    };

    const BillingInformationMedicareClick = () => {
        var billinfo_Medicare = document.getElementById(`${prefix}billinfo_Medicare`);
        document.getElementById(`${prefix}billinfo_PatientSelfPay`).checked = false;
        document.getElementById(`${prefix}billinfo_Medicaid`).checked = false;
        document.getElementById(`${prefix}billinfo_FreeTrial`).checked = false;
        document.getElementById(`${prefix}billinfo_OrderingFacility`).checked = false;

        if (billinfo_Medicare.checked == true) {
            showPrimaryAreaClick();
            //showSecondaryAreaClick();
        } else {
            hidePrimaryAreaClick();
            hideSecondaryAreaClick();
            hideTertiaryAreaClick();
        }
        BillingInformationChechAll();
    }
    const BillingInformationPatientSelfPayClick = () => {
        var billinfo_PatientSelfPay = document.getElementById(`${prefix}billinfo_PatientSelfPay`);

        document.getElementById(`${prefix}billinfo_PrivateInsurance`).checked = false;
        document.getElementById(`${prefix}billinfo_Medicare`).checked = false;
        document.getElementById(`${prefix}billinfo_Medicaid`).checked = false;
        document.getElementById(`${prefix}billinfo_FreeTrial`).checked = false;
        document.getElementById(`${prefix}billinfo_OrderingFacility`).checked = false;

        if (billinfo_PatientSelfPay.checked == true) {
            hidePrimaryAreaClick();
            hideSecondaryAreaClick();
            hideTertiaryAreaClick();
        }
        BillingInformationChechAll();
    }

    const BillingInformationFreeTrialClick = () => {
        var billinfo_FreeTrial = document.getElementById(`${prefix}billinfo_FreeTrial`);

        document.getElementById(`${prefix}billinfo_PrivateInsurance`).checked = false;
        document.getElementById(`${prefix}billinfo_Medicare`).checked = false;
        document.getElementById(`${prefix}billinfo_PatientSelfPay`).checked = false;
        document.getElementById(`${prefix}billinfo_Medicaid`).checked = false;
        document.getElementById(`${prefix}billinfo_OrderingFacility`).checked = false;

        if (billinfo_FreeTrial.checked == true) {
            hidePrimaryAreaClick();
            hideSecondaryAreaClick();
            hideTertiaryAreaClick();
        }
        BillingInformationChechAll();
    }

    const BillingInformationMedicaidClick = () => {
        var billinfo_Medicaid = document.getElementById(`${prefix}billinfo_Medicaid`);

        document.getElementById(`${prefix}billinfo_PrivateInsurance`).checked = false;
        document.getElementById(`${prefix}billinfo_Medicare`).checked = false;
        document.getElementById(`${prefix}billinfo_PatientSelfPay`).checked = false;
        document.getElementById(`${prefix}billinfo_FreeTrial`).checked = false;
        document.getElementById(`${prefix}billinfo_OrderingFacility`).checked = false;

        if (billinfo_Medicaid.checked == true) {
            showPrimaryAreaClick();
            //showSecondaryAreaClick();
        } else {
            hidePrimaryAreaClick();
            hideSecondaryAreaClick();
            hideTertiaryAreaClick();
        }
        BillingInformationChechAll();
    }
    const BillingInformationOrderingFacilityClick = () => {
        var billinfo_OrderingFacility = document.getElementById(`${prefix}billinfo_OrderingFacility`);

        document.getElementById(`${prefix}billinfo_PrivateInsurance`).checked = false;
        document.getElementById(`${prefix}billinfo_Medicare`).checked = false;
        document.getElementById(`${prefix}billinfo_PatientSelfPay`).checked = false;
        document.getElementById(`${prefix}billinfo_Medicaid`).checked = false;
        document.getElementById(`${prefix}billinfo_FreeTrial`).checked = false;

        if (billinfo_OrderingFacility.checked == true) {
            hidePrimaryAreaClick();
            hideSecondaryAreaClick();
            hideTertiaryAreaClick();
        }
        BillingInformationChechAll();
    }

    $(document).ready(() => {

        $(`#${prefix}btnBillingInfoFormReset`).click(() => {
            $(`#${prefix}billingoInfoContainer`).find(':input').map((i, elem) => {
                const _type = $(elem).attr('type');
                console.log("_type: ", _type);
                helper.resetForm(_type, elem);
            });

            console.log("billinfo_dob: ", billinfo_dob);
            if (billinfo_dob) {
                setBillingInfo_DOB(null);
            }
        });
    });

    return (
        <>
            <div id={`${prefix}billingoInfoContainer`} class="panel-body">
                <div class="row clearfix" id="billing_info_container">
                    <div class="col-md-12">
                        <div class="form-group required">
                            <label class="form-label" for={`${prefix}BillingInformation`}>Billing Information</label>
                            <div class="demo-checkbox">
                                <input type="checkbox" id={`${prefix}billinfo_PrivateInsurance`} name={`${prefix}billinfo_PrivateInsurance`} class="filled-in" onClick={() => {
                                    BillingInformationPrivateInsuranceClick()
                                }} />
                                <label for={`${prefix}billinfo_PrivateInsurance`}>Private Insurance &nbsp; </label>
                                <input type="checkbox" id={`${prefix}billinfo_Medicare`} name={`${prefix}billinfo_Medicare`} class="filled-in" onClick={() => {
                                    BillingInformationMedicareClick()
                                }} />
                                <label for={`${prefix}billinfo_Medicare`}>Medicare<sup>1</sup> &nbsp; </label>
                                <input type="checkbox" id={`${prefix}billinfo_PatientSelfPay`} name={`${prefix}billinfo_PatientSelfPay`} class="filled-in" onClick={() => {
                                    BillingInformationPatientSelfPayClick()
                                }} />
                                <label for={`${prefix}billinfo_PatientSelfPay`}>Patient Self-Pay &nbsp; </label>
                                <input type="checkbox" id={`${prefix}billinfo_Medicaid`} name={`${prefix}billinfo_Medicaid`} class="filled-in" onClick={() => {
                                    BillingInformationMedicaidClick()
                                }} />
                                <label for={`${prefix}billinfo_Medicaid`}>Medicaid &nbsp; </label>
                                <input type="checkbox" id={`${prefix}billinfo_FreeTrial`} name={`${prefix}billinfo_FreeTrial`} class="filled-in" onClick={() => {
                                    BillingInformationFreeTrialClick()
                                }} />
                                <label for={`${prefix}billinfo_FreeTrial`}>Free Trial &nbsp; </label>
                                <input type="checkbox" id={`${prefix}billinfo_OrderingFacility`} name={`${prefix}billinfo_OrderingFacility`} class="filled-in" onClick={() => {
                                    BillingInformationOrderingFacilityClick()
                                }} />
                                <label for={`${prefix}billinfo_OrderingFacility`}>Ordering Facility (Client Bill) &nbsp; </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row clearfix">
                    <div class="col-md-12">
                        <p class="billInfoOva"><sup>1</sup>By ordering this test for your Medicare physician, you are certifying that the physician has met the requirements for use <br />i.e has an ovarian mass, has surgery planned and is over 18 years of age</p>
                        <p id={`${prefix}title_insurance_container`} class=" hideFormContent">Insurance Information</p>
                    </div>
                </div>
                <div id={`${prefix}primary_insurance_container`} class=" hideFormContent">
                    <div id="primary_insurance_container" class="row clearfix">
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for={`${prefix}PrimaryInsuranceCarrier`}>Primary Insurance Carrier </label>
                                <div class="form-line">
                                    <input type="text" id={`${prefix}billinfo_PrimaryInsuranceCarrier`} class="form-control required" placeholder="Primary Insurance Carrier" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for={`${prefix}MemberID`}>Member ID# </label>
                                <div class="form-line">
                                    <input type="text" id={`${prefix}billinfo_MemberID`} class="form-control required" placeholder="Member ID" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for={`${prefix}GroupID`}>Group ID# </label>
                                <div class="form-line">
                                    <input type="text" id={`${prefix}billinfo_GroupID`} class="form-control required" placeholder="Group ID#" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id={`${prefix}secondary_insurance_container`} class=" hideFormContent">
                    <div id="secondary_insurance_container" class="row clearfix">
                        <div class="col-md-4">
                            <div class="form-group ">
                                <label class="form-label" for={`${prefix}SecondaryInsuranceCarrier`}>Secondary Insurance Carrier </label>
                                <div class="form-line">
                                    <input type="text" id={`${prefix}billinfo_SecondaryInsuranceCarrier`} class="form-control " placeholder="Secondary Insurance Carrier" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group ">
                                <label class="form-label" for={`${prefix}SecondaryMemberID`}>Member ID# </label>
                                <div class="form-line">
                                    <input type="text" id={`${prefix}billinfo_SecondaryMemberID`} class="form-control " placeholder="Member ID" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group ">
                                <label class="form-label" for={`${prefix}SecondaryGroupID`}>Group ID# </label>
                                <div class="form-line">
                                    <input type="text" id={`${prefix}billinfo_SecondaryGroupID`} class="form-control " placeholder="Group ID#" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id={`${prefix}tertiary_insurance_container`} class=" hideFormContent">
                    <div id="tertiary_insurance_container" class="row clearfix">
                        <div class="col-md-4">
                            <div class="form-group ">
                                <label class="form-label" for={`${prefix}TertiaryInsuranceCarrier`}>Tertiary Insurance Carrier </label>
                                <div class="form-line">
                                    <input type="text" id={`${prefix}billinfo_TertiaryInsuranceCarrier`} class="form-control " placeholder="Tertiary Insurance Carrier" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group ">
                                <label class="form-label" for={`${prefix}TertiaryMemberID`}>Member ID# </label>
                                <div class="form-line">
                                    <input type="text" id={`${prefix}billinfo_TertiaryMemberID`} class="form-control " placeholder="Member ID" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group ">
                                <label class="form-label" for={`${prefix}TertiaryGroupID`}>Group ID# </label>
                                <div class="form-line">
                                    <input type="text" id={`${prefix}billinfo_TertiaryGroupID`} class="form-control " placeholder="Group ID#" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div id={`${prefix}insured_info_container`} class=" hideFormContent">
                    <div id="insured_info" class="row clearfix">
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for={`${prefix}NameofInsured`}>Name of Insured </label>
                                {/* <div class="form-line">
                                    <input type="text" id={`${prefix}billinfo_NameofInsured`} class="form-control required" placeholder="Name of Insured" />
                                </div> */}
                                <div class="form-line">
                                    <div className="row clearfix">
                                        <div className="col-md-6">
                                            <input
                                                type="text"

                                                id={`${prefix}billinfo_FirstNameofInsured`}
                                                class="form-control required"
                                                placeholder="First Name"
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <input
                                                type="text"

                                                id={`${prefix}billinfo_LastNameofInsured`}
                                                class="form-control required"
                                                placeholder="Last Name"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for={`${prefix}DOB`}>DOB (mm/dd/yyyy) </label>
                                <div class="form-line">
                                    <DatePicker
                                        id={`${prefix}billinfoC_dob`}
                                        className={"form-control required"}
                                        selected={billinfo_dob}
                                        onChange={onChangeBillingDoB}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />


                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for={`${prefix}Relationshiptoinsured`}>Relationship to insured</label>
                                <div class="form-line">
                                    <select class="form-control show-tick" id={`${prefix}billinfo_RelationshipInsured`}>
                                        {/* <option value="">-- Relationship to insured --</option> */}
                                        <option value="Self" selected>Self</option>
                                        <option value="Spouse">Spouse</option>
                                        <option value="Child">Child</option>
                                        <option value="Other">Other</option>
                                        <option value="Unknown">Unknown</option>
                                    </select>
                                    {/* <input type="text" id={`${prefix}billinfo_RelationshipInsured`} class="form-control required" placeholder="Relationship to insured" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row clearfix">
                    <div class="col-md-12 text-right">
                        <button id={`${prefix}btnBillingInfoFormReset`} class="btn btn-light">Reset Form</button>
                        <button id={`${prefix}btnBillingInformationFormNext`} class="btn btn-light">Next</button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BillingInformationForm;