/* eslint-disable react/jsx-pascal-case */
import { MsalContext } from "@azure/msal-react";
import { message } from "antd";
import axios from "axios";
import $ from "jquery";
import moment from "moment";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import AsyncSelect from "react-select/async";
import Toast from "../mycomponents/toast/Toast";
import { protectedResources } from "./../authConfig";
import helper from "./../utils/helper";
import Billing_Information from "./add_edit_order_components/Billing_Information";
import DiagnosisCodes from "./add_edit_order_components/DiagnosisCodes";
import Family_histry from "./add_edit_order_components/Family_histry";
import Kit_Shipping_Information from "./add_edit_order_components/Kit_Shipping_Information";
import Notes_and_Certify from "./add_edit_order_components/Notes_and_Certify";
import PatientInformation from "./add_edit_order_components/PatientInformation";
import PhysicianInformation from "./add_edit_order_components/PhysicianInformation";
import Retrive_Reprot from "./add_edit_order_components/RetriveReport";
import BillingNotes from "./BillingNotes";
import CmtForm from "./CmtForm";
import TopActions from "./add_edit_order_components/TopActions";


const API_BASE_URL = helper.getAPIBaseURI();


const noPreviousFamilyCancerHistoryClick = () => {
  var pfHistory_yesCancer = document.getElementById("pfHistory_yesCancer");
};

//hboc1_q09 Two breast cancers (bilateral) in one relative

//hboc1_q011 Ovarian cancer at any age

//family history

let me = null;
let hbocaccessToken = "";
let reportId = "";

export default class AddEditOrderHBOC1 extends React.PureComponent {
  static contextType = MsalContext;

  constructor(props) {
    super(props);

    this.state = {
      user: null,
      notesText: "",
      loading: true,
      buttonText: "Create",
      frmprefix: "GTCS_",

      toastMessage: "",
      list: [],
      toastList: [],
      orderData: [],

      IndicationsTest_otherValue: "",

      //physician info
      phyinfo_Organization_account: "",
      phyinfo_first_name: "",
      phyinfo_last_name: "",
      phyinfo_email: "",
      phyinfo_npi: "",
      phyinfoC_address: "",
      phyinfoC_practiceLocation: "",
      phyinfoC_city: "",
      phyinfo_state: "",
      phyinfoC_zipCode: "",
      phyinfoC_Phone: "",
      phyinfoC_fax: "",
      phyinfoC_Faxcopyto: "",

      //patient info
      patinfo_firstname: "",
      patinfo_lastname: "",
      patinfo_address: "",
      selectedState: "ALABAMA",
      patinfo_State: "",
      patinfo_city: "",
      patinfo_zipcode: "",
      patinfo_dob: "",
      patinfo_PhoneNumber: "",
      patinfo_emailaddress: "",
      patinfo_OtherEthnicitycity: "",
      patinfo_ethnicity: "",
      ethnicityArray: {},
      patinfo_gender: "",

      //billing info
      selectedPayor: {},
      allPayors: [],
      billinfo_PrimaryInsuranceCarrier: "",
      billinfo_planId: "",
      billinfo_MemberID: "",
      billinfo_GroupID: "",

      billinfo_SecondaryInsuranceCarrier: "",
      billinfo_SecondaryplanId: "",
      billinfo_SecondaryMemberID: "",
      billinfo_SecondaryGroupID: "",

      billinfo_TertiaryInsuranceCarrier: "",
      billinfo_TertiaryMemberID: "",
      billinfo_TertiaryGroupID: "",
      billinfo_TertiaryPlanID: "",

      billinfo_FirstNameofInsured: "",
      billinfo_LastNameofInsured: "",

      billinfoC_dob: "",
      billinfo_RelationshipInsured: "",
      //edit billing portion
      privateInsurance: false,
      patientSelfPay: false,
      medicare: false,
      medicaid: false,
      orderingFacility: false,
      freeTrial: false,
      isBillableOrder: false,

      //Family History
      pfHistory_noCancer: false,
      pfHistory_BrOvPanAnyAge: false,
      pfHistory_ColorUterineAnyAge: false,
      pfHistory_previousCancer: false,
      pfHistory_SomaticMutationProf: false,
      pfHistory_PrevGermltesting: false,
      pfHistory_FBrOvPanAnyAge: false,
      pfHistory_FTwoBreasCanAge: false,
      pfHistory_FThrBreasCanAge: false,
      pfHistory_OvarianCanAge: false,
      pfHistory_PancreaticCanAge: false,
      pfHistory_MaleBreastCanAge: false,
      pfHistory_MetaProstateCanAge: false,
      pfHistory_ColorCanAge: false,
      pfHistory_UterineCanAge: false,
      pfHistory_otherCan: false,
      pfHistory_geHerediraryCan: false,
      pfHistory_PCurTreat_boneMar: false,
      pfHistory_PCurTreat_Chemox: false,
      pfHistory_PCurTreat_Radia: false,
      pfHistory_PCurTreat_Surgery: false,
      pfHistory_BrOvPanAnyAgeCan1: "",
      pfHistory_BrOvPanAnyAgeCan2: "",
      pfHistory_BrOvPanAnyAgeCan3: "",
      pfHistory_ColorUterineAnyAgeCan1: "",
      pfHistory_ColorUterineAnyAgeCan2: "",

      //hboc1_q03
      pfHistory_prevCanDefine: "",
      //hboc1_q04
      pfHistory_SoMutaProfDefine: "",
      //hboc1_q05
      pfHistory_PrevGermltestDefine: "",
      //hboc1_q06
      pfHistory_BrOvPanAnyAgeCan: "",
      pfHistory_BrOvPanAnyAgeInfo: "",
      //hboc1_q07  Colorectal or uterine cancer at 64 or younger?
      pfHistory_ColorUterineAnyAgeCan: "",
      pfHistory_ColorUterineAnyAgeInfo: "",

      // Family HIstory
      pfHistory_AnyAge_Mothside: false,
      pfHistory_AnyAge_Fathside: false,
      pfHistory_AnyAge_relative: "",
      pfHistory_AnyAge_age: "",

      pfHistory_AnyAge_dataid: "",

      hboc1_q08_1_showResult: "",
      hboc1_q08_1_pfHistory_AnyAgeValuesJustShow: "",
      hboc1_q08_1_pfHistory_AnyAgeValues: "",
      hboc1_q09_1_showResult: "",
      hboc1_q09_1_pfHistory_AnyAgeValuesJustShow: "",
      hboc1_q09_1_pfHistory_AnyAgeValues: "",
      hboc1_q010_1_showResult: "",
      hboc1_q010_1_pfHistory_AnyAgeValuesJustShow: "",
      hboc1_q010_1_pfHistory_AnyAgeValues: "",
      hboc1_q011_1_showResult: "",
      hboc1_q011_1_pfHistory_AnyAgeValuesJustShow: "",
      hboc1_q011_1_pfHistory_AnyAgeValues: "",
      hboc1_q012_1_showResult: "",
      hboc1_q012_1_pfHistory_AnyAgeValuesJustShow: "",
      hboc1_q012_1_pfHistory_AnyAgeValues: "",
      hboc1_q013_1_showResult: "",
      hboc1_q013_1_pfHistory_AnyAgeValuesJustShow: "",
      hboc1_q013_1_pfHistory_AnyAgeValues: "",
      hboc1_q014_1_showResult: "",
      hboc1_q014_1_pfHistory_AnyAgeValuesJustShow: "",
      hboc1_q014_1_pfHistory_AnyAgeValues: "",
      hboc1_q015_1_showResult: "",
      hboc1_q015_1_pfHistory_AnyAgeValuesJustShow: "",
      hboc1_q015_1_pfHistory_AnyAgeValues: "",
      hboc1_q016_1_showResult: "",
      hboc1_q016_1_pfHistory_AnyAgeValuesJustShow: "",
      hboc1_q016_1_pfHistory_AnyAgeValues: "",
      hboc1_q017_1_showResult: "",
      hboc1_q017_1_pfHistory_AnyAgeValuesJustShow: "",
      hboc1_q017_1_pfHistory_AnyAgeValues: "",

      //hboc1_q018
      pfHistory_otherCan_info: "",
      pfHistory_geHerediraryCan_info: "",
      pfHistory_OtherClinicalHistory: "",
      pfHistory_PCurTreat_SurgDe: "",

      otherICD10Codes: [],
      otherICD10Code: "",
      diagonisisCodes: [],
      kitShip_notes: "",
      kitShip_PatMobPhlebotomy: null,
      kitShip_PatblooddrawQuest: null,
      kitShip_PatOthers: null,
      kitShip_PatOthersInfo: "",
      reportDate: "",

      updatedAt: Date.now(),
    };

    this.IndicationsTest_otherValue = React.createRef();

    //physician
    this.phyinfo_Organization_account = React.createRef();
    this.phyinfo_first_name = React.createRef();
    this.phyinfo_last_name = React.createRef();
    this.phyinfo_email = React.createRef();
    this.phyinfo_npi = React.createRef();
    this.phyinfoC_address = React.createRef();
    this.phyinfoC_practiceLocation = React.createRef();
    this.phyinfoC_city = React.createRef();
    this.phyinfo_state = React.createRef();
    this.phyinfoC_zipCode = React.createRef();
    this.phyinfoC_Phone = React.createRef();
    this.phyinfoC_fax = React.createRef();
    this.phyinfoC_Faxcopyto = React.createRef();

    //patient info
    this.patinfo_firstname = React.createRef();
    this.patinfo_lastname = React.createRef();
    this.patinfo_address = React.createRef();
    this.patinfo_State = React.createRef();
    this.patinfo_city = React.createRef();
    this.patinfo_zipcode = React.createRef();
    this.patinfo_dob = React.createRef();
    this.patinfo_PhoneNumber = React.createRef();
    this.patinfo_emailaddress = React.createRef();
    this.patinfo_OtherEthnicitycity = React.createRef();
    this.patinfo_ethnicity = React.createRef();
    this.patinfo_gender = React.createRef();

    //billing info
    this.billinfo_PrimaryInsuranceCarrier = React.createRef();
    //this.billinfo_planId = React.createRef();
    this.billinfo_MemberID = React.createRef();
    this.billinfo_GroupID = React.createRef();

    this.billinfo_SecondaryInsuranceCarrier = React.createRef();
    //this.billinfo_SecondaryplanId = React.createRef();
    this.billinfo_SecondaryMemberID = React.createRef();
    this.billinfo_SecondaryGroupID = React.createRef();

    this.billinfo_TertiaryInsuranceCarrier = React.createRef();
    this.billinfo_TertiaryMemberID = React.createRef();
    this.billinfo_TertiaryGroupID = React.createRef();

    this.billinfo_FirstNameofInsured = React.createRef();
    this.billinfo_LastNameofInsured = React.createRef();

    this.billinfoC_dob = React.createRef();
    this.billinfo_RelationshipInsured = React.createRef();

    //Family History
    this.pfHistory_noCancer = React.createRef();
    this.pfHistory_BrOvPanAnyAge = React.createRef();
    this.pfHistory_BrOvPanAnyAge2 = React.createRef();
    this.pfHistory_ColorUterineAnyAge = React.createRef();
    this.pfHistory_ColorUterineAnyAge2 = React.createRef();
    this.pfHistory_previousCancer = React.createRef();
    this.pfHistory_SomaticMutationProf = React.createRef();
    this.pfHistory_PrevGermltesting = React.createRef();
    this.pfHistory_FBrOvPanAnyAge = React.createRef();
    this.pfHistory_FBrOvPanAnyAge2 = React.createRef();
    this.pfHistory_FTwoBreasCanAge = React.createRef();
    this.pfHistory_FTwoBreasCanAge2 = React.createRef();
    this.pfHistory_FThrBreasCanAge = React.createRef();
    this.pfHistory_FThrBreasCanAge2 = React.createRef();
    this.pfHistory_OvarianCanAge = React.createRef();
    this.pfHistory_OvarianCanAge2 = React.createRef();
    this.pfHistory_PancreaticCanAge = React.createRef();
    this.pfHistory_PancreaticCanAge2 = React.createRef();
    this.pfHistory_MaleBreastCanAge = React.createRef();
    this.pfHistory_MaleBreastCanAge2 = React.createRef();
    this.pfHistory_MetaProstateCanAge = React.createRef();
    this.pfHistory_MetaProstateCanAge2 = React.createRef();
    this.pfHistory_ColorCanAge = React.createRef();
    this.pfHistory_ColorCanAge2 = React.createRef();
    this.pfHistory_UterineCanAge = React.createRef();
    this.pfHistory_AshJewishAncesCanAge2 = React.createRef();
    this.pfHistory_AshJewishAncesCanAge = React.createRef();
    this.pfHistory_UterineCanAge2 = React.createRef();
    this.pfHistory_otherCan = React.createRef();
    this.pfHistory_otherCan2 = React.createRef();
    this.pfHistory_geHerediraryCan = React.createRef();
    this.pfHistory_geHerediraryCan2 = React.createRef();
    this.pfHistory_PCurTreat_boneMar = React.createRef();
    this.pfHistory_PCurTreat_Chemo = React.createRef();
    this.pfHistory_PCurTreat_Radia = React.createRef();
    this.pfHistory_PCurTreat_Surgery = React.createRef();
    this.pfHistory_BrOvPanAnyAgeCan1 = React.createRef();
    this.pfHistory_BrOvPanAnyAgeCan2 = React.createRef();
    this.pfHistory_BrOvPanAnyAgeCan3 = React.createRef();
    this.pfHistory_ColorUterineAnyAgeCan1 = React.createRef();
    this.pfHistory_ColorUterineAnyAgeCan2 = React.createRef();

    //hboc1_q03
    this.pfHistory_prevCanDefine = React.createRef();
    //hboc1_q04
    this.pfHistory_SoMutaProfDefine = React.createRef();
    //hboc1_q05
    this.pfHistory_PrevGermltestDefine = React.createRef();
    //hboc1_q06
    this.pfHistory_BrOvPanAnyAgeCan = React.createRef();
    this.pfHistory_BrOvPanAnyAgeInfo = React.createRef();
    //hboc1_q07  Colorectal or uterine cancer at 64 or younger?
    this.pfHistory_ColorUterineAnyAgeCan = React.createRef();
    this.pfHistory_ColorUterineAnyAgeInfo = React.createRef();
    //hboc1_q018
    this.pfHistory_otherCan_info = React.createRef();
    this.pfHistory_geHerediraryCan_info = React.createRef();
    this.pfHistory_OtherClinicalHistory = React.createRef();
    this.pfHistory_PCurTreat_SurgDe = React.createRef();

    this.hBOCdiagnosis_c500 = React.createRef();
    this.hBOCdiagnosis_z1501 = React.createRef();
    this.hBOCdiagnosis_z1502 = React.createRef();
    this.hBOCdiagnosis_z1503 = React.createRef();
    this.hBOCdiagnosis_c481 = React.createRef();
    this.hBOCdiagnosis_c561 = React.createRef();
    this.hBOCdiagnosis_z1504 = React.createRef();
    this.hBOCdiagnosis_c562 = React.createRef();
    this.hBOCdiagnosis_z1509 = React.createRef();
    this.hBOCdiagnosis_c569 = React.createRef();
    this.hBOCdiagnosis_z1581 = React.createRef();
    this.hBOCdiagnosis_c5700 = React.createRef();
    this.hBOCdiagnosis_C250 = React.createRef();
    this.hBOCdiagnosis_c5701 = React.createRef();
    this.hBOCdiagnosis_C251 = React.createRef();
    this.hBOCdiagnosis_c5702 = React.createRef();
    this.hBOCdiagnosis_C252 = React.createRef();
    this.hBOCdiagnosis_c61 = React.createRef();
    this.hBOCdiagnosis_C253 = React.createRef();
    this.hBOCdiagnosis_D0511 = React.createRef();
    this.hBOCdiagnosis_C254 = React.createRef();
    this.hBOCdiagnosis_D0512 = React.createRef();
    this.hBOCdiagnosis_C257 = React.createRef();
    this.hBOCdiagnosis_Z8507 = React.createRef();
    this.hBOCdiagnosis_C258 = React.createRef();
    this.hBOCdiagnosis_Z853 = React.createRef();
    this.hBOCdiagnosis_C259 = React.createRef();
    this.hBOCdiagnosis_Z8543 = React.createRef();
    this.hBOCdiagnosis_Z8546 = React.createRef();

    this.OtherICD10Codes = React.createRef();
    this.diagonisisCodes = React.createRef();
    this.kitShip_PatOthersInfo = React.createRef();
    this.kitShip_notes = React.createRef();

    this.orderId = null;
    this._id = null;
    me = this;
  }

  noPreviousPersonalCancerHistoryClick = () => {
    //var pfHistory_noCancer = document.getElementById("pfHistory_noCancer");

    if (this.pfHistory_noCancer.checked) {
      this.setState({ pfHistory_noCancer: true });
      $("#personalHistoryArea")
        .find("input")
        .map((i, elem) => {
          $(elem).prop("checked", false);
          $(elem).prop("disabled", true);
        });
      this.previousCancerHistoryClick();
      this.SomaticMutationProfClick();
      this.PrevGermltestingClick();
      this.pfHistory_BrOvPanAnyAge2.checked = true;
      this.pfHistory_BrOvPanAnyAge2Click();
      this.pfHistory_ColorUterineAnyAge2.checked = true;
      // document.getElementById("pfHistory_ColorUterineAnyAge2").checked = true;
      this.pfHistory_ColorUterineAnyAge2Click();
    } else {
      this.setState({ pfHistory_noCancer: false });
      $("#personalHistoryArea")
        .find("input")
        .map((i, elem) => {
          $(elem).prop("checked", false);
          $(elem).prop("disabled", false);
        });
    }
  };
  pfHistory_BrOvPanAnyAgeClick = () => {
    console.log("cliked yes to this.pfHistory_BrOvPanAnyAge");
    console.log(this.pfHistory_BrOvPanAnyAge.checked);

    if (this.pfHistory_BrOvPanAnyAge.checked) {
      this.setState({ pfHistory_BrOvPanAnyAge: true });
    }
  };
  pfHistory_BrOvPanAnyAge2Click = () => {
    if (this.pfHistory_BrOvPanAnyAge2.checked) {
      this.setState({ pfHistory_BrOvPanAnyAge: false });
    }
  };
  pfHistory_ColorUterineAnyAgeClick = () => {
    if (this.pfHistory_ColorUterineAnyAge.checked) {
      this.setState({ pfHistory_ColorUterineAnyAge: true });
    }
  };

  pfHistory_ColorUterineAnyAge2Click = () => {
    if (this.pfHistory_ColorUterineAnyAge2.checked) {
      this.setState({ pfHistory_ColorUterineAnyAge: false });
    }
  };
  previousCancerHistoryClick = () => {
    if (this.pfHistory_previousCancer.checked) {
      this.setState({ pfHistory_previousCancer: true });
    } else {
      this.setState({ pfHistory_previousCancer: false });
    }
  };

  SomaticMutationProfClick = () => {
    if (this.pfHistory_SomaticMutationProf.checked) {
      this.setState({ pfHistory_SomaticMutationProf: true });
    } else {
      this.setState({ pfHistory_SomaticMutationProf: false });
    }
  };

  //hboc1_q05 Previous germline testing? If yes, Define:(gene/varient detected)
  PrevGermltestingClick = () => {
    if (this.pfHistory_PrevGermltesting.checked) {
      this.setState({ pfHistory_PrevGermltesting: true });
    } else {
      this.setState({ pfHistory_PrevGermltesting: false });
    }
  };
  pfHistory_FBrOvPanAnyAgeClick = () => {
    if (this.pfHistory_FBrOvPanAnyAge.checked) {
      this.setState({ pfHistory_FBrOvPanAnyAge: true });
      console.log(this.state.pfHistory_FBrOvPanAnyAge);
    }
  };

  pfHistory_FBrOvPanAnyAge2Click = () => {
    if (this.pfHistory_FBrOvPanAnyAge2.checked == true) {
      this.setState({ pfHistory_FBrOvPanAnyAge: false });
      console.log(this.state.pfHistory_FBrOvPanAnyAge);
    }
  };

  //hboc1_q09 Two breast cancers (bilateral) in one relative
  pfHistory_FTwoBreasCanAgeClick = () => {
    if (this.pfHistory_FTwoBreasCanAge.checked == true) {
      this.setState({ pfHistory_FTwoBreasCanAge: true });
    }
  };

  pfHistory_FTwoBreasCanAge2Click = () => {
    if (this.pfHistory_FTwoBreasCanAge2.checked == true) {
      this.setState({ pfHistory_FTwoBreasCanAge: false });
    }
  };

  //hboc1_q010 Three breast cancers in relatives on the same
  pfHistory_FThrBreasCanAgeClick = () => {
    this.pfHistory_FThrBreasCanAge.checked = true;
    this.pfHistory_FThrBreasCanAge2.checked = false;
    if (this.pfHistory_FThrBreasCanAge.checked == true) {
      this.setState({ pfHistory_FThrBreasCanAge: true });
    }
  };

  pfHistory_FThrBreasCanAge2Click = () => {
    this.pfHistory_FThrBreasCanAge2.checked = true;
    this.pfHistory_FThrBreasCanAge.checked = false;

    if (this.pfHistory_FThrBreasCanAge2.checked == true) {
      this.setState({ pfHistory_FThrBreasCanAge: false });
    }
  };

  //hboc1_q011 Ovarian cancer at any age
  pfHistory_OvarianCanAgeClick = () => {
    if (this.pfHistory_OvarianCanAge.checked == true) {
      this.setState({ pfHistory_OvarianCanAge: true });
    }
  };

  pfHistory_OvarianCanAge2Click = () => {
    if (this.pfHistory_OvarianCanAge2.checked == true) {
      this.setState({ pfHistory_OvarianCanAge: false });
    }
  };

  //hboc1_q012 Pancreatic cancer at any age
  pfHistory_PancreaticCanAgeClick = () => {
    if (this.pfHistory_PancreaticCanAge.checked == true) {
      this.setState({ pfHistory_PancreaticCanAge: true });
    }
  };

  pfHistory_PancreaticCanAge2Click = () => {
    if (this.pfHistory_PancreaticCanAge2.checked == true) {
      this.setState({ pfHistory_PancreaticCanAge: false });
    }
  };

  //hboc1_q013 Male breast cancer at any age
  pfHistory_MaleBreastCanAgeClick = () => {
    if (this.pfHistory_MaleBreastCanAge.checked == true) {
      this.setState({ pfHistory_MaleBreastCanAge: true });
    }
  };

  pfHistory_MaleBreastCanAge2Click = () => {
    if (this.pfHistory_MaleBreastCanAge2.checked == true) {
      this.setState({ pfHistory_MaleBreastCanAge: false });
    }
  };

  //hboc1_q014 Metastatic prostate cancer at any age
  pfHistory_MetaProstateCanAgeClick = () => {
    if (this.pfHistory_MetaProstateCanAge.checked == true) {
      this.setState({ pfHistory_MetaProstateCanAge: true });
    }
  };

  pfHistory_MetaProstateCanAge2Click = () => {
    if (this.pfHistory_MetaProstateCanAge2.checked == true) {
      this.setState({ pfHistory_MetaProstateCanAge: false });
    }
  };

  //hboc1_q015 Colon cancer at 49 or younger
  pfHistory_ColorCanAgeClick = () => {
    if (this.pfHistory_ColorCanAge.checked == true) {
      this.setState({ pfHistory_ColorCanAge: true });
    }
  };

  pfHistory_ColorCanAge2Click = () => {
    if (this.pfHistory_ColorCanAge2.checked == true) {
      this.setState({ pfHistory_ColorCanAge: false });
    }
  };

  //hboc1_q016 Uterine cancer at 49 or younger
  pfHistory_UterineCanAgeClick = () => {
    if (this.pfHistory_UterineCanAge.checked == true) {
      this.setState({ pfHistory_UterineCanAge: true });
    }
  };

  pfHistory_UterineCanAge2Click = () => {
    if (this.pfHistory_UterineCanAge2.checked == true) {
      this.setState({ pfHistory_UterineCanAge: false });
    }
  };

  //hboc1_q017 Ashkenazi Jewish ancestry with breast cancer
  pfHistory_AshJewishAncesCanAgeClick = () => {
    if (this.pfHistory_AshJewishAncesCanAge.checked == true) {
      this.setState({ pfHistory_AshJewishAncesCanAge: true });
    }
  };

  pfHistory_AshJewishAncesCanAge2Click = () => {
    if (this.pfHistory_AshJewishAncesCanAge2.checked == true) {
      this.setState({ pfHistory_AshJewishAncesCanAge: false });
    }
  };

  //hboc1_q018 Does the patient have a family history of other cancers?
  pfHistory_otherCanClick = () => {
    if (this.pfHistory_otherCan.checked == true) {
      this.setState({ pfHistory_otherCan: true });
    }
  };

  pfHistory_otherCan2Click = () => {
    if (this.pfHistory_otherCan2.checked == true) {
      this.setState({ pfHistory_otherCan: false });
    }
  };

  //hboc1_q019 Has anyone in the patient's family had genetic testing for hereditary cancer?
  pfHistory_geHerediraryCanClick = () => {
    if (this.pfHistory_geHerediraryCan.checked == true) {
      this.setState({ pfHistory_geHerediraryCan: true });
    }
  };

  pfHistory_geHerediraryCan2Click = () => {
    if (this.pfHistory_geHerediraryCan2.checked == true) {
      this.setState({ pfHistory_geHerediraryCan: false });
    }
  };

  btnpfHistoryAddFamilyHistoryClick = (dataid) => {
    //alert(dataid);
    // $("#pfHistory_AnyAge_dataid").val(dataid);
    this.setState({ pfHistory_AnyAge_dataid: dataid });
  };
  noPreviousFamilyCancerHistoryClick = () => {
    var pfHistory_yesCancer = document.getElementById("pfHistory_yesCancer");
  };

  //btnpfHistoryAddFamilyHistory
  btnpfHistoryAddFamilyHistory = () => {
    const {
      pfHistory_AnyAge_dataid,
      pfHistory_AnyAge_Mothside,
      pfHistory_AnyAge_Fathside,
      pfHistory_AnyAge_relative,
      pfHistory_AnyAge_age,
    } = this.state;

    const _showResult = pfHistory_AnyAge_dataid + "_showResult";
    const _pfHistory_AnyAgeValues =
      pfHistory_AnyAge_dataid + "_pfHistory_AnyAgeValues";
    const _pfHistory_AnyAgeValuesJustShow =
      pfHistory_AnyAge_dataid + "_pfHistory_AnyAgeValuesJustShow";

    // const pfHistory_AnyAge_relative = $("#pfHistory_AnyAge_relative").val();
    // const pfHistory_AnyAge_age = $("#pfHistory_AnyAge_age").val();

    const strr = pfHistory_AnyAge_age.replace(/\s+/g, " ").trim();
    // $("#pfHistory_AnyAge_age").val(strr);
    this.setState({ pfHistory_AnyAge_age: strr });

    //take existing value
    const _pfHistory_AnyAgeValuesExisting = $(
      "#" + _pfHistory_AnyAgeValues
    ).val();
    const _pfHistory_AnyAgeValuesJustShowExisting = $(
      "#" + _pfHistory_AnyAgeValuesJustShow
    ).val();

    let dataSide = "";
    let dataSideJustShow = "";

    let dataValueanswers = [];
    let dataValueanswer = {
      side: "",
      relative: "",
      age: "",
    };

    let sideValue = "";

    if (
      !pfHistory_AnyAge_Mothside &&
      !pfHistory_AnyAge_Fathside
      // $("#pfHistory_AnyAge_Mothside").is(":not(:checked)") &&
      // $("#pfHistory_AnyAge_Fathside").is(":not(:checked)")
    ) {
      alert("please select family side");
    } else if (pfHistory_AnyAge_relative.length < 1) {
      alert("please select family relative");
    } else if (strr.length < 1) {
      alert("please enter age");
      $("#pfHistory_AnyAge_age").focus();
    } else {
      if (!pfHistory_AnyAge_Mothside) {
        //$("#pfHistory_AnyAge_Mothside").is(":not(:checked)")
        sideValue = "Father";
      } else {
        sideValue = "Mother";
      }

      if (_pfHistory_AnyAgeValuesExisting.length < 1) {
        dataSide = sideValue + "," + pfHistory_AnyAge_relative + "," + strr;
        dataSideJustShow =
          "side: " +
          sideValue +
          " relative: " +
          pfHistory_AnyAge_relative +
          " age: " +
          strr;
      } else {
        dataSide =
          _pfHistory_AnyAgeValuesExisting +
          "," +
          sideValue +
          "," +
          pfHistory_AnyAge_relative +
          "," +
          strr;
        dataSideJustShow =
          _pfHistory_AnyAgeValuesJustShowExisting +
          ", side: " +
          sideValue +
          " relative: " +
          pfHistory_AnyAge_relative +
          " age: " +
          strr;
      }

      dataValueanswer = {
        side: sideValue,
        relative: pfHistory_AnyAge_relative,
        age: strr,
      };

      dataValueanswers.push(dataValueanswer);

      //alert(dataValueanswer);
      //set results
      // $("#" + _showResult).html(dataSideJustShow);
      // $("#" + _pfHistory_AnyAgeValuesJustShow).val(dataSideJustShow);
      // $("#" + _pfHistory_AnyAgeValues).val(dataSide);
      // console.log('sm test obj: ', {
      //   [_showResult]: dataSideJustShow,
      //   [_pfHistory_AnyAgeValuesJustShow]: dataSideJustShow,
      //   [_pfHistory_AnyAgeValues]: dataSide
      // })
      //reset fields
      // document.getElementById("pfHistory_AnyAge_Mothside").checked = false;
      // document.getElementById("pfHistory_AnyAge_Fathside").checked = false;
      // $("#pfHistory_AnyAge_relative").val("");
      // $("#pfHistory_AnyAge_age").val("");

      this.setState({
        [_showResult]: dataSideJustShow,
        [_pfHistory_AnyAgeValuesJustShow]: dataSideJustShow,
        [_pfHistory_AnyAgeValues]: dataSide,
        pfHistory_AnyAge_Mothside: false,
        pfHistory_AnyAge_Fathside: false,
        pfHistory_AnyAge_relative: "",
        pfHistory_AnyAge_age: "",
      });

      $("#defaultModal .mclose").click();
    }
  };

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log("user.token: ", user.token);

    axios.interceptors.response.use(
      function (response) {
        // Do something with response data
        return response;
      },
      function (error) {
        switch (error.response.status) {
          case 409:
            console.log("interceptors->error: ", error);

            me.setState(
              {
                toastMessage: `An Order with the same already exists`,
                loading: false,
              },
              () => {
                me.danger_failed.click();
              }
            );
            break;

          default:
            break;
        }
        // Do something with response error
        return Promise.reject(error);
      }
    );

    this.setState(
      {
        loading: true,
        user: user,
      },
      async () => {
        console.log(window.location.search);
        const params = new URLSearchParams(window.location.search);
        this._id = params.get("id");

        console.log("this._id: ", this._id);

        if (this._id) {
          //Edit Mode

          const accessToken = await helper.getAccessToken(this.context);
          hbocaccessToken = accessToken;

          let response = await axios({
            url: `${API_BASE_URL}/orders/${this._id}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-type": "Application/json",
            },
          });

          response.data = response.data.orders[0];
          console.log("response.data: ", response.data);
          let orderObj = response.data;
          reportId = response.data["baylorReportId"];
          let reportDate = moment.utc(response.data["baylorReportCompletionDate"], "YYYY-MM-DD HH:mm:ss").isValid()
            ? moment.utc(response.data["baylorReportCompletionDate"], "YYYY-MM-DD HH:mm:ss").toISOString() : undefined;

          console.log("reprotId" + reportId + "--------------------------report Id------------");
          console.log(response.data);

          // check if its an order that is billable i.e. not flagged as RUO - Research Use Only
          const isBillableOrder =
            response.data.researchUseOnly === "Yes" ? false : true;

          //assays data "HBOC"
          let _assays_hboc = response.data.assays.filter(
            (x) => x.assayType.indexOf("HBOC") > -1
          );
          console.log("_assays_hboc: ", _assays_hboc);
          let _assays_hboccontent = ``;
          _assays_hboc.map((a, i) => {
            _assays_hboccontent += `<div class="col-md-6">`;
            _assays_hboccontent += `<input data-qbenchid="${a.qBenchId}" data-val="${a.name}" type="radio" name="gtcs_assay_" id="hboc_assay_${i}" class="form-control" />
                    <label for="hboc_assay_${i}">${a.name}  &nbsp; </label></div>`;
          });

          //assays data HBOC

          let patinfo_dobFormatted = "";
          let billinfoC_dobFormatted = "";

          if (response.data.Patient.dob) {
            patinfo_dobFormatted = helper.formatDate(response.data.Patient.dob);
          }
          if (response.data.billingInfo.insuredInfo.dob) {
            billinfoC_dobFormatted = helper.formatDate(
              response.data.billingInfo.insuredInfo.dob
            );
          }
          //let duedate_pregnantFormatted = moment(moment(response.data.tests[0].dueDate).format("MM/DD/YYYY")).toDate();
          console.log(
            "patinfo_dobFormatted: ",
            patinfo_dobFormatted,
            "billinfoC_dobFormatted: ",
            billinfoC_dobFormatted
          );

          // load payor list
          let _url = `${API_BASE_URL}/payors`;
          let _options = {
            url: _url,
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          };

          let axiosResponse = await axios(_options);
          let assignedPayor = {};
          let payorInfo = {};
          const allPayors = axiosResponse.data.payors.map((payor) => {
            let { payorId, payorName, payorContactAddress } = payor;
            payorInfo = {
              label: `${payorId}, ${payorName}, ${payorContactAddress}`,
              value: payorId,
            };

            if (orderObj.payorId && orderObj.payorId === payor.payorId)
              assignedPayor = payorInfo;

            return payorInfo;
          });

          console.log("\n===");
          console.log("orderObj: ", orderObj);
          console.log("===");
          console.log("assignedPayor: ", assignedPayor);
          console.log("===");
          console.log("allPayors: ", allPayors);
          console.log("===\n");

          me.setState(
            {
              reportDate,
              orderData: response.data,
              frmprefix: response.data.tests[0].testName + "_",
              loading: false,
              patinfo_dob: patinfo_dobFormatted,
              billinfoC_dob: billinfoC_dobFormatted,
              _assays_hboccontent: _assays_hboccontent,
              _assays_hboc: _assays_hboc,
              isBillableOrder,
              allPayors,
              selectedPayor: assignedPayor,
              //duedate_pregnant: duedate_pregnantFormatted,
            },
            () => {
              //assays data GENETIX_CS print
              $("#printHere_CS").html(this.state._assays_hboccontent);
              me.orderId = response.data.orderId;
              this.patientId = response.data.Patient._id;
              this.physicianId = response.data.Physician._id;

              //me.IndicationsTest_otherValue.value = response.data.tests[0].genetixCSIndicationOfTesting.other;

              //Physician
              /*
              me.phyinfo_Organization_account.value = `${response.data.legacyName} / ${response.data.legacyClientId}`;
              // response.data.Physician.organizationAccount.length < 1
              //   ? `${response.data.legacyName} / ${response.data.legacyClientId}`
              //   : response.data.Physician.organizationAccount;

              me.phyinfo_first_name.value = response.data.Physician.firstName;
              me.phyinfo_last_name.value = response.data.Physician.lastName;
              me.phyinfo_email.value = response.data.Physician.email;
              me.phyinfo_npi.value = response.data.Physician.npiNumber;
              me.phyinfoC_address.value =
                response.data.Physician.addresses[0].address_1;
              me.phyinfoC_practiceLocation.value =
                response.data.Physician.practiceLocationPlain;
              me.phyinfoC_city.value =
                response.data.Physician.addresses[0].city;
              me.phyinfo_state.value =
                response.data.Physician.addresses[0].state;
              me.phyinfoC_zipCode.value =
                response.data.Physician.addresses[0].zipCode;
              me.phyinfoC_Phone.value =
                response.data.Physician.addresses[0].phoneNo;
              me.phyinfoC_fax.value =
                typeof response.data.Physician.addresses[0].fax !== 'undefined' && response.data.Physician.addresses[0].fax
                  ? response.data.Physician.addresses[0].fax
                  : "";
              me.phyinfoC_Faxcopyto.value =
                typeof response.data.Physician.addresses[0].faxCopyTo !== 'undefined' && response.data.Physician.addresses[0].faxCopyTo
                  ? response.data.Physician.addresses[0].faxCopyTo
                  : "";*/

              //Patient Information
              //ethnicity array
              const ethnicityArray1 = response.data.Patient.ethnicity
                ? response.data.Patient.ethnicity.split(",")
                : [];
              /*me.patinfo_firstname.value = response.data.Patient.firstName;
              me.patinfo_lastname.value = response.data.Patient.lastName;
              me.patinfo_address.value =
                response.data.Patient.address.address_1;
              me.patinfo_State.value = response.data.Patient.address.state;
              me.patinfo_city.value = response.data.Patient.address.city;
              me.patinfo_zipcode.value = response.data.Patient.address.zipCode;
              me.patinfo_PhoneNumber.value = response.data.Patient.phoneNo;
              me.patinfo_emailaddress.value = response.data.Patient.email;
              me.patinfo_OtherEthnicitycity.value = "";*/
              //Patient information ethnicity
              if (
                response.data.Patient.ethnicity &&
                response.data.Patient.ethnicity.includes("Caucasian")
              ) {
                // $("#patinfo_Caucasian").prop("checked", true);
                this.setState({ patinfo_ethnicity: "Caucasian" });
              }
              if (
                response.data.Patient.ethnicity &&
                response.data.Patient.ethnicity.includes("Ashkenazi Jewish")
              ) {
                this.setState({ patinfo_ethnicity: "Ashkenazi Jewish" });
                //$("#patinfo_AshkenaziJewish").prop("checked", true);
              }
              if (
                response.data.Patient.ethnicity &&
                response.data.Patient.ethnicity.includes("Sephardic Jewish")
              ) {
                this.setState({ patinfo_ethnicity: "Sephardic Jewish" });
                // $("#patinfo_SephardicJewish").prop("checked", true);
              }
              if (
                response.data.Patient.ethnicity &&
                response.data.Patient.ethnicity.includes("Asian")
              ) {
                //$("#patinfo_Asian").prop("checked", true);
                this.setState({ patinfo_ethnicity: "Asian" });
              }
              if (
                response.data.Patient.ethnicity &&
                response.data.Patient.ethnicity.includes("Hispanic")
              ) {
                //$("#patinfo_HIspanic").prop("checked", true);
                this.setState({ patinfo_ethnicity: "Hispanic" });
              }
              if (
                response.data.Patient.ethnicity &&
                response.data.Patient.ethnicity.includes("Native American")
              ) {
                // $("#patinfo_NativeAmerican").prop("checked", true);
                this.setState({ patinfo_ethnicity: "Native American" });
              }
              if (
                response.data.Patient.ethnicity &&
                response.data.Patient.ethnicity.includes("African American")
              ) {
                // $("#patinfo_AfricanAmerican").prop("checked", true);
                this.setState({ patinfo_ethnicity: "African American" });
              }
              if (
                response.data.Patient.ethnicity &&
                response.data.Patient.ethnicity.includes("Other")
              ) {
                // $("#patinfo_Other").prop("checked", true);
                this.setState({ patinfo_ethnicity: "Other" });
              }

              //Patient Gender
              //console.log("gender: ",response.data.Patient.gender);
              /* if (response.data.Patient.gender === "male") {
                $(`#${this.state.frmprefix}patinfo_gender`).prop(
                  "checked",
                  true
                );
              } else {
                $(`#${this.state.frmprefix}patinfo_gender2`).prop(
                  "checked",
                  true
                );
              }
 */
              if (response.data.Patient.gender === "male") {
                this.setState({
                  patinfo_gender: "male",
                });
              } else {
                this.setState({
                  patinfo_gender: "female",
                });
              }
              //billing information
              /*if (this.state.isBillableOrder) {
                me.billinfo_PrimaryInsuranceCarrier.value =
                  response.data.billingInfo.primaryInsurance.primaryInsuranceCarrier;
                // me.billinfo_planId.value =
                //   response.data.billingInfo.primaryInsurance.planId;
                me.billinfo_MemberID.value =
                  response.data.billingInfo.primaryInsurance.memberID;
                me.billinfo_GroupID.value =
                  response.data.billingInfo.primaryInsurance.groupID;

                me.billinfo_SecondaryInsuranceCarrier.value =
                  response.data.billingInfo.secondaryInsurance.secondaryInsuranceCarrier;
                // me.billinfo_SecondaryplanId.value =
                //   response.data.billingInfo.secondaryInsurance.secondaryplanId;
                me.billinfo_SecondaryMemberID.value =
                  response.data.billingInfo.secondaryInsurance.secondaryMemberID;
                me.billinfo_SecondaryGroupID.value =
                  response.data.billingInfo.secondaryInsurance.secondaryGroupID;

                me.billinfo_TertiaryInsuranceCarrier.value =
                  response.data.billingInfo?.tertiaryInsurance?.tertiaryInsuranceCarrier
                    ? response.data.billingInfo?.tertiaryInsurance?.tertiaryInsuranceCarrier
                    : '';
                me.billinfo_TertiaryMemberID.value =
                  response.data.billingInfo?.tertiaryInsurance?.tertiaryMemberID
                    ? response.data.billingInfo?.tertiaryInsurance?.tertiaryMemberID
                    : '';
                me.billinfo_TertiaryGroupID.value =
                  response.data.billingInfo?.tertiaryInsurance?.tertiaryGroupID
                    ? response.data.billingInfo?.tertiaryInsurance?.tertiaryGroupID
                    : '';

                me.billinfo_FirstNameofInsured.value =
                  response.data.billingInfo.insuredInfo.nameOfInsured.firstName;
                me.billinfo_LastNameofInsured.value =
                  response.data.billingInfo.insuredInfo.nameOfInsured.lastName;

                //me.billinfoC_dob.value = response.data.billingInfo.insuredInfo.GTCS_billinfoC_dob;
                me.billinfo_RelationshipInsured.value =
                  response.data.billingInfo.insuredInfo.relationshipInsured;
              }

              me.OtherICD10Codes.value =
                response.data.tests[0].icd10Code.otherCodes;
              me.kitShip_PatOthersInfo.value =
                response.data.tests[0].kitInformation.otherInfo;
              me.kitShip_notes.value = response.data.tests[0].notes;*/

              //Family History
              //hboc1_q03
              /* this.state.pfHistory_prevCanDefine = response.data.tests[0]
                .clinicalData[2]
                ? response.data.tests[0].clinicalData[2].subQuestion[0].answer
                : ""; */
              this.setState({
                pfHistory_prevCanDefine: response.data.tests[0].clinicalData[2]
                  ? response.data.tests[0].clinicalData[2].subQuestion[0].answer
                  : "",
                pfHistory_SoMutaProfDefine: response.data.tests[0]
                  .clinicalData[3]
                  ? response.data.tests[0].clinicalData[3].subQuestion[0].answer
                  : "",
                pfHistory_PrevGermltestDefine: response.data.tests[0]
                  .clinicalData[4]
                  ? response.data.tests[0].clinicalData[4].subQuestion[0].answer
                  : "",
                pfHistory_BrOvPanAnyAgeInfo: response.data.tests[0]
                  .clinicalData[5]
                  ? response.data.tests[0].clinicalData[5].subQuestion[1].answer
                  : "",
                pfHistory_ColorUterineAnyAgeInfo: response.data.tests[0]
                  .clinicalData[6]
                  ? response.data.tests[0].clinicalData[6].subQuestion[1].answer
                  : "",
                pfHistory_otherCan_info: response.data.tests[0].clinicalData[17]
                  ? response.data.tests[0].clinicalData[17].subQuestion[0]
                    .answer
                  : "",
                pfHistory_geHerediraryCan_info: response.data.tests[0]
                  .clinicalData[18]
                  ? response.data.tests[0].clinicalData[18].subQuestion[0]
                    .answer
                  : "",
                pfHistory_OtherClinicalHistory: response.data.tests[0]
                  .clinicalData[19]
                  ? response.data.tests[0].clinicalData[19].answer
                  : "",
                pfHistory_PCurTreat_SurgDe: response.data.tests[0]
                  .clinicalData[24]
                  ? response.data.tests[0].clinicalData[24].answer
                  : "",


              });
              //hboc1_q04
              /* this.state.pfHistory_SoMutaProfDefine = response.data.tests[0]
                .clinicalData[3]
                ? response.data.tests[0].clinicalData[3].subQuestion[0].answer
                : "", */

              //hboc1_q05
              /*  this.state.pfHistory_PrevGermltestDefine = response.data.tests[0]
                 .clinicalData[4]
                 ? response.data.tests[0].clinicalData[4].subQuestion[0].answer
                 : "";
               //hboc1_q06 Does the Patient have a history of
               //me.pfHistory_BrOvPanAnyAgeCan.value = response.data.tests[0].clinicalData[5].subQuestion[0].answer;
               this.state.pfHistory_BrOvPanAnyAgeInfo = response.data.tests[0]
                 .clinicalData[5]
                 ? response.data.tests[0].clinicalData[5].subQuestion[1].answer
                 : "";
               //hboc1_q07  Colorectal or uterine cancer at 64 or younger?
               //me.pfHistory_ColorUterineAnyAgeCan.value = response.data.tests[0].clinicalData[6].subQuestion[0].answer;
               this.state.pfHistory_ColorUterineAnyAgeInfo = response.data
                 .tests[0].clinicalData[6]
                 ? response.data.tests[0].clinicalData[6].subQuestion[1].answer
                 : "";
 
               //hboc1_q018
               this.state.pfHistory_otherCan_info = response.data.tests[0]
                 .clinicalData[17]
                 ? response.data.tests[0].clinicalData[17].subQuestion[0].answer
                 : "";
               this.state.pfHistory_geHerediraryCan_info = response.data.tests[0]
                 .clinicalData[18]
                 ? response.data.tests[0].clinicalData[18].subQuestion[0].answer
                 : "";
               this.state.pfHistory_OtherClinicalHistory = response.data.tests[0]
                 .clinicalData[19]
                 ? response.data.tests[0].clinicalData[19].answer
                 : "";
               this.state.pfHistory_PCurTreat_SurgDe = response.data.tests[0]
                 .clinicalData[24]
                 ? response.data.tests[0].clinicalData[24].answer
                 : ""; */

              me.setState(
                {
                  buttonText: "Update",
                  IndicationsTest_otherValue:
                    response.data.tests[0].genetixCSIndicationOfTesting.other,

                  phyinfo_Organization_account:
                    response.data.Physician.organizationAccount.length < 1
                      ? `${response.data.legacyName} / ${response.data.legacyClientId}`
                      : response.data.Physician.organizationAccount,

                  phyinfo_first_name: response.data.Physician.firstName,
                  phyinfo_last_name: response.data.Physician.lastName,
                  phyinfo_email: response.data.Physician.email,
                  phyinfo_npi: response.data.Physician.npiNumber,
                  phyinfoC_address:
                    response.data.Physician.addresses[0].address_1,
                  phyinfoC_practiceLocation:
                    response.data.Physician.practiceLocationPlain,
                  phyinfoC_city: response.data.Physician.addresses[0].city,
                  phyinfo_state: {
                    label: response.data.Physician.addresses[0].state,
                    value: response.data.Physician.addresses[0].state,
                  },
                  phyinfoC_zipCode:
                    response.data.Physician.addresses[0].zipCode,
                  phyinfoC_Phone: response.data.Physician.addresses[0].phoneNo,
                  phyinfoC_fax: response.data.Physician.addresses[0].fax,
                  phyinfoC_Faxcopyto:
                    response.data.Physician.addresses[0].faxCopyTo,

                  patinfo_firstname: response.data.Patient.firstName,
                  patinfo_lastname: response.data.Patient.lastName,
                  patinfo_address: response.data.Patient.address.address_1,
                  patinfo_State: {
                    label: response.data.Patient.address.state,
                    value: response.data.Patient.address.state,
                  },
                  patinfo_city: response.data.Patient.address.city,
                  patinfo_zipcode: response.data.Patient.address.zipCode,
                  patinfo_PhoneNumber: response.data.Patient.phoneNo,
                  patinfo_emailaddress: response.data.Patient.email,
                  patinfo_dob: patinfo_dobFormatted,
                  patinfo_OtherEthnicitycity:
                    response.data.Patient.ethnicity &&
                      response.data.Patient.ethnicity.includes("Other")
                      ? response.data.Patient.ethnicity
                      : "",
                  /*  patinfo_ethnicity: 
                     response.data.Patient.ethnicity &&
                     response.data.Patient.ethnicity.includes("Other")
                       ? 'Other'
                       : response.data.Patient.ethnicity, */
                  patinfo_gender: response.data.Patient.gender,

                  //billing info
                  billinfo_PrimaryInsuranceCarrier:
                    response.data.billingInfo.primaryInsurance
                      .primaryInsuranceCarrier,
                  billinfo_planId:
                    response.data.billingInfo.primaryInsurance.planID,
                  billinfo_MemberID:
                    response.data.billingInfo.primaryInsurance.memberID,
                  billinfo_GroupID:
                    response.data.billingInfo.primaryInsurance.groupID,

                  billinfo_SecondaryInsuranceCarrier:
                    response.data.billingInfo.secondaryInsurance
                      .secondaryInsuranceCarrier,
                  billinfo_SecondaryplanId:
                    response.data.billingInfo.secondaryInsurance
                      .secondaryPlanID,
                  billinfo_SecondaryMemberID:
                    response.data.billingInfo.secondaryInsurance
                      .secondaryMemberID,
                  billinfo_SecondaryGroupID:
                    response.data.billingInfo.secondaryInsurance
                      .secondaryGroupID,

                  billinfo_TertiaryInsuranceCarrier: response.data.billingInfo
                    ?.tertiaryInsurance?.tertiaryInsuranceCarrier
                    ? response.data.billingInfo?.tertiaryInsurance
                      ?.tertiaryInsuranceCarrier
                    : "",
                  billinfo_TertiaryMemberID: response.data.billingInfo
                    ?.tertiaryInsurance?.tertiaryMemberID
                    ? response.data.billingInfo?.tertiaryInsurance
                      ?.tertiaryMemberID
                    : "",
                  billinfo_TertiaryGroupID: response.data.billingInfo
                    ?.tertiaryInsurance?.tertiaryGroupID
                    ? response.data.billingInfo?.tertiaryInsurance
                      ?.tertiaryGroupID
                    : "",
                  billinfo_TertiaryPlanID: response.data.billingInfo
                    .tertiaryInsurance
                    ? response.data.billingInfo.tertiaryInsurance.tertiaryPlanID
                    : "",
                  billinfo_FirstNameofInsured: response.data.billingInfo
                    .insuredInfo
                    ? response.data.billingInfo.insuredInfo.nameOfInsured
                      .firstName
                    : "",
                  billinfo_LastNameofInsured: response.data.billingInfo
                    .insuredInfo
                    ? response.data.billingInfo.insuredInfo.nameOfInsured
                      .lastName
                    : "",

                  billinfoC_dob: billinfoC_dobFormatted,
                  billinfo_RelationshipInsured:
                    response.data.billingInfo.insuredInfo.relationshipInsured,

                  OtherICD10Codes: response.data.tests[0].icd10Code.otherCodes,
                  kitShip_PatOthersInfo:
                    response.data.tests[0].kitInformation.otherInfo,
                  kitShip_notes: response.data.tests[0].notes,

                  //Family History
                  //hboc1_q03
                  pfHistory_prevCanDefine: response.data.tests[0]
                    .clinicalData[2]
                    ? response.data.tests[0].clinicalData[2].subQuestion[0]
                      .answer
                    : "",
                  //hboc1_q04
                  pfHistory_SoMutaProfDefine: response.data.tests[0]
                    .clinicalData[3]
                    ? response.data.tests[0].clinicalData[3].subQuestion[0]
                      .answer
                    : "",
                  //hboc1_q05
                  pfHistory_PrevGermltestDefine: response.data.tests[0]
                    .clinicalData[4]
                    ? response.data.tests[0].clinicalData[4].subQuestion[0]
                      .answer
                    : "",
                  //hboc1_q06 Does the Patient have a history of
                  //pfHistory_BrOvPanAnyAgeCan: response.data.tests[0].clinicalData[5].subQuestion[0].answer,
                  pfHistory_BrOvPanAnyAgeInfo: response.data.tests[0]
                    .clinicalData[5]
                    ? response.data.tests[0].clinicalData[5].subQuestion[1]
                      .answer
                    : "",
                  //hboc1_q07  Colorectal or uterine cancer at 64 or younger?
                  //pfHistory_ColorUterineAnyAgeCan: response.data.tests[0].clinicalData[6].subQuestion[0].answer,
                  pfHistory_ColorUterineAnyAgeInfo: response.data.tests[0]
                    .clinicalData[6]
                    ? response.data.tests[0].clinicalData[6].subQuestion[1]
                      .answer
                    : "",
                  //hboc1_q018
                  pfHistory_otherCan_info: response.data.tests[0]
                    .clinicalData[17]
                    ? response.data.tests[0].clinicalData[17].subQuestion[0]
                      .answer
                    : "",
                  pfHistory_geHerediraryCan_info: response.data.tests[0]
                    .clinicalData[18]
                    ? response.data.tests[0].clinicalData[18].subQuestion[0]
                      .answer
                    : "",
                  pfHistory_OtherClinicalHistory: response.data.tests[0]
                    .clinicalData[19]
                    ? response.data.tests[0].clinicalData[19].answer
                    : "",
                  pfHistory_PCurTreat_SurgDe: response.data.tests[0]
                    .clinicalData[24]
                    ? response.data.tests[0].clinicalData[24].answer
                    : "",

                  updatedAt: response.data.updatedAt,
                },

                () => {
                  //ASPIRA GENETIX Hereditary Breast and Ovarian Cancer
                  if (
                    typeof this.state.orderData.tests[0].genetixCSPanel !=
                    "undefined"
                  ) {
                    if (
                      this.state.orderData.tests[0].genetixCSPanel.length > 0
                    ) {
                      this.state._assays_hboc.map((a, i) => {
                        if (
                          this.state.orderData.tests[0].genetixCSPanel.includes(
                            a.name
                          )
                        ) {
                          $(`#hboc_assay_${i}`).prop("checked", true);
                        }
                      });
                    }
                  }

                  //Billing information
                  //alert(this.state.orderData.billingInfo.hboc_billinfo_PrivateInsurance);

                  if (this.state.orderData.billingInfo.medicare) {
                    console.log("Medicare-----");
                    this.setState({
                      orderingFacility: false,
                      freeTrial: false,
                      medicaid: false,
                      medicare: true,
                      patientSelfPay: false,
                    });
                  }
                  if (this.state.orderData.billingInfo.privateInsurance) {
                    this.setState({
                      orderingFacility: false,
                      freeTrial: false,
                      privateInsurance: true,
                      medicaid: false,

                      patientSelfPay: false,
                    });
                  }
                  if (this.state.orderData.billingInfo.patientSelfPay) {
                    this.setState({
                      orderingFacility: false,
                      freeTrial: false,
                      privateInsurance: false,
                      medicaid: false,
                      meidcare: false,
                      patientSelfPay: true,
                    });
                  }
                  if (this.state.orderData.billingInfo.medicaid) {
                    this.setState({
                      orderingFacility: false,
                      freeTrial: false,
                      privateInsurance: false,
                      medicaid: true,
                      meidcare: false,
                      patientSelfPay: false,
                    });
                  }
                  if (this.state.orderData.billingInfo.orderingFacility) {
                    this.setState({
                      orderingFacility: true,
                      freeTrial: false,
                      privateInsurance: false,
                      medicaid: false,
                      meidcare: false,
                      patientSelfPay: false,
                    });
                  }
                  if (this.state.orderData.billingInfo.freeTrial) {
                    this.setState({
                      orderingFacility: false,
                      freeTrial: true,
                      privateInsurance: false,
                      medicaid: false,
                      meidcare: false,
                      patientSelfPay: false,
                    });
                  }

                  //Diagnosis Codes | ICD-10 Codes
                  var diagonosisDataFromDb = [];
                  if (this.state.orderData.tests.length > 0) {
                    if (
                      this.state.orderData.tests[0].icd10Code.codes.length > 0
                    ) {
                      //HBOC

                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "Z15.01"
                        )
                      ) {
                        diagonosisDataFromDb.push("Z15.01");
                        this.hBOCdiagnosis_z1501.checked = true;

                        // $("#hBOCdiagnosis_z1501").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "C48.1"
                        )
                      ) {
                        diagonosisDataFromDb.push("C48.1");
                        this.hBOCdiagnosis_c481.checked = true;
                        //$("#hBOCdiagnosis_c481").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "Z15.02"
                        )
                      ) {
                        diagonosisDataFromDb.push("Z15.02");
                        this.hBOCdiagnosis_z1502.checked = true;
                        //$("#hBOCdiagnosis_z1502").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "C50.0"
                        )
                      ) {
                        diagonosisDataFromDb.push("C50.0");
                        this.hBOCdiagnosis_c500.checked = true;

                        //$("#hBOCdiagnosis_c500").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "Z15.03"
                        )
                      ) {
                        diagonosisDataFromDb.push("Z15.03");
                        this.hBOCdiagnosis_z1503.checked = true;
                        // $("#hBOCdiagnosis_z1503").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "C56.1"
                        )
                      ) {
                        diagonosisDataFromDb.push("C56.1");
                        this.hBOCdiagnosis_c561.checked = true;
                        //$("#hBOCdiagnosis_c561").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "Z15.04"
                        )
                      ) {
                        diagonosisDataFromDb.push("Z15.04");
                        this.hBOCdiagnosis_z1504.checked = true;
                        // $("#hBOCdiagnosis_z1504").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "C56.2"
                        )
                      ) {
                        diagonosisDataFromDb.push("C56.2");
                        this.hBOCdiagnosis_c562.checked = true;
                        //$("#hBOCdiagnosis_c562").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "Z15.09"
                        )
                      ) {
                        diagonosisDataFromDb.push("Z15.09");
                        this.hBOCdiagnosis_z1509.checked = true;
                        // $("#hBOCdiagnosis_z1509").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "C56.9"
                        )
                      ) {
                        diagonosisDataFromDb.push("C56.9");
                        this.hBOCdiagnosis_c569.checked = true;
                        // $("#hBOCdiagnosis_c569").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "Z15.81"
                        )
                      ) {
                        diagonosisDataFromDb.push("Z15.81");
                        this.hBOCdiagnosis_z1581.checked = true;
                        //$("#hBOCdiagnosis_z1581").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "C57.00"
                        )
                      ) {
                        diagonosisDataFromDb.push("C57.00");
                        this.hBOCdiagnosis_c5700.checked = true;
                        $("#hBOCdiagnosis_c5700").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "C25.0"
                        )
                      ) {
                        diagonosisDataFromDb.push("C25.0");
                        this.hBOCdiagnosis_C250.checked = true;
                        //$("#hBOCdiagnosis_C250").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "C57.01"
                        )
                      ) {
                        diagonosisDataFromDb.push("C57.01");
                        this.hBOCdiagnosis_c5701.checked = true;
                        // $("#hBOCdiagnosis_c5701").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "C25.1"
                        )
                      ) {
                        diagonosisDataFromDb.push("C25.1");
                        this.hBOCdiagnosis_C251.checked = true;
                        //$("#hBOCdiagnosis_C251").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "C57.01"
                        )
                      ) {
                        diagonosisDataFromDb.push("C57.01");
                        this.hBOCdiagnosis_c5702.checked = true;
                        //$("#hBOCdiagnosis_c5702").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "C25.2"
                        )
                      ) {
                        diagonosisDataFromDb.push("C25.2");
                        this.hBOCdiagnosis_C252.checked = true;
                        // $("#hBOCdiagnosis_C252").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "C61"
                        )
                      ) {
                        diagonosisDataFromDb.push("C61");
                        this.hBOCdiagnosis_c61.checked = true;
                        //$("#hBOCdiagnosis_c61").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "C25.3"
                        )
                      ) {
                        diagonosisDataFromDb.push("C25.3");
                        this.hBOCdiagnosis_C253.checked = true;
                        // $("#hBOCdiagnosis_C253").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "D05.11"
                        )
                      ) {
                        diagonosisDataFromDb.push("D05.11");
                        this.hBOCdiagnosis_C253.checked = true;
                        // $("#hBOCdiagnosis_D0511").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "C25.4"
                        )
                      ) {
                        diagonosisDataFromDb.push("C25.4");
                        this.hBOCdiagnosis_C254.checked = true;
                        // $("#hBOCdiagnosis_C254").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "D05.12"
                        )
                      ) {
                        diagonosisDataFromDb.push("D05.12");
                        this.hBOCdiagnosis_D0512.checked = true;
                        $("#hBOCdiagnosis_D0512").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "C25.7"
                        )
                      ) {
                        diagonosisDataFromDb.push("C25.7");
                        this.hBOCdiagnosis_C257.checked = true;
                        $("#hBOCdiagnosis_C257").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "Z85.07"
                        )
                      ) {
                        diagonosisDataFromDb.push("Z85.07");
                        this.hBOCdiagnosis_Z8507.checked = true;
                        //$("#hBOCdiagnosis_Z8507").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "C25.8"
                        )
                      ) {
                        diagonosisDataFromDb.push("Z15.01");
                        this.hBOCdiagnosis_C258.checked = true;
                        // $("#hBOCdiagnosis_C258").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "Z85.3"
                        )
                      ) {
                        diagonosisDataFromDb.push("Z85.3");
                        this.hBOCdiagnosis_Z853.checked = true;
                        //  $("#hBOCdiagnosis_Z853").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "C25.9"
                        )
                      ) {
                        diagonosisDataFromDb.push("C25.9");
                        this.hBOCdiagnosis_C259.checked = true;
                        // $("#hBOCdiagnosis_C259").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "Z85.43"
                        )
                      ) {
                        diagonosisDataFromDb.push("Z85.43");
                        this.hBOCdiagnosis_Z8543.checked = true;
                        // $("#hBOCdiagnosis_Z8543").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "Z85.46"
                        )
                      ) {
                        diagonosisDataFromDb.push("Z85.46");
                        this.hBOCdiagnosis_Z8546.checked = true;
                        // $("#hBOCdiagnosis_Z8546").prop("checked", true);
                      }
                    }
                  }

                  if (this.state.orderData.tests.length > 1) {
                    $("#covid_icd_codes").removeClass(" hidediv");
                    $("#covid_icd_codes").addClass(" showdiv");

                    if (
                      this.state.orderData.tests[1].icd10Code.codes.length > 0
                    ) {
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "Z11.59"
                        )
                      ) {
                        diagonosisDataFromDb.push("Z11.59");
                        $("#diagnosis_z1159").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "Z03.818"
                        )
                      ) {
                        diagonosisDataFromDb.push("Z03.818");
                        $("#diagnosis_z03818").prop("checked", true);
                      }
                    }
                  }
                  /* this.setState({
                    diagonisisCodes:diagonosisDataFromDb,
                  }); */
                  var tempOtherCodes =
                    response.data.tests[0].icd10Code.otherCodes;
                  console.log("other", tempOtherCodes);
                  //console.log()

                  let displayOtherIcdCodes = tempOtherCodes.join(",");
                  this.setState({
                    otherICD10Codes: tempOtherCodes,
                    otherICD10Code: displayOtherIcdCodes,
                  });

                  //   this.state.diagonisisCodes = diagonosisDataFromDb;
                  this.setState({ diagonisisCodes: diagonosisDataFromDb });
                  console.log(
                    "diagonosisCodes--------------------------------"
                  );
                  console.log(diagonosisDataFromDb);
                  console.log(this.state.diagonisisCodes);
                  console.log("diagonosisCodes------------------end");
                  //end Diagnosis Codes | ICD-10 Codes

                  //Family History
                  //hboc1_q01
                  if (
                    this.state.orderData.tests[0].clinicalData[0] &&
                    typeof this.state.orderData.tests[0].clinicalData[0]
                      .answer != "undefined" &&
                    this.state.orderData.tests[0].clinicalData[0].answer ==
                    "true"
                  ) {
                    // $(`#pfHistory_noCancer`).prop("checked", true);
                    this.pfHistory_noCancer.checked = true;
                    this.setState({ pfHistory_noCancer: true });
                    this.noPreviousPersonalCancerHistoryClick();
                  }

                  //hboc1_q02
                  if (
                    this.state.orderData.tests[0].clinicalData[1] &&
                    typeof this.state.orderData.tests[0].clinicalData[1]
                      .answer != "undefined" &&
                    this.state.orderData.tests[0].clinicalData[1].answer ==
                    "true"
                  ) {
                    $(`#pfHistory_yesCancer`).prop("checked", true);
                    noPreviousFamilyCancerHistoryClick();
                  }

                  //hboc1_q03 Previous cancer diagnosis? If yes, Define
                  if (
                    this.state.orderData.tests[0].clinicalData[2] &&
                    typeof this.state.orderData.tests[0].clinicalData[2]
                      .answer != "undefined" &&
                    this.state.orderData.tests[0].clinicalData[2].answer ==
                    "true"
                  ) {
                    // $(`#pfHistory_previousCancer`).prop("checked", true);
                    //  this.pfHistory_previousCancer.checked = true;
                    this.setState({ pfHistory_previousCancer: true });
                    this.state.pfHistory_previousCancer = true;
                    this.previousCancerHistoryClick();
                  }

                  //hboc1_q04 Somatic mutation profiling? If yes, Define: (gene, variant identified)
                  if (
                    this.state.orderData.tests[0].clinicalData[3] &&
                    typeof this.state.orderData.tests[0].clinicalData[3]
                      .answer != "undefined" &&
                    this.state.orderData.tests[0].clinicalData[3].answer ==
                    "true"
                  ) {
                    // $(`#pfHistory_SomaticMutationProf`).prop("checked", true);
                    this.pfHistory_SomaticMutationProf.checked = true;
                    this.state.pfHistory_SomaticMutationProf = true;
                    this.setState({ pfHistory_SomaticMutationProf: true });
                    this.SomaticMutationProfClick();
                  }

                  //hboc1_q05 Previous germline testing? If yes, Define:(gene/varient detected)
                  if (
                    this.state.orderData.tests[0].clinicalData[4] &&
                    typeof this.state.orderData.tests[0].clinicalData[4]
                      .answer != "undefined" &&
                    this.state.orderData.tests[0].clinicalData[4].answer ==
                    "true"
                  ) {
                    //  $(`#pfHistory_PrevGermltesting`).prop("checked", true);
                    this.pfHistory_PrevGermltesting.checked = true;
                    //   this.state.pfHistory_PrevGermltesting = true;
                    this.setState({ pfHistory_PrevGermltesting: true });
                    this.PrevGermltestingClick();
                  }

                  //hboc1_q05 hboc1_q05_02
                  if (
                    this.state.orderData.tests[0].clinicalData[4] &&
                    typeof this.state.orderData.tests[0].clinicalData[4]
                      .subQuestion[1].answer != "undefined" &&
                    this.state.orderData.tests[0].clinicalData[4].subQuestion[1].answer.includes(
                      "Familial/Inherited or"
                    )
                  ) {
                    $(`#pfHistory_Familial_Inherited`).prop("checked", true);
                  }
                  if (
                    this.state.orderData.tests[0].clinicalData[4] &&
                    typeof this.state.orderData.tests[0].clinicalData[4]
                      .subQuestion[1].answer != "undefined" &&
                    this.state.orderData.tests[0].clinicalData[4].subQuestion[1].answer.includes(
                      "Newly-identified/De Novo"
                    )
                  ) {
                    $(`#pfHistory_Newly_identified`).prop("checked", true);
                  }

                  //hboc1_q06 Does the Patient have a history of
                  if (
                    this.state.orderData.tests[0].clinicalData[5] &&
                    typeof this.state.orderData.tests[0].clinicalData[5]
                      .answer != "undefined" &&
                    this.state.orderData.tests[0].clinicalData[5].answer ==
                    "true"
                  ) {
                    $("#pfHistory_BrOvPanAnyAge").prop("checked", true);
                    // this.state.pfHistory_BrOvPanAnyAge=true;
                    this.setState({ pfHistory_BrOvPanAnyAge: true });

                    this.state.pfHistory_BrOvPanAnyAge = true;
                    this.pfHistory_BrOvPanAnyAge.checked = true;
                    this.pfHistory_BrOvPanAnyAge2.checked = false;
                    if (
                      response.data.tests[0].clinicalData[5] &&
                      response.data.tests[0].clinicalData[5].subQuestion[0].answer.includes(
                        "Breast"
                      )
                    ) {
                      $("#pfHistory_BrOvPanAnyAgeCan1").prop("checked", true);
                      //ali
                      this.pfHistory_BrOvPanAnyAgeCan1 = true;
                      this.setState({ pfHistory_BrOvPanAnyAgeCan1: "Breast" });
                      // this.state.pfHistory_BrOvPanAnyAgeCan1 = "Breast";
                    }
                    if (
                      response.data.tests[0].clinicalData[5] &&
                      response.data.tests[0].clinicalData[5].subQuestion[0].answer.includes(
                        "Ovarian"
                      )
                    ) {
                      console.log("pfHistory_BrOvPanAnyAgeCan2-----has");
                      $("#pfHistory_BrOvPanAnyAgeCan2").prop("checked", true);
                      this.pfHistory_BrOvPanAnyAgeCan2 = true;
                      this.setState({ pfHistory_BrOvPanAnyAgeCan2: "Ovarian" });
                      // this.state.pfHistory_BrOvPanAnyAgeCan2 = "Ovarian";
                    }
                    if (
                      response.data.tests[0].clinicalData[5] &&
                      response.data.tests[0].clinicalData[5].subQuestion[0].answer.includes(
                        "Pancreative"
                      )
                    ) {
                      $("#pfHistory_BrOvPanAnyAgeCan3").prop("checked", true);
                      // this.state.pfHistory_BrOvPanAnyAgeCan3 = "Pancreative";
                      this.setState({
                        pfHistory_BrOvPanAnyAgeCan3: "Pancreative",
                      });
                      this.pfHistory_BrOvPanAnyAgeCan3 = true;
                    }
                    //pfHistory_BrOvPanAnyAgeClick();
                  } else {
                    $("#pfHistory_BrOvPanAnyAge2").prop("checked", true);
                    this.setState({ pfHistory_BrOvPanAnyAge: false });
                    this.pfHistory_BrOvPanAnyAge.checked = false;
                    this.pfHistory_BrOvPanAnyAge2.checked = true;
                  }
                  //hboc1_q07  Colorectal or uterine cancer at 64 or younger?
                  if (
                    this.state.orderData.tests[0].clinicalData[6] &&
                    typeof this.state.orderData.tests[0].clinicalData[6]
                      .answer != "undefined" &&
                    this.state.orderData.tests[0].clinicalData[6].answer ==
                    "true"
                  ) {
                    $("#pfHistory_ColorUterineAnyAge").prop("checked", true);
                    this.pfHistory_ColorUterineAnyAge.checked = true;
                    //this.state.pfHistory_ColorUterineAnyAge = true;
                    this.setState({ pfHistory_ColorUterineAnyAge: true });
                    if (
                      response.data.tests[0].clinicalData[6].subQuestion[0].answer.includes(
                        "Colorectal"
                      )
                    ) {
                      /*   $("#pfHistory_ColorUterineAnyAgeCan1").prop(
                          "checked",
                          true
                        ); */
                      //ali
                      //    this.state.pfHistory_ColorUterineAnyAgeCan1 = true;
                      /*  this.state.pfHistory_ColorUterineAnyAgeCan1 =
                         "Colorectal"; */
                      this.setState({
                        pfHistory_ColorUterineAnyAgeCan1: "Colorectal",
                      });
                    }
                    if (
                      response.data.tests[0].clinicalData[6].subQuestion[0].answer.includes(
                        "Uterine"
                      )
                    ) {
                      /* $("#pfHistory_ColorUterineAnyAgeCan2").prop(
                        "checked",
                        true
                      ); */
                      //
                      // this.pfHistory_ColorUterineAnyAgeCan2 = true;
                      this.setState({
                        pfHistory_ColorUterineAnyAgeCan2: "Uterine",
                      });
                      // this.state.pfHistory_ColorUterineAnyAgeCan2 = "Uterine";
                    }

                    this.pfHistory_ColorUterineAnyAgeClick();
                  } else {
                    this.pfHistory_ColorUterineAnyAge2.checked = false;
                    this.state.pfHistory_ColorUterineAnyAge2 = false;
                    $("#pfHistory_ColorUterineAnyAge2").prop("checked", true);
                    //this.pfHistory_ColorUterineAnyAge2Click();
                  }

                  //hboc1_q08  Breast, ovarian or pancreative cancer at any age?
                  if (
                    this.state.orderData.tests[0].clinicalData[7] &&
                    typeof this.state.orderData.tests[0].clinicalData[7]
                      .answer != "undefined" &&
                    this.state.orderData.tests[0].clinicalData[7].answer ==
                    "true"
                  ) {
                    $("#pfHistory_FBrOvPanAnyAge").prop("checked", true);
                    // this.pfHistory_FBrOvPanAnyAge.checked=true;
                    // this.pfHistory_FBrOvPanAnyAge2.checked=false;
                    this.pfHistory_FBrOvPanAnyAgeClick();

                    let hboc1_q08_1_showResult = "";
                    let hboc1_q08_1_pfHistory_AnyAgeValuesJustShow = "";
                    let hboc1_q08_1_pfHistory_AnyAgeValues = "";

                    if (
                      this.state.orderData.tests[0].clinicalData[7]
                        .hBOCSubQuestions[0].answer.length > 0
                    ) {
                      this.state.orderData.tests[0].clinicalData[7].hBOCSubQuestions[0].answer.map(
                        (hBOCSubQuestions, i) => {
                          if (hboc1_q08_1_showResult == "") {
                            hboc1_q08_1_showResult =
                              "side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q08_1_pfHistory_AnyAgeValuesJustShow =
                              "side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q08_1_pfHistory_AnyAgeValues =
                              hBOCSubQuestions.side +
                              "," +
                              hBOCSubQuestions.relative +
                              "," +
                              hBOCSubQuestions.age;
                          } else {
                            hboc1_q08_1_showResult +=
                              ",side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q08_1_pfHistory_AnyAgeValuesJustShow +=
                              ",side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q08_1_pfHistory_AnyAgeValues +=
                              "," +
                              hBOCSubQuestions.side +
                              "," +
                              hBOCSubQuestions.relative +
                              "," +
                              hBOCSubQuestions.age;
                          }
                        }
                      );
                      this.setState({
                        hboc1_q08_1_showResult,
                        hboc1_q08_1_pfHistory_AnyAgeValuesJustShow,
                        hboc1_q08_1_pfHistory_AnyAgeValues,
                      });
                    }
                  } else {
                    $("#pfHistory_FBrOvPanAnyAge2").prop("checked", true);
                    //  this.pfHistory_FBrOvPanAnyAge2.checked = true;
                    this.pfHistory_FBrOvPanAnyAge.checked = false;
                    //this.pfHistory_FBrOvPanAnyAge2Click();
                  }

                  //hboc1_q09  Two breast cancers (bilateral) in one relative at any age
                  if (
                    this.state.orderData.tests[0].clinicalData[8] &&
                    typeof this.state.orderData.tests[0].clinicalData[8]
                      .answer != "undefined" &&
                    this.state.orderData.tests[0].clinicalData[8].answer ==
                    "true"
                  ) {
                    $("#pfHistory_FTwoBreasCanAge").prop("checked", true);
                    this.pfHistory_FTwoBreasCanAgeClick();

                    let hboc1_q09_1_showResult = "";
                    let hboc1_q09_1_pfHistory_AnyAgeValuesJustShow = "";
                    let hboc1_q09_1_pfHistory_AnyAgeValues = "";

                    if (
                      this.state.orderData.tests[0].clinicalData[8]
                        .hBOCSubQuestions[0].answer.length > 0
                    ) {
                      this.state.orderData.tests[0].clinicalData[8].hBOCSubQuestions[0].answer.map(
                        (hBOCSubQuestions, i) => {
                          if (hboc1_q09_1_showResult == "") {
                            hboc1_q09_1_showResult =
                              "side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q09_1_pfHistory_AnyAgeValuesJustShow =
                              "side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q09_1_pfHistory_AnyAgeValues =
                              hBOCSubQuestions.side +
                              "," +
                              hBOCSubQuestions.relative +
                              "," +
                              hBOCSubQuestions.age;
                          } else {
                            hboc1_q09_1_showResult +=
                              ",side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q09_1_pfHistory_AnyAgeValuesJustShow +=
                              ",side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q09_1_pfHistory_AnyAgeValues +=
                              "," +
                              hBOCSubQuestions.side +
                              "," +
                              hBOCSubQuestions.relative +
                              "," +
                              hBOCSubQuestions.age;
                          }
                        }
                      );

                      this.setState({
                        hboc1_q09_1_showResult,
                        hboc1_q09_1_pfHistory_AnyAgeValuesJustShow,
                        hboc1_q09_1_pfHistory_AnyAgeValues,
                      });
                    }
                  } else {
                    $("#pfHistory_FTwoBreasCanAge2").prop("checked", true);
                    this.pfHistory_FTwoBreasCanAge2Click();
                  }

                  //hboc1_q010 Three breast cancers in relatives on the same  side of the family at any age
                  if (
                    this.state.orderData.tests[0].clinicalData[9] &&
                    typeof this.state.orderData.tests[0].clinicalData[9]
                      .answer != "undefined" &&
                    this.state.orderData.tests[0].clinicalData[9].answer ==
                    "true"
                  ) {
                    $("#pfHistory_FThrBreasCanAge").prop("checked", true);
                    // this.pfHistory_FThrBreasCanAgeClick();
                    // this.state.pfHistory_FThrBreasCanAge = true;
                    this.setState({ pfHistory_FThrBreasCanAge: true });
                    let hboc1_q010_1_showResult = "";
                    let hboc1_q010_1_pfHistory_AnyAgeValuesJustShow = "";
                    let hboc1_q010_1_pfHistory_AnyAgeValues = "";

                    if (
                      this.state.orderData.tests[0].clinicalData[9]
                        .hBOCSubQuestions[0].answer.length > 0
                    ) {
                      this.state.orderData.tests[0].clinicalData[9].hBOCSubQuestions[0].answer.map(
                        (hBOCSubQuestions, i) => {
                          if (hboc1_q010_1_showResult == "") {
                            hboc1_q010_1_showResult =
                              "side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q010_1_pfHistory_AnyAgeValuesJustShow =
                              "side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q010_1_pfHistory_AnyAgeValues =
                              hBOCSubQuestions.side +
                              "," +
                              hBOCSubQuestions.relative +
                              "," +
                              hBOCSubQuestions.age;
                          } else {
                            hboc1_q010_1_showResult +=
                              ",side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q010_1_pfHistory_AnyAgeValuesJustShow +=
                              ",side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q010_1_pfHistory_AnyAgeValues +=
                              "," +
                              hBOCSubQuestions.side +
                              "," +
                              hBOCSubQuestions.relative +
                              "," +
                              hBOCSubQuestions.age;
                          }
                        }
                      );

                      // $(`#hboc1_q010_1_showResult`).html(
                      //   hboc1_q010_1_showResult
                      // );
                      // $(`#hboc1_q010_1_pfHistory_AnyAgeValuesJustShow`).val(
                      //   hboc1_q010_1_pfHistory_AnyAgeValuesJustShow
                      // );
                      // $(`#hboc1_q010_1_pfHistory_AnyAgeValues`).val(
                      //   hboc1_q010_1_pfHistory_AnyAgeValues
                      // );

                      this.setState({
                        hboc1_q010_1_showResult,
                        hboc1_q010_1_pfHistory_AnyAgeValuesJustShow,
                        hboc1_q010_1_pfHistory_AnyAgeValues,
                      });
                    }
                  } else {
                    $("#pfHistory_FThrBreasCanAge2").prop("checked", true);
                    // this.state.pfHistory_FThrBreasCanAge = false;
                    this.setState({ pfHistory_FThrBreasCanAge: false });
                    // this.pfHistory_FThrBreasCanAge2Click();
                  }

                  //hboc1_q011 Ovarian cancer at any age
                  if (
                    this.state.orderData.tests[0].clinicalData[10] &&
                    typeof this.state.orderData.tests[0].clinicalData[10]
                      .answer != "undefined" &&
                    this.state.orderData.tests[0].clinicalData[10].answer ==
                    "true"
                  ) {
                    $("#pfHistory_OvarianCanAge").prop("checked", true);
                    this.state.pfHistory_OvarianCanAge = true;
                    // pfHistory_OvarianCanAgeClick();
                    this.setState({ pfHistory_OvarianCanAge: true });
                    let hboc1_q011_1_showResult = "";
                    let hboc1_q011_1_pfHistory_AnyAgeValuesJustShow = "";
                    let hboc1_q011_1_pfHistory_AnyAgeValues = "";

                    if (
                      this.state.orderData.tests[0].clinicalData[10]
                        .hBOCSubQuestions[0].answer.length > 0
                    ) {
                      this.state.orderData.tests[0].clinicalData[10].hBOCSubQuestions[0].answer.map(
                        (hBOCSubQuestions, i) => {
                          if (hboc1_q011_1_showResult == "") {
                            hboc1_q011_1_showResult =
                              "side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q011_1_pfHistory_AnyAgeValuesJustShow =
                              "side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q011_1_pfHistory_AnyAgeValues =
                              hBOCSubQuestions.side +
                              "," +
                              hBOCSubQuestions.relative +
                              "," +
                              hBOCSubQuestions.age;
                          } else {
                            hboc1_q011_1_showResult +=
                              ",side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q011_1_pfHistory_AnyAgeValuesJustShow +=
                              ",side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q011_1_pfHistory_AnyAgeValues +=
                              "," +
                              hBOCSubQuestions.side +
                              "," +
                              hBOCSubQuestions.relative +
                              "," +
                              hBOCSubQuestions.age;
                          }
                        }
                      );

                      // $(`#hboc1_q011_1_showResult`).html(
                      //   hboc1_q011_1_showResult
                      // );
                      // $(`#hboc1_q011_1_pfHistory_AnyAgeValuesJustShow`).val(
                      //   hboc1_q011_1_pfHistory_AnyAgeValuesJustShow
                      // );
                      // $(`#hboc1_q011_1_pfHistory_AnyAgeValues`).val(
                      //   hboc1_q011_1_pfHistory_AnyAgeValues
                      // );

                      this.setState({
                        hboc1_q011_1_showResult,
                        hboc1_q011_1_pfHistory_AnyAgeValuesJustShow,
                        hboc1_q011_1_pfHistory_AnyAgeValues,
                      });
                    }
                  } else {
                    $("#pfHistory_OvarianCanAge2").prop("checked", true);
                    //   this.state.pfHistory_OvarianCanAge = false;
                    // pfHistory_OvarianCanAge2Click();
                    this.setState({
                      pfHistory_OvarianCanAge: false,
                    });
                  }

                  //hboc1_q012 Pancreatic cancer at any age
                  if (
                    this.state.orderData.tests[0].clinicalData[11] &&
                    typeof this.state.orderData.tests[0].clinicalData[11]
                      .answer != "undefined" &&
                    this.state.orderData.tests[0].clinicalData[11].answer ==
                    "true"
                  ) {
                    $("#pfHistory_PancreaticCanAge").prop("checked", true);
                    //  this.state.pfHistory_PancreaticCanAge = true;
                    // pfHistory_PancreaticCanAgeClick();
                    this.setState({ pfHistory_PancreaticCanAge: true });
                    let hboc1_q012_1_showResult = "";
                    let hboc1_q012_1_pfHistory_AnyAgeValuesJustShow = "";
                    let hboc1_q012_1_pfHistory_AnyAgeValues = "";

                    if (
                      this.state.orderData.tests[0].clinicalData[11]
                        .hBOCSubQuestions[0].answer.length > 0
                    ) {
                      this.state.orderData.tests[0].clinicalData[11].hBOCSubQuestions[0].answer.map(
                        (hBOCSubQuestions, i) => {
                          if (hboc1_q012_1_showResult == "") {
                            hboc1_q012_1_showResult =
                              "side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q012_1_pfHistory_AnyAgeValuesJustShow =
                              "side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q012_1_pfHistory_AnyAgeValues =
                              hBOCSubQuestions.side +
                              "," +
                              hBOCSubQuestions.relative +
                              "," +
                              hBOCSubQuestions.age;
                          } else {
                            hboc1_q012_1_showResult +=
                              ",side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q012_1_pfHistory_AnyAgeValuesJustShow +=
                              ",side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q012_1_pfHistory_AnyAgeValues +=
                              "," +
                              hBOCSubQuestions.side +
                              "," +
                              hBOCSubQuestions.relative +
                              "," +
                              hBOCSubQuestions.age;
                          }
                        }
                      );

                      // $(`#hboc1_q012_1_showResult`).html(
                      //   hboc1_q012_1_showResult
                      // );
                      // $(`#hboc1_q012_1_pfHistory_AnyAgeValuesJustShow`).val(
                      //   hboc1_q012_1_pfHistory_AnyAgeValuesJustShow
                      // );
                      // $(`#hboc1_q012_1_pfHistory_AnyAgeValues`).val(
                      //   hboc1_q012_1_pfHistory_AnyAgeValues
                      // );

                      this.setState({
                        hboc1_q012_1_showResult,
                        hboc1_q012_1_pfHistory_AnyAgeValuesJustShow,
                        hboc1_q012_1_pfHistory_AnyAgeValues,
                      });
                    }
                  } else {
                    $("#pfHistory_PancreaticCanAge2").prop("checked", true);
                    // this.state.pfHistory_PancreaticCanAge = false;
                    this.setState({ pfHistory_PancreaticCanAge: false });
                    //pfHistory_PancreaticCanAge2Click();
                  }

                  //hboc1_q013 Male breast cancer at any age
                  if (
                    this.state.orderData.tests[0].clinicalData[12] &&
                    typeof this.state.orderData.tests[0].clinicalData[12]
                      .answer != "undefined" &&
                    this.state.orderData.tests[0].clinicalData[12].answer ==
                    "true"
                  ) {
                    $("#pfHistory_MaleBreastCanAge").prop("checked", true);
                    this.state.pfHistory_MaleBreastCanAge = true;
                    // pfHistory_MaleBreastCanAgeClick();
                    this.setState({ pfHistory_MaleBreastCanAge: true });

                    let hboc1_q013_1_showResult = "";
                    let hboc1_q013_1_pfHistory_AnyAgeValuesJustShow = "";
                    let hboc1_q013_1_pfHistory_AnyAgeValues = "";

                    if (
                      this.state.orderData.tests[0].clinicalData[12]
                        .hBOCSubQuestions[0].answer.length > 0
                    ) {
                      this.state.orderData.tests[0].clinicalData[12].hBOCSubQuestions[0].answer.map(
                        (hBOCSubQuestions, i) => {
                          if (hboc1_q013_1_showResult == "") {
                            hboc1_q013_1_showResult =
                              "side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q013_1_pfHistory_AnyAgeValuesJustShow =
                              "side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q013_1_pfHistory_AnyAgeValues =
                              hBOCSubQuestions.side +
                              "," +
                              hBOCSubQuestions.relative +
                              "," +
                              hBOCSubQuestions.age;
                          } else {
                            hboc1_q013_1_showResult +=
                              ",side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q013_1_pfHistory_AnyAgeValuesJustShow +=
                              ",side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q013_1_pfHistory_AnyAgeValues +=
                              "," +
                              hBOCSubQuestions.side +
                              "," +
                              hBOCSubQuestions.relative +
                              "," +
                              hBOCSubQuestions.age;
                          }
                        }
                      );

                      // $(`#hboc1_q013_1_showResult`).html(
                      //   hboc1_q013_1_showResult
                      // );
                      // $(`#hboc1_q013_1_pfHistory_AnyAgeValuesJustShow`).val(
                      //   hboc1_q013_1_pfHistory_AnyAgeValuesJustShow
                      // );
                      // $(`#hboc1_q013_1_pfHistory_AnyAgeValues`).val(
                      //   hboc1_q013_1_pfHistory_AnyAgeValues
                      // );

                      this.setState({
                        hboc1_q013_1_showResult,
                        hboc1_q013_1_pfHistory_AnyAgeValuesJustShow,
                        hboc1_q013_1_pfHistory_AnyAgeValues,
                      });
                    }
                  } else {
                    $("#pfHistory_MaleBreastCanAge2").prop("checked", true);
                    this.state.pfHistory_MaleBreastCanAge = false;
                    this.setState({ pfHistory_MaleBreastCanAge: false });
                    // pfHistory_MaleBreastCanAge2Click();
                  }

                  //hboc1_q014 Metastatic prostate cancer at any age
                  if (
                    this.state.orderData.tests[0].clinicalData[13] &&
                    typeof this.state.orderData.tests[0].clinicalData[13]
                      .answer != "undefined" &&
                    this.state.orderData.tests[0].clinicalData[13].answer ==
                    "true"
                  ) {
                    $("#pfHistory_MetaProstateCanAge").prop("checked", true);
                    this.pfHistory_MetaProstateCanAgeClick();
                    // this.state.pfHistory_MetaProstateCanAge = true;
                    this.setState({ pfHistory_MetaProstateCanAge: true });

                    let hboc1_q014_1_showResult = "";
                    let hboc1_q014_1_pfHistory_AnyAgeValuesJustShow = "";
                    let hboc1_q014_1_pfHistory_AnyAgeValues = "";

                    if (
                      this.state.orderData.tests[0].clinicalData[13]
                        .hBOCSubQuestions[0].answer.length > 0
                    ) {
                      this.state.orderData.tests[0].clinicalData[13].hBOCSubQuestions[0].answer.map(
                        (hBOCSubQuestions, i) => {
                          if (hboc1_q014_1_showResult == "") {
                            hboc1_q014_1_showResult =
                              "side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q014_1_pfHistory_AnyAgeValuesJustShow =
                              "side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q014_1_pfHistory_AnyAgeValues =
                              hBOCSubQuestions.side +
                              "," +
                              hBOCSubQuestions.relative +
                              "," +
                              hBOCSubQuestions.age;
                          } else {
                            hboc1_q014_1_showResult +=
                              ",side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q014_1_pfHistory_AnyAgeValuesJustShow +=
                              ",side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q014_1_pfHistory_AnyAgeValues +=
                              "," +
                              hBOCSubQuestions.side +
                              "," +
                              hBOCSubQuestions.relative +
                              "," +
                              hBOCSubQuestions.age;
                          }
                        }
                      );

                      // $(`#hboc1_q014_1_showResult`).html(
                      //   hboc1_q014_1_showResult
                      // );
                      // $(`#hboc1_q014_1_pfHistory_AnyAgeValuesJustShow`).val(
                      //   hboc1_q014_1_pfHistory_AnyAgeValuesJustShow
                      // );
                      // $(`#hboc1_q014_1_pfHistory_AnyAgeValues`).val(
                      //   hboc1_q014_1_pfHistory_AnyAgeValues
                      // );

                      this.setState({
                        hboc1_q014_1_showResult,
                        hboc1_q014_1_pfHistory_AnyAgeValuesJustShow,
                        hboc1_q014_1_pfHistory_AnyAgeValues,
                      });
                    }
                  } else {
                    $("#pfHistory_MetaProstateCanAge2").prop("checked", true);
                    this.pfHistory_MetaProstateCanAge.checked = false;
                    // this.state.pfHistory_MetaProstateCanAge = false;
                    this.pfHistory_MetaProstateCanAge2.checked = true;
                    this.setState({
                      pfHistory_MetaProstateCanAge: false,
                    });
                    // pfHistory_MetaProstateCanAge2Click();
                  }

                  //hboc1_q015 Colon cancer at 49 or younger
                  if (
                    this.state.orderData.tests[0].clinicalData[14] &&
                    typeof this.state.orderData.tests[0].clinicalData[14]
                      .answer != "undefined" &&
                    this.state.orderData.tests[0].clinicalData[14].answer ==
                    "true"
                  ) {
                    $("#pfHistory_ColorCanAge").prop("checked", true);
                    //  pfHistory_ColorCanAgeClick();
                    this.state.pfHistory_ColorCanAge = true;
                    this.setState({ pfHistory_ColorCanAge: true });
                    let hboc1_q015_1_showResult = "";
                    let hboc1_q015_1_pfHistory_AnyAgeValuesJustShow = "";
                    let hboc1_q015_1_pfHistory_AnyAgeValues = "";

                    if (
                      this.state.orderData.tests[0].clinicalData[14]
                        .hBOCSubQuestions[0].answer.length > 0
                    ) {
                      this.state.orderData.tests[0].clinicalData[14].hBOCSubQuestions[0].answer.map(
                        (hBOCSubQuestions, i) => {
                          if (hboc1_q015_1_showResult == "") {
                            hboc1_q015_1_showResult =
                              "side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q015_1_pfHistory_AnyAgeValuesJustShow =
                              "side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q015_1_pfHistory_AnyAgeValues =
                              hBOCSubQuestions.side +
                              "," +
                              hBOCSubQuestions.relative +
                              "," +
                              hBOCSubQuestions.age;
                          } else {
                            hboc1_q015_1_showResult +=
                              ",side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q015_1_pfHistory_AnyAgeValuesJustShow +=
                              ",side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q015_1_pfHistory_AnyAgeValues +=
                              "," +
                              hBOCSubQuestions.side +
                              "," +
                              hBOCSubQuestions.relative +
                              "," +
                              hBOCSubQuestions.age;
                          }
                        }
                      );

                      // $(`#hboc1_q015_1_showResult`).html(
                      //   hboc1_q015_1_showResult
                      // );
                      // $(`#hboc1_q015_1_pfHistory_AnyAgeValuesJustShow`).val(
                      //   hboc1_q015_1_pfHistory_AnyAgeValuesJustShow
                      // );
                      // $(`#hboc1_q015_1_pfHistory_AnyAgeValues`).val(
                      //   hboc1_q015_1_pfHistory_AnyAgeValues
                      // );

                      this.setState({
                        hboc1_q015_1_showResult,
                        hboc1_q015_1_pfHistory_AnyAgeValuesJustShow,
                        hboc1_q015_1_pfHistory_AnyAgeValues,
                      });
                    }
                  } else {
                    $("#pfHistory_ColorCanAge2").prop("checked", true);
                    // this.state.pfHistory_ColorCanAge = false;
                    this.setState({ pfHistory_ColorCanAge: false });
                    // pfHistory_ColorCanAge2Click();
                  }
                  //hboc1_q016 Uterine cancer at 49 or younger
                  if (
                    this.state.orderData.tests[0].clinicalData[15] &&
                    typeof this.state.orderData.tests[0].clinicalData[15]
                      .answer != "undefined" &&
                    this.state.orderData.tests[0].clinicalData[15].answer ==
                    "true"
                  ) {
                    $("#pfHistory_UterineCanAge").prop("checked", true);
                    // pfHistory_UterineCanAgeClick();
                    // this.state.pfHistory_UterineCanAge = true;
                    this.setState({ pfHistory_UterineCanAge: true });

                    let hboc1_q016_1_showResult = "";
                    let hboc1_q016_1_pfHistory_AnyAgeValuesJustShow = "";
                    let hboc1_q016_1_pfHistory_AnyAgeValues = "";

                    if (
                      this.state.orderData.tests[0].clinicalData[15]
                        .hBOCSubQuestions[0].answer.length > 0
                    ) {
                      this.state.orderData.tests[0].clinicalData[15].hBOCSubQuestions[0].answer.map(
                        (hBOCSubQuestions, i) => {
                          if (hboc1_q016_1_showResult == "") {
                            hboc1_q016_1_showResult =
                              "side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q016_1_pfHistory_AnyAgeValuesJustShow =
                              "side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q016_1_pfHistory_AnyAgeValues =
                              hBOCSubQuestions.side +
                              "," +
                              hBOCSubQuestions.relative +
                              "," +
                              hBOCSubQuestions.age;
                          } else {
                            hboc1_q016_1_showResult +=
                              ",side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q016_1_pfHistory_AnyAgeValuesJustShow +=
                              ",side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q016_1_pfHistory_AnyAgeValues +=
                              "," +
                              hBOCSubQuestions.side +
                              "," +
                              hBOCSubQuestions.relative +
                              "," +
                              hBOCSubQuestions.age;
                          }
                        }
                      );

                      // $(`#hboc1_q016_1_showResult`).html(
                      //   hboc1_q016_1_showResult
                      // );
                      // $(`#hboc1_q016_1_pfHistory_AnyAgeValuesJustShow`).val(
                      //   hboc1_q016_1_pfHistory_AnyAgeValuesJustShow
                      // );
                      // $(`#hboc1_q016_1_pfHistory_AnyAgeValues`).val(
                      //   hboc1_q016_1_pfHistory_AnyAgeValues
                      // );

                      this.setState({
                        hboc1_q016_1_showResult,
                        hboc1_q016_1_pfHistory_AnyAgeValuesJustShow,
                        hboc1_q016_1_pfHistory_AnyAgeValues,
                      });
                    }
                  } else {
                    $("#pfHistory_UterineCanAge2").prop("checked", true);
                    //this.state.pfHistory_UterineCanAge = false;
                    this.setState({
                      pfHistory_UterineCanAge: false,
                    });
                    // pfHistory_UterineCanAge2Click();
                  }

                  //hboc1_q017 Ashkenazi Jewish ancestry with breast cancer
                  if (
                    this.state.orderData.tests[0].clinicalData[16] &&
                    typeof this.state.orderData.tests[0].clinicalData[16]
                      .answer != "undefined" &&
                    this.state.orderData.tests[0].clinicalData[16].answer ==
                    "true"
                  ) {
                    $("#pfHistory_AshJewishAncesCanAge").prop("checked", true);
                    //pfHistory_AshJewishAncesCanAgeClick();
                    // this.state.pfHistory_AshJewishAncesCanAge = true;
                    this.setState({ pfHistory_AshJewishAncesCanAge: true });
                    let hboc1_q017_1_showResult = "";
                    let hboc1_q017_1_pfHistory_AnyAgeValuesJustShow = "";
                    let hboc1_q017_1_pfHistory_AnyAgeValues = "";

                    if (
                      this.state.orderData.tests[0].clinicalData[16]
                        .hBOCSubQuestions[0].answer.length > 0
                    ) {
                      this.state.orderData.tests[0].clinicalData[16].hBOCSubQuestions[0].answer.map(
                        (hBOCSubQuestions, i) => {
                          if (hboc1_q017_1_showResult == "") {
                            hboc1_q017_1_showResult =
                              "side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q017_1_pfHistory_AnyAgeValuesJustShow =
                              "side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q017_1_pfHistory_AnyAgeValues =
                              hBOCSubQuestions.side +
                              "," +
                              hBOCSubQuestions.relative +
                              "," +
                              hBOCSubQuestions.age;
                          } else {
                            hboc1_q017_1_showResult +=
                              ",side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q017_1_pfHistory_AnyAgeValuesJustShow +=
                              ",side: " +
                              hBOCSubQuestions.side +
                              " relative: " +
                              hBOCSubQuestions.relative +
                              " age: " +
                              hBOCSubQuestions.age;
                            hboc1_q017_1_pfHistory_AnyAgeValues +=
                              "," +
                              hBOCSubQuestions.side +
                              "," +
                              hBOCSubQuestions.relative +
                              "," +
                              hBOCSubQuestions.age;
                          }
                        }
                      );

                      // $(`#hboc1_q017_1_showResult`).html(
                      //   hboc1_q017_1_showResult
                      // );
                      // $(`#hboc1_q017_1_pfHistory_AnyAgeValuesJustShow`).val(
                      //   hboc1_q017_1_pfHistory_AnyAgeValuesJustShow
                      // );
                      // $(`#hboc1_q017_1_pfHistory_AnyAgeValues`).val(
                      //   hboc1_q017_1_pfHistory_AnyAgeValues
                      // );

                      this.setState({
                        hboc1_q017_1_showResult,
                        hboc1_q017_1_pfHistory_AnyAgeValuesJustShow,
                        hboc1_q017_1_pfHistory_AnyAgeValues,
                      });
                    }
                  } else {
                    $("#pfHistory_AshJewishAncesCanAge2").prop("checked", true);
                    // this.state.pfHistory_AshJewishAncesCanAge = false;
                    this.setState({ pfHistory_AshJewishAncesCanAge: false });
                    //pfHistory_AshJewishAncesCanAge2Click();
                  }

                  //hboc1_q018 Does the patient have a family history of other cancers?
                  if (
                    this.state.orderData.tests[0].clinicalData[17] &&
                    typeof this.state.orderData.tests[0].clinicalData[17]
                      .answer != "undefined" &&
                    this.state.orderData.tests[0].clinicalData[17].answer ==
                    "true"
                  ) {
                    $("#pfHistory_otherCan").prop("checked", true);
                    // this.state.pfHistory_otherCan = true;
                    this.setState({ pfHistory_otherCan: true });
                    // pfHistory_otherCanClick();
                  } else {
                    // this.state.pfHistory_otherCan = false;
                    this.setState({ pfHistory_otherCan: false });
                    $("#pfHistory_otherCan2").prop("checked", true);
                    // pfHistory_otherCan2Click();
                  }

                  //hboc1_q019 Has anyone in the patient's family had genetic testing for hereditary cancer?
                  if (
                    this.state.orderData.tests[0].clinicalData[18] &&
                    typeof this.state.orderData.tests[0].clinicalData[18]
                      .answer != "undefined" &&
                    this.state.orderData.tests[0].clinicalData[18].answer ==
                    "true"
                  ) {
                    $("#pfHistory_geHerediraryCan").prop("checked", true);
                    // pfHistory_geHerediraryCanClick();
                    this.setState({ pfHistory_geHerediraryCan: true });
                    //  this.state.pfHistory_geHerediraryCan = true;
                  } else {
                    $("#pfHistory_geHerediraryCan2").prop("checked", true);
                    // this.state.pfHistory_geHerediraryCan = false;
                    this.setState({ pfHistory_geHerediraryCan: false });
                    // pfHistory_geHerediraryCan2Click();
                  }

                  //hboc1_q021
                  if (
                    this.state.orderData.tests[0].clinicalData[20] &&
                    typeof this.state.orderData.tests[0].clinicalData[20]
                      .answer != "undefined" &&
                    this.state.orderData.tests[0].clinicalData[20].answer ==
                    "true"
                  ) {
                    $("#pfHistory_PCurTreat_boneMar").prop("checked", true);
                    // this.state.pfHistory_PCurTreat_boneMar = true;
                    this.setState({ pfHistory_PCurTreat_boneMar: false });
                  }
                  //hboc1_q022
                  if (
                    this.state.orderData.tests[0].clinicalData[21] &&
                    typeof this.state.orderData.tests[0].clinicalData[21]
                      .answer != "undefined" &&
                    this.state.orderData.tests[0].clinicalData[21].answer ==
                    "true"
                  ) {
                    $("#pfHistory_PCurTreat_Chemo").prop("checked", true);
                    // this.state.pfHistory_PCurTreat_Chemo = true;
                    this.setState({ pfHistory_PCurTreat_Chemo: true });
                  }
                  //hboc1_q023
                  if (
                    this.state.orderData.tests[0].clinicalData[22] &&
                    typeof this.state.orderData.tests[0].clinicalData[22]
                      .answer != "undefined" &&
                    this.state.orderData.tests[0].clinicalData[22].answer ==
                    "true"
                  ) {
                    $("#pfHistory_PCurTreat_Radia").prop("checked", true);
                    //  this.state.pfHistory_PCurTreat_Radia = true;
                    this.setState({ pfHistory_PCurTreat_Radia: true });
                  }
                  //hboc1_q024
                  if (
                    this.state.orderData.tests[0].clinicalData[23] &&
                    typeof this.state.orderData.tests[0].clinicalData[23]
                      .answer != "undefined" &&
                    this.state.orderData.tests[0].clinicalData[23].answer ==
                    "true"
                  ) {
                    $("#pfHistory_PCurTreat_Surgery").prop("checked", true);
                    // this.state.pfHistory_PCurTreat_Surgery = true;
                    this.setState({ pfHistory_PCurTreat_Surgery: true });
                  }

                  //Kit Shipping Information
                  if (
                    this.state.orderData.tests[0].kitInformation.drawType
                      .length > 0
                  ) {
                    if (
                      this.state.orderData.tests[0].kitInformation.drawType.includes(
                        "My physician needs mobile phlebotomy"
                      )
                    ) {
                      this.setState({
                        kitShip_PatMobPhlebotomy:
                          "My physician needs mobile phlebotomy",
                      });
                    }
                    if (
                      this.state.orderData.tests[0].kitInformation.drawType.includes(
                        "Patient will get blood draw at Quest PSC"
                      )
                    ) {
                      this.setState({
                        kitShip_PatblooddrawQuest:
                          "Patient will get blood draw at Quest PSC",
                      });
                    }
                    if (
                      this.state.orderData.tests[0].kitInformation.drawType.includes(
                        "Other"
                      )
                    ) {
                      this.setState({ kitShip_PatOthers: "Other" });
                    }
                  } //end Kit Shipping Information

                  //researchConsent
                  if (
                    typeof this.state.orderData.researchConsent != "undefined"
                  ) {
                    if (this.state.orderData.researchConsent == "Yes") {
                      $("#researchConsent").prop("checked", true);
                    }
                  }

                  //researchUseOnly
                  if (
                    typeof this.state.orderData.researchUseOnly != "undefined"
                  ) {
                    if (this.state.orderData.researchUseOnly == "Yes") {
                      $("#researchUseOnly").prop("checked", true);
                    }
                  }

                  //certify Types certifyTypes
                  if (typeof this.state.orderData.certifyTypes != "undefined") {
                    if (this.state.orderData.certifyTypes.length > 0) {
                      if (
                        this.state.orderData.certifyTypes.includes(
                          "A healthcare provider"
                        )
                      ) {
                        $("#kitShip_healthcareProvider").prop("checked", true);
                      }
                      if (
                        this.state.orderData.certifyTypes.includes(
                          "An Aspira employee ordering on behalf of a healthcare provider"
                        )
                      ) {
                        $("#kitShip_employeehealthcareProvider").prop(
                          "checked",
                          true
                        );
                      }
                      if (
                        this.state.orderData.certifyTypes.includes(
                          "An Aspira partner ordering on behalf of a healthcare provider"
                        )
                      ) {
                        $("#kitShip_partnerhealthcareProvider").prop(
                          "checked",
                          true
                        );
                      }
                    }
                  }
                  //certify Types certifyTypes end
                }
              );
            }
          );
        }
      }
    );
  }

  //onChangeDuedate_pregnant = date => this.setState({ duedate_pregnant: date });
  onChangePatinfoDob = (date) => this.setState({ patinfo_dob: date });
  onChangeBillinfoCDob = (date) => this.setState({ billinfoC_dob: date });

  showToast(type) {
    const id = Math.floor(Math.random() * 101 + 1);

    switch (type) {
      case "success":
        this.toastProperties = {
          id,
          title: "Success",
          description: "This is a success toast component",
          backgroundColor: "#5cb85c",
          icon: "/assets/assets/check.svg",
        };
        break;
      case "danger":
        this.toastProperties = {
          id,
          title: "Danger",
          description: "This is a error toast component",
          backgroundColor: "#d9534f",
          icon: "/assets/assets/error.svg",
        };
        break;
      case "info":
        this.toastProperties = {
          id,
          title: "Info",
          description: "This is an info toast component",
          backgroundColor: "#5bc0de",
          icon: "/assets/assets/info.svg",
        };
        break;
      case "warning":
        this.toastProperties = {
          id,
          title: "Warning",
          description: "This is a warning toast component",
          backgroundColor: "#f0ad4e",
          icon: "/assets/assets/warning.svg",
        };
        break;

      default:
        this.setState({ toastList: [] });
    }

    // this.setState([...this.state.list, this.toastProperties]);
    this.setState({ list: [this.toastProperties] });
  }

  getBillingInfoJson() {
    //Billing Information
    const privateInsurance = this.state.privateInsurance;
    const medicare = this.state.medicare;
    const patientSelfPay = this.state.patientSelfPay;
    const medicaid = this.state.medicaid;
    const freeTrial = this.state.freeTrial;
    const orderingFacility = this.state.orderingFacility;

    let billingInfo = {
      privateInsurance,
      medicare,
      patientSelfPay,
      medicaid,
      freeTrial,
      orderingFacility,
      primaryInsurance: {
        primaryInsuranceCarrier: this.state.selectedPayor.value,
        planID: this.state.billinfo_planId,
        memberID: this.state.billinfo_MemberID,
        groupID: this.state.billinfo_GroupID,
      },
      secondaryInsurance: {
        secondaryInsuranceCarrier:
          this.state.billinfo_SecondaryInsuranceCarrier,
        secondaryPlanID: this.state.billinfo_SecondaryplanId,
        secondaryMemberID: this.state.billinfo_SecondaryMemberID,
        secondaryGroupID: this.state.billinfo_SecondaryGroupID,
      },
      tertiaryInsurance: {
        tertiaryInsuranceCarrier: this.state.billinfo_TertiaryInsuranceCarrier,
        tertiaryMemberID: this.state.billinfo_TertiaryMemberID,
        tertiaryGroupID: this.state.billinfo_TertiaryGroupID,
        tertiaryPlanID: this.state.billinfo_TertiaryPlanID,
      },
      insuredInfo: {
        nameOfInsured: {
          firstName: this.state.billinfo_FirstNameofInsured,
          lastName: this.state.billinfo_LastNameofInsured,
        },
        dob: moment(this.state.billinfoC_dob).format("YYYY-MM-DD"),
        relationshipInsured: this.state.billinfo_RelationshipInsured,
      },
      notesText: this.state.notesText,
    };
    console.log("after pushing billing info=========================");
    console.log(billingInfo);
    //return;
    const isFirstNameMissing = !billingInfo.insuredInfo.nameOfInsured.firstName;
    const isLastNameMissing = !billingInfo.insuredInfo.nameOfInsured.lastName;
    const isDOBMissing =
      billingInfo.insuredInfo.dob === "Invalid date" ||
      !billingInfo.insuredInfo.dob;

    console.log("\n---");
    console.log("do person: ", billingInfo.insuredInfo.dob);
    console.log("---\n");

    if (
      (privateInsurance || medicare || medicaid) &&
      (isFirstNameMissing || isLastNameMissing || isDOBMissing)
    ) {
      if (isFirstNameMissing)
        message.warning("Insured person first name is missing");
      if (isLastNameMissing)
        message.warning("Insured person last name is missing");
      if (isDOBMissing)
        message.warning("Insured person date of birth is missing");

      return null;
    }
    console.log("billingInfo:");
    console.log(billingInfo);
    return billingInfo;
    //Billing Information
  }

  getFamilyHistorySubQuestions = (
    _FBrOvPanAnyAge,
    _AnyAgeValues,
    _question
  ) => {
    let dataValueanswers = [];
    let dataValueanswer = {
      side: "",
      relative: "",
      age: "",
    };
    let qnA = {
      questionCode: "",
      answer: false,
      hBOCSubQuestions: [],
    };
    if (_FBrOvPanAnyAge && _AnyAgeValues.length > 0) {
      const temp_pfHistory_AnyAgeValues = _AnyAgeValues.split(",");
      let j = 0;
      for (let i = 0; i < temp_pfHistory_AnyAgeValues.length / 3; i++) {
        dataValueanswer = {
          side: temp_pfHistory_AnyAgeValues[j],
          relative: temp_pfHistory_AnyAgeValues[j + 1],
          age: temp_pfHistory_AnyAgeValues[j + 2],
        };

        dataValueanswers.push(dataValueanswer);
        j = j + 3;
      }
    }

    if (_FBrOvPanAnyAge) {
      qnA = {
        questionCode: _question,
        answer: true,
        hBOCSubQuestions: [
          {
            questionCode: `${_question}_1`,
            answer: dataValueanswers,
          },
        ],
      };
    } else {
      qnA = {
        questionCode: _question,
        answer: false,
        hBOCSubQuestions: [
          {
            questionCode: `${_question}_1`,
            answer: [],
          },
        ],
      };
    }
    return qnA;
  };

  async handleSave() {
    console.log("enter-------------------------------");
    console.log("-------------------------diagonisis");
    console.log(this.state.diagonisisCodes.length);
    const user = JSON.parse(localStorage.getItem("user"));
    console.log("user.token: ", user.token);

    const formData = new FormData();

    //ASPIRA GENETIX Hereditary Breast and Ovarian Cancer
    const hbocOvarianCancerFormPanel = [];
    $(
      `#${this.state.frmprefix}ASPGENHereditaryBreastOvarianCancerForm :input`
    ).each((i, inp) => {
      if ($(inp).is(":checked")) {
        // hbocOvarianCancerFormPanel.push($(inp).data('val'));
        hbocOvarianCancerFormPanel.push({
          code: $(inp).data("val"),
          qBenchId: $(inp).data("qbenchid"),
        });
      }
    });
    /* const privateInsurance = this.state.privateInsurance;
    const medicare = this.state.medicare;
    const patientSelfPay = this.state.patientSelfPay;
    const medicaid = this.state.medicaid;
    const freeTrial = this.state.freeTrial;
    const orderingFacility = this.state.orderingFacility; */

    let dob = moment(this.state.billinfoC_dob).format("YYYY-MM-DD");
    console.log("getBillingInfoJson->dob: ", dob);

    let billingInfo = this.getBillingInfoJson();
    if (!billingInfo) {
      return;
    }
    /* {
     privateInsurance,
     medicare,
     patientSelfPay,
     medicaid,
     freeTrial,
     orderingFacility,
     primaryInsurance: {
       primaryInsuranceCarrier: this.state.billinfo_PrimaryInsuranceCarrier,
       planID: this.state.billinfo_planId,
       memberID: this.state.billinfo_MemberID,
       groupID: this.state.billinfo_GroupID,
     },
     secondaryInsurance: {
       secondaryInsuranceCarrier: this.state.billinfo_SecondaryInsuranceCarrier,
       secondaryPlanID: this.state.billinfo_SecondaryplanId,
       secondaryMemberID: this.state.billinfo_SecondaryMemberID,
       secondaryGroupID: this.state.billinfo_SecondaryGroupID,
     },
     tertiaryInsurance: {
       tertiaryInsuranceCarrier: this.state.billinfo_TertiaryInsuranceCarrier,
       tertiaryMemberID: this.state.billinfo_TertiaryMemberID,
       tertiaryGroupID: this.state.billinfo_TertiaryGroupID,
       tertiaryPlanID: this.state.billinfo_TertiaryPlanID || '',
     },
     insuredInfo: {
       nameOfInsured: {
         firstName: this.state.billinfo_FirstNameofInsured,
         lastName: this.state.billinfo_LastNameofInsured,
       },
       dob: moment(this.state.billinfoC_dob).format("YYYY-MM-DD"),
       relationshipInsured: this.state.billinfo_RelationshipInsured,
     },
     notesText: this.state.notesText,
   }; */

    console.log("sm hbocOvarianCancerFormPanel: ", hbocOvarianCancerFormPanel);
    console.log("sm billingInfo: ", billingInfo);
    //add physician
    let addresses = [
      {
        legacyClientId: this.state.orderData.legacyClientId,
        locationName: "USA",
        address_1: this.state.phyinfoC_address,
        address_2: "",
        addressType: "Primary",
        city: this.state.phyinfoC_city,
        country: "USA",
        state: this.state.phyinfo_state.label,
        zipCode: this.state.phyinfoC_zipCode,
        phoneNo: this.state.phyinfoC_Phone,
        fax: this.state.phyinfoC_fax,
        faxCopyTo: this.state.phyinfoC_Faxcopyto,
      },
    ];
    let patinfo_dob = moment(this.state.patinfo_dob).format("YYYY-MM-DD");
    let patient = {
      patientId: this.patientId,
      email: this.state.patinfo_emailaddress,
      firstName: this.state.patinfo_firstname,
      lastName: this.state.patinfo_lastname,
      dob: moment(this.state.patinfo_dob).format("YYYY-MM-DD"),
      phoneNo: this.state.patinfo_PhoneNumber,
      address: {
        locationName: "USA",
        address_1: this.state.patinfo_address,
        address_2: "",
        addressType: "Primary",
        city: this.state.patinfo_city,
        country: "USA",
        state: this.state.patinfo_State.label,
        zipCode: this.state.patinfo_zipcode,
      },

      ethnicity: this.state.patinfo_ethnicity.includes("Other")
        ? "Other" + this.state.patinfo_OtherEthnicitycity
        : this.state.patinfo_ethnicity,
      gender: this.state.patinfo_gender,
    };
    const researchConsent = $(`#researchConsent`).is(":checked") ? "Yes" : "No";
    const researchUseOnly = $(`#researchUseOnly`).is(":checked") ? "Yes" : "No";
    let order = {
      _id: this._id,
      orderId: this.orderId,
      Physician: {
        physicianId: this.physicianId,
        organizationAccount: this.state.phyinfo_Organization_account,
        firstName: this.state.phyinfo_first_name,
        lastName: this.state.phyinfo_last_name,
        email: this.state.phyinfo_email,
        npiNumber: this.state.phyinfo_npi,
        addresses: addresses,
      },
      Patient: patient,
      billingInfo,
      tests: [
        {
          ova1PlusBiomarkers: [],
          icd10Code: {},
          kitInformation: {
            drawType: [""],
            otherInfo: "",
          },
          notes: "",
          genetixCSPanel: hbocOvarianCancerFormPanel,
          genetixCSIndicationOfTesting: {},
          dueDate: "",
          ultrasoundsFindings: "",
          clinicalData: [],
          testName: "GenetixHBOC1",
          testCode: "GenetixHBOC1",
        },
      ],
      certifyTypes: [],
      payorId: this.state.selectedPayor,
      researchConsent: researchConsent,
      researchUseOnly: researchUseOnly,
      // createdAt: this.state.orderData.createdAt,
      // isDeleted: false,
      // orderId: this.state.orderData.orderId,
      // patientId: this.state.orderData.patientId,
      // physicianId: this.state.orderData.physicianId,
      // status: this.state.orderData.status,

      updatedAt: this.state.updatedAt,
    };
    console.log("edited order------------------------------------");
    console.log(order);
    console.log("Order complete with every field========================");
    //order.tests[0].clinicalData = this.state.orderData.tests[0].clinicalData;
    //add physician

    //add patient
    let ethnicities = [];
    /* let inputs = $("#pat_ethnicity_container").find(":input");
    inputs.map((i, elem) => {
      let $elem = $(elem);

      const _type = $elem.attr("type");

      if (_type == "radio") {
        if ($elem.is(":checked")) {
          ethnicities.push($elem.data("val"));
        }
      }
    }); */
    if ($("#pat_ethnicity_container #patinfo_Other").is(":checked")) {
      ethnicities.push($("#patinfo_OtherEthnicitycity").val());
    }
    if (this.state.patinfo_ethnicity === "Other") {
      ethnicities.push(this.state.patinfo_OtherEthnicitycity);
    } else {
      ethnicities.push(this.state.patinfo_ethnicity);
    }

    /*  let gender = "";
    if ($(`#${this.state.frmprefix}patinfo_gender`).is(":checked")) {
      gender = "male";
    } else {
      gender = "female";
    } */

    //order.patient = patientObj;

    //add patient

    // order.billingInfo = this.getBillingInfoJson();

    //ICD Codes
    this.state.otherICD10Codes = this.state.otherICD10Code.split(",");
    // icd10Code.otherCodes = this.state.otherICD10Codes
    let icd10Code = {
      codes: this.state.diagonisisCodes,
      otherCodes: this.state.otherICD10Codes,
    };

    let inputs = $(
      `#${this.state.frmprefix}hboc_icd_codes  #hboc_icd_codes`
    ).find(":input");
    /* inputs.map((i, elem) => {
      let $elem = $(elem);

      const _type = $elem.attr("type");

      if (_type == "checkbox") {
        if ($elem.is(":checked")) {
          icd10Code.codes.push($elem.data("val"));
        }
      }
    }); */

    order.tests[0].icd10Code = icd10Code;

    //covid_icd_codes

    if (this.state.orderData.tests.length > 1) {
      const icd10CodesCOVD = [];
      let icd10CodeCOVD = {
        codes: [],
        otherCodes: "",
      };

      inputs = $(
        `#${this.state.frmprefix}hboc_icd_codes #covid_icd_codes`
      ).find(":input");
      inputs.map((i, input) => {
        const $input = $(input);

        const _type = $input.attr("type");

        if (_type == "checkbox") {
          if ($input.is(":checked")) {
            icd10CodesCOVD.push($input.data("val"));
          }
        }
      });

      icd10CodeCOVD = {
        codes: icd10CodesCOVD,
        otherCodes: "",
      };

      var covid_tests = {
        testName: "COVD",
        testCode: "COVD",
        icd10Code: icd10CodeCOVD,
      };

      order.tests.push(covid_tests);
    }
    //ICD Codes

    //Clinical Data

    let qnA = {
      questionCode: "",
      answer: "",
      hBOCSubQuestions: [],
    };

    let clinicalData = [];

    //hboc1_q01
    if ($("#pfHistory_noCancer").is(":checked")) {
      qnA = {
        questionCode: "hboc1_q01",
        answer: true,
      };
    } else {
      qnA = {
        questionCode: "hboc1_q01",
        answer: false,
      };
    }

    clinicalData.push(qnA);

    //hboc1_q02
    if ($("#pfHistory_yesCancer").is(":checked")) {
      qnA = {
        questionCode: "hboc1_q02",
        answer: true,
      };
    } else {
      qnA = {
        questionCode: "hboc1_q02",
        answer: false,
      };
    }

    clinicalData.push(qnA);

    //hboc1_q03
    /*   if ($("#pfHistory_previousCancer").is(":checked")) {
      qnA = {
        'questionCode': "hboc1_q03",
        'answer': true,
        'subQuestion': [
          {
            'questionCode': "hboc1_q03_1",
            'answer': $("#pfHistory_prevCanDefine").val(),
          }
        ]
      }
    } else {
      qnA = {
        'questionCode': "hboc1_q03",
        'answer': false,
        'subQuestion': [
          {
            'questionCode': "hboc1_q03_1",
            'answer': '',
          }
        ]
      }
    } */
    if (this.pfHistory_previousCancer.checked) {
      qnA = {
        questionCode: "hboc1_q03",
        answer: true,
        subQuestion: [
          {
            questionCode: "hboc1_q03_1",
            answer: this.state.pfHistory_prevCanDefine,
          },
        ],
      };
    } else {
      qnA = {
        questionCode: "hboc1_q03",
        answer: false,
        subQuestion: [
          {
            questionCode: "hboc1_q03_1",
            answer: "",
          },
        ],
      };
    }
    clinicalData.push(qnA);

    //hboc1_q04
    if (this.pfHistory_SomaticMutationProf.checked) {
      qnA = {
        questionCode: "hboc1_q04",
        answer: true,
        subQuestion: [
          {
            questionCode: "hboc1_q04_1",
            answer: this.state.pfHistory_SoMutaProfDefine,
          },
        ],
      };
    } else {
      qnA = {
        questionCode: "hboc1_q04",
        answer: false,
        subQuestion: [
          {
            questionCode: "hboc1_q04_1",
            answer: "",
          },
        ],
      };
    }
    /* if ($("#pfHistory_SomaticMutationProf").is(":checked")) {
      qnA = {
        'questionCode': "hboc1_q04",
        'answer': true,
        'subQuestion': [
          {
            'questionCode': "hboc1_q04_1",
            'answer': $("#pfHistory_SoMutaProfDefine").val(),
          }
        ]
      }
    } else {
      qnA = {
        'questionCode': "hboc1_q04",
        'answer': false,
        'subQuestion': [
          {
            'questionCode': "hboc1_q04_1",
            'answer': '',
          }
        ]
      }
    } */
    clinicalData.push(qnA);

    //hboc1_q05
    const hboc1_q05_2 = [];
    if ($("#pfHistory_Familial_Inherited").is(":checked")) {
      hboc1_q05_2.push($("#pfHistory_Familial_Inherited").data("val"));
    }
    if ($("#pfHistory_Newly_identified").is(":checked")) {
      hboc1_q05_2.push($("#pfHistory_Newly_identified").data("val"));
    }

    /*  if ($("#pfHistory_PrevGermltesting").is(":checked")) {
      qnA = {
        'questionCode': "hboc1_q05",
        'answer': true,
        'subQuestion': [
          {
            'questionCode': "hboc1_q05_1",
            'answer': $("#pfHistory_PrevGermltestDefine").val(),
          },
          {
            "questionCode": "hboc1_q05_2",
            "answer": hboc1_q05_2.join(),
          }
        ]
      }
    } else {
      qnA = {
        'questionCode': "hboc1_q05",
        'answer': false,
        'subQuestion': [
          {
            'questionCode': "hboc1_q05_1",
            'answer': '',
          }
          ,
          {
            "questionCode": "hboc1_q05_2",
            "answer": hboc1_q05_2.join(),
          }
        ]
      }
    } */
    if (this.pfHistory_PrevGermltesting.checked) {
      qnA = {
        questionCode: "hboc1_q05",
        answer: true,
        subQuestion: [
          {
            questionCode: "hboc1_q05_1",
            answer: this.state.pfHistory_PrevGermltesting,
          },
          {
            questionCode: "hboc1_q05_2",
            answer: hboc1_q05_2.join(),
          },
        ],
      };
    } else {
      qnA = {
        questionCode: "hboc1_q05",
        answer: false,
        subQuestion: [
          {
            questionCode: "hboc1_q05_1",
            answer: "",
          },
          {
            questionCode: "hboc1_q05_2",
            answer: hboc1_q05_2.join(),
          },
        ],
      };
    }
    clinicalData.push(qnA);

    //hboc1_q06 Does the Patient have a history of
    //hboc1_q06_1
    console.log(
      "pfHistory_BrOvPanAnyAgeCan2",
      this.state.pfHistory_BrOvPanAnyAgeCan2
    );
    const hboc1_q06_1 = [];
    if (this.pfHistory_BrOvPanAnyAgeCan1.checked) {
      hboc1_q06_1.push(this.state.pfHistory_BrOvPanAnyAgeCan1);
    }
    if (this.pfHistory_BrOvPanAnyAgeCan2.checked) {
      hboc1_q06_1.push(this.state.pfHistory_BrOvPanAnyAgeCan2);
    }
    if (this.pfHistory_BrOvPanAnyAgeCan3.checked) {
      hboc1_q06_1.push(this.state.pfHistory_BrOvPanAnyAgeCan3);
    }

    if (
      this.state
        .pfHistory_BrOvPanAnyAge /* $("#pfHistory_BrOvPanAnyAge").is(":checked") */
    ) {
      qnA = {
        questionCode: "hboc1_q06",
        answer: true,
        subQuestion: [
          {
            questionCode: "hboc1_q06_1",
            answer: hboc1_q06_1.join(),
          },
          {
            questionCode: "hboc1_q06_2",
            answer: this.state.pfHistory_BrOvPanAnyAgeInfo,
          },
        ],
      };
    } else {
      qnA = {
        questionCode: "hboc1_q06",
        answer: false,
        subQuestion: [
          {
            questionCode: "hboc1_q06_1",
            answer: "",
          },
          {
            questionCode: "hboc1_q06_2",
            options: "",
          },
        ],
      };
    }
    clinicalData.push(qnA);

    //hboc1_q07  Colorectal or uterine cancer at 64 or younger?
    //hboc1_q07_1
    const hboc1_q07_1 = [];
    if (this.pfHistory_ColorUterineAnyAgeCan1.checked) {
      hboc1_q07_1.push(this.state.pfHistory_ColorUterineAnyAgeCan1);
    }
    if (this.pfHistory_ColorUterineAnyAgeCan2.checked) {
      hboc1_q07_1.push(this.state.pfHistory_ColorUterineAnyAgeCan2);
    }

    if (this.state.pfHistory_ColorUterineAnyAge) {
      qnA = {
        questionCode: "hboc1_q07",
        answer: true,
        subQuestion: [
          {
            questionCode: "hboc1_q07_1",
            answer: hboc1_q07_1.join(),
          },
          {
            questionCode: "hboc1_q07_2",
            answer: this.state.pfHistory_ColorUterineAnyAgeInfo,
          },
        ],
      };
    } else {
      qnA = {
        questionCode: "hboc1_q07",
        answer: false,
        subQuestion: [
          {
            questionCode: "hboc1_q07_1",
            answer: "",
          },
          {
            questionCode: "hboc1_q07_2",
            answer: "",
          },
        ],
      };
    }
    clinicalData.push(qnA);
    // ---------------------------------------------------------
    //hboc1_q08  Breast, ovarian or pancreative cancer at any age?
    //hboc1_q08_1
    const { pfHistory_FBrOvPanAnyAge, hboc1_q08_1_pfHistory_AnyAgeValues } =
      this.state;
    qnA = this.getFamilyHistorySubQuestions(
      pfHistory_FBrOvPanAnyAge,
      hboc1_q08_1_pfHistory_AnyAgeValues,
      "hboc1_q08"
    );
    clinicalData.push(qnA);
    console.log("sm hboc1_q08 qnA: ", qnA);

    //hboc1_q09  Two breast cancers (bilateral) in one relative at any age
    //hboc1_q09_1
    const { pfHistory_FTwoBreasCanAge, hboc1_q09_1_pfHistory_AnyAgeValues } =
      this.state;
    qnA = this.getFamilyHistorySubQuestions(
      pfHistory_FTwoBreasCanAge,
      hboc1_q09_1_pfHistory_AnyAgeValues,
      "hboc1_q09"
    );
    clinicalData.push(qnA);

    //hboc1_q010  Three breast cancers in relatives on the same  side of the family at any age
    //hboc1_q010_1

    const { pfHistory_FThrBreasCanAge, hboc1_q010_1_pfHistory_AnyAgeValues } =
      this.state;
    qnA = this.getFamilyHistorySubQuestions(
      pfHistory_FThrBreasCanAge,
      hboc1_q010_1_pfHistory_AnyAgeValues,
      "hboc1_q010"
    );
    clinicalData.push(qnA);

    //hboc1_q011 Ovarian cancer at any age
    //hboc1_q011_1
    const { pfHistory_OvarianCanAge, hboc1_q011_1_pfHistory_AnyAgeValues } =
      this.state;
    qnA = this.getFamilyHistorySubQuestions(
      pfHistory_OvarianCanAge,
      hboc1_q011_1_pfHistory_AnyAgeValues,
      "hboc1_q011"
    );
    clinicalData.push(qnA);

    //hboc1_q012 Pancreatic cancer at any age
    //hboc1_q012_1
    const { pfHistory_PancreaticCanAge, hboc1_q012_1_pfHistory_AnyAgeValues } =
      this.state;
    qnA = this.getFamilyHistorySubQuestions(
      pfHistory_PancreaticCanAge,
      hboc1_q012_1_pfHistory_AnyAgeValues,
      "hboc1_q012"
    );
    clinicalData.push(qnA);

    //hboc1_q013  Male breast cancer at any age
    //hboc1_q013_1
    const { pfHistory_MaleBreastCanAge, hboc1_q013_1_pfHistory_AnyAgeValues } =
      this.state;
    qnA = this.getFamilyHistorySubQuestions(
      pfHistory_MaleBreastCanAge,
      hboc1_q013_1_pfHistory_AnyAgeValues,
      "hboc1_q013"
    );
    clinicalData.push(qnA);

    //hboc1_q014 Metastatic prostate cancer at any age?
    //hboc1_q014_1
    const {
      pfHistory_MetaProstateCanAge,
      hboc1_q014_1_pfHistory_AnyAgeValues,
    } = this.state;
    qnA = this.getFamilyHistorySubQuestions(
      pfHistory_MetaProstateCanAge,
      hboc1_q014_1_pfHistory_AnyAgeValues,
      "hboc1_q014"
    );
    clinicalData.push(qnA);

    //hboc1_q015 Colon cancer at 49 or younger
    //hboc1_q015_1
    const { pfHistory_ColorCanAge, hboc1_q015_1_pfHistory_AnyAgeValues } =
      this.state;
    qnA = this.getFamilyHistorySubQuestions(
      pfHistory_ColorCanAge,
      hboc1_q015_1_pfHistory_AnyAgeValues,
      "hboc1_q015"
    );
    clinicalData.push(qnA);

    //hboc1_q016 Uterine cancer at 49 or younger?
    //hboc1_q016_1
    const { pfHistory_UterineCanAge, hboc1_q016_1_pfHistory_AnyAgeValues } =
      this.state;
    qnA = this.getFamilyHistorySubQuestions(
      pfHistory_UterineCanAge,
      hboc1_q016_1_pfHistory_AnyAgeValues,
      "hboc1_q016"
    );
    clinicalData.push(qnA);

    //hboc1_q017 Ashkenazi Jewish ancestry with breast cancer at any age?
    //hboc1_q017_1
    const {
      pfHistory_AshJewishAncesCanAge,
      hboc1_q017_1_pfHistory_AnyAgeValues,
    } = this.state;
    qnA = this.getFamilyHistorySubQuestions(
      pfHistory_AshJewishAncesCanAge,
      hboc1_q017_1_pfHistory_AnyAgeValues,
      "hboc1_q017"
    );
    clinicalData.push(qnA);

    //hboc1_q018 Does the physician have a family history of other cancers?\
    const { pfHistory_otherCan, pfHistory_otherCan_info } = this.state;
    console.log("sm pfHistory_otherCan: ", pfHistory_otherCan);
    // return
    if (pfHistory_otherCan) {
      qnA = {
        questionCode: "hboc1_q018",
        answer: true,
        subQuestion: [
          {
            questionCode: "hboc1_q018_1",
            answer: pfHistory_otherCan_info,
          },
        ],
      };
    } else {
      qnA = {
        questionCode: "hboc1_q018",
        answer: false,
        subQuestion: [
          {
            questionCode: "hboc1_q018_1",
            answer: "",
          },
        ],
      };
    }
    clinicalData.push(qnA);

    //hboc1_q019 Has anyone in the physician's family had genetic testing for hereditary cancer?
    const { pfHistory_geHerediraryCan, pfHistory_geHerediraryCan_info } =
      this.state;
    if (pfHistory_geHerediraryCan) {
      qnA = {
        questionCode: "hboc1_q019",
        answer: true,
        subQuestion: [
          {
            questionCode: "hboc1_q019_1",
            answer: pfHistory_geHerediraryCan_info,
          },
        ],
      };
    } else {
      qnA = {
        questionCode: "hboc1_q019",
        answer: false,
        subQuestion: [
          {
            questionCode: "hboc1_q019_1",
            answer: "",
          },
        ],
      };
    }
    clinicalData.push(qnA);

    //hboc1_q020 Other Clinical History
    qnA = {
      questionCode: "hboc1_q020",
      answer:
        this.state
          .pfHistory_OtherClinicalHistory /* $("#pfHistory_OtherClinicalHistory").val() */,
    };
    console.log("sm hboc1_q020 qnA: ", qnA);
    clinicalData.push(qnA);

    //hboc1_q021 Allogeneic bone marrow or peripheral stem cell transplant
    if (this.state.pfHistory_PCurTreat_boneMar) {
      qnA = {
        questionCode: "hboc1_q021",
        answer: true,
      };
    } else {
      qnA = {
        questionCode: "hboc1_q021",
        answer: false,
      };
    }

    clinicalData.push(qnA);

    //hboc1_q022 Chemotherapy
    if (this.state.pfHistory_PCurTreat_Chemo) {
      qnA = {
        questionCode: "hboc1_q022",
        answer: true,
      };
    } else {
      qnA = {
        questionCode: "hboc1_q022",
        answer: false,
      };
    }

    clinicalData.push(qnA);

    //hboc1_q023 Radiation
    if (this.state.pfHistory_PCurTreat_Radia) {
      qnA = {
        questionCode: "hboc1_q023",
        answer: true,
      };
    } else {
      qnA = {
        questionCode: "hboc1_q023",
        answer: false,
      };
    }

    clinicalData.push(qnA);

    //hboc1_q024 Surgery
    if (this.state.pfHistory_PCurTreat_Surgery) {
      qnA = {
        questionCode: "hboc1_q024",
        answer: true,
      };
    } else {
      qnA = {
        questionCode: "hboc1_q024",
        answer: false,
      };
    }

    clinicalData.push(qnA);

    //hboc1_q025 previous or current treatment
    qnA = {
      questionCode: "hboc1_q025",
      answer: this.state.pfHistory_PCurTreat_SurgDe,
    };
    clinicalData.push(qnA);

    if (this.state.user.isAdmin) order.tests[0].clinicalData = clinicalData;
    else
      order.tests[0].clinicalData = this.state.orderData.tests[0].clinicalData;

    //order.tests[0].clinicalData = this.state.orderData.tests[0].clinicalData;
    //Clinical Data

    //Kit Shipping Info
    const {
      kitShip_PatMobPhlebotomy,
      kitShip_PatblooddrawQuest,
      kitShip_PatOthers,
      kitShip_PatOthersInfo,
    } = this.state;

    const kitInformation = {
      drawType: [
        kitShip_PatMobPhlebotomy,
        kitShip_PatblooddrawQuest,
        kitShip_PatOthers,
      ].filter((item) => item !== null),
      otherInfo: kitShip_PatOthersInfo,
    };

    order.tests[0].kitInformation = kitInformation;
    //Kit Shipping Info

    //Notes & Certify
    order.tests[0].notes = this.state.kitShip_notes;

    const $kitShip_healthcareProvider = $("#kitShip_healthcareProvider");
    const $kitShip_employeehealthcareProvider = $(
      "#kitShip_employeehealthcareProvider"
    );
    const $kitShip_partnerhealthcareProvider = $(
      "#kitShip_partnerhealthcareProvider"
    );
    if ($kitShip_healthcareProvider.is(":checked")) {
      order.certifyTypes.push($kitShip_healthcareProvider.data("val"));
    }
    if ($kitShip_employeehealthcareProvider.is(":checked")) {
      order.certifyTypes.push($kitShip_employeehealthcareProvider.data("val"));
    }
    if ($kitShip_partnerhealthcareProvider.is(":checked")) {
      order.certifyTypes.push($kitShip_partnerhealthcareProvider.data("val"));
    }

    // get the payor id that is selected for Primary insurance carrier
    order.payorId = this.state.selectedPayor.value;

    // get the user response related to user's consent about the use of his/her data for research purpose
    /* const researchConsent = $(`researchConsent`).is(":checked")
      ? "Yes"
      : "No";
    const researchUseOnly = $(`researchUseOnly`).is(":checked")
      ? "Yes"
      : "No";

    order.researchConsent = researchConsent;
    order.researchUseOnly = researchUseOnly; */

    //Notes & Certify
    formData.append("order", JSON.stringify(order));

    formData.append("host", window.location.host);

    console.log("sm formData: ", formData);
    console.log("sm order: ", order);

    const accessToken = await helper.getAccessToken(this.context)

    if (me._id) {
      // formData.append("itemID", me._id);
      // formData.append("orderId", me.orderId);
      axios
        .put(`${API_BASE_URL}/orders`, formData, {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        })
        .then(({ data }) => {
          console.log("data: ", data);

          this.setState(
            {
              toastMessage: `HBOC order updated Successfully`,
            },
            () => {
              // this.showToast("success");
              message.success(me.state.toastMessage);

              setTimeout(() => {
                const params = new URLSearchParams(window.location.search);
                let returnurl = params.get("returnurl");

                console.log("returnurl: ", returnurl);
                if (process.env.REACT_APP_ENV !== "DEV") {
                  window.location.href = returnurl;
                }
              }, 1000);
            }
          );
        })
        .catch((error) => {
          message.error(error.message);
        });
    } else {
      axios
        .post(`${API_BASE_URL}/orders`, formData, {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        })
        .then(({ data }) => {
          console.log("data: ", data);

          this.setState(
            {
              toastMessage: `Successfully added order`,
            },
            () => {
              // this.showToast("success");
              message.success(this.state.toastMessage);
            }
          );
        });
    }
  }
  async handleSaveBillingInfo() {
    // const user = JSON.parse(localStorage.getItem("user"));

    // const urlParams = new URLSearchParams(window.location.search);
    // const _id = urlParams.get("id");
    // console.log("order id: ", _id);

    // let billingInfo = this.getBillingInfoJson();

    // let _payload = billingInfo;
    // let _url = `${API_BASE_URL}/orders/savebillinginfo`;
    // let _options = {
    //   url: _url,
    //   method: "POST",
    //   headers: {
    //     Authorization: `Bearer ${user.token}`,
    //   },
    //   data: {
    //     order_id: _id,
    //     billingInfo: _payload,
    //   },
    // };
    // const response = await axios(_options);
    // console.log("CmtForm->response.data: ", response.data);

    // this.setState(
    //   {
    //     toastMessage: `Successfully updated Billing Information`,
    //   },
    //   async () => {
    //     this.showToast("success");

    //     this.handleSave();

    //     if (process.env.REACT_APP_ENV != 'DEV') {
    //       setTimeout(() => {
    //         window.location.reload();
    //       }, 1000);
    //     }
    //   }
    // );
    this.handleSave();
  }

  takeNotes(notesText) {
    this.setState({
      notesText: notesText,
    });
  }

  getDefaultPayorIdOptions = () => {
    return this.state.allPayors.slice(0, 20);
  };

  getPayorIdOptionsBySearchText = async (searchText) => {
    let payorIdOptions = this.state.allPayors.filter((payor) =>
      payor.label.toLowerCase().includes(searchText.toLowerCase())
    );

    // if the payorIdOptions array contains many elements then the Select element becomes laggy
    // so I'm cutting down the array size if there are too many elements - Khairul
    const numberOfOptions = payorIdOptions.length;

    console.log("\n===");
    console.log("original options count: ", numberOfOptions);

    if (numberOfOptions > 100) {
      payorIdOptions = payorIdOptions.slice(0, 99);
    }

    console.log("Options count after trimming: ", payorIdOptions.length);
    console.log("===\n");

    return payorIdOptions;
  };

  updatePrimaryInsuranceCarrierAndSelectedPayor = (inputPayor) => {
    this.billinfo_PrimaryInsuranceCarrier.value = inputPayor.value;
    this.setState({ selectedPayor: inputPayor });
  };

  renderPayorListDropdownForPrimaryInsuranceCarrierSelection = () => {
    return (
      <div
        class="col-sm-10 col-xs-12 mb-3 hidediv"
        id="payorListSearchAndSelect"
      >
        <div class="form-group drop-custum">
          <label class="color-green" htmlFor="role">
            Search Primary Carrier:
          </label>
          <small className="color-red">*</small>
          <AsyncSelect
            value={this.state.selectedPayor}
            defaultValue={""}
            label="Search Primary Carrier:"
            cacheOptions
            defaultOptions={this.getDefaultPayorIdOptions()}
            loadOptions={this.getPayorIdOptionsBySearchText}
            styles={helper.colourStyles}
            onChange={this.updatePrimaryInsuranceCarrierAndSelectedPayor}
          />
        </div>
      </div>
    );
  };
  DiagnosisChecked = (val) => {
    var tempDiagnosis = this.state.diagonisisCodes;
    console.log("clicked the button");
    //console.log(val);
    console.log("vluew------------------------");

    if (tempDiagnosis.includes(val)) {
      console.log("unchecked---------------------------------");
      const index = tempDiagnosis.indexOf(val);

      console.log(index);

      if (index > -1) {
        tempDiagnosis.splice(index, 1);
      }
      this.setState({
        diagonisisCodes: tempDiagnosis,
      });
      console.log(this.state.diagonisisCodes);
    } else {
      console.log(this.state.diagonisisCodes);
      console.log("checked---------------------------------");
      tempDiagnosis.push(val);
      this.setState({
        diagonisisCodes: tempDiagnosis,
      });
      console.log(this.state.diagonisisCodes);
    }
    console.log("clicked the button");
  };
  render() {
    if (this.state.loading) {
      return null;
    }

    return (
      <div>
        <div class="block-header">
          <h2>
            Edit Order - Id: {this.state.orderData.orderId} (
            {this.state.orderData.status})
          </h2>
          {/* <ul class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/orders">Orders</a>
            </li>
            <li class="breadcrumb-item active">
              {this.state.orderData._id ? "Edit Order" : "New Order"}
            </li>
          </ul> */}
        </div>
        <div
          class="panel-group card"
          id="accordion_105"
          role="tablist"
          aria-multiselectable="true"
        >
          <Toast
            toastList={me.state.list}
            position={"top-center"}
            autoDelete={true}
            dismissTime={3000}
            description={me.state.toastMessage}
          />
          {console.log("Order: ", this.state.orderData)}

          {/* Top Actions */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">Actions</h4>
            </div>
          </div>
          <TopActions
            orderId={this.orderId}
            reportId={reportId}
            galenReportId={me.state.orderData.galenReportId}
            context={this.context}
            orderData={this.state.orderData}
            reportDate={this.state.reportDate}
          />
          {/* baylor */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">Baylor</h4>
            </div>
          </div>
          <div class="body card">
            <div class="row clearfix">
              <div class="col-md-12">
                <div class="form-group required">
                  <label class="form-label" for="baylor">
                    {me.state.orderData.galenReportStatus &&
                      me.state.orderData.galenReportStatus.length > 0 && (
                        <span>
                          Galen Report Status:
                          {me.state.orderData.galenReportStatus}
                        </span>
                      )}
                    {me.state.orderData.baylorReportStatus &&
                      me.state.orderData.baylorReportStatus.length > 0 && (
                        <span>
                          Baylor Report Status:
                          {me.state.orderData.baylorReportStatus}
                        </span>
                      )}
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* ASPIRA GENETIX Hereditary Breast and Ovarian Cancer */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">
                ASPIRA GENETIX Hereditary Breast and Ovarian Cancer
              </h4>
            </div>
          </div>
          <div
            id={`${this.state.frmprefix}ASPGENHereditaryBreastOvarianCancerForm`}
            class="body card"
          >
            <div class="row clearfix">
              <div class="col-md-12">
                <div class="form-group required">
                  <label class="form-label" for="address">
                    ASPIRA GENETIX Hereditary Breast and Ovarian Cancer (select
                    panel)
                  </label>
                  <div class="demo-radio-button">
                    <div id="printHere_CS" class="row clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ASPIRA GENETIX Hereditary Breast and Ovarian Cancer */}

          {/* GTCS Physician Information */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">Physician Information</h4>
            </div>
          </div>

          <PhysicianInformation
            state={this.state}
            me={this}
            phyinfo_state={this.state.phyinfo_state}
          />

          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">Patient Information</h4>
            </div>
          </div>
          <PatientInformation state={this.state} me={this} />
          {/* GTCS Patient Information end */}

          {/* GTCS Billing Information */}
          {/* Only show billing information when the order is billable i.e. not flagged as RUO - Research Use Only */}
          {this.state.isBillableOrder && (
            <>
              <div class="row clearfix">
                <div class="col-md-12">
                  <h4 class="panel-titlepdf">Billing Information</h4>
                </div>
              </div>
              <div
                id={`${this.state.frmprefix}BillingInformation`}
                class="body card"
              >
                <Billing_Information
                  state={this.state}
                  me={this}
                  from="hboc1"
                />
                <CmtForm
                  order={me.state.orderData}
                  handleSaveFunc={this.handleSave.bind(this)}
                  takeNotesFunc={this.takeNotes.bind(this)}
                />

                <BillingNotes
                  order={me.state.orderData}
                  handleSaveFunc={this.handleSave.bind(this)}
                  takeNotes={this.takeNotes.bind(this)}
                />

                <div class="row clearfix">
                  <div class="col-md-12">
                    <div class="form-group">
                      <button
                        id={`btnSaveBillingInfo`}
                        placeholder="Update billing info"
                        name="Submit"
                        className="btn btn-primary text-white"
                        onClick={this.handleSave.bind(this)}
                      >
                        Update billing info
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {/* GTCS Billing Information end */}
          {/* GTCS Diagnosis Codes | ICD-10 Codes */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">Diagnosis Codes | ICD-10 Codes</h4>
            </div>
          </div>
          {<DiagnosisCodes me={this} state={this.state} />}
          {/* GTCS Diagnosis Codes | ICD-10 Codes end */}

          {/* Personal/Family History -- Genetix HBOC */}
          <div
            class={this.state.user.isAdmin ? "showdiv" : "hidediv"}
            id="_FamilyHistoryArea"
          >
            <div class="row clearfix">
              <div class="col-md-12">
                <h4 class="panel-titlepdf">
                  Personal/Family History -- Genetix HBOC
                </h4>
              </div>
            </div>

            {
              <Family_histry
                me={this}
                state={this.state}
                from={"genetixhboc"}
              />
            }
          </div>
          {/* Personal/Family History -- Genetix HBOC */}

          {/* OVA1 Kit Shipping Information */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">Kit Shipping Information</h4>
            </div>
          </div>
          <Kit_Shipping_Information
            state={this.state}
            me={this}
            for="AddEditOrderHBOC1"
          />
          {/* <div
            id={`${this.state.frmprefix}kitShippingInfoFormContainer`}
            class="body card"
          >
            <div class="row clearfix">
              <div class="col-md-12">
                <div class="form-group required">
                  <label class="form-label" for="address">
                    Kit Shipping Info
                  </label>
                  <div id="draw_type" class="demo-checkbox">
                    <input
                      data-val="My physician needs mobile phlebotomy"
                      type="checkbox"
                      id={`kitShip_PatMobPhlebotomy`}
                      name={`kitShip_PatMobPhlebotomy`}
                      class="filled-in"
                      checked={this.state.kitShip_PatMobPhlebotomy}
                      onChange={(e) => this.setState({kitShip_PatMobPhlebotomy: e.target.checked ? 'My physician needs mobile phlebotomy' : null})}
                    />
                    <label for={`kitShip_PatMobPhlebotomy`}>
                      My physician needs mobile phlebotomy &nbsp;{" "}
                    </label>
                    <input
                      data-val="Patient will get blood draw at Quest PSC"
                      type="checkbox"
                      id={`kitShip_PatblooddrawQuest`}
                      name={`kitShip_PatblooddrawQuest`}
                      class="filled-in"
                      checked={this.state.kitShip_PatblooddrawQuest}
                      onChange={(e) => this.setState({kitShip_PatblooddrawQuest: e.target.checked ? 'Patient will get blood draw at Quest PSC' : null})}
                    />
                    <label for={`kitShip_PatblooddrawQuest`}>
                      Patient will get blood draw at Quest PSC &nbsp;{" "}
                    </label>
                    <input
                      data-val="Other"
                      type="checkbox"
                      id={`kitShip_PatOthers`}
                      name="kitShip_PatOthers"
                      class="filled-in"
                      checked={this.state.kitShip_PatOthers}
                      onChange={(e) => this.setState({kitShip_PatOthers: e.target.checked ? 'Other' : null})}
                    />
                    <label for={`kitShip_PatOthers`}>Other &nbsp; </label>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="form-label" for="address">
                    &nbsp;
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      ref={(ref) => (me.kitShip_PatOthersInfo = ref)}
                      id={`kitShip_PatOthersInfo`}
                      class="form-control "
                      placeholder=" Other info"
                      value={this.state.kitShip_PatOthersInfo}
                      onChange={({ target: { value } }) => this.setState({ kitShip_PatOthersInfo: value })}
                      disabled={!this.state.kitShip_PatOthers}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* OVA1 Kit Shipping Information end*/}
          {/* OVA1 Kit Notes & Certify */}
          <Notes_and_Certify
            me={this}
            state={this.state}
            setState={this.setState}
            token={hbocaccessToken}
            reportId={reportId}
            orderId={this.orderId}
            reportDate={this.state.reportDate}
            handleSave={this.handleSave.bind(this)}
            from={'hboc'}
            prefix={this.state.frmprefix}
          />
          {/* OVA1 Kit Notes & Certify  end*/}

          {/* <!-- Default Size --> */}
          <div class="modal fade" id="defaultModal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h4 class="modal-title" id="defaultModalLabel">
                    Does the Patient have a family history of
                  </h4>
                </div>
                <div class="modal-body">
                  <div class="row clearfix">
                    <div class="col-md-6">
                      <div class="demo-radio-button modalcss">
                        <input
                          type="radio"
                          id="pfHistory_AnyAge_Mothside"
                          name="pfHistory_AnyAge_relative"
                          value="yes"
                          class="form-control"
                          checked={this.state.pfHistory_AnyAge_Mothside}
                          onClick={(e) => {
                            if (e.target.checked) {
                              this.setState({
                                pfHistory_AnyAge_Mothside: true,
                              });
                            }
                          }}
                        />
                        <label
                          class="form-label"
                          for="pfHistory_AnyAge_Mothside"
                        >
                          Mother's side
                        </label>
                        <input
                          type="radio"
                          id="pfHistory_AnyAge_Fathside"
                          name="pfHistory_AnyAge_relative"
                          value="no"
                          class="form-control"
                          checked={this.state.pfHistory_AnyAge_Fathside}
                          onClick={(e) => {
                            if (e.target.checked) {
                              this.setState({
                                pfHistory_AnyAge_Fathside: true,
                              });
                            }
                          }}
                        />
                        <label
                          class="form-label"
                          for="pfHistory_AnyAge_Fathside"
                        >
                          Father's side
                        </label>
                      </div>
                    </div>

                    <div class="col-md-3">
                      <div class="form-line">
                        <select
                          class="form-control show-tick"
                          id="pfHistory_AnyAge_relative"
                          onChange={(e) =>
                            this.setState({
                              pfHistory_AnyAge_relative: e.target.value,
                            })
                          }
                        >
                          <option
                            value=""
                            selected={
                              this.state.pfHistory_AnyAge_relative === ""
                            }
                          >
                            -- Relative --
                          </option>
                          <option
                            value="not provided"
                            selected={
                              this.state.pfHistory_AnyAge_relative ===
                              "not provided"
                            }
                          >
                            not provided
                          </option>
                          <option
                            value="parent"
                            selected={
                              this.state.pfHistory_AnyAge_relative === "parent"
                            }
                          >
                            parent
                          </option>
                          <option
                            value="sibling"
                            selected={
                              this.state.pfHistory_AnyAge_relative === "sibling"
                            }
                          >
                            sibling
                          </option>
                          <option
                            value="child"
                            selected={
                              this.state.pfHistory_AnyAge_relative === "child"
                            }
                          >
                            child
                          </option>
                          <option
                            value="uncle"
                            selected={
                              this.state.pfHistory_AnyAge_relative === "uncle"
                            }
                          >
                            uncle
                          </option>
                          <option
                            value="aunt"
                            selected={
                              this.state.pfHistory_AnyAge_relative === "aunt"
                            }
                          >
                            aunt
                          </option>
                          <option
                            value="great uncle"
                            selected={
                              this.state.pfHistory_AnyAge_relative ===
                              "great uncle"
                            }
                          >
                            great uncle
                          </option>
                          <option
                            value="great aunt"
                            selected={
                              this.state.pfHistory_AnyAge_relative ===
                              "great aunt"
                            }
                          >
                            great aunt
                          </option>
                          <option
                            value="newphew"
                            selected={
                              this.state.pfHistory_AnyAge_relative === "newphew"
                            }
                          >
                            newphew
                          </option>
                          <option
                            value="niece"
                            selected={
                              this.state.pfHistory_AnyAge_relative === "niece"
                            }
                          >
                            niece
                          </option>
                          <option
                            value="grandparent"
                            selected={
                              this.state.pfHistory_AnyAge_relative ===
                              "grandparent"
                            }
                          >
                            grandparent
                          </option>
                          <option
                            value="grandchild"
                            selected={
                              this.state.pfHistory_AnyAge_relative ===
                              "grandchild"
                            }
                          >
                            grandchild
                          </option>
                          <option
                            value="half-sibling"
                            selected={
                              this.state.pfHistory_AnyAge_relative ===
                              "half-sibling"
                            }
                          >
                            half-sibling
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="form-line">
                        <input
                          type="text"
                          id="pfHistory_AnyAge_age"
                          class="form-control "
                          placeholder="Age"
                          value={this.state.pfHistory_AnyAge_age}
                          onChange={(e) =>
                            this.setState({
                              pfHistory_AnyAge_age: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn waves-effect btn-primary text-white"
                    onClick={() => {
                      this.btnpfHistoryAddFamilyHistory();

                      window.$("#defaultModal").modal("hide");
                    }}
                  >
                    Add
                  </button>
                  &nbsp; &nbsp;
                  <button
                    type="button"
                    class="btn waves-effect btn-danger text-white mclose"
                    data-dismiss="modal"
                    onClick={() => {
                      this.setState({
                        pfHistory_AnyAge_age: "",
                        pfHistory_AnyAge_relative: "",
                      });

                      window.$("#defaultModal").modal("hide");
                    }}
                  >
                    CLOSE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
