import React, { PureComponent, useEffect, useState } from "react";

import PropTypes from 'prop-types';
import $ from 'jquery';
import moment from 'moment';
import axios from 'axios';

//includes all 3 forms

import { connect } from "react-redux";
import helper from "./utils/helper";
import SignatureCanvas from "react-signature-canvas";
import { ca } from "date-fns/locale";

// import jsPDF from "jspdf";
// import ReactDOMServer from "react-dom/server";
import { protectedResources } from "./authConfig";
import { MsalContext } from "@azure/msal-react";

import LoadingAnimation from "./portalForm/LoadingAnimation";

//import Signature from "./Signature";

import SignaturePad from 'react-signature-canvas'

import Pdf from "react-to-pdf";
import { message } from "antd";
import helperPortal from "./utils/helperPortal";
// import { PDFViewer } from "@react-pdf/renderer";
// import {
//   Document,
//   Image,
//   Note,
//   Page,
//   StyleSheet,
//   Text,
//   View
// } from "@react-pdf/renderer";
// import { PDFDownloadLink } from "@react-pdf/renderer";

const options = {
  orientation: 'landscape',
  unit: 'in',
  format: [4, 2]
};

const provider_url_base = helperPortal.getAPIBaseURI();
const url_base = helper.getAPIBaseURI();

//for billing logic
const showInsuranceTitle = () => {
  $(`#title_insurance_container`).removeClass(" hidediv");
  $(`#title_insurance_container`).addClass(" showdiv");
};

const hideInsuranceTitle = () => {
  $(`#title_insurance_container`).addClass(" hidediv");
  $(`#title_insurance_container`).removeClass(" showdiv");
};

const showPrimaryAreaClick = () => {
  $(`#primary_insurance_container`).removeClass(" hidediv");
  $(`#primary_insurance_container`).addClass(" showdiv");
};
const hidePrimaryAreaClick = () => {
  $(`#primary_insurance_container`).addClass(" hidediv");
  $(`#primary_insurance_container`).removeClass(" showdiv");
};

const showSecondaryAreaClick = () => {
  $(`#secondary_insurance_container`).removeClass(" hidediv");
  $(`#secondary_insurance_container`).addClass(" showdiv");
};
const hideSecondaryAreaClick = () => {
  $(`#secondary_insurance_container`).addClass(" hidediv");
  $(`#secondary_insurance_container`).removeClass(" showdiv");
};

const showTertiaryAreaClick = () => {
  $(`#tertiary_insurance_container`).removeClass(" hidediv");
  $(`#tertiary_insurance_container`).addClass(" showdiv");
};
const hideTertiaryAreaClick = () => {
  $(`#tertiary_insurance_container`).addClass(" hidediv");
  $(`#tertiary_insurance_container`).removeClass(" showdiv");
};

const showInsuranceInsured = () => {
  $(`#insured_info_container`).removeClass(" hidediv");
  $(`#insured_info_container`).addClass(" showdiv");
};

const hideInsuranceInsured = () => {
  $(`#insured_info_container`).addClass(" hidediv");
  $(`#insured_info_container`).removeClass(" showdiv");
};

//for billing logic

export default class TRF_ProviderOrderPortalHBOC extends PureComponent {
  static contextType = MsalContext
  // const[orderId, setOrderId] = useState("");
  // const[retData, setRetData] = useState({ });
  constructor(props) {
    super(props);

    this.state = {
      orderId: "",
      retData: {},
      // loading: true,
      ethnicityArray: {},
      trimmedDataURL: null,
      loadingAnimation_: false,
      isLoading: true,
      errorMessage: null,
    }

    this.sigPad = {};
    this.ref = React.createRef();
  }
  loadOrder = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const temps = document.location.search.split('=');
        console.log("temps: ", temps);

        let _orderId = '5f213c1133509e7592407e41';
        if (temps.length > 1) {
          _orderId = temps[1];
        }

        // setOrderId(_orderId)

        // const accessToken = await helper.getAccessToken(this.context);
        const accessToken = "";

        let opt = {
          url: `${url_base}/orders/${_orderId}`,
          method: "GET",
          data: {},
          headers: {
            Authorization: `Bearer ${accessToken}`
          }

        };
        console.log("loading order with url: ", opt);
        let ret = await axios(opt);
        console.log("ret.data: ", ret.data);


        let retdata = ret.data;

        retdata.order = ret.data.orders[0];
        if (retdata.order) {


          opt = {
            url: `${provider_url_base}/physicians/addressperclientid/${retdata.order.physicianId}/${retdata.order._id}`,
            method: "GET",
            data: {},
            headers: {
              Authorization: `Bearer ${accessToken}`
            }

          };
          console.log("loading order with url: ", opt);
          let physician = await axios(opt);
          console.log("physician.data: ", physician.data);

          opt = {
            url: `${url_base}/patients/${retdata.order.patientId}`,
            method: "GET",
            data: {},
            headers: {
              Authorization: `Bearer ${accessToken}`
            }

          };
          console.log("loading order with url: ", opt);
          let patient = await axios(opt);
          console.log("patient.data: ", patient.data);

          if (typeof patient.data.patient !== 'undefined') {
            retdata.patient = patient.data.patient;
          }
          else {
            retdata.patient = patient.data;
          }

          if (typeof physician.data.physician !== 'undefined') {
            retdata.physician = physician.data.physician;
          }
          else {
            retdata.physician = physician.data;
          }


          //ethnicity array
          const ethnicityArray1 = retdata.patient.ethnicity ? retdata.patient.ethnicity.split(",") : "";


          let finalImage = retdata.order.physicianSignature;

          this.setState({
            orderId: _orderId,
            retData: retdata,
            // loading: false,
            ethnicityArray: ethnicityArray1,
            trimmedDataURL: finalImage,
          }, () => {
            // this.setState({
            //   loading: true,
            // })

            //Billing information          
            if (this.state.retData.order.billingInfo.medicare) {
              showInsuranceTitle();
              showPrimaryAreaClick();
              hideSecondaryAreaClick();
              hideTertiaryAreaClick();
              showInsuranceInsured();
            }
            if (this.state.retData.order.billingInfo.patientSelfPay) {
              hidePrimaryAreaClick();
              hideSecondaryAreaClick();
              hideTertiaryAreaClick();
              hideInsuranceTitle();
              hideInsuranceInsured();
            }
            if (this.state.retData.order.billingInfo.medicaid) {
              showInsuranceTitle();
              showPrimaryAreaClick();
              hideSecondaryAreaClick();
              hideTertiaryAreaClick();
              showInsuranceInsured();
            }
            if (this.state.retData.order.billingInfo.orderingFacility) {
              hidePrimaryAreaClick();
              hideSecondaryAreaClick();
              hideTertiaryAreaClick();
              hideInsuranceTitle();
              hideInsuranceInsured();
            }
            if (this.state.retData.order.billingInfo.privateInsurance) {
              showInsuranceTitle();
              showPrimaryAreaClick();
              showSecondaryAreaClick();
              showTertiaryAreaClick();
              showInsuranceInsured();
            }

            resolve(true);
          });

          console.log("retdata: ", retdata);
        }
      } catch (error) {
        console.log("error on loading order: ", error);
        reject(error);
      }
    })
  }

  componentDidMount() {
    this.setState({ isLoading: true, errorMessage: null }, () => {
      try {
        // const theButton = document.getElementById(`ova1_btnViewTRF`);
        // theButton.textContent = "Loading Page";
        // theButton.disabled = true;
        // theButton.classList.remove("text-white");
        // theButton.classList.add("button--loading");

        this.loadOrder()
          .then(() => {

            this.setState({ isLoading: false, errorMessage: null }, () => {
              // theButton.textContent = "Download Pdf";
              // theButton.classList.add("text-white");
              // theButton.classList.remove("button--loading");
              // theButton.disabled = false;
            });
          })
          .catch((error) => {
            console.log("error on loading ova1: ", error);
            this.setState({ isLoading: false, errorMessage: error });
          });
        // this.btnGeneratePDF.click();
      }
      catch (error) {
        console.log("error on loading ova1: ", error);
        this.setState({ isLoading: false, errorMessage: error });
      }
    })
  }

  renderResearchConsentCheckbox() {
    if (this.state.retData.order.researchConsent && this.state.retData.order.researchConsent.length > 0) {
      if (this.state.retData.order.researchConsent == 'Yes') {
        return <>
          <input type="checkbox" checked id={`${this.props.prefix}researchConsent`} className="filled-in" />
          <label htmlFor={`${this.props.prefix}researchConsent`}>Consent to research</label>
        </>
      }
      else {
        return <>
          <input type="checkbox" id={`${this.props.prefix}researchConsent`} className="filled-in" />
          <label htmlFor={`${this.props.prefix}researchConsent`}>Consent to research</label>
        </>
      }
    }
    else {
      return <>
        <input type="checkbox" id={`${this.props.prefix}researchConsent`} className="filled-in" />
        <label htmlFor={`${this.props.prefix}researchConsent`}>Consent to research</label>
      </>
    }
  }
  renderResearchUseOnlyCheckbox() {
    if (this.state.retData.order.researchUseOnly && this.state.retData.order.researchUseOnly.length > 0) {
      if (this.state.retData.order.researchUseOnly == 'Yes') {
        return <>
          <input type="checkbox" checked id={`${this.props.prefix}researchUseOnly`} className="filled-in" />
          <label htmlFor={`${this.props.prefix}researchUseOnly`}>Research use only</label>
        </>
      }
      else {
        return <>
          <input type="checkbox" id={`${this.props.prefix}researchUseOnly`} className="filled-in" />
          <label htmlFor={`${this.props.prefix}researchUseOnly`}>Research use only</label>
        </>
      }
    }
    else {
      return <>
        <input type="checkbox" id={`${this.props.prefix}researchUseOnly`} className="filled-in" />
        <label htmlFor={`${this.props.prefix}researchUseOnly`}>Research use only</label>
      </>
    }
  }
  render() {

    if (!this.state.isLoading && this.state.errorMessage) {
      return message.error(this.state.errorMessage.message);
    }
    else if (this.state.isLoading) {
      return null;
    }
    else {
      return <div ref={this.ref}>
        <div className="col-sm-12 text-center">
          <button id={`genetixHBOC_btnViewTRF`} className={"btn btn-primary text-white"} onClick={async () => {
            const theButton = document.getElementById(`genetixHBOC_btnViewTRF`);

            theButton.disabled = true;
            theButton.classList.remove("text-white");
            theButton.classList.add("button--loading");

            // const accessToken = await helper.getAccessToken(this.context);
            const accessToken = "";
            await helper.printPDF(this.state.retData.order._id, this.state.retData.order.orderId, accessToken);

            theButton.textContent = "Download Pdf";
            theButton.classList.add("text-white");
            theButton.classList.remove("button--loading");
            theButton.disabled = false;

            // helper.getPDF(this.state.retData.order.orderId);
          }}>Download Pdf</button>
        </div>
        <section class="content home canvas_div_pdf" style={{ marginLeft: 0, marginTop: 0 }}>
          <div class="container-fluid provider_order provider_order_pdf">

            {/* form area start */}
            <div class="row clearfix">
              <div class="col-md-12">
                <div class="body">

                  {console.log("this.state.retData: ", this.state.retData)}

                  {/* HBOC ASPIRA GENETIX Hereditary Breast and Ovarian Cancer */}

                  <div class="row clearfix">
                    <div class="col-md-12"><div class="bg-div"></div><h4 class="panel-titlepdf1">ASPIRA GENETIX Hereditary Breast and Ovarian Cancer</h4></div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div class="form-group required">
                        <label class="form-label" for="address">ASPIRA GENETIX Hereditary Breast and Ovarian Cancer (select panel)</label>
                        <div class="demo-radio-button">
                          <div class="row clearfix">
                            {(this.state.retData.order.tests[0].genetixCSPanel.length > 0) && (
                              this.state.retData.order.tests[0].genetixCSPanel.map((item, i) => {
                                return <>
                                  {(item) && (
                                    <div class="col-md-12">
                                      <input data-val={item} checked type="radio" id={i} name={item} class="filled-in-" />
                                      <label for={i}>{item} &nbsp; </label>
                                    </div>
                                  )}
                                </>
                              })
                            )}


                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* HBOC ASPIRA GENETIX Hereditary Breast and Ovarian Cancer End */}

                  {/* HBOC Physician Information */}
                  {/* {this.state.retData.physician.organizationAccount} */}
                  <div class="row clearfix">
                    <div class="col-md-12"><div class="bg-div"></div><h4 class="panel-titlepdf1">Physician Information</h4></div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div class="form-group">
                        <label class="form-label" for="ordered_before1">Organization/Account #</label>
                        <div class="form-line">
                          <input type="text" value={`${this.state.retData.order.legacyName} / ${this.state.retData.order.legacyClientId}`} id="phyinfo_Organization_account" class="form-control" placeholder="Organization/Account #" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-3" >
                      <div class="form-group required">
                        <label class="form-label" for="ordered_before1">First Name</label>
                        <div class="form-line">
                          <input type="text" id={`phyinfo_first_name`} value={this.state.retData.physician.firstName} class="form-control required" placeholder="First Name" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3" >
                      <div class="form-group required">
                        <label class="form-label" for="ordered_before1">Last Name</label>
                        <div class="form-line">
                          <input type="text" id={`phyinfo_last_name`} value={this.state.retData.physician.lastName} class="form-control required" placeholder="Last Name" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3" >
                      <div class="form-group required">
                        <label class="form-label" for="ordered_before1">Email</label>
                        <div class="form-line">
                          <input type="text" id={`phyinfo_email`} value={this.state.retData.physician.email} class="form-control required" placeholder="Email" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3" >
                      <div class="form-group required">
                        <label class="form-label" for="ordered_before1">NPI</label>
                        <div class="form-line">
                          <input type="text" id={`phyinfo_NPI`} value={this.state.retData.physician.npiNumber} class="form-control required" placeholder="NPI" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-6">
                      <div class="form-group required">
                        <label class="form-label" for="ordered_before1">Address</label>
                        <div class="form-line">
                          <input type="text" id={`phyinfoC_address`} value={this.state.retData.physician.addresses[0].address_1} class="form-control required" placeholder="Address" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group required">
                        <label class="form-label" for="ordered_before1">Practice Location</label>
                        <div class="form-line">
                          <input type="text" id={`phyinfoC_practiceLocation`} value={this.state.retData.physician.practiceLocationPlain} class="form-control required" placeholder="Practice Location" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-4">
                      <div class="form-group ">
                        <label class="form-label" for="ordered_before1">City </label>
                        <div class="form-line">
                          <input type="text" value={this.state.retData.physician.addresses[0].city} id={`phyinfoC_city`} class="form-control" placeholder="City" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group ">
                        <label class="form-label" for="ordered_before1">State</label>
                        <div class="form-line">
                          <input type="text" id={`phyinfoC_state`} value={this.state.retData.physician.addresses[0].state} class="form-control" placeholder="State" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group ">
                        <label class="form-label" for="ordered_before1">Zip Code</label>
                        <div class="form-line">
                          <input type="email" id={`phyinfoC_zipCode`} value={this.state.retData.physician.addresses[0].zipCode} class="form-control" placeholder="Zip Code" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-4">
                      <div class="form-group required">
                        <label class="form-label" for="ordered_before1">Phone </label>
                        <div class="form-line">
                          <input type="text" id="phyinfoC_Phone" value={this.state.retData.physician.addresses[0].phoneNo} class="form-control required" placeholder="Phone" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group">
                        <label class="form-label" for="ordered_before1">Fax</label>
                        <div class="form-line">
                          <input type="text" id={`phyinfoC_fax`} value={this.state.retData.physician.addresses[0].fax} class="form-control" placeholder="Fax" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group ">
                        <label class="form-label" for="ordered_before1">Fax copy to</label>
                        <div class="form-line">
                          <input type="email" id={`phyinfoC_Faxcopyto`} value={this.state.retData.physician.addresses[0].faxCopyTo} class="form-control required" placeholder="Fax copy to" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div class="demo-checkbox ">
                        <input type="checkbox" id={`phyinfoC_confirmation`} name="phyinfoC_confirmation" class="filled-in-1 required" />
                        <label for={`phyinfoC_confirmation`} class="form-label">By checking the box below I am confirming that I have provided informed consent for this test. </label>
                      </div>
                    </div>
                  </div>
                  {/* HBOC Physician Information end */}

                  {/* HBOC Patient Information */}
                  {/* {this.state.retData.patient.organizationAccount} */}
                  <div class="row clearfix">
                    <div class="col-md-12"><div class="bg-div"></div><h4 class="panel-titlepdf1">Patient Information</h4></div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-6">
                      <div class="form-group required">
                        <label class="form-label" for={`patinfo_firstname`}>First Name</label>
                        <div class="form-line">
                          <input type="text" id={`patinfo_firstname`} value={this.state.retData.patient.firstName} class="form-control required" placeholder="First Name" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group required">
                        <label class="form-label" for={`patinfo_lastname`}>Last Name</label>
                        <div class="form-line">
                          <input type="text" id={`patinfo_lastname`} value={this.state.retData.patient.lastName} class="form-control required" placeholder="Last Name" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-6">
                      <div class="form-group required">
                        <label class="form-label" for={`patinfo_address1`}>Address 1</label>
                        <div class="form-line">
                          <input type="text" id={`patinfo_address1`} value={this.state.retData.patient.address.address_1} class="form-control required" placeholder="Address 1" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group required">
                        <label class="form-label" for={`patinfo_address2`}>Address 2</label>
                        <div class="form-line">
                          <input type="text" id={`patinfo_address2`} value={this.state.retData.patient.address.address_2} class="form-control required" placeholder="Address 2" />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix">
                    <div class="col-md-4">
                      <div class="form-group required">
                        <label class="form-label" for={`patinfo_city`}>City</label>
                        <div class="form-line">
                          <input type="text" id={`patinfo_city`} value={this.state.retData.patient.address.city} class="form-control required" placeholder="City" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group required">
                        <label class="form-label" for="state">State</label>
                        <div class="form-line">
                          <input type="text" id={`patinfo_city`} value={this.state.retData.patient.address.state} class="form-control required" placeholder="State" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group required">
                        <label class="form-label" for={`patinfo_zipcode`}>Zip Code</label>
                        <div class="form-line">
                          <input type="text" id={`patinfo_zipcode`} value={this.state.retData.patient.address.zipCode} class="form-control required" placeholder="Zip Code" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-4">
                      <div class="form-group required">
                        <label class="form-label" for={`patinfo_DOB`}>DOB (mm/dd/yyyy)</label>
                        <div class="form-line">
                          <input type="text" id={`patinfo_DOB`} value={moment(this.state.retData.patient.dob).format("MM/DD/YYYY")} class="form-control required" placeholder="DOB" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group required">
                        <label class="form-label" for="PhoneNumber">Phone Number</label>
                        <div class="form-line">
                          <input type="text" id={`patinfo_PhoneNumber`} value={this.state.retData.patient.phoneNo} class="form-control required" placeholder="Phone Number" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-group required">
                        <label class="form-label" for={`patinfo_emailaddress`}>Email Address</label>
                        <div class="form-line">
                          <input type="email" value={this.state.retData.patient.email} id={`patinfo_emailaddress`} class="form-control required" placeholder="Email Address" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-6">
                      <div class="form-group required">
                        <label class="form-label" for={`patinfo_gender`}>Gender</label>
                        {/* {this.state.retData.patient.gender} */}
                        <div class="demo-radio-button">
                          {((this.state.retData.patient.gender).toLowerCase() === 'male') && (<>
                            <input type="radio" checked id={`patinfo_gender`} name="patinfo_gender_" value="male" class=" form-control" />
                            <label class="form-label gender" for={`patinfo_gender`}>Male &nbsp; </label>
                          </>)}
                          {((this.state.retData.patient.gender).toLowerCase() != 'male') && (<>
                            <input type="radio" id={`patinfo_gender`} name="patinfo_gender_" value="male" class=" form-control" />
                            <label class="form-label gender" for={`patinfo_gender`}>Male &nbsp; </label>
                          </>)}

                          {((this.state.retData.patient.gender).toLowerCase() === 'female') && (<>
                            <input type="radio" checked id={`patinfo_gender2`} name="patinfo_gender_" value="female" class=" form-control" />
                            <label class="form-label gender" for={`patinfo_gender2`}>Female &nbsp; </label>
                          </>)}
                          {((this.state.retData.patient.gender).toLowerCase() != 'female') && (<>
                            <input type="radio" id={`patinfo_gender2`} name="patinfo_gender_" value="female" class=" form-control" />
                            <label class="form-label gender" for={`patinfo_gender2`}>Female &nbsp; </label>
                          </>)}
                        </div>

                      </div>
                    </div>
                  </div>
                  {/* {this.state.retData.patient.ethnicity}                */}
                  {/* {console.log("ethin: "+this.state.ethnicityArray)} */}
                  {/* {((this.state.ethnicityArray).includes("Caucasian")) ? "y" : "n" } */}
                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div class="form-group required">
                        <label class="form-label" for="Ethnicity">Ethnicity (Check all that apply)</label>
                        <div class="demo-radio-button">
                          {((this.state.ethnicityArray).includes("Caucasian")) && (
                            <input type="radio" checked data-val="Caucasian" id={`patinfo_Caucasian`} name="patinfo_Caucasian_" class="form-control" />)}
                          {!((this.state.ethnicityArray).includes("Caucasian")) && (
                            <input type="radio" data-val="Caucasian" id={`patinfo_Caucasian`} name="patinfo_Caucasian_" class="form-control" />)}
                          <label for={`patinfo_Caucasian`} class="form-label gender">Caucasian &nbsp; &nbsp; </label>

                          {((this.state.ethnicityArray).includes("Ashkenazi Jewish")) && (
                            <input type="radio" checked data-val="Ashkenazi Jewish" id={`patinfo_AshkenaziJewish`} name="patinfo_Caucasian_" class="form-control" />)}
                          {!((this.state.ethnicityArray).includes("Ashkenazi Jewish")) && (
                            <input type="radio" data-val="Ashkenazi Jewish" id={`patinfo_AshkenaziJewish`} name="patinfo_Caucasian_" class="form-control" />)}
                          <label for={`patinfo_AshkenaziJewish`} class="form-label gender">Ashkenazi Jewish &nbsp; &nbsp; </label>

                          {((this.state.ethnicityArray).includes("Sephardic Jewish")) && (
                            <input type="radio" checked data-val="Sephardic Jewish" id={`patinfo_SephardicJewish`} name="patinfo_Caucasian_" class="form-control" />)}
                          {!((this.state.ethnicityArray).includes("Sephardic Jewish")) && (
                            <input type="radio" data-val="Sephardic Jewish" id={`patinfo_SephardicJewish`} name="patinfo_Caucasian_" class="form-control" />)}
                          <label for={`patinfo_SephardicJewish`} class="form-label gender">Sephardic Jewish &nbsp; &nbsp; </label>

                          {((this.state.ethnicityArray).includes("Asian")) && (
                            <input type="radio" checked data-val="Asian" id={`patinfo_Asian`} name="patinfo_Caucasian_" class="form-control" />)}
                          {!((this.state.ethnicityArray).includes("Asian")) && (
                            <input type="radio" data-val="Asian" id={`patinfo_Asian`} name="patinfo_Caucasian_" class="form-control" />)}
                          <label for={`patinfo_Asian`} class="form-label gender">Asian &nbsp; &nbsp; </label>

                          {((this.state.ethnicityArray).includes("Hispanic")) && (
                            <input type="radio" checked data-val="Hispanic" id={`patinfo_HIspanic`} name="patinfo_Caucasian_" class="form-control" />)}
                          {!((this.state.ethnicityArray).includes("Hispanic")) && (
                            <input type="radio" data-val="Hispanic" id={`patinfo_HIspanic`} name="patinfo_Caucasian_" class="form-control" />)}
                          <label for={`patinfo_HIspanic`} class="form-label gender">Hispanic &nbsp; &nbsp; </label>

                          {((this.state.ethnicityArray).includes("Native American")) && (
                            <input type="radio" checked data-val="Native American" id={`patinfo_NativeAmerican`} name="patinfo_Caucasian_" class="form-control" />)}
                          {!((this.state.ethnicityArray).includes("Native American")) && (
                            <input type="radio" data-val="Native American" id={`patinfo_NativeAmerican`} name="patinfo_Caucasian_" class="form-control" />)}
                          <label for={`patinfo_NativeAmerican`} class="form-label gender">Native American &nbsp; &nbsp; </label>

                          {((this.state.ethnicityArray).includes("African American")) && (
                            <input type="radio" checked data-val="African American" id={`patinfo_AfricanAmerican`} name="patinfo_Caucasian_" class="form-control" />)}
                          {!((this.state.ethnicityArray).includes("African American")) && (
                            <input type="radio" data-val="African American" id={`patinfo_AfricanAmerican`} name="patinfo_Caucasian_" class="form-control" />)}
                          <label for={`patinfo_AfricanAmerican`} class="form-label gender">African American &nbsp; &nbsp; </label>

                          {((this.state.ethnicityArray).includes("N/A")) && (
                            <input type="radio" checked data-val="N/A" id={`patinfo_na`} name="patinfo_Caucasian_" class="form-control" />)}
                          {!((this.state.ethnicityArray).includes("N/A")) && (
                            <input type="radio" data-val="N/A" id={`patinfo_na`} name="patinfo_Caucasian_" class="form-control" />)}
                          <label for={`patinfo_na`} class="form-label gender">N/A &nbsp; &nbsp; </label>

                          {((this.state.ethnicityArray).includes("Other")) && (
                            <input type="radio" checked data-val="other" id={`patinfo_Other`} name="patinfo_Caucasian_" class="form-control" />)}
                          {!((this.state.ethnicityArray).includes("Other")) && (
                            <input type="radio" data-val="other" id={`patinfo_Other`} name="patinfo_Caucasian_" class="form-control" />)}
                          <label for={`patinfo_Other`} class="form-label gender">Other: &nbsp; &nbsp; </label>

                        </div>
                        <div class={((this.state.ethnicityArray).includes("Other")) ? "form-line showFormContent" : "form-line hideFormContent"} id={`patinfo_Othercity_div`}>
                          <input type="text" value={this.state.ethnicityArray[this.state.ethnicityArray.length - 1]} id={`patinfo_OtherEthnicitycity`} name="patinfo_OtherEthnicitycity" class="form-control" placeholder="Other" />
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* HBOC Patient Information end */}

                  {/* HBOC Billing Information  */}
                  <div class="row clearfix">
                    <div class="col-md-12"><div class="bg-div"></div><h4 class="panel-titlepdf1">Billing Information</h4></div>
                  </div>
                  <div class="col-md-12">
                    <div class="form-group required">
                      <label class="form-label" for={`BillingInformation`}>Billing Information</label>
                      <div class="demo-checkbox">
                        {/* {(this.state.retData.order.billingInfo.hboc_billinfo_privateInsurance)} */}
                        {(this.state.retData.order.billingInfo.privateInsurance) && (<input type="checkbox" checked id={`billinfo_PrivateInsurance`} name={`billinfo_PrivateInsurance`} class="filled-in-1" />)}
                        {!(this.state.retData.order.billingInfo.privateInsurance) && (<input type="checkbox" id={`billinfo_PrivateInsurance`} name={`billinfo_PrivateInsurance`} class="filled-in-1" />)}
                        <label for={`billinfo_PrivateInsurance`}>Private Insurance &nbsp; </label>

                        {(this.state.retData.order.billingInfo.medicare) && (<input checked type="checkbox" id={`billinfo_Medicare`} name={`billinfo_Medicare`} class="filled-in-1" />)}
                        {!(this.state.retData.order.billingInfo.medicare) && (<input type="checkbox" id={`billinfo_Medicare`} name={`billinfo_Medicare`} class="filled-in-1" />)}
                        <label for={`billinfo_Medicare`}>Medicare<sup>1</sup> &nbsp; </label>

                        {(this.state.retData.order.billingInfo.patientSelfPay) && (<input checked type="checkbox" id={`billinfo_PatientSelfPay`} name={`billinfo_PatientSelfPay`} class="filled-in-1" />)}
                        {!(this.state.retData.order.billingInfo.patientSelfPay) && (<input type="checkbox" id={`billinfo_PatientSelfPay`} name={`billinfo_PatientSelfPay`} class="filled-in-1" />)}
                        <label for={`billinfo_PatientSelfPay`}>Patient Self-Pay &nbsp; </label>

                        {(this.state.retData.order.billingInfo.medicaid) && (<input type="checkbox" checked id={`billinfo_Medicaid`} name={`billinfo_Medicaid`} class="filled-in-1" />)}
                        {!(this.state.retData.order.billingInfo.medicaid) && (<input type="checkbox" id={`billinfo_Medicaid`} name={`billinfo_Medicaid`} class="filled-in-1" />)}
                        <label for={`billinfo_Medicaid`}>Medicaid &nbsp; </label>

                        {(this.state.retData.order.billingInfo.orderingFacility) && (<input checked type="checkbox" id={`billinfo_OrderingFacility`} name={`billinfo_OrderingFacility`} class="filled-in-1" />)}
                        {!(this.state.retData.order.billingInfo.orderingFacility) && (<input type="checkbox" id={`billinfo_OrderingFacility`} name={`billinfo_OrderingFacility`} class="filled-in-1" />)}
                        <label for={`billinfo_OrderingFacility`}>Ordering Facility (Client Bill) &nbsp; </label>
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix">
                    <div class="col-md-12">
                      <p class="billInfoOva"><sup>1</sup>By ordering this test for your Medicare physician, you are certifying that the physician has met the requirements for use <br />i.e has an ovarian mass, has surgery planned and is over 18 years of age</p>
                      <p id={`title_insurance_container`} class=" hidediv">Insurance Information</p>
                    </div>
                  </div>
                  <div id="primary_insurance_container" class=" hidediv">
                    <div class="row clearfix">
                      <div class="col-md-4">
                        <div class="form-group required">
                          <label class="form-label" for={`PrimaryInsuranceCarrier`}>Primary Insurance Carrier </label>
                          <div class="form-line">
                            <input type="text" id={`billinfo_PrimaryInsuranceCarrier`} value={this.state.retData.order.billingInfo.primaryInsurance.primaryInsuranceCarrier} class="form-control required" placeholder="Primary Insurance Carrier" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group required">
                          <label class="form-label" for={`MemberID`}>Member ID# </label>
                          <div class="form-line">
                            <input type="text" value={this.state.retData.order.billingInfo.primaryInsurance.memberID} id={`billinfo_MemberID`} class="form-control required" placeholder="Member ID" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group required">
                          <label class="form-label" for={`GroupID`}>Group ID# </label>
                          <div class="form-line">
                            <input type="text" value={this.state.retData.order.billingInfo.primaryInsurance.groupID} id={`billinfo_GroupID`} class="form-control required" placeholder="Group ID#" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="secondary_insurance_container" class=" hidediv">
                    <div class="row clearfix">
                      <div class="col-md-4">
                        <div class="form-group ">
                          <label class="form-label" for={`SecondaryInsuranceCarrier`}>Secondary Insurance Carrier </label>
                          <div class="form-line">
                            <input type="text" value={this.state.retData.order.billingInfo.secondaryInsurance.hboc_billinfo_SecondaryInsuranceCarrier} id={`billinfo_SecondaryInsuranceCarrier`} class="form-control " placeholder="Secondary Insurance Carrier" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group ">
                          <label class="form-label" for={`SecondaryMemberID`}>Member ID# </label>
                          <div class="form-line">
                            <input type="text" value={this.state.retData.order.billingInfo.secondaryInsurance.secondaryMemberID} id={`billinfo_SecondaryMemberID`} class="form-control " placeholder="Member ID" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group ">
                          <label class="form-label" for={`SecondaryGroupID`}>Group ID# </label>
                          <div class="form-line">
                            <input type="text" value={this.state.retData.order.billingInfo.secondaryInsurance.secondaryGroupID} id={`billinfo_SecondaryGroupID`} class="form-control " placeholder="Group ID#" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="tertiary_insurance_container" class=" hidediv">
                    <div class="row clearfix">
                      <div class="col-md-4">
                        <div class="form-group ">
                          <label class="form-label" for={`TertiaryInsuranceCarrier`}>Tertiary Insurance Carrier </label>
                          <div class="form-line">
                            <input type="text" value={(typeof this.state.retData.order.billingInfo.tertiaryInsurance != 'undefined') ? this.state.retData.order.billingInfo.tertiaryInsurance.hboc_billinfo_TertiaryInsuranceCarrier : ''} id={`billinfo_TertiaryInsuranceCarrier`} class="form-control " placeholder="Tertiary Insurance Carrier" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group ">
                          <label class="form-label" for={`TertiaryMemberID`}>Member ID# </label>
                          <div class="form-line">
                            <input type="text" value={(typeof this.state.retData.order.billingInfo.tertiaryInsurance != 'undefined') ? this.state.retData.order.billingInfo.tertiaryInsurance.tertiaryMemberID : ''} id={`billinfo_TertiaryMemberID`} class="form-control " placeholder="Member ID" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group ">
                          <label class="form-label" for={`TertiaryGroupID`}>Group ID# </label>
                          <div class="form-line">
                            <input type="text" value={(typeof this.state.retData.order.billingInfo.tertiaryInsurance != 'undefined') ? this.state.retData.order.billingInfo.tertiaryInsurance.tertiaryGroupID : ''} id={`billinfo_TertiaryGroupID`} class="form-control " placeholder="Group ID#" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="insured_info_container" class=" hidediv">
                    <div id="insured_info" class="row clearfix">
                      <div class="col-md-4">
                        <div class="form-group required">
                          <label class="form-label" for={`NameofInsured`}>Name of Insured </label>
                          {/* <div class="form-line">
                  <input type="text" value={this.state.retData.order.billingInfo.insuredInfo.nameofInsured} id={`billinfo_NameofInsured`} class="form-control required" placeholder="Name of Insured" />
                </div> */}
                          <div class="form-line">
                            <div className="row clearfix">
                              <div className="col-md-6">
                                <input
                                  type="text"
                                  value={this.state.retData.order.billingInfo.insuredInfo.nameOfInsured.firstName}
                                  id={`billinfo_FirstNameofInsured`}
                                  class="form-control required"
                                  placeholder="First Name"
                                />
                              </div>
                              <div className="col-md-6">
                                <input
                                  type="text"
                                  value={this.state.retData.order.billingInfo.insuredInfo.nameOfInsured.lastName}
                                  id={`billinfo_LastNameofInsured`}
                                  class="form-control required"
                                  placeholder="Last Name"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group required">
                          <label class="form-label" for={`DOB`}>DOB (mm/dd/yyyy) </label>
                          <div class="form-line">
                            <input type="text" value={moment(this.state.retData.order.billingInfo.insuredInfo.dob).format("MM/DD/YYYY")} id={`billinfoC_dob`} class="form-control required" placeholder="DOB (mm/dd/yyyy)" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group required">
                          <label class="form-label" for={`Relationshiptoinsured`}>Relationship to insured</label>
                          <div class="form-line">
                            <input type="text" value={this.state.retData.order.billingInfo.insuredInfo.relationshipInsured} id={`billinfo_RelationshipInsured`} class="form-control required" placeholder="Relationship to insured" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* HBOC Billing Information end */}
                  {/* Billing Notes */}
                  <div class="row clearfix">
                    <div class="col-md-12"><div class="bg-div"></div><h4 class="panel-titlepdf1">Billing Notes</h4></div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div class="form-group">
                        {typeof this.state.retData.order.billingInfo.notes != 'undefined' && this.state.retData.order.billingInfo.notes.map((n, i) => {
                          return <p>{i + 1}. {`Note: ${n.note}, Posted by: ${n.noteBy}, Date Time: ${n.noteTime}`}</p>
                        })
                        }
                        {(typeof this.state.retData.order.billingInfo.notes == 'undefined') && (
                          <p>No Notes yet!</p>
                        )
                        }
                      </div>
                    </div>
                  </div>
                  {/* Billing Notes */}

                  {/* HBOC Diagnosis Codes | ICD-10 Codes */}
                  <div class="row clearfix">
                    <div class="col-md-12"><div class="bg-div"></div><h4 class="panel-titlepdf1">Diagnosis Codes | ICD-10 Codes</h4></div>
                  </div>
                  {(this.state.retData.order.tests.length > 1) && (<>
                    {(this.state.retData.order.tests[1].icd10Code.codes.length > 0) && (<>
                      <div class="row clearfix">
                        <div class="col-md-12">
                          <label class="form-label" for="DiagnosisCodes_GHBOCG"><b>COVD Diagnosis Codes | ICD-10 Codes (check all that apply)</b></label>
                        </div>
                      </div>
                      <div class="row clearfix">
                        <div class="col-md-12">
                          <div class="demo-checkbox">
                            <div class="row clearfix">
                              {(this.state.retData.order.tests[1].icd10Code.codes.includes("Z11.59")) && (
                                <div class="col-md-6">
                                  <input type="checkbox" checked data-val="Z11.59" id={`${this.props.prefix}diagnosis_z1159`} name={`${this.props.prefix}diagnosis_z1159`} class="filled-in-" />
                                  <label for={`${this.props.prefix}diagnosis_z1159`}>Z11.59, Encounter for screening for other viral diseases &nbsp; </label>
                                </div>
                              )}
                              {(this.state.retData.order.tests[1].icd10Code.codes.includes("Z03.818")) && (
                                <div class="col-md-6">
                                  <input type="checkbox" checked data-val="Z03.818" id={`${this.props.prefix}diagnosis_z03818`} name={`${this.props.prefix}diagnosis_z03818`} class="filled-in-" />
                                  <label for={`${this.props.prefix}diagnosis_z03818`}>Z03.818, Encounter for observation for suspected exposure to other biological agents ruled out &nbsp; </label>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>)}
                  </>)}

                  {(this.state.retData.order.tests.length > 0) && (<>
                    {(this.state.retData.order.tests[0].icd10Code.codes.length > 0) && (<>
                      <div class="row clearfix">
                        <div id={`hboc_icd_codes`} class="col-md-12">
                          <div class="form-group ">
                            <div class="row clearfix">
                              <div class="col-md-12">
                                <label class="form-label" for="DiagnosisCodes_GHBOCG"><b>GHBOCG Diagnosis Codes | ICD-10 Codes (check all that apply)</b></label>
                              </div>
                            </div>
                            <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_1">
                              <div class="row clearfix">
                                {(this.state.retData.order.tests[0].icd10Code.codes.includes("Z15.01")) && (
                                  <div class="col-md-6">
                                    <input type="checkbox" checked data-val="Z15.01" id={`hBOCdiagnosis_z1501`} name={`hBOCdiagnosis_z1501`} class="filled-in-" />
                                    <label for={`hBOCdiagnosis_z1501`}>Z15.01 Genetic susceptibility to malignant neoplasm of breast &nbsp; </label>
                                  </div>
                                )}
                                {(this.state.retData.order.tests[0].icd10Code.codes.includes("C48.1")) && (
                                  <div class="col-md-6">
                                    <input type="checkbox" data-val="C48.1" checked id={`hBOCdiagnosis_c481`} name={`hBOCdiagnosis_c481`} class="filled-in-" />
                                    <label for={`hBOCdiagnosis_c481`}>C48.1 Malignant neoplasm of specified parts of peritoneum &nbsp; </label>
                                  </div>
                                )}
                                {(this.state.retData.order.tests[0].icd10Code.codes.includes("Z15.02")) && (
                                  <div class="col-md-6">
                                    <input type="checkbox" checked data-val="Z15.02" id={`hBOCdiagnosis_z1502`} name={`hBOCdiagnosis_z1502`} class="filled-in-" />
                                    <label for={`hBOCdiagnosis_z1502`}>Z15.02 Genetic susceptibility to malignant neoplasm of ovary &nbsp; </label>
                                  </div>
                                )}
                                {(this.state.retData.order.tests[0].icd10Code.codes.includes("C50.0")) && (
                                  <div class="col-md-6">
                                    <input type="checkbox" checked data-val="C50.0" id={`hBOCdiagnosis_c500`} name={`hBOCdiagnosis_c500`} class="filled-in-" />
                                    <label for={`hBOCdiagnosis_c500`}>C50.0 Malignant neoplasm of breast [add anatomic site detail] &nbsp; </label>
                                  </div>
                                )}
                                {(this.state.retData.order.tests[0].icd10Code.codes.includes("Z15.03")) && (
                                  <div class="col-md-6">
                                    <input type="checkbox" checked data-val="Z15.03" id={`hBOCdiagnosis_z1503`} name={`hBOCdiagnosis_z1503`} class="filled-in-" />
                                    <label for={`hBOCdiagnosis_z1503`}>Z15.03 Genetic susceptibility to malignant neoplasm of prostate &nbsp; </label>
                                  </div>
                                )}
                                {(this.state.retData.order.tests[0].icd10Code.codes.includes("C56.1")) && (
                                  <div class="col-md-6">
                                    <input type="checkbox" checked data-val="C56.1" id={`hBOCdiagnosis_c561`} name={`hBOCdiagnosis_c561`} class="filled-in-" />
                                    <label for={`hBOCdiagnosis_c561`}>C56.1 Malignant neoplasm of right ovary &nbsp; </label>
                                  </div>
                                )}
                                {(this.state.retData.order.tests[0].icd10Code.codes.includes("Z15.04")) && (
                                  <div class="col-md-6">
                                    <input type="checkbox" checked data-val="Z15.04" id={`hBOCdiagnosis_z1504`} name={`hBOCdiagnosis_z1504`} class="filled-in-" />
                                    <label for={`hBOCdiagnosis_z1504`}>Z15.04 Genetic susceptibility to malignant neoplasm of endometrium &nbsp; </label>
                                  </div>
                                )}
                                {(this.state.retData.order.tests[0].icd10Code.codes.includes("C56.2")) && (
                                  <div class="col-md-6">
                                    <input type="checkbox" checked data-val="C56.2" id={`hBOCdiagnosis_c562`} name={`hBOCdiagnosis_c562`} class="filled-in-" />
                                    <label for={`hBOCdiagnosis_c562`}>C56.2 Malignant neoplasm of left ovary &nbsp; </label>
                                  </div>
                                )}
                                {(this.state.retData.order.tests[0].icd10Code.codes.includes("Z15.09")) && (
                                  <div class="col-md-6">
                                    <input checked type="checkbox" data-val="Z15.09" id={`hBOCdiagnosis_z1509`} name={`hBOCdiagnosis_z1509`} class="filled-in-" />
                                    <label for={`hBOCdiagnosis_z1509`}>Z15.09 Genetic susceptibility to other malignant neoplasm &nbsp; </label>
                                  </div>
                                )}
                                {(this.state.retData.order.tests[0].icd10Code.codes.includes("C56.9")) && (
                                  <div class="col-md-6">
                                    <input type="checkbox" checked data-val="C56.9" id={`hBOCdiagnosis_c569`} name={`hBOCdiagnosis_c569`} class="filled-in-" />
                                    <label for={`hBOCdiagnosis_c569`}>C56.9 Malignant neoplasm of unspecified ovary &nbsp; </label>
                                  </div>
                                )}
                                {(this.state.retData.order.tests[0].icd10Code.codes.includes("Z15.81")) && (
                                  <div class="col-md-6">
                                    <input type="checkbox" checked data-val="Z15.81" id={`hBOCdiagnosis_z1581`} name={`hBOCdiagnosis_z1581`} class="filled-in-" />
                                    <label for={`hBOCdiagnosis_z1581`}>Z15.81 Genetic susceptibility to multiple endocrine neoplasia [MEN] &nbsp; </label>
                                  </div>
                                )}
                                {(this.state.retData.order.tests[0].icd10Code.codes.includes("C57.00")) && (
                                  <div class="col-md-6">
                                    <input checked type="checkbox" data-val="C57.00" id={`hBOCdiagnosis_c5700`} name={`hBOCdiagnosis_c5700`} class="filled-in-" />
                                    <label for={`hBOCdiagnosis_c5700`}>C57.00 Malignant neoplasm of unspecified fallopain tube &nbsp; </label>
                                  </div>
                                )}
                                {(this.state.retData.order.tests[0].icd10Code.codes.includes("C25.0")) && (
                                  <div class="col-md-6">
                                    <input type="checkbox" checked data-val="C25.0" id={`hBOCdiagnosis_C250`} name={`hBOCdiagnosis_C250`} class="filled-in-" />
                                    <label for={`hBOCdiagnosis_C250`}>C25.0 Malignant neoplasm of head of pancreas &nbsp; </label>
                                  </div>
                                )}
                                {(this.state.retData.order.tests[0].icd10Code.codes.includes("C57.01")) && (
                                  <div class="col-md-6">
                                    <input type="checkbox" checked data-val="C57.01" id={`hBOCdiagnosis_c5701`} name={`hBOCdiagnosis_c5701`} class="filled-in-" />
                                    <label for={`hBOCdiagnosis_c5701`}>C57.01 Malignant neoplasm of right fallopain tube &nbsp; </label>
                                  </div>
                                )}
                                {(this.state.retData.order.tests[0].icd10Code.codes.includes("C25.1")) && (
                                  <div class="col-md-6">
                                    <input type="checkbox" checked data-val="C25.1" id={`hBOCdiagnosis_C251`} name={`hBOCdiagnosis_C251`} class="filled-in-" />
                                    <label for={`hBOCdiagnosis_C251`}>C25.1 Malignant neoplasm of body of pancreas &nbsp; </label>
                                  </div>
                                )}
                                {(this.state.retData.order.tests[0].icd10Code.codes.includes("C57.01")) && (
                                  <div class="col-md-6">
                                    <input checked type="checkbox" data-val="C57.01" id={`hBOCdiagnosis_c5702`} name={`hBOCdiagnosis_c5702`} class="filled-in-" />
                                    <label for={`hBOCdiagnosis_c5702`}>C57.01 Malignant neoplasm of left fallopain tube &nbsp; </label>
                                  </div>
                                )}
                                {(this.state.retData.order.tests[0].icd10Code.codes.includes("C25.2")) && (
                                  <div class="col-md-6">
                                    <input checked type="checkbox" data-val="C25.2" id={`hBOCdiagnosis_C252`} name={`hBOCdiagnosis_C252`} class="filled-in-" />
                                    <label for={`hBOCdiagnosis_C252`}>C25.2 Malignant neoplasm of tail of pancreas &nbsp; </label>
                                  </div>
                                )}
                                {(this.state.retData.order.tests[0].icd10Code.codes.includes("C61")) && (
                                  <div class="col-md-6">
                                    <input checked type="checkbox" data-val="C61" id={`hBOCdiagnosis_c61`} name={`hBOCdiagnosis_c61`} class="filled-in-" />
                                    <label for={`hBOCdiagnosis_c61`}>C61 Malignant neoplasm of prostate &nbsp; </label>
                                  </div>
                                )}
                                {(this.state.retData.order.tests[0].icd10Code.codes.includes("C25.3")) && (
                                  <div class="col-md-6">
                                    <input checked type="checkbox" data-val="C25.3" id={`hBOCdiagnosis_C253`} name={`hBOCdiagnosis_C253`} class="filled-in-" />
                                    <label for={`hBOCdiagnosis_C253`}>C25.3 Malignant neoplasm of pancreatic duct &nbsp; </label>
                                  </div>
                                )}
                                {(this.state.retData.order.tests[0].icd10Code.codes.includes("D05.11")) && (
                                  <div class="col-md-6">
                                    <input checked type="checkbox" data-val="D05.11" id={`hBOCdiagnosis_D0511`} name={`hBOCdiagnosis_D0511`} class="filled-in-" />
                                    <label for={`hBOCdiagnosis_D0511`}>D05.11 Intraductal carcinoma in situ of right breast &nbsp; </label>
                                  </div>
                                )}
                                {(this.state.retData.order.tests[0].icd10Code.codes.includes("C25.4")) && (
                                  <div class="col-md-6">
                                    <input checked type="checkbox" data-val="C25.4" id={`hBOCdiagnosis_C254`} name={`hBOCdiagnosis_C254`} class="filled-in-" />
                                    <label for={`hBOCdiagnosis_C254`}>C25.4 Malignant neoplasm of endocrine pancreas &nbsp; </label>
                                  </div>
                                )}
                                {(this.state.retData.order.tests[0].icd10Code.codes.includes("D05.12")) && (
                                  <div class="col-md-6">
                                    <input type="checkbox" checked data-val="D05.12" id={`hBOCdiagnosis_D0512`} name={`hBOCdiagnosis_D0512`} class="filled-in-" />
                                    <label for={`hBOCdiagnosis_D0512`}>D05.12 Intraductal carcinoma in situ of left breast &nbsp; </label>
                                  </div>
                                )}
                                {(this.state.retData.order.tests[0].icd10Code.codes.includes("C25.7")) && (
                                  <div class="col-md-6">
                                    <input checked type="checkbox" data-val="C25.7" id={`hBOCdiagnosis_C257`} name={`hBOCdiagnosis_C257`} class="filled-in-" />
                                    <label for={`hBOCdiagnosis_C257`}>C25.7 Malignant neoplasm of other parts of pancreas &nbsp; </label>
                                  </div>
                                )}
                                {(this.state.retData.order.tests[0].icd10Code.codes.includes("Z85.07")) && (
                                  <div class="col-md-6">
                                    <input checked type="checkbox" data-val="Z85.07" id={`hBOCdiagnosis_Z8507`} name={`hBOCdiagnosis_Z8507`} class="filled-in-" />
                                    <label for={`hBOCdiagnosis_Z8507`}>Z85.07 Personal history of malignant neoplasm of pancreas &nbsp; </label>
                                  </div>
                                )}
                                {(this.state.retData.order.tests[0].icd10Code.codes.includes("C25.8")) && (
                                  <div class="col-md-6">
                                    <input checked type="checkbox" data-val="C25.8" id={`hBOCdiagnosis_C258`} name={`hBOCdiagnosis_C258`} class="filled-in-" />
                                    <label for={`hBOCdiagnosis_C258`}>C25.8 Malignant neoplasm of overlapping sites of pancreas &nbsp; </label>
                                  </div>
                                )}
                                {(this.state.retData.order.tests[0].icd10Code.codes.includes("Z85.3")) && (
                                  <div class="col-md-6">
                                    <input checked type="checkbox" data-val="Z85.3" id={`hBOCdiagnosis_Z853`} name={`hBOCdiagnosis_Z853`} class="filled-in-" />
                                    <label for={`hBOCdiagnosis_Z853`}>Z85.3 Personal history of malignant neoplasm of breast &nbsp; </label>
                                  </div>
                                )}
                                {(this.state.retData.order.tests[0].icd10Code.codes.includes("C25.9")) && (
                                  <div class="col-md-6">
                                    <input checked type="checkbox" data-val="C25.9" id={`hBOCdiagnosis_C259`} name={`hBOCdiagnosis_C259`} class="filled-in-" />
                                    <label for={`hBOCdiagnosis_C259`}>C25.9 Malignant neoplasm of pancreas, unspecified &nbsp; </label>
                                  </div>
                                )}
                                {(this.state.retData.order.tests[0].icd10Code.codes.includes("Z85.43")) && (
                                  <div class="col-md-6">
                                    <input type="checkbox" checked data-val="Z85.43" id={`hBOCdiagnosis_Z8543`} name={`hBOCdiagnosis_Z8543`} class="filled-in-" />
                                    <label for={`hBOCdiagnosis_Z8543`}>Z85.43 Personal history of malignant neoplasm of ovary &nbsp; </label>
                                  </div>
                                )}
                                {(this.state.retData.order.tests[0].icd10Code.codes.includes("Z85.46")) && (
                                  <div class="col-md-6">
                                    <input checked type="checkbox" data-val="Z85.46" id={`hBOCdiagnosis_Z8546`} name={`hBOCdiagnosis_Z8546`} class="filled-in-" />
                                    <label for={`hBOCdiagnosis_Z8546`}>Z85.46 Personal history of malignant neoplasm of prostate &nbsp; </label>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>)}
                  </>)}

                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div class="form-group ">
                        <label class="form-label" for={`address`}>Other ICD-10 Codes</label>
                        <div class="form-line">
                          <input type="text" value={this.state.retData.order.tests[0].icd10Code.otherCodes} id={`OtherICD10Codes`} class="form-control " placeholder="Other ICD-10 Codes" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-12">
                      <p>(This is provided for informational purposes only and is not a guarantee of coverage. It is the provider's responsibility to determine  the appropriate codes)</p>
                    </div>
                  </div>

                  {/* HBOC Diagnosis Codes | ICD-10 Codes end */}

                  {/* HBOC Personal/Family History -- Genetix HBOC */}
                  <div class="row clearfix">
                    <div class="col-md-12"><div class="bg-div"></div><h4 class="panel-titlepdf1">Personal/Family History -- Genetix HBOC</h4></div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div class="form-group ">
                        <h4>Personal History</h4>
                        <label class="form-label" for="address">Personal History (check all that apply)</label>
                        <div class="demo-checkbox" id="hboc1_q01">
                          {(this.state.retData.order.tests[0].clinicalData[0].answer == 'true') && (<input type="checkbox" checked id="pfHistory_noCancer" name="pfHistory_noCancer" class="filled-in-" />)}
                          {(this.state.retData.order.tests[0].clinicalData[0].answer == 'false') && (<input type="checkbox" id="pfHistory_noCancer" name="pfHistory_noCancer" class="filled-in-" />)}
                          <label for="pfHistory_noCancer">No personal history of cancer &nbsp; </label>

                        </div>
                        <div class="row clearfix" >
                          <div class="col-md-12">
                            <div class="form-group ">
                              <label class="form-label" for="address">Does the Patient have a history of:</label>
                              <div class="demo-radio-button" id="hboc1_q06">
                                <div class="row clearfix custom-min-height2">
                                  <div class="col-md-5">
                                    <label>Breast, ovarian or pancreative cancer at any age?</label>
                                    {(this.state.retData.order.tests[0].clinicalData[5].answer == 'true') && (<>
                                      <input checked type="radio" id="pfHistory_BrOvPanAnyAge" name="pfHistory_BrOvPanAnyAge_" value="yes" class="form-control" />
                                      <label class="form-label" for="pfHistory_BrOvPanAnyAge">Yes</label>
                                      <input type="radio" id="pfHistory_BrOvPanAnyAge2" name="pfHistory_BrOvPanAnyAge_" value="no" class="form-control" />
                                      <label class="form-label" for="pfHistory_BrOvPanAnyAge2">No</label>
                                    </>)}
                                    {(this.state.retData.order.tests[0].clinicalData[5].answer == 'false') && (<>
                                      <input type="radio" id="pfHistory_BrOvPanAnyAge" name="pfHistory_BrOvPanAnyAge_" value="yes" class="form-control" />
                                      <label class="form-label" for="pfHistory_BrOvPanAnyAge">Yes</label>
                                      <input checked type="radio" id="pfHistory_BrOvPanAnyAge2" name="pfHistory_BrOvPanAnyAge_" value="no" class="form-control" />
                                      <label class="form-label" for="pfHistory_BrOvPanAnyAge2">No</label>
                                    </>)}
                                  </div>
                                  <div class="col-md-7">
                                    <div className={(this.state.retData.order.tests[0].clinicalData[5].answer == 'true') ? " showFormContent" : " hideFormContent"} id="pfHistory_BrOvPanAnyAge_Div">
                                      <div class="row clearfix">
                                        <div class="col-md-2 text-right">
                                          <label>Which cancer?</label>
                                        </div>
                                        <div class="col-md-5">
                                          <div class="demo-checkbox" id="hboc1_q06_1">
                                            {(this.state.retData.order.tests[0].clinicalData[5].answer == 'true') && (<>

                                              {(this.state.retData.order.tests[0].clinicalData[5].subQuestion[0].answer.includes("Breast")) && (
                                                <>
                                                  <input type="checkbox" id="pfHistory_BrOvPanAnyAgeCan1" checked name="pfHistory_BrOvPanAnyAgeCan1" data-val="Breast" class="filled-in-" />
                                                  <label for="pfHistory_BrOvPanAnyAgeCan1">Breast &nbsp; </label>
                                                </>
                                              )}
                                              {(!this.state.retData.order.tests[0].clinicalData[5].subQuestion[0].answer.includes("Breast")) && (
                                                <>
                                                  <input type="checkbox" id="pfHistory_BrOvPanAnyAgeCan1" name="pfHistory_BrOvPanAnyAgeCan1" data-val="Breast" class="filled-in-" />
                                                  <label for="pfHistory_BrOvPanAnyAgeCan1">Breast &nbsp; </label>
                                                </>
                                              )}

                                              {(this.state.retData.order.tests[0].clinicalData[5].subQuestion[0].answer.includes("Ovarian")) && (
                                                <>
                                                  <input type="checkbox" checked id="pfHistory_BrOvPanAnyAgeCan2" name="pfHistory_BrOvPanAnyAgeCan2" data-val="Ovarian" class="filled-in-" />
                                                  <label for="pfHistory_BrOvPanAnyAgeCan2">Ovarian &nbsp; </label>
                                                </>
                                              )}
                                              {(!this.state.retData.order.tests[0].clinicalData[5].subQuestion[0].answer.includes("Ovarian")) && (
                                                <>
                                                  <input type="checkbox" id="pfHistory_BrOvPanAnyAgeCan2" name="pfHistory_BrOvPanAnyAgeCan2" data-val="Ovarian" class="filled-in-" />
                                                  <label for="pfHistory_BrOvPanAnyAgeCan2">Ovarian &nbsp; </label>
                                                </>
                                              )}

                                              {(this.state.retData.order.tests[0].clinicalData[5].subQuestion[0].answer.includes("Pancreative")) && (
                                                <>
                                                  <input type="checkbox" id="pfHistory_BrOvPanAnyAgeCan3" name="pfHistory_BrOvPanAnyAgeCan3" checked data-val="Pancreative" class="filled-in-" />
                                                  <label for="pfHistory_BrOvPanAnyAgeCan3">Pancreative &nbsp; </label>
                                                </>
                                              )}
                                              {(!this.state.retData.order.tests[0].clinicalData[5].subQuestion[0].answer.includes("Pancreative")) && (
                                                <>
                                                  <input type="checkbox" id="pfHistory_BrOvPanAnyAgeCan3" name="pfHistory_BrOvPanAnyAgeCan3" data-val="Pancreative" class="filled-in-" />
                                                  <label for="pfHistory_BrOvPanAnyAgeCan3">Pancreative &nbsp; </label>
                                                </>
                                              )}

                                            </>)}

                                            {(this.state.retData.order.tests[0].clinicalData[5].answer == 'false') && (<>

                                              <input type="checkbox" id="pfHistory_BrOvPanAnyAgeCan1" name="pfHistory_BrOvPanAnyAgeCan1" data-val="Breast" class="filled-in-" />
                                              <label for="pfHistory_BrOvPanAnyAgeCan1">Breast &nbsp; </label>

                                              <input type="checkbox" id="pfHistory_BrOvPanAnyAgeCan2" name="pfHistory_BrOvPanAnyAgeCan2" data-val="Ovarian" class="filled-in-" />
                                              <label for="pfHistory_BrOvPanAnyAgeCan2">Ovarian &nbsp; </label>

                                              <input type="checkbox" id="pfHistory_BrOvPanAnyAgeCan3" name="pfHistory_BrOvPanAnyAgeCan3" data-val="Pancreative" class="filled-in-" />
                                              <label for="pfHistory_BrOvPanAnyAgeCan3">Pancreative &nbsp; </label>

                                            </>)}

                                          </div>
                                          {/* <div class="form-line">
                                  <input type="text" value={this.state.retData.order.tests[0].clinicalData[5].subQuestion[0].answer} id="pfHistory_BrOvPanAnyAgeCan" class="form-control " placeholder="Which cancer?" />
                                </div> */}
                                        </div>
                                        <div class="col-md-3 text-right">
                                          <label>Age at diagnosis</label>
                                        </div>
                                        <div class="col-md-2">
                                          <div class="form-line">
                                            <input type="text" value={this.state.retData.order.tests[0].clinicalData[5].subQuestion[1].answer} id="pfHistory_BrOvPanAnyAgeInfo" class="form-control " placeholder="Age" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>{/*  end hboc1_q06 */}
                              <div class="demo-radio-button" id="hboc1_q07">
                                <div class="row clearfix custom-min-height2">
                                  <div class="col-md-5">
                                    <label>Colorectal or uterine cancer at 64 or younger?</label>
                                    {(this.state.retData.order.tests[0].clinicalData[6].answer == 'true') && (<>
                                      <input type="radio" checked id="pfHistory_ColorUterineAnyAge" name="pfHistory_ColorUterineAnyAge_" value="yes" class="form-control" />
                                      <label class="form-label" for="pfHistory_ColorUterineAnyAge">Yes</label>
                                      <input type="radio" id="pfHistory_ColorUterineAnyAge2" name="pfHistory_ColorUterineAnyAge_" value="no" class="form-control" />
                                      <label class="form-label" for="pfHistory_ColorUterineAnyAge2">No</label>
                                    </>)}
                                    {(this.state.retData.order.tests[0].clinicalData[6].answer == 'false') && (<>
                                      <input type="radio" id="pfHistory_ColorUterineAnyAge" name="pfHistory_ColorUterineAnyAge_" value="yes" class="form-control" />
                                      <label class="form-label" for="pfHistory_ColorUterineAnyAge">Yes</label>
                                      <input type="radio" checked id="pfHistory_ColorUterineAnyAge2" name="pfHistory_ColorUterineAnyAge_" value="no" class="form-control" />
                                      <label class="form-label" for="pfHistory_ColorUterineAnyAge2">No</label>
                                    </>)}
                                  </div>
                                  <div class="col-md-7">
                                    <div className={(this.state.retData.order.tests[0].clinicalData[6].answer == 'true') ? " showFormContent" : " hideFormContent"} id="pfHistory_ColorUterineAnyAge_Div">
                                      <div class="row clearfix">
                                        <div class="col-md-2 text-right">
                                          <label>Which cancer?</label>
                                        </div>
                                        <div class="col-md-5">
                                          <div class="demo-checkbox" id="hboc1_q07_1">

                                            {(this.state.retData.order.tests[0].clinicalData[6].answer == 'true') && (<>

                                              {(this.state.retData.order.tests[0].clinicalData[6].subQuestion[0].answer.includes("Colorectal")) && (
                                                <>
                                                  <input type="checkbox" id="pfHistory_ColorUterineAnyAgeCan1" checked name="pfHistory_ColorUterineAnyAgeCan1" data-val="Colorectal" class="filled-in-" />
                                                  <label for="pfHistory_ColorUterineAnyAgeCan1">Colorectal &nbsp; </label>
                                                </>
                                              )}
                                              {(!this.state.retData.order.tests[0].clinicalData[6].subQuestion[0].answer.includes("Colorectal")) && (
                                                <>
                                                  <input type="checkbox" id="pfHistory_ColorUterineAnyAgeCan1" name="pfHistory_ColorUterineAnyAgeCan1" data-val="Colorectal" class="filled-in-" />
                                                  <label for="pfHistory_ColorUterineAnyAgeCan1">Colorectal &nbsp; </label>
                                                </>
                                              )}

                                              {(this.state.retData.order.tests[0].clinicalData[6].subQuestion[0].answer.includes("Uterine")) && (
                                                <>
                                                  <input type="checkbox" id="pfHistory_ColorUterineAnyAgeCan2" checked name="pfHistory_ColorUterineAnyAgeCan2" data-val="Uterine" class="filled-in-" />
                                                  <label for="pfHistory_ColorUterineAnyAgeCan2">Uterine &nbsp; </label>
                                                </>
                                              )}
                                              {(!this.state.retData.order.tests[0].clinicalData[6].subQuestion[0].answer.includes("Uterine")) && (
                                                <>
                                                  <input type="checkbox" id="pfHistory_ColorUterineAnyAgeCan2" name="pfHistory_ColorUterineAnyAgeCan2" data-val="Uterine" class="filled-in-" />
                                                  <label for="pfHistory_ColorUterineAnyAgeCan2">Uterine &nbsp; </label>
                                                </>
                                              )}

                                            </>)}

                                            {(this.state.retData.order.tests[0].clinicalData[6].answer == 'false') && (<>

                                              <input type="checkbox" id="pfHistory_ColorUterineAnyAgeCan1" name="pfHistory_ColorUterineAnyAgeCan1" data-val="Colorectal" class="filled-in-" />
                                              <label for="pfHistory_ColorUterineAnyAgeCan1">Colorectal &nbsp; </label>
                                              <input type="checkbox" id="pfHistory_ColorUterineAnyAgeCan2" name="pfHistory_ColorUterineAnyAgeCan2" data-val="Uterine" class="filled-in-" />
                                              <label for="pfHistory_ColorUterineAnyAgeCan2">Uterine &nbsp; </label>

                                            </>)}
                                          </div>
                                          {/* <div class="form-line">
                                  <input type="text" value={this.state.retData.order.tests[0].clinicalData[6].subQuestion[0].answer} id="pfHistory_ColorUterineAnyAgeCan" class="form-control " placeholder="Which cancer?" />
                                </div> */}
                                        </div>
                                        <div class="col-md-3 text-right">
                                          <label>Age at diagnosis</label>
                                        </div>
                                        <div class="col-md-2">
                                          <div class="form-line">
                                            <input type="text" value={this.state.retData.order.tests[0].clinicalData[6].subQuestion[1].answer} id="pfHistory_ColorUterineAnyAgeInfo" class="form-control " placeholder="Age" />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>{/*  end hboc1_q07 */}
                            </div>
                          </div>
                        </div> {/*  end hboc1_q06 and hboc1_q07 */}

                        <div class="demo-checkbox" id="hboc1_q03">
                          <div class="row clearfix custom-min-height">
                            <div class="col-md-4">
                              {(this.state.retData.order.tests[0].clinicalData[2].answer == 'true') && (<input checked type="checkbox" id="pfHistory_previousCancer" name="pfHistory_previousCancer" class="filled-in-" />)}
                              {(this.state.retData.order.tests[0].clinicalData[2].answer == 'false') && (<input type="checkbox" id="pfHistory_previousCancer" name="pfHistory_previousCancer" class="filled-in-" />)}
                              <label for="pfHistory_previousCancer">Previous cancer diagnosis? If yes, Define:</label>
                            </div>
                            <div class="col-md-8">
                              <div class={(this.state.retData.order.tests[0].clinicalData[2].answer == 'true') ? "form-line showFormContent" : "form-line hideFormContent"} id="pfHistory_prevCanDefine_div">
                                <input type="text" value={this.state.retData.order.tests[0].clinicalData[2].subQuestion[0].answer} id="pfHistory_prevCanDefine" class="form-control " placeholder="Define" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="demo-checkbox" id="hboc1_q04">
                          <div class="row clearfix custom-min-height">
                            <div class="col-md-5">
                              {(this.state.retData.order.tests[0].clinicalData[3].answer == 'true') && (<input type="checkbox" checked id="pfHistory_SomaticMutationProf" name="pfHistory_SomaticMutationProf" class="filled-in-" />)}
                              {(this.state.retData.order.tests[0].clinicalData[3].answer == 'false') && (<input type="checkbox" id="pfHistory_SomaticMutationProf" name="pfHistory_SomaticMutationProf" class="filled-in-" />)}
                              <label for="pfHistory_SomaticMutationProf">Somatic mutation profiling? If yes, Define: (gene, variant identified) &nbsp; </label>
                            </div>
                            <div class="col-md-7">
                              <div class={(this.state.retData.order.tests[0].clinicalData[3].answer == 'true') ? "form-line showFormContent" : "form-line hideFormContent"} id="pfHistory_SoMutaProfDefine_div">
                                <input type="text" value={this.state.retData.order.tests[0].clinicalData[3].subQuestion[0].answer} id="pfHistory_SoMutaProfDefine" class="form-control " placeholder="Define" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="demo-checkbox" id="hboc1_q05">
                          <div class="row clearfix custom-min-height">
                            <div class="col-md-5">
                              {(this.state.retData.order.tests[0].clinicalData[4].answer == 'true') && (<input checked type="checkbox" id="pfHistory_PrevGermltesting" name="pfHistory_PrevGermltesting" class="filled-in-" />)}
                              {(this.state.retData.order.tests[0].clinicalData[4].answer == 'false') && (<input type="checkbox" id="pfHistory_PrevGermltesting" name="pfHistory_PrevGermltesting" class="filled-in-" />)}
                              <label for="pfHistory_PrevGermltesting">Previous germline testing? If yes, Define:(gene/varient detected) &nbsp; </label>
                            </div>
                            <div class="col-md-7">
                              <div class={(this.state.retData.order.tests[0].clinicalData[4].answer == 'true') ? "form-line showFormContent" : "form-line hideFormContent"} id="pfHistory_PrevGermltestDefine_div">
                                <input type="text" value={this.state.retData.order.tests[0].clinicalData[4].subQuestion[0].answer} id="pfHistory_PrevGermltestDefine" class="form-control " placeholder="Define" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="demo-checkbox" id="hboc1_q05_01_hboc1_q05_02">
                          <label for="">If yes, is the variant:&nbsp; </label>
                          {(this.state.retData.order.tests[0].clinicalData[4].subQuestion[1].answer.includes("Familial/Inherited or")) && (<input checked type="checkbox" data-val="Familial/Inherited or" id="pfHistory_Familial_Inherited" name="pfHistory_Familial_Inherited" class="filled-in-" />)}
                          {!(this.state.retData.order.tests[0].clinicalData[4].subQuestion[1].answer.includes("Familial/Inherited or")) && (<input type="checkbox" data-val="Familial/Inherited or" id="pfHistory_Familial_Inherited" name="pfHistory_Familial_Inherited" class="filled-in-" />)}
                          <label for="pfHistory_Familial_Inherited">Familial/Inherited or &nbsp; </label>

                          {(this.state.retData.order.tests[0].clinicalData[4].subQuestion[1].answer.includes("Newly-identified/De Novo")) && (<input checked type="checkbox" data-val="Newly-identified/De Novo" id="pfHistory_Newly_identified" name="pfHistory_Newly_identified" class="filled-in-" />)}
                          {!(this.state.retData.order.tests[0].clinicalData[4].subQuestion[1].answer.includes("Newly-identified/De Novo")) && (<input type="checkbox" data-val="Newly-identified/De Novo" id="pfHistory_Newly_identified" name="pfHistory_Newly_identified" class="filled-in-" />)}
                          <label for="pfHistory_Newly_identified">Newly-identified/De Novo &nbsp; </label>
                        </div>

                      </div>
                    </div>
                  </div>

                  <div class="row clearfix" >
                    <div class="col-md-12">
                      <div class="form-group ">
                        <h4>Family History</h4>
                        <label class="form-label hideFormContent" for="address">Family History (check all that apply)</label>
                        <div class="demo-checkbox hideFormContent" id="hboc1_q01">
                          {/* hboc1_q02 */}
                          {(this.state.retData.order.tests[0].clinicalData[1].answer == 'true') && (<input checked type="checkbox" id="pfHistory_yesCancer" name="pfHistory_yesCancer" class="filled-in-" />)}
                          {(this.state.retData.order.tests[0].clinicalData[1].answer == 'false') && (<input type="checkbox" id="pfHistory_yesCancer" name="pfHistory_yesCancer" class="filled-in-" />)}
                          <label for="pfHistory_yesCancer">Family history of breast, ovarian or endometrial cancer &nbsp; </label>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="row clearfix hideFormContent">
                    <div class="col-md-12">
                      <p>(Close relatives include: parent, sibling, child, uncle, aunt, great uncle, great aunt, nephew, niece, grandparent, grandchild or half-sibling)</p>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div class="form-group ">
                        <label class="form-label hideFormContent" for="address">Does the Patient have a family history of:</label>
                        <div class="demo-radio-button " id="hboc1_q08">
                          <div class="row clearfix custom-min-height">
                            <div class="col-md-4">
                              <label>One breast cancer in one relative at any age</label>
                            </div>
                            <div class="col-md-2">
                              {(this.state.retData.order.tests[0].clinicalData[7].answer == 'true') && (<>
                                <input type="radio" checked id="pfHistory_FBrOvPanAnyAge" name="pfHistory_FBrOvPanAnyAge_" value="yes" class="form-control" />
                                <label class="form-label" for="pfHistory_FBrOvPanAnyAge">Yes</label>
                                <input type="radio" id="pfHistory_FBrOvPanAnyAge2" name="pfHistory_FBrOvPanAnyAge_" value="no" class="form-control" />
                                <label class="form-label" for="pfHistory_FBrOvPanAnyAge2">No</label>
                              </>)}
                              {(this.state.retData.order.tests[0].clinicalData[7].answer == 'false') && (<>
                                <input type="radio" id="pfHistory_FBrOvPanAnyAge" name="pfHistory_FBrOvPanAnyAge_" value="yes" class="form-control" />
                                <label class="form-label" for="pfHistory_FBrOvPanAnyAge">Yes</label>
                                <input type="radio" checked id="pfHistory_FBrOvPanAnyAge2" name="pfHistory_FBrOvPanAnyAge_" value="no" class="form-control" />
                                <label class="form-label" for="pfHistory_FBrOvPanAnyAge2">No</label>
                              </>)}
                            </div>
                            <div class="col-md-6 custom-margin-bottom-clinicalp">
                              {(this.state.retData.order.tests[0].clinicalData[7].answer == 'true') && (<>

                                {(this.state.retData.order.tests[0].clinicalData[7].hBOCSubQuestions[0].answer.length > 0) && (<>

                                  {this.state.retData.order.tests[0].clinicalData[7].hBOCSubQuestions[0].answer.map((hBOCSubQuestions, i) => {
                                    return <>
                                      <p class="clinicalp">{i + 1}. Side: {hBOCSubQuestions.side} Relative: {hBOCSubQuestions.relative} Age: {hBOCSubQuestions.age}</p>
                                    </>
                                  })
                                  }
                                </>)}

                              </>)}

                            </div>
                          </div>
                        </div>

                        <div class="demo-radio-button" id="hboc1_q09">
                          <div class="row clearfix custom-min-height">
                            <div class="col-md-4">
                              <label>Two breast cancers (bilateral) in one relative at any age</label>
                            </div>
                            <div class="col-md-2">
                              {(this.state.retData.order.tests[0].clinicalData[8].answer == 'true') && (<>
                                <input type="radio" checked id="pfHistory_FTwoBreasCanAge" name="pfHistory_FTwoBreasCanAge_" value="yes" class="form-control" />
                                <label class="form-label" for="pfHistory_FTwoBreasCanAge">Yes</label>
                                <input type="radio" id="pfHistory_FTwoBreasCanAge2" name="pfHistory_FTwoBreasCanAge_" value="no" class="form-control" />
                                <label class="form-label" for="pfHistory_FTwoBreasCanAge2">No</label>
                              </>)}
                              {(this.state.retData.order.tests[0].clinicalData[8].answer == 'false') && (<>
                                <input type="radio" id="pfHistory_FTwoBreasCanAge" name="pfHistory_FTwoBreasCanAge_" value="yes" class="form-control" />
                                <label class="form-label" for="pfHistory_FTwoBreasCanAge">Yes</label>
                                <input type="radio" checked id="pfHistory_FTwoBreasCanAge2" name="pfHistory_FTwoBreasCanAge_" value="no" class="form-control" />
                                <label class="form-label" for="pfHistory_FTwoBreasCanAge2">No</label>
                              </>)}
                            </div>
                            <div class="col-md-6 custom-margin-bottom-clinicalp">
                              {(this.state.retData.order.tests[0].clinicalData[8].answer == 'true') && (<>

                                {(this.state.retData.order.tests[0].clinicalData[8].hBOCSubQuestions[0].answer.length > 0) && (<>

                                  {this.state.retData.order.tests[0].clinicalData[8].hBOCSubQuestions[0].answer.map((hBOCSubQuestions, i) => {
                                    return <>
                                      <p class="clinicalp">{i + 1}. Side: {hBOCSubQuestions.side} Relative: {hBOCSubQuestions.relative} Age: {hBOCSubQuestions.age}</p>
                                    </>
                                  })
                                  }
                                </>)}

                              </>)}
                            </div>
                          </div>
                        </div>

                        <div class="demo-radio-button" id="hboc1_q010">
                          <div class="row clearfix custom-min-height">
                            <div class="col-md-4">
                              <label>Three breast cancers in relatives on the same  side of the family at any age</label>
                            </div>
                            <div class="col-md-2">
                              {(this.state.retData.order.tests[0].clinicalData[9].answer == 'true') && (<>
                                <input type="radio" checked id="pfHistory_FThrBreasCanAge" name="pfHistory_FThrBreasCanAge_" value="yes" class="form-control" />
                                <label class="form-label" for="pfHistory_FThrBreasCanAge">Yes</label>
                                <input type="radio" id="pfHistory_FThrBreasCanAge2" name="pfHistory_FThrBreasCanAge_" value="no" class="form-control" />
                                <label class="form-label" for="pfHistory_FThrBreasCanAge2">No</label>
                              </>)}
                              {(this.state.retData.order.tests[0].clinicalData[9].answer == 'false') && (<>
                                <input type="radio" id="pfHistory_FThrBreasCanAge" name="pfHistory_FThrBreasCanAge_" value="yes" class="form-control" />
                                <label class="form-label" for="pfHistory_FThrBreasCanAge">Yes</label>
                                <input type="radio" checked id="pfHistory_FThrBreasCanAge2" name="pfHistory_FThrBreasCanAge_" value="no" class="form-control" />
                                <label class="form-label" for="pfHistory_FThrBreasCanAge2">No</label>
                              </>)}
                            </div>
                            <div class="col-md-6 custom-margin-bottom-clinicalp">
                              {(this.state.retData.order.tests[0].clinicalData[9].answer == 'true') && (<>

                                {(this.state.retData.order.tests[0].clinicalData[9].hBOCSubQuestions[0].answer.length > 0) && (<>

                                  {this.state.retData.order.tests[0].clinicalData[9].hBOCSubQuestions[0].answer.map((hBOCSubQuestions, i) => {
                                    return <>
                                      <p class="clinicalp">{i + 1}. Side: {hBOCSubQuestions.side} Relative: {hBOCSubQuestions.relative} Age: {hBOCSubQuestions.age}</p>
                                    </>
                                  })
                                  }
                                </>)}

                              </>)}
                            </div>
                          </div>
                        </div>

                        <div class="demo-radio-button" id="hboc1_q011">
                          <div class="row clearfix custom-min-height">
                            <div class="col-md-4">
                              <label>Ovarian cancer at any age</label>
                            </div>
                            <div class="col-md-2">
                              {(this.state.retData.order.tests[0].clinicalData[10].answer == 'true') && (<>
                                <input type="radio" checked id="pfHistory_OvarianCanAge" name="pfHistory_OvarianCanAge_" value="yes" class="form-control" />
                                <label class="form-label" for="pfHistory_OvarianCanAge">Yes</label>
                                <input type="radio" id="pfHistory_OvarianCanAge2" name="pfHistory_OvarianCanAge_" value="no" class="form-control" />
                                <label class="form-label" for="pfHistory_OvarianCanAge2">No</label>
                              </>)}
                              {(this.state.retData.order.tests[0].clinicalData[10].answer == 'false') && (<>
                                <input type="radio" id="pfHistory_OvarianCanAge" name="pfHistory_OvarianCanAge_" value="yes" class="form-control" />
                                <label class="form-label" for="pfHistory_OvarianCanAge">Yes</label>
                                <input type="radio" checked id="pfHistory_OvarianCanAge2" name="pfHistory_OvarianCanAge_" value="no" class="form-control" />
                                <label class="form-label" for="pfHistory_OvarianCanAge2">No</label>
                              </>)}
                            </div>
                            <div class="col-md-6 custom-margin-bottom-clinicalp">
                              {(this.state.retData.order.tests[0].clinicalData[10].answer == 'true') && (<>

                                {(this.state.retData.order.tests[0].clinicalData[10].hBOCSubQuestions[0].answer.length > 0) && (<>

                                  {this.state.retData.order.tests[0].clinicalData[10].hBOCSubQuestions[0].answer.map((hBOCSubQuestions, i) => {
                                    return <>
                                      <p class="clinicalp">{i + 1}. Side: {hBOCSubQuestions.side} Relative: {hBOCSubQuestions.relative} Age: {hBOCSubQuestions.age}</p>
                                    </>
                                  })
                                  }
                                </>)}

                              </>)}
                            </div>
                          </div>
                        </div>

                        <div class="demo-radio-button" id="hboc1_q012">
                          <div class="row clearfix custom-min-height">
                            <div class="col-md-4">
                              <label>Pancreatic cancer at any age</label>
                            </div>
                            <div class="col-md-2">
                              {(this.state.retData.order.tests[0].clinicalData[11].answer == 'true') && (<>
                                <input type="radio" checked id="pfHistory_PancreaticCanAge" name="pfHistory_PancreaticCanAge_" value="yes" class="form-control" />
                                <label class="form-label" for="pfHistory_PancreaticCanAge">Yes</label>
                                <input type="radio" id="pfHistory_PancreaticCanAge2" name="pfHistory_PancreaticCanAge_" value="no" class="form-control" />
                                <label class="form-label" for="pfHistory_PancreaticCanAge2">No</label>
                              </>)}
                              {(this.state.retData.order.tests[0].clinicalData[11].answer == 'false') && (<>
                                <input type="radio" id="pfHistory_PancreaticCanAge" name="pfHistory_PancreaticCanAge_" value="yes" class="form-control" />
                                <label class="form-label" for="pfHistory_PancreaticCanAge">Yes</label>
                                <input type="radio" checked id="pfHistory_PancreaticCanAge2" name="pfHistory_PancreaticCanAge_" value="no" class="form-control" />
                                <label class="form-label" for="pfHistory_PancreaticCanAge2">No</label>
                              </>)}
                            </div>
                            <div class="col-md-6 custom-margin-bottom-clinicalp">
                              {(this.state.retData.order.tests[0].clinicalData[11].answer == 'true') && (<>

                                {(this.state.retData.order.tests[0].clinicalData[11].hBOCSubQuestions[0].answer.length > 0) && (<>

                                  {this.state.retData.order.tests[0].clinicalData[11].hBOCSubQuestions[0].answer.map((hBOCSubQuestions, i) => {
                                    return <>
                                      <p class="clinicalp">{i + 1}. Side: {hBOCSubQuestions.side} Relative: {hBOCSubQuestions.relative} Age: {hBOCSubQuestions.age}</p>
                                    </>
                                  })
                                  }
                                </>)}

                              </>)}
                            </div>
                          </div>
                        </div>

                        <div class="demo-radio-button" id="hboc1_q013">
                          <div class="row clearfix custom-min-height">
                            <div class="col-md-4">
                              <label>Male breast cancer at any age</label>
                            </div>
                            <div class="col-md-2">
                              {(this.state.retData.order.tests[0].clinicalData[12].answer == 'true') && (<>
                                <input type="radio" checked id="pfHistory_MaleBreastCanAge" name="pfHistory_MaleBreastCanAge_" value="yes" class="form-control" />
                                <label class="form-label" for="pfHistory_MaleBreastCanAge">Yes</label>
                                <input type="radio" id="pfHistory_MaleBreastCanAge2" name="pfHistory_MaleBreastCanAge_" value="no" class="form-control" />
                                <label class="form-label" for="pfHistory_MaleBreastCanAge2">No</label>
                              </>)}
                              {(this.state.retData.order.tests[0].clinicalData[12].answer == 'false') && (<>
                                <input type="radio" id="pfHistory_MaleBreastCanAge" name="pfHistory_MaleBreastCanAge_" value="yes" class="form-control" />
                                <label class="form-label" for="pfHistory_MaleBreastCanAge">Yes</label>
                                <input type="radio" checked id="pfHistory_MaleBreastCanAge2" name="pfHistory_MaleBreastCanAge_" value="no" class="form-control" />
                                <label class="form-label" for="pfHistory_MaleBreastCanAge2">No</label>
                              </>)}
                            </div>
                            <div class="col-md-6 custom-margin-bottom-clinicalp">
                              {(this.state.retData.order.tests[0].clinicalData[12].answer == 'true') && (<>

                                {(this.state.retData.order.tests[0].clinicalData[12].hBOCSubQuestions[0].answer.length > 0) && (<>

                                  {this.state.retData.order.tests[0].clinicalData[12].hBOCSubQuestions[0].answer.map((hBOCSubQuestions, i) => {
                                    return <>
                                      <p class="clinicalp">{i + 1}. Side: {hBOCSubQuestions.side} Relative: {hBOCSubQuestions.relative} Age: {hBOCSubQuestions.age}</p>
                                    </>
                                  })
                                  }
                                </>)}

                              </>)}
                            </div>
                          </div>
                        </div>

                        <div class="demo-radio-button" id="hboc1_q014">
                          <div class="row clearfix custom-min-height">
                            <div class="col-md-4">
                              <label>Metastatic prostate cancer at any age</label>
                            </div>
                            <div class="col-md-2">
                              {(this.state.retData.order.tests[0].clinicalData[13].answer == 'true') && (<>
                                <input type="radio" checked id="pfHistory_MetaProstateCanAge" name="pfHistory_MetaProstateCanAge_" value="yes" class="form-control" />
                                <label class="form-label" for="pfHistory_MetaProstateCanAge">Yes</label>
                                <input type="radio" id="pfHistory_MetaProstateCanAge2" name="pfHistory_MetaProstateCanAge_" value="no" class="form-control" />
                                <label class="form-label" for="pfHistory_MetaProstateCanAge2">No</label>
                              </>)}
                              {(this.state.retData.order.tests[0].clinicalData[13].answer == 'false') && (<>
                                <input type="radio" id="pfHistory_MetaProstateCanAge" name="pfHistory_MetaProstateCanAge_" value="yes" class="form-control" />
                                <label class="form-label" for="pfHistory_MetaProstateCanAge">Yes</label>
                                <input type="radio" checked id="pfHistory_MetaProstateCanAge2" name="pfHistory_MetaProstateCanAge_" value="no" class="form-control" />
                                <label class="form-label" for="pfHistory_MetaProstateCanAge2">No</label>
                              </>)}
                            </div>
                            <div class="col-md-6 custom-margin-bottom-clinicalp">
                              {(this.state.retData.order.tests[0].clinicalData[13].answer == 'true') && (<>

                                {(this.state.retData.order.tests[0].clinicalData[13].hBOCSubQuestions[0].answer.length > 0) && (<>

                                  {this.state.retData.order.tests[0].clinicalData[13].hBOCSubQuestions[0].answer.map((hBOCSubQuestions, i) => {
                                    return <>
                                      <p class="clinicalp">{i + 1}. Side: {hBOCSubQuestions.side} Relative: {hBOCSubQuestions.relative} Age: {hBOCSubQuestions.age}</p>
                                    </>
                                  })
                                  }
                                </>)}

                              </>)}
                            </div>
                          </div>
                        </div>

                        <div class="demo-radio-button" id="hboc1_q015">
                          <div class="row clearfix custom-min-height">
                            <div class="col-md-4">
                              <label>Colon cancer at 49 or younger</label>
                            </div>
                            <div class="col-md-2">
                              {(this.state.retData.order.tests[0].clinicalData[14].answer == 'true') && (<>
                                <input type="radio" checked id="pfHistory_ColorCanAge" name="pfHistory_ColorCanAge_" value="yes" class="form-control" />
                                <label class="form-label" for="pfHistory_ColorCanAge">Yes</label>
                                <input type="radio" id="pfHistory_ColorCanAge2" name="pfHistory_ColorCanAge_" value="no" class="form-control" />
                                <label class="form-label" for="pfHistory_ColorCanAge2">No</label>
                              </>)}
                              {(this.state.retData.order.tests[0].clinicalData[14].answer == 'false') && (<>
                                <input type="radio" id="pfHistory_ColorCanAge" name="pfHistory_ColorCanAge_" value="yes" class="form-control" />
                                <label class="form-label" for="pfHistory_ColorCanAge">Yes</label>
                                <input type="radio" checked id="pfHistory_ColorCanAge2" name="pfHistory_ColorCanAge_" value="no" class="form-control" />
                                <label class="form-label" for="pfHistory_ColorCanAge2">No</label>
                              </>)}
                            </div>
                            <div class="col-md-6 custom-margin-bottom-clinicalp">
                              {(this.state.retData.order.tests[0].clinicalData[14].answer == 'true') && (<>

                                {(this.state.retData.order.tests[0].clinicalData[14].hBOCSubQuestions[0].answer.length > 0) && (<>

                                  {this.state.retData.order.tests[0].clinicalData[14].hBOCSubQuestions[0].answer.map((hBOCSubQuestions, i) => {
                                    return <>
                                      <p class="clinicalp">{i + 1}. Side: {hBOCSubQuestions.side} Relative: {hBOCSubQuestions.relative} Age: {hBOCSubQuestions.age}</p>
                                    </>
                                  })
                                  }
                                </>)}

                              </>)}
                            </div>
                          </div>
                        </div>

                        <div class="demo-radio-button" id="hboc1_q016">
                          <div class="row clearfix custom-min-height">
                            <div class="col-md-4">
                              <label>Uterine cancer at 49 or younger</label>
                            </div>
                            <div class="col-md-2">
                              {(this.state.retData.order.tests[0].clinicalData[15].answer == 'true') && (<>
                                <input type="radio" checked id="pfHistory_UterineCanAge" name="pfHistory_UterineCanAge_" value="yes" class="form-control" />
                                <label class="form-label" for="pfHistory_UterineCanAge">Yes</label>
                                <input type="radio" id="pfHistory_UterineCanAge2" name="pfHistory_UterineCanAge_" value="no" class="form-control" />
                                <label class="form-label" for="pfHistory_UterineCanAge2">No</label>
                              </>)}
                              {(this.state.retData.order.tests[0].clinicalData[15].answer == 'false') && (<>
                                <input type="radio" id="pfHistory_UterineCanAge" name="pfHistory_UterineCanAge_" value="yes" class="form-control" />
                                <label class="form-label" for="pfHistory_UterineCanAge">Yes</label>
                                <input type="radio" checked id="pfHistory_UterineCanAge2" name="pfHistory_UterineCanAge_" value="no" class="form-control" />
                                <label class="form-label" for="pfHistory_UterineCanAge2">No</label>
                              </>)}
                            </div>
                            <div class="col-md-6 custom-margin-bottom-clinicalp">
                              {(this.state.retData.order.tests[0].clinicalData[15].answer == 'true') && (<>

                                {(this.state.retData.order.tests[0].clinicalData[15].hBOCSubQuestions[0].answer.length > 0) && (<>

                                  {this.state.retData.order.tests[0].clinicalData[15].hBOCSubQuestions[0].answer.map((hBOCSubQuestions, i) => {
                                    return <>
                                      <p class="clinicalp">{i + 1}. Side: {hBOCSubQuestions.side} Relative: {hBOCSubQuestions.relative} Age: {hBOCSubQuestions.age}</p>
                                    </>
                                  })
                                  }
                                </>)}

                              </>)}
                            </div>
                          </div>
                        </div>
                        <div class="demo-radio-button" id="hboc1_q017">
                          <div class="row clearfix custom-min-height">
                            <div class="col-md-4">
                              <label>Ashkenazi Jewish ancestry with breast cancer at any age</label>
                            </div>
                            <div class="col-md-2">
                              {(this.state.retData.order.tests[0].clinicalData[16].answer == 'true') && (<>
                                <input type="radio" checked id="pfHistory_AshJewishAncesCanAge" name="pfHistory_AshJewishAncesCanAge_" value="yes" class="form-control" />
                                <label class="form-label" for="pfHistory_AshJewishAncesCanAge">Yes</label>
                                <input type="radio" id="pfHistory_AshJewishAncesCanAge2" name="pfHistory_AshJewishAncesCanAge_" value="no" class="form-control" />
                                <label class="form-label" for="pfHistory_AshJewishAncesCanAge2">No</label>
                              </>)}
                              {(this.state.retData.order.tests[0].clinicalData[16].answer == 'false') && (<>
                                <input type="radio" id="pfHistory_AshJewishAncesCanAge" name="pfHistory_AshJewishAncesCanAge_" value="yes" class="form-control" />
                                <label class="form-label" for="pfHistory_AshJewishAncesCanAge">Yes</label>
                                <input type="radio" checked id="pfHistory_AshJewishAncesCanAge2" name="pfHistory_AshJewishAncesCanAge_" value="no" class="form-control" />
                                <label class="form-label" for="pfHistory_AshJewishAncesCanAge2">No</label>
                              </>)}
                            </div>
                            <div class="col-md-6 custom-margin-bottom-clinicalp">
                              {(this.state.retData.order.tests[0].clinicalData[16].answer == 'true') && (<>

                                {(this.state.retData.order.tests[0].clinicalData[16].hBOCSubQuestions[0].answer.length > 0) && (<>

                                  {this.state.retData.order.tests[0].clinicalData[16].hBOCSubQuestions[0].answer.map((hBOCSubQuestions, i) => {
                                    return <>
                                      <p class="clinicalp">{i + 1}. Side: {hBOCSubQuestions.side} Relative: {hBOCSubQuestions.relative} Age: {hBOCSubQuestions.age}</p>
                                    </>
                                  })
                                  }
                                </>)}

                              </>)}
                            </div>
                          </div>
                        </div>

                        <div class="demo-radio-button" id="hboc1_q018">
                          <div class="row clearfix custom-min-height">
                            <div class="col-md-4">
                              <label>Does the Patient have a family history of other cancers?</label>
                            </div>
                            <div class="col-md-3">
                              {(this.state.retData.order.tests[0].clinicalData[17].answer == 'true') && (<>
                                <input type="radio" checked id="pfHistory_otherCan" name="pfHistory_otherCan_" value="yes" class="form-control" />
                                <label class="form-label" for="pfHistory_otherCan">Yes</label>
                                <input type="radio" id="pfHistory_otherCan2" name="pfHistory_otherCan_" value="no" class="form-control" />
                                <label class="form-label" for="pfHistory_otherCan2">No If yes, please list:</label>
                              </>)}
                              {(this.state.retData.order.tests[0].clinicalData[17].answer == 'false') && (<>
                                <input type="radio" id="pfHistory_otherCan" name="pfHistory_otherCan_" value="yes" class="form-control" />
                                <label class="form-label" for="pfHistory_otherCan">Yes</label>
                                <input type="radio" checked id="pfHistory_otherCan2" name="pfHistory_otherCan_" value="no" class="form-control" />
                                <label class="form-label" for="pfHistory_otherCan2">No If yes, please list:</label>
                              </>)}
                            </div>
                            <div class="col-md-5">
                              <div className={(this.state.retData.order.tests[0].clinicalData[17].answer == 'true') ? " showFormContent" : " hideFormContent"} id="pfHistory_otherCan_Div">
                                <div class="row clearfix">
                                  <div class="col-md-12">
                                    <div class="form-line">
                                      <input type="text" value={this.state.retData.order.tests[0].clinicalData[17].subQuestion[0].answer} id="pfHistory_otherCan_info" class="form-control " placeholder="please list" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="demo-radio-button" id="hboc1_q019">
                          <div class="row clearfix custom-min-height">
                            <div class="col-md-4">
                              <label>Has anyone in the Patient's family had genetic testing for hereditary cancer?</label>
                            </div>
                            <div class="col-md-5">
                              {(this.state.retData.order.tests[0].clinicalData[18].answer == 'true') && (<>
                                <input type="radio" checked id="pfHistory_geHerediraryCan" name="pfHistory_geHerediraryCan_" value="yes" class="form-control" />
                                <label class="form-label" for="pfHistory_geHerediraryCan">Yes</label>
                                <input type="radio" id="pfHistory_geHerediraryCan2" name="pfHistory_geHerediraryCan_" value="no" class="form-control" />
                                <label class="form-label" for="pfHistory_geHerediraryCan2">No If yes, please list: Who, what genes and results (if known):</label>
                              </>)}
                              {(this.state.retData.order.tests[0].clinicalData[18].answer == 'false') && (<>
                                <input type="radio" id="pfHistory_geHerediraryCan" name="pfHistory_geHerediraryCan_" value="yes" class="form-control" />
                                <label class="form-label" for="pfHistory_geHerediraryCan">Yes</label>
                                <input type="radio" checked id="pfHistory_geHerediraryCan2" name="pfHistory_geHerediraryCan_" value="no" class="form-control" />
                                <label class="form-label" for="pfHistory_geHerediraryCan2">No If yes, please list: Who, what genes and results (if known):</label>
                              </>)}
                            </div>
                            <div class="col-md-3">
                              <div className={(this.state.retData.order.tests[0].clinicalData[18].answer == 'true') ? " showFormContent" : " hideFormContent"} id="pfHistory_geHerediraryCan_Div">
                                <div class="row clearfix">
                                  <div class="col-md-12">
                                    <div class="form-line">
                                      <input type="text" value={this.state.retData.order.tests[0].clinicalData[18].subQuestion[0].answer} id="pfHistory_geHerediraryCan_info" class="form-control " placeholder="please list" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="demo-radio-button" id="hboc1_q020">
                          <div class="row clearfix">
                            <div class="col-md-12">
                              <label>Other Clinical History:</label>
                              <div class="form-line">
                                <input type="text" value={this.state.retData.order.tests[0].clinicalData[19].answer} id="pfHistory_OtherClinicalHistory" class="form-control " placeholder="Other Clinical History" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div class="form-group ">
                        <label class="form-label" for="address">Previous or current treatment</label>
                        <div class="demo-checkbox" id="hboc1_q021-hboc1_q024">
                          {(this.state.retData.order.tests[0].clinicalData[20].answer == 'true') && (<input checked type="checkbox" id="pfHistory_PCurTreat_boneMar" name="pfHistory_PCurTreat_boneMar" class="filled-in-" />)}
                          {(this.state.retData.order.tests[0].clinicalData[20].answer == 'false') && (<input type="checkbox" id="pfHistory_PCurTreat_boneMar" name="pfHistory_PCurTreat_boneMar" class="filled-in-" />)}
                          <label for="pfHistory_PCurTreat_boneMar"> Allogeneic bone marrow or peripheral stem cell transplant <sup>*</sup> &nbsp; </label>

                          {(this.state.retData.order.tests[0].clinicalData[21].answer == 'true') && (<input checked type="checkbox" id="pfHistory_PCurTreat_Chemo" name="pfHistory_PCurTreat_Chemo" class="filled-in-" />)}
                          {(this.state.retData.order.tests[0].clinicalData[21].answer == 'false') && (<input type="checkbox" id="pfHistory_PCurTreat_Chemo" name="pfHistory_PCurTreat_Chemo" class="filled-in-" />)}
                          <label for="pfHistory_PCurTreat_Chemo"> Chemotherapy<sup>*</sup> &nbsp; </label>

                          {(this.state.retData.order.tests[0].clinicalData[22].answer == 'true') && (<input checked type="checkbox" id="pfHistory_PCurTreat_Radia" name="pfHistory_PCurTreat_Radia" class="filled-in-" />)}
                          {(this.state.retData.order.tests[0].clinicalData[22].answer == 'false') && (<input type="checkbox" id="pfHistory_PCurTreat_Radia" name="pfHistory_PCurTreat_Radia" class="filled-in-" />)}
                          <label for="pfHistory_PCurTreat_Radia"> Radiation &nbsp; </label>

                          {(this.state.retData.order.tests[0].clinicalData[23].answer == 'true') && (<input checked type="checkbox" id="pfHistory_PCurTreat_Surgery" name="pfHistory_PCurTreat_Surgery" class="filled-in-" />)}
                          {(this.state.retData.order.tests[0].clinicalData[23].answer == 'false') && (<input type="checkbox" id="pfHistory_PCurTreat_Surgery" name="pfHistory_PCurTreat_Surgery" class="filled-in-" />)}
                          <label for="pfHistory_PCurTreat_Surgery"> Surgery, &nbsp; </label>
                        </div>
                        <div class="form-line" id="hboc1_q025">
                          <input type="text" value={this.state.retData.order.tests[0].clinicalData[24].answer} id="pfHistory_PCurTreat_SurgDe" class="form-control " placeholder="specify" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-12">
                      <p><sup>*</sup>DNA analysis from blood samples of individuals who have undergone stem cell transplants, bone marrow transplants, or chemotheray may not reflect the germline genotype. Similarly, DNA analysis from blood samples of individuals with hematologic malignancy may not distinguish between somatic and germline variants</p>
                    </div>
                  </div>


                  {/* HBOC Personal/Family History -- Genetix HBOC end */}

                  {/* HBOC Kit Shipping Information */}
                  <div class="row clearfix">
                    <div class="col-md-12"><div class="bg-div"></div><h4 class="panel-titlepdf1">Kit Shipping Information</h4></div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div class="form-group required">
                        <div class="row clearfix">
                          <div class="col-md-12">
                            <p>Label the EDTA tube with patient name and DOB. Place the EDTA tube into the biohazard bag. (If Saliva sample, place Saliva samples directly into the biohazard bag.) Print this form (after you submit) and fold into quarters and place in the side pocket of the biohazard bag. Remove the lid from the shipping box. Place the biohazard bag with the EDTA or saliva tube in the box. Close kit box and place into the FedEx Bag. Affix the shipping label to the outside of the FedEx Bag in the designated area. Contact FedEx online or call 1-800-463-3339 to schedule a pick up. Kits may also be dropped off at a FedEx shipping location.</p>
                          </div>
                        </div>

                      </div>
                    </div>

                  </div>
                  {/* HBOC Kit Shipping Information end */}

                  {/* HBOC Kit Notes & Certify */}
                  <div class="row clearfix">
                    <div class="col-md-12"><div class="bg-div"></div><h4 class="panel-titlepdf1">Notes & Certify</h4></div>
                  </div>
                  <div class="row clearfix">
                    <div className="col-md-6">
                      <div class="row clearfix">
                        <div class="col-md-12">
                          <div class="form-group ">
                            <label class="form-label" for="address">Notes</label>
                            <div class="form-line">
                              <textarea id="kitShip_notes" value={this.state.retData.order.tests[0].notes} class="form-control no-resize auto-growth" placeholder="Notes" rows="3"></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row clearfix">
                        <div class="col-md-12">
                          <p><strong> Please note this page is for healthcare professionals. If you are a patient and would like access to one of our tests, please contact your healthcare provider. </strong></p>
                        </div>
                      </div>
                      <div className="row clearfix">
                        <div className="col-md-12">
                          <div className="form-group required">
                            {this.renderResearchConsentCheckbox()}
                          </div>
                          <div className="form-group required">
                            {this.renderResearchUseOnlyCheckbox()}
                          </div>
                        </div>
                      </div>
                      <div class="row clearfix">
                        <div class="col-md-12">
                          <div class="form-group required">
                            <label class="form-label" for="address">I certify that I am:</label>
                            <div class="demo-radio-button" id="certifytype">
                              {(this.state.retData.order.certifyTypes) && (<>
                                {(this.state.retData.order.certifyTypes.includes("A healthcare provider")) && (<input checked type="radio" data-val="A healthcare provider" id="kitShip_healthcareProvider" name="kitShip_healthcareProvider" class="form-control" />)}
                                {!(this.state.retData.order.certifyTypes.includes("A healthcare provider")) && (<input type="radio" data-val="A healthcare provider" id="kitShip_healthcareProvider" name="kitShip_healthcareProvider" class="form-control" />)}
                                <label for="kitShip_healthcareProvider">A healthcare provider &nbsp; </label>

                                {(this.state.retData.order.certifyTypes.includes("An Aspira employee ordering on behalf of a healthcare provider")) && (<input type="radio" checked data-val="An Aspira employee ordering on behalf of a healthcare provider" id="kitShip_employeehealthcareProvider" name="kitShip_healthcareProvider" class="form-control" />)}
                                {!(this.state.retData.order.certifyTypes.includes("An Aspira employee ordering on behalf of a healthcare provider")) && (<input type="radio" data-val="An Aspira employee ordering on behalf of a healthcare provider" id="kitShip_employeehealthcareProvider" name="kitShip_healthcareProvider" class="form-control" />)}
                                <label for="kitShip_employeehealthcareProvider">An Aspira employee ordering on behalf of a healthcare provider &nbsp; </label>

                                {(this.state.retData.order.certifyTypes.includes("An Aspira partner ordering on behalf of a healthcare provider")) && (<input type="radio" checked data-val="An Aspira partner ordering on behalf of a healthcare provider" id="kitShip_partnerhealthcareProvider" name="kitShip_healthcareProvider" class="form-control" />)}
                                {!(this.state.retData.order.certifyTypes.includes("An Aspira partner ordering on behalf of a healthcare provider")) && (<input type="radio" data-val="An Aspira partner ordering on behalf of a healthcare provider" id="kitShip_partnerhealthcareProvider" name="kitShip_healthcareProvider" class="form-control" />)}
                                <label for="kitShip_partnerhealthcareProvider">An Aspira partner ordering on behalf of a healthcare provider &nbsp; </label>
                              </>)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div class="form-group">
                        <label class="form-label" for="Signature_Pad">Your Signature </label>
                        <div>
                          <div>
                            {this.state.trimmedDataURL
                              ?
                              <>
                                <img src={`${this.state.trimmedDataURL}`} />
                              </>
                              : null
                            }
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  {(this.state.retData.order.isTestOrder == true) &&
                    (<div>
                      <input type="radio" checked class=" filled-in" />
                      <label >Test Order &nbsp; </label>
                    </div>)}
                  {/* HBOC Kit Notes & Certify end */}

                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

    }
  }
}