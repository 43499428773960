import React from "react";
import helper from "../../utils/helper";

import DatePicker from "react-datepicker";

import AsyncSelect from "react-select/async";

const Billing_Information = (props) => {
  console.log('sm props.state: ', props.state)
  console.log("privateInsurance from billing");
  console.log(props.state.privateInsurance);
  console.log(props.state.medicare);
  console.log(props.state.patientSelfPay);
  console.log(props.state.medicaid);
  console.log(props.state.orderingFacility);
  var bprivateInsurance = props.state.privateInsurance;
  var bmedicare = props.state.medicare;
  var bpatientSelfPay = props.state.patientSelfPay;
  var bmedicaid = props.state.medicaid;
  var bfreeTrial = props.state.freeTrial;
  var borderingFacility = props.state.orderingFacility;


  const onChangeBillinfoCDob = (date) => {
    this.setState({ billinfoC_dob: date });
  };
  const BillingInformationPrivateInsuranceClick = (val) => {
    props.me.setState({
      privateInsurance: val,
      patientSelfPay: false,
      medicaid: false,
      orderingFacility: false,
      freeTrial: false,
    });

    bprivateInsurance = val;
    bpatientSelfPay = false;
    bmedicaid = false;
    borderingFacility = false;
    bfreeTrial = false;
  };

  const BillingInformationMedicareClick = (val) => {
    props.me.setState({
      medicare: val,
      patientSelfPay: false,
      medicaid: false,
      orderingFacility: false,
      freeTrial: false,
    });
    bmedicare = val;
    bpatientSelfPay = false;
    bmedicaid = false;
    borderingFacility = false;
    bfreeTrial = false;
  };
  const BillingInformationPatientSelfPayClick = (val) => {
    props.me.setState({
      privateInsurance: false,
      medicare: false,
      patientSelfPay: val,
      medicaid: false,
      orderingFacility: false,
      freeTrial: false,
    });
    bprivateInsurance = false;
    bmedicare = false;
    bpatientSelfPay = val;
    bmedicaid = false;
    borderingFacility = false;
    bfreeTrial = false;
  };
  const BillingInformationMedicaidClick = (val) => {
    props.me.setState({
      patientSelfPay: false,
      privateInsurance: false,
      medicare: false,
      medicaid: val,
      orderingFacility: false,
      freeTrial: false,
    });
    bprivateInsurance = false;
    bpatientSelfPay = false;
    bmedicaid = val;
    borderingFacility = false;
    bfreeTrial = false;
    bmedicare = false;
  };

  const BillingInformationFreeTrialClick = (val) => {
    props.me.setState({
      patientSelfPay: false,
      privateInsurance: false,
      medicare: false,
      medicaid: false,
      orderingFacility: false,
      freeTrial: val,
    });
    bprivateInsurance = false;
    bpatientSelfPay = false;
    bmedicaid = false;
    borderingFacility = false;
    bfreeTrial = val;
    bmedicare = false;
  };

  const BillingInformationOrderingFacilityClick = (val) => {
    props.me.setState({
      patientSelfPay: false,
      privateInsurance: false,
      medicare: false,
      medicaid: false,
      orderingFacility: val,
      freeTrial: false,
    });
    bprivateInsurance = false;
    bpatientSelfPay = false;
    bmedicaid = false;
    borderingFacility = val;
    bfreeTrial = false;
    bmedicare = false;
  };
  const updatePrimaryInsuranceCarrierAndSelectedPayor = (inputPayor) => {
    console.log("8888888888888888888888888*************************");
    console.log(inputPayor.value);

    props.me.setState({ selectedPayor: inputPayor, billinfo_PrimaryInsuranceCarrier: inputPayor.value, });
  };
  const getDefaultPayorIdOptions = () => {
    return props.state.allPayors.slice(0, 20);
  };
  const getPayorIdOptionsBySearchText = async (searchText) => {
    let payorIdOptions = props.state.allPayors.filter((payor) =>
      payor.label.toLowerCase().includes(searchText.toLowerCase())
    );

    // if the payorIdOptions array contains many elements then the Select element becomes laggy
    // so I'm cutting down the array size if there are too many elements - Khairul
    const numberOfOptions = payorIdOptions.length;

    console.log("\n===");
    console.log("original options count: ", numberOfOptions);

    if (numberOfOptions > 100) {
      payorIdOptions = payorIdOptions.slice(0, 99);
    }

    console.log("Options count after trimming: ", payorIdOptions.length);
    console.log("===\n");

    return payorIdOptions;
  };
  const renderPayorListDropdownForPrimaryInsuranceCarrierSelection = () => {

    return (
      <div class="col-sm-10 col-xs-12 mb-3 " id="payorListSearchAndSelect">
        <div class="form-group drop-custum">
          <label class="color-green" htmlFor="role">
            Search Primary Carrier:
          </label>
          <small className="color-red">*</small>
          <AsyncSelect
            // value={props.state.selectedPayor.value}
            defaultValue={""}
            label="Search Primary Carrier:"
            cacheOptions
            defaultOptions={getDefaultPayorIdOptions()}
            loadOptions={getPayorIdOptionsBySearchText}
            styles={helper.colourStyles}
            onChange={updatePrimaryInsuranceCarrierAndSelectedPayor}
            value={props.state.selectedPayor}
          />
        </div>
      </div>
    );
  };
  const divPadding = {

    paddingLeft: "20px",

  };
  return (
    <div style={divPadding}>
      <div class="row clearfix" id="billing_info_container">
        <div class="col-md-12">
          <div class="form-group required">
            <label
              class="form-label"
              for={`${props.state.frmprefix}BillingInformation`}
            >
              Billing Information
            </label>
            <div id={`billing_info_insurance_container`} class="demo-checkbox">
              <input
                type="checkbox"
                id={`billinfo_PrivateInsurance`}
                name={`billinfo_PrivateInsurance`}
                class="filled-in"
                checked={props.state.privateInsurance}
                onClick={(e) => {
                  BillingInformationPrivateInsuranceClick(e.target.checked);
                }}
              />
              <label for={`billinfo_PrivateInsurance`}>
                Private Insurance &nbsp;{" "}
              </label>
              <input
                type="checkbox"
                id={`billinfo_Medicare`}
                name={`billinfo_Medicare`}
                class="filled-in"
                checked={props.state.medicare}
                onClick={(e) => {
                  BillingInformationMedicareClick(e.target.checked);
                }}
              />
              <label for={`billinfo_Medicare`}>
                Medicare<sup>1</sup> &nbsp;{" "}
              </label>
              <input
                type="checkbox"
                id={`billinfo_PatientSelfPay`}
                name={`billinfo_PatientSelfPay`}
                class="filled-in"
                checked={props.state.patientSelfPay}
                onClick={(e) => {
                  BillingInformationPatientSelfPayClick(e.target.checked);
                }}
              />
              <label for={`billinfo_PatientSelfPay`}>
                Patient Self-Pay &nbsp;{" "}
              </label>
              <input
                type="checkbox"
                id={`billinfo_Medicaid`}
                name={`billinfo_Medicaid`}
                class="filled-in"
                checked={props.state.medicaid}
                onClick={(e) => {
                  BillingInformationMedicaidClick(e.target.checked);
                }}
              />
              <label for={`billinfo_Medicaid`}>Medicaid &nbsp; </label>
              <input
                type="checkbox"
                id={`billinfo_FreeTrial`}
                name={`billinfo_FreeTrial`}
                class="filled-in"
                checked={props.state.freeTrial}
                onClick={(e) => {
                  BillingInformationFreeTrialClick(e.target.checked);
                }}
              />
              <label for={`billinfo_FreeTrial`}>Free Trial &nbsp; </label>
              <input
                type="checkbox"
                id={`billinfo_OrderingFacility`}
                name={`billinfo_OrderingFacility`}
                class="filled-in"
                checked={props.state.orderingFacility}
                onClick={(e) => {
                  BillingInformationOrderingFacilityClick(e.target.checked);
                }}
              />
              <label for={`billinfo_OrderingFacility`}>
                Ordering Facility (Client Bill) &nbsp;{" "}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row clearfix">
        <div class="col-md-12">
          <p class="billInfoOva">
            <sup>1</sup>By ordering this test for your Medicare physician, you
            are certifying that the physician has met the requirements for use{" "}
            <br />
            i.e has an ovarian mass, has surgery planned and is over 18 years of
            age
          </p>
          <p id={`title_insurance_container`}>Insurance Information</p>
        </div>
      </div>
      {renderPayorListDropdownForPrimaryInsuranceCarrierSelection()}
      {(props.state.privateInsurance ||
        props.state.medicaid ||
        props.state.medicare) && (
          <div id="primary_insurance_container">
            <div class="row clearfix">
              <div class="col-md-3">
                <div class="form-group required">
                  <label class="form-label" for={`PrimaryInsuranceCarrier`}>
                    Primary Insurance Carrier{" "}
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      value={props.state.billinfo_PrimaryInsuranceCarrier}
                      id={`billinfo_PrimaryInsuranceCarrier`}
                      class="form-control required"
                      placeholder="Primary Insurance Carrier"
                      onChange={(e) => {
                        props.me.setState({
                          billinfo_PrimaryInsuranceCarrier: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group required">
                  <label class="form-label" for={`planId`}>
                    Plan ID#{" "}
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      id={`billinfo_planId`}
                      class="form-control required"
                      placeholder="Plan ID"
                      onChange={(e) => {
                        props.me.setState({ billinfo_planId: e.target.value });
                      }}
                      value={props.state.billinfo_planId}
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group required">
                  <label class="form-label" for={`MemberID`}>
                    Member ID#{" "}
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      id={`billinfo_MemberID`}
                      class="form-control required"
                      placeholder="Member ID"
                      onChange={(e) => {
                        props.me.setState({ billinfo_MemberID: e.target.value });
                      }}
                      value={props.state.billinfo_MemberID}
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group required">
                  <label class="form-label" for={`GroupID`}>
                    Group ID#{" "}
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      id={`billinfo_GroupID`}
                      class="form-control required"
                      placeholder="Group ID#"
                      onChange={(e) => {
                        props.me.setState({ billinfo_GroupID: e.target.value });
                      }}
                      value={props.state.billinfo_GroupID}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      {(props.state.privateInsurance ||
        props.state.medicaid ||
        (props.state.privateInsurance && props.state.medicare)) && (
          <div id="secondary_insurance_container" /*  */>
            <div class="row clearfix">
              <div class="col-md-3">
                <div class="form-group ">
                  <label class="form-label" for={`SecondaryInsuranceCarrier`}>
                    Secondary Insurance Carrier{" "}
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      id={`billinfo_SecondaryInsuranceCarrier`}
                      class="form-control "
                      placeholder="Secondary Insurance Carrier"
                      onChange={(e) => {
                        props.me.setState({
                          billinfo_SecondaryInsuranceCarrier: e.target.value,
                        });
                      }}
                      value={props.state.billinfo_SecondaryInsuranceCarrier}
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group ">
                  <label class="form-label" for={`SecondaryplanId`}>
                    Plan ID#{" "}
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      id={`billinfo_SecondaryplanId`}
                      class="form-control "
                      placeholder="Plan ID"
                      onChange={(e) => {
                        props.me.setState({
                          billinfo_SecondaryplanId: e.target.value,
                        });
                      }}
                      value={props.state.billinfo_SecondaryplanId}
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group ">
                  <label class="form-label" for={`SecondaryMemberID`}>
                    Member ID#{" "}
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      id={`billinfo_SecondaryMemberID`}
                      class="form-control "
                      placeholder="Member ID"
                      onChange={(e) => {
                        props.me.setState({
                          billinfo_SecondaryMemberID: e.target.value,
                        });
                      }}
                      value={props.state.billinfo_SecondaryMemberID}
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group ">
                  <label class="form-label" for={`SecondaryGroupID`}>
                    Group ID#{" "}
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      id={`billinfo_SecondaryGroupID`}
                      class="form-control "
                      placeholder="Group ID#"
                      onChange={(e) => {
                        props.me.setState({
                          billinfo_SecondaryGroupID: e.target.value,
                        });
                      }}
                      value={props.state.billinfo_SecondaryGroupID}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      {props.from && (props.from === "genetix-carrier-screening" || props.from === "hboc1")
        ? (props.state.privateInsurance ||
          props.state.medicaid ||
          (props.state.privateInsurance && props.state.medicare)) && (
          <div id="tertiary_insurance_container">
            <div class="row clearfix">
              <div class="col-md-3">
                <div class="form-group ">
                  <label class="form-label" for={`TertiaryInsuranceCarrier`}>
                    Tertiary Insurance Carrier{" "}
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      id={`billinfo_TertiaryInsuranceCarrier`}
                      class="form-control "
                      placeholder="Tertiary Insurance Carrier"
                      onChange={(e) => {
                        props.me.setState({
                          billinfo_TertiaryInsuranceCarrier: e.target.value,
                        });
                      }}
                      value={props.state.billinfo_TertiaryInsuranceCarrier}
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group ">
                  <label class="form-label" for={`TertiaryPlanID`}>
                    Plan ID#{" "}
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      class="form-control "
                      placeholder="Plan ID"
                      onChange={(e) => {
                        props.me.setState({
                          billinfo_TertiaryPlanID: e.target.value,
                        });
                      }}
                      value={props.state.billinfo_TertiaryPlanID}
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group ">
                  <label class="form-label" for={`TertiaryMemberID`}>
                    Member ID#{" "}
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      id={`billinfo_TertiaryMemberID`}
                      class="form-control "
                      placeholder="Member ID"
                      onChange={(e) => {
                        props.me.setState({
                          billinfo_TertiaryMemberID: e.target.value,
                        });
                      }}
                      value={props.state.billinfo_TertiaryMemberID}
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group ">
                  <label class="form-label" for={`TertiaryGroupID`}>
                    Group ID#{" "}
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      id={`billinfo_TertiaryGroupID`}
                      class="form-control "
                      placeholder="Group ID#"
                      onChange={(e) => {
                        props.me.setState({
                          billinfo_TertiaryGroupID: e.target.value,
                        });
                      }}
                      value={props.state.billinfo_TertiaryGroupID}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
        : (<div></div>)}

      {(props.state.privateInsurance ||
        props.state.medicaid ||
        props.state.medicare) && (
          <div id="insured_info_container">
            <div id="insured_info" class="row clearfix">
              <div class="col-md-4">
                <div class="form-group required">
                  <label class="form-label" for={`NameofInsured`}>
                    Name of Insured{" "}
                  </label>
                  <small className="color-red">*</small>
                  <div class="form-line">
                    <div className="row clearfix">
                      <div className="col-md-6">
                        <input
                          type="text"
                          id={`billinfo_FirstNameofInsured`}
                          class="form-control required"
                          placeholder="First Name"
                          onChange={(e) => {
                            props.me.setState({
                              billinfo_FirstNameofInsured: e.target.value,
                            });
                          }}
                          value={props.state.billinfo_FirstNameofInsured}
                        />
                      </div>
                      <div className="col-md-6">
                        <input
                          type="text"
                          id={`billinfo_LastNameofInsured`}
                          class="form-control required"
                          placeholder="Last Name"
                          onChange={(e) => {
                            props.me.setState({
                              billinfo_LastNameofInsured: e.target.value,
                            });
                          }}
                          value={props.state.billinfo_LastNameofInsured}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group required">
                  <label class="form-label" for={`DOB`}>
                    DOB (mm/dd/yyyy){" "}
                  </label>
                  <small className="color-red">*</small>
                  <div class="form-line">
                    <DatePicker
                      id={`billinfoC_dob`}
                      className={"form-control required"}
                      selected={props.state.billinfoC_dob}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      onChange={(e) => {
                        props.me.setState({ billinfoC_dob: e });
                      }}
                      value={props.state.billinfoC_dob}
                    />
                  </div>
                </div>
              </div>
              {
                <div class="col-md-4">
                  <div class="form-group required">
                    <label class="form-label" for={`Relationshiptoinsured`}>
                      Relationship to insured
                    </label>
                    <small className="color-red">*</small>
                    <div class="form-line">
                      <select
                        class="form-control show-tick"
                        id={`billinfo_RelationshipInsured`}
                        onChange={(e) => {
                          props.me.setState({
                            billinfo_RelationshipInsured: e.target.value,
                          });
                        }}
                        value={props.state.billinfo_RelationshipInsured}
                      >
                        {props.state.billinfo_RelationshipInsured === "Self" && (
                          <option value="Self" selected>
                            {" "}
                            Self{" "}
                          </option>
                        )}
                        {props.state.billinfo_RelationshipInsured != "Self" && (
                          <option value="Self"> Self </option>
                        )}

                        {props.state.billinfo_RelationshipInsured ===
                          "Spouse" && (
                            <option value="Spouse" selected>
                              {" "}
                              Spouse{" "}
                            </option>
                          )}
                        {props.state.billinfo_RelationshipInsured != "Spouse" && (
                          <option value="Spouse"> Spouse </option>
                        )}

                        {props.state.billinfo_RelationshipInsured === "Child" && (
                          <option value="Child" selected>
                            {" "}
                            Child{" "}
                          </option>
                        )}
                        {props.state.billinfo_RelationshipInsured != "Child" && (
                          <option value="Child"> Child </option>
                        )}

                        {props.state.billinfo_RelationshipInsured ===
                          "Unknown" && (
                            <option value="Unknown" selected>
                              {" "}
                              Unknown{" "}
                            </option>
                          )}
                        {props.state.billinfo_RelationshipInsured !=
                          "Unknown" && <option value="Unknown"> Unknown </option>}
                      </select>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        )}
    </div>
  );
};

export default Billing_Information;
