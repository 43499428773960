import React, { PureComponent } from "react";
import axios from "axios";
//include template related files
import PageLoader from "./layout/PageLoader";
import { BoxLoading } from 'react-loadingg';

import { message } from 'antd';

import { Redirect, withRouter } from "react-router-dom";
import PropTypes from 'prop-types';
// //import dotenv from "dotenv";
// import createHistory from "history/createBrowserHistory";
import VerifyPatientModal from "./mycomponents/modal";

import Toast from './mycomponents/toast/Toast';

import helper from './utils/helper';
// const history = createHistory();


//require("dotenv").config();


const API_BASE_URL = helper.getAPIBaseURI();

var me = null;
class Login extends PureComponent {
    // static propTypes = {
    //     history: PropTypes.object.isRequired
    // }
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            loggedIn: false,
            redirectToReferrer: false,
            loginFailed: false,

            showVerifyPatientModal: false,
            showDangerMessage: false,
            toastMessage: `You have entered an invalid username or password`,
            list: [],
        };

        this.handleLogin = this.handleLogin.bind(this);

        this.useremail = React.createRef();
        this.userpassword = React.createRef();
        this.danger_loginFailed = React.createRef();
        // this.history = useHistory();

    }

    componentDidMount() {
        me = this;

        console.log("this.props: ", this.props.location.search);

        const search = this.props.location.search;
        const params = new URLSearchParams(search);

        const patient_id = params.get('id');
        const code = params.get('code');

        if (patient_id != undefined && code != undefined) {

            this.setState({
                showVerifyPatientModal: true,
            })
        }
        axios.interceptors.response.use(function (response) {
            // Do something with response data
            return response;
        }, function (error) {
            switch (error.response.status) {
                case 502:
                    console.log("interceptors->error: ", error);

                    if (patient_id != undefined && code != undefined) {
                        me.setState({
                            showDangerMessage: true,
                            dangerMessage: `Probably the link expired or Invalid ID found.`,
                        }, () => {

                        });
                    }
                    break;
                case 503:
                    // props.history.push('/503') //we will redirect user into 503 page 
                    console.log("interceptors->error: ", error);

                    if (patient_id != undefined && code != undefined) {
                        me.setState({
                            showDangerMessage: true,
                            dangerMessage: `Temporary Password doesn't match.`,
                        }, () => {

                        });

                    }
                    else {
                        me.setState({
                            loading: false,
                            loginFailed: true,
                            toastMessage: `This user is locked. Please contact your administrator`,
                        }, () => {
                            // me.showToast("danger");
                            message.error(me.state.toastMessage);
                        });
                    }
                    break
                case 401:
                    console.log("interceptors->error: ", error);

                    me.setState({
                        loading: false,
                        loginFailed: true,
                        toastMessage: `Login failed, Unauthorized.`,
                    }, () => {
                        // me.showToast("danger");
                        message.error(me.state.toastMessage);
                    });
                    break;
                default:
                    break
            }
            // Do something with response error
            return Promise.reject(error);
        });
    }
    handleLogin = () => {
        const { state = {} } = this.props.location;

        const me = this;

        // alert(this.useremail.value);

        const useremail = this.useremail.value;
        const password = this.userpassword.value;

        if (this.useremail.value.length < 1 || this.userpassword.value.length < 1) {
            this.setState({
                toastMessage: `User Name and Password is Required.`,
            }, () => {
                // this.showToast("danger");
                message.error(me.state.toastMessage);
            });

        }
        else {

            const _url = `${API_BASE_URL}/auth`;
            axios({
                method: "POST",
                url: _url,
                data: {
                    "email": useremail,
                    "password": password,
                }
            })
                .then(({ data }) => {
                    console.log("handleLogin->data: ", data);

                    if (data.status) {

                        localStorage.setItem("user", JSON.stringify({
                            token: data.token,
                            email: useremail,
                            firstName: data.firstName,
                            lastName: data.lastName,
                            isAdmin: data.isAdmin,
                            role: data.role,
                            allRoles: data.allRoles,
                            id: data.id,
                            _id: data.id,
                            resetPasswordRequired: data.resetPasswordRequired,
                            buildNumber: data.buildNumber,
                        }));

                        //CP-99
                        let orderId = localStorage.getItem("orderId");
                        if (orderId) {
                            helper.redirectToReportReviewPage(orderId);

                            localStorage.removeItem("orderId");
                        }
                        //END OF CP-99
                        else if (data.resetPasswordRequired) {
                            window.location.href = '/reset-password';
                        }
                        else if (data.role.name == 'Portal_Billing') {
                            window.location.href = '/billing-review';
                        }
                        else if (data.role.name == 'Portal_Benefits_Reivewer') {
                            window.location.href = '/benefits-review';
                        }
                        else if (data.role.name == 'Portal_Report_Viewer') {
                            window.location.href = '/resulted_report';
                        }
                        else {
                            window.location.href = '/orders';
                        }

                    }
                    else {
                        this.setState({
                            loading: false,
                            loginFailed: true,
                            toastMessage: `Login Failed.`,
                        }, () => {
                            // this.showToast("danger");
                            message.error(me.state.toastMessage);
                        });
                    }

                })
                .catch(error => {
                    console.log("error: ", error);

                });
            //     },
            // );
        }
    };
    handleVerifyPatient = () => {
        me = this;

        console.log("this.props: ", this.props.location.search);

        const search = this.props.location.search;
        const params = new URLSearchParams(search);

        const patient_id = params.get('id');
        const code = params.get('code');

        let tempPassword = this.tempPassword.value;
        let newPassword = this.newPassword.value;


        axios({
            url: `${API_BASE_URL}/patients/verify`,
            method: "POST",
            headers: {
                "Content-type": "Application/json",
            },
            data: {
                id: patient_id,
                code: code,
                tempPassword: tempPassword,
                newPassword: newPassword,
            }
        })
            .then(({ data }) => {
                console.log("data: ", data);
                // setPatients(data.patients);


                localStorage.setItem("user", JSON.stringify({
                    token: data.token,
                    email: data.patient.email,
                    firstName: data.patient.firstName,
                    lastName: data.patient.lastName,
                    isAdmin: data.patient.isAdmin,
                }));
                window.location.href = '/orders';
            });

    }

    showToast(type) {
        const id = Math.floor((Math.random() * 101) + 1);

        switch (type) {
            case 'success':
                this.toastProperties = {
                    id,
                    title: 'Success',
                    description: 'This is a success toast component',
                    backgroundColor: '#5cb85c',
                    icon: "/assets/assets/check.svg"
                }
                break;
            case 'danger':
                this.toastProperties = {
                    id,
                    title: 'Danger',
                    description: 'This is a error toast component',
                    backgroundColor: '#d9534f',
                    icon: "/assets/assets/error.svg"
                }
                break;
            case 'info':
                this.toastProperties = {
                    id,
                    title: 'Info',
                    description: 'This is an info toast component',
                    backgroundColor: '#5bc0de',
                    icon: "/assets/assets/info.svg"
                }
                break;
            case 'warning':
                this.toastProperties = {
                    id,
                    title: 'Warning',
                    description: 'This is a warning toast component',
                    backgroundColor: '#f0ad4e',
                    icon: "/assets/assets/warning.svg"
                }
                break;

            default:
                this.setState({ list: [] });
        }

        // this.setState([...this.state.list, this.toastProperties]);
        this.setState({ list: [this.toastProperties] });
    }
    render() {

        if (this.state.loading) {
            return <BoxLoading />;
        }
        else {
            const { redirectToReferrer } = this.state;

            if (redirectToReferrer) {
                return (
                    <Redirect to="/orders" />
                )
            }

            return (
                <div class="authentication" id="authentication">
                    <Toast
                        toastList={this.state.list}
                        position={"top-center"}
                        autoDelete={true}
                        dismissTime={3000}
                        description={this.state.toastMessage}
                        width={300}
                    />
                    <div class="container-fluid">
                        <div class="row clearfix">
                            <div class="col-xl-9 col-lg-8 col-md-12 p-l-0 blackybg">
                                <div class="l-detail">
                                    <h5 class="position">Welcome</h5>
                                    <h1 class="position">Aspira Admin Portal</h1>
                                    <h3 class="position">Sign in to start your session</h3>


                                </div>
                            </div>

                            <div class="col-xl-3 col-lg-4 col-md-12 p-r-0">

                                <div class="card position">
                                    <h4 class="l-login">Login</h4>
                                    {/* <form class="col-md-12" id="sign_in" method="POST"> */}

                                    <div class="form-group form-float">
                                        <div class="form-line">
                                            <label htmlFor="useremail" class="color-green">Email:</label>
                                            <input id="useremail" ref={(un) => this.useremail = un} type="text" name="useremail" class="form-control" />

                                        </div>
                                    </div>
                                    <div class="form-group form-float">
                                        <div class="form-line">
                                            <label htmlFor="userpassword" class="color-green">Password:</label>
                                            <input id="userpassword" autoSave={false} ref={(up) => this.userpassword = up} type="password" name="userpassword" class="form-control" />

                                        </div>
                                    </div>
                                    {/* {this.state.loginFailed && <div className="login-failed">
                                        <span>Login Failed.</span>
                                    </div>} */}
                                    <div>
                                        <input type="checkbox" name="rememberme" id="rememberme" class="filled-in chk-col-cyan" />
                                        <label for="rememberme">Remember Me</label>
                                    </div>
                                    <button class="btn btn-raised g-bg-blue waves-effect" onClick={this.handleLogin}>Login</button> &nbsp; &nbsp;
                                    {/* <input type="submit" class="btn btn-raised g-bg-blue waves-effect" value="SIGN IN" /> */}
                                    <a href="/signup" class="btn btn-raised waves-effect btn-signup" title="">SIGN UP</a>
                                    <div class="text-left"> <a href="/forgot-password">Forgot Password?</a> </div>
                                    {/* </form> */}

                                    <div className="">
                                        <VerifyPatientModal show={this.state.showVerifyPatientModal}>
                                            <div class="form-group form-float">
                                                <div class="form-line">
                                                    <label htmlFor="userpassword" class="color-green">Temp Password:</label>
                                                    <input id="tempPassword" autoSave={false} ref={(up) => this.tempPassword = up} type="password" name="tempPassword" class="form-control" />

                                                </div>
                                            </div>
                                            <div class="form-group form-float">
                                                <div class="form-line">
                                                    <label htmlFor="newPassword" class="color-green">New Password:</label>
                                                    <input id="newPassword" autoSave={false} ref={(up) => this.newPassword = up} type="password" name="newPassword" class="form-control" />

                                                </div>
                                            </div>

                                            <button class="btn btn-raised g-bg-blue waves-effect" onClick={this.handleVerifyPatient}>Verify</button> &nbsp; &nbsp;

                                            <div className={"text-center"}>
                                                {this.state.showDangerMessage && <span className={"alert-danger"}>{this.state.dangerMessage}</span>}
                                            </div>
                                        </VerifyPatientModal>


                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            );
        }
    }
}

export default withRouter(Login);