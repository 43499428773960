import { PureComponent } from "react";

import DeleteModal from "../mycomponents/modal";
import ProgressBar from "../mycomponents/progressbar";

import DatePicker from 'react-datepicker';

import Spinner from "../Spinner/Spinner";
export default class ReportSearchComponent extends PureComponent {

    render() {
        return (
            <div>
                <div class="block-header">
                    <h2>{`${this.props.activeTab} Report Search`}</h2>

                </div>
                {this.props.activeTab === 'Ova1' && <div class="row clearfix">
                    <div class="card orders-list">
                        <div class="body">
                            <div class="col-md-12 col-xs-12">

                                <div class="row clearfix">
                                    <div class="col-md-12">
                                    </div>
                                </div>
                                <div class="row clearfix">
                                    <div class="col-md-12">
                                        <div class="form-group required">
                                            <label class="form-label" for="address">Order Number:</label>
                                            <div>

                                                <input ref={(_ref) => this.props.me.orderIds = _ref} type="text" placeholder={`OVAXXXXXXXXX`} id={`inp_orderIds`} name={`inp_orderIds`} class="form-control" />


                                            </div>
                                        </div>
                                    </div>
                                </div >

                            </div>
                        </div>
                    </div>
                </div>
                }
                {this.props.activeTab === 'Genetix' && <div class="row clearfix">
                    <div class="card orders-list">
                        <div class="body">
                            <div class="col-md-12 col-xs-12">
                                <div class="col-md-4 col-xs-4">
                                    <div class="form-group required">
                                        <label class="form-label required" for="baylor">
                                            Start Date
                                        </label>
                                        <div class="form-line">
                                            <DatePicker
                                                placeholderText={`MM/DD/YYYY`}
                                                id={`inpStartDate1`}
                                                className={"form-control required"}
                                                selected={this.props.state.startDate}
                                                onChange={date => this.props.me.onChangeStartDate1(date)}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-3 col-xs-3">
                                    <div class="form-group required">
                                        <label class="form-label" for="baylor">
                                            End Date
                                        </label>
                                        <div class="form-line">

                                            <DatePicker
                                                placeholderText={`MM/DD/YYYY`}
                                                id={`inpEndDate2`}
                                                className={"form-control required"}
                                                selected={this.props.state.endDate}
                                                onChange={date => this.props.me.onChangeEndDate2(date)}
                                                peekNextMonth
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class="row clearfix">
                                    <div class="col-md-12">
                                    </div>
                                </div>
                                <div class="row clearfix">
                                    <div class="col-md-12">
                                        <div class="form-group required">
                                            <label class="form-label" for="address">Report Type:</label>
                                            <div class="demo-radio-button">
                                                <div className="col-md-3">
                                                    <input ref={(_ref) => this.props.me.completed = _ref} checked={this.props.state.reportType == 'Completed'} type="radio" id={`radio_completed`} name={`radio_report_type`} value={`Completed`} onChange={this.props.me.handleChangeReportType} class="filled-in" />
                                                    <label for={`radio_completed`}>Completed &nbsp; </label>
                                                </div>
                                                <div className="col-md-3">
                                                    <input ref={(_ref) => this.props.me.amended = _ref} checked={this.props.state.reportType == 'Amended'} type="radio" id={`radio_amended`} name={`radio_report_type`} value={'Amended'} onChange={this.props.me.handleChangeReportType} class="filled-in" />
                                                    <label for={`radio_amended`}>Amended &nbsp; </label>
                                                </div>
                                                <div className="col-md-3">
                                                    <input ref={(_ref) => this.props.me.cancelled = _ref} checked={this.props.state.reportType == 'Cancelled'} type="radio" id={`radio_cancelled`} name={`radio_report_type`} value={`Cancelled`} onChange={this.props.me.handleChangeReportType} class="filled-in" />
                                                    <label for={`radio_cancelled`}>Cancelled &nbsp; </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div >

                            </div>
                        </div>
                    </div>
                </div>
                }
                <div id="ignoreMeInPDF" class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group">
                            <button
                                id={`btnSearchSubmit`}
                                onClick={() => {
                                    this.props.me.handleSearch(this.props.activeTab)
                                }}
                                placeholder="Submit"
                                name="Submit"
                                className="btn btn-primary text-white"
                            >
                                {`Search ${this.props.activeTab === 'Genetix' ? "Baylor" : "Report"}`}
                            </button>
                        </div>
                    </div>
                </div>
                <hr />
                <div class="row clearfix">
                    <div class="col-md-12 col-xs-12">
                        <div class="card orders-list">
                            <div class="body">
                                <div class="table-responsive">
                                    <table
                                        className="display"
                                        width="100%"
                                        ref={(el) => (this.props.me.orderDataTable = el)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    this.props.state.showLoader && <div>
                        <Spinner />
                    </div>
                }
                <DeleteModal
                    onClose={this.props.state.showModal}
                    show={this.props.state.showOrderModal}
                >
                    <div>
                        <span>
                            Hold delete button for 3 seconds in order to confirm delete
                            Patient {this.props.state.deletePatientName}
                        </span>
                    </div>
                    <div class="delete-buttons">
                        <button
                            class="btn waves-effect btn-delete"
                            onTouchStart={this.props.me.handleButtonPress}
                            onTouchEnd={this.props.me.handleButtonRelease}
                            onMouseDown={this.props.me.handleButtonPress}
                            onMouseUp={this.props.me.handleButtonRelease}
                            onMouseLeave={this.props.me.handleButtonRelease}
                        // onClick={this.deletePatient}
                        >
                            Delete
                        </button>
                        <button
                            class="btn btn-raised g-bg-blue waves-effect"
                            onClick={this.props.me.showModal}
                        >
                            Cancel
                        </button>
                    </div>
                    <div>
                        {this.props.state.showProgressBar && (
                            <ProgressBar entity={"order"} id={this.props.state.deleteOrderId} />
                        )}
                    </div>
                </DeleteModal>
            </div>
        );
    }
}