import React from "react";
import Loader from 'react-loader-spinner';
import "./Spinner.css";

export default class Spinner extends React.Component {
  
    render() {
     return(
      <Loader type="ThreeDots" color="#14522d" height={100} width={100} />
     );
    }
 }