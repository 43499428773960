import React from "react";
import "./progressbar.css";
import axios from "axios";

import helper from './../utils/helper';
import { protectedResources } from "./../authConfig";
import { MsalContext } from "@azure/msal-react";

const API_BASE_URL = helper.getAPIBaseURI();
export default class Progressbar extends React.PureComponent {
    static contextType = MsalContext;

    constructor(props) {
        super(props);

        this.state = {
            progress: 0,
            speed: 1,
            color: "#ff0050",
            completed: false,
            message: "",
        }
        this.frame = this.frame.bind(this);
        this.green = this.green.bind(this);
        this.red = this.red.bind(this);
    }
    componentDidMount() {
        console.clear();
        this.interval = setInterval(() => this.frame(), 50);
    }
    async delete() {


        if (this.state.completed) {
            console.log("Already Completed deleting...");
            return;
        }
        let user = JSON.parse(localStorage.getItem("user"));
        console.log("user.token: ", user.token);

        const accessToken = await helper.getAccessToken(this.context);
        if (process.env.REACT_APP_ENV !== 'DEV') {
            if (!user) {

                ({ data: { user } } = await axios.get(`${API_BASE_URL}/users/current`, {
                    headers: {
                        "Authorization": `Bearer ${accessToken}`
                    }
                }));

                localStorage.setItem("user", JSON.stringify(user));
            }


        }



        let _url = null;
        if (this.props.entity === "user") {
            _url = `${API_BASE_URL}/users`;
        }
        else if (this.props.entity === "patient") {
            _url = `${API_BASE_URL}/patients`;
        }
        else if (this.props.entity === "auditlog") {
            _url = `${API_BASE_URL}/audits`;
        }
        else if (this.props.entity === "order") {
            _url = `${API_BASE_URL}/orders`;
        }
        else if (this.props.entity === 'Legacyclient') {
            _url = `${API_BASE_URL}/legacy`;
        }
        axios({
            url: _url,
            method: "DELETE",
            headers: {
                "Content-type": "Application/json",
                Authorization: `Bearer ${accessToken}`
            },
            data: { itemID: this.props.id }
        })
            .then(({ data }) => {
                console.log("after delete data: ", data);
                if (this.props.entity === "user") {
                    this.setState({
                        completed: true,
                        message: `Successfully deleted User ${this.props.name}`,
                    }, () => {
                        setTimeout(() => {
                            window.location.reload();
                        }, 10);

                    });
                }
                else if (this.props.entity === "patient") {
                    this.setState({
                        completed: true,
                        message: `Successfully deleted Patient ${this.props.name}`,
                    }, () => {
                        setTimeout(() => {
                            window.location.reload();
                        }, 10);

                    });
                }
                else if (this.props.entity === "auditlog") {
                    this.setState({
                        completed: true,
                        message: `Successfully deleted Audit Log`,
                    }, () => {
                        setTimeout(() => {
                            window.location.reload();
                        }, 10);

                    });
                }
                else if (this.props.entity === "order") {
                    this.setState({
                        completed: true,
                        message: `Successfully deleted Order`,
                    }, () => {
                        setTimeout(() => {
                            window.location.reload();
                        }, 10);

                    });
                }
                else if (this.props.entity === "Legacyclient") {
                    this.setState({
                        completed: true,
                        message: `Successfully deleted Legacy Client`,
                    }, () => {
                        setTimeout(() => {
                            window.location.reload();
                        }, 10);

                    });
                }

            });
    }
    frame() {
        if (this.state.progress < 100) {
            this.setState((prevState, props) => ({
                progress: prevState.progress + this.state.speed,
                // color: "#" + this.red() + this.green() + "50"
            }));
            console.log(this.state.color);


        }
        else {
            this.delete();

        }
    }

    componentWillUnmount() {
        // alert("im here")
        clearInterval(this.interval);
    }
    green() {
        let progress = this.state.progress;
        progress *= 3.55;
        progress = Math.round(progress);
        progress = progress.toString(16);
        return progress;
    }
    red() {
        let progress = this.state.progress;
        progress *= 3.55;
        progress = Math.round(progress);
        progress = 255 - progress;
        progress = progress.toString(16);
        return progress;
    }
    render() {
        return (
            <div id="myBar" style={{
                width: this.state.progress + "%",
                backgroundColor: this.state.color
            }}>

                {!this.state.completed && <div id="label">{this.state.progress}%</div>}
                {this.state.completed && <div id="label">{this.state.message}</div>}
            </div>

        );
    }
}
