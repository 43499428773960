import { MsalContext } from "@azure/msal-react";
import axios from "axios";
import React, { PureComponent } from "react";
import { withRouter } from 'react-router-dom';
import { protectedResources } from "./../authConfig";
import helper from "../utils/helper";

const API_BASE_URL = helper.getAPIBaseURI();

class TopBar extends PureComponent {
    static contextType = MsalContext;

    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            loggedIn: false,
            redirectToReferrer: false,
            user: null,
            buildNumber: "",
        };

        this.handleLogout = this.handleLogout.bind(this);

        this.useremail = React.createRef();
        this.userpassword = React.createRef();
        // this.history = useHistory();
    }

    handleLogout() {
        localStorage.clear();

        if (process.env.REACT_APP_ENV === 'DEV') {
            window.location.replace("/");
        }
        else {
            this.context.instance.logout();
        }

    }

    async componentDidMount() {
        const user = JSON.parse(localStorage.getItem('user'));
        await this.loadUserInfo(user);

    }

    async loadUserInfo(userExisting) {

        let token = {};

        if (process.env.REACT_APP_ENV !== 'DEV') {
            try {
                token = await this.context.instance.acquireTokenSilent({
                    scopes: protectedResources.apiOrders.scopes,
                    account: this.context.accounts[0]
                });

                const { data: { user, buildNumber } } = await axios.get(`${API_BASE_URL}/users/current`, {
                    headers: {
                        "Authorization": `Bearer ${token.accessToken}`
                    }
                });

                console.log("TopBar->loadUserInfo->buildNumber: ", buildNumber);
                localStorage.setItem("user", JSON.stringify(user));

                this.setState({ allRoles: user.allRoles, user, buildNumber: buildNumber });
            }
            catch (error) {
                console.log("error TopBar.js->error: ", error);


                // this.handleLogout(); //Temp
                
            }

        }
        else if (userExisting) {
            localStorage.setItem("user", JSON.stringify(userExisting));
            this.setState({ allRoles: userExisting.allRoles, user: userExisting, buildNumber: userExisting.buildNumber });
        }

    };
    render() {
        // if (!this.state.user) return null;

        return (
            <div>
                <nav class="navbar clearHeader">
                    <div class="col-12">
                        <div class="navbar-header"> <a href={`/profile-user?id=${this.state.user ? this.state.user.id : ""}`} class="bars"></a> <a class="navbar-brand" href="/"><img class="logo" src="../assets/images/header-logo.png" alt="profile img" /></a> </div>
                        <ul class="nav navbar-nav navbar-right">
                            <li><span style={{ color: 'white' }}>v{this.state.buildNumber}</span></li>
                            <li class="dropdown menu-app"> <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown" role="button"> <i class="zmdi zmdi-apps"></i> </a>
                                <ul class="dropdown-menu">
                                    <li class="body">
                                        <ul class="menu">
                                            <li>
                                                <ul>
                                                    <li><a href="javascript:void(0)"><i class="zmdi zmdi-email"></i><span>Mail</span></a></li>
                                                    <li><a href="javascript:void(0)"><i class="zmdi zmdi-accounts-list"></i><span>Contacts</span></a></li>
                                                    <li><a href="javascript:void(0)"><i class="zmdi zmdi-comment-text"></i><span>Chat</span></a></li>
                                                    <li><a href="javascript:void(0)"><i class="zmdi zmdi-arrows"></i><span>Notes</span></a></li>
                                                    <li><a href="javascript:void(0)"><i class="zmdi zmdi-view-column"></i><span>Taskboard</span></a></li>
                                                    <li><a href="javascript:void(0)"><i class="zmdi zmdi-calendar-note"></i><span>Calendar</span></a></li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>

                            <li><button onClick={this.handleLogout} class="mega-menu button-logout"><i class="zmdi zmdi-power"></i></button></li>


                        </ul>
                    </div>
                </nav>
            </div>
        );
    }
}

export default withRouter(TopBar);
