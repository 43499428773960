import React from 'react';

import './../AppPortal.css';

const validateInput = value => {
    let error = ""

    if (!value) error = "Required!"
    else if (value.length !== 14) error = "Invalid phone format. ex: (555) 555-5555";

    return error;
};

const initialState = {
    areaCode: process.env.REACT_APP_ENV === "DEV" ? "" : "",
    prefix: process.env.REACT_APP_ENV === "DEV" ? "" : "",
    suffix: process.env.REACT_APP_ENV === "DEV" ? "" : "",
    error: ""
};

export default class PhoneNumberInput extends React.PureComponent {
    constructor() {
        super();

        this.state = initialState;
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleReset = this.handleReset.bind(this);
        this.setInputRef = this.setInputRef.bind(this);
    }

    handleChange({ target: { name, value } }) {
        let valueChanged = false;
        this.setState(prevState => {
            const nextValue = value.replace(/[^\d]/g, '');
            const changedValue = prevState[name];
            if (changedValue.length !== nextValue.length) valueChanged = true;

            return { [name]: nextValue }
        }, () => {
            if (valueChanged) this.handleFocus(name)
        });
    };

    setInputRef(name, element) {
        this[name] = element;
    }

    handleFocus(name) {
        const { areaCode, prefix, suffix } = this.state;
        const areaCodeFilled = areaCode.length === 3;
        const prefixFilled = prefix.length === 3;

        if (areaCodeFilled && name === "areaCode") {
            this.prefix.focus();
            this.prefix.selectionEnd = 0;
        } else if (prefixFilled && name === "prefix") {
            this.suffix.focus();
            this.suffix.selectionEnd = 0;
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        const { areaCode, prefix, suffix } = this.state;
        const phoneNumber = `(${areaCode}) ${prefix}-${suffix}`
        const error = validateInput(phoneNumber);

        this.setState({ error }, () => {
            if (!error) {
                setTimeout(() => {
                    alert(phoneNumber);
                }, 300)
            }
        });
    }

    handleReset() {
        this.setState(initialState);
    };

    render() {
        return (
            <>
                <div className="input-container">
                    <div className="parenthesis" style={{ marginLeft: 1, marginRight: 0 }}>&#40;</div>
                    <input
                        id={this.props.prefix + "ph_areacode"}
                        className="input area-code ph-no"
                        type="text"
                        name="areaCode"
                        placeholder="xxx"
                        value={this.state.areaCode}
                        onChange={this.handleChange}
                        maxLength="3"
                    />
                    <div className="parenthesis" style={{ marginRight: 2 }}>&#41;</div>
                    <input
                        id={this.props.prefix + "ph_prefix"}
                        ref={node => this.setInputRef("prefix", node)}
                        className="input prefix ph-no"
                        type="text"
                        name="prefix"
                        placeholder="xxx"
                        value={this.state.prefix}
                        onChange={this.handleChange}
                        maxLength="3"
                    />
                    <div className="dash">-</div>
                    <input
                        id={this.props.prefix + "ph_suffix"}
                        ref={node => this.setInputRef("suffix", node)}
                        className="input suffix ph-no"
                        type="text"
                        name="suffix"
                        placeholder="xxxx"
                        value={this.state.suffix}
                        onChange={this.handleChange}
                        maxLength="4"
                    />

                    <p className="error">{this.state.error}</p>
                </div>
            </>
        );
    }
}
