import React from "react";

const Order_header = (props) => {
  const {orderId , orderStatus} = props;
  return (
    <div class="block-header">
      <h2>
        Edit Order - Id: {orderId} ({orderStatus})
      </h2>
    </div>
  );
};

export default Order_header;
