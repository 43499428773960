import React, { PureComponent, createRef } from "react";
import helper from "./../utils/helperPortal";

import PropTypes from 'prop-types';
//import $ from 'jquery';
//import DatePicker from 'react-datepicker';

import Signature from "./Signature";

import SignaturePad from 'react-signature-canvas'
const options = {
    orientation: 'landscape',
    unit: 'in',
    format: [4, 2]
};

export default class NotesCertifyForm extends PureComponent {



    constructor(props) {
        super(props)

        this.state = {
            trimmedDataURL: null
        }

        this.containerRef = createRef();
        this.sigPad = {};
    }

    componentDidMount() {

    }
    //onChangeBillingDoB = date => this.setState({ billinfo_dob: date }); 
    clear = () => {
        this.sigPad.clear()
    }
    trim = () => {
        this.setState({
            trimmedDataURL: (window.location.hostname === 'lab.aspirawh.com' || this.sigPad.isEmpty()) ? "" :
                this.sigPad.getTrimmedCanvas().toDataURL('image/png')
        })
    }
    render() {

        return (
            <>
                <div class="panel-body">
                    <div class="row clearfix">
                        <div className={`${window.location.hostname === 'lab.aspirawh.com' ? 'col-md-12' : 'col-md-6'}`}>
                            <div class="row clearfix">
                                <div class="col-md-12">
                                    <div class="form-group ">
                                        <label class="form-label" for="address">Notes</label>
                                        <div class="form-line">
                                            <textarea id={`${this.props.prefix}kitShip_notes`} class="form-control no-resize auto-growth" placeholder="Notes" rows="3"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row clearfix">
                                <div class="col-md-12">
                                    <p><strong> Please note this page is for healthcare professionals. If you are a patient and would like access to one of our tests, please contact your healthcare provider. </strong></p>

                                </div>
                            </div>
                            <div className="row clearfix">
                                <div className="col-md-12">
                                    <div className="form-group required">
                                        <input type="checkbox" id={`${this.props.prefix}researchConsent`} className="filled-in" />
                                        <label htmlFor={`${this.props.prefix}researchConsent`}>Consent to research</label>
                                    </div>
                                    <div className="form-group required">
                                        <input type="checkbox" id={`${this.props.prefix}researchUseOnly`} className="filled-in" />
                                        <label htmlFor={`${this.props.prefix}researchUseOnly`}>Research use only</label>
                                    </div>
                                    {helper.isAccessingFromAspiraLab() && <>
                                        <div className="form-group required">
                                            <input type="checkbox" id={`${this.props.prefix}testOrder`} className="filled-in" />
                                            <label htmlFor={`${this.props.prefix}testOrder`}>Test order</label>
                                        </div>
                                    </>}
                                </div>
                            </div>
                            <div class="row clearfix">
                                <div class="col-md-12">
                                    <div class="form-group required">
                                        <label class="form-label" for="address">I certify that I am:</label>
                                        <div class="demo-radio-button gender" id={`${this.props.prefix}certifytype`}>
                                            <div>
                                                <input type="radio" data-val="A healthcare provider" id={`${this.props.prefix}kitShip_healthcareProvider`} name={`${this.props.prefix}kitShip_healthcareProvider`} class="filled-in" />
                                                <label for={`${this.props.prefix}kitShip_healthcareProvider`}>A healthcare provider &nbsp; </label>
                                            </div>
                                            <div>
                                                <input type="radio" data-val="An Aspira employee ordering on behalf of a healthcare provider" id={`${this.props.prefix}kitShip_employeehealthcareProvider`} name={`${this.props.prefix}kitShip_healthcareProvider`} class="filled-in" />
                                                <label for={`${this.props.prefix}kitShip_employeehealthcareProvider`}>An Aspira employee ordering on behalf of a healthcare provider &nbsp; </label>
                                            </div>
                                            <div>
                                                <input type="radio" data-val="An Aspira partner ordering on behalf of a healthcare provider" id={`${this.props.prefix}kitShip_partnerhealthcareProvider`} name={`${this.props.prefix}kitShip_healthcareProvider`} class="filled-in" />
                                                <label for={`${this.props.prefix}kitShip_partnerhealthcareProvider`}>An Aspira partner ordering on behalf of a healthcare provider &nbsp; </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div >
                            <div id="ignoreMeInPDF" class="row clearfix">
                                <div class="col-md-12">
                                    <div class="form-group">

                                        <button id={`${this.props.prefix}btnOrderSubmit`} placeholder="Submit" name="Submit" className="btn btn-primary text-white">Submit</button>

                                    </div>
                                </div>
                            </div>

                        </div >

                        {window.location.hostname !== 'lab.aspirawh.com' &&
                            <div className="col-md-6">
                                <div class="form-group">
                                    <label class="form-label" for="Signature_Pad">Your Signature </label>
                                    <div>
                                        <SignaturePad penColor='green'
                                            canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
                                            ref={(ref) => { this.sigPad = ref }}
                                            onEnd={() => {
                                                this.props.sigCollect(this.sigPad);
                                                if (this.props.captureSignature) this.props.captureSignature(this.sigPad.getTrimmedCanvas().toDataURL('image/png'));
                                            }}
                                        />
                                    </div>
                                    <div>
                                        <button className={"btn btn-light"} onClick={this.clear}>
                                            Clear
                                    </button>
                                        {/* <button className={"btn btn-light"} onClick={this.trim}>
                                            Trim
                                    </button> */}
                                    </div>
                                    {/* {trimmedDataURL
                                ? <img className={styles.sigImage}
                                    src={trimmedDataURL} />
                                : null} */}
                                </div>
                            </div>
                        }
                    </div >
                </div >
            </>
        );
    }
}
