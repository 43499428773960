import React, { useState, useEffect } from "react";
//import dotenv from "dotenv";
import axios from "axios";

import DeleteModal from "./../mycomponents/modal";
import ProgressBar from "./../mycomponents/progressbar";

import { protectedResources } from "./../authConfig";
import { MsalContext } from "@azure/msal-react";

import Spinner from "../Spinner/Spinner";

import helper from "./../utils/helper";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net-bs/css/dataTables.bootstrap.css";
import { message } from "antd";
const $ = require("jquery");
$.DataTable = require("datatables.net");



// require( 'datatables.net-bs' )( window, $ );

//dotenv.config();

const API_BASE_URL = helper.getAPIBaseURI();
let me = null;
export default class LegacyClientsContent extends React.PureComponent {
  static contextType = MsalContext;

  constructor(props) {
    super(props);

    this.state = {
      Legacyclients: [],
      loading: true,
      loading1: true,

      showLegacyClientModal: false,
      deleteLegacyClientId: null,
      showProgressBar: false,
      deleteLegacyClientName: "",
      accessToken: "",

      lastEvaluatedKey: null,
      searchText: null,
    };

    this.legacyclientDataTable = React.createRef();

    this.handleButtonPress = this.handleButtonPress.bind(this);
    this.handleButtonRelease = this.handleButtonRelease.bind(this);

    this.inpSearchRef = React.createRef();
    me = this;
  }
  handleButtonPress() {
    this.buttonPressTimer = setTimeout(
      () => this.setState({ showProgressBar: true }),
      1000
    );
  }

  handleButtonRelease() {
    clearTimeout(this.buttonPressTimer);
  }

  listenAndRespondToDataTableDrawEvent = (dataTableJQuerySelector) => {
    dataTableJQuerySelector.on("draw", () => {
      // scroll to the top of the parent container where data table lives after each table draw completes
      $('html, body').animate({
        scrollTop: $(".content").offset().top
      }, 'slow');
    });
  }

  async callDataTable(datatotables) {
    // first time, el will be null
    // see: https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs

    console.log("this.legacyclientDataTable: ", this.legacyclientDataTable);

    if (!this.legacyclientDataTable) return;

    // console.log("datatotables: ", datatotables);

    let $el = $(this.legacyclientDataTable);
    //  console.log(this.$el.DataTable);

    console.log();
    // =======
    // server-side datatable processing
    // =======
    const accessToken = await helper.getAccessToken(this.context)

    const table = $el.DataTable({
      pageLength: 25,
      columns: [
        {
          title: "Client Id",
          data: "clientId",
          name: "clientId",
          sortable: true,
          visible: true,
        },
        {
          title: "Name",
          data: "name",
          name: "name",
          width: "20px",
          sortable: true,
        },
        {
          title: "Email",
          data: "emailAddress",
          name: "emailAddress",
          width: "15px",
        },
        {
          title: "Office Phone",
          data: "officePhone",
          name: "officePhone",
          width: "15px",
        },
        {
          title: "Address",
          data: null,
          name: "address",
          width: "150px",
          render: (data, type) => {
            let address = `Address: ${data.addressLine1}, City: ${data.city}, State: ${data.state}, Zip: ${data.zip}`;

            if (type === "display") {
              return `<td>
                                <span class="">${address}</td>
                            </td> `;
            }
            return data;
          },
        },
        {
          title: "Fax",
          data: "fax",
          name: "fax",
          width: "10px",
          sortable: true,
        },
        {
          title: "Physician Count",
          data: null,
          name: "physicianCount",
          width: "10px",
          sortable: true,
          render: (data, type) => {

            if (type == 'display') {

              return `<td>${data.physicians.length}</td>`;
            }

            return data;
          },
        },
        {
          title: "Inactive",
          data: "inactive",
          name: "inactive",
        },
        {
          title: "",
          name: "",
          data: null,
          sortable: false,
          render: function (data, type) {
            if (type === "display") {
              console.log("pdfclass->data: ", data);

              let lockIcon = `<img style="width: 40; height: 40;" src="/icons/icons8-lock-50.svg" />`;
              if (data.isLocked) {
                lockIcon = `<img style="width: 40; height: 40;" src="/icons/icons8-padlock-50.svg" />`;
              }

              return `<td>
              <a class="rowOption editClass" href="/addeditclient?id=${data._id}&returnurl=/clientlist" data-id="${data._id}"><img src="/icons/icons8-edit-50.svg" /></a >
                                    <a data-id="${data._id}" data-name="${data.name}" id="option-delete" class="rowOption deleteClass"><img src="/icons/icons8-delete-trash-50.svg" /></a>
                                    <a id="option-lock" class="rowOption lockClass">${lockIcon}</a>
                                </td > `;
            }
            return data;
          },
        },

      ],
      data: datatotables,
      // serverSide: true,
      // deferRender: true,
      // ajax: {
      //   url: `${API_BASE_URL}/legacy/paginatedList`,
      //   type: "GET",
      //   headers: {
      //     "Content-type": "Application/json",
      //     Authorization: `Bearer ${accessToken}`,
      //   }
      // }
    });

    this.listenAndRespondToDataTableDrawEvent(table);


    $el.on("click", "tr a.deleteClass", function () {
      let item_id = $(this).data("id");
      let item_name = $(this).data("name");
      let yes = false;
      me.setState({
        showLegacyClientModal: true,
        deleteLegacyClientId: item_id,
        deleteLegacyClientName: item_name,
        accessToken: accessToken,
      });
    });

    // $el.on("click", "tr a.editClass", function () {
    //   let item_id = $(this).data("id");
    //   let item_prefix = $(this).data("val");
    //   let editHref = "";

    //   if (item_prefix == "Ova1") editHref = `/ editLegacyclient ? id = ${ item_id } `;
    //   else editHref = `/em_prefix }?id = ${ item_id }& returnurl=${ window.location.href } `;

    //   console.log("editHref: ", editHref);
    //   // console.log("me.props: ", me.props);
    //   // me.props.history.push(editHref);
    //   // return <Redirect to={editHref} />

    //   // me.context.router.history.push(editHref);

    //   window.location.href = editHref;
    // });
  }
  async componentDidMount() {
    await this.handlePaging();
  }

  handlePaging = async () => {
    const accessToken = await helper.getAccessToken(this.context)

    let _pageTitle = "Legacy Client List"

    message.loading("Loading Legacy client list ...");
    this.setState({ _pageTitle: _pageTitle, loading: true, }, () => {

      axios({
        url: `${API_BASE_URL}/legacy?lastEvaluatedKey=${this.state.lastEvaluatedKey ? this.state.lastEvaluatedKey.toString() : null}&pageSize=25&searchText=${this.state.searchText}`,
        method: "GET",
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${accessToken}`,
        },


      }).then(({ data }) => {
        console.log("data: ", data);

        this.setState({ loading: false, }, () => {
          this.callDataTable(data.clients);
        });
      });

    });
  }
  showModal = (e) => {
    this.setState({
      showLegacyClientModal: !this.state.showLegacyClientModal,
    });
  };
  render() {

    if (!this.state.loading) {
      return (
        <div>
          <div class="block-header">
            <h2>{this.state._pageTitle}</h2>
          </div>
          <div class="row clearfix">
            <div class="col-md-12 col-xs-12">
              <div class="card orders-list">
                <div class="body">

                  <button className="btn btn-raised g-bg-blue waves-effect" onClick={() => {
                    this.handlePaging();
                  }}>Load more</button>

                  <input onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      console.log("this.inpSearchRef: ", this.inpSearchRef);
                      this.setState({ searchText: this.inpSearchRef.value, lastEvaluatedKey: null }, async () => {
                        await this.handlePaging();

                        this.inpSearchRef.value = this.state.searchText;
                      });
                    }
                  }} style={{ marginLeft: "20px" }} className="" ref={(ref) => this.inpSearchRef = ref} type="text" id="inpSearch" name="inpSearch" value={this.state.searchText || ""} onChange={() => this.setState({ searchText: this.inpSearchRef.value })}></input>
                  <button className="btn btn-raised g-bg-blue waves-effect" onClick={() => {
                    console.log("this.inpSearchRef: ", this.inpSearchRef);
                    this.setState({ searchText: this.inpSearchRef.value, lastEvaluatedKey: null }, async () => {
                      await this.handlePaging();

                      this.inpSearchRef.value = this.state.searchText;
                    });
                  }}>Search</button>

                </div>
              </div>
            </div>
          </div>
          <div class="row clearfix">
            <div class="col-md-12 col-xs-12">
              <div class="card Legacyclients-list">
                <div class="body">
                  <div class="table-responsive">
                    <table
                      className="display"
                      width="100%"
                      ref={(el) => (this.legacyclientDataTable = el)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <DeleteModal
            onClose={this.showModal}
            show={this.state.showLegacyClientModal}
          >
            <div>
              <span>
                Hold delete button for 3 seconds in Legacyclient to confirm delete
                Client {this.state.deleteLegacyClientName}
              </span>
            </div>
            <div class="delete-buttons">
              <button
                class="btn waves-effect btn-delete"
                onTouchStart={this.handleButtonPress}
                onTouchEnd={this.handleButtonRelease}
                onMouseDown={this.handleButtonPress}
                onMouseUp={this.handleButtonRelease}
                onMouseLeave={this.handleButtonRelease}
              // onClick={this.deletePatient}
              >
                Delete
              </button>
              <button
                class="btn btn-raised g-bg-blue waves-effect"
                onClick={this.showModal}
              >
                Cancel
              </button>
            </div>
            <div>
              {this.state.showProgressBar && (
                <ProgressBar accessToken={this.state.accessToken} entity={"Legacyclient"} id={this.state.deleteLegacyClientId} />
              )}
            </div>
          </DeleteModal>
        </div>
      );
    } else {
      return (
        <div>
          <div class="block-header">
            <h2>LegacyClients</h2>
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/Legacyclients">LegacyClients</a>
              </li>
              <li class="breadcrumb-item active">View All LegacyClients</li>
            </ul>
          </div>
          <div class="row clearfix">
            <div class="col-md-12 col-xs-12">
              <div class="card Legacyclients-list">
                <div class="body"></div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
