import React, { PureComponent } from "react";

//include template related files
import PageLoader from "./layout/PageLoader";
import Search from "./layout/Search";
import TopBar from "./layout/TopBar";
import LeftSidebar from "./layout/LeftSidebar";
import RightSidebar from "./layout/RightSidebar";
import FooterContent from "./layout/FooterContent";
import OnHoldReportContent from "./components/OnHoldReportContent";


import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';

//require("dotenv").config();
export default class OnHoldReport extends PureComponent {

    constructor(props) {
        super(props)
    
        this.state = {
          timeout: process.env.REACT_APP_LOGIN_TIMEOUT,
          showModal: false,
          orderLoggedIn: false,
          isTimedOut: false
        }
    
        this.idleTimer = null
        this.onAction = this._onAction.bind(this)
        this.onActive = this._onActive.bind(this)
        this.onIdle = this._onIdle.bind(this)
        this.handleClose = this.handleClose.bind(this)
        this.handleLogout = this.handleLogout.bind(this)
    }
    
    _onAction(e) {
      console.log('order did something', e)
      this.setState({ isTimedOut: false })
    }
    
    _onActive(e) {
      console.log('order is active', e)
      this.setState({ isTimedOut: false })
    }
    
    _onIdle(e) {
      console.log('order is idle', e)
      const isTimedOut = this.state.isTimedOut
      if (isTimedOut) {
        localStorage.clear();
        if (process.env.REACT_APP_ENV === 'DEV') {
      this.props.history.push('/')
    }
    else {
      this.props.history.push('/dashboard')
    }
      } else {
        this.setState({ showModal: true })
        this.idleTimer.reset();
        this.setState({ isTimedOut: true })
      }
  
    }
    
    handleClose() {
      this.setState({ showModal: false })
    }
    
    handleLogout() {
      this.setState({ showModal: false });
  
      localStorage.clear();
      if (process.env.REACT_APP_ENV === 'DEV') {
      this.props.history.push('/')
    }
    else {
      this.props.history.push('/dashboard')
    }
    }

    render() {
      return (
        <>
          <IdleTimer
            ref={ref => { this.idleTimer = ref }}
            element={document}
            onActive={this.onActive}
            onIdle={this.onIdle}
            onAction={this.onAction}
            debounce={250}
            timeout={this.state.timeout} />
          <div>
            {/*  Page Loader  */}
            <PageLoader />
            {/*  Overlay For Sidebars  */}
            <div class="overlay"></div>
  
            {/* Search */}
            <Search />
  
            {/* #Top Bar   */}
            <TopBar />
  
            {/* Left Sidebar */}
            <LeftSidebar />
  
            {/* Right Sidebar */}
            <RightSidebar />
  
            {/* Main Content Area */}
            <section class="content home content-user">
              <div class="container-fluid">
                <OnHoldReportContent />
                {/* Footer Content  */}
                <FooterContent/>
              </div>{/* container-fluid */}
            </section>
            
          </div>
        </>
      );
    }
}

OnHoldReport.propTypes = {
  match: PropTypes.any.isRequired,
  history: PropTypes.func.isRequired
}