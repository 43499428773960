/* eslint-disable react/jsx-pascal-case */
import React, { createRef, useState } from "react";

import axios from "axios";
import Select from "react-select";
import { protectedResources } from "./../authConfig";
import { MsalContext } from "@azure/msal-react";
import $ from "jquery";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AsyncSelect from "react-select/async";
import moment from "moment";

import { message } from "antd";

import Toast from "../mycomponents/toast/Toast";
import helper from "../utils/helper";

import CmtForm from "./CmtForm";
import BillingNotes from "./BillingNotes";

import OrderHeader from "./add_edit_order_components/Order_header";
import PhysicianInformation from "./add_edit_order_components/PhysicianInformation";
import PatientInformation from "./add_edit_order_components/PatientInformation";
import Clinical_Information from "./add_edit_order_components/Clinical_Information";
import Billing_Information from "./add_edit_order_components/Billing_Information";
import Notes_and_Certify from "./add_edit_order_components/Notes_and_Certify";
import Kit_Shipping_Information from "./add_edit_order_components/Kit_Shipping_Information";
import TopActions from "./add_edit_order_components/TopActions";

const API_BASE_URL = helper.getAPIBaseURI();

//for billing logic
const showInsuranceTitle = () => {
  $(`#title_insurance_container`).removeClass(" hidediv");
  $(`#title_insurance_container`).addClass(" showdiv");
};

const hideInsuranceTitle = () => {
  $(`#title_insurance_container`).addClass(" hidediv");
  $(`#title_insurance_container`).removeClass(" showdiv");
};

const showPayorListSearchAndSelect = () => {
  $("#payorListSearchAndSelect").removeClass(" hidediv");
  $("#payorListSearchAndSelect").addClass(" showdiv");
};

const hidePayorListSearchAndSelect = () => {
  $("#payorListSearchAndSelect").removeClass(" showdiv");
  $("#payorListSearchAndSelect").addClass(" hidediv");
};

const showPrimaryAreaClick = () => {
  $(`#primary_insurance_container`).removeClass(" hidediv");
  $(`#primary_insurance_container`).addClass(" showdiv");
};
const hidePrimaryAreaClick = () => {
  $(`#primary_insurance_container`).addClass(" hidediv");
  $(`#primary_insurance_container`).removeClass(" showdiv");
};
const showSecondaryAreaClick = () => {
  $(`#secondary_insurance_container`).removeClass(" hidediv");
  $(`#secondary_insurance_container`).addClass(" showdiv");
};
const hideSecondaryAreaClick = () => {
  $(`#secondary_insurance_container`).addClass(" hidediv");
  $(`#secondary_insurance_container`).removeClass(" showdiv");
};

const showInsuranceInsured = () => {
  $(`#insured_info_container`).removeClass(" hidediv");
  $(`#insured_info_container`).addClass(" showdiv");
};

const hideInsuranceInsured = () => {
  $(`#insured_info_container`).addClass(" hidediv");
  $(`#insured_info_container`).removeClass(" showdiv");
};
const BillingInformationChechAll = () => {
  var billinfo_PrivateInsurance = document.getElementById(
    `billinfo_PrivateInsurance`
  );
  var billinfo_Medicare = document.getElementById(`billinfo_Medicare`);
  var billinfo_PatientSelfPay = document.getElementById(
    `billinfo_PatientSelfPay`
  );
  var billinfo_Medicaid = document.getElementById(`billinfo_Medicaid`);
  var billinfo_OrderingFacility = document.getElementById(
    `billinfo_OrderingFacility`
  );

  if (billinfo_PrivateInsurance.checked == true) {
    showInsuranceTitle();
    showPrimaryAreaClick();
    showSecondaryAreaClick();
    showInsuranceInsured();
  } else if (billinfo_Medicare.checked == true) {
    showInsuranceTitle();
    showPrimaryAreaClick();
    hideSecondaryAreaClick();
    showInsuranceInsured();
  } else if (billinfo_Medicaid.checked == true) {
    showInsuranceTitle();
    showPrimaryAreaClick();
    hideSecondaryAreaClick();
    showInsuranceInsured();
  } else if (billinfo_PatientSelfPay.checked == true) {
    hidePrimaryAreaClick();
    hideSecondaryAreaClick();
    hideInsuranceTitle();
    hideInsuranceInsured();
  } else {
    hideInsuranceTitle();
    hidePrimaryAreaClick();
    hideSecondaryAreaClick();
    hideInsuranceInsured();
  }
};

const BillingInformationPrivateInsuranceClick = () => {
  var billinfo_PrivateInsurance = document.getElementById(
    `billinfo_PrivateInsurance`
  );
  document.getElementById(`billinfo_PatientSelfPay`).checked = false;
  document.getElementById(`billinfo_Medicaid`).checked = false;
  document.getElementById(`billinfo_OrderingFacility`).checked = false;
  document.getElementById(`billinfo_FreeTrial`).checked = false;

  if (billinfo_PrivateInsurance.checked == true) {
    showPrimaryAreaClick();
    showSecondaryAreaClick();
    showPayorListSearchAndSelect();
  } else {
    hidePrimaryAreaClick();
    hideSecondaryAreaClick();
    hidePayorListSearchAndSelect();
  }

  BillingInformationChechAll();
};

const BillingInformationMedicareClick = () => {
  var billinfo_Medicare = document.getElementById(`billinfo_Medicare`);
  document.getElementById(`billinfo_PatientSelfPay`).checked = false;
  document.getElementById(`billinfo_Medicaid`).checked = false;
  document.getElementById(`billinfo_OrderingFacility`).checked = false;
  document.getElementById(`billinfo_FreeTrial`).checked = false;

  if (billinfo_Medicare.checked == true) {
    showPrimaryAreaClick();
    //showSecondaryAreaClick();
    showPayorListSearchAndSelect();
  } else {
    hidePrimaryAreaClick();
    hideSecondaryAreaClick();
    hidePayorListSearchAndSelect();
  }
  BillingInformationChechAll();
};
const BillingInformationPatientSelfPayClick = () => {
  var billinfo_PatientSelfPay = document.getElementById(
    `billinfo_PatientSelfPay`
  );

  document.getElementById(`billinfo_PrivateInsurance`).checked = false;
  document.getElementById(`billinfo_Medicare`).checked = false;
  document.getElementById(`billinfo_Medicaid`).checked = false;
  document.getElementById(`billinfo_OrderingFacility`).checked = false;
  document.getElementById(`billinfo_FreeTrial`).checked = false;

  if (billinfo_PatientSelfPay.checked == true) {
    hidePrimaryAreaClick();
    hideSecondaryAreaClick();
    hidePayorListSearchAndSelect();
  }
  BillingInformationChechAll();
};
const BillingInformationFreeTrialClick = () => {
  var billinfo_FreeTrial = document.getElementById(`billinfo_FreeTrial`);

  document.getElementById(`billinfo_PrivateInsurance`).checked = false;
  document.getElementById(`billinfo_Medicare`).checked = false;
  document.getElementById(`billinfo_PatientSelfPay`).checked = false;
  document.getElementById(`billinfo_Medicaid`).checked = false;
  document.getElementById(`billinfo_OrderingFacility`).checked = false;

  if (billinfo_FreeTrial.checked == true) {
    hidePrimaryAreaClick();
    hideSecondaryAreaClick();
    // hideTertiaryAreaClick();
    hidePayorListSearchAndSelect();
  }
  BillingInformationChechAll();
};
const BillingInformationMedicaidClick = () => {
  var billinfo_Medicaid = document.getElementById(`billinfo_Medicaid`);

  document.getElementById(`billinfo_PrivateInsurance`).checked = false;
  document.getElementById(`billinfo_Medicare`).checked = false;
  document.getElementById(`billinfo_PatientSelfPay`).checked = false;
  document.getElementById(`billinfo_OrderingFacility`).checked = false;
  document.getElementById(`billinfo_FreeTrial`).checked = false;

  if (billinfo_Medicaid.checked == true) {
    showPrimaryAreaClick();
    //showSecondaryAreaClick();
    showPayorListSearchAndSelect();
  } else {
    hidePrimaryAreaClick();
    hideSecondaryAreaClick();
    hidePayorListSearchAndSelect();
  }
  BillingInformationChechAll();
};
const BillingInformationOrderingFacilityClick = () => {
  var billinfo_OrderingFacility = document.getElementById(
    `billinfo_OrderingFacility`
  );

  document.getElementById(`billinfo_PrivateInsurance`).checked = false;
  document.getElementById(`billinfo_Medicare`).checked = false;
  document.getElementById(`billinfo_PatientSelfPay`).checked = false;
  document.getElementById(`billinfo_Medicaid`).checked = false;
  document.getElementById(`billinfo_FreeTrial`).checked = false;

  if (billinfo_OrderingFacility.checked == true) {
    hidePrimaryAreaClick();
    hideSecondaryAreaClick();
  }
  BillingInformationChechAll();
};
//for billing logic
const makeUltrasoundResultsCheckboxesBehaveLikeRadioInputs = () => {
  $(`#clinicinfo_lowrisk`).change(() => {
    if ($(`#clinicinfo_lowrisk`).is(":checked")) {
      $(`#clinicinfo_highrisk`).prop("checked", false);
      $(`#clinicinfo_NotDefinitive`).prop("checked", false);
    }
  });

  $(`#clinicinfo_highrisk`).change(() => {
    if ($(`#clinicinfo_highrisk`).is(":checked")) {
      $(`#clinicinfo_lowrisk`).prop("checked", false);
      $(`#clinicinfo_NotDefinitive`).prop("checked", false);
    }
  });

  $(`#clinicinfo_NotDefinitive`).change(() => {
    if ($(`#clinicinfo_NotDefinitive`).is(":checked")) {
      $(`#clinicinfo_lowrisk`).prop("checked", false);
      $(`#clinicinfo_highrisk`).prop("checked", false);
    }
  });
};

const ShowPatienAbdomenPainAreaClick = () => {
  const clinicinfo_pain_abdomen_pelvis = document.getElementById(
    `clinicinfo_pain_abdomen_pelvis`
  );

  if (clinicinfo_pain_abdomen_pelvis.checked == true) {
    $(`#PatienAbdomenPainArea`).addClass(" showPatienAbdomenPainArea");
    $(`#PatienAbdomenPainArea`).removeClass(" hidePatienAbdomenPainArea");
  }
};

const HidePatienAbdomenPainAreaClick = () => {
  const clinicinfo_pain_abdomen_pelvis2 = document.getElementById(
    `clinicinfo_pain_abdomen_pelvis2`
  );

  if (clinicinfo_pain_abdomen_pelvis2.checked == true) {
    $(`#PatienAbdomenPainArea`).removeClass(" showPatienAbdomenPainArea");
    $(`#PatienAbdomenPainArea`).addClass(" hidePatienAbdomenPainArea");
  }
};

const ShowPatienfullQuicklyUnableToeAreaClick = () => {
  const clinicinfo_fullQuickly_unableToe = document.getElementById(
    `clinicinfo_fullQuickly_unableToe`
  );

  if (clinicinfo_fullQuickly_unableToe.checked == true) {
    $(`#PatienfullQuicklyUnableToeArea`).addClass(" showPatienAbdomenPainArea");
    $(`#PatienfullQuicklyUnableToeArea`).removeClass(
      " hidePatienAbdomenPainArea"
    );
  }
};

const HidePatienfullQuicklyUnableToeAreaClick = () => {
  const clinicinfo_fullQuickly_unableToe2 = document.getElementById(
    `clinicinfo_fullQuickly_unableToe2`
  );

  if (clinicinfo_fullQuickly_unableToe2.checked == true) {
    $(`#PatienfullQuicklyUnableToeArea`).removeClass(
      " showPatienAbdomenPainArea"
    );
    $(`#PatienfullQuicklyUnableToeArea`).addClass(" hidePatienAbdomenPainArea");
  }
};

const ShowPatienExpabdominalbloatAreaClick = () => {
  const clinicinfo_Expabdominalbloat_IncrSize = document.getElementById(
    `clinicinfo_Expabdominalbloat_IncrSize`
  );

  if (clinicinfo_Expabdominalbloat_IncrSize.checked == true) {
    $(`#PatienExpabdominalbloatArea`).addClass(" showPatienAbdomenPainArea");
    $(`#PatienExpabdominalbloatArea`).removeClass(" hidePatienAbdomenPainArea");
  }
};

const HidePatienExpabdominalbloatAreaClick = () => {
  const clinicinfo_Expabdominalbloat_IncrSize2 = document.getElementById(
    `clinicinfo_Expabdominalbloat_IncrSize2`
  );

  if (clinicinfo_Expabdominalbloat_IncrSize2.checked == true) {
    $(`#PatienExpabdominalbloatArea`).removeClass(" showPatienAbdomenPainArea");
    $(`#PatienExpabdominalbloatArea`).addClass(" hidePatienAbdomenPainArea");
  }
};

let me = null;
export default class AddEditOrderContent extends React.PureComponent {
  static contextType = MsalContext;

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      patients: [],
      profileImage: null,
      profileImagePreview: null,
      selectedPhysicianState: "",

      toastMessage: "",
      list: [],
      toastList: [],
      orderData: [],

      firstName: "",
      lastName: "",
      testOrdered: "",
      buttonText: "Create",
      frmprefix: "OvaWatch_",

      //physician info
      phyinfo_Organization_account: "",
      phyinfo_first_name: "",
      phyinfo_last_name: "",
      phyinfo_email: "",
      phyinfo_npi: "",
      phyinfoC_address: "",
      phyinfoC_practiceLocation: "",
      phyinfoC_city: "",
      phyinfo_state: "",
      phyinfoC_zipCode: "",
      phyinfoC_Phone: "",
      phyinfoC_fax: "",
      phyinfoC_Faxcopyto: "",

      //patient info
      patinfo_firstname: "",
      patinfo_lastname: "",
      patinfo_address: "",
      selectedState: "ALABAMA",
      patinfo_State: "",
      patinfo_city: "",
      patinfo_zipcode: "",
      patinfo_dob: "",
      patinfo_PhoneNumber: "",
      patinfo_emailaddress: "",
      patinfo_OtherEthnicitycity: "",
      patinfo_ethnicity: '',
      ethnicityArray: {},
      patinfo_gender: "",

      //clinical info Start
      clinicinfo_DoLastMentrualPeriod: null,
      // questionCode - OvaWatch_q01
      clinicinfo_Menopausal_status: null,
      // questionCode - OvaWatch_q02
      ultrasound_results_q02: null,
      // questionCode - OvaWatch_q04
      clinicinfo_MassSize_longdime: null,
      clinicinfo_MassSize_Height: null,
      clinicinfo_MassSize_Height_unit: null,
      clinicinfo_MassSize_Weight: null,
      clinicinfo_MassSize_Weight_unit: null,
      // questionCode - OvaWatch_q05
      clinicinfo_pain_abdomen_pelvis: false,
      days_per_month_q05_1: null,
      how_long_month_q05_2: null,
      // questionCode - OvaWatch_q06
      clinicinfo_fullQuickly_unableToe: false,
      days_per_month_q06_1: null,
      how_long_month_q06_2: null,
      // questionCode - OvaWatch_q07
      clinicinfo_Expabdominalbloat_IncrSize: false,
      days_per_month_q07_1: null,
      how_long_month_q07_2: null,
      //clinical info End

      //billing info
      /*  billinfo_PrimaryInsuranceCarrier: "",
       billinfo_planId: "",
       billinfo_MemberID: "",
       billinfo_GroupID: "",
       billinfo_SecondaryInsuranceCarrier: "",
       billinfo_SecondaryplanId: "",
       billinfo_SecondaryMemberID: "",
       billinfo_SecondaryGroupID: "", */
      billinfo_PrimaryInsuranceCarrier: "",
      billinfo_planId: "",
      billinfo_MemberID: "",
      billinfo_GroupID: "",

      billinfo_SecondaryInsuranceCarrier: "",
      billinfo_SecondaryplanId: "",
      billinfo_SecondaryMemberID: "",
      billinfo_SecondaryGroupID: "",
      /*  billinfo_NameofInsured: "",
       billinfoC_dob: "",
       billinfo_RelationshipInsured: "", */
      billinfo_FirstNameofInsured: "",
      billinfo_LastNameofInsured: "",
      billinfoC_dob: "",
      billinfo_RelationshipInsured: "",
      //edit billing portion
      privateInsurance: false,
      patientSelfPay: false,
      medicare: false,
      medicaid: false,
      orderingFacility: false,
      freeTrial: false,
      isBillableOrder: false,

      OtherICD10Codes: [],
      otherICD10Code: '',
      kitShip_notes: "",
      kitShip_PatMobPhlebotomy: null,
      kitShip_PatblooddrawQuest: null,
      kitShip_PatOthers: null,
      kitShip_PatOthersInfo: "",

      clinicalData: [],

      //------------
      patientId: '',
      physicianId: '',

      updatedAt: Date.now(),
    };

    this.firstName = React.createRef();
    this.lastName = React.createRef();
    this.testOrdered = React.createRef();

    //physician
    this.phyinfo_Organization_account = React.createRef();
    this.phyinfo_first_name = React.createRef();
    this.phyinfo_last_name = React.createRef();
    this.phyinfo_email = React.createRef();
    this.phyinfo_npi = React.createRef();
    this.phyinfoC_address = React.createRef();
    this.phyinfoC_practiceLocation = React.createRef();
    this.phyinfoC_city = React.createRef();
    this.phyinfo_state = React.createRef();
    this.phyinfoC_zipCode = React.createRef();
    this.phyinfoC_Phone = React.createRef();
    this.phyinfoC_fax = React.createRef();
    this.phyinfoC_Faxcopyto = React.createRef();

    //patient info
    this.patinfo_firstname = React.createRef();
    this.patinfo_lastname = React.createRef();
    this.patinfo_address = React.createRef();
    this.patinfo_State = React.createRef();
    this.patinfo_city = React.createRef();
    this.patinfo_zipcode = React.createRef();
    this.patinfo_dob = React.createRef();
    this.patinfo_PhoneNumber = React.createRef();
    this.patinfo_emailaddress = React.createRef();
    this.patinfo_OtherEthnicitycity = React.createRef();
    this.patinfo_gender = React.createRef();

    //billing info
    /* this.billinfo_PrimaryInsuranceCarrier = React.createRef();
    this.billinfo_planId = React.createRef();
    this.billinfo_MemberID = React.createRef();
    this.billinfo_GroupID = React.createRef();
    this.billinfo_SecondaryInsuranceCarrier = React.createRef();
    this.billinfo_SecondaryplanId = React.createRef();
    this.billinfo_SecondaryMemberID = React.createRef();
    this.billinfo_SecondaryGroupID = React.createRef(); */


    this.billinfo_PrimaryInsuranceCarrier = React.createRef();
    this.billinfo_planId = React.createRef();
    this.billinfo_MemberID = React.createRef();
    this.billinfo_GroupID = React.createRef();

    this.billinfo_SecondaryInsuranceCarrier = React.createRef();
    this.billinfo_SecondaryplanId = React.createRef();
    this.billinfo_SecondaryMemberID = React.createRef();
    this.billinfo_SecondaryGroupID = React.createRef();


    /* this.billinfo_NameofInsured = React.createRef();
    this.billinfoC_dob = React.createRef();
    this.billinfo_RelationshipInsured = React.createRef(); */
    this.billinfo_FirstNameofInsured = React.createRef();
    this.billinfo_LastNameofInsured = React.createRef();

    this.billinfoC_dob = React.createRef();
    this.billinfo_RelationshipInsured = React.createRef();
    this.OtherICD10Codes = React.createRef();
    this.kitShip_PatOthersInfo = React.createRef();
    this.kitShip_notes = React.createRef();

    this.orderId = null;
    this._id = null;

    me = this;
  }

  async componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log("user.token: ", user.token);

    const accessToken = await helper.getAccessToken(this.context)

    axios.interceptors.response.use(
      function (response) {
        // Do something with response data
        return response;
      },
      function (error) {
        switch (error.response.status) {
          case 409:
            console.log("interceptors->error: ", error);

            me.setState(
              {
                toastMessage: `An Order with the same already exists`,
                loading: false,
              },
              () => {
                me.danger_failed.click();
              }
            );
            break;

          default:
            break;
        }
        // Do something with response error
        return Promise.reject(error);
      }
    );

    axios({
      url: `${API_BASE_URL}/patients`,
      method: "GET",
      headers: {
        "Content-type": "Application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then(async ({ data }) => {
        console.log("patients data: ", data);

        let patients = [];
        data.patients.map((item) => {
          patients.push({
            label: `${item.firstName} ${item.lastName}`,
            value: item._id,
          });
        });

        this.setState(
          {
            loading: true,
            // patients: patients,
          },
          async () => {
            console.log(window.location.search);
            const params = new URLSearchParams(window.location.search);
            this._id = params.get("id");

            console.log("this._id: ", this._id);

            if (this._id) {
              //Edit Mode
              const user = JSON.parse(localStorage.getItem("user"));

              let response = await axios({
                url: `${API_BASE_URL}/orders/${this._id}`,
                method: "GET",
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                  "Content-type": "Application/json",
                },
              });
              console.log("before response.data: ", response.data);
              response.data = response.data.orders[0];
              let orderObj = response.data;

              // check if its an order that is billable i.e. not flagged as RUO - Research Use Only
              const isBillableOrder =
                response.data.researchUseOnly === "Yes" ? false : true;

              //assays data
              let _assays_OvaWatch = response.data.assays.filter(
                (x) => x.assayType.indexOf("OvaWatch") > -1
              );
              console.log("_assays_OvaWatch: ", _assays_OvaWatch);

              let _assays_content = `<div class="row clearfix">
                        <div class="col-md-12 demo-checkbox">
                        <input data-val="${_assays_OvaWatch[0].name}" data-qbenchid="${_assays_OvaWatch[0].qBenchId}" type="checkbox" id="_OvaWatch" name="_OvaWatch" class="filled-in" disabled />
                         <label for="_OvaWatch"><b style="color: #444;">${_assays_OvaWatch[0].name}</b> &nbsp; </label><br />
                        </div>
                    </div>`;

              //assays data

              let patinfo_dobFormatted = "";
              if (response.data.Patient.dob) {
                patinfo_dobFormatted = helper.formatDate(
                  response.data.Patient.dob
                );
              }

              let billinfoC_dobFormatted = "";

              if (response.data.billingInfo.insuredInfo.dob) {
                billinfoC_dobFormatted = helper.formatDate(
                  response.data.billingInfo.insuredInfo.dob
                );
              }

              console.log(
                "patinfo_dobFormatted: ",
                patinfo_dobFormatted,
                "billinfoC_dobFormatted: ",
                billinfoC_dobFormatted
              );

              // load payor list
              let _url = `${API_BASE_URL}/payors`;
              let _options = {
                url: _url,
                method: "GET",
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              };

              let axiosResponse = await axios(_options);
              let assignedPayor = {};
              let payorInfo = {};
              const allPayors = axiosResponse.data.payors.map((payor) => {
                let { payorId, payorName, payorContactAddress } = payor;
                payorInfo = {
                  label: `${payorId}, ${payorName}, ${payorContactAddress}`,
                  value: payorId,
                };

                if (orderObj.payorId && orderObj.payorId === payor.payorId)
                  assignedPayor = payorInfo;

                return payorInfo;
              });

              me.setState(
                {
                  patinfo_dob: patinfo_dobFormatted,
                  billinfoC_dob: billinfoC_dobFormatted,
                  orderData: response.data,
                  frmprefix: response.data.tests[0].testName + "_",
                  patientId: response.data.Patient._id,
                  physicianId: response.data.Physician._id,
                  _assays_content: _assays_content,
                  isBillableOrder,
                  allPayors,
                  selectedPayor: assignedPayor,
                  loading: false,
                },
                () => {
                  //assays data print
                  $("#printHere").html(this.state._assays_content);
                  makeUltrasoundResultsCheckboxesBehaveLikeRadioInputs();


                  // this.patientId = response.data.Patient._id;
                  // this.physicianId = response.data.Physician._id;

                  // console.log(
                  //   "this.patientId: ",
                  //   this.patientId,
                  //   ", this.physicianId: ",
                  //   this.physicianId
                  // );

                  console.log(
                    "response.data.Patient.firstName: ",
                    response.data.Patient.firstName
                  );

                  me.orderId = response.data.orderId;

                  //Physician
                  /*me.phyinfo_Organization_account.value =
                    response.data.Physician.organizationAccount;
                  me.phyinfo_first_name.value =
                    response.data.Physician.firstName;
                  me.phyinfo_last_name.value = response.data.Physician.lastName;
                  me.phyinfo_email.value = response.data.Physician.email;
                  me.phyinfo_npi.value = response.data.Physician.npiNumber;
                  me.phyinfoC_address.value =
                    response.data.Physician.addresses[0].address_1;
                  me.phyinfoC_practiceLocation.value =
                    response.data.Physician.practiceLocationPlain;
                  me.phyinfoC_city.value =
                    response.data.Physician.addresses[0].city;
                  me.phyinfo_state.value =
                    response.data.Physician.addresses[0].state;
                  me.phyinfoC_zipCode.value =
                    response.data.Physician.addresses[0].zipCode;
                  me.phyinfoC_Phone.value =
                    response.data.Physician.addresses[0].phoneNo;
                  me.phyinfoC_fax.value =
                    response.data?.Physician?.addresses?.[0]?.fax || "";
                  me.phyinfoC_Faxcopyto.value =
                    response.data?.Physician?.addresses?.[0]?.faxCopyTo || "";*/

                  //Patient Information
                  //ethnicity array
                  const ethnicityArray1 = response.data.Patient.ethnicity
                    ? response.data.Patient.ethnicity.split(",")
                    : [];
                  /*me.patinfo_firstname.value = response.data.Patient.firstName;
                  me.patinfo_lastname.value = response.data.Patient.lastName;
                  me.patinfo_address.value =
                    response.data.Patient.address.address_1;
                  me.patinfo_State.value = response.data.Patient.address.state;
                  me.patinfo_city.value = response.data.Patient.address.city;
                  me.patinfo_zipcode.value =
                    response.data.Patient.address.zipCode;
                  me.patinfo_PhoneNumber.value = response.data.Patient.phoneNo;
                  me.patinfo_emailaddress.value = response.data.Patient.email;
                  me.patinfo_OtherEthnicitycity.value = "";*/
                  //Patient information ethnicity
                  if (
                    response.data.Patient.ethnicity &&
                    response.data.Patient.ethnicity.includes("Caucasian")
                  ) {
                    $("#patinfo_Caucasian").prop("checked", true);
                  }
                  if (
                    response.data.Patient.ethnicity &&
                    response.data.Patient.ethnicity.includes("Ashkenazi Jewish")
                  ) {
                    $("#patinfo_AshkenaziJewish").prop("checked", true);
                  }
                  if (
                    response.data.Patient.ethnicity &&
                    response.data.Patient.ethnicity.includes("Sephardic Jewish")
                  ) {
                    $("#patinfo_SephardicJewish").prop("checked", true);
                  }
                  if (
                    response.data.Patient.ethnicity &&
                    response.data.Patient.ethnicity.includes("Asian")
                  ) {
                    $("#patinfo_Asian").prop("checked", true);
                  }
                  if (
                    response.data.Patient.ethnicity &&
                    response.data.Patient.ethnicity.includes("Hispanic")
                  ) {
                    $("#patinfo_HIspanic").prop("checked", true);
                  }
                  if (
                    response.data.Patient.ethnicity &&
                    response.data.Patient.ethnicity.includes("Native American")
                  ) {
                    $("#patinfo_NativeAmerican").prop("checked", true);
                  }
                  if (
                    response.data.Patient.ethnicity &&
                    response.data.Patient.ethnicity.includes("African American")
                  ) {
                    $("#patinfo_AfricanAmerican").prop("checked", true);
                  }
                  if (
                    response.data.Patient.ethnicity &&
                    response.data.Patient.ethnicity.includes("N/A")
                  ) {
                    $("#patinfo_na").prop("checked", true);
                  }
                  if (
                    response.data.Patient.ethnicity &&
                    response.data.Patient.ethnicity.includes("Other")
                  ) {
                    $("#patinfo_Other").prop("checked", true);
                    this.state.patinfo_OtherEthnicitycity =
                      ethnicityArray1[ethnicityArray1.length - 1];
                  }

                  //Patient Gender
                  //console.log("gender: ",response.data.Patient.gender);
                  if (response.data.Patient.gender === "male") {
                    $(`#${this.state.frmprefix}patinfo_gender`).prop(
                      "checked",
                      true
                    );
                  } else {
                    $(`#${this.state.frmprefix}patinfo_gender2`).prop(
                      "checked",
                      true
                    );
                  }

                  // Fill up clinical information
                  const clinicalData =
                    this.state.orderData.tests?.[0]?.clinicalData || [];
                  let lastMenstrualDate = "";
                  console.log('sm: clinicalData: ', clinicalData)
                  for (let questionAndAnswerPair of clinicalData) {
                    let {
                      questionCode,
                      answer,
                      subQuestion = [],
                    } = questionAndAnswerPair;

                    if (questionCode.toLowerCase() === "ovawatch_q01") {
                      this.setState({ clinicinfo_Menopausal_status: answer })
                    }

                    if (questionCode.toLowerCase() === "ovawatch_q02") {
                      this.setState({ ultrasound_results_q02: answer })
                    }

                    if (questionCode.toLowerCase() === "ovawatch_q03")
                      lastMenstrualDate = helper.formatDate(answer);

                    if (questionCode.toLowerCase() === "ovawatch_q04") {
                      this.setState({ clinicinfo_MassSize_longdime: answer })

                      for (let subQuestionAnswerPair of subQuestion) {
                        let { questionCode: subQuestionCode, answer: subAnswer } = subQuestionAnswerPair;

                        if (subQuestionCode.toLowerCase() === "ovawatch_q04_1") {
                          let answerParts = subAnswer.split(" ");
                          this.setState({ clinicinfo_MassSize_Height: answerParts[0] })
                          this.setState({ clinicinfo_MassSize_Height_unit: answerParts[1] })
                        }

                        if (subQuestionCode.toLowerCase() === "ovawatch_q04_2") {
                          let answerParts = subAnswer.split(" ");
                          this.setState({ clinicinfo_MassSize_Weight: answerParts[0] })
                          this.setState({ clinicinfo_MassSize_Weight_unit: answerParts[1] })
                        }
                      }
                    }

                    if (questionCode.toLowerCase() === "ovawatch_q05") {
                      if (answer === "true") {
                        this.setState({ clinicinfo_pain_abdomen_pelvis: true })
                        for (let subQuestionAnswerPair of subQuestion) {
                          let {
                            questionCode: subQuestionCode,
                            answer: subAnswer,
                          } = subQuestionAnswerPair;

                          if (subQuestionCode.toLowerCase() === "ovawatch_q05_1")
                            this.setState({ days_per_month_q05_1: subAnswer })
                          if (subQuestionCode.toLowerCase() === "ovawatch_q05_2")
                            this.setState({ how_long_month_q05_2: subAnswer })
                        }
                      } else if (answer === "false")
                        this.setState({ clinicinfo_pain_abdomen_pelvis: false })
                    }

                    if (questionCode.toLowerCase() === "ovawatch_q06") {
                      if (answer === "true") {
                        this.setState({ clinicinfo_fullQuickly_unableToe: true })
                        for (let subQuestionAnswerPair of subQuestion) {
                          let {
                            questionCode: subQuestionCode,
                            answer: subAnswer,
                          } = subQuestionAnswerPair;

                          if (subQuestionCode.toLowerCase() === "ovawatch_q06_1")
                            this.setState({ days_per_month_q06_1: subAnswer })
                          if (subQuestionCode.toLowerCase() === "ovawatch_q06_2")
                            this.setState({ how_long_month_q06_2: subAnswer })
                        }
                      } else if (answer === "false")
                        this.setState({ clinicinfo_fullQuickly_unableToe: false })
                    }

                    if (questionCode.toLowerCase() === "ovawatch_q07") {
                      if (answer === "true") {
                        this.setState({ clinicinfo_Expabdominalbloat_IncrSize: true })
                        for (let subQuestionAnswerPair of subQuestion) {
                          let {
                            questionCode: subQuestionCode,
                            answer: subAnswer,
                          } = subQuestionAnswerPair;

                          if (subQuestionCode.toLowerCase() === "ovawatch_q07_1")
                            this.setState({ days_per_month_q07_1: subAnswer })
                          if (subQuestionCode.toLowerCase() === "ovawatch_q07_2")
                            this.setState({ how_long_month_q07_2: subAnswer })
                        }
                      } else if (answer === "false")
                        this.setState({ clinicinfo_Expabdominalbloat_IncrSize: false })
                    }
                  }

                  //billing information
                  /*if (this.state.isBillableOrder) {
                    me.billinfo_PrimaryInsuranceCarrier.value =
                      response.data.billingInfo.primaryInsurance.primaryInsuranceCarrier;
                    // me.billinfo_planId.value = response.data.billingInfo.primaryInsurance.planId;
                    me.billinfo_MemberID.value =
                      response.data.billingInfo.primaryInsurance.memberID;
                    me.billinfo_GroupID.value =
                      response.data.billingInfo.primaryInsurance.groupID;

                    me.billinfo_SecondaryInsuranceCarrier.value =
                      response.data.billingInfo.secondaryInsurance.secondaryInsuranceCarrier;
                    // me.billinfo_SecondaryplanId.value = response.data.billingInfo.secondaryInsurance.secondaryplanId;
                    me.billinfo_SecondaryMemberID.value =
                      response.data.billingInfo.secondaryInsurance.secondaryMemberID;
                    me.billinfo_SecondaryGroupID.value =
                      response.data.billingInfo.secondaryInsurance.secondaryGroupID;

                    me.billinfo_FirstNameofInsured.value =
                      response.data.billingInfo.insuredInfo.nameOfInsured.firstName;
                    me.billinfo_LastNameofInsured.value =
                      response.data.billingInfo.insuredInfo.nameOfInsured.lastName;
                    me.billinfo_RelationshipInsured.value =
                      response.data.billingInfo.insuredInfo.relationshipInsured;
                  }

                  me.OtherICD10Codes.value =
                    response.data.tests[0].icd10Code.otherCodes;
                  me.kitShip_PatOthersInfo.value =
                    response.data.tests[0].kitInformation.otherInfo;
                  me.kitShip_notes.value = response.data.tests[0].notes;*/

                  me.setState(
                    {
                      // firstName: response.data.Patient.firstName,
                      // lastName: response.data.Patient.lastName,
                      // testOrdered: response.data.orderedTest,
                      // selectedPatient: _selectedPatient,
                      phyinfo_Organization_account:
                        response.data.Physician.organizationAccount,
                      phyinfo_first_name: response.data.Physician.firstName,
                      phyinfo_last_name: response.data.Physician.lastName,
                      phyinfo_email: response.data.Physician.email,
                      phyinfo_npi: response.data.Physician.npiNumber,
                      phyinfoC_address:
                        response.data.Physician.addresses[0].address_1,
                      phyinfoC_practiceLocation:
                        response.data.Physician.practiceLocationPlain,
                      phyinfoC_city: response.data.Physician.addresses[0].city,
                      phyinfo_state: {
                        label: response.data.Physician.addresses[0].state,
                        value: response.data.Physician.addresses[0].state,
                        code: response.data.Physician.addresses[0].state,
                      },
                      phyinfoC_zipCode:
                        response.data.Physician.addresses[0].zipCode,
                      phyinfoC_Phone:
                        response.data.Physician.addresses[0].phoneNo,
                      phyinfoC_fax:
                        response.data.Physician.addresses[0].fax,
                      phyinfoC_Faxcopyto:
                        response.data.Physician.addresses[0].faxCopyTo,
                      buttonText: "Update",
                      patinfo_firstname: response.data.Patient.firstName,
                      patinfo_lastname: response.data.Patient.lastName,
                      patinfo_address: response.data.Patient.address.address_1,
                      patinfo_State: {
                        label: response.data.Patient.address.state,
                        value: response.data.Patient.address.state,
                        code: response.data.Patient.address.state,
                      },
                      patinfo_city: response.data.Patient.address.city,
                      patinfo_zipcode: response.data.Patient.address.zipCode,
                      patinfo_PhoneNumber: response.data.Patient.phoneNo,
                      patinfo_emailaddress: response.data.Patient.email,
                      patinfo_dob: patinfo_dobFormatted,
                      patinfo_gender: response.data.Patient.gender,
                      patinfo_OtherEthnicitycity:
                        response.data.Patient.ethnicity.includes("Other")
                          ? ethnicityArray1[ethnicityArray1.length - 1]
                          : "",
                      patinfo_ethnicity:
                        response.data.Patient.ethnicity &&
                          response.data.Patient.ethnicity.includes("Other")
                          ? 'Other'
                          : response.data.Patient.ethnicity,
                      clinicinfo_DoLastMentrualPeriod: lastMenstrualDate,

                      //billing info
                      /*  billinfo_PrimaryInsuranceCarrier:
                         response.data.billingInfo.primaryInsurance
                           .primaryInsuranceCarrier,
                       billinfo_planId:
                         response.data.billingInfo.primaryInsurance.planId,
                       billinfo_MemberID:
                         response.data.billingInfo.primaryInsurance.memberID,
                       billinfo_GroupID:
                         response.data.billingInfo.primaryInsurance.groupID,
                       billinfo_SecondaryInsuranceCarrier:
                         response.data.billingInfo.secondaryInsurance
                           .secondaryInsuranceCarrier,
                       billinfo_SecondaryplanId:
                         response.data.billingInfo.secondaryInsurance
                           .secondaryplanId,
                       billinfo_SecondaryMemberID:
                         response.data.billingInfo.secondaryInsurance
                           .secondaryMemberID,
                       billinfo_SecondaryGroupID:
                         response.data.billingInfo.secondaryInsurance
                           .secondaryGroupID, */
                      billinfo_PrimaryInsuranceCarrier: response.data.billingInfo
                        .primaryInsurance
                        ? response.data.billingInfo.primaryInsurance
                          .primaryInsuranceCarrier
                        : "",
                      billinfo_planId: response.data.billingInfo.primaryInsurance
                        ? response.data.billingInfo.primaryInsurance.planID
                        : "",
                      billinfo_MemberID: response.data.billingInfo.primaryInsurance
                        ? response.data.billingInfo.primaryInsurance.memberID
                        : "",
                      billinfo_GroupID: response.data.billingInfo.primaryInsurance
                        ? response.data.billingInfo.primaryInsurance.groupID
                        : "",

                      billinfo_SecondaryInsuranceCarrier: response.data.billingInfo
                        .secondaryInsurance
                        ? response.data.billingInfo.secondaryInsurance
                          .secondaryInsuranceCarrier
                        : "",
                      billinfo_SecondaryplanId: response.data.billingInfo
                        .secondaryInsurance
                        ? response.data.billingInfo.secondaryInsurance
                          .secondaryPlanID
                        : "",
                      billinfo_SecondaryMemberID: response.data.billingInfo
                        .secondaryInsurance
                        ? response.data.billingInfo.secondaryInsurance
                          .secondaryMemberID
                        : "",
                      billinfo_SecondaryGroupID: response.data.billingInfo
                        .secondaryInsurance
                        ? response.data.billingInfo.secondaryInsurance
                          .secondaryGroupID
                        : "",
                      /* billinfo_NameofInsured:
                        response.data.billingInfo.insuredInfo.nameofInsured,
                      billinfoC_dob: billinfoC_dobFormatted,
                      billinfo_RelationshipInsured:
                        response.data.billingInfo.insuredInfo
                          .relationshipInsured, */
                      billinfo_FirstNameofInsured: response.data.billingInfo
                        .insuredInfo
                        ? response.data.billingInfo.insuredInfo.nameOfInsured
                          .firstName
                        : "",
                      billinfo_LastNameofInsured: response.data.billingInfo
                        .insuredInfo
                        ? response.data.billingInfo.insuredInfo.nameOfInsured
                          .lastName
                        : "",

                      billinfoC_dob: billinfoC_dobFormatted,
                      billinfo_RelationshipInsured: response.data.billingInfo
                        .insuredInfo
                        ? response.data.billingInfo.insuredInfo.relationshipInsured
                        : "",
                      OtherICD10Codes:
                        response.data.tests[0].icd10Code.otherCodes,
                      kitShip_PatOthersInfo:
                        response.data.tests[0].kitInformation.otherInfo,
                      kitShip_notes: response.data.tests[0].notes,

                      updatedAt: response.data.updatedAt,
                    },
                    () => { }
                  );

                  //sam code
                  //Additional Biomarkers
                  if (
                    this.state.orderData.tests[0].ova1PlusBiomarkers.other ===
                    "OvaWatch"
                  )
                    $("#_OvaWatch").prop("checked", true);

                  //Billing information

                  /*  if (this.state.orderData.billingInfo.medicare) {
                     $("#billinfo_Medicare").prop("checked", true);
                     showInsuranceTitle();
                     showPrimaryAreaClick();
                     hideSecondaryAreaClick();
                     showInsuranceInsured();
                     showPayorListSearchAndSelect();
                   }
                   if (this.state.orderData.billingInfo.privateInsurance) {
                     $("#billinfo_PrivateInsurance").prop("checked", true);
                     showInsuranceTitle();
                     showPrimaryAreaClick();
                     showSecondaryAreaClick();
                     showInsuranceInsured();
                     showPayorListSearchAndSelect();
                   }
                   if (this.state.orderData.billingInfo.patientSelfPay) {
                     $("#billinfo_PatientSelfPay").prop("checked", true);
                     hidePrimaryAreaClick();
                     hideSecondaryAreaClick();
                     hideInsuranceTitle();
                     hideInsuranceInsured();
                     hidePayorListSearchAndSelect();
                   }
                   if (this.state.orderData.billingInfo.medicaid) {
                     $("#billinfo_Medicaid").prop("checked", true);
                     showInsuranceTitle();
                     showPrimaryAreaClick();
                     hideSecondaryAreaClick();
                     showInsuranceInsured();
                     showPayorListSearchAndSelect();
                   }
                   if (this.state.orderData.billingInfo.orderingFacility) {
                     $("#billinfo_OrderingFacility").prop("checked", true);
                     hidePrimaryAreaClick();
                     hideSecondaryAreaClick();
                     hideInsuranceTitle();
                     hideInsuranceInsured();
                     hidePayorListSearchAndSelect();
                   }
 
                   if (this.state.orderData.billingInfo.freeTrial) {
                     $("#billinfo_FreeTrial").prop("checked", true);
                     hidePrimaryAreaClick();
                     hideSecondaryAreaClick();
                     // hideTertiaryAreaClick();
                     hideInsuranceTitle();
                     hideInsuranceInsured();
                     hidePayorListSearchAndSelect();
                   } */

                  if (this.state.orderData.billingInfo.medicare) {
                    this.setState({
                      orderingFacility: false,
                      freeTrial: false,
                      medicaid: false,
                      medicare: true,
                      patientSelfPay: false,
                    });
                  }
                  if (this.state.orderData.billingInfo.privateInsurance) {
                    this.setState({
                      orderingFacility: false,
                      freeTrial: false,
                      privateInsurance: true,
                      medicaid: false,

                      patientSelfPay: false,
                    });
                  }
                  if (this.state.orderData.billingInfo.patientSelfPay) {
                    this.setState({
                      orderingFacility: false,
                      freeTrial: false,
                      privateInsurance: false,
                      medicaid: false,
                      medicare: false,
                      patientSelfPay: true,
                    });
                  }
                  if (this.state.orderData.billingInfo.medicaid) {
                    this.setState({
                      orderingFacility: false,
                      freeTrial: false,
                      privateInsurance: false,
                      medicaid: true,
                      medicare: false,
                      patientSelfPay: false,
                    });
                  }
                  if (this.state.orderData.billingInfo.orderingFacility) {
                    this.setState({
                      orderingFacility: true,
                      freeTrial: false,
                      privateInsurance: false,
                      medicaid: false,
                      medicare: false,
                      patientSelfPay: false,
                    });
                  }
                  if (this.state.orderData.billingInfo.freeTrial) {
                    this.state.freeTrial = true;
                    this.setState({
                      orderingFacility: false,
                      freeTrial: true,
                      privateInsurance: false,
                      medicaid: false,
                      medicare: false,
                      patientSelfPay: false,
                    });
                  }

                  //Diagnosis Codes | ICD-10 Codes
                  if (this.state.orderData.tests.length > 0) {
                    if (
                      this.state.orderData.tests[0].icd10Code.codes.length > 0
                    ) {
                      //OvaWatch

                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "N83.201"
                        )
                      ) {
                        $("#diagnosis_UnOcyst_rs").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "R19.00"
                        )
                      ) {
                        $("#diagnosis_IntraAbdPelSwell").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "R19.05"
                        )
                      ) {
                        $("#diagnosis_Periumbilicalswell").prop(
                          "checked",
                          true
                        );
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "N83.202"
                        )
                      ) {
                        $("#diagnosis_UnOcyst_ls").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "R19.03"
                        )
                      ) {
                        $("#diagnosis_lowerquadrantSwell").prop(
                          "checked",
                          true
                        );
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "R19.09"
                        )
                      ) {
                        $("#diagnosis_intraabdominalpelvicswell").prop(
                          "checked",
                          true
                        );
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "N83.209"
                        )
                      ) {
                        $("#diagnosis_UnOcyst").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "R19.04"
                        )
                      ) {
                        $("#diagnosis_LlQabdominalswelling").prop(
                          "checked",
                          true
                        );
                      }
                    } //end OvaWatch
                  }

                  if (this.state.orderData.tests.length > 1) {
                    $("#covid_icd_codes").removeClass(" hidediv");
                    $("#covid_icd_codes").addClass(" showdiv");

                    if (
                      this.state.orderData.tests[1].icd10Code.codes.length > 0
                    ) {
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "Z11.59"
                        )
                      ) {
                        $("#diagnosis_z1159").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "Z03.818"
                        )
                      ) {
                        $("#diagnosis_z03818").prop("checked", true);
                      }
                    }
                  }

                  var tempOtherCodes = response.data.tests[0].icd10Code.otherCodes;
                  console.log('other', tempOtherCodes);
                  //console.log()
                  let displayOtherIcdCodes = tempOtherCodes.join(',');
                  this.setState({ otherICD10Codes: tempOtherCodes, otherICD10Code: displayOtherIcdCodes });
                  //end Diagnosis Codes | ICD-10 Codes


                  //Kit Shipping Information
                  if (this.state.orderData.tests[0].kitInformation.drawType.length > 0) {
                    if (this.state.orderData.tests[0].kitInformation.drawType.includes("My physician needs mobile phlebotomy")) {
                      this.setState({ kitShip_PatMobPhlebotomy: 'My physician needs mobile phlebotomy' })
                    } if (this.state.orderData.tests[0].kitInformation.drawType.includes("Patient will get blood draw at Quest PSC")) {
                      this.setState({ kitShip_PatblooddrawQuest: 'Patient will get blood draw at Quest PSC' })
                    } if (this.state.orderData.tests[0].kitInformation.drawType.includes("Other")) {
                      this.setState({ kitShip_PatOthers: 'Other' })
                    }
                  } //end Kit Shipping Information

                  //certify Types certifyTypes
                  if (typeof this.state.orderData.certifyTypes != "undefined") {
                    if (this.state.orderData.certifyTypes.length > 0) {
                      if (
                        this.state.orderData.certifyTypes.includes(
                          "A healthcare provider"
                        )
                      ) {
                        $("#kitShip_healthcareProvider").prop("checked", true);
                      }
                      if (
                        this.state.orderData.certifyTypes.includes(
                          "An Aspira employee ordering on behalf of a healthcare provider"
                        )
                      ) {
                        $("#kitShip_employeehealthcareProvider").prop(
                          "checked",
                          true
                        );
                      }
                      if (
                        this.state.orderData.certifyTypes.includes(
                          "An Aspira partner ordering on behalf of a healthcare provider"
                        )
                      ) {
                        $("#kitShip_partnerhealthcareProvider").prop(
                          "checked",
                          true
                        );
                      }
                    }
                  }

                  if (this.state.orderData.researchConsent === "Yes")
                    $("#researchConsent").prop("checked", true);

                  if (this.state.orderData.researchUseOnly === "Yes")
                    $("#researchUseOnly").prop("checked", true);
                  //certify Types certifyTypes end
                }
              );
            }
          }
        );
      })
      .catch((error) => {
        me.setState(
          {
            loading: false,
          },
          () => {
            console.log("error: ", error);
          }
        );
      });
  }
  onChangePatinfoDob = (date) => this.setState({ patinfo_dob: date });
  onChangeBillinfoCDob = (date) => {
    this.setState({ billinfoC_dob: date });
  };
  onChangeDoLastMentrualPeriod = (date) => {
    this.setState({ clinicinfo_DoLastMentrualPeriod: date });
  };
  onChange(event) {
    console.log("event.target.files: ", event.target.files[0]);
    me.setState(
      {
        profileImage: event.target.files[0],
        profileImagePreview: URL.createObjectURL(event.target.files[0]),
      },
      () => { }
    );
  }

  getBillingInfoJson() {
    //Billing Information
    const privateInsurance = this.state.privateInsurance;
    const medicare = this.state.medicare;
    const patientSelfPay = this.state.patientSelfPay;
    const medicaid = this.state.medicaid;
    const freeTrial = this.state.freeTrial;
    const orderingFacility = this.state.orderingFacility;

    let billingInfo = {
      privateInsurance,
      medicare,
      patientSelfPay,
      medicaid,
      freeTrial,
      orderingFacility,
      primaryInsurance: {
        primaryInsuranceCarrier: this.state.selectedPayor.value,
        planID: this.state.billinfo_planId,
        memberID: this.state.billinfo_MemberID,
        groupID: this.state.billinfo_GroupID,
      },
      secondaryInsurance: {
        secondaryInsuranceCarrier:
          this.state.billinfo_SecondaryInsuranceCarrier,
        secondaryPlanID: this.state.billinfo_SecondaryplanId,
        secondaryMemberID: this.state.billinfo_SecondaryMemberID,
        secondaryGroupID: this.state.billinfo_SecondaryGroupID,
      },
      /* tertiaryInsurance: {
        tertiaryInsuranceCarrier: this.state.billinfo_TertiaryInsuranceCarrier,
        tertiaryMemberID: this.state.billinfo_TertiaryMemberID,
        tertiaryGroupID: this.state.billinfo_TertiaryGroupID,
        tertiaryPlanID: this.state.billinfo_TertiaryPlanID,
      }, */
      insuredInfo: {
        nameOfInsured: {
          firstName: this.state.billinfo_FirstNameofInsured,
          lastName: this.state.billinfo_LastNameofInsured,
        },
        dob: moment(this.state.billinfoC_dob).format("YYYY-MM-DD"),
        relationshipInsured: this.state.billinfo_RelationshipInsured,
      },
      notesText: this.state.notesText,
    };
    console.log("after pushing billing info=========================");
    console.log(billingInfo);
    //return;
    const isFirstNameMissing = !billingInfo.insuredInfo.nameOfInsured.firstName;
    const isLastNameMissing = !billingInfo.insuredInfo.nameOfInsured.lastName;
    const isDOBMissing =
      billingInfo.insuredInfo.dob === "Invalid date" ||
      !billingInfo.insuredInfo.dob;

    console.log("\n---");
    console.log("do person: ", billingInfo.insuredInfo.dob);
    console.log("---\n");

    if (
      (privateInsurance || medicare || medicaid) &&
      (isFirstNameMissing || isLastNameMissing || isDOBMissing)
    ) {
      if (isFirstNameMissing)
        message.warning("Insured person first name is missing");
      if (isLastNameMissing)
        message.warning("Insured person last name is missing");
      if (isDOBMissing)
        message.warning("Insured person date of birth is missing");

      return null;
    }
    return billingInfo;
    //Billing Information
  }

  handleSave = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log("user.token: ", user.token);

    const formData = new FormData();
    console.log("\n---");
    console.log("extract data for update API call for OvaWatch > start");
    console.log("---\n");

    let ova1PlusBiomarkers = {
      GCP: [],
      MCP: [],
      other: {},
    };
    if ($(`#_OvaWatch`).is(":checked")) {
      let $OvaWatchCheckbox = $(`#_OvaWatch`);
      ova1PlusBiomarkers.other = {
        qBenchId: $OvaWatchCheckbox.data("qbenchid"),
        code: $OvaWatchCheckbox.data("val"),
      };
    }

    console.log("\n---");
    console.log("ova1PlusBiomarkers: ", ova1PlusBiomarkers);
    console.log("---\n");

    // Pull physician data
    const physicianAddress = {
      legacyClientId: this.state.orderData.legacyClientId,
      locationName: "USA",
      address_1: this.state.phyinfoC_address,
      address_2: "",
      addressType: "Primary",
      city: this.state.phyinfoC_city,
      country: "USA",
      state: this.state.phyinfo_state.code,
      zipCode: this.state.phyinfoC_zipCode,
      phoneNo: this.state.phyinfoC_Phone,
      fax: this.state.phyinfoC_fax,
      faxCopyTo: this.state.phyinfoC_Faxcopyto,
    };
    console.log("\n---");
    console.log("physicianAddress: ", physicianAddress);
    console.log("---\n");

    const physician = {
      physicianId: this.state.physicianId,
      organizationAccount: this.state.phyinfo_Organization_account,
      firstName: this.state.phyinfo_first_name,
      lastName: this.state.phyinfo_last_name,
      email: this.state.phyinfo_email,
      npiNumber: this.state.phyinfo_npi,
      addresses: [physicianAddress],
    };

    console.log("\n---");
    console.log("physician -- : ", physician);
    console.log("---\n");

    // Pull patient data

    const patientEthnicity = [];

    if ($("#patinfo_Caucasian").is(":checked"))
      patientEthnicity.push($("#patinfo_Caucasian").data("val"));
    if ($("#patinfo_AshkenaziJewish").is(":checked"))
      patientEthnicity.push($("#patinfo_AshkenaziJewish").data("val"));
    if ($("#patinfo_SephardicJewish").is(":checked"))
      patientEthnicity.push($("#patinfo_SephardicJewish").data("val"));
    if ($("#patinfo_Asian").is(":checked"))
      patientEthnicity.push($("#patinfo_Asian").data("val"));
    if ($("#patinfo_HIspanic").is(":checked"))
      patientEthnicity.push($("#patinfo_HIspanic").data("val"));
    if ($("#patinfo_NativeAmerican").is(":checked"))
      patientEthnicity.push($("#patinfo_NativeAmerican").data("val"));
    if ($("#patinfo_AfricanAmerican").is(":checked"))
      patientEthnicity.push($("#patinfo_AfricanAmerican").data("val"));
    if ($("#patinfo_na").is(":checked"))
      patientEthnicity.push($("#patinfo_na").data("val"));
    if ($("#patinfo_Other").is(":checked")) {
      patientEthnicity.push($("#patinfo_Other").data("val"));
      patientEthnicity.push($("#patinfo_OtherEthnicitycity").val());
    }
    if (patientEthnicity.length === 0) patientEthnicity.push("N/A");

    console.log("\n---");
    console.log("Patient Ethnicity array: ", patientEthnicity);
    console.log("---\n");

    let patientGender = "female";
    if ($(`#${this.state.frmprefix}patinfo_gender`).is(":checked"))
      patientGender = "male";

    const patient = {
      patientId: this.state.patientId,
      email: this.state.patinfo_emailaddress,
      firstName: this.state.patinfo_firstname,
      lastName: this.state.patinfo_lastname,
      dob: moment(this.state.patinfo_dob).format("YYYY-MM-DD"),
      phoneNo: this.state.patinfo_PhoneNumber,
      address: {
        locationName: "USA",
        address_1: this.state.patinfo_address,
        address_2: "",
        addressType: "Primary",
        city: this.state.patinfo_city,
        country: "USA",
        state: this.state.patinfo_State.code,
        zipCode: this.state.patinfo_zipcode,
      },

      ethnicity:
        this.state.patinfo_ethnicity !== "Other"
          ? this.state.patinfo_ethnicity
          : 'Other,' + this.state.patinfo_OtherEthnicitycity,
      gender: patientGender,
    };

    console.log("\n---");
    console.log("patient: ", patient);
    console.log("---\n");

    // Pull clinical data
    //
    //
    //
    //
    const {
      clinicinfo_DoLastMentrualPeriod,
      clinicinfo_Menopausal_status,
      ultrasound_results_q02,
      clinicinfo_MassSize_longdime,
      clinicinfo_MassSize_Height,
      clinicinfo_MassSize_Height_unit,
      clinicinfo_MassSize_Weight,
      clinicinfo_MassSize_Weight_unit,
      clinicinfo_pain_abdomen_pelvis,
      days_per_month_q05_1,
      how_long_month_q05_2,
      clinicinfo_fullQuickly_unableToe,
      days_per_month_q06_1,
      how_long_month_q06_2,
      clinicinfo_Expabdominalbloat_IncrSize,
      days_per_month_q07_1,
      how_long_month_q07_2,
    } = this.state
    let clinicalData = [
      {
        questionCode: "OvaWatch_q01",
        answer: clinicinfo_Menopausal_status,
      },
      {
        questionCode: "OvaWatch_q02",
        answer: ultrasound_results_q02,
      },
      {
        questionCode: "OvaWatch_q03",
        answer: moment(this.state.clinicinfo_DoLastMentrualPeriod).format("YYYY-MM-DD"),
      },
      {
        questionCode: "OvaWatch_q04",
        answer: clinicinfo_MassSize_longdime,
        subQuestion: [
          {
            questionCode: "OvaWatch_q04_1",
            answer: clinicinfo_MassSize_Height + ' ' + clinicinfo_MassSize_Height_unit
          },
          {
            questionCode: "OvaWatch_q04_2",
            answer: clinicinfo_MassSize_Weight + ' ' + clinicinfo_MassSize_Weight_unit
          },
        ],
      },
      clinicinfo_pain_abdomen_pelvis ? {
        questionCode: "OvaWatch_q05",
        answer: true,
        subQuestion: [
          {
            questionCode: "OvaWatch_q05_1",
            answer: days_per_month_q05_1,
          },
          {
            questionCode: "OvaWatch_q05_2",
            answer: how_long_month_q05_2,
          },
        ],
      } : {
        questionCode: "OvaWatch_q05",
        answer: false,
      },
      clinicinfo_fullQuickly_unableToe ? {
        questionCode: "OvaWatch_q06",
        answer: true,
        subQuestion: [
          {
            questionCode: "OvaWatch_q06_1",
            answer: days_per_month_q06_1,
          },
          {
            questionCode: "OvaWatch_q06_2",
            answer: how_long_month_q06_2,
          },
        ],
      } : {
        questionCode: "OvaWatch_q06",
        answer: false,
      },
      clinicinfo_Expabdominalbloat_IncrSize ? {
        questionCode: "OvaWatch_q07",
        answer: true,
        subQuestion: [
          {
            questionCode: "OvaWatch_q07_1",
            answer: days_per_month_q07_1,
          },
          {
            questionCode: "OvaWatch_q07_2",
            answer: how_long_month_q07_2,
          },
        ],
      } : {
        questionCode: "OvaWatch_q07",
        answer: false,
      }
    ];


    /*const OvaWatch_q02 = [];
    sections = $(`${"#clinicalInfoFormContainer"} #OvaWatch_q02`).find(
      ":input"
    );
    sections.map((input, i) => {
      const $input = $(sections[input]);
      console.log(
        "$input.attr('id'): ",
        $input.attr("id"),
        ", $input.attr('type'): ",
        $input.attr("type")
      );

      let obj_id = $input.attr("id");

      const _type = $input.attr("type");
      obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1);

      if (_type == "checkbox") {
        if ($input.is(":checked")) {
          OvaWatch_q02.push($input.data("val"));
        }
      }
    });

    qnA = {
      questionCode: "OvaWatch_q02",
      answer: OvaWatch_q02.join(", "),
      };*/



    console.log("sm clinicalData: ", clinicalData);

    // Pull billing data
    /*     let billingInfo = this.state.orderData.billingInfo;
    
        if (this.state.isBillableOrder) {
          const privateInsurance = $("#billinfo_PrivateInsurance").is(":checked")
            ? true
            : false;
          const medicare = $("#billinfo_Medicare").is(":checked") ? true : false;
          const patientSelfPay = $("#billinfo_PatientSelfPay").is(":checked")
            ? true
            : false;
          const medicaid = $("#billinfo_Medicaid").is(":checked") ? true : false;
          const freeTrial = $("#billinfo_FreeTrial").is(":checked") ? true : false;
          const orderingFacility = $("#billinfo_OrderingFacility").is(":checked")
            ? true
            : false;
    
          billingInfo = {
            privateInsurance,
            medicare,
            patientSelfPay,
            medicaid,
            freeTrial,
            orderingFacility,
            primaryInsurance: {
              primaryInsuranceCarrier: this.state.billinfo_PrimaryInsuranceCarrier,
              memberID: this.state.billinfo_MemberID,
              groupID: this.state.billinfo_GroupID,
            },
            secondaryInsurance: {
              secondaryInsuranceCarrier:
                this.state.billinfo_SecondaryInsuranceCarrier,
              secondaryMemberID: this.state.billinfo_SecondaryMemberID,
              secondaryGroupID: this.state.billinfo_SecondaryGroupID,
            },
            tertiaryInsurance: {
              tertiaryInsuranceCarrier: "",
              tertiaryMemberID: "",
              tertiaryGroupID: "",
            },
            insuredInfo: {
              nameOfInsured: {
                firstName: this.state.billinfo_FirstNameofInsured,
                lastName: this.state.billinfo_LastNameofInsured,
              },
              dob: moment(this.state.billinfoC_dob).format("YYYY-MM-DD"),
              relationshipInsured: this.state.billinfo_RelationshipInsured,
            },
            notesText: this.state.notesText || "",
          };
          const isFirstNameMissing =
            !billingInfo.insuredInfo.nameOfInsured.firstName;
          const isLastNameMissing = !billingInfo.insuredInfo.nameOfInsured.lastName;
          const isDOBMissing =
            billingInfo.insuredInfo.dob === "Invalid date" ||
            !billingInfo.insuredInfo.dob;
    
          console.log("\n---");
          console.log("do person: ", billingInfo.insuredInfo.dob);
          console.log("---\n");
    
          if (
            (privateInsurance || medicare || medicaid) &&
            (isFirstNameMissing || isLastNameMissing || isDOBMissing)
          ) {
            if (isFirstNameMissing)
              message.warning("Insured person first name is missing");
            if (isLastNameMissing)
              message.warning("Insured person last name is missing");
            if (isDOBMissing)
              message.warning("Insured person date of birth is missing");
    
            return;
          }
        } */
    let billingInfo = this.getBillingInfoJson();
    if (!billingInfo) {
      return
    }
    console.log("\n---");
    console.log("billingInfo: ", billingInfo);
    console.log("---\n");

    // Pull diagnosis codes data

    const icd10Code = {
      codes: [],
      otherCodes: [],
    };
    let inputs = [];
    inputs = $(`#${this.state.frmprefix}DiagnosisCodes`).find(":input");
    inputs.map((input, i) => {
      const $input = $(inputs[input]);
      console.log(
        "$input.attr('id'): ",
        $input.attr("id"),
        ", $input.attr('type'): ",
        $input.attr("type")
      );

      const _type = $input.attr("type");

      if (_type == "checkbox") {
        if ($input.is(":checked")) {
          icd10Code.codes.push($input.data("val"));
        }
      }/*  else if (_type == "text") {
        icd10Code.otherCodes.push($input.val());
      } */
    });
    this.state.OtherICD10Codes = this.state.otherICD10Code.split(',');
    icd10Code.otherCodes = this.state.OtherICD10Codes;

    console.log("\n---");
    console.log("icd10Code: ", icd10Code);
    console.log("---\n");

    //Pull Kit Information
    const {
      kitShip_PatMobPhlebotomy,
      kitShip_PatblooddrawQuest,
      kitShip_PatOthers,
      kitShip_PatOthersInfo
    } = this.state

    const kitInformation = {
      drawType: [
        kitShip_PatMobPhlebotomy,
        kitShip_PatblooddrawQuest,
        kitShip_PatOthers
      ].filter((item) => item !== null),
      otherInfo: kitShip_PatOthersInfo,
    };

    console.log("\n---");
    console.log("kitInformation: ", kitInformation);
    console.log("---\n");

    // Pull notes & certify data

    const kitShippingNotes = this.state.kitShip_notes;
    const certifyTypes = [];

    if ($("#kitShip_healthcareProvider").is(":checked"))
      certifyTypes.push($("#kitShip_healthcareProvider").data("val"));
    if ($("#kitShip_employeehealthcareProvider").is(":checked"))
      certifyTypes.push($("#kitShip_employeehealthcareProvider").data("val"));
    if ($("#kitShip_partnerhealthcareProvider").is(":checked"))
      certifyTypes.push($("#kitShip_partnerhealthcareProvider").data("val"));

    let OvaWatchResearch = [];
    if ($(`#OresearchConsent`).is(":checked")) {
      let $researchCheckbox = $(`#researchConsent`);
      OvaWatchResearch.other = {
        code: $researchCheckbox.data("val"),
      };
    }
    if ($(`#researchUseOnly`).is(":checked")) {
      let $researchCheckbox = $(`#researchUseOnly`);
      OvaWatchResearch.other = {
        code: $researchCheckbox.data("val"),
      };
    }
    console.log("\n---");
    console.log("Research: ", OvaWatchResearch);
    console.log("---\n");

    // get the user response related to user's consent about the use of his/her data for research purpose
    const researchConsent = $(`#researchConsent`).is(":checked")
      ? "Yes"
      : "No";
    const researchUseOnly = $(`#researchUseOnly`).is(":checked")
      ? "Yes"
      : "No";

    console.log("\n---");
    console.log("kitShippingNotes: ", kitShippingNotes);
    console.log("---");
    console.log("certifyTypes: ", certifyTypes);
    console.log("---");
    console.log("researchConsent: ", researchConsent);
    console.log("---");
    console.log("researchUseOnly: ", researchUseOnly);
    console.log("---\n");

    const order = {
      _id: this._id,
      orderId: this.orderId,
      Physician: physician,
      Patient: patient,
      billingInfo,
      tests: [
        {
          ova1PlusBiomarkers,
          icd10Code,
          kitInformation,
          notes: kitShippingNotes,
          clinicalData: clinicalData,
          testCode: "OvaWatch",
          testName: "OvaWatch",
        },
      ],
      certifyTypes,
      payorId: this.state.selectedPayor?.value || '',
      researchConsent,
      researchUseOnly,
      updatedAt: this.state.updatedAt,
    };

    console.log("\n---");
    console.log("sm order: ", order);
    console.log("---\n");

    formData.append("order", JSON.stringify(order));
    formData.append("host", window.location.host);

    const accessToken = await helper.getAccessToken(this.context)

    if (me._id) {
      axios
        .put(`${API_BASE_URL}/orders`, formData, {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        })
        .then(({ data }) => {
          console.log("data: ", data);

          this.setState(
            {
              toastMessage: `OvaWatch order updated Successfully`,
            },
            () => {
              // this.showToast("success");
              message.success(this.state.toastMessage);

              setTimeout(() => {
                const params = new URLSearchParams(window.location.search);
                let returnurl = params.get("returnurl");

                console.log("returnurl: ", returnurl);
                if (process.env.REACT_APP_ENV !== "DEV") {
                  window.location.href = returnurl;
                }
              }, 1000);
            }
          );
        })
        .catch((error) => {
          message.error(error.message);
        })
    } else {
      axios
        .post(`${API_BASE_URL}/orders`, formData, {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        })
        .then(({ data }) => {
          console.log("data: ", data);

          this.setState(
            {
              toastMessage: `Successfully added order`,
            },
            () => {
              // this.showToast("success");
              message.success(this.state.toastMessage);
            }
          );
        });
    }
  };
  showToast(type) {
    const id = Math.floor(Math.random() * 101 + 1);

    switch (type) {
      case "success":
        this.toastProperties = {
          id,
          title: "Success",
          description: "This is a success toast component",
          backgroundColor: "#5cb85c",
          icon: "/assets/assets/check.svg",
        };
        break;
      case "danger":
        this.toastProperties = {
          id,
          title: "Danger",
          description: "This is a error toast component",
          backgroundColor: "#d9534f",
          icon: "/assets/assets/error.svg",
        };
        break;
      case "info":
        this.toastProperties = {
          id,
          title: "Info",
          description: "This is an info toast component",
          backgroundColor: "#5bc0de",
          icon: "/assets/assets/info.svg",
        };
        break;
      case "warning":
        this.toastProperties = {
          id,
          title: "Warning",
          description: "This is a warning toast component",
          backgroundColor: "#f0ad4e",
          icon: "/assets/assets/warning.svg",
        };
        break;

      default:
        this.setState({ toastList: [] });
    }

    // this.setState([...this.state.list, this.toastProperties]);
    this.setState({ list: [this.toastProperties] });
  }
  takeNotes(notesText) {
    this.setState({ notesText: notesText });
  }

  getDefaultPayorIdOptions = () => {
    return this.state.allPayors.slice(0, 20);
  };
  getPayorIdOptionsBySearchText = async (searchText) => {
    let payorIdOptions = this.state.allPayors.filter((payor) =>
      payor.label.toLowerCase().includes(searchText.toLowerCase())
    );

    // if the payorIdOptions array contains many elements then the Select element becomes laggy
    // so I'm cutting down the array size if there are too many elements - Khairul
    const numberOfOptions = payorIdOptions.length;

    console.log("\n===");
    console.log("original options count: ", numberOfOptions);

    if (numberOfOptions > 100) {
      payorIdOptions = payorIdOptions.slice(0, 99);
    }

    console.log("Options count after trimming: ", payorIdOptions.length);
    console.log("===\n");

    return payorIdOptions;
  };
  updatePrimaryInsuranceCarrierAndSelectedPayor = (inputPayor) => {
    this.billinfo_PrimaryInsuranceCarrier.value = inputPayor.value;
    this.setState({ selectedPayor: inputPayor });
  };
  renderPayorListDropdownForPrimaryInsuranceCarrierSelection = () => {
    return (
      <div
        class="col-sm-10 col-xs-12 mb-3 hidediv"
        id="payorListSearchAndSelect"
      >
        <div class="form-group drop-custum">
          <label class="color-green" htmlFor="role">
            Search Primary Carrier:
          </label>
          <small className="color-red">*</small>
          <AsyncSelect
            value={this.state.selectedPayor}
            defaultValue={""}
            label="Search Primary Carrier:"
            cacheOptions
            defaultOptions={this.getDefaultPayorIdOptions()}
            loadOptions={this.getPayorIdOptionsBySearchText}
            styles={helper.colourStyles}
            onChange={this.updatePrimaryInsuranceCarrierAndSelectedPayor}
          />
        </div>
      </div>
    );
  };
  render() {
    var tempOtherCode = this.state.otherICD10Code;


    if (this.state.loading) {
      return null;
    }

    return (
      <div>
        <div class="block-header">
          <OrderHeader
            orderId={this.state.orderData.orderId}
            orderStatus={this.state.orderData.status}
          />
          {/* <ul class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/orders">Orders</a></li>
                        <li class="breadcrumb-item active">{(this.state.orderData._id) ? "Edit Order" : "New Order"}</li>
                    </ul> */}
        </div>
        {console.log("Order: ", this.state.orderData)}

        <div
          class="panel-group card"
          id="accordion_105"
          role="tablist"
          aria-multiselectable="true"
        >
          <Toast
            toastList={me.state.list}
            position={"top-center"}
            autoDelete={true}
            dismissTime={3000}
            description={me.state.toastMessage}
          />

          {/* Top Actions */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">Actions</h4>
            </div>
          </div>
          <TopActions
            orderId={this.orderId}
            // reportId={reportId}
            galenReportId={me.state.orderData.galenReportId}
            context={this.context}
            orderData={this.state.orderData}
            reportDate={this.state.reportDate}
          />
          {/* baylor */}
          {this.state.orderData.baylorStatus &&
            this.state.orderData.baylorStatus.length > 0 && (
              <>
                <div class="row clearfix">
                  <div class="col-md-12">
                    <h4 class="panel-titlepdf">Baylor</h4>
                  </div>
                </div>
                <div class="body card">
                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div class="form-group required">
                        <label class="form-label" for="baylor">
                          Baylor Status: {this.state.orderData.baylorStatus}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          {/* Start Additional Biomarkers */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">OvaWatch</h4>
            </div>
          </div>
          <div id="AdditionalBiomarkersForm" className="body card">
            <div class="panel-body">
              <div id="printHere" class=""></div>
            </div>
          </div>
          {/* end Additional Biomarkers */}

          {/* OvaWatch Physician Information */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">Physician Information</h4>
            </div>
          </div>
          <PhysicianInformation
            state={this.state}
            me={this}
            phyinfo_state={this.state.phyinfo_state}
          />
          {/* OvaWatch Physician Information end */}

          {/* OvaWatch Patient Information */}
          {/* {this.state.orderData.Patient.email} */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">Patient Information</h4>
            </div>
          </div>
          <PatientInformation state={this.state} me={this} />
          {/* OvaWatch Patient Information end */}

          {/* OvaWatch Clinical Information*/}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">Clinical Information</h4>
            </div>
          </div>
          <Clinical_Information
            state={this.state}
            me={this}
          />
          {/* OvaWatch Clinical Information End*/}

          {/* OvaWatch Billing Information */}
          {this.state.isBillableOrder && (
            <>
              <div class="row clearfix">
                <div class="col-md-12">
                  <h4 class="panel-titlepdf">Billing Information</h4>
                </div>
              </div>
              <div
                id={`${this.state.frmprefix}BillingInformation`}
                class="body card"
              >
                <Billing_Information
                  state={this.state}
                  me={this}
                />

                <CmtForm
                  order={me.state.orderData}
                  handleSaveFunc={this.handleSave.bind(this)}
                  takeNotesFunc={this.takeNotes.bind(this)}
                />

                <BillingNotes
                  order={me.state.orderData}
                  handleSaveFunc={this.handleSave.bind(this)}
                  takeNotes={this.takeNotes.bind(this)}
                />

                <div class="row clearfix">
                  <div class="col-md-12">
                    <div class="form-group">
                      <button
                        id={`btnSaveBillingInfo`}
                        placeholder="Update billing info"
                        name="Submit"
                        className="btn btn-primary text-white"
                        onClick={this.handleSave.bind(this)}
                      >
                        Update billing info
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* OvaWatch Billing Information end */}

          {/* OvaWatch Diagnosis Codes | ICD-10 Codes */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">Diagnosis Codes | ICD-10 Codes</h4>
            </div>
          </div>
          <div id={`${this.state.frmprefix}DiagnosisCodes`} class="body card">
            <div class="row clearfix">
              <div id={`OvaWatch_icd_codes`} class="col-md-12">
                <div class="form-group required">
                  <label class="form-label" for={`DiagnosisCodes`}>
                    <b>
                      OvaWatch Diagnosis Codes | ICD-10 Codes (check all that
                      apply)
                    </b>
                  </label>
                  <div class="demo-checkbox">
                    <div class="row clearfix">
                      <div class="col-md-4">
                        <input
                          type="checkbox"
                          data-val="N83.201"
                          id={`diagnosis_UnOcyst_rs`}
                          name={`diagnosis_UnOcyst_rs`}
                          class="filled-in"
                        />
                        <label for={`diagnosis_UnOcyst_rs`}>
                          N83.201 Unspecified ovarian cyst, right side+ &nbsp;{" "}
                        </label>
                      </div>
                      <div class="col-md-4">
                        <input
                          type="checkbox"
                          data-val="R19.00"
                          id={`diagnosis_IntraAbdPelSwell`}
                          name={`diagnosis_IntraAbdPelSwell`}
                          class="filled-in"
                        />
                        <label for={`diagnosis_IntraAbdPelSwell`}>
                          R19.00 Intra-abdominal and pelvic swelling, mass and
                          lump, unspecified site+ &nbsp;{" "}
                        </label>
                      </div>
                      <div class="col-md-4">
                        <input
                          type="checkbox"
                          data-val="R19.05"
                          id={`diagnosis_Periumbilicalswell`}
                          name={`diagnosis_Periumbilicalswell`}
                          class="filled-in"
                        />
                        <label for={`diagnosis_Periumbilicalswell`}>
                          R19.05 Periumbilical swelling, mass and lump+ &nbsp;{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="demo-checkbox">
                    <div class="row clearfix">
                      <div class="col-md-4">
                        <input
                          type="checkbox"
                          data-val="N83.202"
                          id={`diagnosis_UnOcyst_ls`}
                          name={`diagnosis_UnOcyst_ls`}
                          class="filled-in"
                        />
                        <label for={`diagnosis_UnOcyst_ls`}>
                          N83.202 Unspecified ovarian cyst, left side+ &nbsp;{" "}
                        </label>
                      </div>
                      <div class="col-md-4">
                        <input
                          type="checkbox"
                          data-val="R19.03"
                          id={`diagnosis_lowerquadrantSwell`}
                          name={`diagnosis_lowerquadrantSwell`}
                          class="filled-in"
                        />
                        <label for={`diagnosis_lowerquadrantSwell`}>
                          R19.03 Right lower quadrant abdominal swelling, mass
                          and lump &nbsp;{" "}
                        </label>
                      </div>
                      <div class="col-md-4">
                        <input
                          type="checkbox"
                          data-val="R19.09"
                          id={`diagnosis_intraabdominalpelvicswell`}
                          name={`diagnosis_intraabdominalpelvicswell`}
                          class="filled-in"
                        />
                        <label for={`diagnosis_intraabdominalpelvicswell`}>
                          R19.09 Other intra-abdominal pelvic swelling, mass and
                          lump+ &nbsp;{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="demo-checkbox">
                    <div class="row clearfix">
                      <div class="col-md-4">
                        <input
                          type="checkbox"
                          data-val="N83.209"
                          id={`diagnosis_UnOcyst`}
                          name={`diagnosis_UnOcyst`}
                          class="filled-in"
                        />
                        <label for={`diagnosis_UnOcyst`}>
                          N83.209 Unspecified ovarian cyst, unspecified side+
                          &nbsp;{" "}
                        </label>
                      </div>
                      <div class="col-md-4">
                        <input
                          type="checkbox"
                          data-val="R19.04"
                          id={`diagnosis_LlQabdominalswelling`}
                          name={`diagnosis_LlQabdominalswelling`}
                          class="filled-in"
                        />
                        <label for={`diagnosis_LlQabdominalswelling`}>
                          R19.04 Left lower quadrant abdominal swelling, mass
                          and lump &nbsp;{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row clearfix hidediv" id={`covid_icd_codes`}>
              <div class="col-md-12">
                <div class="form-group required">
                  <label class="form-label" for={`DiagnosisCodes`}>
                    <b>
                      {" "}
                      COVD Diagnosis Codes | ICD-10 Codes (check all that apply)
                    </b>
                  </label>
                  <div class="demo-checkbox">
                    <div class="row clearfix">
                      <div class="col-md-6">
                        <input
                          type="checkbox"
                          data-val="Z11.59"
                          id={`diagnosis_z1159`}
                          name={`diagnosis_z1159`}
                          class="filled-in"
                        />
                        <label for={`diagnosis_z1159`}>
                          Z11.59, Encounter for screening for other viral
                          diseases &nbsp;{" "}
                        </label>
                      </div>
                      <div class="col-md-6">
                        <input
                          type="checkbox"
                          data-val="Z03.818"
                          id={`diagnosis_z03818`}
                          name={`diagnosis_z03818`}
                          class="filled-in"
                        />
                        <label for={`diagnosis_z03818`}>
                          Z03.818, Encounter for observation for suspected
                          exposure to other biological agents ruled out &nbsp;{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row clearfix">
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="form-label" for={`address`}>
                    Other ICD-10 Codes
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      ref={(ref) => (this.OtherICD10Codes = ref)}
                      id={`OtherICD10Codes`}
                      class="form-control "
                      value={me.state.otherICD10Code}

                      placeholder="Other ICD-10 Codes"
                      onChange={(e) => {
                        tempOtherCode = e.target.value;
                        //  if(tempOtherCode.includes(',')){
                        // tempOtherCode=props.state.otherICD10Code+tempOtherCode;
                        this.setState({ otherICD10Code: tempOtherCode });
                        //  tempOtherCode='';
                        //  e.target.value='';
                        //  }


                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row clearfix">
              <div class="col-md-12">
                <p>
                  (This is provided for informational purposes only and is not a
                  guarantee of coverage. It is the provider's responsibility to
                  determine the appropriate codes)
                </p>
              </div>
            </div>
          </div>
          {/* OvaWatch Diagnosis Codes | ICD-10 Codes end */}

          {/* OvaWatch Kit Shipping Information */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">Kit Shipping Information</h4>
            </div>
          </div>
          <Kit_Shipping_Information
            state={this.state}
            me={this}
            for='AddEditOrderOvaWatchContent'
          />
          {/* <div
            id={`${this.state.frmprefix}kitShippingInfoFormContainer`}
            class="body card"
          >
            <div class="row clearfix">
              <div class="col-md-12">
                <div class="form-group required">
                  <label class="form-label" for="address">
                    Kit Shipping Info
                  </label>
                  <div id="draw_type" class="demo-checkbox">
                    <input
                      data-val="My physician needs mobile phlebotomy"
                      type="checkbox"
                      id={`kitShip_PatMobPhlebotomy`}
                      name={`kitShip_PatMobPhlebotomy`}
                      class="filled-in"
                      checked={this.state.kitShip_PatMobPhlebotomy}
                      onChange={(e) => this.setState({kitShip_PatMobPhlebotomy: e.target.checked ? 'My physician needs mobile phlebotomy' : null})}
                    />
                    <label for={`kitShip_PatMobPhlebotomy`}>
                      My physician needs mobile phlebotomy &nbsp;{" "}
                    </label>
                    <input
                      data-val="Patient will get blood draw at Quest PSC"
                      type="checkbox"
                      id={`kitShip_PatblooddrawQuest`}
                      name={`kitShip_PatblooddrawQuest`}
                      class="filled-in"
                      checked={this.state.kitShip_PatblooddrawQuest}
                      onChange={(e) => this.setState({kitShip_PatblooddrawQuest: e.target.checked ? 'Patient will get blood draw at Quest PSC' : null})}
                    />
                    <label for={`kitShip_PatblooddrawQuest`}>
                      Patient will get blood draw at Quest PSC &nbsp;{" "}
                    </label>
                    <input
                      data-val="Other"
                      type="checkbox"
                      id={`kitShip_PatOthers`}
                      name="kitShip_PatOthers"
                      class="filled-in"
                      checked={this.state.kitShip_PatOthers}
                      onChange={(e) => this.setState({kitShip_PatOthers: e.target.checked ? 'Other' : null})}
                    />
                    <label for={`kitShip_PatOthers`}>Other &nbsp; </label>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="form-label" for="address">
                    &nbsp;
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      ref={(ref) => (me.kitShip_PatOthersInfo = ref)}
                      id={`kitShip_PatOthersInfo`}
                      class="form-control "
                      placeholder=" Other info"
                      value={me.state.kitShip_PatOthersInfo}
                      onChange={({ target: { value } }) => me.setState({kitShip_PatOthersInfo: value})}
                      disabled={!this.state.kitShip_PatOthers}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* OvaWatch Kit Shipping Information end*/}
          {/* OvaWatch Kit Notes & Certify */}
          <Notes_and_Certify
            me={this}
            state={this.state}
            handleSave={this.handleSave.bind(this)}
            setState={this.setState}
          />
          {/* OvaWatch Kit Notes & Certify  end*/}
        </div>
        {/* end tab panel */}

        {/* <div class="row clearfix">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card">
                            <div class="header">
                                <h2>Basic Information {this.state.orderData.status}</h2>
                                <ul class="header-dropdown m-r--5">
                                    <li class="dropdown"> <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i class="zmdi zmdi-more-vert"></i></a>
                                        <ul class="dropdown-menu pull-right">
                                            <li><a href="javascript:void(0);" class=" waves-effect waves-block">Action</a></li>
                                            <li><a href="javascript:void(0);" class=" waves-effect waves-block">Another action</a></li>
                                            <li><a href="javascript:void(0);" class=" waves-effect waves-block">Something else here</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div class="body card position">

                                <div class="row clearfix">
                                    <div class="col-sm-4 col-xs-12">
                                        <div class="form-group form-float">
                                            <div class="form-line">
                                                <label htmlFor="firstName" class="color-green">First Name:</label><small className="color-red">*</small>
                                                <input id="firstName" ref={(ref) => me.firstName = ref} type="text" class="form-control" />

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-xs-12">
                                        <div class="form-group">
                                            <div class="form-line">
                                                <label class="color-green" htmlFor="lastName">Last Name:</label><small className="color-red">*</small>
                                                <input id="lastName" ref={(ref) => me.lastName = ref} type="text" class="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row clearfix">
                                    <div class="col-sm-4 col-xs-12">
                                        <div class="form-group">
                                            <div class="form-line">
                                                <label class="color-green" htmlFor="testOrdered">Test Ordered:</label><small className="color-red">*</small>
                                                <input id="testOrdered" ref={(ref) => me.testOrdered = ref} type="text" class="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-3 col-xs-12">
                                        <div class="form-group">
                                            <label class="color-green" htmlFor="role">Select Patient:</label><small className="color-red">*</small>
                                            <Select
                                                value={me.state.selectedPatient}
                                                defaultValue={""}
                                                label="Select Patient:"
                                                options={me.state.patients}
                                                styles={colourStyles}
                                                onChange={(val) => me.setState({ selectedPatient: val })}
                                            />

                                        </div>
                                    </div>
                                </div>
                                <div class="row clearfix">
                                    <div class="col-sm-4 col-xs-12">                                       

                                        <button id="btnSave" class="btn btn-raised g-bg-blue waves-effect" onClick={me.handleSave.bind(this)}>{me.state.buttonText}</button> &nbsp; &nbsp;
                                            
                                    </div>




                                </div>

                            </div>
                        </div>
                    </div>

                </div> */}
      </div>
    );
  }
}
