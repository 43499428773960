import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import FileSaver from "file-saver";
// import $ from "jquery";
// import jsPDF from "jspdf";
// import html2canvas from "html2canvas";
import { protectedResources } from "./../authConfig";

// import jsPDF from "jspdf";
// import ReactDOMServer from "react-dom/server";

// const dotenv = require('dotenv');
// //dotenv.config();
const helper = {
  getAccessToken: async (_context) => {
    if (process.env.REACT_APP_ENV === "DEV") {
      let user = JSON.parse(localStorage.getItem("user"));
      return user.token;
    }
    const { accessToken } = await _context.instance.acquireTokenSilent({
      scopes: protectedResources.apiOrders.scopes,
      account: _context.accounts[0],
    });
    return accessToken;
  },
  getTRFHtmlFromUrl: async (exactHtmlUrl, accessToken) => {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log("helper->user.token: ", user.token);

    let opt = {
      url: exactHtmlUrl,
      method: "GET",
      data: {},
      // responseType: 'arraybuffer',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        Accept: "text/html",
      },
    };
    console.log("printing PDF with url: ", opt);
    let response = await axios(opt);
    console.log("printing return data: ", response.data);

    return response.data;
  },
  getTRFHtmlUrl: async (order) => {
    let provider_url_base = "";
    if (process.env.REACT_APP_ENV === "PROD") {
      // provider_url_base = `https://api.aspirawh.com/api/provider`;
      provider_url_base = `${process.env.REACT_APP_PROVIDER_FRONTEND_BASE_URL_PROD}`;
    } else if (process.env.REACT_APP_ENV === "STAGING") {
      // provider_url_base = `https://api.dev-aspiralab.com/api/provider`;
      provider_url_base = `${process.env.REACT_APP_PROVIDER_FRONTEND_BASE_URL_STAGING}`;
    } else {
      // provider_url_base = `http://localhost:3008/api/provider`;
      provider_url_base = `${process.env.REACT_APP_PROVIDER_FRONTEND_BASE_URL_DEV}`;
    }

    let _baseUrl = provider_url_base;
    let orderid = order._id;

    let _fetchUrl = `${
      _baseUrl.length > 0 ? _baseUrl : ""
    }/provider-order-ova1?orderId=${orderid}`;

    let i = 0;
    if (order?.tests.length === 1) {
      console.log("|--->order?.tests[0]: ", order?.tests[0]);

      const testCodeLowerCase = order?.tests[0].testCode.toLowerCase();
      switch (testCodeLowerCase) {
        case "ova1":
          break;
        case "ovawatch":
        case "ovasight":
        case "vds-200":
          _fetchUrl = `${
            _baseUrl.length > 0 ? _baseUrl : ""
          }/provider-order-OvaWatch?orderId=${orderid}`;
          break;
        case "genetixcarrierscreening":
          _fetchUrl = `${
            _baseUrl.length > 0 ? _baseUrl : ""
          }/provider-order-gtcs?orderId=${orderid}`;
          break;
        case "genetixhboc1":
          _fetchUrl = `${
            _baseUrl.length > 0 ? _baseUrl : ""
          }/provider-order-hboc?orderid=${orderid}`;
          break;
        case "COVD":
          _fetchUrl = `${
            _baseUrl.length > 0 ? _baseUrl : ""
          }/provider-order-covd?orderid=${orderid}`;
          break;
      }
    } else if (order?.tests.length === 2) {
      // const testcode_1: any = order.tests[1].testCode || "";
      ++i;

      if (
        order?.tests[0].testCode === "Ova1" &&
        order?.tests[i]?.testCode === "COVD"
      ) {
        _fetchUrl = `${
          _baseUrl.length > 0 ? _baseUrl : ""
        }/provider-order-ova1?orderid=${orderid}`;
      } else if (
        order?.tests[0].testCode === "Ova1" &&
        order?.tests[i]?.testCode === "GenetixHBOC1"
      ) {
        _fetchUrl = `${
          _baseUrl.length > 0 ? _baseUrl : ""
        }/provider-order-ovahboc?orderid=${orderid}`;
      } else if (
        order?.tests[0].testCode === "GenetiXCarrierScreening" &&
        order?.tests[i]?.testCode === "COVD"
      ) {
        _fetchUrl = `${
          _baseUrl.length > 0 ? _baseUrl : ""
        }/provider-order-gtcs?orderId=${orderid}`;
      } else if (
        order?.tests[0].testCode === "GenetixHBOC1" &&
        order?.tests[i]?.testCode === "COVD"
      ) {
        _fetchUrl = `${
          _baseUrl.length > 0 ? _baseUrl : ""
        }/provider-order-hboc?orderid=${orderid}`;
      }
    } else if (order?.tests.length === 3) {
      i = 1;
      let j = 2;
      if (
        order?.tests[0].testCode === "Ova1" &&
        order?.tests[i].testCode === "GenetixHBOC1" &&
        order?.tests[j]?.testCode === "COVD"
      ) {
        _fetchUrl = `${
          _baseUrl.length > 0 ? _baseUrl : ""
        }/provider-order-ovahboc?orderid=${orderid}`;
      }
    }

    return _fetchUrl;

    // const user = JSON.parse(localStorage.getItem("user"));
    // console.log("helper->user.token: ", user.token);

    // // static contextType = MsalContext;
    // // const { accessToken } = await this.context.instance.acquireTokenSilent({
    // //     scopes: protectedResources.apiOrders.scopes,
    // //     account: this.context.accounts[0]
    // //   });

    // let provider_url_base = '';
    // if (process.env.REACT_APP_ENV === "PROD") {
    //     // provider_url_base = `https://api.aspirawh.com/api/provider`;
    //     provider_url_base = `${process.env.REACT_APP_PROVIDER_API_BASE_URL_PROD}`;
    // }
    // else if (process.env.REACT_APP_ENV === "STAGING") {
    //     // provider_url_base = `https://api.dev-aspiralab.com/api/provider`;
    //     provider_url_base = `${process.env.REACT_APP_PROVIDER_API_BASE_URL_STAGING}`;
    // }
    // else {
    //     // provider_url_base = `http://localhost:3008/api/provider`;
    //     provider_url_base = `${process.env.REACT_APP_PROVIDER_API_BASE_URL_DEV}`;
    // }
    // let opt = {
    //     url: `${provider_url_base}/orders/getOrderHtmlExactUrl/${orderId}`,
    //     method: "GET",
    //     data: {},
    //     responseType: 'arraybuffer',
    //     headers: {
    //         Authorization: `Bearer ${accessToken}`,
    //         Accept: 'application/pdf',
    //     }

    // };
    // console.log("printing PDF with url: ", opt);
    // let response = await axios(opt);
    // console.log("printing return data: ", response.data);
    // // FileSaver.saveAs(
    // //     new Blob([response.data], { type: 'application/pdf' }),
    // //     `trf-${custom_formatted_id}.pdf`
    // // );

    // return response.data;
  },

  getPDF: (custom_formatted_id) => {
    // var HTML_Width = $(".canvas_div_pdf").width();
    // var HTML_Height = $(".canvas_div_pdf").height();
    // var top_left_margin = 15;
    // var PDF_Width = HTML_Width + top_left_margin * 2;
    // var PDF_Height = PDF_Width * 1.5 + top_left_margin * 2;
    // var canvas_image_width = HTML_Width;
    // var canvas_image_height = HTML_Height;

    // var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;

    // html2canvas($(".canvas_div_pdf")[0], { allowTaint: true }).then(function (
    //   canvas
    // ) {
    //   canvas.getContext("2d");

    //   console.log(canvas.height + "  " + canvas.width);

    //   var imgData = canvas.toDataURL("image/jpeg", 1.0);
    //   var pdf = new jsPDF("p", "pt", [PDF_Width, PDF_Height]);
    //   pdf.addImage(
    //     imgData,
    //     "JPG",
    //     top_left_margin,
    //     top_left_margin,
    //     canvas_image_width,
    //     canvas_image_height
    //   );

    //   for (var i = 1; i <= totalPDFPages; i++) {
    //     pdf.addPage(String(PDF_Width), String(PDF_Height));
    //     pdf.addImage(
    //       imgData,
    //       "JPG",
    //       top_left_margin,
    //       -(PDF_Height * i) + top_left_margin * 4,
    //       canvas_image_width,
    //       canvas_image_height
    //     );
    //   }

    //   pdf.save(`${custom_formatted_id}.pdf`);
    // });
  },

  printPDF: async (orderId, custom_formatted_id, accessToken) => {
    const user = JSON.parse(localStorage.getItem("user"));
    // console.log("helper->user.token: ", user.token);

    // static contextType = MsalContext;
    // const { accessToken } = await this.context.instance.acquireTokenSilent({
    //     scopes: protectedResources.apiOrders.scopes,
    //     account: this.context.accounts[0]
    //   });

    let provider_url_base = "";
    if (process.env.REACT_APP_ENV === "PROD") {
      // provider_url_base = `https://api.aspirawh.com/api/provider`;
      provider_url_base = `${process.env.REACT_APP_PROVIDER_API_BASE_URL_PROD}`;
    } else if (process.env.REACT_APP_ENV === "STAGING") {
      // provider_url_base = `https://api.dev-aspiralab.com/api/provider`;
      provider_url_base = `${process.env.REACT_APP_PROVIDER_API_BASE_URL_STAGING}`;
    } else {
      // provider_url_base = `http://localhost:3008/api/provider`;
      provider_url_base = `${process.env.REACT_APP_PROVIDER_API_BASE_URL_DEV}`;
    }

    let pdfFileName = `trf-${custom_formatted_id}.pdf`;
    // if (process.env.REACT_APP_ENV !== 'DEV') {
    //     let opt = {
    //         url: `${provider_url_base}/orders/printpdf/${orderId}`,
    //         method: "GET",
    //         data: {},
    //         // responseType: 'arraybuffer',
    //         headers: {
    //             Authorization: `Bearer ${accessToken}`,
    //             // Accept: 'application/pdf',
    //         }

    //     };
    //     console.log("printing PDF with url: ", opt);
    //     let response = await axios(opt);
    //     console.log("printing return data: ", response.data);

    //     const signedUrl = response.data;
    //     const res = await axios({ url: signedUrl, method: 'GET', responseType: 'blob' });
    //     console.log("res: ", res);
    //     const blob = await res.data;
    //     FileSaver.saveAs(blob, pdfFileName);

    //     return response.data;
    // }
    // else {
    let opt = {
      url: `${provider_url_base}/orders/printpdf/${orderId}`,
      method: "GET",
      data: {},
      //   responseType: "arraybuffer",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        // Accept: "application/pdf",
      },
    };
    console.log("printing PDF with url: ", opt);
    let response = await axios(opt);
    console.log("printing return data: ", response.data);

    let pdfBuffer = helper.base64ToArrayBuffer(response.data);
    FileSaver.saveAs(
      new Blob([pdfBuffer], { type: "application/pdf" }),
      pdfFileName
    );

    return pdfBuffer;
    // }
  },
  base64ToArrayBuffer: (base64) => {
    var binaryString = window.atob(base64);
    console.log("binaryString ", binaryString);
    var binaryLen = binaryString.length;
    var bytes = new Uint8Array(binaryLen);
    for (var i = 0; i < binaryLen; i++) {
      var ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  },
  formatDate: (_inputDateStr) => {
    //2020-10-11T16:09:30.095Z
    if (moment(_inputDateStr, "YYYY-MM-DD HH:mm:ss", true).isValid()) {
      console.log("formatDate->_inputDateStr: ", _inputDateStr);
      let temps = _inputDateStr.split("T");
      console.log("temps: ", temps);
      return moment(moment(temps[0]).format("MM/DD/YYYY")).toDate();
    }

    return "";
  },
  formatDateReturnStr: (_inputDateStr) => {
    //2020-10-11T16:09:30.095Z
    if (
      typeof _inputDateStr !== "undefined" &&
      _inputDateStr &&
      moment(_inputDateStr).isValid()
    ) {
      let temps = _inputDateStr.split("T");
      console.log("temps: ", temps);
      return moment(temps[0]).format("MM/DD/YYYY");
    }

    return "";
  },
  colourStyles: {
    control: (base) => ({
      ...base,
      border: 0,
      // This line disable the blue border
      boxShadow: "none",
      background: "white",
      color: "black",
    }),
    option: (base, state) => ({
      ...base,
      // backgroundColor: state.isSelected ? "red" : "green",
      // ':active': {
      //     backgroundColor: state.isSelected ? "cyan": "yellow"
      // }
    }),
    // option: (styles, { data, isDisabled, isFocused, isSelected }) => {

    //     return {
    //         ...styles,
    //         backgroundColor: isDisabled ? 'red' : "white",
    //         color: 'darkgray',
    //         cursor: isDisabled ? 'not-allowed' : 'default',
    //     };
    // },
  },
  usStates: [
    { code: "AL", label: "Alabama", value: "Alabama" },
    { code: "AK", label: "Alaska", value: "Alaska" },
    { code: "AZ", label: "Arizona", value: "Arizona" },
    { code: "AR", label: "Arkansas", value: "Arkansas" },
    { code: "CA", label: "California", value: "California" },
    { code: "CO", label: "Colorado", value: "Colorado" },
    { code: "CT", label: "Connecticut", value: "Connecticut" },
    { code: "DE", label: "Delaware", value: "Delaware" },
    {
      code: "DC",
      label: "District Of Columbia",
      value: "District Of Columbia",
    },
    { code: "FL", label: "Florida", value: "Florida" },
    { code: "GA", label: "Georgia", value: "Georgia" },
    { code: "HI", label: "Hawaii", value: "Hawaii" },
    { code: "ID", label: "Idaho", value: "Idaho" },
    { code: "IL", label: "Illinois", value: "Illinois" },
    { code: "IN", label: "Indiana", value: "Indiana" },
    { code: "IA", label: "Iowa", value: "Iowa" },
    { code: "KS", label: "Kansas", value: "Kansas" },
    { code: "KY", label: "Kentucky", value: "Kentucky" },
    { code: "LA", label: "Louisiana", value: "Louisiana" },
    { code: "ME", label: "Maine", value: "Maine" },
    { code: "MD", label: "Maryland", value: "Maryland" },
    { code: "MA", label: "Massachusetts", value: "Massachusetts" },
    { code: "MI", label: "Michigan", value: "Michigan" },
    { code: "MN", label: "Minnesota", value: "Minnesota" },
    { code: "MS", label: "Mississippi", value: "Mississippi" },
    { code: "MO", label: "Missouri", value: "Missouri" },
    { code: "MT", label: "Montana", value: "Montana" },
    { code: "NE", label: "Nebraska", value: "Nebraska" },
    { code: "NV", label: "Nevada", value: "Nevada" },
    { code: "NH", label: "New Hampshire", value: "New Hampshire" },
    { code: "NJ", label: "New Jersey", value: "New Jersey" },
    { code: "NM", label: "New Mexico", value: "New Mexico" },
    { code: "NY", label: "New York", value: "New York" },
    { code: "NC", label: "North Carolina", value: "North Carolina" },
    { code: "ND", label: "North Dakota", value: "North Dakota" },
    { code: "OH", label: "Ohio", value: "Ohio" },
    { code: "OK", label: "Oklahoma", value: "Oklahoma" },
    { code: "OR", label: "Oregon", value: "Oregon" },
    { code: "PA", label: "Pennsylvania", value: "Pennsylvania" },
    { code: "RI", label: "Rhode Island", value: "Rhode Island" },
    { code: "SC", label: "South Carolina", value: "South Carolina" },
    { code: "SD", label: "South Dakota", value: "South Dakota" },
    { code: "TN", label: "Tennessee", value: "Tennessee" },
    { code: "TX", label: "Texas", value: "Texas" },
    { code: "UT", label: "Utah", value: "Utah" },
    { code: "VT", label: "Vermont", value: "Vermont" },
    { code: "VA", label: "Virginia", value: "Virginia" },
    { code: "WA", label: "Washington", value: "Washington" },
    { code: "WV", label: "West Virginia", value: "West Virginia" },
    { code: "WI", label: "Wisconsin", value: "Wisconsin" },
    { code: "WY", label: "Wyoming", value: "Wyoming" },
  ],
  useQuery: function () {
    return new URLSearchParams(useLocation().search);
  },
  getAzureRedirectURL: function () {
    if (process.env.REACT_APP_ENV === "PROD") {
      return `${process.env.REACT_APP_AZURE_REDIRECT_URL_PROD}`;
    } else if (process.env.REACT_APP_ENV === "STAGING") {
      return `${process.env.REACT_APP_AZURE_REDIRECT_URL_STAGING}`;
    } else {
      return `${process.env.REACT_APP_AZURE_REDIRECT_URL_DEV}`;
    }
  },
  getAPIBaseURI: function () {
    if (process.env.REACT_APP_ENV === "PROD") {
      return `${process.env.REACT_APP_API_BASE_URL_PROD}`;
    } else if (process.env.REACT_APP_ENV === "STAGING") {
      return `${process.env.REACT_APP_API_BASE_URL_STAGING}`;
    } else {
      return `${process.env.REACT_APP_API_BASE_URL_DEV}`;
    }
  },
  getAssaysByType: async (_type) => {
    try {
      let url_base = helper.getAPIBaseURI();
      let opt = {
        url: `${url_base}/assays/bytype/${_type}`,
        method: "GET",
        // data: data,
        headers: {
          Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFsYW1yZXpvYW51bEBnbWFpbC5jb20iLCJpYXQiOjE1OTIzODQ0NjR9.Vlqn6clmjUMKFdtMd2NOxrJbu8n0PpPSoH_eGXVkSEM`,
        },
      };
      console.log("Getting Assays with url: ", opt);
      let ret = await axios(opt);
      console.log("ret.data: ", ret.data);

      const retdata = ret.data;

      return retdata;
    } catch (error) {
      return error;
    }
  },
  getOrderIdFromQueryString: (name, url = window.location.href) => {
    // const search = this.props.location.search;
    // const orderId = new URLSearchParams(search).get("orderId");

    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return "";
    return decodeURIComponent(results[2].replace(/\+/g, " "));
  },
  redirectToReportReviewPage: (orderId) => {
    window.location.href = `/report_review?orderId=${orderId}`;
  },

  getOrderFromServerById: (_id, accessToken) => {
    return new Promise(async (resolve, reject) => {
      try {
        let url_base = helper.getAPIBaseURI();
        let opt = {
          url: `${url_base}/orders/${_id}`,
          method: "GET",
          // data: data,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        };
        console.log("Getting order with options: ", opt);
        let ret = await axios(opt);
        console.log("ret.data: ", ret.data);

        const retdata = ret.data;

        resolve(retdata.orders[0]);
      } catch (error) {
        console.log("getOrderFromServerById->error: ", error);
        reject(error);
      }
    });
  },

  savePdfUsingJSPDF: (element, custom_formatted_id, parent = null) => {
    // const doc = new jsPDF("p", "pt", "letter");
    // doc.html(ReactDOMServer.renderToString(element), {
    //     callback: function (doc) {
    //         doc.save(`trf-${custom_formatted_id}.pdf`);
    //         if (parent) {
    //             const theButton = document.getElementById(`${parent}_btnViewTRF`);
    //             theButton.textContent = "View TRF";
    //             theButton.classList.add("text-white");
    //             theButton.classList.remove("button--loading");
    //             theButton.disabled = false;
    //         }
    //     }
    // });
  },
};
export default helper;
