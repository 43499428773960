import React, { PureComponent } from "react";

import PropTypes from 'prop-types';
//import $ from 'jquery';
import PhoneNumberInput from './../portalComponents/phoneNumberInput';

export default class PhysicianInformationHBOCForm2 extends PureComponent {

    constructor(props) {
        super(props)
    
        this.state = {          
        }   
        
    }  
    
    render() {

        

        return (
        <>  
            <div class="panel-body">
                <div class="row clearfix">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-label" for="ordered_before1">Organization/Account #</label>
                            <div class="form-line">                                
                                <input type="text" id="phyinfoHBOC_Organization_account2" class="form-control" placeholder="Organization/Account #"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group required">
                            <label class="form-label" for="ordered_before1">Name </label>
                            <div class="form-line">                                
                                <input type="text" id="phyinfoHBOC_name2" class="form-control required" placeholder="Name"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group required">
                            <label class="form-label" for="ordered_before1">NPI</label>
                            <div class="form-line">                                
                                <input type="text" id="phyinfoHBOC_npi2" class="form-control required" placeholder="NPI"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group required">
                            <label class="form-label" for="ordered_before1">Email</label>
                            <div class="form-line">                                
                                <input type="email" id="phyinfoHBOC_email2" class="form-control required" placeholder="Email"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row clearfix">
                    <div class="col-md-12">
                        <div class="form-group required">
                            <label class="form-label" for="ordered_before1">Address</label>
                            <div class="form-line">                                
                                <input type="text" id="phyinfoHBOC_address2" class="form-control required" placeholder="Address"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group ">
                            <label class="form-label" for="ordered_before1">City </label>
                            <div class="form-line">                                
                                <input type="text" id="phyinfoHBOC_city2" class="form-control" placeholder="City"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group ">
                            <label class="form-label" for="ordered_before1">State</label>
                            <div class="form-line">                                
                                <input type="text" id="phyinfoHBOC_state2" class="form-control" placeholder="State"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group ">
                            <label class="form-label" for="ordered_before1">Zip Code</label>
                            <div class="form-line">                                
                                <input type="email" id="phyinfoHBOC_zipCode2" class="form-control" placeholder="Zip Code"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group required">
                            <label class="form-label" for="ordered_before1">Phone </label>
                            <div class="form-line"> 
                                <PhoneNumberInput />                                 
                                {/* <input type="text" id="phyinfoHBOC_Phone2" class="form-control required" placeholder="Phone"/> */}
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group required">
                            <label class="form-label" for="ordered_before1">Fax</label>
                            <div class="form-line">                                
                                <input type="text" id="phyinfoHBOC_fax2" class="form-control required" placeholder="Fax"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group required">
                            <label class="form-label" for="ordered_before1">Fax copy to</label>
                            <div class="form-line">                                
                                <input type="email" id="phyinfoHBOC_Faxcopyto2" class="form-control required" placeholder="Fax copy to"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row clearfix">
                    <div class="col-md-12">
                        <div class="demo-checkbox ">      
                            <input type="checkbox" id="phyinfoHBOC_confirmation2" name="phyinfoHBOC_confirmation2" class="filled-in required" />
                            <label for="phyinfoHBOC_confirmation2" class="form-label">By checking the box below I am confirming that I have provided informed consent for this test. </label> 
                                                        
                        </div>
                    </div>
                </div>

                <div class="row clearfix">
                    <div class="col-md-12 text-right">
                        <button id="btnPhysicianInformationHBOCForm2Next" class="btn btn-light">Next</button>
                        <button style={{ display: 'none' }} id="btnPatientInfoHBOCForm2Next" data-toggle="collapse" data-target="#PatientInformationHBOCForm2" class="hide">Next</button>
                        <button style={{ display: 'none' }} id="btnPhysicianInfoHBOCForm2Next" data-toggle="collapse" data-target="#PhysicianInformationHBOCForm2" class="hide">Next</button>
                    </div>
                </div>     
            </div>        
        </>
    );
}
}
