import React from "react";
import Select from 'react-select'
import DatePicker from 'react-date-picker';
import axios from "axios";
import { message } from 'antd';
import { protectedResources } from "./../authConfig";
import { MsalContext } from "@azure/msal-react";

import Toast from '../mycomponents/toast/Toast';
import helper from './../utils/helper';

const API_BASE_URL = helper.getAPIBaseURI();


export default class AddEditPatientContent extends React.PureComponent {
    static contextType = MsalContext;

    constructor(props) {
        super(props);

        this.state = {
            usStates: [
                { label: 'ALABAMA', value: 'ALABAMA' },
                { label: 'ALASKA', value: 'ALASKA' },
                { label: 'AMERICAN SAMOA', value: 'AMERICAN SAMOA' },
                { label: 'ARIZONA', value: 'ARIZONA' },
                { label: 'ARKANSAS', value: 'ARKANSAS' },
                { label: 'CALIFORNIA', value: 'CALIFORNIA' },
                { label: 'COLORADO', value: 'COLORADO' },
                { label: 'CONNECTICUT', value: 'CONNECTICUT' },
                { label: 'DELAWARE', value: 'DELAWARE' },
                { label: 'DISTRICT OF COLUMBIA', value: 'DISTRICT OF COLUMBIA' },
                { label: 'FEDERATED STATES OF MICRONESIA', value: 'FEDERATED STATES OF MICRONESIA' },
                { label: 'FLORIDA', value: 'FLORIDA' },
                { label: 'GEORGIA', value: 'GEORGIA' },
                { label: 'GUAM', value: 'GUAM' },
                { label: 'HAWAII', value: 'HAWAII' },
                { label: 'IDAHO', value: 'IDAHO' },
                { label: 'ILLINOIS', value: 'ILLINOIS' },
                { label: 'INDIANA', value: 'INDIANA' },
                { label: 'IOWA', value: 'IOWA' },
                { label: 'KANSAS', value: 'KANSAS' },
                { label: 'KENTUCKY', value: 'KENTUCKY' },
                { label: 'LOUISIANA', value: 'LOUISIANA' },
                { label: 'MAINE', value: 'MAINE' },
                { label: 'MARSHALL ISLANDS', value: 'MARSHALL ISLANDS' },
                { label: 'MARYLAND', value: 'MARYLAND' },
                { label: 'MASSACHUSETTS', value: 'MASSACHUSETTS' },
                { label: 'MICHIGAN', value: 'MICHIGAN' },
                { label: 'MINNESOTA', value: 'MINNESOTA' },
                { label: 'MISSISSIPPI', value: 'MISSISSIPPI' },
                { label: 'MISSOURI', value: 'MISSOURI' },
                { label: 'MONTANA', value: 'MT' },
                { label: 'NEBRASKA', value: 'NEBRASKA' },
                { label: 'NEVADA', value: 'NEVADA' },
                { label: 'NEW HAMPSHIRE', value: 'NEW HAMPSHIRE' },
                { label: 'NEW JERSEY', value: 'NEW JERSEY' },
                { label: 'NEW MEXICO', value: 'NEW MEXICO' },
                { label: 'NEW YORK', value: 'NEW YORK' },
                { label: 'NORTH CAROLINA', value: 'NORTH CAROLINA' },
                { label: 'NORTH DAKOTA', value: 'NORTH DAKOTA' },
                { label: 'NORTHERN MARIANA ISLANDS', value: 'NORTHERN MARIANA ISLANDS' },
                { label: 'OHIO', value: 'OHIO' },
                { label: 'OKLAHOMA', value: 'OKLAHOMA' },
                { label: 'OREGON', value: 'OREGON' },
                { label: 'PALAU', value: 'PALAU' },
                { label: 'PENNSYLVANIA', value: 'PENNSYLVANIA' },
                { label: 'PUERTO RICO', value: 'PUERTO RICO' },
                { label: 'RHODE ISLAND', value: 'RHODE ISLAND' },
                { label: 'SOUTH CAROLINA', value: 'SOUTH CAROLINA' },
                { label: 'SOUTH DAKOTA', value: 'SOUTH DAKOTA' },
                { label: 'TENNESSEE', value: 'TENNESSEE' },
                { label: 'TEXAS', value: 'TEXAS' },
                { label: 'UTAH', value: 'UTAH' },
                { label: 'VERMONT', value: 'VERMONT' },
                { label: 'VIRGIN ISLANDS', value: 'VIRGIN ISLANDS' },
                { label: 'VIRGINIA', value: 'VIRGINIA' },
                { label: 'WASHINGTON', value: 'WASHINGTON' },
                { label: 'WEST VIRGINIA', value: 'WEST VIRGINIA' },
                { label: 'WISCONSIN', value: 'WISCONSIN' },
                { label: 'WYOMING', value: 'WYOMING' }
            ],
            maleFemale: [
                { label: 'Male', value: 'male' },
                { label: 'Female', value: 'female' },
            ],

            selectedGender: "",
            selectedState: "",
            dob: "",

            toastMessage: "",
            list: [],
            buttonText: "Create",
        };

        this.fileInput = React.createRef();
        this.fileInputButton = React.createRef();

        this.onfileInputButton.bind(this);


        this.patientID = React.createRef();
        this.email = React.createRef();
        this.firstName = React.createRef();
        this.lastName = React.createRef();
        this.address = React.createRef();
        this.gender = React.createRef();
        this.phoneNo = React.createRef();
        this.city = React.createRef();
        this.zip = React.createRef();

        this._id = null;
    }

    async componentDidMount() {
        const me = this;

        axios.interceptors.response.use(function (response) {
            // Do something with response data
            return response;
        }, function (error) {
            switch (error.response.status) {
                case 409:
                    console.log("interceptors->error: ", error);

                    if (me._id) {
                        me.setState({
                            toastMessage: `Error occured`
                        }, () => {
                            //me.showToast("danger");
                            message.error(me.state.toastMessage);
                        })
                    }
                    else {
                        me.setState({
                            toastMessage: `A Patient with the same email address already exists`
                        }, () => {
                            //me.showToast("danger");
                            message.error(me.state.toastMessage);
                        })
                    }
                    break;

                default:
                    break
            }
            // Do something with response error
            return Promise.reject(error);
        });


        console.log(window.location.search);
        const params = new URLSearchParams(window.location.search);
        this._id = params.get("id");

        console.log("this._id: ", this._id);

        if (this._id) { //Edit Mode
            
            const accessToken = await helper.getAccessToken(this.context)

            let response = await axios({
                url: `${API_BASE_URL}/patients/${this._id}`,
                method: "GET",
                headers: {
                    "Authorization": `Bearer ${accessToken}`,
                    "Content-type": "Application/json",
                }
            });


            console.log("response.data: ", response.data);

            this.firstName.value = response.data.firstName;
            this.lastName.value = response.data.lastName;
            this.email.value = response.data.email;
            this.phoneNo.value = response.data.phoneNo;
            // dob
            // this.gender.value = response.data.gender;
            if (typeof response.data.address == 'string' && response.data.address != '[object Object]') {
                response.data.address = JSON.parse(response.data.address);
            }
            this.address.value = response.data.address.address_1;
            this.city.value = response.data.address.city;
            // this.state
            this.zip.value = response.data.address.zipCode;
            this.patientID.value = response.data.patientID;



            let selectedGenders = this.state.maleFemale.filter(option => {
                return option.value === response.data.gender;
            });
            let _selectedGender = selectedGenders.length > 0 ? selectedGenders[0] : { label: 'Male', value: 'male' };

            let selectedStates = this.state.usStates.filter(option => {
                if (typeof response.data.address.state != 'undefined') {
                    return option.value === response.data.address.state.toUpperCase();
                }
                else {
                    return false;
                }
            });
            let _selectedState = selectedStates.length > 0 ? selectedStates[0] : { label: 'ALABAMA', value: 'AL' };

            this.setState({
                dob: response.data.dob,
                selectedGender: _selectedGender,
                selectedState: _selectedState,
                buttonText: "Update",
            }, () => {

                console.log("this.state.selectedGender: ", this.state.selectedGender);
                console.log("this.state.selectedState: ", this.state.selectedState);
            });


        }
    }
    onfileInputButton() {
        this.fileInput.click();
    }
    onChangeDob = date => this.setState({ dob: date });

    async handleSave() {


        if (this.email.value.length < 1 || this.firstName.value.length < 1 || this.lastName.value.length < 1) {
            this.setState({
                toastMessage: `Please fill up all required fields`
            }, () => {
                this.showToast("danger");
            })
        }
        else {

            const user = JSON.parse(localStorage.getItem("user"));
            // console.log("user.token: ", user.token);

            // this.email = React.createRef();
            // this.firstName = React.createRef();
            // this.lastName = React.createRef();
            // this.address = React.createRef();
            // this.city = React.createRef();
            // this.state = React.createRef();
            // this.zip = React.createRef();

            const formData = new FormData();
            console.log("profileImage: ", this.state.profileImage);


            formData.append('email', this.email.value);
            formData.append('firstName', this.firstName.value);
            formData.append('lastName', this.lastName.value);
            // 
            formData.append('gender', this.state.selectedGender.value);
            formData.append('phoneNo', this.phoneNo.value);
            formData.append('dob', this.state.dob);
            const address = {
                address_1: this.address.value,
                city: this.city.value,
                state: this.state.selectedState.value,
                zipCode: this.zip.value,
            };
            formData.append('address', JSON.stringify(address));
            // formData.append('city', this.city.value);
            // formData.append('state', this.state.selectedState.value);
            // formData.append('zip', this.zip.value);
            formData.append('patientID', this.patientID.value);
            formData.append('host', window.location.host);

            
            const accessToken = await helper.getAccessToken(this.context)

            if (this._id) {
                formData.append("itemID", this._id);
                axios.put(`${API_BASE_URL}/patients`, formData, {
                    headers: {
                        'Authorization': 'Bearer ' + accessToken,
                        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
                    }
                })
                    .then(({ data }) => {
                        console.log("data: ", data);

                        this.setState({
                            toastMessage: `Successfully updated pateint`
                        }, () => {
                            this.showToast("success");
                        });
                    })
            }
            else {
                axios.post(`${API_BASE_URL}/patients`, formData, {
                    headers: {
                        'Authorization': 'Bearer ' + accessToken,
                        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
                    }
                })
                    .then(({ data }) => {
                        console.log("data: ", data);

                        this.setState({
                            toastMessage: `Successfully added pateint`
                        }, () => {
                            this.showToast("success");
                        });
                    })
            }
        }
    }

    showToast(type) {
        const id = Math.floor((Math.random() * 101) + 1);

        switch (type) {
            case 'success':
                this.toastProperties = {
                    id,
                    title: 'Success',
                    description: 'This is a success toast component',
                    backgroundColor: '#5cb85c',
                    icon: "/assets/assets/check.svg"
                }
                break;
            case 'danger':
                this.toastProperties = {
                    id,
                    title: 'Danger',
                    description: 'This is a error toast component',
                    backgroundColor: '#d9534f',
                    icon: "/assets/assets/error.svg"
                }
                break;
            case 'info':
                this.toastProperties = {
                    id,
                    title: 'Info',
                    description: 'This is an info toast component',
                    backgroundColor: '#5bc0de',
                    icon: "/assets/assets/info.svg"
                }
                break;
            case 'warning':
                this.toastProperties = {
                    id,
                    title: 'Warning',
                    description: 'This is a warning toast component',
                    backgroundColor: '#f0ad4e',
                    icon: "/assets/assets/warning.svg"
                }
                break;

            default:
                this.setState({ list: [] });
        }

        // this.setState([...this.state.list, this.toastProperties]);
        this.setState({ list: [this.toastProperties] });
    }
    render() {
        if (!this.props) {
            return null;
        }

        return (
            <div>
                <div class="block-header">
                    <h2>{(window.location.href.includes("editpatient")) ? "Edit Patient" : "Add Patient"}</h2>
                </div>
                <div class="row clearfix patient-add">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card">
                            <div class="header">
                                <h2>Basic Information</h2>
                                <ul class="header-dropdown m-r--5">
                                    <li class="dropdown"> <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i class="zmdi zmdi-more-vert"></i></a>
                                        <ul class="dropdown-menu pull-right">
                                            <li><a href="javascript:void(0);" class=" waves-effect waves-block">Action</a></li>
                                            <li><a href="javascript:void(0);" class=" waves-effect waves-block">Another action</a></li>
                                            <li><a href="javascript:void(0);" class=" waves-effect waves-block">Something else here</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div class="body">

                                <div class="row clearfix">
                                    <div class="col-sm-4 col-xs-12">
                                        <div class="form-group">
                                            <div class="form-line">
                                                <label class="color-green" htmlFor="firstName">First Name:</label><small className="color-red">*</small>
                                                <input ref={(el) => this.firstName = el} id="firstName" type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-xs-12">
                                        <div class="form-group">
                                            <div class="form-line">
                                                <label class="color-green" htmlFor="lastName">Last Name:</label><small className="color-red">*</small>
                                                <input ref={(el) => this.lastName = el} id="lastName" type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-xs-12">
                                        <div class="form-group">
                                            <div class="form-line">
                                                <label class="color-green" htmlFor="email">Email:</label><small className="color-red">*</small>
                                                <input ref={(el) => this.email = el} id="email" type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row clearfix">
                                    <div class="col-sm-4 col-xs-12">
                                        <div class="form-group">
                                            <div class="form-line">
                                                <label class="color-green" htmlFor="dob">Date of Birth:</label>
                                                {/* <input id="dob" type="text" className="datepicker form-control" placeholder="" /> */}
                                                <DatePicker
                                                    className={"form-control"}
                                                    showLeadingZeros={true}
                                                    calendarIcon={null}
                                                    onChange={this.onChangeDob}
                                                    value={this.state.dob}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class="col-sm-4 col-xs-12">
                                            <div class="form-group">
                                                <div class="form-line">
                                                    <label class="color-green" htmlFor="age">Age:</label>
                                                    <input id="age" type="text" className="form-control" placeholder="" />
                                                </div>
                                            </div>
                                        </div> */}
                                    <div class="col-sm-4 col-xs-12">
                                        <div class="form-group">
                                            <div class="form-line">
                                                <label class="color-green" htmlFor="phoneNo">Phone No:</label>
                                                <input ref={(el) => this.phoneNo = el} id="phoneNo" type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-xs-12">
                                        <div class="form-group">
                                            <label class="color-green" htmlFor="gender">Gender:</label>
                                            <Select
                                                // defaultValue={"Gender"}
                                                value={this.state.selectedGender}
                                                label="Gender:"
                                                options={this.state.maleFemale}
                                                styles={helper.colourStyles}
                                                onChange={(val) => this.setState({ selectedGender: val })}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div class="row clearfix">


                                    <div class="col-sm-4 col-xs-12">
                                        <div class="form-group">
                                            <div class="form-line">
                                                <label class="color-green" htmlFor="address">Address:</label>
                                                <input ref={(el) => this.address = el} type="text" id="address" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-sm-4 col-xs-12">
                                        <div class="form-group">
                                            <div class="form-line">
                                                <label class="color-green" htmlFor="city">City:</label>
                                                <input ref={(el) => this.city = el} id="city" type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-xs-12 dropdown">
                                        <div class="form-group">
                                            <div class="form-line">
                                                <label class="color-green" htmlFor="state">State:</label>
                                                <Select
                                                    // defaultValue={"Choose State"}
                                                    value={this.state.selectedState}
                                                    label="State:"
                                                    options={this.state.usStates}
                                                    styles={helper.colourStyles}
                                                    onChange={(val) => this.setState({ selectedState: val })}
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row clearfix">

                                    <div class="col-sm-4 col-xs-12">
                                        <div class="form-group">
                                            <div class="form-line">
                                                <label class="color-green" htmlFor="zipcode">Zip Code:</label>
                                                <input ref={(el) => this.zip = el} id="zipcode" type="number" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-xs-12">
                                        <div class="form-group">
                                            <div class="form-line">
                                                <label class="color-green" htmlFor="patientID">Patient ID:</label>
                                                <input ref={(el) => this.patientID = el} id="patientID" type="text" className="form-control" placeholder="" />
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class="col-sm-3 col-xs-12">
                                            
                                            <h3 class="patient-drop-file">Drop files here or click to upload.</h3>
                                            <div class="dz-message">
                                                <div class="drag-icon-cph"> <i class="material-icons">touch_app</i> </div>
                                                
                                            </div>
                                            <div class="fallback">
                                                <input ref={(el) => this.fileInput = el} class="inputfile" id="files" name="files" type="file" multiple />
                                                <button onClick={this.onfileInputButton.bind(this)} ref={(el) => this.fileInputButton = el} type="button" class="btn btn-raised btn-primary btn-lg m-l-15 waves-effect">Choose files</button>
                                            </div>
                                        </div> */}
                                </div>

                                <div class="row clearfix">
                                    <div class="col-sm-12 col-xs-12">
                                        <button id="btnSave" class="btn btn-raised g-bg-blue waves-effect" onClick={this.handleSave.bind(this)}>{this.state.buttonText}</button> &nbsp; &nbsp;
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Toast
                    toastList={this.state.list}
                    position={"top-center"}
                    autoDelete={true}
                    dismissTime={3000}
                    description={this.state.toastMessage}

                />
            </div>
        );
    }
}        
