import React, { useState, useEffect } from "react";
//import dotenv from "dotenv";
import axios from "axios";
import ReactDOM from 'react-dom';

import helper from './../utils/helper';
import DeleteModal from "./../mycomponents/modal";
import ProgressBar from "./../mycomponents/progressbar";
import { protectedResources } from "./../authConfig";
import { MsalContext } from "@azure/msal-react";
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-bs/css/dataTables.bootstrap.css';
const $ = require('jquery');
$.DataTable = require('datatables.net');
// require( 'datatables.net-bs' )( window, $ );

//dotenv.config();

const API_BASE_URL = helper.getAPIBaseURI();
let me = null;
export default class ViewUsersContent extends React.PureComponent {
    static contextType = MsalContext;

    constructor(props) {
        super(props);

        this.state = {
            users: [],
            loading: true,
            showTimer: true,
            showDeleteModal: false,
            deleteUserId: null,
            deleteUserName: "",
            showProgressBar: false,
        };


        this.userDataTable = React.createRef();

        this.handleButtonPress = this.handleButtonPress.bind(this)
        this.handleButtonRelease = this.handleButtonRelease.bind(this)

        this.$el = null;
    }
    handleButtonPress() {
        this.buttonPressTimer = setTimeout(() => this.setState({ showProgressBar: true }), 1000);
    }

    handleButtonRelease() {
        clearTimeout(this.buttonPressTimer);
    }
    callDataTable(datatotables) {
        // first time, el will be null
        // see: https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs

        console.log("this.userDataTable: ", this.userDataTable);

        me = this;
        if (!this.userDataTable) return;

        console.log("datatotables: ", datatotables);

        if (this.$el) {
            this.$el.DataTable().clear().destroy();
        }


        this.$el = $(this.userDataTable);

        //  console.log(this.this.$el.DataTable);
        let table = this.$el.DataTable({
            pageLength: 25,
            data: datatotables,
            columns: [
                {
                    title: "User ID", data: null, name: "id",
                    render: function (data, type) {
                        console.log("role.data: ", data);
                        if (type === "display") {
                            if (data.id) {
                                return data.id;
                            }
                            else {
                                return "";
                            }
                        }
                        return data;
                    }
                },
                { title: "First Name", data: "firstName", name: "firstName" },
                { title: "Last Name", data: "lastName", name: "lastName" },
                { title: "Email", data: "email", name: "email" },
                {
                    title: "Role", data: null, "name": "role",
                    render: function (data, type) {
                        console.log("role.data: ", data);
                        if (type === "display") {
                            if (data.Roles.length > 0) {
                                return data.Roles[0].name;
                            }
                            else {
                                return "Portal_Admin";
                            }
                        }
                        return data;
                    }
                },
                {
                    title: "Status", data: null, "name": "status",
                    render: function (data, type) {
                        if (type === "display") {
                            if ((data.status == undefined || data.status === "Active")) {
                                return `<td>
                                <span class="label label-success">Active</td>
                            </td> `;
                            }
                            else if (data.status === "Suspened") {
                                return `<td>
                                <span class="label label-default">Suspened</span>
                            </td> `;
                            }
                            else if (data.status === "Locked") {
                                return `<td>
                                <span class="label label-danger">${data.status}</td>
                            </td> `;
                            }

                        }
                        return data;
                    }
                },
                {
                    title: "",
                    name: "",
                    data: null,
                    sortable: false,
                    render: function (data, type) {
                        if (type === "display") {
                            console.log("data: ", data);

                            let lockIcon = `<img src="/icons/icons8-lock-50.svg" />`;
                            if (data.isLocked) {
                                lockIcon = `<img src="/icons/icons8-padlock-50.svg" />`;
                            }

                            let editHref = `/edituser?id=${data._id}`;

                            return `<td>
                                    <a class="rowOption editClass" href="${editHref}"><img src="/icons/icons8-edit-50.svg" /></a>
                                    <a data-id="${data._id}" data-name="${data.firstName} ${data.lastName}" id="option-delete" class="rowOption deleteClass"><img src="/icons/icons8-delete-trash-50.svg" /></a>
                                    <a data-id="${data._id}" id="option-lock" class="rowOption lockClass">${lockIcon}</a>
                                </td> `;

                        }
                        return data;
                    }
                }
            ]
        });

        this.$el.on('click', 'tr a.deleteClass', function () {
            let item_id = ($(this).data("id"));
            let user_name = ($(this).data("name"));
            // console.log("data: ", $(this).data());//.parent("tr").data());
            let yes = false;
            me.setState({
                showDeleteModal: true,
                deleteUserId: item_id,
                deleteUserName: user_name,
            })

        });

        this.$el.on('click', 'tr a.lockClass', async function () {

            const user = JSON.parse(localStorage.getItem("user"));

            let item_id = ($(this).data("id"));

            let _options = {
                url: `${API_BASE_URL}/users/lockunlock`,
                method: "POST",
                headers: {
                    "Content-type": "Application/json",
                    Authorization: `Bearer ${user.token}`
                },
                data: {
                    itemID: item_id,
                }
            };

            let response = await axios(_options);

            if (response.data.status) {
                me.componentDidMount();
            }
            else {
                alert("Failed.");
            }
        });
    }

    async componentDidMount() {

        const me = this;
        let user = JSON.parse(localStorage.getItem("user"));
        // console.log("user.token: ", user.token);
        let accessToken = await helper.getAccessToken(this.context)
        if (process.env.REACT_APP_ENV !== 'DEV') {
            if (!user) {

                ({ data: { user } } = await axios.get(`${API_BASE_URL}/users/current`, {
                    headers: {
                        "Authorization": `Bearer ${accessToken}`
                    }
                }));

                localStorage.setItem("user", JSON.stringify(user));
            }

        }

        axios({
            url: `${API_BASE_URL}/users`,
            method: "GET",
            headers: {
                "Content-type": "Application/json",
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(({ data }) => {
                console.log("data.users: ", data.users);
                // setUsers(data.users);

                this.setState({ loading: false }, () => {

                    this.callDataTable(data.users);

                });
            });
    }

    showModal = e => {
        this.setState({
            showDeleteModal: !this.state.showDeleteModal
        });
    };
    render() {

        if (!this.state.loading) {
            return (
                <div>
                    <div class="block-header">
                        <h2>View Users</h2>
                        {/* <ul class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/orders">Orders</a></li>
                            <li class="breadcrumb-item active">View All Users</li>
                        </ul> */}
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12 col-xs-12">
                            <div class="card users-list">
                                <div class="body">
                                    <div class="table-responsive">
                                        <table id="users_table" className="display" width="100%" ref={el => (this.userDataTable = el)} />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <DeleteModal onClose={this.showModal} show={this.state.showDeleteModal}>
                        <div>
                            <span>Hold delete button for 3 seconds in order to confirm delete User {this.state.deleteUserName}</span>
                        </div>
                        <div class="delete-buttons">
                            <button class="btn waves-effect btn-delete"
                                onTouchStart={this.handleButtonPress}
                                onTouchEnd={this.handleButtonRelease}
                                onMouseDown={this.handleButtonPress}
                                onMouseUp={this.handleButtonRelease}
                                onMouseLeave={this.handleButtonRelease}
                            // onClick={this.deleteUser}
                            >
                                Delete
                            </button>
                            <button class="btn btn-raised g-bg-blue waves-effect" onClick={this.showModal}>
                                Cancel
                            </button>
                        </div>
                        <div>
                            {this.state.showProgressBar && <ProgressBar entity={"user"} id={this.state.deleteUserId} name={this.state.deleteUserName} />}
                        </div>
                    </DeleteModal>

                </div>

            );

        }
        else {
            return <div>
                <div class="block-header">
                    <h2>View Users</h2>
                    {/* <ul class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/orders">Orders</a></li>
                        <li class="breadcrumb-item active">View All Users</li>
                    </ul> */}
                </div>
                <div class="row clearfix">
                    <div class="col-md-12 col-xs-12">
                        <div class="card users-list">
                            <div class="body">

                            </div>
                        </div>
                    </div>
                </div>
            </div>;
        }

    }
}
