import React, { useEffect, useState } from "react";
import axios from "axios";
import helper from './utils/helper';

//require("dotenv").config();
const API_BASE_URL = helper.getAPIBaseURI();

const ForgotPasswordResetPage = (props) => {
  // state variables
  const [ tryingToVerifyTheRequest, setTryingToVerifyTheRequest ] = useState(true);
  const [ verificationPassed , setVerificationPassed ] = useState(false);
  const [ verificationFailed, setVerificationFailed ] = useState(false);
  const [ verificationFailedFeedback, setVerificationFailedFeedback ] = useState("");
  const [ inputPassword, setInputPassword ] = useState("");
  const [ retypedPassword, setRetypedPassword ] = useState("");
  const [ passwordMismatched , setPasswordMismatched ] = useState(false);
  const [ formSubmittedStatus, setFormSubmittedStatus ] = useState(false);
  const [ showFrom, setShowForm ] = useState(false);
  const [ redirectStatus, setRedirectStatus ] = useState(false);

  useEffect(() => {
    setTimeout(validatePasswordResetRequestFromUrlParams, 3200);
  }, [])

  const validatePasswordResetRequestFromUrlParams = async () => {
    const { userId, verificationCode } = props.match.params;

    console.log("\n===");
    console.log("userId: ", userId);
    console.log("verificationCode: ", verificationCode);
    console.log("====\n");

    const passwordResetRequestVerificationEndPoint = 
      `${API_BASE_URL}/users/forgotPassword/${userId}/${verificationCode}`;
    try {
      const { data } = await axios.get(passwordResetRequestVerificationEndPoint);
      console.log("\n===");
      console.log("data: ", data);
      console.log("===\n");

      if (data.status === "Valid verification code") {
        setVerificationPassed(true);
        setShowForm(true);
      } else {
        setVerificationFailedFeedback(data.message);
        setVerificationFailed(true);
      }
    } catch (err) {
      setVerificationFailedFeedback("Oops, something went wrong! Please try again.");
      setVerificationFailed(true);
    }

    setTryingToVerifyTheRequest(false);
  }

  const validateInputPassword = () => {
    setPasswordMismatched(false);
    const result = inputPassword === retypedPassword && inputPassword.length > 0;
    setTimeout(() => {
      setPasswordMismatched(!result);
    }, 15);

    return result;
  }

  const redirectToLoginPage = () => {
    setTimeout(() => window.location = "/", 550);
  }

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const passwordTypedCorrectly = validateInputPassword();
    console.log("\n====");
    console.log("inputPassword: ", inputPassword);
    console.log("retypedPassword: ", retypedPassword);
    console.log("====\n");
    
    const passwordUpdateAfterForgottenAPIEndPoint = `${API_BASE_URL}/users/forgotPassword`;
    const { userId, verificationCode } = props.match.params;
    if (passwordTypedCorrectly) {
      setShowForm(false);
      setFormSubmittedStatus(true);

      try {
          const { data } = await axios.put(
          passwordUpdateAfterForgottenAPIEndPoint,
          { userId, verificationCode, password: inputPassword }
        );

        console.log("\n===");
        console.log("data: ", data);
        console.log("====\n");
        setTimeout(() => {
          setFormSubmittedStatus(false);
          setRedirectStatus(true);
          redirectToLoginPage();
        }, 1750);
      } catch (err) {}
      
    }
  }

  const showRequestProcessingAnimation = () => {
    let generalFeedbackText = "";
    if (tryingToVerifyTheRequest)
      generalFeedbackText = "please wait while we verify your request...";
    else if (formSubmittedStatus)
      generalFeedbackText = "Resetting your password, please wait...";
    else if (redirectStatus)
      generalFeedbackText = "Password reset successful! Redirecting to login page...";

    return (
      <>
      <div className="request-processing-animation-box">
        <div className="circle-loader-animation"></div>
      </div>
      <p className={redirectStatus ? "no-animation" : ""}>{generalFeedbackText}</p>
      </>
    );
  }

  const showFeedBackForFailedVerification = () => {
    return (
      <p className="verification-feedback-message">{verificationFailedFeedback}</p>
    );
  }

  const showResetPasswordForm = () => {
    return (
      <form className="reset-password-form-box card-display" onSubmit={handleFormSubmit}>
        <div>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/logo_aspira.png`}
            alt="aspira logo"
            style={{ marginBottom: "2.25vw", marginLeft: "-1vw" }}
          />
        </div>
        <input 
          type="password"
          name="password"
          id="password"
          placeholder="type in a new password"
          autoComplete="off"
          autoFocus={true}
          className={passwordMismatched ? "err-email-input" : "" }
          onChange={(e) => setInputPassword(e.target.value)}
        />
        <input 
          type="password"
          name="retypedPassword"
          id="retypedPassword"
          placeholder="type again the new password"
          autoComplete="off"
          className="margin-top-2vw"
          className={passwordMismatched ? "err-email-input" : "" }
          onChange={(e) => setRetypedPassword(e.target.value)}
        />
        <button type="submit" onSubmit={handleFormSubmit}>Reset Password</button>
      </form>
    );
  }

  return (
    <div className="forgot-password-reset-page">
      {tryingToVerifyTheRequest && showRequestProcessingAnimation()}
      {verificationFailed && showFeedBackForFailedVerification()}
      {verificationPassed && showFrom && showResetPasswordForm()}
      {formSubmittedStatus && showRequestProcessingAnimation()}
      {redirectStatus && showRequestProcessingAnimation()}
    </div>
  );

}

export default ForgotPasswordResetPage;