import React, { PureComponent } from "react";

import PropTypes from 'prop-types';
import $ from 'jquery';
import helper from './../utils/helperPortal';
//import DatePicker from 'react-datepicker';

export default class ASPGENHereditaryBreastOvarianCancerForm extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {

        }

    }

    componentDidMount() {
        $(document).ready(async () => {

            //Assays mapping dynamically
            let _assays = await helper.getAssaysByType('HBOC');
            console.log("HBOC _assays: ", _assays);

            let content = ``;
            _assays.assays.map((a, i) => {
                content += `<div class="col-md-6">`;
                content += `<input data-qbenchid="${a.qBenchId}" data-val="${a.name}" type="radio" name="gtcs_assay_" id="hboc_assay_${i}" class="form-control" />
                <label for="hboc_assay_${i}">${a.name}  &nbsp; </label></div>`;
            });
            
            this.setState({ assays: content }, () => {
                $('#printHere_HBOC').html(content);
            });
        

            $('#btnASPGENHereditaryBreastOvarianCancerFormReset').click(() => {
                $(`#ASPGENHereditaryBreastOvarianCancerFormContainer`).find(':input').map((i, elem) => {
                    const _type = $(elem).attr('type');
                    console.log("_type: ", _type);
                    helper.resetForm(_type, elem);
                });
            })
        })
    }

    render() {

        return (
            <>
                <div id={`ASPGENHereditaryBreastOvarianCancerFormContainer`} class="panel-body">
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="form-group required">
                                <label class="form-label" for="address">ASPIRA GENETIX Hereditary Breast and Ovarian Cancer (select panel)</label>
                                <div class="demo-radio-button">
                                    <div id="printHere_HBOC" class="row clearfix"></div>                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row clearfix">
                        <div class="col-md-12 text-right">
                            <button id="btnASPGENHereditaryBreastOvarianCancerFormReset" class="btn btn-light">Reset Form</button>
                            <button id="btnASPGENHereditaryBreastOvarianCancerFormNext" class="btn btn-light">Next</button>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}
