import React from "react";

const DiagnosisCodes = (props) => {
  var tempOtherCode=props.state.otherICD10Code;
  const DiagnosisChecked = (val) => {
    var tempDiagnosis = props.me.state.diagonisisCodes;

    if (tempDiagnosis.includes(val)) {
      const index = tempDiagnosis.indexOf(val);

      if (index > -1) {
        tempDiagnosis.splice(index, 1);
      }
      props.me.setState({
        diagonisisCodes: tempDiagnosis,
      });
    } else {
      tempDiagnosis.push(val);
      props.me.setState({
        diagonisisCodes: tempDiagnosis,
      });
    }
  };
  return (
    <div id={`${props.me.state.frmprefix}hboc_icd_codes`} class="body card">
      <div id={`hboc_icd_codes`} class="col-md-12">
        <div class="form-group required">
          <div class="row clearfix">
            <div class="col-md-12">
              <label class="form-label" for="DiagnosisCodes_GHBOCG">
                <b>
                  GHBOCG Diagnosis Codes | ICD-10 Codes (check all that apply)
                </b>
              </label>
            </div>
          </div>
          <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_1">
            <div class="row clearfix">
              <div class="col-md-6">
                <input
                  type="checkbox"
                  data-val="Z15.01"
                  id={`hBOCdiagnosis_z1501`}
                  name={`hBOCdiagnosis_z1501`}
                  class="filled-in"
                  ref={(ref) => {
                    props.me.hBOCdiagnosis_z1501 = ref;
                  }}
                  onClick={(e) => {
                    props.me.DiagnosisChecked("Z15.01");
                  }}
                />
                <label for={`hBOCdiagnosis_z1501`}>
                  Z15.01 Genetic susceptibility to malignant neoplasm of breast
                  &nbsp;{" "}
                </label>
              </div>
              <div class="col-md-6">
                <input
                  type="checkbox"
                  data-val="C48.1"
                  id={`hBOCdiagnosis_c481`}
                  name={`hBOCdiagnosis_c481`}
                  class="filled-in"
                  ref={(ref) => {
                    props.me.hBOCdiagnosis_c481 = ref;
                  }}
                  onClick={(e) => {
                    props.me.DiagnosisChecked("C48.1");
                  }}
                />
                <label for={`hBOCdiagnosis_c481`}>
                  C48.1 Malignant neoplasm of specified parts of peritoneum
                  &nbsp;{" "}
                </label>
              </div>
            </div>
          </div>
          <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_2">
            <div class="row clearfix">
              <div class="col-md-6">
                <input
                  type="checkbox"
                  data-val="Z15.02"
                  id={`hBOCdiagnosis_z1502`}
                  name={`hBOCdiagnosis_z1502`}
                  class="filled-in"
                  ref={(ref) => {
                    props.me.hBOCdiagnosis_z1502 = ref;
                  }}
                  onClick={(e) => {
                    props.me.DiagnosisChecked("Z15.02");
                  }}
                />
                <label for={`hBOCdiagnosis_z1502`}>
                  Z15.02 Genetic susceptibility to malignant neoplasm of ovary
                  &nbsp;{" "}
                </label>
              </div>
              <div class="col-md-6">
                <input
                  type="checkbox"
                  data-val="C50.0"
                  id={`hBOCdiagnosis_c500`}
                  name={`hBOCdiagnosis_c500`}
                  class="filled-in"
                  ref={(ref) => {
                    props.me.hBOCdiagnosis_c500 = ref;
                  }}
                  onClick={(e) => {
                    props.me.DiagnosisChecked("C50.0");
                  }}
                />
                <label for={`hBOCdiagnosis_c500`}>
                  C50.0 Malignant neoplasm of breast [add anatomic site detail]
                  &nbsp;{" "}
                </label>
              </div>
            </div>
          </div>
          <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_3">
            <div class="row clearfix">
              <div class="col-md-6">
                <input
                  type="checkbox"
                  data-val="Z15.03"
                  id={`hBOCdiagnosis_z1503`}
                  name={`hBOCdiagnosis_z1503`}
                  class="filled-in"
                  ref={(ref) => {
                    props.me.hBOCdiagnosis_z1503 = ref;
                  }}
                  onClick={(e) => {
                    props.me.DiagnosisChecked("Z15.03");
                  }}
                />
                <label for={`hBOCdiagnosis_z1503`}>
                  Z15.03 Genetic susceptibility to malignant neoplasm of
                  prostate &nbsp;{" "}
                </label>
              </div>
              <div class="col-md-6">
                <input
                  type="checkbox"
                  data-val="C56.1"
                  id={`hBOCdiagnosis_c561`}
                  name={`hBOCdiagnosis_c561`}
                  class="filled-in"
                  ref={(ref) => {
                    props.me.hBOCdiagnosis_c561 = ref;
                  }}
                  onClick={(e) => {
                    props.me.DiagnosisChecked("C56.1");
                  }}
                />
                <label for={`hBOCdiagnosis_c561`}>
                  C56.1 Malignant neoplasm of right ovary &nbsp;{" "}
                </label>
              </div>
            </div>
          </div>
          <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_4">
            <div class="row clearfix">
              <div class="col-md-6">
                <input
                  type="checkbox"
                  data-val="Z15.04"
                  id={`hBOCdiagnosis_z1504`}
                  name={`hBOCdiagnosis_z1504`}
                  class="filled-in"
                  ref={(ref) => {
                    props.me.hBOCdiagnosis_z1504 = ref;
                  }}
                  onClick={(e) => {
                    props.me.DiagnosisChecked("Z15.04");
                  }}
                />
                <label for={`hBOCdiagnosis_z1504`}>
                  Z15.04 Genetic susceptibility to malignant neoplasm of
                  endometrium &nbsp;{" "}
                </label>
              </div>
              <div class="col-md-6">
                <input
                  type="checkbox"
                  data-val="C56.2"
                  id={`hBOCdiagnosis_c562`}
                  name={`hBOCdiagnosis_c562`}
                  class="filled-in"
                  ref={(ref) => {
                    props.me.hBOCdiagnosis_c562 = ref;
                  }}
                  onClick={(e) => {
                    props.me.DiagnosisChecked("C56.2");
                  }}
                />
                <label for={`hBOCdiagnosis_c562`}>
                  C56.2 Malignant neoplasm of left ovary &nbsp;{" "}
                </label>
              </div>
            </div>
          </div>
          <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_5">
            <div class="row clearfix">
              <div class="col-md-6">
                <input
                  type="checkbox"
                  data-val="Z15.09"
                  id={`hBOCdiagnosis_z1509`}
                  name={`hBOCdiagnosis_z1509`}
                  class="filled-in"
                  ref={(ref) => {
                    props.me.hBOCdiagnosis_z1509 = ref;
                  }}
                  onClick={(e) => {
                    props.me.DiagnosisChecked("Z15.09");
                  }}
                />
                <label for={`hBOCdiagnosis_z1509`}>
                  Z15.09 Genetic susceptibility to other malignant neoplasm
                  &nbsp;{" "}
                </label>
              </div>
              <div class="col-md-6">
                <input
                  type="checkbox"
                  data-val="C56.9"
                  id={`hBOCdiagnosis_c569`}
                  name={`hBOCdiagnosis_c569`}
                  class="filled-in"
                  ref={(ref) => {
                    props.me.hBOCdiagnosis_c569 = ref;
                  }}
                  onClick={(e) => {
                    props.me.DiagnosisChecked("C56.9");
                  }}
                />
                <label for={`hBOCdiagnosis_c569`}>
                  C56.9 Malignant neoplasm of unspecified ovary &nbsp;{" "}
                </label>
              </div>
            </div>
          </div>
          <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_6">
            <div class="row clearfix">
              <div class="col-md-6">
                <input
                  type="checkbox"
                  data-val="Z15.81"
                  id={`hBOCdiagnosis_z1581`}
                  name={`hBOCdiagnosis_z1581`}
                  class="filled-in"
                  ref={(ref) => {
                    props.me.hBOCdiagnosis_z1581 = ref;
                  }}
                  onClick={(e) => {
                    props.me.DiagnosisChecked("Z15.81");
                  }}
                />
                <label for={`hBOCdiagnosis_z1581`}>
                  Z15.81 Genetic susceptibility to multiple endocrine neoplasia
                  [MEN] &nbsp;{" "}
                </label>
              </div>
              <div class="col-md-6">
                <input
                  type="checkbox"
                  data-val="C57.00"
                  id={`hBOCdiagnosis_c5700`}
                  name={`hBOCdiagnosis_c5700`}
                  class="filled-in"
                  ref={(ref) => {
                    props.me.hBOCdiagnosis_c5700 = ref;
                  }}
                  onClick={(e) => {
                    props.me.DiagnosisChecked("C57.00");
                  }}
                />
                <label for={`hBOCdiagnosis_c5700`}>
                  C57.00 Malignant neoplasm of unspecified fallopain tube &nbsp;{" "}
                </label>
              </div>
            </div>
          </div>
          <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_7">
            <div class="row clearfix">
              <div class="col-md-6">
                <input
                  type="checkbox"
                  data-val="C25.0"
                  id={`hBOCdiagnosis_C250`}
                  name={`hBOCdiagnosis_C250`}
                  class="filled-in"
                  ref={(ref) => {
                    props.me.hBOCdiagnosis_C250 = ref;
                  }}
                  onClick={(e) => {
                    props.me.DiagnosisChecked("C25.0");
                  }}
                />
                <label for={`hBOCdiagnosis_C250`}>
                  C25.0 Malignant neoplasm of head of pancreas &nbsp;{" "}
                </label>
              </div>
              <div class="col-md-6">
                <input
                  type="checkbox"
                  data-val="C57.01"
                  id={`hBOCdiagnosis_c5701`}
                  name={`hBOCdiagnosis_c5701`}
                  class="filled-in"
                  ref={(ref) => {
                    props.me.hBOCdiagnosis_c5701 = ref;
                  }}
                  onClick={(e) => {
                    props.me.DiagnosisChecked("C57.01");
                  }}
                />
                <label for={`hBOCdiagnosis_c5701`}>
                  C57.01 Malignant neoplasm of right fallopain tube &nbsp;{" "}
                </label>
              </div>
            </div>
          </div>
          <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_8">
            <div class="row clearfix">
              <div class="col-md-6">
                <input
                  type="checkbox"
                  data-val="C25.1"
                  id={`hBOCdiagnosis_C251`}
                  name={`hBOCdiagnosis_C251`}
                  class="filled-in"
                  ref={(ref) => {
                    props.me.hBOCdiagnosis_C251 = ref;
                  }}
                  onClick={(e) => {
                    props.me.DiagnosisChecked("C25.1");
                  }}
                />
                <label for={`hBOCdiagnosis_C251`}>
                  C25.1 Malignant neoplasm of body of pancreas &nbsp;{" "}
                </label>
              </div>
              <div class="col-md-6">
                <input
                  type="checkbox"
                  data-val="C57.01"
                  id={`hBOCdiagnosis_c5702`}
                  name={`hBOCdiagnosis_c5702`}
                  class="filled-in"
                  ref={(ref) => {
                    props.me.hBOCdiagnosis_c5702 = ref;
                  }}
                  onClick={(e) => {
                    props.me.DiagnosisChecked("C57.01");
                  }}
                />
                <label for={`hBOCdiagnosis_c5702`}>
                  C57.01 Malignant neoplasm of left fallopain tube &nbsp;{" "}
                </label>
              </div>
            </div>
          </div>
          <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_9">
            <div class="row clearfix">
              <div class="col-md-6">
                <input
                  type="checkbox"
                  data-val="C25.2"
                  id={`hBOCdiagnosis_C252`}
                  name={`hBOCdiagnosis_C252`}
                  class="filled-in"
                  ref={(ref) => {
                    props.me.hBOCdiagnosis_C252 = ref;
                  }}
                  onClick={(e) => {
                    props.me.DiagnosisChecked("C25.2");
                  }}
                />
                <label for={`hBOCdiagnosis_C252`}>
                  C25.2 Malignant neoplasm of tail of pancreas &nbsp;{" "}
                </label>
              </div>
              <div class="col-md-6">
                <input
                  type="checkbox"
                  data-val="C61"
                  id={`hBOCdiagnosis_c61`}
                  name={`hBOCdiagnosis_c61`}
                  class="filled-in"
                  ref={(ref) => {
                    props.me.hBOCdiagnosis_c61 = ref;
                  }}
                  onClick={(e) => {
                    props.me.DiagnosisChecked("C61");
                  }}
                />
                <label for={`hBOCdiagnosis_c61`}>
                  C61 Malignant neoplasm of prostate &nbsp;{" "}
                </label>
              </div>
            </div>
          </div>
          <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_10">
            <div class="row clearfix">
              <div class="col-md-6">
                <input
                  type="checkbox"
                  data-val="C25.3"
                  id={`hBOCdiagnosis_C253`}
                  name={`hBOCdiagnosis_C253`}
                  class="filled-in"
                  ref={(ref) => {
                    props.me.hBOCdiagnosis_C253 = ref;
                  }}
                  onClick={(e) => {
                    props.me.DiagnosisChecked("C25.3");
                  }}
                />
                <label for={`hBOCdiagnosis_C253`}>
                  C25.3 Malignant neoplasm of pancreatic duct &nbsp;{" "}
                </label>
              </div>
              <div class="col-md-6">
                <input
                  type="checkbox"
                  data-val="D05.11"
                  id={`hBOCdiagnosis_D0511`}
                  name={`hBOCdiagnosis_D0511`}
                  class="filled-in"
                  ref={(ref) => {
                    props.me.hBOCdiagnosis_D0511 = ref;
                  }}
                  onClick={(e) => {
                    props.me.DiagnosisChecked("D05.11");
                  }}
                />
                <label for={`hBOCdiagnosis_D0511`}>
                  D05.11 Intraductal carcinoma in situ of right breast &nbsp;{" "}
                </label>
              </div>
            </div>
          </div>
          <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_11">
            <div class="row clearfix">
              <div class="col-md-6">
                <input
                  type="checkbox"
                  data-val="C25.4"
                  id={`hBOCdiagnosis_C254`}
                  name={`hBOCdiagnosis_C254`}
                  class="filled-in"
                  ref={(ref) => {
                    props.me.hBOCdiagnosis_C254 = ref;
                  }}
                  onClick={(e) => {
                    props.me.DiagnosisChecked("C25.4");
                  }}
                />
                <label for={`hBOCdiagnosis_C254`}>
                  C25.4 Malignant neoplasm of endocrine pancreas &nbsp;{" "}
                </label>
              </div>
              <div class="col-md-6">
                <input
                  type="checkbox"
                  data-val="D05.12"
                  id={`hBOCdiagnosis_D0512`}
                  name={`hBOCdiagnosis_D0512`}
                  class="filled-in"
                  ref={(ref) => {
                    props.me.hBOCdiagnosis_D0512 = ref;
                  }}
                  onClick={(e) => {
                    props.me.DiagnosisChecked("D05.12");
                  }}
                />
                <label for={`hBOCdiagnosis_D0512`}>
                  D05.12 Intraductal carcinoma in situ of left breast &nbsp;{" "}
                </label>
              </div>
            </div>
          </div>
          <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_12">
            <div class="row clearfix">
              <div class="col-md-6">
                <input
                  type="checkbox"
                  data-val="C25.7"
                  id={`hBOCdiagnosis_C257`}
                  name={`hBOCdiagnosis_C257`}
                  class="filled-in"
                  ref={(ref) => {
                    props.me.hBOCdiagnosis_C257 = ref;
                  }}
                  onClick={(e) => {
                    props.me.DiagnosisChecked("C25.7");
                  }}
                />
                <label for={`hBOCdiagnosis_C257`}>
                  C25.7 Malignant neoplasm of other parts of pancreas &nbsp;{" "}
                </label>
              </div>
              <div class="col-md-6">
                <input
                  type="checkbox"
                  data-val="Z85.07"
                  id={`hBOCdiagnosis_Z8507`}
                  name={`hBOCdiagnosis_Z8507`}
                  class="filled-in"
                  ref={(ref) => {
                    props.me.hBOCdiagnosis_Z8507 = ref;
                  }}
                  onClick={(e) => {
                    props.me.DiagnosisChecked("Z85.07");
                  }}
                />
                <label for={`hBOCdiagnosis_Z8507`}>
                  Z85.07 Personal history of malignant neoplasm of pancreas
                  &nbsp;{" "}
                </label>
              </div>
            </div>
          </div>
          <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_13">
            <div class="row clearfix">
              <div class="col-md-6">
                <input
                  type="checkbox"
                  data-val="C25.8"
                  id={`hBOCdiagnosis_C258`}
                  name={`hBOCdiagnosis_C258`}
                  class="filled-in"
                  ref={(ref) => {
                    props.me.hBOCdiagnosis_C258 = ref;
                  }}
                  onClick={(e) => {
                    props.me.DiagnosisChecked("C25.8");
                  }}
                />
                <label for={`hBOCdiagnosis_C258`}>
                  C25.8 Malignant neoplasm of overlapping sites of pancreas
                  &nbsp;{" "}
                </label>
              </div>
              <div class="col-md-6">
                <input
                  type="checkbox"
                  data-val="Z85.3"
                  id={`hBOCdiagnosis_Z853`}
                  name={`hBOCdiagnosis_Z853`}
                  class="filled-in"
                  ref={(ref) => {
                    props.me.hBOCdiagnosis_Z853 = ref;
                  }}
                  onClick={(e) => {
                    props.me.DiagnosisChecked("Z85.3");
                  }}
                />
                <label for={`hBOCdiagnosis_Z853`}>
                  Z85.3 Personal history of malignant neoplasm of breast &nbsp;{" "}
                </label>
              </div>
            </div>
          </div>
          <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_14">
            <div class="row clearfix">
              <div class="col-md-6">
                <input
                  type="checkbox"
                  data-val="C25.9"
                  id={`hBOCdiagnosis_C259`}
                  name={`hBOCdiagnosis_C259`}
                  class="filled-in"
                  ref={(ref) => {
                    props.me.hBOCdiagnosis_C259 = ref;
                  }}
                  onClick={(e) => {
                    props.me.DiagnosisChecked("C25.9");
                  }}
                />
                <label for={`hBOCdiagnosis_C259`}>
                  C25.9 Malignant neoplasm of pancreas, unspecified &nbsp;{" "}
                </label>
              </div>
              <div class="col-md-6">
                <input
                  type="checkbox"
                  data-val="Z85.43"
                  id={`hBOCdiagnosis_Z8543`}
                  name={`hBOCdiagnosis_Z8543`}
                  class="filled-in"
                  ref={(ref) => {
                    props.me.hBOCdiagnosis_Z8543 = ref;
                  }}
                  onClick={(e) => {
                    props.me.DiagnosisChecked("Z85.43");
                  }}
                />
                <label for={`hBOCdiagnosis_Z8543`}>
                  Z85.43 Personal history of malignant neoplasm of ovary &nbsp;{" "}
                </label>
              </div>
            </div>
          </div>
          <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_15">
            <div class="row clearfix">
              <div class="col-md-6"></div>
              <div class="col-md-6">
                <input
                  type="checkbox"
                  data-val="Z85.46"
                  id={`hBOCdiagnosis_Z8546`}
                  name={`hBOCdiagnosis_Z8546`}
                  class="filled-in"
                  ref={(ref) => {
                    props.me.hBOCdiagnosis_Z8546 = ref;
                  }}
                  onClick={(e) => {
                    props.me.DiagnosisChecked("Z85.46");
                  }}
                />
                <label for={`hBOCdiagnosis_Z8546`}>
                  Z85.46 Personal history of malignant neoplasm of prostate
                  &nbsp;{" "}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row clearfix hidediv" id={`covid_icd_codes`}>
          <div class="col-md-12">
            <div class="form-group required">
              <label class="form-label" for={`DiagnosisCodes`}>
                <b>
                  {" "}
                  COVD Diagnosis Codes | ICD-10 Codes (check all that apply)
                </b>
              </label>
              <div class="demo-checkbox">
                <div class="row clearfix">
                  <div class="col-md-6">
                    <input
                      type="checkbox"
                      data-val="Z11.59"
                      id={`diagnosis_z1159`}
                      name={`diagnosis_z1159`}
                      class="filled-in"
                    />
                    <label for={`diagnosis_z1159`}>
                      Z11.59, Encounter for screening for other viral diseases
                      &nbsp;{" "}
                    </label>
                  </div>
                  <div class="col-md-6">
                    <input
                      type="checkbox"
                      data-val="Z03.818"
                      id={`diagnosis_z03818`}
                      name={`diagnosis_z03818`}
                      class="filled-in"
                    />
                    <label for={`diagnosis_z03818`}>
                      Z03.818, Encounter for observation for suspected exposure
                      to other biological agents ruled out &nbsp;{" "}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row clearfix">
          <div class="col-md-12">
            <div class="form-group ">
              <label class="form-label" for={`address`}>
                Other ICD-10 Codes
              </label>
              <div class="form-line">
                <input
                  type="text"
                   ref={(ref) => (props.me.OtherICD10Codes = ref)}
                  id={`OtherICD10Codes`}
                  class="form-control "
                  placeholder="Other ICD-10 Codes"
                  value={props.state.otherICD10Code}
                  /* onChange={(e)=>{
                    console.log('pre other 10codes----------');
                    console.log(props.me.state.OtherICD10Codes);
                   // var tempOterICD10Codes=props.me.state.OtherICD10Codes;
                 //   tempOterICD10Codes.push(e.target.value);
                    props.me.setState({OtherICD10Code:e.target.value});
                   // console.log('post other 10codes----------');
                    console.log(props.me.state.OtherICD10Codes);
                  }} */
                  onChange={(e)=>{
                    tempOtherCode=e.target.value;
                 //  if(tempOtherCode.includes(',')){
                    // tempOtherCode=props.state.otherICD10Code+tempOtherCode;
                    props.me.setState({otherICD10Code:tempOtherCode});
                  //  tempOtherCode='';
                  //  e.target.value='';
                 //  }
                  
                    
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row clearfix">
          <div class="col-md-12">
            <p>
              (This is provided for informational purposes only and is not a
              guarantee of coverage. It is the provider's responsibility to
              determine the appropriate codes)
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiagnosisCodes;
