import React, { useState, createRef, useContext } from "react";
import { message } from "antd";
import $ from 'jquery';
import helper from './../utils/helperPortal';
import Toast from './../portalComponents/toast/Toast';
import { AppContext } from "../context";
import { createPhysicianApi } from "./../context/actions/physiciansActions";
import { createPatientApi, createOva1OrderApi } from "./../context/actions/patientActions";

import OvaWatchSuitOfTestsForm from "./OvaWatchSuitOfTestsForm";
import PhysicianInformationForm from "./PhysicianInformationForm";
import PatientInformationForm from "./PatientInformationForm";
import ClinicalInformationForm from "./ClinicalInformationForm";
import BillingInformationForm from "./BillingInformationForm";
import DiagnosisCodes_ICD10CodesForm from "./DiagnosisCodes_ICD10CodesForm";
import KitShippingInfoForm from "./KitShippingInfoForm";
import NotesCertifyForm from "./NotesCertifyForm";

const OvaWatchFormContent = (props) => {
  const ovaWatchPrefix = "ovaWatch_";
  const patientPhoneNo_prefix = "ovaWatchPatient_";
  let sigPad = createRef(null);

  const [physicianSignature, setPhysicianSignature] = useState("");

  const [patientDOBLocal, setPatientDOBLocal] = useState("");
  const [billinfoOvaWatch_dobLocal, setBillinfoOvaWatch_dobLocal] = useState("");

  const [physicianState, setPhysicianState] = useState("");
  const [patientState, setPatientState] = useState("");
  const [clinicalInfoLastMenstrualPeriodDate, setClinicalInfoLastMenstrualPeriodDate] = useState("");

  const [selectedPhysicianLegacyInfoState, setSelectedPhysicianLegacyInfoState] = useState(null);

  const physicianAddressContext = useContext(AppContext);
  const { physicianAddresses, selectedPhysicianAddress, physicianPracticeLocation, selectedPhysicianPracticeLocation } = physicianAddressContext;

  let toastProperties = {};
  const [toastList, setToastList] = useState([])
  const [toastMessage, setToastMessage] = useState("");

  const selectedPhysicianLegacyInfoFunc = (selectedPhysician) => {
    setSelectedPhysicianLegacyInfoState(selectedPhysician);
  };

  const onChangePhysicianStateLocal = (_state) => {
    setPhysicianState(_state);
  };

  const patientDOBFunc = (_dob) => {
    setPatientDOBLocal(_dob);
  };

  const onChangeState = (_state) => {
    console.log("ovaWatch->_state: ", _state);
    setPatientState(_state);
  }

  const onChangeLastMentrualPeriodDateLocal = (_date) => {
    setClinicalInfoLastMenstrualPeriodDate(_date);
  }

  const billinfoOvaWatch_dobLocalFunc = (_dob) => {
    setBillinfoOvaWatch_dobLocal(_dob);
  };

  const showToast = (type, msg = "") => {

    const id = Math.floor((Math.random() * 101) + 1);

    switch (type) {
      case 'success':
        message.success(msg);
        toastProperties = {
          id,
          title: 'Success',
          description: 'This is a success toast component',
          backgroundColor: '#5cb85c',
          icon: "/assets/toast/check.svg"
        }
        break;
      case 'danger':
        message.warning(msg);
        toastProperties = {
          id,
          title: 'Danger',
          description: 'This is a error toast component',
          backgroundColor: '#d9534f',
          icon: "/assets/toast/error.svg"
        }
        break;
      case 'info':
        message.info(msg);
        toastProperties = {
          id,
          title: 'Info',
          description: 'This is an info toast component',
          backgroundColor: '#5bc0de',
          icon: "/assets/toast/info.svg"
        }
        break;
      case 'warning':
        message.warning(msg);
        toastProperties = {
          id,
          title: 'Warning',
          description: 'This is a warning toast component',
          backgroundColor: '#f0ad4e',
          icon: "/assets/toast/warning.svg"
        }
        break;

      // default:
      // setToastList([]);
    }

    // setToastList([toastProperties]);
  }

  // jquery codes for form validation and submission
  $(document).ready(() => {
    const ovaWatchBiomarkersFormNextFunc = () => {
      let inputs = $('#ovaWatchTestsForm').find(':input');

      let isGood = false;
      for (let i = 0; i < inputs.length; ++i) {
        let $elem = $(inputs[i]);

        const _type = $elem.attr('type');
        switch (_type) {
          case 'text':
            if ($elem.val().length > 0) {
              isGood = true;
            }
            break;
          case 'number':
            if ($elem.val().length > 0) {
              isGood = true;
            }
            break;
          case 'email':
            if ($elem.val().length > 0) {
              isGood = true;
            }
            break;
          case 'checkbox':
            if ($elem.is(':checked')) {
              isGood = true;
            }
            break;

          case 'radio':
            if ($elem.is(':checked')) {
              isGood = true;
            }
            break;

          default:
            isGood = false;
            break;
        }

        if (isGood) {
          break;
        }
      }

      if (isGood) {
        return true;
      }
      else {
        setToastMessage(`OvaWatch - please select a panel to continue`);
        showToast('danger', `OvaWatch - please select a panel to continue`);
        return false;
      }
    };

    const ovaWatchPhysicianInfoFormNextFunc = () => {
      const phyinfo_Organization_account = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}phyinfo_Organization_account`).val());
      const phyinfo_first_name = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}phyinfo_first_name`).val());
      const phyinfo_last_name = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}phyinfo_last_name`).val());
      const phyinfo_npi = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}phyinfo_npi`).val());
      const phyinfo_email = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}phyinfo_email`).val());
      const phyinfo_address = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}phyinfoC_address`).val());
      const phyinfo_practiceLocation = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}phyinfoC_practiceLocation`).val());
      // const phyinfo_city = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}phyinfoC_city`).val());
      // const phyinfo_state = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}phyinfoC_state`).val());
      // const phyinfo_zipCode = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}phyinfoC_zipCode`).val());
      const ph_areacode = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}phyinfoC_ph_areacode`).val());
      const ph_prefix = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}phyinfoC_ph_prefix`).val());
      const ph_suffix = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}phyinfoC_ph_suffix`).val());
      // const phyinfo_fax = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}phyinfoC_fax`).val());
      // const phyinfo_Faxcopyto = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}phyinfoC_Faxcopyto`).val());
      // const phyinfo_confirmation = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}phyinfoC_confirmation`).val());
      const phyinfo_no_npi = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}phyinfo_no_npi`).val());
      const phyinfo_account_number = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}phyinfo_account_number`).val());
      const phone_ph_areacode = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}phyinfo_phone_ph_areacode`).val());
      const phone_ph_prefix = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}phyinfo_phone_ph_prefix`).val());
      const phone_ph_suffix = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}phyinfo_phone_ph_suffix`).val());

      if ($(`#${ovaWatchPrefix}ordered_before1`).is(":not(:checked)")
        && $(`#${ovaWatchPrefix}ordered_before2`).is(":not(:checked)")) {

        setToastMessage(`Have you ordered from us before is required.`);
        showToast(`danger`, `Have you ordered from us before is required.`);
        return false;
      } else if ($(`#${ovaWatchPrefix}ordered_before1`).is(":checked")) {
        // The user has already used our portal
        let _selectedNPI = "", _selectedClientId = "";
        let selectedPhysicianLegacyInfo = selectedPhysicianLegacyInfoState;

        console.log("selectedPhysicianLegacyInfoState: ", selectedPhysicianLegacyInfoState);
        console.log("selectedPhysicianLegacyInfo: ", selectedPhysicianLegacyInfo);

        if (
          typeof selectedPhysicianLegacyInfo !== "undefined" &&
          selectedPhysicianLegacyInfo
        ) {
          _selectedNPI = selectedPhysicianLegacyInfo.selectedNPI;
          _selectedClientId = selectedPhysicianLegacyInfo.selectedClientId;
        }

        if (phyinfo_account_number.length < 1) {

          setToastMessage(`Physician Account Number is required field.`);
          showToast(`danger`, `Physician Account Number is required field.`);

          $(`#${ovaWatchPrefix}phyinfo_account_number`).focus();

          return false;
        } else {
          if (_selectedNPI.length < 1) {

            setToastMessage(`Physician selection is required.`);
            showToast(`danger`, `Physician selection is required.`);

            return false;
          }
        }
      } else if ($(`#${ovaWatchPrefix}ordered_before2`).is(":checked")) {
        // The user is new to our portal
        if (phyinfo_first_name.length < 1) {

          setToastMessage(`Physician first name is required field.`);
          showToast(`danger`, `Physician first name is required field.`);

          $(`#${ovaWatchPrefix}phyinfo_first_name`).focus();

          return false;
        } else if (phyinfo_last_name.length < 1) {

          setToastMessage(`Physician last name is required field.`);
          showToast(`danger`, `Physician last name is required field.`);

          $(`#${ovaWatchPrefix}phyinfo_last_name`).focus();

          return false;
        } else if (phyinfo_npi.length < 1) {

          setToastMessage(`Physician npi is required field.`);
          showToast(`danger`, `Physician npi is required field.`);

          $(`#${ovaWatchPrefix}phyinfo_npi`).focus();

          return false;
        } else if (phyinfo_email.length < 1) {

          setToastMessage(`Physician email address is required field.`);
          showToast(`danger`, `Physician email address is required field.`);

          $(`#${ovaWatchPrefix}phyinfo_email`).focus();

          return false;
        } else if (!helper.isEmail(phyinfo_email)) {
          setToastMessage(`Please enter a valid email address.`);
          showToast(`danger`, `Please enter a valid email address.`);

          $(`#${ovaWatchPrefix}phyinfo_email`).focus();

          return false;
        } else if (phone_ph_areacode.length < 1) {

          setToastMessage(`Physician phone is required field.`);
          showToast(`danger`, `Physician phone is required field.`);
          $(`#${ovaWatchPrefix}phyinfo_phone_ph_areacode`).focus();

          return false;
        } else if (phone_ph_prefix.length < 1) {

          setToastMessage(`Physician phone is required field.`);
          showToast(`danger`, `Physician phone is required field.`);
          $(`#${ovaWatchPrefix}phyinfo_phone_ph_prefix`).focus();

          return false;
        } else if (phone_ph_suffix.length < 1) {

          setToastMessage(`Physician phone is required field.`);
          showToast(`danger`, `Physician phone is required field.`);
          $(`#${ovaWatchPrefix}phyinfo_phone_ph_suffix`).focus();

          return false;
        } else if (phyinfo_no_npi === '1' && phyinfo_address.length < 1) {

          setToastMessage(`Physician address is required field.`);
          showToast(`danger`, `Physician address is required field.`);
          $(`#${ovaWatchPrefix}phyinfoC_address`).focus();

          return false;
        } else if (phyinfo_no_npi === '1' && phyinfo_practiceLocation.length < 1) {

          setToastMessage(`Physician Practice Location is required field.`);
          showToast(`danger`, `Physician Practice Location is required field.`);
          $(`#${ovaWatchPrefix}phyinfoC_practiceLocation`).focus();

          return false;
        } else if (phyinfo_no_npi === '1' && ph_areacode.length < 1) {

          setToastMessage(`Physician phone is required field.`);
          showToast(`danger`, `Physician phone is required field.`);
          $(`#${ovaWatchPrefix}phyinfoC_ph_areacode`).focus();

          return false;
        } else if (phyinfo_no_npi === '1' && ph_prefix.length < 1) {

          setToastMessage(`Physician phone is required field.`);
          showToast(`danger`, `Physician phone is required field.`);
          $(`#${ovaWatchPrefix}phyinfoC_ph_prefix`).focus();

          return false;
        } else if (phyinfo_no_npi === '1' && ph_suffix.length < 1) {

          setToastMessage(`Physician phone is required field.`);
          showToast(`danger`, `Physician phone is required field.`);
          $(`#${ovaWatchPrefix}phyinfoC_ph_suffix`).focus();

          return false;
        }
      }

      return true;
    };


    const ovaWatchPatientInfoFormNextFunc = () => {

      const patinfo_firstname = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}patinfo_firstname`).val());
      $(`#${ovaWatchPrefix}patinfo_firstname`).val(patinfo_firstname);

      const patinfo_lastname = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}patinfo_lastname`).val());
      $(`#${ovaWatchPrefix}patinfo_lastname`).val();

      const patinfo_address1 = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}patinfo_address1`).val());
      $(`#${ovaWatchPrefix}patinfo_address1`).val();

      const patinfo_city = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}patinfo_city`).val());
      $(`#${ovaWatchPrefix}patinfo_city`).val();

      const patinfo_zipcode = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}patinfo_zipcode`).val());
      $(`#${ovaWatchPrefix}patinfo_zipcode`).val(patinfo_zipcode);

      const ovaWatch_patinfo_ph_areacode = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}patinfo_ph_areacode`).val());
      $(`#${ovaWatchPrefix}patinfo_ph_areacode`).val(ovaWatch_patinfo_ph_areacode);

      const ovaWatch_patinfo_ph_prefix = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}patinfo_ph_prefix`).val());
      $(`#${ovaWatchPrefix}patinfo_ph_prefix`).val(ovaWatch_patinfo_ph_prefix);

      const ovaWatch_patinfo_ph_suffix = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}patinfo_ph_suffix`).val());
      $(`#${ovaWatchPrefix}patinfo_ph_suffix`).val(ovaWatch_patinfo_ph_suffix);

      const patinfo_emailaddress = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}patinfo_emailaddress`).val());
      $(`#${ovaWatchPrefix}patinfo_emailaddress`).val(patinfo_emailaddress);

      const patinfo_OtherEthnicitycity = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}patinfo_OtherEthnicitycity`).val());
      $(`#${ovaWatchPrefix}patinfo_OtherEthnicitycity`).val(patinfo_OtherEthnicitycity);

      const hostname = $(`#${ovaWatchPrefix}hostname`).val();

      let isGood = true;

      if (!helper.isAccessingFromAspiraLab()) { // not lab

        if (patinfo_firstname.length < 1) {
          setToastMessage(`Patient first name is required.`);
          showToast(`danger`, `Patient first name is required.`);
          $(`#${ovaWatchPrefix}patinfo_firstname`).focus();

          isGood = false;

        } else if (patinfo_lastname.length < 1) {
          setToastMessage(`Patient last name is required.`);
          showToast(`danger`, `Patient last name is required.`);
          $(`#${ovaWatchPrefix}patinfo_lastname`).focus();

          isGood = false;

        } else if ($(`#${ovaWatchPrefix}patinfo_gender`).is(":not(:checked)") && $(`#${ovaWatchPrefix}patinfo_gender2`).is(":not(:checked)")) {

          setToastMessage(`Patient gender is required.`);
          showToast(`danger`, `Patient gender is required.`);

          isGood = false;

        } else if (patinfo_address1.length < 1) {
          setToastMessage(`Patient address is required.`);
          showToast(`danger`, `Patient address is required.`);
          $(`#${ovaWatchPrefix}patinfo_address`).focus();

          isGood = false;
        } else if (patinfo_city.length < 1) {
          setToastMessage(`Patient city is required.`);
          showToast(`danger`, `Patient city is required.`);
          $(`#${ovaWatchPrefix}patinfo_city`).focus();

          isGood = false;
        } else if (patinfo_zipcode.length < 1) {
          setToastMessage(`Patient zipcode is required.`);
          showToast(`danger`, `Patient zipcode is required.`);
          $(`#${ovaWatchPrefix}patinfo_zipcode`).focus();

          isGood = false;
        } else if (ovaWatch_patinfo_ph_areacode.length < 1) {
          setToastMessage(`Patient phone is required.`);
          showToast(`danger`, `Patient phone is required.`);
          $(`#${ovaWatchPrefix}patinfo_ph_areacode`).focus();

          isGood = false;
        } else if (ovaWatch_patinfo_ph_prefix.length < 1) {
          setToastMessage(`Patient phone is required.`);
          showToast(`danger`, `Patient phone is required.`);
          $(`#${ovaWatchPrefix}patinfo_ph_prefix`).focus();

          isGood = false;
        } else if (ovaWatch_patinfo_ph_suffix.length < 1) {
          setToastMessage(`Patient phone is required.`);
          showToast(`danger`, `Patient phone is required.`);
          $(`#${ovaWatchPrefix}patinfo_ph_suffix`).focus();

          isGood = false;
        } else if (patinfo_emailaddress.length < 1) {

          setToastMessage(`Patient email is required.`);
          showToast(`danger`, `Patient email is required.`);

          $(`#${ovaWatchPrefix}patinfo_emailaddress`).focus();

          isGood = false;
        }
        else if (!helper.isEmail(patinfo_emailaddress)) {
          setToastMessage(`Please enter a valid email address.`);
          showToast(`danger`, `Please enter a valid email address.`);
          $(`#${ovaWatchPrefix}patinfo_emailaddress`).focus();

          isGood = false;
        }

        else if (!($(`#${ovaWatchPrefix}patinfo_Other`).is(":not(:checked)")) && (patinfo_OtherEthnicitycity.length < 1)) {

          setToastMessage(`Please fill up all required fields.`);
          showToast(`danger`, `Please fill up all required fields.`);
          $(`#${ovaWatchPrefix}patinfo_OtherEthnicitycity`).focus();

          isGood = false;
        } else {

          isGood = true;
        }

      } else {  //yes it is lab.aspirawh.com

        if (patinfo_firstname.length < 1) {

          setToastMessage(`Patient first name is required.`);
          showToast(`danger`, `Patient first name is required.`);
          $(`#${ovaWatchPrefix}patinfo_firstname`).focus();

          isGood = false;

        } else if (patinfo_lastname.length < 1) {

          setToastMessage(`Patient last name is required.`);
          showToast(`danger`, `Patient last name is required.`);
          $(`#${ovaWatchPrefix}patinfo_lastname`).focus();

          isGood = false;

        } else if ($(`#${ovaWatchPrefix}patinfo_gender`).is(":not(:checked)") && $(`#${ovaWatchPrefix}patinfo_gender2`).is(":not(:checked)")) {

          setToastMessage(`Patient gender is required.`);
          showToast(`danger`, `Patient gender is required.`);

          isGood = false;
        } else if ($(`#${ovaWatchPrefix}clinicinfo_Menopausal_status`).is(":not(:checked)") && $(`#${ovaWatchPrefix}clinicinfo_Menopausal_status2`).is(":not(:checked)") && $(`#${ovaWatchPrefix}clinicinfo_Menopausal_status3`).is(":not(:checked)") && $(`#${ovaWatchPrefix}clinicinfo_Menopausal_status4`).is(":not(:checked)")) {

          setToastMessage(`Please select one menopausal status.`);
          showToast(`danger`, `Please select one menopausal status.`);

          isGood = false;

        } else if ($(`#${ovaWatchPrefix}clinicinfo_lowrisk`).is(":not(:checked)") && $(`#${ovaWatchPrefix}clinicinfo_highrisk`).is(":not(:checked)") && $(`#${ovaWatchPrefix}clinicinfo_NotDefinitive`).is(":not(:checked)") && $(`#${ovaWatchPrefix}clinicinfo_NotGiven`).is(":not(:checked)")) {

          setToastMessage(`Please select Ultrasound Results.`);
          showToast(`danger`, `Please select Ultrasound Results.`);

          isGood = false;
        }
        // else if ($(`#${ovaWatchPrefix}clinicinfo_DoLastMentrualPeriod`).val().length <= 0) {
        //   setToastMessage(`Please provide the last menstrual period date.`);
        //   showToast(`danger`);

        //   isGood = false;
        // } 
        else if (!($(`#${ovaWatchPrefix}patinfo_Other`).is(":not(:checked)")) && (patinfo_OtherEthnicitycity.length < 1)) {

          setToastMessage(`Please fill up all required fields.`);
          showToast(`danger`, `Please fill up all required fields.`);
          $(`#${ovaWatchPrefix}patinfo_OtherEthnicitycity`).focus();

          isGood = false;
        } else if (!patientDOBLocal || patientDOBLocal.length < 1) {
          isGood = false;
          message.warning(`Patient DOB is required.`);
        }
        else {

          isGood = true;
        }

      }

      return isGood;
    };

    const ovaWatchClinicalInfoFormNextFunc = () => {

      let isGood = true;
      if ($(`#${ovaWatchPrefix}clinicinfo_Menopausal_status`).is(":not(:checked)") && $(`#${ovaWatchPrefix}clinicinfo_Menopausal_status2`).is(":not(:checked)")) {

        setToastMessage(`Please select one menopausal status.`);
        showToast(`danger`, `Please select one menopausal status.`);

        isGood = false;

      } else if ($(`#${ovaWatchPrefix}clinicinfo_lowrisk`).is(":not(:checked)") && $(`#${ovaWatchPrefix}clinicinfo_highrisk`).is(":not(:checked)") && $(`#${ovaWatchPrefix}clinicinfo_NotDefinitive`).is(":not(:checked)")) {

        setToastMessage(`Please select Ultrasound Results.`);
        showToast(`danger`, `Please select Ultrasound Results.`);

        isGood = false;
      } else if ($(`#${ovaWatchPrefix}clinicinfo_DoLastMentrualPeriod`).val().length <= 0) {
        setToastMessage(`Please provide the last menstrual period date.`);
        showToast(`danger`, `Please provide the last menstrual period date.`);

        isGood = false;
      }
      else {

        isGood = true;
      }

      return isGood;
    };

    const ovaWatchBillingInfoFormNextFunc = () => {

      let isGood = true;

      if (window.location.hostname === 'lab.aspirawh.com'
        || window.location.hostname === 'dev.aspirawh.cloud'
        || window.location.hostname === "order.dev-aspiralab.com"
      ) {
        return isGood;
      }

      const billinfo_PrimaryInsuranceCarrier = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}billinfo_PrimaryInsuranceCarrier`).val());
      $(`#${ovaWatchPrefix}billinfo_PrimaryInsuranceCarrier`).val(billinfo_PrimaryInsuranceCarrier);

      const billinfo_planId = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}billinfo_planId`).val());
      $(`#${ovaWatchPrefix}billinfo_planId`).val(billinfo_planId);

      const billinfo_MemberID = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}billinfo_MemberID`).val());
      $(`#${ovaWatchPrefix}billinfo_MemberID`).val(billinfo_MemberID);

      const billinfo_GroupID = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}billinfo_GroupID`).val());
      $(`#${ovaWatchPrefix}billinfo_GroupID`).val(billinfo_GroupID);

      const billinfo_SecondaryInsuranceCarrier = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}billinfo_SecondaryInsuranceCarrier`).val());
      $(`#${ovaWatchPrefix}billinfo_SecondaryInsuranceCarrier`).val(billinfo_SecondaryInsuranceCarrier);

      const SecondaryplanId = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}SecondaryplanId`).val());
      $(`#${ovaWatchPrefix}SecondaryplanId`).val(SecondaryplanId);

      const billinfo_SecondaryMemberID = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}billinfo_SecondaryMemberID`).val());
      $(`#${ovaWatchPrefix}billinfo_SecondaryMemberID`).val(billinfo_SecondaryMemberID);

      const billinfo_SecondaryGroupID = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}billinfo_SecondaryGroupID`).val());
      $(`#${ovaWatchPrefix}billinfo_SecondaryGroupID`).val(billinfo_SecondaryGroupID);

      const billinfo_TertiaryInsuranceCarrier = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}billinfo_TertiaryInsuranceCarrier`).val());
      $(`#${ovaWatchPrefix}billinfo_TertiaryInsuranceCarrier`).val(billinfo_TertiaryInsuranceCarrier);

      const billinfo_TertiaryMemberID = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}billinfo_TertiaryMemberID`).val());
      $(`#${ovaWatchPrefix}billinfo_TertiaryMemberID`).val(billinfo_TertiaryMemberID);

      const billinfo_TertiaryGroupID = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}billinfo_TertiaryGroupID`).val());
      $(`#${ovaWatchPrefix}billinfo_TertiaryGroupID`).val(billinfo_TertiaryGroupID);

      const billinfo_FirstNameofInsured = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}billinfo_FirstNameofInsured`).val());
      $(`#${ovaWatchPrefix}billinfo_FirstNameofInsured`).val(billinfo_FirstNameofInsured);
      const billinfo_LastNameofInsured = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}billinfo_LastNameofInsured`).val());
      $(`#${ovaWatchPrefix}billinfo_LastNameofInsured`).val(billinfo_LastNameofInsured);

      const billinfo_NameofInsured = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}billinfo_NameofInsured`).val());
      $(`#${ovaWatchPrefix}billinfo_NameofInsured`).val(billinfo_NameofInsured);

      const billinfo_RelationshipInsured = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}billinfo_RelationshipInsured`).val());
      $(`#${ovaWatchPrefix}billinfo_RelationshipInsured`).val(billinfo_RelationshipInsured);

      if ($(`#${ovaWatchPrefix}billinfo_PrivateInsurance`).is(":not(:checked)")
        && $(`#${ovaWatchPrefix}billinfo_Medicare`).is(":not(:checked)")
        && $(`#${ovaWatchPrefix}billinfo_PatientSelfPay`).is(":not(:checked)")
        && $(`#${ovaWatchPrefix}billinfo_Medicaid`).is(":not(:checked)")
        && $(`#${ovaWatchPrefix}billinfo_OrderingFacility`).is(":not(:checked)")) {

        setToastMessage(`Please select Billing Information.`);
        showToast(`danger`, `Please select Billing Information.`);

        isGood = false;

      } else if ($(`#${ovaWatchPrefix}billinfo_PrivateInsurance`).is(":checked") || $(`#${ovaWatchPrefix}billinfo_Medicare`).is(":checked") || $(`#${ovaWatchPrefix}billinfo_Medicaid`).is(":checked")) {

        if (billinfo_PrimaryInsuranceCarrier.length < 1) {

          setToastMessage(`Primary Insurance Carrier is required field.`);
          showToast(`danger`, `Primary Insurance Carrier is required field.`);

          $(`#${ovaWatchPrefix}billinfo_PrimaryInsuranceCarrier`).focus();
          isGood = false;
        } else if (billinfo_MemberID.length < 1) {

          setToastMessage(`Primary Insurance Carrier Member Id is required field.`);
          showToast(`danger`, `Primary Insurance Carrier Member Id is required field.`);

          $(`#${ovaWatchPrefix}billinfo_MemberID`).focus();
          isGood = false;
        } else if (billinfo_GroupID.length < 1) {

          setToastMessage(`Primary Insurance Carrier Group Id is required field.`);
          showToast(`danger`, `Primary Insurance Carrier Group Id is required field.`);

          $(`#${ovaWatchPrefix}billinfo_GroupID`).focus();
          isGood = false;
        } else if (billinfo_FirstNameofInsured.length < 1) {
          setToastMessage("First name of insured is required.");
          showToast("danger", "First name of insured is required.");

          $(`#${ovaWatchPrefix}billinfo_FirstNameofInsured`).focus();

          isGood = false;
        } else if (billinfo_LastNameofInsured.length < 1) {
          setToastMessage(`Last name of insured is required.`);
          showToast(`danger`, `Last name of insured is required.`);

          $(`#${ovaWatchPrefix}billinfo_LastNameofInsured`).focus();

          isGood = false;
        } else if (billinfo_RelationshipInsured.length < 1) {

          setToastMessage(`Relationship to insured is required field.`);
          showToast(`danger`, `Relationship to insured is required field.`);

          $(`#${ovaWatchPrefix}billinfo_RelationshipInsured`).focus();

          isGood = false;
        }
      }

      else {

        isGood = true;
      }

      return isGood;
    };

    const ovaWatchDiagICD10CodesInfoFormNextFunc = () => {
      let isGood = true;

      return isGood;
    };

    const ovaWatchKitShippingInfoFormNextFunc = () => {
      let isGood = true;

      const kitShip4_PatOthersInfo = helper.removeWhiteSpaceTrim($(`#${ovaWatchPrefix}kitShip_PatOthers`).val());
      $(`#${ovaWatchPrefix}kitShip_PatOthers`).val(kitShip4_PatOthersInfo);

      if ($(`${ovaWatchPrefix}kitShip_PatMobPhlebotomy`).is(":not(:checked)")
        && $(`${ovaWatchPrefix}kitShip_PatblooddrawQuest`).is(":not(:checked)")
        && $(`${ovaWatchPrefix}kitShip_PatOthers`).is(":not(:checked)")) {

        setToastMessage(`Please select Kit shipping options.`);
        showToast(`danger`, `Please select Kit shipping options.`);

        isGood = false;

      } else if ($(`${ovaWatchPrefix}kitShip_PatOthers`).is(":checked") && (kitShip4_PatOthersInfo.length < 1)) {

        setToastMessage(`Kit shipping other options is required field.`);
        showToast(`danger`, `Kit shipping other options is required field.`);
        $(`#${ovaWatchPrefix}kitShip_PatOthers`).focus();

        isGood = false;
      }
      else {
        isGood = true;
      }
      return isGood;
    };

    $(`#${ovaWatchPrefix}btnOvaWatchSuitOfTestsFormNext`).off().on('click', function (event) {
      event.preventDefault();

      if (ovaWatchBiomarkersFormNextFunc()) {
        $('#ovaWatchTestsForm').removeClass(' show')
        $('#ovaWatchPhysicianInformationForm').addClass(' show')
        $('#ovaWatchTestsFormCheckMark').removeClass('d-none');

        //go to next form
        document.body.scrollTop = 260;
        document.documentElement.scrollTop = 260;
      }

    });

    $(`#${ovaWatchPrefix}btnPhysicianInformationFormNext`).off().on("click", (e) => {
      e.preventDefault();

      if (ovaWatchPhysicianInfoFormNextFunc()) {
        $('#ovaWatchPhysicianInformationForm').removeClass(' show');
        $('#ovaWatchPatientInformationForm').addClass(' show');
        $('#ovaWatchPhysicianInformationFormCheckMark').removeClass('d-none');

        // scroll to next form
        document.body.scrollTop = 306;
        document.documentElement.scrollTop = 306;
      }
    });

    $(`#${ovaWatchPrefix}btnPatientInformationFormNext`).off().on('click', (event) => {
      event.preventDefault();

      if (ovaWatchPatientInfoFormNextFunc()) {

        $('#ovaWatchPatientInformationForm').removeClass(' show');
        $('#ovaWatchPatientInformationFormCheckMark').removeClass('d-none');

        if (helper.isAccessingFromAspiraLab()) $('#ovaWatchNotesAndCertifyForm').addClass(' show');
        else $('#ovaWatchClinicalInformationForm').addClass(' show');

        //scroll to next form
        document.body.scrollTop = 356;
        document.documentElement.scrollTop = 356;
      }

    });

    $(`#${ovaWatchPrefix}btnClinicalInformationFormNext`).off().on('click', function (event) {
      event.preventDefault();

      if (ovaWatchClinicalInfoFormNextFunc()) {

        $('#ovaWatchBillingInformationForm').addClass(' show')
        $('#ovaWatchClinicalInformationForm').removeClass(' show')
        $('#ovaWatchClinicalInformationFormCheckMark').removeClass('d-none');

        //scroll to next form
        document.body.scrollTop = 406;
        document.documentElement.scrollTop = 406;
      }

    });

    $(`#${ovaWatchPrefix}btnBillingInformationFormNext`).off().on('click', function (event) {
      event.preventDefault();

      if (ovaWatchBillingInfoFormNextFunc()) {

        $('#ovaWatchBillingInformationForm').removeClass(' show')
        $('#ovaWatchDiagnosisCodeSelectionForm').addClass(' show')

        $('#ovaWatchBillingInformationFormCheckMark').removeClass('d-none');

        //scroll to next form
        document.body.scrollTop = 456;
        document.documentElement.scrollTop = 456;
      }

    });

    $(`#${ovaWatchPrefix}btnDiagnosisCodes_ICD10CodesFormNext`).off().on('click', function (event) {
      event.preventDefault();

      if (ovaWatchDiagICD10CodesInfoFormNextFunc()) {

        $('#ovaWatchKitShippingInformationForm').addClass(' show')
        $('#ovaWatchDiagnosisCodeSelectionForm').removeClass(' show')
        $('#ovaWatchDiagnosisCodeSelectionFormCheckMark').removeClass('d-none');

      }

    });

    $(`#${ovaWatchPrefix}btnKitShippingInfoFormNext`).on('click', function (event) {
      event.preventDefault();

      if (ovaWatchKitShippingInfoFormNextFunc()) {

        $('#ovaWatchKitShippingInformationForm').removeClass(' show')
        $('#ovaWatchNotesAndCertifyForm').addClass(' show')
        $('#ovaWatchKitShippingInformationFormCheckMark').removeClass('d-none');

      }

    });

    $(`#${ovaWatchPrefix}btnOrderSubmit`).off('click').on('click', async function () {
      let $this = $(`#${ovaWatchPrefix}btnOrderSubmit`);

      if (helper.isAccessingFromAspiraLab()) {
        if (!ovaWatchBiomarkersFormNextFunc()) {
          $('#ovaWatchTestsForm').addClass(' show');
          $('#ovaWatchNotesAndCertifyForm').removeClass(' show');
          return;
        } else if (!ovaWatchPhysicianInfoFormNextFunc()) {
          $('#ovaWatchPhysicianInformationForm').addClass(' show')
          $('#ovaWatchNotesAndCertifyForm').removeClass(' show');
          return;
        }
        else if (!ovaWatchPatientInfoFormNextFunc()) {
          $('#ovaWatchPatientInformationForm').addClass(' show');
          $('#ovaWatchNotesAndCertifyForm').removeClass(' show');
          return;
        }
      } else {
        if (!ovaWatchBiomarkersFormNextFunc()) {
          $('#ovaWatchTestsForm').addClass(' show');
          $('#ovaWatchNotesAndCertifyForm').removeClass(' show');
          return;
        } else if (!ovaWatchPhysicianInfoFormNextFunc()) {
          $('#ovaWatchPhysicianInformationForm').addClass(' show')
          $('#ovaWatchNotesAndCertifyForm').removeClass(' show');
          return;
        }
        else if (!ovaWatchPatientInfoFormNextFunc()) {
          $('#ovaWatchPatientInformationForm').addClass(' show');
          $('#ovaWatchNotesAndCertifyForm').removeClass(' show');
          return;
        } else if (!ovaWatchClinicalInfoFormNextFunc()) {
          $('#ovaWatchClinicalInformationForm').addClass(' show');
          $('#ovaWatchNotesAndCertifyForm').removeClass(' show');
          return;
        } else if (!ovaWatchBillingInfoFormNextFunc()) {
          $('#ovaWatchBillingInformationForm').addClass(' show')
          $('#ovaWatchNotesAndCertifyForm').removeClass(' show')
          return;
        } else if (!ovaWatchDiagICD10CodesInfoFormNextFunc()) {
          $('#ovaWatchDiagnosisCodeSelectionForm').addClass(' show')
          $('#ovaWatchNotesAndCertifyForm').removeClass(' show')
          return;
        } else if (!ovaWatchKitShippingInfoFormNextFunc()) {
          $('#ovaWatchKitShippingInformationForm').addClass(' show')
          $('#ovaWatchNotesAndCertifyForm').removeClass(' show')
          return;
        }
      }


      $this.html($this.data('loading-text'));
      $(`#${ovaWatchPrefix}btnOrderSubmit`).prop("disabled", true);

      let phyinfo_ordered_before = '';
      if ($(`#${ovaWatchPrefix}ordered_before1`).is(":checked")) {
        phyinfo_ordered_before = $(`#${ovaWatchPrefix}ordered_before1`).data('val');
      }
      if ($(`#${ovaWatchPrefix}ordered_before2`).is(":checked")) {
        phyinfo_ordered_before = $(`#${ovaWatchPrefix}ordered_before2`).data('val');
      }

      let physician = {};
      let legacyCustomerQbenchId = {};
      let selectedPhysicianLegacyInfo = selectedPhysicianLegacyInfoState;
      if ($(`#${ovaWatchPrefix}ordered_before1`).is(":checked")) {

        let physicianRet = await helper.createPhysicianFromClientId(selectedPhysicianLegacyInfo);

        physician = physicianRet.physician;
        legacyCustomerQbenchId = physicianRet.legacyCustomerQbenchId;
      }
      else {
        const phyinfo_Organization_account = $('#ovaWatch_phyinfo_Organization_account').val();
        const phyinfo_first_name = $('#ovaWatch_phyinfo_first_name').val();
        const phyinfo_last_name = $('#ovaWatch_phyinfo_last_name').val();
        const phyinfo_npi = $('#ovaWatch_phyinfo_npi').val();
        const phyinfo_email = $('#ovaWatch_phyinfo_email').val();

        const phyinfo_address = $('#ovaWatch_phyinfoC_address').val();
        const phyinfo_city = $('#ovaWatch_phyinfoC_city').val();
        const phyinfo_state = physicianState;
        const phyinfo_zipCode = $('#ovaWatch_phyinfoC_zipCode').val();
        const phyinfo_phone = $('#ovaWatch_phyinfoC_ph_areacode').val() + ' ' + $('#ovaWatch_phyinfoC_ph_prefix').val() + $('#ovaWatch_phyinfoC_ph_suffix').val()
        const phyinfo_fax = $('#ovaWatch_phyinfoC_fax').val();
        const phyinfo_Faxcopyto = $('#ovaWatch_phyinfoC_Faxcopyto').val();
        const phyinfo_confirmation = $('#ovaWatch_phyinfoC_confirmation').val();

        const phyinfoC_practiceLocation = $('#ovaWatch_phyinfoC_practiceLocation').val();


        const addresses = [];
        const practiceLocation = [];

        const licenses = [];

        console.log("physicianAddresses: ", physicianAddresses);

        let data = {
          organizationAccount: phyinfo_Organization_account,
          firstName: phyinfo_first_name,
          lastName: phyinfo_last_name,
          npiNumber: phyinfo_npi,
          email: phyinfo_email,
          addresses: [],
          address_type: "Primary",
          practiceLocation: {
          },
          licenses: licenses,
          registeredUser: "",
        }
        if (physicianAddresses && physicianAddresses.length < 1) {

          addresses.push({
            locationName: "USA",
            address_1: phyinfo_address,
            address_2: "",
            addressType: "Primary",
            city: phyinfo_city,
            country: "USA",
            state: phyinfo_state,
            zipCode: phyinfo_zipCode,
            phoneNo: phyinfo_phone,
            fax: phyinfo_fax,
            fax_copyto: phyinfo_Faxcopyto,

          });

          data.addresses = addresses;
          data.practiceLocationPlain = phyinfoC_practiceLocation;
        }
        else {

          physicianAddresses.map((a) => {
            addresses.push({
              locationName: "USA",
              address_1: a.address_1,
              address_2: a.address_1,
              addressType: a.address_type,
              city: a.city,
              country: a.country_name,
              state: a.state,
              zipCode: a.postal_code,
              phoneNo: a.telephone_number,
            });
          });

          data.addresses = addresses;
          data.practiceLocationPlain = phyinfoC_practiceLocation;
        }

        console.log("data: ", data);


        let physicianRet = await createPhysicianApi(data);
        console.log("physicianRet: ", physicianRet);
        physician = physicianRet.physician;
        legacyCustomerQbenchId = physicianRet.legacyCustomerQbenchId;
      }

      console.log("\n===");
      console.log("physician: ", physician);
      console.log("===\n");

      if (physician.isok) {
        $this.html('Done');
        $(`#${ovaWatchPrefix}btnOrderSubmit`).prop("disabled", true);
        setToastMessage(`Physician Created Successfully. Creating Patient`);
        showToast(`success`, `Physician Created Successfully. Creating Patient`);

        const patinfo_firstname = $('#ovaWatch_patinfo_firstname').val();
        const patinfo_lastname = $('#ovaWatch_patinfo_lastname').val();
        const patinfo_address1 = $('#ovaWatch_patinfo_address1').val();
        const patinfo_address2 = $('#ovaWatch_patinfo_address2').val();
        const patinfo_city = $('#ovaWatch_patinfo_city').val();
        const patinfo_state = patientState;
        const patinfo_zipcode = $('#ovaWatch_patinfo_zipcode').val();
        const patinfo_PhoneNumber = $("#ovaWatch_patinfo_ph_areacode").val() + $("#ovaWatch_patinfo_ph_prefix").val() + $("#ovaWatch_patinfo_ph_suffix").val()
        const patinfo_emailaddress = $('#ovaWatch_patinfo_emailaddress').val();
        const patinfo_DOB = patientDOBLocal;

        const ethnicity = [];

        if ($("#ovaWatch_patinfo_Caucasian").is(":checked")) {
          ethnicity.push($("#ovaWatch_patinfo_Caucasian").data('val'));
        }
        if ($("#ovaWatch_patinfo_AshkenaziJewish").is(":checked")) {
          ethnicity.push($("#ovaWatch_patinfo_AshkenaziJewish").data('val'));
        }
        if ($("#ovaWatch_patinfo_SephardicJewish").is(":checked")) {
          ethnicity.push($("#ovaWatch_patinfo_SephardicJewish").data('val'));
        }
        if ($("#ovaWatch_patinfo_Asian").is(":checked")) {
          ethnicity.push($("#ovaWatch_patinfo_Asian").data('val'));
        }
        if ($("#ovaWatch_patinfo_HIspanic").is(":checked")) {
          ethnicity.push($("#ovaWatch_patinfo_HIspanic").data('val'));
        }
        if ($("#ovaWatch_patinfo_NativeAmerican").is(":checked")) {
          ethnicity.push($("#ovaWatch_patinfo_NativeAmerican").data('val'));
        }
        if ($("#ovaWatch_patinfo_AfricanAmerican").is(":checked")) {
          ethnicity.push($("#ovaWatch_patinfo_AfricanAmerican").data('val'));
        }
        if ($("#ovaWatch_patinfo_Other").is(":checked")) {
          ethnicity.push($("#ovaWatch_patinfo_Other").data('val'));
          ethnicity.push($("#ovaWatch_patinfo_OtherEthnicitycity").val());
        }


        let gender = "";

        if ($(`#${ovaWatchPrefix}patinfo_gender`).is(":checked")) {
          gender = 'male';
        } else {
          gender = 'female';
        }

        let data = {
          email: patinfo_emailaddress,
          firstName: patinfo_firstname,
          lastName: patinfo_lastname,
          address: {
            locationName: "USA",
            address_1: patinfo_address1,
            address_2: patinfo_address2,
            addressType: "",
            city: patinfo_city,
            country: "USA",
            state: patientState,
            zipCode: patinfo_zipcode,
          },
          dob: patinfo_DOB,
          phoneNo: patinfo_PhoneNumber,

          ethnicity: ethnicity.join(","),
          registeredUser: "",
          gender: gender,
        }

        console.log("Before Submit data: ", data);

        $this.html("<img style='height: 30px;' src='/assets/images/Spinner-1s-200px.svg' /> Creating Patient")
        $(`#${ovaWatchPrefix}btnOrderSubmit`).prop("disabled", true);


        let patient = await createPatientApi(data);

        console.log("\n===");
        console.log("patient: ", patient);
        console.log("===\n");

        if (patient.isok) {
          let billing_info = {
            primaryInsurance: {},
            secondaryInsurance: {},
            tertiaryInsurance: {},
            insuredInfo: {
              nameOfInsured: {
                firstName: "",
                lastName: "",
              },
              dob: "",
              relationshipInsured: "",
            },
          };

          let sections;

          if (helper.isAccessingFromAspiraLab()) {
            billing_info = {
              freeTrial: false,
              medicaid: false,
              medicare: false,
              orderingFacility: false,
              patientSelfPay: false,
              privateInsurance: false,
              insuredInfo: {
                dob: "",
                nameOfInsured: { firstName: "", lastName: "" },
                relationshipInsured: "",
              },
              primaryInsurance: {
                groupID: "",
                memberID: "",
                primaryInsuranceCarrier: ""
              },
              secondaryInsurance: {
                secondaryGroupID: "",
                secondaryInsuranceCarrier: "",
                secondaryMemberID: ""
              },
              tertiaryInsurance: {
                tertiaryGroupID: "",
                tertiaryInsuranceCarrier: "",
                tertiaryMemberID: ""
              }
            }
          } else {
            sections = $("div#ovaWatchBillingInformationForm #billing_info_container").find(":input");
            sections.map((input, i) => {
              const $input = $(sections[input]);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));
              console.log("$input: ", $input);

              let _id = $input.attr('id');

              if (typeof _id == 'undefined' || _id.length < 1) {
                _id = `${ovaWatchPrefix}billinfo_DOB`;
              }
              const _type = $input.attr('type');
              let obj_id = _id.replace(`${ovaWatchPrefix}billinfo_`, "");
              obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)
              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  billing_info[obj_id] = true;
                }
                else {
                  billing_info[obj_id] = false;
                }
              }
              else {
                billing_info[obj_id] = $input.val();
              }
            });

            sections = $("div#ovaWatchBillingInformationForm #primary_insurance_container").find(":input");
            sections.map((input, i) => {
              const $input = $(sections[input]);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));
              console.log("$input: ", $input);

              let _id = $input.attr('id');

              if (typeof _id == 'undefined' || _id.length < 1) {
                _id = `${ovaWatchPrefix}billinfo_DOB`;
              }
              const _type = $input.attr('type');
              let obj_id = _id.replace(`${ovaWatchPrefix}billinfo_`, "");
              obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)
              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  billing_info['primaryInsurance'][obj_id] = true;
                }
                else {
                  billing_info['primaryInsurance'][obj_id] = false;
                }
              }
              else {
                billing_info['primaryInsurance'][obj_id] = $input.val();
              }

            });

            sections = $("div#ovaWatchBillingInformationForm #secondary_insurance_container").find(":input");
            sections.map((input, i) => {
              const $input = $(sections[input]);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));
              console.log("$input: ", $input);

              let _id = $input.attr('id');

              if (typeof _id == 'undefined' || _id.length < 1) {
                _id = `${ovaWatchPrefix}billinfo_DOB`;
              }
              const _type = $input.attr('type');
              let obj_id = _id.replace(`${ovaWatchPrefix}billinfo_`, "");
              obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)
              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  billing_info['secondaryInsurance'][obj_id] = true;
                }
                else {
                  billing_info['secondaryInsurance'][obj_id] = false;
                }
              }
              else {
                billing_info['secondaryInsurance'][obj_id] = $input.val();
              }

            });

            sections = $("div#ovaWatchBillingInformationForm #tertiary_insurance_container").find(":input");
            sections.map((input, i) => {
              const $input = $(sections[input]);
              let _id = $input.attr('id');

              if (typeof _id == 'undefined' || _id.length < 1) {
                _id = "billinfoC_dob";
              }
              const _type = $input.attr('type');
              let obj_id = _id.replace(`${ovaWatchPrefix}billinfo_`, "");
              obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)
              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  billing_info['tertiaryInsurance'][obj_id] = true;
                }
                else {
                  billing_info['tertiaryInsurance'][obj_id] = false;
                }
              }
              else {
                billing_info['tertiaryInsurance'][obj_id] = $input.val();
              }
            });

            billing_info["insuredInfo"]["nameOfInsured"]["firstName"] =
              $(`#${ovaWatchPrefix}billinfo_FirstNameofInsured`).val();
            billing_info["insuredInfo"]["nameOfInsured"]["lastName"] =
              $(`#${ovaWatchPrefix}billinfo_LastNameofInsured`).val();
            billing_info['insuredInfo']["dob"] = billinfoOvaWatch_dobLocal;
            billing_info['insuredInfo']["relationshipInsured"] = $(`#${ovaWatchPrefix}billinfo_RelationshipInsured`).val();
          }

          console.log("\n===");
          console.log("billing_info: ", billing_info);
          console.log("===\n");

          // clinical data
          let qnA = {
            questionCode: "",
            answer: "",
            subQuestion: []
          };

          let clinicalData = [];
          sections = $(`${helper.isAccessingFromAspiraLab() ? "#ovaWatchPatientInformationForm" : "#ovaWatchClinicalInformationForm"} #${ovaWatchPrefix}ova1_q01`).find(":input");
          sections.map((input, i) => {
            const $input = $(sections[input]);
            console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));

            let obj_id = $input.attr('id');

            const _type = $input.attr('type');
            obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)

            if (_type == 'radio') {
              if ($input.is(':checked')) {
                qnA = {
                  'questionCode': "ovaWatch_q01",
                  'answer': $input.val(),
                }
              }
            }
          });
          clinicalData.push(qnA);

          const ovaWatch_q02 = [];
          sections = $(`${helper.isAccessingFromAspiraLab() ? "#ovaWatchPatientInformationForm" : "#ovaWatchClinicalInformationForm"} #${ovaWatchPrefix}ova1_q02`).find(":input");
          sections.map((input, i) => {
            const $input = $(sections[input]);
            console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));

            let obj_id = $input.attr('id');

            const _type = $input.attr('type');
            obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)

            if (_type == 'checkbox') {
              if ($input.is(':checked')) {
                ovaWatch_q02.push($input.data('val'));
              }
            }

          });

          qnA = {
            'questionCode': "ovaWatch_q02",
            'answer': ovaWatch_q02.join(', '),
          }
          clinicalData.push(qnA);

          qnA = {
            'questionCode': "ovaWatch_q03",
            'answer': clinicalInfoLastMenstrualPeriodDate,
          }
          clinicalData.push(qnA);

          if (!helper.isAccessingFromAspiraLab()) {
            qnA = {
              'questionCode': "ovaWatch_q04",
              'answer': $(`#ovaWatchClinicalInformationForm #${ovaWatchPrefix}clinicinfo_MassSize_longdime`).val(),
              subQuestion: [
                {
                  'questionCode': "ovaWatch_q04_1",
                  'answer': $(`#ovaWatchClinicalInformationForm #${ovaWatchPrefix}clinicinfo_MassSize_Height`).val() + " " + $(`#ovaWatchClinicalInformationForm #${ovaWatchPrefix}clinicinfo_MassSize_Height_unit`).val(),
                },
                {
                  'questionCode': "ovaWatch_q04_2",
                  'answer': $(`#ovaWatchClinicalInformationForm #${ovaWatchPrefix}clinicinfo_MassSize_Weight`).val() + " " + $(`#ovaWatchClinicalInformationForm #${ovaWatchPrefix}clinicinfo_MassSize_Weight_unit`).val(),
                },
              ]
            };
            clinicalData.push(qnA);

            if (!$(`#${ovaWatchPrefix}clinicinfo_pain_abdomen_pelvis`).is(":checked")) {
              qnA = {
                'questionCode': "ovaWatch_q05",
                'answer': false,
              };
            }
            else {
              let radioValue1 = $(`input[name='${ovaWatchPrefix}clinicinfo_painAbdomenPelvis_Day_']:checked`).val();
              let radioValue2 = $(`input[name='${ovaWatchPrefix}clinicinfo_painAbdomenPelvis_long_']:checked`).val();
              console.log("radioValue1: ", radioValue1);
              console.log("radioValue2: ", radioValue2);

              qnA = {
                'questionCode': "ovaWatch_q05",
                'answer': true,
                subQuestion: [
                  {
                    'questionCode': "ovaWatch_q05_1",
                    'answer': radioValue1,
                  },
                  {
                    'questionCode': "ovaWatch_q05_2",
                    'answer': radioValue2,
                  },
                ]
              };
            }
            clinicalData.push(qnA);

            if (!$(`#${ovaWatchPrefix}clinicinfo_fullQuickly_unableToe`).is(":checked")) {
              qnA = {
                'questionCode': "ovaWatch_q06",
                'answer': false,
              };
            }
            else {
              let radioValue1 = $(`input[name='${ovaWatchPrefix}clinicinfo_fullQuicklyUnableToe_Day_']:checked`).val();
              let radioValue2 = $(`input[name='${ovaWatchPrefix}clinicinfo_fullQuicklyUnableToe_long_']:checked`).val();
              qnA = {
                'questionCode': "ovaWatch_q06",
                'answer': true,
                subQuestion: [
                  {
                    'questionCode': "ovaWatch_q06_1",
                    'answer': radioValue1,
                  },
                  {
                    'questionCode': "ovaWatch_q06_2",
                    'answer': radioValue2,
                  },
                ]
              };
            }
            clinicalData.push(qnA);

            if (!$(`#${ovaWatchPrefix}clinicinfo_Expabdominalbloat_IncrSize`).is(":checked")) {
              qnA = {
                'questionCode': "ovaWatch_q07",
                'answer': false,
              };
            }
            else {
              let radioValue1 = $(`input[name='${ovaWatchPrefix}clinicinfo_ExpabdominalbloatIncrSize_Day_']:checked`).val();
              let radioValue2 = $(`input[name='${ovaWatchPrefix}clinicinfo_ExpabdominalbloatIncrSize_long_']:checked`).val();
              qnA = {
                'questionCode': "ovaWatch_q07",
                'answer': true,
                subQuestion: [
                  {
                    'questionCode': "ovaWatch_q07_1",
                    'answer': radioValue1,
                  },
                  {
                    'questionCode': "ovaWatch_q07_2",
                    'answer': radioValue2,
                  },
                ]
              };
            }
            clinicalData.push(qnA);
          }


          console.log("\n===");
          console.log("clinicalData: ", clinicalData);
          console.log("===\n");

          const icd10Codes = [];
          const icd10CodesOther = [];
          let icd10Code = {
            'codes': [],
            'otherCodes': [],
          };

          if (!helper.isAccessingFromAspiraLab()) {
            sections = $(`#ovaWatchDiagnosisCodeSelectionForm #ova1_icd_codes`).find(":input");
            sections.map((input, i) => {
              const $input = $(sections[input]);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));

              const _type = $input.attr('type');

              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  icd10Codes.push($input.data('val'));
                }
              }
              else if (_type == 'text') {
                icd10CodesOther.push($("#ovaWatch_OtherICD10Codes").val());
              }

            });

            icd10CodesOther.push($("#ovaWatch_OtherICD10Codes").val());

            icd10Code = {
              'codes': icd10Codes,
              'otherCodes': icd10CodesOther,
            };
          }


          let ova1PlusBiomarkers = {
            GCP: [],
            MCP: [],
            other: {},
          };

          const ovaWatchAssayInputs = $(`#${ovaWatchPrefix}_ovaWatchTestsForm`).find(`:input`);
          ovaWatchAssayInputs.map((i, elem) => {
            console.log("OvaWatch->elem: ", elem);

            if ($(elem).is(":checked")) {
              let $ovaWatchCheckbox = $(elem);
              ova1PlusBiomarkers.other = {
                qBenchId: $ovaWatchCheckbox.data('qbenchid'),
                code: $ovaWatchCheckbox.data('val'),
              };
            }
          });

          console.log("ova1PlusBiomarkers.other: ", ova1PlusBiomarkers.other);


          let kitInformation = {
            drawType: [],
            otherInfo: "",
          };

          if (!helper.isAccessingFromAspiraLab()) {
            sections = $(`#${ovaWatchPrefix}kitShippingInfoFormContainer`).find(":input");
            sections.map((i, input) => {
              const $input = $(input);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));

              let obj_id = $input.attr('id');

              const _type = $input.attr('type');

              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  kitInformation.drawType.push($input.data('val'));
                }
              }
            });

            kitInformation.otherInfo = $(`#${ovaWatchPrefix}kitShip_PatOthersInfo`).val();
          }


          let notes = $(`#ovaWatchNotesAndCertifyForm #${ovaWatchPrefix}kitShip_notes`).val();
          var tests = [
            {
              testName: "OvaWatch",
              testCode: "OvaWatch",
              icd10Code: icd10Code,
              kitInformation: kitInformation,
              notes: notes,
              ova1PlusBiomarkers: ova1PlusBiomarkers,
              clinicalData: clinicalData
            }
          ];

          //NotesCertifyForm


          let certifyTypes = [];

          sections = $(`#ovaWatchNotesAndCertifyForm #${ovaWatchPrefix}certifytype`).find(':input');
          sections.map((i, input) => {
            const $input = $(input);
            console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));

            let obj_id = $input.attr('id');

            const _type = $input.attr('type');

            if (_type == 'radio') {
              if ($input.is(':checked')) {

                certifyTypes.push($input.data('val'));

              }
            }
          });

          const covid_checked = $("#product_4").is(":checked");
          if (covid_checked) {

            const icd10CodesCOVD = [];
            let icd10CodeCOVD = {
              'codes': [],
              'otherCodes': "",
            };

            sections = $("#ovaWatchDiagnosisCodeSelectionForm #covid_icd_codes").find(":input");
            sections.map((i, input) => {
              const $input = $(input);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));

              // let obj_id = $input.attr('id');

              const _type = $input.attr('type');
              // obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)

              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  icd10CodesCOVD.push($input.data('val'));
                }
              }

            });

            icd10CodeCOVD = {
              'codes': icd10CodesCOVD,
              'otherCodes': "",
            }

            var covid_tests = {
              testName: "COVD",
              testCode: "COVD",
              icd10Code: icd10CodeCOVD,
            };

            tests.push(covid_tests);
          }

          console.log("sigPad: ", sigPad);
          let dataString = "";

          if (sigPad) {
            if (typeof sigPad.current !== 'undefined' && sigPad.current == null) {

            }
            else {
              dataString = sigPad.getTrimmedCanvas().toDataURL('image/png');
            }
          }

          console.log("physician before submitting to place order: ", physician);
          if (typeof patient.patient !== "undefined" && patient.patient) {
            patient = patient.patient;
          }
          if (typeof physician.physician !== "undefined" && physician.physician) {
            physician = physician.physician;
          }

          // get the user response related to user's consent about the use of his/her data for research purpose
          const researchConsent = $(`#${ovaWatchPrefix}researchConsent`).is(":checked") ? "Yes" : "No";
          const researchUseOnly = $(`#${ovaWatchPrefix}researchUseOnly`).is(":checked") ? "Yes" : "No";

          // check if the order is a test order
          const isTestOrder = helper.isAccessingFromAspiraLab() ? $(`#${ovaWatchPrefix}testOrder`).is(":checked") : false;

          data = {
            orderId: (Math.floor(100000 + Math.random() * 900000)).toString(),
            patientId: patient._id.toString(),
            physicianId: physician._id.toString(),
            billingInfo: billing_info,
            tests: tests,
            certifyTypes,
            physicianSignature: dataString || physicianSignature,
            qbenchCustomerId: legacyCustomerQbenchId,
            legacyClientId: selectedPhysicianLegacyInfo ? selectedPhysicianLegacyInfo.selectedClientId : "",
            researchConsent,
            researchUseOnly,
            isTestOrder
          };

          console.log("\n===");
          console.log("data for order API call: ", data);
          console.log("===\n");

          $this.html("<img style='height: 30px;' src='/assets/images/Spinner-1s-200px.svg' /> Creating Order");
          $(`#${ovaWatchPrefix}btnOrderSubmit`).prop("disabled", true);

          let response = await createOva1OrderApi(data);
          if (response.isok) {
            $this.html('Done');
            setToastMessage(`Order Created Successfully.`);
            showToast(`success`, `Order Created Successfully.`);
            $(`#${ovaWatchPrefix}btnOrderSubmit`).prop("disabled", false);

            // if (process.env.REACT_APP_ENV == "DEV") {
            //   console.log("intentionally not redirecting to pdf page");
            // } else {
            setTimeout(() => {
              window.location.href = response.redirectUrl;
            }, 500);
            // }
          }
        }
      }
    });
  });

  // helper methods for rendering
  const renderToastMessageComponent = () => {
    return (
      <Toast
        toastList={toastList}
        position={"top-center"}
        autoDelete={true}
        dismissTime={3000}
        description={toastMessage}

      />
    );
  };

  const renderOvaWatchSuitOfTestsForm = () => {
    return (
      <div class="panel panel-col-blue-grey">
        <div class="panel-heading" role="tab" id="ovaWatch_01">
          <h4 className="panel-title">
            <a
              className="collapsed"
              role="button"
              data-toggle="collapse"
              data-parent="#accordion_11"
              href="#ovaWatchTestsForm"
              aria-expanded="false"
              aria-controls="collapseTwo_10"
            >
              OvaWatch <span id="ovaWatchTestsFormCheckMark" className="d-none">&#10004;</span>
            </a>
          </h4>
        </div>

        <div
          id="ovaWatchTestsForm"
          className="print-wrap panel-collapse collapse"
          role="tabpanel"
          aria-labelledby="ovaWatch_01"
        >
          <OvaWatchSuitOfTestsForm prefix={ovaWatchPrefix} />
        </div>
      </div>
    )
  };

  const renderPhysicianInformationForm = () => {
    return (
      <div class="panel panel-col-blue-grey">
        <div class="panel-heading" role="tab" id="ovaWatch_02">
          <h4 class="panel-title">
            <a
              id="physicianInfoToggle"
              class="accordion-toggle"
              role="button"
              data-toggle="collapse"
              data-parent="#accordion_11"
              href="#ovaWatchPhysicianInformationForm"
              aria-expanded="true"
              aria-controls="collapseOne_10"
            >
              Physician Information <span id="ovaWatchPhysicianInformationFormCheckMark" className="d-none">&#10004;</span>
            </a>
          </h4>
        </div>

        <div
          id="ovaWatchPhysicianInformationForm"
          class="print-wrap panel-collapse collapse in"
          role="tabpanel"
          aria-labelledby="ovaWatch_02"
        >
          <PhysicianInformationForm
            prefix={ovaWatchPrefix}
            onChangePhysicianState={onChangePhysicianStateLocal}
            selectedPhysicianLegacyInfo={selectedPhysicianLegacyInfoFunc}
          />
        </div>
      </div>
    );
  };

  const renderPatientInformationForm = () => {
    return (
      <div class="panel panel-col-blue-grey">
        <div class="panel-heading" role="tab" id="ovaWatch_03">
          <h4 class="panel-title">
            <a
              id="physicianInfoToggle"
              class="accordion-toggle"
              role="button"
              data-toggle="collapse"
              data-parent="#accordion_11"
              href="#ovaWatchPatientInformationForm"
              aria-expanded="true"
              aria-controls="collapseOne_10"
            >
              Patient Information <span id="ovaWatchPatientInformationFormCheckMark" className="d-none">&#10004;</span>
            </a>
          </h4>
        </div>

        <div
          id="ovaWatchPatientInformationForm"
          class="print-wrap panel-collapse collapse in"
          role="tabpanel"
          aria-labelledby="ovaWatch_03"
        >
          <PatientInformationForm
            prefix={ovaWatchPrefix}
            phoneNoPrefix={patientPhoneNo_prefix}
            dobFunc={patientDOBFunc}
            onChangeState={onChangeState}
            onChangeLastMentrualPeriodDate={onChangeLastMentrualPeriodDateLocal}
          />
        </div>
      </div>
    );
  };

  const renderClinicalInformationForm = () => {
    return (
      <div class="panel panel-col-blue-grey">
        <div class="panel-heading" role="tab" id="ovaWatch_04">
          <h4 class="panel-title">
            <a
              id="physicianInfoToggle"
              class="accordion-toggle"
              role="button"
              data-toggle="collapse"
              data-parent="#accordion_11"
              href="#ovaWatchClinicalInformationForm"
              aria-expanded="true"
              aria-controls="collapseOne_10"
            >
              Clinical Information <span id="ovaWatchClinicalInformationFormCheckMark" className="d-none">&#10004;</span>
            </a>
          </h4>
        </div>

        <div
          id="ovaWatchClinicalInformationForm"
          class="print-wrap panel-collapse collapse in"
          role="tabpanel"
          aria-labelledby="ovaWatch_04"
        >
          <ClinicalInformationForm
            prefix={ovaWatchPrefix}
            onChangeLastMentrualPeriodDate={onChangeLastMentrualPeriodDateLocal}
          />
        </div>
      </div>
    );
  };

  const renderBillingInformationForm = () => {
    return (
      <div class="panel panel-col-blue-grey">
        <div class="panel-heading" role="tab" id="ovaWatch_05">
          <h4 class="panel-title">
            <a
              id="physicianInfoToggle"
              class="accordion-toggle"
              role="button"
              data-toggle="collapse"
              data-parent="#accordion_11"
              href="#ovaWatchBillingInformationForm"
              aria-expanded="true"
              aria-controls="collapseOne_10"
            >
              Billing Information <span id="ovaWatchBillingInformationFormCheckMark" className="d-none">&#10004;</span>
            </a>
          </h4>
        </div>

        <div
          id="ovaWatchBillingInformationForm"
          class="print-wrap panel-collapse collapse in"
          role="tabpanel"
          aria-labelledby="ovaWatch_05"
        >
          <BillingInformationForm
            prefix={ovaWatchPrefix}
            billinfo_dobFunc={billinfoOvaWatch_dobLocalFunc}
          />
        </div>
      </div>
    );
  };

  const renderDiagnosisCodeSelectionForm = () => {
    return (
      <div class="panel panel-col-blue-grey">
        <div class="panel-heading" role="tab" id="ovaWatch_06">
          <h4 class="panel-title">
            <a
              id="physicianInfoToggle"
              class="accordion-toggle"
              role="button"
              data-toggle="collapse"
              data-parent="#accordion_11"
              href="#ovaWatchDiagnosisCodeSelectionForm"
              aria-expanded="true"
              aria-controls="collapseOne_10"
            >
              Diagnosis Codes | ICD-10 Codes <span id="ovaWatchDiagnosisCodeSelectionFormCheckMark" className="d-none">&#10004;</span>
            </a>
          </h4>
        </div>

        <div
          id="ovaWatchDiagnosisCodeSelectionForm"
          class="print-wrap panel-collapse collapse in"
          role="tabpanel"
          aria-labelledby="ovaWatch_06"
        >
          <DiagnosisCodes_ICD10CodesForm
            prefix={ovaWatchPrefix}
          />
        </div>
      </div>
    );
  };

  const renderKitShippingInformationForm = () => {
    return (
      <div class="panel panel-col-blue-grey">
        <div class="panel-heading" role="tab" id="ovaWatch_07">
          <h4 class="panel-title">
            <a
              id="physicianInfoToggle"
              class="accordion-toggle"
              role="button"
              data-toggle="collapse"
              data-parent="#accordion_11"
              href="#ovaWatchKitShippingInformationForm"
              aria-expanded="true"
              aria-controls="collapseOne_10"
            >
              Kit Shipping Information <span id="ovaWatchKitShippingInformationFormCheckMark" className="d-none">&#10004;</span>
            </a>
          </h4>
        </div>

        <div
          id="ovaWatchKitShippingInformationForm"
          class="print-wrap panel-collapse collapse in"
          role="tabpanel"
          aria-labelledby="ovaWatch_07"
        >
          <KitShippingInfoForm
            prefix={ovaWatchPrefix}
          />
        </div>
      </div>
    );
  };

  const renderNotesAndCertifyForm = () => {
    return (
      <div class="panel panel-col-blue-grey">
        <div class="panel-heading" role="tab" id="ovaWatch_08">
          <h4 class="panel-title">
            <a
              id="physicianInfoToggle"
              class="accordion-toggle"
              role="button"
              data-toggle="collapse"
              data-parent="#accordion_11"
              href="#ovaWatchNotesAndCertifyForm"
              aria-expanded="true"
              aria-controls="collapseOne_10"
            >
              Notes & certify <span id="ovaWatchNotesAndCertifyFormCheckMark" className="d-none">&#10004;</span>
            </a>
          </h4>
        </div>

        <div
          id="ovaWatchNotesAndCertifyForm"
          class="print-wrap panel-collapse collapse in"
          role="tabpanel"
          aria-labelledby="ovaWatch_08"
        >
          <NotesCertifyForm
            prefix={ovaWatchPrefix}
            sigCollect={(_sigPad) => {
              sigPad = _sigPad;
            }}
            captureSignature={(signatureString) => {
              setPhysicianSignature(signatureString);
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div id="ovaWatchFormContentContainer" className="body">
      <div className="row clearfix">
        <div className="col-md-12 col-lg-12">
          <div className="panel-group" id="accordion-11" role="tablist" aria-multiselectable="true">
            {renderToastMessageComponent()}
            {renderOvaWatchSuitOfTestsForm()}
            {renderPhysicianInformationForm()}
            {renderPatientInformationForm()}
            {!helper.isAccessingFromAspiraLab() && <>
              {renderClinicalInformationForm()}
              {renderBillingInformationForm()}
              {renderDiagnosisCodeSelectionForm()}
              {renderKitShippingInformationForm()}
            </>}
            {renderNotesAndCertifyForm()}
          </div>
        </div>
      </div>
    </div>
  )
};

export default OvaWatchFormContent;