import React, { PureComponent, useState, useContext } from "react";

import PropTypes from 'prop-types';
import $ from 'jquery';

//includes all forms here
import ASPGENHereditaryBreastOvarianCancerForm2 from "./ASPGENHereditaryBreastOvarianCancerForm2";
import PhysicianInformationForm from "./PhysicianInformationForm";
import PhysicianInformationHBOCForm2 from "./PhysicianInformationHBOCForm2";
import PatientInformationHBOCForm2 from "./PatientInformationHBOCForm2";
import PatientInformationForm from "./PatientInformationForm";
import BillingInformationHBOCForm2 from "./BillingInformationHBOCForm2";
import BillingInformationForm from "./BillingInformationForm";
import DiagnosisCodes_ICD10CodesForm2 from "./DiagnosisCodes_ICD10CodesForm2";
//import HBOCClicnicalInfoForm2 from "./HBOCClicnicalInfoForm2";
import HBOCFamilyHistoryInfoForm2 from "./HBOCFamilyHistoryInfoForm2";
import ClinicalInformationOVAForm from "./ClinicalInformationOVAForm";
import ClinicalInformationForm from "./ClinicalInformationForm";
import KitShippingInfoHBOCForm2 from "./KitShippingInfoHBOCForm2";
import KitShippingInfoOVAForm from "./KitShippingInfoOVAForm";
import NotesCertifyHBOC_OVAForm from "./NotesCertifyHBOC_OVAForm";

import GenetiXHBOCForm from "./GenetiXHBOCForm";
import Toast from '../portalComponents/toast/Toast';
import helper from './../utils/helperPortal';

import { AppContext } from "./../context";
import { createPhysicianApi } from "./../context/actions/physiciansActions";
import { createPatientApi, createPatients, createOva1OrderApi } from "./../context/actions/patientActions";

const Ova1GenetixHBOCForm = () => {
  let selectedPhysicianLegacyInfo = null;

  const ova1GenetixHBOCPrefix = `ova1GenetixHBOC_`;
  const ova1GenetixHBOCPhoneNo_prefix = `ova1GenetixHBOC_phoneNo_`;

  const physicianAddressContext = useContext(AppContext);
  const { physicianAddresses, selectedPhysicianAddress, physicianPracticeLocation, selectedPhysicianPracticeLocation } = physicianAddressContext;

  let toastProperties = {};
  const [toastList, setToastList] = useState([]);
  const [toastMessage, setToastMessage] = useState("");


  const [physicianState, setPhysicianState] = useState("");
  const [patientState, setPatientState] = useState("");
  const [patientDOBLocal, setPatientDOBLocal] = useState("");

  const [clinicalInfoLastMenstrualPeriodDate, setClinicalInfoLastMenstrualPeriodDate] = useState("");
  const showToast = (type) => {

    const id = Math.floor((Math.random() * 101) + 1);

    switch (type) {
      case 'success':
        toastProperties = {
          id,
          title: 'Success',
          description: 'This is a success toast component',
          backgroundColor: '#5cb85c',
          icon: "/assets/toast/check.svg"
        }
        break;
      case 'danger':
        toastProperties = {
          id,
          title: 'Danger',
          description: 'This is a error toast component',
          backgroundColor: '#d9534f',
          icon: "/assets/toast/error.svg"
        }
        break;
      case 'info':
        toastProperties = {
          id,
          title: 'Info',
          description: 'This is an info toast component',
          backgroundColor: '#5bc0de',
          icon: "/assets/toast/info.svg"
        }
        break;
      case 'warning':
        toastProperties = {
          id,
          title: 'Warning',
          description: 'This is a warning toast component',
          backgroundColor: '#f0ad4e',
          icon: "/assets/toast/warning.svg"
        }
        break;

      default:
        setToastList([]);
    }

    setToastList([toastProperties]);
  }
  const loadInfo = () => {

  };
  React.useEffect(() => {
    loadInfo();
  });

  $(document).ready(() => {
    $("input.form-control").keyup(function (event) {
      console.log("event.keyCode: ", event.keyCode);
      if (event.keyCode == 13) {
        let textboxes = $("input.form-control");
        let currentBoxNumber = textboxes.index(this);
        if (textboxes[currentBoxNumber + 1] != null) {
          let nextBox = textboxes[currentBoxNumber + 1];
          nextBox.focus();
          nextBox.select();
        }
        event.preventDefault();
        return false;
      }

      return true;
    });

    //ASPGENHereditaryBreastOvarianCancerForm2
    const btnASPGENHereditaryBreastOvarianCancerForm2NextFunc = () => {

      let inputs = $('#ASPGENHereditaryBreastOvarianCancerForm2').find(':input');

      let isGood = false;
      for (let i = 0; i < inputs.length; ++i) {
        let $elem = $(inputs[i]);

        const _type = $elem.attr('type');
        switch (_type) {
          case 'text':
            if ($elem.val().length > 0) {
              isGood = true;
            }
            break;
          case 'number':
            if ($elem.val().length > 0) {
              isGood = true;
            }
            break;
          case 'email':
            if ($elem.val().length > 0) {
              isGood = true;
            }
            break;
          case 'checkbox':
            if ($elem.is(':checked')) {
              isGood = true;
            }
            break;

          case 'radio':
            if ($elem.is(':checked')) {
              isGood = true;
            }
            break;

          default:
            isGood = false;
            break;
        }

        if (isGood) {
          break;
        }
      }

      if (isGood) {
        return true;
      }
      else {
        setToastMessage(`ASPIRA GENETIX Hereditary. 
        Breast and Ovarian Cancer panel is required`);
        showToast('danger');
        return false;
      }
    }

    $('#btnASPGENHereditaryBreastOvarianCancerForm2Next').off('click').on('click', function (event) {
      event.preventDefault();

      if (btnASPGENHereditaryBreastOvarianCancerForm2NextFunc()) {

        $('div#ASPGENHereditaryBreastOvarianCancerForm2').removeClass(' show');
        $('div#PhysicianInformationHBOCForm2').addClass(' show');

        $('#ASPGENHereditaryBreastOvarianCancerForm2CheckMark').removeClass('d-none');
      }

    });

    //PhysicianInformationHBOCForm2
    const btnPhysicianInformationHBOCForm2NextFunc = () => {

      const phyinfo_Organization_account = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}phyinfo_Organization_account`).val());
      $(`#${ova1GenetixHBOCPrefix}phyinfo_Organization_account`).val(phyinfo_Organization_account);

      const phyinfo_first_name = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}phyinfo_first_name`).val());
      $(`#${ova1GenetixHBOCPrefix}phyinfo_first_name`).val(phyinfo_first_name);

      const phyinfo_last_name = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}phyinfo_last_name`).val());
      $(`#${ova1GenetixHBOCPrefix}phyinfo_last_name`).val(phyinfo_last_name);

      const phyinfo_npi = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}phyinfo_npi`).val());
      $(`#${ova1GenetixHBOCPrefix}phyinfo_npi`).val(phyinfo_npi);

      const phyinfo_email = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}phyinfo_email`).val());
      $(`#${ova1GenetixHBOCPrefix}phyinfo_email`).val(phyinfo_email);

      const phyinfo_address = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}phyinfoC_address`).val());
      $(`#${ova1GenetixHBOCPrefix}phyinfoC_address`).val(phyinfo_address);

      const phyinfo_practiceLocation = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}phyinfoC_practiceLocation`).val());
      $(`#${ova1GenetixHBOCPrefix}phyinfoC_practiceLocation`).val(phyinfo_practiceLocation);

      const phyinfo_city = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}phyinfoC_city`).val());
      $(`#${ova1GenetixHBOCPrefix}phyinfoC_city`).val(phyinfo_city);

      const phyinfo_state = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}phyinfoC_state`).val());
      $(`#${ova1GenetixHBOCPrefix}phyinfoC_state`).val(phyinfo_state);

      const phyinfo_zipCode = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}phyinfoC_zipCode`).val());
      $(`#${ova1GenetixHBOCPrefix}phyinfoC_zipCode`).val(phyinfo_zipCode);

      const ph_areacode = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}phyinfoC_ph_areacode`).val());
      $(`#${ova1GenetixHBOCPrefix}phyinfoC_ph_areacode`).val(ph_areacode);

      const ph_prefix = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}phyinfoC_ph_prefix`).val());
      $(`#${ova1GenetixHBOCPrefix}phyinfoC_ph_prefix`).val(ph_prefix);

      const ph_suffix = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}phyinfoC_ph_suffix`).val());
      $(`#${ova1GenetixHBOCPrefix}phyinfoC_ph_suffix`).val(ph_suffix);

      const phyinfo_fax = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}phyinfoC_fax`).val());
      $(`#${ova1GenetixHBOCPrefix}phyinfoC_fax`).val(phyinfo_fax);

      const phyinfo_Faxcopyto = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}phyinfoC_Faxcopyto`).val());
      $(`#${ova1GenetixHBOCPrefix}phyinfoC_Faxcopyto`).val(phyinfo_Faxcopyto);

      const phyinfo_confirmation = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}phyinfoC_confirmation`).val());
      $(`#${ova1GenetixHBOCPrefix}phyinfoC_confirmation`).val(phyinfo_confirmation);

      const phyinfo_no_npi = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}phyinfo_no_npi`).val());
      $(`#${ova1GenetixHBOCPrefix}phyinfo_no_npi`).val(phyinfo_no_npi);

      //ova1_phyinfo_phone_ph_suffix
      const phyinfo_account_number = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}phyinfo_account_number`).val());
      $(`#${ova1GenetixHBOCPrefix}phyinfo_account_number`).val(phyinfo_account_number);

      const phone_ph_areacode = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}phyinfo_phone_ph_areacode`).val());
      $(`#${ova1GenetixHBOCPrefix}phyinfo_phone_ph_areacode`).val(phone_ph_areacode);

      const phone_ph_prefix = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}phyinfo_phone_ph_prefix`).val());
      $(`#${ova1GenetixHBOCPrefix}phyinfo_phone_ph_prefix`).val(phone_ph_prefix);

      const phone_ph_suffix = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}phyinfo_phone_ph_suffix`).val());
      $(`#${ova1GenetixHBOCPrefix}phyinfo_phone_ph_suffix`).val(phone_ph_suffix);


      let isGood = true;

      if ($(`#${ova1GenetixHBOCPrefix}ordered_before1`).is(":not(:checked)")
        && $(`#${ova1GenetixHBOCPrefix}ordered_before2`).is(":not(:checked)")) {

        setToastMessage(`Have you ordered from us before is required.`);
        showToast(`danger`);
        isGood = false;
      }
      else if ($(`#${ova1GenetixHBOCPrefix}ordered_before1`).is(":checked")) { //already Order Placed

        let _selectedNPI = "";

        selectedPhysicianLegacyInfo = localStorage.getItem("selectedPhysicianLegacyInfo");
        if (typeof selectedPhysicianLegacyInfo !== 'undefined' && selectedPhysicianLegacyInfo) {
          selectedPhysicianLegacyInfo = JSON.parse(selectedPhysicianLegacyInfo);

          _selectedNPI = selectedPhysicianLegacyInfo.selectedNPI
        }
        if (phyinfo_account_number.length < 1) {

          setToastMessage(`Physician Account Number is required field.`);
          showToast(`danger`);

          $(`#${ova1GenetixHBOCPrefix}phyinfo_account_number`).focus();

          isGood = false;
        }
        else {
          if (_selectedNPI.length < 1) {

            setToastMessage(`Physician selection is required.`);
            showToast(`danger`);

            // $(`#${gTcs_prefix}phyinfo_account_number`).focus();

            isGood = false;
          }
        }

      }
      else if ($(`#${ova1GenetixHBOCPrefix}ordered_before2`).is(":checked")) { //Not Order Placed yet

        if (phyinfo_first_name.length < 1) {

          setToastMessage(`Physician first name is required field.`);
          showToast(`danger`);

          $(`#${ova1GenetixHBOCPrefix}phyinfo_first_name`).focus();

          isGood = false;
        }
        else if (phyinfo_last_name.length < 1) {

          setToastMessage(`Physician last name is required field.`);
          showToast(`danger`);

          $(`#${ova1GenetixHBOCPrefix}phyinfo_last_name`).focus();

          isGood = false;
        }
        else if (phyinfo_npi.length < 1) {

          setToastMessage(`Physician npi is required field.`);
          showToast(`danger`);

          $(`#${ova1GenetixHBOCPrefix}phyinfo_npi`).focus();

          isGood = false;
        }
        else if (phyinfo_email.length < 1) {

          setToastMessage(`Physician email address is required field.`);
          showToast(`danger`);

          $(`#${ova1GenetixHBOCPrefix}phyinfo_email`).focus();

          isGood = false;
        }
        else if (!helper.isEmail(phyinfo_email)) {
          setToastMessage(`Please enter a valid email address.`);
          showToast(`danger`);

          $(`#${ova1GenetixHBOCPrefix}phyinfo_email`).focus();

          isGood = false;

        } else if (phone_ph_areacode.length < 1) {

          setToastMessage(`Physician phone is required field.`);
          showToast(`danger`);
          $(`#${ova1GenetixHBOCPrefix}phyinfo_phone_ph_areacode`).focus();

          isGood = false;
        } else if (phone_ph_prefix.length < 1) {

          setToastMessage(`Physician phone is required field.`);
          showToast(`danger`);
          $(`#${ova1GenetixHBOCPrefix}phyinfo_phone_ph_prefix`).focus();

          isGood = false;
        } else if (phone_ph_suffix.length < 1) {

          setToastMessage(`Physician phone is required field.`);
          showToast(`danger`);
          $(`#${ova1GenetixHBOCPrefix}phyinfo_phone_ph_suffix`).focus();

          isGood = false;

        } else if (phyinfo_no_npi === '1' && phyinfo_address.length < 1) {

          setToastMessage(`Physician address is required field.`);
          showToast(`danger`);
          $(`#${ova1GenetixHBOCPrefix}phyinfoC_address`).focus();

          isGood = false;
        } else if (phyinfo_no_npi === '1' && phyinfo_practiceLocation.length < 1) {

          setToastMessage(`Physician Practice Location is required field.`);
          showToast(`danger`);
          $(`#${ova1GenetixHBOCPrefix}phyinfoC_practiceLocation`).focus();

          isGood = false;
        } else if (phyinfo_no_npi === '1' && ph_areacode.length < 1) {

          setToastMessage(`Physician phone is required field.`);
          showToast(`danger`);
          $(`#${ova1GenetixHBOCPrefix}phyinfoC_ph_areacode`).focus();

          isGood = false;
        } else if (phyinfo_no_npi === '1' && ph_prefix.length < 1) {

          setToastMessage(`Physician phone is required field.`);
          showToast(`danger`);
          $(`#${ova1GenetixHBOCPrefix}phyinfoC_ph_prefix`).focus();

          isGood = false;
        } else if (phyinfo_no_npi === '1' && ph_suffix.length < 1) {

          setToastMessage(`Physician phone is required field.`);
          showToast(`danger`);
          $(`#${ova1GenetixHBOCPrefix}phyinfoC_ph_suffix`).focus();

          isGood = false;
        }

      }

      return isGood;

    }

    $(`#${ova1GenetixHBOCPrefix}btnPhysicianInformationFormNext`).off('click').on('click', function (event) {
      event.preventDefault();

      if (btnPhysicianInformationHBOCForm2NextFunc()) {

        $('div#PhysicianInformationHBOCForm2').removeClass(' show');
        $('div#PatientInformationHBOCForm2').addClass(' show');
        $('#PhysicianInformationHBOCForm2CheckMark').removeClass('d-none');

      }

    });


    //PatientInformationForm
    const ova1GenetixHBOCPatientInfoFormNextFunc = () => {

      const patinfo_firstname = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}patinfo_firstname`).val());
      $(`#${ova1GenetixHBOCPrefix}patinfo_firstname`).val(patinfo_firstname);

      const patinfo_lastname = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}patinfo_lastname`).val());
      $(`#${ova1GenetixHBOCPrefix}patinfo_lastname`).val();

      const patinfo_address = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}patinfo_address`).val());
      $(`#${ova1GenetixHBOCPrefix}patinfo_address`).val();

      const patinfo_city = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}patinfo_city`).val());
      $(`#${ova1GenetixHBOCPrefix}patinfo_city`).val();

      const patinfo_zipcode = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}patinfo_zipcode`).val());
      $(`#${ova1GenetixHBOCPrefix}patinfo_zipcode`).val(patinfo_zipcode);

      const ova1_patinfo_ph_areacode = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}patinfo_ph_areacode`).val());
      $(`#${ova1GenetixHBOCPrefix}patinfo_ph_areacode`).val(ova1_patinfo_ph_areacode);

      const ova1_patinfo_ph_prefix = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}patinfo_ph_prefix`).val());
      $(`#${ova1GenetixHBOCPrefix}patinfo_ph_prefix`).val(ova1_patinfo_ph_prefix);

      const ova1_patinfo_ph_suffix = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}patinfo_ph_suffix`).val());
      $(`#${ova1GenetixHBOCPrefix}patinfo_ph_suffix`).val(ova1_patinfo_ph_suffix);

      const patinfo_emailaddress = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}patinfo_emailaddress`).val());
      $(`#${ova1GenetixHBOCPrefix}patinfo_emailaddress`).val(patinfo_emailaddress);

      const patinfo_OtherEthnicitycity = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}patinfo_OtherEthnicitycity`).val());
      $(`#${ova1GenetixHBOCPrefix}patinfo_OtherEthnicitycity`).val(patinfo_OtherEthnicitycity);

      let isGood = true;
      if (patinfo_firstname.length < 1) {
        setToastMessage(`Patient first name is required.`);
        showToast(`danger`);
        $(`#${ova1GenetixHBOCPrefix}patinfo_firstname`).focus();

        isGood = false;

      } else if (patinfo_lastname.length < 1) {
        setToastMessage(`Patient last name is required.`);
        showToast(`danger`);
        $(`#${ova1GenetixHBOCPrefix}patinfo_lastname`).focus();

        isGood = false;

      } else if ($(`#${ova1GenetixHBOCPrefix}patinfo_gender`).is(":not(:checked)") && $(`#${ova1GenetixHBOCPrefix}patinfo_gender2`).is(":not(:checked)")) {

        setToastMessage(`Patient gender is required.`);
        showToast(`danger`);

        isGood = false;

      } else if (patinfo_address.length < 1) {
        setToastMessage(`Patient address is required.`);
        showToast(`danger`);
        $(`#${ova1GenetixHBOCPrefix}patinfo_address`).focus();

        isGood = false;
      } else if (patinfo_city.length < 1) {
        setToastMessage(`Patient city is required.`);
        showToast(`danger`);
        $(`#${ova1GenetixHBOCPrefix}patinfo_city`).focus();

        isGood = false;
      } else if (patinfo_zipcode.length < 1) {
        setToastMessage(`Patient zipcode is required.`);
        showToast(`danger`);
        $(`#${ova1GenetixHBOCPrefix}patinfo_zipcode`).focus();

        isGood = false;
      } else if (ova1_patinfo_ph_areacode.length < 1) {
        setToastMessage(`Patient phone is required.`);
        showToast(`danger`);
        $(`#${ova1GenetixHBOCPrefix}patinfo_ph_areacode`).focus();

        isGood = false;
      } else if (ova1_patinfo_ph_prefix.length < 1) {
        setToastMessage(`Patient phone is required.`);
        showToast(`danger`);
        $(`#${ova1GenetixHBOCPrefix}patinfo_ph_prefix`).focus();

        isGood = false;
      } else if (ova1_patinfo_ph_suffix.length < 1) {
        setToastMessage(`Patient phone is required.`);
        showToast(`danger`);
        $(`#${ova1GenetixHBOCPrefix}patinfo_ph_suffix`).focus();

        isGood = false;
      } else if (patinfo_emailaddress.length < 1) {

        setToastMessage(`Patient email is required.`);
        showToast(`danger`);

        $(`#${ova1GenetixHBOCPrefix}patinfo_emailaddress`).focus();

        isGood = false;
      }
      else if (!helper.isEmail(patinfo_emailaddress)) {
        setToastMessage(`Please enter a valid email address.`);
        showToast(`danger`);
        $(`#${ova1GenetixHBOCPrefix}patinfo_emailaddress`).focus();

        isGood = false;
      } else if ($(`#${ova1GenetixHBOCPrefix}patinfo_Caucasian`).is(":not(:checked)")
        && $(`#${ova1GenetixHBOCPrefix}patinfo_AshkenaziJewish`).is(":not(:checked)")
        && $(`#${ova1GenetixHBOCPrefix}patinfo_SephardicJewish`).is(":not(:checked)")
        && $(`#${ova1GenetixHBOCPrefix}patinfo_Asian`).is(":not(:checked)")
        && $(`#${ova1GenetixHBOCPrefix}patinfo_HIspanic`).is(":not(:checked)")
        && $(`#${ova1GenetixHBOCPrefix}patinfo_NativeAmerican`).is(":not(:checked)")
        && $(`#${ova1GenetixHBOCPrefix}patinfo_AfricanAmerican`).is(":not(:checked)")
        && $(`#${ova1GenetixHBOCPrefix}patinfo_Other`).is(":not(:checked)")) {

        setToastMessage(`Please select patient ethnicity.`);
        showToast(`danger`);

        isGood = false;
      } else if (!($(`#${ova1GenetixHBOCPrefix}patinfo_Other`).is(":not(:checked)")) && (patinfo_OtherEthnicitycity.length < 1)) {

        setToastMessage(`Please fill up all required fields.`);
        showToast(`danger`);
        $(`#${ova1GenetixHBOCPrefix}patinfo_OtherEthnicitycity`).focus();

        isGood = false;
      } else if (!patientDOBLocal || patientDOBLocal.length < 1) {
        isGood = false;
        
      }

      return isGood;
    }

    $(`#${ova1GenetixHBOCPrefix}btnPatientInformationFormNext`).off('click').on('click', (event) => {

      event.preventDefault();

      if (ova1GenetixHBOCPatientInfoFormNextFunc()) {

        $('div#PatientInformationHBOCForm2').removeClass(' show');
        $('div#BillingInformationHBOCForm2').addClass(' show');
        $('#PatientInformationHBOCForm2CheckMark').removeClass('d-none');

      }
    });

    //BillingInformationHBOCForm2
    const ova1GenetixHBOCBillingInfoFormNextFunc = () => {

      let isGood = true;
      const billinfo_PrimaryInsuranceCarrier = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}billinfo_PrimaryInsuranceCarrier`).val());
      $(`#${ova1GenetixHBOCPrefix}billinfo_PrimaryInsuranceCarrier`).val(billinfo_PrimaryInsuranceCarrier);

      const billinfo_planId = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}billinfo_planId`).val());
      $(`#${ova1GenetixHBOCPrefix}billinfo_planId`).val(billinfo_planId);

      const billinfo_MemberID = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}billinfo_MemberID`).val());
      $(`#${ova1GenetixHBOCPrefix}billinfo_MemberID`).val(billinfo_MemberID);

      const billinfo_GroupID = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}billinfo_GroupID`).val());
      $(`#${ova1GenetixHBOCPrefix}billinfo_GroupID`).val(billinfo_GroupID);

      const billinfo_SecondaryInsuranceCarrier = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}billinfo_SecondaryInsuranceCarrier`).val());
      $(`#${ova1GenetixHBOCPrefix}billinfo_SecondaryInsuranceCarrier`).val(billinfo_SecondaryInsuranceCarrier);

      const SecondaryplanId = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}SecondaryplanId`).val());
      $(`#${ova1GenetixHBOCPrefix}SecondaryplanId`).val(SecondaryplanId);

      const billinfo_SecondaryMemberID = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}billinfo_SecondaryMemberID`).val());
      $(`#${ova1GenetixHBOCPrefix}billinfo_SecondaryMemberID`).val(billinfo_SecondaryMemberID);

      const billinfo_SecondaryGroupID = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}billinfo_SecondaryGroupID`).val());
      $(`#${ova1GenetixHBOCPrefix}billinfo_SecondaryGroupID`).val(billinfo_SecondaryGroupID);

      const billinfo_NameofInsured = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}billinfo_NameofInsured`).val());
      $(`#${ova1GenetixHBOCPrefix}billinfo_NameofInsured`).val(billinfo_NameofInsured);

      const billinfo_RelationshipInsured = helper.removeWhiteSpaceTrim($(`#${ova1GenetixHBOCPrefix}billinfo_RelationshipInsured`).val());
      $(`#${ova1GenetixHBOCPrefix}billinfo_RelationshipInsured`).val(billinfo_RelationshipInsured);

      if ($(`#${ova1GenetixHBOCPrefix}billinfo_PrivateInsurance`).is(":not(:checked)")
        && $(`#${ova1GenetixHBOCPrefix}billinfo_Medicare`).is(":not(:checked)")
        && $(`#${ova1GenetixHBOCPrefix}billinfo_PatientSelfPay`).is(":not(:checked)")
        && $(`#${ova1GenetixHBOCPrefix}billinfo_Medicaid`).is(":not(:checked)")
        && $(`#${ova1GenetixHBOCPrefix}billinfo_OrderingFacility`).is(":not(:checked)")) {

        setToastMessage(`Please select Billing Information.`);
        showToast(`danger`);

        isGood = false;

      } else if ($(`#${ova1GenetixHBOCPrefix}billinfo_PrivateInsurance`).is(":checked") || $(`#${ova1GenetixHBOCPrefix}billinfo_Medicare`).is(":checked") || $(`#${ova1GenetixHBOCPrefix}billinfo_Medicaid`).is(":checked")) {

        if (billinfo_PrimaryInsuranceCarrier.length < 1) {

          setToastMessage(`Primary Insurance Carrier is required field.`);
          showToast(`danger`);

          $(`#${ova1GenetixHBOCPrefix}billinfo_PrimaryInsuranceCarrier`).focus();
          isGood = false;
        } else if (billinfo_planId.length < 1) {

          setToastMessage(`Primary Insurance Carrier Plan Id is required field.`);
          showToast(`danger`);

          $(`#${ova1GenetixHBOCPrefix}billinfo_planId`).focus();
          isGood = false;
        } else if (billinfo_MemberID.length < 1) {

          setToastMessage(`Primary Insurance Carrier Member Id is required field.`);
          showToast(`danger`);

          $(`#${ova1GenetixHBOCPrefix}billinfo_MemberID`).focus();
          isGood = false;
        } else if (billinfo_GroupID.length < 1) {

          setToastMessage(`Primary Insurance Carrier Group Id is required field.`);
          showToast(`danger`);

          $(`#${ova1GenetixHBOCPrefix}billinfo_GroupID`).focus();
          isGood = false;
        }
        else if (billinfo_NameofInsured.length < 1) {
          setToastMessage(`Name of Insured is required field.`);
          showToast(`danger`);

          $(`#${ova1GenetixHBOCPrefix}billinfo_NameofInsured`).focus();

          isGood = false;
        }
        else if (billinfo_RelationshipInsured.length < 1) {

          setToastMessage(`Relationship to insured is required field.`);
          showToast(`danger`);

          $(`#${ova1GenetixHBOCPrefix}billinfo_RelationshipInsured`).focus();

          isGood = false;
        }
      }
      else {
        isGood = true;
      }

      return isGood;
    }

    $(`#${ova1GenetixHBOCPrefix}btnBillingInformationFormNext`).off('click').on('click', function (event) {
      event.preventDefault();

      if (ova1GenetixHBOCBillingInfoFormNextFunc()) {

        $('div#BillingInformationHBOCForm2').removeClass(' show');
        $('div#DiagnosisCodes_ICD10CodesForm2').addClass(' show');
        $('#BillingInformationHBOCForm2CheckMark').removeClass('d-none');

      }

    });

    //DiagnosisCodes_ICD10CodesForm2
    const btnDiagnosisCodes_ICD10CodesForm2NextFunc = () => {

      let inputs = $('#DiagnosisCodes_ICD10CodesForm2').find(':input');

      let isGood = false;
      for (let i = 0; i < inputs.length; ++i) {
        let $elem = $(inputs[i]);

        const _type = $elem.attr('type');
        switch (_type) {
          case 'text':
            if ($elem.val().length > 0) {
              isGood = true;
            }
            break;
          case 'number':
            if ($elem.val().length > 0) {
              isGood = true;
            }
            break;
          case 'email':
            if ($elem.val().length > 0) {
              isGood = true;
            }
            break;
          case 'checkbox':
            if ($elem.is(':checked')) {
              isGood = true;
            }
            break;

          case 'radio':
            if ($elem.is(':checked')) {
              isGood = true;
            }
            break;

          default:
            isGood = false;
            break;
        }

        if (isGood) {
          break;
        }
      }

      if (!isGood) {

        setToastMessage(`Please select Diagnosis Codes | ICD-10 Codes.`);
        showToast('danger');
      }

      return isGood;
    }
    $(`#btnDiagnosisCodes_ICD10CodesForm2Next`).off('click').on('click', function (event) {
      event.preventDefault();

      if (btnDiagnosisCodes_ICD10CodesForm2NextFunc()) {

        $('div#DiagnosisCodes_ICD10CodesForm2').removeClass(' show');
        $('div#HBOCFamilyHistoryInfoForm2').addClass(' show');
        $('#DiagnosisCodes_ICD10CodesForm2CheckMark').removeClass('d-none');

      }

    });


    //HBOCFamilyHistoryInfoForm2
    const btnHBOCFamilyHistoryInfoForm2NextFunc = () => {

      // //For DEBUG:
      // let hboc1_q08_1dataValueanswers = [];
      // let hboc1_q08_1dataValueanswer = {
      //   "side": "",
      //   "relative": "",
      //   "age": ""
      // };

      // const isChecked = $("#pfHistory2_FBrOvPanAnyAge").is(":checked");
      // const vals = $("#hboc1_q08_1_pfHistory2_AnyAgeValues").val();
      // console.log("isChecked: ", isChecked, ", vals: ", vals);

      // if (isChecked && vals.length > 0) {

      //   const hboc1_q08_1_pfHistory_AnyAgeValues = vals.split(",");
      //   let j = 0;
      //   for (let i = 0; i < (hboc1_q08_1_pfHistory_AnyAgeValues.length / 3); i++) {
      //     //alert("side: "+ hboc1_q08_1_pfHistory_AnyAgeValues[j]+" relative: "+ hboc1_q08_1_pfHistory_AnyAgeValues[j+1]+" age: "+ hboc1_q08_1_pfHistory_AnyAgeValues[j+2]);
      //     hboc1_q08_1dataValueanswer = {
      //       side: hboc1_q08_1_pfHistory_AnyAgeValues[j],
      //       relative: hboc1_q08_1_pfHistory_AnyAgeValues[j + 1],
      //       age: hboc1_q08_1_pfHistory_AnyAgeValues[j + 2]
      //     };

      //     hboc1_q08_1dataValueanswers.push(hboc1_q08_1dataValueanswer);
      //     j = j + 3;
      //   }
      // }

      // let qnA = {};
      // if (isChecked) {
      //   qnA = {
      //     'questionCode': "hboc1_q08",
      //     'answer': true,
      //     'hBOCSubQuestions': [
      //       {
      //         "questionCode": "hboc1_q08_1",
      //         "answer": hboc1_q08_1dataValueanswers
      //       }
      //     ]
      //   }
      // } else {
      //   qnA = {
      //     'questionCode': "hboc1_q08",
      //     'answer': false,
      //     'hBOCSubQuestions': [
      //       {
      //         "questionCode": "hboc1_q08_1",
      //         "answer": []
      //       }
      //     ]
      //   }
      // }

      // console.log("qnA: ", qnA);
      // //END FOR DEBUG



      let inputs = $('#HBOCFamilyHistoryInfoForm2').find(':input');

      let isGood = false;
      for (let i = 0; i < inputs.length; ++i) {
        let $elem = $(inputs[i]);

        const _type = $elem.attr('type');
        switch (_type) {
          case 'text':
            if ($elem.val().length > 0) {
              isGood = true;
            }
            break;
          case 'number':
            if ($elem.val().length > 0) {
              isGood = true;
            }
            break;
          case 'email':
            if ($elem.val().length > 0) {
              isGood = true;
            }
            break;
          case 'checkbox':
            if ($elem.is(':checked')) {
              isGood = true;
            }
            break;

          case 'radio':
            if ($elem.is(':checked')) {
              isGood = true;
            }
            break;

          default:
            isGood = false;
            break;
        }

        if (isGood) {
          break;
        }
      }

      if (!isGood) {
        setToastMessage(`Please select Family History.`);
        showToast('danger');
      }

      return isGood;
    }
    $('#btnHBOCFamilyHistoryInfoForm2Next').off('click').on('click', function (event) {
      event.preventDefault();

      if (btnHBOCFamilyHistoryInfoForm2NextFunc()) {
        $('div#HBOCFamilyHistoryInfoForm2').removeClass(' show');
        $('div#ClinicalInformationOVAForm').addClass(' show');
        $('#HBOCFamilyHistoryInfoForm2CheckMark').removeClass('d-none');
      }

    });

    //ClinicalInformationOVAForm
    const ova1GenetixHBOCClinicalInfoFormNextFunc = () => {

      let isGood = true;
      console.log("clinicalInfoLastMenstrualPeriodDate: ", clinicalInfoLastMenstrualPeriodDate);
      if ($(`#${ova1GenetixHBOCPrefix}clinicinfo_Menopausal_status`).is(":not(:checked)") && $(`#${ova1GenetixHBOCPrefix}clinicinfo_Menopausal_status2`).is(":not(:checked)")) {

        setToastMessage(`Please select one menopausal status.`);
        showToast(`danger`);

        isGood = false;

      } else if ($(`#${ova1GenetixHBOCPrefix}clinicinfo_lowrisk`).is(":not(:checked)") && $(`#${ova1GenetixHBOCPrefix}clinicinfo_highrisk`).is(":not(:checked)") && $(`#${ova1GenetixHBOCPrefix}clinicinfo_NotDefinitive`).is(":not(:checked)")) {

        setToastMessage(`Please select Ultrasound Results.`);
        showToast(`danger`);

        isGood = false;
      }
      else if (clinicalInfoLastMenstrualPeriodDate.length < 1) {
        setToastMessage(`Please select last menstrual period date.`);
        showToast(`danger`);

        isGood = false;
      }
      else {

        isGood = true;
      }

      return isGood;
    }
    $(`#${ova1GenetixHBOCPrefix}btnClinicalInformationFormNext`).off('click').on('click', function (event) {
      event.preventDefault();

      if (ova1GenetixHBOCClinicalInfoFormNextFunc()) {

        $(`#ClinicalInformationOVAForm`).removeClass(' show');
        $(`#KitShippingInfoHBOCForm2`).addClass(' show');
        $(`#ClinicalInformationOVAFormCheckMark`).removeClass('d-none');

      }

    });

    //KitShippingInfoHBOCForm2
    const genetixHBOCKitShippingInfoFormNextFunc = () => {
      let isGood = true;

      const kitShipHBOC2_PatOthersInfo = helper.removeWhiteSpaceTrim($('#kitShipHBOC2_PatOthersInfo').val());
      $('#kitShipHBOC2_PatOthers').val(kitShipHBOC2_PatOthersInfo);

      if ($("#kitShipHBOC2_PatMobPhlebotomy").is(":not(:checked)")
        && $("#kitShipHBOC2_salivaCollkit").is(":not(:checked)")
        && $("#kitShipHBOC2_PatOthers").is(":not(:checked)")) {

        setToastMessage(`Please select Kit shipping options.`);
        showToast(`danger`);

        isGood = false;
      } else if (($("#kitShipHBOC2_PatOthers").is(":checked")) && (kitShipHBOC2_PatOthersInfo.length < 1)) {

        setToastMessage(`Kit shipping other options is required field.`);
        showToast(`danger`);
        $('#kitShipHBOC2_PatOthersInfo').focus();

        isGood = false;
      }
      else {

        isGood = true;
      }

      return isGood;
    }

    $('#btnKitShippingInfoHBOCForm2Next').off('click').on('click', function (event) {
      event.preventDefault();

      if (genetixHBOCKitShippingInfoFormNextFunc()) {

        $('div#KitShippingInfoHBOCForm2').removeClass(' show');
        $('div#KitShippingInfoOVAForm').addClass(' show');
        $('#KitShippingInfoHBOCForm2CheckMark').removeClass('d-none');

      }

    });

    //KitShippingInfoOVAForm
    const genetixOVAKitShippingInfoFormNextFunc = () => {
      let isGood = true;

      const kitShipOVA_PatOthersInfo = helper.removeWhiteSpaceTrim($('#kitShipOVA_PatOthersInfo').val());
      $('#kitShipOVA_PatOthers').val(kitShipOVA_PatOthersInfo);

      if ($("#kitShipOVA_PatMobPhlebotomy").is(":not(:checked)")
        && $("#kitShipOVA_PatblooddrawQuest").is(":not(:checked)")
        && $("#kitShipOVA_PatOthers").is(":not(:checked)")) {

        setToastMessage(`Please select Kit shipping options.`);
        showToast(`danger`);

        isGood = false;
      } else if (!($("#kitShipOVA_PatOthers").is(":not(:checked)")) && (kitShipOVA_PatOthersInfo.length < 1)) {

        setToastMessage(`Kit shipping other options is required field.`);
        showToast(`danger`);
        $('#kitShipOVA_PatOthersInfo').focus();

        isGood = false;
      }
      else {

        isGood = true;
      }

      return isGood;
    }
    $('#btnKitShippingInfoOVAFormNext').off('click').on('click', function (event) {
      event.preventDefault();

      if (genetixOVAKitShippingInfoFormNextFunc()) {

        $('div#KitShippingInfoOVAForm').removeClass(' show');
        $('div#NotesCertifyHBOC_OVAForm').addClass(' show');
        $('#KitShippingInfoOVAFormCheckMark').removeClass('d-none');

      }

    });


    $(`#${ova1GenetixHBOCPrefix}btnOva1GenetixHBOCOrderSubmit`).off('click').click(async () => {
      // 

      const $this = $(this);

      if (!btnASPGENHereditaryBreastOvarianCancerForm2NextFunc()) {
        //return;
        $('div#ASPGENHereditaryBreastOvarianCancerForm2').addClass(' show');
        $('div#NotesCertifyHBOC_OVAForm').removeClass(' show');

      } else if (!btnPhysicianInformationHBOCForm2NextFunc()) {
        //return;
        $('div#PhysicianInformationHBOCForm2').addClass(' show');
        $('div#NotesCertifyHBOC_OVAForm').removeClass(' show');

      } else if (!ova1GenetixHBOCPatientInfoFormNextFunc()) {
        //return;
        $('div#PatientInformationHBOCForm2').addClass(' show');
        $('div#NotesCertifyHBOC_OVAForm').removeClass(' show');

      } else if (!ova1GenetixHBOCBillingInfoFormNextFunc()) {
        //return;
        $('div#BillingInformationHBOCForm2').addClass(' show');
        $('div#NotesCertifyHBOC_OVAForm').removeClass(' show');

      } else if (!btnDiagnosisCodes_ICD10CodesForm2NextFunc()) {
        //return;        
        $('div#DiagnosisCodes_ICD10CodesForm2').addClass(' show');
        $('div#NotesCertifyHBOC_OVAForm').removeClass(' show');

      } else if (!btnHBOCFamilyHistoryInfoForm2NextFunc()) {
        //return; 
        $('div#HBOCFamilyHistoryInfoForm2').addClass(' show');
        $('div#NotesCertifyHBOC_OVAForm').removeClass(' show');

      } else if (!ova1GenetixHBOCClinicalInfoFormNextFunc()) {
        //return;
        $('div#ClinicalInformationOVAForm').addClass(' show');
        $('div#NotesCertifyHBOC_OVAForm').removeClass(' show');

      } else if (!genetixHBOCKitShippingInfoFormNextFunc()) {
        //return; 
        $('div#KitShippingInfoHBOCForm2').addClass(' show');
        $('div#NotesCertifyHBOC_OVAForm').removeClass(' show');

      } else if (!genetixOVAKitShippingInfoFormNextFunc()) {
        //return;
        $('div#KitShippingInfoOVAForm').addClass(' show');
        $('div#NotesCertifyHBOC_OVAForm').removeClass(' show');

      } else if ($("#kitShipHBOC_OVA_healthcareProvider").is(":not(:checked)")
        && $("#kitShipHBOC_OVA_employeehealthcareProvider").is(":not(:checked)")
        && $("#kitShipHBOC_OVA_partnerhealthcareProvider").is(":not(:checked)")) {

        setToastMessage(`Please select certify options.`);
        showToast(`danger`);

      } else {

        $this.html($this.data('loading-text'));

        const aSPiRAGENETIXCarrierScreening = [];

        $(`#ASPGENHereditaryBreastOvarianCancerForm2 :input`).each((i, inp) => {

          if ($(inp).is(':checked')) {
            aSPiRAGENETIXCarrierScreening.push({
              code: $(inp).data('val'),
              qBenchId: $(inp).data('qbenchid'),
            });
          }

        });


        console.log("aSPiRAGENETIXCarrierScreening: ", aSPiRAGENETIXCarrierScreening);

        //phyinfo_account_number
        let phyinfo_ordered_before = '';
        if ($(`#${ova1GenetixHBOCPrefix}ordered_before1`).is(":checked")) {
          phyinfo_ordered_before = $(`#${ova1GenetixHBOCPrefix}ordered_before1`).data('val');
        }
        if ($(`#${ova1GenetixHBOCPrefix}ordered_before2`).is(":checked")) {
          phyinfo_ordered_before = $(`#${ova1GenetixHBOCPrefix}ordered_before2`).data('val');
        }

        const phyinfo_account_number = $(`#${ova1GenetixHBOCPrefix}phyinfo_account_number`).val();
        const phyinfo_phyinfo_phone = $(`#${ova1GenetixHBOCPrefix}phyinfo_phone_ph_areacode`).val() + ' ' + $(`#${ova1GenetixHBOCPrefix}phyinfo_phone_ph_prefix`).val() + $(`#${ova1GenetixHBOCPrefix}phyinfo_phone_ph_suffix`).val();

        let physician = {};
        if ($(`#${ova1GenetixHBOCPrefix}ordered_before1`).is(":checked")) {

          physician = await helper.createPhysicianFromClientId(selectedPhysicianLegacyInfo);
        }
        else {
          const phyinfo_Organization_account = $(`#${ova1GenetixHBOCPrefix}phyinfo_Organization_account`).val();
          const phyinfo_first_name = $(`#${ova1GenetixHBOCPrefix}phyinfo_first_name`).val();
          const phyinfo_last_name = $(`#${ova1GenetixHBOCPrefix}phyinfo_last_name`).val();
          const phyinfo_npi = $(`#${ova1GenetixHBOCPrefix}phyinfo_npi`).val();
          const phyinfo_email = $(`#${ova1GenetixHBOCPrefix}phyinfo_email`).val();


          const phyinfo_address = $(`#${ova1GenetixHBOCPrefix}phyinfoC_address`).val();
          const phyinfo_city = $(`#${ova1GenetixHBOCPrefix}phyinfoC_city`).val();
          const phyinfo_state = physicianState;
          const phyinfo_zipCode = $(`#${ova1GenetixHBOCPrefix}phyinfoC_zipCode`).val();
          const phyinfo_phone = $(`${ova1GenetixHBOCPhoneNo_prefix}_phyinfoC_ph_areacode`).val() + ' ' + $(`${ova1GenetixHBOCPhoneNo_prefix}_phyinfoC_ph_prefix`).val() + $(`${ova1GenetixHBOCPhoneNo_prefix}_phyinfoC_ph_suffix`).val()
          const phyinfo_fax = $(`#${ova1GenetixHBOCPrefix}phyinfoC_fax`).val();
          const phyinfo_Faxcopyto = $(`#${ova1GenetixHBOCPrefix}phyinfoC_Faxcopyto`).val();
          const phyinfo_confirmation = $(`#${ova1GenetixHBOCPrefix}phyinfoC_confirmation`).val();

          const phyinfoC_practiceLocation = $(`#${ova1GenetixHBOCPrefix}phyinfoC_practiceLocation`).val();


          const addresses = [];
          const practiceLocation = [];

          const licenses = [];

          console.log("physicianAddresses: ", physicianAddresses)
          // if (typeof physicianAddresses == "undefined" || physicianAddresses.length < 1) {
          //   return;
          // }

          let data = {
            organizationAccount: phyinfo_Organization_account,
            firstName: phyinfo_first_name,
            lastName: phyinfo_last_name,
            npiNumber: phyinfo_npi,
            email: phyinfo_email,
            addresses: [],
            address_type: "Primary",
            practiceLocation: {
            },
            licenses: licenses,
            registeredUser: "",
          }
          if (physicianAddresses && physicianAddresses.length < 1) {

            addresses.push({
              locationName: "USA",
              address_1: phyinfo_address,
              address_2: "",
              addressType: "Primary",
              city: phyinfo_city,
              country: "USA",
              state: phyinfo_state,
              zipCode: phyinfo_zipCode,
              phoneNo: phyinfo_phone,
              fax: phyinfo_fax,
              fax_copyto: phyinfo_Faxcopyto,

            });

            data.addresses = addresses;
            data.practiceLocationPlain = phyinfoC_practiceLocation;
          }
          else {

            physicianAddresses.map((a) => {
              addresses.push({
                locationName: "USA",
                address_1: a.address_1,
                address_2: a.address_1,
                addressType: a.address_type,
                city: a.city,
                country: a.country_name,
                state: a.state,
                zipCode: a.postal_code,
                phoneNo: a.telephone_number,
              });
            });

            data.addresses = addresses;
            data.practiceLocationPlain = phyinfoC_practiceLocation;
          }


          // console.log("physicianAddresses: ", physicianAddresses);
          console.log("data: ", data);


          physician = await createPhysicianApi(data);
        }

        console.log("physician: ", physician);

        if (physician.isok) {
          $this.html('Done');
          setToastMessage(`Physician Created Successfully. Creating Patient`);
          showToast(`success`);


          const patinfo_firstname = $(`#${ova1GenetixHBOCPrefix}patinfo_firstname`).val();
          const patinfo_lastname = $(`#${ova1GenetixHBOCPrefix}patinfo_lastname`).val();
          const patinfo_address = $(`#${ova1GenetixHBOCPrefix}patinfo_address`).val();
          const patinfo_city = $(`#${ova1GenetixHBOCPrefix}patinfo_city`).val();
          const patinfo_state = $(`#${ova1GenetixHBOCPrefix}patinfo_state`).val();
          const patinfo_zipcode = $(`#${ova1GenetixHBOCPrefix}patinfo_zipcode`).val();
          const patinfo_PhoneNumber = $(`#${ova1GenetixHBOCPhoneNo_prefix}patinfo_ph_areacode`).val() + $(`#${ova1GenetixHBOCPhoneNo_prefix}patinfo_ph_prefix`).val() + $(`#${ova1GenetixHBOCPhoneNo_prefix}patinfo_ph_suffix`).val()
          const patinfo_emailaddress = $(`#${ova1GenetixHBOCPrefix}patinfo_emailaddress`).val();
          const patinfo_DOB = patientDOBLocal;

          const ethnicity = [];

          if ($(`#${ova1GenetixHBOCPrefix}patinfo_Caucasian`).is(":checked")) {
            ethnicity.push($(`#${ova1GenetixHBOCPrefix}patinfo_Caucasian`).data('val'));
          }
          if ($(`#${ova1GenetixHBOCPrefix}patinfo_AshkenaziJewish`).is(":checked")) {
            ethnicity.push($(`#${ova1GenetixHBOCPrefix}patinfo_AshkenaziJewish`).data('val'));
          }
          if ($(`#${ova1GenetixHBOCPrefix}patinfo_SephardicJewish`).is(":checked")) {
            ethnicity.push($(`#${ova1GenetixHBOCPrefix}patinfo_SephardicJewish`).data('val'));
          }
          if ($(`#${ova1GenetixHBOCPrefix}patinfo_Asian`).is(":checked")) {
            ethnicity.push($(`#${ova1GenetixHBOCPrefix}patinfo_Asian`).data('val'));
          }
          if ($(`#${ova1GenetixHBOCPrefix}patinfo_HIspanic`).is(":checked")) {
            ethnicity.push($(`#${ova1GenetixHBOCPrefix}patinfo_HIspanic`).data('val'));
          }
          if ($(`#${ova1GenetixHBOCPrefix}patinfo_NativeAmerican`).is(":checked")) {
            ethnicity.push($(`#${ova1GenetixHBOCPrefix}patinfo_NativeAmerican`).data('val'));
          }
          if ($(`#${ova1GenetixHBOCPrefix}patinfo_AfricanAmerican`).is(":checked")) {
            ethnicity.push($(`#${ova1GenetixHBOCPrefix}patinfo_AfricanAmerican`).data('val'));
          }
          if ($(`#${ova1GenetixHBOCPrefix}patinfo_Other`).is(":checked")) {
            ethnicity.push($(`#${ova1GenetixHBOCPrefix}patinfo_Other`).data('val'));
            ethnicity.push($(`#${ova1GenetixHBOCPrefix}patinfo_OtherEthnicitycity`).val());
          }

          let gender = "";

          if ($("#ova1GenetixHBOC_patinfo_gender").is(":checked")) {
            gender = 'male';
          } else {
            gender = 'female';
          }
          // $(`#ova1GenetixHBOC_patinfo_gender_div`).find('input').map((elem, i) => {
          //   if ($(elem).is(":checked")) {
          //     gender = $(elem).val();
          //   }
          // });

          let data = {
            email: patinfo_emailaddress,
            firstName: patinfo_firstname,
            lastName: patinfo_lastname,
            address: {
              locationName: "USA",
              address_1: patinfo_address,
              address_2: "",
              addressType: "",
              city: patinfo_city,
              country: "USA",
              state: patientState,
              zipCode: patinfo_zipcode,
            },
            dob: patinfo_DOB,
            phoneNo: patinfo_PhoneNumber,

            ethnicity: ethnicity.join(","),
            registeredUser: "",
            gender: gender,

          }

          console.log("Before Submit data: ", data);

          $this.html("<img style='height: 30px;' src='/assets/images/Spinner-1s-200px.svg' /> Creating Patient")


          let patient = await createPatientApi(data);
          console.log("patient: ", patient);

          if (patient.isok) {

            let billing_info = {
              primaryInsurance: {},
              secondaryInsurance: {},
              insuredInfo: {}
            };
            var sections = $("div#BillingInformationHBOCForm2 #billing_info_container").find(":input");

            sections.map((input, i) => {
              const $input = $(sections[input]);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));
              console.log("$input: ", $input);

              let _id = $input.attr('id');

              if (typeof _id == 'undefined' || _id.length < 1) {
                _id = `${ova1GenetixHBOCPrefix}billinfo_DOB`;
              }
              const _type = $input.attr('type');
              let obj_id = _id.replace(`${ova1GenetixHBOCPrefix}billinfo_`, "");
              obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)
              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  billing_info[obj_id] = true;
                }
                else {
                  billing_info[obj_id] = false;
                }
              }
              else {
                billing_info[obj_id] = $input.val();
              }
            });

            sections = $("div#BillingInformationHBOCForm2 #primary_insurance_container").find(":input");
            sections.map((input, i) => {
              const $input = $(sections[input]);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));
              console.log("$input: ", $input);

              let _id = $input.attr('id');

              if (typeof _id == 'undefined' || _id.length < 1) {
                _id = `${ova1GenetixHBOCPrefix}billinfo_DOB`;
              }
              const _type = $input.attr('type');
              let obj_id = _id.replace(`${ova1GenetixHBOCPrefix}billinfo_`, "");
              obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)
              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  billing_info['primaryInsurance'][obj_id] = true;
                }
                else {
                  billing_info['primaryInsurance'][obj_id] = false;
                }
              }
              else {
                billing_info['primaryInsurance'][obj_id] = $input.val();
              }

            });

            sections = $("div#BillingInformationHBOCForm2 #secondary_insurance_container").find(":input");
            sections.map((input, i) => {
              const $input = $(sections[input]);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));
              console.log("$input: ", $input);

              let _id = $input.attr('id');

              if (typeof _id == 'undefined' || _id.length < 1) {
                _id = `${ova1GenetixHBOCPrefix}billinfo_DOB`;
              }
              const _type = $input.attr('type');
              let obj_id = _id.replace(`${ova1GenetixHBOCPrefix}billinfo_`, "");
              obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)
              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  billing_info['secondaryInsurance'][obj_id] = true;
                }
                else {
                  billing_info['secondaryInsurance'][obj_id] = false;
                }
              }
              else {
                billing_info['secondaryInsurance'][obj_id] = $input.val();
              }

            });

            sections = $("div#BillingInformationHBOCForm2 #insured_info").find(":input");
            sections.map((input, i) => {
              const $input = $(sections[input]);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));
              console.log("$input: ", $input);

              let _id = $input.attr('id');

              if (typeof _id == 'undefined' || _id.length < 1) {
                _id = `${ova1GenetixHBOCPrefix}billinfo_DOB`;
              }
              const _type = $input.attr('type');
              let obj_id = _id.replace(`${ova1GenetixHBOCPrefix}billinfo_`, "");
              obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)
              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  billing_info['insuredInfo'][obj_id] = true;
                }
                else {
                  billing_info['insuredInfo'][obj_id] = false;
                }
              }
              else {
                billing_info['insuredInfo'][obj_id] = $input.val();
              }

            });
            console.log("billing_info: ", billing_info);

            //Clinical data start
            let qnA = {
              questionCode: "",
              answer: "",
              subQuestion: []
            };

            let clinicalData = [];

            sections = $(`#ClinicalInformationOVAForm #${ova1GenetixHBOCPrefix}ova1_q01`).find(":input");
            sections.map((input, i) => {
              const $input = $(sections[input]);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));

              let obj_id = $input.attr('id');

              const _type = $input.attr('type');
              obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)

              if (_type == 'radio') {
                if ($input.is(':checked')) {
                  qnA = {
                    'questionCode': "ova1_q01",
                    'answer': $input.val(),
                  }
                }
              }
            });
            clinicalData.push(qnA);

            const ova1_q02 = [];
            sections = $(`#ClinicalInformationOVAForm #${ova1GenetixHBOCPrefix}ova1_q02`).find(":input");
            sections.map((input, i) => {
              const $input = $(sections[input]);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));

              let obj_id = $input.attr('id');

              const _type = $input.attr('type');
              obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)

              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  ova1_q02.push($input.data('val'));
                }
              }

            });

            qnA = {
              'questionCode': "ova1_q02",
              'answer': ova1_q02.join(', '),
            }
            clinicalData.push(qnA);


            qnA = {
              'questionCode': "ova1_q03",
              'answer': clinicalInfoLastMenstrualPeriodDate,
            }
            clinicalData.push(qnA);


            qnA = {
              'questionCode': "ova1_q04",
              'answer': $(`#ClinicalInformationOVAForm #${ova1GenetixHBOCPrefix}clinicinfo_MassSize_longdime`).val(),
              subQuestion: [
                {
                  'questionCode': "ova1_q04_1",
                  'answer': $(`#ClinicalInformationOVAForm #${ova1GenetixHBOCPrefix}clinicinfo_MassSize_Height`).val() + " " + $(`#ClinicalInformationOVAForm #${ova1GenetixHBOCPrefix}clinicinfo_MassSize_Height_unit`).val(),
                },
                {
                  'questionCode': "ova1_q04_2",
                  'answer': $(`#ClinicalInformationOVAForm #${ova1GenetixHBOCPrefix}clinicinfo_MassSize_Weight`).val() + " " + $(`#ClinicalInformationOVAForm #${ova1GenetixHBOCPrefix}clinicinfo_MassSize_Weight_unit`).val(),
                },
              ]
            };

            clinicalData.push(qnA);

            if (!$(`#${ova1GenetixHBOCPrefix}clinicinfo_pain_abdomen_pelvis`).is(":checked")) {
              qnA = {
                'questionCode': "ova1_q05",
                'answer': false,
              };
            }
            else {
              let radioValue1 = $(`input[name='clinicinfo_painAbdomenPelvis_Day_']:checked`).val();
              let radioValue2 = $(`input[name='clinicinfo_painAbdomenPelvis_long_']:checked`).val();
              console.log("radioValue1: ", radioValue1);
              console.log("radioValue2: ", radioValue2);
              qnA = {
                'questionCode': "ova1_q05",
                'answer': true,
                subQuestion: [
                  {
                    'questionCode': "ova1_q05_1",
                    'answer': radioValue1,
                  },
                  {
                    'questionCode': "ova1_q05_2",
                    'answer': radioValue2,
                  },
                ]
              };
            }
            clinicalData.push(qnA);

            if (!$(`#${ova1GenetixHBOCPrefix}clinicinfo_fullQuickly_unableToe`).is(":checked")) {
              qnA = {
                'questionCode': "ova1_q06",
                'answer': false,
              };
            }
            else {
              let radioValue1 = $("input[name='clinicinfo_fullQuicklyUnableToe_Day_']:checked").val();
              let radioValue2 = $("input[name='clinicinfo_fullQuicklyUnableToe_long_']:checked").val();
              qnA = {
                'questionCode': "ova1_q06",
                'answer': true,
                subQuestion: [
                  {
                    'questionCode': "ova1_q06_1",
                    'answer': radioValue1,
                  },
                  {
                    'questionCode': "ova1_q06_2",
                    'answer': radioValue2,
                  },
                ]
              };
            }
            clinicalData.push(qnA);

            if (!$(`#${ova1GenetixHBOCPrefix}clinicinfo_Expabdominalbloat_IncrSize`).is(":checked")) {
              qnA = {
                'questionCode': "ova1_q07",
                'answer': false,
              };
            }
            else {
              let radioValue1 = $("input[name='clinicinfo_ExpabdominalbloatIncrSize_Day_']:checked").val();
              let radioValue2 = $("input[name='clinicinfo_ExpabdominalbloatIncrSize_long_']:checked").val();
              qnA = {
                'questionCode': "ova1_q07",
                'answer': true,
                subQuestion: [
                  {
                    'questionCode': "ova1_q07_1",
                    'answer': radioValue1,
                  },
                  {
                    'questionCode': "ova1_q07_2",
                    'answer': radioValue2,
                  },
                ]
              };
            }
            clinicalData.push(qnA);
            console.log("clinicalData: ", clinicalData);

            //For OVA1 ICD Codes
            const icd10Codes_ova1 = [];
            const icd10CodesOther_ova1 = [];
            let icd10Code_ova1 = {
              'codes': [],
              'otherCodes': [],
            };

            sections = $("#DiagnosisCodes_ICD10CodesForm2 #oVA1-DiagnosisCodes").find(":input");
            sections.map((input, i) => {
              const $input = $(sections[input]);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));

              // let obj_id = $input.attr('id');

              const _type = $input.attr('type');
              // obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)

              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  icd10Codes_ova1.push($input.data('val'));
                }
              }
              // else if (_type == 'text') {
              //   icd10CodesOther_ova1.push($input.val());
              // }

            });

            icd10CodesOther_ova1.push($("#OtherICD10Codes2").val());

            icd10Code_ova1 = {
              'codes': icd10Codes_ova1,
              'otherCodes': icd10CodesOther_ova1,
            }

            //hBOC-DiagnosisCodes
            const icd10Codes_hboc = [];
            const icd10CodesOther_hboc = [];
            let icd10Code_hboc = {
              'codes': [],
              'otherCodes': [],
            };

            sections = $("#DiagnosisCodes_ICD10CodesForm2 #hBOC-DiagnosisCodes").find(":input");
            sections.map((input, i) => {
              const $input = $(sections[input]);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));

              // let obj_id = $input.attr('id');

              const _type = $input.attr('type');
              // obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)

              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  icd10Codes_hboc.push($input.data('val'));
                }
              }
              // else if (_type == 'text') {
              //   icd10CodesOther_ova1.push($input.val());
              // }

            });

            icd10CodesOther_hboc.push($("#OtherICD10Codes2").val());

            icd10Code_hboc = {
              'codes': icd10Codes_hboc,
              'otherCodes': icd10CodesOther_hboc,
            }

            //ova1 Plus Biomarkers
            let ova1PlusBiomarkers = {
              GCP: [],
              MCP: [],
              other: "",
            };

            //Personal/Family History -- Genetix OVA 
            //Clinical data
            //hboc1_q01
            if ($("#pfHistory2_noCancer").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q01",
                'answer': true,
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q01",
                'answer': false,
              }
            }

            clinicalData.push(qnA);

            //hboc1_q02
            if ($("#pfHistory2_yesCancer").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q02",
                'answer': true,
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q02",
                'answer': false,
              }
            }

            clinicalData.push(qnA);

            //hboc1_q03        
            if ($("#pfHistory2_previousCancer").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q03",
                'answer': true,
                'subQuestion': [
                  {
                    'questionCode': "hboc1_q03_1",
                    'answer': $("#pfHistory2_prevCanDefine").val(),
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q03",
                'answer': false,
                'subQuestion': [
                  {
                    'questionCode': "hboc1_q03_1",
                    'answer': '',
                  }
                ]
              }
            }

            clinicalData.push(qnA);

            //hboc1_q04 
            if ($("#pfHistory2_SomaticMutationProf").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q04",
                'answer': true,
                'subQuestion': [
                  {
                    'questionCode': "hboc1_q04_1",
                    'answer': $("#pfHistory2_SoMutaProfDefine").val(),
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q04",
                'answer': false,
                'subQuestion': [
                  {
                    'questionCode': "hboc1_q04_1",
                    'answer': '',
                  }
                ]
              }
            }
            clinicalData.push(qnA);

            //hboc1_q05
            const hboc1_q05_2 = [];
            if ($("#pfHistory2_Familial_Inherited").is(":checked")) {
              hboc1_q05_2.push($("#pfHistory2_Familial_Inherited").data('val'));
            }
            if ($("#pfHistory2_Newly_identified").is(":checked")) {
              hboc1_q05_2.push($("#pfHistory2_Newly_identified").data('val'));
            }

            if ($("#pfHistory2_PrevGermltesting").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q05",
                'answer': true,
                'subQuestion': [
                  {
                    'questionCode': "hboc1_q05_1",
                    'answer': $("#pfHistory2_PrevGermltestDefine").val(),
                  },
                  {
                    "questionCode": "hboc1_q05_2",
                    "answer": hboc1_q05_2.join(),
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q05",
                'answer': false,
                'subQuestion': [
                  {
                    'questionCode': "hboc1_q05_1",
                    'answer': '',
                  }
                  ,
                  {
                    "questionCode": "hboc1_q05_2",
                    "answer": hboc1_q05_2.join(),
                  }
                ]
              }
            }
            clinicalData.push(qnA);

            //hboc1_q06
            if ($("#pfHistory2_BrOvPanAnyAge").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q06",
                'answer': true,
                'subQuestion': [
                  {
                    'questionCode': "hboc1_q06_1",
                    'answer': $("#pfHistory2_BrOvPanAnyAgeCan").val(),
                  },
                  {
                    "questionCode": "hboc1_q06_2",
                    "answer": $("#pfHistory2_BrOvPanAnyAgeInfo").val(),
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q06",
                'answer': false,
                'subQuestion': [
                  {
                    'questionCode': "hboc1_q06_1",
                    'answer': '',
                  }
                  ,
                  {
                    "questionCode": "hboc1_q06_2",
                    "options": '',
                  }
                ]
              }
            }
            clinicalData.push(qnA);

            //hboc1_q07
            if ($("#pfHistory2_ColorUterineAnyAge").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q07",
                'answer': true,
                'subQuestion': [
                  {
                    'questionCode': "hboc1_q07_1",
                    'answer': $("#pfHistory2_ColorUterineAnyAgeCan").val(),
                  },
                  {
                    "questionCode": "hboc1_q07_2",
                    "answer": $("#pfHistory2_ColorUterineAnyAgeInfo").val(),
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q07",
                'answer': false,
                'subQuestion': [
                  {
                    'questionCode': "hboc1_q07_1",
                    'answer': '',
                  }
                  ,
                  {
                    "questionCode": "hboc1_q07_2",
                    "options": '',
                  }
                ]
              }
            }
            clinicalData.push(qnA);

            //hboc1_q08  Breast, ovarian or pancreative cancer at any age?  
            //hboc1_q08_1
            let hboc1_q08_1dataValueanswers = [];
            let hboc1_q08_1dataValueanswer = {
              "side": "",
              "relative": "",
              "age": ""
            };

            if ($("#pfHistory2_FBrOvPanAnyAge").is(":checked") && $("#hboc1_q08_1_pfHistory2_AnyAgeValues").val().length > 0) {

              const hboc1_q08_1_pfHistory_AnyAgeValues = $("#hboc1_q08_1_pfHistory2_AnyAgeValues").val().split(",");
              let j = 0;
              for (let i = 0; i < (hboc1_q08_1_pfHistory_AnyAgeValues.length / 3); i++) {
                //alert("side: "+ hboc1_q08_1_pfHistory_AnyAgeValues[j]+" relative: "+ hboc1_q08_1_pfHistory_AnyAgeValues[j+1]+" age: "+ hboc1_q08_1_pfHistory_AnyAgeValues[j+2]);
                hboc1_q08_1dataValueanswer = {
                  side: hboc1_q08_1_pfHistory_AnyAgeValues[j],
                  relative: hboc1_q08_1_pfHistory_AnyAgeValues[j + 1],
                  age: hboc1_q08_1_pfHistory_AnyAgeValues[j + 2]
                };

                hboc1_q08_1dataValueanswers.push(hboc1_q08_1dataValueanswer);
                j = j + 3;
              }
            }

            if ($("#pfHistory2_FBrOvPanAnyAge").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q08",
                'answer': true,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q08_1",
                    "answer": hboc1_q08_1dataValueanswers
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q08",
                'answer': false,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q08_1",
                    "answer": []
                  }
                ]
              }
            }
            clinicalData.push(qnA);


            //hboc1_q09  Two breast cancers (bilateral) in one relative at any age 
            //hboc1_q09_1
            let hboc1_q09_1dataValueanswers = [];
            let hboc1_q09_1dataValueanswer = {
              "side": "",
              "relative": "",
              "age": ""
            };

            if ($("#pfHistory2_FTwoBreasCanAge").is(":checked") && $("#hboc1_q09_1_pfHistory2_AnyAgeValues").val().length > 0) {

              const hboc1_q09_1_pfHistory_AnyAgeValues = $("#hboc1_q09_1_pfHistory2_AnyAgeValues").val().split(",");
              let j = 0;
              for (let i = 0; i < (hboc1_q09_1_pfHistory_AnyAgeValues.length / 3); i++) {

                hboc1_q09_1dataValueanswer = {
                  side: hboc1_q09_1_pfHistory_AnyAgeValues[j],
                  relative: hboc1_q09_1_pfHistory_AnyAgeValues[j + 1],
                  age: hboc1_q09_1_pfHistory_AnyAgeValues[j + 2]
                };

                hboc1_q09_1dataValueanswers.push(hboc1_q09_1dataValueanswer);
                j = j + 3;
              }
            }

            if ($("#pfHistory2_FTwoBreasCanAge").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q09",
                'answer': true,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q09_1",
                    "answer": hboc1_q09_1dataValueanswers
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q09",
                'answer': false,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q09_1",
                    "answer": []
                  }
                ]
              }
            }
            clinicalData.push(qnA);


            //hboc1_q010 Three breast cancers in relatives on the same  side of the family at any age
            //hboc1_q010_1
            let hboc1_q010_1dataValueanswers = [];
            let hboc1_q010_1dataValueanswer = {
              "side": "",
              "relative": "",
              "age": ""
            };

            if ($("#pfHistory2_FThrBreasCanAge").is(":checked") && $("#hboc1_q010_1_pfHistory2_AnyAgeValues").val().length > 0) {

              const hboc1_q010_1_pfHistory_AnyAgeValues = $("#hboc1_q010_1_pfHistory2_AnyAgeValues").val().split(",");
              let j = 0;
              for (let i = 0; i < (hboc1_q010_1_pfHistory_AnyAgeValues.length / 3); i++) {

                hboc1_q010_1dataValueanswer = {
                  side: hboc1_q010_1_pfHistory_AnyAgeValues[j],
                  relative: hboc1_q010_1_pfHistory_AnyAgeValues[j + 1],
                  age: hboc1_q010_1_pfHistory_AnyAgeValues[j + 2]
                };

                hboc1_q010_1dataValueanswers.push(hboc1_q010_1dataValueanswer);
                j = j + 3;
              }
            }

            if ($("#pfHistory2_FThrBreasCanAge").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q010",
                'answer': true,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q010_1",
                    "answer": hboc1_q010_1dataValueanswers
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q010",
                'answer': false,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q010_1",
                    "answer": []
                  }
                ]
              }
            }
            clinicalData.push(qnA);

            //hboc1_q011 Ovarian cancer at any age    
            //hboc1_q011_1
            let hboc1_q011_1dataValueanswers = [];
            let hboc1_q011_1dataValueanswer = {
              "side": "",
              "relative": "",
              "age": ""
            };


            if ($("#pfHistory2_OvarianCanAge").is(":checked") && $("#hboc1_q011_1_pfHistory2_AnyAgeValues").val().length > 0) {

              const hboc1_q011_1_pfHistory_AnyAgeValues = $("#hboc1_q011_1_pfHistory2_AnyAgeValues").val().split(",");
              let j = 0;
              for (let i = 0; i < (hboc1_q011_1_pfHistory_AnyAgeValues.length / 3); i++) {

                hboc1_q011_1dataValueanswer = {
                  side: hboc1_q011_1_pfHistory_AnyAgeValues[j],
                  relative: hboc1_q011_1_pfHistory_AnyAgeValues[j + 1],
                  age: hboc1_q011_1_pfHistory_AnyAgeValues[j + 2]
                };

                hboc1_q011_1dataValueanswers.push(hboc1_q011_1dataValueanswer);
                j = j + 3;
              }
            }


            if ($("#pfHistory2_OvarianCanAge").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q011",
                'answer': true,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q011_1",
                    "answer": hboc1_q011_1dataValueanswers
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q011",
                'answer': false,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q011_1",
                    "answer": []
                  }
                ]
              }
            }
            clinicalData.push(qnA);

            //hboc1_q012 Pancreatic cancer at any age
            //hboc1_q012_1
            let hboc1_q012_1dataValueanswers = [];
            let hboc1_q012_1dataValueanswer = {
              "side": "",
              "relative": "",
              "age": ""
            };

            if ($("#pfHistory2_PancreaticCanAge").is(":checked") && $("#hboc1_q012_1_pfHistory2_AnyAgeValues").val().length > 0) {

              const hboc1_q012_1_pfHistory_AnyAgeValues = $("#hboc1_q012_1_pfHistory2_AnyAgeValues").val().split(",");
              let j = 0;
              for (let i = 0; i < (hboc1_q012_1_pfHistory_AnyAgeValues.length / 3); i++) {

                hboc1_q012_1dataValueanswer = {
                  side: hboc1_q012_1_pfHistory_AnyAgeValues[j],
                  relative: hboc1_q012_1_pfHistory_AnyAgeValues[j + 1],
                  age: hboc1_q012_1_pfHistory_AnyAgeValues[j + 2]
                };

                hboc1_q012_1dataValueanswers.push(hboc1_q012_1dataValueanswer);
                j = j + 3;
              }
            }

            if ($("#pfHistory2_PancreaticCanAge").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q012",
                'answer': true,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q012_1",
                    "answer": hboc1_q012_1dataValueanswers
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q012",
                'answer': false,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q012_1",
                    "answer": []
                  }
                ]
              }
            }
            clinicalData.push(qnA);

            //hboc1_q013  Male breast cancer at any age        
            //hboc1_q013_1
            let hboc1_q013_1dataValueanswers = [];
            let hboc1_q013_1dataValueanswer = {
              "side": "",
              "relative": "",
              "age": ""
            };

            if ($("#pfHistory2_MaleBreastCanAge").is(":checked") && $("#hboc1_q013_1_pfHistory2_AnyAgeValues").val().length > 0) {

              const hboc1_q013_1_pfHistory_AnyAgeValues = $("#hboc1_q013_1_pfHistory2_AnyAgeValues").val().split(",");
              let j = 0;
              for (let i = 0; i < (hboc1_q013_1_pfHistory_AnyAgeValues.length / 3); i++) {

                hboc1_q013_1dataValueanswer = {
                  side: hboc1_q013_1_pfHistory_AnyAgeValues[j],
                  relative: hboc1_q013_1_pfHistory_AnyAgeValues[j + 1],
                  age: hboc1_q013_1_pfHistory_AnyAgeValues[j + 2]
                };

                hboc1_q013_1dataValueanswers.push(hboc1_q013_1dataValueanswer);
                j = j + 3;
              }
            }

            if ($("#pfHistory2_MaleBreastCanAge").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q013",
                'answer': true,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q013_1",
                    "answer": hboc1_q013_1dataValueanswers
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q013",
                'answer': false,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q013_1",
                    "answer": []
                  }
                ]
              }
            }
            clinicalData.push(qnA);

            //hboc1_q014 Metastatic prostate cancer at any age?
            //hboc1_q014_1
            let hboc1_q014_1dataValueanswers = [];
            let hboc1_q014_1dataValueanswer = {
              "side": "",
              "relative": "",
              "age": ""
            };

            if ($("#pfHistory2_MetaProstateCanAge").is(":checked") && $("#hboc1_q014_1_pfHistory2_AnyAgeValues").val().length > 0) {

              const hboc1_q014_1_pfHistory_AnyAgeValues = $("#hboc1_q014_1_pfHistory2_AnyAgeValues").val().split(",");
              let j = 0;
              for (let i = 0; i < (hboc1_q014_1_pfHistory_AnyAgeValues.length / 3); i++) {

                hboc1_q014_1dataValueanswer = {
                  side: hboc1_q014_1_pfHistory_AnyAgeValues[j],
                  relative: hboc1_q014_1_pfHistory_AnyAgeValues[j + 1],
                  age: hboc1_q014_1_pfHistory_AnyAgeValues[j + 2]
                };

                hboc1_q014_1dataValueanswers.push(hboc1_q014_1dataValueanswer);
                j = j + 3;
              }
            }

            if ($("#pfHistory2_MetaProstateCanAge").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q014",
                'answer': true,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q014_1",
                    "answer": hboc1_q014_1dataValueanswers
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q014",
                'answer': false,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q014_1",
                    "answer": []
                  }
                ]
              }
            }
            clinicalData.push(qnA);

            //hboc1_q015 Colon cancer at 49 or younger
            //hboc1_q015_1
            let hboc1_q015_1dataValueanswers = [];
            let hboc1_q015_1dataValueanswer = {
              "side": "",
              "relative": "",
              "age": ""
            };

            if ($("#pfHistory2_ColorCanAge").is(":checked") && $("#hboc1_q015_1_pfHistory2_AnyAgeValues").val().length > 0) {

              const hboc1_q015_1_pfHistory_AnyAgeValues = $("#hboc1_q015_1_pfHistory2_AnyAgeValues").val().split(",");
              let j = 0;
              for (let i = 0; i < (hboc1_q015_1_pfHistory_AnyAgeValues.length / 3); i++) {

                hboc1_q015_1dataValueanswer = {
                  side: hboc1_q015_1_pfHistory_AnyAgeValues[j],
                  relative: hboc1_q015_1_pfHistory_AnyAgeValues[j + 1],
                  age: hboc1_q015_1_pfHistory_AnyAgeValues[j + 2]
                };

                hboc1_q015_1dataValueanswers.push(hboc1_q015_1dataValueanswer);
                j = j + 3;
              }
            }

            if ($("#pfHistory2_ColorCanAge").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q015",
                'answer': true,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q015_1",
                    "answer": hboc1_q015_1dataValueanswers
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q015",
                'answer': false,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q015_1",
                    "answer": []
                  }
                ]
              }
            }
            clinicalData.push(qnA);

            //hboc1_q016 Uterine cancer at 49 or younger?
            //hboc1_q016_1
            let hboc1_q016_1dataValueanswers = [];
            let hboc1_q016_1dataValueanswer = {
              "side": "",
              "relative": "",
              "age": ""
            };

            if ($("#pfHistory2_UterineCanAge").is(":checked") && $("#hboc1_q016_1_pfHistory2_AnyAgeValues").val().length > 0) {

              const hboc1_q016_1_pfHistory_AnyAgeValues = $("#hboc1_q016_1_pfHistory2_AnyAgeValues").val().split(",");
              let j = 0;
              for (let i = 0; i < (hboc1_q016_1_pfHistory_AnyAgeValues.length / 3); i++) {

                hboc1_q016_1dataValueanswer = {
                  side: hboc1_q016_1_pfHistory_AnyAgeValues[j],
                  relative: hboc1_q016_1_pfHistory_AnyAgeValues[j + 1],
                  age: hboc1_q016_1_pfHistory_AnyAgeValues[j + 2]
                };

                hboc1_q016_1dataValueanswers.push(hboc1_q016_1dataValueanswer);
                j = j + 3;
              }
            }

            if ($("#pfHistory2_UterineCanAge").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q016",
                'answer': true,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q016_1",
                    "answer": hboc1_q016_1dataValueanswers
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q016",
                'answer': false,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q016_1",
                    "answer": []
                  }
                ]
              }
            }
            clinicalData.push(qnA);


            //hboc1_q017 Ashkenazi Jewish ancestry with breast cancer at any age?
            //hboc1_q017_1
            let hboc1_q017_1dataValueanswers = [];
            let hboc1_q017_1dataValueanswer = {
              "side": "",
              "relative": "",
              "age": ""
            };

            if ($("#pfHistory2_AshJewishAncesCanAge").is(":checked") && $("#hboc1_q017_1_pfHistory2_AnyAgeValues").val().length > 0) {

              const hboc1_q017_1_pfHistory_AnyAgeValues = $("#hboc1_q017_1_pfHistory2_AnyAgeValues").val().split(",");
              let j = 0;
              for (let i = 0; i < (hboc1_q017_1_pfHistory_AnyAgeValues.length / 3); i++) {

                hboc1_q017_1dataValueanswer = {
                  side: hboc1_q017_1_pfHistory_AnyAgeValues[j],
                  relative: hboc1_q017_1_pfHistory_AnyAgeValues[j + 1],
                  age: hboc1_q017_1_pfHistory_AnyAgeValues[j + 2]
                };

                hboc1_q017_1dataValueanswers.push(hboc1_q017_1dataValueanswer);
                j = j + 3;
              }
            }
            if ($("#pfHistory2_AshJewishAncesCanAge").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q017",
                'answer': true,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q017_1",
                    "answer": hboc1_q017_1dataValueanswers
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q017",
                'answer': false,
                'hBOCSubQuestions': [
                  {
                    "questionCode": "hboc1_q017_1",
                    "answer": []
                  }
                ]
              }
            }
            clinicalData.push(qnA);

            //hboc1_q018 Does the physician have a family history of other cancers?
            if ($("#pfHistory2_otherCan").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q018",
                'answer': true,
                'subQuestion': [
                  {
                    'questionCode': "hboc1_q018_1",
                    'answer': $("#pfHistory2_otherCan_info").val(),
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q018",
                'answer': false,
                'subQuestion': [
                  {
                    'questionCode': "hboc1_q018_1",
                    'answer': '',
                  }
                ]
              }
            }
            clinicalData.push(qnA);

            //hboc1_q019 Has anyone in the physician's family had genetic testing for hereditary cancer?
            if ($("#pfHistory2_geHerediraryCan").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q019",
                'answer': true,
                'subQuestion': [
                  {
                    'questionCode': "hboc1_q019_1",
                    'answer': $("#pfHistory2_geHerediraryCan_info").val(),
                  }
                ]
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q019",
                'answer': false,
                'subQuestion': [
                  {
                    'questionCode': "hboc1_q019_1",
                    'answer': '',
                  }
                ]
              }
            }
            clinicalData.push(qnA);

            //hboc1_q020 Other Clinical History        
            qnA = {
              'questionCode': "hboc1_q020",
              'answer': $("#pfHistory2_OtherClinicalHistory").val(),
            }
            clinicalData.push(qnA);

            //hboc1_q021 Allogeneic bone marrow or peripheral stem cell transplant
            if ($("#pfHistory2_PCurTreat_boneMar").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q021",
                'answer': true,
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q021",
                'answer': false,
              }
            }

            clinicalData.push(qnA);

            //hboc1_q022 Chemotherapy
            if ($("#pfHistory2_PCurTreat_Chemo").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q022",
                'answer': true,
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q022",
                'answer': false,
              }
            }

            clinicalData.push(qnA);

            //hboc1_q023 Radiation
            if ($("#pfHistory2_PCurTreat_Radia").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q023",
                'answer': true,
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q023",
                'answer': false,
              }
            }

            clinicalData.push(qnA);

            //hboc1_q024 Surgery
            if ($("#pfHistory2_PCurTreat_Surgery").is(":checked")) {
              qnA = {
                'questionCode': "hboc1_q024",
                'answer': true,
              }
            } else {
              qnA = {
                'questionCode': "hboc1_q024",
                'answer': false,
              }
            }

            clinicalData.push(qnA);

            //hboc1_q025 previous or current treatment          
            qnA = {
              'questionCode': "hboc1_q025",
              'answer': $("#pfHistory2_PCurTreat_SurgDe1").val(),
            }
            clinicalData.push(qnA);

            //hboc1_q026 previous or current treatment          
            // qnA = {
            //   'questionCode': "hboc1_q026",
            //   'answer': $("#pfHistory2_PCurTreat_SurgDe2").val(),
            // }
            // clinicalData.push(qnA);

            //clinicalData end

            //Kit Shipping Hboc
            let kitInformation_hboc = {
              drawType: [],
              otherInfo: "",
            };


            sections = $("div#KitShippingInfoHBOCForm2 #draw_type").find(":input");
            sections.map((input, i) => {
              const $input = $(sections[input]);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));

              let obj_id = $input.attr('id');

              const _type = $input.attr('type');
              // obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)

              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  if (obj_id == 'kitShipHBOC2_PatOthers') {
                    kitInformation_hboc.otherInfo = $("#kitShipHBOC2_PatOthersInfo").val();
                  }
                  else {
                    kitInformation_hboc.drawType.push($input.data('val'));
                  }
                }
              }
            });

            kitInformation_hboc.otherInfo = $(`#KitShippingInfoHBOCForm2 #kitShipHBOC2_PatOthersInfo`).val();

            //Kit Shipping OVA
            let kitInformation_ova1 = {
              drawType: [],
              otherInfo: "",
            };


            sections = $("div#KitShippingInfoOVAForm #draw_type").find(":input");
            sections.map((input, i) => {
              const $input = $(sections[input]);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));

              let obj_id = $input.attr('id');

              const _type = $input.attr('type');
              // obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)

              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  if (obj_id == 'kitShipOVA_PatOthers') {
                    kitInformation_ova1.otherInfo = $("#kitShipOVA_PatOthersInfo").val();
                  }
                  else {
                    kitInformation_ova1.drawType.push($input.data('val'));
                  }
                }
              }
            });

            kitInformation_ova1.otherInfo = $(`#KitShippingInfoOVAForm #kitShipOVA_PatOthersInfo`).val();

            let notes = $(`#NotesCertifyHBOC_OVAForm #kitShipHBOC_OVA_notes`).val();

            let certifytype = [];

            sections = $(`#NotesCertifyHBOC_OVAForm #${ova1GenetixHBOCPrefix}certifytype`).find(":input");
            sections.map((input, i) => {
              const $input = $(sections[input]);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));

              let obj_id = $input.attr('id');

              const _type = $input.attr('type');
              // obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)

              if (_type == 'checkbox') {
                if ($input.is(':checked')) {

                  certifytype.push($input.data('val'));

                }
              }
            });

            var tests = [];

            var tests_ova1 = {
              testName: "Ova1",
              testCode: "Ova1",
              icd10Code: icd10Code_ova1,
              kitInformation: kitInformation_ova1,
              notes: notes,
              ova1PlusBiomarkers: ova1PlusBiomarkers,
              clinicalData: clinicalData
            };
            var tests_hboc = {
              testName: "GenetixHBOC1",
              testCode: "GenetixHBOC1",
              icd10Code: icd10Code_hboc,
              kitInformation: kitInformation_hboc,
              notes: notes,
              clinicalData: [],
              genetixCSPanel: aSPiRAGENETIXCarrierScreening,

            };

            tests.push(tests_ova1);
            tests.push(tests_hboc);

            const covid_checked = $('#product_4').is(':checked');

            var covid_tests = {}
            let icd10CodesCOVD = [];
            if (covid_checked) {

              let icd10CodeCOVD = {
                'codes': [],
                'otherCodes': "",
              };

              sections = $("#DiagnosisCodes_ICD10CodesForm2 #Ova1_HBOC_covid_icd_codes2").find(":input");
              sections.map((i, input) => {
                const $input = $(input);
                console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));

                // let obj_id = $input.attr('id');

                const _type = $input.attr('type');
                // obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)

                if (_type == 'checkbox') {
                  if ($input.is(':checked')) {
                    icd10CodesCOVD.push($input.data('val'));
                  }
                }

              });

              icd10CodeCOVD = {
                'codes': icd10CodesCOVD,
                'otherCodes': "",
              }

              covid_tests = {
                testName: "COVD",
                testCode: "COVD",
                icd10Code: icd10CodeCOVD,
              };

              tests.push(covid_tests);
            }



            data = {
              orderId: Math.floor((Math.random() * 101) + 1),
              patientId: patient.patient._id.toString(),
              physicianId: physician.physician._id.toString(),
              billingInfo: billing_info,
              certifyTypes: certifytype,
              tests: tests,
            }


            $this.html("<img style='height: 30px;' src='/assets/images/Spinner-1s-200px.svg' /> Creating Order")

            let ret1 = await createOva1OrderApi(data);

            if (ret1.isok) {
              $this.html('Done');
              setToastMessage(`Order Created Successfully.`);
              showToast(`success`);
              // $this.prop("disabled", true);
            } //end if ret.isok

          } //end of patient.isok
        } //end of physician.isok
      }
    });

  })

  const onChangePhysicianStateLocal = (_state) => {
    setPhysicianState(_state);
  }

  const onChangeState = (_state) => {
    console.log("genetixHBOC->_state: ", _state);
    setPatientState(_state);
  }
  const patientDOBFunc = (_dob) => {
    setPatientDOBLocal(_dob);
  };

  const onChangeLastMentrualPeriodDateLocal = (_date) => {
    console.log("last menstrual period date: ", _date);
    setClinicalInfoLastMenstrualPeriodDate(_date);
  }
  return (
    <>
      <div class="body">
        <div class="row clearfix">
          <div class="col-md-12 col-lg-12">
            <input type="hidden" id={`${ova1GenetixHBOCPrefix}billinginfo_DOB`} class="form-control required" placeholder="DOB" />
            <div class="panel-group" id="accordion_10" role="tablist" aria-multiselectable="true">
              <Toast
                toastList={toastList}
                position={"top-center"}
                autoDelete={true}
                dismissTime={3000}
                description={toastMessage}
              />

              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingTwentySeven_10">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_10" href="#ASPGENHereditaryBreastOvarianCancerForm2" aria-expanded="false" aria-controls="collapseTwo_10">
                      ASPIRA GENETIX Hereditary Breast and Ovarian Cancer <span id="ASPGENHereditaryBreastOvarianCancerForm2CheckMark" className="d-none">&#10004;</span>
                    </a>
                  </h4>
                </div>
                <div id="ASPGENHereditaryBreastOvarianCancerForm2" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingTwentySeven_10">
                  <ASPGENHereditaryBreastOvarianCancerForm2 />
                </div>
              </div>
              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingTwentyEight_10">
                  <h4 class="panel-title">
                    <a role="button" data-toggle="collapse" data-parent="#accordion_10" href="#PhysicianInformationHBOCForm2" aria-expanded="true" aria-controls="collapseOne_10">
                      Physician Information <span id="PhysicianInformationHBOCForm2CheckMark" className="d-none">&#10004;</span>
                    </a>
                  </h4>
                </div>
                <div id="PhysicianInformationHBOCForm2" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingTwentyEight_10">
                  {/* <PhysicianInformationHBOCForm2/> */}
                  <PhysicianInformationForm prefix={`ova1GenetixHBOC_`} onChangePhysicianState={onChangePhysicianStateLocal} />
                </div>
              </div>
              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingTwentyNine_10">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_10" href="#PatientInformationHBOCForm2" aria-expanded="false" aria-controls="collapseTwo_10">
                      Patient Information <span id="PatientInformationHBOCForm2CheckMark" className="d-none">&#10004;</span>
                    </a>
                  </h4>
                </div>
                <div id="PatientInformationHBOCForm2" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwentyNine_10">
                  {/* <PatientInformationHBOCForm2 /> */}
                  <PatientInformationForm prefix={`${ova1GenetixHBOCPrefix}`} phoneNoPrefix={`${ova1GenetixHBOCPhoneNo_prefix}`} dobFunc={patientDOBFunc} onChangeState={onChangeState} />
                </div>
              </div>
              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingThirty_10">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_10" href="#BillingInformationHBOCForm2" aria-expanded="false" aria-controls="collapseTwo_10">
                      Billing Information <span id="BillingInformationHBOCForm2CheckMark" className="d-none">&#10004;</span>
                    </a>
                  </h4>
                </div>
                <div id="BillingInformationHBOCForm2" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingThirty_10">
                  {/* <BillingInformationHBOCForm2 /> */}
                  <BillingInformationForm prefix={`${ova1GenetixHBOCPrefix}`} />
                </div>
              </div>

              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingThirtyOne_10">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_10" href="#DiagnosisCodes_ICD10CodesForm2" aria-expanded="false" aria-controls="collapseTwo_10">
                      Diagnosis Codes | ICD-10 Codes <span id="DiagnosisCodes_ICD10CodesForm2CheckMark" className="d-none">&#10004;</span>
                    </a>
                  </h4>
                </div>
                <div id="DiagnosisCodes_ICD10CodesForm2" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingThirtyOne_10">
                  <DiagnosisCodes_ICD10CodesForm2 prefix={ova1GenetixHBOCPrefix} />
                </div>
              </div>
              {/* <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingThirtyTwo_10">
                  <h4 class="panel-title">
                      <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_10" href="#HBOCClicnicalInfoForm2" aria-expanded="false" aria-controls="collapseTwo_10"> 
                      HBOC Clicnical Info
                      </a> 
                  </h4>
                </div>
                <div id="HBOCClicnicalInfoForm2" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThirtyTwo_10">
                  <HBOCClicnicalInfoForm2/> 
                </div>                     
              </div> */}
              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingThirtyThree_10">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_10" href="#HBOCFamilyHistoryInfoForm2" aria-expanded="false" aria-controls="collapseTwo_10">
                      Personal/Family History -- Genetix OVA <span id="HBOCFamilyHistoryInfoForm2CheckMark" className="d-none">&#10004;</span>
                    </a>
                  </h4>
                </div>
                <div id="HBOCFamilyHistoryInfoForm2" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThirtyThree_10">
                  <HBOCFamilyHistoryInfoForm2 />
                </div>
              </div>

              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingThirtyFour_10">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_10" href="#ClinicalInformationOVAForm" aria-expanded="false" aria-controls="collapseTwo_10">
                      OVA Clinical Information <span id="ClinicalInformationOVAFormCheckMark" className="d-none">&#10004;</span>
                    </a>
                  </h4>
                </div>
                <div id="ClinicalInformationOVAForm" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThirtyFour_10">
                  {/* <ClinicalInformationOVAForm /> */}
                  <ClinicalInformationForm prefix={`${ova1GenetixHBOCPrefix}`} onChangeLastMentrualPeriodDate={onChangeLastMentrualPeriodDateLocal} />
                </div>
              </div>
              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingThirtyFve_10">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_10" href="#KitShippingInfoHBOCForm2" aria-expanded="false" aria-controls="collapseTwo_10">
                      HBOC kit Shipping Information <span id="KitShippingInfoHBOCForm2CheckMark" className="d-none">&#10004;</span>
                    </a>
                  </h4>
                </div>
                <div id="KitShippingInfoHBOCForm2" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThirtyFve_10">
                  <KitShippingInfoHBOCForm2 />
                </div>
              </div>

              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingThirtySix_10">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_10" href="#KitShippingInfoOVAForm" aria-expanded="false" aria-controls="collapseTwo_10">
                      OVA kit Shipping Information <span id="KitShippingInfoOVAFormCheckMark" className="d-none">&#10004;</span>
                    </a>
                  </h4>
                </div>
                <div id="KitShippingInfoOVAForm" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThirtySix_10">
                  <KitShippingInfoOVAForm />
                </div>
              </div>
              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingThirtySeven_10">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_10" href="#NotesCertifyHBOC_OVAForm" aria-expanded="false" aria-controls="collapseTwo_10">
                      Notes & Certify
                    </a>
                  </h4>
                </div>
                <div id="NotesCertifyHBOC_OVAForm" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThirtySeven_10">
                  <NotesCertifyHBOC_OVAForm prefix={ova1GenetixHBOCPrefix} />
                </div>
              </div>



            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Ova1GenetixHBOCForm;
