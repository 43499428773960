import React, { PureComponent } from "react";

import PropTypes from 'prop-types';
import $ from 'jquery';
import DatePicker from 'react-datepicker';
import PhoneNumberInput from './../portalComponents/phoneNumberInput';

export default class PatientInformationHBOCForm2 extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {

        }

    }

    onChangeHbocDob2 = date => this.setState({ patinfoHboc_dob2: date });

    render() {
        const otherEthnicityClick = () => {
            var patinfoHboc_Other2 = document.getElementById("patinfoHboc_Other2");
            if (patinfoHboc_Other2.checked == true) {
                $('#patinfo_Othercity2_div').removeClass(" hideFormContent");
                $('#patinfo_Othercity2_div').addClass(" showFormContent");
                $('#patinfo_Othercity2').addClass(" required")
            }
            else {
                $('#patinfo_Othercity2_div').addClass(" hideFormContent");
                $('#patinfo_Othercity2_div').removeClass(" showFormContent");
                $('#patinfo_Othercity2').removeClass(" required")
            }
        }

        return (
            <>
                <div class="panel-body">
                    <div class="row clearfix">
                        <div class="col-md-6">
                            <div class="form-group required">
                                <label class="form-label" for="ordered_before1">First Name</label>
                                <div class="form-line">
                                    <input type="text" id="patinfoHboc_firstname2" class="form-control required" placeholder="First Name" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group required">
                                <label class="form-label" for="ordered_before1">Last Name</label>
                                <div class="form-line">
                                    <input type="text" id="patinfoHboc_lastname2" class="form-control required" placeholder="Last Name" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="form-group required">
                                <label class="form-label" for="address">Address</label>
                                <div class="form-line">
                                    <input type="text" id="patinfoHboc_address2" class="form-control required" placeholder="Address" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for="city">City</label>
                                <div class="form-line">
                                    <input type="text" id="patinfoHboc_city2" class="form-control required" placeholder="City" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for="state">State</label>
                                <div class="form-line">
                                    <input type="text" id="patinfoHboc_state2" class="form-control required" placeholder="State" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for="zip">Zip Code</label>
                                <div class="form-line">
                                    <input type="text" id="patinfoHboc_zipcode2" class="form-control required" placeholder="Zip Code" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for="dob">DOB (mm/dd/yyyy)</label>
                                <div class="form-line">
                                    <DatePicker
                                        id={"patinfoHBOC_dob"}
                                        className={"form-control required"}
                                        selected={this.state.patinfoHboc_dob2}
                                        onChange={(date) => {
                                            this.onChangeHbocDob2(date);
                                        }}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />

                                </div>
                            </div>
                        </div>
                        {/* <div class="col-md-4">
                            <div class="form-group ">
                                <label class="form-label" for="SSN">SSN</label>
                                <div class="form-line">                                
                                    <input type="text" id="patinfoHboc_ssn2" class="form-control " placeholder="SSN"/>
                                </div>
                            </div>
                        </div> */}
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for="Sex">Sex</label>
                                <div class="form-line">
                                    <input type="text" id="patinfoHboc_sex2" class="form-control required" placeholder="Sex" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for="PhoneNumber">Phone Number</label>
                                <div class="form-line">
                                    <PhoneNumberInput />
                                    {/* <input type="text" id="patinfoHboc_PhoneNumber2" class="form-control required" placeholder="Phone Number"/> */}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">

                        <div class="col-md-12">
                            <div class="form-group required">
                                <label class="form-label" for="emailaddress">Email Address</label>
                                <div class="form-line">
                                    <input type="email" id="patinfoHboc_emailaddress2" class="form-control required" placeholder="Email Address" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="form-group required">
                                <label class="form-label" for="Ethnicity">Ethnicity (Check all that apply)</label>
                                <div class="demo-checkbox">
                                    <input type="checkbox" id="patinfoHboc_Caucasian2" name="patinfoHboc_Caucasian2" class="filled-in" />
                                    <label for="patinfoHboc_Caucasian2">Caucasian &nbsp; &nbsp; </label>
                                    <input type="checkbox" id="patinfoHboc_AshkenaziJewish2" name="patinfoHboc_AshkenaziJewish2" class="filled-in" />
                                    <label for="patinfoHboc_AshkenaziJewish2">Ashkenazi Jewish &nbsp; &nbsp; </label>
                                    <input type="checkbox" id="patinfoHboc_SephardicJewish2" name="patinfoHboc_SephardicJewish2" class="filled-in" />
                                    <label for="patinfoHboc_SephardicJewish2">Sephardic Jewish &nbsp; &nbsp; </label>
                                    <input type="checkbox" id="patinfoHboc_Asian2" name="patinfoHboc_Asian2" class="filled-in" />
                                    <label for="patinfoHboc_Asian2">Asian &nbsp; &nbsp; </label>
                                    <input type="checkbox" id="patinfoHboc_HIspanic2" name="patinfoHboc_HIspanic2" class="filled-in" />
                                    <label for="patinfoHboc_HIspanic2">Hispanic &nbsp; &nbsp; </label>
                                    <input type="checkbox" id="patinfoHboc_NativeAmerican2" name="patinfoHboc_NativeAmerican2" class="filled-in" />
                                    <label for="patinfoHboc_NativeAmerican2">Native American &nbsp; &nbsp; </label>
                                    <input type="checkbox" id="patinfoHboc_AfricanAmerican2" name="patinfoHboc_AfricanAmerican2" class="filled-in" />
                                    <label for="patinfoHboc_AfricanAmerican2">African American &nbsp; &nbsp; </label>
                                    <input type="checkbox" id="patinfoHboc_Other2" name="patinfoHboc_Other2" class="filled-in" onClick={() => {
                                        otherEthnicityClick()
                                    }} />
                                    <label for="patinfoHboc_Other2">Other: &nbsp; &nbsp; </label>
                                </div>
                                <div class="form-line hideFormContent" id="patinfo_Othercity2_div">
                                    <input type="text" id="patinfo_Othercity2" name="patinfo_Othercity" class="form-control" placeholder="Other" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="form-group required">
                                <label class="form-label" for="Indicationfortesting">Indication for testing</label>
                                <div class="form-line">
                                    <input type="email" id="patinfoHboc_IndicTesting2" class="form-control required" placeholder="Indication for testing" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12 text-right">
                            <button id="btnPatientInformationHBOCForm2Next" class="btn btn-light">Next</button>
                            <button style={{ display: 'none' }} id="btnBillingInfoHBOCForm2Next" data-toggle="collapse" data-target="#BillingInformationHBOCForm2" class="hide">Next</button>
                            <button style={{ display: 'none' }} id="btnPatientInfoHBOCForm2Next" data-toggle="collapse" data-target="#PatientInformationHBOCForm2" class="hide">Next</button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
