import React, { useState, useEffect } from "react";
//import dotenv from "dotenv";
import axios from "axios";
import { message } from 'antd';

// import jsPDF from "jspdf";
// import ReactDOMServer from "react-dom/server";
import { protectedResources } from "./../authConfig";
import { MsalContext } from "@azure/msal-react";

import DeleteModal from "../mycomponents/modal";
import ProgressBar from "../mycomponents/progressbar";

import DatePicker from 'react-datepicker';
import helper from "../utils/helper";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net-bs/css/dataTables.bootstrap.css";

import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"

import moment from "moment";
import Spinner from "../Spinner/Spinner";

const $ = require("jquery");
$.DataTable = require("datatables.net");
// require( 'datatables.net-bs' )( window, $ );

const jzip = require('jzip');
window.JSZip = jzip;

//dotenv.config();

const API_BASE_URL = helper.getAPIBaseURI();
let me = null;
export default class ResearchOrdersWithFilterContent extends React.PureComponent {
  static contextType = MsalContext;

  constructor(props) {
    super(props);

    this.state = {
      orders: [],
      loading: true,
      showLoader: false,

      showOrderModal: false,
      deleteOrderId: null,
      showProgressBar: false,

      startDate: "",
      endDate: "",

      toastMessage: "",
      list: [],
      toastList: [],
      reportType: "genetix"
    };

    this.orderDataTable = React.createRef();

    this.handleButtonPress = this.handleButtonPress.bind(this);
    this.handleButtonRelease = this.handleButtonRelease.bind(this);

    me = this;

    this.$el = null;

    this.completed = React.createRef();
    this.amended = React.createRef();
    this.cancelled = React.createRef();
  }
  showToast(type) {
    const id = Math.floor(Math.random() * 101 + 1);

    switch (type) {
      case "success":
        this.toastProperties = {
          id,
          title: "Success",
          description: "This is a success toast component",
          backgroundColor: "#5cb85c",
          icon: "/assets/assets/check.svg",
        };
        break;
      case "danger":
        this.toastProperties = {
          id,
          title: "Danger",
          description: "This is a error toast component",
          backgroundColor: "#d9534f",
          icon: "/assets/assets/error.svg",
        };
        break;
      case "info":
        this.toastProperties = {
          id,
          title: "Info",
          description: "This is an info toast component",
          backgroundColor: "#5bc0de",
          icon: "/assets/assets/info.svg",
        };
        break;
      case "warning":
        this.toastProperties = {
          id,
          title: "Warning",
          description: "This is a warning toast component",
          backgroundColor: "#f0ad4e",
          icon: "/assets/assets/warning.svg",
        };
        break;

      default:
        this.setState({ toastList: [] });
    }

    // this.setState([...this.state.list, this.toastProperties]);
    this.setState({ list: [this.toastProperties] });
  }
  handleButtonPress() {
    this.buttonPressTimer = setTimeout(
      () => this.setState({ showProgressBar: true }),
      1000
    );
  }

  handleButtonRelease() {
    clearTimeout(this.buttonPressTimer);
  }


  callDataTable(datatotables) {
    // first time, el will be null
    // see: https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs

    console.log("this.orderDataTable: ", this.orderDataTable);

    if (!this.orderDataTable) return;

    console.log("datatotables: ", datatotables);

    let $el = $(this.orderDataTable);
    //  console.log(this.$el.DataTable);
    $el.DataTable({
      dom: 'Bfrtip',
      buttons: [
        {
          extend: 'excel',
          text: 'Export to Excel',
          fileName: "orders.xlsx",
          exportOptions: {
            columns: ':visible'
          }
        }
      ],
      pageLength: 25,
      order: [],
      data: datatotables,
      columns: [
        {
          title: "DOB",
          data: "Patient.dob",
          name: "DOB",
          sortable: false,
          visible: false,
          searchable: true,
          exportable: false,
        },
        {
          title: "QBench Order ID",
          data: "orderId",
          name: "orderId",
          width: "10px",
          sortable: true,
        },
        // { title: "Patient ID", data: "Patient._id", name: "patientId" },
        {
          title: "Patient First Name",
          data: "Patient.firstName",
          name: "firstName",
          width: "15px",
        },
        {
          title: "Patient Last Name",
          data: "Patient.lastName",
          name: "lastName",
          width: "15px",
        },
        {
          title: "Test Ordered",
          data: null,
          name: "orderedTest",
          width: "150px",
          render: (data, type) => {
            let testName = "";

            // if(data.tests.length===1) {
            //     testName=data.tests[0].testName;
            // }
            // else if(data.tests.)

            data.tests.map((item, i) => {
              if (typeof item.testName != "undefined") {
                //   if (testName == "") {
                //     testName += item.testName;
                //   } else {
                //     testName += "+" + item.testName;
                //   }

                testName = item.testName == "GenetixHBOC1" || item.testName == 'GenetiXCarrierScreening' ? item.genetixCSPanel[0]
                  : item.testName
              }

            });

            //testName = testName.substring(0, testName.length - 1);
            if (type === "display") {
              return `<td>
                                <span class="">${testName}</td>
                            </td> `;
            }
            return data;
          },
        },
        {
          title: "Client Id",
          data: "legacyClientId",
          name: "legacyClientId",
          width: "50px",
          sortable: true,
        },
        {
          title: "Benefits Reviewer",
          data: "billingAssignedName",
          name: "billingAssignedName",
          width: "100px",
          sortable: true,
        },
        {
          title: "Status",
          data: null,
          name: "status",
          width: "7vw",
          render: function (data, type) {
            if (type === "display") {
              if (
                data.status === "Approved" ||
                data.status == "Order Authorized"
              ) {
                return `<td>
                                <span class="status_font label label-success">${data.status}</td>
                            </td> `;
              } else if (
                data.status === "Pending" ||
                data.status == "WAITING BILLING"
              ) {
                return `<td>
                                <span class="status_font label label-default">${data.status}</span>
                            </td> `;
              } else if (data.status === "Denied") {
                return `<td>
                                <span class="status_font label label-danger">${data.status}</td>
                            </td> `;
              } else {
                return `<td>
                                <span class="status_font label">${data.status}</td>
                            </td> `;
              }
            }
            return data;
          },
        },
        {
          title: "Baylor Status",
          data: "baylorStatus",
          name: "baylorStatus",
          width: "50px",
        },
        {
          title: "Report Status",
          data: "reportStatus",
          name: "reportStatus",
          width: "50px",
        },
        {
          title: "Xifin Status",
          data: "xifinStatus",
          name: "xifinStatus",
          width: "50px",
        },
        {
          title: "Created At",
          data: "createdDateOnly",
          name: "createdDateOnly",
          width: "50px",
        },
        {
          title: "",
          name: "",
          data: null,
          sortable: false,
          render: function (data, type) {
            if (type === "display") {
              console.log("pdfclass->data: ", data);

              let lockIcon = `<img src="/icons/icons8-lock-50.svg" />`;
              if (data.isLocked) {
                lockIcon = `<img src="/icons/icons8-padlock-50.svg" />`;
              }

              let testName_page = "";

              data.tests.map((item, i) => {
                if (typeof item.testName != "undefined") {
                  if (testName_page == "") {
                    testName_page += item.testName;
                  } else {
                    if (item.testName != "COVD")
                      testName_page += "_" + item.testName;
                  }
                }
              });

              return `<td>
                                    <a class="rowOption pdfClass" data-orderid="${data.orderId}" data-id="${data._id}" data-val="${testName_page}"><img style="width: 40; height: 40;" src="/icons/PDF_file_icon.svg" /></a>
                                    <a class="rowOption editClass" data-id="${data._id}" data-val="${testName_page}"><img src="/icons/icons8-edit-50.svg" /></a>
                                    <a data-id="${data._id}" id="option-delete" class="rowOption deleteClass"><img src="/icons/icons8-delete-trash-50.svg" /></a>
                                    <a id="option-lock" class="rowOption lockClass">${lockIcon}</a>
                                </td> `;
            }
            return data;
          },
        },

      ],
    });

    $el.on("click", "tr a.pdfClass",async function () {
      let item_id = $(this).data("id");
      let custom_formatted_id = $(this).data("orderid");
      let item_prefix = $(this).data("val");
      let editHref = "";

      // const accessToken = await helper.getAccessToken(me.context);
      // helper.printPDF(item_id, custom_formatted_id, accessToken);

      const accessToken = await helper.getAccessToken(me.context);
      let orderData = await helper.getOrderFromServerById(item_id, accessToken);
      let exactHtmlUrl = "";
      if (process.env.REACT_APP_ENV === 'DEV') {
        exactHtmlUrl = await helper.getTRFHtmlUrl(orderData);
      }
      else {
        exactHtmlUrl = await helper.getTRFHtmlUrl(orderData);
      }

      if (exactHtmlUrl) {
        window.open(exactHtmlUrl);
        // let htmlRawData = await helper.getTRFHtmlFromUrl(exactHtmlUrl, accessToken);

        // let element = htmlRawData;
        // const doc = new jsPDF("p", "pt", "letter");
        // doc.html(ReactDOMServer.renderToString(element), {
        //   callback: function (doc) {
        //     doc.save(`trf-${custom_formatted_id}.pdf`);
        //   }
        // });
      }
    });
    $el.on("click", "tr a.deleteClass", function () {
      let item_id = $(this).data("id");

      let yes = false;
      me.setState({
        showOrderModal: true,
        deleteOrderId: item_id,
      });
    });

    $el.on("click", "tr a.editClass", function () {
      let item_id = $(this).data("id");
      let item_prefix = $(this).data("val");
      let editHref = "";

      if (item_prefix == "Ova1") editHref = `/editorder_ova1?id=${item_id}&returnurl=${window.location.href}`;
      else editHref = `/editorder_${item_prefix}?id=${item_id}&returnurl=${window.location.href}`;

      console.log("editHref: ", editHref);
      // console.log("me.props: ", me.props);
      // me.props.history.push(editHref);
      // return <Redirect to={editHref} />

      // me.context.router.history.push(editHref);

      window.location.href = editHref;
    });
  }


  componentDidMount() {
    this.setState({
      loading: false,
    }, async () => {

    });
  }

  showModal = (e) => {
    this.setState({
      showOrderModal: !this.state.showOrderModal,
    });
  };


  async handleSearch() {

    const _reportType = this.state.reportType;
    console.log("1. _reportType: ", _reportType);

    const start_date = moment(this.state.startDate).format("MM/DD/YYYY");
    const end_date = moment(this.state.endDate).format("MM/DD/YYYY");

    if (start_date.length < 1 || start_date == 'Invalid date') {
      message.warn("Start date is required");
      return;
    }

    this.setState({
      loading: true,
    }, async () => {
      const accessToken = await helper.getAccessToken(this.context)

      let _ordertype = "research_orders_filter";
      let _url = `${API_BASE_URL}/orders/researchorders/filter?startDate=${start_date}`;

      if (end_date.length > 0 && end_date != 'Invalid date') {
        _url = `${_url}&endDate=${end_date}`;
      }

      _url = `${_url}&reportType=${_reportType}&type=${_ordertype}`;

      document.body.classList.add('busy-cursor');

      axios({
        url: _url,
        method: "GET",
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }).then(({ data }) => {
        console.log("data: ", data);

        this.setState({ loading: false }, () => {

          console.log("_reportType: ", _reportType);
          console.log("this.state.reportType: ", this.state.reportType);


          this.callDataTable(data.orders);

          document.body.classList.remove('busy-cursor');
        });
      })
        .catch((error) => {
          this.setState({ loading: false }, () => {

            message.info(`No Orders found.`);

            document.body.classList.remove('busy-cursor');
          });

        })

    })
  }

  onChangeStartDate1(_date) {
    this.setState({ startDate: _date });
  }
  onChangeEndDate2(_date) {
    this.setState({ endDate: _date });
  }
  render() {
    if (!this.state.loading) {
      return (
        <div>
          <div class="block-header">
            <h2>{this.state._pageTitle}</h2>
            {/* <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/orders">Orders</a>
              </li>
              <li class="breadcrumb-item active">View All Orders</li>
            </ul> */}
          </div>
          <div class="row clearfix">
            <div class="card orders-list">
              <div class="body">
                <div class="col-md-12 col-xs-12">
                  <div class="col-md-4 col-xs-4">
                    <div class="form-group required">
                      <label class="form-label required" for="baylor">
                        Start Date
                      </label>
                      <div class="form-line">
                        <DatePicker
                          placeholderText={`MM/DD/YYYY`}
                          id={`inpStartDate1`}
                          className={"form-control required"}
                          selected={this.state.startDate}
                          onChange={date => this.onChangeStartDate1(date)}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="col-md-3 col-xs-3">
                    <div class="form-group required">
                      <label class="form-label" for="baylor">
                        End Date
                      </label>
                      <div class="form-line">

                        <DatePicker
                          placeholderText={`MM/DD/YYYY`}
                          id={`inpEndDate2`}
                          className={"form-control required"}
                          selected={this.state.endDate}
                          onChange={date => this.onChangeEndDate2(date)}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix">
                    <div class="col-md-12">
                    </div>
                  </div>
                  {/* <div class="row clearfix">
                    <div class="col-md-12">
                      <div class="form-group required">
                        <label class="form-label" for="address">Report Type:</label>
                        <div class="demo-radio-button">
                          <div className="col-md-3">
                            <input ref={(_ref) => this.completed = _ref} checked={this.state.reportType == 'Completed'} type="radio" id={`radio_completed`} name={`radio_report_type`} value={`Completed`} onChange={this.handleChangeReportType.bind(this)} class="filled-in" />
                            <label for={`radio_completed`}>Completed &nbsp; </label>
                          </div>
                          <div className="col-md-3">
                            <input ref={(_ref) => this.amended = _ref} checked={this.state.reportType == 'Amended'} type="radio" id={`radio_amended`} name={`radio_report_type`} value={'Amended'} onChange={this.handleChangeReportType.bind(this)} class="filled-in" />
                            <label for={`radio_amended`}>Amended &nbsp; </label>
                          </div>
                          <div className="col-md-3">
                            <input ref={(_ref) => this.cancelled = _ref} checked={this.state.reportType == 'Cancelled'} type="radio" id={`radio_cancelled`} name={`radio_report_type`} value={`Cancelled`} onChange={this.handleChangeReportType.bind(this)} class="filled-in" />
                            <label for={`radio_cancelled`}>Cancelled &nbsp; </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div > */}
                  <div id="ignoreMeInPDF" class="row clearfix">
                    <div class="col-md-4">
                      <div class="form-group">
                        <button
                          id={`btnSearchSubmit`}
                          onClick={this.handleSearch.bind(this)}
                          placeholder="Submit"
                          name="Submit"
                          className="btn btn-primary text-white"
                        >
                          {`Retrieve Research Orders`}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div class="row clearfix">
            <div class="col-md-12 col-xs-12">
              <div class="card orders-list">
                <div class="body">
                  <div class="table-responsive">
                    <table
                      className="display"
                      width="100%"
                      ref={(el) => (this.orderDataTable = el)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            this.state.showLoader && <div>
              <Spinner />
            </div>
          }
          <DeleteModal
            onClose={this.showModal}
            show={this.state.showOrderModal}
          >
            <div>
              <span>
                Hold delete button for 3 seconds in order to confirm delete
                Patient {this.state.deletePatientName}
              </span>
            </div>
            <div class="delete-buttons">
              <button
                class="btn waves-effect btn-delete"
                onTouchStart={this.handleButtonPress}
                onTouchEnd={this.handleButtonRelease}
                onMouseDown={this.handleButtonPress}
                onMouseUp={this.handleButtonRelease}
                onMouseLeave={this.handleButtonRelease}
              // onClick={this.deletePatient}
              >
                Delete
              </button>
              <button
                class="btn btn-raised g-bg-blue waves-effect"
                onClick={this.showModal}
              >
                Cancel
              </button>
            </div>
            <div>
              {this.state.showProgressBar && (
                <ProgressBar entity={"order"} id={this.state.deleteOrderId} />
              )}
            </div>
          </DeleteModal>
        </div>
      );
    } else {
      return (
        <div>
          <div class="block-header">
            <h2>Orders</h2>
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/orders">Orders</a>
              </li>
              <li class="breadcrumb-item active">View All Orders</li>
            </ul>
          </div>
          <div class="row clearfix">
            <div class="col-md-12 col-xs-12">
              <div class="card orders-list">
                <div class="body">
                  <Spinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
