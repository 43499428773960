/* eslint-disable react/jsx-pascal-case */
import React, { createRef, useState } from "react";

import axios from "axios";
import Select from "react-select";
import $ from "jquery";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from "moment";
import { message, Button } from "antd";

import Toast from "../mycomponents/toast/Toast";
import helper from "./../utils/helper";

import CmtForm from "./CmtForm";
import BillingNotes from "./BillingNotes";
import AsyncSelect from "react-select/async";

import PhysicianInformation from "./add_edit_order_components/PhysicianInformation";
import PatientInformation from "./add_edit_order_components/PatientInformation";
import Billing_Information from "./add_edit_order_components/Billing_Information";
import Notes_and_Certify from "./add_edit_order_components/Notes_and_Certify";
import Kit_Shipping_Information from "./add_edit_order_components/Kit_Shipping_Information";
import { protectedResources } from "./../authConfig";
import { MsalContext } from "@azure/msal-react";
import Retrive_Reprot from "./add_edit_order_components/RetriveReport";
import TopActions from "./add_edit_order_components/TopActions";

const API_BASE_URL = helper.getAPIBaseURI();

let me = null;
let reportId = "";

export default class AddEditOrderGenetiXCarrierScreening extends React.PureComponent {
  static contextType = MsalContext;

  constructor(props) {
    super(props);

    this.state = {
      notesText: "",
      loading: true,
      buttonText: "Create",
      frmprefix: "GTCS_",

      dobs: [],
      dateFields: {},
      customDiv: ["div1"],
      len: 1,

      toastMessage: "",
      list: [],
      toastList: [],
      orderData: [],

      IndicationsTest_otherValue: "",
      duedate_pregnant: "",
      ultrasound_findings: "",
      //Indication for testing
      pat_screening_test: false,
      pat_pregnancy: false,
      pat_infertality: false,
      pat_partner_screening: false,
      family_history: false,
      egg_sperm_donner: false,
      pat_info_test_other: false,

      //physician info
      phyinfo_Organization_account: "",
      phyinfo_first_name: "",
      phyinfo_last_name: "",
      phyinfo_email: "",
      phyinfo_npi: "",
      phyinfoC_address: "",
      phyinfoC_practiceLocation: "",
      phyinfoC_city: "",
      phyinfo_state: "",
      phyinfo_state_save_value: "",
      phyinfoC_zipCode: "",
      phyinfoC_Phone: "",
      phyinfoC_fax: "",
      phyinfoC_Faxcopyto: "",

      //patient info
      patinfo_firstname: "",
      patinfo_lastname: "",
      patinfo_address: "",
      selectedState: "ALABAMA",
      patinfo_State: "",
      patinfo_State_Save_value: "",
      patinfo_city: "",
      patinfo_zipcode: "",
      patinfo_dob: "",
      patinfo_PhoneNumber: "",
      patinfo_emailaddress: "",
      patinfo_OtherEthnicitycity: "",
      patinfo_ethnicity: "",
      ethnicityArray: {},
      patinfo_gender: "",

      //billing info
      selectedPayor: {},
      allPayors: [],
      billinfo_PrimaryInsuranceCarrier: "",
      billinfo_planId: "",
      billinfo_MemberID: "",
      billinfo_GroupID: "",

      billinfo_SecondaryInsuranceCarrier: "",
      billinfo_SecondaryplanId: "",
      billinfo_SecondaryMemberID: "",
      billinfo_SecondaryGroupID: "",

      billinfo_TertiaryInsuranceCarrier: "",
      billinfo_TertiaryMemberID: "",
      billinfo_TertiaryGroupID: "",
      billinfo_TertiaryPlanID: "",

      billinfo_FirstNameofInsured: "",
      billinfo_LastNameofInsured: "",
      billinfoC_dob: "",
      billinfo_RelationshipInsured: "",

      OtherICD10Codes: [],
      otherICD10Code: '',
      kitShip_notes: "",
      kitShip_PatMobPhlebotomy: null,
      kitShip_PatblooddrawQuest: null,
      kitShip_PatOthers: null,
      kitShip_PatOthersInfo: "",


      //edit billing portion
      privateInsurance: false,
      patientSelfPay: false,
      medicare: false,
      medicaid: false,
      orderingFacility: false,
      freeTrial: false,
      isBillableOrder: false,

      //family histry
      familyHistoryForm_dob: "",

      accessToken: "",

      reportDate: "",
      updatedAt: Date.now(),
    };

    this.IndicationsTest_otherValue = React.createRef();
    this.duedate_pregnant = React.createRef();
    this.ultrasound_findings = React.createRef();

    //physician
    this.phyinfo_Organization_account = React.createRef();
    this.phyinfo_first_name = React.createRef();
    this.phyinfo_last_name = React.createRef();
    this.phyinfo_email = React.createRef();
    this.phyinfo_npi = React.createRef();
    this.phyinfoC_address = React.createRef();
    this.phyinfoC_practiceLocation = React.createRef();
    this.phyinfoC_city = React.createRef();
    this.phyinfo_state = React.createRef();
    this.phyinfoC_zipCode = React.createRef();
    this.phyinfoC_Phone = React.createRef();
    this.phyinfoC_fax = React.createRef();
    this.phyinfoC_Faxcopyto = React.createRef();

    //patient info
    this.patinfo_firstname = React.createRef();
    this.patinfo_lastname = React.createRef();
    this.patinfo_address = React.createRef();
    this.patinfo_State = React.createRef();
    this.patinfo_city = React.createRef();
    this.patinfo_zipcode = React.createRef();
    this.patinfo_dob = React.createRef();
    this.patinfo_PhoneNumber = React.createRef();
    this.patinfo_emailaddress = React.createRef();
    this.patinfo_OtherEthnicitycity = React.createRef();
    this.patinfo_ethnicity = React.createRef();
    this.patinfo_gender = React.createRef();

    //billing info
    this.billinfo_PrimaryInsuranceCarrier = React.createRef();
    this.billinfo_planId = React.createRef();
    this.billinfo_MemberID = React.createRef();
    this.billinfo_GroupID = React.createRef();

    this.billinfo_SecondaryInsuranceCarrier = React.createRef();
    this.billinfo_SecondaryplanId = React.createRef();
    this.billinfo_SecondaryMemberID = React.createRef();
    this.billinfo_SecondaryGroupID = React.createRef();

    this.billinfo_TertiaryInsuranceCarrier = React.createRef();
    this.billinfo_TertiaryMemberID = React.createRef();
    this.billinfo_TertiaryGroupID = React.createRef();
    this.billinfo_TertiaryPlanID = React.createRef();

    this.billinfo_FirstNameofInsured = React.createRef();
    this.billinfo_LastNameofInsured = React.createRef();

    this.billinfoC_dob = React.createRef();
    this.billinfo_RelationshipInsured = React.createRef();
    this.OtherICD10Codes = React.createRef();
    this.kitShip_PatOthersInfo = React.createRef();
    this.kitShip_notes = React.createRef();

    this.OtherICD10Codes = React.createRef();
    this.kitShip_PatOthersInfo = React.createRef();
    this.kitShip_notes = React.createRef();

    this.orderId = null;
    this._id = null;
    me = this;
  }

  // addNewRow() {
  //   let cDivs = this.state.customDiv;
  //   cDivs.push('newDiv')
  //   console.log("cDivs: ", cDivs)
  //   this.setState({ customDiv: cDivs, len: cDivs.length })
  // }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log("user.token: ", user.token);

    axios.interceptors.response.use(
      function (response) {
        // Do something with response data
        return response;
      },
      function (error) {
        if (error && error.response) {
          switch (error.response.status) {
            case 409:
              console.log("interceptors->error: ", error);

              me.setState(
                {
                  toastMessage: `An Order with the same already exists`,
                  loading: false,
                },
                () => {
                  me.danger_failed.click();
                }
              );
              break;

            default:
              break;
          }
          // Do something with response error
          return Promise.reject(error);
        }
      }
    );

    this.setState(
      {
        loading: true,
        user: user,
      },
      async () => {
        console.log(window.location.search);
        const params = new URLSearchParams(window.location.search);
        this._id = params.get("id");

        console.log("this._id: ", this._id);

        if (this._id) {
          //Edit Mode

          const accessToken = await helper.getAccessToken(me.context);
          this.setState({ accessToken });

          console.log("accessToken: ", accessToken);
          let response = await axios({
            url: `${API_BASE_URL}/orders/${this._id}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-type": "Application/json",
            },
          });

          response.data = response.data.orders[0];
          console.log("response.data: ", response.data);
          let orderObj = response.data;
          //get the bylor report id

          reportId = response.data["baylorReportId"];
          console.log("response.data['baylorReportCompletionDate']: ", response.data["baylorReportCompletionDate"]);
          let reportDate = moment.utc(response.data["baylorReportCompletionDate"], "YYYY-MM-DD HH:mm:ss").isValid()
            ? moment.utc(response.data["baylorReportCompletionDate"], "YYYY-MM-DD HH:mm:ss").toISOString() : undefined;


          console.log("reportDate: ", reportDate);
          console.log("reprotId" + reportId + "--------------------------report Id------------");
          console.log(response.data);
          //assays data GENETIX_CS
          let _assays_gtcs = response.data.assays.filter(
            (x) => x.assayType.indexOf("GENETIX_CS") > -1
          );
          console.log("_assays_gtcs: ", _assays_gtcs);
          let _assays_gtcscontent = ``;
          _assays_gtcs.map((a, i) => {
            _assays_gtcscontent += `<div class="col-md-6">`;
            _assays_gtcscontent += `<input data-qbenchid="${a.qBenchId}" data-val="${a.name}" type="radio" name="gtcs_assay_" id="gtcs_assay_${i}" class="form-control" />
                    <label for="gtcs_assay_${i}">${a.name}  &nbsp; </label></div>`;
          });

          // check whether its an order that is billable i.e. not flagged as RUO - Research Use Only
          const isBillableOrder =
            response.data.researchUseOnly === "Yes" ? false : true;

          //assays data GENETIX_CS

          let patinfo_dobFormatted = "";
          let billinfoC_dobFormatted = "";
          let duedate_pregnantFormatted = "";

          if (response.data.Patient.dob) {
            patinfo_dobFormatted = helper.formatDate(response.data.Patient.dob);
          }
          if (
            response.data.billingInfo.insuredInfo &&
            response.data.billingInfo.insuredInfo.dob &&
            response.data.billingInfo.insuredInfo.dob
          ) {
            billinfoC_dobFormatted = helper.formatDate(
              response.data.billingInfo.insuredInfo.dob
            );
          }
          if (response.data.tests[0].dueDate) {
            duedate_pregnantFormatted = helper.formatDate(
              response.data.tests[0].dueDate
            );
          }

          console.log(
            "patinfo_dobFormatted: ",
            patinfo_dobFormatted,
            "billinfoC_dobFormatted: ",
            billinfoC_dobFormatted
          );

          // load payor list
          let _url = `${API_BASE_URL}/payors`;
          let _options = {
            url: _url,
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          };

          let axiosResponse = await axios(_options);
          let assignedPayor = {};
          let payorInfo = {};
          const allPayors = axiosResponse.data.payors.map((payor) => {
            let { payorId, payorName, payorContactAddress } = payor;
            payorInfo = {
              label: `${payorId}, ${payorName}, ${payorContactAddress}`,
              value: payorId,
            };

            if (orderObj.payorId && orderObj.payorId === payor.payorId)
              assignedPayor = payorInfo;

            return payorInfo;
          });

          console.log("\n===");
          console.log("orderObj: ", orderObj);
          console.log("===");
          console.log("assignedPayor: ", assignedPayor);
          console.log("===");
          console.log("allPayors: ", allPayors);
          console.log("===\n");

          me.setState(
            {
              reportDate,
              patinfo_dob: patinfo_dobFormatted,
              billinfoC_dob: billinfoC_dobFormatted,
              duedate_pregnant: duedate_pregnantFormatted,
              orderData: response.data,
              frmprefix: response.data.tests[0].testName + "_",
              _assays_gtcscontent: _assays_gtcscontent,
              _assays_gtcs: _assays_gtcs,
              isBillableOrder,
              allPayors,
              selectedPayor: assignedPayor,
              loading: false,
            },
            () => {
              //assays data GENETIX_CS print
              $("#printHere_CS").html(this.state._assays_gtcscontent);

              me.orderId = response.data.orderId;
              this.patientId = response.data.Patient._id;
              this.physicianId = response.data.Physician._id;

              /* me.IndicationsTest_otherValue.value =
                response.data.tests[0].genetixCSIndicationOfTesting.other; */
              /* me.ultrasound_findings.value =
                response.data.tests[0].ultrasoundsFindings; */
              //Physician
              console.log(
                "me.phyinfo_Organization_account: ",
                me.phyinfo_Organization_account
              );

              // me.phyinfo_Organization_account.value = `${response.data.legacyName} / ${response.data.legacyClientId}`
              // // response.data.Physician.organizationAccount.length < 1
              // //   ? `${response.data.legacyName} / ${response.data.legacyClientId}`
              // //   : response.data.Physician.organizationAccount;

              // me.phyinfo_first_name.value = response.data.Physician.firstName
              // me.phyinfo_last_name.value = response.data.Physician.lastName
              // me.phyinfo_email.value = response.data.Physician.email
              // me.phyinfo_npi.value = response.data.Physician.npiNumber
              // me.phyinfoC_address.value = response.data.Physician.addresses[0].address_1
              // me.phyinfoC_practiceLocation.value = response.data.Physician.practiceLocationPlain
              // me.phyinfoC_city.value = response.data.Physician.addresses[0].city
              // me.phyinfo_state.value = response.data.Physician.addresses[0].state
              // me.phyinfoC_zipCode.value = response.data.Physician.addresses[0].zipCode
              // me.phyinfoC_Phone.value = response.data.Physician.addresses[0].phoneNo
              // me.phyinfoC_fax.value =
              //   typeof response.data.Physician.addresses[0].fax !== 'undefined' && response.data.Physician.addresses[0].fax
              //     ? response.data.Physician.addresses[0].fax
              //     : ''
              // me.phyinfoC_Faxcopyto.value =
              //   typeof response.data.Physician.addresses[0].faxCopyTo !== 'undefined' && response.data.Physician.addresses[0].faxCopyTo
              //     ? response.data.Physician.addresses[0].faxCopyTo
              //     : ''

              //Patient Information
              //ethnicity array
              const ethnicityArray1 = response.data.Patient.ethnicity
                ? response.data.Patient.ethnicity.split(",")
                : [];
              /*me.patinfo_firstname.value = response.data.Patient.firstName
              me.patinfo_lastname.value = response.data.Patient.lastName
              me.patinfo_address.value = response.data.Patient.address.address_1
              me.patinfo_State.value = response.data.Patient.address.state
              me.patinfo_city.value = response.data.Patient.address.city
              me.patinfo_zipcode.value = response.data.Patient.address.zipCode
              me.patinfo_PhoneNumber.value = response.data.Patient.phoneNo
              me.patinfo_emailaddress.value = response.data.Patient.email
              me.patinfo_OtherEthnicitycity.value = ''*/
              //Patient information ethnicity
              if (
                response.data.Patient.ethnicity &&
                response.data.Patient.ethnicity.includes("Caucasian")
              ) {
                this.setState({ patinfo_ethnicity: "Caucasian" });
              }
              if (
                response.data.Patient.ethnicity &&
                response.data.Patient.ethnicity.includes("Ashkenazi Jewish")
              ) {
                this.setState({ patinfo_ethnicity: "Ashkenazi Jewish" });
              }
              if (
                response.data.Patient.ethnicity &&
                response.data.Patient.ethnicity.includes("Sephardic Jewish")
              ) {
                this.setState({ patinfo_ethnicity: "Sephardic Jewish" });
              }
              if (
                response.data.Patient.ethnicity &&
                response.data.Patient.ethnicity.includes("Asian")
              ) {
                this.setState({ patinfo_ethnicity: "Asian" });
              }
              if (
                response.data.Patient.ethnicity &&
                response.data.Patient.ethnicity.includes("Hispanic")
              ) {
                this.setState({ patinfo_ethnicity: "Hispanic" });
              }
              if (
                response.data.Patient.ethnicity &&
                response.data.Patient.ethnicity.includes("Native American")
              ) {
                this.setState({ patinfo_ethnicity: "Native American" });
              }
              if (
                response.data.Patient.ethnicity &&
                response.data.Patient.ethnicity.includes("African American")
              ) {
                this.setState({ patinfo_ethnicity: "African American" });
              }
              if (
                response.data.Patient.ethnicity &&
                response.data.Patient.ethnicity.includes("Other")
              ) {
                this.setState({ patinfo_ethnicity: "Other" });

                /* this.setState({
                  patinfo_OtherEthnicitycity:
                  response.data.Patient.ethnicity,
                }); */
              }

              //Patient Gender
              //console.log("gender: ",response.data.Patient.gender);
              if (response.data.Patient.gender === "male") {
                this.setState({
                  patinfo_gender: "male",
                });
              } else {
                this.setState({
                  patinfo_gender: "female",
                });
              }

              //billing information
              // if (response.data.billingInfo.primaryInsurance && this.state.isBillableOrder) {
              //   me.billinfo_PrimaryInsuranceCarrier.value = response.data.billingInfo.primaryInsurance.primaryInsuranceCarrier
              //   // me.billinfo_planId.value =
              //   //   response.data.billingInfo.primaryInsurance.planId;
              //   me.billinfo_MemberID.value = response.data.billingInfo.primaryInsurance.memberID
              //   me.billinfo_GroupID.value = response.data.billingInfo.primaryInsurance.groupID

              //   me.billinfo_SecondaryInsuranceCarrier.value = response.data.billingInfo.secondaryInsurance.secondaryInsuranceCarrier
              //   // me.billinfo_SecondaryplanId.value =
              //   //   response.data.billingInfo.secondaryInsurance.secondaryplanId;
              //   me.billinfo_SecondaryMemberID.value = response.data.billingInfo.secondaryInsurance.secondaryMemberID
              //   me.billinfo_SecondaryGroupID.value = response.data.billingInfo.secondaryInsurance.secondaryGroupID

              //   me.billinfo_TertiaryInsuranceCarrier.value = response.data.billingInfo?.tertiaryInsurance?.tertiaryInsuranceCarrier
              //     ? response.data.billingInfo?.tertiaryInsurance?.tertiaryInsuranceCarrier
              //     : ''
              //   me.billinfo_TertiaryMemberID.value = response.data.billingInfo?.tertiaryInsurance?.tertiaryMemberID
              //     ? response.data.billingInfo?.tertiaryInsurance?.tertiaryMemberID
              //     : ''
              //   me.billinfo_TertiaryGroupID.value = response.data.billingInfo?.tertiaryInsurance?.tertiaryGroupID
              //     ? response.data.billingInfo?.tertiaryInsurance?.tertiaryGroupID
              //     : ''

              //   me.billinfo_FirstNameofInsured.value = response.data.billingInfo.insuredInfo.nameOfInsured.firstName
              //   me.billinfo_LastNameofInsured.value = response.data.billingInfo.insuredInfo.nameOfInsured.lastName
              //   //me.billinfoC_dob.value = response.data.billingInfo.insuredInfo.GTCS_billinfoC_dob;
              //   me.billinfo_RelationshipInsured.value = response.data.billingInfo.insuredInfo.relationshipInsured
              // }
              /*me.OtherICD10Codes.value = response.data.tests[0].icd10Code.otherCodes
              me.kitShip_PatOthersInfo.value = response.data.tests[0].kitInformation.otherInfo
              me.kitShip_notes.value = response.data.tests[0].notes */

              me.setState(
                {
                  buttonText: "Update",
                  IndicationsTest_otherValue:
                    response.data.tests[0].genetixCSIndicationOfTesting.other,
                  duedate_pregnant: duedate_pregnantFormatted,
                  ultrasound_findings:
                    response.data.tests[0].ultrasoundsFindings,

                  phyinfo_Organization_account:
                    response.data.Physician.organizationAccount.length < 1
                      ? `${response.data.legacyName} / ${response.data.legacyClientId}`
                      : response.data.Physician.organizationAccount,
                  phyinfo_first_name: response.data.Physician.firstName,
                  phyinfo_last_name: response.data.Physician.lastName,
                  phyinfo_email: response.data.Physician.email,
                  phyinfo_npi: response.data.Physician.npiNumber,
                  phyinfoC_address:
                    response.data.Physician.addresses[0].address_1,
                  phyinfoC_practiceLocation:
                    response.data.Physician.practiceLocationPlain,
                  phyinfoC_city: response.data.Physician.addresses[0].city,
                  phyinfo_state_save_value:
                    response.data.Physician.addresses[0].state,
                  phyinfo_state: {
                    label: response.data.Physician.addresses[0].state,
                    value: response.data.Physician.addresses[0].state,
                  },
                  patinfo_State_Save_value: response.data.Patient.address.state,
                  phyinfoC_zipCode:
                    response.data.Physician.addresses[0].zipCode,
                  phyinfoC_Phone: response.data.Physician.addresses[0].phoneNo,
                  phyinfoC_fax: response.data.Physician.addresses[0].fax,
                  phyinfoC_Faxcopyto:
                    response.data.Physician.addresses[0].faxCopyTo,

                  patinfo_firstname: response.data.Patient.firstName,
                  patinfo_lastname: response.data.Patient.lastName,
                  patinfo_address: response.data.Patient.address.address_1,
                  patinfo_State: {
                    label: response.data.Patient.address.state,
                    value: response.data.Patient.address.state,
                  },
                  patinfo_city: response.data.Patient.address.city,
                  patinfo_zipcode: response.data.Patient.address.zipCode,
                  patinfo_PhoneNumber: response.data.Patient.phoneNo,
                  patinfo_emailaddress: response.data.Patient.email,
                  patinfo_dob: patinfo_dobFormatted,
                  patinfo_OtherEthnicitycity:
                    response.data.Patient.ethnicity &&
                      response.data.Patient.ethnicity.includes("Other")
                      ? response.data.Patient.ethnicity
                      : "",
                  patinfo_ethnicity:
                    response.data.Patient.ethnicity &&
                      response.data.Patient.ethnicity.includes("Other")
                      ? 'Other'
                      : response.data.Patient.ethnicity,
                  patinfo_gender: response.data.Patient.gender,

                  //billing info

                  billinfo_PrimaryInsuranceCarrier: response.data.billingInfo
                    .primaryInsurance
                    ? response.data.billingInfo.primaryInsurance
                      .primaryInsuranceCarrier
                    : "",
                  billinfo_planId: response.data.billingInfo.primaryInsurance
                    ? response.data.billingInfo.primaryInsurance.planID
                    : "",
                  billinfo_MemberID: response.data.billingInfo.primaryInsurance
                    ? response.data.billingInfo.primaryInsurance.memberID
                    : "",
                  billinfo_GroupID: response.data.billingInfo.primaryInsurance
                    ? response.data.billingInfo.primaryInsurance.groupID
                    : "",

                  billinfo_SecondaryInsuranceCarrier: response.data.billingInfo
                    .secondaryInsurance
                    ? response.data.billingInfo.secondaryInsurance
                      .secondaryInsuranceCarrier
                    : "",
                  billinfo_SecondaryplanId: response.data.billingInfo
                    .secondaryInsurance
                    ? response.data.billingInfo.secondaryInsurance
                      .secondaryPlanID
                    : "",
                  billinfo_SecondaryMemberID: response.data.billingInfo
                    .secondaryInsurance
                    ? response.data.billingInfo.secondaryInsurance
                      .secondaryMemberID
                    : "",
                  billinfo_SecondaryGroupID: response.data.billingInfo
                    .secondaryInsurance
                    ? response.data.billingInfo.secondaryInsurance
                      .secondaryGroupID
                    : "",

                  billinfo_TertiaryInsuranceCarrier: response.data.billingInfo
                    ?.tertiaryInsurance
                    ? response.data.billingInfo?.tertiaryInsurance
                      ?.tertiaryInsuranceCarrier
                    : "",
                  billinfo_TertiaryMemberID: response.data.billingInfo
                    ?.tertiaryInsurance
                    ? response.data.billingInfo?.tertiaryInsurance
                      ?.tertiaryMemberID
                    : "",
                  billinfo_TertiaryGroupID: response.data.billingInfo
                    ?.tertiaryInsurance
                    ? response.data.billingInfo?.tertiaryInsurance
                      ?.tertiaryGroupID
                    : "",
                  billinfo_TertiaryPlanID: response.data.billingInfo
                    .tertiaryInsurance
                    ? response.data.billingInfo.tertiaryInsurance.tertiaryPlanID
                    : "",
                  billinfo_FirstNameofInsured: response.data.billingInfo
                    .insuredInfo
                    ? response.data.billingInfo.insuredInfo.nameOfInsured
                      .firstName
                    : "",
                  billinfo_LastNameofInsured: response.data.billingInfo
                    .insuredInfo
                    ? response.data.billingInfo.insuredInfo.nameOfInsured
                      .lastName
                    : "",

                  billinfoC_dob: billinfoC_dobFormatted,
                  billinfo_RelationshipInsured: response.data.billingInfo
                    .insuredInfo
                    ? response.data.billingInfo.insuredInfo.relationshipInsured
                    : "",

                  OtherICD10Codes: response.data.tests[0].icd10Code.otherCodes,
                  kitShip_PatOthersInfo:
                    response.data.tests[0].kitInformation.otherInfo,
                  kitShip_notes: response.data.tests[0].notes,

                  updatedAt: response.data.updatedAt,
                },
                () => {
                  //ASPiRA GENETIX Carrier Screening
                  if (
                    typeof this.state.orderData.tests[0].genetixCSPanel !=
                    "undefined"
                  ) {
                    if (
                      this.state.orderData.tests[0].genetixCSPanel.length > 0
                    ) {
                      this.state._assays_gtcs.map((a, i) => {
                        if (
                          this.state.orderData.tests[0].genetixCSPanel.includes(
                            a.name
                          )
                        ) {
                          $(`#gtcs_assay_${i}`).prop("checked", true);
                        }
                      });
                    }
                  }
                  //ASPiRA GENETIX Carrier Screening

                  //Indications for testing
                  if (
                    typeof this.state.orderData.tests[0]
                      .genetixCSIndicationOfTesting.testing != "undefined"
                  ) {
                    if (
                      this.state.orderData.tests[0].genetixCSIndicationOfTesting
                        .testing.length > 0
                    ) {
                      if (
                        this.state.orderData.tests[0].genetixCSIndicationOfTesting.testing.includes(
                          "Patient Screening"
                        )
                      ) {
                        this.setState({
                          pat_screening_test: true,
                        });
                      }
                      if (
                        this.state.orderData.tests[0].genetixCSIndicationOfTesting.testing.includes(
                          "Pregnancy"
                        )
                      ) {
                        this.setState({
                          pat_pregnancy: true,
                        });
                      }
                      if (
                        this.state.orderData.tests[0].genetixCSIndicationOfTesting.testing.includes(
                          "Infertility"
                        )
                      ) {
                        this.setState({
                          pat_infertality: true,
                        });
                      }
                      if (
                        this.state.orderData.tests[0].genetixCSIndicationOfTesting.testing.includes(
                          "Other"
                        )
                      ) {
                        this.setState({
                          pat_info_test_other: true,
                        });
                      }
                      if (
                        this.state.orderData.tests[0].genetixCSIndicationOfTesting.testing.includes(
                          "Partner Screening"
                        )
                      ) {
                        this.setState({
                          pat_partner_screening: true,
                        });
                      }
                      if (
                        this.state.orderData.tests[0].genetixCSIndicationOfTesting.testing.includes(
                          "Family History"
                        )
                      ) {
                        this.setState({
                          family_history: true,
                        });
                      }
                      if (
                        this.state.orderData.tests[0].genetixCSIndicationOfTesting.testing.includes(
                          "Egg/Sperm Donor"
                        )
                      ) {
                        this.setState({
                          egg_sperm_donner: true,
                        });
                      }
                    }
                  }
                  //Indications for testing

                  //Billing information

                  if (this.state.orderData.billingInfo.medicare) {
                    this.setState({
                      orderingFacility: false,
                      freeTrial: false,
                      medicaid: false,
                      medicare: true,
                      patientSelfPay: false,
                    });
                  }
                  if (this.state.orderData.billingInfo.privateInsurance) {
                    this.setState({
                      orderingFacility: false,
                      freeTrial: false,
                      privateInsurance: true,
                      medicaid: false,

                      patientSelfPay: false,
                    });
                  }
                  if (this.state.orderData.billingInfo.patientSelfPay) {
                    this.setState({
                      orderingFacility: false,
                      freeTrial: false,
                      privateInsurance: false,
                      medicaid: false,
                      medicare: false,
                      patientSelfPay: true,
                    });
                  }
                  if (this.state.orderData.billingInfo.medicaid) {
                    this.setState({
                      orderingFacility: false,
                      freeTrial: false,
                      privateInsurance: false,
                      medicaid: true,
                      medicare: false,
                      patientSelfPay: false,
                    });
                  }
                  if (this.state.orderData.billingInfo.orderingFacility) {
                    this.setState({
                      orderingFacility: true,
                      freeTrial: false,
                      privateInsurance: false,
                      medicaid: false,
                      medicare: false,
                      patientSelfPay: false,
                    });
                  }
                  if (this.state.orderData.billingInfo.freeTrial) {

                    this.setState({
                      orderingFacility: false,
                      freeTrial: true,
                      privateInsurance: false,
                      medicaid: false,
                      medicare: false,
                      patientSelfPay: false,
                    });
                  }

                  //Diagnosis Codes | ICD-10 Codes

                  if (this.state.orderData.tests.length > 0) {
                    if (
                      this.state.orderData.tests[0].icd10Code.codes.length > 0
                    ) {
                      //GTCS

                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "Z13.71"
                        )
                      ) {
                        $("#diagnosisC_z1371EncNProGeDisCaSt").prop(
                          "checked",
                          true
                        );
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "Z15.81"
                        )
                      ) {
                        $("#diagnosisC_GenSusMulEndocrineNeopl").prop(
                          "checked",
                          true
                        );
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "Z13.79"
                        )
                      ) {
                        $("#diagnosisC_z1379EncNProGeChroAnom").prop(
                          "checked",
                          true
                        );
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "Z15.89"
                        )
                      ) {
                        $("#diagnosisC_GenSusOtherDis").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "Z14.1"
                        )
                      ) {
                        $("#diagnosisC_z141CysticFibrosisCa").prop(
                          "checked",
                          true
                        );
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "Z31.430"
                        )
                      ) {
                        $("#diagnosisC_z31430FeProcman").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "Z14.8"
                        )
                      ) {
                        $("#diagnosisC_z148Gencarotdis").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "Z31.440"
                        )
                      ) {
                        $("#diagnosisC_z31430MaProcman").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "Z15.01"
                        )
                      ) {
                        $("#diagnosisC_z1501").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "Z31.5"
                        )
                      ) {
                        $("#diagnosisC_z315").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "Z15.02"
                        )
                      ) {
                        $("#diagnosisC_z1502").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "Z36.0"
                        )
                      ) {
                        $("#diagnosisC_z360").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "Z15.03"
                        )
                      ) {
                        $("#diagnosisC_z1503").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "Z36.81"
                        )
                      ) {
                        $("#diagnosisC_z3681").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "Z15.04"
                        )
                      ) {
                        $("#diagnosisC_z1504").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "Z36.89"
                        )
                      ) {
                        $("#diagnosisC_z3689").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "Z15.09"
                        )
                      ) {
                        $("#diagnosisC_z1509").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "Z36.9"
                        )
                      ) {
                        $("#diagnosisC_z369").prop("checked", true);
                      }
                    }
                  }

                  if (this.state.orderData.tests.length > 1) {
                    $("#covid_icd_codes").removeClass(" hidediv");
                    $("#covid_icd_codes").addClass(" showdiv");

                    if (
                      this.state.orderData.tests[1].icd10Code.codes.length > 0
                    ) {
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "Z11.59"
                        )
                      ) {
                        $("#diagnosis_z1159").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "Z03.818"
                        )
                      ) {
                        $("#diagnosis_z03818").prop("checked", true);
                      }
                    }
                  }

                  var tempOtherCodes = response.data.tests[0].icd10Code.otherCodes;
                  console.log('other', tempOtherCodes);
                  //console.log()

                  let displayOtherIcdCodes = tempOtherCodes.join(',');
                  this.setState({ otherICD10Codes: tempOtherCodes, otherICD10Code: displayOtherIcdCodes });
                  //end Diagnosis Codes | ICD-10 Codes

                  //Family History
                  if (
                    typeof this.state.orderData.tests[0].clinicalData !=
                    "undefined" &&
                    this.state.orderData.tests[0].clinicalData.length > 0
                  ) {
                    this.state.orderData.tests[0].clinicalData.map(
                      (itemcli, i) => {
                        $(`#famHist_familyMemName1_${i}`).val(
                          itemcli.familyMemberName
                        );
                        $(`#famHist_RelationPatient1_${i}`).val(
                          itemcli.relationToPatient
                        );

                        if (itemcli.sex == "male")
                          $(`#famHist_GenSexM1_${i}`).prop("checked", true);
                        else if (itemcli.sex == "female")
                          $(`#famHist_GenSexFe1_${i}`).prop("checked", true);
                        else
                          $(`#famHist_GenSexUnknown1_${i}`).prop(
                            "checked",
                            true
                          );

                        $(`#famHist_DiagSymptoms1_${i}`).val(itemcli.diagnosis);
                        $(`#famHist_AgeofOnset1_${i}`).val(itemcli.ageOfOnset);

                        let FamilyHistoryForm_dob_Formatted = "";
                        console.log("datei", itemcli.dateOfBirth);
                        if (itemcli.dateOfBirth) {
                          FamilyHistoryForm_dob_Formatted = helper.formatDate(
                            itemcli.dateOfBirth
                          );

                          this.setState({ familyHistoryForm_dob: FamilyHistoryForm_dob_Formatted })
                          $(`#FamilyHistoryForm_dob_${i}`).val(
                            moment(itemcli.dateOfBirth).format("YYYY-MM-DD")
                          );
                          $(`#datepicker_${i}`).val(
                            moment(itemcli.dateOfBirth).format("YYYY-MM-DD")
                          );
                        }
                      }
                    );
                  }

                  //Family History


                  //Kit Shipping Information
                  if (this.state.orderData.tests[0].kitInformation.drawType.length > 0) {
                    if (this.state.orderData.tests[0].kitInformation.drawType.includes("My physician needs mobile phlebotomy")) {
                      this.setState({ kitShip_PatMobPhlebotomy: 'My physician needs mobile phlebotomy' })
                    } if (this.state.orderData.tests[0].kitInformation.drawType.includes("Ship physician saliva collection kit")) {
                      this.setState({ kitShip_PatblooddrawQuest: 'Ship physician saliva collection kit' })
                    } if (this.state.orderData.tests[0].kitInformation.drawType.includes("Other")) {
                      this.setState({ kitShip_PatOthers: 'Other' })
                    }
                  } //end Kit Shipping Information

                  //researchConsent
                  if (
                    typeof this.state.orderData.researchConsent != "undefined"
                  ) {
                    if (this.state.orderData.researchConsent == "Yes") {
                      $("#researchConsent").prop("checked", true);
                    }
                  }

                  //researchUseOnly
                  if (
                    typeof this.state.orderData.researchUseOnly != "undefined"
                  ) {
                    if (this.state.orderData.researchUseOnly == "Yes") {
                      $("#researchUseOnly").prop("checked", true);
                    }
                  }

                  //certify Types certifyTypes
                  if (typeof this.state.orderData.certifyTypes != "undefined") {
                    if (this.state.orderData.certifyTypes.length > 0) {
                      if (
                        this.state.orderData.certifyTypes.includes(
                          "A healthcare provider"
                        )
                      ) {
                        $("#kitShip_healthcareProvider").prop("checked", true);
                      }
                      if (
                        this.state.orderData.certifyTypes.includes(
                          "An Aspira employee ordering on behalf of a healthcare provider"
                        )
                      ) {
                        $("#kitShip_employeehealthcareProvider").prop(
                          "checked",
                          true
                        );
                      }
                      if (
                        this.state.orderData.certifyTypes.includes(
                          "An Aspira partner ordering on behalf of a healthcare provider"
                        )
                      ) {
                        $("#kitShip_partnerhealthcareProvider").prop(
                          "checked",
                          true
                        );
                      }
                    }
                  }
                  //certify Types certifyTypes end
                }
              );
            }
          );
        }
      }
    );
  }

  onChangeDuedate_pregnant = (date) =>
    this.setState({ duedate_pregnant: date });
  onChangePatinfoDob = (date) => this.setState({ patinfo_dob: date });
  onChangeBillinfoCDob = (date) => this.setState({ billinfoC_dob: date });

  onChangeDob1 = (dateName, date, _index) => {
    console.log('this.state.familyHistoryForm_dob--------------');
    console.log(this.state.familyHistoryForm_dob);
    console.log(this.state.dobs);
    let _dobs = this.state.dobs;
    _dobs[_index] = date;

    this.setState(
      {
        dateFields: {
          ...this.state.dateFields,
          [dateName]: date,
        },
        date,
        dobs: _dobs,
      },
      () => {
        console.log("this.state.dateFields: ", this.state.dateFields);

        let dateStr = moment(date).format("YYYY-MM-DD");
        $(`#FamilyHistoryForm_dob_${_index}`).val(dateStr);
        this.setState({ familyHistoryForm_dob: dateStr });
      }
    );
  };

  showToast(type) {
    const id = Math.floor(Math.random() * 101 + 1);

    switch (type) {
      case "success":
        this.toastProperties = {
          id,
          title: "Success",
          description: "This is a success toast component",
          backgroundColor: "#5cb85c",
          icon: "/assets/assets/check.svg",
        };
        break;
      case "danger":
        this.toastProperties = {
          id,
          title: "Danger",
          description: "This is a error toast component",
          backgroundColor: "#d9534f",
          icon: "/assets/assets/error.svg",
        };
        break;
      case "info":
        this.toastProperties = {
          id,
          title: "Info",
          description: "This is an info toast component",
          backgroundColor: "#5bc0de",
          icon: "/assets/assets/info.svg",
        };
        break;
      case "warning":
        this.toastProperties = {
          id,
          title: "Warning",
          description: "This is a warning toast component",
          backgroundColor: "#f0ad4e",
          icon: "/assets/assets/warning.svg",
        };
        break;

      default:
        this.setState({ toastList: [] });
    }

    // this.setState([...this.state.list, this.toastProperties]);
    this.setState({ list: [this.toastProperties] });
  }

  getBillingInfoJson() {
    //Billing Information
    const privateInsurance = this.state.privateInsurance;
    const medicare = this.state.medicare;
    const patientSelfPay = this.state.patientSelfPay;
    const medicaid = this.state.medicaid;
    const freeTrial = this.state.freeTrial;
    const orderingFacility = this.state.orderingFacility;

    let billingInfo = {
      privateInsurance,
      medicare,
      patientSelfPay,
      medicaid,
      freeTrial,
      orderingFacility,
      primaryInsurance: {
        primaryInsuranceCarrier: this.state.selectedPayor.value,
        planID: this.state.billinfo_planId,
        memberID: this.state.billinfo_MemberID,
        groupID: this.state.billinfo_GroupID,
      },
      secondaryInsurance: {
        secondaryInsuranceCarrier:
          this.state.billinfo_SecondaryInsuranceCarrier,
        secondaryPlanID: this.state.billinfo_SecondaryplanId,
        secondaryMemberID: this.state.billinfo_SecondaryMemberID,
        secondaryGroupID: this.state.billinfo_SecondaryGroupID,
      },
      tertiaryInsurance: {
        tertiaryInsuranceCarrier: this.state.billinfo_TertiaryInsuranceCarrier,
        tertiaryMemberID: this.state.billinfo_TertiaryMemberID,
        tertiaryGroupID: this.state.billinfo_TertiaryGroupID,
        tertiaryPlanID: this.state.billinfo_TertiaryPlanID,
      },
      insuredInfo: {
        nameOfInsured: {
          firstName: this.state.billinfo_FirstNameofInsured,
          lastName: this.state.billinfo_LastNameofInsured,
        },
        dob: moment(this.state.billinfoC_dob).format("YYYY-MM-DD"),
        relationshipInsured: this.state.billinfo_RelationshipInsured,
      },
      notesText: this.state.notesText,
    };
    console.log("after pushing billing info=========================");
    console.log(billingInfo);
    //return;
    const isFirstNameMissing = !billingInfo.insuredInfo.nameOfInsured.firstName;
    const isLastNameMissing = !billingInfo.insuredInfo.nameOfInsured.lastName;
    const isDOBMissing =
      billingInfo.insuredInfo.dob === "Invalid date" ||
      !billingInfo.insuredInfo.dob;

    console.log("\n---");
    console.log("do person: ", billingInfo.insuredInfo.dob);
    console.log("---\n");

    if (
      (privateInsurance || medicare || medicaid) &&
      (isFirstNameMissing || isLastNameMissing || isDOBMissing)
    ) {
      if (isFirstNameMissing)
        message.warning("Insured person first name is missing");
      if (isLastNameMissing)
        message.warning("Insured person last name is missing");
      if (isDOBMissing)
        message.warning("Insured person date of birth is missing");

      return null;
    }
    return billingInfo;
    //Billing Information
  }
  async handleSave() {
    const user = JSON.parse(localStorage.getItem("user"));
    // console.log("user.token: ", user.token);

    const formData = new FormData();

    //ASPiRA GENETIX Carrier Screening Form data

    const aSPiRAGENETIXCarrierScreening = [];

    let sections = $(
      `#${this.state.frmprefix}ASPiRAGENETIXCarrierScreening #printHere_CS`
    ).find(":input");
    sections.map((input, i) => {
      const $input = $(sections[input]);
      console.log(
        "$input.attr('id'): ",
        $input.attr("id"),
        ", $input.attr('type'): ",
        $input.attr("type")
      );

      const _type = $input.attr("type");

      if (_type == "radio") {
        if ($input.is(":checked")) {
          // aSPiRAGENETIXCarrierScreening.push($input.data('val'));
          aSPiRAGENETIXCarrierScreening.push({
            code: $input.data("val"),
            qBenchId: $input.data("qbenchid"),
          });
        }
      }
    });

    const indicationsfortesting = [];

    if (this.state.pat_screening_test) {
      indicationsfortesting.push("Patient Screening");
    }
    if (this.state.pat_pregnancy) {
      indicationsfortesting.push("Pregnancy");
    }
    if (this.state.pat_infertality) {
      indicationsfortesting.push("Infertility");
    }
    if (this.state.pat_info_test_other) {
      indicationsfortesting.push("Other");
    }

    if (this.state.pat_partner_screening) {
      indicationsfortesting.push("Partner Screening");
    }
    if (this.state.family_history) {
      indicationsfortesting.push("Family History");
    }
    if (this.state.egg_sperm_donner) {
      indicationsfortesting.push("Egg/Sperm Donor");
    }

    const duedate_pregnant = moment(this.state.duedate_pregnant).format(
      "MM/DD/YYYY"
    );
    const ultrasound_findings = this.state.ultrasound_findings;

    let aSPiRAGENETIXCarrierScreening_data = {
      genetixCSPanel: aSPiRAGENETIXCarrierScreening,
      indicationsfortesting: {
        testing: indicationsfortesting,
        other: this.state.IndicationsTest_otherValue,
      },
      duedate_pregnant: duedate_pregnant,
      ultrasound_findings: ultrasound_findings,
    };

    //ASPiRA GENETIX Carrier Screening Form data end

    //add physician
    let addresses = [
      {
        legacyClientId: this.state.orderData.legacyClientId,
        locationName: "USA",
        address_1: this.state.phyinfoC_address,
        address_2: "",
        addressType: "Primary",
        city: this.state.phyinfoC_city,
        country: "USA",
        state: this.state.phyinfo_state_save_value,
        zipCode: this.state.phyinfoC_zipCode,
        phoneNo: this.state.phyinfoC_Phone,
        fax: this.state.phyinfoC_fax,
        faxCopyTo: this.state.phyinfoC_Faxcopyto,
      },
    ];
    let ethnicities = [];
    /* let inputs = $("#pat_ethnicity_container").find(":input");
    inputs.map((i, elem) => {
      let $elem = $(elem);

      const _type = $elem.attr("type");

      if (_type == "radio") {
        if ($elem.is(":checked")) {
          ethnicities.push($elem.data("val"));
        }
      }
    }); */
    /* if ($("#pat_ethnicity_container #patinfo_Other").is(":checked")) {
      ethnicities.push($("#patinfo_OtherEthnicitycity").val());
    }
    if (this.state.patinfo_ethnicity === "Other") {
      ethnicities.push(this.state.patinfo_OtherEthnicitycity);
    } else {
      ethnicities.push(this.state.patinfo_ethnicity);
    } */

    let patient = {
      patientId: this.patientId,
      email: this.state.patinfo_emailaddress,
      firstName: this.state.patinfo_firstname,
      lastName: this.state.patinfo_lastname,
      dob: moment(this.state.patinfo_dob).format("YYYY-MM-DD"),
      phoneNo: this.state.patinfo_PhoneNumber,
      address: {
        locationName: "USA",
        address_1: this.state.patinfo_address,
        address_2: "",
        addressType: "Primary",
        city: this.state.patinfo_city,
        country: "USA",
        state: this.state.patinfo_State_Save_value,
        zipCode: this.state.patinfo_zipcode,
      },


      ethnicity:
        this.state.patinfo_ethnicity.includes('Other')
          ? 'Other' + this.state
            .patinfo_OtherEthnicitycity
          : this.state.patinfo_ethnicity /* ethnicities.join() */,


      gender: this.state.patinfo_gender,
      // paymentOptions: [
      //   {
      //     name: String,
      //     isDefault: Boolean,
      //     stripeID: String,
      //     createdAt: { type: Date, default: new Date() },
      //     expiringAt: { type: Date, required: false },
      //     address: { type: String, required: false, },
      //     zipcode: String,
      //   }
      // ],
    };
    const researchConsent = $(`#researchConsent`).is(":checked")
      ? "Yes"
      : "No";
    const researchUseOnly = $(`#researchUseOnly`).is(":checked")
      ? "Yes"
      : "No";
    let billingInfo = this.getBillingInfoJson();
    if (!billingInfo) {
      return
    }
    let order = {
      orderId: this.orderId,
      _id: this._id,
      Physician: {
        physicianId: this.physicianId,
        organizationAccount: this.state.phyinfo_Organization_account,
        firstName: this.state.phyinfo_first_name,
        lastName: this.state.phyinfo_last_name,
        email: this.state.phyinfo_email,
        npiNumber: this.state.phyinfo_npi,
        addresses: addresses,
      },
      Patient: patient,
      billingInfo,
      tests: [
        {
          ova1PlusBiomarkers: [],
          icd10Code: {},
          kitInformation: {
            drawType: [""],
            otherInfo: "",
          },
          notes: "",
          genetixCSPanel: aSPiRAGENETIXCarrierScreening_data.genetixCSPanel,
          genetixCSIndicationOfTesting:
            aSPiRAGENETIXCarrierScreening_data.indicationsfortesting,
          dueDate: aSPiRAGENETIXCarrierScreening_data.duedate_pregnant,
          ultrasoundsFindings:
            aSPiRAGENETIXCarrierScreening_data.ultrasound_findings,
          clinicalData: [],
          testName: "GenetiXCarrierScreening",
          testCode: "GenetiXCarrierScreening",
        },
      ],
      certifyTypes: [],
      payorId: this.state.selectedPayor.value,
      researchConsent,
      researchUseOnly,

      updatedAt: this.state.updatedAt,
      // createdAt: this.state.orderData.createdAt,
      // isDeleted: false,
      // orderId: this.state.orderData.orderId,
      // patientId: this.state.orderData.patientId,
      // physicianId: this.state.orderData.physicianId,
      // status: this.state.orderData.status,
    };
    console.log("order edit data ============");
    console.log(order);

    //
    //add physician

    //add patient

    //let patinfo_dob = moment(this.state.patinfo_dob).format('YYYY-MM-DD')

    //order.patient = patient
    //add patient

    //order.billingInfo = this.getBillingInfoJson()

    //ICD Codes
    let icd10Code = {
      codes: [],
      otherCodes: [],
    };

    let inputs = $(
      `#${this.state.frmprefix}gtcs_icd_codes  #gtcs_icd_codes`
    ).find(":input");
    inputs.map((i, elem) => {
      let $elem = $(elem);

      const _type = $elem.attr("type");

      if (_type == "checkbox") {
        if ($elem.is(":checked")) {
          icd10Code.codes.push($elem.data("val"));
        }
      }
    });
    this.state.OtherICD10Codes = this.state.otherICD10Code.split(',');
    icd10Code.otherCodes = this.state.OtherICD10Codes;
    // icd10Code.otherCodes = this.OtherICD10Codes.value;

    order.tests[0].icd10Code = icd10Code;

    if (this.state.orderData.tests.length > 1) {
      const icd10CodesCOVD = [];
      let icd10CodeCOVD = {
        codes: [],
        otherCodes: "",
      };

      inputs = $(
        `#${this.state.frmprefix}gtcs_icd_codes #covid_icd_codes`
      ).find(":input");
      inputs.map((i, input) => {
        const $input = $(input);

        const _type = $input.attr("type");

        if (_type == "checkbox") {
          if ($input.is(":checked")) {
            icd10CodesCOVD.push($input.data("val"));
          }
        }
      });

      icd10CodeCOVD = {
        codes: icd10CodesCOVD,
        otherCodes: "",
      };

      var covid_tests = {
        testName: "COVD",
        testCode: "COVD",
        icd10Code: icd10CodeCOVD,
      };

      order.tests.push(covid_tests);
    }
    //ICD Codes

    //Family History Form
    let familyHistories = [];
    let i = 0;

    $("div.duplicator").each(() => {
      let familyHistory = {
        familyMemberName: "",
        relationToPatient: "",
        sex: "",
        diagnosis: "",
        ageOfOnset: "",
        dateOfBirth: "",
      };

      let famHist_GenSex = "";
      sections = $("div#famHist_GenSex_container_" + i).find(":input");
      sections.map((input, i) => {
        const $input = $(sections[input]);

        const _type = $input.attr("type");

        if (_type == "radio") {
          if ($input.is(":checked")) {
            famHist_GenSex = $input.data("val");
          }
        }
      });
      this.state.familyHistoryForm_dob = $(`#duplicator_${i}`)
        .find(`#FamilyHistoryForm_dob_${i}`)
        .val();
      familyHistory = {
        familyMemberName: $(`#duplicator_${i}`)
          .find(`#famHist_familyMemName1_${i}`)
          .val(),
        relationToPatient: $(`#duplicator_${i}`)
          .find(`#famHist_RelationPatient1_${i}`)
          .val(),
        sex: famHist_GenSex,
        diagnosis: $(`#duplicator_${i}`)
          .find(`#famHist_DiagSymptoms1_${i}`)
          .val(),
        ageOfOnset: $(`#duplicator_${i}`)
          .find(`#famHist_AgeofOnset1_${i}`)
          .val(),
        dateOfBirth: $(`#duplicator_${i}`)
          .find(`#FamilyHistoryForm_dob_${i}`)
          .val(),
      };
      familyHistories.push(familyHistory);

      ++i;
    });

    if (this.state.user.isAdmin) order.tests[0].clinicalData = familyHistories;
    else
      order.tests[0].clinicalData = this.state.orderData.tests[0].clinicalData;

    //Family History Form end

    //Kit Shipping Info
    const {
      kitShip_PatMobPhlebotomy,
      kitShip_PatblooddrawQuest,
      kitShip_PatOthers,
      kitShip_PatOthersInfo
    } = this.state

    const kitInformation = {
      drawType: [
        kitShip_PatMobPhlebotomy,
        kitShip_PatblooddrawQuest,
        kitShip_PatOthers
      ].filter((item) => item !== null),
      otherInfo: kitShip_PatOthersInfo,
    };

    order.tests[0].kitInformation = kitInformation;
    //Kit Shipping Info

    //Notes & Certify
    order.tests[0].notes = this.state.kitShip_notes;

    const $kitShip_healthcareProvider = $("#kitShip_healthcareProvider");
    const $kitShip_employeehealthcareProvider = $(
      "#kitShip_employeehealthcareProvider"
    );
    const $kitShip_partnerhealthcareProvider = $(
      "#kitShip_partnerhealthcareProvider"
    );
    if ($kitShip_healthcareProvider.is(":checked")) {
      order.certifyTypes.push($kitShip_healthcareProvider.data("val"));
    }
    if ($kitShip_employeehealthcareProvider.is(":checked")) {
      order.certifyTypes.push($kitShip_employeehealthcareProvider.data("val"));
    }
    if ($kitShip_partnerhealthcareProvider.is(":checked")) {
      order.certifyTypes.push($kitShip_partnerhealthcareProvider.data("val"));
    }

    // get the payor id that is selected for Primary insurance carrier
    //order.payorId = this.state.selectedPayor.value

    // get the user response related to user's consent about the use of his/her data for research purpose

    // order.researchConsent = researchConsent
    // order.researchUseOnly = researchUseOnly

    //Notes & Certify

    formData.append("order", JSON.stringify(order));

    formData.append("host", window.location.host);

    console.log('sm order: ', order)
    console.log('sm formData: ', formData)

    const accessToken = await helper.getAccessToken(this.context)
    if (me._id) {
      // formData.append("itemID", me._id);
      // formData.append("orderId", me.orderId);
      axios
        .put(`${API_BASE_URL}/orders`, formData, {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        })
        .then(({ data }) => {
          console.log("sm success data: ", data);

          // this.setState(
          //   {
          //     toastMessage: `Successfully updated order`,
          //   },
          //   () => {
          // this.showToast("success");
          // console.log("message: ", message);

          message.success(`Successfully updated order`, 2, () => {
            if (process.env.REACT_APP_ENV !== "DEV") {
              // setTimeout(() => {
              const params = new URLSearchParams(window.location.search);
              let returnurl = params.get("returnurl");

              console.log("returnurl: ", returnurl);
              if (process.env.REACT_APP_ENV !== "DEV") {
                window.location.href = returnurl;
              }
              // }, 1000);
            }
          });
        })
        .catch((error) => {
          message.error(error.message);
        })
    } else {
      axios
        .post(`${API_BASE_URL}/orders`, formData, {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        })
        .then(({ data }) => {
          console.log("data: ", data);

          this.setState(
            {
              toastMessage: `Successfully added order`,
            },
            () => {
              // this.showToast("success");
              message.success(me.state.toastMessage);
            }
          );
        })
        .catch((error) => {
          message.error(error);
        })
    }
    //covid_icd_codes
  }

  async handleSaveBillingInfo() {
    const me = this;
    // const user = JSON.parse(localStorage.getItem("user"));

    // const urlParams = new URLSearchParams(window.location.search);
    // const _id = urlParams.get("id");
    // console.log("order id: ", _id);

    // let billingInfo = this.getBillingInfoJson();

    // let _payload = billingInfo;
    // let _url = `${API_BASE_URL}/orders/savebillinginfo`;
    // let _options = {
    //   url: _url,
    //   method: "POST",
    //   headers: {
    //     Authorization: `Bearer ${user.token}`,
    //   },
    //   data: {
    //     order_id: _id,
    //     billingInfo: _payload,
    //   },
    // };
    // const response = await axios(_options);
    // console.log("CmtForm->response.data: ", response.data);

    // this.setState(
    //   {
    //     toastMessage: `Successfully updated Billing Information`,
    //   },
    //   async () => {
    //     me.showToast("success");

    me.handleSave();

    //   if (process.env.REACT_APP_ENV !== 'DEV') {
    //     setTimeout(() => {
    //       window.location.reload();
    //     }, 1000);
    //   }
    // });
  }

  takeNotes(notesText) {
    console.log("notesText: ", notesText);
    this.setState({
      notesText: notesText,
    });
  }

  getDefaultPayorIdOptions = () => {
    return this.state.allPayors.slice(0, 20);
  };

  getPayorIdOptionsBySearchText = async (searchText) => {
    let payorIdOptions = this.state.allPayors.filter((payor) =>
      payor.label.toLowerCase().includes(searchText.toLowerCase())
    );

    // if the payorIdOptions array contains many elements then the Select element becomes laggy
    // so I'm cutting down the array size if there are too many elements - Khairul
    const numberOfOptions = payorIdOptions.length;

    console.log("\n===");
    console.log("original options count: ", numberOfOptions);

    if (numberOfOptions > 100) {
      payorIdOptions = payorIdOptions.slice(0, 99);
    }

    console.log("Options count after trimming: ", payorIdOptions.length);
    console.log("===\n");

    return payorIdOptions;
  };

  updatePrimaryInsuranceCarrierAndSelectedPayor = (inputPayor) => {
    this.billinfo_PrimaryInsuranceCarrier.value = inputPayor.value;
    this.setState({ selectedPayor: inputPayor });
  };

  renderPayorListDropdownForPrimaryInsuranceCarrierSelection = () => {
    return (
      <div
        class="col-sm-10 col-xs-12 mb-3 hidediv"
        id="payorListSearchAndSelect"
      >
        <div class="form-group drop-custum">
          <label class="color-green" htmlFor="role">
            Search Primary Carrier:
          </label>
          <small className="color-red">*</small>
          <AsyncSelect
            value={this.state.selectedPayor}
            defaultValue={""}
            label="Search Primary Carrier:"
            cacheOptions
            defaultOptions={this.getDefaultPayorIdOptions()}
            loadOptions={this.getPayorIdOptionsBySearchText}
            styles={helper.colourStyles}
            onChange={this.updatePrimaryInsuranceCarrierAndSelectedPayor}
          />
        </div>
      </div>
    );
  };

  render() {
    var tempOtherCode = this.state.otherICD10Code;
    if (this.state.loading) {
      return null;
    }

    return (
      <div>
        <div class="block-header">
          <h2>
            Edit Order - Id: {this.state.orderData.orderId} (
            {this.state.orderData.status})
          </h2>
          {/* <ul class="breadcrumb">
            <li class="breadcrumb-item">
              <a href="/orders">Orders</a>
            </li>
            <li class="breadcrumb-item active">
              {this.state.orderData._id ? "Edit Order" : "New Order"}
            </li>
          </ul> */}
        </div>
        {console.log("Order: ", this.state.orderData)}

        <div
          class="panel-group card"
          id="accordion_105"
          role="tablist"
          aria-multiselectable="true"
        >
          <Toast
            toastList={me.state.list}
            position={"top-center"}
            autoDelete={true}
            dismissTime={3000}
            description={me.state.toastMessage}
          />
          {/* Top Actions */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">Actions</h4>
            </div>
          </div>
          <TopActions
            parent='genetix'
            reportId={reportId}
            galenReportId={me.state.orderData.galenReportId}
            context={this.context}
            orderData={this.state.orderData}
            reportDate={this.state.reportDate}
          />
          {/* baylor */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">Baylor</h4>
            </div>
          </div>
          <div class="body card">
            <div class="row clearfix">
              <div class="col-md-12">
                <div class="form-group required">
                  <label class="form-label" for="baylor">
                    {me.state.orderData.galenReportStatus &&
                      me.state.orderData.galenReportStatus.length > 0 && (
                        <span>
                          Galen Report Status:
                          {me.state.orderData.galenReportStatus}
                        </span>
                      )}
                    {me.state.orderData.baylorReportStatus &&
                      me.state.orderData.baylorReportStatus.length > 0 && (
                        <span>
                          Baylor Report Status:
                          {me.state.orderData.baylorReportStatus}
                        </span>
                      )}
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* ASPiRA GENETIX Carrier Screening */}

          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">ASPiRA GENETIX Carrier Screening</h4>
            </div>
          </div>
          <div
            id={`${this.state.frmprefix}ASPiRAGENETIXCarrierScreening`}
            class="body card"
          >
            <div class="row clearfix">
              <div class="col-md-12">
                <div class="form-group required">
                  <label class="form-label" for="address">
                    ASPiRA GENETIX Carrier Screening (select panel)
                  </label>
                  <div class="demo-radio-button">
                    <div id="printHere_CS" class="row clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row clearfix">
              <div class="col-md-12">
                <p>Male partner testing will not include X-linked genes.</p>
              </div>
            </div>
            <div class="row clearfix">
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="form-label" for="address">
                    Indications for testing: check all that apply
                  </label>
                  <div class="row clearfix">
                    <div class="col-md-8">
                      <div class="demo-checkbox">
                        <input
                          type="checkbox"
                          data-val="Patient Screening"
                          id="IndicationsTest_PatientScreening"
                          name="IndicationsTest_PatientScreening"
                          class="filled-in"
                          checked={this.state.pat_screening_test}
                          onChange={(val) => {
                            this.setState({
                              pat_screening_test: val.target.checked,
                            });
                          }}
                        />
                        <label for="IndicationsTest_PatientScreening">
                          Patient Screening &nbsp; &nbsp;{" "}
                        </label>
                        <input
                          type="checkbox"
                          data-val="Pregnancy"
                          id="IndicationsTest_Pregnancy"
                          name="IndicationsTest_Pregnancy"
                          class="filled-in"
                          checked={this.state.pat_pregnancy}
                          onChange={(val) => {
                            this.setState({
                              pat_pregnancy: val.target.checked,
                            });
                          }}
                        />
                        <label for="IndicationsTest_Pregnancy">
                          Pregnancy &nbsp; &nbsp;{" "}
                        </label>
                        <input
                          type="checkbox"
                          data-val="Infertility"
                          id="IndicationsTest_Infertility"
                          name="IndicationsTest_Infertility"
                          class="filled-in"
                          checked={this.state.pat_infertality}
                          onChange={(val) => {
                            this.setState({
                              pat_infertality: val.target.checked,
                            });
                          }}
                        />
                        <label for="IndicationsTest_Infertility">
                          Infertility &nbsp; &nbsp;{" "}
                        </label>
                        <input
                          type="checkbox"
                          data-val="Other"
                          id="IndicationsTest_other"
                          name="IndicationsTest_other"
                          class="filled-in"
                          checked={this.state.pat_info_test_other}
                          onChange={(val) => {
                            this.setState({
                              pat_info_test_other: val.target.checked,
                            });
                            if (this.state.pat_info_test_other) {
                              this.setState({ IndicationsTest_otherValue: "" });
                            }
                          }}
                        />
                        <label for="IndicationsTest_other">Other: </label>
                      </div>
                    </div>
                    <div class="col-md-4">
                      <div class="form-line">
                        <input
                          type="text"
                          id="IndicationsTest_otherValue"
                          class="form-control "
                          placeholder=""
                          value={this.state.IndicationsTest_otherValue}
                          onChange={(val) => {
                            this.setState({
                              IndicationsTest_otherValue: val.target.value,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div class="demo-checkbox">
                    <input
                      type="checkbox"
                      data-val="Partner Screening"
                      id="IndicationsTest_PartnerScreening"
                      name="IndicationsTest_PartnerScreening"
                      class="filled-in"
                      checked={this.state.pat_partner_screening}
                      onChange={(val) => {
                        this.setState({
                          pat_partner_screening: val.target.checked,
                        });
                      }}
                    />
                    <label for="IndicationsTest_PartnerScreening">
                      Partner Screening &nbsp; &nbsp;{" "}
                    </label>
                    <input
                      type="checkbox"
                      data-val="Family History"
                      id="IndicationsTest_FamilyHistory"
                      name="IndicationsTest_FamilyHistory"
                      class="filled-in"
                      checked={this.state.family_history}
                      onChange={(val) => {
                        this.setState({ family_history: val.target.checked });
                      }}
                    />
                    <label for="IndicationsTest_FamilyHistory">
                      Family History &nbsp; &nbsp;{" "}
                    </label>
                    <input
                      type="checkbox"
                      data-val="Egg/Sperm Donor"
                      id="IndicationsTest_EggSperm_Donor"
                      name="IndicationsTest_EggSperm_Donor"
                      class="filled-in"
                      checked={this.state.egg_sperm_donner}
                      onChange={(val) => {
                        this.setState({ egg_sperm_donner: val.target.checked });
                      }}
                    />
                    <label for="IndicationsTest_EggSperm_Donor">
                      Egg/Sperm Donor &nbsp; &nbsp;{" "}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row clearfix">
              <div class="col-md-6">
                <div class="form-group">
                  <label class="form-label" for="DOB">
                    Due date if pregnant{" "}
                  </label>
                  <div class="form-line">
                    <DatePicker
                      id={`duedate_pregnant`}
                      className={"form-control required"}
                      selected={this.state.duedate_pregnant}
                      onChange={(date) => this.onChangeDuedate_pregnant(date)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group required">
                  <label class="form-label" for="NameofInsured">
                    Ultrasound findings / Clinical testing{" "}
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      // ref={(ref) => (me.ultrasound_findings = ref)}
                      id="ultrasound_findings"
                      class="form-control "
                      value={this.state.ultrasound_findings}
                      placeholder=" Ultrasound findings / Clinical testing"
                      onChange={(val) => {
                        this.setState({
                          ultrasound_findings: val.target.value,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <ASPiRA_GENETIX_CarrierScreening
            state={this.state}
            setState={this.setState}
            set_IndicationsTest_otherValue={(ref) => (me.IndicationsTest_otherValue = ref)}
            set_ultrasound_findings={(ref) => (me.ultrasound_findings = ref)}
          /> */}
          {/* ASPiRA GENETIX Carrier Screening end */}
          {/* GTCS Physician Information */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">Physician Information</h4>
            </div>
          </div>
          <PhysicianInformation
            state={this.state}
            me={this}
            phyinfo_state={this.state.phyinfo_state}
          />

          {/* GTCS Physician Information end */}
          {/* GTCS Patient Information */}
          {/* {this.state.orderData.Patient.email} */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">Patient Information</h4>
            </div>
          </div>
          <PatientInformation
            state={this.state}
            me={this}
          />
          {/* GTCS Patient Information end */}

          {/* GTCS Billing Information */}
          {/* Only show Billing Information when the order is billable i.e. not flagged as RUO - Research Use Only */}
          {this.state.isBillableOrder && (
            <>
              <div class="row clearfix">
                <div class="col-md-12">
                  <h4 class="panel-titlepdf">Billing Information</h4>
                </div>
              </div>
              <Billing_Information
                state={this.state}
                me={this}
                from="genetix-carrier-screening"
              />
              <CmtForm
                order={me.state.orderData}
                handleSaveFunc={this.handleSave.bind(this)}
                takeNotesFunc={this.takeNotes.bind(this)}
              />
              <BillingNotes
                order={me.state.orderData}
                handleSaveFunc={this.handleSave.bind(this)}
                takeNotes={this.takeNotes.bind(this)}
              />
              <div class="row clearfix">
                <div class="col-md-12">
                  <div class="form-group">
                    <button
                      id={`btnSaveBillingInfo`}
                      placeholder="Update billing info"
                      name="Submit"
                      className="btn btn-primary text-white"
                      onClick={this.handleSave.bind(this)}
                    >
                      Update billing info
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* GTCS Billing Information end */}
          {/* GTCS Diagnosis Codes | ICD-10 Codes */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">Diagnosis Codes | ICD-10 Codes</h4>
            </div>
          </div>
          <div id={`${this.state.frmprefix}gtcs_icd_codes`} class="body card">
            <div class="form-group required">
              <label class="form-label" for="DiagnosisCodes">
                <b>GCS Diagnosis Codes | ICD-10 Codes (check all that apply)</b>
              </label>
              <div class="demo-checkbox" id="gtcs_icd_codes">
                <div class="row clearfix">
                  <div class="col-md-6">
                    <input
                      type="checkbox"
                      data-val="Z13.71"
                      id={`diagnosisC_z1371EncNProGeDisCaSt`}
                      name={`diagnosisC_z1371EncNProGeDisCaSt`}
                      class="filled-in"
                    />
                    <label for={`diagnosisC_z1371EncNProGeDisCaSt`}>
                      Z13.71 Encounter for nonprocreative screening for genetic
                      disease carrier status &nbsp;{" "}
                    </label>
                  </div>
                  <div class="col-md-6">
                    <input
                      type="checkbox"
                      data-val="Z15.81"
                      id={`diagnosisC_GenSusMulEndocrineNeopl`}
                      name={`diagnosisC_GenSusMulEndocrineNeopl`}
                      class="filled-in"
                    />
                    <label for={`diagnosisC_GenSusMulEndocrineNeopl`}>
                      Z15.81 Genetic susceptibility to multiple endocrine
                      neoplasia [MEN] &nbsp;{" "}
                    </label>
                  </div>
                </div>
                <div class="row clearfix">
                  <div class="col-md-6">
                    <input
                      type="checkbox"
                      data-val="Z13.79"
                      id={`diagnosisC_z1379EncNProGeChroAnom`}
                      name={`diagnosisC_z1379EncNProGeChroAnom`}
                      class="filled-in"
                    />
                    <label for={`diagnosisC_z1379EncNProGeChroAnom`}>
                      Z13.79 Encounter for other screening for genetic and
                      chromosomal anomalies &nbsp;{" "}
                    </label>
                  </div>
                  <div class="col-md-6">
                    <input
                      type="checkbox"
                      data-val="Z15.89"
                      id={`diagnosisC_GenSusOtherDis`}
                      name={`diagnosisC_GenSusOtherDis`}
                      class="filled-in"
                    />
                    <label for={`diagnosisC_GenSusOtherDis`}>
                      Z15.89 Genetic susceptibility to other disease &nbsp;{" "}
                    </label>
                  </div>
                </div>
                <div class="row clearfix">
                  <div class="col-md-6">
                    <input
                      type="checkbox"
                      data-val="Z14.1"
                      id={`diagnosisC_z141CysticFibrosisCa`}
                      name={`diagnosisC_z141CysticFibrosisCa`}
                      class="filled-in"
                    />
                    <label for={`diagnosisC_z141CysticFibrosisCa`}>
                      Z14.1 Cystic fibrosis carrier &nbsp;{" "}
                    </label>
                  </div>
                  <div class="col-md-6">
                    <input
                      type="checkbox"
                      data-val="Z31.430"
                      id={`diagnosisC_z31430FeProcman`}
                      name={`diagnosisC_z31430FeProcman`}
                      class="filled-in"
                    />
                    <label for={`diagnosisC_z31430FeProcman`}>
                      Z31.430 Encounter of female for testing for genetic
                      disease carrier status for procreative management &nbsp;{" "}
                    </label>
                  </div>
                </div>
                <div class="row clearfix">
                  <div class="col-md-6">
                    <input
                      type="checkbox"
                      data-val="Z14.8"
                      id={`diagnosisC_z148Gencarotdis`}
                      name={`diagnosisC_z148Gencarotdis`}
                      class="filled-in"
                    />
                    <label for={`diagnosisC_z148Gencarotdis`}>
                      Z14.8 Genetic carrier of other disease &nbsp;{" "}
                    </label>
                  </div>
                  <div class="col-md-6">
                    <input
                      type="checkbox"
                      data-val="Z31.440"
                      id={`diagnosisC_z31430MaProcman`}
                      name={`diagnosisC_z31430MaProcman`}
                      class="filled-in"
                    />
                    <label for={`diagnosisC_z31430MaProcman`}>
                      Z31.440 Encounter of male for testing for genetic disease
                      carrier status for procreative management &nbsp;{" "}
                    </label>
                  </div>
                </div>
                <div class="row clearfix">
                  <div class="col-md-6">
                    <input
                      type="checkbox"
                      data-val="Z15.01"
                      id={`diagnosisC_z1501`}
                      name={`diagnosisC_z1501`}
                      class="filled-in"
                    />
                    <label for={`diagnosisC_z1501`}>
                      Z15.01 Genetic susceptibility to malignant neoplasm of
                      breast &nbsp;{" "}
                    </label>
                  </div>
                  <div class="col-md-6">
                    <input
                      type="checkbox"
                      data-val="Z31.5"
                      id={`diagnosisC_z315`}
                      name={`diagnosisC_z315`}
                      class="filled-in"
                    />
                    <label for={`diagnosisC_z315`}>
                      Z31.5 Encounter for procreative genetic counseling &nbsp;{" "}
                    </label>
                  </div>
                </div>
                <div class="row clearfix">
                  <div class="col-md-6">
                    <input
                      type="checkbox"
                      data-val="Z15.02"
                      id={`diagnosisC_z1502`}
                      name={`diagnosisC_z1502`}
                      class="filled-in"
                    />
                    <label for={`diagnosisC_z1502`}>
                      Z15.02 Genetic susceptibility to malignant neoplasm of
                      ovary &nbsp;{" "}
                    </label>
                  </div>
                  <div class="col-md-6">
                    <input
                      type="checkbox"
                      data-val="Z36.0"
                      id={`diagnosisC_z360`}
                      name={`diagnosisC_z360`}
                      class="filled-in"
                    />
                    <label for={`diagnosisC_z360`}>
                      Z36.0 Encounter for antenatal screening for chromosomal
                      anomalies &nbsp;{" "}
                    </label>
                  </div>
                </div>
                <div class="row clearfix">
                  <div class="col-md-6">
                    <input
                      type="checkbox"
                      data-val="Z15.03"
                      id={`diagnosisC_z1503`}
                      name={`diagnosisC_z1503`}
                      class="filled-in"
                    />
                    <label for={`diagnosisC_z1503`}>
                      Z15.03 Genetic susceptibility to malignant neoplasm of
                      prostate &nbsp;{" "}
                    </label>
                  </div>
                  <div class="col-md-6">
                    <input
                      type="checkbox"
                      data-val="Z36.81"
                      id={`diagnosisC_z3681`}
                      name={`diagnosisC_z3681`}
                      class="filled-in"
                    />
                    <label for={`diagnosisC_z3681`}>
                      Z36.81 Encounter for antenatal screening for hydrops
                      fetails &nbsp;{" "}
                    </label>
                  </div>
                </div>
                <div class="row clearfix">
                  <div class="col-md-6">
                    <input
                      type="checkbox"
                      data-val="Z15.04"
                      id={`diagnosisC_z1504`}
                      name={`diagnosisC_z1504`}
                      class="filled-in"
                    />
                    <label for={`diagnosisC_z1504`}>
                      Z15.04 Genetic susceptibility to malignant neoplasm of
                      endometrium &nbsp;{" "}
                    </label>
                  </div>
                  <div class="col-md-6">
                    <input
                      type="checkbox"
                      data-val="Z36.89"
                      id={`diagnosisC_z3689`}
                      name={`diagnosisC_z3689`}
                      class="filled-in"
                    />
                    <label for={`diagnosisC_z3689`}>
                      Z36.89 Encounter for other specified antenatal screening
                      &nbsp;{" "}
                    </label>
                  </div>
                </div>
                <div class="row clearfix">
                  <div class="col-md-6">
                    <input
                      type="checkbox"
                      data-val="Z15.09"
                      id={`diagnosisC_z1509`}
                      name={`diagnosisC_z1509`}
                      class="filled-in"
                    />
                    <label for={`diagnosisC_z1509`}>
                      Z15.09 Genetic susceptibility to other malignant neoplasm
                      &nbsp;{" "}
                    </label>
                  </div>
                  <div class="col-md-6">
                    <input
                      type="checkbox"
                      data-val="Z36.9"
                      id={`diagnosisC_z369`}
                      name={`diagnosisC_z369`}
                      class="filled-in"
                    />
                    <label for={`diagnosisC_z369`}>
                      Z36.9 Encounter for antenatal screening, unspecified
                      &nbsp;{" "}
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div class="row clearfix hidediv" id={`covid_icd_codes`}>
              <div class="col-md-12">
                <div class="form-group required">
                  <label class="form-label" for={`DiagnosisCodes`}>
                    <b>
                      {" "}
                      COVD Diagnosis Codes | ICD-10 Codes (check all that apply)
                    </b>
                  </label>
                  <div class="demo-checkbox">
                    <div class="row clearfix">
                      <div class="col-md-6">
                        <input
                          type="checkbox"
                          data-val="Z11.59"
                          id={`diagnosis_z1159`}
                          name={`diagnosis_z1159`}
                          class="filled-in"
                        />
                        <label for={`diagnosis_z1159`}>
                          Z11.59, Encounter for screening for other viral
                          diseases &nbsp;{" "}
                        </label>
                      </div>
                      <div class="col-md-6">
                        <input
                          type="checkbox"
                          data-val="Z03.818"
                          id={`diagnosis_z03818`}
                          name={`diagnosis_z03818`}
                          class="filled-in"
                        />
                        <label for={`diagnosis_z03818`}>
                          Z03.818, Encounter for observation for suspected
                          exposure to other biological agents ruled out &nbsp;{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row clearfix">
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="form-label" for={`address`}>
                    Other ICD-10 Codes
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      ref={(ref) => (this.OtherICD10Codes = ref)}
                      id={`OtherICD10Codes`}
                      class="form-control "
                      placeholder="Other ICD-10 Codes"
                      value={me.state.otherICD10Code}
                      onChange={(e) => {
                        tempOtherCode = e.target.value;
                        //  if(tempOtherCode.includes(',')){
                        // tempOtherCode=props.state.otherICD10Code+tempOtherCode;
                        this.setState({ otherICD10Code: tempOtherCode });
                        //  tempOtherCode='';
                        //  e.target.value='';
                        //  }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row clearfix">
              <div class="col-md-12">
                <p>
                  (This is provided for informational purposes only and is not a
                  guarantee of coverage. It is the provider's responsibility to
                  determine the appropriate codes)
                </p>
              </div>
            </div>
          </div>
          {/* GTCS Diagnosis Codes | ICD-10 Codes end */}

          {/* Family History */}
          <div
            class={this.state.user.isAdmin ? "showdiv" : "hidediv"}
            id="_FamilyHistoryArea"
          >
            <div class="row clearfix">
              <div class="col-md-12">
                <h4 class="panel-titlepdf">Family History</h4>
              </div>
            </div>
            <div class="body card">
              {typeof this.state.orderData.tests[0].clinicalData !=
                "undefined" &&
                this.state.orderData.tests[0].clinicalData.length > 0 && (
                  <>
                    {this.state.orderData.tests[0].clinicalData.map(
                      (cliItem, i) => {
                        return (
                          <div
                            key={i}
                            className="duplicator"
                            id={`duplicator_${i}`}
                          >
                            <div class="row clearfix">
                              <div class="col-md-3">
                                <div class="form-group ">
                                  <label
                                    class="form-label"
                                    for={`Familymembername${i + 1}`}
                                  >
                                    Family member {i + 1} name
                                  </label>
                                  <div class="form-line">
                                    <input
                                      type="text"
                                      id={`famHist_familyMemName1_${i}`}
                                      name="famHist_familyMemName[]"
                                      class="form-control "
                                      placeholder={`Family member ${i + 1
                                        } name`}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="form-group">
                                  <label
                                    class="form-label"
                                    for="Relationtophysician1"
                                  >
                                    Relation to Patient
                                  </label>
                                  <div class="form-line">
                                    <input
                                      type="text"
                                      id={`famHist_RelationPatient1_${i}`}
                                      name="famHist_RelationPatient[]"
                                      class="form-control"
                                      placeholder="Relation to Patient"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <label class="form-label" for="Geneticsex">
                                    Genetic sex
                                  </label>
                                  <div
                                    id={`famHist_GenSex_container_${i}`}
                                    class="demo-radio-button"
                                  >
                                    <input
                                      type="radio"
                                      id={`famHist_GenSexM1_${i}`}
                                      name={`famHist_GenSex_${i}`}
                                      data-val="male"
                                      class="form-control"
                                    />
                                    <label
                                      class="form-label"
                                      for={`famHist_GenSexM1_${i}`}
                                    >
                                      Male &nbsp;{" "}
                                    </label>
                                    <input
                                      type="radio"
                                      id={`famHist_GenSexFe1_${i}`}
                                      name={`famHist_GenSex_${i}`}
                                      data-val="female"
                                      class="form-control"
                                    />
                                    <label
                                      class="form-label"
                                      for={`famHist_GenSexFe1_${i}`}
                                    >
                                      Female &nbsp;{" "}
                                    </label>
                                    <input
                                      type="radio"
                                      id={`famHist_GenSexUnknown1_${i}`}
                                      name={`famHist_GenSex_${i}`}
                                      data-val="Unknown"
                                      class="form-control"
                                    />
                                    <label
                                      class="form-label"
                                      for={`famHist_GenSexUnknown1_${i}`}
                                    >
                                      Unknown &nbsp;{" "}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row clearfix">
                              <div class="col-md-6">
                                <div class="form-group ">
                                  <label
                                    class="form-label"
                                    for="Diagnosisand_orsymptoms"
                                  >
                                    Diagnosis and/or symptoms
                                  </label>
                                  <div class="form-line">
                                    <input
                                      type="text"
                                      id={`famHist_DiagSymptoms1_${i}`}
                                      name="famHist_DiagSymptoms[]"
                                      class="form-control "
                                      placeholder="Diagnosis and/or symptoms"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="form-group ">
                                  <label class="form-label" for="Ageofonset">
                                    Age of onset
                                  </label>
                                  <div class="form-line">
                                    <input
                                      type="text"
                                      id={`famHist_AgeofOnset1_${i}`}
                                      name="famHist_AgeofOnset[]"
                                      class="form-control"
                                      placeholder="Age of onset"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-md-3">
                                <div class="form-group ">
                                  <label class="form-label" for="dob1">
                                    DOB (MM/DD/YYYY)
                                  </label>
                                  <div class="form-line">
                                    <DatePicker
                                      id={`datepicker_${i}`}
                                      className={"form-control"}
                                      selected={
                                        this.state.dateFields[`dob_${i}`]
                                      }
                                      // value={this.state.familyHistoryForm_dob}
                                      onChange={(date) => {
                                        this.onChangeDob1(`dob_${i}`, date, i);
                                      }}
                                      peekNextMonth
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                      value={moment(
                                        this.state.familyHistoryForm_dob
                                      ).format("YYYY-MM-DD")}
                                    />

                                    <input
                                      type="hidden"
                                      id={`FamilyHistoryForm_dob_${i}`}
                                      name="FamilyHistoryForm_dob[]"
                                      value={moment(
                                        this.state.familyHistoryForm_dob
                                      ).format("YYYY-MM-DD")}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      }
                    )}
                  </>
                )}
              {/* <div class="row clearfix">
                <div class="col-md-12">
                    <div class="form-group text-right">
                        <button className={"btn-family-history-add-new"} onClick={() => this.addNewRow()}>+</button>
                    </div>
                </div>
              </div> */}
            </div>
          </div>
          {/* Family History */}

          {/* GTCS Kit Shipping Information */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">Kit Shipping Information</h4>
            </div>
          </div>
          <Kit_Shipping_Information
            state={this.state}
            me={this}
            for='AddEditOrderGenetiXCarrierScreening'
          />
          {/* <div
            id={`${this.state.frmprefix}kitShippingInfoFormContainer`}
            class="body card"
          >
            <div class="row clearfix">
              <div class="col-md-12">
                <div class="form-group required">
                  <label class="form-label" for="address">
                    Kit Shipping Info
                  </label>
                  <div id="draw_type" class="demo-checkbox">
                    <input
                      data-val="My physician needs mobile phlebotomy"
                      type="checkbox"
                      id={`kitShip_PatMobPhlebotomy`}
                      name={`kitShip_PatMobPhlebotomy`}
                      class="filled-in"
                      checked={this.state.kitShip_PatMobPhlebotomy}
                      onChange={(e) => this.setState({kitShip_PatMobPhlebotomy: e.target.checked ? 'My physician needs mobile phlebotomy' : null})}
                    />
                    <label for={`kitShip_PatMobPhlebotomy`}>
                      My physician needs mobile phlebotomy &nbsp;{" "}
                    </label>
                    <input
                      data-val="Ship physician saliva collection kit"
                      type="checkbox"
                      id="kitShip_PatblooddrawQuest"
                      name="kitShip_PatblooddrawQuest"
                      class="filled-in"
                      checked={this.state.kitShip_PatblooddrawQuest}
                      onChange={(e) => this.setState({kitShip_PatblooddrawQuest: e.target.checked ? 'Ship physician saliva collection kit' : null})}
                    />
                    <label for="kitShip_PatblooddrawQuest">
                      Ship physician saliva collection kit &nbsp;{" "}
                    </label>
                    <input
                      data-val="Other"
                      type="checkbox"
                      id={`kitShip_PatOthers`}
                      name="kitShip_PatOthers"
                      class="filled-in"
                      checked={this.state.kitShip_PatOthers}
                      onChange={(e) => this.setState({kitShip_PatOthers: e.target.checked ? 'Other' : null})}
                    />
                    <label for={`kitShip_PatOthers`}>Other &nbsp; </label>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="form-label" for="address">
                    &nbsp;
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      ref={(ref) => (me.kitShip_PatOthersInfo = ref)}
                      id={`kitShip_PatOthersInfo`}
                      class="form-control "
                      placeholder=" Other info"
                      value={this.state.kitShip_PatOthersInfo}
                      onChange={({ target: { value } }) => this.setState({ kitShip_PatOthersInfo: value })}
                      disabled={!this.state.kitShip_PatOthers}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* GTCS Kit Shipping Information end*/}
          {/* GTCS Kit Notes & Certify */}
          <Notes_and_Certify
            me={this}
            state={this.state}
            handleSave={this.handleSave.bind(this)}
            token={this.state.accessToken}
            reportId={reportId}
            orderId={this.orderId}
            reportDate={this.state.reportDate}
            from={'genetix'}
            prefix={this.state.frmprefix}
          />
          {/*<div class='row clearfix'>
            <div class='col-md-12'>
              <h4 class='panel-titlepdf'>Notes & Certify</h4>
            </div>
          </div>
          <div id={`${this.state.frmprefix}NotesCertify`} class='body card'>
            <div class='row clearfix'>
              <div class='col-md-12'>
                <div class='form-group '>
                  <label class='form-label' for='address'>
                    Notes
                  </label>
                  <div class='form-line'>
                    <textarea
                      ref={(ref) => (me.kitShip_notes = ref)}
                      id={`kitShip_notes`}
                      class='form-control no-resize auto-growth'
                      placeholder='Notes'
                      rows='3'
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class='row clearfix'>
              <div class='col-md-12'>
                <p>
                  <strong>
                    {' '}
                    Please note this page is for healthcare professionals. If you are a patient and would like access to one of our tests, please
                    contact your healthcare provider.{' '}
                  </strong>
                </p>
              </div>
            </div>
            <div className='row clearfix'>
              <div className='col-md-12'>
                <div className='form-group required'>
                  <input type='checkbox' id={`gtcs_researchConsent`} className='filled-in' />
                  <label htmlFor={`gtcs_researchConsent`}>Consent to research</label>
                </div>
                <div className='form-group required'>
                  <input type='checkbox' id={`gtcs_researchUseOnly`} className='filled-in' />
                  <label htmlFor={`gtcs_researchUseOnly`}>Research use only</label>
                </div>
              </div>
            </div>

            <div class='row clearfix'>
              <div class='col-md-12'>
                <div class='form-group required'>
                  <label class='form-label' for='address'>
                    I certify that I am:
                  </label>
                  <div class='demo-radio-button' id={`certifytype`}>
                    <input
                      type='radio'
                      data-val='A healthcare provider'
                      id={`kitShip_healthcareProvider`}
                      name={`kitShip_healthcareProvider`}
                      class='form-control'
                    />
                    <label for={`kitShip_healthcareProvider`}>A healthcare provider &nbsp; </label>
                    <input
                      type='radio'
                      data-val='An Aspira employee ordering on behalf of a healthcare provider'
                      id={`kitShip_employeehealthcareProvider`}
                      name={`kitShip_healthcareProvider`}
                      class='form-control'
                    />
                    <label for={`kitShip_employeehealthcareProvider`}>An Aspira employee ordering on behalf of a healthcare provider &nbsp; </label>
                    <input
                      type='radio'
                      data-val='An Aspira partner ordering on behalf of a healthcare provider'
                      id={`kitShip_partnerhealthcareProvider`}
                      name={`kitShip_healthcareProvider`}
                      class='form-control'
                    />
                    <label for={`kitShip_partnerhealthcareProvider`}>An Aspira partner ordering on behalf of a healthcare provider &nbsp; </label>
                  </div>
                </div>
              </div>
            </div>
            <div id='ignoreMeInPDF' class='row clearfix'>
              <div class='col-md-12'>
                <div class='form-group'>
                  <Button
                    id={`btnOrderSubmit`}
                    onClick={me.handleSave.bind(this)}
                    placeholder='Submit'
                    name='Submit'
                    className='btn btn-primary text-white'
                  >
                    {me.state.buttonText}
                  </Button>
                </div>
              </div>
            </div>
          </div>*/}
          {/* GTCS Kit Notes & Certify  end*/}
        </div>
      </div>
    );
  }
}
