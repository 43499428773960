import React, { PureComponent, useEffect, useState } from "react";

import PropTypes from 'prop-types';
import $ from 'jquery';
import moment from 'moment';
import axios from 'axios';

// import jsPDF from "jspdf";
// import ReactDOMServer from "react-dom/server";
import { protectedResources } from "./authConfig";
import { MsalContext } from "@azure/msal-react";


//includes all 3 forms

import { connect } from "react-redux";
import helper from "./utils/helperPortal";
import helper1 from "./utils/helper";
import { Spinner } from "react-bootstrap";
import { message } from "antd";
// import Ova1PlusFormContent from "./form/Ova1PlusFormContent";

const url_base = helper1.getAPIBaseURI();
const provider_url_base = helper.getAPIBaseURI();

//for billing logic
const showInsuranceTitle = () => {
  $(`#title_insurance_container`).removeClass(" hidediv");
  $(`#title_insurance_container`).addClass(" showdiv");
};

const hideInsuranceTitle = () => {
  $(`#title_insurance_container`).addClass(" hidediv");
  $(`#title_insurance_container`).removeClass(" showdiv");
};

const showPrimaryAreaClick = () => {

  $(`#primary_insurance_container`).removeClass(" hidediv");
  $(`#primary_insurance_container`).addClass(" showdiv");
};
const hidePrimaryAreaClick = () => {
  $(`#primary_insurance_container`).addClass(" hidediv");
  $(`#primary_insurance_container`).removeClass(" showdiv");
};
const showSecondaryAreaClick = () => {

  $(`#secondary_insurance_container`).removeClass(" hidediv");
  $(`#secondary_insurance_container`).addClass(" showdiv");
};
const hideSecondaryAreaClick = () => {

  $(`#secondary_insurance_container`).addClass(" hidediv");
  $(`#secondary_insurance_container`).removeClass(" showdiv");
};

const showInsuranceInsured = () => {
  $(`#insured_info_container`).removeClass(" hidediv");
  $(`#insured_info_container`).addClass(" showdiv");
};

const hideInsuranceInsured = () => {
  $(`#insured_info_container`).addClass(" hidediv");
  $(`#insured_info_container`).removeClass(" showdiv");
};

//for billing logic


export default class TRF_ProviderOrderPortalOva1 extends PureComponent {
  static contextType = MsalContext
  // const[orderId, setOrderId] = useState("");
  // const[retData, setRetData] = useState({ });
  constructor(props) {
    super(props);

    this.state = {
      orderId: "",
      retData: {},
      loading: false,
      ethnicityArray: {},
      isLoading: true,
      errorMessage: null,
    }

    this.btnGeneratePDF = React.createRef();
  }
  loadOrder = async () => {
    return new Promise(async (resolve, reject) => {
      try {
        const temps = document.location.search.split('=');
        console.log("temps: ", temps);

        let _orderId = '5f213c1133509e7592407e41';
        if (temps.length > 1) {
          _orderId = temps[1];
        }

        // setOrderId(_orderId)

        // const accessToken = await helper1.getAccessToken(this.context);
        const accessToken = "";
        let opt = {
          url: `${url_base}/orders/${_orderId}`,
          method: "GET",
          data: {},
          headers: {
            Authorization: `Bearer ${accessToken}`
          }

        };
        console.log("loading order with url: ", opt);
        let ret = await axios(opt);
        console.log("ret.data: ", ret.data);


        let retdata = ret.data;
        retdata.order = retdata.orders[0];
        if (retdata.order) {


          opt = {
            url: `${provider_url_base}/physicians/addressperclientid/${retdata.order.physicianId}/${retdata.order._id}`,
            method: "GET",
            data: {},
            headers: {
              Authorization: `Bearer ${accessToken}`
            }

          };
          console.log("loading order with url: ", opt);
          let physician = await axios(opt);
          console.log("physician.data: ", physician.data);

          opt = {
            url: `${url_base}/patients/${retdata.order.patientId}`,
            method: "GET",
            data: {},
            headers: {
              Authorization: `Bearer ${accessToken}`
            }

          };
          console.log("loading order with url: ", opt);
          let patient = await axios(opt);
          console.log("patient.data: ", patient.data);

          if (typeof patient.data.patient !== 'undefined') {
            retdata.patient = patient.data.patient;
          }
          else {
            retdata.patient = patient.data;
          }

          if (typeof physician.data.physician !== 'undefined') {
            retdata.physician = physician.data.physician;
          }
          else {
            retdata.physician = physician.data;
          }


          //ethnicity array
          const ethnicityArray1 = retdata.patient.ethnicity ? retdata.patient.ethnicity.split(",") : "";
          this.setState({
            orderId: _orderId,
            retData: retdata,
            ethnicityArray: ethnicityArray1,
            isLoading: false,
          }, () => {
            console.log("retdata: ", retdata);
            //Billing information          
            if (this.state.retData.order.billingInfo.medicare) {

              showInsuranceTitle();
              showPrimaryAreaClick();
              hideSecondaryAreaClick();
              showInsuranceInsured();
            }
            if (this.state.retData.order.billingInfo.patientSelfPay) {

              hidePrimaryAreaClick();
              hideSecondaryAreaClick();
              hideInsuranceTitle();
              hideInsuranceInsured();
            }
            if (this.state.retData.order.billingInfo.medicaid) {

              showInsuranceTitle();
              showPrimaryAreaClick();
              hideSecondaryAreaClick();
              showInsuranceInsured();
            }
            if (this.state.retData.order.billingInfo.orderingFacility) {
              hidePrimaryAreaClick();
              hideSecondaryAreaClick();
              hideInsuranceTitle();
              hideInsuranceInsured();
            }
            if (this.state.retData.order.billingInfo.privateInsurance) {

              showInsuranceTitle();
              showPrimaryAreaClick();
              showSecondaryAreaClick();
              showInsuranceInsured();
            }


            resolve(true);
          });


        }
        else {
          // this.setState({ loading: false, error: true, });
          reject(false);
        }

      } catch (error) {
        console.log("error on loading order: ", error);
        reject(error);
      }
    });
  }

  componentDidMount() {
    this.setState({ isLoading: true, errorMessage: null }, () => {
      try {
        // const theButton = document.getElementById(`ova1_btnViewTRF`);
        // theButton.textContent = "Loading Page";
        // theButton.disabled = true;
        // theButton.classList.remove("text-white");
        // theButton.classList.add("button--loading");

        this.loadOrder()
          .then(() => {

            this.setState({ isLoading: false, errorMessage: null }, () => {
              // theButton.textContent = "Download Pdf";
              // theButton.classList.add("text-white");
              // theButton.classList.remove("button--loading");
              // theButton.disabled = false;
            });
          })
          .catch((error) => {
            console.log("error on loading ova1: ", error);
            this.setState({ isLoading: false, errorMessage: error });
          });
        // this.btnGeneratePDF.click();
      }
      catch (error) {
        console.log("error on loading ova1: ", error);
        this.setState({ isLoading: false, errorMessage: error });
      }
    });


  }

  // useEffect(() => {

  //   loadOrder();

  // })  


  render() {

    if (!this.state.isLoading && this.state.errorMessage) {
      return message.error(this.state.errorMessage.message);
    }
    else if (this.state.isLoading) {
      return null;
    }
    else {
      return (
        <>
          {/* Bread Crumb Area */}

          {/* Content Area */}

          <section class="content home" style={{ marginLeft: 0, marginTop: 0 }}>
            <div class="container-fluid provider_order provider_order_pdf">

              {/* form area start */}
              <div class="row clearfix">
                <div class="col-md-12">
                  <div class="body">

                    {/* <Ova1PlusFormContent /> */}
                    {console.log("-----")}
                    {console.log("this.state.retData: ", this.state.retData)}
                    {console.log("-----")}

                    {/* OVA1 Biomarker */}

                    <div class="row clearfix">
                      <div class="col-md-12"><div class="bg-div"></div><h4 class="panel-titlepdf1">Additional Biomarkers</h4></div>
                    </div>
                    <div class="row clearfix">

                      {((this.state.retData.order.tests[0].ova1PlusBiomarkers && this.state.retData.order.tests[0].ova1PlusBiomarkers.other && this.state.retData.order.tests[0].ova1PlusBiomarkers.other).length > 0) && (
                        <div class="col-md-12">
                          <div class="form-group required">
                            <div id="ova1_container" class="demo-checkbox">
                              {((this.state.retData.order.tests[0].ova1PlusBiomarkers.other)?.toLowerCase().includes("ova1")) && (<>
                                <input data-val="Ova1" checked type="checkbox" id="ova1" name="ova1" class="filled-in-1-1" />
                                <label for="ova1"><b>OVA 1</b> &nbsp; </label><br />
                              </>)}
                            </div>
                          </div>
                        </div>
                      )}

                      {((this.state.retData.order.tests[0].ova1PlusBiomarkers.GCP).length > 0) && (
                        <div class="col-md-6">
                          {/* {this.state.retData.order.tests[0].ova1PlusBiomarkers.GCP[0] } */}

                          <div class="form-group required">
                            <div id="gcp_container1" class="demo-checkbox">
                              {((this.state.retData.order.tests[0].ova1PlusBiomarkers.GCP).length === 3) && (<>
                                <input data-val="LDH" checked type="checkbox" id="germCellPanel_GCP100_LDH" name="germCellPanel_GCP100_LDH" class="filled-in-1-1" />
                                <label for="germCellPanel_GCP100_LDH"><b>(GCP-100) Germ Cell Panel</b> (Includes: AFP, bHCG, LDH) &nbsp; </label><br />
                              </>)}

                              {((this.state.retData.order.tests[0].ova1PlusBiomarkers.GCP).length < 3) && (<>
                                <input data-val="LDH" type="checkbox" id="germCellPanel_GCP100_LDH" name="germCellPanel_GCP100_LDH" class="filled-in-1-1" />
                                <label for="germCellPanel_GCP100_LDH"><b>(GCP-100) Germ Cell Panel</b> (Includes: AFP, bHCG, LDH) &nbsp; </label><br />
                              </>)}

                              {((this.state.retData.order.tests[0].ova1PlusBiomarkers.MCP).length === 2) && (<>
                                <input data-val="CEA" checked type="checkbox" id="germCellPanel_MCP100_CEA" name="germCellPanel_MCP100_CEA" class="filled-in-1-1" />
                                <label for="germCellPanel_MCP100_CEA"><b>(MCP-100) Mucinous Panel</b> (Includes: CA19.9, CEA) &nbsp; </label>
                              </>)}

                              {((this.state.retData.order.tests[0].ova1PlusBiomarkers.MCP).length < 2) && (<>
                                <input data-val="CEA" type="checkbox" id="germCellPanel_MCP100_CEA" name="germCellPanel_MCP100_CEA" class="filled-in-1-1" />
                                <label for="germCellPanel_MCP100_CEA"><b>(MCP-100) Mucinous Panel</b> (Includes: CA19.9, CEA) &nbsp; </label>
                              </>)}

                            </div>
                          </div>
                        </div>
                      )}
                      {((this.state.retData.order.tests[0].ova1PlusBiomarkers.GCP).length > 0) && (
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="form-label" for="address"><b>Germ Cell Tests:</b></label>
                            <div id="gcp_container2" class="demo-checkbox">
                              {
                                this.state.retData.order.tests[0].ova1PlusBiomarkers.GCP.map((item, i) => {
                                  return <>
                                    {(item) && (<>
                                      <input data-val="AFP" checked type="checkbox" id={`germCellPanel_GCP_${i}`} name="germCellPanel_GCP100_AFP" class="filled-in-1-1" />
                                      <label for={`germCellPanel_GCP_${i}`}>{item} &nbsp; </label><br />
                                    </>
                                    )}
                                  </>
                                })
                              }

                            </div>
                          </div>
                        </div>
                      )}
                      {((this.state.retData.order.tests[0].ova1PlusBiomarkers.MCP).length > 0) && (
                        <div class="col-md-3">
                          <div class="form-group">
                            <label class="form-label" for="address"><b>Mucinous Panel Tests:</b></label>
                            <div id="mcp_container" class="demo-checkbox">
                              {
                                this.state.retData.order.tests[0].ova1PlusBiomarkers.MCP.map((item, i) => {
                                  return <>
                                    <input data-val="AFP" checked type="checkbox" id={`germCellPanel_MCP_${i}`} name="germCellPanel_GCP100_AFP" class="filled-in-1-1" />
                                    <label for={`germCellPanel_MCP_${i}`}>{item} &nbsp; </label><br />
                                  </>
                                })
                              }

                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    {/* OVA1 Biomarker end */}

                    {/* OVA1 Physician Information */}
                    {/* {this.state.retData.physician.organizationAccount} */}
                    <div class="row clearfix">
                      <div class="col-md-12"><div class="bg-div"></div><h4 class="panel-titlepdf1">Physician Information</h4></div>
                    </div>
                    <div class="row clearfix">
                      <div class="col-md-12">
                        <div class="form-group">
                          <label class="form-label" for="ordered_before1">Organization/Account #</label>
                          <div class="form-line">
                            <input type="text" value={this.state.retData.physician.organizationAccount} id="phyinfo_Organization_account" class="form-control" placeholder="Organization/Account #" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row clearfix">
                      <div class="col-md-3" >
                        <div class="form-group required">
                          <label class="form-label" for="ordered_before1">First Name</label>
                          <div class="form-line">
                            <input type="text" id={`phyinfo_first_name`} value={this.state.retData.physician.firstName} class="form-control required" placeholder="First Name" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3" >
                        <div class="form-group required">
                          <label class="form-label" for="ordered_before1">Last Name</label>
                          <div class="form-line">
                            <input type="text" id={`phyinfo_last_name`} value={this.state.retData.physician.lastName} class="form-control required" placeholder="Last Name" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3" >
                        <div class="form-group required">
                          <label class="form-label" for="ordered_before1">Email</label>
                          <div class="form-line">
                            <input type="text" id={`phyinfo_email`} value={this.state.retData.physician.email} class="form-control required" placeholder="Email" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-3" >
                        <div class="form-group required">
                          <label class="form-label" for="ordered_before1">NPI</label>
                          <div class="form-line">
                            <input type="text" id={`phyinfo_NPI`} value={this.state.retData.physician.npiNumber} class="form-control required" placeholder="NPI" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row clearfix">
                      <div class="col-md-6">
                        <div class="form-group required">
                          <label class="form-label" for="ordered_before1">Address</label>
                          <div class="form-line">
                            <input type="text" id={`phyinfoC_address`} value={this.state.retData.physician.addresses[0].address_1} class="form-control required" placeholder="Address" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group required">
                          <label class="form-label" for="ordered_before1">Practice Location</label>
                          <div class="form-line">
                            <input type="text" id={`phyinfoC_practiceLocation`} value={this.state.retData.physician.practiceLocationPlain} class="form-control required" placeholder="Practice Location" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row clearfix">
                      <div class="col-md-4">
                        <div class="form-group ">
                          <label class="form-label" for="ordered_before1">City </label>
                          <div class="form-line">
                            <input type="text" value={this.state.retData.physician.addresses[0].city} id={`phyinfoC_city`} class="form-control" placeholder="City" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group ">
                          <label class="form-label" for="ordered_before1">State</label>
                          <div class="form-line">
                            <input type="text" id={`phyinfoC_state`} value={this.state.retData.physician.addresses[0].state} class="form-control" placeholder="State" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group ">
                          <label class="form-label" for="ordered_before1">Zip Code</label>
                          <div class="form-line">
                            <input type="email" id={`phyinfoC_zipCode`} value={this.state.retData.physician.addresses[0].zipCode} class="form-control" placeholder="Zip Code" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row clearfix">
                      <div class="col-md-4">
                        <div class="form-group required">
                          <label class="form-label" for="ordered_before1">Phone </label>
                          <div class="form-line">
                            <input type="text" id="phyinfoC_Phone" value={this.state.retData.physician.addresses[0].phoneNo} class="form-control required" placeholder="Phone" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group">
                          <label class="form-label" for="ordered_before1">Fax</label>
                          <div class="form-line">
                            <input type="text" id={`phyinfoC_fax`} value={this.state.retData.physician.addresses[0].phoneNo} class="form-control" placeholder="Fax" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group ">
                          <label class="form-label" for="ordered_before1">Fax copy to</label>
                          <div class="form-line">
                            <input type="email" id={`phyinfoC_Faxcopyto`} value={this.state.retData.physician.addresses[0].phoneNo} class="form-control required" placeholder="Fax copy to" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row clearfix">
                      <div class="col-md-12">
                        <div class="demo-checkbox ">
                          <input type="checkbox" id={`phyinfoC_confirmation`} name="phyinfoC_confirmation" class="filled-in-1 required" />
                          <label for={`phyinfoC_confirmation`} class="form-label">By checking the box below I am confirming that I have provided informed consent for this test. </label>
                        </div>
                      </div>
                    </div>
                    {/* OVA1 Physician Information end */}

                    {/* OVA1 Patient Information */}
                    {/* {this.state.retData.patient.organizationAccount} */}
                    <div class="row clearfix">
                      <div class="col-md-12"><div class="bg-div"></div><h4 class="panel-titlepdf1">Patient Information</h4></div>
                    </div>
                    <div class="row clearfix">
                      <div class="col-md-6">
                        <div class="form-group required">
                          <label class="form-label" for={`patinfo_firstname`}>First Name</label>
                          <div class="form-line">
                            <input type="text" id={`patinfo_firstname`} value={this.state.retData.patient.firstName} class="form-control required" placeholder="First Name" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group required">
                          <label class="form-label" for={`patinfo_lastname`}>Last Name</label>
                          <div class="form-line">
                            <input type="text" id={`patinfo_lastname`} value={this.state.retData.patient.lastName} class="form-control required" placeholder="Last Name" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row clearfix">
                      <div class="col-md-6">
                        <div class="form-group required">
                          <label class="form-label" for={`patinfo_address1`}>Address 1</label>
                          <div class="form-line">
                            <input type="text" id={`patinfo_address1`} value={this.state.retData.patient.address.address_1} class="form-control required" placeholder="Address 1" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group required">
                          <label class="form-label" for={`patinfo_address2`}>Address 2</label>
                          <div class="form-line">
                            <input type="text" id={`patinfo_address2`} value={this.state.retData.patient.address.address_2} class="form-control required" placeholder="Address 2" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row clearfix">
                      <div class="col-md-4">
                        <div class="form-group required">
                          <label class="form-label" for={`patinfo_city`}>City</label>
                          <div class="form-line">
                            <input type="text" id={`patinfo_city`} value={this.state.retData.patient.address.city} class="form-control required" placeholder="City" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group required">
                          <label class="form-label" for="state">State</label>
                          <div class="form-line">
                            <input type="text" id={`patinfo_city`} value={this.state.retData.patient.address.state} class="form-control required" placeholder="State" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group required">
                          <label class="form-label" for={`patinfo_zipcode`}>Zip Code</label>
                          <div class="form-line">
                            <input type="text" id={`patinfo_zipcode`} value={this.state.retData.patient.address.zipCode} class="form-control required" placeholder="Zip Code" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row clearfix">
                      <div class="col-md-4">
                        <div class="form-group required">
                          <label class="form-label" for={`patinfo_DOB`}>DOB (mm/dd/yyyy)</label>
                          <div class="form-line">
                            <input type="text" id={`patinfo_DOB`} value={moment.utc(this.state.retData.patient.dob).format("YYYY-MM-DD")} class="form-control required" placeholder="DOB" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group required">
                          <label class="form-label" for="PhoneNumber">Phone Number</label>
                          <div class="form-line">
                            <input type="text" id={`patinfo_PhoneNumber`} value={this.state.retData.patient.phoneNo} class="form-control required" placeholder="Phone Number" />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group required">
                          <label class="form-label" for={`patinfo_emailaddress`}>Email Address</label>
                          <div class="form-line">
                            <input type="email" value={this.state.retData.patient.email} id={`patinfo_emailaddress`} class="form-control required" placeholder="Email Address" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row clearfix">
                      <div class="form-group required">
                        <label class="form-label" for={`patinfo_gender`}>Gender</label>
                        {/* {this.state.retData.patient.gender} */}
                        <div class="demo-radio-button">
                          {((this.state.retData.patient.gender).toLowerCase() === 'male') && (<>
                            <input type="radio" checked id={`patinfo_gender`} name="patinfo_gender_" value="male" class=" form-control" />
                            <label class="form-label gender" for={`patinfo_gender`}>Male &nbsp; </label>
                          </>)}
                          {((this.state.retData.patient.gender).toLowerCase() != 'male') && (<>
                            <input type="radio" id={`patinfo_gender`} name="patinfo_gender_" value="male" class=" form-control" />
                            <label class="form-label gender" for={`patinfo_gender`}>Male &nbsp; </label>
                          </>)}

                          {((this.state.retData.patient.gender).toLowerCase() === 'female') && (<>
                            <input type="radio" checked id={`patinfo_gender2`} name="patinfo_gender_" value="female" class=" form-control" />
                            <label class="form-label gender" for={`patinfo_gender2`}>Female &nbsp; </label>
                          </>)}
                          {((this.state.retData.patient.gender).toLowerCase() != 'female') && (<>
                            <input type="radio" id={`patinfo_gender2`} name="patinfo_gender_" value="female" class=" form-control" />
                            <label class="form-label gender" for={`patinfo_gender2`}>Female &nbsp; </label>
                          </>)}
                        </div>
                      </div>
                    </div>
                    {/* {this.state.retData.patient.ethnicity}                */}
                    {/* {console.log("ethin: "+this.state.ethnicityArray)} */}
                    {/* {((this.state.ethnicityArray).includes("Caucasian")) ? "y" : "n" } */}
                    <div class="row clearfix">
                      <div class="col-md-12">
                        <div class="form-group required">
                          <label class="form-label" for="Ethnicity">Ethnicity (Check all that apply)</label>
                          <div class="demo-checkbox">
                            {((this.state.ethnicityArray).includes("Caucasian")) && (
                              <input type="checkbox" checked data-val="Caucasian" id={`patinfo_Caucasian`} name="patinfo_Caucasian" class="filled-in-1" />)}
                            {!((this.state.ethnicityArray).includes("Caucasian")) && (
                              <input type="checkbox" data-val="Caucasian" id={`patinfo_Caucasian`} name="patinfo_Caucasian" class="filled-in-1" />)}
                            <label for={`patinfo_Caucasian`}>Caucasian &nbsp; &nbsp; </label>

                            {((this.state.ethnicityArray).includes("Ashkenazi Jewish")) && (
                              <input type="checkbox" checked data-val="Ashkenazi Jewish" id={`patinfo_AshkenaziJewish`} name="patinfo_AshkenaziJewish" class="filled-in-1" />)}
                            {!((this.state.ethnicityArray).includes("Ashkenazi Jewish")) && (
                              <input type="checkbox" data-val="Ashkenazi Jewish" id={`patinfo_AshkenaziJewish`} name="patinfo_AshkenaziJewish" class="filled-in-1" />)}
                            <label for={`patinfo_AshkenaziJewish`}>Ashkenazi Jewish &nbsp; &nbsp; </label>

                            {((this.state.ethnicityArray).includes("Sephardic Jewish")) && (
                              <input type="checkbox" checked data-val="Sephardic Jewish" id={`patinfo_SephardicJewish`} name="patinfo_SephardicJewish" class="filled-in-1" />)}
                            {!((this.state.ethnicityArray).includes("Sephardic Jewish")) && (
                              <input type="checkbox" data-val="Sephardic Jewish" id={`patinfo_SephardicJewish`} name="patinfo_SephardicJewish" class="filled-in-1" />)}
                            <label for={`patinfo_SephardicJewish`}>Sephardic Jewish &nbsp; &nbsp; </label>

                            {((this.state.ethnicityArray).includes("Asian")) && (
                              <input type="checkbox" checked data-val="Asian" id={`patinfo_Asian`} name="patinfo_Asian" class="filled-in-1" />)}
                            {!((this.state.ethnicityArray).includes("Asian")) && (
                              <input type="checkbox" data-val="Asian" id={`patinfo_Asian`} name="patinfo_Asian" class="filled-in-1" />)}
                            <label for={`patinfo_Asian`}>Asian &nbsp; &nbsp; </label>

                            {((this.state.ethnicityArray).includes("Hispanic")) && (
                              <input type="checkbox" checked data-val="Hispanic" id={`patinfo_HIspanic`} name="patinfo_HIspanic" class="filled-in-1" />)}
                            {!((this.state.ethnicityArray).includes("Hispanic")) && (
                              <input type="checkbox" data-val="Hispanic" id={`patinfo_HIspanic`} name="patinfo_HIspanic" class="filled-in-1" />)}
                            <label for={`patinfo_HIspanic`}>Hispanic &nbsp; &nbsp; </label>

                            {((this.state.ethnicityArray).includes("Native American")) && (
                              <input type="checkbox" checked data-val="Native American" id={`patinfo_NativeAmerican`} name="patinfo_NativeAmerican" class="filled-in-1" />)}
                            {!((this.state.ethnicityArray).includes("Native American")) && (
                              <input type="checkbox" data-val="Native American" id={`patinfo_NativeAmerican`} name="patinfo_NativeAmerican" class="filled-in-1" />)}
                            <label for={`patinfo_NativeAmerican`}>Native American &nbsp; &nbsp; </label>

                            {((this.state.ethnicityArray).includes("African American")) && (
                              <input type="checkbox" checked data-val="African American" id={`patinfo_AfricanAmerican`} name="patinfo_AfricanAmerican" class="filled-in-1" />)}
                            {!((this.state.ethnicityArray).includes("African American")) && (
                              <input type="checkbox" data-val="African American" id={`patinfo_AfricanAmerican`} name="patinfo_AfricanAmerican" class="filled-in-1" />)}
                            <label for={`patinfo_AfricanAmerican`}>African American &nbsp; &nbsp; </label>

                            {((this.state.ethnicityArray).includes("other")) && (
                              <input type="checkbox" checked data-val="other" id={`patinfo_Other`} name="patinfo_Other" class="filled-in-1" />)}
                            {!((this.state.ethnicityArray).includes("other")) && (
                              <input type="checkbox" data-val="other" id={`patinfo_Other`} name="patinfo_Other" class="filled-in-1" />)}
                            <label for={`patinfo_Other`}>Other: &nbsp; &nbsp; </label>

                          </div>
                          <div class={((this.state.ethnicityArray).includes("other")) ? "form-line showFormContent" : "form-line hideFormContent"} id={`patinfo_Othercity_div`}>
                            <input type="text" value={this.state.ethnicityArray[this.state.ethnicityArray.length - 1]} id={`patinfo_OtherEthnicitycity`} name="patinfo_OtherEthnicitycity" class="form-control" placeholder="Other" />
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* OVA1 Patient Information end */}

                    {/* OVA1 Clinical Information */}
                    <div class="row clearfix">
                      <div class="col-md-12"><div class="bg-div"></div><h4 class="panel-titlepdf1">Clinical Information</h4></div>
                    </div>
                    <div class="row clearfix">
                      <div class="col-md-6">
                        <div class="form-group required">
                          <label class="form-label" for={`MenopausalStatus`}>Menopausal Status</label>
                          {/* {this.state.retData.order.tests[0].clinicalData[0].answer} */}
                          {(this.state.retData.order.tests[0]?.clinicalData[0] && this.state.retData.order.tests[0]?.clinicalData[0]?.answer === 'Pre-Menopausal') && (
                            <div id={`ova1_q01`} class="demo-radio-button">
                              <input type="radio" checked id={`clinicinfo_Menopausal_status`} name="clinicinfo_Menopausal_status_" value="Pre-Menopausal" class="filled-in" />
                              <label class="form-label- menopausalStatus" for={`clinicinfo_Menopausal_status`}>Pre-Menopausal &nbsp; </label>
                              <input type="radio" id={`clinicinfo_Menopausal_status2`} name="clinicinfo_Menopausal_status_" value="Post-Menopausal" class=" filled-in" />
                              <label class="form-label- menopausalStatus" for={`clinicinfo_Menopausal_status2`}>Post-Menopausal &nbsp; </label>
                              <input type="radio" id={`clinicinfo_Menopausal_status3`} name="clinicinfo_Menopausal_status_" value="Unknown" class=" filled-in" />
                              <label class="form-label- menopausalStatus" for={`clinicinfo_Menopausal_status3`}>Unknown &nbsp; </label>
                              <input type="radio" id={`clinicinfo_Menopausal_status4`} name="clinicinfo_Menopausal_status_" value="Not Given" class=" filled-in" />
                              <label class="form-label- menopausalStatus" for={`clinicinfo_Menopausal_status3`}>Not Given &nbsp; </label>
                            </div>
                          )}
                          {(this.state.retData.order.tests[0]?.clinicalData[0] && this.state.retData.order.tests[0]?.clinicalData[0]?.answer === 'Post-Menopausal') && (
                            <div id={`ova1_q01`} class="demo-radio-button">
                              <input type="radio" id={`clinicinfo_Menopausal_status`} name="clinicinfo_Menopausal_status_" value="Pre-Menopausal" class=" filled-in" />
                              <label class="form-label menopausalStatus" for={`clinicinfo_Menopausal_status`}>Pre-Menopausal &nbsp; </label>
                              <input type="radio" checked id={`clinicinfo_Menopausal_status2`} name="clinicinfo_Menopausal_status_" value="Post-Menopausal" class=" filled-in" />
                              <label class="form-label menopausalStatus" for={`clinicinfo_Menopausal_status2`}>Post-Menopausal &nbsp; </label>
                              <input type="radio" id={`clinicinfo_Menopausal_status3`} name="clinicinfo_Menopausal_status_" value="Unknown" class=" filled-in" />
                              <label class="form-label- menopausalStatus" for={`clinicinfo_Menopausal_status3`}>Unknown &nbsp; </label>
                              <input type="radio" id={`clinicinfo_Menopausal_status4`} name="clinicinfo_Menopausal_status_" value="Not Given" class=" filled-in" />
                              <label class="form-label- menopausalStatus" for={`clinicinfo_Menopausal_status3`}>Not Given &nbsp; </label>
                            </div>
                          )}
                          {(this.state.retData.order.tests[0]?.clinicalData[0] && this.state.retData.order.tests[0]?.clinicalData[0]?.answer === 'Unknown') && (
                            <div id={`ova1_q01`} class="demo-radio-button">
                              <input type="radio" id={`clinicinfo_Menopausal_status`} name="clinicinfo_Menopausal_status_" value="Pre-Menopausal" class=" filled-in" />
                              <label class="form-label menopausalStatus" for={`clinicinfo_Menopausal_status`}>Pre-Menopausal &nbsp; </label>
                              <input type="radio" id={`clinicinfo_Menopausal_status2`} name="clinicinfo_Menopausal_status_" value="Post-Menopausal" class=" filled-in" />
                              <label class="form-label menopausalStatus" for={`clinicinfo_Menopausal_status2`}>Post-Menopausal &nbsp; </label>
                              <input type="radio" checked id={`clinicinfo_Menopausal_status3`} name="clinicinfo_Menopausal_status_" value="Unknown" class=" filled-in" />
                              <label class="form-label- menopausalStatus" for={`clinicinfo_Menopausal_status3`}>Unknown &nbsp; </label>
                              <input type="radio" id={`clinicinfo_Menopausal_status4`} name="clinicinfo_Menopausal_status_" value="Not Given" class=" filled-in" />
                              <label class="form-label- menopausalStatus" for={`clinicinfo_Menopausal_status3`}>Not Given &nbsp; </label>
                            </div>
                          )}
                          {(this.state.retData.order.tests[0]?.clinicalData[0] && this.state.retData.order.tests[0]?.clinicalData[0]?.answer === 'Not Given') && (
                            <div id={`ova1_q01`} class="demo-radio-button">
                              <input type="radio" id={`clinicinfo_Menopausal_status`} name="clinicinfo_Menopausal_status_" value="Pre-Menopausal" class=" filled-in" />
                              <label class="form-label menopausalStatus" for={`clinicinfo_Menopausal_status`}>Pre-Menopausal &nbsp; </label>
                              <input type="radio" id={`clinicinfo_Menopausal_status2`} name="clinicinfo_Menopausal_status_" value="Post-Menopausal" class=" filled-in" />
                              <label class="form-label menopausalStatus" for={`clinicinfo_Menopausal_status2`}>Post-Menopausal &nbsp; </label>
                              <input type="radio" id={`clinicinfo_Menopausal_status3`} name="clinicinfo_Menopausal_status_" value="Unknown" class=" filled-in" />
                              <label class="form-label- menopausalStatus" for={`clinicinfo_Menopausal_status3`}>Unknown &nbsp; </label>
                              <input type="radio" checked id={`clinicinfo_Menopausal_status4`} name="clinicinfo_Menopausal_status_" value="Not Given" class=" filled-in" />
                              <label class="form-label- menopausalStatus" for={`clinicinfo_Menopausal_status3`}>Not Given &nbsp; </label>
                            </div>
                          )}


                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="form-group required">
                          <label class="form-label" for={`UltrasoundResults`}>Ultrasound Results</label>
                          <div id={`ova1_q02`} class="demo-checkbox">
                            {((this.state.retData.order.tests[0]?.clinicalData[1] && this.state.retData.order.tests[0]?.clinicalData[1]?.answer).includes('Low Risk')) && (
                              <input data-val="Low Risk" checked type="checkbox" id={`clinicinfo_lowrisk`} name="clinicinfo_lowrisk" class=" filled-in-1" />)}
                            {!((this.state.retData.order.tests[0]?.clinicalData[1] && this.state.retData.order.tests[0]?.clinicalData[1]?.answer).includes('Low Risk')) && (
                              <input data-val="Low Risk" type="checkbox" id={`clinicinfo_lowrisk`} name="clinicinfo_lowrisk" class=" filled-in-1" />)}
                            <label for={`clinicinfo_lowrisk`}>Low Risk &nbsp; </label>

                            {((this.state.retData.order.tests[0]?.clinicalData[1] && this.state.retData.order.tests[0]?.clinicalData[1]?.answer).includes('High Risk')) && (
                              <input data-val="High Risk" checked type="checkbox" id={`clinicinfo_highrisk`} name="clinicinfo_highrisk" class=" filled-in-1" />)}
                            {!((this.state.retData.order.tests[0]?.clinicalData[1] && this.state.retData.order.tests[0]?.clinicalData[1]?.answer).includes('High Risk')) && (
                              <input data-val="High Risk" type="checkbox" id={`clinicinfo_highrisk`} name="clinicinfo_highrisk" class=" filled-in-1" />)}
                            <label for={`clinicinfo_highrisk`}>High Risk &nbsp; </label>

                            {((this.state.retData.order.tests[0]?.clinicalData[1] && this.state.retData.order.tests[0]?.clinicalData[1]?.answer).includes('Not Definitive')) && (
                              <input data-val="Not Definitive" checked type="checkbox" id={`clinicinfo_NotDefinitive`} name="clinicinfo_NotDefinitive" class=" filled-in-1" />)}
                            {!((this.state.retData.order.tests[0]?.clinicalData[1] && this.state.retData.order.tests[0]?.clinicalData[1]?.answer).includes('Not Definitive')) && (
                              <input data-val="Not Definitive" type="checkbox" id={`clinicinfo_NotDefinitive`} name="clinicinfo_NotDefinitive" class=" filled-in-1" />)}
                            <label for={`clinicinfo_NotDefinitive`}>Not Definitive &nbsp; </label>

                            {((this.state.retData.order.tests[0]?.clinicalData[1] && this.state.retData.order.tests[0]?.clinicalData[1]?.answer).includes('Not Given')) && (
                              <input data-val="Not Given" checked type="checkbox" id={`clinicinfo_NotGiven`} name="clinicinfo_NotGiven" class=" filled-in-1" />)}
                            {!((this.state.retData.order.tests[0]?.clinicalData[1] && this.state.retData.order.tests[0]?.clinicalData[1]?.answer).includes('Not Given')) && (
                              <input data-val="Not Given" type="checkbox" id={`clinicinfo_NotGiven`} name="clinicinfo_NotGiven" class=" filled-in-1" />)}
                            <label for={`clinicinfo_NotGiven`}>Not Given &nbsp; </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id={`ova1_q03`} class="row clearfix">
                      <div class="col-md-12">
                        <div class="form-group required">
                          <label class="form-label" for={`dob`}>Date of last menstrual period </label>
                          <div class="form-line">
                            <input type="text" id={`clinicinfo_DoLastMentrualPeriod`} value={moment(this.state.retData.order.tests[0]?.clinicalData[2]?.answer).format("YYYY-MM-DD")} class="form-control required" placeholder="Date of last menstrual period" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row clearfix">
                      <div id={`ova1_q04`} class="col-md-4">
                        <div class="form-group ">
                          <label class="form-label" for={`masssize_long`}>Size of mass(longest dimension) </label>
                          <div class="form-line">
                            <input type="text" value={this.state.retData.order.tests[0]?.clinicalData[3] && this.state.retData.order.tests[0]?.clinicalData[3]?.answer} id={`clinicinfo_MassSize_longdime`} class="form-control" placeholder=" longest dimension" />
                          </div>
                        </div>
                      </div>
                      <div id={`ova1_q04_1`} class="col-md-4">
                        <div class="form-group ">
                          <label class="form-label" for={`masssize_height`}>Height </label>
                          <div class="form-line">
                            <input type="text" value={this.state.retData.order.tests[0]?.clinicalData[3] && this.state.retData.order.tests[0]?.clinicalData[3]?.subQuestion[0]?.answer} id={`clinicinfo_MassSize_Height`} class="form-control" placeholder=" Height" />
                          </div>
                        </div>
                      </div>
                      <div id={`ova1_q04_2`} class="col-md-4">
                        <div class="form-group ">
                          <label class="form-label" for={`masssize_weight`}>Weight </label>
                          <div class="form-line">
                            <input type="text" value={this.state.retData.order.tests[0]?.clinicalData[3] && this.state.retData.order.tests[0]?.clinicalData[3]?.subQuestion[1]?.answer} id={`clinicinfo_MassSize_Weight`} class="form-control" placeholder=" Weight" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id={`ova1_q05`} class="row clearfix">
                      <div class="col-md-12">
                        <div class="demo-radio-button">
                          {/* {this.state.retData.order.tests[0].clinicalData[4].answer} */}
                          <label>Does the patient have pain in the abdomen or pelvis?</label>
                          {(this.state.retData.order.tests[0]?.clinicalData[4] && this.state.retData.order.tests[0]?.clinicalData[4]?.answer === 'true') && (<>
                            <input type="radio" checked id={`clinicinfo_pain_abdomen_pelvis`} name="clinicinfo_pain_abdomen_pelvis_" value="yes" class="form-control" />
                            <label class="form-label" for={`clinicinfo_pain_abdomen_pelvis`}>Yes</label>
                            <input type="radio" id={`clinicinfo_pain_abdomen_pelvis2`} name="clinicinfo_pain_abdomen_pelvis_" value="no" class=" form-control" />
                            <label class="form-label" for={`clinicinfo_pain_abdomen_pelvis2`}>No</label>
                          </>)}
                          {(this.state.retData.order.tests[0]?.clinicalData[4] && this.state.retData.order.tests[0]?.clinicalData[4]?.answer === 'false') && (<>
                            <input type="radio" id={`clinicinfo_pain_abdomen_pelvis`} name="clinicinfo_pain_abdomen_pelvis_" value="yes" class="form-control" />
                            <label class="form-label" for={`clinicinfo_pain_abdomen_pelvis`}>Yes</label>
                            <input type="radio" checked id={`clinicinfo_pain_abdomen_pelvis2`} name="clinicinfo_pain_abdomen_pelvis_" value="no" class=" form-control" />
                            <label class="form-label" for={`clinicinfo_pain_abdomen_pelvis2`}>No</label>
                          </>)}

                        </div>
                      </div>
                    </div>
                    <div id={`PatienAbdomenPainArea`} class={(this.state.retData.order.tests[0]?.clinicalData[4] && this.state.retData.order.tests[0]?.clinicalData[4]?.answer == 'true') ? " showPatienAbdomenPainArea" : " hidePatienAbdomenPainArea"}>
                      <div class="row clearfix " >
                        <div class="col-md-6">
                          <label class="form-label" >How many days per month?</label>
                          <div id={`ova1_q05_1`} class="demo-radio-button">
                            {(this.state.retData.order.tests[0]?.clinicalData[4] && this.state.retData.order.tests[0]?.clinicalData[4]?.answer === 'true') && (<>

                              {(this.state.retData.order.tests[0]?.clinicalData[4] && this.state.retData.order.tests[0]?.clinicalData[4]?.subQuestion[0]?.answer == '0-5 days') && (
                                <input type="radio" checked id={`clinicinfo_painAbdomenPelvis_Day_1`} name="clinicinfo_painAbdomenPelvis_Day_" value="0-5 days" class=" form-control" />
                              )}

                              {(this.state.retData.order.tests[0]?.clinicalData[4] && this.state.retData.order.tests[0]?.clinicalData[4]?.subQuestion[0]?.answer != '0-5 days') && (
                                <input type="radio" id={`clinicinfo_painAbdomenPelvis_Day_1`} name="clinicinfo_painAbdomenPelvis_Day_" value="0-5 days" class=" form-control" />
                              )}

                              <label class="form-label" for={`clinicinfo_painAbdomenPelvis_Day_1`}>0-5 days &nbsp; </label>
                            </>)}

                            {(this.state.retData.order.tests[0]?.clinicalData[4] && this.state.retData.order.tests[0]?.clinicalData[4]?.answer === 'true') && (<>

                              {(this.state.retData.order.tests[0]?.clinicalData[4] && this.state.retData.order.tests[0]?.clinicalData[4]?.subQuestion[0]?.answer == '6-12 days') && (
                                <input type="radio" checked id={`clinicinfo_painAbdomenPelvis_Day_2`} name="clinicinfo_painAbdomenPelvis_Day_" value="6-12 days" class=" form-control" />
                              )}

                              {(this.state.retData.order.tests[0]?.clinicalData[4] && this.state.retData.order.tests[0]?.clinicalData[4]?.subQuestion[0]?.answer != '6-12 days') && (
                                <input type="radio" id={`clinicinfo_painAbdomenPelvis_Day_2`} name="clinicinfo_painAbdomenPelvis_Day_" value="6-12 days" class=" form-control" />
                              )}
                              <label class="form-label" for={`clinicinfo_painAbdomenPelvis_Day_2`}>6-12 days &nbsp; </label>
                            </>)}

                            {(this.state.retData.order.tests[0]?.clinicalData[4] && this.state.retData.order.tests[0]?.clinicalData[4]?.answer === 'true') && (<>

                              {(this.state.retData.order.tests[0]?.clinicalData[4] && this.state.retData.order.tests[0]?.clinicalData[4]?.subQuestion[0]?.answer == '> 12 days') && (
                                <input type="radio" checked id={`clinicinfo_painAbdomenPelvis_Day_3`} name="clinicinfo_painAbdomenPelvis_Day_" value="> 12 days" class=" form-control" />
                              )}

                              {(this.state.retData.order.tests[0]?.clinicalData[4] && this.state.retData.order.tests[0]?.clinicalData[4]?.subQuestion[0]?.answer != '> 12 days') && (
                                <input type="radio" id={`clinicinfo_painAbdomenPelvis_Day_3`} name="clinicinfo_painAbdomenPelvis_Day_" value="> 12 days" class=" form-control" />
                              )}
                              <label class="form-label" for={`clinicinfo_painAbdomenPelvis_Day_3`}>&gt; 12 days &nbsp; </label>
                            </>)}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <label class="form-label" for={``}>For how long?</label>
                          <div id={`ova1_q05_2`} class="demo-radio-button">
                            {(this.state.retData.order.tests[0]?.clinicalData[4] && this.state.retData.order.tests[0]?.clinicalData[4]?.answer === 'true') && (<>

                              {(this.state.retData.order.tests[0]?.clinicalData[4] && this.state.retData.order.tests[0]?.clinicalData[4]?.subQuestion[1]?.answer == '< 1 month') && (
                                <input type="radio" checked id={`clinicinfo_painAbdomenPelvis_long_1`} name="clinicinfo_painAbdomenPelvis_long_" value="< 1 month" class=" form-control" />
                              )}

                              {(this.state.retData.order.tests[0]?.clinicalData[4] && this.state.retData.order.tests[0]?.clinicalData[4]?.subQuestion[1]?.answer != '< 1 month') && (
                                <input type="radio" id={`clinicinfo_painAbdomenPelvis_long_1`} name="clinicinfo_painAbdomenPelvis_long_" value="< 1 month" class=" form-control" />
                              )}
                              <label class="form-label" for={`clinicinfo_painAbdomenPelvis_long_1`}>&lt; 1 month &nbsp; </label>
                            </>)}

                            {(this.state.retData.order.tests[0]?.clinicalData[4] && this.state.retData.order.tests[0]?.clinicalData[4]?.answer === 'true') && (<>

                              {(this.state.retData.order.tests[0]?.clinicalData[4] && this.state.retData.order.tests[0]?.clinicalData[4]?.subQuestion[1]?.answer == '1-6 months') && (
                                <input type="radio" checked id={`clinicinfo_painAbdomenPelvis_long_2`} name="clinicinfo_painAbdomenPelvis_long_" value="1-6 months" class=" form-control" />
                              )}

                              {(this.state.retData.order.tests[0]?.clinicalData[4] && this.state.retData.order.tests[0]?.clinicalData[4]?.subQuestion[1]?.answer != '1-6 months') && (
                                <input type="radio" id={`clinicinfo_painAbdomenPelvis_long_2`} name="clinicinfo_painAbdomenPelvis_long_" value="1-6 months" class=" form-control" />
                              )}
                              <label class="form-label" for={`clinicinfo_painAbdomenPelvis_long_2`}>1-6 months &nbsp; </label>
                            </>)}

                            {(this.state.retData.order.tests[0]?.clinicalData[4] && this.state.retData.order.tests[0]?.clinicalData[4]?.answer === 'true') && (<>
                              {(this.state.retData.order.tests[0]?.clinicalData[4] && this.state.retData.order.tests[0]?.clinicalData[4]?.subQuestion[1]?.answer == '7-12 months') && (
                                <input type="radio" checked id={`clinicinfo_painAbdomenPelvis_long_3`} name="clinicinfo_painAbdomenPelvis_long_" value="7-12 months" class=" form-control" />
                              )}

                              {(this.state.retData.order.tests[0]?.clinicalData[4] && this.state.retData.order.tests[0]?.clinicalData[4]?.subQuestion[1]?.answer != '7-12 months') && (
                                <input type="radio" id={`clinicinfo_painAbdomenPelvis_long_3`} name="clinicinfo_painAbdomenPelvis_long_" value="7-12 months" class=" form-control" />
                              )}
                              <label class="form-label" for={`clinicinfo_painAbdomenPelvis_long_3`}>7-12 months &nbsp; </label>
                            </>)}

                            {(this.state.retData.order.tests[0]?.clinicalData[4] && this.state.retData.order.tests[0]?.clinicalData[4]?.answer === 'true') && (<>
                              {(this.state.retData.order.tests[0]?.clinicalData[4] && this.state.retData.order.tests[0]?.clinicalData[4]?.subQuestion[1]?.answer == '> 12 months') && (
                                <input type="radio" checked id={`clinicinfo_painAbdomenPelvis_long_4`} name="clinicinfo_painAbdomenPelvis_long_" value="> 12 months" class=" form-control" />
                              )}

                              {(this.state.retData.order.tests[0]?.clinicalData[4] && this.state.retData.order.tests[0]?.clinicalData[4]?.subQuestion[1]?.answer != '> 12 months') && (
                                <input type="radio" id={`clinicinfo_painAbdomenPelvis_long_4`} name="clinicinfo_painAbdomenPelvis_long_" value="> 12 months" class=" form-control" />
                              )}
                              <label class="form-label" for={`clinicinfo_painAbdomenPelvis_long_4`}>&gt; 12 months &nbsp; </label>
                            </>)}

                          </div>
                        </div>
                      </div>
                    </div>

                    <div id={`ova1_q06`} class="row clearfix">
                      <div class="col-md-12">
                        <div class="demo-radio-button">
                          <label>Does the patient feel full quickly or unable to eat normally?</label>
                          {(this.state.retData.order.tests[0]?.clinicalData[5]?.answer === 'true') && (<>
                            <input type="radio" checked id={`clinicinfo_fullQuickly_unableToe`} name="clinicinfo_fullQuickly_unableToe_" value="yes" class=" form-control" />
                            <label class="form-label" for={`clinicinfo_fullQuickly_unableToe`}>Yes</label>
                            <input type="radio" id={`clinicinfo_fullQuickly_unableToe2`} name="clinicinfo_fullQuickly_unableToe_" value="no" class=" form-control" />
                            <label class="form-label" for={`clinicinfo_fullQuickly_unableToe2`}>No</label>
                          </>)}
                          {(this.state.retData.order.tests[0]?.clinicalData[5]?.answer === 'false') && (<>
                            <input type="radio" id={`clinicinfo_fullQuickly_unableToe`} name="clinicinfo_fullQuickly_unableToe_" value="yes" class=" form-control" />
                            <label class="form-label" for={`clinicinfo_fullQuickly_unableToe`}>Yes</label>
                            <input type="radio" checked id={`clinicinfo_fullQuickly_unableToe2`} name="clinicinfo_fullQuickly_unableToe_" value="no" class=" form-control" />
                            <label class="form-label" for={`clinicinfo_fullQuickly_unableToe2`}>No</label>
                          </>)}
                        </div>
                      </div>
                    </div>
                    <div id={`PatienfullQuicklyUnableToeArea`} class={(this.state.retData.order.tests[0]?.clinicalData[5]?.answer == 'true') ? " showPatienAbdomenPainArea" : " hidePatienAbdomenPainArea"}>
                      <div class="row clearfix" >
                        <div class="col-md-6">
                          <label class="form-label" for={``}>How many days per month?</label>
                          <div id={`ova1_q06_1`} class="demo-radio-button">
                            {(this.state.retData.order.tests[0]?.clinicalData[5]?.answer === 'true') && (<>

                              {(this.state.retData.order.tests[0]?.clinicalData[5]?.subQuestion[0]?.answer == '0-5 days') && (
                                <input type="radio" checked id={`clinicinfo_fullQuicklyUnableToe_Day_1`} name="clinicinfo_fullQuicklyUnableToe_Day_" value="0-5 days" class=" form-control" />
                              )}

                              {(this.state.retData.order.tests[0]?.clinicalData[5]?.subQuestion[0]?.answer != '0-5 days') && (
                                <input type="radio" id={`clinicinfo_fullQuicklyUnableToe_Day_1`} name="clinicinfo_fullQuicklyUnableToe_Day_" value="0-5 days" class=" form-control" />
                              )}
                              <label class="form-label" for={`clinicinfo_fullQuicklyUnableToe_Day_1`}>0-5 days &nbsp; </label>
                            </>)}

                            {(this.state.retData.order.tests[0]?.clinicalData[5]?.answer === 'true') && (<>

                              {(this.state.retData.order.tests[0]?.clinicalData[5]?.subQuestion[0]?.answer == '6-12 days') && (
                                <input type="radio" checked id={`clinicinfo_fullQuicklyUnableToe_Day_2`} name="clinicinfo_fullQuicklyUnableToe_Day_" value="6-12 days" class=" form-control" />
                              )}

                              {(this.state.retData.order.tests[0]?.clinicalData[5]?.subQuestion[0]?.answer != '6-12 days') && (
                                <input type="radio" id={`clinicinfo_fullQuicklyUnableToe_Day_2`} name="clinicinfo_fullQuicklyUnableToe_Day_" value="6-12 days" class=" form-control" />
                              )}
                              <label class="form-label" for={`clinicinfo_fullQuicklyUnableToe_Day_2`}>6-12 days &nbsp; </label>
                            </>)}

                            {(this.state.retData.order.tests[0]?.clinicalData[5]?.answer === 'true') && (<>

                              {(this.state.retData.order.tests[0]?.clinicalData[5]?.subQuestion[0]?.answer == '> 12 days') && (
                                <input type="radio" checked id={`clinicinfo_fullQuicklyUnableToe_Day_3`} name="clinicinfo_fullQuicklyUnableToe_Day_" value="> 12 days" class=" form-control" />
                              )}

                              {(this.state.retData.order.tests[0]?.clinicalData[5]?.subQuestion[0]?.answer != '> 12 days') && (
                                <input type="radio" id={`clinicinfo_fullQuicklyUnableToe_Day_3`} name="clinicinfo_fullQuicklyUnableToe_Day_" value="> 12 days" class=" form-control" />
                              )}
                              <label class="form-label" for={`clinicinfo_fullQuicklyUnableToe_Day_3`}>&gt; 12 days &nbsp; </label>
                            </>)}

                          </div>
                        </div>
                        <div class="col-md-6">
                          <label class="form-label" for={``}>For how long?</label>
                          <div id={`ova1_q06_2`} class="demo-radio-button">
                            {(this.state.retData.order.tests[0]?.clinicalData[5]?.answer === 'true') && (<>

                              {(this.state.retData.order.tests[0]?.clinicalData[5]?.subQuestion[1]?.answer == '< 1 month') && (
                                <input type="radio" checked id={`clinicinfo_fullQuicklyUnableToe_long_1`} name="clinicinfo_fullQuicklyUnableToe_long_" value="< 1 month" class=" form-control" />
                              )}

                              {(this.state.retData.order.tests[0]?.clinicalData[5]?.subQuestion[1]?.answer != '< 1 month') && (
                                <input type="radio" id={`clinicinfo_fullQuicklyUnableToe_long_1`} name="clinicinfo_fullQuicklyUnableToe_long_" value="< 1 month" class=" form-control" />
                              )}
                              <label class="form-label" for={`clinicinfo_fullQuicklyUnableToe_long_1`}>&lt; 1 month &nbsp; </label>
                            </>)}
                            {(this.state.retData.order.tests[0]?.clinicalData[5]?.answer === 'true') && (<>

                              {(this.state.retData.order.tests[0]?.clinicalData[5]?.subQuestion[1]?.answer == '1-6 months') && (
                                <input type="radio" checked id={`clinicinfo_fullQuicklyUnableToe_long_2`} name="clinicinfo_fullQuicklyUnableToe_long_" value="1-6 months" class=" form-control" />
                              )}

                              {(this.state.retData.order.tests[0]?.clinicalData[5]?.subQuestion[1]?.answer != '1-6 months') && (
                                <input type="radio" id={`clinicinfo_fullQuicklyUnableToe_long_2`} name="clinicinfo_fullQuicklyUnableToe_long_" value="1-6 months" class=" form-control" />
                              )}
                              <label class="form-label" for={`clinicinfo_fullQuicklyUnableToe_long_2`}>1-6 months &nbsp; </label>
                            </>)}

                            {(this.state.retData.order.tests[0]?.clinicalData[5]?.answer === 'true') && (<>

                              {(this.state.retData.order.tests[0]?.clinicalData[5]?.subQuestion[1]?.answer == '7-12 months') && (
                                <input type="radio" checked id={`clinicinfo_fullQuicklyUnableToe_long_3`} name="clinicinfo_fullQuicklyUnableToe_long_" value="7-12 months" class=" form-control" />
                              )}
                              {(this.state.retData.order.tests[0]?.clinicalData[5]?.subQuestion[1]?.answer != '7-12 months') && (
                                <input type="radio" id={`clinicinfo_fullQuicklyUnableToe_Day_3`} name="clinicinfo_fullQuicklyUnableToe_Day_" value="> 12 days" class=" form-control" />
                              )}
                              <label class="form-label" for={`clinicinfo_fullQuicklyUnableToe_long_3`}>7-12 months &nbsp; </label>
                            </>)}
                            {(this.state.retData.order.tests[0]?.clinicalData[5]?.answer === 'true') && (<>

                              {(this.state.retData.order.tests[0]?.clinicalData[5]?.subQuestion[1]?.answer == '> 12 months') && (
                                <input type="radio" checked id={`clinicinfo_fullQuicklyUnableToe_long_4`} name="clinicinfo_fullQuicklyUnableToe_long_" value="> 12 months" class=" form-control" />
                              )}

                              {(this.state.retData.order.tests[0]?.clinicalData[5]?.subQuestion[1]?.answer != '> 12 months') && (
                                <input type="radio" id={`clinicinfo_fullQuicklyUnableToe_long_4`} name="clinicinfo_fullQuicklyUnableToe_long_" value="> 12 months" class=" form-control" />
                              )}
                              <label class="form-label" for={`clinicinfo_fullQuicklyUnableToe_long_4`}>&gt; 12 months &nbsp; </label>
                            </>)}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div id={`ova1_q07`} class="row clearfix">
                      <div class="col-md-12">
                        <div class="demo-radio-button">
                          <label>Does the patient experience abdominal bloating or an increased abdominal size?</label>
                          {(this.state.retData.order.tests[0]?.clinicalData[6]?.answer === 'true') && (<>
                            <input type="radio" checked id={`clinicinfo_Expabdominalbloat_IncrSize`} name="clinicinfo_Expabdominalbloat_IncrSize_" value="yes" class=" form-control" />
                            <label class="form-label" for={`clinicinfo_Expabdominalbloat_IncrSize`}>Yes</label>
                            <input type="radio" id={`clinicinfo_Expabdominalbloat_IncrSize2`} name="clinicinfo_Expabdominalbloat_IncrSize_" value="no" class=" form-control" />
                            <label class="form-label" for={`clinicinfo_Expabdominalbloat_IncrSize2`}>No</label>
                          </>)}
                          {(this.state.retData.order.tests[0]?.clinicalData[6]?.answer === 'false') && (<>
                            <input type="radio" id={`clinicinfo_Expabdominalbloat_IncrSize`} name="clinicinfo_Expabdominalbloat_IncrSize_" value="yes" class=" form-control" />
                            <label class="form-label" for={`clinicinfo_Expabdominalbloat_IncrSize`}>Yes</label>
                            <input type="radio" checked id={`clinicinfo_Expabdominalbloat_IncrSize2`} name="clinicinfo_Expabdominalbloat_IncrSize_" value="no" class=" form-control" />
                            <label class="form-label" for={`clinicinfo_Expabdominalbloat_IncrSize2`}>No</label>
                          </>)}
                        </div>
                      </div>
                    </div>
                    <div id={`PatienExpabdominalbloatArea`} class={(this.state.retData.order.tests[0]?.clinicalData[6]?.answer == 'true') ? " showPatienAbdomenPainArea" : " hidePatienAbdomenPainArea"}>
                      <div class="row clearfix" >
                        <div id={`ova1_q07_1`} class="col-md-6">
                          <label class="form-label" for={``}>How many days per month?</label>
                          <div class="demo-radio-button">
                            {(this.state.retData.order.tests[0]?.clinicalData[6]?.answer === 'true') && (<>

                              {(this.state.retData.order.tests[0]?.clinicalData[6]?.subQuestion[0]?.answer == '0-5 days') && (
                                <input type="radio" checked id={`clinicinfo_ExpabdominalbloatIncrSize_Day_1`} name="clinicinfo_ExpabdominalbloatIncrSize_Day_" value="0-5 days" class=" form-control" />
                              )}
                              {(this.state.retData.order.tests[0]?.clinicalData[6]?.subQuestion[0]?.answer != '0-5 days') && (
                                <input type="radio" id={`clinicinfo_ExpabdominalbloatIncrSize_Day_1`} name="clinicinfo_ExpabdominalbloatIncrSize_Day_" value="0-5 days" class=" form-control" />
                              )}
                              <label class="form-label" for={`clinicinfo_ExpabdominalbloatIncrSize_Day_1`}>0-5 days &nbsp; </label>
                            </>)}

                            {(this.state.retData.order.tests[0]?.clinicalData[6]?.answer === 'true') && (<>

                              {(this.state.retData.order.tests[0]?.clinicalData[6]?.subQuestion[0]?.answer == '6-12 days') && (
                                <input type="radio" checked id={`clinicinfo_ExpabdominalbloatIncrSize_Day_2`} name="clinicinfo_ExpabdominalbloatIncrSize_Day_" value="6-12 days" class=" form-control" />
                              )}
                              {(this.state.retData.order.tests[0]?.clinicalData[6]?.subQuestion[0]?.answer != '6-12 days') && (
                                <input type="radio" id={`clinicinfo_ExpabdominalbloatIncrSize_Day_2`} name="clinicinfo_ExpabdominalbloatIncrSize_Day_" value="6-12 days" class=" form-control" />
                              )}
                              <label class="form-label" for={`clinicinfo_ExpabdominalbloatIncrSize_Day_2`}>6-12 days &nbsp; </label>
                            </>)}
                            {(this.state.retData.order.tests[0]?.clinicalData[6]?.answer === 'true') && (<>

                              {(this.state.retData.order.tests[0]?.clinicalData[6]?.subQuestion[0]?.answer == '> 12 days') && (
                                <input type="radio" checked id={`clinicinfo_ExpabdominalbloatIncrSize_Day_3`} name="clinicinfo_ExpabdominalbloatIncrSize_Day_" value="> 12 days" class=" form-control" />
                              )}
                              {(this.state.retData.order.tests[0]?.clinicalData[6]?.subQuestion[0]?.answer != '> 12 days') && (
                                <input type="radio" id={`clinicinfo_ExpabdominalbloatIncrSize_Day_3`} name="clinicinfo_ExpabdominalbloatIncrSize_Day_" value="> 12 days" class=" form-control" />
                              )}
                              <label class="form-label" for={`clinicinfo_ExpabdominalbloatIncrSize_Day_3`}>&gt; 12 days &nbsp; </label>
                            </>)}
                          </div>
                        </div>
                        <div class="col-md-6">
                          <label class="form-label" for={``}>For how long?</label>
                          <div id={`ova1_q07_2`} class="demo-radio-button">
                            {(this.state.retData.order.tests[0]?.clinicalData[6]?.answer === 'true') && (<>

                              {(this.state.retData.order.tests[0]?.clinicalData[6]?.subQuestion[1]?.answer == '< 1 month') && (
                                <input type="radio" checked id={`clinicinfo_ExpabdominalbloatIncrSize_long_1`} name="clinicinfo_ExpabdominalbloatIncrSize_long_" value="< 1 month" class=" form-control" />
                              )}
                              {(this.state.retData.order.tests[0]?.clinicalData[6]?.subQuestion[1]?.answer != '< 1 month') && (
                                <input type="radio" id={`clinicinfo_ExpabdominalbloatIncrSize_long_1`} name="clinicinfo_ExpabdominalbloatIncrSize_long_" value="< 1 month" class=" form-control" />
                              )}
                              <label class="form-label" for={`clinicinfo_ExpabdominalbloatIncrSize_long_1`}>&lt; 1 month &nbsp; </label>
                            </>)}
                            {(this.state.retData.order.tests[0]?.clinicalData[6]?.answer === 'true') && (<>

                              {(this.state.retData.order.tests[0]?.clinicalData[6]?.subQuestion[1]?.answer == '1-6 months') && (
                                <input type="radio" checked id={`clinicinfo_ExpabdominalbloatIncrSize_long_2`} name="clinicinfo_ExpabdominalbloatIncrSize_long_" value="1-6 months" class=" form-control" />
                              )}
                              {(this.state.retData.order.tests[0]?.clinicalData[6]?.subQuestion[1]?.answer != '1-6 months') && (
                                <input type="radio" id={`clinicinfo_ExpabdominalbloatIncrSize_long_2`} name="clinicinfo_ExpabdominalbloatIncrSize_long_" value="1-6 months" class=" form-control" />
                              )}
                              <label class="form-label" for={`clinicinfo_ExpabdominalbloatIncrSize_long_2`}>1-6 months &nbsp; </label>
                            </>)}

                            {(this.state.retData.order.tests[0]?.clinicalData[6]?.answer === 'true') && (<>

                              {(this.state.retData.order.tests[0]?.clinicalData[6]?.subQuestion[1]?.answer == '7-12 months') && (
                                <input type="radio" checked id={`clinicinfo_ExpabdominalbloatIncrSize_long_3`} name="clinicinfo_ExpabdominalbloatIncrSize_long_" value="7-12 months" class=" form-control" />
                              )}
                              {(this.state.retData.order.tests[0]?.clinicalData[6]?.subQuestion[1]?.answer != '7-12 months') && (
                                <input type="radio" id={`clinicinfo_ExpabdominalbloatIncrSize_long_3`} name="clinicinfo_ExpabdominalbloatIncrSize_long_" value="7-12 months" class=" form-control" />
                              )}
                              <label class="form-label" for={`clinicinfo_ExpabdominalbloatIncrSize_long_3`}>7-12 months &nbsp; </label>
                            </>)}

                            {(this.state.retData.order.tests[0]?.clinicalData[6]?.answer === 'true') && (<>

                              {(this.state.retData.order.tests[0]?.clinicalData[6]?.subQuestion[1]?.answer == '> 12 months') && (
                                <input type="radio" checked id={`clinicinfo_ExpabdominalbloatIncrSize_long_4`} name="clinicinfo_ExpabdominalbloatIncrSize_long_" value="> 12 months" class=" form-control" />
                              )}
                              {(this.state.retData.order.tests[0]?.clinicalData[6]?.subQuestion[1]?.answer != '> 12 months') && (
                                <input type="radio" id={`clinicinfo_ExpabdominalbloatIncrSize_long_4`} name="clinicinfo_ExpabdominalbloatIncrSize_long_" value="> 12 months" class=" form-control" />
                              )}
                              <label class="form-label" for={`clinicinfo_ExpabdominalbloatIncrSize_long_4`}>&gt; 12 months &nbsp; </label>
                            </>)}

                          </div>
                        </div>
                      </div>
                    </div>
                    {(this.state.retData.order.isTestOrder == true) &&
                      (<div>
                        <input type="radio" checked class=" filled-in" />
                        <label >Test Order &nbsp; </label>
                      </div>)}

                    {/* OVA1 Clinical Information end */}

                    {/* OVA1 Billing Information  */}
                    <div class="row clearfix">
                      <div class="col-md-12"><div class="bg-div"></div><h4 class="panel-titlepdf1">Billing Information</h4></div>
                    </div>
                    <div class="col-md-12">
                      <div class="form-group required">
                        <label class="form-label" for={`BillingInformation`}>Billing Information</label>
                        <div class="demo-checkbox">
                          {/* {(this.state.retData.order.billingInfo.privateInsurance)} */}
                          {(this.state.retData.order.billingInfo.privateInsurance) && (<input type="checkbox" checked id={`billinfo_PrivateInsurance`} name={`billinfo_PrivateInsurance`} class="filled-in-1" />)}
                          {!(this.state.retData.order.billingInfo.privateInsurance) && (<input type="checkbox" id={`billinfo_PrivateInsurance`} name={`billinfo_PrivateInsurance`} class="filled-in-1" />)}
                          <label for={`billinfo_PrivateInsurance`}>Private Insurance &nbsp; </label>

                          {(this.state.retData.order.billingInfo.medicare) && (<input checked type="checkbox" id={`billinfo_Medicare`} name={`billinfo_Medicare`} class="filled-in-1" />)}
                          {!(this.state.retData.order.billingInfo.medicare) && (<input type="checkbox" id={`billinfo_Medicare`} name={`billinfo_Medicare`} class="filled-in-1" />)}
                          <label for={`billinfo_Medicare`}>Medicare<sup>1</sup> &nbsp; </label>

                          {(this.state.retData.order.billingInfo.patientSelfPay) && (<input checked type="checkbox" id={`billinfo_PatientSelfPay`} name={`billinfo_PatientSelfPay`} class="filled-in-1" />)}
                          {!(this.state.retData.order.billingInfo.patientSelfPay) && (<input type="checkbox" id={`billinfo_PatientSelfPay`} name={`billinfo_PatientSelfPay`} class="filled-in-1" />)}
                          <label for={`billinfo_PatientSelfPay`}>Patient Self-Pay &nbsp; </label>

                          {(this.state.retData.order.billingInfo.medicaid) && (<input type="checkbox" checked id={`billinfo_Medicaid`} name={`billinfo_Medicaid`} class="filled-in-1" />)}
                          {!(this.state.retData.order.billingInfo.medicaid) && (<input type="checkbox" id={`billinfo_Medicaid`} name={`billinfo_Medicaid`} class="filled-in-1" />)}
                          <label for={`billinfo_Medicaid`}>Medicaid &nbsp; </label>

                          {(this.state.retData.order.billingInfo.orderingFacility) && (<input checked type="checkbox" id={`billinfo_OrderingFacility`} name={`billinfo_OrderingFacility`} class="filled-in-1" />)}
                          {!(this.state.retData.order.billingInfo.orderingFacility) && (<input type="checkbox" id={`billinfo_OrderingFacility`} name={`billinfo_OrderingFacility`} class="filled-in-1" />)}
                          <label for={`billinfo_OrderingFacility`}>Ordering Facility (Client Bill) &nbsp; </label>
                        </div>
                      </div>
                    </div>

                    <div class="row clearfix">
                      <div class="col-md-12">
                        <p class="billInfoOva"><sup>1</sup>By ordering this test for your Medicare physician, you are certifying that the physician has met the requirements for use <br />i.e has an ovarian mass, has surgery planned and is over 18 years of age</p>
                        <p id={`title_insurance_container`} class=" hidediv">Insurance Information</p>
                      </div>
                    </div>
                    <div id="primary_insurance_container" class=" hidediv">
                      <div class="row clearfix">
                        <div class="col-md-3">
                          <div class="form-group required">
                            <label class="form-label" for={`PrimaryInsuranceCarrier`}>Primary Insurance Carrier </label>
                            <div class="form-line">
                              <input type="text" id={`billinfo_PrimaryInsuranceCarrier`} value={this.state.retData.order.billingInfo.primaryInsurance.primaryInsuranceCarrier} class="form-control required" placeholder="Primary Insurance Carrier" />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group required">
                            <label class="form-label" for={`MemberID`}>Plan ID# </label>
                            <div class="form-line">
                              <input type="text" value={this.state.retData.order.billingInfo.primaryInsurance.planId} id={`billinfo_planId`} class="form-control required" placeholder="Plan ID" />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group required">
                            <label class="form-label" for={`MemberID`}>Member ID# </label>
                            <div class="form-line">
                              <input type="text" value={this.state.retData.order.billingInfo.primaryInsurance.memberID} id={`billinfo_MemberID`} class="form-control required" placeholder="Member ID" />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group required">
                            <label class="form-label" for={`GroupID`}>Group ID# </label>
                            <div class="form-line">
                              <input type="text" value={this.state.retData.order.billingInfo.primaryInsurance.groupID} id={`billinfo_GroupID`} class="form-control required" placeholder="Group ID#" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="secondary_insurance_container" class=" hidediv">
                      <div class="row clearfix">
                        <div class="col-md-3">
                          <div class="form-group ">
                            <label class="form-label" for={`SecondaryInsuranceCarrier`}>Secondary Insurance Carrier </label>
                            <div class="form-line">
                              <input type="text" value={this.state.retData.order.billingInfo.secondaryInsurance.secondaryInsuranceCarrier} id={`billinfo_SecondaryInsuranceCarrier`} class="form-control " placeholder="Secondary Insurance Carrier" />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group required">
                            <label class="form-label" for={`MemberID`}>Plan ID# </label>
                            <div class="form-line">
                              <input type="text" value={this.state.retData.order.billingInfo.secondaryInsurance.secondaryplanId} id={`billinfo_planId`} class="form-control required" placeholder="Plan ID" />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group ">
                            <label class="form-label" for={`SecondaryMemberID`}>Member ID# </label>
                            <div class="form-line">
                              <input type="text" value={this.state.retData.order.billingInfo.secondaryInsurance.secondaryMemberID} id={`billinfo_SecondaryMemberID`} class="form-control " placeholder="Member ID" />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-3">
                          <div class="form-group ">
                            <label class="form-label" for={`SecondaryGroupID`}>Group ID# </label>
                            <div class="form-line">
                              <input type="text" value={this.state.retData.order.billingInfo.secondaryInsurance.secondaryGroupID} id={`billinfo_SecondaryGroupID`} class="form-control " placeholder="Group ID#" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div id="insured_info_container" class=" hidediv">
                      <div id="insured_info" class="row clearfix">
                        <div class="col-md-4">
                          <div class="form-group required">
                            <label class="form-label" for={`NameofInsured`}>Name of Insured </label>
                            <div class="form-line">
                              <input type="text" value={this.state.retData.order.billingInfo.insuredInfo.nameofInsured} id={`billinfo_NameofInsured`} class="form-control required" placeholder="Name of Insured" />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group required">
                            <label class="form-label" for={`DOB`}>DOB (mm/dd/yyyy) </label>
                            <div class="form-line">
                              <input type="text" value={moment(this.state.retData.order.billingInfo.insuredInfo.ova1_billinfoC_dob).format("YYYY-MM-DD")} id={`billinfoC_dob`} class="form-control required" placeholder="DOB (mm/dd/yyyy)" />
                            </div>
                          </div>
                        </div>
                        <div class="col-md-4">
                          <div class="form-group required">
                            <label class="form-label" for={`Relationshiptoinsured`}>Relationship to insured</label>
                            <div class="form-line">
                              <input type="text" value={this.state.retData.order.billingInfo.insuredInfo.relationshipInsured} id={`billinfo_RelationshipInsured`} class="form-control required" placeholder="Relationship to insured" />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* OVA1 Billing Information end */}

                    {/* OVA1 Diagnosis Codes | ICD-10 Codes */}
                    <div class="row clearfix">
                      <div class="col-md-12"><div class="bg-div"></div><h4 class="panel-titlepdf1">Diagnosis Codes | ICD-10 Codes</h4></div>
                    </div>

                    {(this.state.retData.order.tests.length > 0) && (<>
                      {(this.state.retData.order.tests[0].icd10Code.codes.length > 0) && (<>
                        <div class="row clearfix">
                          <div class="col-md-12">
                            <div class="form-group required">
                              <label class="form-label" for={`DiagnosisCodes`}><b>Diagnosis Codes | ICD-10 Codes (check all that apply)</b></label>
                              <div class="demo-checkbox">
                                <div class="row clearfix">
                                  {(this.state.retData.order.tests[0].icd10Code.codes.includes("N83.201")) && (<>
                                    <div class="col-md-4">
                                      <input type="checkbox" checked data-val="N83.201" id={`diagnosis_UnOcyst_rs`} name={`diagnosis_UnOcyst_rs`} class="filled-in-1" />
                                      <label for={`diagnosis_UnOcyst_rs`}>N83.201 Unspecified ovarian cyst, right side+ &nbsp; </label>
                                    </div>
                                  </>)}
                                  {(this.state.retData.order.tests[0].icd10Code.codes.includes("R19.00")) && (<>
                                    <div class="col-md-4">
                                      <input checked type="checkbox" data-val="R19.00" id={`diagnosis_IntraAbdPelSwell`} name={`diagnosis_IntraAbdPelSwell`} class="filled-in-1" />
                                      <label for={`diagnosis_IntraAbdPelSwell`}>R19.00 Intra-abdominal and pelvic swelling, mass and lump, unspecified site+ &nbsp; </label>
                                    </div>
                                  </>)}
                                  {(this.state.retData.order.tests[0].icd10Code.codes.includes("R19.05")) && (
                                    <div class="col-md-4">
                                      <input type="checkbox" checked data-val="R19.05" id={`diagnosis_Periumbilicalswell`} name={`diagnosis_Periumbilicalswell`} class="filled-in-1" />
                                      <label for={`diagnosis_Periumbilicalswell`}>R19.05 Periumbilical swelling, mass and lump+ &nbsp; </label>
                                    </div>
                                  )}
                                  {(this.state.retData.order.tests[0].icd10Code.codes.includes("N83.202")) && (
                                    <div class="col-md-4">
                                      <input type="checkbox" checked data-val="N83.202" id={`diagnosis_UnOcyst_ls`} name={`diagnosis_UnOcyst_ls`} class="filled-in-1" />
                                      <label for={`diagnosis_UnOcyst_ls`}>N83.202 Unspecified ovarian cyst, left side+ &nbsp; </label>
                                    </div>
                                  )}
                                  {(this.state.retData.order.tests[0].icd10Code.codes.includes("R19.03")) && (
                                    <div class="col-md-4">
                                      <input type="checkbox" checked data-val="R19.03" id={`diagnosis_lowerquadrantSwell`} name={`diagnosis_lowerquadrantSwell`} class="filled-in-1" />
                                      <label for={`diagnosis_lowerquadrantSwell`}>R19.03 Right lower quadrant abdominal swelling, mass and lump &nbsp; </label>
                                    </div>
                                  )}
                                  {(this.state.retData.order.tests[0].icd10Code.codes.includes("R19.09")) && (
                                    <div class="col-md-4">
                                      <input type="checkbox" checked data-val="R19.09" id={`diagnosis_intraabdominalpelvicswell`} name={`diagnosis_intraabdominalpelvicswell`} class="filled-in-1" />
                                      <label for={`diagnosis_intraabdominalpelvicswell`}>R19.09 Other intra-abdominal pelvic swelling, mass and lump+ &nbsp; </label>
                                    </div>
                                  )}
                                  {(this.state.retData.order.tests[0].icd10Code.codes.includes("N83.209")) && (
                                    <div class="col-md-4">
                                      <input type="checkbox" checked data-val="N83.209" id={`diagnosis_UnOcyst`} name={`diagnosis_UnOcyst`} class="filled-in-1" />
                                      <label for={`diagnosis_UnOcyst`}>N83.209 Unspecified ovarian cyst, unspecified side+ &nbsp; </label>
                                    </div>
                                  )}
                                  {(this.state.retData.order.tests[0].icd10Code.codes.includes("R19.04")) && (
                                    <div class="col-md-4">
                                      <input type="checkbox" checked data-val="R19.04" id={`diagnosis_LlQabdominalswelling`} name={`diagnosis_LlQabdominalswelling`} class="filled-in-1" />
                                      <label for={`diagnosis_LlQabdominalswelling`}>R19.04 Left lower quadrant abdominal swelling, mass and lump &nbsp; </label>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>)}
                    </>)}

                    {(this.state.retData.order.tests.length > 1) && (<>
                      {(this.state.retData.order.tests[1].icd10Code.codes.length > 0) && (<>
                        <div class="row clearfix">
                          <div class="col-md-12">
                            <label class="form-label" for="DiagnosisCodes_GHBOCG"><b>COVD Diagnosis Codes | ICD-10 Codes (check all that apply)</b></label>
                          </div>
                        </div>
                        <div class="row clearfix">
                          <div class="col-md-12">
                            <div class="demo-checkbox">
                              <div class="row clearfix">
                                {(this.state.retData.order.tests[1].icd10Code.codes.includes("Z11.59")) && (
                                  <div class="col-md-6">
                                    <input type="checkbox" checked data-val="Z11.59" id={`${this.props.prefix}diagnosis_z1159`} name={`${this.props.prefix}diagnosis_z1159`} class="filled-in-" />
                                    <label for={`${this.props.prefix}diagnosis_z1159`}>Z11.59, Encounter for screening for other viral diseases &nbsp; </label>
                                  </div>
                                )}
                                {(this.state.retData.order.tests[1].icd10Code.codes.includes("Z03.818")) && (
                                  <div class="col-md-6">
                                    <input type="checkbox" checked data-val="Z03.818" id={`${this.props.prefix}diagnosis_z03818`} name={`${this.props.prefix}diagnosis_z03818`} class="filled-in-" />
                                    <label for={`${this.props.prefix}diagnosis_z03818`}>Z03.818, Encounter for observation for suspected exposure to other biological agents ruled out &nbsp; </label>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </>)}
                    </>)}

                    <div class="row clearfix">
                      <div class="col-md-12">
                        <div class="form-group ">
                          <label class="form-label" for={`address`}>Other ICD-10 Codes</label>
                          <div class="form-line">
                            <input type="text" value={this.state.retData.order.tests[0].icd10Code.otherCodes} id={`OtherICD10Codes`} class="form-control " placeholder="Other ICD-10 Codes" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row clearfix">
                      <div class="col-md-12">
                        <p>(This is provided for informational purposes only and is not a guarantee of coverage. It is the provider's responsibility to determine  the appropriate codes)</p>
                      </div>
                    </div>
                    {/* OVA1 Diagnosis Codes | ICD-10 Codes end */}

                    {/* OVA1 Kit Shipping Information */}
                    <div class="row clearfix">
                      <div class="col-md-12"><div class="bg-div"></div><h4 class="panel-titlepdf1">Kit Shipping Information</h4></div>
                    </div>
                    <div class="row clearfix">
                      <div class="col-md-7">
                        <div class="form-group required">
                          <label class="form-label" for="address">Kit Shipping Info</label>
                          <div id="draw_type" class="demo-checkbox">
                            {(this.state.retData.order.tests[0].kitInformation.drawType.includes("My physician needs mobile phlebotomy")) && (<input checked data-val="My physician needs mobile phlebotomy" type="checkbox" id="kitShip_PatMobPhlebotomy" name="kitShip_PatMobPhlebotomy" class="filled-in-1" />)}
                            {!(this.state.retData.order.tests[0].kitInformation.drawType.includes("My physician needs mobile phlebotomy")) && (<input data-val="My physician needs mobile phlebotomy" type="checkbox" id="kitShip_PatMobPhlebotomy" name="kitShip_PatMobPhlebotomy" class="filled-in-1" />)}
                            <label for="kitShip_PatMobPhlebotomy">My physician needs mobile phlebotomy &nbsp; </label>

                            {(this.state.retData.order.tests[0].kitInformation.drawType.includes("Patient will get blood draw at Quest PSC")) && (<input data-val="Patient will get blood draw at Quest PSC" type="checkbox" checked id="kitShip_PatblooddrawQuest" name="kitShip_PatblooddrawQuest" class="filled-in-1" />)}
                            {!(this.state.retData.order.tests[0].kitInformation.drawType.includes("Patient will get blood draw at Quest PSC")) && (<input data-val="Patient will get blood draw at Quest PSC" type="checkbox" id="kitShip_PatblooddrawQuest" name="kitShip_PatblooddrawQuest" class="filled-in-1" />)}
                            <label for="kitShip_PatblooddrawQuest">Patient will get blood draw at Quest PSC &nbsp; </label>

                            {(typeof this.state.retData.order.tests[0].kitInformation.otherInfo != "undefined") && (<input data-val="Other" checked type="checkbox" id="kitShip_PatOthers" name="kitShip_PatOthers" class="filled-in-1" />)}
                            {(typeof this.state.retData.order.tests[0].kitInformation.otherInfo == "undefined") && (<input data-val="Other" type="checkbox" id="kitShip_PatOthers" name="kitShip_PatOthers" class="filled-in-1" />)}
                            <label for="kitShip_PatOthers">Other &nbsp; </label>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-5">
                        <div class="form-group ">
                          <label class="form-label" for="address">&nbsp;</label>
                          <div class="form-line">
                            <input type="text" value={this.state.retData.order.tests[0].kitInformation.otherInfo} id="kitShip_PatOthersInfo" class="form-control " placeholder=" Other info" />
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* OVA1 Kit Shipping Information end */}

                    {/* OVA1 Kit Notes & Certify */}
                    <div class="row clearfix">
                      <div class="col-md-12"><div class="bg-div"></div><h4 class="panel-titlepdf1">Notes & Certify</h4></div>
                    </div>
                    <div class="row clearfix">
                      <div class="col-md-12">
                        <div class="form-group ">
                          <label class="form-label" for="address">Notes</label>
                          <div class="form-line">
                            <textarea id="kitShip_notes" value={this.state.retData.order.tests[0].notes} class="form-control no-resize auto-growth" placeholder="Notes" rows="3"></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row clearfix">
                      <div class="col-md-12">
                        <p><strong> Please note this page is for healthcare professionals. If you are a patient and would like access to one of our tests, please contact your healthcare provider. </strong></p>
                      </div>
                    </div>
                    <div class="row clearfix">
                      <div class="col-md-12">
                        <div class="form-group required">
                          <label class="form-label" for="address">I certify that I am:</label>
                          <div class="demo-checkbox" id="certifytype">
                            {(this.state.retData.order.certifyTypes) && (<>
                              {(this.state.retData.order.certifyTypes.includes("A healthcare provider")) && (<input type="checkbox" checked data-val="A healthcare provider" id="kitShip_healthcareProvider" name="kitShip_healthcareProvider" class="filled-in-1" />)}
                              {!(this.state.retData.order.certifyTypes.includes("A healthcare provider")) && (<input type="checkbox" data-val="A healthcare provider" id="kitShip_healthcareProvider" name="kitShip_healthcareProvider" class="filled-in-1" />)}
                              <label for="kitShip_healthcareProvider">A healthcare provider &nbsp; </label>

                              {(this.state.retData.order.certifyTypes.includes("An Aspira employee ordering on behalf of a healthcare provider")) && (<input type="checkbox" checked data-val="An Aspira employee ordering on behalf of a healthcare provider" id="kitShip_employeehealthcareProvider" name="kitShip_employeehealthcareProvider" class="filled-in-1" />)}
                              {!(this.state.retData.order.certifyTypes.includes("An Aspira employee ordering on behalf of a healthcare provider")) && (<input type="checkbox" data-val="An Aspira employee ordering on behalf of a healthcare provider" id="kitShip_employeehealthcareProvider" name="kitShip_employeehealthcareProvider" class="filled-in-1" />)}
                              <label for="kitShip_employeehealthcareProvider">An Aspira employee ordering on behalf of a healthcare provider &nbsp; </label>

                              {(this.state.retData.order.certifyTypes.includes("An Aspira partner ordering on behalf of a healthcare provider")) && (<input type="checkbox" checked data-val="An Aspira partner ordering on behalf of a healthcare provider" id="kitShip_partnerhealthcareProvider" name="kitShip_partnerhealthcareProvider" class="filled-in-1" />)}
                              {!(this.state.retData.order.certifyTypes.includes("An Aspira partner ordering on behalf of a healthcare provider")) && (<input type="checkbox" data-val="An Aspira partner ordering on behalf of a healthcare provider" id="kitShip_partnerhealthcareProvider" name="kitShip_partnerhealthcareProvider" class="filled-in-1" />)}
                              <label for="kitShip_partnerhealthcareProvider">An Aspira partner ordering on behalf of a healthcare provider &nbsp; </label>
                            </>)}

                            {!(this.state.retData.order.certifyTypes) && (<>
                              <input type="checkbox" data-val="A healthcare provider" id="kitShip_healthcareProvider" name="kitShip_healthcareProvider" class="filled-in-1" />
                              <label for="kitShip_healthcareProvider">A healthcare provider &nbsp; </label>
                              <input type="checkbox" data-val="An Aspira employee ordering on behalf of a healthcare provider" id="kitShip_employeehealthcareProvider" name="kitShip_employeehealthcareProvider" class="filled-in-1" />
                              <label for="kitShip_employeehealthcareProvider">An Aspira employee ordering on behalf of a healthcare provider &nbsp; </label>
                              <input type="checkbox" data-val="An Aspira partner ordering on behalf of a healthcare provider" id="kitShip_partnerhealthcareProvider" name="kitShip_partnerhealthcareProvider" class="filled-in-1" />
                              <label for="kitShip_partnerhealthcareProvider">An Aspira partner ordering on behalf of a healthcare provider &nbsp; </label>
                            </>)}
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* OVA1 Kit Notes & Certify end */}

                  </div>
                </div>
              </div>

            </div>
          </section>
          <div className="col-sm-12 text-center pdf_area">
            <button ref={(_ref) => this.btnGeneratePDF = _ref} id={`ova1_btnViewTRF`} className={"btn btn-primary text-white"} onClick={async () => {
              const theButton = document.getElementById(`ova1_btnViewTRF`);

              theButton.disabled = true;
              theButton.classList.remove("text-white");
              theButton.classList.add("button--loading");

              // this.setState({ loading: false }, async () => {
              // const accessToken = await helper1.getAccessToken(this.context);
              const accessToken = "";
              await helper1.printPDF(this.state.retData.order._id, this.state.retData.order.orderId, accessToken);


              // });
              theButton.textContent = "Download Pdf";
              theButton.classList.add("text-white");
              theButton.classList.remove("button--loading");
              theButton.disabled = false;

              // helper.getPDF(this.state.retData.order.orderId);
            }}>Download Pdf</button>
          </div>
          {/* <div className="col-md-12 text-center">
          <button className={"btn btn-light"} id={`gtcs_btnPrint`} onClick={async () => {

            console.log("printPDF()->this.state.orderId: ", this.state.orderId);
            // helper.printPDF(this.state.orderId);

            let exactHtmlUrl = "";
            let accessToken = "";
            if (process.env.REACT_APP_ENV === 'DEV') {
              exactHtmlUrl = await helper.getTRFHtmlUrl(this.state.retData.order);
              const user = JSON.parse(localStorage.getItem("user"));
              accessToken = user.token;
            }
            else {

              exactHtmlUrl = await helper.getTRFHtmlUrl(this.state.retData.order);
              accessToken = await helper.getAccessToken(this.context);
            }

            const me = this;
            if (exactHtmlUrl) {

              // let htmlRawData = await helper.getTRFHtmlFromUrl(exactHtmlUrl, accessToken);

              // let element = htmlRawData;
              // const doc = new jsPDF("p", "pt", "letter");
              // doc.html(ReactDOMServer.renderToString(element), {
              //   callback: function (doc) {
              //     doc.save(`trf-${me.state.retData.order.orderId}.pdf`);
              //   }
              // });
            }

          }}>SAVE PDF</button>
          {process.env.REACT_APP_ENV == 'DEV' &&
            <button id={`btnPrint`} onClick={async () => {
              let opt = {
                url: `${url_base}/orders/printpdfandemail/${this.state.orderId}`,
                method: "GET",
                data: {},
                headers: {
                  Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFsYW1yZXpvYW51bEBnbWFpbC5jb20iLCJpYXQiOjE1OTIzODQ0NjR9.Vlqn6clmjUMKFdtMd2NOxrJbu8n0PpPSoH_eGXVkSEM`
                }

              };
              console.log("printing PDF with url: ", opt);
              let ret = await axios(opt);
              console.log("printing return data: ", ret.data);

            }}>SAVE PDF AND EMAIL</button>
          }
        </div> */}
        </>
      );
    }
  }
}

// export default TRF_ProviderOrderPortalOva1;