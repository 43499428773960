import React, { PureComponent } from "react";
import PageLoader from "./layout/PageLoader";
import Search from "./layout/Search";
import TopBar from "./layout/TopBar";
import LeftSidebar from "./layout/LeftSidebar";
import RightSidebar from "./layout/RightSidebar";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import NewOrdersBarChart from "./components/NewOrdersBarChart";
import OrdersBreakDownPieChartByCategory from "./components/OrdersBreakDownPieChartByCategory";
import ATATOfOrders from "./components/ATATOfOrders";
import helper from "./utils/helper";
import { protectedResources } from "./authConfig";
import { MsalContext } from "@azure/msal-react";
import axios from "axios";

const API_BASE_URL = helper.getAPIBaseURI();
export default class Dashboard extends PureComponent {
  static contextType = MsalContext

  constructor(props) {
    super(props);
  }

  async componentDidMount() {

    //CP-99
    let user = JSON.parse(localStorage.getItem("user"));


    if (process.env.REACT_APP_ENV !== 'DEV') {
      if (!user) {
        const token = await this.context.instance.acquireTokenSilent({
          scopes: protectedResources.apiOrders.scopes,
          account: this.context.accounts[0]
        });

        console.log("Dashboard->token: ", token);
        ({ data: { user } } = await axios.get(`${API_BASE_URL}/users/current`, {
          headers: {
            "Authorization": `Bearer ${token.accessToken}`
          }
        }));

        localStorage.setItem("user", JSON.stringify(user));
      }


    }


    let orderId = helper.getOrderIdFromQueryString("orderId");
    if (orderId) {
      localStorage.setItem("orderId", orderId);
    }

    orderId = localStorage.getItem("orderId");
    if (orderId) {
      helper.redirectToReportReviewPage(orderId);

      let user = JSON.parse(localStorage.getItem("user"));
      console.log("Dashboard->user: ", user);
    }
    else {
      // let refresh = localStorage.getItem('refresh');
      // if (!refresh) {

      //   window.location.reload();
      //   localStorage.setItem('refresh', true);
      // }
    }

  }
  // helper methods for rendering
  renderGraphs = () => {
    return (
      <div className="row graph-container">
        <div className="col-md-4 graph-outer-box">
          <NewOrdersBarChart />
        </div>
        <div className="col-md-4 graph-outer-box">
          <OrdersBreakDownPieChartByCategory />
        </div>
        <div className="col-md-4 graph-outer-box">
          <ATATOfOrders />
        </div>
      </div>
    );
  }

  render() {
    return (
      <>
        <div>
          {/*  Page Loader  */}
          {/* <PageLoader /> */}
          {/*  Overlay For Sidebars  */}
          <div class="overlay"></div>

          {/* Search */}
          <Search />

          {/* #Top Bar   */}
          <TopBar />

          {/* Left Sidebar */}
          <LeftSidebar />

          {/* Right Sidebar */}
          <RightSidebar />

          {/* Main Content Area */}
          <section class="content home">
            <div class="container-fluid" style={{ background: "white" }}>
              {this.renderGraphs()}
              {/* Footer Content  */}
              {/* <FooterContent/> */}
            </div>{/* container-fluid */}
          </section>

        </div>
      </>
    );
  }
}