import React, { useState, useEffect, PureComponent } from "react";
//import dotenv from "dotenv";
import axios from "axios";
import { message } from 'antd';

import { protectedResources } from "./../authConfig";
import { MsalContext } from "@azure/msal-react";

// import FaxModal from "../mycomponents/modal";
import ProgressBar from "../mycomponents/progressbar";

import DatePicker from 'react-datepicker';
import helper from "../utils/helper";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net-bs/css/dataTables.bootstrap.css";
import moment from "moment";

import Spinner from "../Spinner/Spinner";

const $ = require("jquery");
$.DataTable = require("datatables.net");
// require( 'datatables.net-bs' )( window, $ );

//dotenv.config();

const API_BASE_URL = helper.getAPIBaseURI();

export default class OnHoldReportContent extends PureComponent {
  static contextType = MsalContext;

  constructor(props) {
    super(props);

    this.state = {
      pageTitle: "On Hold Report Orders",
      selectedOrder: null,
      orders: [],
      loading: true,
      showLoader: false,

      showReportContent: false,
      reportSignedUrl: "",
      selectedOrderId: "",

      toastMessage: "",
      list: [],
      toastList: [],

      //For PDF
      numPages: 0,
      pageNumber: 0,
    }

    this.orderDataTable = React.createRef();
    this.$el = null;
  }

  callDataTable = (tableData = []) => {
    // first time, el will be null
    // see: https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs

    this.$el = $(this.orderDataTable);

    this.$el.DataTable({
      pageLength: 25,
      order: [],
      data: tableData,
      columns: [
        {
          title: "QBench Order ID",
          data: "orderId",
          name: "orderId",
          // width: "10px",
          sortable: true,
        },
        {
          title: "Patient First Name",
          data: "Patient.firstName",
          name: "firstName",
          width: "15px",
        },
        {
          title: "Patient Last Name",
          data: "Patient.lastName",
          name: "lastName",
          width: "15px",
        },
        {
          title: "Test Ordered",
          data: null,
          name: "orderedTest",
          width: "150px",
          render: (data, type) => {
            let testName = "";

            // if(data.tests.length===1) {
            //     testName=data.tests[0].testName;
            // }
            // else if(data.tests.)

            data.tests.map((item, i) => {
              if (typeof item.testName != "undefined") {
                //   if (testName == "") {
                //     testName += item.testName;
                //   } else {
                //     testName += "+" + item.testName;
                //   }

                testName = item.testName == "GenetixHBOC1" || item.testName == 'GenetiXCarrierScreening' ? item.genetixCSPanel[0]
                  : item.testName
              }

            });

            //testName = testName.substring(0, testName.length - 1);
            if (type === "display") {
              return `<td>
                                <span class="">${testName}</td>
                            </td> `;
            }
            return data;
          },
        },
        {
          title: "Client Id",
          data: "legacyClientId",
          name: "legacyClientId",
          width: "50px",
          sortable: true,
        },
        {
          title: "Status",
          data: null,
          name: "status",
          width: "200px",
          render: function (data, type) {
            if (type === "display") {
              if (
                data.status === "Approved" ||
                data.status == "Order Authorized"
              ) {
                return `<td>
                                <span class="status_font label label-success">${data.status}</td>
                            </td> `;
              } else if (
                data.status === "Pending" ||
                data.status == "WAITING BILLING"
              ) {
                return `<td>
                                <span class="status_font label label-default">${data.status}</span>
                            </td> `;
              } else if (data.status === "Denied") {
                return `<td>
                                <span class="status_font label label-danger">${data.status}</td>
                            </td> `;
              } else {
                return `<td>
                                <span class="status_font label">${data.status}</td>
                            </td> `;
              }
            }
            return data;
          },
        },
        {
          title: "Report Status",
          data: "reportStatus",
          name: "reportStatus",
        },

        {
          title: "Report Conclusion",
          data: null,
          name: "reportConclusion",
          // width: "150px",
          render: function (data, type) {

            if (type == 'display') {
              if (typeof data.reportConclusion == 'undefined' || !data.reportConclusion) {
                return "";
              }
              else {
                let imgPath = "";
                if (data.reportConclusion == "Cancelled" ||
                  data.reportConclusion == "cancelled") {
                  imgPath = `/icons/cancelled.png`;
                }
                else if (data.reportConclusion == 'Positive' || data.reportConclusion == 'positive') {
                  imgPath = `/icons/Positive.png`;
                }
                else if (data.reportConclusion == 'VUS') {
                  imgPath = `/icons/VUS.png`;
                }
                else {
                  imgPath = `/icons/Negative.png`;
                }
                return `<td>
                                
                                <img style="width: 40; height: 40;" class="positive_negative_image" src="${imgPath}" />
                                </td>
                            </td>`;

              }
            }
            return data;
          },
        },
        {
          title: "",
          name: "",
          data: null,
          sortable: false,
          render: function (data, type) {
            if (type === "display") {
              console.log("uploadDownloadClass->data: ", data);

              let testName_page = "";

              data.tests.map((item, i) => {
                if (typeof item.testName != "undefined") {
                  if (testName_page == "") {
                    testName_page += item.testName;
                  } else {
                    if (item.testName != "COVD")
                      testName_page += "_" + item.testName;
                  }
                }
              });

              return `<td>
              <a style="cursor: pointer;" class="ViewReportClass" data-orderid="${data.orderId}" data-reportid="${data.baylorReportId} data-galenreportid=${data.galenReportId}"><div style="min-width:5.5vw;">View</div></a>
              <a style="cursor: pointer;" class="UnholdReportClass" data-orderid="${data.orderId}" data-reportid="${data.baylorReportId} data-galenreportid=${data.galenReportId}"><div style="min-width:5.5vw;">Unhold</div></a>
                                </td> `;
            }
            return data;
          },
        },
      ],
    });

    const user = JSON.parse(localStorage.getItem("user"));
    const me = this;

    this.$el.on("click", "tr a.ViewReportClass", function () {
      let custom_formatted_id = $(this).data("orderid");
      let report_id = $(this).data("reportid");
      let galen_report_id = $(this).data("galenreportid");

      document.body.classList.add('busy-cursor');

      me.setState(
        { showLoader: false, loading: false, showReportContent: true, },
        async () => {

          $('html, body').animate({
            scrollTop: $("div#reportContent").offset().top
          }, 2000);

          const accessToken = await helper.getAccessToken(this.context);

          if (report_id) {
            axios({
              url: `${API_BASE_URL}/baylor/reports/${custom_formatted_id}/signedUrl`,
              method: "GET",
              headers: {
                "Content-type": "Application/json",
                Authorization: `Bearer ${accessToken}`,
              },
              // data: _payload,
            }).then(({ data }) => {
              console.log("data: ", data);

              document.body.classList.remove('busy-cursor');

              me.setState(
                {

                  reportSignedUrl: data,
                  selectedOrderId: custom_formatted_id,
                  loading: false,
                  showLoader: false,
                  toastMessage: `Successfully Viewed the Report, SEE BOTTOM`,
                },
                () => {
                  //me.showToast("success");
                  message.success(me.state.toastMessage);

                }
              );
            }).catch((error) => {
              document.body.classList.remove('busy-cursor');

              me.setState(
                {
                  loading: false,
                  showLoader: false,
                  toastMessage: `Viewing Report error: ${error}`,
                },
                () => {
                  //me.showToast("success");
                  message.success(me.state.toastMessage);
                }
              );

            })
          }
          else if (galen_report_id) {
            axios({
              url: `${API_BASE_URL}/galen/reports/${custom_formatted_id}/signedUrl`,
              method: "GET",
              headers: {
                "Content-type": "Application/json",
                Authorization: `Bearer ${accessToken}`,
              },
              // data: _payload,
            }).then(({ data }) => {
              console.log("data: ", data);

              document.body.classList.remove('busy-cursor');

              me.setState(
                {

                  reportSignedUrl: data,
                  selectedOrderId: custom_formatted_id,
                  loading: false,
                  showLoader: false,
                  toastMessage: `Successfully Viewed the Report, SEE BOTTOM`,
                },
                () => {
                  //me.showToast("success");
                  message.success(me.state.toastMessage);

                }
              );
            }).catch((error) => {
              document.body.classList.remove('busy-cursor');

              me.setState(
                {
                  loading: false,
                  showLoader: false,
                  toastMessage: `Viewing Report error: ${error}`,
                },
                () => {
                  //me.showToast("success");
                  message.success(me.state.toastMessage);
                }
              );

            })
          }
        });
    });

    this.$el.on("click", "tr a.UnholdReportClass", function () {
      let custom_formatted_id = $(this).data("orderid");
      let report_id = $(this).data("reportid");
      let galen_report_id = $(this).data("galenreportid");

      document.body.classList.add('busy-cursor');

      me.setState(
        { showLoader: false, loading: false, showReportContent: true, },
        async () => {

          $('html, body').animate({
            scrollTop: $("div#reportContent").offset().top
          }, 2000);

          const accessToken = await helper.getAccessToken(me.context);

          if (report_id || galen_report_id) {
            axios({
              url: `${API_BASE_URL}/baylor/reports/${custom_formatted_id}/unhold`,
              method: "GET",
              headers: {
                "Content-type": "Application/json",
                Authorization: `Bearer ${accessToken}`,
              },
              // data: _payload,
            }).then(({ data }) => {
              console.log("data: ", data);

              document.body.classList.remove('busy-cursor');

              me.setState(
                {
                  selectedOrderId: custom_formatted_id,
                  loading: false,
                  showLoader: false,
                  toastMessage: `Successfully UNHOLD the Report`,
                },
                () => {

                  message.success(me.state.toastMessage);

                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                }
              );
            }).catch((error) => {
              document.body.classList.remove('busy-cursor');

              me.setState(
                {
                  loading: false,
                  showLoader: false,
                  toastMessage: `UNHOLD Report error: ${error}`,
                },
                () => {

                  message.error(me.state.toastMessage);
                }
              );

            })
          }

        });
    });
  }

  componentDidMount = async () => {
    let user = JSON.parse(localStorage.getItem("user"));

    const accessToken = await helper.getAccessToken(this.context)
    if (process.env.REACT_APP_ENV !== 'DEV') {
      if (!user) {

        ({ data: { user } } = await axios.get(`${API_BASE_URL}/users/current`, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        }));

        localStorage.setItem("user", JSON.stringify(user));
      }


    }

    const orderType = "report_on_hold";

    const { data } = await axios({
      url: `${API_BASE_URL}/orders?type=${orderType}`,
      method: "GET",
      headers: {
        "Content-type": "Application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    this.setState({ loading: false }, () => {
      this.callDataTable(data.orders);
    });
  }

  render = () => {
    if (!this.state.loading) {
      return (
        <div>
          <div className="block-header">
            <h2>{this.state.pageTitle}</h2>
          </div>
          <div class="row clearfix">
            <div class="col-md-12 col-xs-12">
              <div class="card orders-list">
                <div class="body">
                  <div class="table-responsive">
                    <table
                      className="display"
                      width="100%"
                      ref={(el) => this.orderDataTable = el}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.showLoader && <div>
            <Spinner />
          </div>}
          {this.state.showReportContent && (
            <div id="reportContent">
              <div className="row-clearfix">
                <div className="col-md-12 col-xs-12">
                  <hr />
                  <object
                    data={`${this.state.reportSignedUrl}`}
                    type="application/pdf"
                    width="100%"
                    height="678"
                  >

                    <iframe
                      src={`${this.state.reportSignedUrl}`}
                      width="100%"
                      height="678"
                    >
                      <p>This browser does not support PDF!</p>
                    </iframe>

                  </object>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <div class="block-header">
            <h2>Orders</h2>
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/orders">Orders</a>
              </li>
              <li class="breadcrumb-item active">View All Orders</li>
            </ul>
          </div>
          <div class="row clearfix">
            <div class="col-md-12 col-xs-12">
              <div class="card orders-list">
                <div class="body">
                  <Spinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
