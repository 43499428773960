import React, { useState, useEffect } from "react";
//import dotenv from "dotenv";
import axios from "axios";
import ReactDOM from 'react-dom';
import DeleteModal from "../mycomponents/modal";
import ProgressBar from "../mycomponents/progressbar";
import { protectedResources } from "./../authConfig";
import { MsalContext } from "@azure/msal-react";
import 'datatables.net-dt/css/jquery.dataTables.css';
import 'datatables.net-bs/css/dataTables.bootstrap.css';
import helper from './../utils/helper';

const $ = require('jquery');
$.DataTable = require('datatables.net');
// require( 'datatables.net-bs' )( window, $ );

//dotenv.config();

const API_BASE_URL=helper.getAPIBaseURI();

let me = null;
export default class AuditsContent extends React.PureComponent {
    static contextType = MsalContext;

    constructor(props) {
        super(props);

        this.state = {
            audits: [],
            loading: true,
            showTimer: true,
            showDeleteModal: false,
            deleteAuditId: null,
            deleteAuditName: "",
            showProgressBar: false,
        };


        this.auditDataTable = React.createRef();

        this.handleButtonPress = this.handleButtonPress.bind(this)
        this.handleButtonRelease = this.handleButtonRelease.bind(this)
    }
    handleButtonPress() {
        this.buttonPressTimer = setTimeout(() => this.setState({ showProgressBar: true }), 1000);
    }

    handleButtonRelease() {
        clearTimeout(this.buttonPressTimer);
    }
    async callDataTable(datatotables) {
        // first time, el will be null
        // see: https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs

        console.log("this.auditDataTable: ", this.auditDataTable);

        me = this;
        if (!this.auditDataTable) return;

        // console.log("datatotables: ", datatotables);

        let $el = $(this.auditDataTable);
        //  console.log(this.$el.DataTable);

        // eventFor: {
        //     type: String,
        //     enum: ["Patient", "User", "Order", "Report",],
        //     required: true,
        // },
        // entityOwnerId: String,
        // entityOwnerName: String,
        // typeOfEvent: {
        //     type: String,
        //     enum: ["viewed", "created", "updated", "deleted"],
        // },
        // userId: { type: String, required: false },
        // userName: { type: String, requiredPaths: false, },
        // // patientId: { type: String, required: false },
        // // patientName: { type: String, requiredPaths: false, },

        // createdAt: { type: Date, default: new Date() },
        // updatedAt: { type: Date, default: new Date() },
        
        const accessToken = await helper.getAccessToken(this.context)

        const _columns = [
            { title: "eventFor", data: "eventFor", name: "eventFor", sortable: true, defaultContent: "" },
            { title: "entityOwnerName", data: "entityOwnerName", name: "entityOwnerName", sortable: true, defaultContent: "" },
            { title: "typeOfEvent", data: "typeOfEvent", name: "typeOfEvent", sortable: true, defaultContent: "" },
            { title: "userName", data: "userName", name: "userName", sortable: true, defaultContent: "", },
            { title: "createdAt", data: "createdAt", "name": "createdAt", sortable: true, defaultContent: "" },
            { title: "updatedAt", data: "updatedAt", "name": "updatedAt", sortable: true, defaultContent: "" },

            {
                title: "",
                name: "",
                data: null,
                sortable: false,
                render: function (data, type) {
                    if (type === "display") {
                        console.log("data: ", data);

                        let lockIcon = `<img style="width: 40; height: 40;" src="/icons/icons8-lock-50.svg" />`;
                        if (data.isLocked) {
                            lockIcon = `<img style="width: 40; height: 40;" src="/icons/icons8-padlock-50.svg" />`;
                        }

                        return `<td>
                                <a data-id="${data._id}" id="option-delete" class="rowOption deleteClass"><img src="/icons/icons8-delete-trash-50.svg" /></a>
                            </td> `;

                    }
                    return data;
                }
            }
        ];
        var _ajax = {
            url: `${API_BASE_URL}/audits/datatable`,
            type: "POST",
            headers: {
                // "Content-type": "Application/json",
                Authorization: `Bearer ${accessToken}`
            },
            data: { search: { value: "" }, columns: _columns, order: [{ column: '1', dir: 'asc' }], },
            dataType: 'json',
        };

        let table = $el.DataTable({
            processing: true,
            // serverSide: true,
            // ajax: _ajax,
            columns: _columns,
            "iDisplayLength": 25,
            rowId: "_id",
            data: datatotables,
            // "ordering": true,
            // "paging": true,
            // "dom": 'Bfrtip',
        });

        $el.on('click', 'tr a.deleteClass', function () {
            let item_id = ($(this).data("id"));

            let yes = false;
            me.setState({
                showDeleteModal: true,
                deleteAuditId: item_id,
            })

        });
    }

    componentWillUnmount() {
        $('.data-table-wrapper')
            .find('table')
            .DataTable()
            .destroy(true);
    }
    async componentDidMount() {

        // const user = JSON.parse(localStorage.getItem("user"));
        // console.log("user.token: ", user.token);

        const accessToken = await helper.getAccessToken(this.context);
        axios({
            url: `${API_BASE_URL}/audits`,
            method: "GET",
            headers: {
                "Content-type": "Application/json",
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(({ data }) => {
                console.log("data.audits: ", data.audits);


                this.setState({ loading: false }, () => {
                    this.callDataTable(data.audits);
                });

        // this.setState({ loading: false }, () => {
        //     this.callDataTable(null);
        // });
        //     });
        });
    }

    showModal = e => {
        this.setState({
            showDeleteModal: !this.state.showDeleteModal
        });
    };
    render() {

        if (!this.state.loading) {
            return (
                <div>
                    <div class="block-header">
                        <h2>Audits</h2>
                        {/* <ul class="breadcrumb">
                            <li class="breadcrumb-item"><a href="/orders">Orders</a></li>
                            <li class="breadcrumb-item active">View All Audits</li>
                        </ul> */}
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12 col-xs-12">
                            <div class="card audits-list">
                                <div class="body">
                                    <div class="table-responsive">
                                        <table id="audits_table" className="display" width="100%" ref={el => (this.auditDataTable = el)} />

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <DeleteModal onClose={this.showModal} show={this.state.showDeleteModal}>
                        <div>
                            <span>Hold delete button for 3 seconds in order to confirm delete Audit {this.state.deleteAuditName}</span>
                        </div>
                        <div class="delete-buttons">
                            <button class="btn waves-effect btn-delete"
                                onTouchStart={this.handleButtonPress}
                                onTouchEnd={this.handleButtonRelease}
                                onMouseDown={this.handleButtonPress}
                                onMouseUp={this.handleButtonRelease}
                                onMouseLeave={this.handleButtonRelease}
                            // onClick={this.deleteAudit}
                            >
                                Delete
                            </button>
                            <button class="btn btn-raised g-bg-blue waves-effect" onClick={this.showModal}>
                                Cancel
                            </button>
                        </div>
                        <div>
                            {this.state.showProgressBar && <ProgressBar entity={"auditlog"} id={this.state.deleteAuditId} />}
                        </div>
                    </DeleteModal>

                </div>

            );

        }
        else {
            return <div>
                <div class="block-header">
                    <h2>Audits</h2>
                    {/* <ul class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/orders">Orders</a></li>
                        <li class="breadcrumb-item active">View All Audits</li>
                    </ul> */}
                </div>
                <div class="row clearfix">
                    <div class="col-md-12 col-xs-12">
                        <div class="card audits-list">
                            <div class="body">

                            </div>
                        </div>
                    </div>
                </div>
            </div>;
        }

    }
}
