import React, { PureComponent, useEffect, useState } from "react";

import PropTypes from 'prop-types';
import $ from 'jquery';
import moment from 'moment';
import axios from 'axios';

// import jsPDF from "jspdf";
// import ReactDOMServer from "react-dom/server";
import { protectedResources } from "./authConfig";
import { MsalContext } from "@azure/msal-react";
//includes all 3 forms

import { connect } from "react-redux";
import helper from "./utils/helper";
import helperPortal from './utils/helperPortal';
// import Ova1PlusFormContent from "./form/Ova1PlusFormContent";

import LoadingAnimation from "./portalForm/LoadingAnimation";

//import Signature from "./Signature";
import { message } from "antd";
import SignaturePad from 'react-signature-canvas'
const options = {
    orientation: 'landscape',
    unit: 'in',
    format: [4, 2]
};

const provider_url_base = helperPortal.getAPIBaseURI();
const url_base = helper.getAPIBaseURI();

//for billing logic
const showInsuranceTitle = () => {
    $(`#title_insurance_container`).removeClass(" hidediv");
    $(`#title_insurance_container`).addClass(" showdiv");
};

const hideInsuranceTitle = () => {
    $(`#title_insurance_container`).addClass(" hidediv");
    $(`#title_insurance_container`).removeClass(" showdiv");
};

const showPrimaryAreaClick = () => {
    $(`#primary_insurance_container`).removeClass(" hidediv");
    $(`#primary_insurance_container`).addClass(" showdiv");
};
const hidePrimaryAreaClick = () => {
    $(`#primary_insurance_container`).addClass(" hidediv");
    $(`#primary_insurance_container`).removeClass(" showdiv");
};

const showSecondaryAreaClick = () => {
    $(`#secondary_insurance_container`).removeClass(" hidediv");
    $(`#secondary_insurance_container`).addClass(" showdiv");
};
const hideSecondaryAreaClick = () => {
    $(`#secondary_insurance_container`).addClass(" hidediv");
    $(`#secondary_insurance_container`).removeClass(" showdiv");
};

const showTertiaryAreaClick = () => {
    $(`#tertiary_insurance_container`).removeClass(" hidediv");
    $(`#tertiary_insurance_container`).addClass(" showdiv");
};
const hideTertiaryAreaClick = () => {

    $(`#tertiary_insurance_container`).addClass(" hidediv");
    $(`#tertiary_insurance_container`).removeClass(" showdiv");
};

const showInsuranceInsured = () => {
    $(`#insured_info_container`).removeClass(" hidediv");
    $(`#insured_info_container`).addClass(" showdiv");
};

const hideInsuranceInsured = () => {
    $(`#insured_info_container`).addClass(" hidediv");
    $(`#insured_info_container`).removeClass(" showdiv");
};

//for billing logic

export default class TRF_ProviderOrderPortalGTCS extends PureComponent {
    static contextType = MsalContext
    // const[orderId, setOrderId] = useState("");
    // const[retData, setRetData] = useState({ });
    constructor(props) {
        super(props);

        this.state = {
            orderId: "",
            retData: {},
            isLoading: true,
            errorMessage: null,
            ethnicityArray: {},
            loadingAnimation_: false,
            trimmedDataURL: null,
        }
    }

    loadOrder = async () => {
        return new Promise(async (resolve, reject) => {
            try {
                const temps = document.location.search.split('=');
                console.log("temps: ", temps);

                let _orderId = '';
                if (temps.length > 1) {
                    _orderId = temps[1];
                }

                // setOrderId(_orderId)

                // const accessToken = await helper.getAccessToken(this.context);
                const accessToken = "";
                let opt = {
                    url: `${url_base}/orders/${_orderId}`,
                    method: "GET",
                    data: {},
                    headers: {
                        Authorization: `Bearer ${accessToken}`
                    }

                };
                console.log("loading order with url: ", opt);
                let ret = await axios(opt);
                console.log("ret.data: ", ret.data);


                let retdata = ret.data;
                retdata.order = ret.data.orders[0];
                if (retdata.order) {


                    opt = {
                        url: `${provider_url_base}/physicians/addressperclientid/${retdata.order.physicianId}/${retdata.order._id}`,
                        method: "GET",
                        data: {},
                        headers: {
                            Authorization: `Bearer ${accessToken}`
                        }

                    };
                    console.log("loading order with url: ", opt);
                    let physician = await axios(opt);
                    console.log("physician.data: ", physician.data);

                    opt = {
                        url: `${url_base}/patients/${retdata.order.patientId}`,
                        method: "GET",
                        data: {},
                        headers: {
                            Authorization: `Bearer ${accessToken}`
                        }

                    };
                    console.log("loading order with url: ", opt);
                    let patient = await axios(opt);
                    console.log("patient.data: ", patient.data);

                    if (typeof patient.data.patient !== 'undefined') {
                        retdata.patient = patient.data.patient;
                    }
                    else {
                        retdata.patient = patient.data;
                    }

                    if (typeof physician.data.physician !== 'undefined') {
                        retdata.physician = physician.data.physician;
                    }
                    else {
                        retdata.physician = physician.data;
                    }

                    //ethnicity array
                    const ethnicityArray1 = retdata.patient.ethnicity ? retdata.patient.ethnicity.split(",") : "";

                    let finalImage = retdata.order.physicianSignature;
                    this.setState({
                        orderId: _orderId,
                        retData: retdata,
                        // loading: false,
                        ethnicityArray: ethnicityArray1,
                        trimmedDataURL: finalImage,
                    }, () => {
                        // this.setState({
                        //   loading: true,
                        // })
                        //Billing information          
                        if (this.state.retData.order.billingInfo.medicare) {
                            showInsuranceTitle();
                            showPrimaryAreaClick();
                            hideSecondaryAreaClick();
                            hideTertiaryAreaClick();
                            showInsuranceInsured();
                        }
                        if (this.state.retData.order.billingInfo.patientSelfPay) {
                            hidePrimaryAreaClick();
                            hideSecondaryAreaClick();
                            hideTertiaryAreaClick();
                            hideInsuranceTitle();
                            hideInsuranceInsured();
                        }
                        if (this.state.retData.order.billingInfo.medicaid) {
                            showInsuranceTitle();
                            showPrimaryAreaClick();
                            hideSecondaryAreaClick();
                            hideTertiaryAreaClick();
                            showInsuranceInsured();
                        }
                        if (this.state.retData.order.billingInfo.orderingFacility) {
                            hidePrimaryAreaClick();
                            hideSecondaryAreaClick();
                            hideTertiaryAreaClick();
                            hideInsuranceTitle();
                            hideInsuranceInsured();
                        }
                        if (this.state.retData.order.billingInfo.privateInsurance) {
                            showInsuranceTitle();
                            showPrimaryAreaClick();
                            showSecondaryAreaClick();
                            showTertiaryAreaClick();
                            showInsuranceInsured();
                        }

                        resolve(true);
                    });



                    console.log("retdata: ", retdata);
                }
            } catch (error) {
                console.log("error on loading order: ", error);
                reject(error);
            }
        })
    }

    componentDidMount() {
        this.setState({ isLoading: true, errorMessage: null }, () => {
            try {
                // const theButton = document.getElementById(`ova1_btnViewTRF`);
                // theButton.textContent = "Loading Page";
                // theButton.disabled = true;
                // theButton.classList.remove("text-white");
                // theButton.classList.add("button--loading");

                this.loadOrder()
                    .then(() => {

                        this.setState({ isLoading: false, errorMessage: null }, () => {
                            // theButton.textContent = "Download Pdf";
                            // theButton.classList.add("text-white");
                            // theButton.classList.remove("button--loading");
                            // theButton.disabled = false;
                        });
                    })
                    .catch((error) => {
                        console.log("error on loading ova1: ", error);
                        this.setState({ isLoading: false, errorMessage: error });
                    });
                // this.btnGeneratePDF.click();
            }
            catch (error) {
                console.log("error on loading ova1: ", error);
                this.setState({ isLoading: false, errorMessage: error });
            }
        })
    }

    renderResearchConsentCheckbox() {
        if (this.state.retData.order.researchConsent && this.state.retData.order.researchConsent.length > 0) {
            if (this.state.retData.order.researchConsent == 'Yes') {
                return <>
                    <input type="checkbox" checked id={`${this.props.prefix}researchConsent`} className="filled-in" />
                    <label htmlFor={`${this.props.prefix}researchConsent`}>Consent to research</label>
                </>
            }
            else {
                return <>
                    <input type="checkbox" id={`${this.props.prefix}researchConsent`} className="filled-in" />
                    <label htmlFor={`${this.props.prefix}researchConsent`}>Consent to research</label>
                </>
            }
        }
        else {
            return <>
                <input type="checkbox" id={`${this.props.prefix}researchConsent`} className="filled-in" />
                <label htmlFor={`${this.props.prefix}researchConsent`}>Consent to research</label>
            </>
        }
    }
    renderResearchUseOnlyCheckbox() {
        if (this.state.retData.order.researchUseOnly && this.state.retData.order.researchUseOnly.length > 0) {
            if (this.state.retData.order.researchUseOnly == 'Yes') {
                return <>
                    <input type="checkbox" checked id={`${this.props.prefix}researchUseOnly`} className="filled-in" />
                    <label htmlFor={`${this.props.prefix}researchUseOnly`}>Research use only</label>
                </>
            }
            else {
                return <>
                    <input type="checkbox" id={`${this.props.prefix}researchUseOnly`} className="filled-in" />
                    <label htmlFor={`${this.props.prefix}researchUseOnly`}>Research use only</label>
                </>
            }
        }
        else {
            return <>
                <input type="checkbox" id={`${this.props.prefix}researchUseOnly`} className="filled-in" />
                <label htmlFor={`${this.props.prefix}researchUseOnly`}>Research use only</label>
            </>
        }
    }
    render() {

        if (!this.state.isLoading && this.state.errorMessage) {
            return message.error(this.state.errorMessage.message);
        }
        else if (this.state.isLoading) {
            return null;
        }
        else {
            return (
                <>
                    {/* Content Area */}
                    <div className="col-sm-12 text-center">
                        <button id={`genetix_btnViewTRF`} className={"btn btn-primary text-white"} onClick={async () => {
                            const theButton = document.getElementById(`genetix_btnViewTRF`);

                            theButton.disabled = true;
                            theButton.classList.remove("text-white");
                            theButton.classList.add("button--loading");

                            // const accessToken = await helper.getAccessToken(this.context);
                            const accessToken = "";
                            await helper.printPDF(this.state.retData.order._id, this.state.retData.order.orderId, accessToken);

                            theButton.textContent = "Download Pdf";
                            theButton.classList.add("text-white");
                            theButton.classList.remove("button--loading");
                            theButton.disabled = false;

                            // helper.getPDF(this.state.retData.order.orderId);
                        }}>Download Pdf</button>
                    </div>
                    <section id="printMePdf" class="content home canvas_div_pdf" style={{ marginLeft: 0, marginTop: 0 }}>
                        <div class="container-fluid provider_order provider_order_pdf">

                            {/* form area start */}
                            <div class="row clearfix">
                                <div class="col-md-12">
                                    <div class="body">
                                        {console.log("this.state.retData: ", this.state.retData)}

                                        {/* ASPiRA GENETIX Carrier Screening */}
                                        <div class="row clearfix">
                                            <div class="col-md-12">
                                                <div class="bg-div"></div>
                                                <h4 class="panel-titlepdf1">ASPiRA GENETIX Carrier Screening </h4>
                                            </div>
                                        </div>
                                        <div class="row clearfix">
                                            <div class="col-md-12">
                                                <div class="form-group required">
                                                    <label class="form-label" for="address">ASPiRA GENETIX Carrier Screening (select panel)</label>
                                                    <div class="demo-radio-button">
                                                        <div class="row clearfix">
                                                            {(this.state.retData.order.tests[0].genetixCSPanel.length > 0) && (
                                                                this.state.retData.order.tests[0].genetixCSPanel.map((item, i) => {
                                                                    return <>
                                                                        {(item) && (
                                                                            <div class="col-md-12">
                                                                                <input type="radio" checked data-val={item} id={i} name={item} class="filled-in-1" />
                                                                                <label for={i}>{item} &nbsp; </label>
                                                                            </div>
                                                                        )}
                                                                    </>

                                                                })
                                                            )}

                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="row clearfix">
                                            <div class="col-md-12">
                                                <p>Male partner testing will not include X-linked genes.</p>
                                            </div>
                                        </div>
                                        <div class="row clearfix">
                                            <div class="col-md-12">
                                                <div class="form-group ">
                                                    <label class="form-label" for="address">Indications for testing: check all that apply</label>
                                                    <div class="row clearfix">
                                                        <div class="col-md-5">
                                                            <div class="demo-checkbox">
                                                                {((this.state.retData.order.tests[0].genetixCSIndicationOfTesting.testing).includes("Patient Screening")) && (<>
                                                                    <input type="checkbox" checked data-val="Patient Screening" id="IndicationsTest_PatientScreening" name="IndicationsTest_PatientScreening" class="filled-in-" />
                                                                    <label for="IndicationsTest_PatientScreening">Patient Screening &nbsp; &nbsp; </label>
                                                                </>)}

                                                                {((this.state.retData.order.tests[0].genetixCSIndicationOfTesting.testing).includes("Pregnancy")) && (<>
                                                                    <input type="checkbox" checked data-val="Pregnancy" id="IndicationsTest_Pregnancy" name="IndicationsTest_Pregnancy" class="filled-in-" />
                                                                    <label for="IndicationsTest_Pregnancy">Pregnancy &nbsp; &nbsp; </label>
                                                                </>)}

                                                                {((this.state.retData.order.tests[0].genetixCSIndicationOfTesting.testing).includes("Infertility")) && (<>
                                                                    <input type="checkbox" checked data-val="Infertility" id="IndicationsTest_Infertility" name="IndicationsTest_Infertility" class="filled-in-" />
                                                                    <label for="IndicationsTest_Infertility">Infertility &nbsp;  &nbsp; </label>
                                                                </>)}

                                                                {((this.state.retData.order.tests[0].genetixCSIndicationOfTesting.testing).includes("Other")) && (<>
                                                                    <input type="checkbox" checked data-val="Other" id="IndicationsTest_other" name="IndicationsTest_other" class="filled-in-" />
                                                                    <label for="IndicationsTest_other">Other: </label>
                                                                </>)}
                                                            </div>
                                                        </div>
                                                        <div class="col-md-7">
                                                            <div class="form-line">
                                                                <input type="text" id="IndicationsTest_otherValue" value={this.state.retData.order.tests[0].genetixCSIndicationOfTesting.other} class="form-control " placeholder="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="demo-checkbox">
                                                        {((this.state.retData.order.tests[0].genetixCSIndicationOfTesting.testing).includes("Partner Screening")) && (<>
                                                            <input type="checkbox" checked data-val="Partner Screening" id="IndicationsTest_PartnerScreening" name="IndicationsTest_PartnerScreening" class="filled-in-" />
                                                            <label for="IndicationsTest_PartnerScreening">Partner Screening &nbsp; &nbsp; </label>
                                                        </>)}

                                                        {((this.state.retData.order.tests[0].genetixCSIndicationOfTesting.testing).includes("Family History")) && (<>
                                                            <input type="checkbox" checked data-val="Family History" id="IndicationsTest_FamilyHistory" name="IndicationsTest_FamilyHistory" class="filled-in-" />
                                                            <label for="IndicationsTest_FamilyHistory">Family History &nbsp; &nbsp; </label>
                                                        </>)}

                                                        {((this.state.retData.order.tests[0].genetixCSIndicationOfTesting.testing).includes("Egg/Sperm Donor")) && (<>
                                                            <input type="checkbox" checked data-val="Egg/Sperm Donor" id="IndicationsTest_EggSperm_Donor" name="IndicationsTest_EggSperm_Donor" class="filled-in-" />
                                                            <label for="IndicationsTest_EggSperm_Donor">Egg/Sperm Donor &nbsp;  &nbsp; </label>
                                                        </>)}
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div class="row clearfix">
                                            <div class="col-md-6">
                                                <div class="form-group">
                                                    <label class="form-label" for="DOB">Due date if pregnant </label>
                                                    <div class="form-line">
                                                        <input type="text" id="duedate_pregnant" value={moment(this.state.retData.order.tests[0].dueDate).format("MM/DD/YYYY")} class="form-control " />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group required">
                                                    <label class="form-label" for="NameofInsured">Ultrasound findings / Clinical testing </label>
                                                    <div class="form-line">
                                                        <input type="text" id="ultrasound_findings" value={this.state.retData.order.tests[0].ultrasoundsFindings} class="form-control " placeholder=" Ultrasound findings / Clinical testing" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ASPiRA GENETIX Carrier Screening end */}
                                        {/* GTCS Physician Information */}
                                        <div class="row clearfix">
                                            <div class="col-md-12">
                                                <div class="bg-div"></div>
                                                <h4 class="panel-titlepdf1">Physician Information</h4>
                                            </div>
                                        </div>
                                        <div class="row clearfix">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    <label class="form-label" for="ordered_before1">Organization/Account #</label>
                                                    <div class="form-line">
                                                        <input type="text" value={`${this.state.retData.order.legacyName} / ${this.state.retData.order.legacyClientId}`} id="phyinfo_Organization_account" class="form-control" placeholder="Organization/Account #" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row clearfix">
                                            <div class="col-md-3" >
                                                <div class="form-group required">
                                                    <label class="form-label" for="ordered_before1">First Name</label>
                                                    <div class="form-line">
                                                        <input type="text" id={`phyinfo_first_name`} value={this.state.retData.physician.firstName} class="form-control required" placeholder="First Name" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3" >
                                                <div class="form-group required">
                                                    <label class="form-label" for="ordered_before1">Last Name</label>
                                                    <div class="form-line">
                                                        <input type="text" id={`phyinfo_last_name`} value={this.state.retData.physician.lastName} class="form-control required" placeholder="Last Name" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-3" >
                                                <div class="form-group required">
                                                    <label class="form-label" for="ordered_before1">Email</label>
                                                    <div class="form-line">
                                                        <input type="text" id={`phyinfo_email`} value={this.state.retData.physician.email} class="form-control required" placeholder="Email" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3" >
                                                <div class="form-group required">
                                                    <label class="form-label" for="ordered_before1">NPI</label>
                                                    <div class="form-line">
                                                        <input type="text" id={`phyinfo_NPI`} value={this.state.retData.physician.npiNumber} class="form-control required" placeholder="NPI" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row clearfix">
                                            <div class="col-md-6">
                                                <div class="form-group required">
                                                    <label class="form-label" for="ordered_before1">Address</label>
                                                    <div class="form-line">
                                                        <input type="text" id={`phyinfoC_address`} value={this.state.retData.physician.addresses[0].address_1} class="form-control required" placeholder="Address" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group required">
                                                    <label class="form-label" for="ordered_before1">Practice Location</label>
                                                    <div class="form-line">
                                                        <input type="text" id={`phyinfoC_practiceLocation`} value={this.state.retData.physician.practiceLocationPlain} class="form-control required" placeholder="Practice Location" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row clearfix">
                                            <div class="col-md-4">
                                                <div class="form-group ">
                                                    <label class="form-label" for="ordered_before1">City </label>
                                                    <div class="form-line">
                                                        <input type="text" value={this.state.retData.physician.addresses[0].city} id={`phyinfoC_city`} class="form-control" placeholder="City" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group ">
                                                    <label class="form-label" for="ordered_before1">State</label>
                                                    <div class="form-line">
                                                        <input type="text" id={`phyinfoC_state`} value={this.state.retData.physician.addresses[0].state} class="form-control" placeholder="State" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group ">
                                                    <label class="form-label" for="ordered_before1">Zip Code</label>
                                                    <div class="form-line">
                                                        <input type="email" id={`phyinfoC_zipCode`} value={this.state.retData.physician.addresses[0].zipCode} class="form-control" placeholder="Zip Code" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row clearfix">
                                            <div class="col-md-4">
                                                <div class="form-group required">
                                                    <label class="form-label" for="ordered_before1">Phone </label>
                                                    <div class="form-line">
                                                        <input type="text" id="phyinfoC_Phone" value={this.state.retData.physician.addresses[0].phoneNo} class="form-control required" placeholder="Phone" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label class="form-label" for="ordered_before1">Fax</label>
                                                    <div class="form-line">
                                                        <input type="text" id={`phyinfoC_fax`} value={this.state.retData.physician.addresses[0].fax} class="form-control" placeholder="Fax" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group ">
                                                    <label class="form-label" for="ordered_before1">Fax copy to</label>
                                                    <div class="form-line">
                                                        <input type="email" id={`phyinfoC_Faxcopyto`} value={this.state.retData.physician.addresses[0].faxCopyTo} class="form-control required" placeholder="Fax copy to" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row clearfix">
                                            <div class="col-md-12">
                                                <div class="demo-checkbox ">
                                                    <input type="checkbox" id={`phyinfoC_confirmation`} name="phyinfoC_confirmation" class="filled-in-1 required" />
                                                    <label for={`phyinfoC_confirmation`} class="form-label">By checking the box below I am confirming that I have provided informed consent for this test. </label>
                                                </div>
                                            </div>
                                        </div>
                                        {/* GTCS Physician Information end */}

                                        {/* GTCS Patient Information */}
                                        {/* {this.state.retData.patient.organizationAccount} */}
                                        <div class="row clearfix">
                                            <div class="col-md-12">
                                                <div class="bg-div"></div>
                                                <h4 class="panel-titlepdf1">Patient Information</h4>
                                            </div>
                                        </div>
                                        <div class="row clearfix">
                                            <div class="col-md-6">
                                                <div class="form-group required">
                                                    <label class="form-label" for={`patinfo_firstname`}>First Name</label>
                                                    <div class="form-line">
                                                        <input type="text" id={`patinfo_firstname`} value={this.state.retData.patient.firstName} class="form-control required" placeholder="First Name" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group required">
                                                    <label class="form-label" for={`patinfo_lastname`}>Last Name</label>
                                                    <div class="form-line">
                                                        <input type="text" id={`patinfo_lastname`} value={this.state.retData.patient.lastName} class="form-control required" placeholder="Last Name" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row clearfix">
                                            <div class="col-md-6">
                                                <div class="form-group required">
                                                    <label class="form-label" for={`patinfo_address1`}>Address 1</label>
                                                    <div class="form-line">
                                                        <input type="text" id={`patinfo_address1`} value={this.state.retData.patient.address.address_1} class="form-control required" placeholder="Address 1" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <div class="form-group required">
                                                    <label class="form-label" for={`patinfo_address2`}>Address 2</label>
                                                    <div class="form-line">
                                                        <input type="text" id={`patinfo_address2`} value={this.state.retData.patient.address.address_2} class="form-control required" placeholder="Address 2" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row clearfix">
                                            <div class="col-md-4">
                                                <div class="form-group required">
                                                    <label class="form-label" for={`patinfo_city`}>City</label>
                                                    <div class="form-line">
                                                        <input type="text" id={`patinfo_city`} value={this.state.retData.patient.address.city} class="form-control required" placeholder="City" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group required">
                                                    <label class="form-label" for="state">State</label>
                                                    <div class="form-line">
                                                        <input type="text" id={`patinfo_city`} value={this.state.retData.patient.address.state} class="form-control required" placeholder="State" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group required">
                                                    <label class="form-label" for={`patinfo_zipcode`}>Zip Code</label>
                                                    <div class="form-line">
                                                        <input type="text" id={`patinfo_zipcode`} value={this.state.retData.patient.address.zipCode} class="form-control required" placeholder="Zip Code" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row clearfix">
                                            <div class="col-md-4">
                                                <div class="form-group required">
                                                    <label class="form-label" for={`patinfo_DOB`}>DOB (mm/dd/yyyy)</label>
                                                    <div class="form-line">
                                                        <input type="text" id={`patinfo_DOB`} value={moment(this.state.retData.patient.dob).format("MM/DD/YYYY")} class="form-control required" placeholder="DOB" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group required">
                                                    <label class="form-label" for="PhoneNumber">Phone Number</label>
                                                    <div class="form-line">
                                                        <input type="text" id={`patinfo_PhoneNumber`} value={this.state.retData.patient.phoneNo} class="form-control required" placeholder="Phone Number" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group required">
                                                    <label class="form-label" for={`patinfo_emailaddress`}>Email Address</label>
                                                    <div class="form-line">
                                                        <input type="email" value={this.state.retData.patient.email} id={`patinfo_emailaddress`} class="form-control required" placeholder="Email Address" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* {this.state.retData.patient.ethnicity}                */}
                                        {/* {console.log("ethin: "+this.state.ethnicityArray)} */}
                                        {/* {((this.state.ethnicityArray).includes("Caucasian")) ? "y" : "n" } */}
                                        <div class="row clearfix">
                                            <div class="col-md-6">
                                                <div class="form-group required">
                                                    <label class="form-label" for={`patinfo_gender`}>Gender</label>
                                                    {/* {this.state.retData.patient.gender} */}
                                                    <div class="demo-radio-button">
                                                        {((this.state.retData.patient.gender).toLowerCase() === 'male') && (<>
                                                            <input type="radio" checked id={`patinfo_gender`} name="patinfo_gender_" value="male" class=" form-control" />
                                                            <label class="form-label gender" for={`patinfo_gender`}>Male &nbsp; </label>
                                                        </>)}
                                                        {((this.state.retData.patient.gender).toLowerCase() != 'male') && (<>
                                                            <input type="radio" id={`patinfo_gender`} name="patinfo_gender_" value="male" class=" form-control" />
                                                            <label class="form-label gender" for={`patinfo_gender`}>Male &nbsp; </label>
                                                        </>)}

                                                        {((this.state.retData.patient.gender).toLowerCase() === 'female') && (<>
                                                            <input type="radio" checked id={`patinfo_gender2`} name="patinfo_gender_" value="female" class=" form-control" />
                                                            <label class="form-label gender" for={`patinfo_gender2`}>Female &nbsp; </label>
                                                        </>)}
                                                        {((this.state.retData.patient.gender).toLowerCase() != 'female') && (<>
                                                            <input type="radio" id={`patinfo_gender2`} name="patinfo_gender_" value="female" class=" form-control" />
                                                            <label class="form-label gender" for={`patinfo_gender2`}>Female &nbsp; </label>
                                                        </>)}
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                        <div class="row clearfix">
                                            <div class="col-md-12">
                                                <div class="form-group required">
                                                    <label class="form-label" for="Ethnicity">Ethnicity (Check all that apply)</label>
                                                    <div class="demo-radio-button">
                                                        {((this.state.ethnicityArray).includes("Caucasian")) && (
                                                            <input type="radio" checked data-val="Caucasian" id={`patinfo_Caucasian`} name="patinfo_Caucasian_" class="form-control" />)}
                                                        {!((this.state.ethnicityArray).includes("Caucasian")) && (
                                                            <input type="radio" data-val="Caucasian" id={`patinfo_Caucasian`} name="patinfo_Caucasian_" class="form-control" />)}
                                                        <label for={`patinfo_Caucasian`} class="form-label gender">Caucasian &nbsp; &nbsp; </label>

                                                        {((this.state.ethnicityArray).includes("Ashkenazi Jewish")) && (
                                                            <input type="radio" checked data-val="Ashkenazi Jewish" id={`patinfo_AshkenaziJewish`} name="patinfo_Caucasian_" class="form-control" />)}
                                                        {!((this.state.ethnicityArray).includes("Ashkenazi Jewish")) && (
                                                            <input type="radio" data-val="Ashkenazi Jewish" id={`patinfo_AshkenaziJewish`} name="patinfo_Caucasian_" class="form-control" />)}
                                                        <label for={`patinfo_AshkenaziJewish`} class="form-label gender">Ashkenazi Jewish &nbsp; &nbsp; </label>

                                                        {((this.state.ethnicityArray).includes("Sephardic Jewish")) && (
                                                            <input type="radio" checked data-val="Sephardic Jewish" id={`patinfo_SephardicJewish`} name="patinfo_Caucasian_" class="form-control" />)}
                                                        {!((this.state.ethnicityArray).includes("Sephardic Jewish")) && (
                                                            <input type="radio" data-val="Sephardic Jewish" id={`patinfo_SephardicJewish`} name="patinfo_Caucasian_" class="form-control" />)}
                                                        <label for={`patinfo_SephardicJewish`} class="form-label gender">Sephardic Jewish &nbsp; &nbsp; </label>

                                                        {((this.state.ethnicityArray).includes("Asian")) && (
                                                            <input type="radio" checked data-val="Asian" id={`patinfo_Asian`} name="patinfo_Caucasian_" class="form-control" />)}
                                                        {!((this.state.ethnicityArray).includes("Asian")) && (
                                                            <input type="radio" data-val="Asian" id={`patinfo_Asian`} name="patinfo_Caucasian_" class="form-control" />)}
                                                        <label for={`patinfo_Asian`} class="form-label gender">Asian &nbsp; &nbsp; </label>

                                                        {((this.state.ethnicityArray).includes("Hispanic")) && (
                                                            <input type="radio" checked data-val="Hispanic" id={`patinfo_HIspanic`} name="patinfo_Caucasian_" class="form-control" />)}
                                                        {!((this.state.ethnicityArray).includes("Hispanic")) && (
                                                            <input type="radio" data-val="Hispanic" id={`patinfo_HIspanic`} name="patinfo_Caucasian_" class="form-control" />)}
                                                        <label for={`patinfo_HIspanic`} class="form-label gender">Hispanic &nbsp; &nbsp; </label>

                                                        {((this.state.ethnicityArray).includes("Native American")) && (
                                                            <input type="radio" checked data-val="Native American" id={`patinfo_NativeAmerican`} name="patinfo_Caucasian_" class="form-control" />)}
                                                        {!((this.state.ethnicityArray).includes("Native American")) && (
                                                            <input type="radio" data-val="Native American" id={`patinfo_NativeAmerican`} name="patinfo_Caucasian_" class="form-control" />)}
                                                        <label for={`patinfo_NativeAmerican`} class="form-label gender">Native American &nbsp; &nbsp; </label>

                                                        {((this.state.ethnicityArray).includes("African American")) && (
                                                            <input type="radio" checked data-val="African American" id={`patinfo_AfricanAmerican`} name="patinfo_Caucasian_" class="form-control" />)}
                                                        {!((this.state.ethnicityArray).includes("African American")) && (
                                                            <input type="radio" data-val="African American" id={`patinfo_AfricanAmerican`} name="patinfo_Caucasian_" class="form-control" />)}
                                                        <label for={`patinfo_AfricanAmerican`} class="form-label gender">African American &nbsp; &nbsp; </label>

                                                        {((this.state.ethnicityArray).includes("N/A")) && (
                                                            <input type="radio" checked data-val="N/A" id={`patinfo_na`} name="patinfo_Caucasian_" class="form-control" />)}
                                                        {!((this.state.ethnicityArray).includes("N/A")) && (
                                                            <input type="radio" data-val="N/A" id={`patinfo_na`} name="patinfo_Caucasian_" class="form-control" />)}
                                                        <label for={`patinfo_na`} class="form-label gender">N/A &nbsp; &nbsp; </label>

                                                        {((this.state.ethnicityArray).includes("Other")) && (
                                                            <input type="radio" checked data-val="other" id={`patinfo_Other`} name="patinfo_Caucasian_" class="form-control" />)}
                                                        {!((this.state.ethnicityArray).includes("Other")) && (
                                                            <input type="radio" data-val="other" id={`patinfo_Other`} name="patinfo_Caucasian_" class="form-control" />)}
                                                        <label for={`patinfo_Other`} class="form-label gender">Other: &nbsp; &nbsp; </label>


                                                    </div>
                                                    <div class={((this.state.ethnicityArray).includes("Other")) ? "form-line showFormContent" : "form-line hideFormContent"} id={`patinfo_Othercity_div`}>
                                                        <input type="text" value={this.state.ethnicityArray[this.state.ethnicityArray.length - 1]} id={`patinfo_OtherEthnicitycity`} name="patinfo_OtherEthnicitycity" class="form-control" placeholder="Other" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* GTCS Patient Information end */}

                                        {/* GTCS Billing Information  */}
                                        <div class="row clearfix">
                                            <div class="col-md-12"><div class="bg-div"></div><h4 class="panel-titlepdf1">Billing Information</h4></div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="form-group required">
                                                <label class="form-label" for={`BillingInformation`}>Billing Information</label>
                                                <div class="demo-checkbox">{(this.state.retData.order.billingInfo.privateInsurance)}
                                                    {(this.state.retData.order.billingInfo.privateInsurance) && (<input type="checkbox" checked id={`billinfo_PrivateInsurance`} name={`billinfo_PrivateInsurance`} class="filled-in-1" />)}
                                                    {!(this.state.retData.order.billingInfo.privateInsurance) && (<input type="checkbox" id={`billinfo_PrivateInsurance`} name={`billinfo_PrivateInsurance`} class="filled-in-1" />)}
                                                    <label for={`billinfo_PrivateInsurance`}>Private Insurance &nbsp; </label>

                                                    {(this.state.retData.order.billingInfo.medicare) && (<input checked type="checkbox" id={`billinfo_Medicare`} name={`billinfo_Medicare`} class="filled-in-1" />)}
                                                    {!(this.state.retData.order.billingInfo.medicare) && (<input type="checkbox" id={`billinfo_Medicare`} name={`billinfo_Medicare`} class="filled-in-1" />)}
                                                    <label for={`billinfo_Medicare`}>Medicare<sup>1</sup> &nbsp; </label>

                                                    {(this.state.retData.order.billingInfo.patientSelfPay) && (<input checked type="checkbox" id={`billinfo_PatientSelfPay`} name={`billinfo_PatientSelfPay`} class="filled-in-1" />)}
                                                    {!(this.state.retData.order.billingInfo.patientSelfPay) && (<input type="checkbox" id={`billinfo_PatientSelfPay`} name={`billinfo_PatientSelfPay`} class="filled-in-1" />)}
                                                    <label for={`billinfo_PatientSelfPay`}>Patient Self-Pay &nbsp; </label>

                                                    {(this.state.retData.order.billingInfo.medicaid) && (<input type="checkbox" checked id={`billinfo_Medicaid`} name={`billinfo_Medicaid`} class="filled-in-1" />)}
                                                    {!(this.state.retData.order.billingInfo.medicaid) && (<input type="checkbox" id={`billinfo_Medicaid`} name={`billinfo_Medicaid`} class="filled-in-1" />)}
                                                    <label for={`billinfo_Medicaid`}>Medicaid &nbsp; </label>

                                                    {(this.state.retData.order.billingInfo.orderingFacility) && (<input checked type="checkbox" id={`billinfo_OrderingFacility`} name={`billinfo_OrderingFacility`} class="filled-in-1" />)}
                                                    {!(this.state.retData.order.billingInfo.orderingFacility) && (<input type="checkbox" id={`billinfo_OrderingFacility`} name={`billinfo_OrderingFacility`} class="filled-in-1" />)}
                                                    <label for={`billinfo_OrderingFacility`}>Ordering Facility (Client Bill) &nbsp; </label>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row clearfix">
                                            <div class="col-md-12">
                                                <p class="billInfoOva"><sup>1</sup>By ordering this test for your Medicare physician, you are certifying that the physician has met the requirements for use <br />i.e has an ovarian mass, has surgery planned and is over 18 years of age</p>
                                                <p id={`title_insurance_container`} class=" hidediv">Insurance Information</p>
                                            </div>
                                        </div>
                                        <div id="primary_insurance_container" class=" hidediv">
                                            <div class="row clearfix">
                                                <div class="col-md-4">
                                                    <div class="form-group required">
                                                        <label class="form-label" for={`PrimaryInsuranceCarrier`}>Primary Insurance Carrier </label>
                                                        <div class="form-line">
                                                            <input type="text" id={`billinfo_PrimaryInsuranceCarrier`} value={this.state.retData.order.billingInfo.primaryInsurance.primaryInsuranceCarrier} class="form-control required" placeholder="Primary Insurance Carrier" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group required">
                                                        <label class="form-label" for={`MemberID`}>Member ID# </label>
                                                        <div class="form-line">
                                                            <input type="text" value={this.state.retData.order.billingInfo.primaryInsurance.memberID} id={`billinfo_MemberID`} class="form-control required" placeholder="Member ID" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group required">
                                                        <label class="form-label" for={`GroupID`}>Group ID# </label>
                                                        <div class="form-line">
                                                            <input type="text" value={this.state.retData.order.billingInfo.primaryInsurance.groupID} id={`billinfo_GroupID`} class="form-control required" placeholder="Group ID#" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="secondary_insurance_container" class=" hidediv">
                                            <div class="row clearfix">
                                                <div class="col-md-4">
                                                    <div class="form-group ">
                                                        <label class="form-label" for={`SecondaryInsuranceCarrier`}>Secondary Insurance Carrier </label>
                                                        <div class="form-line">
                                                            <input type="text" value={this.state.retData.order.billingInfo.secondaryInsurance.secondaryInsuranceCarrier} id={`billinfo_SecondaryInsuranceCarrier`} class="form-control " placeholder="Secondary Insurance Carrier" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group ">
                                                        <label class="form-label" for={`SecondaryMemberID`}>Member ID# </label>
                                                        <div class="form-line">
                                                            <input type="text" value={this.state.retData.order.billingInfo.secondaryInsurance.secondaryMemberID} id={`billinfo_SecondaryMemberID`} class="form-control " placeholder="Member ID" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group ">
                                                        <label class="form-label" for={`SecondaryGroupID`}>Group ID# </label>
                                                        <div class="form-line">
                                                            <input type="text" value={this.state.retData.order.billingInfo.secondaryInsurance.secondaryGroupID} id={`billinfo_SecondaryGroupID`} class="form-control " placeholder="Group ID#" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="tertiary_insurance_container" class=" hidediv">
                                            <div class="row clearfix">
                                                <div class="col-md-4">
                                                    <div class="form-group ">
                                                        <label class="form-label" for={`TertiaryInsuranceCarrier`}>Tertiary Insurance Carrier </label>
                                                        <div class="form-line">
                                                            <input
                                                                type="text"
                                                                value={(typeof this.state.retData.order.billingInfo.tertiaryInsurance != 'undefined') ? this.state.retData.order.billingInfo.tertiaryInsurance.tertiaryInsuranceCarrier : ''}
                                                                id={`billinfo_TertiaryInsuranceCarrier`}
                                                                class="form-control "
                                                                placeholder="Tertiary Insurance Carrier"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group ">
                                                        <label class="form-label" for={`TertiaryMemberID`}>Member ID# </label>
                                                        <div class="form-line">
                                                            <input
                                                                type="text"
                                                                value={(typeof this.state.retData.order.billingInfo.tertiaryInsurance != 'undefined') ? this.state.retData.order.billingInfo.tertiaryInsurance.tertiaryMemberID : ''}
                                                                id={`billinfo_TertiaryMemberID`}
                                                                class="form-control "
                                                                placeholder="Member ID"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group ">
                                                        <label class="form-label" for={`TertiaryGroupID`}>Group ID# </label>
                                                        <div class="form-line">
                                                            <input
                                                                type="text"
                                                                value={(typeof this.state.retData.order.billingInfo.tertiaryInsurance != 'undefined') ? this.state.retData.order.billingInfo.tertiaryInsurance.tertiaryGroupID : ''}
                                                                id={`billinfo_TertiaryGroupID`}
                                                                class="form-control "
                                                                placeholder="Group ID#"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="insured_info_container" class=" hidediv">
                                            <div id="insured_info" class="row clearfix">
                                                <div class="col-md-4">
                                                    <div class="form-group required">
                                                        <label class="form-label" for={`NameofInsured`}>Name of Insured </label>
                                                        {/* <div class="form-line">
                                                        <input type="text" value={this.state.retData.order.billingInfo.insuredInfo.nameofInsured} id={`billinfo_NameofInsured`} class="form-control required" placeholder="Name of Insured" />
                                                    </div> */}
                                                        <div class="form-line">
                                                            <div className="row clearfix">
                                                                <div className="col-md-6">
                                                                    <input
                                                                        type="text"
                                                                        value={this.state.retData.order.billingInfo.insuredInfo.nameOfInsured.firstName}
                                                                        id={`billinfo_FirstNameofInsured`}
                                                                        class="form-control required"
                                                                        placeholder="First Name"
                                                                    />
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <input
                                                                        type="text"
                                                                        value={this.state.retData.order.billingInfo.insuredInfo.nameOfInsured.lastName}
                                                                        id={`billinfo_LastNameofInsured`}
                                                                        class="form-control required"
                                                                        placeholder="Last Name"
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group required">
                                                        <label class="form-label" for={`DOB`}>DOB (mm/dd/yyyy) </label>
                                                        <div class="form-line">
                                                            <input type="text" value={moment(this.state.retData.order.billingInfo.insuredInfo.dob).format("MM/DD/YYYY")} id={`billinfoC_dob`} class="form-control required" placeholder="DOB (mm/dd/yyyy)" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="form-group required">
                                                        <label class="form-label" for={`Relationshiptoinsured`}>Relationship to insured</label>
                                                        <div class="form-line">
                                                            <input type="text" value={this.state.retData.order.billingInfo.insuredInfo.relationshipInsured} id={`billinfo_RelationshipInsured`} class="form-control required" placeholder="Relationship to insured" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* GTCS Billing Information end */}
                                        {/* Billing Notes */}
                                        <div class="row clearfix">
                                            <div class="col-md-12"><div class="bg-div"></div><h4 class="panel-titlepdf1">Billing Notes</h4></div>
                                        </div>
                                        <div class="row clearfix">
                                            <div class="col-md-12">
                                                <div class="form-group">
                                                    {typeof this.state.retData.order.billingInfo.notes != 'undefined' && this.state.retData.order.billingInfo.notes.map((n, i) => {
                                                        return <p>{i + 1}. {`Note: ${n.note}, Posted by: ${n.noteBy}, Date Time: ${n.noteTime}`}</p>
                                                    })
                                                    }

                                                    {(typeof this.state.retData.order.billingInfo.notes == 'undefined') && (
                                                        <p>No Notes yet!</p>
                                                    )
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                        {/* Billing Notes */}

                                        {/* GTCS Diagnosis Codes | ICD-10 Codes */}
                                        <div class="row clearfix">
                                            <div class="col-md-12">
                                                <div class="bg-div"></div>
                                                <h4 class="panel-titlepdf1">Diagnosis Codes | ICD-10 Codes</h4>
                                            </div>
                                        </div>
                                        {(this.state.retData.order.tests.length > 1) && (<>
                                            {(this.state.retData.order.tests[1].icd10Code.codes.length > 0) && (<>
                                                <div class="row clearfix">
                                                    <div class="col-md-12">
                                                        <label class="form-label" for="DiagnosisCodes_GHBOCG"><b>COVD Diagnosis Codes | ICD-10 Codes (check all that apply)</b></label>
                                                    </div>
                                                </div>
                                                <div class="row clearfix">
                                                    <div class="col-md-12">
                                                        <div class="demo-checkbox">
                                                            <div class="row clearfix">
                                                                {(this.state.retData.order.tests[1].icd10Code.codes.includes("Z11.59")) && (
                                                                    <div class="col-md-6">
                                                                        <input type="checkbox" checked data-val="Z11.59" id={`${this.props.prefix}diagnosis_z1159`} name={`${this.props.prefix}diagnosis_z1159`} class="filled-in-" />
                                                                        <label for={`${this.props.prefix}diagnosis_z1159`}>Z11.59, Encounter for screening for other viral diseases &nbsp; </label>
                                                                    </div>
                                                                )}
                                                                {(this.state.retData.order.tests[1].icd10Code.codes.includes("Z03.818")) && (
                                                                    <div class="col-md-6">
                                                                        <input type="checkbox" checked data-val="Z03.818" id={`${this.props.prefix}diagnosis_z03818`} name={`${this.props.prefix}diagnosis_z03818`} class="filled-in-" />
                                                                        <label for={`${this.props.prefix}diagnosis_z03818`}>Z03.818, Encounter for observation for suspected exposure to other biological agents ruled out &nbsp; </label>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>)}
                                        </>)}
                                        {(this.state.retData.order.tests.length > 0) && (<>
                                            {(this.state.retData.order.tests[0].icd10Code.codes.length > 0) && (<>
                                                <div class="row clearfix">
                                                    <div class="col-md-12">
                                                        <div class="form-group ">
                                                            <label class="form-label" for="DiagnosisCodes"><b>GCS Diagnosis Codes | ICD-10 Codes (check all that apply)</b></label>
                                                            <div class="demo-checkbox">
                                                                <div class="row clearfix">
                                                                    {(this.state.retData.order.tests[0].icd10Code.codes.includes("Z13.71")) && (
                                                                        <div class="col-md-6">
                                                                            <input type="checkbox" checked data-val="Z13.71" id="diagnosisC_z1371EncNProGeDisCaSt" name="diagnosisC_z1371EncNProGeDisCaSt" class="filled-in-" />
                                                                            <label for="diagnosisC_z1371EncNProGeDisCaSt">Z13.71 Encounter for nonprocreative screening for genetic disease carrier status &nbsp; </label>
                                                                        </div>)}

                                                                    {(this.state.retData.order.tests[0].icd10Code.codes.includes("Z15.81")) && (
                                                                        <div class="col-md-6">
                                                                            <input type="checkbox" checked data-val="Z15.81" id="diagnosisC_GenSusMulEndocrineNeopl" name="diagnosisC_GenSusMulEndocrineNeopl" class="filled-in-" />
                                                                            <label for="diagnosisC_GenSusMulEndocrineNeopl">Z15.81 Genetic susceptibility to multiple endocrine neoplasia [MEN] &nbsp; </label>
                                                                        </div>)}

                                                                    {(this.state.retData.order.tests[0].icd10Code.codes.includes("Z13.79")) && (
                                                                        <div class="col-md-6">
                                                                            <input type="checkbox" checked data-val="Z13.79" id="diagnosisC_z1379EncNProGeChroAnom" name="diagnosisC_z1379EncNProGeChroAnom" class="filled-in-" />
                                                                            <label for="diagnosisC_z1379EncNProGeChroAnom">Z13.79 Encounter for other screening for genetic and chromosomal anomalies &nbsp; </label>
                                                                        </div>)}

                                                                    {(this.state.retData.order.tests[0].icd10Code.codes.includes("Z15.89")) && (
                                                                        <div class="col-md-6">
                                                                            <input type="checkbox" checked data-val="Z15.89" id="diagnosisC_GenSusOtherDis" name="diagnosisC_GenSusOtherDis" class="filled-in-" />
                                                                            <label for="diagnosisC_GenSusOtherDis">Z15.89 Genetic susceptibility to other disease &nbsp; </label>
                                                                        </div>)}

                                                                    {(this.state.retData.order.tests[0].icd10Code.codes.includes("Z14.1")) && (
                                                                        <div class="col-md-6">
                                                                            <input type="checkbox" checked data-val="Z14.1" id="diagnosisC_z141CysticFibrosisCa" name="diagnosisC_z141CysticFibrosisCa" class="filled-in-" />
                                                                            <label for="diagnosisC_z141CysticFibrosisCa">Z14.1 Cystic fibrosis carrier &nbsp; </label>
                                                                        </div>)}

                                                                    {(this.state.retData.order.tests[0].icd10Code.codes.includes("Z31.430")) && (
                                                                        <div class="col-md-6">
                                                                            <input type="checkbox" data-val="Z31.430" checked id="diagnosisC_z31430FeProcman" name="diagnosisC_z31430FeProcman" class="filled-in-" />
                                                                            <label for="diagnosisC_z31430FeProcman">Z31.430 Encounter of female for testing for genetic disease carrier status for procreative management &nbsp; </label>
                                                                        </div>)}

                                                                    {(this.state.retData.order.tests[0].icd10Code.codes.includes("Z14.8")) && (
                                                                        <div class="col-md-6">
                                                                            <input type="checkbox" checked data-val="Z14.8" id="diagnosisC_z148Gencarotdis" name="diagnosisC_z148Gencarotdis" class="filled-in-" />
                                                                            <label for="diagnosisC_z148Gencarotdis">Z14.8 Genetic carrier of other disease &nbsp; </label>
                                                                        </div>)}

                                                                    {(this.state.retData.order.tests[0].icd10Code.codes.includes("Z31.440")) && (
                                                                        <div class="col-md-6">
                                                                            <input type="checkbox" checked data-val="Z31.440" id="diagnosisC_z31430MaProcman" name="diagnosisC_z31430MaProcman" class="filled-in-" />
                                                                            <label for="diagnosisC_z31430MaProcman">Z31.440 Encounter of male for testing for genetic disease carrier status for procreative management &nbsp; </label>
                                                                        </div>)}

                                                                    {(this.state.retData.order.tests[0].icd10Code.codes.includes("Z15.01")) && (
                                                                        <div class="col-md-6">
                                                                            <input type="checkbox" checked data-val="Z15.01" id="diagnosisC_z1501" name="diagnosisC_z1501" class="filled-in-" />
                                                                            <label for="diagnosisC_z1501">Z15.01 Genetic susceptibility to malignant neoplasm of breast &nbsp; </label>
                                                                        </div>)}

                                                                    {(this.state.retData.order.tests[0].icd10Code.codes.includes("Z31.5")) && (
                                                                        <div class="col-md-6">
                                                                            <input type="checkbox" checked data-val="Z31.5" id="diagnosisC_z315" name="diagnosisC_z315" class="filled-in-" />
                                                                            <label for="diagnosisC_z315">Z31.5 Encounter for procreative genetic counseling &nbsp; </label>
                                                                        </div>)}

                                                                    {(this.state.retData.order.tests[0].icd10Code.codes.includes("Z15.02")) && (
                                                                        <div class="col-md-6">
                                                                            <input type="checkbox" checked data-val="Z15.02" id="diagnosisC_z1502" name="diagnosisC_z1502" class="filled-in-" />
                                                                            <label for="diagnosisC_z1502">Z15.02 Genetic susceptibility to malignant neoplasm of ovary &nbsp; </label>
                                                                        </div>)}

                                                                    {(this.state.retData.order.tests[0].icd10Code.codes.includes("Z36.0")) && (
                                                                        <div class="col-md-6">
                                                                            <input type="checkbox" checked data-val="Z36.0" id="diagnosisC_z360" name="diagnosisC_z360" class="filled-in-" />
                                                                            <label for="diagnosisC_z360">Z36.0 Encounter for antenatal screening for chromosomal anomalies &nbsp; </label>
                                                                        </div>)}

                                                                    {(this.state.retData.order.tests[0].icd10Code.codes.includes("Z15.03")) && (
                                                                        <div class="col-md-6">
                                                                            <input type="checkbox" checked data-val="Z15.03" id="diagnosisC_z1503" name="diagnosisC_z1503" class="filled-in-" />
                                                                            <label for="diagnosisC_z1503">Z15.03 Genetic susceptibility to malignant neoplasm of prostate &nbsp; </label>
                                                                        </div>)}

                                                                    {(this.state.retData.order.tests[0].icd10Code.codes.includes("Z36.81")) && (
                                                                        <div class="col-md-6">
                                                                            <input type="checkbox" checked data-val="Z36.81" id="diagnosisC_z3681" name="diagnosisC_z3681" class="filled-in-" />
                                                                            <label for="diagnosisC_z3681">Z36.81 Encounter for antenatal screening for hydrops fetails &nbsp; </label>
                                                                        </div>)}

                                                                    {(this.state.retData.order.tests[0].icd10Code.codes.includes("Z15.04")) && (
                                                                        <div class="col-md-6">
                                                                            <input type="checkbox" checked data-val="Z15.04" id="diagnosisC_z1504" name="diagnosisC_z1504" class="filled-in-" />
                                                                            <label for="diagnosisC_z1504">Z15.04 Genetic susceptibility to malignant neoplasm of endometrium &nbsp; </label>
                                                                        </div>)}

                                                                    {(this.state.retData.order.tests[0].icd10Code.codes.includes("Z36.89")) && (
                                                                        <div class="col-md-6">
                                                                            <input type="checkbox" checked data-val="Z36.89" id="diagnosisC_z3689" name="diagnosisC_z3689" class="filled-in-" />
                                                                            <label for="diagnosisC_z3689">Z36.89 Encounter for other specified antenatal screening &nbsp; </label>
                                                                        </div>)}

                                                                    {(this.state.retData.order.tests[0].icd10Code.codes.includes("Z15.09")) && (
                                                                        <div class="col-md-6">
                                                                            <input type="checkbox" checked data-val="Z15.09" id="diagnosisC_z1509" name="diagnosisC_z1509" class="filled-in-" />
                                                                            <label for="diagnosisC_z1509">Z15.09 Genetic susceptibility to other malignant neoplasm &nbsp; </label>
                                                                        </div>)}

                                                                    {(this.state.retData.order.tests[0].icd10Code.codes.includes("Z36.9")) && (
                                                                        <div class="col-md-6">
                                                                            <input type="checkbox" checked data-val="Z36.9" id="diagnosisC_z369" name="diagnosisC_z369" class="filled-in-" />
                                                                            <label for="diagnosisC_z369">Z36.9 Encounter for antenatal screening, unspecified &nbsp; </label>
                                                                        </div>)}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>)}
                                        </>)}

                                        <div class="row clearfix">
                                            <div class="col-md-12">
                                                <div class="form-group ">
                                                    <label class="form-label" for="address">Other ICD-10 Codes</label>
                                                    <div class="form-line">
                                                        <input type="text" value={this.state.retData.order.tests[0].icd10Code.otherCodes} id="OtherICD10Codes1" class="form-control " placeholder="Other ICD-10 Codes" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* GTCS Diagnosis Codes | ICD-10 Codes end */}

                                        {/* GTCS Family History */}
                                        <div class="row clearfix">
                                            <div class="col-md-12">
                                                <div class="bg-div"></div>
                                                <h4 class="panel-titlepdf1">Family History</h4>
                                            </div>
                                        </div>

                                        {/* {this.state.retData.order.tests[0].clinicalData.length} */}
                                        {this.state.retData.order.tests[0].clinicalData.map((familyitem, i) => {
                                            return <>
                                                <div class="row clearfix">
                                                    <div class="col-md-4">
                                                        <div class="form-group ">
                                                            <label class="form-label" for="Familymembername1">Family member {(i + 1)} name</label>
                                                            <div class="form-line">
                                                                <input type="text" value={familyitem.familyMemberName} id={`famHist_familyMemName1_${i}`} name="famHist_familyMemName[]" class="form-control " placeholder="Family member 1 name" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label class="form-label" for="Relationtophysician1">Relation to Patient</label>
                                                            <div class="form-line">
                                                                <input type="text" value={familyitem.relationToPatient} id={`famHist_RelationPatient1_${i}`} name="famHist_RelationPatient[]" class="form-control" placeholder="Relation to Patient" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-group">
                                                            <label class="form-label" for="Geneticsex">Genetic sex</label>
                                                            <div id={`famHist_GenSex_container_${i}`} class="demo-radio-button">
                                                                {(familyitem.sex == 'male') && (
                                                                    <>
                                                                        <input type="radio" checked id={`famHist_GenSexM1_${i}`} name={`famHist_GenSex_${i}`} data-val="male" class="form-control" />
                                                                        <label class="form-label" for={`famHist_GenSexM1_${i}`}>Male &nbsp; </label>
                                                                        <input type="radio" id={`famHist_GenSexFe1_${i}`} name={`famHist_GenSex_${i}`} data-val="female" class="form-control" />
                                                                        <label class="form-label" for={`famHist_GenSexFe1_${i}`}>Female &nbsp; </label>
                                                                        <input type="radio" id={`famHist_GenSexUnknown1_${i}`} name={`famHist_GenSex_${i}`} data-val="Unknown" class="form-control" />
                                                                        <label class="form-label" for={`famHist_GenSexUnknown1_${i}`}>Unknown &nbsp; </label>
                                                                    </>
                                                                )}
                                                                {(familyitem.sex == 'female') && (
                                                                    <>
                                                                        <input type="radio" id={`famHist_GenSexM1_${i}`} name={`famHist_GenSex_${i}`} data-val="male" class="form-control" />
                                                                        <label class="form-label" for={`famHist_GenSexM1_${i}`}>Male &nbsp; </label>
                                                                        <input type="radio" checked id={`famHist_GenSexFe1_${i}`} name={`famHist_GenSex_${i}`} data-val="female" class="form-control" />
                                                                        <label class="form-label" for={`famHist_GenSexFe1_${i}`}>Female &nbsp; </label>
                                                                        <input type="radio" id={`famHist_GenSexUnknown1_${i}`} name={`famHist_GenSex_${i}`} data-val="Unknown" class="form-control" />
                                                                        <label class="form-label" for={`famHist_GenSexUnknown1_${i}`}>Unknown &nbsp; </label>
                                                                    </>
                                                                )}
                                                                {(familyitem.sex == 'Unknown') && (
                                                                    <>
                                                                        <input type="radio" id={`famHist_GenSexM1_${i}`} name={`famHist_GenSex_${i}`} data-val="male" class="form-control" />
                                                                        <label class="form-label" for={`famHist_GenSexM1_${i}`}>Male &nbsp; </label>
                                                                        <input type="radio" id={`famHist_GenSexFe1_${i}`} name={`famHist_GenSex_${i}`} data-val="female" class="form-control" />
                                                                        <label class="form-label" for={`famHist_GenSexFe1_${i}`}>Female &nbsp; </label>
                                                                        <input type="radio" checked id={`famHist_GenSexUnknown1_${i}`} name={`famHist_GenSex_${i}`} data-val="Unknown" class="form-control" />
                                                                        <label class="form-label" for={`famHist_GenSexUnknown1_${i}`}>Unknown &nbsp; </label>
                                                                    </>
                                                                )}

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row clearfix">
                                                    <div class="col-md-8">
                                                        <div class="form-group ">
                                                            <label class="form-label" for="Diagnosisand_orsymptoms">Diagnosis and/or symptoms</label>
                                                            <div class="form-line">
                                                                <input type="text" value={familyitem.diagnosis} id={`famHist_DiagSymptoms1_${i}`} name="famHist_DiagSymptoms[]" class="form-control " placeholder="Diagnosis and/or symptoms" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <div class="form-group ">
                                                            <label class="form-label" for="Ageofonset">Age of onset</label>
                                                            <div class="form-line">
                                                                <input type="text" value={familyitem.ageOfOnset} id={`famHist_AgeofOnset1_${i}`} name="famHist_AgeofOnset[]" class="form-control" placeholder="Age of onset" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <div class="form-group ">
                                                            <label class="form-label" for="dob1">DOB (MM/DD/YYYY)</label>
                                                            <div class="form-line">
                                                                {console.log("familyitem.dateOfBirth: ", familyitem.dateOfBirth)}
                                                                <input type="text" id={`FamilyHistoryForm_dob_${i}`} name="FamilyHistoryForm_dob[]" value={familyitem.dateOfBirth ? moment(familyitem.dateOfBirth).format("MM/DD/YYYY") : ""} class="form-control" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        })
                                        }
                                        {/* GTCS Family History end */}

                                        {/* GTCS Kit Shipping Information */}
                                        <div class="row clearfix">
                                            <div class="col-md-12"><div class="bg-div"></div><h4 class="panel-titlepdf1">Kit Shipping Information</h4></div>
                                        </div>
                                        <div class="row clearfix">
                                            <div class="col-md-12">
                                                <div class="form-group required">
                                                    <div class="row clearfix">
                                                        <div class="col-md-12">
                                                            <p>Label the EDTA tube with patient name and DOB. Place the EDTA tube into the biohazard bag. (If Saliva sample, place Saliva samples directly into the biohazard bag.) Print this form (after you submit) and fold into quarters and place in the side pocket of the biohazard bag. Remove the lid from the shipping box. Place the biohazard bag with the EDTA or saliva tube in the box. Close kit box and place into the FedEx Bag. Affix the shipping label to the outside of the FedEx Bag in the designated area. Contact FedEx online or call 1-800-463-3339 to schedule a pick up. Kits may also be dropped off at a FedEx shipping location.</p>
                                                        </div>
                                                    </div>
                                                    {/* <label class="form-label" for="address">Kit Shipping Info</label> */}
                                                    {/* <div id="draw_type" class="demo-checkbox">
                                                    {(this.state.retData.order.tests[0].kitInformation.drawType.includes("My physician needs mobile phlebotomy")) && (<input checked data-val="My physician needs mobile phlebotomy" type="checkbox" id="kitShip_PatMobPhlebotomy" name="kitShip_PatMobPhlebotomy" class="filled-in-1" />)}
                                                    {!(this.state.retData.order.tests[0].kitInformation.drawType.includes("My physician needs mobile phlebotomy")) && (<input data-val="My physician needs mobile phlebotomy" type="checkbox" id="kitShip_PatMobPhlebotomy" name="kitShip_PatMobPhlebotomy" class="filled-in-1" />)}
                                                    <label for="kitShip_PatMobPhlebotomy">My physician needs mobile phlebotomy &nbsp; </label>

                                                    {(this.state.retData.order.tests[0].kitInformation.drawType.includes("Patient will get blood draw at Quest PSC")) && (<input data-val="Patient will get blood draw at Quest PSC" type="checkbox" checked id="kitShip_PatblooddrawQuest" name="kitShip_PatblooddrawQuest" class="filled-in-1" />)}
                                                    {!(this.state.retData.order.tests[0].kitInformation.drawType.includes("Patient will get blood draw at Quest PSC")) && (<input data-val="Patient will get blood draw at Quest PSC" type="checkbox" id="kitShip_PatblooddrawQuest" name="kitShip_PatblooddrawQuest" class="filled-in-1" />)}
                                                    <label for="kitShip_PatblooddrawQuest">Patient will get blood draw at Quest PSC &nbsp; </label>

                                                    {(this.state.retData.order.tests[0].kitInformation.drawType.includes("Other")) && (<input data-val="Other" checked type="checkbox" id="kitShip_PatOthers" name="kitShip_PatOthers" class="filled-in-1" />)}
                                                    {!(this.state.retData.order.tests[0].kitInformation.drawType.includes("Other")) && (<input data-val="Other" type="checkbox" id="kitShip_PatOthers" name="kitShip_PatOthers" class="filled-in-1" />)}
                                                    <label for="kitShip_PatOthers">Other &nbsp; </label>
                                                </div> */}
                                                </div>
                                            </div>
                                            {/* <div class="col-md-5">
                                            <div class="form-group ">
                                                <label class="form-label" for="address">&nbsp;</label>
                                                <div class="form-line">
                                                    <input type="text" value={this.state.retData.order.tests[0].kitInformation.otherInfo} id="kitShip_PatOthersInfo" class="form-control " placeholder=" Other info" />
                                                </div>
                                            </div>
                                        </div> */}
                                        </div>
                                        {/* GTCS Kit Shipping Information end */}

                                        {/* GTCS Kit Notes & Certify */}
                                        <div class="row clearfix">
                                            <div class="col-md-12"><div class="bg-div"></div><h4 class="panel-titlepdf1">Notes & Certify</h4></div>
                                        </div>
                                        <div class="row clearfix">
                                            <div className="col-md-6">

                                                <div class="row clearfix">
                                                    <div class="col-md-12">
                                                        <div class="form-group ">
                                                            <label class="form-label" for="address">Notes</label>
                                                            <div class="form-line">
                                                                <textarea id="kitShip_notes" value={this.state.retData.order.tests[0].notes} class="form-control no-resize auto-growth" placeholder="Notes" rows="3"></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row clearfix">
                                                    <div class="col-md-12">
                                                        <p><strong> Please note this page is for healthcare professionals. If you are a patient and would like access to one of our tests, please contact your healthcare provider. </strong></p>
                                                    </div>
                                                </div>
                                                <div className="row clearfix">
                                                    <div className="col-md-12">
                                                        <div className="form-group required">
                                                            {this.renderResearchConsentCheckbox()}
                                                        </div>
                                                        <div className="form-group required">
                                                            {this.renderResearchUseOnlyCheckbox()}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row clearfix">
                                                    <div class="col-md-12">
                                                        <div class="form-group required">
                                                            <label class="form-label" for="address">I certify that I am:</label>
                                                            <div class="demo-radio-button" id="certifytype">
                                                                {(this.state.retData.order.certifyTypes) && (<>
                                                                    {(this.state.retData.order.certifyTypes.includes("A healthcare provider")) && (<input checked type="radio" data-val="A healthcare provider" id="kitShip_healthcareProvider" name="kitShip_healthcareProvider" class="form-control" />)}
                                                                    {!(this.state.retData.order.certifyTypes.includes("A healthcare provider")) && (<input type="radio" data-val="A healthcare provider" id="kitShip_healthcareProvider" name="kitShip_healthcareProvider" class="form-control" />)}
                                                                    <label for="kitShip_healthcareProvider">A healthcare provider &nbsp; </label>

                                                                    {(this.state.retData.order.certifyTypes.includes("An Aspira employee ordering on behalf of a healthcare provider")) && (<input type="radio" checked data-val="An Aspira employee ordering on behalf of a healthcare provider" id="kitShip_employeehealthcareProvider" name="kitShip_healthcareProvider" class="form-control" />)}
                                                                    {!(this.state.retData.order.certifyTypes.includes("An Aspira employee ordering on behalf of a healthcare provider")) && (<input type="radio" data-val="An Aspira employee ordering on behalf of a healthcare provider" id="kitShip_employeehealthcareProvider" name="kitShip_healthcareProvider" class="form-control" />)}
                                                                    <label for="kitShip_employeehealthcareProvider">An Aspira employee ordering on behalf of a healthcare provider &nbsp; </label>

                                                                    {(this.state.retData.order.certifyTypes.includes("An Aspira partner ordering on behalf of a healthcare provider")) && (<input type="radio" checked data-val="An Aspira partner ordering on behalf of a healthcare provider" id="kitShip_partnerhealthcareProvider" name="kitShip_healthcareProvider" class="form-control" />)}
                                                                    {!(this.state.retData.order.certifyTypes.includes("An Aspira partner ordering on behalf of a healthcare provider")) && (<input type="radio" data-val="An Aspira partner ordering on behalf of a healthcare provider" id="kitShip_partnerhealthcareProvider" name="kitShip_healthcareProvider" class="form-control" />)}
                                                                    <label for="kitShip_partnerhealthcareProvider">An Aspira partner ordering on behalf of a healthcare provider &nbsp; </label>
                                                                </>)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div class="form-group">
                                                    <label class="form-label" for="Signature_Pad">Your Signature </label>
                                                    <div>
                                                        {this.state.trimmedDataURL
                                                            ?
                                                            <>
                                                                <img src={`${this.state.trimmedDataURL}`} />
                                                            </>
                                                            : null
                                                        }
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                        {(this.state.retData.order.isTestOrder == true) &&
                                            (<div>
                                                <input type="radio" checked class=" filled-in" />
                                                <label >Test Order &nbsp; </label>
                                            </div>)}
                                        {/* GTCS Kit Notes & Certify end */}

                                    </div>
                                </div>
                            </div>
                        </div>

                    </section>
                    {/* {(this.state.loadingAnimation_) && (

                    <div className="col-md-12">
                        <LoadingAnimation className="btn-btnSaveStore" />
                    </div>

                )}
                {(!this.state.loadingAnimation_) && (

                    <div className="col-md-12 text-center">
                        <button className={"btn btn-light"} id={`gtcs_btnPrint`} onClick={async () => {

                            console.log("printPDF()->this.state.orderId: ", this.state.orderId);
                            this.setState({
                                loadingAnimation_: true,
                            });
                            // await helper.printPDF(this.state.orderId);

                            let exactHtmlUrl = "";
                            let accessToken = "";
                            if (process.env.REACT_APP_ENV === 'DEV') {
                                exactHtmlUrl = await helper.getTRFHtmlUrl(this.state.retData.order);
                                const user = JSON.parse(localStorage.getItem("user"));
                                accessToken = user.token;
                            }
                            else {

                                exactHtmlUrl = await helper.getTRFHtmlUrl(this.state.retData.order);
                                accessToken = await helper.getAccessToken(this.context);
                            }

                            const me = this;
                            if (exactHtmlUrl) {

                                // let htmlRawData = await helper.getTRFHtmlFromUrl(exactHtmlUrl, accessToken);

                                // let element = htmlRawData;
                                // const doc = new jsPDF("p", "pt", "letter");
                                // doc.html(ReactDOMServer.renderToString(element), {
                                //     callback: function (doc) {
                                //         doc.save(`trf-${me.state.retData.order.orderId}.pdf`);
                                //     }
                                // });
                            }


                            this.setState({
                                loadingAnimation_: false,
                            });


                        }}>SAVE PDF</button> &nbsp;
                        {process.env.REACT_APP_ENV == 'DEV' &&
                            <button id={`btnPrint`} onClick={async () => {
                                let opt = {
                                    url: `${url_base}/orders/printpdfandemail/${this.state.orderId}`,
                                    method: "GET",
                                    data: {},
                                    headers: {
                                        Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFsYW1yZXpvYW51bEBnbWFpbC5jb20iLCJpYXQiOjE1OTIzODQ0NjR9.Vlqn6clmjUMKFdtMd2NOxrJbu8n0PpPSoH_eGXVkSEM`
                                    }

                                };
                                console.log("printing PDF with url: ", opt);
                                let ret = await axios(opt);
                                console.log("printing return data: ", ret.data);

                            }} className={"btn btn-light"} >SAVE PDF AND EMAIL</button>
                        }
                    </div>

                )} */}


                </>
            );
        }
    }
}
