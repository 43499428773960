import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { PublicClientApplication } from "@azure/msal-browser"
import { msalConfig } from "./authConfig";
import { MsalProvider } from "@azure/msal-react";


export const msalInstance = new PublicClientApplication(msalConfig);

// var waves = require('node-waves');
// window.Waves = waves;
// window.Waves.init();
const routes = (
    <BrowserRouter>
        <MsalProvider instance={msalInstance} >
            <App />
        </MsalProvider>
    </BrowserRouter>
);

ReactDOM.render(routes, document.getElementById("root"));
