import React, { createRef } from "react";

import axios from "axios";
import Select from 'react-select';
import { message } from 'antd';

import Toast from '../mycomponents/toast/Toast';
import helper from './../utils/helper';
import { protectedResources } from "./../authConfig";
import { MsalContext } from "@azure/msal-react";

const API_BASE_URL = helper.getAPIBaseURI();

let me = null;
export default class AddEditUserContent extends React.PureComponent {
    static contextType = MsalContext;

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            roles: [],
            profileImage: null,
            profileImagePreview: null,
            selectedRole: [],
            buttonText: "Create",

            toastMessage: "",
            list: [],
            toastList: [],
            showRole: props.showRole,
            isAdmin: false,
            needResetPasswordInNextLogin: true,
            editingMyProfile: false,
        };


        this.email = createRef();
        this.password = createRef();
        this.fName = createRef();
        this.lName = createRef();
        this.needResetPasswordInNextLogin = createRef();

        this.roles = createRef();
        this.profileImagePath = createRef();

        this._id = null;
    }


    async componentDidMount() {
        me = this;

        let user = JSON.parse(localStorage.getItem("user"));
        const accessToken = await helper.getAccessToken(this.context)
        if (process.env.REACT_APP_ENV !== 'DEV') {
            if (!user) {

                ({ data: { user } } = await axios.get(`${API_BASE_URL}/users/current`, {
                    headers: {
                        "Authorization": `Bearer ${accessToken}`
                    }
                }));

                localStorage.setItem("user", JSON.stringify(user));
            }

        }


        axios({
            url: `${API_BASE_URL}/roles`,
            method: "GET",
            headers: {
                "Content-type": "Application/json",
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(({ data }) => {
                console.log("data: ", data);

                let roles = [];
                data.roles.map((item) => {
                    roles.push({
                        label: item.name,
                        value: item._id
                    });
                });


                this.setState({
                    loading: false,
                    roles: roles,
                    isAdmin: user.isAdmin,
                }, async () => {


                    console.log(window.location.search);
                    const params = new URLSearchParams(window.location.search);
                    this._id = params.get("id");

                    console.log("this._id: ", this._id);

                    if (this._id) { //Edit Mode
                        const user = JSON.parse(localStorage.getItem("user"));

                        let response = await axios({
                            url: `${API_BASE_URL}/users/${this._id}`,
                            method: "GET",
                            headers: {
                                "Authorization": `Bearer ${accessToken}`,
                                "Content-type": "Application/json",
                            }
                        });

                        response.data = response.data.user;
                        console.log("response.data: ", response.data);

                        me.email.value = response.data.email;
                        // me.password.value = response.data.password;
                        me.fName.value = response.data.firstName;
                        me.lName.value = response.data.lastName;


                        let selectedRoles = roles.filter(option => {
                            return response.data.roles.includes(option.value);
                        });

                        let _selectedRole = selectedRoles.length > 0 ? selectedRoles : [{ label: '', value: '' }];
                        const editingMyProfile = user.email === response.data.email;
                        const needResetPasswordInNextLogin = editingMyProfile ? false : true;

                        me.setState({
                            selectedRole: _selectedRole,
                            editingMyProfile,
                            needResetPasswordInNextLogin,
                            buttonText: "Update",
                        }, () => {

                        });

                    }

                });
            })
            .catch((error) => {
                me.setState({
                    loading: false,
                }, () => {
                    console.log("error: ", error);
                });
            })
    }

    onChange(event) {
        console.log("event.target.files: ", event.target.files[0]);
        me.setState({
            profileImage: event.target.files[0],
            profileImagePreview: URL.createObjectURL(event.target.files[0]),
        }, () => {

        });
    }

    validateFormData = () => {
        let allInputDataValid = false;
        const hasEmailAddress = me.email.value.length > 1;
        const hasFirstName = me.fName.value.length > 1;
        const hasLastName = me.lName.value.length > 1;
        const hasSelectedAnUserRole = me.state.selectedRole.length > 0;

        if (hasEmailAddress && hasFirstName && hasLastName && hasSelectedAnUserRole)
            allInputDataValid = true;

        return allInputDataValid;
    }

    async handleSave() {

        // if (me.email.value.length < 1 || me.fName.value.length < 1 || me.lName.value.length < 1
        //     || me.password.value.length < 1 || me.state.selectedRole.length < 1) {
        //     // alert("")
        //     me.setState({
        //         loading: false,
        //         toastMessage: `Please fill up all required fields`
        //     }, () => {
        //         //me.showToast("danger");
        //         message.error(me.state.toastMessage);
        //     })
        // }
        if (!this.validateFormData()) {
            me.setState({
                loading: false,
                toastMessage: "Please fill up all required fields"
            }, () => message.error(me.state.toastMessage));
        }
        else {


            const accessToken = await helper.getAccessToken(this.context)

            // email: req.body.email,
            // password: req.body.password,
            // firstName: req.body.firstName,
            // lastName: req.body.lastName,
            // createdBy: existingUser._id,
            // updatedBy: existingUser._id,
            // isActive: req.body.isActive,
            // verification_code: rand,
            // roles: [req.body.roles],

            const selectedRoleValuesArr = me.state.selectedRole.map(role => role.value);
            const stringifiedSelectedRoleValuesArr = JSON.stringify(selectedRoleValuesArr);

            const formData = new FormData();
            console.log("profileImage: ", me.state.profileImage);

            // formData.append('profileImage', me.state.profileImage);
            formData.append('email', me.email.value);
            formData.append('password', me.password.value);
            formData.append('firstName', me.fName.value);
            formData.append('lastName', me.lName.value);

            formData.append('roles', stringifiedSelectedRoleValuesArr);
            formData.append('host', window.location.host);
            formData.append('needResetPasswordInNextLogin', this.state.needResetPasswordInNextLogin);

            if (me._id) {
                formData.append("itemID", me._id);
                formData.append("shouldUpdatePassword", me.password.value.length > 0);

                axios.put(`${API_BASE_URL}/users`, formData, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
                    }
                })
                    .then(({ data }) => {
                        console.log("data: ", data);

                        this.setState({
                            toastMessage: `Successfully updated user`
                        }, () => {
                            // this.showToast("success");
                            message.success(this.state.toastMessage);
                        });
                    })
            }
            else {
                axios.post(`${API_BASE_URL}/users`, formData, {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`,
                        'Content-Type': `multipart/form-data; boundary=${formData._boundary}`
                    }
                })
                    .then(({ data }) => {
                        console.log("data: ", data);

                        this.setState({
                            toastMessage: `Successfully added user`
                        }, () => {
                            // this.showToast("success");
                            message.success(this.state.toastMessage);
                        });
                    })
            }
        }
    }

    showToast(type) {
        const id = Math.floor((Math.random() * 101) + 1);

        switch (type) {
            case 'success':
                this.toastProperties = {
                    id,
                    title: 'Success',
                    description: 'This is a success toast component',
                    backgroundColor: '#5cb85c',
                    icon: "/assets/assets/check.svg"
                }
                break;
            case 'danger':
                this.toastProperties = {
                    id,
                    title: 'Danger',
                    description: 'This is a error toast component',
                    backgroundColor: '#d9534f',
                    icon: "/assets/assets/error.svg"
                }
                break;
            case 'info':
                this.toastProperties = {
                    id,
                    title: 'Info',
                    description: 'This is an info toast component',
                    backgroundColor: '#5bc0de',
                    icon: "/assets/assets/info.svg"
                }
                break;
            case 'warning':
                this.toastProperties = {
                    id,
                    title: 'Warning',
                    description: 'This is a warning toast component',
                    backgroundColor: '#f0ad4e',
                    icon: "/assets/assets/warning.svg"
                }
                break;

            default:
                this.setState({ toastList: [] });
        }

        // this.setState([...this.state.list, this.toastProperties]);
        this.setState({ list: [this.toastProperties] });
    }

    handleNeedResetPasswordInNextLogin(event) {
        this.setState({ needResetPasswordInNextLogin: !this.state.needResetPasswordInNextLogin });
    }
    render() {
        if (this.state.loading) {
            return null;
        }

        return (
            <div>
                <div class="block-header">
                    <h2>{(window.location.href.includes("edituser")) ? "Edit User" : "Add User"}</h2>

                </div>
                <div class="row clearfix">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card">
                            <div class="header">
                                <h2>Basic Information</h2>
                                <ul class="header-dropdown m-r--5">
                                    <li class="dropdown"> <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i class="zmdi zmdi-more-vert"></i></a>
                                        <ul class="dropdown-menu pull-right">
                                            <li><a href="javascript:void(0);" class=" waves-effect waves-block">Action</a></li>
                                            <li><a href="javascript:void(0);" class=" waves-effect waves-block">Another action</a></li>
                                            <li><a href="javascript:void(0);" class=" waves-effect waves-block">Something else here</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div class="body card position">
                                <div class="row clearfix">
                                    <div class="col-sm-4 col-xs-12">
                                        <div class="form-group">
                                            <div class="form-line">
                                                <label class="color-green" htmlFor="email">Email:</label><small className="color-red">*</small>
                                                <input id="email" ref={(el) => this.email = el} type="text" class="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-xs-12">
                                        <div class="form-group">
                                            <div class="form-line">
                                                <label class="color-green" htmlFor="password">Password:</label><small className="color-red">*</small>
                                                <input id="password" ref={(el) => this.password = el} type="text" class="form-control" />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="row clearfix">
                                    <div class="col-sm-4 col-xs-12">
                                        <div class="form-group form-float">
                                            <div class="form-line">
                                                <label htmlFor="firstName" class="color-green">First Name:</label><small className="color-red">*</small>
                                                <input id="firstName" ref={(el) => this.fName = el} type="text" class="form-control" />

                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-4 col-xs-12">
                                        <div class="form-group">
                                            <div class="form-line">
                                                <label class="color-green" htmlFor="lastName">Last Name:</label><small className="color-red">*</small>
                                                <input id="lastName" ref={(el) => this.lName = el} type="text" class="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row clearfix">
                                    {/* <div class="col-sm-3 col-xs-12">
                                        <div class="form-group drop-custum">
                                            <input id="file" ref={(el) => this.profileImagePath = el} accept="image/*" type="file" onChange={this.onChange.bind(this)} />
                                            <label htmlFor="file">Profile Image</label>
                                            <img style={{ width: 40, height: 40 }} src={this.state.profileImagePreview} />
                                        </div>
                                    </div> */}
                                    {this.state.isAdmin && <>
                                        <div class="col-sm-4 col-xs-12">
                                            <div class="form-group drop-custum">
                                                <label class="color-green" htmlFor="role">Select Role:</label><small className="color-red">*</small>
                                                <Select
                                                    isMulti={true}
                                                    value={this.state.selectedRole}
                                                    defaultValue={""}
                                                    label="Select Role:"
                                                    options={this.state.roles}
                                                    styles={helper.colourStyles}
                                                    onChange={(val) => this.setState({ selectedRole: val })}
                                                />
                                                {/* <select id="role" ref={(el) => this.roles = el} class="form-control show-tick">
                                                <option value="">-- Roles --</option>
                                                {
                                                    this.state.roles.map((item) => {
                                                        return <option value={item._id.toString()}>{item.name}</option>
                                                    })
                                                }

                                            </select> */}
                                            </div>
                                        </div>


                                        <div class="col-sm-8 col-xs-12 margin-top-30">
                                            <div class="form-group">
                                                <div class="form-line">
                                                    <input
                                                        defaultChecked={this.state.needResetPasswordInNextLogin}
                                                        value={`Yes`} id="needResetPasswordInNextLogin"
                                                        ref={(el) => this.needResetPasswordInNextLogin = el}
                                                        type="checkbox"
                                                        checked={this.state.needResetPasswordInNextLogin}
                                                        class=""
                                                        onChange={() => this.handleNeedResetPasswordInNextLogin()}
                                                    />
                                                    <label class="color-green" htmlFor="needResetPasswordInNextLogin">Need to Reset password in next Login</label>
                                                </div>
                                            </div>
                                        </div>
                                    </>

                                    }
                                </div>
                                <div class="row clearfix">
                                    <div class="col-sm-4 col-xs-12">
                                        <div class="form-group">
                                            <div class="">

                                                <button id="btnSave" class="btn btn-raised g-bg-blue waves-effect" onClick={this.handleSave.bind(this)}>{this.state.buttonText}</button> &nbsp; &nbsp;
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <Toast
                    toastList={this.state.list}
                    position={"top-center"}
                    autoDelete={true}
                    dismissTime={3000}
                    description={this.state.toastMessage}

                />
            </div>
        );
    }
}
