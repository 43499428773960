import React, { useState, useEffect } from 'react'
import helper from '../../utils/helper';
import axios from 'axios';
import { message } from 'antd'
import TextArea from "antd/lib/input/TextArea";
// import jsPDF from "jspdf";
// import ReactDOMServer from "react-dom/server";
import $ from 'jquery';

const API_BASE_URL = helper.getAPIBaseURI();

const TopActions = ({
  reportId,
  ova1ReportId,
  galenReportId,
  context,
  orderData,
  reportDate,
  parent
}) => {
  const [view, setView] = useState({
    type: 'buttons',
    buttons: true
  })
  const [accessToken, setAccessToken] = useState('')
  const [clinicalReviewerName, setClinicalReviewerName] = useState('')
  const [labReviewerName, setLabReviewerName] = useState('')
  const [hasLabReviewerRole, setHasLabReviewerRole] = useState(false)
  const [hasClinicalReviewerRole, setHasClinicalReviewerRole] = useState(false)
  const [reportSignedUrl, setReportSignedUrl] = useState('')
  const [reportData, setReportData] = useState(false)
  const [clinicalNotes, setClinicalNotes] = useState("")

  useEffect(() => {

    console.log('sm ----- TopActions --------')
    console.log('sm orderData.orderId: ', orderData.orderId)
    console.log('sm reportId: ', reportId)
    console.log('sm ova1ReportId: ', ova1ReportId)
    console.log('sm galenReportId: ', galenReportId)
    console.log('sm orderData: ', orderData)
    console.log('sm ----- TopActions --------')

    getInitialValues()
  }, [])

  const loggedInUserHasRole = (roleStr) => {
    const user = JSON.parse(localStorage.getItem('user'));
    const allRolesNameArr = user.allRoles.map(role => role.name.toLowerCase());

    console.log('loggedInUserHasRole->allRolesNameArr: ', allRolesNameArr);
    return (allRolesNameArr.includes(roleStr.toLowerCase()) || allRolesNameArr.includes('portal_admin'));
  }

  const getInitialValues = async () => {
    const _temp1 = loggedInUserHasRole('Portal_Lab_Reviewer')
    console.log('sm Portal_Lab_Reviewer: ', _temp1)
    setHasLabReviewerRole(_temp1)
    const _temp2 = loggedInUserHasRole('clinical reviewer')
    console.log('sm clinical reviewer: ', _temp2)
    console.log('sm orderData.orderId: ', orderData.orderId)
    setHasClinicalReviewerRole(_temp2)
    const _accessToken = await helper.getAccessToken(context);
    console.log('sm accesstoken: ', _accessToken)
    setAccessToken(_accessToken)
    let response = await axios({
      url: `${API_BASE_URL}/orders/${orderData.orderId}/clinical reviewer`,
      method: 'GET',
      headers: {
        'Content-type': 'Application/json',
        Authorization: `Bearer ${_accessToken}`,
      }
    });
    console.log('sm clinical reviewer: ', response.data)
    setClinicalReviewerName(response.data)

    response = await axios({
      url: `${API_BASE_URL}/orders/${orderData.orderId}/lab reviewer`,
      method: 'GET',
      headers: {
        'Content-type': 'Application/json',
        Authorization: `Bearer ${_accessToken}`,
      }
    });
    console.log('sm lab reviewer: ', response.data)
    setLabReviewerName(response.data)
  }

  const handleViewTRF = async function () {

    let item_id = orderData._id;
    let custom_formatted_id = orderData.orderId;

    let response = "";
    if (process.env.REACT_APP_ENV === 'DEV') {
      const user = JSON.parse(localStorage.getItem("user"));
      response = await helper.printPDF(item_id, custom_formatted_id, user.token);
    }
    else {
      const accessToken = await helper.getAccessToken(context);

      response = await helper.printPDF(item_id, custom_formatted_id, accessToken);
    }

    if (response) {
      const theButton = document.getElementById(`${parent}_btnViewTRF`);

      theButton.textContent = "View TRF";

      theButton.classList.add("text-white");
      theButton.classList.remove("button--loading");
      theButton.disabled = false;
    }

    // let item_id = orderData._id;
    // let custom_formatted_id = orderData.orderId;

    // let exactHtmlUrl = "";
    // let accessToken = "";
    // if (process.env.REACT_APP_ENV === 'DEV') {
    //   const user = JSON.parse(localStorage.getItem("user"));
    //   exactHtmlUrl = await helper.getTRFHtmlUrl(orderData);

    //   accessToken = user.token;
    // }
    // else {
    //   accessToken = await helper.getAccessToken(context);

    //   exactHtmlUrl = await helper.getTRFHtmlUrl(orderData);
    // }

    // if (exactHtmlUrl) {
    //   window.open(exactHtmlUrl);
    //   // let htmlRawData = await helper.getTRFHtmlFromUrl(exactHtmlUrl, accessToken);

    //   // var element = new DOMParser().parseFromString(htmlRawData, "text/xml");

    //   // helper.savePdfUsingJSPDF(element, custom_formatted_id, parent);
    // }
  }
  const handlePutReportOnHold = async () => {

    const accessToken = await helper.getAccessToken(context)

    const url = `${API_BASE_URL}/baylor/reports-on-hold/${orderData.orderId}`;
    axios({
      url,
      method: 'PUT',
      headers: {
        'Content-type': 'Application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(({ data }) => {
      console.log(data)
      message.success(`Put Report on Hold success`);
    }).catch((error) => {
      message.error(`Put Report on Hold error: ${error}`);
    })

    // window.location.reload();
  }

  const handleReviewed = async (reviewTypeStr) => {
    let _url = `${API_BASE_URL}/baylor/report-reviewed/${reviewTypeStr}/${orderData.orderId}`;
    axios({
      url: _url,
      method: "POST",
      headers: {
        "Content-type": "Application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        reviewNote: clinicalNotes
      }
    }).then(({ data }) => {
      console.log("data: ", data);

      message.success(`Successfully Reviewed the Report`);

    }).catch((error) => {
      message.error(`Report Review Unsuccessful`);
    });
  }
  const handleRetrieveUpdatedReport = async () => {
    if (reportId) {
      const _payload = {
        orderId: orderData.orderId,
        reportId,
        reportCompletionDateString: reportDate,
      };
      axios({
        url: `${API_BASE_URL}/baylor/retrieveReport`,
        method: "POST",
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        data: _payload,
      }).then(({ data }) => {
        console.log("data: ", data);
        message.success(`Successfully Retrieved the Report`);
      })
        .catch((error) => {
          message.error(`Failed to Retrieve Report: ${error.message}`);
        })
    } else {
      message.error(`The Report Id not exits`)
      console.log("Report is not retrived");
    }
  }

  const handleViewReport = async () => {
    // const accessToken = await helper.getAccessToken(context);
    let response = await axios({
      url: `${API_BASE_URL}/orders/${orderData.orderId}/clinical reviewer`,
      method: 'GET',
      headers: {
        'Content-type': 'Application/json',
        Authorization: `Bearer ${accessToken}`,
      }
    });
    console.log('sm clinical reviewer: ', response.data)
    setClinicalReviewerName(response.data)

    response = await axios({
      url: `${API_BASE_URL}/orders/${orderData.orderId}/lab reviewer`,
      method: 'GET',
      headers: {
        'Content-type': 'Application/json',
        Authorization: `Bearer ${accessToken}`,
      }
    });
    console.log('sm lab reviewer: ', response.data)
    setLabReviewerName(response.data)

    if (ova1ReportId) {
      try {
        let custom_formatted_id = orderData.orderId;
        if (process.env.REACT_APP_ENV === 'DEV') {
          const requestOptions = {
            method: 'GET',
            responseType: "blob",
            headers: {
              // 'Content-type': 'Application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          };
          fetch(`${API_BASE_URL}/ova1/reports/${custom_formatted_id}/signedUrl`, requestOptions)
            .then((response) => {
              response.blob()
                .then(blob => {
                  const fileUrl = URL.createObjectURL(blob)

                  const iframe = document.querySelector("iframe");
                  iframe.src = fileUrl;

                  console.log("iframe: ", iframe);
                  // window.open(fileUrl);
                  setView({
                    type: 'content',
                    buttons: true
                  })
                  window.$('#viewReportActionsModal').modal('show');
                  setReportData(true);
                })
            })

        }
        else {
          let options = {
            url: `${API_BASE_URL}/ova1/reports/${custom_formatted_id}/signedUrl`,
            method: 'GET',
            headers: {
              'Content-type': 'Application/json',
              Authorization: `Bearer ${accessToken}`,
            },

          };

          console.log("options: ", options);
          let response = await axios(options);

          let data = response.data;
          console.log('data: ', data);

          setView({
            type: 'content',
            buttons: true
          })
          window.$('#viewReportActionsModal').modal('show');
          setReportSignedUrl(data);
        }
        message.success(`Successfully Viewed the Report, SEE BOTTOM`);

      }
      catch (error) {
        message.error(`Viewing Report error: ${error}`);
        console.log('sm ova1/reports/{orderData.orderId}/signedUrl: ', error)

      }
    }
    else if (reportId || galenReportId) {
      const _target = reportId ? 'baylor' : 'galen'
      try {
        if (process.env.REACT_APP_ENV === 'DEV') {
          const requestOptions = {
            method: 'GET',
            responseType: "blob",
            headers: {
              // 'Content-type': 'Application/json',
              Authorization: `Bearer ${accessToken}`,
            },
          };
          fetch(`${API_BASE_URL}/${_target}/reports/${orderData.orderId}/signedUrl`, requestOptions)
            .then((response) => {
              response.blob()
                .then(blob => {
                  const fileUrl = URL.createObjectURL(blob)

                  const iframe = document.querySelector("iframe");
                  iframe.src = fileUrl;

                  console.log("iframe: ", iframe);
                  // window.open(fileUrl);
                  setView({
                    type: 'content',
                    buttons: true
                  })
                  window.$('#viewReportActionsModal').modal('show');
                  setReportData(true);
                })
            })

        }
        else {
          let options = {
            url: `${API_BASE_URL}/${_target}/reports/${orderData.orderId}/signedUrl`,
            method: 'GET',
            headers: {
              'Content-type': 'Application/json',
              Authorization: `Bearer ${accessToken}`,
            },

          };

          console.log("options: ", options);
          let response = await axios(options);

          let data = response.data;
          console.log('data: ', data);

          setView({
            type: 'content',
            buttons: true
          })
          window.$('#viewReportActionsModal').modal('show');
          setReportSignedUrl(data);
        }
        message.success(`Successfully Viewed the Report, SEE BOTTOM`);

      }
      catch (error) {
        message.error(`Viewing Report error: ${error}`);
        console.log('sm baylor/reports/{orderData.orderId}/signedUrl: ', error)

      }
    } else {
      message.error(`Viewing Report error: Report Id or Ova1 Report Id or Galen Report Id not exits.`);
    }
    // else if (galenReportId) {
    //   axios({
    //     url: `${API_BASE_URL}/galen/reports/${orderData.orderId}/signedUrl`,
    //     method: 'GET',
    //     headers: {
    //       'Content-type': 'Application/json',
    //       Authorization: `Bearer ${accessToken}`,
    //     },
    //   }).then(({ data }) => {
    //     console.log('data: ', data);
    //     setView({
    //       type: 'content',
    //       buttons: true
    //     })
    //     message.success(`Successfully Viewed the Report, SEE BOTTOM`);
    //     setReportSignedUrl(data)
    //   }).catch((error) => {
    //     message.error(`Viewing Report error: ${error}`);
    //   })
    // }
  }

  return (
    <>
      <div>
        {parent && parent === 'genetix' && (
          <button
            id={``}
            className='btn btn-primary text-white '
            style={{ marginRight: '10px' }}
            onClick={handleRetrieveUpdatedReport}
          >
            Retrieve Updated Report
          </button>
        )}

        <button
          id={``}
          className='btn btn-primary text-white '
          style={{ marginRight: '10px' }}
          onClick={async () => {
            await handleViewReport();
          }}
        >
          View Report
        </button>
        {reportId && <button
          id={``}
          className='btn btn-primary text-white '
          style={{ marginRight: '10px' }}
          onClick={handlePutReportOnHold}
        >
          Put Report on Hold
        </button>
        }

        <button
          id={`${parent}_btnViewTRF`}
          className='btn btn-primary text-white '
          style={{ marginRight: '10px' }}
          onClick={() => {
            const theButton = document.getElementById(`${parent}_btnViewTRF`);

            theButton.disabled = true;
            theButton.classList.remove("text-white");
            theButton.classList.add("button--loading");

            handleViewTRF();
          }}
        >
          View TRF
        </button>
      </div>
      <div class='modal fade' id='viewReportActionsModal' tabindex='-1' role='dialog'>
        <div class='modal-dialog' role='document' style={view.type === 'buttons' ? {} : { maxWidth: '100%' }}>
          <div class='modal-content'>
            <div class='modal-header'>
              <h4 class='modal-title' id='defaultModalLabel'>
                View Report Actions
              </h4>
              <button
                type='button'
                class='btn waves-effect btn-danger text-white mclose'
                data-dismiss='modal'
                onClick={() => {
                  window.$('#viewReportActionsModal').modal('hide');
                }}
              >
                CLOSE
              </button>
            </div>
            <div className='modal-body'>
              <div>
                {view.buttons &&
                  <div>
                    {/* <button
                      id={``}
                      className='btn btn-primary text-white '
                      style={{marginRight: '10px'}}
                      onClick={handleViewReport}
                    >
                      PDF REPORT
                    </button> */}
                    {(!(!hasLabReviewerRole || labReviewerName) || !(!hasClinicalReviewerRole || clinicalReviewerName)) && (
                      <div style={{ minWidth: "50%" }}>
                        <TextArea
                          placeholder={"Add some notes..."}
                          value={clinicalNotes}
                          onChange={(e) => setClinicalNotes(e.target.value)}
                          rows={5}
                        />
                      </div>
                    )}
                    <button
                      id={``}
                      className='btn btn-primary text-white '
                      style={{ marginRight: '10px' }}
                      disabled={!hasLabReviewerRole || labReviewerName}
                      onClick={() => handleReviewed("lab reviewer")}
                    >
                      {labReviewerName ? `Lab Reviewed By ${labReviewerName}` : 'Lab Reviewed'}
                    </button>
                    <button
                      id={``}
                      className='btn btn-primary text-white '
                      style={{ marginRight: '10px' }}
                      disabled={!hasClinicalReviewerRole || clinicalReviewerName}
                      onClick={() => handleReviewed("clinical reviewer")}
                    >
                      {clinicalReviewerName ? `Clinical Reviewed By ${clinicalReviewerName}` : 'Clinical Reviewed'}
                    </button>
                  </div>
                }
                {process.env.REACT_APP_ENV === 'DEV' && (

                  <div id="my-container" class="ng-scope pdfobject-container" style={{ height: '1000px' }}>
                    <iframe title='PDF Report' src="" type="application/pdf" width="100%" height="100%" style={{ overflow: "auto;" }}>
                    </iframe>
                  </div>
                )}

                {reportSignedUrl && process.env.REACT_APP_ENV !== 'DEV' && (
                  <div class="row clearfix">
                    <div class="col-md-12 col-xs-12">
                      <object
                        data={`${reportSignedUrl}`}
                        type="application/pdf"
                        width="100%"
                        height="678"
                      >
                        <iframe
                          title="PDF Report"
                          src={`${reportSignedUrl}`}
                          width="100%"
                          height="678"
                        >
                          <p>This browser does not support PDF!</p>
                        </iframe>

                      </object>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div class='modal-footer'>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default TopActions