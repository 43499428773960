import React, { createRef, useState } from "react";

import axios from "axios";
import Select from "react-select";
import $ from "jquery";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from "moment";

import Toast from "../mycomponents/toast/Toast";
import helper from "./../utils/helper";
import { protectedResources } from "./../authConfig";
import { MsalContext } from "@azure/msal-react";

import CmtForm from "./CmtForm";
import BillingNotes from "./BillingNotes";
import Retrive_Reprot from "./add_edit_order_components/RetriveReport";
import TopActions from "./add_edit_order_components/TopActions";

const API_BASE_URL = helper.getAPIBaseURI();

//for billing logic
const showInsuranceTitle = () => {
  $(`#title_insurance_container`).removeClass(" hidediv");
  $(`#title_insurance_container`).addClass(" showdiv");
};

const hideInsuranceTitle = () => {
  $(`#title_insurance_container`).addClass(" hidediv");
  $(`#title_insurance_container`).removeClass(" showdiv");
};

const showPrimaryAreaClick = () => {
  $(`#primary_insurance_container`).removeClass(" hidediv");
  $(`#primary_insurance_container`).addClass(" showdiv");
};
const hidePrimaryAreaClick = () => {
  $(`#primary_insurance_container`).addClass(" hidediv");
  $(`#primary_insurance_container`).removeClass(" showdiv");
};
const showSecondaryAreaClick = () => {
  $(`#secondary_insurance_container`).removeClass(" hidediv");
  $(`#secondary_insurance_container`).addClass(" showdiv");
};
const hideSecondaryAreaClick = () => {
  $(`#secondary_insurance_container`).addClass(" hidediv");
  $(`#secondary_insurance_container`).removeClass(" showdiv");
};

const showInsuranceInsured = () => {
  $(`#insured_info_container`).removeClass(" hidediv");
  $(`#insured_info_container`).addClass(" showdiv");
};

const hideInsuranceInsured = () => {
  $(`#insured_info_container`).addClass(" hidediv");
  $(`#insured_info_container`).removeClass(" showdiv");
};

const BillingInformationChechAll = () => {
  var billinfo_PrivateInsurance = document.getElementById(
    `billinfo_PrivateInsurance`
  );
  var billinfo_Medicare = document.getElementById(`billinfo_Medicare`);
  var billinfo_PatientSelfPay = document.getElementById(
    `billinfo_PatientSelfPay`
  );
  var billinfo_Medicaid = document.getElementById(`billinfo_Medicaid`);
  var billinfo_OrderingFacility = document.getElementById(
    `billinfo_OrderingFacility`
  );

  if (billinfo_PrivateInsurance.checked == true) {
    showInsuranceTitle();
    showPrimaryAreaClick();
    showSecondaryAreaClick();
    showInsuranceInsured();
  } else if (billinfo_Medicare.checked == true) {
    showInsuranceTitle();
    showPrimaryAreaClick();
    hideSecondaryAreaClick();
    showInsuranceInsured();
  } else if (billinfo_Medicaid.checked == true) {
    showInsuranceTitle();
    showPrimaryAreaClick();
    hideSecondaryAreaClick();
    showInsuranceInsured();
  } else if (billinfo_PatientSelfPay.checked == true) {
    hidePrimaryAreaClick();
    hideSecondaryAreaClick();
    hideInsuranceTitle();
    hideInsuranceInsured();
  } else {
    hideInsuranceTitle();
    hidePrimaryAreaClick();
    hideSecondaryAreaClick();
    hideInsuranceInsured();
  }
};

const BillingInformationPrivateInsuranceClick = () => {
  var billinfo_PrivateInsurance = document.getElementById(
    `billinfo_PrivateInsurance`
  );
  document.getElementById(`billinfo_PatientSelfPay`).checked = false;
  document.getElementById(`billinfo_Medicaid`).checked = false;
  document.getElementById(`billinfo_OrderingFacility`).checked = false;
  document.getElementById(`billinfo_FreeTrial`).checked = false;

  if (billinfo_PrivateInsurance.checked == true) {
    showPrimaryAreaClick();
    showSecondaryAreaClick();
  } else {
    hidePrimaryAreaClick();
    hideSecondaryAreaClick();
  }

  BillingInformationChechAll();
};

const BillingInformationMedicareClick = () => {
  var billinfo_Medicare = document.getElementById(`billinfo_Medicare`);
  document.getElementById(`billinfo_PatientSelfPay`).checked = false;
  document.getElementById(`billinfo_Medicaid`).checked = false;
  document.getElementById(`billinfo_OrderingFacility`).checked = false;
  document.getElementById(`billinfo_FreeTrial`).checked = false;

  if (billinfo_Medicare.checked == true) {
    showPrimaryAreaClick();
    //showSecondaryAreaClick();
  } else {
    hidePrimaryAreaClick();
    hideSecondaryAreaClick();
  }
  BillingInformationChechAll();
};
const BillingInformationPatientSelfPayClick = () => {
  var billinfo_PatientSelfPay = document.getElementById(
    `billinfo_PatientSelfPay`
  );

  document.getElementById(`billinfo_PrivateInsurance`).checked = false;
  document.getElementById(`billinfo_Medicare`).checked = false;
  document.getElementById(`billinfo_Medicaid`).checked = false;
  document.getElementById(`billinfo_OrderingFacility`).checked = false;
  document.getElementById(`billinfo_FreeTrial`).checked = false;

  if (billinfo_PatientSelfPay.checked == true) {
    hidePrimaryAreaClick();
    hideSecondaryAreaClick();
  }
  BillingInformationChechAll();
};
const BillingInformationFreeTrialClick = () => {
  var billinfo_FreeTrial = document.getElementById(`billinfo_FreeTrial`);

  document.getElementById(`billinfo_PrivateInsurance`).checked = false;
  document.getElementById(`billinfo_Medicare`).checked = false;
  document.getElementById(`billinfo_PatientSelfPay`).checked = false;
  document.getElementById(`billinfo_Medicaid`).checked = false;
  document.getElementById(`billinfo_OrderingFacility`).checked = false;

  if (billinfo_FreeTrial.checked == true) {
    hidePrimaryAreaClick();
    hideSecondaryAreaClick();
    // hideTertiaryAreaClick();
    // hidePayorListSearchAndSelect();
  }
  BillingInformationChechAll();
};
const BillingInformationMedicaidClick = () => {
  var billinfo_Medicaid = document.getElementById(`billinfo_Medicaid`);

  document.getElementById(`billinfo_PrivateInsurance`).checked = false;
  document.getElementById(`billinfo_Medicare`).checked = false;
  document.getElementById(`billinfo_PatientSelfPay`).checked = false;
  document.getElementById(`billinfo_OrderingFacility`).checked = false;
  document.getElementById(`billinfo_FreeTrial`).checked = false;

  if (billinfo_Medicaid.checked == true) {
    showPrimaryAreaClick();
    //showSecondaryAreaClick();
  } else {
    hidePrimaryAreaClick();
    hideSecondaryAreaClick();
  }
  BillingInformationChechAll();
};
const BillingInformationOrderingFacilityClick = () => {
  var billinfo_OrderingFacility = document.getElementById(
    `billinfo_OrderingFacility`
  );

  document.getElementById(`billinfo_PrivateInsurance`).checked = false;
  document.getElementById(`billinfo_Medicare`).checked = false;
  document.getElementById(`billinfo_PatientSelfPay`).checked = false;
  document.getElementById(`billinfo_Medicaid`).checked = false;
  document.getElementById(`billinfo_FreeTrial`).checked = false;

  if (billinfo_OrderingFacility.checked == true) {
    hidePrimaryAreaClick();
    hideSecondaryAreaClick();
  }
  BillingInformationChechAll();
};
//for billing logic

let me = null;
let ovaHbocAccessToken = "";
let reportId = "";
let reportDate = "";

export default class AddEditOrderOVA1HBOC1 extends React.PureComponent {
  static contextType = MsalContext;

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      buttonText: "Create",
      frmprefix: "GTCS_",

      toastMessage: "",
      list: [],
      toastList: [],
      orderData: [],

      IndicationsTest_otherValue: "",

      //physician info
      phyinfo_Organization_account: "",
      phyinfo_first_name: "",
      phyinfo_last_name: "",
      phyinfo_email: "",
      phyinfo_npi: "",
      phyinfoC_address: "",
      phyinfoC_practiceLocation: "",
      phyinfoC_city: "",
      phyinfo_state: "",
      phyinfoC_zipCode: "",
      phyinfoC_Phone: "",
      phyinfoC_fax: "",
      phyinfoC_Faxcopyto: "",

      //patient info
      patinfo_firstname: "",
      patinfo_lastname: "",
      patinfo_address: "",
      selectedState: "ALABAMA",
      patinfo_State: "",
      patinfo_city: "",
      patinfo_zipcode: "",
      patinfo_dob: "",
      patinfo_PhoneNumber: "",
      patinfo_emailaddress: "",
      patinfo_OtherEthnicitycity: "",
      ethnicityArray: {},
      patinfo_gender: "",

      //billing info
      billinfo_PrimaryInsuranceCarrier: "",
      billinfo_planId: "",
      billinfo_MemberID: "",
      billinfo_GroupID: "",
      billinfo_SecondaryInsuranceCarrier: "",
      billinfo_SecondaryplanId: "",
      billinfo_SecondaryMemberID: "",
      billinfo_SecondaryGroupID: "",
      billinfo_NameofInsured: "",
      billinfoC_dob: "",
      billinfo_RelationshipInsured: "",
      //edit billing portion
      privateInsurance: false,
      patientSelfPay: false,
      medicare: false,
      medicaid: false,
      orderingFacility: false,
      freeTrial: false,
      isBillableOrder: false,

      OtherICD10Codes: "",
      kitShip_PatOthersInfo: "",
      kitShip_PatOthersInfo2: "",
      kitShip_notes: "",

      kitShip_PatOthers: false,
      kitShip_PatOthers2: false,
    };
    this.IndicationsTest_otherValue = React.createRef();

    //physician
    this.phyinfo_Organization_account = React.createRef();
    this.phyinfo_first_name = React.createRef();
    this.phyinfo_last_name = React.createRef();
    this.phyinfo_email = React.createRef();
    this.phyinfo_npi = React.createRef();
    this.phyinfoC_address = React.createRef();
    this.phyinfoC_practiceLocation = React.createRef();
    this.phyinfoC_city = React.createRef();
    this.phyinfo_state = React.createRef();
    this.phyinfoC_zipCode = React.createRef();
    this.phyinfoC_Phone = React.createRef();
    this.phyinfoC_fax = React.createRef();
    this.phyinfoC_Faxcopyto = React.createRef();

    //patient info
    this.patinfo_firstname = React.createRef();
    this.patinfo_lastname = React.createRef();
    this.patinfo_address = React.createRef();
    this.patinfo_State = React.createRef();
    this.patinfo_city = React.createRef();
    this.patinfo_zipcode = React.createRef();
    this.patinfo_dob = React.createRef();
    this.patinfo_PhoneNumber = React.createRef();
    this.patinfo_emailaddress = React.createRef();
    this.patinfo_OtherEthnicitycity = React.createRef();
    this.patinfo_gender = React.createRef();

    //billing info
    this.billinfo_PrimaryInsuranceCarrier = React.createRef();
    this.billinfo_planId = React.createRef();
    this.billinfo_MemberID = React.createRef();
    this.billinfo_GroupID = React.createRef();
    this.billinfo_SecondaryInsuranceCarrier = React.createRef();
    this.billinfo_SecondaryplanId = React.createRef();
    this.billinfo_SecondaryMemberID = React.createRef();
    this.billinfo_SecondaryGroupID = React.createRef();
    this.billinfo_NameofInsured = React.createRef();
    this.billinfoC_dob = React.createRef();
    this.billinfo_RelationshipInsured = React.createRef();

    this.OtherICD10Codes = React.createRef();
    this.kitShip_PatOthersInfo = React.createRef();
    this.kitShip_PatOthersInfo2 = React.createRef();
    this.kitShip_notes = React.createRef();

    this.orderId = null;
    this._id = null;
    me = this;
  }

  componentDidMount() {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log("user.token: ", user.token);

    axios.interceptors.response.use(
      function (response) {
        // Do something with response data
        return response;
      },
      function (error) {
        switch (error.response.status) {
          case 409:
            console.log("interceptors->error: ", error);

            me.setState(
              {
                toastMessage: `An Order with the same already exists`,
                loading: false,
              },
              () => {
                me.danger_failed.click();
              }
            );
            break;

          default:
            break;
        }
        // Do something with response error
        return Promise.reject(error);
      }
    );

    this.setState(
      {
        loading: true,
      },
      async () => {
        console.log(window.location.search);
        const params = new URLSearchParams(window.location.search);
        this._id = params.get("id");

        console.log("this._id: ", this._id);

        if (this._id) {
          //Edit Mode
          
          const accessToken = await helper.getAccessToken(this.context)
          ovaHbocAccessToken = accessToken;
          let response = await axios({
            url: `${API_BASE_URL}/orders/${this._id}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-type": "Application/json",
            },
          });

          response.data = response.data.orders[0];
          console.log("response.data: ", response.data);
          reportId = response.data["baylorReportId"];
          reportDate = moment.utc(response.data["baylorReportCompletionDate"], "YYYY-MM-DD HH:mm:ss", true).isValid()
            ? moment.utc(response.data["baylorReportCompletionDate"], "YYYY-MM-DD HH:mm:ss", true).toISOString() : undefined;


          console.log(
            "reprotId" +
            reportId +
            "--------------------------report Id------------"
          );
          console.log(response.data);
          //assays data "HBOC"
          let _assays_hboc = response.data.assays.filter(
            (x) => x.assayType.indexOf("HBOC") > -1
          );
          console.log("_assays_hboc: ", _assays_hboc);
          let _assays_hboccontent = ``;
          _assays_hboc.map((a, i) => {
            _assays_hboccontent += `<div class="col-md-6">`;
            _assays_hboccontent += `<input data-qbenchid="${a.qBenchId}" data-val="${a.name}" type="radio" name="gtcs_assay_" id="hboc_assay_${i}" class="form-control" />
                    <label for="hboc_assay_${i}">${a.name}  &nbsp; </label></div>`;
          });

          //assays data HBOC
          let patinfo_dobFormatted = "";
          let billinfoC_dobFormatted = "";

          //console.log("p:", response.data.Patient.dob);
          //console.log("pb:", response.data.billingInfo.insuredInfo.ova1GenetixHBOC_billinfoC_dob);

          if (response.data.Patient.dob) {
            patinfo_dobFormatted = helper.formatDate(response.data.Patient.dob);
          }

          if (
            response.data.billingInfo.insuredInfo.ova1GenetixHBOC_billinfoC_dob
          ) {
            billinfoC_dobFormatted = helper.formatDate(
              response.data.billingInfo.insuredInfo
                .ova1GenetixHBOC_billinfoC_dob
            );
          }

          console.log(
            "patinfo_dobFormatted: ",
            patinfo_dobFormatted,
            "billinfoC_dobFormatted: ",
            billinfoC_dobFormatted
          );

          me.setState(
            {
              orderData: response.data,
              frmprefix: response.data.tests[0].testName + "_",
              loading: false,
              patinfo_dob: patinfo_dobFormatted,
              billinfoC_dob: billinfoC_dobFormatted,
              _assays_hboccontent: _assays_hboccontent,
              _assays_hboc: _assays_hboc,
              //duedate_pregnant: duedate_pregnantFormatted,
            },
            () => {
              //assays data GENETIX_CS print
              $("#printHere_CS").html(this.state._assays_hboccontent);
              me.orderId = response.data.orderId;
              this.patientId = response.data.Patient._id;
              this.physicianId = response.data.Physician._id;

              //me.IndicationsTest_otherValue.value = response.data.tests[0].genetixCSIndicationOfTesting.other;

              //Physician
              me.phyinfo_Organization_account.value =
                response.data.Physician.organizationAccount;
              me.phyinfo_first_name.value = response.data.Physician.firstName;
              me.phyinfo_last_name.value = response.data.Physician.lastName;
              me.phyinfo_email.value = response.data.Physician.email;
              me.phyinfo_npi.value = response.data.Physician.npiNumber;
              me.phyinfoC_address.value =
                response.data.Physician.addresses[0].address_1;
              me.phyinfoC_practiceLocation.value =
                response.data.Physician.practiceLocationPlain;
              me.phyinfoC_city.value =
                response.data.Physician.addresses[0].city;
              me.phyinfo_state.value =
                response.data.Physician.addresses[0].state;
              me.phyinfoC_zipCode.value =
                response.data.Physician.addresses[0].zipCode;
              me.phyinfoC_Phone.value =
                response.data.Physician.addresses[0].phoneNo;
              me.phyinfoC_fax.value =
                response.data.Physician.addresses[0].phoneNo;
              me.phyinfoC_Faxcopyto.value =
                response.data.Physician.addresses[0].phoneNo;

              //Patient Information
              //ethnicity array
              const ethnicityArray1 =
                response.data.Patient.ethnicity.split(",");
              me.patinfo_firstname.value = response.data.Patient.firstName;
              me.patinfo_lastname.value = response.data.Patient.lastName;
              me.patinfo_address.value =
                response.data.Patient.address.address_1;
              me.patinfo_State.value = response.data.Patient.address.state;
              me.patinfo_city.value = response.data.Patient.address.city;
              me.patinfo_zipcode.value = response.data.Patient.address.zipCode;
              me.patinfo_PhoneNumber.value = response.data.Patient.phoneNo;
              me.patinfo_emailaddress.value = response.data.Patient.email;
              me.patinfo_OtherEthnicitycity.value = "";
              //Patient information ethnicity
              if (response.data.Patient.ethnicity.includes("Caucasian")) {
                $("#patinfo_Caucasian").prop("checked", true);
              }
              if (
                response.data.Patient.ethnicity.includes("Ashkenazi Jewish")
              ) {
                $("#patinfo_AshkenaziJewish").prop("checked", true);
              }
              if (
                response.data.Patient.ethnicity.includes("Sephardic Jewish")
              ) {
                $("#patinfo_SephardicJewish").prop("checked", true);
              }
              if (response.data.Patient.ethnicity.includes("Asian")) {
                $("#patinfo_Asian").prop("checked", true);
              }
              if (response.data.Patient.ethnicity.includes("Hispanic")) {
                $("#patinfo_HIspanic").prop("checked", true);
              }
              if (response.data.Patient.ethnicity.includes("Native American")) {
                $("#patinfo_NativeAmerican").prop("checked", true);
              }
              if (
                response.data.Patient.ethnicity.includes("African American")
              ) {
                $("#patinfo_AfricanAmerican").prop("checked", true);
              }
              if (response.data.Patient.ethnicity.includes("Other")) {
                $("#patinfo_Other").prop("checked", true);
                me.patinfo_OtherEthnicitycity.value =
                  ethnicityArray1[ethnicityArray1.length - 1];
              }

              //Patient Gender
              //console.log("gender: ",response.data.Patient.gender);
              if (response.data.Patient.gender === "male") {
                $(`#${this.state.frmprefix}patinfo_gender`).prop(
                  "checked",
                  true
                );
              } else {
                $(`#${this.state.frmprefix}patinfo_gender2`).prop(
                  "checked",
                  true
                );
              }

              //billing information
              me.billinfo_PrimaryInsuranceCarrier.value =
                response.data.billingInfo.primaryInsurance.primaryInsuranceCarrier;
              me.billinfo_planId.value =
                response.data.billingInfo.primaryInsurance.planId;
              me.billinfo_MemberID.value =
                response.data.billingInfo.primaryInsurance.memberID;
              me.billinfo_GroupID.value =
                response.data.billingInfo.primaryInsurance.groupID;
              me.billinfo_SecondaryInsuranceCarrier.value =
                response.data.billingInfo.secondaryInsurance.secondaryInsuranceCarrier;
              me.billinfo_SecondaryplanId.value =
                response.data.billingInfo.secondaryInsurance.secondaryplanId;
              me.billinfo_SecondaryMemberID.value =
                response.data.billingInfo.secondaryInsurance.secondaryMemberID;
              me.billinfo_SecondaryGroupID.value =
                response.data.billingInfo.secondaryInsurance.secondaryGroupID;
              me.billinfo_NameofInsured.value =
                response.data.billingInfo.insuredInfo.nameofInsured;
              //me.billinfoC_dob.value = response.data.billingInfo.insuredInfo.GTCS_billinfoC_dob;
              me.billinfo_RelationshipInsured.value =
                response.data.billingInfo.insuredInfo.relationshipInsured;

              me.OtherICD10Codes.value =
                response.data.tests[0].icd10Code.otherCodes;
              me.kitShip_PatOthersInfo.value =
                response.data.tests[0].kitInformation.otherInfo;
              me.kitShip_PatOthersInfo2.value =
                response.data.tests[1].kitInformation.otherInfo;
              me.kitShip_notes.value = response.data.tests[0].notes;

              me.setState(
                {
                  buttonText: "Update",
                  IndicationsTest_otherValue:
                    response.data.tests[0].genetixCSIndicationOfTesting.other,

                  phyinfo_Organization_account:
                    response.data.Physician.organizationAccount,
                  phyinfo_first_name: response.data.Physician.firstName,
                  phyinfo_last_name: response.data.Physician.lastName,
                  phyinfo_email: response.data.Physician.email,
                  phyinfo_npi: response.data.Physician.npiNumber,
                  phyinfoC_address:
                    response.data.Physician.addresses[0].address_1,
                  phyinfoC_practiceLocation:
                    response.data.Physician.practiceLocationPlain,
                  phyinfoC_city: response.data.Physician.addresses[0].city,
                  phyinfo_state: {
                    label: response.data.Physician.addresses[0].state,
                    value: response.data.Physician.addresses[0].state,
                  },
                  phyinfoC_zipCode:
                    response.data.Physician.addresses[0].zipCode,
                  phyinfoC_Phone: response.data.Physician.addresses[0].phoneNo,
                  phyinfoC_fax: response.data.Physician.addresses[0].phoneNo,
                  phyinfoC_Faxcopyto:
                    response.data.Physician.addresses[0].phoneNo,

                  patinfo_firstname: response.data.Patient.firstName,
                  patinfo_lastname: response.data.Patient.lastName,
                  patinfo_address: response.data.Patient.address.address_1,
                  patinfo_State: {
                    label: response.data.Patient.address.state,
                    value: response.data.Patient.address.state,
                  },
                  patinfo_city: response.data.Patient.address.city,
                  patinfo_zipcode: response.data.Patient.address.zipCode,
                  patinfo_PhoneNumber: response.data.Patient.phoneNo,
                  patinfo_emailaddress: response.data.Patient.email,
                  patinfo_dob: patinfo_dobFormatted,
                  patinfo_OtherEthnicitycity:
                    response.data.Patient.ethnicity.includes("Other")
                      ? ethnicityArray1[ethnicityArray1.length - 1]
                      : "",
                  patinfo_gender: response.data.Patient.gender,

                  //billing info
                  billinfo_PrimaryInsuranceCarrier:
                    response.data.billingInfo.primaryInsurance
                      .primaryInsuranceCarrier,
                  billinfo_planId:
                    response.data.billingInfo.primaryInsurance.planId,
                  billinfo_MemberID:
                    response.data.billingInfo.primaryInsurance.memberID,
                  billinfo_GroupID:
                    response.data.billingInfo.primaryInsurance.groupID,
                  billinfo_SecondaryInsuranceCarrier:
                    response.data.billingInfo.secondaryInsurance
                      .secondaryInsuranceCarrier,
                  billinfo_SecondaryplanId:
                    response.data.billingInfo.secondaryInsurance
                      .secondaryplanId,
                  billinfo_SecondaryMemberID:
                    response.data.billingInfo.secondaryInsurance
                      .secondaryMemberID,
                  billinfo_SecondaryGroupID:
                    response.data.billingInfo.secondaryInsurance
                      .secondaryGroupID,
                  billinfo_NameofInsured:
                    response.data.billingInfo.insuredInfo.nameofInsured,
                  billinfoC_dob: billinfoC_dobFormatted,
                  billinfo_RelationshipInsured:
                    response.data.billingInfo.insuredInfo.relationshipInsured,

                  OtherICD10Codes: response.data.tests[0].icd10Code.otherCodes,
                  kitShip_PatOthersInfo:
                    response.data.tests[0].kitInformation.otherInfo,
                  kitShip_PatOthersInfo2:
                    response.data.tests[1].kitInformation.otherInfo,
                  kitShip_notes: response.data.tests[0].notes,
                },
                () => {
                  //ASPIRA GENETIX Hereditary Breast and Ovarian Cancer
                  if (
                    typeof this.state.orderData.tests[1].genetixCSPanel !=
                    "undefined"
                  ) {
                    if (
                      this.state.orderData.tests[1].genetixCSPanel.length > 0
                    ) {
                      this.state._assays_hboc.map((a, i) => {
                        if (
                          this.state.orderData.tests[1].genetixCSPanel.includes(
                            a.name
                          )
                        ) {
                          $(`#hboc_assay_${i}`).prop("checked", true);
                        }
                      });
                    }
                  }

                  //Billing information

                  if (this.state.orderData.billingInfo.medicare) {
                    $("#billinfo_Medicare").prop("checked", true);
                    showInsuranceTitle();
                    showPrimaryAreaClick();
                    hideSecondaryAreaClick();
                    showInsuranceInsured();
                  }
                  if (this.state.orderData.billingInfo.privateInsurance) {
                    $("#billinfo_PrivateInsurance").prop("checked", true);
                    showInsuranceTitle();
                    showPrimaryAreaClick();
                    showSecondaryAreaClick();
                    showInsuranceInsured();
                  }
                  if (this.state.orderData.billingInfo.patientSelfPay) {
                    $("#billinfo_PatientSelfPay").prop("checked", true);
                    hidePrimaryAreaClick();
                    hideSecondaryAreaClick();
                    hideInsuranceTitle();
                    hideInsuranceInsured();
                  }
                  if (this.state.orderData.billingInfo.medicaid) {
                    $("#billinfo_Medicaid").prop("checked", true);
                    showInsuranceTitle();
                    showPrimaryAreaClick();
                    hideSecondaryAreaClick();
                    showInsuranceInsured();
                  }
                  if (this.state.orderData.billingInfo.orderingFacility) {
                    $("#billinfo_OrderingFacility").prop("checked", true);
                    hidePrimaryAreaClick();
                    hideSecondaryAreaClick();
                    hideInsuranceTitle();
                    hideInsuranceInsured();
                  }
                  if (this.state.orderData.billingInfo.freeTrial) {
                    $("#billinfo_FreeTrial").prop("checked", true);
                    hidePrimaryAreaClick();
                    hideSecondaryAreaClick();
                    // hideTertiaryAreaClick();
                    hideInsuranceTitle();
                    hideInsuranceInsured();
                    // hidePayorListSearchAndSelect();
                  }

                  //Diagnosis Codes | ICD-10 Codes

                  if (this.state.orderData.tests.length > 0) {
                    if (
                      this.state.orderData.tests[0].icd10Code.codes.length > 0
                    ) {
                      //ova1

                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "N83.201"
                        )
                      ) {
                        $("#diagnosis_UnOcyst_rs").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "R19.00"
                        )
                      ) {
                        $("#diagnosis_IntraAbdPelSwell").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "R19.05"
                        )
                      ) {
                        $("#diagnosis_Periumbilicalswell").prop(
                          "checked",
                          true
                        );
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "N83.202"
                        )
                      ) {
                        $("#diagnosis_UnOcyst_ls").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "R19.03"
                        )
                      ) {
                        $("#diagnosis_lowerquadrantSwell").prop(
                          "checked",
                          true
                        );
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "R19.09"
                        )
                      ) {
                        $("#diagnosis_intraabdominalpelvicswell").prop(
                          "checked",
                          true
                        );
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "N83.209"
                        )
                      ) {
                        $("#diagnosis_UnOcyst").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[0].icd10Code.codes.includes(
                          "R19.04"
                        )
                      ) {
                        $("#diagnosis_LlQabdominalswelling").prop(
                          "checked",
                          true
                        );
                      }
                    } //end ova1
                  }

                  if (this.state.orderData.tests.length > 1) {
                    if (
                      this.state.orderData.tests[1].icd10Code.codes.length > 0
                    ) {
                      //HBOC

                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "Z15.01"
                        )
                      ) {
                        $("#hBOCdiagnosis_z1501").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "C48.1"
                        )
                      ) {
                        $("#hBOCdiagnosis_c481").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "Z15.02"
                        )
                      ) {
                        $("#hBOCdiagnosis_z1502").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "C50.0"
                        )
                      ) {
                        $("#hBOCdiagnosis_c500").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "Z15.03"
                        )
                      ) {
                        $("#hBOCdiagnosis_z1503").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "C56.1"
                        )
                      ) {
                        $("#hBOCdiagnosis_c561").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "Z15.04"
                        )
                      ) {
                        $("#hBOCdiagnosis_z1504").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "C56.2"
                        )
                      ) {
                        $("#hBOCdiagnosis_c562").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "Z15.09"
                        )
                      ) {
                        $("#hBOCdiagnosis_z1509").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "C56.9"
                        )
                      ) {
                        $("#hBOCdiagnosis_c569").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "Z15.81"
                        )
                      ) {
                        $("#hBOCdiagnosis_z1581").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "C57.00"
                        )
                      ) {
                        $("#hBOCdiagnosis_c5700").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "C25.0"
                        )
                      ) {
                        $("#hBOCdiagnosis_C250").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "C57.01"
                        )
                      ) {
                        $("#hBOCdiagnosis_c5701").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "C25.1"
                        )
                      ) {
                        $("#hBOCdiagnosis_C251").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "C57.01"
                        )
                      ) {
                        $("#hBOCdiagnosis_c5702").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "C25.2"
                        )
                      ) {
                        $("#hBOCdiagnosis_C252").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "C61"
                        )
                      ) {
                        $("#hBOCdiagnosis_c61").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "C25.3"
                        )
                      ) {
                        $("#hBOCdiagnosis_C253").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "D05.11"
                        )
                      ) {
                        $("#hBOCdiagnosis_D0511").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "C25.4"
                        )
                      ) {
                        $("#hBOCdiagnosis_C254").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "D05.12"
                        )
                      ) {
                        $("#hBOCdiagnosis_D0512").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "C25.7"
                        )
                      ) {
                        $("#hBOCdiagnosis_C257").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "Z85.07"
                        )
                      ) {
                        $("#hBOCdiagnosis_Z8507").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "C25.8"
                        )
                      ) {
                        $("#hBOCdiagnosis_C258").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "Z85.3"
                        )
                      ) {
                        $("#hBOCdiagnosis_Z853").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "C25.9"
                        )
                      ) {
                        $("#hBOCdiagnosis_C259").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "Z85.43"
                        )
                      ) {
                        $("#hBOCdiagnosis_Z8543").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[1].icd10Code.codes.includes(
                          "Z85.46"
                        )
                      ) {
                        $("#hBOCdiagnosis_Z8546").prop("checked", true);
                      }
                    }
                  }

                  if (this.state.orderData.tests.length > 2) {
                    $("#covid_icd_codes").removeClass(" hidediv");
                    $("#covid_icd_codes").addClass(" showdiv");

                    if (
                      this.state.orderData.tests[2].icd10Code.codes.length > 0
                    ) {
                      if (
                        this.state.orderData.tests[2].icd10Code.codes.includes(
                          "Z11.59"
                        )
                      ) {
                        $("#diagnosis_z1159").prop("checked", true);
                      }
                      if (
                        this.state.orderData.tests[2].icd10Code.codes.includes(
                          "Z03.818"
                        )
                      ) {
                        $("#diagnosis_z03818").prop("checked", true);
                      }
                    }
                  }
                  //end Diagnosis Codes | ICD-10 Codes

                  //OVA1 Kit Shipping Information
                  if (
                    this.state.orderData.tests[0].kitInformation.drawType
                      .length > 0
                  ) {
                    if (
                      this.state.orderData.tests[0].kitInformation.drawType.includes(
                        "My physician needs mobile phlebotomy"
                      )
                    ) {
                      $("#kitShip_PatMobPhlebotomy").prop("checked", true);
                    }
                    if (
                      this.state.orderData.tests[0].kitInformation.drawType.includes(
                        "Patient will get blood draw at Quest PSC"
                      )
                    ) {
                      $("#kitShip_PatblooddrawQuest").prop("checked", true);
                    }
                    if (
                      this.state.orderData.tests[0].kitInformation.drawType.includes(
                        "Other"
                      )
                    ) {
                      this.setState({ kitShip_PatOthers: true });
                      $("#kitShip_PatOthers").prop("checked", true);
                    }
                  } //end Kit Shipping Information

                  //HBOC Kit Shipping Information
                  if (
                    this.state.orderData.tests[1].kitInformation.drawType
                      .length > 0
                  ) {
                    if (
                      this.state.orderData.tests[1].kitInformation.drawType.includes(
                        "My physician needs mobile phlebotomy"
                      )
                    ) {
                      $("#kitShip_PatMobPhlebotomy2").prop("checked", true);
                    }
                    if (
                      this.state.orderData.tests[1].kitInformation.drawType.includes(
                        "Patient will get blood draw at Quest PSC"
                      )
                    ) {
                      $("#kitShip_PatblooddrawQuest2").prop("checked", true);
                    }
                    if (
                      this.state.orderData.tests[1].kitInformation.drawType.includes(
                        "Other"
                      )
                    ) {
                      this.setState({ kitShip_PatOthers2: true });
                      $("#kitShip_PatOthers2").prop("checked", true);
                    }
                  } //end Kit Shipping Information

                  //certify Types certifyTypes
                  if (typeof this.state.orderData.certifyTypes != "undefined") {
                    if (this.state.orderData.certifyTypes.length > 0) {
                      if (
                        this.state.orderData.certifyTypes.includes(
                          "A healthcare provider"
                        )
                      ) {
                        $("#kitShip_healthcareProvider").prop("checked", true);
                      }
                      if (
                        this.state.orderData.certifyTypes.includes(
                          "An Aspira employee ordering on behalf of a healthcare provider"
                        )
                      ) {
                        $("#kitShip_employeehealthcareProvider").prop(
                          "checked",
                          true
                        );
                      }
                      if (
                        this.state.orderData.certifyTypes.includes(
                          "An Aspira partner ordering on behalf of a healthcare provider"
                        )
                      ) {
                        $("#kitShip_partnerhealthcareProvider").prop(
                          "checked",
                          true
                        );
                      }
                    }
                  }
                  //certify Types certifyTypes end
                }
              );
            }
          );
        }
      }
    );
  }

  //onChangeDuedate_pregnant = date => this.setState({ duedate_pregnant: date });
  onChangePatinfoDob = (date) => this.setState({ patinfo_dob: date });
  onChangeBillinfoCDob = (date) => this.setState({ billinfoC_dob: date });

  showToast(type) {
    const id = Math.floor(Math.random() * 101 + 1);

    switch (type) {
      case "success":
        this.toastProperties = {
          id,
          title: "Success",
          description: "This is a success toast component",
          backgroundColor: "#5cb85c",
          icon: "/assets/assets/check.svg",
        };
        break;
      case "danger":
        this.toastProperties = {
          id,
          title: "Danger",
          description: "This is a error toast component",
          backgroundColor: "#d9534f",
          icon: "/assets/assets/error.svg",
        };
        break;
      case "info":
        this.toastProperties = {
          id,
          title: "Info",
          description: "This is an info toast component",
          backgroundColor: "#5bc0de",
          icon: "/assets/assets/info.svg",
        };
        break;
      case "warning":
        this.toastProperties = {
          id,
          title: "Warning",
          description: "This is a warning toast component",
          backgroundColor: "#f0ad4e",
          icon: "/assets/assets/warning.svg",
        };
        break;

      default:
        this.setState({ toastList: [] });
    }

    // this.setState([...this.state.list, this.toastProperties]);
    this.setState({ list: [this.toastProperties] });
  }

  handleSave() {
    const user = JSON.parse(localStorage.getItem("user"));
    console.log("user.token: ", user.token);

    const formData = new FormData();

    //ASPIRA GENETIX Hereditary Breast and Ovarian Cancer
    const hbocOvarianCancerFormPanel = [];
    $(
      `#${this.state.frmprefix}ASPGENHereditaryBreastOvarianCancerForm :input`
    ).each((i, inp) => {
      if ($(inp).is(":checked")) {
        // hbocOvarianCancerFormPanel.push($(inp).data('val'));
        hbocOvarianCancerFormPanel.push({
          code: $(inp).data("val"),
          qBenchId: $(inp).data("qbenchid"),
        });
      }
    });

    //add physician
    let addresses = [
      {
        locationName: "USA",
        address_1: me.phyinfoC_address.value,
        address_2: "",
        addressType: "Primary",
        city: me.phyinfoC_city.value,
        country: "USA",
        state: me.state.phyinfo_state.value,
        zipCode: me.phyinfoC_zipCode.value,
        phoneNo: me.phyinfoC_Phone.value,
      },
    ];

    let order = {
      orderId: this.orderId,
      _id: this._id,
      physician: {
        physicianId: this.physicianId,
        organizationAccount: me.phyinfo_Organization_account.value,
        firstName: me.phyinfo_first_name.value,
        lastName: me.phyinfo_last_name.value,
        email: me.phyinfo_email.value,
        npiNumber: me.phyinfo_npi.value,
        addresses: addresses,
      },
      tests: [
        {
          ova1PlusBiomarkers: [],
          icd10Code: {},
          kitInformation: {
            drawType: [""],
            otherInfo: "",
          },
          notes: "",
          genetixCSPanel: [],
          genetixCSIndicationOfTesting: {},
          dueDate: "",
          ultrasoundsFindings: "",
          clinicalData: [],
          testName: "OVA1",
          testCode: "OVA1",
        },
      ],
      certifyTypes: [],
    };

    order.tests[0].clinicalData = this.state.orderData.tests[0].clinicalData;
    //add physician

    //add patient
    let ethnicities = [];
    let inputs = $("#pat_ethnicity_container").find(":input");
    inputs.map((i, elem) => {
      let $elem = $(elem);

      const _type = $elem.attr("type");

      if (_type == "checkbox") {
        if ($elem.is(":checked")) {
          ethnicities.push($elem.data("val"));
        }
      }
    });
    if ($("#pat_ethnicity_container #patinfo_Other").is(":checked")) {
      ethnicities.push($("#patinfo_OtherEthnicitycity").val());
    }

    let gender = "";
    if ($(`#${this.state.frmprefix}patinfo_gender`).is(":checked")) {
      gender = "male";
    } else {
      gender = "female";
    }

    let patinfo_dob = moment(this.state.patinfo_dob).format("YYYY-MM-DD");
    let patientObj = {
      patientId: this.patientId,
      email: this.patinfo_emailaddress.value,
      firstName: this.patinfo_firstname.value,
      lastName: this.patinfo_lastname.value,
      dob: patinfo_dob,
      phoneNo: this.patinfo_PhoneNumber.value,
      address: {
        locationName: "USA",
        address_1: this.patinfo_address.value,
        address_2: "",
        addressType: "Primary",
        city: this.patinfo_city.value,
        country: "USA",
        state: this.state.patinfo_State.value,
        zipCode: this.patinfo_zipcode.value,
      },

      ethnicity: ethnicities.join(),
      gender: gender,
    };

    order.patient = patientObj;

    //add patient

    //Billing Information
    const $billinfo_PrivateInsurance = $("#billinfo_PrivateInsurance");
    const $billinfo_Medicare = $("#billinfo_Medicare");
    const $billinfo_PatientSelfPay = $("#billinfo_PatientSelfPay");
    const $billinfo_Medicaidconst = $("#billinfo_Medicaidconst");
    const $billinfo_Orderingconst = $("#billinfo_Orderingconst");

    let billinfo_PrivateInsurance = false;
    if ($("#billinfo_PrivateInsurance").is(":checked")) {
      billinfo_PrivateInsurance = true;
    }
    let billinfo_Medicare = false;
    if ($("#billinfo_Medicare").is(":checked")) {
      billinfo_Medicare = true;
    }
    let billinfo_PatientSelfPay = false;
    if ($("#billinfo_PatientSelfPay").is(":checked")) {
      billinfo_PatientSelfPay = true;
    }
    let billinfo_Medicaidconst = false;
    if ($("#billinfo_Medicaid").is(":checked")) {
      billinfo_Medicaidconst = true;
    }
    let billinfo_FreeTrial = false;
    if ($("#billinfo_FreeTrial").is(":checked")) {
      billinfo_FreeTrial = true;
    }
    let billinfo_Orderingconst = false;
    if ($("#billinfo_OrderingFacility").is(":checked")) {
      billinfo_Orderingconst = true;
    }
    let billingInfo = {
      privateInsurance: billinfo_PrivateInsurance,
      medicare: billinfo_Medicare,
      patientSelfPay: billinfo_PatientSelfPay,
      medicaid: billinfo_Medicaidconst,
      orderingFacility: billinfo_Orderingconst,
      freeTrial: billinfo_FreeTrial,
      primaryInsurance: {
        primaryInsuranceCarrier: this.billinfo_PrimaryInsuranceCarrier.value,
        planId: this.billinfo_planId.value,
        memberID: this.billinfo_MemberID.value,
        groupID: this.billinfo_GroupID.value,
      },
      secondaryInsurance: {
        secondaryInsuranceCarrier:
          this.billinfo_SecondaryInsuranceCarrier.value,
        secondaryplanId: this.billinfo_SecondaryplanId.value,
        secondaryMemberID: this.billinfo_SecondaryMemberID.value,
        secondaryGroupID: this.billinfo_SecondaryGroupID.value,
      },
      insuredInfo: {
        nameofInsured: this.billinfo_NameofInsured.value,
        ova1GenetixHBOC_billinfoC_dob: moment(this.state.billinfoC_dob).format(
          "YYYY-MM-DD"
        ),
        relationshipInsured: this.billinfo_RelationshipInsured.value,
      },
    };
    order.billingInfo = billingInfo;
    //Billing Information

    //ICD Codes OVA1
    let icd10Code = {
      codes: [],
      otherCodes: [],
    };

    inputs = $(`#${this.state.frmprefix}hboc_icd_codes  #ova1_icd_codes`).find(
      ":input"
    );
    inputs.map((i, elem) => {
      let $elem = $(elem);

      const _type = $elem.attr("type");

      if (_type == "checkbox") {
        if ($elem.is(":checked")) {
          icd10Code.codes.push($elem.data("val"));
        }
      }
    });

    icd10Code.otherCodes = this.OtherICD10Codes.value;

    order.tests[0].icd10Code = icd10Code;

    //OVA1 Kit Shipping Info
    let kitInformation = {
      drawType: [],
      otherInfo: "",
    };
    inputs = $(
      `#${this.state.frmprefix}kitShippingInfoFormContainer #draw_type`
    ).find(":input");
    inputs.map((i, elem) => {
      let $elem = $(elem);

      const _type = $elem.attr("type");

      if (_type == "checkbox") {
        if ($elem.is(":checked")) {
          kitInformation.drawType.push($elem.data("val"));
        }
      }
    });
    kitInformation.otherInfo = this.kitShip_PatOthersInfo.value;
    order.tests[0].kitInformation = kitInformation;
    //Kit Shipping Info

    //Notes & Certify
    order.tests[0].notes = this.kitShip_notes.value;

    const $kitShip_healthcareProvider = $("#kitShip_healthcareProvider");
    const $kitShip_employeehealthcareProvider = $(
      "#kitShip_employeehealthcareProvider"
    );
    const $kitShip_partnerhealthcareProvider = $(
      "#kitShip_partnerhealthcareProvider"
    );
    if ($kitShip_healthcareProvider.is(":checked")) {
      order.certifyTypes.push($kitShip_healthcareProvider.data("val"));
    }
    if ($kitShip_employeehealthcareProvider.is(":checked")) {
      order.certifyTypes.push($kitShip_employeehealthcareProvider.data("val"));
    }
    if ($kitShip_partnerhealthcareProvider.is(":checked")) {
      order.certifyTypes.push($kitShip_partnerhealthcareProvider.data("val"));
    }
    //Notes & Certify

    //HBOC ICD codes
    const icd10CodesHBOC = [];
    let icd10CodeHBOC = {
      codes: [],
      otherCodes: "",
    };

    inputs = $(`#${this.state.frmprefix}hboc_icd_codes  #hboc_icd_codes`).find(
      ":input"
    );
    inputs.map((i, elem) => {
      let $elem = $(elem);

      const _type = $elem.attr("type");

      if (_type == "checkbox") {
        if ($elem.is(":checked")) {
          icd10CodesHBOC.push($elem.data("val"));
        }
      }
    });

    icd10CodeHBOC = {
      codes: icd10CodesHBOC,
      otherCodes: "",
    };

    //HBOC Kit Shipping
    let kitInformation_hboc = {
      drawType: [],
      otherInfo: "",
    };
    inputs = $(
      `#${this.state.frmprefix}kitShippingInfoFormContainerHBOC #draw_type`
    ).find(":input");
    inputs.map((i, elem) => {
      let $elem = $(elem);

      const _type = $elem.attr("type");

      if (_type == "checkbox") {
        if ($elem.is(":checked")) {
          kitInformation_hboc.drawType.push($elem.data("val"));
        }
      }
    });
    kitInformation_hboc.otherInfo = this.kitShip_PatOthersInfo2.value;
    console.log("sm kitInformation_hboc: ", kitInformation_hboc);
    return;

    var HBOC_tests = {
      ova1PlusBiomarkers: [],
      clinicalData: [],
      genetixCSIndicationOfTesting: {},
      genetixCSPanel: hbocOvarianCancerFormPanel,
      testName: "GenetixHBOC1",
      testCode: "GenetixHBOC1",
      icd10Code: icd10CodeHBOC,
      kitInformation: kitInformation_hboc,
    };

    order.tests.push(HBOC_tests);

    //covid_icd_codes
    if (this.state.orderData.tests.length > 2) {
      const icd10CodesCOVD = [];
      let icd10CodeCOVD = {
        codes: [],
        otherCodes: "",
      };

      inputs = $(
        `#${this.state.frmprefix}hboc_icd_codes #covid_icd_codes`
      ).find(":input");
      inputs.map((i, input) => {
        const $input = $(input);

        const _type = $input.attr("type");

        if (_type == "checkbox") {
          if ($input.is(":checked")) {
            icd10CodesCOVD.push($input.data("val"));
          }
        }
      });

      icd10CodeCOVD = {
        codes: icd10CodesCOVD,
        otherCodes: "",
      };

      var covid_tests = {
        testName: "COVD",
        testCode: "COVD",
        icd10Code: icd10CodeCOVD,
      };

      order.tests.push(covid_tests);
    }
    //ICD Codes

    formData.append("order", JSON.stringify(order));

    formData.append("host", window.location.host);

    if (me._id) {
      // formData.append("itemID", me._id);
      // formData.append("orderId", me.orderId);
      axios
        .put(`${API_BASE_URL}/orders`, formData, {
          headers: {
            Authorization: "Bearer " + user.token,
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        })
        .then(({ data }) => {
          console.log("data: ", data);

          this.setState(
            {
              toastMessage: `OVA1 HBOC order has beenupdated Successfully`,
            },
            () => {
              this.showToast("success");
            }
          );
        });
    } else {
      axios
        .post(`${API_BASE_URL}/orders`, formData, {
          headers: {
            Authorization: "Bearer " + user.token,
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        })
        .then(({ data }) => {
          console.log("data: ", data);

          this.setState(
            {
              toastMessage: `OVA1 HBOC order has been added Successfully`,
            },
            () => {
              this.showToast("success");
            }
          );
        });
    }
  }

  render() {
    if (this.state.loading) {
      return null;
    }

    return (
      <div>
        <div class="block-header">
          <h2>Edit Order</h2>
          {/* <ul class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/orders">Orders</a></li>
                        <li class="breadcrumb-item active">{(this.state.orderData._id) ? "Edit Order" : "New Order"}</li>
                    </ul> */}
        </div>
        <div
          class="panel-group card"
          id="accordion_105"
          role="tablist"
          aria-multiselectable="true"
        >
          <Toast
            toastList={me.state.list}
            position={"top-center"}
            autoDelete={true}
            dismissTime={3000}
            description={me.state.toastMessage}
          />
          {console.log("Order: ", this.state.orderData)}

          {/* Top Actions */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">Actions</h4>
            </div>
          </div>
          <TopActions
            orderId={this.orderId}
            reportId={reportId}
            galenReportId={me.state.orderData.galenReportId}
            context={this.context}
            orderData={this.state.orderData}
            reportDate={this.state.reportDate}
          />
          {/* baylor */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">Baylor</h4>
            </div>
          </div>
          <div class="body card">
            <div class="row clearfix">
              <div class="col-md-12">
                <div class="form-group required">
                  <label class="form-label" for="baylor">
                    Baylor Status:{" "}
                  </label>
                </div>
              </div>
            </div>
          </div>

          {/* ASPIRA GENETIX Hereditary Breast and Ovarian Cancer */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">
                ASPIRA GENETIX Hereditary Breast and Ovarian Cancer
              </h4>
            </div>
          </div>
          <div
            id={`${this.state.frmprefix}ASPGENHereditaryBreastOvarianCancerForm`}
            class="body card"
          >
            <div class="row clearfix">
              <div class="col-md-12">
                <div class="form-group required">
                  <label class="form-label" for="address">
                    ASPIRA GENETIX Hereditary Breast and Ovarian Cancer (select
                    panel)
                  </label>
                  <div class="demo-radio-button">
                    <div id="printHere_CS" class="row clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ASPIRA GENETIX Hereditary Breast and Ovarian Cancer */}

          {/* GTCS Physician Information */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">Physician Information</h4>
            </div>
          </div>
          <div id="PhysicianInformationForm" className="body card">
            <div class="row clearfix">
              <div class="col-md-12">
                <div class="form-group">
                  <label class="form-label" for="ordered_before1">
                    Organization/Account #
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      ref={(ref) => (me.phyinfo_Organization_account = ref)}
                      id={`phyinfo_Organization_account`}
                      class="form-control"
                      placeholder="Organization/Account #"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row clearfix">
              <div class="col-md-3">
                <div class="form-group required">
                  <label class="form-label" for="ordered_before1">
                    First Name
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      ref={(ref) => (me.phyinfo_first_name = ref)}
                      id={`phyinfo_first_name`}
                      class="form-control required"
                      placeholder="First Name"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group required">
                  <label class="form-label" for="ordered_before1">
                    Last Name{" "}
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      ref={(ref) => (me.phyinfo_last_name = ref)}
                      id={`phyinfo_last_name`}
                      class="form-control required"
                      placeholder="Last Name"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group required">
                  <label class="form-label" for="ordered_before1">
                    Email
                  </label>
                  <div class="form-line">
                    <input
                      type="email"
                      ref={(ref) => (me.phyinfo_email = ref)}
                      id={`phyinfo_email`}
                      class="form-control required"
                      placeholder="Email"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group required">
                  <label class="form-label" for="ordered_before1">
                    NPI
                  </label>
                  <div class="form-line">
                    <input
                      type="number"
                      ref={(ref) => (me.phyinfo_npi = ref)}
                      id={`phyinfo_npi`}
                      class="form-control required"
                      placeholder="NPI"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row clearfix">
              <div class="col-md-6">
                <div class="form-group required">
                  <label class="form-label" for="ordered_before1">
                    Address
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      ref={(ref) => (me.phyinfoC_address = ref)}
                      id={`phyinfoC_address`}
                      class="form-control required"
                      placeholder="Address"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group required">
                  <label class="form-label" for="ordered_before1">
                    Practice Location
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      ref={(ref) => (me.phyinfoC_practiceLocation = ref)}
                      id={`phyinfoC_practiceLocation`}
                      class="form-control required"
                      placeholder="Practice Location"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row clearfix">
              <div class="col-md-4">
                <div class="form-group ">
                  <label class="form-label" for="ordered_before1">
                    City{" "}
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      ref={(ref) => (me.phyinfoC_city = ref)}
                      id={`phyinfoC_city`}
                      class="form-control"
                      placeholder="City"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group ">
                  <label class="form-label" for="ordered_before1">
                    State
                  </label>
                  <div class="form-line">
                    <Select
                      class="form-control"
                      ref={(_ref) => {
                        this.phyinfo_state = _ref;
                      }}
                      id={`phyinfo_state`}
                      // defaultValue={"Choose State"}
                      value={this.state.phyinfo_state}
                      label="State:"
                      options={helper.usStates}
                      styles={helper.colourStyles}
                      onChange={(val) => {
                        this.setState({ phyinfo_state: val });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group ">
                  <label class="form-label" for="ordered_before1">
                    Zip Code
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      ref={(ref) => (me.phyinfoC_zipCode = ref)}
                      id={`phyinfoC_zipCode`}
                      class="form-control"
                      placeholder="Zip Code"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row clearfix">
              <div class="col-md-4">
                <div class="form-group required">
                  <label class="form-label" for="ordered_before1">
                    Phone{" "}
                  </label>
                  <div class="form-line">
                    {/* <PhoneNumberInput prefix={`phyinfoC_`} /> */}
                    <input
                      type="text"
                      ref={(ref) => (me.phyinfoC_Phone = ref)}
                      id="phyinfoC_Phone"
                      class="form-control required"
                      placeholder="Phone"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <label class="form-label" for="ordered_before1">
                    Fax
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      ref={(ref) => (me.phyinfoC_fax = ref)}
                      id={`phyinfoC_fax`}
                      class="form-control"
                      placeholder="Fax"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group ">
                  <label class="form-label" for="ordered_before1">
                    Fax copy to
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      ref={(ref) => (me.phyinfoC_Faxcopyto = ref)}
                      id={`phyinfoC_Faxcopyto`}
                      class="form-control required"
                      placeholder="Fax copy to"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* GTCS Physician Information end */}
          {/* GTCS Patient Information */}
          {/* {this.state.orderData.Patient.email} */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">Patient Information</h4>
            </div>
          </div>
          <div id={`${this.state.frmprefix}PatientContainer`} class="body card">
            <div class="row clearfix">
              <div class="col-md-6">
                <div class="form-group required">
                  <label
                    class="form-label"
                    for={`${this.state.frmprefix}patinfo_firstname`}
                  >
                    First Name
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      ref={(ref) => (me.patinfo_firstname = ref)}
                      id={`patinfo_firstname`}
                      class="form-control required"
                      placeholder="First Name"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group required">
                  <label
                    class="form-label"
                    for={`${this.state.frmprefix}patinfo_lastname`}
                  >
                    Last Name
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      ref={(ref) => (me.patinfo_lastname = ref)}
                      id={`patinfo_lastname`}
                      class="form-control required"
                      placeholder="Last Name"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row clearfix">
              <div class="col-md-6">
                <div class="form-group required">
                  <label
                    class="form-label"
                    for={`${this.state.frmprefix}patinfo_gender`}
                  >
                    Gender
                  </label>
                  <div class="demo-radio-button">
                    <input
                      type="radio"
                      id={`${this.state.frmprefix}patinfo_gender`}
                      name="patinfo_gender_"
                      value="male"
                      class=" form-control"
                    />
                    <label
                      class="form-label gender"
                      for={`${this.state.frmprefix}patinfo_gender`}
                    >
                      Male &nbsp;{" "}
                    </label>
                    <input
                      type="radio"
                      id={`${this.state.frmprefix}patinfo_gender2`}
                      name="patinfo_gender_"
                      value="female"
                      class=" form-control"
                    />
                    <label
                      class="form-label gender"
                      for={`${this.state.frmprefix}patinfo_gender2`}
                    >
                      Female &nbsp;{" "}
                    </label>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group required">
                  <label
                    class="form-label"
                    for={`${this.state.frmprefix}patinfo_address`}
                  >
                    Address
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      ref={(ref) => (me.patinfo_address = ref)}
                      id={`patinfo_address`}
                      class="form-control required"
                      placeholder="Address"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row clearfix">
              <div class="col-md-4">
                <div class="form-group required">
                  <label
                    class="form-label"
                    for={`${this.state.frmprefix}patinfo_city`}
                  >
                    City
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      ref={(ref) => (me.patinfo_city = ref)}
                      id={`patinfo_city`}
                      class="form-control required"
                      placeholder="City"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group required">
                  <label class="form-label" for="state">
                    State
                  </label>
                  <div class="form-line">
                    <Select
                      class="form-control"
                      ref={(_ref) => {
                        me.patinfo_State = _ref;
                      }}
                      id={`patinfo_State`}
                      // defaultValue={"Choose State"}
                      value={me.state.patinfo_State}
                      label="State:"
                      options={helper.usStates}
                      styles={helper.colourStyles}
                      onChange={(val) => me.setState({ patinfo_State: val })}
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group required">
                  <label
                    class="form-label"
                    for={`${this.state.frmprefix}patinfo_zipcode`}
                  >
                    Zip Code
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      ref={(ref) => (me.patinfo_zipcode = ref)}
                      id={`patinfo_zipcode`}
                      class="form-control required"
                      placeholder="Zip Code"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row clearfix">
              <div class="col-md-4">
                <div class="form-group required">
                  <label
                    class="form-label"
                    for={`${this.state.frmprefix}patinfo_DOB`}
                  >
                    DOB (mm/dd/yyyy)
                  </label>
                  <div class="form-line">
                    <DatePicker
                      id={`patinfo_dob`}
                      className={"form-control required"}
                      selected={this.state.patinfo_dob}
                      onChange={(date) => this.onChangePatinfoDob(date)}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group required">
                  <label class="form-label" for="PhoneNumber">
                    Phone Number
                  </label>
                  <div class="form-line">
                    {/* <PhoneNumberInput prefix={`patinfo_`} /> */}
                    <input
                      type="text"
                      ref={(ref) => (me.patinfo_PhoneNumber = ref)}
                      id={`patinfo_PhoneNumber`}
                      class="form-control required"
                      placeholder="Phone Number"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="form-group required">
                  <label
                    class="form-label"
                    for={`${this.state.frmprefix}patinfo_emailaddress`}
                  >
                    Email Address
                  </label>
                  <div class="form-line">
                    <input
                      type="email"
                      ref={(ref) => (me.patinfo_emailaddress = ref)}
                      id={`patinfo_emailaddress`}
                      class="form-control required"
                      placeholder="Email Address"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row clearfix">
              <div class="col-md-12">
                <div class="form-group required">
                  <label class="form-label" for="Ethnicity">
                    Ethnicity (Check all that apply)
                  </label>
                  <div class="demo-checkbox" id="pat_ethnicity_container">
                    <input
                      type="checkbox"
                      data-val="Caucasian"
                      id={`patinfo_Caucasian`}
                      name="patinfo_Caucasian"
                      class="filled-in"
                    />
                    <label for={`patinfo_Caucasian`}>
                      Caucasian &nbsp; &nbsp;{" "}
                    </label>
                    <input
                      type="checkbox"
                      data-val="Ashkenazi Jewish"
                      id={`patinfo_AshkenaziJewish`}
                      name="patinfo_AshkenaziJewish"
                      class="filled-in"
                    />
                    <label for={`patinfo_AshkenaziJewish`}>
                      Ashkenazi Jewish &nbsp; &nbsp;{" "}
                    </label>
                    <input
                      type="checkbox"
                      data-val="Sephardic Jewish"
                      id={`patinfo_SephardicJewish`}
                      name="patinfo_SephardicJewish"
                      class="filled-in"
                    />
                    <label for={`patinfo_SephardicJewish`}>
                      Sephardic Jewish &nbsp; &nbsp;{" "}
                    </label>
                    <input
                      type="checkbox"
                      data-val="Asian"
                      id={`patinfo_Asian`}
                      name="patinfo_Asian"
                      class="filled-in"
                    />
                    <label for={`patinfo_Asian`}>Asian &nbsp; &nbsp; </label>
                    <input
                      type="checkbox"
                      data-val="Hispanic"
                      id={`patinfo_HIspanic`}
                      name="patinfo_HIspanic"
                      class="filled-in"
                    />
                    <label for={`patinfo_HIspanic`}>
                      Hispanic &nbsp; &nbsp;{" "}
                    </label>
                    <input
                      type="checkbox"
                      data-val="Native American"
                      id={`patinfo_NativeAmerican`}
                      name="patinfo_NativeAmerican"
                      class="filled-in"
                    />
                    <label for={`patinfo_NativeAmerican`}>
                      Native American &nbsp; &nbsp;{" "}
                    </label>
                    <input
                      type="checkbox"
                      data-val="African American"
                      id={`patinfo_AfricanAmerican`}
                      name="patinfo_AfricanAmerican"
                      class="filled-in"
                    />
                    <label for={`patinfo_AfricanAmerican`}>
                      African American &nbsp; &nbsp;{" "}
                    </label>
                    <input
                      type="checkbox"
                      data-val="Other"
                      id={`patinfo_Other`}
                      name="patinfo_Other"
                      class="filled-in"
                    />
                    <label for={`patinfo_Other`}>Other: &nbsp; &nbsp; </label>
                  </div>
                  <div class="form-line" id={`patinfo_Othercity_div`}>
                    <input
                      type="text"
                      ref={(ref) => (me.patinfo_OtherEthnicitycity = ref)}
                      id={`patinfo_OtherEthnicitycity`}
                      name="patinfo_OtherEthnicitycity"
                      class="form-control"
                      placeholder="Other"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* GTCS Patient Information end */}

          {/* GTCS Billing Information */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">Billing Information</h4>
            </div>
          </div>
          <div
            id={`${this.state.frmprefix}BillingInformation`}
            class="body card"
          >
            <div class="row clearfix" id="billing_info_container">
              <div class="col-md-12">
                <div class="form-group required">
                  <label
                    class="form-label"
                    for={`${this.state.frmprefix}BillingInformation`}
                  >
                    Billing Information
                  </label>
                  <div class="demo-checkbox">
                    <input
                      type="checkbox"
                      id={`billinfo_PrivateInsurance`}
                      name={`billinfo_PrivateInsurance`}
                      class="filled-in"
                      onClick={() => {
                        BillingInformationPrivateInsuranceClick();
                      }}
                    />
                    <label for={`billinfo_PrivateInsurance`}>
                      Private Insurance &nbsp;{" "}
                    </label>
                    <input
                      type="checkbox"
                      id={`billinfo_Medicare`}
                      name={`billinfo_Medicare`}
                      class="filled-in"
                      onClick={() => {
                        BillingInformationMedicareClick();
                      }}
                    />
                    <label for={`billinfo_Medicare`}>
                      Medicare<sup>1</sup> &nbsp;{" "}
                    </label>
                    <input
                      type="checkbox"
                      id={`billinfo_PatientSelfPay`}
                      name={`billinfo_PatientSelfPay`}
                      class="filled-in"
                      onClick={() => {
                        BillingInformationPatientSelfPayClick();
                      }}
                    />
                    <label for={`billinfo_PatientSelfPay`}>
                      Patient Self-Pay &nbsp;{" "}
                    </label>
                    <input
                      type="checkbox"
                      id={`billinfo_Medicaid`}
                      name={`billinfo_Medicaid`}
                      class="filled-in"
                      onClick={() => {
                        BillingInformationMedicaidClick();
                      }}
                    />
                    <label for={`billinfo_Medicaid`}>Medicaid &nbsp; </label>
                    <input
                      type="checkbox"
                      id={`billinfo_FreeTrial`}
                      name={`billinfo_FreeTrial`}
                      class="filled-in"
                      onClick={() => {
                        BillingInformationFreeTrialClick();
                      }}
                    />
                    <label for={`billinfo_FreeTrial`}>Free Trial &nbsp; </label>

                    <input
                      type="checkbox"
                      id={`billinfo_OrderingFacility`}
                      name={`billinfo_OrderingFacility`}
                      class="filled-in"
                      onClick={() => {
                        BillingInformationOrderingFacilityClick();
                      }}
                    />
                    <label for={`billinfo_OrderingFacility`}>
                      Ordering Facility (Client Bill) &nbsp;{" "}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="row clearfix">
              <div class="col-md-12">
                <p class="billInfoOva">
                  <sup>1</sup>By ordering this test for your Medicare physician,
                  you are certifying that the physician has met the requirements
                  for use <br />
                  i.e has an ovarian mass, has surgery planned and is over 18
                  years of age
                </p>
                <p id={`title_insurance_container`} class=" hidediv">
                  Insurance Information
                </p>
              </div>
            </div>
            <div id="primary_insurance_container" class=" hidediv">
              <div class="row clearfix">
                <div class="col-md-3">
                  <div class="form-group required">
                    <label class="form-label" for={`PrimaryInsuranceCarrier`}>
                      Primary Insurance Carrier{" "}
                    </label>
                    <div class="form-line">
                      <input
                        type="text"
                        ref={(ref) =>
                          (me.billinfo_PrimaryInsuranceCarrier = ref)
                        }
                        id={`billinfo_PrimaryInsuranceCarrier`}
                        class="form-control required"
                        placeholder="Primary Insurance Carrier"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group required">
                    <label class="form-label" for={`planId`}>
                      Plan ID#{" "}
                    </label>
                    <div class="form-line">
                      <input
                        type="text"
                        ref={(ref) => (me.billinfo_planId = ref)}
                        id={`billinfo_planId`}
                        class="form-control required"
                        placeholder="Plan ID"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group required">
                    <label class="form-label" for={`MemberID`}>
                      Member ID#{" "}
                    </label>
                    <div class="form-line">
                      <input
                        type="text"
                        ref={(ref) => (me.billinfo_MemberID = ref)}
                        id={`billinfo_MemberID`}
                        class="form-control required"
                        placeholder="Member ID"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group required">
                    <label class="form-label" for={`GroupID`}>
                      Group ID#{" "}
                    </label>
                    <div class="form-line">
                      <input
                        type="text"
                        ref={(ref) => (me.billinfo_GroupID = ref)}
                        id={`billinfo_GroupID`}
                        class="form-control required"
                        placeholder="Group ID#"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="secondary_insurance_container" class=" hidediv">
              <div class="row clearfix">
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`SecondaryInsuranceCarrier`}>
                      Secondary Insurance Carrier{" "}
                    </label>
                    <div class="form-line">
                      <input
                        type="text"
                        ref={(ref) =>
                          (me.billinfo_SecondaryInsuranceCarrier = ref)
                        }
                        id={`billinfo_SecondaryInsuranceCarrier`}
                        class="form-control "
                        placeholder="Secondary Insurance Carrier"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`SecondaryplanId`}>
                      Plan ID#{" "}
                    </label>
                    <div class="form-line">
                      <input
                        type="text"
                        ref={(ref) => (me.billinfo_SecondaryplanId = ref)}
                        id={`billinfo_SecondaryplanId`}
                        class="form-control "
                        placeholder="Plan ID"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`SecondaryMemberID`}>
                      Member ID#{" "}
                    </label>
                    <div class="form-line">
                      <input
                        type="text"
                        ref={(ref) => (me.billinfo_SecondaryMemberID = ref)}
                        id={`billinfo_SecondaryMemberID`}
                        class="form-control "
                        placeholder="Member ID"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`SecondaryGroupID`}>
                      Group ID#{" "}
                    </label>
                    <div class="form-line">
                      <input
                        type="text"
                        ref={(ref) => (me.billinfo_SecondaryGroupID = ref)}
                        id={`billinfo_SecondaryGroupID`}
                        class="form-control "
                        placeholder="Group ID#"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div id="insured_info_container" class=" hidediv">
              <div id="insured_info" class="row clearfix">
                <div class="col-md-4">
                  <div class="form-group required">
                    <label class="form-label" for={`NameofInsured`}>
                      Name of Insured{" "}
                    </label>
                    <div class="form-line">
                      <input
                        type="text"
                        ref={(ref) => (me.billinfo_NameofInsured = ref)}
                        id={`billinfo_NameofInsured`}
                        class="form-control required"
                        placeholder="Name of Insured"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group required">
                    <label class="form-label" for={`DOB`}>
                      DOB (mm/dd/yyyy){" "}
                    </label>
                    <div class="form-line">
                      <DatePicker
                        id={`billinfoC_dob`}
                        className={"form-control required"}
                        selected={this.state.billinfoC_dob}
                        onChange={(date) => this.onChangeBillinfoCDob(date)}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group required">
                    <label class="form-label" for={`Relationshiptoinsured`}>
                      Relationship to insured
                    </label>
                    <div class="form-line">
                      <select
                        class="form-control show-tick"
                        ref={(ref) => (me.billinfo_RelationshipInsured = ref)}
                        id={`billinfo_RelationshipInsured`}
                      >
                        {this.state.billinfo_RelationshipInsured ===
                          "Other with Self" && (
                            <option value="Other with Self" selected>
                              {" "}
                              Other with Self
                            </option>
                          )}
                        {this.state.billinfo_RelationshipInsured !=
                          "Other with Self" && (
                            <option value="Other with Self">
                              {" "}
                              Other with Self
                            </option>
                          )}

                        {this.state.billinfo_RelationshipInsured === "Self" && (
                          <option value="Self" selected>
                            {" "}
                            Self{" "}
                          </option>
                        )}
                        {this.state.billinfo_RelationshipInsured != "Self" && (
                          <option value="Self"> Self </option>
                        )}

                        {this.state.billinfo_RelationshipInsured ===
                          "Spouse" && (
                            <option value="Spouse" selected>
                              {" "}
                              Spouse{" "}
                            </option>
                          )}
                        {this.state.billinfo_RelationshipInsured !=
                          "Spouse" && <option value="Spouse"> Spouse </option>}

                        {this.state.billinfo_RelationshipInsured ===
                          "Child" && (
                            <option value="Child" selected>
                              {" "}
                              Child{" "}
                            </option>
                          )}
                        {this.state.billinfo_RelationshipInsured != "Child" && (
                          <option value="Child"> Child </option>
                        )}
                      </select>
                      {/* <input type="text" ref={(ref) => me.billinfo_RelationshipInsured = ref} id={`billinfo_RelationshipInsured`} class="form-control required" placeholder="Relationship to insured" /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CmtForm order={me.state.orderData} />

            <BillingNotes order={me.state.orderData} />
          </div>
          {/* GTCS Billing Information end */}
          {/* GTCS Diagnosis Codes | ICD-10 Codes */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">Diagnosis Codes | ICD-10 Codes</h4>
            </div>
          </div>
          <div id={`${this.state.frmprefix}hboc_icd_codes`} class="body card">
            <div class="row clearfix">
              <div id={`ova1_icd_codes`} class="col-md-12">
                <div class="form-group required">
                  <label class="form-label" for={`DiagnosisCodes`}>
                    <b>
                      OVA1 Diagnosis Codes | ICD-10 Codes (check all that apply)
                    </b>
                  </label>
                  <div class="demo-checkbox">
                    <div class="row clearfix">
                      <div class="col-md-4">
                        <input
                          type="checkbox"
                          data-val="N83.201"
                          id={`diagnosis_UnOcyst_rs`}
                          name={`diagnosis_UnOcyst_rs`}
                          class="filled-in"
                        />
                        <label for={`diagnosis_UnOcyst_rs`}>
                          N83.201 Unspecified ovarian cyst, right side+ &nbsp;{" "}
                        </label>
                      </div>
                      <div class="col-md-4">
                        <input
                          type="checkbox"
                          data-val="R19.00"
                          id={`diagnosis_IntraAbdPelSwell`}
                          name={`diagnosis_IntraAbdPelSwell`}
                          class="filled-in"
                        />
                        <label for={`diagnosis_IntraAbdPelSwell`}>
                          R19.00 Intra-abdominal and pelvic swelling, mass and
                          lump, unspecified site+ &nbsp;{" "}
                        </label>
                      </div>
                      <div class="col-md-4">
                        <input
                          type="checkbox"
                          data-val="R19.05"
                          id={`diagnosis_Periumbilicalswell`}
                          name={`diagnosis_Periumbilicalswell`}
                          class="filled-in"
                        />
                        <label for={`diagnosis_Periumbilicalswell`}>
                          R19.05 Periumbilical swelling, mass and lump+ &nbsp;{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="demo-checkbox">
                    <div class="row clearfix">
                      <div class="col-md-4">
                        <input
                          type="checkbox"
                          data-val="N83.202"
                          id={`diagnosis_UnOcyst_ls`}
                          name={`diagnosis_UnOcyst_ls`}
                          class="filled-in"
                        />
                        <label for={`diagnosis_UnOcyst_ls`}>
                          N83.202 Unspecified ovarian cyst, left side+ &nbsp;{" "}
                        </label>
                      </div>
                      <div class="col-md-4">
                        <input
                          type="checkbox"
                          data-val="R19.03"
                          id={`diagnosis_lowerquadrantSwell`}
                          name={`diagnosis_lowerquadrantSwell`}
                          class="filled-in"
                        />
                        <label for={`diagnosis_lowerquadrantSwell`}>
                          R19.03 Right lower quadrant abdominal swelling, mass
                          and lump &nbsp;{" "}
                        </label>
                      </div>
                      <div class="col-md-4">
                        <input
                          type="checkbox"
                          data-val="R19.09"
                          id={`diagnosis_intraabdominalpelvicswell`}
                          name={`diagnosis_intraabdominalpelvicswell`}
                          class="filled-in"
                        />
                        <label for={`diagnosis_intraabdominalpelvicswell`}>
                          R19.09 Other intra-abdominal pelvic swelling, mass and
                          lump+ &nbsp;{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="demo-checkbox">
                    <div class="row clearfix">
                      <div class="col-md-4">
                        <input
                          type="checkbox"
                          data-val="N83.209"
                          id={`diagnosis_UnOcyst`}
                          name={`diagnosis_UnOcyst`}
                          class="filled-in"
                        />
                        <label for={`diagnosis_UnOcyst`}>
                          N83.209 Unspecified ovarian cyst, unspecified side+
                          &nbsp;{" "}
                        </label>
                      </div>
                      <div class="col-md-4">
                        <input
                          type="checkbox"
                          data-val="R19.04"
                          id={`diagnosis_LlQabdominalswelling`}
                          name={`diagnosis_LlQabdominalswelling`}
                          class="filled-in"
                        />
                        <label for={`diagnosis_LlQabdominalswelling`}>
                          R19.04 Left lower quadrant abdominal swelling, mass
                          and lump &nbsp;{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id={`hboc_icd_codes`} class="col-md-12">
              <div class="form-group required">
                <div class="row clearfix">
                  <div class="col-md-12">
                    <label class="form-label" for="DiagnosisCodes_GHBOCG">
                      <b>
                        GHBOCG Diagnosis Codes | ICD-10 Codes (check all that
                        apply)
                      </b>
                    </label>
                  </div>
                </div>
                <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_1">
                  <div class="row clearfix">
                    <div class="col-md-6">
                      <input
                        type="checkbox"
                        data-val="Z15.01"
                        id={`hBOCdiagnosis_z1501`}
                        name={`hBOCdiagnosis_z1501`}
                        class="filled-in"
                      />
                      <label for={`hBOCdiagnosis_z1501`}>
                        Z15.01 Genetic susceptibility to malignant neoplasm of
                        breast &nbsp;{" "}
                      </label>
                    </div>
                    <div class="col-md-6">
                      <input
                        type="checkbox"
                        data-val="C48.1"
                        id={`hBOCdiagnosis_c481`}
                        name={`hBOCdiagnosis_c481`}
                        class="filled-in"
                      />
                      <label for={`hBOCdiagnosis_c481`}>
                        C48.1 Malignant neoplasm of specified parts of
                        peritoneum &nbsp;{" "}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_2">
                  <div class="row clearfix">
                    <div class="col-md-6">
                      <input
                        type="checkbox"
                        data-val="Z15.02"
                        id={`hBOCdiagnosis_z1502`}
                        name={`hBOCdiagnosis_z1502`}
                        class="filled-in"
                      />
                      <label for={`hBOCdiagnosis_z1502`}>
                        Z15.02 Genetic susceptibility to malignant neoplasm of
                        ovary &nbsp;{" "}
                      </label>
                    </div>
                    <div class="col-md-6">
                      <input
                        type="checkbox"
                        data-val="C50.0"
                        id={`hBOCdiagnosis_c500`}
                        name={`hBOCdiagnosis_c500`}
                        class="filled-in"
                      />
                      <label for={`hBOCdiagnosis_c500`}>
                        C50.0 Malignant neoplasm of breast [add anatomic site
                        detail] &nbsp;{" "}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_3">
                  <div class="row clearfix">
                    <div class="col-md-6">
                      <input
                        type="checkbox"
                        data-val="Z15.03"
                        id={`hBOCdiagnosis_z1503`}
                        name={`hBOCdiagnosis_z1503`}
                        class="filled-in"
                      />
                      <label for={`hBOCdiagnosis_z1503`}>
                        Z15.03 Genetic susceptibility to malignant neoplasm of
                        prostate &nbsp;{" "}
                      </label>
                    </div>
                    <div class="col-md-6">
                      <input
                        type="checkbox"
                        data-val="C56.1"
                        id={`hBOCdiagnosis_c561`}
                        name={`hBOCdiagnosis_c561`}
                        class="filled-in"
                      />
                      <label for={`hBOCdiagnosis_c561`}>
                        C56.1 Malignant neoplasm of right ovary &nbsp;{" "}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_4">
                  <div class="row clearfix">
                    <div class="col-md-6">
                      <input
                        type="checkbox"
                        data-val="Z15.04"
                        id={`hBOCdiagnosis_z1504`}
                        name={`hBOCdiagnosis_z1504`}
                        class="filled-in"
                      />
                      <label for={`hBOCdiagnosis_z1504`}>
                        Z15.04 Genetic susceptibility to malignant neoplasm of
                        endometrium &nbsp;{" "}
                      </label>
                    </div>
                    <div class="col-md-6">
                      <input
                        type="checkbox"
                        data-val="C56.2"
                        id={`hBOCdiagnosis_c562`}
                        name={`hBOCdiagnosis_c562`}
                        class="filled-in"
                      />
                      <label for={`hBOCdiagnosis_c562`}>
                        C56.2 Malignant neoplasm of left ovary &nbsp;{" "}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_5">
                  <div class="row clearfix">
                    <div class="col-md-6">
                      <input
                        type="checkbox"
                        data-val="Z15.09"
                        id={`hBOCdiagnosis_z1509`}
                        name={`hBOCdiagnosis_z1509`}
                        class="filled-in"
                      />
                      <label for={`hBOCdiagnosis_z1509`}>
                        Z15.09 Genetic susceptibility to other malignant
                        neoplasm &nbsp;{" "}
                      </label>
                    </div>
                    <div class="col-md-6">
                      <input
                        type="checkbox"
                        data-val="C56.9"
                        id={`hBOCdiagnosis_c569`}
                        name={`hBOCdiagnosis_c569`}
                        class="filled-in"
                      />
                      <label for={`hBOCdiagnosis_c569`}>
                        C56.9 Malignant neoplasm of unspecified ovary &nbsp;{" "}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_6">
                  <div class="row clearfix">
                    <div class="col-md-6">
                      <input
                        type="checkbox"
                        data-val="Z15.81"
                        id={`hBOCdiagnosis_z1581`}
                        name={`hBOCdiagnosis_z1581`}
                        class="filled-in"
                      />
                      <label for={`hBOCdiagnosis_z1581`}>
                        Z15.81 Genetic susceptibility to multiple endocrine
                        neoplasia [MEN] &nbsp;{" "}
                      </label>
                    </div>
                    <div class="col-md-6">
                      <input
                        type="checkbox"
                        data-val="C57.00"
                        id={`hBOCdiagnosis_c5700`}
                        name={`hBOCdiagnosis_c5700`}
                        class="filled-in"
                      />
                      <label for={`hBOCdiagnosis_c5700`}>
                        C57.00 Malignant neoplasm of unspecified fallopain tube
                        &nbsp;{" "}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_7">
                  <div class="row clearfix">
                    <div class="col-md-6">
                      <input
                        type="checkbox"
                        data-val="C25.0"
                        id={`hBOCdiagnosis_C250`}
                        name={`hBOCdiagnosis_C250`}
                        class="filled-in"
                      />
                      <label for={`hBOCdiagnosis_C250`}>
                        C25.0 Malignant neoplasm of head of pancreas &nbsp;{" "}
                      </label>
                    </div>
                    <div class="col-md-6">
                      <input
                        type="checkbox"
                        data-val="C57.01"
                        id={`hBOCdiagnosis_c5701`}
                        name={`hBOCdiagnosis_c5701`}
                        class="filled-in"
                      />
                      <label for={`hBOCdiagnosis_c5701`}>
                        C57.01 Malignant neoplasm of right fallopain tube &nbsp;{" "}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_8">
                  <div class="row clearfix">
                    <div class="col-md-6">
                      <input
                        type="checkbox"
                        data-val="C25.1"
                        id={`hBOCdiagnosis_C251`}
                        name={`hBOCdiagnosis_C251`}
                        class="filled-in"
                      />
                      <label for={`hBOCdiagnosis_C251`}>
                        C25.1 Malignant neoplasm of body of pancreas &nbsp;{" "}
                      </label>
                    </div>
                    <div class="col-md-6">
                      <input
                        type="checkbox"
                        data-val="C57.01"
                        id={`hBOCdiagnosis_c5702`}
                        name={`hBOCdiagnosis_c5702`}
                        class="filled-in"
                      />
                      <label for={`hBOCdiagnosis_c5702`}>
                        C57.01 Malignant neoplasm of left fallopain tube &nbsp;{" "}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_9">
                  <div class="row clearfix">
                    <div class="col-md-6">
                      <input
                        type="checkbox"
                        data-val="C25.2"
                        id={`hBOCdiagnosis_C252`}
                        name={`hBOCdiagnosis_C252`}
                        class="filled-in"
                      />
                      <label for={`hBOCdiagnosis_C252`}>
                        C25.2 Malignant neoplasm of tail of pancreas &nbsp;{" "}
                      </label>
                    </div>
                    <div class="col-md-6">
                      <input
                        type="checkbox"
                        data-val="C61"
                        id={`hBOCdiagnosis_c61`}
                        name={`hBOCdiagnosis_c61`}
                        class="filled-in"
                      />
                      <label for={`hBOCdiagnosis_c61`}>
                        C61 Malignant neoplasm of prostate &nbsp;{" "}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_10">
                  <div class="row clearfix">
                    <div class="col-md-6">
                      <input
                        type="checkbox"
                        data-val="C25.3"
                        id={`hBOCdiagnosis_C253`}
                        name={`hBOCdiagnosis_C253`}
                        class="filled-in"
                      />
                      <label for={`hBOCdiagnosis_C253`}>
                        C25.3 Malignant neoplasm of pancreatic duct &nbsp;{" "}
                      </label>
                    </div>
                    <div class="col-md-6">
                      <input
                        type="checkbox"
                        data-val="D05.11"
                        id={`hBOCdiagnosis_D0511`}
                        name={`hBOCdiagnosis_D0511`}
                        class="filled-in"
                      />
                      <label for={`hBOCdiagnosis_D0511`}>
                        D05.11 Intraductal carcinoma in situ of right breast
                        &nbsp;{" "}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_11">
                  <div class="row clearfix">
                    <div class="col-md-6">
                      <input
                        type="checkbox"
                        data-val="C25.4"
                        id={`hBOCdiagnosis_C254`}
                        name={`hBOCdiagnosis_C254`}
                        class="filled-in"
                      />
                      <label for={`hBOCdiagnosis_C254`}>
                        C25.4 Malignant neoplasm of endocrine pancreas &nbsp;{" "}
                      </label>
                    </div>
                    <div class="col-md-6">
                      <input
                        type="checkbox"
                        data-val="D05.12"
                        id={`hBOCdiagnosis_D0512`}
                        name={`hBOCdiagnosis_D0512`}
                        class="filled-in"
                      />
                      <label for={`hBOCdiagnosis_D0512`}>
                        D05.12 Intraductal carcinoma in situ of left breast
                        &nbsp;{" "}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_12">
                  <div class="row clearfix">
                    <div class="col-md-6">
                      <input
                        type="checkbox"
                        data-val="C25.7"
                        id={`hBOCdiagnosis_C257`}
                        name={`hBOCdiagnosis_C257`}
                        class="filled-in"
                      />
                      <label for={`hBOCdiagnosis_C257`}>
                        C25.7 Malignant neoplasm of other parts of pancreas
                        &nbsp;{" "}
                      </label>
                    </div>
                    <div class="col-md-6">
                      <input
                        type="checkbox"
                        data-val="Z85.07"
                        id={`hBOCdiagnosis_Z8507`}
                        name={`hBOCdiagnosis_Z8507`}
                        class="filled-in"
                      />
                      <label for={`hBOCdiagnosis_Z8507`}>
                        Z85.07 Personal history of malignant neoplasm of
                        pancreas &nbsp;{" "}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_13">
                  <div class="row clearfix">
                    <div class="col-md-6">
                      <input
                        type="checkbox"
                        data-val="C25.8"
                        id={`hBOCdiagnosis_C258`}
                        name={`hBOCdiagnosis_C258`}
                        class="filled-in"
                      />
                      <label for={`hBOCdiagnosis_C258`}>
                        C25.8 Malignant neoplasm of overlapping sites of
                        pancreas &nbsp;{" "}
                      </label>
                    </div>
                    <div class="col-md-6">
                      <input
                        type="checkbox"
                        data-val="Z85.3"
                        id={`hBOCdiagnosis_Z853`}
                        name={`hBOCdiagnosis_Z853`}
                        class="filled-in"
                      />
                      <label for={`hBOCdiagnosis_Z853`}>
                        Z85.3 Personal history of malignant neoplasm of breast
                        &nbsp;{" "}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_14">
                  <div class="row clearfix">
                    <div class="col-md-6">
                      <input
                        type="checkbox"
                        data-val="C25.9"
                        id={`hBOCdiagnosis_C259`}
                        name={`hBOCdiagnosis_C259`}
                        class="filled-in"
                      />
                      <label for={`hBOCdiagnosis_C259`}>
                        C25.9 Malignant neoplasm of pancreas, unspecified &nbsp;{" "}
                      </label>
                    </div>
                    <div class="col-md-6">
                      <input
                        type="checkbox"
                        data-val="Z85.43"
                        id={`hBOCdiagnosis_Z8543`}
                        name={`hBOCdiagnosis_Z8543`}
                        class="filled-in"
                      />
                      <label for={`hBOCdiagnosis_Z8543`}>
                        Z85.43 Personal history of malignant neoplasm of ovary
                        &nbsp;{" "}
                      </label>
                    </div>
                  </div>
                </div>
                <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_15">
                  <div class="row clearfix">
                    <div class="col-md-6"></div>
                    <div class="col-md-6">
                      <input
                        type="checkbox"
                        data-val="Z85.46"
                        id={`hBOCdiagnosis_Z8546`}
                        name={`hBOCdiagnosis_Z8546`}
                        class="filled-in"
                      />
                      <label for={`hBOCdiagnosis_Z8546`}>
                        Z85.46 Personal history of malignant neoplasm of
                        prostate &nbsp;{" "}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row clearfix hidediv" id={`covid_icd_codes`}>
                <div class="col-md-12">
                  <div class="form-group required">
                    <label class="form-label" for={`DiagnosisCodes`}>
                      <b>
                        {" "}
                        COVD Diagnosis Codes | ICD-10 Codes (check all that
                        apply)
                      </b>
                    </label>
                    <div class="demo-checkbox">
                      <div class="row clearfix">
                        <div class="col-md-6">
                          <input
                            type="checkbox"
                            data-val="Z11.59"
                            id={`diagnosis_z1159`}
                            name={`diagnosis_z1159`}
                            class="filled-in"
                          />
                          <label for={`diagnosis_z1159`}>
                            Z11.59, Encounter for screening for other viral
                            diseases &nbsp;{" "}
                          </label>
                        </div>
                        <div class="col-md-6">
                          <input
                            type="checkbox"
                            data-val="Z03.818"
                            id={`diagnosis_z03818`}
                            name={`diagnosis_z03818`}
                            class="filled-in"
                          />
                          <label for={`diagnosis_z03818`}>
                            Z03.818, Encounter for observation for suspected
                            exposure to other biological agents ruled out &nbsp;{" "}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row clearfix">
                <div class="col-md-12">
                  <div class="form-group ">
                    <label class="form-label" for={`address`}>
                      Other ICD-10 Codes
                    </label>
                    <div class="form-line">
                      <input
                        type="text"
                        ref={(ref) => (me.OtherICD10Codes = ref)}
                        id={`OtherICD10Codes`}
                        class="form-control "
                        placeholder="Other ICD-10 Codes"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row clearfix">
                <div class="col-md-12">
                  <p>
                    (This is provided for informational purposes only and is not
                    a guarantee of coverage. It is the provider's responsibility
                    to determine the appropriate codes)
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* GTCS Diagnosis Codes | ICD-10 Codes end */}

          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">HBOC kit Shipping Information</h4>
            </div>
          </div>
          <div
            id={`${this.state.frmprefix}kitShippingInfoFormContainerHBOC`}
            class="body card"
          >
            <div class="row clearfix">
              <div class="col-md-12">
                <div class="form-group required">
                  <label class="form-label" for="address">
                    Kit Shipping Info
                  </label>
                  <div id="draw_type" class="demo-checkbox">
                    <input
                      data-val="My physician needs mobile phlebotomy"
                      type="checkbox"
                      id={`kitShip_PatMobPhlebotomy2`}
                      name={`kitShip_PatMobPhlebotomy2`}
                      class="filled-in"
                    />
                    <label for={`kitShip_PatMobPhlebotomy2`}>
                      My physician needs mobile phlebotomy &nbsp;{" "}
                    </label>
                    <input
                      data-val="Ship physician saliva collection kit"
                      type="checkbox"
                      id="kitShip_PatblooddrawQuest2"
                      name="kitShip_PatblooddrawQuest2"
                      class="filled-in"
                    />
                    <label for="kitShip_PatblooddrawQuest2">
                      Ship physician saliva collection kit &nbsp;{" "}
                    </label>
                    <input
                      data-val="Other"
                      type="checkbox"
                      id={`kitShip_PatOthers2`}
                      name="kitShip_PatOthers2"
                      class="filled-in"
                    />
                    <label for={`kitShip_PatOthers2`}>Other &nbsp; </label>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="form-label" for="address">
                    &nbsp;
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      ref={(ref) => (me.kitShip_PatOthersInfo2 = ref)}
                      id={`kitShip_PatOthersInfo2`}
                      class="form-control "
                      placeholder=" Other info"
                    // disabled={!me.state.kitShip_PatOthers2}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* OVA1 Kit Shipping Information */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">OVA1 Kit Shipping Information</h4>
            </div>
          </div>
          <div
            id={`${this.state.frmprefix}kitShippingInfoFormContainer`}
            class="body card"
          >
            <div class="row clearfix">
              <div class="col-md-12">
                <div class="form-group required">
                  <label class="form-label" for="address">
                    Kit Shipping Info
                  </label>
                  <div id="draw_type" class="demo-checkbox">
                    <input
                      data-val="My physician needs mobile phlebotomy"
                      type="checkbox"
                      id={`kitShip_PatMobPhlebotomy`}
                      name={`kitShip_PatMobPhlebotomy`}
                      class="filled-in"
                      onClick={(e) =>
                        console.log(
                          "sm 1 click e.target.checked: ",
                          e.target.checked
                        )
                      }
                      onChange={(e) =>
                        console.log(
                          "sm 1 change e.target.checked: ",
                          e.target.checked
                        )
                      }
                    />
                    <label for={`kitShip_PatMobPhlebotomy`}>
                      My physician needs mobile phlebotomy &nbsp;{" "}
                    </label>
                    <input
                      data-val="Patient will get blood draw at Quest PSC"
                      type="checkbox"
                      id={`kitShip_PatblooddrawQuest`}
                      name={`kitShip_PatblooddrawQuest`}
                      class="filled-in"
                      onClick={(e) =>
                        console.log(
                          "sm 2 click e.target.checked: ",
                          e.target.checked
                        )
                      }
                      onChange={(e) =>
                        console.log(
                          "sm 2 change e.target.checked: ",
                          e.target.checked
                        )
                      }
                    />
                    <label for={`kitShip_PatblooddrawQuest`}>
                      Patient will get blood draw at Quest PSC &nbsp;{" "}
                    </label>
                    <input
                      data-val="Other"
                      type="checkbox"
                      id={`kitShip_PatOthers`}
                      name="kitShip_PatOthers"
                      class="filled-in"
                    />
                    <label for={`kitShip_PatOthers`}>Other &nbsp; </label>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="form-label" for="address">
                    &nbsp;
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      ref={(ref) => (me.kitShip_PatOthersInfo = ref)}
                      id={`kitShip_PatOthersInfo`}
                      class="form-control "
                      placeholder=" Other info"
                    // disabled={!me.state.kitShip_PatOthers}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* OVA1 Kit Shipping Information end*/}
          {/* OVA1 Kit Notes & Certify */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">Notes & Certify</h4>
            </div>
          </div>
          <div id={`${this.state.frmprefix}NotesCertify`} class="body card">
            <div class="row clearfix">
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="form-label" for="address">
                    Notes
                  </label>
                  <div class="form-line">
                    <textarea
                      ref={(ref) => (me.kitShip_notes = ref)}
                      id={`kitShip_notes`}
                      class="form-control no-resize auto-growth"
                      placeholder="Notes"
                      rows="3"
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div class="row clearfix">
              <div class="col-md-12">
                <p>
                  <strong>
                    {" "}
                    Please note this page is for healthcare professionals. If
                    you are a patient and would like access to one of our tests,
                    please contact your healthcare provider.{" "}
                  </strong>
                </p>
              </div>
            </div>
            <div class="row clearfix">
              <div class="col-md-12">
                <div class="form-group required">
                  <label class="form-label" for="address">
                    I certify that I am:
                  </label>
                  <div class="demo-radio-button" id={`certifytype`}>
                    <input
                      type="radio"
                      data-val="A healthcare provider"
                      id={`kitShip_healthcareProvider`}
                      name={`kitShip_partnerhealthcareProvider`}
                      class="form-control"
                    />
                    <label for={`kitShip_healthcareProvider`}>
                      A healthcare provider &nbsp;{" "}
                    </label>
                    <input
                      type="radio"
                      data-val="An Aspira employee ordering on behalf of a healthcare provider"
                      id={`kitShip_employeehealthcareProvider`}
                      name={`kitShip_partnerhealthcareProvider`}
                      class="form-control"
                    />
                    <label for={`kitShip_employeehealthcareProvider`}>
                      An Aspira employee ordering on behalf of a healthcare
                      provider &nbsp;{" "}
                    </label>
                    <input
                      type="radio"
                      data-val="An Aspira partner ordering on behalf of a healthcare provider"
                      id={`kitShip_partnerhealthcareProvider`}
                      name={`kitShip_partnerhealthcareProvider`}
                      class="form-control"
                    />
                    <label for={`kitShip_partnerhealthcareProvider`}>
                      An Aspira partner ordering on behalf of a healthcare
                      provider &nbsp;{" "}
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div id="ignoreMeInPDF" class="row clearfix">
              <div class="col-md-12">
                <div class="form-group" style={{ display: "flex", }}>
                  <button
                    id={`btnOrderSubmit`}
                    onClick={me.handleSave.bind(this)}
                    placeholder="Submit"
                    name="Submit"
                    className="btn btn-primary text-white"
                  >
                    {me.state.buttonText}
                  </button>
                  <Retrive_Reprot
                    state={this.state}
                    token={ovaHbocAccessToken}
                    reportId={reportId}
                    orderId={this.orderId}
                    reportDate={reportDate}
                    me={this}
                    from={'ova1hboc'}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* OVA1 Kit Notes & Certify  end*/}
        </div>
      </div>
    );
  }
}
