import React, { useState, useEffect } from "react";
//import dotenv from "dotenv";
import axios from "axios";
import { Radio, message } from 'antd';

import FaxModal from "../mycomponents/modal";
import ProgressBar from "../mycomponents/progressbar";

import helper from "../utils/helper";

// import jsPDF from "jspdf";
// import ReactDOMServer from "react-dom/server";
import { protectedResources } from "./../authConfig";
import { MsalContext } from "@azure/msal-react";

import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net-bs/css/dataTables.bootstrap.css";
import moment from "moment";

import Spinner from "../Spinner/Spinner";

const $ = require("jquery");
$.DataTable = require("datatables.net");
// require( 'datatables.net-bs' )( window, $ );

//dotenv.config();

const API_BASE_URL = helper.getAPIBaseURI();
let me = null;

export default class SuccessFulFaxesContent extends React.PureComponent {
  static contextType = MsalContext;

  constructor(props) {
    super(props);

    this.state = {
      fax: "",
      selectedOrder: null,
      orders: [],
      loading: true,
      showLoader: false,

      showFaxModal: false,
      showFaxOutForm: false,
      defaultFaxNumberLoaded: false,
      useDefaultFaxNumberToFaxOut: true,
      customFaxNumber: "",
      faxOutFormSubmitted: false,
      faxOutFormProcessingComplete: false,
      faxQeuedSuccessfully: false,
      deleteOrderId: null,
      showProgressBar: false,

      startDate: "",
      endDate: "",

      showReportContent: false,
      reportSignedUrl: "",
      selectedOrderId: "",

      toastMessage: "",
      list: [],
      toastList: [],

      //For PDF
      numPages: 0,
      pageNumber: 0,

      faxSubmitButtonText: "Send Fax",
    };

    this.orderDataTable = React.createRef();

    this.handleButtonPress = this.handleButtonPress.bind(this);
    this.handleButtonRelease = this.handleButtonRelease.bind(this);

    me = this;

    this.$el = null;
    this.inpFax = React.createRef();
  }

  showToast(type) {
    const id = Math.floor(Math.random() * 101 + 1);

    switch (type) {
      case "success":
        this.toastProperties = {
          id,
          title: "Success",
          description: "This is a success toast component",
          backgroundColor: "#5cb85c",
          icon: "/assets/assets/check.svg",
        };
        break;
      case "danger":
        this.toastProperties = {
          id,
          title: "Danger",
          description: "This is a error toast component",
          backgroundColor: "#d9534f",
          icon: "/assets/assets/error.svg",
        };
        break;
      case "info":
        this.toastProperties = {
          id,
          title: "Info",
          description: "This is an info toast component",
          backgroundColor: "#5bc0de",
          icon: "/assets/assets/info.svg",
        };
        break;
      case "warning":
        this.toastProperties = {
          id,
          title: "Warning",
          description: "This is a warning toast component",
          backgroundColor: "#f0ad4e",
          icon: "/assets/assets/warning.svg",
        };
        break;

      default:
        this.setState({ toastList: [] });
    }

    // this.setState([...this.state.list, this.toastProperties]);
    this.setState({ list: [this.toastProperties] });
  }
  handleButtonPress() {
    this.buttonPressTimer = setTimeout(
      () => this.setState({ showProgressBar: true }),
      1000
    );
  }

  handleButtonRelease() {
    clearTimeout(this.buttonPressTimer);
  }

  callDataTable(datatotables) {
    const me = this;

    const user = JSON.parse(localStorage.getItem("user"));

    // first time, el will be null
    // see: https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs

    console.log("this.orderDataTable: ", this.orderDataTable);

    if (!this.orderDataTable) return;

    console.log("datatotables: ", datatotables);

    if (this.$el) {
      this.$el.DataTable().clear().destroy();
    }

    this.$el = $(this.orderDataTable);
    //  console.log(this.$el.DataTable);
    this.$el.DataTable({
      pageLength: 25,
      order: [],
      data: datatotables,
      columns: [
        {
          title: "QBench Order ID",
          data: "orderId",
          name: "orderId",
          // width: "10px",
          sortable: true,
        },
        {
          title: "Patient First Name",
          data: "Patient.firstName",
          name: "firstName",
          width: "15px",
        },
        {
          title: "Patient Last Name",
          data: "Patient.lastName",
          name: "lastName",
          width: "15px",
        },
        {
          title: "Test Ordered",
          data: null,
          name: "orderedTest",
          width: "150px",
          render: (data, type) => {
            let testName = "";

            // if(data.tests.length===1) {
            //     testName=data.tests[0].testName;
            // }
            // else if(data.tests.)

            data.tests.map((item, i) => {
              if (typeof item.testName != "undefined") {
                //   if (testName == "") {
                //     testName += item.testName;
                //   } else {
                //     testName += "+" + item.testName;
                //   }

                testName = item.testName == "GenetixHBOC1" || item.testName == 'GenetiXCarrierScreening' ? item.genetixCSPanel[0]
                  : item.testName
              }
            });

            //testName = testName.substring(0, testName.length - 1);
            if (type === "display") {
              return `<td>
                                <span class="">${testName}</td>
                            </td> `;
            }
            return data;
          },
        },
        {
          title: "Client Id",
          data: "legacyClientId",
          name: "legacyClientId",
          width: "50px",
          sortable: true,
        },
        {
          title: "Status",
          data: null,
          name: "status",
          width: "200px",
          render: function (data, type) {
            if (type === "display") {
              if (
                data.status === "Approved" ||
                data.status == "Order Authorized"
              ) {
                return `<td>
                                <span class="status_font label label-success">${data.status}</td>
                            </td> `;
              } else if (
                data.status === "Pending" ||
                data.status == "WAITING BILLING"
              ) {
                return `<td>
                                <span class="status_font label label-default">${data.status}</span>
                            </td> `;
              } else if (data.status === "Denied") {
                return `<td>
                                <span class="status_font label label-danger">${data.status}</td>
                            </td> `;
              } else {
                return `<td>
                                <span class="status_font label">${data.status}</td>
                            </td> `;
              }
            }
            return data;
          },
        },
        {
          title: "Report Status",
          data: "reportStatus",
          name: "reportStatus",
        },

        {
          title: "Report Conclusion",
          data: null,
          name: "reportConclusion",
          // width: "150px",
          render: function (data, type) {

            if (type == 'display') {
              if (typeof data.reportConclusion == 'undefined' || !data.reportConclusion) {
                return "";
              }
              else {
                let imgPath = "";
                if (data.reportConclusion == 'Positive' || data.reportConclusion == 'positive') {
                  imgPath = `/icons/Positive.png`;
                }
                else if (data.reportConclusion == 'VUS') {
                  imgPath = `/icons/VUS.png`;
                }
                else if (data.reportConclusion == "Cancelled" ||
                  data.reportConclusion == "cancelled") {
                  imgPath = `/icons/cancelled.png`;
                }
                else {
                  imgPath = `/icons/Negative.png`;
                }
                return `<td>
                                
                                <img style="width: 40; height: 40;" class="positive_negative_image" src="${imgPath}" />
                                </td>
                            </td>`;

              }
            }
            return data;
          },
        },
        {
          title: "",
          name: "",
          data: null,
          sortable: false,
          render: function (data, type) {
            if (type === "display") {
              console.log("uploadDownloadClass->data: ", data);

              let testName_page = "";

              data.tests.map((item, i) => {
                if (typeof item.testName != "undefined") {
                  if (testName_page == "") {
                    testName_page += item.testName;
                  } else {
                    if (item.testName != "COVD")
                      testName_page += "_" + item.testName;
                  }
                }
              });

              return `<td>
              <a style="cursor: pointer;" class="viewFaxNumber" data-orderid="${data.orderId}" data-id="${data._id}">Fax Out</a>
              | <a style="cursor: pointer;" class="pdfClass" data-orderid="${data.orderId}" data-id="${data._id}" data-val="${testName_page}">Download TRF</a>
              | <a style="cursor: pointer;" class="ViewReportClass" data-orderid="${data.orderId}" data-reportid="${data.baylorReportId} data-galenreportid="${data.galenReportId}">View Report</a>
                                </td> `;

              //                   return `<td>
              // <a style="cursor: pointer;" class="viewFaxNumber" data-orderid="${data.orderId}" data-id="${data._id}">Fax Out</a>
              // | <a style="cursor: pointer;" class="pdfClass" data-orderid="${data.orderId}" data-id="${data._id}" data-val="${testName_page}">Download TRF</a>
              // | <a style="cursor: pointer;" class="ViewReportClass" data-orderid="${data.orderId}" data-reportid="${data.baylorReportId}">View Report</a>
              //                   </td> `;
            }
            return data;
          },
        },
      ],
    });

    this.$el.on("click", "tr a.viewFaxNumber", function () {
      let item_id = $(this).data("id");
      let orderId = $(this).data("orderid");
      let item_prefix = $(this).data("val");
      let editHref = "";

      me.setState({
        useDefaultFaxNumberToFaxOut: true,
        selectedOrderId: orderId,
        showFaxOutForm: true,
        customFaxNumber: "",
        faxOutFormSubmitted: false,
        faxOutFormProcessingComplete: false,
        faxQeuedSuccessfully: false,
      }, () => {

        me.loadDefaultFaxNumber();
      });
    });

    this.$el.on("click", "tr a.pdfClass", async function () {
      let item_id = $(this).data("id");
      let custom_formatted_id = $(this).data("orderid");
      let item_prefix = $(this).data("val");
      let editHref = "";

      // const accessToken = await helper.getAccessToken(me.context);
      // helper.printPDF(item_id, custom_formatted_id, accessToken);

      const accessToken = await helper.getAccessToken(me.context);
      let orderData = await helper.getOrderFromServerById(item_id, accessToken);
      let exactHtmlUrl = "";
      if (process.env.REACT_APP_ENV === 'DEV') {
        exactHtmlUrl = await helper.getTRFHtmlUrl(orderData);
      }
      else {
        exactHtmlUrl = await helper.getTRFHtmlUrl(orderData);
      }

      if (exactHtmlUrl) {
        window.open(exactHtmlUrl);
        // let htmlRawData = await helper.getTRFHtmlFromUrl(exactHtmlUrl, accessToken);

        // let element = htmlRawData;
        // const doc = new jsPDF("p", "pt", "letter");
        // doc.html(ReactDOMServer.renderToString(element), {
        //   callback: function (doc) {
        //     doc.save(`trf-${custom_formatted_id}.pdf`);
        //   }
        // });
      }
    });
    this.$el.on("click", "tr a.ViewReportClass", function () {
      let custom_formatted_id = $(this).data("orderid");
      let report_id = $(this).data("reportid");
      let galen_report_id = $(this).data("galenreportid");

      document.body.classList.add('busy-cursor');

      me.setState(
        { showLoader: false, loading: false, showReportContent: true, },
        async () => {

          $('html, body').animate({
            scrollTop: $("div#reportContent").offset().top
          }, 2000);

          const accessToken = await helper.getAccessToken(me.context);
          

          if (report_id) {
            axios({
              url: `${API_BASE_URL}/baylor/reports/${custom_formatted_id}/signedUrl`,
              method: "GET",
              headers: {
                "Content-type": "Application/json",
                Authorization: `Bearer ${accessToken}`,
              },
              // data: _payload,
            }).then(({ data }) => {
              console.log("data: ", data);

              document.body.classList.remove('busy-cursor');

              me.setState(
                {

                  reportSignedUrl: data,
                  selectedOrderId: custom_formatted_id,
                  loading: false,
                  showLoader: false,
                  toastMessage: `Successfully Viewed the Report, SEE BOTTOM`,
                },
                () => {
                  //me.showToast("success");
                  message.success(me.state.toastMessage);
                }
              );
            }).catch((error) => {
              document.body.classList.remove('busy-cursor');

              me.setState(
                {
                  loading: false,
                  showLoader: false,
                  toastMessage: `Viewing Report error: ${error}`,
                },
                () => {
                  //me.showToast("success");
                  message.success(me.state.toastMessage);
                }
              );

            })
          }
          else if (galen_report_id) {
            axios({
              url: `${API_BASE_URL}/galen/reports/${custom_formatted_id}/signedUrl`,
              method: "GET",
              headers: {
                "Content-type": "Application/json",
                Authorization: `Bearer ${accessToken}`,
              },
              // data: _payload,
            }).then(({ data }) => {
              console.log("data: ", data);

              document.body.classList.remove('busy-cursor');

              me.setState(
                {

                  reportSignedUrl: data,
                  selectedOrderId: custom_formatted_id,
                  loading: false,
                  showLoader: false,
                  toastMessage: `Successfully Viewed the Report, SEE BOTTOM`,
                },
                () => {
                  //me.showToast("success");
                  message.success(me.state.toastMessage);
                }
              );
            }).catch((error) => {
              document.body.classList.remove('busy-cursor');

              me.setState(
                {
                  loading: false,
                  showLoader: false,
                  toastMessage: `Viewing Report error: ${error}`,
                },
                () => {
                  //me.showToast("success");
                  message.success(me.state.toastMessage);
                }
              );

            })
          }
        });
    });
  }

  async componentDidMount() {
    const me = this;
    let user = JSON.parse(localStorage.getItem("user"));
    // console.log("user.token: ", user.token);
    let accessToken = await helper.getAccessToken(this.context)
    if (process.env.REACT_APP_ENV !== 'DEV') {
        if (!user) {
            
            ({ data: { user } } = await axios.get(`${API_BASE_URL}/users/current`, {
            headers: {
                "Authorization": `Bearer ${accessToken}`
            }
            }));

            localStorage.setItem("user", JSON.stringify(user));
        }

    }

    const ordertype = "successful_fax";
    const pageTitle = "Successfully Faxed Orders";

    this.setState({ _pageTitle: pageTitle });
    axios({
      url: `${API_BASE_URL}/orders?type=${ordertype}`,
      method: "GET",
      headers: {
        "Content-type": "Application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }).then(({ data }) => {
      // setOrders(data.orders);

      me.setState({ loading: false }, () => {
        me.callDataTable(data.orders);
      });
    });
  }

  showModal = (e) => {
    this.setState({
      showFaxModal: !this.state.showFaxModal,
    });
  };


  //For PDF
  /*To Prevent right click on screen*/
  // window.document.addEventListener("contextmenu", (event) => {
  //   event.preventDefault();
  // });

  /*When document gets loaded successfully*/
  onDocumentLoadSuccess({ numPages }) {
    this.setState({ numPages: numPages, pageNumber: 1, });

  }

  changePage(offset) {
    this.setState({ pageNumber: (this.state.pageNumber + offset) });
  }

  previousPage() {
    this.changePage(-1);
  }

  nextPage() {
    this.changePage(1);
  }
  showModal = (e) => {
    this.setState({
      showFaxModal: !this.state.showFaxModal,
    });
  };
  modal() {
    return <FaxModal
      onClose={this.showModal}
      show={this.state.showFaxModal}
      header={`Click Send Fax to Fax or Input your Fax Number And Click Send Fax`}
    >
      <div>
        <span>
          <label class="form-label gender" for={`inpFaxNo`}>Fax Number: &nbsp; </label>
          <input ref={(_ref) => { this.inpFax = _ref }} onChange={(_t) => { this.setState({ fax: _t.text }) }} type="text" id={`inpFaxNo`} name="inpFaxNo" value={this.state.fax} class=" form-control" />
        </span>
      </div>
      <div class="delete-buttons">
        <button
          class="btn waves-effect"
          onClick={() => {
            const user = JSON.parse(localStorage.getItem("user"));

            this.setState({
              loading: true,
            }, async () => {
              this.showModal(null);
              let _url = `${API_BASE_URL}/baylor/send-fax/${this.state.selectedOrder.orderId}`;

              const accessToken = await helper.getAccessToken(this.context)
              
              axios({
                url: _url,
                method: "POST",
                headers: {
                  "Content-type": "Application/json",
                  Authorization: `Bearer ${accessToken}`,
                },
                data: {
                  fax: this.inpFax.value.replace("-", ""),
                },
              }).then(({ data }) => {
                console.log("data: ", data);

                this.setState({ loading: false }, () => {

                  document.body.classList.remove('busy-cursor');

                  this.setState({
                    toastMessage: "Successfully Sent the fax"
                  }, () => {
                    // this.showToast('success');
                    message.success(this.state.toastMessage, 1, () => {
                      window.location.reload();
                    })

                  })
                });
              }).catch(error => {
                console.log('fax cound not be sent for error: ', error);
                this.setState({
                  toastMessage: "Fax could not be sent"
                }, () => {
                  message.error(this.state.toastMessage, 1, () => {
                    window.location.reload();
                  })
                })
              });
            });
          }}
        >
          Send Fax
        </button>
        <button
          class="btn btn-raised g-bg-blue waves-effect"
          onClick={this.showModal}
        >
          Cancel
        </button>
      </div>
      <div>
        {this.state.showProgressBar && (
          <ProgressBar entity={"order"} id={this.state.deleteOrderId} />
        )}
      </div>
    </FaxModal>
  }

  loadDefaultFaxNumber = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    const url = `${API_BASE_URL}/orders/${this.state.selectedOrderId}/faxNumber`;

    const accessToken = await helper.getAccessToken(this.context)

    const { data: { faxNumber } } = await axios({
      url,
      method: "GET",
      headers: {
        "Content-type": "Application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    });

    this.setState({ defaultFaxNumberLoaded: true, fax: faxNumber });
  }

  closeFaxOutForm = () => {
    this.setState({ showFaxOutForm: false });
  }

  validateFaxOutFormData = () => {
    const { fax, customFaxNumber, useDefaultFaxNumberToFaxOut } = this.state;

    if (useDefaultFaxNumberToFaxOut === 'alreadyDone') {
      return true;
    }

    const faxNumber = useDefaultFaxNumberToFaxOut ? fax : customFaxNumber;
    return faxNumber.replace(/-/, "").length >= 9;
  }

  handleFaxOutForm = async () => {
    const hasAllRequiredData = this.validateFaxOutFormData();

    if (hasAllRequiredData) {
      this.setState({ faxOutFormSubmitted: true });
      const user = JSON.parse(localStorage.getItem("user"));
      const url = `${API_BASE_URL}/baylor/send-fax/${this.state.selectedOrderId}`;
      const fax = this.state.useDefaultFaxNumberToFaxOut ? (this.state.useDefaultFaxNumberToFaxOut === 'alreadyDone' ? "alreadyDone" : this.state.fax) : this.state.customFaxNumber;

      console.log("fax: ", fax);

      try {
        const accessToken = await helper.getAccessToken(this.context)
        
        await axios({
          url,
          method: "POST",
          headers: {
            "Content-type": "Application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          data: {
            fax: fax.split("-").join(""),
          }
        });
        setTimeout(() => {
          this.setState({ faxOutFormProcessingComplete: true, faxQeuedSuccessfully: true }, () => {

            setTimeout(() => {
              //   window.location.href = '/successfull_faxes';
              window.location.reload();
            }, 500);


          });
        }, 1000);
      } catch (err) {

        console.log("error on fax: ", err);
        this.setState({ faxOutFormProcessingComplete: true, faxQeuedSuccessfully: false });
      }

    } else {
      message.error("Please input a fax number to continue!");
    }
  }

  renderCircleLoader = () => {
    return (
      <div className="circle-loader-positive-report-content"></div>
    );
  }

  renderFaxOutFormFeedbackMessage = () => {
    const feedbackMessage = this.state.faxQeuedSuccessfully ? "Fax qeued successfully!" : "Sorry, something went wrong!";
    const className = this.state.faxQeuedSuccessfully ? "" : "red-alert";
    return (
      <h3 className={className}>{feedbackMessage}</h3>
    );
  }

  renderFaxOutFormFields = () => {
    return (
      <>
        <div className="fax-out-order-label">Order Id</div>
        <p>{this.state.selectedOrderId}</p>
        <div className="fax-out-order-label">Fax out to</div>
        <Radio.Group defaultValue={true} onChange={(e) => {

          if (e.target.value === 'alreadyDone') {
            this.setState({ useDefaultFaxNumberToFaxOut: e.target.value, faxSubmitButtonText: 'Confirm Fax-Out Status' });
          }
          else {
            this.setState({ useDefaultFaxNumberToFaxOut: e.target.value, faxSubmitButtonText: 'Send Fax' });
          }
        }}>
          <Radio value={true}>Default Number</Radio>
          <Radio value={false}>Custom Number</Radio>
          <Radio value={"alreadyDone"}>Faxed Out Successfully</Radio>
        </Radio.Group>
        <div className="fax-out-number-fields">
          <p className={this.state.useDefaultFaxNumberToFaxOut && this.state.useDefaultFaxNumberToFaxOut !== 'alreadyDone' ? "" : "greyed-out"}>{this.state.fax}</p>
          <input
            type="text"
            disabled={this.state.useDefaultFaxNumberToFaxOut || this.state.useDefaultFaxNumberToFaxOut === 'alreadyDone'}
            className={!this.state.useDefaultFaxNumberToFaxOut ? "" : "greyed-out"}
            placeholder="fax number..."
            value={this.state.customFaxNumber}
            onChange={(e) => this.setState({ customFaxNumber: e.target.value })}
          />
        </div>
        <button
          type="submit"
          onClick={this.handleFaxOutForm}
        >
          {this.state.faxSubmitButtonText}
        </button>
      </>
    );
  }

  renderFaxOutForm = () => {
    return (
      <div className="fax-out-form-container">
        <div className="fax-out-form-box card-display">
          {!this.state.defaultFaxNumberLoaded && this.renderCircleLoader()}
          {this.state.defaultFaxNumberLoaded && !this.state.faxOutFormSubmitted && this.renderFaxOutFormFields()}
          {this.state.faxOutFormSubmitted && !this.state.faxOutFormProcessingComplete && this.renderCircleLoader()}
          {this.state.faxOutFormSubmitted && this.state.faxOutFormProcessingComplete && this.renderFaxOutFormFeedbackMessage()}
          <button
            type="button"
            className="fax-out-form-close-button"
            onClick={this.closeFaxOutForm}
          >
            <div className="close-line"></div>
          </button>
        </div>
      </div>
    );
  }

  render() {

    if (!this.state.loading) {
      return (
        <div>
          {this.state.showFaxOutForm && this.renderFaxOutForm()}
          <div class="block-header">
            <h2>{this.state._pageTitle}</h2>
            {/* <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/orders">Orders</a>
              </li>
              <li class="breadcrumb-item active">View All Orders</li>
            </ul> */}
          </div>
          <div class="row clearfix">
            <div class="col-md-12 col-xs-12">
              <div class="card orders-list">
                <div class="body">
                  <div class="table-responsive">
                    <table
                      className="display"
                      width="100%"
                      ref={(el) => (this.orderDataTable = el)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.showLoader && <div>
            <Spinner />
          </div>}
          {
            this.state.showReportContent && <>
              <div id="reportContent">

                {/* <embed src={`${this.state.reportSignedUrl}`} pluginspage="http://www.adobe.com/products/acrobat/readstep2.html"></embed> */}
                <div class="row clearfix">
                  <div class="col-md-12 col-xs-12">


                    {/* <Document
                      file={this.state.reportSignedUrl}
                      onLoadSuccess={this.onDocumentLoadSuccess}
                    >
                      <Page pageNumber={this.state.pageNumber} />
                    </Document>
                    <div>
                      <div className="pagec">
                        Page {this.state.pageNumber || (this.state.numPages ? 1 : '--')} of {this.state.numPages || '--'}
                      </div>
                      <div className="buttonc">
                        <button
                          type="button"
                          disabled={this.state.pageNumber <= 1}
                          onClick={this.previousPage}
                          className="Pre"

                        >
                          Previous
        </button>
                        <button
                          type="button"
                          disabled={this.state.pageNumber >= this.state.numPages}
                          onClick={this.nextPage}

                        >
                          Next
        </button>
                      </div>
                    </div> */}
                    <hr />
                    <object
                      data={`${this.state.reportSignedUrl}`}
                      type="application/pdf"
                      width="100%"
                      height="678"
                    >

                      <iframe
                        src={`${this.state.reportSignedUrl}`}
                        width="100%"
                        height="678"
                      >
                        <p>This browser does not support PDF!</p>
                      </iframe>

                    </object>
                  </div>
                </div>
              </div>
            </>
          }
          {this.modal()}
        </div>
      );
    } else {
      return (
        <div>
          <div class="block-header">
            <h2>Orders</h2>
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/orders">Orders</a>
              </li>
              <li class="breadcrumb-item active">View All Orders</li>
            </ul>
          </div>
          <div class="row clearfix">
            <div class="col-md-12 col-xs-12">
              <div class="card orders-list">
                <div class="body">
                  <Spinner />
                </div>
              </div>
            </div>
          </div>
          {this.modal()}
        </div>
      );
    }
  }
}
