import React from 'react'

const Diagnosis_Code = (props) => {
  var tempOtherCode=props.state.OtherICD10Code;

    return (
        <div>
             <div id={`${props.state.frmprefix}DiagnosisCodes`} class="body card">
            <div class="row clearfix">
              <div id={`ova1_icd_codes`} class="col-md-12">
                <div class="form-group required">
                  <label class="form-label" for={`DiagnosisCodes`}>
                    <b>
                      OVA1 Diagnosis Codes | ICD-10 Codes (check all that apply)
                    </b>
                  </label>
                  <div class="demo-checkbox">
                    <div class="row clearfix">
                      <div class="col-md-4">
                        <input
                          type="checkbox"
                          data-val="N83.201"
                          id={`diagnosis_UnOcyst_rs`}
                          name={`diagnosis_UnOcyst_rs`}
                          class="filled-in"
                          ref={(ref) => {
                            props.me.diagnosis_UnOcyst_rs = ref;
                          }}
                          onClick={(e) => {
                            props.me.DiagnosisChecked("N83.201");
                          }}
                        />
                        <label for={`diagnosis_UnOcyst_rs`}>
                          N83.201 Unspecified ovarian cyst, right side+ &nbsp;{" "}
                        </label>
                      </div>
                      <div class="col-md-4">
                        <input
                          type="checkbox"
                          data-val="R19.00"
                          id={`diagnosis_IntraAbdPelSwell`}
                          name={`diagnosis_IntraAbdPelSwell`}
                          class="filled-in"
                          ref={(ref) => {
                            props.me.diagnosis_IntraAbdPelSwell = ref;
                          }}
                          onClick={(e) => {
                            props.me.DiagnosisChecked("R19.00");
                          }}
                        />
                        <label for={`diagnosis_IntraAbdPelSwell`}>
                          R19.00 Intra-abdominal and pelvic swelling, mass and
                          lump, unspecified site+ &nbsp;{" "}
                        </label>
                      </div>
                      <div class="col-md-4">
                        <input
                          type="checkbox"
                          data-val="R19.05"
                          id={`diagnosis_Periumbilicalswell`}
                          name={`diagnosis_Periumbilicalswell`}
                          class="filled-in"
                          ref={(ref) => {
                            props.me.diagnosis_Periumbilicalswell = ref;
                          }}
                          onClick={(e) => {
                            props.me.DiagnosisChecked("R19.05");
                          }}
                        />
                        <label for={`diagnosis_Periumbilicalswell`}>
                          R19.05 Periumbilical swelling, mass and lump+ &nbsp;{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="demo-checkbox">
                    <div class="row clearfix">
                      <div class="col-md-4">
                        <input
                          type="checkbox"
                          data-val="N83.202"
                          id={`diagnosis_UnOcyst_ls`}
                          name={`diagnosis_UnOcyst_ls`}
                          class="filled-in"
                          ref={(ref) => {
                            props.me.diagnosis_UnOcyst_ls = ref;
                          }}
                          onClick={(e) => {
                            props.me.DiagnosisChecked("N83.202");
                          }}
                        />
                        <label for={`diagnosis_UnOcyst_ls`}>
                          N83.202 Unspecified ovarian cyst, left side+ &nbsp;{" "}
                        </label>
                      </div>
                      <div class="col-md-4">
                        <input
                          type="checkbox"
                          data-val="R19.03"
                          id={`diagnosis_lowerquadrantSwell`}
                          name={`diagnosis_lowerquadrantSwell`}
                          class="filled-in"
                          ref={(ref) => {
                            props.me.diagnosis_lowerquadrantSwell = ref;
                          }}
                          onClick={(e) => {
                            props.me.DiagnosisChecked("R19.03");
                          }}
                        />
                        <label for={`diagnosis_lowerquadrantSwell`}>
                          R19.03 Right lower quadrant abdominal swelling, mass
                          and lump &nbsp;{" "}
                        </label>
                      </div>
                      <div class="col-md-4">
                        <input
                          type="checkbox"
                          data-val="R19.09"
                          id={`diagnosis_intraabdominalpelvicswell`}
                          name={`diagnosis_intraabdominalpelvicswell`}
                          class="filled-in"
                          ref={(ref) => {
                            props.me.diagnosis_intraabdominalpelvicswell = ref;
                          }}
                          onClick={(e) => {
                            props.me.DiagnosisChecked("R19.09");
                          }}
                        />
                        <label for={`diagnosis_intraabdominalpelvicswell`}>
                          R19.09 Other intra-abdominal pelvic swelling, mass and
                          lump+ &nbsp;{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="demo-checkbox">
                    <div class="row clearfix">
                      <div class="col-md-4">
                        <input
                          type="checkbox"
                          data-val="N83.209"
                          id={`diagnosis_UnOcyst`}
                          name={`diagnosis_UnOcyst`}
                          class="filled-in"
                          ref={(ref) => {
                            props.me.diagnosis_UnOcyst = ref;
                          }}
                          onClick={(e) => {
                            props.me.DiagnosisChecked("N83.209");
                          }}
                        />
                        <label for={`diagnosis_UnOcyst`}>
                          N83.209 Unspecified ovarian cyst, unspecified side+
                          &nbsp;{" "}
                        </label>
                      </div>
                      <div class="col-md-4">
                        <input
                          type="checkbox"
                          data-val="R19.04"
                          id={`diagnosis_LlQabdominalswelling`}
                          name={`diagnosis_LlQabdominalswelling`}
                          class="filled-in"
                          ref={(ref) => {
                            props.me.diagnosis_LlQabdominalswelling = ref;
                          }}
                          onClick={(e) => {
                            props.me.DiagnosisChecked("R19.04");
                          }}
                        />
                        <label for={`diagnosis_LlQabdominalswelling`}>
                          R19.04 Left lower quadrant abdominal swelling, mass
                          and lump &nbsp;{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row clearfix hidediv" id={`covid_icd_codes`}>
              <div class="col-md-12">
                <div class="form-group required">
                  <label class="form-label" for={`DiagnosisCodes`}>
                    <b>
                      {" "}
                      COVD Diagnosis Codes | ICD-10 Codes (check all that apply)
                    </b>
                  </label>
                  <div class="demo-checkbox">
                    <div class="row clearfix">
                      <div class="col-md-6">
                        <input
                          type="checkbox"
                          data-val="Z11.59"
                          id={`diagnosis_z1159`}
                          name={`diagnosis_z1159`}
                          class="filled-in"
                        />
                        <label for={`diagnosis_z1159`}>
                          Z11.59, Encounter for screening for other viral
                          diseases &nbsp;{" "}
                        </label>
                      </div>
                      <div class="col-md-6">
                        <input
                          type="checkbox"
                          data-val="Z03.818"
                          id={`diagnosis_z03818`}
                          name={`diagnosis_z03818`}
                          class="filled-in"
                        />
                        <label for={`diagnosis_z03818`}>
                          Z03.818, Encounter for observation for suspected
                          exposure to other biological agents ruled out &nbsp;{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row clearfix">
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="form-label" for={`address`}>
                    Other ICD-10 Codes
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      ref={(ref) => (props.state.OtherICD10Codes = ref)}
                      id={`OtherICD10Codes`}
                      class="form-control "
                      placeholder="Other ICD-10 Codes"
                     value={props.state.otherICD10Code}
                      
                      onChange={(e)=>{
                        tempOtherCode=e.target.value;
                     //  if(tempOtherCode.includes(',')){
                        // tempOtherCode=props.state.otherICD10Code+tempOtherCode;
                        props.me.setState({otherICD10Code:tempOtherCode});
                      //  tempOtherCode='';
                      //  e.target.value='';
                     //  }
                      
                        
                      }}
                    />
                  </div>
                 
                </div>
              </div>
            </div>
            <div class="row clearfix">
              <div class="col-md-12">
                <p>
                  (This is provided for informational purposes only and is not a
                  guarantee of coverage. It is the provider's responsibility to
                  determine the appropriate codes)
                </p>
              </div>
            </div>
          </div>
        </div>
    )
}

export default Diagnosis_Code
