import React, { useEffect, useState } from "react";
import axios from "axios";
import helper from "../utils/helper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";

import moment from "moment";
import { message } from 'antd';

const API_BASE_URL = helper.getAPIBaseURI();

const ReportCorrectionForm = ({ me, orderId, onClose }) => {
  const [orderDetails, setOrderDetails] = useState(null);

  const [patientFirstName, setPatientFirstName] = useState("");
  const [patientLastName, setPatientLastName] = useState("");
  const [patientDOB, setPatientDOB] = useState("");
  const [patientGender, setPaitentGender] = useState("");
  const [medicalRecordNumber, setMedicalRecordNumber] = useState("");

  const [ultrasoundResults, setUltrasoundResults] = useState("");
  const [sampleCollectionDate, setSampleCollectionDate] = useState("");

  const [physicianOrganizationAccount, setPhysicianOrganizationAccount] =
    useState("");
  const [physicianFirstName, setPhysicianFirstName] = useState("");
  const [physicianLastName, setPhysicianLastName] = useState("");
  const [physicianEmail, setPhysicianEmail] = useState("");
  const [physicianNPI, setPhysicianNPI] = useState("");
  const [physicianAddress, setPhysicianAddress] = useState("");
  const [physicianPracticeLocation, setPhysicianPracticeLocation] =
    useState("");
  const [physicianCity, setPhysicianCity] = useState("");
  const [physicianState, setPhysicianState] = useState(null);
  const [physicianZIPCode, setPhysicianZIPCode] = useState("");
  const [physicianPhone, setPhysicianPhone] = useState("");
  const [physicianFax, setPhysicianFax] = useState("");
  const [physicianFaxCopyTo, setPhysicianFaxCopyTo] = useState("");

  useEffect(() => {
    loadOrderDetails();
  }, []);

  const getUltrasoundResults = (orderDetails) => {
    const clinicalData = orderDetails.tests[0].clinicalData;
    for (let data of clinicalData) {
      if (data.questionCode === "ova1_q02") return data.answer;
    }
  };

  const loadOrderDetails = async () => {
    const user = JSON.parse(localStorage.getItem("user"));
    let response = await axios({
      url: `${API_BASE_URL}/orders/${orderId}`,
      method: "GET",
      headers: {
        Authorization: `Bearer ${user.token}`,
        "Content-type": "Application/json",
      },
    });

    let orderDetails = response.data.orders[0];

    console.log("\n---");
    console.log("orderDetails: ", orderDetails);
    console.log("---\n");

    setOrderDetails(orderDetails);

    setPatientFirstName(orderDetails.Patient.firstName);
    setPatientLastName(orderDetails.Patient.lastName);
    setPatientDOB(new Date(orderDetails.Patient.dob));
    setPaitentGender(orderDetails.Patient.gender);
    setMedicalRecordNumber(orderDetails.Patient.medicalRecordNumber);

    setUltrasoundResults(getUltrasoundResults(orderDetails));
    setSampleCollectionDate(new Date(orderDetails.sampleCollectionDate));

    setPhysicianOrganizationAccount(orderDetails.Physician.organizationAccount);
    setPhysicianFirstName(orderDetails.Physician.firstName);
    setPhysicianLastName(orderDetails.Physician.lastName);
    setPhysicianEmail(orderDetails.Physician.email);
    setPhysicianNPI(orderDetails.Physician.npiNumber);
    setPhysicianAddress(orderDetails.Physician.addresses[0].address_1);
    setPhysicianPracticeLocation(orderDetails.Physician.practiceLocationPlain);
    setPhysicianCity(orderDetails.Physician.addresses[0].city);
    setPhysicianState({
      label: orderDetails.Physician.addresses[0].state,
      value: orderDetails.Physician.addresses[0].state,
      code: orderDetails.Physician.addresses[0].state,
    });
    setPhysicianZIPCode(orderDetails.Physician.addresses[0].zipCode);
    setPhysicianPhone(orderDetails.Physician.addresses[0].phoneNo);
    setPhysicianFax(orderDetails.Physician.addresses[0].phoneNo);
    setPhysicianFaxCopyTo(orderDetails.Physician.addresses[0].phoneNo);
  };

  const handleSubmit = async () => {
    try {
      const orderInfo = { ...orderDetails };

      orderInfo.patient = orderDetails.Patient;
      orderInfo.patient.patientId = orderDetails.Patient._id;
      orderInfo.patient.firstName = patientFirstName;
      orderInfo.patient.lastName = patientLastName;
      orderInfo.patient.dob = moment(patientDOB).format("YYYY-MM-DD");
      orderInfo.patient.gender = patientGender;
      orderInfo.patient.medicalRecordNumber = medicalRecordNumber;

      orderInfo.tests[0].clinicalData = orderInfo.tests[0].clinicalData.map(
        (data) => {
          if (data.questionCode === "ova1_q02") {
            data.answer = ultrasoundResults;
          }

          return data;
        }
      );
      orderInfo.sampleCollectionDate =
        moment(sampleCollectionDate).format("YYYY-MM-DD");

      const physicianAddressLocal = {
        legacyClientId: orderInfo.legacyClientId,
        locationName: "USA",
        address_1: physicianAddress,
        address_2: "",
        addressType: "Primary",
        city: physicianCity,
        country: "USA",
        state: physicianState.code,
        zipCode: physicianZIPCode,
        phoneNo: physicianPhone,
        fax: physicianFax,
        faxCopyTo: physicianFaxCopyTo,
      };

      orderInfo.physician = {
        physicianId: orderDetails.Physician._id,
        organizationAccount: physicianOrganizationAccount,
        firstName: physicianFirstName,
        lastName: physicianLastName,
        email: physicianEmail,
        npiNumber: physicianNPI,
        addresses: [physicianAddressLocal],
      };

      const formData = new FormData();
      formData.append("order", JSON.stringify(orderInfo));
      formData.append("host", window.location.host);
      formData.append('ova1', true); //Only for CP-29

      const user = JSON.parse(localStorage.getItem("user"));


      const response = await axios.put(`${API_BASE_URL}/orders`, formData, {
        headers: {
          Authorization: "Bearer " + user.token,
          "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
        },
      });

      console.log(
        "Save and generate report -> update order response: ",
        response
      );

      me.setState({ showReportCorrectionForm: false }, () => {
        message.success(`Successuflly Saved and Generated Report.`);
      });

    }
    catch (error) {
      message.error(error.message);
    }

  };

  return (
    <div className="report-correction-modal">
      <div className="form-container">
        {/* Patient Information */}
        <div>
          <h4 class="panel-titlepdf">Patient Information</h4>
        </div>
        <div className="row clearfix" style={{ padding: "0 1vw", margin: 0 }}>
          <div class="col-md-6">
            <div class="form-group required">
              <label class="form-label" for="patientFirstName">
                First Name
              </label>
              <div class="form-line">
                <input
                  type="text"
                  id="patientFirstName"
                  class="form-control required"
                  placeholder="First Name"
                  value={patientFirstName}
                  onChange={(e) => setPatientFirstName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group required">
              <label class="form-label" for="patientLastName">
                Last Name
              </label>
              <div class="form-line">
                <input
                  type="text"
                  id="patientLastName"
                  class="form-control required"
                  placeholder="Last Name"
                  value={patientLastName}
                  onChange={(e) => setPatientLastName(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row clearfix" style={{ padding: "0 1vw", margin: 0 }}>
          <div className="col-md-6">
            <div class="form-group required">
              <label class="form-label" for="patientDOB">
                DOB (mm/dd/yyyy)
              </label>
              <div class="form-line">
                <DatePicker
                  id="patientDOB"
                  className={"form-control required"}
                  selected={patientDOB}
                  onChange={(date) => setPatientDOB(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group required">
              <label class="form-label" for="patientGender">
                Gender
              </label>
              <div class="demo-radio-button">
                <input
                  type="radio"
                  id="patientGenderMale"
                  name="patinfo_gender_"
                  value="male"
                  class=" form-control"
                  checked={patientGender === "male"}
                  onChange={() => setPaitentGender("male")}
                />
                <label class="form-label gender" for="patientGenderMale">
                  Male &nbsp;{" "}
                </label>
                <input
                  type="radio"
                  id="patientGenderFemale"
                  name="patinfo_gender_"
                  value="female"
                  class=" form-control"
                  checked={patientGender === "female"}
                  onChange={() => setPaitentGender("female")}
                />
                <label class="form-label gender" for="patientGenderFemale">
                  Female &nbsp;{" "}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="row clearfix" style={{ padding: "0 1vw", margin: 0 }}>
          <div class="col-md-6">
            <div class="form-group required">
              <label class="form-label" for="medicalRecordNumber">
                Medical Record Number
              </label>
              <div class="form-line">
                <input
                  type="text"
                  id="medicalRecordNumber"
                  class="form-control required"
                  placeholder="Medical Record Number"
                  value={medicalRecordNumber}
                  onChange={(e) => setMedicalRecordNumber(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        {/* Clinical Information */}
        <div>
          <h4 class="panel-titlepdf">Clinical Information</h4>
        </div>
        <div className="row clearfix" style={{ padding: "0 1vw", margin: 0 }}>
          <div class="col-md-6">
            <div class="form-group required">
              <label class="form-label" for="ultrasoundResults">
                Ultrasound Results
              </label>
              <div class="demo-radio-button">
                <input
                  type="radio"
                  id="ultrasoundLowRisk"
                  name="ultrasoundResults"
                  value="Low Risk"
                  class=" form-control"
                  checked={ultrasoundResults === "Low Risk"}
                  onChange={() => setUltrasoundResults("Low Risk")}
                />
                <label class="form-label gender" for="ultrasoundLowRisk">
                  Low Risk &nbsp;{" "}
                </label>

                <input
                  type="radio"
                  id="ultrasoundHighRisk"
                  name="ultrasoundResults"
                  value="High Risk"
                  class=" form-control"
                  checked={ultrasoundResults === "High Risk"}
                  onChange={() => setUltrasoundResults("High Risk")}
                />
                <label class="form-label gender" for="ultrasoundHighRisk">
                  High Risk &nbsp;{" "}
                </label>

                <input
                  type="radio"
                  id="ultrasoundNotDefinitive"
                  name="ultrasoundResults"
                  value="Not Definitive"
                  class=" form-control"
                  checked={ultrasoundResults === "Not Definitive"}
                  onChange={() => setUltrasoundResults("Not Definitive")}
                />
                <label class="form-label gender" for="ultrasoundNotDefinitive">
                  Not Definitive &nbsp;{" "}
                </label>

                <input
                  type="radio"
                  id="ultrasoundNotGiven"
                  name="ultrasoundResults"
                  value="Not Given"
                  class=" form-control"
                  checked={ultrasoundResults === "Not Given"}
                  onChange={() => setUltrasoundResults("Not Given")}
                />
                <label class="form-label gender" for="ultrasoundNotGiven">
                  Not Given &nbsp;{" "}
                </label>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div class="form-group required">
              <label class="form-label" for="sampleCollectionDate">
                Sample Collection Date (mm/dd/yyyy)
              </label>
              <div class="form-line">
                <DatePicker
                  id="sampleCollectionDate"
                  className={"form-control required"}
                  selected={sampleCollectionDate}
                  onChange={(date) => setSampleCollectionDate(date)}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                />
              </div>
            </div>
          </div>
        </div>
        {/* Physician Information */}
        <div>
          <h4 class="panel-titlepdf">Physician Information</h4>
        </div>
        <div className="row clearfix" style={{ padding: "0 1vw", margin: 0 }}>
          <div className="col-md-12">
            <div class="form-group">
              <label class="form-label" for="physicianOrganization">
                Organization/Account #
              </label>
              <div class="form-line">
                <input
                  type="text"
                  id="physicianOrganization"
                  class="form-control"
                  placeholder="Organization/Account #"
                  value={physicianOrganizationAccount}
                  onChange={(e) =>
                    setPhysicianOrganizationAccount(e.target.value)
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row clearfix" style={{ padding: "0 1vw", margin: 0 }}>
          <div class="col-md-3">
            <div class="form-group required">
              <label class="form-label" for="physicianFirstName">
                First Name
              </label>
              <div class="form-line">
                <input
                  type="text"
                  id="physicianFirstName"
                  class="form-control required"
                  placeholder="First Name"
                  value={physicianFirstName}
                  onChange={(e) => setPhysicianFirstName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group required">
              <label class="form-label" for="physicianLastName">
                Last Name{" "}
              </label>
              <div class="form-line">
                <input
                  type="text"
                  id="physicianLastName"
                  class="form-control required"
                  placeholder="Last Name"
                  value={physicianLastName}
                  onChange={(e) => setPhysicianLastName(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group required">
              <label class="form-label" for="physicianEmail">
                Email
              </label>
              <div class="form-line">
                <input
                  type="email"
                  id="physicianEmail"
                  class="form-control required"
                  placeholder="Email"
                  value={physicianEmail}
                  onChange={(e) => setPhysicianEmail(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group required">
              <label class="form-label" for="physicianNPI">
                NPI
              </label>
              <div class="form-line">
                <input
                  type="number"
                  id="physicianNPI"
                  class="form-control required"
                  placeholder="NPI"
                  value={physicianNPI}
                  onChange={(e) => setPhysicianNPI(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row clearfix" style={{ padding: "0 1vw", margin: 0 }}>
          <div class="col-md-6">
            <div class="form-group required">
              <label class="form-label" for="physicianAddress">
                Address
              </label>
              <div class="form-line">
                <input
                  type="text"
                  id="physicianAddress"
                  class="form-control required"
                  placeholder="Address"
                  value={physicianAddress}
                  onChange={(e) => setPhysicianAddress(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group required">
              <label class="form-label" for="physicianPracticeLocation">
                Practice Location
              </label>
              <div class="form-line">
                <input
                  type="text"
                  id="physicianPracticeLocation"
                  class="form-control required"
                  placeholder="Practice Location"
                  value={physicianPracticeLocation}
                  onChange={(e) => setPhysicianPracticeLocation(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row clearfix" style={{ padding: "0 1vw", margin: 0 }}>
          <div class="col-md-4">
            <div class="form-group ">
              <label class="form-label" for="physicianCity">
                City{" "}
              </label>
              <div class="form-line">
                <input
                  type="text"
                  id="physicianCity"
                  class="form-control"
                  placeholder="City"
                  value={physicianCity}
                  onChange={(e) => setPhysicianCity(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group ">
              <label class="form-label" for="physicianState">
                State
              </label>
              <div class="form-line">
                <Select
                  class="form-control"
                  id="physicianState"
                  value={physicianState}
                  label="State:"
                  options={helper.usStates}
                  styles={helper.colourStyles}
                  onChange={(val) => setPhysicianState(val)}
                />
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group ">
              <label class="form-label" for="physicianZIPCode">
                Zip Code
              </label>
              <div class="form-line">
                <input
                  type="text"
                  id="physicianZIPCode"
                  class="form-control"
                  placeholder="Zip Code"
                  value={physicianZIPCode}
                  onChange={(e) => setPhysicianZIPCode(physicianZIPCode)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row clearfix" style={{ padding: "0 1vw", margin: 0 }}>
          <div class="col-md-4">
            <div class="form-group required">
              <label class="form-label" for="physicianPhone">
                Phone{" "}
              </label>
              <div class="form-line">
                <input
                  type="text"
                  id="physicianPhone"
                  class="form-control required"
                  placeholder="Phone"
                  value={physicianPhone}
                  onChange={(e) => setPhysicianPhone(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="form-label" for="physicianFax">
                Fax
              </label>
              <div class="form-line">
                <input
                  type="text"
                  id="physicianFax"
                  class="form-control"
                  placeholder="Fax"
                  value={physicianFax}
                  onChange={(e) => setPhysicianFax(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group ">
              <label class="form-label" for="physicianFaxCopyTo">
                Fax copy to
              </label>
              <div class="form-line">
                <input
                  type="text"
                  id="physicianFaxCopyTo"
                  class="form-control required"
                  placeholder="Fax copy to"
                  value={physicianFaxCopyTo}
                  onChange={(e) => setPhysicianFaxCopyTo(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="form-action-container">
        <button type="button" class="btn-action-cancel" onClick={onClose}>
          Cancel
        </button>
        <button type="button" class="btn-action-submit" onClick={handleSubmit}>
          Save and generate new report
        </button>
      </div>
    </div>
  );
};

export default ReportCorrectionForm;
