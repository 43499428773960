import React from "react";
import RetrieveReport from "../add_edit_order_components/RetriveReport";

const Notes_and_Certify = (props) => {
  return (
    <div>
      <div class="row clearfix">
        <div class="col-md-12">
          <h4 class="panel-titlepdf">Notes & Certify</h4>
        </div>
      </div>
      <div id={`${props.frmprefix}NotesCertify`} class="body card">
        <div class="row clearfix">
          <div class="col-md-12">
            <div class="form-group ">
              <label class="form-label" for="address">
                Notes
              </label>
              <div class="form-line">
                <textarea
                  // ref={(ref) => (me.kitShip_notes = ref)}
                  // id={`kitShip_notes`}
                  class="form-control no-resize auto-growth"
                  placeholder="Notes"
                  rows="3"
                  value={props.state.kitShip_notes}
                  onChange={(e) => {
                    props.me.setState({ kitShip_notes: e.target.value });
                  }}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="row clearfix">
          <div class="col-md-12">
            <p>
              <strong>
                {" "}
                Please note this page is for healthcare professionals. If you
                are a patient and would like access to one of our tests, please
                contact your healthcare provider.{" "}
              </strong>
            </p>
          </div>
        </div>
        <div className="row clearfix">
          <div className="col-md-12">
            <div className="form-group required">
              <input
                type="checkbox"
                id={`researchConsent`}
                className="filled-in"
              />
              <label htmlFor={`researchConsent`}>Consent to research</label>
            </div>
            <div className="form-group required">
              <input
                type="checkbox"
                id={`researchUseOnly`}
                className="filled-in"
              />
              <label htmlFor={`researchUseOnly`}>Research use only</label>
            </div>
          </div>
        </div>
        <div class="row clearfix">
          <div class="col-md-12">
            <div class="form-group required">
              <label class="form-label" for="address">
                I certify that I am:
              </label>
              <div class="demo-radio-button" id={`certifytype`}>
                <input
                  type="radio"
                  data-val="A healthcare provider"
                  id={`kitShip_healthcareProvider`}
                  name={`kitShip_partnerhealthcareProvider`}
                  class="form-control"
                />
                <label for={`kitShip_healthcareProvider`}>
                  A healthcare provider &nbsp;{" "}
                </label>
                <input
                  type="radio"
                  data-val="An Aspira employee ordering on behalf of a healthcare provider"
                  id={`kitShip_employeehealthcareProvider`}
                  name={`kitShip_partnerhealthcareProvider`}
                  class="form-control"
                />
                <label for={`kitShip_employeehealthcareProvider`}>
                  An Aspira employee ordering on behalf of a healthcare provider
                  &nbsp;{" "}
                </label>
                <input
                  type="radio"
                  data-val="An Aspira partner ordering on behalf of a healthcare provider"
                  id={`kitShip_partnerhealthcareProvider`}
                  name={`kitShip_partnerhealthcareProvider`}
                  class="form-control"
                />
                <label for={`kitShip_partnerhealthcareProvider`}>
                  An Aspira partner ordering on behalf of a healthcare provider
                  &nbsp;{" "}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div id="ignoreMeInPDF" class="row clearfix">
          <div class="col-md-12">
            <div
              class="form-group"
              style={{ display: "flex", }}
            >
              <button
                id={`btnOrderSubmit`}
                onClick={() => {
                  if (props.handleSave) {
                    props.me.handleSave();
                  }
                }}
                placeholder="Submit"
                name="Submit"
                className="btn btn-primary text-white"
              >
                {props.state.buttonText}
              </button>
              {/* {typeof props.reportId !== 'undefined' && props.reportId !== "" && (
                <>
                  <RetrieveReport
                    state={props.state}
                    token={props.token}
                    reportId={props.reportId}
                    orderId={props.orderId}
                    reportDate={props.reportDate}
                    me={props.me}
                    from={props.from}
                    prefix={props.prefix}
                  />
                </>
              )} */}

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notes_and_Certify;
