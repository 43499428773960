import React, { createRef } from "react";

import axios from "axios";
import Select from 'react-select';
import Toast from '../mycomponents/toast/Toast';
import helper from './../utils/helper';
import { message } from "antd";

const API_BASE_URL = helper.getAPIBaseURI();


let me = null;
export default class ResetPasswordContent extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            roles: [],
            profileImage: null,
            profileImagePreview: null,
            selectedRole: "",
            buttonText: "Change",

            toastMessage: "",
            list: [],
            toastList: [],
        };


        this.password = createRef();
        this.cnfpassword = createRef();
        this._id = null;
    }

    componentDidMount() {

        this.setState({ loading: false })
    }

    showToast(type) {
        const id = Math.floor((Math.random() * 101) + 1);

        switch (type) {
            case 'success':
                this.toastProperties = {
                    id,
                    title: 'Success',
                    description: 'This is a success toast component',
                    backgroundColor: '#5cb85c',
                    icon: "/assets/assets/check.svg"
                }
                break;
            case 'danger':
                this.toastProperties = {
                    id,
                    title: 'Danger',
                    description: 'This is a error toast component',
                    backgroundColor: '#d9534f',
                    icon: "/assets/assets/error.svg"
                }
                break;
            case 'info':
                this.toastProperties = {
                    id,
                    title: 'Info',
                    description: 'This is an info toast component',
                    backgroundColor: '#5bc0de',
                    icon: "/assets/assets/info.svg"
                }
                break;
            case 'warning':
                this.toastProperties = {
                    id,
                    title: 'Warning',
                    description: 'This is a warning toast component',
                    backgroundColor: '#f0ad4e',
                    icon: "/assets/assets/warning.svg"
                }
                break;

            default:
                this.setState({ toastList: [] });
        }

        // this.setState([...this.state.list, this.toastProperties]);
        this.setState({ list: [this.toastProperties] });
    }

    handleChangePassword() {

        let _password = this.password.value;
        let _cnfpassword = this.cnfpassword.value;

        if (_password.length < 1 || _cnfpassword.length < 1) {
            this.setState({
                toastMessage: "Password and Confirm Password both are required",
            }, () => {
                // this.showToast('danger');
                message.error(this.state.toastMessage)
            });
        }
        else if (_password !== _cnfpassword) {

            this.setState({
                toastMessage: "Password and Confirm Password must be same",
            }, () => {
                // this.showToast('danger');
                message.error(this.state.toastMessage);
            });
        }
        else {

            const _url = `${API_BASE_URL}/users/changepassword`;
            axios({
                method: "PUT",
                url: _url,
                data: {
                    "itemID": this.props.user.id,
                    "password": _password,
                }
            })
                .then(({ data }) => {
                    console.log("handleLogin->data: ", data);

                    window.location.href = this.props.user.role.name == 'Billing' ? '/billing-review' :
                        this.props.user.role.name == 'Portal_Benefits_Reviewer' ? '/benefits-review' : '/orders';
                });
        }
    }
    render() {

        return (
            <div>
                <div class="block-header">
                    <h2>Reset Password</h2>

                </div>
                <div class="row clearfix">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div class="card">
                            <div class="body card position">
                                <div class="row clearfix">
                                    <div class="col-sm-12 col-xs-12">
                                        <div class="form-group">
                                            <div class="form-line">
                                                <label class="color-green" htmlFor="password">Password:</label><small className="color-red">*</small>
                                                <input ref={(_ref) => this.password = _ref} id="password" type="text" class="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row clearfix">
                                    <div class="col-sm-12 col-xs-12">
                                        <div class="form-group">
                                            <div class="form-line">
                                                <label class="color-green" htmlFor="password">Confirm Password:</label><small className="color-red">*</small>
                                                <input ref={(_ref) => this.cnfpassword = _ref} id="cnfpassword" type="text" class="form-control" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row clearfix">
                                    <div class="col-sm-4 col-xs-12">
                                        <div class="form-group">
                                            <div class="">

                                                <button id="btnSave" onClick={this.handleChangePassword.bind(this)} class="btn btn-raised g-bg-blue waves-effect" >{this.state.buttonText}</button> &nbsp; &nbsp;
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <Toast
                    toastList={this.state.list}
                    position={"top-center"}
                    autoDelete={true}
                    dismissTime={3000}
                    description={this.state.toastMessage}

                />
            </div>
        );
    }
}