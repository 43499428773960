import { message } from 'antd';
import axios from 'axios';
import 'datatables.net-bs/css/dataTables.bootstrap.css';
import 'datatables.net-dt/css/jquery.dataTables.css';
import React, { useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import Select from 'react-select';
import helper from '../utils/helperPortal';
import { AppContext } from "./../context";
import PhoneNumberInput from './../portalComponents/phoneNumberInput';










const $ = require('jquery');
$.DataTable = require('datatables.net');



const api_base_url = helper.getAPIBaseURI();


const PhysicianInformationCarrierForm = (props) => {


    console.log("PhysicianInformationCarrierForm->props: ", props);

    let location = useLocation();
    let hostname = window.location.hostname;
    let _Checkedhostnames = ["localhost", "lab.aspirawh.com", "dev.aspirawh.cloud", "order.dev-aspiralab.com"];
    console.log("location:", hostname);
    console.log("_Checkedhostnames:", _Checkedhostnames);

    const physicianAddressContext = useContext(AppContext);

    const [phyinfo_no_npi_val, setPhyinfo_no_npi_val] = useState("");


    const { setSelectedPhysicianLegacyInfo, physicianAddresses, addNewPhysicianAddress, selectedPhysicianAddress, setSelectedPhysicianAddress } = physicianAddressContext;
    const { physicianPracticeLocation, addNewPhysicianPracticeLocation, selectedPhysicianPracticeLocation, setSelectedPhysicianPracticeLocation } = physicianAddressContext;

    const [showAddressTableC, setShowAddressTableC] = React.useState(true);
    const [loading, setLoading] = React.useState(false);
    const [showPracticeLocationTableC, setShowPracticeLocationTableC] = React.useState(true);
    const [showTaxonomyTableC, setShowTaxonomyTableC] = React.useState(true);

    const [primaryAddress, setPrimaryAddress] = React.useState({});
    const [practiceLocation, setPracticeLocation] = React.useState({
        address_1: "6707 WHITESTONE RD STE 106",
        address_2: "",
        address_purpose: "LOCATION",
        address_type: "DOM",
        city: "GWYNN OAK",
        country_code: "US",
        country_name: "United States",
        postal_code: "212074140",
        state: "MD",
        telephone_number: "410-265-8737",
        fax: "",
        faxCopyTo: "",
    });
    const [primaryTaxonomoy, setPrimaryTaxonomy] = React.useState({
        "code": "",
        "desc": "",
        "primary": false,
        "state": "",
        "license": ""
    });

    let addressDataTableC = React.createRef();
    let practiceLocationTableC = React.createRef();
    let taxonomyTableC = React.createRef();
    let tblCustomers = React.createRef();

    const [selectedState, setSelectedState] = React.useState({});

    const [showCustomersTable, setShowCustomersTable] = useState(false);

    const [selectedClientId, setSelectedClientId] = useState(null);
    const [selectedNPI, setSelectedNPI] = useState(null);
    const [showError, setShowError] = useState(false);
    const [customerInfo, setCustomerInfo] = useState("");

    const [showLegacyPhysicianEntryForm, setShowLegacyPhysicianEntryForm] = useState(false);
    const [showLegacyPhysicianEntryForm2, setShowLegacyPhysicianEntryForm2] = useState(false);
    const [showAddPhysicianInfo, setShowAddPhysicianInfo] = useState(false);
    // const [physcianFirstName, setPhysicianFirstName] = useState("");
    // const [physcianLastName, setPhysicianLastName] = useState("");
    // const [physcianNPI, setPhysicianNPI] = useState("");

    const callDataTable = (mytable, tableid, datatotables, taxonomy = false) => {
        // let history = useHistory();

        // first time, el will be null
        // see: https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs

        // console.log("mytable: ", mytable);


        // if (!mytable) return;

        console.log("callDataTable()->tableid: ", tableid);
        let $el = $(`#${tableid}`);
        if ($.fn.DataTable.isDataTable(`#${tableid}`)) {
            $el.DataTable().destroy();
        }


        //  console.log($el.DataTable);
        // let table = null;

        if (taxonomy) {
            let index = 0;
            // "": "101Y00000X",
            // "": "Counselor",
            // "primary": true,
            // "state": "MD",
            // "": "LGP5389"
            let table = $el.DataTable({
                data: datatotables,
                "bPaginate": false,
                "ordering": false,
                columns: [

                    {
                        title: "Primary Taxonomy", data: null, name: "primary", sortable: false,
                        render: (data, type) => {
                            if (type == "display") {
                                console.log("taxonomy->data: ", data);
                                ++index;
                                let checkbox = data.primary ? `<input checked="true" id="phyinfoC_primary_taxonomy_${index}" type="checkbox" />` : `<input id="phyinfoC_primary_taxonomy_${index}" type="checkbox" />`;

                                return `${checkbox}<label for="phyinfoC_primary_taxonomy_${index}" class="">`;
                            }
                            return data;
                        },
                    },

                    { title: "Code", data: "code", name: "code" },
                    { title: "Description", data: "desc", name: "desc" },
                    { title: "State", data: "state", name: "state" },
                    { title: "License", data: "license", name: "license" },

                ]
            });
            console.log("table: ", table);
            return table;
        }
        else {
            let index = 0;
            let table = $el.DataTable({
                data: datatotables,
                "bPaginate": false,
                "ordering": false,
                columns: [

                    {
                        title: "Primary", data: null, name: "primary", sortable: false,
                        render: (data, type) => {
                            if (type == "display") {
                                ++index;
                                return `<input id="${tableid}_phyinfoC_primary_${index}" type="checkbox" /><label for="${tableid}_phyinfoC_primary_${index}" class="">`;
                            }
                            return data;
                        },
                    },
                    { title: "Address type", data: "address_type", name: "address_type" },
                    { title: "Country", data: "country_name", name: "country_name" },
                    { title: "State", data: "state", name: "state" },
                    { title: "City", data: "city", name: "city" },
                    { title: "Address1", data: "address_1", "name": "address_1", width: 150 },
                    { title: "Address2", data: "address_2", "name": "address_2", width: 150 },
                    { title: "Postal code", data: "postal_code", "name": "postal_code", width: 150 },
                    { title: "Phone", data: "telephone_number", "name": "telephone_number", width: 150 },
                    { title: "Fax", data: "fax", "name": "fax", width: 150 },
                    { title: "Fax Copy To", data: "faxCopyTo", "name": "faxCopyTo", width: 150 },
                ]
            });
            console.log("else->table: ", table);
            return table;
        }

    }

    const phyinfoC_npiClick_backend = async (phyinfo_npi, phyinfo_first_name, phyinfo_last_name) => {
        let opt = {

            url: `${api_base_url}/physicians/npi/${phyinfo_npi}/${phyinfo_first_name}/${phyinfo_last_name}`,
            method: 'GET',
            data: {

            },
            headers: {
                Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFsYW1yZXpvYW51bEBnbWFpbC5jb20iLCJpYXQiOjE1OTIzODQ0NjR9.Vlqn6clmjUMKFdtMd2NOxrJbu8n0PpPSoH_eGXVkSEM`
            }

        };

        console.log("trying npi registry with url: ", opt);

        //initially on
        $(`#${props.prefix}phyinfoC_nodata_div`).removeClass(" hideFormContent");
        $(`#${props.prefix}phyinfoC_nodata_div`).addClass(" showFormContent");
        setPhyinfo_no_npi_val(1);

        try {

            let ret = await axios(opt);
            console.log("ret.data: ", ret.data);

            const NPI = ret.data.npi;

            if (NPI) {
                setShowAddressTableC(true);
                let table = callDataTable(addressDataTableC, `${props.prefix}address_tableC`, NPI.addresses);

                $(`#${props.prefix}phyinfoC_address_div`).removeClass(" hideFormContent");
                $(`#${props.prefix}phyinfoC_address_div`).addClass(" showFormContent");

                //now off
                $(`#${props.prefix}phyinfoC_nodata_div`).removeClass(" showFormContent");
                $(`#${props.prefix}phyinfoC_nodata_div`).addClass(" hideFormContent");
                setPhyinfo_no_npi_val(2);

                $(`#${props.prefix}address_tableC tbody`).on('click', 'input[type="checkbox"]', function () {
                    table = $(`#${props.prefix}address_tableC`).DataTable();

                    var $this = $(this);
                    let addressTr = $this.closest('tr');
                    let addressRow = (table.row(addressTr).data());

                    console.log("addressRow: ", addressRow);

                    var $checkboxes = $(`#${props.prefix}address_tableC input[type="checkbox"]`).not($this);

                    $checkboxes.each(function (i, check) {
                        $(check).prop('checked', false);
                    });

                    // let temp = {};
                    // Object.keys(addressRow).map((k, i) => {
                    //     temp[k] = addressRow[k];
                    // });

                    // dispatch(setPrimaryAddressRedux(addressRow));
                    // console.log("context.state: ", context.state);

                    setSelectedPhysicianAddress(
                        addressRow.address_1,
                        addressRow.address_2,
                        addressRow.address_purpose,
                        addressRow.address_type,
                        addressRow.city,
                        addressRow.country_code,
                        addressRow.country_name,
                        addressRow.postal_code,
                        addressRow.state,
                        addressRow.telephone_number,
                        addressRow.fax,
                        addressRow.faxCopyTo
                    );

                });

                NPI.addresses.map((item) => {
                    addNewPhysicianAddress(item.address_1, item.address_2,
                        item.address_purpose, item.address_type, item.city, item.country_code,
                        item.country_name, item.postal_code, item.state, item.telephone_number,
                        item.fax, item.faxCopyTo,
                    );
                });
                //Practice location

                if (NPI.practiceLocations && NPI.practiceLocations.length > 0) {
                    console.log("NPI.practiceLocations: ", NPI.practiceLocations)
                    if (typeof NPI.practiceLocations.address_type == "undefined") {
                        // NPI.practiceLocations
                    }
                    else {
                        setShowPracticeLocationTableC(true);
                        const tablePracticeLocations = callDataTable(practiceLocationTableC, `${props.prefix}practice_location_tableC`, NPI.practiceLocations);

                        $(`#${props.prefix}phyinfoC_practiceLocation_div`).removeClass(" hideFormContent");
                        $(`#${props.prefix}phyinfoC_practiceLocation_div`).addClass(" showFormContent");

                        //now off
                        $(`#${props.prefix}phyinfoC_nodata_div`).removeClass(" showFormContent");
                        $(`#${props.prefix}phyinfoC_nodata_div`).addClass(" hideFormContent");
                        setPhyinfo_no_npi_val(2);

                        $(`#${props.prefix}practice_location_tableC tbody`).on('click', 'input[type="checkbox"]', function () {
                            table = $(`#${props.prefix}practice_location_tableC`).DataTable();

                            var $this = $(this);
                            let practiceLocationTr = $this.closest('tr');
                            let practiceLocationRow = (table.row(practiceLocationTr).data());

                            console.log("practiceLocationRow: ", practiceLocationRow);

                            var $checkboxes = $(`#${props.prefix}practice_location_tableC input[type="checkbox"]`).not($this);

                            $checkboxes.each(function (i, check) {
                                $(check).prop('checked', false);
                            });

                            // let temp = {};
                            // Object.keys(addressRow).map((k, i) => {
                            //     temp[k] = addressRow[k];
                            // });

                            // setPracticeLocation(practiceLocationRow);

                            // console.log("practiceLocation: ", practiceLocation);

                            setSelectedPhysicianPracticeLocation(
                                practiceLocationRow.address_1,
                                practiceLocationRow.address_2,
                                practiceLocationRow.address_purpose,
                                practiceLocationRow.address_type,
                                practiceLocationRow.city,
                                practiceLocationRow.country_code,
                                practiceLocationRow.country_name,
                                practiceLocationRow.postal_code,
                                practiceLocationRow.state,
                                practiceLocationRow.telephone_number,
                                practiceLocationRow.fax,
                                practiceLocationRow.faxCopyTo,
                            );

                        });

                        NPI.practiceLocations.map((item) => {
                            addNewPhysicianPracticeLocation(item.address_1, item.address_2,
                                item.address_purpose, item.address_type, item.city, item.country_code,
                                item.country_name, item.postal_code, item.state, item.telephone_number
                                , item.fax, item.faxCopyTo,
                            );
                        });
                    }
                }
                //

                //Taxonomy
                if (NPI.taxonomies && NPI.taxonomies.length > 0) {
                    setShowTaxonomyTableC(true);
                    let tableTaxonomies = callDataTable(taxonomyTableC, `${props.prefix}taxonomy_tableC`, NPI.taxonomies, true);

                    $(`#${props.prefix}phyinfoC_taxonomy_div`).removeClass(" hideFormContent");
                    $(`#${props.prefix}phyinfoC_taxonomy_div`).addClass(" showFormContent");

                    //now off
                    $(`#${props.prefix}phyinfoC_nodata_div`).removeClass(" showFormContent");
                    $(`#${props.prefix}phyinfoC_nodata_div`).addClass(" hideFormContent");
                    setPhyinfo_no_npi_val(2);

                    $(`#${props.prefix}taxonomy_tableC tbody`).on('click', 'input[type="checkbox"]', function () {
                        tableTaxonomies = $(`#${props.prefix}taxonomy_tableC`).DataTable();

                        var $this = $(this);
                        let taxonomyTr = $this.closest('tr');
                        let taxonomyRow = (tableTaxonomies.row(taxonomyTr).data());

                        console.log("taxonomyRow: ", taxonomyRow);

                        var $checkboxes = $(`#${props.prefix}taxonomy_tableC input[type="checkbox"]`).not($this);

                        $checkboxes.each(function (i, check) {
                            $(check).prop('checked', false);
                        });

                        // let temp = {};
                        // Object.keys(taxonomyRow).map((k, i) => {
                        //     temp[k] = taxonomyRow[k];
                        // });

                        setPrimaryTaxonomy({
                            code: taxonomyRow.code,
                            desc: taxonomyRow.desc,
                            primary: taxonomyRow.primary,
                            state: taxonomyRow.state,
                            license: taxonomyRow.license,
                        });

                        console.log("primaryTaxonomoy: ", primaryTaxonomoy);

                    });

                }
                //
            } else {

                $(`#${props.prefix}phyinfoC_nodata_div`).removeClass(" hideFormContent");
                $(`#${props.prefix}phyinfoC_nodata_div`).addClass(" showFormContent");
                setPhyinfo_no_npi_val(1);
            }


        } catch (error) {
            console.log("error: ", error);
            $(`#${props.prefix}phyinfoC_nodata_div`).removeClass(" hideFormContent");
            $(`#${props.prefix}phyinfoC_nodata_div`).addClass(" showFormContent");
            setPhyinfo_no_npi_val(1);
        }
    }
    const phyinfoC_npiClick = async () => {

        // "number": 1619385200,
        // "first_name": "ANASTASIA",
        // "last_name": "FANASELLE",


        var phyinfo_npi = $(`#${props.prefix}phyinfo_npi`).val();
        // var phyinfo_email = $(`#${props.prefix}phyinfo_email`).val();
        var phyinfo_first_name = $(`#${props.prefix}phyinfo_first_name`).val();
        var phyinfo_last_name = $(`#${props.prefix}phyinfo_last_name`).val();


        await phyinfoC_npiClick_backend(phyinfo_npi, phyinfo_first_name, phyinfo_last_name);

    };


    const loadInfo = async () => {

        console.log("PhysicianInformationForm->props: ", props);
    };
    React.useEffect(() => {
        loadInfo();
    });

    const onChangeState = (state) => {

        if (props.onChangePhysicianState) {
            props.onChangePhysicianState(state);
        }
    };

    const phyinfoOrderBeforeUsClick1 = () => {

        var ordered_before1 = document.getElementById(`${props.prefix}ordered_before1`);

        if (ordered_before1.checked == true) {
            $(`#${props.prefix}ordered_before_placed_div`).removeClass(" hideFormContent");
            $(`#${props.prefix}ordered_before_placed_div`).addClass(" showFormContent");

            $(`#${props.prefix}ordered_before_notplaced_div`).addClass(" hideFormContent");
            $(`#${props.prefix}ordered_before_notplaced_div`).removeClass(" showFormContent");
        }
    };
    const phyinfoOrderBeforeUsClick2 = () => {

        var ordered_before2 = document.getElementById(`${props.prefix}ordered_before2`);

        if (ordered_before2.checked == true) {
            $(`#${props.prefix}ordered_before_placed_div`).addClass(" hideFormContent");
            $(`#${props.prefix}ordered_before_placed_div`).removeClass(" showFormContent");

            $(`#${props.prefix}ordered_before_notplaced_div`).removeClass(" hideFormContent");
            $(`#${props.prefix}ordered_before_notplaced_div`).addClass(" showFormContent");
        }
    };

    const phyinfoC_AccountNumberClick = async () => {

        setLoading(true);

        let clientId = $(`#${props.prefix}phyinfo_account_number`).val();

        let opt = {

            url: `${api_base_url}/physicians/customers/${clientId}`,
            method: 'GET',
            data: {

            },
            headers: {
                Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFsYW1yZXpvYW51bEBnbWFpbC5jb20iLCJpYXQiOjE1OTIzODQ0NjR9.Vlqn6clmjUMKFdtMd2NOxrJbu8n0PpPSoH_eGXVkSEM`
            }

        };


        console.log("trying npi registry with url: ", opt);
        console.log(loading);

        try {
            let ret = await axios(opt);
            console.log("ret.data: ", ret.data);

            const customer = ret.data.customer;
            console.log('tryinggggggggggggggggg---------');

            // const theButton = document.getElementById(`${props.prefix}_btn_verify`);
            // theButton.textContent = "Verify";
            // theButton.classList.add("text-white");
            // theButton.classList.remove("button--loading");
            // theButton.disabled = false;

            if (customer) {
                setShowLegacyPhysicianEntryForm2(false);

                if (customer.physicians.length < 1) {
                    const tableid = `${props.prefix}customers`;
                    const $el = $(`#${tableid}`);

                    if ($.fn.DataTable.isDataTable(`#${tableid}`)) {
                        $el.DataTable().destroy();
                    }
                    setShowCustomersTable(false);

                    setShowAddPhysicianInfo(false);
                    setShowLegacyPhysicianEntryForm(true);
                } else {
                    // if(customer.physicians){
                    setShowAddPhysicianInfo(true);
                    setShowCustomersTable(true);
                    setShowLegacyPhysicianEntryForm(false);
                    const datatotables = [];
                    customer.physicians.map((p, i) => {
                        datatotables.push({
                            clientId: customer.clientId,
                            firstName: p.firstName,
                            lastName: p.lastName,
                            npi: p.npi,
                        })
                    });

                    let index = 0;

                    const tableid = `${props.prefix}customers`;
                    const $el = $(`#${tableid}`);
                    if ($.fn.DataTable.isDataTable(`#${tableid}`)) {
                        $el.DataTable().destroy();
                    }
                    let table = $el.DataTable({
                        data: datatotables,
                        "bPaginate": false,
                        "ordering": false,
                        columns: [
                            {
                                title: "Physician", data: null, name: "physician", sortable: false,
                                render: (data, type) => {
                                    if (type == "display") {
                                        console.log("render()->data: ", data);

                                        ++index;
                                        let checkbox = `<input data-npi="${data.npi}" id="phyinfoC_customer_physcian_${index}" type="checkbox" />`;

                                        return `${checkbox}<label for="phyinfoC_customer_physcian_${index}" class="">`;
                                    }
                                    return data;
                                },
                            },
                            { title: "First Name", data: "firstName", name: "firstName", sortable: true, },
                            { title: "Last Name", data: "lastName", name: "lastName", sortable: true, },


                        ]
                    });



                    $(`#${tableid} tbody`).on('click', 'input[type="checkbox"]', function () {
                        var $this = $(this);

                        console.log("table: ", table);

                        let dataTr = $this.closest('tr');
                        console.log("dataTr: ", dataTr);

                        let dataRow = (table.row(dataTr).data());
                        console.log("dataRow: ", dataRow);

                        let _selectedNPI = $this.data('npi'); //dataRow['npi'];
                        console.log("_selectedNPI: ", _selectedNPI);
                        if ($(this).prop("checked") === true) {
                            setShowAddPhysicianInfo(false);
                        } else {
                            setShowAddPhysicianInfo(true);
                        }
                        const $checkboxes = $(`#${tableid} input[type="checkbox"]`).not($this);

                        $checkboxes.each(function (i, check) {
                            $(check).prop('checked', false);
                        });

                        setSelectedClientId(customer.clientId);
                        setSelectedNPI(_selectedNPI);

                        setSelectedPhysicianLegacyInfo(_selectedNPI, customer.clientId);

                        if (props && props.selectedPhysicianLegacyInfo) {
                            props.selectedPhysicianLegacyInfo({
                                selectedNPI: _selectedNPI,
                                selectedClientId: customer.clientId
                            });
                        }
                        // localStorage.setItem("selectedPhysicianLegacyInfo", JSON.stringify({
                        //     selectedNPI: _selectedNPI,
                        //     selectedClientId: customer.clientId
                        // }));
                    });

                    setCustomerInfo(`${customer.name} ${customer.addressLine1} ${customer.city} ${customer.state} ${customer.zip}, Phone: ${customer.officePhone}, Fax: ${customer.fax}`);

                }
                console.log('have customer---------------');
                setLoading(false);
                console.log(loading);
            }
            else {
                message.error(`sorry but this client is not currently acitve`, 2, () => {
                    const tableid = `${props.prefix}customers`;
                    const $el = $(`#${tableid}`);

                    if ($.fn.DataTable.isDataTable(`#${tableid}`)) {
                        $el.DataTable().destroy();
                    }
                    setShowCustomersTable(false);

                    setShowAddPhysicianInfo(false);
                    setShowLegacyPhysicianEntryForm(false);
                });
                setLoading(false);
                console.log('client is not acitve-------');
                console.log(loading);
            }

        }
        catch (error) {
            setLoading(false);
            console.log('errrrrrrrrrrrrrrrrrrrrr------------');
            console.log("error: ", error);
            setShowError(true);

        }
    };

    $(document).ready(() => {

        // $(`#${props.prefix}PhysicianContainer`).hide();

        $(`#${props.prefix}btnPhysicianInformationFormReset`).click(() => {
            $(`#${props.prefix}PhysicianContainer`).find(':input').map((i, elem) => {
                const _type = $(elem).attr('type');
                console.log("_type: ", _type);
                helper.resetForm(_type, elem);
            });
            setSelectedState({});

        });

    })

    async function handleSavePhysician(suffix = '') {

        const phyinfo_client_id = $(`#${props.prefix}phyinfo_account_number`).val();
        const phyinfo_first_name = $(`#${props.prefix}phyinfo_first_name_${suffix}`).val();
        const phyinfo_last_name = $(`#${props.prefix}phyinfo_last_name_${suffix}`).val();
        const phyinfo_no_npi = $(`#${props.prefix}phyinfo_npi_${suffix}`).val();

        if (phyinfo_client_id && phyinfo_first_name && phyinfo_last_name && phyinfo_no_npi) {
            console.log('valid info');

        } else {
            // need to handle invalid case
            console.log('inValid info');
            message.error("All fields are required.");
            return;
        }

        const opt = {

            url: `${api_base_url}/physicians/physicianinlegacy`,
            method: 'POST',
            data: {
                from: 'clientid',
                clientId: phyinfo_client_id,
                firstName: phyinfo_first_name,
                lastName: phyinfo_last_name,
                npi: phyinfo_no_npi,
            },
            headers: {
                Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFsYW1yZXpvYW51bEBnbWFpbC5jb20iLCJpYXQiOjE1OTIzODQ0NjR9.Vlqn6clmjUMKFdtMd2NOxrJbu8n0PpPSoH_eGXVkSEM`
            }

        };

        console.log("trying npi registry with url: ", opt);

        //initially on

        try {
            const ret = await axios(opt);
            console.log("ret.data: ", ret.data);

            if (ret.data.status == true) {
                await phyinfoC_AccountNumberClick();
            }
        }
        catch (error) {
            console.log('handleSavePhysician', error);
        }
    }

    return (
        <>
            <input type="hidden" id={`${props.prefix}selectedNPI`} value="" />
            <input type="hidden" id={`${props.prefix}selectedCustomerId`} value="" />
            <input type="hidden" id={`${props.prefix}hostname`} value={hostname} />
            <div class="panel-body" id={`${props.prefix}PhysicianContainer`}>
                <div class={(_Checkedhostnames.includes(hostname)) ? " showFormContent" : " hideFormContent"}>
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="form-group required">
                                <label class="form-label">Have you ordered from us before?</label>
                                <div class="demo-radio-button" id={`${props.prefix}ordered_before_box`}>
                                    <input type="radio" id={`${props.prefix}ordered_before1`} name="ordered_before" data-val="yes" class="form-control" onClick={() => { phyinfoOrderBeforeUsClick1() }} />
                                    <label class="form-label gender" for={`${props.prefix}ordered_before1`}>Yes</label>
                                    <input type="radio" id={`${props.prefix}ordered_before2`} name="ordered_before" data-val="no" class="form-control" onClick={() => { phyinfoOrderBeforeUsClick2() }} />
                                    <label class="form-label gender" for={`${props.prefix}ordered_before2`}>No</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class=" hideFormContent" id={`${props.prefix}ordered_before_placed_div`}>
                    <div class="row clearfix">
                        <div class="col-md-11" >
                            <div class="form-group required">
                                <label class="form-label" for="ordered_before1">Physician's Account Number</label>
                                <div class="form-line">
                                    <input type="text" id={`${props.prefix}phyinfo_account_number`} class="form-control required" placeholder="Physician's Account Number" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-1">
                            <div class="form-group">
                                <div>
                                    <a href="javascript:void(0);" class="btn btn-light" onClick={() => {
                                        phyinfoC_AccountNumberClick()

                                    }}>{loading == false && <div>Verify</div>}{loading == true && <div class="spin"></div>}</a>
                                    {/* <div>
                                         <a id={`${props.prefix}_btn_verify`} href="javascript:void(0);" class="btn btn-primary text-white" onClick={() => {
                                        const theButton = document.getElementById(`${props.prefix}_btn_verify`);

                                        theButton.disabled = true;
                                        theButton.classList.remove("text-white");
                                        theButton.classList.add("button--loading");
                                        phyinfoC_AccountNumberClick();
                                    }}>Verify</a> 
                                    </div>*/}
                                </div>
                            </div>

                            {showError && <div class="row clearfix">
                                <div class="col-md-12">
                                    <div class="row clearfix">
                                        <div class="form-group">
                                            <div class="form-line">
                                                <span className="danger-info">We could not verify your information, please proceed with your order. A customer service representative will reach out to confirm your information</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>
                        {showLegacyPhysicianEntryForm && (<>
                            <div class="row clearfix">
                                <div class="col-md-12">
                                    <h5>Add Physician Information</h5>
                                </div>
                            </div>
                            <div class="row clearfix">
                                <div class="col-md-4" >
                                    <div class="form-group required">
                                        <label class="form-label" for="ordered_before1">First Name</label>
                                        <div class="form-line">
                                            <input type="text" id={`${props.prefix}phyinfo_first_name_`} class="form-control required" placeholder="First Name" />                                    </div>
                                    </div>
                                </div>

                                <div class="col-md-4">
                                    <div class="form-group required">
                                        <label class="form-label" for="ordered_before1">Last Name </label>
                                        <div class="form-line">
                                            <input type="text" id={`${props.prefix}phyinfo_last_name_`} class="form-control required" placeholder="Last Name" />                                    </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group required">
                                        <label class="form-label" for="ordered_before1">NPI </label>
                                        <div class="form-line">
                                            <input type="text" id={`${props.prefix}phyinfo_npi_`} class="form-control required" placeholder="NPI" />                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row clearfix">
                                <div class="col-md-12 text-right">
                                    <button onClick={async () => {
                                        await handleSavePhysician();
                                    }} id={`${props.prefix}btnPhysicianInformationSave`} class="btn btn-info text-white">Save Physician Info</button>
                                </div>
                            </div>
                        </>)}

                        {showCustomersTable && (<>
                            <div class="row clearfix">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="form-label" for="PracticeLocation">Practice Location </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row clearfix">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <label class="form-label" for="PracticeLocation2">{customerInfo}</label>
                                    </div>
                                </div>
                            </div>
                        </>)}
                        <div class="row clearfix">
                            <div class="col-md-12" id={`${props.prefix}phyinfoC_customers_div`}>
                                <div class="row clearfix">
                                    <div class="form-group required">
                                        <div class="form-line">
                                            {showCustomersTable &&
                                                <table id={`${props.prefix}customers`} className="display" width="100%" ref={el => (tblCustomers = el)} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showAddPhysicianInfo && (
                            <div>
                                <div class="row clearfix">
                                    <div class="col-md-12 text-right">
                                        <button
                                            onClick={() => {
                                                setShowLegacyPhysicianEntryForm2(true);
                                            }}
                                            id={`${props.prefix}btnPhysicianInformationAdd`}
                                            class="btn btn-info text-white"
                                        >
                                            Add Physician Info
                                        </button>
                                    </div>
                                </div>
                                {showLegacyPhysicianEntryForm2 && (<>
                                    <div class="row clearfix">
                                        <div class="col-md-12">
                                            <h5>Add Physician Information</h5>
                                        </div>
                                    </div>
                                    <div class="row clearfix">
                                        <div class="col-md-4" >
                                            <div class="form-group required">
                                                <label class="form-label" for="ordered_before1">First Name</label>
                                                <div class="form-line">
                                                    <input type="text" id={`${props.prefix}phyinfo_first_name_2`} class="form-control required" placeholder="First Name" />                                    </div>
                                            </div>
                                        </div>

                                        <div class="col-md-4">
                                            <div class="form-group required">
                                                <label class="form-label" for="ordered_before1">Last Name </label>
                                                <div class="form-line">
                                                    <input type="text" id={`${props.prefix}phyinfo_last_name_2`} class="form-control required" placeholder="Last Name" />                                    </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group required">
                                                <label class="form-label" for="ordered_before1">NPI </label>
                                                <div class="form-line">
                                                    <input type="text" id={`${props.prefix}phyinfo_npi_2`} class="form-control required" placeholder="NPI" />                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row clearfix">
                                        <div class="col-md-12 text-right">
                                            <button onClick={async () => {
                                                await handleSavePhysician('2');
                                            }} id={`${props.prefix}btnPhysicianInformationSave`} class="btn btn-info text-white">Save Physician Info</button>
                                        </div>
                                    </div>
                                </>)}
                            </div>)}

                    </div>

                    <div class={(_Checkedhostnames.includes(hostname)) ? " hideFormContent " : " showFormContent"} id={`${props.prefix}ordered_before_notplaced_div`}>
                        <div class="row clearfix">
                            <div class={(props.prefix === 'ova1_') ? "col-md-2" : "col-md-2"} >
                                <div class="form-group required">
                                    <label class="form-label" for="ordered_before1">
                                        {(props.prefix === 'ova1_') ? "First Name" : "First Name"}

                                    </label>
                                    <div class="form-line">
                                        {hostname == 'localhost' &&
                                            <input type="text" id={`${props.prefix}phyinfo_first_name`} value={(props.prefix === 'ova1_') ? "ANASTASIA" : "ANASTASIA"} class="form-control required" placeholder={(props.prefix === 'ova1_') ? "First Name" : "First Name"} />
                                        }

                                        {hostname !== 'localhost' &&
                                            <input type="text" id={`${props.prefix}phyinfo_first_name`} class="form-control required" placeholder="First Name" />
                                        }

                                    </div>
                                </div>
                            </div>
                            {/* {(props.prefix === 'ova1_') && */}
                            <div class="col-md-2">
                                <div class="form-group required">
                                    <label class="form-label" for="ordered_before1">Last Name </label>
                                    <div class="form-line">
                                        {hostname == 'localhost' &&
                                            <input type="text" id={`${props.prefix}phyinfo_last_name`} value="FANASELLE" class="form-control required" placeholder="Last Name" />
                                        }

                                        {hostname !== 'localhost' &&
                                            <input type="text" id={`${props.prefix}phyinfo_last_name`} class="form-control required" placeholder="Last Name" />
                                        }

                                    </div>
                                </div>
                            </div>
                            {/* } */}
                            <div class="col-md-3">
                                <div class="form-group required">
                                    <label class="form-label" for="ordered_before1">Email</label>
                                    <div class="form-line">
                                        {hostname == 'localhost' &&
                                            <input type="email" id={`${props.prefix}phyinfo_email`} value={`alamrezoanul@gmail.com`} class="form-control required" placeholder="Email" />
                                        }

                                        {hostname !== 'localhost' &&
                                            <input type="email" id={`${props.prefix}phyinfo_email`} class="form-control required" placeholder="Email" />
                                        }

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group required">
                                    <label class="form-label" for="ordered_before1">Phone</label>
                                    <div class="form-line">
                                        <PhoneNumberInput prefix={`${props.prefix}phyinfo_phone_`} />
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-2">
                                <div class="form-group required">
                                    <label class="form-label" for="ordered_before1">NPI</label>
                                    <div class="form-line">
                                        {hostname == 'localhost' &&
                                            <input type="number" id={`${props.prefix}phyinfo_npi`} value={`1619385200`} class="form-control required" placeholder="NPI" />
                                        }

                                        {hostname !== 'localhost' &&
                                            <input type="number" id={`${props.prefix}phyinfo_npi`} class="form-control required" placeholder="NPI" />
                                        }

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-1">
                                <div class="form-group">
                                    <div>
                                        <a href="javascript:void(0);" class="btn btn-light" onClick={() => {
                                            phyinfoC_npiClick()
                                        }}>Verify</a>
                                        <input type="hidden" id={`${props.prefix}phyinfo_no_npi`} value={phyinfo_no_npi_val} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row clearfix">
                            <div class="col-md-12 hideFormContent" id={`${props.prefix}phyinfoC_address_div`}>
                                <div class="row clearfix">
                                    <div class="form-group required">
                                        <p class="form-label addressPhy" for="ordered_before1">Address <span class="addressPhySpan">(Please choose an address to register)</span></p>
                                        <div class="form-line">
                                            {showAddressTableC &&
                                                <table id={`${props.prefix}address_tableC`} className="display" width="100%" ref={el => (addressDataTableC = el)} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row clearfix">
                            <div class="col-md-12 hideFormContent" id={`${props.prefix}phyinfoC_practiceLocation_div`}>
                                <div class="row clearfix">
                                    <div class="form-group required">
                                        <p class="form-label addressPhy" for="ordered_before1">Practice location <span class="addressPhySpan">(Please choose a practice location to register)</span></p>
                                        <div class="form-line">
                                            {showPracticeLocationTableC &&
                                                <table id={`${props.prefix}practice_location_tableC`} className="display" width="100%" ref={el => (practiceLocationTableC = el)} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row clearfix">
                            <div class="col-md-12 hideFormContent" id={`${props.prefix}phyinfoC_taxonomy_div`} >
                                <div class="row clearfix">
                                    <div class="form-group required">
                                        <p class="form-label addressPhy" for="ordered_before1">Taxonomy <span class="addressPhySpan">(Please choose a taxonomy to register)</span></p>
                                        <div class="form-line">
                                            {showTaxonomyTableC &&
                                                <table id={`${props.prefix}taxonomy_tableC`} className="display" width="100%" ref={el => (taxonomyTableC = el)} />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row clearfix">
                            <div class="col-md-12 hideFormContent" id={`${props.prefix}phyinfoC_nodata_div`}>
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                        <div class="form-group required">
                                            <label class="form-label" for="ordered_before1">Address</label>
                                            <div class="form-line">
                                                <input type="text" id={`${props.prefix}phyinfoC_address`} class="form-control required" placeholder="Address" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group required">
                                            <label class="form-label" for="ordered_before1">Practice Location</label>
                                            <div class="form-line">
                                                <input type="text" id={`${props.prefix}phyinfoC_practiceLocation`} class="form-control required" placeholder="Practice Location" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row clearfix">
                                    <div class="col-md-4">
                                        <div class="form-group ">
                                            <label class="form-label" for="ordered_before1">City </label>
                                            <div class="form-line">
                                                <input type="text" id={`${props.prefix}phyinfoC_city`} class="form-control" placeholder="City" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group ">
                                            <label class="form-label" for="ordered_before1">State</label>
                                            <div class="form-line">
                                                <Select
                                                    // defaultValue={"Choose State"}
                                                    value={selectedState}
                                                    label="State:"
                                                    options={helper.usStates}
                                                    styles={helper.colourStyles}
                                                    onChange={(val) => {
                                                        onChangeState(val.value);
                                                        $(`#${props.prefix}phyinfo_state`).val(val.value);
                                                        setSelectedState(val);
                                                    }}

                                                />

                                                {/* <input type="text" id={`${props.prefix}phyinfoC_state`} class="form-control" placeholder="State" /> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group ">
                                            <label class="form-label" for="ordered_before1">Zip Code</label>
                                            <div class="form-line">
                                                <input type="email" id={`${props.prefix}phyinfoC_zipCode`} class="form-control" placeholder="Zip Code" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row clearfix">
                                    <div class="col-md-4">
                                        <div class="form-group required">
                                            <label class="form-label" for="ordered_before1">Phone </label>
                                            <div class="form-line">
                                                <PhoneNumberInput prefix={`${props.prefix}phyinfoC_`} />
                                                {/* <input type="text" id="phyinfoC_Phone" class="form-control required" placeholder="Phone"/> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label class="form-label" for="ordered_before1">Fax</label>
                                            <div class="form-line">
                                                <input type="text" id={`${props.prefix}phyinfoC_fax`} class="form-control" placeholder="Fax" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group ">
                                            <label class="form-label" for="ordered_before1">Fax copy to</label>
                                            <div class="form-line">
                                                <input type="email" id={`${props.prefix}phyinfoC_Faxcopyto`} class="form-control required" placeholder="Fax copy to" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="demo-checkbox ">
                                <input type="checkbox" id={`${props.prefix}phyinfoC_confirmation`} name="phyinfoC_confirmation" class="filled-in required" />
                                <label for={`${props.prefix}phyinfoC_confirmation`} class="form-label">By checking the box below I am confirming that I have provided informed consent for this test. </label>

                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="ordered_before1">Organization</label>
                                <div class="form-line">
                                    <input type="text" id={`${props.prefix}phyinfo_Organization_account`} class="form-control" placeholder="Organization" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row clearfix">
                        <div class="col-md-12 text-right">
                            <button id={`${props.prefix}btnPhysicianInformationFormReset`} class="btn btn-light">Reset Form</button>
                            <button id={`${props.prefix}btnPhysicianInformationFormNext`} class="btn btn-light">Next</button>
                        </div>
                    </div>
                </div>
            </div>

        </>
    );
}

export default PhysicianInformationCarrierForm;
