import React from "react";


export default class HomeContent extends React.PureComponent {

    render() {
        return (
            <div>
                <div class="block-header">
                    <h2>Orders</h2>
                    <ul class="breadcrumb">
                        <li class="breadcrumb-item"><a href="/orders">Orders</a></li>
                        <li class="breadcrumb-item active">Orders</li>
                    </ul>
                </div>

                <div class="row clearfix">
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="card widget-stat">
                            <div class="body">
                                <div class="media">
                                    <div class="media-icon g-bg-cyan">
                                        <i class="zmdi zmdi-shopping-basket zmdi-hc-2x"></i>
                                    </div>
                                    <div class="media-text">
                                        <span class="title1">Reports Delivered</span>
                                        <span class="value">25</span>
                                    </div>
                                </div>
                                <p class="media-footer text-success"><i class="zmdi zmdi-caret-up"></i> 13%
                                <span>Compared to last week</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 ">
                        <div class="card widget-stat">
                            <div class="body">
                                <div class="media">
                                    <div class="media-icon g-bg-blue">
                                        <i class="zmdi zmdi-account-o zmdi-hc-2x"></i>
                                    </div>
                                    <div class="media-text">
                                        <span class="title1">New Orders </span>
                                        <span class="value">18</span>
                                    </div>
                                </div>
                                <p class="media-footer text-success"><i class="zmdi zmdi-caret-up"></i> 18%
                                <span>Compared to last month</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 ">
                        <div class="card widget-stat">
                            <div class="body">
                                <div class="media">
                                    <div class="media-icon g-bg-blush2">
                                        <i class="zmdi zmdi-label zmdi-hc-2x"></i>
                                    </div>
                                    <div class="media-text">
                                        <span class="title1">Orders Pending</span>
                                        <span class="value">23</span>
                                    </div>
                                </div>
                                <p class="media-footer text-danger"><i class="zmdi zmdi-caret-down"></i> 12%
                                <span>Compared to last Week</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-sm-12 ">
                        <div class="card widget-stat">
                            <div class="body">
                                <div class="media">
                                    <div class="media-icon g-bg-green">
                                        <i class="zmdi zmdi-money zmdi-hc-2x"></i>
                                    </div>
                                    <div class="media-text">
                                        <span class="title1">Kits processed this week</span>
                                        <span class="value">104</span>
                                    </div>
                                </div>
                                <p class="media-footer text-success"><i class="zmdi zmdi-caret-up"></i> 21%
                                <span>Compared to last Year</span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row clearfix">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="card">
                            <div class="header">
                                <h2>User Status</h2>
                                <ul class="header-dropdown m-r--5">
                                    <li class="dropdown"> <a href="javascript:void(0);" class="dropdown-toggle"
                                        data-toggle="dropdown" role="button" aria-haspopup="true"
                                        aria-expanded="false"><i class="zmdi zmdi-more-vert"></i></a>
                                        <ul class="dropdown-menu pull-right">
                                            <li><a href="javascript:void(0);">Action</a></li>
                                            <li><a href="javascript:void(0);">Another action</a></li>
                                            <li><a href="javascript:void(0);">Something else here</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div class="body">
                                <p><span class="label label-success">3 Done</span>
                                    <span class="label label-default">2 Pending</span>
                                </p>
                                <div class="table-responsive">
                                    <table class="table table-striped">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Name</th>
                                                <th>ADDRESS</th>
                                                <th>START DATE</th>
                                                <th>END DATE</th>
                                                <th>PRIORITY</th>
                                                <th>PROGRESS</th>
                                                <th>STATUS</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td><img src="assets/images/xs/avatar3.jpg"
                                                    class="rounded-circle width30 m-r-15"
                                                    alt="profile-image" /><span>John</span></td>
                                                <td><span class="text-info">70 Bowman St. South Windsor, CT 06074</span>
                                                </td>
                                                <td>Sept 13, 2017</td>
                                                <td>Sept 16, 2017</td>
                                                <td><span class="label label-warning">MEDIUM</span></td>
                                                <td>
                                                    <div class="progress m-b-0 m-t-10">
                                                        <div class="progress-bar l-amber" role="progressbar"
                                                            aria-valuenow="40" aria-valuemin="0" aria-valuemax="100"
                                                            style={{ width: "40%" }}> <span class="sr-only">40% Complete</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td><span class="label label-success">Done</span></td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td><img src="assets/images/xs/avatar1.jpg"
                                                    class="rounded-circle width30 m-r-15" alt="profile-image" /><span>Jack
                                                    Bird</span></td>
                                                <td><span class="text-info">123 6th St. Melbourne, FL 32904</span></td>
                                                <td>Sept 13, 2017</td>
                                                <td>Sept 22, 2017</td>
                                                <td><span class="label label-warning">MEDIUM</span></td>
                                                <td>
                                                    <div class="progress m-b-0 m-t-10">
                                                        <div class="progress-bar l-blue" role="progressbar"
                                                            aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                                                            style={{ width: "100%" }}> <span class="sr-only">100% Complete</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td><span class="label label-default">Pending</span></td>
                                            </tr>
                                            <tr>
                                                <td>3</td>
                                                <td><img src="assets/images/xs/avatar4.jpg"
                                                    class="rounded-circle width30 m-r-15" alt="profile-image" /><span>Dean
                                                    Otto</span></td>
                                                <td><span class="text-info">123 6th St. Melbourne, FL 32904</span></td>
                                                <td>Sept 13, 2017</td>
                                                <td>Sept 23, 2017</td>
                                                <td><span class="label label-warning">MEDIUM</span></td>
                                                <td>
                                                    <div class="progress m-b-0 m-t-10">
                                                        <div class="progress-bar l-green" role="progressbar"
                                                            aria-valuenow="15" aria-valuemin="0" aria-valuemax="100"
                                                            style={{ width: "15%" }}> <span class="sr-only">15% Complete</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td><span class="label label-success">Done</span></td>
                                            </tr>
                                            <tr>
                                                <td>4</td>
                                                <td><img src="assets/images/xs/avatar2.jpg"
                                                    class="rounded-circle width30 m-r-15" alt="profile-image" /><span>Jack
                                                    Bird</span></td>
                                                <td><span class="text-info">4 Shirley Ave. West Chicago, IL 60185</span>
                                                </td>
                                                <td>Sept 17, 2017</td>
                                                <td>Sept 16, 2017</td>
                                                <td><span class="label label-success">LOW</span></td>
                                                <td>
                                                    <div class="progress m-b-0 m-t-10">
                                                        <div class="progress-bar l-blue" role="progressbar"
                                                            aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"
                                                            style={{ width: "100%" }}> <span class="sr-only">100% Complete</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td><span class="label label-default">Pending</span></td>
                                            </tr>
                                            <tr>
                                                <td>5</td>
                                                <td><img src="assets/images/xs/avatar5.jpg"
                                                    class="rounded-circle width30 m-r-15"
                                                    alt="profile-image" /><span>Hughe L.</span></td>
                                                <td><span class="text-info">4 Shirley Ave. West Chicago, IL 60185</span>
                                                </td>
                                                <td>Sept 18, 2017</td>
                                                <td>Sept 18, 2017</td>
                                                <td><span class="label label-danger">HIGH</span></td>
                                                <td>
                                                    <div class="progress m-b-0 m-t-10">
                                                        <div class="progress-bar l-coral" role="progressbar"
                                                            aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"
                                                            style={{ width: "85%" }}> <span class="sr-only">85% Complete</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td><span class="label label-success">Admit</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row clearfix">
                    <div class="col-lg-4 col-md-6 col-sm-12 ">
                        <div class="card widget-reports">
                            <div class="header">
                                <h2>Reports</h2>
                                <ul class="header-dropdown m-r--5">
                                    <li class="dropdown"> <a href="javascript:void(0);" class="dropdown-toggle"
                                        data-toggle="dropdown" role="button" aria-haspopup="true"
                                        aria-expanded="false"><i class="zmdi zmdi-more-vert"></i></a>
                                        <ul class="dropdown-menu pull-right">
                                            <li><a href="javascript:void(0);">Action</a></li>
                                            <li><a href="javascript:void(0);">Another action</a></li>
                                            <li><a href="javascript:void(0);">Something else here</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div class="body">
                                <div class="table-responsive">
                                    <table class="table table-hover">
                                        <thead>
                                            <tr>
                                                <th>Profile</th>
                                                <th>First Name</th>
                                                <th>Last Name</th>
                                                <th>Charts</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td><img src="assets/images/xs/avatar1.jpg" class="rounded-circle avatar"
                                                    alt="profile-image" /></td>
                                                <td>Dean Otto</td>
                                                <td>Dean Otto</td>
                                                <td><span class="sparkbar">5,8,6,3,5,9,2,9,4,3</span></td>
                                            </tr>
                                            <tr>
                                                <td><img src="assets/images/xs/avatar2.jpg" class="rounded-circle avatar"
                                                    alt="profile-image" /></td>
                                                <td>K. Thornton</td>
                                                <td>Dean Otto</td>
                                                <td><span class="sparkbar">10,8,9,3,5,8,5,6,5,4</span></td>
                                            </tr>
                                            <tr>
                                                <td><img src="assets/images/xs/avatar3.jpg" class="rounded-circle avatar"
                                                    alt="profile-image" /></td>
                                                <td>Kane D.</td>
                                                <td>Dean Otto</td>
                                                <td><span class="sparkbar">7,5,9,3,5,2,5,3,5,7</span></td>
                                            </tr>
                                            <tr>
                                                <td><img src="assets/images/xs/avatar4.jpg" class="rounded-circle avatar"
                                                    alt="profile-image" /></td>
                                                <td>Jack Bird</td>
                                                <td>Dean Otto</td>
                                                <td><span class="sparkbar">10,8,1,3,3,8,7,7,5,6</span></td>
                                            </tr>
                                            <tr>
                                                <td><img src="assets/images/xs/avatar5.jpg" class="rounded-circle avatar"
                                                    alt="profile-image" /></td>
                                                <td>Hughe L.</td>
                                                <td>Dean Otto</td>
                                                <td><span class="sparkbar">2,8,9,8,5,1,5,6,9,1</span></td>
                                            </tr>
                                            <tr>
                                                <td><img src="assets/images/xs/avatar1.jpg" class="rounded-circle avatar"
                                                    alt="profile-image" /></td>
                                                <td>Jack Bird</td>
                                                <td>Dean Otto</td>
                                                <td><span class="sparkbar">1,8,2,3,9,8,5,5,9,4</span></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-7 col-md-12 col-sm-12 ">
                        <div class="card product-report">
                            <div class="header">
                                <h2>Product Report</h2>
                                <ul class="header-dropdown m-r--5">
                                    <li class="dropdown"> <a href="javascript:void(0);" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"> <i class="zmdi zmdi-more-vert"></i> </a>
                                        <ul class="dropdown-menu pull-right">
                                            <li><a href="javascript:void(0);">Action</a></li>
                                            <li><a href="javascript:void(0);">Another action</a></li>
                                            <li><a href="javascript:void(0);">Something else here</a></li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                            <div class="body">
                                <div class="row clearfix m-b-25">
                                    <div class="col-lg-4 col-md-4 col-sm-4">
                                        <div class="icon"><i class="zmdi zmdi-chart-donut"></i></div>
                                        <div class="col-in">
                                            <h3 class="counter m-b-0">$4516</h3>
                                            <small class="text-muted m-t-0">Sales Report</small> </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4">
                                        <div class="icon"><i class="zmdi zmdi-chart"></i></div>
                                        <div class="col-in">
                                            <h3 class="counter m-b-0">$6481</h3>
                                            <small class="text-muted m-t-0">Annual Revenue </small> </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-sm-4">
                                        <div class="icon"><i class="zmdi zmdi-card"></i></div>
                                        <div class="col-in">
                                            <h3 class="counter m-b-0">$3915</h3>
                                            <small class="text-muted m-t-0">Monthly Revenue</small> </div>
                                    </div>
                                </div>
                                <div id="area_chart" class="graph"></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


        );
    }
}