import React, { PureComponent } from 'react'
import SignaturePad from 'react-signature-canvas'

// import styles from './styles.module.css'

export default class Signature extends PureComponent {
    state = { trimmedDataURL: null }
    sigPad = {}
    clear = () => {
        this.sigPad.clear()
    }
    trim = () => {
        this.setState({
            trimmedDataURL: this.sigPad.getTrimmedCanvas()
                .toDataURL('image/png')
        })
    }
    render() {
        let { trimmedDataURL } = this.state
        return <div>
            <div>
                <SignaturePad canvasProps={{ className: "sigPad" }}
                    ref={(ref) => { this.sigPad = ref }} />
            </div>
            <div>
                <button className={"btn btn-light"} onClick={this.clear}>
                    Clear
        </button>
                <button className={"btn btn-light"} onClick={this.trim}>
                    Trim
        </button>
            </div>
            {/* {trimmedDataURL
                ? <img className={styles.sigImage}
                    src={trimmedDataURL} />
                : null} */}
        </div>
    }
}
