import React, { useEffect, useState } from 'react';
import { PureComponent } from 'react';
import { useLocation } from "react-router-dom";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import helper from '../utils/helper';
import { protectedResources } from "./../authConfig";
import { MsalContext, useMsal } from "@azure/msal-react";

//require("dotenv").config();
const API_BASE_URL = helper.getAPIBaseURI();

const NewAccessionsPDFContent = () => {
    const [pdf, setPdf] = useState(undefined);
    const { instance } = useMsal();

    const query = new URLSearchParams(useLocation().search);
    const orderId = query.get("orderId");

    const LoadPdf = async () => {
        console.log("orderId: ", orderId);

        let user = localStorage.getItem('user');

        if (user) {
            user = JSON.parse(user);

            const { accessToken } = await instance.acquireTokenSilent({
                scopes: protectedResources.apiOrders.scopes,
                account: this.context.accounts[0]
            });

            const options = {
                method: 'GET',
                url: `${API_BASE_URL}/orders/getNewAccessionPDF?orderId=${orderId}`,
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
            };
            const pdf = await axios(options);

            setPdf(pdf.data.signedUrl);
        }
        else {
            window.location.href = '/';
        }
    }

    useEffect(() => {

        LoadPdf();

    }, [instance]);


    return (<>
        <object
            data={`${pdf}`}
            type="application/pdf"
            width="100%"
            height="678"
        >

            <iframe
                src={`${pdf}`}
                width="100%"
                height="678"
            >
                <p>This browser does not support PDF!</p>
            </iframe>

        </object>
    </>);

}

export default NewAccessionsPDFContent;
