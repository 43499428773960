import React, { PureComponent } from "react";

import PropTypes from 'prop-types';
import $ from 'jquery';
//import DatePicker from 'react-datepicker';

export default class HBOCFamilyHistoryInfoForm extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {

        }

    }

    //onChangeBillingDoB = date => this.setState({ billinfo_dob: date }); 

    render() {

        const noPreviousPersonalCancerHistoryClick = () => {
            var pfHistory_noCancer = document.getElementById("pfHistory_noCancer");
            
            if (pfHistory_noCancer.checked == true) {
                
                $('#personalHistoryArea').find('input').map((i, elem) => {
                    $(elem).prop('checked', false);
                    $(elem).prop('disabled', true);
                }) 
                previousCancerHistoryClick(); 
                SomaticMutationProfClick(); 
                PrevGermltestingClick(); 
                document.getElementById("pfHistory_BrOvPanAnyAge2").checked = true;
                pfHistory_BrOvPanAnyAge2Click();

                document.getElementById("pfHistory_ColorUterineAnyAge2").checked = true;
                pfHistory_ColorUterineAnyAge2Click();           
            }else {
                $('#personalHistoryArea').find('input').map((i, elem) => {
                    $(elem).prop('checked', false);
                    $(elem).prop('disabled', false);
                }) 
            }
        };

        const previousCancerHistoryClick = () => {

            var pfHistory_previousCancer = document.getElementById("pfHistory_previousCancer");

            if (pfHistory_previousCancer.checked == true) {
                $("#pfHistory_prevCanDefine_div").removeClass(" hideFormContent");
                $("#pfHistory_prevCanDefine_div").addClass(" showFormContent");
                $("#pfHistory_prevCanDefine").addClass(" required");
            } else {
                $("#pfHistory_prevCanDefine_div").addClass(" hideFormContent");
                $("#pfHistory_prevCanDefine_div").removeClass(" showFormContent");
                $("#pfHistory_prevCanDefine").removeClass(" required");
            }
        };

        const SomaticMutationProfClick = () => {

            var pfHistory_SomaticMutationProf = document.getElementById("pfHistory_SomaticMutationProf");

            if (pfHistory_SomaticMutationProf.checked == true) {

                $("#pfHistory_SoMutaProfDefine_div").removeClass(" hideFormContent");
                $("#pfHistory_SoMutaProfDefine_div").addClass(" showFormContent");
                $("#pfHistory_SoMutaProfDefine").addClass(" required");
            } else {
                $("#pfHistory_SoMutaProfDefine_div").removeClass(" showFormContent");
                $("#pfHistory_SoMutaProfDefine_div").addClass(" hideFormContent");
                $("#pfHistory_SoMutaProfDefine").removeClass(" required");
            }
        };

        const PrevGermltestingClick = () => {

            var pfHistory_PrevGermltesting = document.getElementById("pfHistory_PrevGermltesting");

            if (pfHistory_PrevGermltesting.checked == true) {
                $("#pfHistory_PrevGermltestDefine_div").removeClass(" hideFormContent");
                $("#pfHistory_PrevGermltestDefine_div").addClass(" showFormContent");
                $("#pfHistory_PrevGermltestDefine").addClass(" required");
            } else {
                $("#pfHistory_PrevGermltestDefine_div").removeClass(" showFormContent");
                $("#pfHistory_PrevGermltestDefine_div").addClass(" hideFormContent");
                $("#pfHistory_PrevGermltestDefine").removeClass(" required");
            }
        };

        const noPreviousFamilyCancerHistoryClick = () => {
            
            var pfHistory_yesCancer = document.getElementById("pfHistory_yesCancer");           
           
        };

        const pfHistory_BrOvPanAnyAgeClick = () => {

            var pfHistory_BrOvPanAnyAge = document.getElementById("pfHistory_BrOvPanAnyAge");

            if (pfHistory_BrOvPanAnyAge.checked == true) {
                $("#pfHistory_BrOvPanAnyAge_Div").removeClass(" hideFormContent");
                $("#pfHistory_BrOvPanAnyAge_Div").addClass(" showFormContent");
            }
        };

        const pfHistory_BrOvPanAnyAge2Click = () => {

            var pfHistory_BrOvPanAnyAge2 = document.getElementById("pfHistory_BrOvPanAnyAge2");

            if (pfHistory_BrOvPanAnyAge2.checked == true) {
                $("#pfHistory_BrOvPanAnyAge_Div").addClass(" hideFormContent");
                $("#pfHistory_BrOvPanAnyAge_Div").removeClass(" showFormContent");
            }
        };

        //Colorectal or uterine cancer at 64 or younger
        const pfHistory_ColorUterineAnyAgeClick = () => {

            var pfHistory_ColorUterineAnyAge = document.getElementById("pfHistory_ColorUterineAnyAge");

            if (pfHistory_ColorUterineAnyAge.checked == true) {
                $("#pfHistory_ColorUterineAnyAge_Div").removeClass(" hideFormContent");
                $("#pfHistory_ColorUterineAnyAge_Div").addClass(" showFormContent");
            }
        };

        const pfHistory_ColorUterineAnyAge2Click = () => {

            var pfHistory_ColorUterineAnyAge2 = document.getElementById("pfHistory_ColorUterineAnyAge2");

            if (pfHistory_ColorUterineAnyAge2.checked == true) {
                $("#pfHistory_ColorUterineAnyAge_Div").addClass(" hideFormContent");
                $("#pfHistory_ColorUterineAnyAge_Div").removeClass(" showFormContent");
            }
        };

        //Breast, ovarian or pancreative cancer        
        const pfHistory_FBrOvPanAnyAgeClick = () => {

            var pfHistory_FBrOvPanAnyAge = document.getElementById("pfHistory_FBrOvPanAnyAge");

            if (pfHistory_FBrOvPanAnyAge.checked == true) {
                $("#pfHistory_FBrOvPanAnyAge_Div").removeClass(" hideFormContent");
                $("#pfHistory_FBrOvPanAnyAge_Div").addClass(" showFormContent");
            }
        };

        const pfHistory_FBrOvPanAnyAge2Click = () => {

            var pfHistory_FBrOvPanAnyAge2 = document.getElementById("pfHistory_FBrOvPanAnyAge2");

            if (pfHistory_FBrOvPanAnyAge2.checked == true) {
                $("#pfHistory_FBrOvPanAnyAge_Div").addClass(" hideFormContent");
                $("#pfHistory_FBrOvPanAnyAge_Div").removeClass(" showFormContent");
            }
        };

        //Two breast cancers (bilateral) in one relative        
        const pfHistory_FTwoBreasCanAgeClick = () => {

            var pfHistory_FTwoBreasCanAge = document.getElementById("pfHistory_FTwoBreasCanAge");

            if (pfHistory_FTwoBreasCanAge.checked == true) {
                $("#pfHistory_FTwoBreasCanAge_Div").removeClass(" hideFormContent");
                $("#pfHistory_FTwoBreasCanAge_Div").addClass(" showFormContent");
            }
        };

        const pfHistory_FTwoBreasCanAge2Click = () => {

            var pfHistory_FTwoBreasCanAge2 = document.getElementById("pfHistory_FTwoBreasCanAge2");

            if (pfHistory_FTwoBreasCanAge2.checked == true) {
                $("#pfHistory_FTwoBreasCanAge_Div").addClass(" hideFormContent");
                $("#pfHistory_FTwoBreasCanAge_Div").removeClass(" showFormContent");
            }
        };

        //Three breast cancers in relatives on the same 
        const pfHistory_FThrBreasCanAgeClick = () => {

            var pfHistory_FThrBreasCanAge = document.getElementById("pfHistory_FThrBreasCanAge");

            if (pfHistory_FThrBreasCanAge.checked == true) {
                $("#pfHistory_FThrBreasCanAge_Div").removeClass(" hideFormContent");
                $("#pfHistory_FThrBreasCanAge_Div").addClass(" showFormContent");
            }
        };

        const pfHistory_FThrBreasCanAge2Click = () => {

            var pfHistory_FThrBreasCanAge2 = document.getElementById("pfHistory_FThrBreasCanAge2");

            if (pfHistory_FThrBreasCanAge2.checked == true) {
                $("#pfHistory_FThrBreasCanAge_Div").addClass(" hideFormContent");
                $("#pfHistory_FThrBreasCanAge_Div").removeClass(" showFormContent");
            }
        };

        //Ovarian cancer at any age
        const pfHistory_OvarianCanAgeClick = () => {

            var pfHistory_OvarianCanAge = document.getElementById("pfHistory_OvarianCanAge");

            if (pfHistory_OvarianCanAge.checked == true) {
                $("#pfHistory_OvarianCanAge_Div").removeClass(" hideFormContent");
                $("#pfHistory_OvarianCanAge_Div").addClass(" showFormContent");
            }
        };

        const pfHistory_OvarianCanAge2Click = () => {

            var pfHistory_OvarianCanAge2 = document.getElementById("pfHistory_OvarianCanAge2");

            if (pfHistory_OvarianCanAge2.checked == true) {
                $("#pfHistory_OvarianCanAge_Div").addClass(" hideFormContent");
                $("#pfHistory_OvarianCanAge_Div").removeClass(" showFormContent");
            }
        };

        //Pancreatic cancer at any age
        const pfHistory_PancreaticCanAgeClick = () => {

            var pfHistory_PancreaticCanAge = document.getElementById("pfHistory_PancreaticCanAge");

            if (pfHistory_PancreaticCanAge.checked == true) {
                $("#pfHistory_PancreaticCanAge_Div").removeClass(" hideFormContent");
                $("#pfHistory_PancreaticCanAge_Div").addClass(" showFormContent");
            }
        };

        const pfHistory_PancreaticCanAge2Click = () => {

            var pfHistory_PancreaticCanAge2 = document.getElementById("pfHistory_PancreaticCanAge2");

            if (pfHistory_PancreaticCanAge2.checked == true) {
                $("#pfHistory_PancreaticCanAge_Div").addClass(" hideFormContent");
                $("#pfHistory_PancreaticCanAge_Div").removeClass(" showFormContent");
            }
        };

        //Male breast cancer at any age
        const pfHistory_MaleBreastCanAgeClick = () => {

            var pfHistory_MaleBreastCanAge = document.getElementById("pfHistory_MaleBreastCanAge");

            if (pfHistory_MaleBreastCanAge.checked == true) {
                $("#pfHistory_MaleBreastCanAge_Div").removeClass(" hideFormContent");
                $("#pfHistory_MaleBreastCanAge_Div").addClass(" showFormContent");
            }
        };

        const pfHistory_MaleBreastCanAge2Click = () => {

            var pfHistory_MaleBreastCanAge2 = document.getElementById("pfHistory_MaleBreastCanAge2");

            if (pfHistory_MaleBreastCanAge2.checked == true) {
                $("#pfHistory_MaleBreastCanAge_Div").addClass(" hideFormContent");
                $("#pfHistory_MaleBreastCanAge_Div").removeClass(" showFormContent");
            }
        };

        //Metastatic prostate cancer at any age
        const pfHistory_MetaProstateCanAgeClick = () => {

            var pfHistory_MetaProstateCanAge = document.getElementById("pfHistory_MetaProstateCanAge");

            if (pfHistory_MetaProstateCanAge.checked == true) {
                $("#pfHistory_MetaProstateCanAge_Div").removeClass(" hideFormContent");
                $("#pfHistory_MetaProstateCanAge_Div").addClass(" showFormContent");
            }
        };

        const pfHistory_MetaProstateCanAge2Click = () => {

            var pfHistory_MetaProstateCanAge2 = document.getElementById("pfHistory_MetaProstateCanAge2");

            if (pfHistory_MetaProstateCanAge2.checked == true) {
                $("#pfHistory_MetaProstateCanAge_Div").addClass(" hideFormContent");
                $("#pfHistory_MetaProstateCanAge_Div").removeClass(" showFormContent");
            }
        };

        //Colon cancer at 49 or younger
        const pfHistory_ColorCanAgeClick = () => {

            var pfHistory_ColorCanAge = document.getElementById("pfHistory_ColorCanAge");

            if (pfHistory_ColorCanAge.checked == true) {
                $("#pfHistory_ColorCanAge_Div").removeClass(" hideFormContent");
                $("#pfHistory_ColorCanAge_Div").addClass(" showFormContent");
            }
        };

        const pfHistory_ColorCanAge2Click = () => {

            var pfHistory_ColorCanAge2 = document.getElementById("pfHistory_ColorCanAge2");

            if (pfHistory_ColorCanAge2.checked == true) {
                $("#pfHistory_ColorCanAge_Div").addClass(" hideFormContent");
                $("#pfHistory_ColorCanAge_Div").removeClass(" showFormContent");
            }
        };

        //Uterine cancer at 49 or younger
        const pfHistory_UterineCanAgeClick = () => {

            var pfHistory_UterineCanAge = document.getElementById("pfHistory_UterineCanAge");

            if (pfHistory_UterineCanAge.checked == true) {
                $("#pfHistory_UterineCanAge_Div").removeClass(" hideFormContent");
                $("#pfHistory_UterineCanAge_Div").addClass(" showFormContent");
            }
        };

        const pfHistory_UterineCanAge2Click = () => {

            var pfHistory_UterineCanAge2 = document.getElementById("pfHistory_UterineCanAge2");

            if (pfHistory_UterineCanAge2.checked == true) {
                $("#pfHistory_UterineCanAge_Div").addClass(" hideFormContent");
                $("#pfHistory_UterineCanAge_Div").removeClass(" showFormContent");
            }
        };

        //Ashkenazi Jewish ancestry with breast cancer
        const pfHistory_AshJewishAncesCanAgeClick = () => {

            var pfHistory_AshJewishAncesCanAge = document.getElementById("pfHistory_AshJewishAncesCanAge");

            if (pfHistory_AshJewishAncesCanAge.checked == true) {
                $("#pfHistory_AshJewishAncesCanAge_Div").removeClass(" hideFormContent");
                $("#pfHistory_AshJewishAncesCanAge_Div").addClass(" showFormContent");
            }
        };

        const pfHistory_AshJewishAncesCanAge2Click = () => {

            var pfHistory_AshJewishAncesCanAge2 = document.getElementById("pfHistory_AshJewishAncesCanAge2");

            if (pfHistory_AshJewishAncesCanAge2.checked == true) {
                $("#pfHistory_AshJewishAncesCanAge_Div").addClass(" hideFormContent");
                $("#pfHistory_AshJewishAncesCanAge_Div").removeClass(" showFormContent");
            }
        };

        //Does the patient have a family history of other cancers?
        const pfHistory_otherCanClick = () => {

            var pfHistory_otherCan = document.getElementById("pfHistory_otherCan");

            if (pfHistory_otherCan.checked == true) {
                $("#pfHistory_otherCan_Div").removeClass(" hideFormContent");
                $("#pfHistory_otherCan_Div").addClass(" showFormContent");
            }
        };

        const pfHistory_otherCan2Click = () => {

            var pfHistory_otherCan2 = document.getElementById("pfHistory_otherCan2");

            if (pfHistory_otherCan2.checked == true) {
                $("#pfHistory_otherCan_Div").addClass(" hideFormContent");
                $("#pfHistory_otherCan_Div").removeClass(" showFormContent");
            }
        };

        //Has anyone in the patient's family had genetic testing for hereditary cancer?
        const pfHistory_geHerediraryCanClick = () => {

            var pfHistory_geHerediraryCan = document.getElementById("pfHistory_geHerediraryCan");

            if (pfHistory_geHerediraryCan.checked == true) {
                $("#pfHistory_geHerediraryCan_Div").removeClass(" hideFormContent");
                $("#pfHistory_geHerediraryCan_Div").addClass(" showFormContent");
            }
        };

        const pfHistory_geHerediraryCan2Click = () => {

            var pfHistory_geHerediraryCan2 = document.getElementById("pfHistory_geHerediraryCan2");

            if (pfHistory_geHerediraryCan2.checked == true) {
                $("#pfHistory_geHerediraryCan_Div").addClass(" hideFormContent");
                $("#pfHistory_geHerediraryCan_Div").removeClass(" showFormContent");
            }
        };

        //btnpfHistoryAddFamilyHistory 
        const btnpfHistoryAddFamilyHistory = () => {

            const pfHistory_AnyAge_dataid = $("#pfHistory_AnyAge_dataid").val();

            const _showResult = pfHistory_AnyAge_dataid + '_showResult';
            const _pfHistory_AnyAgeValues = pfHistory_AnyAge_dataid + '_pfHistory_AnyAgeValues';
            const _pfHistory_AnyAgeValuesJustShow = pfHistory_AnyAge_dataid + '_pfHistory_AnyAgeValuesJustShow';

            const pfHistory_AnyAge_relative = $("#pfHistory_AnyAge_relative").val();
            const pfHistory_AnyAge_age = $("#pfHistory_AnyAge_age").val();

            const strr = pfHistory_AnyAge_age.replace(/\s+/g, " ").trim();
            $("#pfHistory_AnyAge_age").val(strr);

            //take existing value
            const _pfHistory_AnyAgeValuesExisting = $("#" + _pfHistory_AnyAgeValues).val();
            const _pfHistory_AnyAgeValuesJustShowExisting = $("#" + _pfHistory_AnyAgeValuesJustShow).val();

            let dataSide = '';
            let dataSideJustShow = '';

            let dataValueanswers = [];
            let dataValueanswer = {
                "side": "",
                "relative": "",
                "age": ""
            };

            let sideValue = '';


            if ($("#pfHistory_AnyAge_Mothside").is(":not(:checked)") && $("#pfHistory_AnyAge_Fathside").is(":not(:checked)")) {

                alert("please select family side");
            } else if (pfHistory_AnyAge_relative.length < 1) {
                alert("please select family relative");

            } else if (strr.length < 1) {
                alert("please enter age");
                $('#pfHistory_AnyAge_age').focus();
            } else {

                if ($("#pfHistory_AnyAge_Mothside").is(":not(:checked)")) {
                    sideValue = 'Father';
                } else {
                    sideValue = 'Mother';
                }

                if (_pfHistory_AnyAgeValuesExisting.length < 1) {
                    dataSide = sideValue + "," + pfHistory_AnyAge_relative + "," + strr;
                    dataSideJustShow = "side: " + sideValue + " relative: " + pfHistory_AnyAge_relative + " age: " + strr;
                } else {
                    dataSide = _pfHistory_AnyAgeValuesExisting + "," + sideValue + "," + pfHistory_AnyAge_relative + "," + strr;
                    dataSideJustShow = _pfHistory_AnyAgeValuesJustShowExisting + ", side: " + sideValue + " relative: " + pfHistory_AnyAge_relative + " age: " + strr;
                }

                dataValueanswer = {
                    side: sideValue,
                    relative: pfHistory_AnyAge_relative,
                    age: strr
                };

                dataValueanswers.push(dataValueanswer);

                //alert(dataValueanswer);
                //set results
                $("#" + _showResult).html(dataSideJustShow);
                $("#" + _pfHistory_AnyAgeValuesJustShow).val(dataSideJustShow);
                $("#" + _pfHistory_AnyAgeValues).val(dataSide);

                //reset fields
                document.getElementById("pfHistory_AnyAge_Mothside").checked = false;
                document.getElementById("pfHistory_AnyAge_Fathside").checked = false;
                $("#pfHistory_AnyAge_relative").val("");
                $('#pfHistory_AnyAge_age').val("");

                $("#defaultModal .mclose").click();
            }

        };

        const btnpfHistoryAddFamilyHistoryClick = (dataid) => {
            //alert(dataid);
            $("#pfHistory_AnyAge_dataid").val(dataid);
        };



        return (
            <>
                <div class="panel-body">
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="form-group custom-margin-bottom">
                                <h4>Personal History</h4>
                                <label class="form-label" for="address">Personal History (check all that apply)</label>
                                <div class="demo-checkbox" id="hboc1_q01">
                                    <input type="checkbox" id="pfHistory_noCancer" name="pfHistory_noCancer" class="filled-in" onClick={() => {
                                        noPreviousPersonalCancerHistoryClick()
                                    }} />
                                    <label for="pfHistory_noCancer">No personal history of cancer &nbsp; </label>
                                    {/* hboc1_q02 */}
                                    {/* <input type="checkbox" id="pfHistory_yesCancer" name="pfHistory_yesCancer" class="filled-in" />
                                    <label for="pfHistory_yesCancer">Family history of breast, ovarian or endometrial cancer &nbsp; </label> */}
                                </div>
                            </div>
                        </div>

                        <div class="col-md-12">
                            <div class="form-group " id="personalHistoryArea">
                                <div class="row clearfix">
                                    <div class="col-md-12">
                                        <div class="form-group custom-margin-bottom custom-margin-top">                                
                                            <label class="form-label" for="address">Does the Patient have a history of:</label>
                                            <div class="demo-radio-button" id="hboc1_q06">
                                                <div class="row clearfix custom-min-height2">
                                                    <div class="col-md-5">
                                                        <label>Breast, ovarian or pancreative cancer at any age?</label>
                                                        <input type="radio" id="pfHistory_BrOvPanAnyAge" name="pfHistory_BrOvPanAnyAge_" value="yes" class="form-control" onClick={() => {
                                                            pfHistory_BrOvPanAnyAgeClick()
                                                        }} />
                                                        <label class="form-label" for="pfHistory_BrOvPanAnyAge">Yes</label>
                                                        <input type="radio" id="pfHistory_BrOvPanAnyAge2" name="pfHistory_BrOvPanAnyAge_" value="no" class="form-control" onClick={() => {
                                                            pfHistory_BrOvPanAnyAge2Click()
                                                        }} />
                                                        <label class="form-label" for="pfHistory_BrOvPanAnyAge2">No</label>
                                                    </div>
                                                    <div class="col-md-7">
                                                        <div className=" hideFormContent" id="pfHistory_BrOvPanAnyAge_Div">
                                                            <div class="row clearfix">
                                                                <div class="col-md-2 text-right">
                                                                    <label>Which cancer?</label>
                                                                </div>
                                                                <div class="col-md-5">
                                                                    <div class="demo-checkbox" id="hboc1_q06_1">
                                                                        <input type="checkbox" id="pfHistory_BrOvPanAnyAgeCan1" name="pfHistory_BrOvPanAnyAgeCan1" data-val="Breast" class="filled-in" />
                                                                        <label for="pfHistory_BrOvPanAnyAgeCan1">Breast &nbsp; </label> 
                                                                        <input type="checkbox" id="pfHistory_BrOvPanAnyAgeCan2" name="pfHistory_BrOvPanAnyAgeCan2" data-val="Ovarian" class="filled-in" />
                                                                        <label for="pfHistory_BrOvPanAnyAgeCan2">Ovarian &nbsp; </label>
                                                                        <input type="checkbox" id="pfHistory_BrOvPanAnyAgeCan3" name="pfHistory_BrOvPanAnyAgeCan3" data-val="Pancreative" class="filled-in" />
                                                                        <label for="pfHistory_BrOvPanAnyAgeCan3">Pancreative &nbsp; </label>                                                                       
                                                                    </div>
                                                                    {/* <div class="form-line">
                                                                        <input type="text" id="pfHistory_BrOvPanAnyAgeCan" class="form-control " placeholder="Which cancer?" />
                                                                    </div> */}
                                                                </div>
                                                                <div class="col-md-3 text-right">
                                                                    <label>Age at diagnosis</label>
                                                                </div>
                                                                <div class="col-md-2" id="hboc1_q06_2">
                                                                    <div class="form-line">
                                                                        <input type="text" id="pfHistory_BrOvPanAnyAgeInfo" class="form-control " placeholder="Age" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="demo-radio-button" id="hboc1_q07">
                                                <div class="row clearfix custom-min-height2">
                                                    <div class="col-md-5">
                                                        <label>Colorectal or uterine cancer at 64 or younger?</label>
                                                        <input type="radio" id="pfHistory_ColorUterineAnyAge" name="pfHistory_ColorUterineAnyAge_" value="yes" class="form-control" onClick={() => {
                                                            pfHistory_ColorUterineAnyAgeClick()
                                                        }} />
                                                        <label class="form-label" for="pfHistory_ColorUterineAnyAge">Yes</label>
                                                        <input type="radio" id="pfHistory_ColorUterineAnyAge2" name="pfHistory_ColorUterineAnyAge_" value="no" class="form-control" onClick={() => {
                                                            pfHistory_ColorUterineAnyAge2Click()
                                                        }} />
                                                        <label class="form-label" for="pfHistory_ColorUterineAnyAge2">No</label>
                                                    </div>
                                                    <div class="col-md-7">
                                                        <div className=" hideFormContent" id="pfHistory_ColorUterineAnyAge_Div">
                                                            <div class="row clearfix">
                                                                <div class="col-md-2 text-right">
                                                                    <label>Which cancer?</label>
                                                                </div>
                                                                <div class="col-md-5">
                                                                    <div class="demo-checkbox" id="hboc1_q07_1">
                                                                        <input type="checkbox" id="pfHistory_ColorUterineAnyAgeCan1" name="pfHistory_ColorUterineAnyAgeCan1" data-val="Colorectal" class="filled-in" />
                                                                        <label for="pfHistory_ColorUterineAnyAgeCan1">Colorectal &nbsp; </label> 
                                                                        <input type="checkbox" id="pfHistory_ColorUterineAnyAgeCan2" name="pfHistory_ColorUterineAnyAgeCan2" data-val="Uterine" class="filled-in" />
                                                                        <label for="pfHistory_ColorUterineAnyAgeCan2">Uterine &nbsp; </label>                                                                        
                                                                    </div>
                                                                    {/* <div class="form-line">
                                                                        <input type="text" id="pfHistory_ColorUterineAnyAgeCan" class="form-control " placeholder="Which cancer?" />
                                                                    </div> */}
                                                                </div>
                                                                <div class="col-md-3 text-right">
                                                                    <label>Age at diagnosis</label>
                                                                </div>
                                                                <div class="col-md-2">
                                                                    <div class="form-line">
                                                                        <input type="text" id="pfHistory_ColorUterineAnyAgeInfo" class="form-control " placeholder="Age" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>                               
                                <div class="demo-checkbox" id="hboc1_q03">
                                    <div class="row clearfix custom-min-height">
                                        <div class="col-md-4">
                                            <input type="checkbox" id="pfHistory_previousCancer" name="pfHistory_previousCancer" class="filled-in" onClick={() => {
                                                previousCancerHistoryClick()
                                            }} />
                                            <label for="pfHistory_previousCancer">Previous cancer diagnosis? If yes, Define:</label>
                                        </div>
                                        <div class="col-md-8">
                                            <div class="form-line hideFormContent" id="pfHistory_prevCanDefine_div">
                                                <input type="text" id="pfHistory_prevCanDefine" class="form-control " placeholder="Define" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="demo-checkbox" id="hboc1_q04">
                                    <div class="row clearfix custom-min-height">
                                        <div class="col-md-5">
                                            <input type="checkbox" id="pfHistory_SomaticMutationProf" name="pfHistory_SomaticMutationProf" class="filled-in" onClick={() => {
                                                SomaticMutationProfClick()
                                            }} />
                                            <label for="pfHistory_SomaticMutationProf">Somatic mutation profiling? If yes, Define: (gene, variant identified) &nbsp; </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-line hideFormContent" id="pfHistory_SoMutaProfDefine_div">
                                                <input type="text" id="pfHistory_SoMutaProfDefine" class="form-control " placeholder="Define" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="demo-checkbox" id="hboc1_q05">
                                    <div class="row clearfix custom-min-height">
                                        <div class="col-md-5">
                                            <input type="checkbox" id="pfHistory_PrevGermltesting" name="pfHistory_PrevGermltesting" class="filled-in" onClick={() => {
                                                PrevGermltestingClick()
                                            }} />
                                            <label for="pfHistory_PrevGermltesting">Previous germline testing? If yes, Define:(gene/varient detected) &nbsp; </label>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="form-line hideFormContent" id="pfHistory_PrevGermltestDefine_div">
                                                <input type="text" id="pfHistory_PrevGermltestDefine" class="form-control " placeholder="Define" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="demo-checkbox" id="hboc1_q05_01_hboc1_q05_02">
                                    <label for="">If yes, is the variant:&nbsp; </label>
                                    <input type="checkbox" data-val="Familial/Inherited or" id="pfHistory_Familial_Inherited" name="pfHistory_Familial_Inherited" class="filled-in" />
                                    <label for="pfHistory_Familial_Inherited">Familial/Inherited or &nbsp; </label>
                                    <input type="checkbox" data-val="Newly-identified/De Novo" id="pfHistory_Newly_identified" name="pfHistory_Newly_identified" class="filled-in" />
                                    <label for="pfHistory_Newly_identified">Newly-identified/De Novo &nbsp; </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="form-group custom-margin-bottom">
                                <h4>Family History</h4>
                                <div class="demo-checkbox hideFormContent" id="hboc1_q01">                                    
                                    {/* hboc1_q02 */}
                                    <input type="checkbox" id="pfHistory_yesCancer" name="pfHistory_yesCancer" class="filled-in" onClick={() => {
                                        noPreviousFamilyCancerHistoryClick()
                                    }} />
                                    <label for="pfHistory_yesCancer">Family history of breast, ovarian or endometrial cancer &nbsp; </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="familyHistoryArea">
                        

                        <div class="row clearfix hideFormContent">
                            <div class="col-md-12">
                                <p>(Close relatives include: parent, sibling, child, uncle, aunt, great uncle, great aunt, nephew, niece, grandparent, grandchild or half-sibling)</p>
                            </div>
                        </div>

                        <div class="row clearfix">
                            <div class="col-md-12">
                                <div class="form-group ">
                                    <label class="form-label hideFormContent" for="address">Does the Patient have a family history of:</label>
                                    <div class="demo-radio-button " id="hboc1_q08">
                                        <div class="row clearfix custom-min-height">
                                            <div class="col-md-4">
                                                <label>One breast cancer in one relative at any age</label>
                                            </div>
                                            <div class="col-md-2">
                                                <input type="radio" id="pfHistory_FBrOvPanAnyAge" name="pfHistory_FBrOvPanAnyAge_" value="yes" class="form-control" onClick={() => {
                                                    pfHistory_FBrOvPanAnyAgeClick()
                                                }} />
                                                <label class="form-label" for="pfHistory_FBrOvPanAnyAge">Yes</label>
                                                <input type="radio" id="pfHistory_FBrOvPanAnyAge2" name="pfHistory_FBrOvPanAnyAge_" value="no" class="form-control" onClick={() => {
                                                    pfHistory_FBrOvPanAnyAge2Click()
                                                }} />
                                                <label class="form-label" for="pfHistory_FBrOvPanAnyAge2">No</label>
                                            </div>
                                            <div class="col-md-6">
                                                <div className=" hideFormContent" id="pfHistory_FBrOvPanAnyAge_Div">
                                                    <div class="row clearfix">
                                                        <div class="col-md-3">
                                                            <button type="button" class="btn btn-default waves-effect m-r-20" data-toggle="modal" data-val="subQuestion-hboc1_q08_1" data-target="#defaultModal" onClick={() => {
                                                                btnpfHistoryAddFamilyHistoryClick("hboc1_q08_1")
                                                            }}>add Info</button>

                                                        </div>
                                                        <div class="col-md-9">
                                                            <div id="hboc1_q08_1_showResult"></div>
                                                            <input type="hidden" id="hboc1_q08_1_pfHistory_AnyAgeValuesJustShow" class="form-control " />
                                                            <input type="hidden" id="hboc1_q08_1_pfHistory_AnyAgeValues" class="form-control " />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="demo-radio-button" id="hboc1_q09">
                                        <div class="row clearfix custom-min-height">
                                            <div class="col-md-4">
                                                <label>Two breast cancers (bilateral) in one relative at any age</label>
                                            </div>
                                            <div class="col-md-2">
                                                <input type="radio" id="pfHistory_FTwoBreasCanAge" name="pfHistory_FTwoBreasCanAge_" value="yes" class="form-control" onClick={() => {
                                                    pfHistory_FTwoBreasCanAgeClick()
                                                }} />
                                                <label class="form-label" for="pfHistory_FTwoBreasCanAge">Yes</label>
                                                <input type="radio" id="pfHistory_FTwoBreasCanAge2" name="pfHistory_FTwoBreasCanAge_" value="no" class="form-control" onClick={() => {
                                                    pfHistory_FTwoBreasCanAge2Click()
                                                }} />
                                                <label class="form-label" for="pfHistory_FTwoBreasCanAge2">No</label>
                                            </div>
                                            <div class="col-md-6">
                                                <div className=" hideFormContent" id="pfHistory_FTwoBreasCanAge_Div">
                                                    <div class="row clearfix">
                                                        <div class="col-md-3">
                                                            <button type="button" class="btn btn-default waves-effect m-r-20" data-toggle="modal" data-target="#defaultModal" onClick={() => {
                                                                btnpfHistoryAddFamilyHistoryClick("hboc1_q09_1")
                                                            }}>add Info</button>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <div id="hboc1_q09_1_showResult"></div>
                                                            <input type="hidden" id="hboc1_q09_1_pfHistory_AnyAgeValuesJustShow" class="form-control " />
                                                            <input type="hidden" id="hboc1_q09_1_pfHistory_AnyAgeValues" class="form-control " />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div class="demo-radio-button" id="hboc1_q010">
                                        <div class="row clearfix custom-min-height">
                                            <div class="col-md-4">
                                                <label>Three breast cancers in relatives on the same  side of the family at any age</label>
                                            </div>
                                            <div class="col-md-2">
                                                <input type="radio" id="pfHistory_FThrBreasCanAge" name="pfHistory_FThrBreasCanAge_" value="yes" class="form-control" onClick={() => {
                                                    pfHistory_FThrBreasCanAgeClick()
                                                }} />
                                                <label class="form-label" for="pfHistory_FThrBreasCanAge">Yes</label>
                                                <input type="radio" id="pfHistory_FThrBreasCanAge2" name="pfHistory_FThrBreasCanAge_" value="no" class="form-control" onClick={() => {
                                                    pfHistory_FThrBreasCanAge2Click()
                                                }} />
                                                <label class="form-label" for="pfHistory_FThrBreasCanAge2">No</label>
                                            </div>
                                            <div class="col-md-6">
                                                <div className=" hideFormContent" id="pfHistory_FThrBreasCanAge_Div">
                                                    <div class="row clearfix">
                                                        <div class="col-md-3">
                                                            <button type="button" class="btn btn-default waves-effect m-r-20" data-toggle="modal" data-target="#defaultModal" onClick={() => {
                                                                btnpfHistoryAddFamilyHistoryClick("hboc1_q010_1")
                                                            }}>add Info</button>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <div id="hboc1_q010_1_showResult"></div>
                                                            <input type="hidden" id="hboc1_q010_1_pfHistory_AnyAgeValuesJustShow" class="form-control " />
                                                            <input type="hidden" id="hboc1_q010_1_pfHistory_AnyAgeValues" class="form-control " />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="demo-radio-button" id="hboc1_q011">
                                        <div class="row clearfix custom-min-height">
                                            <div class="col-md-4">
                                                <label>Ovarian cancer at any age</label>
                                            </div>
                                            <div class="col-md-2">
                                                <input type="radio" id="pfHistory_OvarianCanAge" name="pfHistory_OvarianCanAge_" value="yes" class="form-control" onClick={() => {
                                                    pfHistory_OvarianCanAgeClick()
                                                }} />
                                                <label class="form-label" for="pfHistory_OvarianCanAge">Yes</label>
                                                <input type="radio" id="pfHistory_OvarianCanAge2" name="pfHistory_OvarianCanAge_" value="no" class="form-control" onClick={() => {
                                                    pfHistory_OvarianCanAge2Click()
                                                }} />
                                                <label class="form-label" for="pfHistory_OvarianCanAge2">No</label>
                                            </div>
                                            <div class="col-md-6">
                                                <div className=" hideFormContent" id="pfHistory_OvarianCanAge_Div">
                                                    <div class="row clearfix">
                                                        <div class="col-md-3">
                                                            <button type="button" class="btn btn-default waves-effect m-r-20" data-toggle="modal" data-target="#defaultModal" onClick={() => {
                                                                btnpfHistoryAddFamilyHistoryClick("hboc1_q011_1")
                                                            }}>add Info</button>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <div id="hboc1_q011_1_showResult"></div>
                                                            <input type="hidden" id="hboc1_q011_1_pfHistory_AnyAgeValuesJustShow" class="form-control " />
                                                            <input type="hidden" id="hboc1_q011_1_pfHistory_AnyAgeValues" class="form-control " />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="demo-radio-button" id="hboc1_q012">
                                        <div class="row clearfix custom-min-height">
                                            <div class="col-md-4">
                                                <label>Pancreatic cancer at any age</label>
                                            </div>
                                            <div class="col-md-2">
                                                <input type="radio" id="pfHistory_PancreaticCanAge" name="pfHistory_PancreaticCanAge_" value="yes" class="form-control" onClick={() => {
                                                    pfHistory_PancreaticCanAgeClick()
                                                }} />
                                                <label class="form-label" for="pfHistory_PancreaticCanAge">Yes</label>
                                                <input type="radio" id="pfHistory_PancreaticCanAge2" name="pfHistory_PancreaticCanAge_" value="no" class="form-control" onClick={() => {
                                                    pfHistory_PancreaticCanAge2Click()
                                                }} />
                                                <label class="form-label" for="pfHistory_PancreaticCanAge2">No</label>
                                            </div>
                                            <div class="col-md-6">
                                                <div className=" hideFormContent" id="pfHistory_PancreaticCanAge_Div">
                                                    <div class="row clearfix">
                                                        <div class="col-md-3">
                                                            <button type="button" class="btn btn-default waves-effect m-r-20" data-toggle="modal" data-target="#defaultModal" onClick={() => {
                                                                btnpfHistoryAddFamilyHistoryClick("hboc1_q012_1")
                                                            }}>add Info</button>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <div id="hboc1_q012_1_showResult"></div>
                                                            <input type="hidden" id="hboc1_q012_1_pfHistory_AnyAgeValuesJustShow" class="form-control " />
                                                            <input type="hidden" id="hboc1_q012_1_pfHistory_AnyAgeValues" class="form-control " />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="demo-radio-button" id="hboc1_q013">
                                        <div class="row clearfix custom-min-height">
                                            <div class="col-md-4">
                                                <label>Male breast cancer at any age</label>
                                            </div>
                                            <div class="col-md-2">
                                                <input type="radio" id="pfHistory_MaleBreastCanAge" name="pfHistory_MaleBreastCanAge_" value="yes" class="form-control" onClick={() => {
                                                    pfHistory_MaleBreastCanAgeClick()
                                                }} />
                                                <label class="form-label" for="pfHistory_MaleBreastCanAge">Yes</label>
                                                <input type="radio" id="pfHistory_MaleBreastCanAge2" name="pfHistory_MaleBreastCanAge_" value="no" class="form-control" onClick={() => {
                                                    pfHistory_MaleBreastCanAge2Click()
                                                }} />
                                                <label class="form-label" for="pfHistory_MaleBreastCanAge2">No</label>
                                            </div>
                                            <div class="col-md-6">
                                                <div className=" hideFormContent" id="pfHistory_MaleBreastCanAge_Div">
                                                    <div class="row clearfix">
                                                        <div class="col-md-3">
                                                            <button type="button" class="btn btn-default waves-effect m-r-20" data-toggle="modal" data-target="#defaultModal" onClick={() => {
                                                                btnpfHistoryAddFamilyHistoryClick("hboc1_q013_1")
                                                            }}>add Info</button>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <div id="hboc1_q013_1_showResult"></div>
                                                            <input type="hidden" id="hboc1_q013_1_pfHistory_AnyAgeValuesJustShow" class="form-control " />
                                                            <input type="hidden" id="hboc1_q013_1_pfHistory_AnyAgeValues" class="form-control " />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="demo-radio-button" id="hboc1_q014">
                                        <div class="row clearfix custom-min-height">
                                            <div class="col-md-4">
                                                <label>Metastatic prostate cancer at any age</label>
                                            </div>
                                            <div class="col-md-2">
                                                <input type="radio" id="pfHistory_MetaProstateCanAge" name="pfHistory_MetaProstateCanAge_" value="yes" class="form-control" onClick={() => {
                                                    pfHistory_MetaProstateCanAgeClick()
                                                }} />
                                                <label class="form-label" for="pfHistory_MetaProstateCanAge">Yes</label>
                                                <input type="radio" id="pfHistory_MetaProstateCanAge2" name="pfHistory_MetaProstateCanAge_" value="no" class="form-control" onClick={() => {
                                                    pfHistory_MetaProstateCanAge2Click()
                                                }} />
                                                <label class="form-label" for="pfHistory_MetaProstateCanAge2">No</label>
                                            </div>
                                            <div class="col-md-6">
                                                <div className=" hideFormContent" id="pfHistory_MetaProstateCanAge_Div">
                                                    <div class="row clearfix">
                                                        <div class="col-md-3">
                                                            <button type="button" class="btn btn-default waves-effect m-r-20" data-toggle="modal" data-target="#defaultModal" onClick={() => {
                                                                btnpfHistoryAddFamilyHistoryClick("hboc1_q014_1")
                                                            }}>add Info</button>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <div id="hboc1_q014_1_showResult"></div>
                                                            <input type="hidden" id="hboc1_q014_1_pfHistory_AnyAgeValuesJustShow" class="form-control " />
                                                            <input type="hidden" id="hboc1_q014_1_pfHistory_AnyAgeValues" class="form-control " />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="demo-radio-button" id="hboc1_q015">
                                        <div class="row clearfix custom-min-height">
                                            <div class="col-md-4">
                                                <label>Colon cancer at 49 or younger</label>
                                            </div>
                                            <div class="col-md-2">
                                                <input type="radio" id="pfHistory_ColorCanAge" name="pfHistory_ColorCanAge_" value="yes" class="form-control" onClick={() => {
                                                    pfHistory_ColorCanAgeClick()
                                                }} />
                                                <label class="form-label" for="pfHistory_ColorCanAge">Yes</label>
                                                <input type="radio" id="pfHistory_ColorCanAge2" name="pfHistory_ColorCanAge_" value="no" class="form-control" onClick={() => {
                                                    pfHistory_ColorCanAge2Click()
                                                }} />
                                                <label class="form-label" for="pfHistory_ColorCanAge2">No</label>
                                            </div>
                                            <div class="col-md-6">
                                                <div className=" hideFormContent" id="pfHistory_ColorCanAge_Div">
                                                    <div class="row clearfix">
                                                        <div class="col-md-3">
                                                            <button type="button" class="btn btn-default waves-effect m-r-20" data-toggle="modal" data-target="#defaultModal" onClick={() => {
                                                                btnpfHistoryAddFamilyHistoryClick("hboc1_q015_1")
                                                            }}>add Info</button>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <div id="hboc1_q015_1_showResult"></div>
                                                            <input type="hidden" id="hboc1_q015_1_pfHistory_AnyAgeValuesJustShow" class="form-control " />
                                                            <input type="hidden" id="hboc1_q015_1_pfHistory_AnyAgeValues" class="form-control " />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="demo-radio-button" id="hboc1_q016">
                                        <div class="row clearfix custom-min-height">
                                            <div class="col-md-4">
                                                <label>Uterine cancer at 49 or younger</label>
                                            </div>
                                            <div class="col-md-2">
                                                <input type="radio" id="pfHistory_UterineCanAge" name="pfHistory_UterineCanAge_" value="yes" class="form-control" onClick={() => {
                                                    pfHistory_UterineCanAgeClick()
                                                }} />
                                                <label class="form-label" for="pfHistory_UterineCanAge">Yes</label>
                                                <input type="radio" id="pfHistory_UterineCanAge2" name="pfHistory_UterineCanAge_" value="no" class="form-control" onClick={() => {
                                                    pfHistory_UterineCanAge2Click()
                                                }} />
                                                <label class="form-label" for="pfHistory_UterineCanAge2">No</label>
                                            </div>
                                            <div class="col-md-6">
                                                <div className=" hideFormContent" id="pfHistory_UterineCanAge_Div">
                                                    <div class="row clearfix">
                                                        <div class="col-md-3">
                                                            <button type="button" class="btn btn-default waves-effect m-r-20" data-toggle="modal" data-target="#defaultModal" onClick={() => {
                                                                btnpfHistoryAddFamilyHistoryClick("hboc1_q016_1")
                                                            }}>add Info</button>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <div id="hboc1_q016_1_showResult"></div>
                                                            <input type="hidden" id="hboc1_q016_1_pfHistory_AnyAgeValuesJustShow" class="form-control " />
                                                            <input type="hidden" id="hboc1_q016_1_pfHistory_AnyAgeValues" class="form-control " />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="demo-radio-button" id="hboc1_q017">
                                        <div class="row clearfix custom-min-height">
                                            <div class="col-md-4">
                                                <label>Ashkenazi Jewish ancestry with breast cancer at any age</label>
                                            </div>
                                            <div class="col-md-2">
                                                <input type="radio" id="pfHistory_AshJewishAncesCanAge" name="pfHistory_AshJewishAncesCanAge_" value="yes" class="form-control" onClick={() => {
                                                    pfHistory_AshJewishAncesCanAgeClick()
                                                }} />
                                                <label class="form-label" for="pfHistory_AshJewishAncesCanAge">Yes</label>
                                                <input type="radio" id="pfHistory_AshJewishAncesCanAge2" name="pfHistory_AshJewishAncesCanAge_" value="no" class="form-control" onClick={() => {
                                                    pfHistory_AshJewishAncesCanAge2Click()
                                                }} />
                                                <label class="form-label" for="pfHistory_AshJewishAncesCanAge2">No</label>
                                            </div>
                                            <div class="col-md-6">
                                                <div className=" hideFormContent" id="pfHistory_AshJewishAncesCanAge_Div">
                                                    <div class="row clearfix">
                                                        <div class="col-md-3">
                                                            <button type="button" class="btn btn-default waves-effect m-r-20" data-toggle="modal" data-target="#defaultModal" onClick={() => {
                                                                btnpfHistoryAddFamilyHistoryClick("hboc1_q017_1")
                                                            }}>add Info</button>
                                                        </div>
                                                        <div class="col-md-9">
                                                            <div id="hboc1_q017_1_showResult"></div>
                                                            <input type="hidden" id="hboc1_q017_1_pfHistory_AnyAgeValuesJustShow" class="form-control " />
                                                            <input type="hidden" id="hboc1_q017_1_pfHistory_AnyAgeValues" class="form-control " />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="demo-radio-button" id="hboc1_q018">
                                        <div class="row clearfix custom-min-height">
                                            <div class="col-md-4">
                                                <label>Does the Patient have a family history of other cancers?</label>
                                            </div>
                                            <div class="col-md-3">
                                                <input type="radio" id="pfHistory_otherCan" name="pfHistory_otherCan_" value="yes" class="form-control" onClick={() => {
                                                    pfHistory_otherCanClick()
                                                }} />
                                                <label class="form-label" for="pfHistory_otherCan">Yes</label>
                                                <input type="radio" id="pfHistory_otherCan2" name="pfHistory_otherCan_" value="no" class="form-control" onClick={() => {
                                                    pfHistory_otherCan2Click()
                                                }} />
                                                <label class="form-label" for="pfHistory_otherCan2">No If yes, please list:</label>
                                            </div>
                                            <div class="col-md-5">
                                                <div className=" hideFormContent" id="pfHistory_otherCan_Div">
                                                    <div class="row clearfix">
                                                        <div class="col-md-12">
                                                            <div class="form-line">
                                                                <input type="text" id="pfHistory_otherCan_info" class="form-control " placeholder="please list" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="demo-radio-button" id="hboc1_q019">
                                        <div class="row clearfix custom-min-height">
                                            <div class="col-md-4">
                                                <label>Has anyone in the Patient's family had genetic testing for hereditary cancer?</label>
                                            </div>
                                            <div class="col-md-5">
                                                <input type="radio" id="pfHistory_geHerediraryCan" name="pfHistory_geHerediraryCan_" value="yes" class="form-control" onClick={() => {
                                                    pfHistory_geHerediraryCanClick()
                                                }} />
                                                <label class="form-label" for="pfHistory_geHerediraryCan">Yes</label>
                                                <input type="radio" id="pfHistory_geHerediraryCan2" name="pfHistory_geHerediraryCan_" value="no" class="form-control" onClick={() => {
                                                    pfHistory_geHerediraryCan2Click()
                                                }} />
                                                <label class="form-label" for="pfHistory_geHerediraryCan2">No If yes, please list: Who, what genes and results (if known):</label>
                                            </div>
                                            <div class="col-md-3">
                                                <div className=" hideFormContent" id="pfHistory_geHerediraryCan_Div">
                                                    <div class="row clearfix">
                                                        <div class="col-md-12">
                                                            <div class="form-line">
                                                                <input type="text" id="pfHistory_geHerediraryCan_info" class="form-control " placeholder="please list" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="demo-radio-button" id="hboc1_q020">
                                        <div class="row clearfix">
                                            <div class="col-md-12">
                                                <label>Other Clinical History:</label>
                                                <div class="form-line">
                                                    <input type="text" id="pfHistory_OtherClinicalHistory" class="form-control " placeholder="Other Clinical History" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row clearfix">
                            <div class="col-md-12">
                                <div class="form-group ">
                                    <label class="form-label" for="address">Previous or current treatment</label>
                                    <div class="demo-checkbox" id="hboc1_q021-hboc1_q024">
                                        <input type="checkbox" id="pfHistory_PCurTreat_boneMar" name="pfHistory_PCurTreat_boneMar" class="filled-in" />
                                        <label for="pfHistory_PCurTreat_boneMar"> Allogeneic bone marrow or peripheral stem cell transplant <sup>*</sup> &nbsp; </label>
                                        <input type="checkbox" id="pfHistory_PCurTreat_Chemo" name="pfHistory_PCurTreat_Chemo" class="filled-in" />
                                        <label for="pfHistory_PCurTreat_Chemo"> Chemotherapy<sup>*</sup> &nbsp; </label>
                                        <input type="checkbox" id="pfHistory_PCurTreat_Radia" name="pfHistory_PCurTreat_Radia" class="filled-in" />
                                        <label for="pfHistory_PCurTreat_Radia"> Radiation &nbsp; </label>
                                        <input type="checkbox" id="pfHistory_PCurTreat_Surgery" name="pfHistory_PCurTreat_Surgery" class="filled-in" />
                                        <label for="pfHistory_PCurTreat_Surgery"> Surgery, &nbsp; </label>
                                    </div>
                                    <div class="form-line" id="hboc1_q025">
                                        <input type="text" id="pfHistory_PCurTreat_SurgDe" class="form-control " placeholder="specify" />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row clearfix">
                            <div class="col-md-12">
                                <p><sup>*</sup>DNA analysis from blood samples of individuals who have undergone stem cell transplants, bone marrow transplants, or chemotheray may not reflect the germline genotype. Similarly, DNA analysis from blood samples of individuals with hematologic malignancy may not distinguish between somatic and germline variants</p>
                            </div>
                        </div>
                        {/* <div class="row clearfix">
                            <div class="col-md-12">
                                <div class="form-group " id="hboc1_q026">
                                    <label class="form-label" for="address">Previous or current treatment</label>
                                    <div class="form-line">
                                        <input type="text" id="pfHistory_PCurTreat_SurgDe2" class="form-control " placeholder="specify" />
                                    </div>
                                </div>
                            </div>
                        </div> */}

                    </div>

                    <div class="row clearfix">
                        <div class="col-md-12 text-right">
                            <button id="btnHBOCFamilyHistoryInfoFormNext" class="btn btn-light">Next</button>
                        </div>
                    </div>  
                    
                </div>

                {/* <!-- Default Size --> */}
                <div class="modal fade" id="defaultModal" tabindex="-1" role="dialog">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h4 class="modal-title" id="defaultModalLabel">Does the Patient have a family history of</h4>
                            </div>
                            <div class="modal-body">
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                        <div class="demo-radio-button modalcss">
                                            <input type="radio" id="pfHistory_AnyAge_Mothside" name="pfHistory_AnyAge_relative" value="yes" class="form-control" />
                                            <label class="form-label" for="pfHistory_AnyAge_Mothside">Mother's side</label>
                                            <input type="radio" id="pfHistory_AnyAge_Fathside" name="pfHistory_AnyAge_relative" value="no" class="form-control" />
                                            <label class="form-label" for="pfHistory_AnyAge_Fathside">Father's side</label>
                                        </div>
                                    </div>

                                    <div class="col-md-3">
                                        <div class="form-line">
                                            <select class="form-control show-tick" id="pfHistory_AnyAge_relative">
                                                <option value="">-- Relative --</option>
                                                <option value="not provided">not provided</option>
                                                <option value="parent">parent</option>
                                                <option value="sibling">sibling</option>
                                                <option value="child">child</option>
                                                <option value="uncle">uncle</option>
                                                <option value="aunt">aunt</option>
                                                <option value="great uncle">great uncle</option>
                                                <option value="great aunt">great aunt</option>
                                                <option value="nephew">nephew</option>
                                                <option value="niece">niece</option>
                                                <option value="grandparent">grandparent</option>
                                                <option value="grandchild">grandchild</option>
                                                <option value="half-sibling">half-sibling</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-3">
                                        <div class="form-line">
                                            <input type="text" id="pfHistory_AnyAge_age" class="form-control " placeholder="Age" />
                                            <input type="hidden" id="pfHistory_AnyAge_dataid" class="form-control " />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="button" class="btn waves-effect btn-primary text-white" onClick={() => {
                                    btnpfHistoryAddFamilyHistory()
                                }}>Add</button>&nbsp; &nbsp;
                                <button type="button" class="btn waves-effect btn-danger text-white mclose" data-dismiss="modal">CLOSE</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
