import React, { useState } from "react";
import axios from "axios";
import helper from './utils/helper';

//require("dotenv").config();
const API_BASE_URL = helper.getAPIBaseURI();

const emailSentSuccessfulMessage = "Please check your inbox, an email has been sent to you with instructions to reset your password";
const generalErrorMessage = "Oops, something went wrong. Please try again";

const ForgotPassword = (props) => {
  // state variables
  const [ inputEmail, setInputEmail ] = useState("");
  const [ inputEmailInvalid, setInputEmailInvalid ] = useState(false);
  const [ responseReceivedFromServer, setResponseReceivedFromServer ] = useState(false);
  const [ feedbackTextAfterFormSubmit, setFeedbackTextAfterFormSubmit ] = useState("");

  // general methods
  const validateInputtedEmail = () => {
    // reset the state variable setInputEmailInvalid = true initially to animate every time email is validated
    setInputEmailInvalid(false);

    const result = /^[a-zA-Z0-9.]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(inputEmail);

    // reset the value of state variable setInputEmailInvalid
    setTimeout(() => {
      setInputEmailInvalid(!result);
    }, 15);

    return result;
  }

  const redirectToHomepage = () => {
    setTimeout(() => window.location = "/", 5000);
  }

  // event handlers
  const handleSubmit = async (e) => {
    e.preventDefault();
    const inputEmailIsValid = validateInputtedEmail();
    setResponseReceivedFromServer(false);
    if (inputEmailIsValid) {
      const forgotPasswordAPIEndPoint = `${API_BASE_URL}/users/forgotPassword`;
      try {
        await axios.post(
          forgotPasswordAPIEndPoint,
          { email: inputEmail, host: `${window.location.hostname}${window.location.port.length > 0 ? ":" + window.location.port : ""}` },
        );
        setFeedbackTextAfterFormSubmit(emailSentSuccessfulMessage);
        setResponseReceivedFromServer(true);
        redirectToHomepage();
      } catch (err) {
        if (err.response) {
          setFeedbackTextAfterFormSubmit(emailSentSuccessfulMessage);
          redirectToHomepage();
        }
        else
          setFeedbackTextAfterFormSubmit(generalErrorMessage);
        setResponseReceivedFromServer(true);
      }
    }
  }

  document.title = "Vermillions - Forgot Password";

  return (
    <div className="forgot-password-section">
      <form className="reset-password-form-box" onSubmit={handleSubmit}>
        <div>
          <img
            src={`${process.env.PUBLIC_URL}/assets/images/logo_aspira.png`}
            alt="aspira logo"
            style={{ marginBottom: "2.25vw", marginLeft: "-1vw" }}
          />
        </div>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="your email here"
          onChange={(e) => setInputEmail(e.target.value)}
          autoComplete="off"
          className={inputEmailInvalid ? "err-email-input" : ""}
        />
        <button type="submit" onClick={handleSubmit}>Send Password Reset to My Email</button>
      </form>
      <div className={ responseReceivedFromServer ? "reset-password-request-message-box height-fitcontent" : "reset-password-request-message-box height-0px" }>
        {feedbackTextAfterFormSubmit}
      </div>
      <footer>aspira admin portal</footer>
    </div>
  );
}

export default ForgotPassword;