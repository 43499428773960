/* eslint-disable react/jsx-pascal-case */
/* eslint-disable react/jsx-no-comment-textnodes */
import { MsalContext } from "@azure/msal-react";
import { message } from "antd";
import axios from "axios";
import $ from "jquery";
import moment from "moment";
import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import AsyncSelect from "react-select/async";
import Toast from "../mycomponents/toast/Toast";
import { protectedResources } from "./../authConfig";
import helper from "./../utils/helper";
import Billing_Information from "./add_edit_order_components/Billing_Information";
import Clinical_Information from "./add_edit_order_components/Clinical_Information";
import Diagnosis_Code from "./add_edit_order_components/Diagnosis_Code";
import Kit_Shipping_Information from "./add_edit_order_components/Kit_Shipping_Information";
import Notes_and_Certify from "./add_edit_order_components/Notes_and_Certify";
import OrderHeader from "./add_edit_order_components/Order_header";
import PatientInformation from "./add_edit_order_components/PatientInformation";
import PhysicianInformation from "./add_edit_order_components/PhysicianInformation";
import Retrive_Reprot from "./add_edit_order_components/RetriveReport";
import BillingNotes from "./BillingNotes";
import CmtForm from "./CmtForm";
import TopActions from "./add_edit_order_components/TopActions";

const API_BASE_URL = helper.getAPIBaseURI();

//for billing logic

// ======
// click releated logic for additional biomarkers & it's child biomarkers associated with it
// ======
const checkAllGCPChildBiomarkers = () => {
  $("#gcp_container2")
    .find("input")
    .map((i, elem) => {
      $(elem).prop("checked", true);
      $(elem).prop("disabled", true);
    });
};

const checkAllMCPChildBiomarkers = () => {
  $("#mcp_container")
    .find("input")
    .map((i, elem) => {
      $(elem).prop("checked", true);
      $(elem).prop("disabled", true);
    });
};

const addLogicForHandlingBiomarkersClickInRelataionWithItsParentOrChildBiomarkers =
  (GCPChildBiomarkers, MCPChildBiomarkers) => {
    $(document).ready(() => {
      $("#germCellPanel_GCP100_LDH").change(() => {
        let $gcp = $("#germCellPanel_GCP100_LDH");
        if ($gcp.is(":checked")) {
          $("#gcp_container2")
            .find("input")
            .map((i, elem) => {
              $(elem).prop("checked", true);
              $(elem).prop("disabled", true);
            });
        } else {
          $("#gcp_container2")
            .find("input")
            .map((i, elem) => {
              $(elem).prop("checked", false);
              $(elem).prop("disabled", false);
            });
        }
      });

      $("#germCellPanel_MCP100_CEA").change(() => {
        let $mcp = $("#germCellPanel_MCP100_CEA");
        if ($mcp.is(":checked")) {
          $("#mcp_container")
            .find("input")
            .map((i, elem) => {
              $(elem).prop("checked", true);
              $(elem).prop("disabled", true);
            });
        } else {
          $("#mcp_container")
            .find("input")
            .map((i, elem) => {
              $(elem).prop("checked", false);
              $(elem).prop("disabled", false);
            });
        }
      });

      const shouldGCP100BeChecked = () => {
        for (let idx in GCPChildBiomarkers) {
          if (!$(`#_gcp_assay_${idx}`).is(":checked")) return false;
        }

        return true;
      };

      const shouldMCP100BeChecked = () => {
        for (let idx in MCPChildBiomarkers) {
          if (!$(`#_mcp_assay_${idx}`).is(":checked")) return false;
        }

        return true;
      };

      GCPChildBiomarkers.forEach((a, i) => {
        $(`#_gcp_assay_${i}`).change(() => {
          if (shouldGCP100BeChecked()) $("#germCellPanel_GCP100_LDH").click();
        });
      });

      MCPChildBiomarkers.forEach((a, i) => {
        $(`#_mcp_assay_${i}`).change(() => {
          if (shouldMCP100BeChecked()) $("#germCellPanel_MCP100_CEA").click();
        });
      });
    });
  };

const makeUltrasoundResultsCheckboxesBehaveLikeRadioInputs = () => {
  $(`#clinicinfo_lowrisk`).change(() => {
    if ($(`#clinicinfo_lowrisk`).is(":checked")) {
      $(`#clinicinfo_highrisk`).prop("checked", false);
      $(`#clinicinfo_NotDefinitive`).prop("checked", false);
      $(`#clinicinfo_NotGiven`).prop("checked", false);
    }
  });

  $(`#clinicinfo_highrisk`).change(() => {
    if ($(`#clinicinfo_highrisk`).is(":checked")) {
      $(`#clinicinfo_lowrisk`).prop("checked", false);
      $(`#clinicinfo_NotDefinitive`).prop("checked", false);
      $(`#clinicinfo_NotGiven`).prop("checked", false);
    }
  });

  $(`#clinicinfo_NotDefinitive`).change(() => {
    if ($(`#clinicinfo_NotDefinitive`).is(":checked")) {
      $(`#clinicinfo_lowrisk`).prop("checked", false);
      $(`#clinicinfo_highrisk`).prop("checked", false);
      $(`#clinicinfo_NotGiven`).prop("checked", false);
    }
  });
  $(`#clinicinfo_NotGiven`).change(() => {
    if ($(`#clinicinfo_NotGiven`).is(":checked")) {
      $(`#clinicinfo_lowrisk`).prop("checked", false);
      $(`#clinicinfo_highrisk`).prop("checked", false);
      $(`#clinicinfo_NotDefinitive`).prop("checked", false);
    }
  });
};

const ShowPatienAbdomenPainAreaClick = () => {
  const clinicinfo_pain_abdomen_pelvis = document.getElementById(
    `clinicinfo_pain_abdomen_pelvis`
  );

  if (clinicinfo_pain_abdomen_pelvis.checked == true) {
    $(`#PatienAbdomenPainArea`).addClass(" showPatienAbdomenPainArea");
    $(`#PatienAbdomenPainArea`).removeClass(" hidePatienAbdomenPainArea");
  }
};

const ShowPatienfullQuicklyUnableToeAreaClick = () => {
  const clinicinfo_fullQuickly_unableToe = document.getElementById(
    `clinicinfo_fullQuickly_unableToe`
  );

  if (clinicinfo_fullQuickly_unableToe.checked == true) {
    $(`#PatienfullQuicklyUnableToeArea`).addClass(" showPatienAbdomenPainArea");
    $(`#PatienfullQuicklyUnableToeArea`).removeClass(
      " hidePatienAbdomenPainArea"
    );
  }
};

const ShowPatienExpabdominalbloatAreaClick = () => {
  const clinicinfo_Expabdominalbloat_IncrSize = document.getElementById(
    `clinicinfo_Expabdominalbloat_IncrSize`
  );

  if (clinicinfo_Expabdominalbloat_IncrSize.checked == true) {
    $(`#PatienExpabdominalbloatArea`).addClass(" showPatienAbdomenPainArea");
    $(`#PatienExpabdominalbloatArea`).removeClass(" hidePatienAbdomenPainArea");
  }
};

const HidePatienExpabdominalbloatAreaClick = () => {
  const clinicinfo_Expabdominalbloat_IncrSize2 = document.getElementById(
    `clinicinfo_Expabdominalbloat_IncrSize2`
  );

  if (clinicinfo_Expabdominalbloat_IncrSize2.checked == true) {
    $(`#PatienExpabdominalbloatArea`).removeClass(" showPatienAbdomenPainArea");
    $(`#PatienExpabdominalbloatArea`).addClass(" hidePatienAbdomenPainArea");
  }
};

let me = null;
let ovaAccessToken = "";
let reportId = "";
let ova1ReportId = "";
export default class AddEditOrderContent extends React.PureComponent {
  static contextType = MsalContext;

  constructor(props) {
    super(props);

    this.state = {
      reportDate: "",
      loading: true,
      patients: [],
      profileImage: null,
      profileImagePreview: null,
      selectedPhysicianState: "",

      toastMessage: "",
      list: [],
      toastList: [],
      orderData: [],

      firstName: "",
      lastName: "",
      testOrdered: "",
      buttonText: "Create",
      frmprefix: "ova1_",

      //physician info
      physicianId: "",
      phyinfo_Organization_account: "",
      phyinfo_first_name: "",
      phyinfo_last_name: "",
      phyinfo_email: "",
      phyinfo_npi: "",
      phyinfoC_address: "",
      phyinfoC_practiceLocation: "",
      phyinfoC_city: "",
      phyinfo_state: "",
      phyinfo_state_save_value: "",
      phyinfoC_zipCode: "",
      phyinfoC_Phone: "",
      phyinfoC_fax: "",
      phyinfoC_Faxcopyto: "",

      //patient info
      // patientId: "",
      patinfo_firstname: "",
      patinfo_lastname: "",
      patinfo_address: "",
      selectedState: "ALABAMA",
      patinfo_State: "",
      patinfo_State_Save_value: "",
      patinfo_city: "",
      patinfo_zipcode: "",
      patinfo_dob: "",
      patinfo_PhoneNumber: "",
      patinfo_emailaddress: "",
      patinfo_OtherEthnicitycity: "",
      patinfo_ethnicity: "",
      ethnicityArray: {},

      patinfo_gender: "",

      //clinical info Start
      clinicinfo_DoLastMentrualPeriod: null,
      // questionCode - ova1_q01
      clinicinfo_Menopausal_status: null,
      // questionCode - ova1_q02
      ultrasound_results_q02: null,
      // questionCode - ova1_q04
      clinicinfo_MassSize_longdime: null,
      clinicinfo_MassSize_Height: null,
      clinicinfo_MassSize_Height_unit: null,
      clinicinfo_MassSize_Weight: null,
      clinicinfo_MassSize_Weight_unit: null,
      // questionCode - ova1_q05
      clinicinfo_pain_abdomen_pelvis: false,
      days_per_month_q05_1: null,
      how_long_month_q05_2: null,
      // questionCode - ova1_q06
      clinicinfo_fullQuickly_unableToe: false,
      days_per_month_q06_1: null,
      how_long_month_q06_2: null,
      // questionCode - ova1_q07
      clinicinfo_Expabdominalbloat_IncrSize: false,
      days_per_month_q07_1: null,
      how_long_month_q07_2: null,
      //clinical info End

      //billing info
      selectedPayor: {},
      allPayors: [],
      billinfo_PrimaryInsuranceCarrier: "",
      billinfo_planId: "",
      billinfo_MemberID: "",
      billinfo_GroupID: "",
      billinfo_SecondaryInsuranceCarrier: "",
      billinfo_SecondaryplanId: "",
      billinfo_SecondaryMemberID: "",
      billinfo_SecondaryGroupID: "",
      billinfo_NameofInsured: "",
      billinfoC_dob: "",
      billinfo_RelationshipInsured: "",

      otherICD10Codes: [""],
      otherICD10Code: "",
      diagonisisCodes: [],
      kitShip_notes: "",
      clinicalData: [],
      kitShip_PatMobPhlebotomy: null,
      kitShip_PatblooddrawQuest: null,
      kitShip_PatOthers: null,
      kitShip_PatOthersInfo: "",

      //edit billing portion
      privateInsurance: false,
      patientSelfPay: false,
      medicare: false,
      medicaid: false,
      orderingFacility: false,
      freeTrial: false,

      billinfo_FirstNameofInsured: "",
      billinfo_LastNameofInsured: "",

      isBillableOrder: false,

      updatedAt: Date.now(),
    };

    this.firstName = React.createRef();
    this.lastName = React.createRef();
    this.testOrdered = React.createRef();

    //physician
    this.phyinfo_Organization_account = React.createRef();
    this.phyinfo_first_name = React.createRef();
    this.phyinfo_last_name = React.createRef();
    this.phyinfo_email = React.createRef();
    // this.phyinfo_npi = React.createRef();
    this.phyinfoC_address = React.createRef();
    this.phyinfoC_practiceLocation = React.createRef();
    this.phyinfoC_city = React.createRef();
    this.phyinfo_state = React.createRef();
    this.phyinfoC_zipCode = React.createRef();
    this.phyinfoC_Phone = React.createRef();
    this.phyinfoC_fax = React.createRef();
    this.phyinfoC_Faxcopyto = React.createRef();

    //patient info
    this.patinfo_firstname = React.createRef();
    this.patinfo_lastname = React.createRef();
    this.patinfo_address = React.createRef();
    this.patinfo_State = React.createRef();
    this.patinfo_city = React.createRef();
    this.patinfo_zipcode = React.createRef();
    this.patinfo_dob = React.createRef();
    this.patinfo_PhoneNumber = React.createRef();
    this.patinfo_emailaddress = React.createRef();
    this.patinfo_OtherEthnicitycity = React.createRef();
    this.patinfo_ethnicity = React.createRef();
    this.patinfo_gender = React.createRef();

    //billing info
    this.billinfo_PrimaryInsuranceCarrier = React.createRef();
    this.billinfo_planId = React.createRef();
    this.billinfo_MemberID = React.createRef();
    this.billinfo_GroupID = React.createRef();
    this.billinfo_SecondaryInsuranceCarrier = React.createRef();
    this.billinfo_SecondaryplanId = React.createRef();
    this.billinfo_SecondaryMemberID = React.createRef();
    this.billinfo_SecondaryGroupID = React.createRef();

    this.billinfo_FirstNameofInsured = React.createRef();
    this.billinfo_LastNameofInsured = React.createRef();

    this.billinfo_NameofInsured = React.createRef();
    this.billinfoC_dob = React.createRef();
    this.billinfo_RelationshipInsured = React.createRef();
    this.OtherICD10Codes = React.createRef();
    this.kitShip_PatOthersInfo = React.createRef();
    this.kitShip_notes = React.createRef();

    this.orderId = null;
    this._id = null;
    this.patientId = null;
    this.physicianId = null;

    me = this;
    this.diagnosis_UnOcyst_rs = React.createRef();
    this.diagnosis_IntraAbdPelSwell = React.createRef();
    this.diagnosis_Periumbilicalswell = React.createRef();
    this.diagnosis_lowerquadrantSwell = React.createRef();
    this.diagnosis_intraabdominalpelvicswell = React.createRef();
    this.diagnosis_UnOcyst = React.createRef();
    this.diagnosis_LlQabdominalswelling = React.createRef();
  }

  isExistTestCode = (testCodeName, gcpOrMcpCodeArray) => {
    for (let i = 0; i < gcpOrMcpCodeArray.length; ++i) {
      const c = gcpOrMcpCodeArray[i];
      if (testCodeName.includes(c)) {
        return true;
      }
    }

    return false;
  };

  async componentDidMount() {
    const accessToken = await helper.getAccessToken(this.context)

    this.setState({
      ovaAccessToken: accessToken,
    });

    axios.interceptors.response.use(
      function (response) {
        // Do something with response data
        return response;
      },
      function (error) {
        switch (error.response.status) {
          case 409:
            console.log("interceptors->error: ", error);

            me.setState(
              {
                toastMessage: `An Order with the same already exists`,
                loading: false,
              },
              () => {
                me.danger_failed.click();
              }
            );
            break;

          default:
            break;
        }
        // Do something with response error
        return Promise.reject(error);
      }
    );

    this.setState(
      {
        loading: true,
        // patients: patients,
      },
      async () => {
        console.log(window.location.search);
        const params = new URLSearchParams(window.location.search);
        this._id = params.get("id");

        console.log("this._id: ", this._id);

        if (this._id) {
          //Edit Mode
          let response = await axios({
            url: `${API_BASE_URL}/orders/${this._id}`,
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-type": "Application/json",
            },
          });
          console.log("before response.data: ", response.data);
          response.data = response.data.orders[0];
          let orderObj = response.data;
          console.log(
            "practiceLocation:",
            response.data.Physician.practiceLocationPlain
          );
          // check if its an order that is billable i.e. not flagged as RUO - Research Use Only
          const isBillableOrder =
            response.data.researchUseOnly === "Yes" ? false : true;
          this.setState({ isBillableOrder: isBillableOrder });

          //assays data
          let _assays_ova1 = response.data.assays.filter(
            (x) => x.assayType.indexOf("OVA_1") > -1 && x.active
          );
          console.log("_assays_ova1: ", _assays_ova1);

          const gcp100QBenchId = _assays_ova1.reduce((qBenchId, assay) => {
            return assay.name.toLowerCase().includes("gcp-100")
              ? assay.qBenchId
              : qBenchId;
          }, 0);

          const mcp100QBenchId = _assays_ova1.reduce((qBenchId, assay) => {
            return assay.name.toLowerCase().includes("mcp-100")
              ? assay.qBenchId
              : qBenchId;
          }, 0);

          console.log("\n---");
          console.log("gcp100QBenchId: ", gcp100QBenchId);
          console.log("mcp100QBenchId: ", mcp100QBenchId);
          console.log("---\n");

          let _gcps = _assays_ova1.filter(
            (x) => x.name.indexOf("GCP") > -1 && !x.name.includes("GCP-100")
          );
          let _mcps = _assays_ova1.filter(
            (x) => x.name.indexOf("MCP") > -1 && !x.name.includes("MCP-100")
          );
          console.log("_assays__gcps: ", _gcps);

          let _assays_content = `<div class="row clearfix">
                        <div class="col-md-12 demo-checkbox">
                        <input data-val="Ova1" data-qbenchid="1" type="checkbox" id="Ova1__Ova1" name="_Ova1" class="filled-in" disabled />
                        <label for="Ova1__Ova1"><b style="color: #444;">OVA1</b> &nbsp; </label><br />
                        </div>
                    </div>`;

          _assays_content += `<div class="row clearfix">
                    <div class="col-md-12 demo-checkbox">
                        <p><strong>Additional biomarkers:</strong></p>
                    </div>
                </div>`;

          _assays_content += `
                    <div class="row clearfix">
                                <div class="col-md-6">
                                    <div class="form-group required">
                                        <div class="demo-checkbox" id="gcp_container1">
                                            <input data-val="GCP-100" data-qbenchid="${gcp100QBenchId}" type="checkbox" id="germCellPanel_GCP100_LDH" name="germCellPanel_GCP100_LDH" class="filled-in" />
                                            <label for="germCellPanel_GCP100_LDH"><b>(GCP-100) Germ Cell Panel</b> (Includes: AFP, bHCG, LDH) &nbsp; </label><br />
                                            <input data-val="MCP-100" data-qbenchid="${mcp100QBenchId}" type="checkbox" id="germCellPanel_MCP100_CEA" name="germCellPanel_MCP100_CEA" class="filled-in" />
                                            <label for="germCellPanel_MCP100_CEA"><b>(MCP-100) Mucinous Panel</b> (Includes: CA19.9, CEA) &nbsp; </label>
                                        </div>
                                    </div>
                                </div>

                    `;

          let _gcpStr = `<div id="gcp_container2" class="col-md-3">`;
          _gcpStr += `<div class="form-group required"><div class="demo-checkbox">`;
          _gcps.map((a, i) => {
            _gcpStr += `
                        <input data-qbenchid="${a.qBenchId}" data-val="${a.testCode}" type="checkbox" id="_gcp_assay_${i}" class="filled-in" />
                        <label for="_gcp_assay_${i}"><b>${a.name}</b></label><br />                
                    `;
          });
          _gcpStr += `</div></div></div>`;

          let _mcpStr = `<div id="mcp_container" class="col-md-3">`;
          _mcpStr += `<div class="form-group required"><div class="demo-checkbox">`;
          _mcps.map((a, i) => {
            _mcpStr += `
                        <input data-qbenchid="${a.qBenchId}" data-val="${a.testCode}" type="checkbox" id="_mcp_assay_${i}" class="filled-in" />
                        <label for="_mcp_assay_${i}"><b>${a.name}</b></label><br />                
                    `;
          });
          _mcpStr += `</div></div></div></div>`;

          _assays_content += _gcpStr + _mcpStr;

          //assays data

          let patinfo_dobFormatted = "";
          if (response.data.Patient.dob) {
            patinfo_dobFormatted = helper.formatDate(response.data.Patient.dob);
          }

          let billinfoC_dobFormatted = "";

          if (response.data.billingInfo.insuredInfo.dob) {
            billinfoC_dobFormatted = helper.formatDate(
              response.data.billingInfo.insuredInfo.dob
            );
          }

          console.log(
            "patinfo_dobFormatted: ",
            patinfo_dobFormatted,
            "billinfoC_dobFormatted: ",
            billinfoC_dobFormatted
          );

          // load payor list
          let _url = `${API_BASE_URL}/payors`;
          let _options = {
            url: _url,
            method: "GET",
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          };

          let axiosResponse = await axios(_options);
          let assignedPayor = {};
          let payorInfo = {};
          const allPayors = axiosResponse.data.payors.map((payor) => {
            let { payorId, payorName, payorContactAddress } = payor;
            payorInfo = {
              label: `${payorId}, ${payorName}, ${payorContactAddress}`,
              value: payorId,
            };

            if (orderObj.payorId && orderObj.payorId === payor.payorId)
              assignedPayor = payorInfo;

            return payorInfo;
          });

          console.log("\n===");
          console.log("orderObj: ", orderObj);
          console.log("===");
          console.log("assignedPayor: ", assignedPayor);
          console.log("===");
          console.log("allPayors: ", allPayors);
          console.log("===\n");

          me.setState(
            {
              patinfo_dob: patinfo_dobFormatted,
              billinfoC_dob: billinfoC_dobFormatted,
              orderData: response.data,
              frmprefix: response.data.tests[0].testName + "_",
              _assays_content: _assays_content,
              _assays_gcps: _gcps,
              _assays_mcps: _mcps,
              isBillableOrder,
              loading: false,
              allPayors,
              selectedPayor: assignedPayor,
              updatedAt: response.data.updatedAt,
            },
            () => {
              //assays data print
              $("#printHere").html(this.state._assays_content);
              addLogicForHandlingBiomarkersClickInRelataionWithItsParentOrChildBiomarkers(
                _gcps,
                _mcps
              );
              makeUltrasoundResultsCheckboxesBehaveLikeRadioInputs();

              this.patientId = response.data.Patient._id;
              this.physicianId = response.data.Physician._id;

              me.orderId = response.data.orderId;
              console.log("private:" + me.orderId);
              console.log(
                "Report Id:--------------------------------------------------999999999999999999999999999999" +
                response.data
              );
              console.log(response.data);
              if (me.state.orderData?.ova1?.reportGUIId && me.state.orderData?.ova1?.reportGUIId.length > 0) {
                ova1ReportId = me.state.orderData.ova1.reportGUIId;
              }
              else {
                reportId = response.data["baylorReportId"];
              }
              let reportDate = moment.utc(response.data["baylorReportCompletionDate"], "YYYY-MM-DD HH:mm:ss").isValid()
                ? moment.utc(response.data["baylorReportCompletionDate"], "YYYY-MM-DD HH:mm:ss").toISOString() : undefined;
              console.log("getReportId:" + response.data["baylorReportId"]);
              console.log("Retrive Date" + reportDate);
              //Physician
              /* me.phyinfo_Organization_account.value =
                response.data.Physician.organizationAccount;
              me.phyinfo_first_name.value = response.data.Physician.firstName;
              me.phyinfo_last_name.value = response.data.Physician.lastName;
              me.phyinfo_email.value = response.data.Physician.email;
              me.phyinfo_npi.value = response.data.Physician.npiNumber;
              me.phyinfoC_address.value =
                response.data.Physician.addresses[0].address_1;
              me.phyinfoC_practiceLocation.value =
                response.data.Physician.practiceLocationPlain;
              me.phyinfoC_city.value =
                response.data.Physician.addresses[0].city;
              me.phyinfo_state.value =
                response.data.Physician.addresses[0].state;
              me.phyinfoC_zipCode.value =
                response.data.Physician.addresses[0].zipCode;
              me.phyinfoC_Phone.value =
                response.data.Physician.addresses[0].phoneNo;
              me.phyinfoC_fax.value =
                response.data?.Physician?.addresses?.[0]?.fax || "";
              me.phyinfoC_Faxcopyto.value =
                response.data?.Physician?.addresses?.[0]?.faxCopyTo || ""; */
              //Patient Information
              //ethnicity array
              const ethnicityArray1 = response.data.Patient.ethnicity
                ? response.data.Patient.ethnicity.split(",")
                : [];
              /*   me.patinfo_firstname.value = response.data.Patient.firstName;
              me.patinfo_lastname.value = response.data.Patient.lastName;
              me.patinfo_address.value =
                response.data.Patient.address.address_1;
              me.patinfo_State.value = response.data.Patient.address.state;
              me.patinfo_city.value = response.data.Patient.address.city;
              me.patinfo_zipcode.value = response.data.Patient.address.zipCode;
              me.patinfo_PhoneNumber.value = response.data.Patient.phoneNo;
              me.patinfo_emailaddress.value = response.data.Patient.email;
              me.patinfo_OtherEthnicitycity.value = ""; */
              //Patient information ethnicity
              if (
                response.data.Patient.ethnicity &&
                response.data.Patient.ethnicity.includes("Caucasian")
              ) {
                this.setState({ patinfo_ethnicity: "Caucasian" });
              }
              if (
                response.data.Patient.ethnicity &&
                response.data.Patient.ethnicity.includes("Ashkenazi Jewish")
              ) {
                this.setState({ patinfo_ethnicity: "Ashkenazi Jewish" });
              }
              if (
                response.data.Patient.ethnicity &&
                response.data.Patient.ethnicity.includes("Sephardic Jewish")
              ) {
                this.setState({ patinfo_ethnicity: "Sephardic Jewish" });
              }
              if (
                response.data.Patient.ethnicity &&
                response.data.Patient.ethnicity.includes("Asian")
              ) {
                this.setState({ patinfo_ethnicity: "Asian" });
              }
              if (
                response.data.Patient.ethnicity &&
                response.data.Patient.ethnicity.includes("Hispanic")
              ) {
                this.setState({ patinfo_ethnicity: "Hispanic" });
              }
              if (
                response.data.Patient.ethnicity &&
                response.data.Patient.ethnicity.includes("Native American")
              ) {
                this.setState({ patinfo_ethnicity: "Native American" });
              }
              if (
                response.data.Patient.ethnicity &&
                response.data.Patient.ethnicity.includes("African American")
              ) {
                this.setState({ patinfo_ethnicity: "African American" });
              }

              if (
                response.data.Patient.hasOwnProperty("ethnicity") &&
                (response.data.Patient.ethnicity.includes("N/A") ||
                  response.data.Patient.ethnicity.trim().length < 1)
              ) {
                this.setState({ patinfo_ethnicity: "N/A" });
              }
              if (
                response.data.Patient.ethnicity &&
                response.data.Patient.ethnicity.includes("Other")
              ) {
                this.setState({ patinfo_ethnicity: "Other" });

                /*  this.state.patinfo_OtherEthnicitycity =
                   ethnicityArray1[ethnicityArray1.length - 1]; */
              }

              //Patient Gender
              //console.log("gender: ",response.data.Patient.gender);
              if (response.data.Patient.gender === "male") {
                this.setState({
                  patinfo_gender: "male",
                });
              } else {
                this.setState({
                  patinfo_gender: "female",
                });
              }

              // ----
              // Fill up clinical information
              // ----
              const clinicalData =
                this.state.orderData.tests?.[0]?.clinicalData || [];
              let lastMenstrualDate = "";

              for (let questionAndAnswerPair of clinicalData) {
                let {
                  questionCode,
                  answer,
                  subQuestion = [],
                } = questionAndAnswerPair;

                if (questionCode.toLowerCase() === "ova1_q01") {
                  this.setState({ clinicinfo_Menopausal_status: answer });
                }

                if (questionCode.toLowerCase() === "ova1_q02") {
                  this.setState({ ultrasound_results_q02: answer });
                }

                if (questionCode.toLowerCase() === "ova1_q03")
                  lastMenstrualDate = helper.formatDate(answer);

                if (questionCode.toLowerCase() === "ova1_q04") {
                  this.setState({ clinicinfo_MassSize_longdime: answer });

                  for (let subQuestionAnswerPair of subQuestion) {
                    let { questionCode: subQuestionCode, answer: subAnswer } =
                      subQuestionAnswerPair;

                    if (subQuestionCode.toLowerCase() === "ova1_q04_1") {
                      let answerParts = subAnswer.split(" ");
                      this.setState({
                        clinicinfo_MassSize_Height: answerParts[0],
                      });
                      this.setState({
                        clinicinfo_MassSize_Height_unit: answerParts[1],
                      });
                    }

                    if (subQuestionCode.toLowerCase() === "ova1_q04_2") {
                      let answerParts = subAnswer.split(" ");
                      this.setState({
                        clinicinfo_MassSize_Weight: answerParts[0],
                      });
                      this.setState({
                        clinicinfo_MassSize_Weight_unit: answerParts[1],
                      });
                    }
                  }
                }

                if (questionCode.toLowerCase() === "ova1_q05") {
                  if (answer === "true") {
                    this.setState({ clinicinfo_pain_abdomen_pelvis: true });
                    for (let subQuestionAnswerPair of subQuestion) {
                      let { questionCode: subQuestionCode, answer: subAnswer } =
                        subQuestionAnswerPair;
                      if (subQuestionCode.toLowerCase() === "ova1_q05_1")
                        this.setState({ days_per_month_q05_1: subAnswer });
                      if (subQuestionCode.toLowerCase() === "ova1_q05_2")
                        this.setState({ how_long_month_q05_2: subAnswer });
                    }
                  } else if (answer === "false") {
                    this.setState({ clinicinfo_pain_abdomen_pelvis: false });
                  }
                }

                if (questionCode.toLowerCase() === "ova1_q06") {
                  if (answer === "true") {
                    this.setState({ clinicinfo_fullQuickly_unableToe: true });
                    for (let subQuestionAnswerPair of subQuestion) {
                      let { questionCode: subQuestionCode, answer: subAnswer } =
                        subQuestionAnswerPair;
                      if (subQuestionCode.toLowerCase() === "ova1_q06_1")
                        this.setState({ days_per_month_q06_1: subAnswer });
                      if (subQuestionCode.toLowerCase() === "ova1_q06_2")
                        this.setState({ how_long_month_q06_2: subAnswer });
                    }
                  } else if (answer === "false") {
                    this.setState({ clinicinfo_fullQuickly_unableToe: false });
                  }
                }

                if (questionCode.toLowerCase() === "ova1_q07") {
                  if (answer === "true") {
                    this.setState({
                      clinicinfo_Expabdominalbloat_IncrSize: true,
                    });
                    for (let subQuestionAnswerPair of subQuestion) {
                      let { questionCode: subQuestionCode, answer: subAnswer } =
                        subQuestionAnswerPair;
                      if (subQuestionCode.toLowerCase() === "ova1_q07_1")
                        this.setState({ days_per_month_q07_1: subAnswer });
                      if (subQuestionCode.toLowerCase() === "ova1_q07_2")
                        this.setState({ how_long_month_q07_2: subAnswer });
                    }
                  } else if (answer === "false")
                    this.setState({
                      clinicinfo_Expabdominalbloat_IncrSize: false,
                    });
                }
              }

              //billing information
              /* if (this.state.isBillableOrder) {
                me.billinfo_PrimaryInsuranceCarrier.value =
                  response.data.billingInfo.primaryInsurance.primaryInsuranceCarrier;
                me.billinfo_planId.value =
                  response.data.billingInfo.primaryInsurance.planId;
                me.billinfo_MemberID.value =
                  response.data.billingInfo.primaryInsurance.memberID;
                me.billinfo_GroupID.value =
                  response.data.billingInfo.primaryInsurance.groupID;

                me.billinfo_SecondaryInsuranceCarrier.value =
                  response.data.billingInfo.secondaryInsurance.secondaryInsuranceCarrier;
                me.billinfo_SecondaryplanId.value =
                  response.data.billingInfo.secondaryInsurance
                    .secondaryplanId ||
                  response.data.billingInfo.secondaryInsurance
                    .secondaryPlanId ||
                  "";
                me.billinfo_SecondaryMemberID.value =
                  response.data.billingInfo.secondaryInsurance
                    .secondaryMemberID ||
                  response.data.billingInfo.secondaryInsurance
                    .secondarymemberID ||
                  "";
                me.billinfo_SecondaryGroupID.value =
                  response.data.billingInfo.secondaryInsurance
                    .secondaryGroupID ||
                  response.data.billingInfo.secondaryInsurance
                    .secondarygroupID ||
                  "";

                me.billinfo_FirstNameofInsured.value =
                  response.data.billingInfo.insuredInfo.nameOfInsured.firstName;
                me.billinfo_LastNameofInsured.value =
                  response.data.billingInfo.insuredInfo.nameOfInsured.lastName;
                me.billinfo_RelationshipInsured.value =
                  response.data.billingInfo.insuredInfo.relationshipInsured;
              }
 */
              /* me.OtherICD10Codes.value =
                response.data.tests[0].icd10Code.otherCodes;
              me.kitShip_PatOthersInfo.value =
                response.data.tests[0].kitInformation.otherInfo;
              me.kitShip_notes.value = response.data.tests[0].notes; */

              me.setState(
                {
                  reportDate,
                  // firstName: response.data.Patient.firstName,
                  // lastName: response.data.Patient.lastName,
                  // testOrdered: response.data.orderedTest,
                  // selectedPatient: _selectedPatient,
                  phyinfo_Organization_account:
                    response.data.Physician.organizationAccount,
                  phyinfo_first_name: response.data.Physician.firstName,
                  phyinfo_last_name: response.data.Physician.lastName,
                  phyinfo_email: response.data.Physician.email,
                  phyinfo_npi: response.data.Physician.npiNumber,
                  phyinfoC_address:
                    response.data.Physician.addresses[0].address_1,
                  phyinfoC_practiceLocation:
                    response.data.Physician.practiceLocation.locationName,
                  phyinfoC_city: response.data.Physician.addresses[0].city,
                  phyinfo_state: {
                    label: response.data.Physician.addresses[0].state,
                    value: response.data.Physician.addresses[0].state,
                    code: response.data.Physician.addresses[0].state,
                  },
                  phyinfo_state_save_value:
                    response.data.Physician.addresses[0].state,
                  phyinfoC_zipCode:
                    response.data.Physician.addresses[0].zipCode,
                  phyinfoC_Phone: response.data.Physician.addresses[0].phoneNo,
                  phyinfoC_fax: response.data.Physician.addresses[0].fax,
                  phyinfoC_Faxcopyto:
                    response.data.Physician.addresses[0].faxCopyTo,
                  buttonText: "Update",
                  patinfo_firstname: response.data.Patient.firstName,
                  patinfo_lastname: response.data.Patient.lastName,
                  patinfo_address: response.data.Patient.address.address_1,
                  patinfo_State: {
                    label: response.data.Patient.address.state,
                    value: response.data.Patient.address.state,
                    code: response.data.Patient.address.state,
                  },
                  patinfo_State_Save_value: response.data.Patient.address.state,
                  patinfo_city: response.data.Patient.address.city,
                  patinfo_zipcode: response.data.Patient.address.zipCode,
                  patinfo_PhoneNumber: response.data.Patient.phoneNo,
                  patinfo_emailaddress: response.data.Patient.email,
                  patinfo_dob: patinfo_dobFormatted,
                  patinfo_gender: response.data.Patient.gender,
                  patinfo_OtherEthnicitycity:
                    response.data.Patient.ethnicity &&
                      response.data.Patient.ethnicity.includes("Other")
                      ? response.data.Patient.ethnicity
                      : "",

                  //clinical info
                  clinicinfo_DoLastMentrualPeriod: lastMenstrualDate,

                  //billing info
                  billinfo_PrimaryInsuranceCarrier:
                    response.data.billingInfo.primaryInsurance
                      .primaryInsuranceCarrier,
                  billinfo_planId:
                    response.data.billingInfo.primaryInsurance.planID,
                  billinfo_MemberID:
                    response.data.billingInfo.primaryInsurance.memberID,
                  billinfo_GroupID:
                    response.data.billingInfo.primaryInsurance.groupID,
                  billinfo_SecondaryInsuranceCarrier:
                    response.data.billingInfo.secondaryInsurance
                      .secondaryInsuranceCarrier,
                  billinfo_SecondaryplanId:
                    response.data.billingInfo.secondaryInsurance
                      .secondaryPlanID,
                  billinfo_SecondaryMemberID:
                    response.data.billingInfo.secondaryInsurance
                      .secondaryMemberID,
                  billinfo_SecondaryGroupID:
                    response.data.billingInfo.secondaryInsurance
                      .secondaryGroupID,
                  billinfo_NameofInsured:
                    response.data.billingInfo.insuredInfo.nameofInsured,
                  billinfoC_dob: billinfoC_dobFormatted,
                  billinfo_FirstNameofInsured:
                    response.data.billingInfo.insuredInfo.nameOfInsured
                      .firstName,
                  billinfo_LastNameofInsured:
                    response.data.billingInfo.insuredInfo.nameOfInsured
                      .lastName,
                  billinfo_RelationshipInsured:
                    response.data.billingInfo.insuredInfo.relationshipInsured,
                  otherICD10Codes: response.data.tests[0].icd10Code.otherCodes,
                  kitShip_PatOthersInfo:
                    response.data.tests[0].kitInformation.otherInfo,
                  kitShip_notes: response.data.tests[0].notes,
                  updatedAt: response.data.updatedAt,
                },
                () => { }
              );

              //   this.state.patinfo_OtherEthnicitycity=

              var tempOtherCodes = response.data.tests[0].icd10Code.otherCodes;
              console.log("other", tempOtherCodes);
              //console.log()
              let displayOtherIcdCodes = tempOtherCodes.join(",");
              this.setState({
                otherICD10Codes: tempOtherCodes,
                otherICD10Code: displayOtherIcdCodes,
              });

              //Additional Biomarkers
              if (
                this.state.orderData.tests[0].ova1PlusBiomarkers.other ===
                "Ova1" ||
                this.state.orderData.tests[0].ova1PlusBiomarkers.other ===
                "VDS-125"
              )
                $("#Ova1__Ova1").prop("checked", true);

              if (
                this.state.orderData.tests[0].ova1PlusBiomarkers.GCP.length > 0
              ) {
                if (
                  this.state.orderData.tests[0].ova1PlusBiomarkers.GCP[0].includes(
                    "GCP-100"
                  )
                ) {
                  $("#germCellPanel_GCP100_LDH").prop("checked", true);
                  checkAllGCPChildBiomarkers();
                }

                this.state._assays_gcps.map((a, i) => {
                  console.log("#####a: ", a, "#####");
                  console.log(
                    "#####this.state.orderData.tests[0].ova1PlusBiomarkers.GCP: ",
                    this.state.orderData.tests[0].ova1PlusBiomarkers.GCP,
                    "#####"
                  );
                  if (
                    this.isExistTestCode(
                      a.name,
                      this.state.orderData.tests[0].ova1PlusBiomarkers.GCP
                    )
                    // this.state.orderData.tests[0].ova1PlusBiomarkers.GCP.includes(
                    //   a.name
                    // )
                  ) {
                    $(`#_gcp_assay_${i}`).prop("checked", true);
                  }
                });
              }
              if (
                this.state.orderData.tests[0].ova1PlusBiomarkers.MCP.length > 0
              ) {
                if (
                  this.state.orderData.tests[0].ova1PlusBiomarkers.MCP[0].includes(
                    "MCP-100"
                  )
                ) {
                  $("#germCellPanel_MCP100_CEA").prop("checked", true);
                  checkAllMCPChildBiomarkers();
                }

                this.state._assays_mcps.map((a, i) => {
                  if (
                    this.isExistTestCode(
                      a.name,
                      this.state.orderData.tests[0].ova1PlusBiomarkers.MCP
                    )
                    // this.state.orderData.tests[0].ova1PlusBiomarkers.MCP.includes(
                    //   a.name
                    // )
                  ) {
                    $(`#_mcp_assay_${i}`).prop("checked", true);
                  }
                });
              }

              //Billing information
              console.log("meidcareInsurance-------------------");
              console.log(this.state.orderData.billingInfo.medicare);
              console.log("meidcareInsurance-------------------");
              console.log(this.state.orderData.billingInfo.privateInsurance);
              if (this.state.orderData.billingInfo.medicare) {
                this.setState({ medicare: true });

                $("#billinfo_Medicare").prop("checked", true);
              }
              if (this.state.orderData.billingInfo.privateInsurance) {
                console.log("not private insurance");
                this.setState({ privateInsurance: true });

                console.log(this.state.privateInsurance);
              }
              if (this.state.orderData.billingInfo.patientSelfPay) {
                this.state.patientSelfPay = true;
              }
              if (this.state.orderData.billingInfo.medicaid) {
                this.setState({ medicaid: true });

                $("#billinfo_Medicaid").prop("checked", true);
              }
              if (this.state.orderData.billingInfo.orderingFacility) {
                this.setState({ orderingFacility: true });

                $("#billinfo_OrderingFacility").prop("checked", true);
              }

              if (this.state.orderData.billingInfo.freeTrial) {
                this.setState({ freeTrial: true });

                $("#billinfo_FreeTrial").prop("checked", true);
              }

              //Diagnosis Codes | ICD-10 Codes
              var diagonosisDataFromDb = [];
              if (this.state.orderData.tests.length > 0) {
                console.log(
                  "this.state.orderData.tests[0].icd10Code.codes: ",
                  this.state.orderData.tests[0].icd10Code.codes
                );
                if (this.state.orderData.tests[0].icd10Code.codes.length > 0) {
                  //ova1

                  if (
                    this.state.orderData.tests[0].icd10Code.codes.includes(
                      "N83.201"
                    )
                  ) {
                    diagonosisDataFromDb.push("N83.201");
                    $("#diagnosis_UnOcyst_rs").prop("checked", true);
                    // this.setState(diagnosis_unocys_rs.checked:true);
                    this.diagnosis_UnOcyst_rs.checked = true;
                  }
                  if (
                    this.state.orderData.tests[0].icd10Code.codes.includes(
                      "R19.00"
                    )
                  ) {
                    diagonosisDataFromDb.push("R19.00");
                    $("#diagnosis_IntraAbdPelSwell").prop("checked", true);
                    this.diagnosis_IntraAbdPelSwell.checked = true;
                  }
                  if (
                    this.state.orderData.tests[0].icd10Code.codes.includes(
                      "R19.05"
                    )
                  ) {
                    diagonosisDataFromDb.push("R19.05");
                    $("#diagnosis_Periumbilicalswell").prop("checked", true);
                    this.diagnosis_Periumbilicalswell.checked = true;
                  }
                  if (
                    this.state.orderData.tests[0].icd10Code.codes.includes(
                      "N83.202"
                    )
                  ) {
                    diagonosisDataFromDb.push("N83.202");
                    $("#diagnosis_UnOcyst_ls").prop("checked", true);
                    this.diagnosis_UnOcyst_ls.checked = true;
                  }
                  if (
                    this.state.orderData.tests[0].icd10Code.codes.includes(
                      "R19.03"
                    )
                  ) {
                    diagonosisDataFromDb.push("R19.03");
                    $("#diagnosis_lowerquadrantSwell").prop("checked", true);
                    this.diagnosis_lowerquadrantSwell.checked = true;
                  }
                  if (
                    this.state.orderData.tests[0].icd10Code.codes.includes(
                      "R19.09"
                    )
                  ) {
                    diagonosisDataFromDb.push("R19.09");
                    $("#diagnosis_intraabdominalpelvicswell").prop(
                      "checked",
                      true
                    );
                    this.diagnosis_intraabdominalpelvicswell.checked = true;
                  }
                  if (
                    this.state.orderData.tests[0].icd10Code.codes.includes(
                      "N83.209"
                    )
                  ) {
                    diagonosisDataFromDb.push("N83.209");
                    $("#diagnosis_UnOcyst").prop("checked", true);
                    this.diagnosis_UnOcyst.checked = true;
                  }
                  if (
                    this.state.orderData.tests[0].icd10Code.codes.includes(
                      "R19.04"
                    )
                  ) {
                    diagonosisDataFromDb.push("R19.04");
                    $("#diagnosis_LlQabdominalswelling").prop("checked", true);
                    this.diagnosis_LlQabdominalswelling.checked = true;
                  }
                } //end ova1
              }
              this.state.diagonisisCodes = diagonosisDataFromDb;

              if (this.state.orderData.tests.length > 1) {
                $("#covid_icd_codes").removeClass(" hidediv");
                $("#covid_icd_codes").addClass(" showdiv");

                if (this.state.orderData.tests[1].icd10Code.codes.length > 0) {
                  if (
                    this.state.orderData.tests[1].icd10Code.codes.includes(
                      "Z11.59"
                    )
                  ) {
                    $("#diagnosis_z1159").prop("checked", true);
                  }
                  if (
                    this.state.orderData.tests[1].icd10Code.codes.includes(
                      "Z03.818"
                    )
                  ) {
                    $("#diagnosis_z03818").prop("checked", true);
                  }
                }
              }
              //end Diagnosis Codes | ICD-10 Codes

              //Kit Shipping Information
              if (
                this.state.orderData.tests[0].kitInformation.drawType.length > 0
              ) {
                if (
                  this.state.orderData.tests[0].kitInformation.drawType.includes(
                    "My physician needs mobile phlebotomy"
                  )
                ) {
                  this.setState({
                    kitShip_PatMobPhlebotomy:
                      "My physician needs mobile phlebotomy",
                  });
                }
                if (
                  this.state.orderData.tests[0].kitInformation.drawType.includes(
                    "Patient will get blood draw at Quest PSC"
                  )
                ) {
                  this.setState({
                    kitShip_PatblooddrawQuest:
                      "Patient will get blood draw at Quest PSC",
                  });
                }
                if (
                  this.state.orderData.tests[0].kitInformation.drawType.includes(
                    "Other"
                  )
                ) {
                  this.setState({ kitShip_PatOthers: "Other" });
                }
              } //end Kit Shipping Information

              //researchConsent
              if (typeof this.state.orderData.researchConsent != "undefined") {
                if (this.state.orderData.researchConsent == "Yes") {
                  $("#researchConsent").prop("checked", true);
                }
              }

              //researchUseOnly
              if (typeof this.state.orderData.researchUseOnly != "undefined") {
                if (this.state.orderData.researchUseOnly == "Yes") {
                  $("#researchUseOnly").prop("checked", true);
                }
              }

              //certify Types certifyTypes
              if (typeof this.state.orderData.certifyTypes != "undefined") {
                if (this.state.orderData.certifyTypes.length > 0) {
                  if (
                    this.state.orderData.certifyTypes.includes(
                      "A healthcare provider"
                    )
                  ) {
                    $("#kitShip_healthcareProvider").prop("checked", true);
                  }
                  if (
                    this.state.orderData.certifyTypes.includes(
                      "An Aspira employee ordering on behalf of a healthcare provider"
                    )
                  ) {
                    $("#kitShip_employeehealthcareProvider").prop(
                      "checked",
                      true
                    );
                  }
                  if (
                    this.state.orderData.certifyTypes.includes(
                      "An Aspira partner ordering on behalf of a healthcare provider"
                    )
                  ) {
                    $("#kitShip_partnerhealthcareProvider").prop(
                      "checked",
                      true
                    );
                  }
                }
              }
              //certify Types certifyTypes end
            }
          );
        }
      }
    );
    // })
    // .catch((error) => {
    //     me.setState({
    //         loading: false,
    //     }, () => {
    //         console.log("error: ", error);
    //     });
    // })
  }
  onChangePatinfoDob = (date) => this.setState({ patinfo_dob: date });
  onChangeBillinfoCDob = (date) => {
    this.setState({ billinfoC_dob: date });
  };

  onChangeDoLastMentrualPeriod = (date) => {
    this.setState({ clinicinfo_DoLastMentrualPeriod: date });
  };

  onChange(event) {
    console.log("event.target.files: ", event.target.files[0]);
    me.setState(
      {
        profileImage: event.target.files[0],
        profileImagePreview: URL.createObjectURL(event.target.files[0]),
      },
      () => { }
    );
  }
  DiagnosisChecked = (val) => {
    var tempDiagnosis = this.state.diagonisisCodes;
    console.log("clicked the button");
    //console.log(val);
    console.log("vluew------------------------");

    if (tempDiagnosis.includes(val)) {
      console.log("unchecked---------------------------------");
      const index = tempDiagnosis.indexOf(val);

      console.log(index);

      if (index > -1) {
        tempDiagnosis.splice(index, 1);
      }
      this.setState({
        diagonisisCodes: tempDiagnosis,
      });
      console.log(this.state.diagonisisCodes);
    } else {
      console.log(this.state.diagonisisCodes);
      console.log("checked---------------------------------");
      tempDiagnosis.push(val);
      this.setState({
        diagonisisCodes: tempDiagnosis,
      });
      console.log(this.state.diagonisisCodes);
    }
    console.log("clicked the button");
  };
  handleSave = async () => {
    const formData = new FormData();

    // =====
    // new updated code > start
    // =====

    console.log("\n---");
    console.log("pulling data for update API call > start");
    console.log("---\n");
    console.log(
      "priiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiiii"
    );
    console.log(this.state.privateInsurance);
    // ----
    // Pull OVA1 test panel data
    // ----
    let ova1PlusBiomarkers = {
      GCP: [],
      MCP: [],
      other: {},
    };
    let inputs = [];

    if ($("#germCellPanel_GCP100_LDH").is(":checked")) {
      ova1PlusBiomarkers.GCP.push({
        code: $("#germCellPanel_GCP100_LDH").data("val"),
        qBenchId: $("#germCellPanel_GCP100_LDH").data("qbenchid"),
      });
    } else {
      inputs = $("#AdditionalBiomarkersForm #gcp_container2").find(":input");
      inputs.map((i, elem) => {
        let $elem = $(elem);

        const _type = $elem.attr("type");

        if (_type == "checkbox") {
          if ($elem.is(":checked")) {
            ova1PlusBiomarkers.GCP.push({
              code: $(elem).data("val"),
              qBenchId: $(elem).data("qbenchid"),
            });
          }
        }
      });
    }

    if ($("#germCellPanel_MCP100_CEA").is(":checked")) {
      ova1PlusBiomarkers.MCP.push({
        code: $("#germCellPanel_MCP100_CEA").data("val"),
        qBenchId: $("#germCellPanel_MCP100_CEA").data("qbenchid"),
      });
    } else {
      inputs = $("#AdditionalBiomarkersForm #mcp_container").find(":input");
      inputs.map((i, elem) => {
        let $elem = $(elem);

        const _type = $elem.attr("type");

        if (_type == "checkbox") {
          if ($elem.is(":checked")) {
            ova1PlusBiomarkers.MCP.push({
              code: $(elem).data("val"),
              qBenchId: $(elem).data("qbenchid"),
            });
          }
        }
      });
    }

    if ($(`#Ova1__Ova1`).is(":checked")) {
      let $ova1Checkbox = $(`#Ova1__Ova1`);
      ova1PlusBiomarkers.other = {
        qBenchId: $ova1Checkbox.data("qbenchid"),
        code: $ova1Checkbox.data("val"),
      };
    }

    console.log("\n---");
    console.log("ova1PlusBiomarkers: ", ova1PlusBiomarkers);
    console.log("---\n");

    // ----
    // Pull physician data
    // ----
    console.log("check physician data======================================");
    console.log(this.state.phyinfo_first_name);
    console.log(this.state.phyinfo_last_name);
    console.log(this.state.phyinfo_email);
    console.log(this.state.phyinfo_state);
    console.log(this.state.phyinfoC_address);
    console.log(this.state.phyinfoC_city);
    console.log(this.state.patinfo_OtherEthnicitycity.value);
    const physicianAddress = {
      legacyClientId: this.state.orderData.legacyClientId,
      locationName: "USA",
      address_1: this.state.phyinfoC_address,
      address_2: "",
      addressType: "Primary",
      city: this.state.phyinfoC_city,
      country: "USA",
      state: this.state.phyinfo_state_save_value,
      zipCode: this.state.phyinfoC_zipCode,
      phoneNo: this.state.phyinfoC_Phone,
      fax: this.state.phyinfoC_fax,
      faxCopyTo: this.state.phyinfoC_Faxcopyto,
    };
    const practiceLocation = {
      // legacyClientId: this.state.orderData.legacyClientId,
      locationName: this.state.phyinfoC_practiceLocation,
      address_1: this.state.phyinfoC_address,
      address_2: "",
      addressType: "Primary",
      city: this.state.phyinfoC_city,
      country: "USA",
      state: this.state.phyinfo_state_save_value,
      zipCode: this.state.phyinfoC_zipCode,
      phoneNo: this.state.phyinfoC_Phone,
      fax: this.state.phyinfoC_fax,
      faxCopyTo: this.state.phyinfoC_Faxcopyto,
    };
    console.log("practice location----------------------");
    console.log(practiceLocation);

    console.log("\n---");
    console.log("physicianAddress: ", physicianAddress);
    console.log("---\n");
    console.log("practiceLocation: " + this.state.phyinfoC_practiceLocation);
    const physician = {
      _id: this.physicianId,
      organizationAccount: this.state.phyinfo_Organization_account,
      firstName: this.state.phyinfo_first_name,
      lastName: this.state.phyinfo_last_name,
      email: this.state.phyinfo_email,
      npiNumber: this.state.phyinfo_npi,
      addresses: [physicianAddress],
      //  practiceLocationPlain:this.state.phyinfoC_practiceLocation,
      practiceLocation: {
        // legacyClientId: this.state.orderData.legacyClientId,
        locationName: "USA",
        address_1: this.state.phyinfoC_practiceLocation,
        address_2: "",
        addressType: "Primary",
        city: this.state.phyinfoC_city,
        country: "USA",
        state: this.state.phyinfo_state_save_value,
        zipCode: this.state.phyinfoC_zipCode,
        phoneNo: this.state.phyinfoC_Phone,
        fax: this.state.phyinfoC_fax,
        faxCopyTo: this.state.phyinfoC_Faxcopyto,
      },
    };
    console.log("physician: ", physician);

    console.log("\n---");
    console.log("physician: ", physician);
    console.log("---\n");

    // ----
    // Pull patient data
    // ----
    const patientEthnicity = [];

    if (patientEthnicity.length === 0) patientEthnicity.push("N/A");

    console.log("\n---");
    console.log("patientEthnicity array: ", patientEthnicity);
    console.log("---\n");

    const patient = {
      _id: this.patientId,
      email: this.state.patinfo_emailaddress,
      firstName: this.state.patinfo_firstname,
      lastName: this.state.patinfo_lastname,
      dob: moment(this.state.patinfo_dob).format("YYYY-MM-DD"),
      phoneNo: this.state.patinfo_PhoneNumber,
      address: {
        locationName: "USA",
        address_1: this.state.patinfo_address,
        address_2: "",
        addressType: "Primary",
        city: this.state.patinfo_city,
        country: "USA",
        state: this.state.patinfo_State_Save_value,
        zipCode: this.state.patinfo_zipcode,
      },

      ethnicity: this.state.patinfo_ethnicity.includes("Other")
        ? "Other" + this.state.patinfo_OtherEthnicitycity
        : this.state.patinfo_ethnicity /* patientEthnicity.join(","), */,

      gender: this.state.patinfo_gender,
    };

    console.log("\n---");
    console.log("patient: ", patient);
    console.log("---\n");

    // ----
    // Pull clinical data
    // ----

    const {
      clinicinfo_DoLastMentrualPeriod,
      clinicinfo_Menopausal_status,
      ultrasound_results_q02,
      clinicinfo_MassSize_longdime,
      clinicinfo_MassSize_Height,
      clinicinfo_MassSize_Height_unit,
      clinicinfo_MassSize_Weight,
      clinicinfo_MassSize_Weight_unit,
      clinicinfo_pain_abdomen_pelvis,
      days_per_month_q05_1,
      how_long_month_q05_2,
      clinicinfo_fullQuickly_unableToe,
      days_per_month_q06_1,
      how_long_month_q06_2,
      clinicinfo_Expabdominalbloat_IncrSize,
      days_per_month_q07_1,
      how_long_month_q07_2,
    } = this.state;

    let clinicalData = [
      {
        questionCode: "ova1_q01",
        answer: clinicinfo_Menopausal_status,
      },
      {
        questionCode: "ova1_q02",
        answer: ultrasound_results_q02,
      },
      {
        questionCode: "ova1_q03",
        answer: moment(clinicinfo_DoLastMentrualPeriod).format("YYYY-MM-DD"),
      },
      {
        questionCode: "ova1_q04",
        answer: clinicinfo_MassSize_longdime,
        subQuestion: [
          {
            questionCode: "ova1_q04_1",
            answer:
              clinicinfo_MassSize_Height +
              " " +
              clinicinfo_MassSize_Height_unit,
          },
          {
            questionCode: "ova1_q04_2",
            answer:
              clinicinfo_MassSize_Weight +
              " " +
              clinicinfo_MassSize_Weight_unit,
          },
        ],
      },
      clinicinfo_pain_abdomen_pelvis
        ? {
          questionCode: "ova1_q05",
          answer: true,
          subQuestion: [
            {
              questionCode: "ova1_q05_1",
              answer: days_per_month_q05_1,
            },
            {
              questionCode: "ova1_q05_2",
              answer: how_long_month_q05_2,
            },
          ],
        }
        : {
          questionCode: "ova1_q05",
          answer: false,
        },
      clinicinfo_fullQuickly_unableToe
        ? {
          questionCode: "ova1_q06",
          answer: true,
          subQuestion: [
            {
              questionCode: "ova1_q06_1",
              answer: days_per_month_q06_1,
            },
            {
              questionCode: "ova1_q06_2",
              answer: how_long_month_q06_2,
            },
          ],
        }
        : {
          questionCode: "ova1_q06",
          answer: false,
        },
      clinicinfo_Expabdominalbloat_IncrSize
        ? {
          questionCode: "ova1_q07",
          answer: true,
          subQuestion: [
            {
              questionCode: "ova1_q07_1",
              answer: days_per_month_q07_1,
            },
            {
              questionCode: "ova1_q07_2",
              answer: how_long_month_q07_2,
            },
          ],
        }
        : {
          questionCode: "ova1_q07",
          answer: false,
        },
    ];

    console.log("sm clinicalData: ", clinicalData);
    // return
    // ----
    // Pull billing data
    // ----
    console.log(
      "=======================================dddddddddddddddddhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhv         vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvd"
    );
    console.log(this.state.privateInsurance);
    console.log(this.state.patientSelfPay);
    console.log(this.state.freeTrial);
    console.log(this.state.orderingFacility);

    let billingInfo = this.state.orderData.billingInfo;
    console.log(
      "BillingInfo IsBiallabale================================================"
    );
    console.log(this.state.isBillableOrder);
    // return;

    if (this.state.isBillableOrder) {
      /*  this.setState({
        privateInsurance:billingInfo.primaryInsurance,
        medicare:billingInfo.medicare,
        patientSelfPay:billingInfo.patientSelfPay,
        meidcaid:billingInfo.medicaid,
        freeTrial:billingInfo.freeTrial,
        orderingFacility:billingInfo.orderingFacility

      }); */
      console.log(
        "Billign cheks result==========================================="
      );
      const privateInsurance = this.state.privateInsurance;
      const medicare = this.state.medicare;
      const patientSelfPay = this.state.patientSelfPay;
      const medicaid = this.state.medicaid;
      const freeTrial = this.state.freeTrial;
      const orderingFacility = this.state.orderingFacility;
      console.log(privateInsurance);
      console.log(medicare);
      console.log(
        "Billign cheks result==========================================="
      );
      // return;
      billingInfo = {
        privateInsurance,
        medicare,
        patientSelfPay,
        medicaid,
        freeTrial,
        orderingFacility,
        primaryInsurance: {
          primaryInsuranceCarrier: this.state.billinfo_PrimaryInsuranceCarrier,
          memberID: this.state.billinfo_MemberID,
          groupID: this.state.billinfo_GroupID,
          planID: this.state.billinfo_planId,
        },
        secondaryInsurance: {
          secondaryInsuranceCarrier:
            this.state.billinfo_SecondaryInsuranceCarrier,
          secondaryMemberID: this.state.billinfo_SecondaryMemberID,
          secondaryGroupID: this.state.billinfo_SecondaryGroupID,
          secondaryPlanID: this.state.billinfo_SecondaryplanId,
        },
        tertiaryInsurance: {
          tertiaryInsuranceCarrier: "",
          tertiaryMemberID: "",
          tertiaryGroupID: "",
          tertiaryPlanID: "",
        },
        insuredInfo: {
          nameOfInsured: {
            firstName: this.state.billinfo_FirstNameofInsured,
            lastName: this.state.billinfo_LastNameofInsured,
          },
          dob: moment(this.state.billinfoC_dob).format("YYYY-MM-DD"),
          relationshipInsured: this.state.billinfo_RelationshipInsured,
        },
        notesText: this.state.notesText || "",
      };
      console.log("after pushing billing info=========================");
      console.log(billingInfo);
      //return;
      const isFirstNameMissing =
        !billingInfo.insuredInfo.nameOfInsured.firstName;
      const isLastNameMissing = !billingInfo.insuredInfo.nameOfInsured.lastName;
      const isDOBMissing =
        billingInfo.insuredInfo.dob === "Invalid date" ||
        !billingInfo.insuredInfo.dob;

      console.log("\n---");
      console.log("do person: ", billingInfo.insuredInfo.dob);
      console.log("---\n");

      if (
        (privateInsurance || medicare || medicaid) &&
        (isFirstNameMissing || isLastNameMissing || isDOBMissing)
      ) {
        if (isFirstNameMissing)
          message.warning("Insured person first name is missing");
        if (isLastNameMissing)
          message.warning("Insured person last name is missing");
        if (isDOBMissing)
          message.warning("Insured person date of birth is missing");

        return;
      }
    }

    console.log("\n---");
    console.log("billingInfo: ", billingInfo);
    console.log("---\n");

    // ----
    // Pull diagnosis codes data
    // ----
    this.state.otherICD10Codes = this.state.otherICD10Code.split(",");
    var icd10Code = {
      codes: this.state.diagonisisCodes,
      otherCodes: this.state.otherICD10Codes,
    };
    this.state.otherICD10Codes = this.state.otherICD10Code.split(",");
    console.log("ohter10code array spit success------");
    console.log(this.state.otherICD10Codes);

    inputs = $(`#${this.state.frmprefix}DiagnosisCodes`).find(":input");
    /* inputs.map((input, i) => {
      const $input = $(inputs[input]);
      console.log(
        "$input.attr('id'): ",
        $input.attr("id"),
        ", $input.attr('type'): ",
        $input.attr("type")
      );

      const _type = $input.attr("type");

      if (_type == "checkbox") {
        if ($input.is(":checked")) {
          icd10Code.codes.push($input.data("val"));
        }
      } else if (_type == "text") {
        icd10Code.otherCodes.push($input.val());
      }
    }); */

    console.log("\n---");
    console.log("icd10Code: ", icd10Code);
    console.log("---\n");

    const {
      kitShip_PatMobPhlebotomy,
      kitShip_PatblooddrawQuest,
      kitShip_PatOthers,
      kitShip_PatOthersInfo,
    } = this.state;

    const kitInformation = {
      drawType: [
        kitShip_PatMobPhlebotomy,
        kitShip_PatblooddrawQuest,
        kitShip_PatOthers,
      ].filter((item) => item !== null),
      otherInfo: kitShip_PatOthersInfo,
    };

    console.log("\n---");
    console.log("sm kitInformation: ", kitInformation);
    console.log("---\n");

    // ----
    // Pull notes & certify data
    // ----

    const kitShippingNotes = this.state.kitShip_notes;

    const certifyTypes = [];

    if ($("#kitShip_healthcareProvider").is(":checked"))
      certifyTypes.push($("#kitShip_healthcareProvider").data("val"));
    if ($("#kitShip_employeehealthcareProvider").is(":checked"))
      certifyTypes.push($("#kitShip_employeehealthcareProvider").data("val"));
    if ($("#kitShip_partnerhealthcareProvider").is(":checked"))
      certifyTypes.push($("#kitShip_partnerhealthcareProvider").data("val"));

    // get the user response related to user's consent about the use of his/her data for research purpose
    const researchConsent = $(`#researchConsent`).is(":checked") ? "Yes" : "No";
    const researchUseOnly = $(`#researchUseOnly`).is(":checked") ? "Yes" : "No";

    console.log("\n---");
    console.log("kitShippingNotes: ", kitShippingNotes);
    console.log("---");
    console.log("certifyTypes: ", certifyTypes);
    console.log("---");
    console.log("researchConsent: ", researchConsent);
    console.log("---");
    console.log("researchUseOnly: ", researchUseOnly);
    console.log("---\n");

    // ----
    // Gather all data together for update API payload
    // ----
    const order = {
      _id: this.state.orderData._id.toString(),
      orderId: this.state.orderData.orderId,
      Physician: physician,
      Patient: patient,
      billingInfo,
      tests: [
        {
          ova1PlusBiomarkers,
          icd10Code,
          kitInformation,
          notes: kitShippingNotes,
          clinicalData,
          testCode: this.state.orderData.tests[0].testCode ?? "Ova1",
          testName: this.state.orderData.tests[0].testName ?? "Ova1",
        },
      ],
      certifyTypes,
      payorId: this.state.selectedPayor?.value,
      researchConsent,
      researchUseOnly,

      updatedAt: this.state.updatedAt,
    };

    console.log("\n---");
    console.log("order:@@@@@@@@@@@@@ ", order);
    console.log("---\n");

    formData.append("order", JSON.stringify(order));
    formData.append("host", window.location.host);

    console.log("me._id: ", me._id);

    const accessToken = await helper.getAccessToken(this.context)

    if (me._id) {
      axios
        .put(`${API_BASE_URL}/orders`, formData, {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        })
        .then(({ data }) => {
          console.log("data: ", data);

          this.setState(
            {
              toastMessage: `OVA1 order updated Successfully`,
            },
            () => {
              // this.showToast("success");
              message.success(this.state.toastMessage);

              setTimeout(() => {
                const params = new URLSearchParams(window.location.search);
                let returnurl = params.get("returnurl");

                console.log("returnurl: ", returnurl);
                if (process.env.REACT_APP_ENV !== "DEV") {
                  window.location.href = returnurl;
                }
              }, 1000);
            }
          );
        })
        .catch((error) => {
          message.error(error.message);
        });
    } else {
      axios
        .post(`${API_BASE_URL}/orders`, formData, {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        })
        .then(({ data }) => {
          console.log("data: ", data);

          this.setState(
            {
              toastMessage: `Successfully added order`,
            },
            () => {
              // this.showToast("success");
              message.success(this.state.toastMessage);
            }
          );
        });
    }
    // =====
    // new updated code < end
    // =====
  };
  showToast(type) {
    const id = Math.floor(Math.random() * 101 + 1);

    switch (type) {
      case "success":
        this.toastProperties = {
          id,
          title: "Success",
          description: "This is a success toast component",
          backgroundColor: "#5cb85c",
          icon: "/assets/assets/check.svg",
        };
        break;
      case "danger":
        this.toastProperties = {
          id,
          title: "Danger",
          description: "This is a error toast component",
          backgroundColor: "#d9534f",
          icon: "/assets/assets/error.svg",
        };
        break;
      case "info":
        this.toastProperties = {
          id,
          title: "Info",
          description: "This is an info toast component",
          backgroundColor: "#5bc0de",
          icon: "/assets/assets/info.svg",
        };
        break;
      case "warning":
        this.toastProperties = {
          id,
          title: "Warning",
          description: "This is a warning toast component",
          backgroundColor: "#f0ad4e",
          icon: "/assets/assets/warning.svg",
        };
        break;

      default:
        this.setState({ toastList: [] });
    }

    // this.setState([...this.state.list, this.toastProperties]);
    this.setState({ list: [this.toastProperties] });
  }

  takeNotes(notesText) {
    this.setState({ notesText: notesText });
  }

  getDefaultPayorIdOptions = () => {
    return this.state.allPayors.slice(0, 20);
  };

  getPayorIdOptionsBySearchText = async (searchText) => {
    let payorIdOptions = this.state.allPayors.filter((payor) =>
      payor.label.toLowerCase().includes(searchText.toLowerCase())
    );

    // if the payorIdOptions array contains many elements then the Select element becomes laggy
    // so I'm cutting down the array size if there are too many elements - Khairul
    const numberOfOptions = payorIdOptions.length;

    console.log("\n===");
    console.log("original options count: ", numberOfOptions);

    if (numberOfOptions > 100) {
      payorIdOptions = payorIdOptions.slice(0, 99);
    }

    console.log("Options count after trimming: ", payorIdOptions.length);
    console.log("===\n");

    return payorIdOptions;
  };

  updatePrimaryInsuranceCarrierAndSelectedPayor = (inputPayor) => {
    this.billinfo_PrimaryInsuranceCarrier.value = inputPayor.value;
    this.setState({ selectedPayor: inputPayor });
  };

  renderPayorListDropdownForPrimaryInsuranceCarrierSelection = () => {
    return (
      <div
        class="col-sm-10 col-xs-12 mb-3 hidediv"
        id="payorListSearchAndSelect"
      >
        <div class="form-group drop-custum">
          <label class="color-green" htmlFor="role">
            Search Primary Carrier:
          </label>
          <small className="color-red">*</small>
          <AsyncSelect
            value={this.state.selectedPayor}
            defaultValue={""}
            label="Search Primary Carrier:"
            cacheOptions
            defaultOptions={this.getDefaultPayorIdOptions()}
            loadOptions={this.getPayorIdOptionsBySearchText}
            styles={helper.colourStyles}
            onChange={this.updatePrimaryInsuranceCarrierAndSelectedPayor}
          />
        </div>
      </div>
    );
  };

  render() {
    const { orderData } = this.state;

    if (this.state.loading) {
      return null;
    }

    return (
      <div>
        {
          <OrderHeader
            orderId={this.state.orderData.orderId}
            orderStatus={this.state.orderData.status}
          />
        }
        {console.log("Order: ", this.state.orderData)}

        <div
          class="panel-group card"
          id="accordion_105"
          role="tablist"
          aria-multiselectable="true"
        >
          <Toast
            toastList={me.state.list}
            position={"top-center"}
            autoDelete={true}
            dismissTime={3000}
            description={me.state.toastMessage}
          />

          {/* Top Actions */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">Actions</h4>
            </div>
          </div>
          <TopActions
            orderId={this.orderId}
            reportId={reportId}
            ova1ReportId={ova1ReportId}
            galenReportId={me.state.orderData.galenReportId}
            context={this.context}
            orderData={this.state.orderData}
            reportDate={this.state.reportDate}
          />
          {/* baylor */}
          {this.state.orderData.baylorStatus &&
            this.state.orderData.baylorStatus.length > 0 && (
              <>
                <div class="row clearfix">
                  <div class="col-md-12">
                    <h4 class="panel-titlepdf">Baylor</h4>
                  </div>
                </div>
                <div class="body card">
                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div class="form-group required">
                        <label class="form-label" for="baylor">
                          Baylor Status: {this.state.orderData.baylorStatus}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          {/* Start Additional Biomarkers */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">OVA1</h4>
            </div>
          </div>
          <div id="AdditionalBiomarkersForm" className="body card">
            <div class="panel-body">
              <div id="printHere" class=""></div>
            </div>
          </div>
          {/* end Additional Biomarkers */}
          {/* OVA1 Physician Information */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">Physician Information</h4>
            </div>
          </div>
          <PhysicianInformation
            state={this.state}
            me={this}
            phyinfo_state={this.state.phyinfo_state}
          />
          {/* OVA1 Physician Information end */}
          {/* OVA1 Patient Information */}
          {/* {this.state.orderData.Patient.email} */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">Patient Information</h4>
            </div>
          </div>
          <PatientInformation state={this.state} me={this} />
          {/* OVA1 Patient Information end */}
          {/* OVA1 Clinical Information -> start*/}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">Clinical Information</h4>
            </div>
          </div>

          <Clinical_Information state={this.state} me={this} />

          {/* OVA1 Clinical Information -< end*/}
          {/* OVA1 Billing Information */}
          {this.state.isBillableOrder && (
            <>
              <div class="row clearfix">
                <div class="col-md-12">
                  <h4 class="panel-titlepdf">Billing Information</h4>
                </div>
              </div>
              <div
                id={`${this.state.frmprefix}BillingInformation`}
                class="body card"
              >
                <Billing_Information state={this.state} me={this} />
                <CmtForm
                  order={me.state.orderData}
                  handleSaveFunc={this.handleSave.bind(this)}
                  takeNotesFunc={this.takeNotes.bind(this)}
                />
                <BillingNotes
                  order={me.state.orderData}
                  handleSaveFunc={this.handleSave.bind(this)}
                  takeNotes={this.takeNotes.bind(this)}
                />
                <div class="row clearfix">
                  <div class="col-md-12">
                    <div class="form-group">
                      <button
                        id={`btnSaveBillingInfo`}
                        placeholder="Update billing info"
                        name="Submit"
                        className="btn btn-primary text-white"
                        onClick={this.handleSave.bind(this)}
                      >
                        Update billing info
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {/* OVA1 Billing Information end */}
          {/* OVA1 Diagnosis Codes | ICD-10 Codes */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">Diagnosis Codes | ICD-10 Codes</h4>
            </div>
          </div>
          <Diagnosis_Code me={this} state={this.state} />
          {/* OVA1 Diagnosis Codes | ICD-10 Codes end */}
          {/* OVA1 Kit Shipping Information */}
          <div class="row clearfix">
            <div class="col-md-12">
              <h4 class="panel-titlepdf">Kit Shipping Information</h4>
            </div>
          </div>
          <Kit_Shipping_Information
            state={this.state}
            me={this}
            for="AddEditOrderContent"
          />
          {/* OVA1 Kit Shipping Information end*/}
          {/* OVA1 Kit Notes & Certify */}
          <Notes_and_Certify
            me={this}
            state={this.state}
            handleSave={this.handleSave.bind(this)}
            orderId={this.orderId}
            token={ovaAccessToken}
            from={'ova1'}
            prefix={this.state.frmprefix}
          />

        </div>
      </div>
    );
  }
}
