import React, { PureComponent, useState, useEffect, useContext, createRef } from "react";

import PropTypes from 'prop-types';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

//includes all forms here
import PhysicianInformationForm from "./PhysicianInformationForm";
import PatientInformationForm from "./PatientInformationForm";
import ClinicalInformationForm from "./ClinicalInformationForm";
import BillingInformationForm from "./BillingInformationForm";
import DiagnosisCodes_ICD10CodesForm from "./DiagnosisCodes_ICD10CodesForm";
import OtherICD10CodesForm from "./OtherICD10CodesForm";
import OVA1plusbiomarkersForm from "./OVA1plusbiomarkersForm";
import KitShippingInfoForm from "./KitShippingInfoForm";
import NotesCertifyForm from "./NotesCertifyForm";
import Toast from './../portalComponents/toast/Toast';
import axios from "axios";
import helper from './../utils/helperPortal';


import { Card, Accordion, Button } from "react-bootstrap";

import { createPhysicianApi } from "./../context/actions/physiciansActions";
import { createPatientApi, createPatients, createOva1OrderApi } from "./../context/actions/patientActions";

import { AppContext } from "./../context";
import { prefix } from "@fortawesome/free-solid-svg-icons";
import { message } from "antd";

// import jsPDF from 'jspdf';
// import html2canvas from 'html2canvas';

const api_base_url = helper.getAPIBaseURI();



const Ova1PlusFormContent = (props) => {
  let sigPad = createRef(null);
  let selectedPhysicianLegacyInfo = null;

  let containerRef = createRef();

  let ova1_prefix = `ova1_`;
  const patientPhoneNo_prefix = "patient_";
  const physicianAddressContext = useContext(AppContext);

  const { patientDOB } = useContext(AppContext);
  const { physicianAddresses, selectedPhysicianAddress, physicianPracticeLocation, selectedPhysicianPracticeLocation } = physicianAddressContext;

  const [physicianSignature, setPhysicianSignature] = useState("");

  let toastProperties = {};
  const [toastList, setToastList] = useState([])
  const [toastMessage, setToastMessage] = useState("");

  const [patientDOBLocal, setPatientDOBLocal] = useState("");

  const [billinfoOVA1_dobLocal, setBillinfoOVA1_dobLocal] = useState("");

  const [physicianState, setPhysicianState] = useState("");
  const [patientState, setPatientState] = useState("");

  const [clinicalInfoLastMenstrualPeriodDate, setClinicalInfoLastMenstrualPeriodDate] = useState("");

  const [render, setRender] = useState(0);
  const [assays, setAssays] = useState([]);

  const [selectedPhysicianLegacyInfoState, setSelectedPhysicianLegacyInfoState] = useState(null); //need-1
  const showToast = (type) => {

    const id = Math.floor((Math.random() * 101) + 1);

    switch (type) {
      case 'success':
        toastProperties = {
          id,
          title: 'Success',
          description: 'This is a success toast component',
          backgroundColor: '#5cb85c',
          icon: "/assets/toast/check.svg"
        }
        break;
      case 'danger':
        toastProperties = {
          id,
          title: 'Danger',
          description: 'This is a error toast component',
          backgroundColor: '#d9534f',
          icon: "/assets/toast/error.svg"
        }
        break;
      case 'info':
        toastProperties = {
          id,
          title: 'Info',
          description: 'This is an info toast component',
          backgroundColor: '#5bc0de',
          icon: "/assets/toast/info.svg"
        }
        break;
      case 'warning':
        toastProperties = {
          id,
          title: 'Warning',
          description: 'This is a warning toast component',
          backgroundColor: '#f0ad4e',
          icon: "/assets/toast/warning.svg"
        }
        break;

      default:
        setToastList([]);
    }

    setToastList([toastProperties]);
  }
  const loadInfo = async () => {
    // dispatch(fetchNewTime());

    const ova1_checked = $('#product_1').is(':checked');
    const covid_checked = $('#product_4').is(':checked');

    if (ova1_checked && covid_checked) {
      ova1_prefix = 'ova1_covid_'
    }
    else if (ova1_checked) {
      ova1_prefix = 'ova1_'
    }

    setRender(1);

  };
  React.useEffect(() => {
    loadInfo();
  }, []);

  $(document).ready(() => {

    // 

    $(`#${ova1_prefix}btnPatientInfoFormSubmit`)
      .off('click')
      .on('click', async function () {
        helper.generatePDF((file) => {
          var fd = new FormData();     // To carry on your data  
          fd.append('mypdf', file);

          const data = {
            orderId: `order0001`
          };
          fd.append('data', JSON.stringify(data));
          $.ajax({
            url: `${api_base_url}/orders/pdf`,   //here is also a problem, depends on your 
            data: fd,           //backend language, it may looks like '/model/send.php'
            dataType: 'text',
            processData: false,
            contentType: false,
            type: 'POST',
            success: function (response) {
              console.log('Exit to send request');
              if (response.isok) {

                message.success(`Order Created Successfully.`);

              }
            },
            error: function (jqXHR) {
              console.log('Failure to send request');
            }
          });
        });

      });

    $(`#${ova1_prefix}btnOrderSubmit`).off('click').on('click', async function () {


      var $this = $(`#${ova1_prefix}btnOrderSubmit`);

      if (helper.isAccessingFromAspiraLab()) {
        if (!ova1PlusBiomarkersFormNextFunc()) {
          $('#OVA1plusbiomarkersForm').addClass(' show');
          $('#NotesCertifyForm').removeClass(' show');
          return;
        } else if (!ova1PhysicianInfoFormNextFunc()) {
          $('#PhysicianInformationForm').addClass(' show')
          $('#NotesCertifyForm').removeClass(' show');
          return;
        }
        else if (!ova1PatientInfoFormNextFunc()) {
          $('#PatientInformationForm').addClass(' show');
          $('#NotesCertifyForm').removeClass(' show');
          return;
        }
      } else {
        if (!ova1PlusBiomarkersFormNextFunc()) {
          $('#OVA1plusbiomarkersForm').addClass(' show');
          $('#NotesCertifyForm').removeClass(' show');
          return;
        } else if (!ova1PhysicianInfoFormNextFunc()) {
          $('#PhysicianInformationForm').addClass(' show')
          $('#NotesCertifyForm').removeClass(' show');
          return;
        }
        else if (!ova1PatientInfoFormNextFunc()) {
          $('#PatientInformationForm').addClass(' show');
          $('#NotesCertifyForm').removeClass(' show');
          return;
        } else if (!ova1ClinicalInfoFormNextFunc()) {
          $('#ClinicalInformationForm').addClass(' show');
          $('#NotesCertifyForm').removeClass(' show');
          return;
        } else if (!ova1BillingInfoFormNextFunc()) {
          //return;
          $('#BillingInformationForm_10').addClass(' show')
          $('#NotesCertifyForm').removeClass(' show')
          return;
        } else if (!ova1DiagICD10CodesInfoFormNextFunc()) {
          //return;
          $('#DiagnosisCodes_ICD10CodesForm').addClass(' show')
          $('#NotesCertifyForm').removeClass(' show')
          return;
        } else if (!ova1KitShippingInfoFormNextFunc()) {
          //return;        
          $('#KitShippingInfoForm').addClass(' show')
          $('#NotesCertifyForm').removeClass(' show')
          return;
        }
      }

      $this.html($this.data('loading-text'));
      $(`#${ova1_prefix}btnOrderSubmit`).prop("disabled", true);

      //phyinfo_account_number
      let phyinfo_ordered_before = '';
      if ($(`#${ova1_prefix}ordered_before1`).is(":checked")) {
        phyinfo_ordered_before = $(`#${ova1_prefix}ordered_before1`).data('val');
      }
      if ($(`#${ova1_prefix}ordered_before2`).is(":checked")) {
        phyinfo_ordered_before = $(`#${ova1_prefix}ordered_before2`).data('val');
      }

      const phyinfo_account_number = $(`#${ova1_prefix}phyinfo_account_number`).val();
      const phyinfo_phyinfo_phone = $(`#${ova1_prefix}phyinfo_phone_ph_areacode`).val() + ' ' + $(`#${ova1_prefix}phyinfo_phone_ph_prefix`).val() + $(`#${ova1_prefix}phyinfo_phone_ph_suffix`).val();

      let physician = {};
      let legacyCustomerQbenchId = {};
      if ($(`#${ova1_prefix}ordered_before1`).is(":checked")) {

        let physicianRet = await helper.createPhysicianFromClientId(selectedPhysicianLegacyInfo);

        physician = physicianRet.physician;
        legacyCustomerQbenchId = physicianRet.legacyCustomerQbenchId;
      }
      else {
        const phyinfo_Organization_account = $('#ova1_phyinfo_Organization_account').val();
        const phyinfo_first_name = $('#ova1_phyinfo_first_name').val();
        const phyinfo_last_name = $('#ova1_phyinfo_last_name').val();
        const phyinfo_npi = $('#ova1_phyinfo_npi').val();
        const phyinfo_email = $('#ova1_phyinfo_email').val();

        const phyinfo_address = $('#ova1_phyinfoC_address').val();
        const phyinfo_city = $('#ova1_phyinfoC_city').val();
        const phyinfo_state = physicianState;
        const phyinfo_zipCode = $('#ova1_phyinfoC_zipCode').val();
        const phyinfo_phone = $('#ova1_phyinfoC_ph_areacode').val() + ' ' + $('#ova1_phyinfoC_ph_prefix').val() + $('#ova1_phyinfoC_ph_suffix').val()
        const phyinfo_fax = $('#ova1_phyinfoC_fax').val();
        const phyinfo_Faxcopyto = $('#ova1_phyinfoC_Faxcopyto').val();
        const phyinfo_confirmation = $('#ova1_phyinfoC_confirmation').val();

        const phyinfoC_practiceLocation = $('#ova1_phyinfoC_practiceLocation').val();


        const addresses = [];
        const practiceLocation = [];

        const licenses = [];

        console.log("physicianAddresses: ", physicianAddresses)
        // if (typeof physicianAddresses == "undefined" || physicianAddresses.length < 1) {
        //   return;
        // }

        let data = {
          organizationAccount: phyinfo_Organization_account,
          firstName: phyinfo_first_name,
          lastName: phyinfo_last_name,
          npiNumber: phyinfo_npi,
          email: phyinfo_email,
          addresses: [],
          address_type: "Primary",
          practiceLocation: {
          },
          licenses: licenses,
          registeredUser: "",
        }
        if (physicianAddresses && physicianAddresses.length < 1) {

          addresses.push({
            locationName: "USA",
            address_1: phyinfo_address,
            address_2: "",
            addressType: "Primary",
            city: phyinfo_city,
            country: "USA",
            state: phyinfo_state,
            zipCode: phyinfo_zipCode,
            phoneNo: phyinfo_phone,
            fax: phyinfo_fax,
            fax_copyto: phyinfo_Faxcopyto,

          });

          data.addresses = addresses;
          data.practiceLocationPlain = phyinfoC_practiceLocation;
        }
        else {

          physicianAddresses.map((a) => {
            addresses.push({
              locationName: "USA",
              address_1: a.address_1,
              address_2: a.address_1,
              addressType: a.address_type,
              city: a.city,
              country: a.country_name,
              state: a.state,
              zipCode: a.postal_code,
              phoneNo: a.telephone_number,
            });
          });

          data.addresses = addresses;
          data.practiceLocationPlain = phyinfoC_practiceLocation;
        }


        // console.log("physicianAddresses: ", physicianAddresses);
        console.log("data: ", data);


        let physicianRet = await createPhysicianApi(data);
        console.log("physicianRet: ", physicianRet);
        physician = physicianRet.physician;
        legacyCustomerQbenchId = physicianRet.legacyCustomerQbenchId;
      }
      console.log("physician: ", physician);

      if (physician.isok) {
        $this.html('Done');
        $(`#${ova1_prefix}btnOrderSubmit`).prop("disabled", true);
        message.success(`Physician Created Successfully. Creating Patient`);


        const patinfo_firstname = $('#ova1_patinfo_firstname').val();
        const patinfo_lastname = $('#ova1_patinfo_lastname').val();
        const patinfo_address1 = $('#ova1_patinfo_address1')?.val();
        const patinfo_address2 = $('#ova1_patinfo_address2')?.val();
        const patinfo_city = $('#ova1_patinfo_city')?.val();
        const patinfo_state = patientState;
        const patinfo_zipcode = $('#ova1_patinfo_zipcode')?.val();
        const patinfo_PhoneNumber = $("#ova1_patinfo_ph_areacode")?.val() + $("#ova1_patinfo_ph_prefix")?.val() + $("#ova1_patinfo_ph_suffix")?.val()
        const patinfo_emailaddress = $('#ova1_patinfo_emailaddress')?.val();
        const patinfo_DOB = patientDOBLocal;

        const ethnicity = [];

        if ($("#ova1_patinfo_Caucasian").is(":checked")) {
          ethnicity.push($("#ova1_patinfo_Caucasian").data('val'));
        }
        if ($("#ova1_patinfo_AshkenaziJewish").is(":checked")) {
          ethnicity.push($("#ova1_patinfo_AshkenaziJewish").data('val'));
        }
        if ($("#ova1_patinfo_SephardicJewish").is(":checked")) {
          ethnicity.push($("#ova1_patinfo_SephardicJewish").data('val'));
        }
        if ($("#ova1_patinfo_Asian").is(":checked")) {
          ethnicity.push($("#ova1_patinfo_Asian").data('val'));
        }
        if ($("#ova1_patinfo_HIspanic").is(":checked")) {
          ethnicity.push($("#ova1_patinfo_HIspanic").data('val'));
        }
        if ($("#ova1_patinfo_NativeAmerican").is(":checked")) {
          ethnicity.push($("#ova1_patinfo_NativeAmerican").data('val'));
        }
        if ($("#ova1_patinfo_AfricanAmerican").is(":checked")) {
          ethnicity.push($("#ova1_patinfo_AfricanAmerican").data('val'));
        }
        if ($("#ova1_patinfo_Other").is(":checked")) {
          ethnicity.push($("#ova1_patinfo_Other").data('val'));
          ethnicity.push($("#ova1_patinfo_OtherEthnicitycity").val());
        }


        let gender = "";
        // $(`#ova1_patinfo_gender_div`).find('input').map((elem, i) => {
        //   if ($(elem).is(":checked")) {
        //     gender = $(elem).val();
        //   }
        // });

        if ($(`#${ova1_prefix}patinfo_gender`).is(":checked")) {
          gender = 'male';
        } else {
          gender = 'female';
        }


        let data = {
          email: patinfo_emailaddress,
          firstName: patinfo_firstname,
          lastName: patinfo_lastname,
          address: {
            locationName: "USA",
            address_1: patinfo_address1,
            address_2: patinfo_address2,
            addressType: "",
            city: patinfo_city,
            country: "USA",
            state: patientState,
            zipCode: patinfo_zipcode,
          },
          dob: patinfo_DOB,
          phoneNo: patinfo_PhoneNumber,

          ethnicity: ethnicity.join(","),
          registeredUser: "",
          gender: gender,
        }

        console.log("Before Submit data: ", data);

        $this.html("<img style='height: 30px;' src='/assets/images/Spinner-1s-200px.svg' /> Creating Patient")
        $(`#${ova1_prefix}btnOrderSubmit`).prop("disabled", true);

        await helper.sleep(2000);
        let patient = await createPatientApi(data);
        console.log("patient: ", patient);

        if (patient.isok) {

          let billing_info = {
            primaryInsurance: {},
            secondaryInsurance: {},
            tertiaryInsurance: {},
            insuredInfo: {
              nameOfInsured: {
                firstName: "",
                lastName: "",
              },
              dob: "",
              relationshipInsured: "",
            },
          };

          if (helper.isAccessingFromAspiraLab()) {
            billing_info = {
              freeTrial: false,
              medicaid: false,
              medicare: false,
              orderingFacility: false,
              patientSelfPay: false,
              privateInsurance: false,
              insuredInfo: {
                dob: "",
                nameOfInsured: { firstName: "", lastName: "" },
                relationshipInsured: "",
              },
              primaryInsurance: {
                groupID: "",
                memberID: "",
                primaryInsuranceCarrier: ""
              },
              secondaryInsurance: {
                secondaryGroupID: "",
                secondaryInsuranceCarrier: "",
                secondaryMemberID: ""
              },
              tertiaryInsurance: {
                tertiaryGroupID: "",
                tertiaryInsuranceCarrier: "",
                tertiaryMemberID: ""
              }
            }
          } else {
            var sections = $("div#BillingInformationForm_10 #billing_info_container").find(":input");


            sections.map((input, i) => {
              const $input = $(sections[input]);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));
              console.log("$input: ", $input);

              let _id = $input.attr('id');

              if (typeof _id == 'undefined' || _id.length < 1) {
                _id = `${ova1_prefix}billinfo_DOB`;
              }
              const _type = $input.attr('type');
              let obj_id = _id.replace(`${ova1_prefix}billinfo_`, "");
              obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)
              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  billing_info[obj_id] = true;
                }
                else {
                  billing_info[obj_id] = false;
                }
              }
              else {
                billing_info[obj_id] = $input.val();
              }
            });

            sections = $("div#BillingInformationForm_10 #primary_insurance_container").find(":input");
            sections.map((input, i) => {
              const $input = $(sections[input]);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));
              console.log("$input: ", $input);

              let _id = $input.attr('id');

              if (typeof _id == 'undefined' || _id.length < 1) {
                _id = `${ova1_prefix}billinfo_DOB`;
              }
              const _type = $input.attr('type');
              let obj_id = _id.replace(`${ova1_prefix}billinfo_`, "");
              obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)
              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  billing_info['primaryInsurance'][obj_id] = true;
                }
                else {
                  billing_info['primaryInsurance'][obj_id] = false;
                }
              }
              else {
                billing_info['primaryInsurance'][obj_id] = $input.val();
              }

            });

            sections = $("div#BillingInformationForm_10 #secondary_insurance_container").find(":input");
            sections.map((input, i) => {
              const $input = $(sections[input]);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));
              console.log("$input: ", $input);

              let _id = $input.attr('id');

              if (typeof _id == 'undefined' || _id.length < 1) {
                _id = `${ova1_prefix}billinfo_DOB`;
              }
              const _type = $input.attr('type');
              let obj_id = _id.replace(`${ova1_prefix}billinfo_`, "");
              obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)
              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  billing_info['secondaryInsurance'][obj_id] = true;
                }
                else {
                  billing_info['secondaryInsurance'][obj_id] = false;
                }
              }
              else {
                billing_info['secondaryInsurance'][obj_id] = $input.val();
              }

            });

            sections = $("div#BillingInformationForm_10 #tertiary_insurance_container").find(":input");
            sections.map((input, i) => {
              const $input = $(sections[input]);
              // console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));
              // console.log("$input: ", $input);

              let _id = $input.attr('id');

              if (typeof _id == 'undefined' || _id.length < 1) {
                _id = "billinfoC_dob";
              }
              const _type = $input.attr('type');
              let obj_id = _id.replace(`${ova1_prefix}billinfo_`, "");
              obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)
              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  billing_info['tertiaryInsurance'][obj_id] = true;
                }
                else {
                  billing_info['tertiaryInsurance'][obj_id] = false;
                }
              }
              else {
                billing_info['tertiaryInsurance'][obj_id] = $input.val();
              }
            });

            billing_info["insuredInfo"]["nameOfInsured"]["firstName"] =
              $(`#${ova1_prefix}billinfo_FirstNameofInsured`).val();
            billing_info["insuredInfo"]["nameOfInsured"]["lastName"] =
              $(`#${ova1_prefix}billinfo_LastNameofInsured`).val();
            billing_info['insuredInfo']["dob"] = billinfoOVA1_dobLocal;
            billing_info['insuredInfo']["relationshipInsured"] = $(`#${ova1_prefix}billinfo_RelationshipInsured`).val();
          }


          // sections = $("div#BillingInformationForm_10 #insured_info").find(":input");
          // sections.map((input, i) => {
          //   const $input = $(sections[input]);
          //   console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));
          //   console.log("$input: ", $input);

          //   let _id = $input.attr('id');

          //   if (typeof _id == 'undefined' || _id.length < 1) {
          //     _id = `${ova1_prefix}billinfo_DOB`;
          //   }
          //   const _type = $input.attr('type');
          //   let obj_id = _id.replace(`${ova1_prefix}billinfo_`, "");
          //   obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)
          //   if (_type == 'checkbox') {
          //     if ($input.is(':checked')) {
          //       billing_info['insuredInfo'][obj_id] = true;
          //     }
          //     else {
          //       billing_info['insuredInfo'][obj_id] = false;
          //     }
          //   }
          //   else {
          //     billing_info['insuredInfo'][obj_id] = $input.val();
          //   }

          // });
          console.log("billing_info: ", billing_info);

          let qnA = {
            questionCode: "",
            answer: "",
            subQuestion: []
          };

          let clinicalData = [];

          sections = $(`${helper.isAccessingFromAspiraLab() ? "#PatientInformationForm" : "#ClinicalInformationForm"} #${ova1_prefix}ova1_q01`).find(":input");
          sections.map((input, i) => {
            const $input = $(sections[input]);
            console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));

            let obj_id = $input.attr('id');

            const _type = $input.attr('type');
            obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)

            if (_type == 'radio') {
              if ($input.is(':checked')) {
                qnA = {
                  'questionCode': "ova1_q01",
                  'answer': $input.val(),
                }
              }
            }
          });
          clinicalData.push(qnA);

          const ova1_q02 = [];
          sections = $(`${helper.isAccessingFromAspiraLab() ? "#PatientInformationForm" : "#ClinicalInformationForm"} #${ova1_prefix}ova1_q02`).find(":input");
          sections.map((input, i) => {
            const $input = $(sections[input]);
            console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));

            let obj_id = $input.attr('id');

            const _type = $input.attr('type');
            obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)

            if (_type == 'checkbox') {
              if ($input.is(':checked')) {
                ova1_q02.push($input.data('val'));
              }
            }

          });

          qnA = {
            'questionCode': "ova1_q02",
            'answer': ova1_q02.join(', '),
          }
          clinicalData.push(qnA);


          qnA = {
            'questionCode': "ova1_q03",
            'answer': clinicalInfoLastMenstrualPeriodDate,
          }
          clinicalData.push(qnA);

          if (!helper.isAccessingFromAspiraLab()) {
            qnA = {
              'questionCode': "ova1_q04",
              'answer': $(`#ClinicalInformationForm #${ova1_prefix}clinicinfo_MassSize_longdime`).val(),
              subQuestion: [
                {
                  'questionCode': "ova1_q04_1",
                  'answer': $(`#ClinicalInformationForm #${ova1_prefix}clinicinfo_MassSize_Height`).val() + " " + $(`#ClinicalInformationForm #${ova1_prefix}clinicinfo_MassSize_Height_unit`).val(),
                },
                {
                  'questionCode': "ova1_q04_2",
                  'answer': $(`#ClinicalInformationForm #${ova1_prefix}clinicinfo_MassSize_Weight`).val() + " " + $(`#ClinicalInformationForm #${ova1_prefix}clinicinfo_MassSize_Weight_unit`).val(),
                },
              ]
            };

            clinicalData.push(qnA);

            if (!$(`#${ova1_prefix}clinicinfo_pain_abdomen_pelvis`).is(":checked")) {
              qnA = {
                'questionCode': "ova1_q05",
                'answer': false,
              };
            }
            else {
              let radioValue1 = $(`input[name='clinicinfo_painAbdomenPelvis_Day_']:checked`).val();
              let radioValue2 = $(`input[name='clinicinfo_painAbdomenPelvis_long_']:checked`).val();
              console.log("radioValue1: ", radioValue1);
              console.log("radioValue2: ", radioValue2);
              qnA = {
                'questionCode': "ova1_q05",
                'answer': true,
                subQuestion: [
                  {
                    'questionCode': "ova1_q05_1",
                    'answer': radioValue1,
                  },
                  {
                    'questionCode': "ova1_q05_2",
                    'answer': radioValue2,
                  },
                ]
              };
            }
            clinicalData.push(qnA);

            if (!$(`#${ova1_prefix}clinicinfo_fullQuickly_unableToe`).is(":checked")) {
              qnA = {
                'questionCode': "ova1_q06",
                'answer': false,
              };
            }
            else {
              let radioValue1 = $("input[name='clinicinfo_fullQuicklyUnableToe_Day_']:checked").val();
              let radioValue2 = $("input[name='clinicinfo_fullQuicklyUnableToe_long_']:checked").val();
              qnA = {
                'questionCode': "ova1_q06",
                'answer': true,
                subQuestion: [
                  {
                    'questionCode': "ova1_q06_1",
                    'answer': radioValue1,
                  },
                  {
                    'questionCode': "ova1_q06_2",
                    'answer': radioValue2,
                  },
                ]
              };
            }
            clinicalData.push(qnA);

            if (!$(`#${ova1_prefix}clinicinfo_Expabdominalbloat_IncrSize`).is(":checked")) {
              qnA = {
                'questionCode': "ova1_q07",
                'answer': false,
              };
            }
            else {
              let radioValue1 = $("input[name='clinicinfo_ExpabdominalbloatIncrSize_Day_']:checked").val();
              let radioValue2 = $("input[name='clinicinfo_ExpabdominalbloatIncrSize_long_']:checked").val();
              qnA = {
                'questionCode': "ova1_q07",
                'answer': true,
                subQuestion: [
                  {
                    'questionCode': "ova1_q07_1",
                    'answer': radioValue1,
                  },
                  {
                    'questionCode': "ova1_q07_2",
                    'answer': radioValue2,
                  },
                ]
              };
            }
            clinicalData.push(qnA);
          }


          console.log("clinicalData: ", clinicalData);

          // //icd10Codes
          const icd10Codes = [];
          const icd10CodesOther = [];
          let icd10Code = {
            'codes': [],
            'otherCodes': [],
          };

          if (!helper.isAccessingFromAspiraLab()) {
            sections = $(`#DiagnosisCodes_ICD10CodesForm #ova1_icd_codes`).find(":input");
            sections.map((input, i) => {
              const $input = $(sections[input]);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));

              // let obj_id = $input.attr('id');

              const _type = $input.attr('type');
              // obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)

              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  icd10Codes.push($input.data('val'));
                }
              }
              else if (_type == 'text') {
                icd10CodesOther.push($("#ova1_OtherICD10Codes").val());
              }

            });

            //sam
            icd10CodesOther.push($("#ova1_OtherICD10Codes").val());

            icd10Code = {
              'codes': icd10Codes,
              'otherCodes': icd10CodesOther,
            }
          }


          let ova1PlusBiomarkers = {
            GCP: [],
            MCP: [],
            other: {},
          };

          if ($('#germCellPanel_GCP100_LDH').is(":checked")) {
            ova1PlusBiomarkers.GCP.push({
              code: $('#germCellPanel_GCP100_LDH').data('val'),
              qBenchId: $('#germCellPanel_GCP100_LDH').data('qbenchid'),
            });
          } else {
            sections = $(`#OVA1plusbiomarkersForm #gcp_container1`).find(":input");
            sections.map((input, i) => {
              const $input = $(sections[input]);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));

              const _type = $input.attr('type');

              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  ova1PlusBiomarkers.GCP.push({
                    code: $input.data('val'),
                    qBenchId: $input.data('qbenchid'),
                  });

                }
              }

            });
          }


          //mcp_container
          if ($('#germCellPanel_MCP100_CEA').is(":checked")) {
            ova1PlusBiomarkers.MCP.push({
              code: $('#germCellPanel_MCP100_CEA').data('val'),
              qBenchId: $('#germCellPanel_MCP100_CEA').data('qbenchid'),
            });
          } else {
            sections = $(`#OVA1plusbiomarkersForm #mcp_container1`).find(":input");
            sections.map((input, i) => {
              const $input = $(sections[input]);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));

              const _type = $input.attr('type');

              if (_type == 'checkbox') {
                if ($input.is(':checked')) {

                  ova1PlusBiomarkers.MCP.push({
                    code: $input.data('val'),
                    qBenchId: $input.data('qbenchid'),
                  });
                }
              }

            });
          }

          if ($(`#${ova1_prefix}_Ova1`).is(":checked")) {
            let $ova1Checkbox = $(`#${ova1_prefix}_Ova1`);
            ova1PlusBiomarkers.other = {
              qBenchId: $ova1Checkbox.data('qbenchid'),
              code: $ova1Checkbox.data('val')
            };
          }


          let kitInformation = {
            drawType: [],
            otherInfo: "",
          };

          if (!helper.isAccessingFromAspiraLab()) {
            sections = $(`#${ova1_prefix}kitShippingInfoFormContainer`).find(":input");
            sections.map((i, input) => {
              const $input = $(input);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));

              let obj_id = $input.attr('id');

              const _type = $input.attr('type');
              // obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)

              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  kitInformation.drawType.push($input.data('val'));
                }
              }
            });

            kitInformation.otherInfo = $(`#${ova1_prefix}kitShip_PatOthersInfo`).val();
          }


          let notes = $(`#NotesCertifyForm #${ova1_prefix}kitShip_notes`).val();
          var tests = [
            {
              testName: "Ova1",
              testCode: "Ova1",
              icd10Code: icd10Code,
              kitInformation: kitInformation,
              notes: notes,
              ova1PlusBiomarkers: ova1PlusBiomarkers,
              clinicalData: clinicalData
            }
          ];


          //NotesCertifyForm


          let certifyTypes = [];

          sections = $(`#NotesCertifyForm #${ova1_prefix}certifytype`).find(':input');
          sections.map((i, input) => {
            const $input = $(input);
            console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));

            let obj_id = $input.attr('id');

            const _type = $input.attr('type');
            // obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)

            if (_type == 'radio') {
              if ($input.is(':checked')) {

                certifyTypes.push($input.data('val'));

              }
            }
          });

          const covid_checked = $("#product_4").is(":checked");
          if (covid_checked) {

            const icd10CodesCOVD = [];
            let icd10CodeCOVD = {
              'codes': [],
              'otherCodes': "",
            };

            sections = $("#DiagnosisCodes_ICD10CodesForm #covid_icd_codes").find(":input");
            sections.map((i, input) => {
              const $input = $(input);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));

              // let obj_id = $input.attr('id');

              const _type = $input.attr('type');
              // obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)

              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  icd10CodesCOVD.push($input.data('val'));
                }
              }

            });

            icd10CodeCOVD = {
              'codes': icd10CodesCOVD,
              'otherCodes': "",
            }

            var covid_tests = {
              testName: "COVD",
              testCode: "COVD",
              icd10Code: icd10CodeCOVD,
            };

            tests.push(covid_tests);
          }

          console.log("sigPad: ", sigPad);
          let dataString = "";

          if (sigPad) {
            if (typeof sigPad.current !== 'undefined' && sigPad.current == null) {

            }
            else {
              dataString = sigPad.getTrimmedCanvas().toDataURL('image/png');
            }
          }

          console.log("physician before submitting to place order: ", physician);
          if (typeof patient.patient !== "undefined" && patient.patient) {
            patient = patient.patient;
          }
          if (typeof physician.physician !== "undefined" && physician.physician) {
            physician = physician.physician;
          }

          // get the user response related to user's consent about the use of his/her data for research purpose
          const researchConsent = $(`#${ova1_prefix}researchConsent`).is(":checked") ? "Yes" : "No";
          const researchUseOnly = $(`#${ova1_prefix}researchUseOnly`).is(":checked") ? "Yes" : "No";

          // check if the order is a test order
          const isTestOrder = helper.isAccessingFromAspiraLab() ? $(`#${ova1_prefix}testOrder`).is(":checked") : false;

          await helper.sleep(2000);
          data = {
            orderId: (Math.floor(100000 + Math.random() * 900000)).toString(),
            patientId: patient._id.toString(),
            physicianId: physician._id.toString(),
            billingInfo: billing_info,
            tests: tests,
            certifyTypes,
            physicianSignature: dataString || physicianSignature,
            qbenchCustomerId: legacyCustomerQbenchId,
            legacyClientId: selectedPhysicianLegacyInfo ? selectedPhysicianLegacyInfo.selectedClientId : "",
            researchConsent,
            researchUseOnly,
            isTestOrder
          }

          console.log("\n===");
          console.log("data to place order: ", data);
          console.log("===\n");

          $this.html("<img style='height: 30px;' src='/assets/images/Spinner-1s-200px.svg' /> Creating Order");
          $(`#${ova1_prefix}btnOrderSubmit`).prop("disabled", true);

          let response = await createOva1OrderApi(data);
          if (response.isok) {
            $this.html('Done');
            message.success(`Order Created Successfully.`);
            $(`#${ova1_prefix}btnOrderSubmit`).prop("disabled", false);
            // $this.prop("disabled", true);

            // need - 7
            // localStorage.removeItem('selectedPhysicianLegacyInfo');
            // if (process.env.REACT_APP_ENV == "DEV") {
            //   console.log("intentionally not redirecting to pdf page");
            // } else {
            setTimeout(() => {
              window.location.href = response.redirectUrl;
            }, 500);
            // }
          }

        }
      }
    });

    $("input.form-control").keyup(function (event) {
      console.log("event.keyCode: ", event.keyCode);
      if (event.keyCode == 13) {
        let textboxes = $("input.form-control");
        let currentBoxNumber = textboxes.index(this);
        if (textboxes[currentBoxNumber + 1] != null) {
          let nextBox = textboxes[currentBoxNumber + 1];
          nextBox.focus();
          nextBox.select();
        }
        event.preventDefault();
        return false;
      }


      return true;
    });

    //OVA1plusbiomarkersForm
    $(`#${ova1_prefix}btnOVA1plusbiomarkersFormNext`).off().on('click', function (event) {
      event.preventDefault();

      if (ova1PlusBiomarkersFormNextFunc()) {
        $('#OVA1plusbiomarkersForm').removeClass(' show')
        $('#PhysicianInformationForm').addClass(' show')
        $('#OVA1plusbiomarkersFormCheckMark').removeClass('d-none');

        //go to top
        document.body.scrollTop = 260;
        document.documentElement.scrollTop = 260;
      }

    })

    //PhysicianInformationForm btnPhysicianInformationFormNext

    const ova1PlusBiomarkersFormNextFunc = () => {
      let inputs = $('#Ova1plusbiomarkersForm').find(':input');

      let isGood = false;
      for (let i = 0; i < inputs.length; ++i) {
        let $elem = $(inputs[i]);

        const _type = $elem.attr('type');
        switch (_type) {
          case 'text':
            if ($elem.val().length > 0) {
              isGood = true;
            }
            break;
          case 'number':
            if ($elem.val().length > 0) {
              isGood = true;
            }
            break;
          case 'email':
            if ($elem.val().length > 0) {
              isGood = true;
            }
            break;
          case 'checkbox':
            if ($elem.is(':checked')) {
              isGood = true;
            }
            break;

          case 'radio':
            if ($elem.is(':checked')) {
              isGood = true;
            }
            break;

          default:
            isGood = false;
            break;
        }

        if (isGood) {
          break;
        }
      }

      if (isGood) {
        return true;
      }
      else {
        message.warning(`Ova1 Biomakers - please select a panel to continue`);
        return false;
      }
    }

    const ova1PhysicianInfoFormNextFunc = () => {
      const phyinfo_Organization_account = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}phyinfo_Organization_account`).val());
      $(`#${ova1_prefix}phyinfo_Organization_account`).val(phyinfo_Organization_account);

      const phyinfo_first_name = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}phyinfo_first_name`).val());
      $(`#${ova1_prefix}phyinfo_first_name`).val(phyinfo_first_name);

      const phyinfo_last_name = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}phyinfo_last_name`).val());
      $(`#${ova1_prefix}phyinfo_last_name`).val(phyinfo_last_name);

      const phyinfo_npi = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}phyinfo_npi`).val());
      $(`#${ova1_prefix}phyinfo_npi`).val(phyinfo_npi);

      const phyinfo_email = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}phyinfo_email`).val());
      $(`#${ova1_prefix}phyinfo_email`).val(phyinfo_email);

      const phyinfo_address = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}phyinfoC_address`).val());
      $(`#${ova1_prefix}phyinfoC_address`).val(phyinfo_address);

      const phyinfo_practiceLocation = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}phyinfoC_practiceLocation`).val());
      $(`#${ova1_prefix}phyinfoC_practiceLocation`).val(phyinfo_practiceLocation);

      const phyinfo_city = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}phyinfoC_city`).val());
      $(`#${ova1_prefix}phyinfoC_city`).val(phyinfo_city);

      const phyinfo_state = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}phyinfoC_state`).val());
      $(`#${ova1_prefix}phyinfoC_state`).val(phyinfo_state);

      const phyinfo_zipCode = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}phyinfoC_zipCode`).val());
      $(`#${ova1_prefix}phyinfoC_zipCode`).val(phyinfo_zipCode);

      const ph_areacode = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}phyinfoC_ph_areacode`).val());
      $(`#${ova1_prefix}phyinfoC_ph_areacode`).val(ph_areacode);

      const ph_prefix = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}phyinfoC_ph_prefix`).val());
      $(`#${ova1_prefix}phyinfoC_ph_prefix`).val(ph_prefix);

      const ph_suffix = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}phyinfoC_ph_suffix`).val());
      $(`#${ova1_prefix}phyinfoC_ph_suffix`).val(ph_suffix);

      const phyinfo_fax = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}phyinfoC_fax`).val());
      $(`#${ova1_prefix}phyinfoC_fax`).val(phyinfo_fax);

      const phyinfo_Faxcopyto = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}phyinfoC_Faxcopyto`).val());
      $(`#${ova1_prefix}phyinfoC_Faxcopyto`).val(phyinfo_Faxcopyto);

      const phyinfo_confirmation = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}phyinfoC_confirmation`).val());
      $(`#${ova1_prefix}phyinfoC_confirmation`).val(phyinfo_confirmation);

      const phyinfo_no_npi = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}phyinfo_no_npi`).val());
      $(`#${ova1_prefix}phyinfo_no_npi`).val(phyinfo_no_npi);

      //ova1_phyinfo_phone_ph_suffix
      const phyinfo_account_number = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}phyinfo_account_number`).val());
      $(`#${ova1_prefix}phyinfo_account_number`).val(phyinfo_account_number);

      const phone_ph_areacode = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}phyinfo_phone_ph_areacode`).val());
      $(`#${ova1_prefix}phyinfo_phone_ph_areacode`).val(phone_ph_areacode);

      const phone_ph_prefix = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}phyinfo_phone_ph_prefix`).val());
      $(`#${ova1_prefix}phyinfo_phone_ph_prefix`).val(phone_ph_prefix);

      const phone_ph_suffix = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}phyinfo_phone_ph_suffix`).val());
      $(`#${ova1_prefix}phyinfo_phone_ph_suffix`).val(phone_ph_suffix);

      const hostname = $(`#${ova1_prefix}hostname`).val();

      let isGood = true;

      if (hostname === "lab.aspirawh.com" || hostname === 'localhost'
        || hostname == 'dev.aspirawh.cloud' || hostname === "order.dev-aspiralab.com" || hostname === "dev.aspirawh.cloud"
      ) { //yes it is lab.aspirawh.com

        if ($(`#${ova1_prefix}ordered_before1`).is(":not(:checked)")
          && $(`#${ova1_prefix}ordered_before2`).is(":not(:checked)")) {

          message.warning(`Have you ordered from us before is required.`);
          isGood = false;
        }
        else if ($(`#${ova1_prefix}ordered_before1`).is(":checked")) { //already Order Placed

          let _selectedNPI = "", _selectedClientId = "";

          // selectedPhysicianLegacyInfo = localStorage.getItem("selectedPhysicianLegacyInfo");
          // if (typeof selectedPhysicianLegacyInfo !== 'undefined' && selectedPhysicianLegacyInfo) {
          //   selectedPhysicianLegacyInfo = JSON.parse(selectedPhysicianLegacyInfo);

          //   _selectedNPI = selectedPhysicianLegacyInfo.selectedNPI;
          //   _selectedClientId = selectedPhysicianLegacyInfo.selectedClientId;
          // }
          //need - 5
          selectedPhysicianLegacyInfo = selectedPhysicianLegacyInfoState;

          // selectedPhysicianLegacyInfo = localStorage.getItem(
          //   "selectedPhysicianLegacyInfo"
          // );

          if (
            typeof selectedPhysicianLegacyInfo !== "undefined" &&
            selectedPhysicianLegacyInfo
          ) {

            //need - 6
            // selectedPhysicianLegacyInfo = JSON.parse(
            //   selectedPhysicianLegacyInfo
            // );

            _selectedNPI = selectedPhysicianLegacyInfo.selectedNPI;
            _selectedClientId = selectedPhysicianLegacyInfo.selectedClientId;
          }

          if (phyinfo_account_number.length < 1) {

            message.warning(`Physician Account Number is required field.`);

            $(`#${ova1_prefix}phyinfo_account_number`).focus();

            isGood = false;
          }
          else {
            if (_selectedNPI.length < 1) {

              message.warning(`Physician selection is required.`);

              // $(`#${gTcs_prefix}phyinfo_account_number`).focus();

              isGood = false;
            }
          }

        }
        else if ($(`#${ova1_prefix}ordered_before2`).is(":checked")) { //Not Order Placed yet

          if (phyinfo_first_name.length < 1) {

            message.warning(`Physician first name is required field.`);

            $(`#${ova1_prefix}phyinfo_first_name`).focus();

            isGood = false;
          }
          else if (phyinfo_last_name.length < 1) {

            message.warning(`Physician last name is required field.`);

            $(`#${ova1_prefix}phyinfo_last_name`).focus();

            isGood = false;
          }
          else if (phyinfo_npi.length < 1) {

            message.warning(`Physician npi is required field.`);

            $(`#${ova1_prefix}phyinfo_npi`).focus();

            isGood = false;
          }
          else if (phyinfo_email.length < 1) {

            message.warning(`Physician email address is required field.`);

            $(`#${ova1_prefix}phyinfo_email`).focus();

            isGood = false;
          }
          else if (!helper.isEmail(phyinfo_email)) {
            message.warning(`Please enter a valid email address.`);

            $(`#${ova1_prefix}phyinfo_email`).focus();

            isGood = false;
          } else if (phone_ph_areacode.length < 1) {

            message.warning(`Physician phone is required field.`);
            $(`#${ova1_prefix}phyinfo_phone_ph_areacode`).focus();

            isGood = false;
          } else if (phone_ph_prefix.length < 1) {

            message.warning(`Physician phone is required field.`);
            $(`#${ova1_prefix}phyinfo_phone_ph_prefix`).focus();

            isGood = false;
          } else if (phone_ph_suffix.length < 1) {

            message.warning(`Physician phone is required field.`);
            $(`#${ova1_prefix}phyinfo_phone_ph_suffix`).focus();

            isGood = false;
          } else if (phyinfo_no_npi === '1' && phyinfo_address.length < 1) {

            message.warning(`Physician address is required field.`);
            $(`#${ova1_prefix}phyinfoC_address`).focus();

            isGood = false;
          } else if (phyinfo_no_npi === '1' && phyinfo_practiceLocation.length < 1) {

            message.warning(`Physician Practice Location is required field.`);
            $(`#${ova1_prefix}phyinfoC_practiceLocation`).focus();

            isGood = false;
          } else if (phyinfo_no_npi === '1' && ph_areacode.length < 1) {

            message.warning(`Physician phone is required field.`);
            $(`#${ova1_prefix}phyinfoC_ph_areacode`).focus();

            isGood = false;
          } else if (phyinfo_no_npi === '1' && ph_prefix.length < 1) {

            message.warning(`Physician phone is required field.`);
            $(`#${ova1_prefix}phyinfoC_ph_prefix`).focus();

            isGood = false;
          } else if (phyinfo_no_npi === '1' && ph_suffix.length < 1) {

            message.warning(`Physician phone is required field.`);
            $(`#${ova1_prefix}phyinfoC_ph_suffix`).focus();

            isGood = false;
          }
        }
        else {

          isGood = true;
        }

      }
      else {
        if (phyinfo_first_name.length < 1) {

          message.warning(`Physician first name is required field.`);

          $(`#${ova1_prefix}phyinfo_first_name`).focus();

          isGood = false;
        }
        else if (phyinfo_last_name.length < 1) {

          message.warning(`Physician last name is required field.`);

          $(`#${ova1_prefix}phyinfo_last_name`).focus();

          isGood = false;
        }
        else if (phyinfo_npi.length < 1) {

          message.warning(`Physician npi is required field.`);

          $(`#${ova1_prefix}phyinfo_npi`).focus();

          isGood = false;
        }
        else if (phyinfo_email.length < 1) {

          message.warning(`Physician email address is required field.`);

          $(`#${ova1_prefix}phyinfo_email`).focus();

          isGood = false;
        }
        else if (!helper.isEmail(phyinfo_email)) {
          message.warning(`Please enter a valid email address.`);

          $(`#${ova1_prefix}phyinfo_email`).focus();

          isGood = false;
        } else if (phone_ph_areacode.length < 1) {

          message.warning(`Physician phone is required field.`);
          $(`#${ova1_prefix}phyinfo_phone_ph_areacode`).focus();

          isGood = false;
        } else if (phone_ph_prefix.length < 1) {

          message.warning(`Physician phone is required field.`);
          $(`#${ova1_prefix}phyinfo_phone_ph_prefix`).focus();

          isGood = false;
        } else if (phone_ph_suffix.length < 1) {

          message.warning(`Physician phone is required field.`);
          $(`#${ova1_prefix}phyinfo_phone_ph_suffix`).focus();

          isGood = false;
        } else if (phyinfo_no_npi === '1' && phyinfo_address.length < 1) {

          message.warning(`Physician address is required field.`);
          $(`#${ova1_prefix}phyinfoC_address`).focus();

          isGood = false;
        } else if (phyinfo_no_npi === '1' && phyinfo_practiceLocation.length < 1) {

          message.warning(`Physician Practice Location is required field.`);
          $(`#${ova1_prefix}phyinfoC_practiceLocation`).focus();

          isGood = false;
        } else if (phyinfo_no_npi === '1' && ph_areacode.length < 1) {

          message.warning(`Physician phone is required field.`);
          $(`#${ova1_prefix}phyinfoC_ph_areacode`).focus();

          isGood = false;
        } else if (phyinfo_no_npi === '1' && ph_prefix.length < 1) {

          message.warning(`Physician phone is required field.`);
          $(`#${ova1_prefix}phyinfoC_ph_prefix`).focus();

          isGood = false;
        } else if (phyinfo_no_npi === '1' && ph_suffix.length < 1) {

          message.warning(`Physician phone is required field.`);
          $(`#${ova1_prefix}phyinfoC_ph_suffix`).focus();

          isGood = false;
        } else {
          isGood = true;
        }
      }

      return isGood;

      // if ($(`#${ova1_prefix}ordered_before1`).is(":not(:checked)")
      //   && $(`#${ova1_prefix}ordered_before2`).is(":not(:checked)")) {

      //   message.warning(`Have you ordered from us before is required.`);
      //   
      //   isGood = false;
      // }
      // else if ($(`#${ova1_prefix}ordered_before1`).is(":checked")) { //already Order Placed

      //   let _selectedNPI = "";

      //   selectedPhysicianLegacyInfo = localStorage.getItem("selectedPhysicianLegacyInfo");
      //   if (typeof selectedPhysicianLegacyInfo !== 'undefined' && selectedPhysicianLegacyInfo) {
      //     selectedPhysicianLegacyInfo = JSON.parse(selectedPhysicianLegacyInfo);

      //     _selectedNPI = selectedPhysicianLegacyInfo.selectedNPI
      //   }

      //   if (phyinfo_account_number.length < 1) {

      //     message.warning(`Physician Account Number is required field.`);
      //     

      //     $(`#${ova1_prefix}phyinfo_account_number`).focus();

      //     isGood = false;
      //   }
      //   else {
      //     if (_selectedNPI.length < 1) {

      //       message.warning(`Physician selection is required.`);
      //       

      //       // $(`#${gTcs_prefix}phyinfo_account_number`).focus();

      //       isGood = false;
      //     }
      //   }

      // }
      // else if ($(`#${ova1_prefix}ordered_before2`).is(":checked")) { //Not Order Placed yet

      //   if (phyinfo_first_name.length < 1) {

      //     message.warning(`Physician first name is required field.`);
      //     

      //     $(`#${ova1_prefix}phyinfo_first_name`).focus();

      //     isGood = false;
      //   }
      //   else if (phyinfo_last_name.length < 1) {

      //     message.warning(`Physician last name is required field.`);
      //     

      //     $(`#${ova1_prefix}phyinfo_last_name`).focus();

      //     isGood = false;
      //   }
      //   else if (phyinfo_npi.length < 1) {

      //     message.warning(`Physician npi is required field.`);
      //     

      //     $(`#${ova1_prefix}phyinfo_npi`).focus();

      //     isGood = false;
      //   }
      //   else if (phyinfo_email.length < 1) {

      //     message.warning(`Physician email address is required field.`);
      //     

      //     $(`#${ova1_prefix}phyinfo_email`).focus();

      //     isGood = false;
      //   }
      //   else if (!helper.isEmail(phyinfo_email)) {
      //     message.warning(`Please enter a valid email address.`);
      //     

      //     $(`#${ova1_prefix}phyinfo_email`).focus();

      //     isGood = false;

      //   } else if (phone_ph_areacode.length < 1) {

      //     message.warning(`Physician phone is required field.`);
      //     
      //     $(`#${ova1_prefix}phyinfo_phone_ph_areacode`).focus();

      //     isGood = false;
      //   } else if (phone_ph_prefix.length < 1) {

      //     message.warning(`Physician phone is required field.`);
      //     
      //     $(`#${ova1_prefix}phyinfo_phone_ph_prefix`).focus();

      //     isGood = false;
      //   } else if (phone_ph_suffix.length < 1) {

      //     message.warning(`Physician phone is required field.`);
      //     
      //     $(`#${ova1_prefix}phyinfo_phone_ph_suffix`).focus();

      //     isGood = false;

      //   } else if (phyinfo_no_npi === '1' && phyinfo_address.length < 1) {

      //     message.warning(`Physician address is required field.`);
      //     
      //     $(`#${ova1_prefix}phyinfoC_address`).focus();

      //     isGood = false;
      //   } else if (phyinfo_no_npi === '1' && phyinfo_practiceLocation.length < 1) {

      //     message.warning(`Physician Practice Location is required field.`);
      //     
      //     $(`#${ova1_prefix}phyinfoC_practiceLocation`).focus();

      //     isGood = false;
      //   } else if (phyinfo_no_npi === '1' && ph_areacode.length < 1) {

      //     message.warning(`Physician phone is required field.`);
      //     
      //     $(`#${ova1_prefix}phyinfoC_ph_areacode`).focus();

      //     isGood = false;
      //   } else if (phyinfo_no_npi === '1' && ph_prefix.length < 1) {

      //     message.warning(`Physician phone is required field.`);
      //     
      //     $(`#${ova1_prefix}phyinfoC_ph_prefix`).focus();

      //     isGood = false;
      //   } else if (phyinfo_no_npi === '1' && ph_suffix.length < 1) {

      //     message.warning(`Physician phone is required field.`);
      //     
      //     $(`#${ova1_prefix}phyinfoC_ph_suffix`).focus();

      //     isGood = false;
      //   }
      // }
      // else {

      //   isGood = true;
      // }

      // return isGood;
    }

    const ova1PatientInfoFormNextFunc = () => {

      const patinfo_firstname = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}patinfo_firstname`).val());
      $(`#${ova1_prefix}patinfo_firstname`).val(patinfo_firstname);

      const patinfo_lastname = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}patinfo_lastname`).val());
      $(`#${ova1_prefix}patinfo_lastname`).val();

      const patinfo_address1 = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}patinfo_address1`).val());
      $(`#${ova1_prefix}patinfo_address1`).val();

      const patinfo_city = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}patinfo_city`).val());
      $(`#${ova1_prefix}patinfo_city`).val();

      const patinfo_zipcode = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}patinfo_zipcode`).val());
      $(`#${ova1_prefix}patinfo_zipcode`).val(patinfo_zipcode);

      const ova1_patinfo_ph_areacode = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}patinfo_ph_areacode`).val());
      $(`#${ova1_prefix}patinfo_ph_areacode`).val(ova1_patinfo_ph_areacode);

      const ova1_patinfo_ph_prefix = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}patinfo_ph_prefix`).val());
      $(`#${ova1_prefix}patinfo_ph_prefix`).val(ova1_patinfo_ph_prefix);

      const ova1_patinfo_ph_suffix = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}patinfo_ph_suffix`).val());
      $(`#${ova1_prefix}patinfo_ph_suffix`).val(ova1_patinfo_ph_suffix);

      const patinfo_emailaddress = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}patinfo_emailaddress`).val());
      $(`#${ova1_prefix}patinfo_emailaddress`).val(patinfo_emailaddress);

      const patinfo_OtherEthnicitycity = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}patinfo_OtherEthnicitycity`).val());
      $(`#${ova1_prefix}patinfo_OtherEthnicitycity`).val(patinfo_OtherEthnicitycity);

      const hostname = $(`#${ova1_prefix}hostname`).val();

      let isGood = true;

      if (!helper.isAccessingFromAspiraLab()) {
        // if (hostname != "lab.aspirawh.com") { // not lab

        if (patinfo_firstname.length < 1) {
          message.warning(`Patient first name is required.`);
          $(`#${ova1_prefix}patinfo_firstname`).focus();

          isGood = false;

        } else if (patinfo_lastname.length < 1) {
          message.warning(`Patient last name is required.`);
          $(`#${ova1_prefix}patinfo_lastname`).focus();

          isGood = false;

        } else if ($(`#${ova1_prefix}patinfo_gender`).is(":not(:checked)") && $(`#${ova1_prefix}patinfo_gender2`).is(":not(:checked)")) {

          message.warning(`Patient gender is required.`);


          isGood = false;

        } else if (patinfo_address1.length < 1) {
          message.warning(`Patient address 1 is required.`);

          $(`#${ova1_prefix}patinfo_address1`).focus();

          isGood = false;
        } else if (patinfo_city.length < 1) {
          message.warning(`Patient city is required.`);

          $(`#${ova1_prefix}patinfo_city`).focus();

          isGood = false;
        } else if (patinfo_zipcode.length < 1) {
          message.warning(`Patient zipcode is required.`);

          $(`#${ova1_prefix}patinfo_zipcode`).focus();

          isGood = false;
        } else if (ova1_patinfo_ph_areacode.length < 1) {
          message.warning(`Patient phone is required.`);

          $(`#${ova1_prefix}patinfo_ph_areacode`).focus();

          isGood = false;
        } else if (ova1_patinfo_ph_prefix.length < 1) {
          message.warning(`Patient phone is required.`);

          $(`#${ova1_prefix}patinfo_ph_prefix`).focus();

          isGood = false;
        } else if (ova1_patinfo_ph_suffix.length < 1) {
          message.warning(`Patient phone is required.`);

          $(`#${ova1_prefix}patinfo_ph_suffix`).focus();

          isGood = false;
        } else if (patinfo_emailaddress.length < 1) {

          message.warning(`Patient email is required.`);


          $(`#${ova1_prefix}patinfo_emailaddress`).focus();

          isGood = false;
        }
        else if (!helper.isEmail(patinfo_emailaddress)) {
          message.warning(`Please enter a valid email address.`);

          $(`#${ova1_prefix}patinfo_emailaddress`).focus();

          isGood = false;
        }

        // else if ($(`#${ova1_prefix}patinfo_Caucasian`).is(":not(:checked)")
        //   && $(`#${ova1_prefix}patinfo_AshkenaziJewish`).is(":not(:checked)")
        //   && $(`#${ova1_prefix}patinfo_SephardicJewish`).is(":not(:checked)")
        //   && $(`#${ova1_prefix}patinfo_Asian`).is(":not(:checked)")
        //   && $(`#${ova1_prefix}patinfo_HIspanic`).is(":not(:checked)")
        //   && $(`#${ova1_prefix}patinfo_NativeAmerican`).is(":not(:checked)")
        //   && $(`#${ova1_prefix}patinfo_AfricanAmerican`).is(":not(:checked)")
        //   && $(`#${ova1_prefix}patinfo_Other`).is(":not(:checked)")) {

        //   message.warning(`Please select patient ethnicity.`);
        //   

        //   isGood = false;
        // } 

        else if (!($(`#${ova1_prefix}patinfo_Other`).is(":not(:checked)")) && (patinfo_OtherEthnicitycity.length < 1)) {

          message.warning(`Please fill up all required fields.`);

          $(`#${ova1_prefix}patinfo_OtherEthnicitycity`).focus();

          isGood = false;
        } else {

          isGood = true;
        }

      } else {  //yes it is lab.aspirawh.com

        if (patinfo_firstname.length < 1) {

          message.warning(`Patient first name is required.`);

          $(`#${ova1_prefix}patinfo_firstname`).focus();

          isGood = false;

        } else if (patinfo_lastname.length < 1) {

          message.warning(`Patient last name is required.`);

          $(`#${ova1_prefix}patinfo_lastname`).focus();

          isGood = false;

        } else if ($(`#${ova1_prefix}patinfo_gender`).is(":not(:checked)") && $(`#${ova1_prefix}patinfo_gender2`).is(":not(:checked)")) {

          message.warning(`Patient gender is required.`);


          isGood = false;
        } else if ($(`#${ova1_prefix}clinicinfo_Menopausal_status`).is(":not(:checked)") && $(`#${ova1_prefix}clinicinfo_Menopausal_status2`).is(":not(:checked)") && $(`#${ova1_prefix}clinicinfo_Menopausal_status3`).is(":not(:checked)") && $(`#${ova1_prefix}clinicinfo_Menopausal_status4`).is(":not(:checked)")) {

          message.warning(`Please select one menopausal status.`);


          isGood = false;

        } else if ($(`#${ova1_prefix}clinicinfo_lowrisk`).is(":not(:checked)") && $(`#${ova1_prefix}clinicinfo_highrisk`).is(":not(:checked)") && $(`#${ova1_prefix}clinicinfo_NotDefinitive`).is(":not(:checked)") && $(`#${ova1_prefix}clinicinfo_NotGiven`).is(":not(:checked)")) {

          message.warning(`Please select Ultrasound Results.`);


          isGood = false;
          //} else if ($(`#${ova1_prefix}clinicinfo_DoLastMentrualPeriod`).val().length <= 0) {
          //message.warning(`Please provide the last menstrual period date.`);
          //

          //isGood = false;
        } else if (!($(`#${ova1_prefix}patinfo_Other`).is(":not(:checked)")) && (patinfo_OtherEthnicitycity.length < 1)) {

          message.warning(`Please fill up all required fields.`);

          $(`#${ova1_prefix}patinfo_OtherEthnicitycity`).focus();

          isGood = false;
        }
        else if (!patientDOBLocal || patientDOBLocal.length < 1) {
          message.warning(`Patient DOB is required.`);
          isGood = false;
        }
        else {

          isGood = true;
        }

      }

      return isGood;

      // if (patinfo_firstname.length < 1) {
      //   message.warning(`Patient first name is required.`);
      //   
      //   $(`#${ova1_prefix}patinfo_firstname`).focus();

      //   isGood = false;

      // } else if (patinfo_lastname.length < 1) {
      //   message.warning(`Patient last name is required.`);
      //   
      //   $(`#${ova1_prefix}patinfo_lastname`).focus();

      //   isGood = false;
      // } else if ($(`#${ova1_prefix}patinfo_gender`).is(":not(:checked)") && $(`#${ova1_prefix}patinfo_gender2`).is(":not(:checked)")) {

      //   message.warning(`Patient gender is required.`);
      //   

      //   isGood = false;

      // } else if (patinfo_address.length < 1) {
      //   message.warning(`Patient address is required.`);
      //   
      //   $(`#${ova1_prefix}patinfo_address`).focus();

      //   isGood = false;
      // } else if (patinfo_city.length < 1) {
      //   message.warning(`Patient city is required.`);
      //   
      //   $(`#${ova1_prefix}patinfo_city`).focus();

      //   isGood = false;
      // } else if (patinfo_zipcode.length < 1) {
      //   message.warning(`Patient zipcode is required.`);
      //   
      //   $(`#${ova1_prefix}patinfo_zipcode`).focus();

      //   isGood = false;
      // } else if (ova1_patinfo_ph_areacode.length < 1) {
      //   message.warning(`Patient phone is required.`);
      //   
      //   $(`#${ova1_prefix}patinfo_ph_areacode`).focus();

      //   isGood = false;
      // } else if (ova1_patinfo_ph_prefix.length < 1) {
      //   message.warning(`Patient phone is required.`);
      //   
      //   $(`#${ova1_prefix}patinfo_ph_prefix`).focus();

      //   isGood = false;
      // } else if (ova1_patinfo_ph_suffix.length < 1) {
      //   message.warning(`Patient phone is required.`);
      //   
      //   $(`#${ova1_prefix}patinfo_ph_suffix`).focus();

      //   isGood = false;
      // } else if (patinfo_emailaddress.length < 1) {

      //   message.warning(`Patient email is required.`);
      //   

      //   $(`#${ova1_prefix}patinfo_emailaddress`).focus();

      //   isGood = false;
      // }
      // else if (!helper.isEmail(patinfo_emailaddress)) {
      //   message.warning(`Please enter a valid email address.`);
      //   
      //   $(`#${ova1_prefix}patinfo_emailaddress`).focus();

      //   isGood = false;
      // } else if ($(`#${ova1_prefix}patinfo_Caucasian`).is(":not(:checked)")
      //   && $(`#${ova1_prefix}patinfo_AshkenaziJewish`).is(":not(:checked)")
      //   && $(`#${ova1_prefix}patinfo_SephardicJewish`).is(":not(:checked)")
      //   && $(`#${ova1_prefix}patinfo_Asian`).is(":not(:checked)")
      //   && $(`#${ova1_prefix}patinfo_HIspanic`).is(":not(:checked)")
      //   && $(`#${ova1_prefix}patinfo_NativeAmerican`).is(":not(:checked)")
      //   && $(`#${ova1_prefix}patinfo_AfricanAmerican`).is(":not(:checked)")
      //   && $(`#${ova1_prefix}patinfo_Other`).is(":not(:checked)")) {

      //   message.warning(`Please select patient ethnicity.`);
      //   

      //   isGood = false;
      // } else if (!($(`#${ova1_prefix}patinfo_Other`).is(":not(:checked)")) && (patinfo_OtherEthnicitycity.length < 1)) {

      //   message.warning(`Please fill up all required fields.`);
      //   
      //   $(`#${ova1_prefix}patinfo_OtherEthnicitycity`).focus();

      //   isGood = false;
      // } else {

      //   isGood = true;
      // }

      // return isGood;
    }

    const ova1ClinicalInfoFormNextFunc = () => {

      let isGood = true;
      if ($(`#${ova1_prefix}clinicinfo_Menopausal_status`).is(":not(:checked)") && $(`#${ova1_prefix}clinicinfo_Menopausal_status2`).is(":not(:checked)") && $(`#${ova1_prefix}clinicinfo_Menopausal_status3`).is(":not(:checked)")) {

        message.warning(`Please select one menopausal status.`);


        isGood = false;

      } else if ($(`#${ova1_prefix}clinicinfo_lowrisk`).is(":not(:checked)") && $(`#${ova1_prefix}clinicinfo_highrisk`).is(":not(:checked)") && $(`#${ova1_prefix}clinicinfo_NotDefinitive`).is(":not(:checked)")) {

        message.warning(`Please select Ultrasound Results.`);


        isGood = false;
      } //else if ($(`#${ova1_prefix}clinicinfo_DoLastMentrualPeriod`).val().length <= 0) {
      //message.warning(`Please provide the last menstrual period date.`);
      //

      //isGood = false;
      //}
      else {

        isGood = true;
      }

      return isGood;
    }

    const ova1BillingInfoFormNextFunc = () => {

      let isGood = true;

      if (window.location.hostname === 'lab.aspirawh.com'
        || window.location.hostname === 'dev.aspirawh.cloud'
        || window.location.hostname === "order.dev-aspiralab.com"
      ) {
        return isGood;
      }

      const billinfo_PrimaryInsuranceCarrier = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}billinfo_PrimaryInsuranceCarrier`).val());
      $(`#${ova1_prefix}billinfo_PrimaryInsuranceCarrier`).val(billinfo_PrimaryInsuranceCarrier);

      const billinfo_planId = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}billinfo_planId`).val());
      $(`#${ova1_prefix}billinfo_planId`).val(billinfo_planId);

      const billinfo_MemberID = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}billinfo_MemberID`).val());
      $(`#${ova1_prefix}billinfo_MemberID`).val(billinfo_MemberID);

      const billinfo_GroupID = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}billinfo_GroupID`).val());
      $(`#${ova1_prefix}billinfo_GroupID`).val(billinfo_GroupID);

      const billinfo_SecondaryInsuranceCarrier = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}billinfo_SecondaryInsuranceCarrier`).val());
      $(`#${ova1_prefix}billinfo_SecondaryInsuranceCarrier`).val(billinfo_SecondaryInsuranceCarrier);

      const SecondaryplanId = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}SecondaryplanId`).val());
      $(`#${ova1_prefix}SecondaryplanId`).val(SecondaryplanId);

      const billinfo_SecondaryMemberID = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}billinfo_SecondaryMemberID`).val());
      $(`#${ova1_prefix}billinfo_SecondaryMemberID`).val(billinfo_SecondaryMemberID);

      const billinfo_SecondaryGroupID = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}billinfo_SecondaryGroupID`).val());
      $(`#${ova1_prefix}billinfo_SecondaryGroupID`).val(billinfo_SecondaryGroupID);

      const billinfo_TertiaryInsuranceCarrier = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}billinfo_TertiaryInsuranceCarrier`).val());
      $(`#${ova1_prefix}billinfo_TertiaryInsuranceCarrier`).val(billinfo_TertiaryInsuranceCarrier);

      const billinfo_TertiaryMemberID = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}billinfo_TertiaryMemberID`).val());
      $(`#${ova1_prefix}billinfo_TertiaryMemberID`).val(billinfo_TertiaryMemberID);

      const billinfo_TertiaryGroupID = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}billinfo_TertiaryGroupID`).val());
      $(`#${ova1_prefix}billinfo_TertiaryGroupID`).val(billinfo_TertiaryGroupID);

      const billinfo_FirstNameofInsured = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}billinfo_FirstNameofInsured`).val());
      $(`#${ova1_prefix}billinfo_FirstNameofInsured`).val(billinfo_FirstNameofInsured);
      const billinfo_LastNameofInsured = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}billinfo_LastNameofInsured`).val());
      $(`#${ova1_prefix}billinfo_LastNameofInsured`).val(billinfo_LastNameofInsured);

      const billinfo_NameofInsured = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}billinfo_NameofInsured`).val());
      $(`#${ova1_prefix}billinfo_NameofInsured`).val(billinfo_NameofInsured);

      const billinfo_RelationshipInsured = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}billinfo_RelationshipInsured`).val());
      $(`#${ova1_prefix}billinfo_RelationshipInsured`).val(billinfo_RelationshipInsured);

      if ($(`#${ova1_prefix}billinfo_PrivateInsurance`).is(":not(:checked)")
        && $(`#${ova1_prefix}billinfo_Medicare`).is(":not(:checked)")
        && $(`#${ova1_prefix}billinfo_PatientSelfPay`).is(":not(:checked)")
        && $(`#${ova1_prefix}billinfo_Medicaid`).is(":not(:checked)")
        && $(`#${ova1_prefix}billinfo_OrderingFacility`).is(":not(:checked)")) {

        message.warning(`Please select Billing Information.`);


        isGood = false;

      } else if ($(`#${ova1_prefix}billinfo_PrivateInsurance`).is(":checked") || $(`#${ova1_prefix}billinfo_Medicare`).is(":checked") || $(`#${ova1_prefix}billinfo_Medicaid`).is(":checked")) {

        if (billinfo_PrimaryInsuranceCarrier.length < 1) {

          message.warning(`Primary Insurance Carrier is required field.`);


          $(`#${ova1_prefix}billinfo_PrimaryInsuranceCarrier`).focus();
          isGood = false;
          // } else if (billinfo_planId.length < 1) {

          //   message.warning(`Primary Insurance Carrier Plan Id is required field.`);
          //   

          //   $(`#${ova1_prefix}billinfo_planId`).focus();
          //   isGood = false;
        } else if (billinfo_MemberID.length < 1) {

          message.warning(`Primary Insurance Carrier Member Id is required field.`);


          $(`#${ova1_prefix}billinfo_MemberID`).focus();
          isGood = false;
        } else if (billinfo_GroupID.length < 1) {

          message.warning(`Primary Insurance Carrier Group Id is required field.`);


          $(`#${ova1_prefix}billinfo_GroupID`).focus();
          isGood = false;
        } else if (billinfo_FirstNameofInsured.length < 1) {
          message.warning("First name of insured is required.");
          showToast("danger");

          $(`#${ova1_prefix}billinfo_FirstNameofInsured`).focus();

          isGood = false;
        } else if (billinfo_LastNameofInsured.length < 1) {
          message.warning(`Last name of insured is required.`);


          $(`#${ova1_prefix}billinfo_LastNameofInsured`).focus();

          isGood = false;
        }
        // else if (billinfo_NameofInsured.length < 1) {
        //   message.warning(`Name of Insured is required field.`);
        //   

        //   $(`#${ova1_prefix}billinfo_NameofInsured`).focus();

        //   isGood = false;
        // }
        else if (billinfo_RelationshipInsured.length < 1) {

          message.warning(`Relationship to insured is required field.`);


          $(`#${ova1_prefix}billinfo_RelationshipInsured`).focus();

          isGood = false;
        }
      }

      else {

        isGood = true;
      }

      return isGood;
    }

    const ova1DiagICD10CodesInfoFormNextFunc = () => {
      let isGood = true;

      // const OtherICD10Codes = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}OtherICD10Codes`).val());
      // $(`#${ova1_prefix}OtherICD10Codes`).val(OtherICD10Codes);

      // if ($(`#${ova1_prefix}diagnosis_UnOcyst_rs`).is(":not(:checked)")
      //   && $(`#${ova1_prefix}diagnosis_IntraAbdPelSwell`).is(":not(:checked)")
      //   && $(`#${ova1_prefix}diagnosis_Periumbilicalswell`).is(":not(:checked)")
      //   && $(`#${ova1_prefix}diagnosis_UnOcyst_ls`).is(":not(:checked)")
      //   && $(`#${ova1_prefix}diagnosis_lowerquadrantSwell`).is(":not(:checked)")
      //   && $(`#${ova1_prefix}diagnosis_intraabdominalpelvicswell`).is(":not(:checked)")
      //   && $(`#${ova1_prefix}diagnosis_UnOcyst`).is(":not(:checked)")
      //   && $(`#${ova1_prefix}diagnosis_LlQabdominalswelling`).is(":not(:checked)")) {

      //   message.warning(`Please select Diagnosis Codes | ICD-10 Codes.`);
      //   

      //   isGood = false;

      // } else {

      //   isGood = true;
      // }

      return isGood;
    }

    const ova1KitShippingInfoFormNextFunc = () => {
      let isGood = true;

      const kitShip4_PatOthersInfo = helper.removeWhiteSpaceTrim($(`#${ova1_prefix}kitShip_PatOthers`).val());
      $(`#${ova1_prefix}kitShip_PatOthers`).val(kitShip4_PatOthersInfo);

      if ($(`${ova1_prefix}kitShip_PatMobPhlebotomy`).is(":not(:checked)")
        && $(`${ova1_prefix}kitShip_PatblooddrawQuest`).is(":not(:checked)")
        && $(`${ova1_prefix}kitShip_PatOthers`).is(":not(:checked)")) {

        message.warning(`Please select Kit shipping options.`);


        isGood = false;

      } else if ($(`${ova1_prefix}kitShip_PatOthers`).is(":checked") && (kitShip4_PatOthersInfo.length < 1)) {

        message.warning(`Kit shipping other options is required field.`);

        $(`#${ova1_prefix}kitShip_PatOthers`).focus();

        isGood = false;
      }
      else {
        isGood = true;
      }
      return isGood;
    }

    $(`#${ova1_prefix}btnPhysicianInformationFormNext`)
      .off('click') // need - 9
      .on('click', function (event) {
        event.preventDefault();

        if (ova1PhysicianInfoFormNextFunc()) {

          $('#PhysicianInformationForm').removeClass(' show');
          $('#PatientInformationForm').addClass(' show');
          $('#PhysicianInformationFormCheckMark').removeClass('d-none');

          //go to top
          document.body.scrollTop = 306;
          document.documentElement.scrollTop = 306;
        }

      });

    //PatientInformationForm
    $(`#${ova1_prefix}btnPatientInformationFormNext`).off().on('click', (event) => {
      event.preventDefault();

      if (ova1PatientInfoFormNextFunc()) {

        $('#PatientInformationForm').removeClass(' show');
        $('#PatientInformationFormCheckMark').removeClass('d-none');

        if (helper.isAccessingFromAspiraLab()) $('#NotesCertifyForm').addClass(' show');
        else $('#ClinicalInformationForm').addClass(' show');

        //go to top
        document.body.scrollTop = 356;
        document.documentElement.scrollTop = 356;
      }

    });

    //ClinicalInformationForm
    $(`#${ova1_prefix}btnClinicalInformationFormNext`).off().on('click', function (event) {
      event.preventDefault();

      if (ova1ClinicalInfoFormNextFunc()) {

        $('#BillingInformationForm_10').addClass(' show')
        $('#ClinicalInformationForm').removeClass(' show')
        $('#ClinicalInformationFormCheckMark').removeClass('d-none');

        //go to top
        document.body.scrollTop = 406;
        document.documentElement.scrollTop = 406;
      }

    })

    //BillingInformationForm_10
    $(`#${ova1_prefix}btnBillingInformationFormNext`).off().on('click', function (event) {
      event.preventDefault();

      if (ova1BillingInfoFormNextFunc()) {

        $('#BillingInformationForm_10').removeClass(' show')
        $('#DiagnosisCodes_ICD10CodesForm').addClass(' show')

        $('#BillingInformationForm_10CheckMark').removeClass('d-none');

        //go to top
        document.body.scrollTop = 456;
        document.documentElement.scrollTop = 456;
      }

    })

    //DiagnosisCodes_ICD10CodesForm
    $(`#${ova1_prefix}btnDiagnosisCodes_ICD10CodesFormNext`).on('click', function (event) {
      event.preventDefault();

      if (ova1DiagICD10CodesInfoFormNextFunc()) {

        $('#KitShippingInfoForm').addClass(' show')
        $('#DiagnosisCodes_ICD10CodesForm').removeClass(' show')
        $('#DiagnosisCodes_ICD10CodesFormCheckMark').removeClass('d-none');

      }

    });

    //KitShippingInfoForm
    $(`#${ova1_prefix}btnKitShippingInfoFormNext`).on('click', function (event) {
      event.preventDefault();

      if (ova1KitShippingInfoFormNextFunc()) {

        $('#KitShippingInfoForm').removeClass(' show')
        $('#NotesCertifyForm').addClass(' show')
        $('#KitShippingInfoFormCheckMark').removeClass('d-none');

      }

    })

    $(`#${ova1_prefix}btnOva1PlusBioMakersFormReset`).click(() => {
      $('#OVA1plusbiomarkersForm').find(':input').map((i, elem) => {
        const _type = $(elem).attr('type');
        console.log("_type: ", _type);
        helper.resetForm(_type, elem);
      });
    });

    $(`#${ova1_prefix}btnPhysicianInformationFormReset`).click(() => {
      $('#PhysicianInformationForm').find(':input').map((i, elem) => {
        const _type = $(elem).attr('type');
        console.log("_type: ", _type);
        helper.resetForm(_type, elem);
      });
    });

    //Patient reset form function is inside patient class


  });

  const onChangeState = (_state) => {
    console.log("genetixHBOC->_state: ", _state);
    setPatientState(_state);
  }
  const patientDOBFunc = (_dob) => {
    setPatientDOBLocal(_dob);
  };

  const billinfoOVA1_dobLocalFunc = (_dob) => {
    setBillinfoOVA1_dobLocal(_dob);
  };

  const onChangePhysicianStateLocal = (_state) => {
    setPhysicianState(_state);
  }

  const onChangeLastMentrualPeriodDateLocal = (_date) => {
    setClinicalInfoLastMenstrualPeriodDate(_date);
  }

  const selectedPhysicianLegacyInfoFunc = (selectedPhysician) => { //need - 2
    setSelectedPhysicianLegacyInfoState(selectedPhysician);
  }

  if (render < 1) {
    return null;
  }
  return (
    <>

      <input type="hidden" id="clinicinfo_DoLastMentrualPeriod_hidden" value="" />
      {/* <input type="hidden" id={`${ova1_prefix}phyinfo_state`} class="form-control required" placeholder="State" /> */}
      <input type="hidden" id={`${ova1_prefix}patinfo_state`} class="form-control required" placeholder="State" />
      <input type="hidden" id={`${ova1_prefix}billinginfo_DOB`} class="form-control required" placeholder="DOB" />
      <div id={`ova1PlusFormContentContainer`} ref={(_ref) => { containerRef = _ref }} class="body">
        <div class="row clearfix">
          <div class="col-md-12 col-lg-12">
            <div class="panel-group" id="accordion_10" role="tablist" aria-multiselectable="true">
              <Toast
                toastList={toastList}
                position={"top-center"}
                autoDelete={true}
                dismissTime={3000}
                description={toastMessage}

              />

              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingFourtyThree_10">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_10" href="#OVA1plusbiomarkersForm" aria-expanded="false" aria-controls="collapseTwo_10">
                      Additional Biomarkers <span id="OVA1plusbiomarkersFormCheckMark" className="d-none">&#10004;</span>
                    </a>
                  </h4>
                </div>
                <div id="OVA1plusbiomarkersForm" class="print-wrap panel-collapse collapse" role="tabpanel" aria-labelledby="headingFourtyThree_10">
                  <OVA1plusbiomarkersForm prefix={`ova1_`} assays={assays} />
                </div>
              </div>

              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingOne_10">
                  <h4 class="panel-title">
                    <a id="physicianInfoToggle" class="accordion-toggle" role="button" data-toggle="collapse" data-parent="#accordion_10" href="#PhysicianInformationForm" aria-expanded="true" aria-controls="collapseOne_10">
                      <span>
                        Physician Information <span id="PhysicianInformationFormCheckMark" className="d-none">&#10004;</span>
                      </span>
                    </a>
                  </h4>
                </div>

                <div id="PhysicianInformationForm" class="print-wrap panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne_10">
                  <PhysicianInformationForm prefix={`ova1_`}
                    onChangePhysicianState={onChangePhysicianStateLocal}
                    selectedPhysicianLegacyInfo={selectedPhysicianLegacyInfoFunc} //need - 3
                  />
                </div>

              </div>

              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingTwo_10">
                  <h4 class="panel-title">
                    <a id="physicianInfoToggle" class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_10" href="#PatientInformationForm" aria-expanded="false" aria-controls="collapseTwo_10">
                      Patient Information <span id="PatientInformationFormCheckMark" className="d-none">&#10004;</span>
                    </a>
                  </h4>
                </div>
                <div id="PatientInformationForm" class="print-wrap page1 panel-collapse collapse" role="tabpanel" aria-labelledby="headingTwo_10">
                  <PatientInformationForm prefix={`${ova1_prefix}`} phoneNoPrefix={`${patientPhoneNo_prefix}`} dobFunc={patientDOBFunc} onChangeState={onChangeState} onChangeLastMentrualPeriodDate={onChangeLastMentrualPeriodDateLocal} />
                </div>
              </div>


              {!helper.isAccessingFromAspiraLab() && <>
                <div class="panel panel-col-blue-grey">
                  <div class="panel-heading" role="tab" id="headingThree_10">
                    <h4 class="panel-title">
                      <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_10" href="#ClinicalInformationForm" aria-expanded="false" aria-controls="collapseTwo_10">
                        Clinical Information <span id="ClinicalInformationFormCheckMark" className="d-none">&#10004;</span>
                      </a>
                    </h4>
                  </div>
                  <div id="ClinicalInformationForm" class="print-wrap page1 panel-collapse collapse" role="tabpanel" aria-labelledby="headingThree_10">
                    <ClinicalInformationForm prefix={`${ova1_prefix}`} onChangeLastMentrualPeriodDate={onChangeLastMentrualPeriodDateLocal} />
                  </div>
                </div>

                <div class="panel panel-col-blue-grey">
                  <div class="panel-heading" role="tab" id="headingFour_10">
                    <h4 class="panel-title">
                      <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_10" href="#BillingInformationForm_10" aria-expanded="false" aria-controls="collapseTwo_10">
                        Billing Information <span id="BillingInformationForm_10CheckMark" className="d-none">&#10004;</span>
                      </a>
                    </h4>
                  </div>
                  <div id="BillingInformationForm_10" class="print-wrap page1 panel-collapse collapse" role="tabpanel" aria-labelledby="headingFour_10">
                    <BillingInformationForm prefix={`${ova1_prefix}`} billinfo_dobFunc={billinfoOVA1_dobLocalFunc} />
                  </div>
                </div>

                <div class="panel panel-col-blue-grey">
                  <div class="panel-heading" role="tab" id="headingFive_10">
                    <h4 class="panel-title">
                      <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_10" href="#DiagnosisCodes_ICD10CodesForm" aria-expanded="false" aria-controls="collapseTwo_10">
                        Diagnosis Codes | ICD-10 Codes <span id="DiagnosisCodes_ICD10CodesFormCheckMark" className="d-none">&#10004;</span>
                      </a>
                    </h4>
                  </div>
                  <div id="DiagnosisCodes_ICD10CodesForm" class="print-wrap page1 panel-collapse collapse" role="tabpanel" aria-labelledby="headingFive_10">
                    <DiagnosisCodes_ICD10CodesForm prefix={`${ova1_prefix}`} />
                  </div>
                </div>
                <div class="panel panel-col-blue-grey">
                  <div class="panel-heading" role="tab" id="headingSeven_10">
                    <h4 class="panel-title">
                      <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_10" href="#KitShippingInfoForm" aria-expanded="false" aria-controls="collapseTwo_10">
                        Kit Shipping Information <span id="KitShippingInfoFormCheckMark" className="d-none">&#10004;</span>
                      </a>
                    </h4>
                  </div>
                  <div id="KitShippingInfoForm" class="print-wrap page1 panel-collapse collapse" role="tabpanel" aria-labelledby="headingSeven_10">
                    <KitShippingInfoForm prefix={ova1_prefix} />
                  </div>
                </div>
              </>}

              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingEight_10">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_10" href="#NotesCertifyForm" aria-expanded="false" aria-controls="collapseTwo_10">
                      Notes & Certify
                    </a>
                  </h4>
                </div>
                <div id="NotesCertifyForm" class="print-wrap page1 panel-collapse collapse" role="tabpanel" aria-labelledby="headingEight_10">
                  <NotesCertifyForm prefix={`${ova1_prefix}`} sigCollect={(_sigPad) => sigPad = _sigPad} captureSignature={(signatureString) => setPhysicianSignature(signatureString)} />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

    </>

  );

}

export default Ova1PlusFormContent;