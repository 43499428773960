import React, { PureComponent } from "react";

import PropTypes from 'prop-types';
import $ from 'jquery';
import helper from './../utils/helperPortal';
//import DatePicker from 'react-datepicker';

export default class DiagnosisCodes_ICD10CodesForm2 extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {

        }

    }

    componentDidMount() {
        $(document).ready(() => {
            $('#btnDiagnosisCodes_ICD10CodesForm2Reset').click(() => {
                let inputs = $('#ova1GenetixHBOCDiagICDCodesContainer').find(':input');

                inputs.map((i, elem) => {
                    const _type = $(elem).attr('type');
                    helper.resetForm(_type, elem);
                })
            });
            
            // $("#product_4").click(() => {
            //     if ($(this).is(":checked")) {
                    
            //         $('#Ova1_HBOC_covid_icd_codes2').show();
            //     }
            //     else {
            //         $('#Ova1_HBOC_covid_icd_codes2').hide();
            //     }
            // })

            $("#diagnosisHBOC_search2").on("keyup", function () {


                // Retrieve the input field text and reset the count to zero
                var filter = $(this).val(),
                    count = 0;

                // Loop through the comment list
                $('.demo-checkbox label').each(function () {


                    // If the list item does not contain the text phrase fade it out
                    if ($(this).text().search(new RegExp(filter, "i")) < 0) {
                        $(this).hide();

                        // Show the list item if the phrase matches and increase the count by 1
                    } else {
                        $(this).show();
                        count++;
                    }

                });

            });
        })
    }

    render() {

        return (
            <>
                <div id={`ova1GenetixHBOCDiagICDCodesContainer`} class="panel-body">
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="form-group required">
                                <div class="row clearfix">
                                    <div class="col-md-6"></div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <div class="form-line">
                                                <input type="text" id="diagnosisHBOC_search2" class="form-control" placeholder="Search..." />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row clearfix">
                                    <div class="col-md-12">
                                        <label class="form-label" for="DiagnosisCodes_OVA1"><b>OVA1 Diagnosis Codes | ICD-10 Codes (check all that apply)</b></label>
                                    </div>
                                </div>
                                <div id="oVA1-DiagnosisCodes">
                                    <div class="demo-checkbox">
                                        <div class="row clearfix">
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="N83.201" id="diagnosis_UnOcyst_rs2" name="diagnosis_UnOcyst_rs2" class="filled-in" />
                                                <label for="diagnosis_UnOcyst_rs2">N83.201 Unspecified ovarian cyst, right side+ &nbsp; </label>
                                            </div>
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="R19.00" id="diagnosis_IntraAbdPelSwell2" name="diagnosis_IntraAbdPelSwell2" class="filled-in" />
                                                <label for="diagnosis_IntraAbdPelSwell2">R19.00 Intra-abdominal and pelvic swelling, mass and lump, unspecified site+ &nbsp; </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="demo-checkbox">
                                        <div class="row clearfix">
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="R19.05" id="diagnosis_Periumbilicalswell2" name="diagnosis_Periumbilicalswell2" class="filled-in" />
                                                <label for="diagnosis_Periumbilicalswell2">R19.05 Periumbilical swelling, mass and lump+ &nbsp; </label>
                                            </div>
                                            <div class="col-md-6">
                                                <input type="checkbox"  data-val="N83.202" id="diagnosis_UnOcyst_ls2" name="diagnosis_UnOcyst_ls2" class="filled-in" />
                                                <label for="diagnosis_UnOcyst_ls2">N83.202 Unspecified ovarian cyst, left side+ &nbsp; </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="demo-checkbox">
                                        <div class="row clearfix">
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="R19.03" id="diagnosis_lowerquadrantSwell2" name="diagnosis_lowerquadrantSwell2" class="filled-in" />
                                                <label for="diagnosis_lowerquadrantSwell2">R19.03 Right lower quadrant abdominal swelling, mass and lump &nbsp; </label>
                                            </div>
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="R19.09" id="diagnosis_intraabdominalpelvicswell2" name="diagnosis_intraabdominalpelvicswell2" class="filled-in" />
                                                <label for="diagnosis_intraabdominalpelvicswell2">R19.09 Other intra-abdominal pelvic swelling, mass and lump+ &nbsp; </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="demo-checkbox">
                                        <div class="row clearfix">
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="N83.209" id="diagnosis_UnOcyst2" name="diagnosis_UnOcyst2" class="filled-in" />
                                                <label for="diagnosis_UnOcyst2">N83.209 Unspecified ovarian cyst, unspecified side+ &nbsp; </label>
                                            </div>
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="R19.04" id="diagnosis_LlQabdominalswelling2" name="diagnosis_LlQabdominalswelling2" class="filled-in" />
                                                <label for="diagnosis_LlQabdominalswelling2">R19.04 Left lower quadrant abdominal swelling, mass and lump &nbsp; </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div id="hBOC-DiagnosisCodes">
                                    <div class="row clearfix">
                                        <div class="col-md-12">
                                            <label class="form-label" for="DiagnosisCodes_GHBOCG"><b>GHBOCG Diagnosis Codes | ICD-10 Codes (check all that apply)</b></label>
                                        </div>
                                    </div>
                                    <div class="demo-checkbox">
                                        <div class="row clearfix">
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="Z15.01" id="hBOCdiagnosis2_z1501" name="hBOCdiagnosis2_z1501" class="filled-in" />
                                                <label for="hBOCdiagnosis2_z1501">Z15.01 Genetic susceptibility to malignant neoplasm of breast &nbsp; </label>
                                            </div>
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="C48.1" id="hBOCdiagnosis2_c481" name="hBOCdiagnosis2_c481" class="filled-in" />
                                                <label for="hBOCdiagnosis2_c481">C48.1 Malignant neoplasm of specified parts of peritoneum &nbsp; </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="demo-checkbox">
                                        <div class="row clearfix">
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="Z15.02" id="hBOCdiagnosis2_z1502" name="hBOCdiagnosis2_z1502" class="filled-in" />
                                                <label for="hBOCdiagnosis2_z1502">Z15.02 Genetic susceptibility to malignant neoplasm of ovary &nbsp; </label>
                                            </div>
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="C50.0" id="hBOCdiagnosis2_c500" name="hBOCdiagnosis2_c500" class="filled-in" />
                                                <label for="hBOCdiagnosis2_c500">C50.0 Malignant neoplasm of breast [add anatomic site detail] &nbsp; </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="demo-checkbox">
                                        <div class="row clearfix">
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="Z15.03" id="hBOCdiagnosis2_z1503" name="hBOCdiagnosis2_z1503" class="filled-in" />
                                                <label for="hBOCdiagnosis2_z1503">Z15.03 Genetic susceptibility to malignant neoplasm of prostate &nbsp; </label>
                                            </div>
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="C56.1" id="hBOCdiagnosis2_c561" name="hBOCdiagnosis2_c561" class="filled-in" />
                                                <label for="hBOCdiagnosis2_c561">C56.1 Malignant neoplasm of right ovary &nbsp; </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="demo-checkbox">
                                        <div class="row clearfix">
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="Z15.04" id="hBOCdiagnosis2_z1504" name="hBOCdiagnosis2_z1504" class="filled-in" />
                                                <label for="hBOCdiagnosis2_z1504">Z15.04 Genetic susceptibility to malignant neoplasm of endometrium &nbsp; </label>
                                            </div>
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="C56.2" id="hBOCdiagnosis2_c562" name="hBOCdiagnosis2_c562" class="filled-in" />
                                                <label for="hBOCdiagnosis2_c562">C56.2 Malignant neoplasm of left ovary &nbsp; </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="demo-checkbox">
                                        <div class="row clearfix">
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="Z15.09" id="hBOCdiagnosis2_z1509" name="hBOCdiagnosis2_z1509" class="filled-in" />
                                                <label for="hBOCdiagnosis2_z1509">Z15.09 Genetic susceptibility to other malignant neoplasm &nbsp; </label>
                                            </div>
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="C56.9" id="hBOCdiagnosis2_c569" name="hBOCdiagnosis2_c569" class="filled-in" />
                                                <label for="hBOCdiagnosis2_c569">C56.9 Malignant neoplasm of unspecified ovary &nbsp; </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="demo-checkbox">
                                        <div class="row clearfix">
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="Z15.81" id="hBOCdiagnosis2_z1581" name="hBOCdiagnosis2_z1581" class="filled-in" />
                                                <label for="hBOCdiagnosis2_z1581">Z15.81 Genetic susceptibility to multiple endocrine neoplasia [MEN] &nbsp; </label>
                                            </div>
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="C57.00" id="hBOCdiagnosis2_c5700" name="hBOCdiagnosis2_c5700" class="filled-in" />
                                                <label for="hBOCdiagnosis2_c5700">C57.00 Malignant neoplasm of unspecified fallopain tube &nbsp; </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="demo-checkbox">
                                        <div class="row clearfix">
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="C25.0" id="hBOCdiagnosis2_C250" name="hBOCdiagnosis2_C250" class="filled-in" />
                                                <label for="hBOCdiagnosis2_C250">C25.0 Malignant neoplasm of head of pancreas &nbsp; </label>
                                            </div>
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="C57.01" id="hBOCdiagnosis2_c5701" name="hBOCdiagnosis2_c5701" class="filled-in" />
                                                <label for="hBOCdiagnosis2_c5701">C57.01 Malignant neoplasm of right fallopain tube &nbsp; </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="demo-checkbox">
                                        <div class="row clearfix">
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="C25.1" id="hBOCdiagnosis2_C251" name="hBOCdiagnosis2_C251" class="filled-in" />
                                                <label for="hBOCdiagnosis2_C251">C25.1 Malignant neoplasm of body of pancreas &nbsp; </label>
                                            </div>
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="C57.01" id="hBOCdiagnosis2_c5702" name="hBOCdiagnosis2_c5702" class="filled-in" />
                                                <label for="hBOCdiagnosis2_c5702">C57.01 Malignant neoplasm of left fallopain tube &nbsp; </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="demo-checkbox">
                                        <div class="row clearfix">
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="C25.2" id="hBOCdiagnosis2_C252" name="hBOCdiagnosis2_C252" class="filled-in" />
                                                <label for="hBOCdiagnosis2_C252">C25.2 Malignant neoplasm of tail of pancreas &nbsp; </label>
                                            </div>
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="C61" id="hBOCdiagnosis2_c61" name="hBOCdiagnosis2_c61" class="filled-in" />
                                                <label for="hBOCdiagnosis2_c61">C61 Malignant neoplasm of prostate &nbsp; </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="demo-checkbox">
                                        <div class="row clearfix">
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="C25.3" id="hBOCdiagnosis2_C253" name="hBOCdiagnosis2_C253" class="filled-in" />
                                                <label for="hBOCdiagnosis2_C253">C25.3 Malignant neoplasm of pancreatic duct &nbsp; </label>
                                            </div>
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="D05.11" id="hBOCdiagnosis2_D0511" name="hBOCdiagnosis2_D0511" class="filled-in" />
                                                <label for="hBOCdiagnosis2_D0511">D05.11 Intraductal carcinoma in situ of right breast &nbsp; </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="demo-checkbox">
                                        <div class="row clearfix">
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="C25.4" id="hBOCdiagnosis2_C254" name="hBOCdiagnosis2_C254" class="filled-in" />
                                                <label for="hBOCdiagnosis2_C254">C25.4 Malignant neoplasm of endocrine pancreas &nbsp; </label>
                                            </div>
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="D05.12" id="hBOCdiagnosis2_D0512" name="hBOCdiagnosis2_D0512" class="filled-in" />
                                                <label for="hBOCdiagnosis2_D0512">D05.12 Intraductal carcinoma in situ of left breast &nbsp; </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="demo-checkbox">
                                        <div class="row clearfix">
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="C25.7" id="hBOCdiagnosis2_C257" name="hBOCdiagnosis2_C257" class="filled-in" />
                                                <label for="hBOCdiagnosis2_C257">C25.7 Malignant neoplasm of other parts of pancreas &nbsp; </label>
                                            </div>
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="Z85.07" id="hBOCdiagnosis2_Z8507" name="hBOCdiagnosis2_Z8507" class="filled-in" />
                                                <label for="hBOCdiagnosis2_Z8507">Z85.07 Personal history of malignant neoplasm of pancreas &nbsp; </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="demo-checkbox">
                                        <div class="row clearfix">
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="C25.8" id="hBOCdiagnosis2_C258" name="hBOCdiagnosis2_C258" class="filled-in" />
                                                <label for="hBOCdiagnosis2_C258">C25.8 Malignant neoplasm of overlapping sites of pancreas &nbsp; </label>
                                            </div>
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="Z85.3" id="hBOCdiagnosis2_Z853" name="hBOCdiagnosis2_Z853" class="filled-in" />
                                                <label for="hBOCdiagnosis2_Z853">Z85.3 Personal history of malignant neoplasm of breast &nbsp; </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="demo-checkbox">
                                        <div class="row clearfix">
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="C25.9" id="hBOCdiagnosis2_C259" name="hBOCdiagnosis2_C259" class="filled-in" />
                                                <label for="hBOCdiagnosis2_C259">C25.9 Malignant neoplasm of pancreas, unspecified &nbsp; </label>
                                            </div>
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="Z85.43" id="hBOCdiagnosis2_Z8543" name="hBOCdiagnosis2_Z8543" class="filled-in" />
                                                <label for="hBOCdiagnosis2_Z8543">Z85.43 Personal history of malignant neoplasm of ovary &nbsp; </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="demo-checkbox">
                                        <div class="row clearfix">
                                            <div class="col-md-6">
                                            </div>
                                            <div class="col-md-6">
                                                <input type="checkbox" data-val="Z85.46" id="hBOCdiagnosis2_Z8546" name="hBOCdiagnosis_Z8546" class="filled-in" />
                                                <label for="hBOCdiagnosis2_Z8546">Z85.46 Personal history of malignant neoplasm of prostate &nbsp; </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div id={`Ova1_HBOC_covid_icd_codes2`}>
                                    <div class="form-group required">
                                        <label class="form-label" for={`${this.props.prefix}DiagnosisCodes`}><b>{(this.props.prefix === 'ova1_') ? "OVA1 " : "COVD "} Diagnosis Codes | ICD-10 Codes (check all that apply)</b></label>
                                        <div class="demo-checkbox">
                                            <div class="row clearfix">
                                                <div class="col-md-6">
                                                    <input type="checkbox" data-val="Z11.59" id={`${this.props.prefix}diagnosis_z1159`} name={`${this.props.prefix}diagnosis_z1159`} class="filled-in" />
                                                    <label for={`${this.props.prefix}diagnosis_z1159`}>Z11.59, Encounter for screening for other viral diseases &nbsp; </label>
                                                </div>
                                                <div class="col-md-6">
                                                    <input type="checkbox"data-val="Z03.818" id={`${this.props.prefix}diagnosis_z03818`} name={`${this.props.prefix}diagnosis_z03818`} class="filled-in" />
                                                    <label for={`${this.props.prefix}diagnosis_z03818`}>Z03.818, Encounter for observation for suspected exposure to other biological agents ruled out &nbsp; </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="form-group ">
                                <label class="form-label" for="address">Other ICD-10 Codes</label>
                                <div class="form-line">
                                    <input type="text" id="OtherICD10Codes2" class="form-control " placeholder="Other ICD-10 Codes" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12 text-right">
                            <button id={`btnDiagnosisCodes_ICD10CodesForm2Reset`} class="btn btn-light">Reset Form</button>
                            <button id="btnDiagnosisCodes_ICD10CodesForm2Next" class="btn btn-light">Next</button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
