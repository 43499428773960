import React, { useState, useEffect } from "react";
//import dotenv from "dotenv";
import axios from "axios";

// import jsPDF from "jspdf";
// import ReactDOMServer from "react-dom/server";
import { protectedResources } from "./../authConfig";
import { MsalContext } from "@azure/msal-react";

import ReportCorrectionForm from "./ReportCorrectionForm";

import DeleteModal from "../mycomponents/modal";
import ProgressBar from "../mycomponents/progressbar";

import DatePicker from "react-datepicker";
import helper from "../utils/helper";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net-bs/css/dataTables.bootstrap.css";
import moment from "moment";
import { message } from "antd";

import Spinner from "../Spinner/Spinner";

const $ = require("jquery");
$.DataTable = require("datatables.net");
// require( 'datatables.net-bs' )( window, $ );

//dotenv.config();

const API_BASE_URL = helper.getAPIBaseURI();
let me = null;
export default class ResultedReportContent extends React.PureComponent {
  static contextType = MsalContext;

  constructor(props) {
    super(props);

    this.state = {
      orders: [],
      loading: true,
      showLoader: false,

      showOrderModal: false,
      deleteOrderId: null,
      showProgressBar: false,

      startDate: "",
      endDate: "",

      showReportContent: false,
      showReportCorrectionForm: false,
      reportSignedUrl: "",
      selectedOrderId: "",

      toastMessage: "",
      list: [],
      toastList: [],

      //For PDF
      numPages: 0,
      pageNumber: 0,
      serverSideRendering: process.env.REACT_APP_SERVER_SIDE_RENDERING_ENABLED === 'true',
    };

    this.orderDataTable = React.createRef();

    this.handleButtonPress = this.handleButtonPress.bind(this);
    this.handleButtonRelease = this.handleButtonRelease.bind(this);

    me = this;

    this.$el = null;
  }

  showToast(type) {
    const id = Math.floor(Math.random() * 101 + 1);

    switch (type) {
      case "success":
        this.toastProperties = {
          id,
          title: "Success",
          description: "This is a success toast component",
          backgroundColor: "#5cb85c",
          icon: "/assets/assets/check.svg",
        };
        break;
      case "danger":
        this.toastProperties = {
          id,
          title: "Danger",
          description: "This is a error toast component",
          backgroundColor: "#d9534f",
          icon: "/assets/assets/error.svg",
        };
        break;
      case "info":
        this.toastProperties = {
          id,
          title: "Info",
          description: "This is an info toast component",
          backgroundColor: "#5bc0de",
          icon: "/assets/assets/info.svg",
        };
        break;
      case "warning":
        this.toastProperties = {
          id,
          title: "Warning",
          description: "This is a warning toast component",
          backgroundColor: "#f0ad4e",
          icon: "/assets/assets/warning.svg",
        };
        break;

      default:
        this.setState({ toastList: [] });
    }

    // this.setState([...this.state.list, this.toastProperties]);
    this.setState({ list: [this.toastProperties] });
  }
  handleButtonPress() {
    this.buttonPressTimer = setTimeout(
      () => this.setState({ showProgressBar: true }),
      1000
    );
  }

  handleButtonRelease() {
    clearTimeout(this.buttonPressTimer);
  }
  async callDataTable(datatotables, type = null) {
    const me = this;

    const user = JSON.parse(localStorage.getItem("user"));

    // first time, el will be null
    // see: https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs

    console.log("this.orderDataTable: ", this.orderDataTable);

    if (!this.orderDataTable) return;

    console.log("datatotables: ", datatotables);

    if (this.$el) {
      this.$el.DataTable().clear().destroy();
    }

    this.$el = $(this.orderDataTable);
    //  console.log(this.$el.DataTable);
    let columns = [
      {
        title: "QBench Order ID",
        data: "orderId",
        name: "orderId",
        // width: "10px",
        sortable: true,
      },
      {
        title: "Patient First Name",
        data: "Patient.firstName",
        name: "firstName",
        width: "15px",
      },
      {
        title: "Patient Last Name",
        data: "Patient.lastName",
        name: "lastName",
        width: "15px",
      },
      {
        title: "Test Ordered",
        data: null,
        name: "orderedTest",
        width: "150px",
        render: (data, type) => {
          let testName = "";

          // if(data.tests.length===1) {
          //     testName=data.tests[0].testName;
          // }
          // else if(data.tests.)

          data.tests.map((item, i) => {
            if (typeof item.testName != "undefined") {
              //   if (testName == "") {
              //     testName += item.testName;
              //   } else {
              //     testName += "+" + item.testName;
              //   }

              testName =
                item.testName == "GenetixHBOC1" ||
                  item.testName == "GenetiXCarrierScreening"
                  ? item.genetixCSPanel[0]
                  : item.testName;
            }
          });

          //testName = testName.substring(0, testName.length - 1);
          if (type === "display") {
            return `<td>
                              <span class="">${testName}</td>
                          </td> `;
          }
          return data;
        },
      },
      {
        title: "Client Id",
        data: "legacyClientId",
        name: "legacyClientId",
        width: "50px",
        sortable: true,
      },
      {
        title: "Status",
        data: null,
        name: "status",
        width: "100px",
        render: function (data, type) {
          if (type === "display") {
            if (
              data.status === "Approved" ||
              data.status == "Order Authorized"
            ) {
              return `<td>
                              <span class="status_font label label-success">${data.status}</td>
                          </td> `;
            } else if (
              data.status === "Pending" ||
              data.status == "WAITING BILLING"
            ) {
              return `<td>
                              <span class="status_font label label-default">${data.status}</span>
                          </td> `;
            } else if (data.status === "Denied") {
              return `<td>
                              <span class="status_font label label-danger">${data.status}</td>
                          </td> `;
            } else {
              return `<td>
                              <span class="status_font label">${data.status}</td>
                          </td> `;
            }
          }
          return data;
        },
      },
      {
        title: "Report Status",
        data: "reportStatus",
        name: "reportStatus",
        width: "100px",
        sortable: true,
      },
      {
        title: "Report Conclusion",
        data: null,
        name: "reportConclusion",
        // width: "150px",
        render: function (data, type) {
          if (type == "display") {
            let imgPath = "";

            if (
              typeof data.reportConclusion == "undefined" ||
              !data.reportConclusion ||
              data.reportConclusion.length === 0
            ) {
              imgPath = `/icons/Negative.png`;
              return `<td>
                              
              <img style="width: 40; height: 40;" class="positive_negative_image" src="${imgPath}" />
              </td>
          </td>`;
            } else {
              if (
                data.reportConclusion == "Negative" ||
                data.reportConclusion == "negative"
              ) {
                imgPath = `/icons/Negative.png`;
              }
              else if (data.reportConclusion == "Cancelled" ||
                data.reportConclusion == "cancelled") {
                imgPath = `/icons/cancelled.png`;
              }
              else if (data.reportConclusion == "VUS") {
                imgPath = `/icons/VUS.png`;
              } else {
                imgPath = `/icons/Positive.png`;
              }
              return `<td>
                              
              <img style="width: 40; height: 40;" class="positive_negative_image" src="${imgPath}" />
              </td>
          </td>`;
            }
          }
          return data;
        },
      },

      {
        title: "",
        name: "",
        data: null,
        sortable: false,
        render: function (data, type) {
          if (type === "display") {
            console.log("uploadDownloadClass->data: ", data);

            let testName_page = "";

            data.tests.map((item, i) => {
              if (typeof item.testName != "undefined") {
                if (testName_page == "") {
                  testName_page += item.testName;
                } else {
                  if (item.testName != "COVD")
                    testName_page += "_" + item.testName;
                }
              }
            });

            let html = `<td>
                                  <a style="cursor: pointer;" class="ViewReportClass" data-orderid="${data.orderId}" data-reportid="${data.baylorReportId} data-galenreportid="${data.galenReportId}">View</a>
                              `;

            //Following is good but not required now as per Pierre and new CTO
            // if (data.tests[0].testCode.toLowerCase() === 'ova1' || data.tests[0].testCode.toLowerCase() === 'vds-125') {
            //   html += `<a class="editClass" data-id="${data._id}" data-val="${testName_page}"><img style="height: 20px; width: 20px; cursor: pointer;" src="/icons/icons8-edit-50.svg" /></a>`;
            // }

            html += `</td>`;
            return html;

          }
          return data;
        },
      },

    ];

    if (me.state.serverSideRendering) {
      const accessToken = await helper.getAccessToken(this.context)

      var ajax = {
        url: `${API_BASE_URL}/orders/paging`,
        type: "POST",

        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: {
          type: type,
          userId: user.id,

        },
      };
      this.$el = $(`#orderDataTable`).DataTable({
        "lengthMenu": [25, 50, 100],
        "pageLength": 25,

        responsive: false,
        processing: true,

        ajax: ajax,
        serverSide: true,

        "deferRender": true,

        columns: columns,
        "order": [],
        "paging": true,
        "autoWidth": false,
      });
    }
    else {
      this.$el.DataTable({
        pageLength: 25,
        order: [],
        data: datatotables,
        columns: columns,
      });
    }
    this.$el.on("click", "tr a.pdfClass", async function () {
      let item_id = $(this).data("id");
      let custom_formatted_id = $(this).data("orderid");
      let item_prefix = $(this).data("val");
      let editHref = "";

      // const accessToken = await helper.getAccessToken(me.context);
      // helper.printPDF(item_id, custom_formatted_id, accessToken);

      const accessToken = await helper.getAccessToken(me.context);
      let orderData = await helper.getOrderFromServerById(item_id, accessToken);
      let exactHtmlUrl = "";
      if (process.env.REACT_APP_ENV === 'DEV') {
        exactHtmlUrl = await helper.getTRFHtmlUrl(orderData);
      }
      else {
        exactHtmlUrl = await helper.getTRFHtmlUrl(orderData);
      }

      if (exactHtmlUrl) {
        window.open(exactHtmlUrl);
        // let htmlRawData = await helper.getTRFHtmlFromUrl(exactHtmlUrl, accessToken);

        // let element = htmlRawData;
        // const doc = new jsPDF("p", "pt", "letter");
        // doc.html(ReactDOMServer.renderToString(element), {
        //   callback: function (doc) {
        //     doc.save(`trf-${custom_formatted_id}.pdf`);
        //   }
        // });
      }
    });

    this.$el.on("click", "tr a.editClass", function () {
      let item_id = $(this).data("id");
      let item_prefix = $(this).data("val");
      let editHref = "";

      if (item_prefix == "Ova1") editHref = `/editorder_ova1?id=${item_id}`;
      else
        editHref = `/editorder_${item_prefix}?id=${item_id}&returnurl=${window.location.href}`;

      console.log("editHref: ", editHref);
      // console.log("me.props: ", me.props);
      // me.props.history.push(editHref);
      // return <Redirect to={editHref} />

      // me.context.router.history.push(editHref);

      const userHasReportCorrectionRole = user.allRoles?.find(
        (role) => role.name === "Portal_Report_Correction" || role.name === "Admin"
      );

      if (userHasReportCorrectionRole) {
        me.setState({
          selectedOrderId: item_id,
          showReportCorrectionForm: true,
        });
      } else window.location.href = editHref;
    });
    this.$el.on("click", "tr a.ViewReportClass", function () {
      let custom_formatted_id = $(this).data("orderid");
      let report_id = $(this).data("reportid");
      let galen_report_id = $(this).data("galenreportid");

      document.body.classList.add("busy-cursor");

      me.setState(
        { showLoader: false, loading: false, showReportContent: true },
        async () => {
          $("html, body").animate(
            {
              scrollTop: $("div#reportContent").offset().top,
            },
            2000
          );

          const accessToken = await helper.getAccessToken(me.context);
          if (report_id) {
            axios({
              url: `${API_BASE_URL}/baylor/reports/${custom_formatted_id}/signedUrl`,
              method: "GET",
              headers: {
                "Content-type": "Application/json",
                Authorization: `Bearer ${accessToken}`,
              },
              // data: _payload,
            })
              .then(({ data }) => {
                console.log("data: ", data);

                document.body.classList.remove("busy-cursor");

                me.setState(
                  {
                    reportSignedUrl: data,
                    selectedOrderId: custom_formatted_id,
                    loading: false,
                    showLoader: false,
                    toastMessage: `Successfully Viewed the Report, SEE BOTTOM`,
                  },
                  () => {
                    //me.showToast("success");
                    message.success(me.state.toastMessage);
                  }
                );
              })
              .catch((error) => {
                document.body.classList.remove("busy-cursor");

                me.setState(
                  {
                    loading: false,
                    showLoader: false,
                    toastMessage: `Viewing Report error: ${error}`,
                  },
                  () => {
                    //me.showToast("success");
                    message.success(me.state.toastMessage);
                  }
                );
              });
          } else if (galen_report_id) {
            axios({
              url: `${API_BASE_URL}/galen/reports/${custom_formatted_id}/signedUrl`,
              method: "GET",
              headers: {
                "Content-type": "Application/json",
                Authorization: `Bearer ${accessToken}`,
              },
              // data: _payload,
            })
              .then(({ data }) => {
                console.log("data: ", data);

                document.body.classList.remove("busy-cursor");

                me.setState(
                  {
                    reportSignedUrl: data,
                    selectedOrderId: custom_formatted_id,
                    loading: false,
                    showLoader: false,
                    toastMessage: `Successfully Viewed the Report, SEE BOTTOM`,
                  },
                  () => {
                    //me.showToast("success");
                    message.success(me.state.toastMessage);
                  }
                );
              })
              .catch((error) => {
                document.body.classList.remove("busy-cursor");

                me.setState(
                  {
                    loading: false,
                    showLoader: false,
                    toastMessage: `Viewing Report error: ${error}`,
                  },
                  () => {
                    //me.showToast("success");
                    message.success(me.state.toastMessage);
                  }
                );
              });
          }
        }
      );
    });
  }
  async componentDidMount() {
    const me = this;


    let user = JSON.parse(localStorage.getItem("user"));
    console.log("user.token: ", user.token);

    const accessToken = await helper.getAccessToken(this.context)
    if (process.env.REACT_APP_ENV !== 'DEV') {
      if (!user) {

        ({ data: { user } } = await axios.get(`${API_BASE_URL}/users/current`, {
          headers: {
            "Authorization": `Bearer ${accessToken}`
          }
        }));

        localStorage.setItem("user", JSON.stringify(user));
      }

    }


    let _ordertype = this.props.forBilling
      ? "resulted_report_for_billing"
      : "resulted_report";
    let _pageTitle = "Final Resulted Report Orders";

    this.setState({ _pageTitle: _pageTitle });

    if (this.state.serverSideRendering) {
      me.setState({ loading: false }, () => {
        me.callDataTable(null, _ordertype);
      });
    }
    else {
      axios({
        url: `${API_BASE_URL}/orders?type=${_ordertype}`,
        method: "GET",
        headers: {
          "Content-type": "Application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      }).then(({ data }) => {
        console.log("data: ", data);
        // setOrders(data.orders);

        me.setState({ loading: false }, () => {
          me.callDataTable(data.orders);
        });
      });
    }
  }

  showModal = (e) => {
    this.setState({
      showOrderModal: !this.state.showOrderModal,
    });
  };

  //For PDF
  /*To Prevent right click on screen*/
  // window.document.addEventListener("contextmenu", (event) => {
  //   event.preventDefault();
  // });

  /*When document gets loaded successfully*/
  onDocumentLoadSuccess({ numPages }) {
    this.setState({ numPages: numPages, pageNumber: 1 });
  }

  changePage(offset) {
    this.setState({ pageNumber: this.state.pageNumber + offset });
  }

  previousPage() {
    this.changePage(-1);
  }

  nextPage() {
    this.changePage(1);
  }
  render() {
    if (!this.state.loading) {
      return (
        <div style={{ position: "relative" }}>
          <div class="block-header">
            <h2>{this.state._pageTitle}</h2>

          </div>
          <div class="row clearfix">
            <div class="col-md-12 col-xs-12">
              <div class="card orders-list">
                <div class="body">
                  <div class="table-responsive">
                    <table
                      id="orderDataTable"
                      itemID="orderDataTable"
                      className="display"
                      width="100%"
                      ref={(el) => (this.orderDataTable = el)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {this.state.showReportCorrectionForm && (
            <ReportCorrectionForm
              me={this}
              orderId={this.state.selectedOrderId}
              onClose={() =>
                this.setState({
                  selectedOrderId: "",
                  showReportCorrectionForm: false,
                })
              }
            />
          )}
          {this.state.showLoader && (
            <div>
              <Spinner />
            </div>
          )}
          {this.state.showReportContent && (
            <>
              <div id="reportContent">
                
                <div class="row clearfix">
                  <div class="col-md-12 col-xs-12">
                    
                    <hr />
                    <object
                      data={`${this.state.reportSignedUrl}`}
                      type="application/pdf"
                      width="100%"
                      height="678"
                    >
                      <iframe
                        src={`${this.state.reportSignedUrl}`}
                        width="100%"
                        height="678"
                      >
                        <p>This browser does not support PDF!</p>
                      </iframe>
                    </object>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      );
    } else {
      return (
        <div>
          <div class="block-header">
            <h2>Orders</h2>
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/orders">Orders</a>
              </li>
              <li class="breadcrumb-item active">View All Orders</li>
            </ul>
          </div>
          <div class="row clearfix">
            <div class="col-md-12 col-xs-12">
              <div class="card orders-list">
                <div class="body">
                  <Spinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
