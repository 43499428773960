import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

export const Context = createContext({});

export const Provider = props => {
    // Initial values are obtained from the props
    const {
        selectedPhysicianLegacyInfo: initialPhysicianLegacyInfo,
        patientDOB: initialPatientDOB,
        physicianAddresses: initialPhysicianAddresses,
        selectedPhysicianAddress: initialSelectedPhysicianAddresses,

        physicianPracticeLocations: initialPhysicianPracticeLocations,
        selectedPhysicianPracticeLocation: initialSelectedPhysicianPracticeLocations,
        children
    } = props;

    // Use State to keep the values
    const [physicianAddresses, setPhysicianAddresses] = useState(initialPhysicianAddresses);
    const [selectedPhysicianAddress, setSelectedPhysicianAddressLocal] = useState(initialSelectedPhysicianAddresses);

    const [physicianPracticeLocations, setPhysicianPracticeLocation] = useState(initialPhysicianPracticeLocations);
    const [selectedPhysicianPracticeLocation, setSelectedPhysicianPracticeLocationLocal] = useState(initialSelectedPhysicianPracticeLocations);

    const [patientDOB, setPatientDOBLocal] = useState(initialPatientDOB);

    const [selectedPhysicianLegacyInfo, setSelectedPhysicianLegacyInfoLocal] = useState(initialPhysicianLegacyInfo);

    const addNewPhysicianAddress = (address_1,
        address_2,
        address_purpose,
        address_type,
        city,
        country_code,
        country_name,
        postal_code,
        state,
        telephone_number) => {

        const newphysicianAddress = {
            id: new Date().getTime().toString(),
            address_1,
            address_2,
            address_purpose,
            address_type,
            city,
            country_code,
            country_name,
            postal_code,
            state,
            telephone_number
        };
        console.log("physicianAddresses before: ", physicianAddresses);
        physicianAddresses.push(newphysicianAddress);
        // physicianAddresses.concat([newphysicianAddress])
        setPhysicianAddresses(physicianAddresses);
        console.log("physicianAddresses after: ", physicianAddresses);
    };

    const setSelectedPhysicianLegacyInfo = (
        selectedNPI,
        selectedClientId
    ) => {
        const newphysicianLegacyInfo = {
            selectedNPI,
            selectedClientId
        };
        setSelectedPhysicianLegacyInfoLocal(newphysicianLegacyInfo);
    };
    const setSelectedPhysicianAddress = (address_1,
        address_2,
        address_purpose,
        address_type,
        city,
        country_code,
        country_name,
        postal_code,
        state,
        telephone_number) => {

        const newphysicianAddress = {
            id: new Date().getTime().toString(),
            address_1,
            address_2,
            address_purpose,
            address_type,
            city,
            country_code,
            country_name,
            postal_code,
            state,
            telephone_number
        };
        setSelectedPhysicianAddressLocal(newphysicianAddress);
    };

    const addNewPhysicianPracticeLocation = (
        address_1,
        address_2,
        address_purpose,
        address_type,
        city,
        country_code,
        country_name,
        postal_code,
        state,
        telephone_number) => {

        const newphysicianPracticeLocation = {
            id: new Date().getTime().toString(),
            address_1,
            address_2,
            address_purpose,
            address_type,
            city,
            country_code,
            country_name,
            postal_code,
            state,
            telephone_number
        };
        setPhysicianPracticeLocation(physicianPracticeLocations.concat([newphysicianPracticeLocation]));
    };


    const setSelectedPhysicianPracticeLocation = (
        address_1,
        address_2,
        address_purpose,
        address_type,
        city,
        country_code,
        country_name,
        postal_code,
        state,
        telephone_number) => {

        const newphysicianPracticeLocation = {
            id: new Date().getTime().toString(),
            address_1,
            address_2,
            address_purpose,
            address_type,
            city,
            country_code,
            country_name,
            postal_code,
            state,
            telephone_number
        };
        setSelectedPhysicianPracticeLocationLocal(newphysicianPracticeLocation);
    };

    const addNewPatientDOB = (
        _dob
    ) => {

        setPatientDOBLocal(_dob);
        console.log("DOB after: ", patientDOB);
    };

    // Make the context object:
    const physicianAddressesContext = {
        selectedPhysicianLegacyInfo,
        setSelectedPhysicianLegacyInfo,

        physicianAddresses,
        setPhysicianAddresses,
        selectedPhysicianAddress,
        setSelectedPhysicianAddress,
        addNewPhysicianAddress,

        physicianPracticeLocations,
        setPhysicianPracticeLocation,
        selectedPhysicianPracticeLocation,
        setSelectedPhysicianPracticeLocation,
        addNewPhysicianPracticeLocation,

        addNewPatientDOB,
    };

    // pass the value in provider and return
    return <Context.Provider value={physicianAddressesContext}>{children}</Context.Provider>;
};

export const { Consumer } = Context;

Provider.propTypes = {
    selectedPhysicianLegacyInfo: PropTypes.object,
    physicianAddresses: PropTypes.array,
    selectedPhysicianAddress: PropTypes.object,

    physicianPracticeLocations: PropTypes.array,
    selectedPhysicianPracticeLocation: PropTypes.object,

    patientDOB: PropTypes.string,
};

Provider.defaultProps = {
    selectedPhysicianLegacyInfo: {},
    physicianPracticeLocations: [],
    selectedPhysicianPracticeLocation: {},

    physicianPracticeLocations: [],

    patientDOB: "",
};
