import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

const Family_histry = (props) => {
  

    return (
      <div class="body card">
      <div class="row clearfix">
        <div class="col-md-12">
          <div class="form-group custom-margin-bottom">
            <h4>Personal History</h4>
            <label class="form-label" for="address">
              Personal History (check all that apply)
            </label>
            <div class="demo-checkbox" id="hboc1_q01">
              <input
                type="checkbox"
                id="pfHistory_noCancer"
                name="pfHistory_noCancer"
                class="filled-in"
                ref={(ref) => {
                  props.me.pfHistory_noCancer = ref;
                }}
                onClick={() => {
                  props.me.noPreviousPersonalCancerHistoryClick();
                }}
              />
              <label for="pfHistory_noCancer">
                No personal history of cancer &nbsp;{" "}
              </label>
            </div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group " id="personalHistoryArea">
            <div class="row clearfix">
              <div class="col-md-12">
                <div class="form-group custom-margin-bottom custom-margin-top">
                  <label class="form-label" for="address">
                    Does the Patient have a history of:
                  </label>
                  <div class="demo-radio-button" id="hboc1_q06">
                    <div class="row clearfix custom-min-height2">
                      <div class="col-md-4">
                        <label>
                          Breast, ovarian or pancreative cancer at any
                          age?
                        </label>
                        <input
                          type="radio"
                          id="pfHistory_BrOvPanAnyAge"
                          name="pfHistory_BrOvPanAnyAge_"
                          value="yes"
                          class="form-control"
                          ref={(ref) => {
                            props.me.pfHistory_BrOvPanAnyAge = ref;
                          }}
                          onClick={(e) => {
                            props.me.pfHistory_BrOvPanAnyAgeClick();
                          }}
                        />
                        <label
                          class="form-label"
                          for="pfHistory_BrOvPanAnyAge"
                        >
                          Yes
                        </label>
                        <input
                          type="radio"
                          id="pfHistory_BrOvPanAnyAge2"
                          name="pfHistory_BrOvPanAnyAge_"
                          value="no"
                          class="form-control"
                          ref={(ref) => {
                            props.me.pfHistory_BrOvPanAnyAge2 = ref;
                          }}
                          onClick={() => {
                            props.me.pfHistory_BrOvPanAnyAge2Click();
                          }}
                        />
                        <label
                          class="form-label"
                          for="pfHistory_BrOvPanAnyAge2"
                        >
                          No
                        </label>
                      </div>
                      {props.me.state.pfHistory_BrOvPanAnyAge && (
                        <>
                          {" "}
                          <div class="col-md-8">
                            <div
                              /* className=" hideFormContent" */ id="pfHistory_BrOvPanAnyAge_Div"
                            >
                              <div class="row clearfix">
                                <div class="col-md-9">
                                  <label>Which cancer?</label>
                                  <div
                                    class="demo-checkbox"
                                    id="hboc1_q06_1"
                                  >
                                    <input
                                      type="checkbox"
                                      id="pfHistory_BrOvPanAnyAgeCan1"
                                      name="pfHistory_BrOvPanAnyAgeCan1"
                                      data-val="Breast"
                                      class="filled-in"
                                      ref={(ref)=>{props.me.pfHistory_BrOvPanAnyAgeCan1=ref}}
                                      checked={props.state.pfHistory_BrOvPanAnyAgeCan1=="Breast"}
                                      onClick={()=>{
                                        if(props.me.pfHistory_BrOvPanAnyAgeCan1.checked){
                                          props.me.setState({pfHistory_BrOvPanAnyAgeCan1:"Breast"});
                                        }else{
                                          props.me.setState({pfHistory_BrOvPanAnyAgeCan1:""});
                                        }
                                      }}
                                    />
                                    <label for="pfHistory_BrOvPanAnyAgeCan1">
                                      Breast
                                    </label>
                                    <input
                                      type="checkbox"
                                      id="pfHistory_BrOvPanAnyAgeCan2"
                                      name="pfHistory_BrOvPanAnyAgeCan2"
                                      data-val="Ovarian"
                                      class="filled-in"
                                      ref={(ref)=>{props.me.pfHistory_BrOvPanAnyAgeCan2=ref}}
                                      checked={props.state.pfHistory_BrOvPanAnyAgeCan2=="Ovarian"}
                                      onClick={()=>{
                                        if(props.me.pfHistory_BrOvPanAnyAgeCan2.checked){
                                          props.me.setState({pfHistory_BrOvPanAnyAgeCan2:"Ovarian"});
                                        }else{
                                          props.me.setState({pfHistory_BrOvPanAnyAgeCan2:""});
                                        }
                                      }}
                                    />
                                    <label for="pfHistory_BrOvPanAnyAgeCan2">
                                      Ovarian
                                    </label>
                                    <input
                                      type="checkbox"
                                      id="pfHistory_BrOvPanAnyAgeCan3"
                                      name="pfHistory_BrOvPanAnyAgeCan3"
                                      data-val="Pancreative"
                                      class="filled-in"
                                      ref={(ref)=>{props.me.pfHistory_BrOvPanAnyAgeCan3=ref}}
                                      checked={props.state.pfHistory_BrOvPanAnyAgeCan3=="Pancreative"}
                                      onClick={()=>{
                                        if(props.me.pfHistory_BrOvPanAnyAgeCan3.checked){
                                          props.me.setState({pfHistory_BrOvPanAnyAgeCan3:"Pancreative"});
                                        }else{
                                          props.me.setState({pfHistory_BrOvPanAnyAgeCan3:""});
                                        }
                                      }}
                                    />
                                    <label for="pfHistory_BrOvPanAnyAgeCan3">
                                      Pancreative
                                    </label>
                                  </div>
                                  {/* <div class="form-line">
                                <input type="text" id="pfHistory_BrOvPanAnyAgeCan" ref={(ref) => (me.pfHistory_BrOvPanAnyAgeCan = ref)} class="form-control " placeholder="Which cancer?" />
                              </div> */}
                                </div>
                                <div class="col-md-3">
                                  <label>Age at diagnosis</label>
                                  <div class="form-line">
                                    <input
                                      type="text"
                                      id="pfHistory_BrOvPanAnyAgeInfo"
                                      ref={(ref) =>
                                        (props.me.pfHistory_BrOvPanAnyAgeInfo =
                                          ref)
                                      }
                                      value={props.state.pfHistory_BrOvPanAnyAgeInfo}
                                      onChange={(e)=>{
                                        props.me.setState({pfHistory_BrOvPanAnyAgeInfo:e.target.value});
                                      }}
                                      class="form-control "
                                      placeholder="Age"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div class="demo-radio-button" id="hboc1_q07">
                    <div class="row clearfix custom-min-height2">
                      <div class="col-md-4">
                        <label>
                          Colorectal or uterine cancer at 64 or younger?
                        </label>
                        <input
                          type="radio"
                          id="pfHistory_ColorUterineAnyAge"
                          name="pfHistory_ColorUterineAnyAge_"
                          value="yes"
                          class="form-control"
                          ref={(ref) => {
                            props.me.pfHistory_ColorUterineAnyAge = ref;
                          }}
                          onClick={() => {
                            props.me.pfHistory_ColorUterineAnyAgeClick();
                          }}
                        />
                        <label
                          class="form-label"
                          for="pfHistory_ColorUterineAnyAge"
                        >
                          Yes
                        </label>
                        <input
                          type="radio"
                          id="pfHistory_ColorUterineAnyAge2"
                          name="pfHistory_ColorUterineAnyAge_"
                          value="no"
                          class="form-control"
                          ref={(ref) => {
                            props.me.pfHistory_ColorUterineAnyAge2 = ref;
                          }}
                          onClick={() => {
                            props.me.pfHistory_ColorUterineAnyAge2Click();
                          }}
                        />
                        <label
                          class="form-label"
                          for="pfHistory_ColorUterineAnyAge2"
                        >
                          No
                        </label>
                      </div>
                      {props.me.state.pfHistory_ColorUterineAnyAge && (
                        <>
                          {" "}
                          <div class="col-md-8">
                            <div
                              /* className=" hideFormContent" */ id="pfHistory_ColorUterineAnyAge_Div"
                            >
                              <div class="row clearfix">
                                <div class="col-md-9">
                                  <label>Which cancer?</label>
                                  <div
                                    class="demo-checkbox"
                                    id="hboc1_q07_1"
                                  >
                                    <input
                                      type="checkbox"
                                      id="pfHistory_ColorUterineAnyAgeCan1"
                                      name="pfHistory_ColorUterineAnyAgeCan1"
                                      data-val="Colorectal"
                                      class="filled-in"
                                      ref={(ref)=>{props.me.pfHistory_ColorUterineAnyAgeCan1=ref}}
                                      checked={props.state.pfHistory_ColorUterineAnyAgeCan1=="Colorectal"}
                                      onClick={()=>{
                                        if(props.me.pfHistory_ColorUterineAnyAgeCan1.checked){
                                          props.me.setState({pfHistory_ColorUterineAnyAgeCan1:"Colorectal"});
                                        }else{
                                          props.me.setState({pfHistory_ColorUterineAnyAgeCan1:""});
                                        }
                                      }}
                                      
                                    />
                                    <label for="pfHistory_ColorUterineAnyAgeCan1">
                                      Colorectal &nbsp;{" "}
                                    </label>
                                    <input
                                      type="checkbox"
                                      id="pfHistory_ColorUterineAnyAgeCan2"
                                      name="pfHistory_ColorUterineAnyAgeCan2"
                                      data-val="Uterine"
                                      class="filled-in"
                                      ref={(ref)=>{props.me.pfHistory_ColorUterineAnyAgeCan2=ref}}
                                      checked={props.state.pfHistory_ColorUterineAnyAgeCan2=="Uterine"}
                                      onClick={()=>{
                                        if(props.me.pfHistory_ColorUterineAnyAgeCan2.checked){
                                          props.me.setState({pfHistory_ColorUterineAnyAgeCan2:"Uterine"});
                                        }else{
                                          props.me.setState({pfHistory_ColorUterineAnyAgeCan2:""});
                                        }
                                      }}
                                    />
                                    <label for="pfHistory_ColorUterineAnyAgeCan2">
                                      Uterine &nbsp;{" "}
                                    </label>
                                  </div>
                                  {/* <div class="form-line">
                                <input type="text" id="pfHistory_ColorUterineAnyAgeCan" ref={(ref) => (me.pfHistory_ColorUterineAnyAgeCan = ref)} class="form-control " placeholder="Which cancer?" />
                              </div> */}
                                </div>
                                <div class="col-md-3">
                                  <label>Age at diagnosis</label>
                                  <div class="form-line">
                                    <input
                                      type="text"
                                      id="pfHistory_ColorUterineAnyAgeInfo"
                                      ref={(ref) =>
                                        (props.me.pfHistory_ColorUterineAnyAgeInfo =
                                          ref)
                                      }
                                      class="form-control "
                                      placeholder="Age"
                                    value={props.state.pfHistory_ColorUterineAnyAgeInfo}
                                      onChange={(e)=>{
                                        props.me.setState({pfHistory_ColorUterineAnyAgeInfo:e.target.value});
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div class="demo-checkbox" id="hboc1_q03">
                    <div class="row clearfix custom-min-height">
                      <div class="col-md-4">
                        <input
                          type="checkbox"
                          id="pfHistory_previousCancer"
                          name="pfHistory_previousCancer"
                          class="filled-in"
                          ref={(ref) =>
                            (props.me.pfHistory_previousCancer = ref)
                          }
                          onClick={() => {
                            props.me.previousCancerHistoryClick();
                          }}
                        />
                        <label for="pfHistory_previousCancer">
                          Previous cancer diagnosis? If yes, Define:
                        </label>
                      </div>
                      {props.me.state.pfHistory_previousCancer && (
                        <>
                          {" "}
                          <div class="col-md-8">
                            <div
                              class="form-line "
                              id="pfHistory_prevCanDefine_div"
                            >
                              <input
                                type="text"
                                id="pfHistory_prevCanDefine"
                                ref={(ref) =>
                                  (props.me.pfHistory_prevCanDefine = ref)
                                }
                                class="form-control "
                                value={props.state.pfHistory_prevCanDefine}
                                onChange={(e) => {
                                  props.me.setState({
                                    pfHistory_prevCanDefine:
                                      e.target.value,
                                  });
                                  console.log(
                                    props.me.state.pfHistory_prevCanDefine
                                  );
                                }}
                                placeholder="Define"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div class="demo-checkbox" id="hboc1_q04">
                    <div class="row clearfix custom-min-height">
                      <div class="col-md-5">
                        <input
                          type="checkbox"
                          id="pfHistory_SomaticMutationProf"
                          name="pfHistory_SomaticMutationProf"
                          class="filled-in"
                          ref={(ref) =>
                            (props.me.pfHistory_SomaticMutationProf = ref)
                          }

                          onClick={() => {
                            props.me.SomaticMutationProfClick();
                          }}
                        />
                        <label for="pfHistory_SomaticMutationProf">
                          Somatic mutation profiling? If yes, Define:
                          (gene, variant identified) &nbsp;{" "}
                        </label>
                      </div>
                      {props.me.state.pfHistory_SomaticMutationProf && (
                        <>
                          <div class="col-md-7">
                            <div
                              /* class="form-line hideFormContent" */ id="pfHistory_SoMutaProfDefine_div"
                            >
                              <input
                                type="text"
                                id="pfHistory_SoMutaProfDefine"
                                ref={(ref) =>
                                  (props.me.pfHistory_SoMutaProfDefine =
                                    ref)
                                }
                                class="form-control "
                                value={props.state.pfHistory_SoMutaProfDefine}
                                onChange={(e) => {
                                  props.me.setState({
                                    pfHistory_SoMutaProfDefine:
                                      e.target.value,
                                  });
                                  console.log(
                                    props.me.state
                                      .pfHistory_SoMutaProfDefine
                                  );
                                }}
                                placeholder="Define"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div class="demo-checkbox" id="hboc1_q05">
                    <div class="row clearfix custom-min-height">
                      <div class="col-md-5">
                        <input
                          type="checkbox"
                          id="pfHistory_PrevGermltesting"
                          name="pfHistory_PrevGermltesting"
                          class="filled-in"
                          ref={(ref) =>
                            (props.me.pfHistory_PrevGermltesting = ref)
                          }
                          onClick={() => {
                            props.me.PrevGermltestingClick();
                          }}
                        />
                        <label for="pfHistory_PrevGermltesting">
                          Previous germline testing? If yes,
                          Define:(gene/varient detected) &nbsp;{" "}
                        </label>
                      </div>
                      {props.me.state.pfHistory_PrevGermltesting && (
                        <>
                          {" "}
                          <div class="col-md-7">
                            <div
                              /* class="form-line hideFormContent" */ id="pfHistory_PrevGermltestDefine_div"
                            >
                              <input
                                type="text"
                                id="pfHistory_PrevGermltestDefine"
                                ref={(ref) =>
                                  (props.me.pfHistory_PrevGermltestDefine =
                                    ref)
                                }
                                class="form-control "
                                value={props.state.pfHistory_PrevGermltestDefine}
                                onChange={(e) => {
                                  props.me.setState({
                                    pfHistory_PrevGermltestDefine:
                                      e.target.value,
                                  });
                                  console.log(
                                    props.me.state
                                      .pfHistory_PrevGermltestDefine
                                  );
                                }}
                                placeholder="Define"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div
                    class="demo-checkbox"
                    id="hboc1_q05_01_hboc1_q05_02"
                  >
                    <label for="">If yes, is the variant:&nbsp; </label>
                    <input
                      type="checkbox"
                      data-val="Familial/Inherited or"
                      id="pfHistory_Familial_Inherited"
                      name="pfHistory_Familial_Inherited"
                      class="filled-in"
                    />
                    <label for="pfHistory_Familial_Inherited">
                      Familial/Inherited or &nbsp;{" "}
                    </label>
                    <input
                      type="checkbox"
                      data-val="Newly-identified/De Novo"
                      id="pfHistory_Newly_identified"
                      name="pfHistory_Newly_identified"
                      class="filled-in"
                    />
                    <label for="pfHistory_Newly_identified">
                      Newly-identified/De Novo &nbsp;{" "}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          {/* personalHistoryArea */}
          <div class="row clearfix">
            <div class="col-md-12">
              <div class="form-group custom-margin-bottom">
                <h4>Family History</h4>
                <div
                  class="demo-checkbox hideFormContent"
                  id="hboc1_q01"
                >
                  {/* hboc1_q02 */}
                  <input
                    type="checkbox"
                    id="pfHistory_yesCancer"
                    name="pfHistory_yesCancer"
                    class="filled-in"
                    onClick={() => {
                      props.me.noPreviousFamilyCancerHistoryClick();
                    }}
                  />
                  <label for="pfHistory_yesCancer">
                    Family history of breast, ovarian or endometrial
                    cancer &nbsp;{" "}
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div id="familyHistoryArea">
            <div class="row clearfix hideFormContent">
              <div class="col-md-12">
                <p>
                  (Close relatives include: parent, sibling, child,
                  uncle, aunt, great uncle, great aunt, newphew, niece,
                  grandparent, grandchild or half-sibling)
                </p>
              </div>
            </div>
            <div class="row clearfix">
              <div class="col-md-12">
                <div class="form-group ">
                  <label
                    class="form-label hideFormContent"
                    for="address"
                  >
                    Does the Patient have a family history of:
                  </label>
                  <div class="demo-radio-button " id="hboc1_q08">
                    <div class="row clearfix custom-min-height">
                      <div class="col-md-6">
                        <label>
                          One breast cancer in one relative at any age
                        </label>
                        <br />
                        <input
                          type="radio"
                          id="pfHistory_FBrOvPanAnyAge"
                          name="pfHistory_FBrOvPanAnyAge_"
                          value="yes"
                          class="form-control"
                          ref={(ref) =>
                            (props.me.pfHistory_FBrOvPanAnyAge =
                              ref)
                          }
                          onClick={() => {
                            props.me.pfHistory_FBrOvPanAnyAgeClick();
                          }}
                        />
                        <label
                          class="form-label"
                          for="pfHistory_FBrOvPanAnyAge"
                        >
                          Yes
                        </label>
                        <input
                          type="radio"
                          id="pfHistory_FBrOvPanAnyAge2"
                          name="pfHistory_FBrOvPanAnyAge_"
                          value="no"
                          class="form-control"
                          ref={(ref) =>
                            (props.me.pfHistory_FBrOvPanAnyAge2 =
                              ref)
                          }
                          onClick={() => {
                            props.me.pfHistory_FBrOvPanAnyAge2Click();
                          }}
                        />
                        <label
                          class="form-label"
                          for="pfHistory_FBrOvPanAnyAge2"
                        >
                          No
                        </label>
                      </div>
                    {props.me.state.pfHistory_FBrOvPanAnyAge && (<> <div class="col-md-6">
                        <div
                          //className=" hideFormContent"
                          id="pfHistory_FBrOvPanAnyAge_Div"
                        >
                          <div class="row clearfix">
                            <div class="col-md-3">
                              <button
                                type="button"
                                class="btn btn-default waves-effect m-r-20"
                                data-toggle="modal"
                                data-val="subQuestion-hboc1_q08_1"
                                data-target="#defaultModal"
                                onClick={() => {
                                  props.me.btnpfHistoryAddFamilyHistoryClick(
                                    "hboc1_q08_1"
                                  );

                                  window.$('#defaultModal').modal('show');
                                }}
                              >
                                add Info
                              </button>
                            </div>
                            <div class="col-md-9">
                              <div id="hboc1_q08_1_showResult">
                                {props.state.hboc1_q08_1_showResult}
                              </div>
                              <input
                                type="hidden"
                                id="hboc1_q08_1_pfHistory_AnyAgeValuesJustShow"
                                class="form-control "
                                value={props.state.hboc1_q08_1_pfHistory_AnyAgeValuesJustShow}
                              />
                              <input
                                type="hidden"
                                id="hboc1_q08_1_pfHistory_AnyAgeValues"
                                class="form-control "
                                value={props.state.hboc1_q08_1_pfHistory_AnyAgeValues}
                              />
                            </div>
                          </div>
                        </div>
                      </div></>)}
                    </div>
                  </div>
                  <div class="demo-radio-button" id="hboc1_q09">
                    <div class="row clearfix custom-min-height">
                      <div class="col-md-6">
                        <label>
                          Two breast cancers (bilateral) in one relative
                          at any age
                        </label>
                        <br />
                        <input
                          type="radio"
                          id="pfHistory_FTwoBreasCanAge"
                          name="pfHistory_FTwoBreasCanAge_"
                          value="yes"
                          class="form-control"
                          ref={(ref) =>
                            (props.me.pfHistory_FTwoBreasCanAge =
                              ref)
                          }
                          onClick={() => {
                            props.me.pfHistory_FTwoBreasCanAgeClick();
                          }}
                        />
                        <label
                          class="form-label"
                          for="pfHistory_FTwoBreasCanAge"
                        >
                          Yes
                        </label>
                        <input
                          type="radio"
                          id="pfHistory_FTwoBreasCanAge2"
                          name="pfHistory_FTwoBreasCanAge_"
                          value="no"
                          class="form-control"
                          ref={(ref) =>
                            (props.me.pfHistory_FTwoBreasCanAge2 =
                              ref)
                          }
                          onClick={() => {
                            props.me.pfHistory_FTwoBreasCanAge2Click();
                          }}
                        />
                        <label
                          class="form-label"
                          for="pfHistory_FTwoBreasCanAge2"
                        >
                          No
                        </label>
                      </div>
                    {props.me.state.pfHistory_FTwoBreasCanAge&& (<><div class="col-md-6">
                        <div
                         // className=" hideFormContent"
                          id="pfHistory_FTwoBreasCanAge_Div"
                        >
                          <div class="row clearfix">
                            <div class="col-md-3">
                              <button
                                type="button"
                                class="btn btn-default waves-effect m-r-20"
                                data-toggle="modal"
                                data-target="#defaultModal"
                                onClick={() => {
                                  props.me.btnpfHistoryAddFamilyHistoryClick(
                                    "hboc1_q09_1"
                                  );
                                  window.$('#defaultModal').modal('show');
                                }}
                              >
                                add Info
                              </button>
                            </div>
                            <div class="col-md-9">
                                <div id="hboc1_q09_1_showResult">{ props.state.hboc1_q09_1_showResult }</div>
                              <input
                                type="hidden"
                                id="hboc1_q09_1_pfHistory_AnyAgeValuesJustShow"
                                class="form-control "
                                value={props.state.hboc1_q09_1_pfHistory_AnyAgeValuesJustShow}
                              />
                              <input
                                type="hidden"
                                id="hboc1_q09_1_pfHistory_AnyAgeValues"
                                class="form-control "
                                value={props.state.hboc1_q09_1_pfHistory_AnyAgeValues}
                              />
                            </div>
                          </div>
                        </div>
                      </div></>)}
                    </div>
                  </div>
                  <div class="demo-radio-button" id="hboc1_q010">
                    <div class="row clearfix custom-min-height">
                      <div class="col-md-6">
                        <label>
                          Three breast cancers in relatives on the same
                          side of the family at any age
                        </label>
                        <br></br>

                        <input
                          type="radio"
                          id="pfHistory_FThrBreasCanAge"
                          name="pfHistory_FThrBreasCanAge_"
                          value="yes"
                          class="form-control"
                          ref={(ref) =>
                            (props.me.pfHistory_FThrBreasCanAge =
                              ref)
                          }
                          onClick={() => {
                            props.me.pfHistory_FThrBreasCanAgeClick();
                          }}
                        />
                        <label
                          class="form-label"
                          for="pfHistory_FThrBreasCanAge"
                        >
                          Yes
                        </label>
                        <input
                          type="radio"
                          id="pfHistory_FThrBreasCanAge2"
                          name="pfHistory_FThrBreasCanAge_"
                          value="no"
                          class="form-control"
                          ref={(ref) =>
                            (props.me.pfHistory_FThrBreasCanAge2 =
                              ref)
                          }
                          onClick={() => {
                           props.me.pfHistory_FThrBreasCanAge2Click();
                          }}
                        />
                        <label
                          class="form-label"
                          for="pfHistory_FThrBreasCanAge2"
                        >
                          No
                        </label>
                      </div>
                   {props.me.state.pfHistory_FThrBreasCanAge &&(<>  <div class="col-md-6">
                        <div
                         // className=" hideFormContent"
                          id="pfHistory_FThrBreasCanAge_Div"
                        >
                          <div class="row clearfix">
                            <div class="col-md-3">
                              <button
                                type="button"
                                class="btn btn-default waves-effect m-r-20"
                                data-toggle="modal"
                                data-target="#defaultModal"
                                onClick={() => {
                                 props.me.btnpfHistoryAddFamilyHistoryClick(
                                    "hboc1_q010_1"
                                  );
                                  window.$('#defaultModal').modal('show');
                                }}
                              >
                                add Info
                              </button>
                            </div>
                            <div class="col-md-9">
                              <div id="hboc1_q010_1_showResult">{ props.state.hboc1_q010_1_showResult }</div>
                              <input
                                type="hidden"
                                id="hboc1_q010_1_pfHistory_AnyAgeValuesJustShow"
                                class="form-control "
                                value={props.state.hboc1_q010_1_pfHistory_AnyAgeValuesJustShow}
                              />
                              <input
                                type="hidden"
                                id="hboc1_q010_1_pfHistory_AnyAgeValues"
                                class="form-control "
                                value={props.state.hboc1_q010_1_pfHistory_AnyAgeValues}
                              />
                            </div>
                          </div>
                        </div>
                      </div></>)}
                    </div>
                  </div>
                  <div class="demo-radio-button" id="hboc1_q011">
                    <div class="row clearfix custom-min-height">
                      <div class="col-md-6">
                        <label>Ovarian cancer at any age</label>
                        <br />
                        <input
                          type="radio"
                          id="pfHistory_OvarianCanAge"
                          name="pfHistory_OvarianCanAge_"
                          value="yes"
                          class="form-control"
                          ref={(ref) =>
                            (props.me.pfHistory_OvarianCanAge =
                              ref)
                          }
                          onClick={() => {
                            props.me.pfHistory_OvarianCanAgeClick();
                          }}
                        />
                        <label
                          class="form-label"
                          for="pfHistory_OvarianCanAge"
                        >
                          Yes
                        </label>
                        <input
                          type="radio"
                          id="pfHistory_OvarianCanAge2"
                          name="pfHistory_OvarianCanAge_"
                          value="no"
                          class="form-control"
                          ref={(ref) =>
                            (props.me.pfHistory_OvarianCanAge2 =
                              ref)
                          }
                          onClick={() => {
                            props.me.pfHistory_OvarianCanAge2Click();
                          }}
                        />
                        <label
                          class="form-label"
                          for="pfHistory_OvarianCanAge2"
                        >
                          No
                        </label>
                      </div>
                    {props.me.state.pfHistory_OvarianCanAge&& (<> <div class="col-md-6">
                        <div
                         // className=" hideFormContent"
                          id="pfHistory_OvarianCanAge_Div"
                        >
                          <div class="row clearfix">
                            <div class="col-md-3">
                              <button
                                type="button"
                                class="btn btn-default waves-effect m-r-20"
                                data-toggle="modal"
                                data-target="#defaultModal"
                                onClick={() => {
                                 props.me.btnpfHistoryAddFamilyHistoryClick(
                                    "hboc1_q011_1"
                                  );
                                  window.$('#defaultModal').modal('show');
                                }}
                              >
                                add Info
                              </button>
                            </div>
                            <div class="col-md-9">
                              <div id="hboc1_q011_1_showResult">{ props.state.hboc1_q011_1_showResult }</div>
                              <input
                                type="hidden"
                                id="hboc1_q011_1_pfHistory_AnyAgeValuesJustShow"
                                class="form-control "
                                value={props.state.hboc1_q011_1_pfHistory_AnyAgeValuesJustShow}
                              />
                              <input
                                type="hidden"
                                id="hboc1_q011_1_pfHistory_AnyAgeValues"
                                class="form-control "
                                value={props.state.hboc1_q011_1_pfHistory_AnyAgeValues}
                              />
                            </div>
                          </div>
                        </div>
                      </div></>)}
                    </div>
                  </div>

                  <div class="demo-radio-button" id="hboc1_q012">
                    <div class="row clearfix custom-min-height">
                      <div class="col-md-6">
                        <label>Pancreatic cancer at any age</label>
                        <br />
                        <input
                          type="radio"
                          id="pfHistory_PancreaticCanAge"
                          name="pfHistory_PancreaticCanAge_"
                          value="yes"
                          class="form-control"
                          ref={(ref) =>
                            (props.me.pfHistory_PancreaticCanAge =
                              ref)
                          }
                          onClick={() => {
                            props.me.pfHistory_PancreaticCanAgeClick();
                          }}
                        />
                        <label
                          class="form-label"
                          for="pfHistory_PancreaticCanAge"
                        >
                          Yes
                        </label>
                        <input
                          type="radio"
                          id="pfHistory_PancreaticCanAge2"
                          name="pfHistory_PancreaticCanAge_"
                          value="no"
                          class="form-control"
                          ref={(ref) =>
                            (props.me.pfHistory_PancreaticCanAge2 =
                              ref)
                          }
                          onClick={() => {
                            props.me.pfHistory_PancreaticCanAge2Click();
                          }}
                        />
                        <label
                          class="form-label"
                          for="pfHistory_PancreaticCanAge2"
                        >
                          No
                        </label>
                      </div>
                  {props.me.state.pfHistory_PancreaticCanAge&&(<>    <div class="col-md-6">
                        <div
                         // className=" hideFormContent"
                          id="pfHistory_PancreaticCanAge_Div"
                        >
                          <div class="row clearfix">
                            <div class="col-md-3">
                              <button
                                type="button"
                                class="btn btn-default waves-effect m-r-20"
                                data-toggle="modal"
                                data-target="#defaultModal"
                                onClick={() => {
                                 props.me.btnpfHistoryAddFamilyHistoryClick(
                                    "hboc1_q012_1"
                                  );
                                  window.$('#defaultModal').modal('show');
                                }}
                              >
                                add Info
                              </button>
                            </div>
                            <div class="col-md-9">
                              <div id="hboc1_q012_1_showResult">{ props.state.hboc1_q012_1_showResult }</div>
                              <input
                                type="hidden"
                                id="hboc1_q012_1_pfHistory_AnyAgeValuesJustShow"
                                class="form-control "
                                value={props.state.hboc1_q012_1_pfHistory_AnyAgeValuesJustShow}
                              />
                              <input
                                type="hidden"
                                id="hboc1_q012_1_pfHistory_AnyAgeValues"
                                class="form-control "
                                value={props.state.hboc1_q012_1_pfHistory_AnyAgeValues}
                              />
                            </div>
                          </div>
                        </div>
                      </div></>)}
                    </div>
                  </div>

                  <div class="demo-radio-button" id="hboc1_q013">
                    <div class="row clearfix custom-min-height">
                      <div class="col-md-6">
                        <label>Male breast cancer at any age</label>
                        <br />
                        <input
                          type="radio"
                          id="pfHistory_MaleBreastCanAge"
                          name="pfHistory_MaleBreastCanAge_"
                          value="yes"
                          class="form-control"
                          ref={(ref) =>
                            (props.me.pfHistory_MaleBreastCanAge =
                              ref)
                          }
                          onClick={() => {
                            props.me.pfHistory_MaleBreastCanAgeClick();
                          }}
                        />
                        <label
                          class="form-label"
                          for="pfHistory_MaleBreastCanAge"
                        >
                          Yes
                        </label>
                        <input
                          type="radio"
                          id="pfHistory_MaleBreastCanAge2"
                          name="pfHistory_MaleBreastCanAge_"
                          value="no"
                          class="form-control"
                          ref={(ref) =>
                            (props.me.pfHistory_MaleBreastCanAge2 =
                              ref)
                          }
                          onClick={() => {
                            props.me.pfHistory_MaleBreastCanAge2Click();
                          }}
                        />
                        <label
                          class="form-label"
                          for="pfHistory_MaleBreastCanAge2"
                        >
                          No
                        </label>
                      </div>
                    {props.me.state.pfHistory_MaleBreastCanAge && (<> <div class="col-md-6">
                        <div
                         // className=" hideFormContent"
                          id="pfHistory_MaleBreastCanAge_Div"
                        >
                          <div class="row clearfix">
                            <div class="col-md-3">
                              <button
                                type="button"
                                class="btn btn-default waves-effect m-r-20"
                                data-toggle="modal"
                                data-target="#defaultModal"
                                onClick={() => {
                                  props.me.btnpfHistoryAddFamilyHistoryClick(
                                    "hboc1_q013_1"
                                  );
                                  window.$('#defaultModal').modal('show');
                                }}
                              >
                                add Info
                              </button>
                            </div>
                            <div class="col-md-9">
                              <div id="hboc1_q013_1_showResult">{ props.state.hboc1_q013_1_showResult }</div>
                              <input
                                type="hidden"
                                id="hboc1_q013_1_pfHistory_AnyAgeValuesJustShow"
                                class="form-control "
                                value={props.state.hboc1_q013_1_pfHistory_AnyAgeValuesJustShow}
                              />
                              <input
                                type="hidden"
                                id="hboc1_q013_1_pfHistory_AnyAgeValues"
                                class="form-control "
                                value={props.state.hboc1_q013_1_pfHistory_AnyAgeValues}
                              />
                            </div>
                          </div>
                        </div>
                      </div></>)}
                    </div>
                  </div>
                  <div class="demo-radio-button" id="hboc1_q014">
                    <div class="row clearfix custom-min-height">
                      <div class="col-md-6">
                        <label>
                          Metastatic prostate cancer at any age
                        </label>
                        <br />
                        <input
                          type="radio"
                          id="pfHistory_MetaProstateCanAge"
                          name="pfHistory_MetaProstateCanAge_"
                          value="yes"
                          class="form-control"
                          ref={(ref) =>
                            (props.me.pfHistory_MetaProstateCanAge =
                              ref)
                          }
                          onClick={() => {
                            props.me.pfHistory_MetaProstateCanAgeClick();
                           
                            
                          }}
                        />
                        <label
                          class="form-label"
                          for="pfHistory_MetaProstateCanAge"
                        >
                          Yes
                        </label>
                        <input
                          type="radio"
                          id="pfHistory_MetaProstateCanAge2"
                          name="pfHistory_MetaProstateCanAge_"
                          value="no"
                          class="form-control"
                          ref={(ref) =>
                            (props.me.pfHistory_MetaProstateCanAge2 =
                              ref)
                          }
                          onClick={() => {
                            props.me.pfHistory_MetaProstateCanAge2Click();
                            
                          }}
                        />
                        <label
                          class="form-label"
                          for="pfHistory_MetaProstateCanAge2"
                        >
                          No
                        </label>
                      </div>
                    {props.me.state.pfHistory_MetaProstateCanAge &&  ( <><div class="col-md-6">
                        <div
                          id="pfHistory_MetaProstateCanAge_Div"
                        >
                          <div class="row clearfix">
                            <div class="col-md-3">
                              <button
                                type="button"
                                class="btn btn-default waves-effect m-r-20"
                                data-toggle="modal"
                                data-target="#defaultModal"
                                onClick={() => {
                                props.me.btnpfHistoryAddFamilyHistoryClick(
                                    "hboc1_q014_1"
                                  );
                                  window.$('#defaultModal').modal('show');
                                }}
                              >
                                add Info
                              </button>
                            </div>
                            <div class="col-md-9">
                              <div id="hboc1_q014_1_showResult">{ props.state.hboc1_q014_1_showResult }</div>
                              <input
                                type="hidden"
                                id="hboc1_q014_1_pfHistory_AnyAgeValuesJustShow"
                                value={props.state.hboc1_q014_1_pfHistory_AnyAgeValuesJustShow}
                                class="form-control "
                              />
                              <input
                                type="hidden"
                                id="hboc1_q014_1_pfHistory_AnyAgeValues"
                                class="form-control "
                                value={props.state.hboc1_q014_1_pfHistory_AnyAgeValues}
                              />
                            </div>
                          </div>
                        </div>
                      </div></>)}
                    </div>
                  </div>
                  <div class="demo-radio-button" id="hboc1_q015">
                    <div class="row clearfix custom-min-height">
                      <div class="col-md-6">
                        <label>Colon cancer at 49 or younger</label>
                        <br />
                        <input
                          type="radio"
                          id="pfHistory_ColorCanAge"
                          name="pfHistory_ColorCanAge_"
                          value="yes"
                          class="form-control"
                          ref={(ref) =>
                            (props.me.pfHistory_ColorCanAge =
                              ref)
                          }
                          onClick={() => {
                            props.me.pfHistory_ColorCanAgeClick();
                          }}
                        />
                        <label
                          class="form-label"
                          for="pfHistory_ColorCanAge"
                        >
                          Yes
                        </label>
                        <input
                          type="radio"
                          id="pfHistory_ColorCanAge2"
                          name="pfHistory_ColorCanAge_"
                          value="no"
                          class="form-control"
                          ref={(ref) =>
                            (props.me.pfHistory_ColorCanAge2 =
                              ref)
                          }
                          onClick={() => {
                            props.me.pfHistory_ColorCanAge2Click();
                          }}
                        />
                        <label
                          class="form-label"
                          for="pfHistory_ColorCanAge2"
                        >
                          No
                        </label>
                      </div>
                  {props.me.state.pfHistory_ColorCanAge&& (<><div class="col-md-6">
                        <div
                         // className=" hideFormContent"
                          id="pfHistory_ColorCanAge_Div"
                        >
                          <div class="row clearfix">
                            <div class="col-md-3">
                              <button
                                type="button"
                                class="btn btn-default waves-effect m-r-20"
                                data-toggle="modal"
                                data-target="#defaultModal"
                                onClick={() => {
                                  props.me.btnpfHistoryAddFamilyHistoryClick(
                                    "hboc1_q015_1"
                                  );
                                  window.$('#defaultModal').modal('show');
                                }}
                              >
                                add Info
                              </button>
                            </div>
                            <div class="col-md-9">
                              <div id="hboc1_q015_1_showResult">{ props.state.hboc1_q015_1_showResult }</div>
                              <input
                                type="hidden"
                                id="hboc1_q015_1_pfHistory_AnyAgeValuesJustShow"
                                class="form-control "
                                value={props.state.hboc1_q015_1_pfHistory_AnyAgeValuesJustShow}
                              />
                              <input
                                type="hidden"
                                id="hboc1_q015_1_pfHistory_AnyAgeValues"
                                class="form-control "
                                value={props.state.hboc1_q015_1_pfHistory_AnyAgeValues}
                              />
                            </div>
                          </div>
                        </div>
                      </div></>)}
                    </div>
                  </div>

                  <div class="demo-radio-button" id="hboc1_q016">
                    <div class="row clearfix custom-min-height">
                      <div class="col-md-6">
                        <label>Uterine cancer at 49 or younger</label>
                        <br />
                        <input
                          type="radio"
                          id="pfHistory_UterineCanAge"
                          name="pfHistory_UterineCanAge_"
                          value="yes"
                          class="form-control"
                          ref={(ref) =>
                            (props.me.pfHistory_UterineCanAge =
                              ref)
                          }
                          onClick={() => {
                            props.me.pfHistory_UterineCanAgeClick();
                          }}
                        />
                        <label
                          class="form-label"
                          for="pfHistory_UterineCanAge"
                        >
                          Yes
                        </label>
                        <input
                          type="radio"
                          id="pfHistory_UterineCanAge2"
                          name="pfHistory_UterineCanAge_"
                          value="no"
                          class="form-control"
                          ref={(ref) =>
                            (props.me.pfHistory_UterineCanAge2 =
                              ref)
                          }
                          onClick={() => {
                            props.me.pfHistory_UterineCanAge2Click();
                          }}
                        />
                        <label
                          class="form-label"
                          for="pfHistory_UterineCanAge2"
                        >
                          No
                        </label>
                      </div>
                    {props.me.state.pfHistory_UterineCanAge &&  (<><div class="col-md-6">
                        <div
                          //className=" hideFormContent"
                          id="pfHistory_UterineCanAge_Div"
                        >
                          <div class="row clearfix">
                            <div class="col-md-3">
                              <button
                                type="button"
                                class="btn btn-default waves-effect m-r-20"
                                data-toggle="modal"
                                data-target="#defaultModal"
                                onClick={() => {
                                 props.me.btnpfHistoryAddFamilyHistoryClick(
                                    "hboc1_q016_1"
                                  );
                                  window.$('#defaultModal').modal('show');
                                }}
                              >
                                add Info
                              </button>
                            </div>
                            <div class="col-md-9">
                              <div id="hboc1_q016_1_showResult">{ props.state.hboc1_q016_1_showResult }</div>
                              <input
                                type="hidden"
                                id="hboc1_q016_1_pfHistory_AnyAgeValuesJustShow"
                                class="form-control "
                                value={props.state.hboc1_q016_1_pfHistory_AnyAgeValuesJustShow}
                              />
                              <input
                                type="hidden"
                                id="hboc1_q016_1_pfHistory_AnyAgeValues"
                                class="form-control "
                                value={props.state.hboc1_q016_1_pfHistory_AnyAgeValues}
                              />
                            </div>
                          </div>
                        </div>
                      </div></>)}
                    </div>
                  </div>
                  <div class="demo-radio-button" id="hboc1_q017">
                    <div class="row clearfix custom-min-height">
                      <div class="col-md-6">
                        <label>
                          Ashkenazi Jewish ancestry with breast cancer
                          at any age
                        </label>
                        <br />
                        <input
                          type="radio"
                          id="pfHistory_AshJewishAncesCanAge"
                          name="pfHistory_AshJewishAncesCanAge_"
                          value="yes"
                          class="form-control"
                          ref={(ref) =>
                            (props.me.pfHistory_AshJewishAncesCanAge =
                              ref)
                          }
                          onClick={() => {
                            props.me.pfHistory_AshJewishAncesCanAgeClick();
                          }}
                        />
                        <label
                          class="form-label"
                          for="pfHistory_AshJewishAncesCanAge"
                        >
                          Yes
                        </label>
                        <input
                          type="radio"
                          id="pfHistory_AshJewishAncesCanAge2"
                          name="pfHistory_AshJewishAncesCanAge_"
                          value="no"
                          class="form-control"
                          ref={(ref) =>
                            (props.me.pfHistory_AshJewishAncesCanAge2 =
                              ref)
                          }
                          onClick={() => {
                            props.me.pfHistory_AshJewishAncesCanAge2Click();
                          }}
                        />
                        <label
                          class="form-label"
                          for="pfHistory_AshJewishAncesCanAge2"
                        >
                          No
                        </label>
                      </div>
                    {props.me.state.pfHistory_AshJewishAncesCanAge && (<>  <div class="col-md-6">
                        <div
                          //className=" hideFormContent"
                          id="pfHistory_AshJewishAncesCanAge_Div"
                        >
                          <div class="row clearfix">
                            <div class="col-md-3">
                              <button
                                type="button"
                                class="btn btn-default waves-effect m-r-20"
                                data-toggle="modal"
                                data-target="#defaultModal"
                                onClick={() => {
                                 props.me.btnpfHistoryAddFamilyHistoryClick(
                                    "hboc1_q017_1"
                                  );
                                  window.$('#defaultModal').modal('show');
                                }}
                              >
                                add Info
                              </button>
                            </div>
                            <div class="col-md-9">
                              <div id="hboc1_q017_1_showResult">{ props.state.hboc1_q017_1_showResult }</div>
                              <input
                                type="hidden"
                                id="hboc1_q017_1_pfHistory_AnyAgeValuesJustShow"
                                class="form-control "
                                value={props.state.hboc1_q017_1_pfHistory_AnyAgeValuesJustShow}
                              />
                              <input
                                type="hidden"
                                id="hboc1_q017_1_pfHistory_AnyAgeValues"
                                class="form-control "
                                value={props.state.hboc1_q017_1_pfHistory_AnyAgeValues}
                              />
                            </div>
                          </div>
                        </div>
                      </div></>)}
                    </div>
                  </div>
                  <div class="demo-radio-button" id="hboc1_q018">
                    <div class="row clearfix custom-min-height">
                      <div class="col-md-6">
                        <label>
                          Does the Patient have a family history of
                          other cancers?
                        </label>
                        <br />
                        <input
                          type="radio"
                          id="pfHistory_otherCan"
                          name="pfHistory_otherCan_"
                          value="yes"
                          class="form-control"
                          ref={(ref) =>
                            (props.me.pfHistory_otherCan =
                              ref)
                          }
                          onClick={() => {
                            props.me.pfHistory_otherCanClick();
                          }}
                        />
                        <label
                          class="form-label"
                          for="pfHistory_otherCan"
                        >
                          Yes
                        </label>
                        <input
                          type="radio"
                          id="pfHistory_otherCan2"
                          name="pfHistory_otherCan_"
                          value="no"
                          class="form-control"
                          ref={(ref) =>
                            (props.me.pfHistory_otherCan2 =
                              ref)
                          }
                          onClick={() => {
                            props.me.pfHistory_otherCan2Click();
                          }}
                        />
                        <label
                          class="form-label"
                          for="pfHistory_otherCan2"
                        >
                          No If yes, please list:
                        </label>
                      </div>
                    {props.me.state.pfHistory_otherCan &&(<>  <div class="col-md-5">
                        <div
                        //  className=" hideFormContent"
                          id="pfHistory_otherCan_Div"
                        >
                          <div class="row clearfix">
                            <div class="col-md-12">
                              <div class="form-line">
                                <input
                                  type="text"
                                  ref={(ref) =>
                                    (props.me.pfHistory_otherCan_info = ref)
                                  }
                                  id="pfHistory_otherCan_info"
                                  class="form-control "
                                  placeholder="please list"
                                  value={props.me.state.pfHistory_otherCan_info}
                                  onChange={({target: {value}}) => props.me.setState({pfHistory_otherCan_info: value})}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div></>)}
                    </div>
                  </div>
                  <div class="demo-radio-button" id="hboc1_q019">
                    <div class="row clearfix custom-min-height">
                      <div class="col-md-8">
                        <label>
                          Has anyone in the Patient's family had genetic
                          testing for hereditary cancer?
                        </label>
                        <br />
                        <input
                          type="radio"
                          id="pfHistory_geHerediraryCan"
                          name="pfHistory_geHerediraryCan_"
                          value="yes"
                          class="form-control"
                          ref={(ref) =>
                            (props.me.pfHistory_geHerediraryCan =
                              ref)
                          }
                          onClick={() => {
                            props.me.pfHistory_geHerediraryCanClick();
                          }}
                        />
                        <label
                          class="form-label"
                          for="pfHistory_geHerediraryCan"
                        >
                          Yes
                        </label>
                        <input
                          type="radio"
                          id="pfHistory_geHerediraryCan2"
                          name="pfHistory_geHerediraryCan_"
                          value="no"
                          class="form-control"
                          ref={(ref) =>
                            (props.me.pfHistory_geHerediraryCan2 =
                              ref)
                          }
                          onClick={() => {
                            props.me.pfHistory_geHerediraryCan2Click();
                          }}
                        />
                        <label
                          class="form-label"
                          for="pfHistory_geHerediraryCan2"
                        >
                          No If yes, please list: Who, what genes and
                          results (if known):
                        </label>
                      </div>
                    {props.me.state.pfHistory_geHerediraryCan &&(<><div class="col-md-4">
                        <div
                          //className=" hideFormContent"
                          id="pfHistory_geHerediraryCan_Div"
                        >
                          <div class="row clearfix">
                            <div class="col-md-12">
                              <div class="form-line">
                                <input
                                  type="text"
                                  ref={(ref) =>
                                    (props.me.pfHistory_geHerediraryCan_info =
                                      ref)
                                  }
                                  id="pfHistory_geHerediraryCan_info"
                                  class="form-control "
                                  placeholder="please list"
                                  value={props.me.state.pfHistory_geHerediraryCan_info}
                                  onChange={({target: {value}}) => props.me.setState({pfHistory_geHerediraryCan_info: value})}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div></>)}
                    </div>
                  </div>
                  <div class="demo-radio-button" id="hboc1_q020">
                    <div class="row clearfix">
                      <div class="col-md-12">
                        <label>Other Clinical History:</label>
                        <div class="form-line">
                          <input
                            type="text"
                            id="pfHistory_OtherClinicalHistory"
                            ref={(ref) => props.me.pfHistory_OtherClinicalHistory = ref}
                            value={props.me.state.pfHistory_OtherClinicalHistory}
                            onChange={(e) => props.me.setState({pfHistory_OtherClinicalHistory: e.target.value})}
                            class="form-control "
                            placeholder="Other Clinical History"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row clearfix">
                    <div class="col-md-12">
                      <div class="form-group ">
                        <label class="form-label" for="address">
                          Previous or current treatment
                        </label>
                        <div
                          class="demo-checkbox"
                          id="hboc1_q021-hboc1_q024"
                        >
                          <input
                            type="checkbox"
                            id="pfHistory_PCurTreat_boneMar"
                            name="pfHistory_PCurTreat_boneMar"
                            class="filled-in"
                            ref={(ref) => props.me.pfHistory_PCurTreat_boneMar = ref }
                            onClick={()=>{
                              if(props.me.pfHistory_PCurTreat_boneMar.checked){
                                props.me.setState({
                                  pfHistory_PCurTreat_boneMar:true,
                                });
                                props.me.state.pfHistory_PCurTreat_boneMar=true;
                                console.log('true----------------------');
                                console.log(props.me.state.pfHistory_PCurTreat_boneMar);
                              }else{
                                props.me.setState({
                                  pfHistory_PCurTreat_boneMar:false
                                });
                                props.me.state.pfHistory_PCurTreat_boneMar=false;
                                console.log('false----------------------');
                                console.log(props.me.state.pfHistory_PCurTreat_boneMar);
                              }
                            }}
                          />
                          <label for="pfHistory_PCurTreat_boneMar">
                            {" "}
                            Allogeneic bone marrow or peripheral stem
                            cell transplant <sup>*</sup> &nbsp;{" "}
                          </label>
                          <input
                            type="checkbox"
                            id="pfHistory_PCurTreat_Chemo"
                            name="pfHistory_PCurTreat_Chemo"
                            class="filled-in"
                            ref={(ref) =>
                              (props.me.pfHistory_PCurTreat_Chemo =
                                ref)
                            }
                            onClick={()=>{
                              if(props.me.pfHistory_PCurTreat_Chemo.checked){
                                props.me.setState({
                                  pfHistory_PCurTreat_Chemo:true,
                                });
                                props.me.state.pfHistory_PCurTreat_Chemo=true;
                                console.log('true----------------------');
                                console.log(props.me.state.pfHistory_PCurTreat_Chemo);
                              }else{
                                props.me.setState({
                                  pfHistory_PCurTreat_Chemo:false
                                });
                                props.me.state.pfHistory_PCurTreat_Chemo=false;
                                console.log('false----------------------');
                                console.log(props.me.state.pfHistory_PCurTreat_Chemo);
                              }
                            }}

                          />
                          <label for="pfHistory_PCurTreat_Chemo">
                            {" "}
                            Chemotherapy<sup>*</sup> &nbsp;{" "}
                          </label>
                          <input
                            type="checkbox"
                            id="pfHistory_PCurTreat_Radia"
                            name="pfHistory_PCurTreat_Radia"
                            class="filled-in"
                            ref={(ref) =>
                              (props.me.pfHistory_PCurTreat_Radia =
                                ref)
                            }
                            onClick={()=>{
                              if(props.me.pfHistory_PCurTreat_Radia.checked){
                                props.me.setState({
                                  pfHistory_PCurTreat_Radia:true,
                                });
                                props.me.state.pfHistory_PCurTreat_Radia=true;
                                console.log('true----------------------');
                                console.log(props.me.state.pfHistory_PCurTreat_Radia);
                              }else{
                                props.me.setState({
                                  pfHistory_PCurTreat_Radia:false
                                });
                                props.me.state.pfHistory_PCurTreat_Radia=false;
                                console.log('false----------------------');
                                console.log(props.me.state.pfHistory_PCurTreat_Radia);
                              }
                            }}
                          />
                          <label for="pfHistory_PCurTreat_Radia">
                            {" "}
                            Radiation &nbsp;{" "}
                          </label>
                          <input
                            type="checkbox"
                            id="pfHistory_PCurTreat_Surgery"
                            name="pfHistory_PCurTreat_Surgery"
                            class="filled-in"
                            ref={(ref) =>
                              (props.me.pfHistory_PCurTreat_Surgery =
                                ref)
                            }
                            onClick={()=>{
                              if(props.me.pfHistory_PCurTreat_Surgery.checked){
                                props.me.setState({
                                  pfHistory_PCurTreat_Surgery:true,
                                });
                                props.me.state.pfHistory_PCurTreat_Surgery=true;
                                console.log('true----------------------');
                                console.log(props.me.state.pfHistory_PCurTreat_Surgery);
                              }else{
                                props.me.setState({
                                  pfHistory_PCurTreat_Surgery:false
                                });
                                props.me.state.pfHistory_PCurTreat_Surgery=false;
                                console.log('false----------------------');
                                console.log(props.me.state.pfHistory_PCurTreat_Surgery);
                              }
                            }}
                          />
                          <label for="pfHistory_PCurTreat_Surgery">
                            {" "}
                            Surgery, &nbsp;{" "}
                          </label>
                        </div>
                        <div class="form-line" id="hboc1_q025">
                          <input
                            type="text"
                            id="pfHistory_PCurTreat_SurgDe"
                            ref={(ref) => props.me.pfHistory_PCurTreat_SurgDe = ref}
                            value={props.me.state.pfHistory_PCurTreat_SurgDe}
                            onChange={(e) => props.me.setState({pfHistory_PCurTreat_SurgDe: e.target.value})}
                            class="form-control "
                            placeholder="specify"
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row clearfix">
                    <div class="col-md-12">
                      <p>
                        <sup>*</sup>DNA analysis from blood samples of
                        individuals who have undergone stem cell
                        transplants, bone marrow transplants, or
                        chemotheray may not reflect the germline
                        genotype. Similarly, DNA analysis from blood
                        samples of individuals with hematologic
                        malignancy may not distinguish between somatic
                        and germline variants
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          {/* familyHistoryArea */}
        </div>
      </div>
    </div>
    );
  
};

export default Family_histry;
