import React from "react";

const CircleLoader = ({ redirectStatus = false }) => {
  return (
    <div className="forgot-password-reset-page">
      <div className="request-processing-animation-box">
        <div className="circle-loader-animation"></div>
      </div>
      <p className={redirectStatus ? "no-animation" : ""}>loading, please wait...</p>
    </div>
  );
}

export default CircleLoader;