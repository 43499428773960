import React, { PureComponent } from "react";

import PropTypes from 'prop-types';
//import $ from 'jquery';
//import DatePicker from 'react-datepicker';

export default class OtherICD10CodesForm extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {

        }

    }

    //onChangeBillingDoB = date => this.setState({ billinfo_dob: date }); 

    render() {

        return (
            <>
                <div class="panel-body">
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="form-group ">
                                <label class="form-label" for="address">Other ICD-10 Codes</label>
                                <div class="form-line">
                                    <input type="text" id="OtherICD10Codes" class="form-control " placeholder="Other ICD-10 Codes" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <p>(This is provided for informational purposes only and is not a guarantee of coverage. It is the provider's responsibility to determine  the appropriate codes)</p>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12 text-right">
                            <button id="btnKitShippingInfoFormNext1" class="btn btn-light">Next</button>
                            <button style={{ display: 'none' }} id="btnKitShippingInfoFormNext" data-toggle="collapse" data-target="#KitShippingInfoForm" class="hide">Next</button>
                            <button style={{ display: 'none' }} id="btnDiagnosisCodesFormNext" data-toggle="collapse" data-target="#DiagnosisCodes_ICD10CodesForm" class="hide">Next</button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
