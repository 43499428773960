import React from 'react'



const Kit_Shipping_Information = (props) => {
    return (
        <div>
            <div
            id={`${props.state.frmprefix}kitShippingInfoFormContainer`}
            class="body card"
          >
            <div class="row clearfix">
              <div class="col-md-12">
                <div class="form-group required">
                  <label class="form-label" for="address">
                    Kit Shipping Info
                  </label>
                  <div id="draw_type" class="demo-checkbox">
                    <input
                      data-val="My physician needs mobile phlebotomy"
                      type="checkbox"
                      id={`kitShip_PatMobPhlebotomy`}
                      name={`kitShip_PatMobPhlebotomy`}
                      class="filled-in"
                      checked={props.state.kitShip_PatMobPhlebotomy}
                      onChange={(e) => props.me.setState({kitShip_PatMobPhlebotomy: e.target.checked ? 'My physician needs mobile phlebotomy' : null})}
                    />
                    <label for={`kitShip_PatMobPhlebotomy`}>
                      My physician needs mobile phlebotomy &nbsp;{" "}
                    </label>
                    <input
                      type="checkbox"
                      id={`kitShip_PatblooddrawQuest`}
                      name={`kitShip_PatblooddrawQuest`}
                      class="filled-in"
                      checked={props.state.kitShip_PatblooddrawQuest}
                      onChange={(e) => {
                        console.log('sm props.for: ', props.for)
                          const value = props.for === 'AddEditOrderGenetiXCarrierScreening' ? 'Ship physician saliva collection kit' : 'Patient will get blood draw at Quest PSC'
                          props.me.setState({kitShip_PatblooddrawQuest: e.target.checked ? value : null})
                        }
                      }
                    />
                    <label for={`kitShip_PatblooddrawQuest`}>
                      {props.for === 'AddEditOrderGenetiXCarrierScreening' ? 'Ship physician saliva collection kit' : 'Patient will get blood draw at Quest PSC'} &nbsp;{" "}
                    </label>
                    <input
                      data-val="Other"
                      type="checkbox"
                      id={`kitShip_PatOthers`}
                      name="kitShip_PatOthers"
                      class="filled-in"
                      checked={props.state.kitShip_PatOthers}
                      onChange={(e) => {
                        props.me.setState({ kitShip_PatOthers: e.target.checked ? 'Other' : null })
                        if (!e.target.checked) {
                          props.me.setState({kitShip_PatOthersInfo: ''})
                        }
                      }}
                    />
                    <label for={`kitShip_PatOthers`}>Other &nbsp; </label>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group ">
                  <label class="form-label" for="address">
                    &nbsp;
                  </label>
                  <div class="form-line">
                    <input
                      type="text"
                      style={{borderBottom: 'none'}}
                      id={`kitShip_PatOthersInfo`}
                      class="form-control "
                      placeholder=" Other info"
                      value={props.me.state.kitShip_PatOthersInfo}
                      onChange={({ target: { value } }) => props.me.setState({kitShip_PatOthersInfo: value})}
                      disabled={!props.me.state.kitShip_PatOthers}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    )
}

export default Kit_Shipping_Information
