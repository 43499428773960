import React, { PureComponent } from "react";

import PropTypes from 'prop-types';
import $ from 'jquery';
//import DatePicker from 'react-datepicker';
import helper from './../utils/helperPortal';

export default class KitShippingInfoOVAForm extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {

        }

    }

    //onChangeBillingDoB = date => this.setState({ billinfo_dob: date }); 

    componentDidMount() {
        $(document).ready(() => {
            $('#btnKitShippingInfoOVAForm2Reset').click(() => {
                $('#OVAKitShippingInfoContainer').find(':input').map((i, elem) => {
                    const _type = $(elem).attr('type');
                    helper.resetForm(_type, elem);
                })
            })
        });
    }
    render() {

        return (
            <>
                <div id={`OVAKitShippingInfoContainer`} class="panel-body">
                    <div class="row clearfix">
                        <div class="col-md-7">
                            <div class="form-group required">
                                <label class="form-label" for="address">Kit Shipping Info</label>
                                <div class="demo-checkbox" id="draw_type" >
                                    <input type="checkbox" data-val="My physician needs mobile phlebotomy" id="kitShipOVA_PatMobPhlebotomy" name="kitShipOVA_PatMobPhlebotomy" class="filled-in" />
                                    <label for="kitShipOVA_PatMobPhlebotomy">My physician needs mobile phlebotomy &nbsp; </label>
                                    <input type="checkbox" data-val="Patient will get blood draw at Quest PSC" id="kitShipOVA_PatblooddrawQuest" name="kitShipOVA_PatblooddrawQuest" class="filled-in" />
                                    <label for="kitShipOVA_PatblooddrawQuest">Patient will get blood draw at Quest PSC &nbsp; </label>
                                    <input type="checkbox" data-val="Other" id="kitShipOVA_PatOthers" name="kitShipOVA_PatOthers" class="filled-in" />
                                    <label for="kitShipOVA_PatOthers">Other &nbsp; </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="form-group ">
                                <label class="form-label" for="address">&nbsp;</label>
                                <div class="form-line">
                                    <input type="text" id="kitShipOVA_PatOthersInfo" class="form-control " placeholder=" Others info" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12 text-right">
                            <button id={`btnKitShippingInfoOVAForm2Reset`} class="btn btn-light">Reset Form</button>
                            <button id="btnKitShippingInfoOVAFormNext" class="btn btn-light">Next</button>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}
