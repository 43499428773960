import React, { useEffect, useState } from "react";
import $ from "jquery";

import helper from '../utils/helperPortal';

const OvaWatchSuitOfTestsForm = (props) => {

  const [assays, setAssays] = useState([]);

  const loadInfo = async () => {

    //Assays mapping dynamically
    let _assays = await helper.getAssaysByType('OvaWatch');
    console.log("OvaWatch _assays: ", _assays);

    setAssays(_assays.assays);

    // let content = ``;
    // _assays.assays.map((a, i) => {
    // content += `<div class="col-md-4">`;
    // content += `<input data-qbenchid="${a.qBenchId}" data-val="${a.name}" type="radio" name="gtcs_assay_" id="gtcs_assay_${i}" class="form-control" />
    //   <label for="gtcs_assay_${i}">${a.name}  &nbsp; </label></div>`;
    // });

    // this.setState({ assays: content }, () => {
    //   $('#printHere_CS').html(content);
    // });

    // $('#btnASPiRAGENETIXCarrierScreeningFormReset').click(() => {
    //   $(`#ASPiRAGENETIXCarrierScreeningForm`).find(`:input`).map((i, elem) => {
    //     const _type = $(elem).attr('type');
    //     helper.resetForm(_type, elem);
    //   });
    // })
  };
  useEffect(() => {
    loadInfo();
  }, []);
  // helper methods for rendering
  const renderOvaWatchMainTestPanelCheckbox = () => {
    return (
      <div className="row clearfix">
        <div id={`${props.prefix}_ovaWatchTestsForm`} className="col-md-12">
          {assays.map((assay, i) => {
            return <>
              <input checked data-val={`${assay.name}`} data-qbenchid={`${assay.qBenchId}`} type="checkbox" id={`${props.prefix}_OvaWatch_${i}`} name={`${props.prefix}_OvaWatch`} class="filled-in" />
              <label for={`${props.prefix}_OvaWatch_${i}`}>OvaWatch &nbsp; </label><br />
            </>
          })
          }
        </div>
      </div>
    );
  };

  const renderNextAndResetFormButtons = () => {
    return (
      <div class="row clearfix">
        <div class="col-md-12 text-right">
          <button id={`${props.prefix}btnOvaWatchSuitOfTestsFormReset`} class="btn btn-light" onClick={(event) => {
            event.preventDefault();
            $(`#${props.prefix}_ovaWatchTestsForm`).find(`:input`).map((i, elem) => {
              const _type = $(elem).attr('type');
              helper.resetForm(_type, elem);
            });
          }}>Reset Form</button>
          <button id={`${props.prefix}btnOvaWatchSuitOfTestsFormNext`} class="btn btn-light">Next</button>
        </div>
      </div>
    );
  }

  return (
    <div id="ovaWatchSuitOfTestsForm" className="panel-body">
      {renderOvaWatchMainTestPanelCheckbox()}
      {renderNextAndResetFormButtons()}
    </div>
  );
};

export default OvaWatchSuitOfTestsForm;