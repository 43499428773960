import React, { PureComponent } from "react";

import PropTypes from 'prop-types';
import $ from 'jquery';
import DatePickerVal from './../portalComponents/DatePickerVal';

import DatePicker from 'react-datepicker';

import moment from 'moment';
import helper from "../utils/helperPortal";

export default class FamilyHistoryForm extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            customDiv: ['div1'],
            len: 1,
            dobs: [],
            dateFields: {},
        }

    }

    addNewRow() {
        let cDivs = this.state.customDiv;
        cDivs.push('newDiv')
        console.log("cDivs: ", cDivs)
        this.setState({ customDiv: cDivs, len: cDivs.length })
    }
    onChangeDob1 = (dateName, date, _index) => {
        let _dobs = this.state.dobs;
        _dobs[_index] = date;

        this.setState({
            dateFields: {
                ...this.state.dateFields,
                [dateName]: date
            },
            date, dobs: _dobs,
        }, () => {
            console.log("this.state.dateFields: ", this.state.dateFields);

            let dateStr = moment(date).format('YYYY-MM-DD');
            $(`#FamilyHistoryForm_dob_${_index}`).val(dateStr);
        })

    };

    componentDidMount() {
        $(document).ready(() => {
            $('#btnFamilyHistoryFormReset').click(() => {
                $('#FamilyHistoryForm').find(':input').map((i, elem) => {

                    const _type = $(elem).attr('type');

                    helper.resetForm(_type, elem);
                });
            })
        })
    }
    render() {

        return (
            <>
                <div class="panel-body">
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="field_wrapper">
                                {this.state.customDiv.map((cdiv, i) => {
                                    return <div key={i} className="duplicator" id={`duplicator_${i}`}>

                                        <div class="row clearfix">
                                            <div class="col-md-4">
                                                <div class="form-group ">
                                                    <label class="form-label" for="Familymembername1">Family member {(i + 1)} name</label>
                                                    <div class="form-line">
                                                        <input type="text" id={`famHist_familyMemName1_${i}`} name="famHist_familyMemName[]" class="form-control " placeholder={`Family member ${(i + 1)} name`} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label class="form-label" for="Relationtophysician1">Relation to Patient</label>
                                                    <div class="form-line">
                                                        <input type="text" id={`famHist_RelationPatient1_${i}`} name="famHist_RelationPatient[]" class="form-control" placeholder="Relation to Patient" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-4">
                                                <div class="form-group">
                                                    <label class="form-label" for="Geneticsex">Genetic sex</label>
                                                    <div id={`famHist_GenSex_container_${i}`} class="demo-radio-button">
                                                        <input type="radio" id={`famHist_GenSexM1_${i}`} name={`famHist_GenSex_${i}`} data-val="male" class="form-control" />
                                                        <label class="form-label" for={`famHist_GenSexM1_${i}`}>Male &nbsp; </label>
                                                        <input type="radio" id={`famHist_GenSexFe1_${i}`} name={`famHist_GenSex_${i}`} data-val="female" class="form-control" />
                                                        <label class="form-label" for={`famHist_GenSexFe1_${i}`}>Female &nbsp; </label>
                                                        <input type="radio" id={`famHist_GenSexUnknown1_${i}`} name={`famHist_GenSex_${i}`} data-val="Unknown" class="form-control" />
                                                        <label class="form-label" for={`famHist_GenSexUnknown1_${i}`}>Unknown &nbsp; </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row clearfix">
                                            <div class="col-md-8">
                                                <div class="form-group ">
                                                    <label class="form-label" for="Diagnosisand_orsymptoms">Diagnosis and/or symptoms</label>
                                                    <div class="form-line">
                                                        <input type="text" id={`famHist_DiagSymptoms1_${i}`} name="famHist_DiagSymptoms[]" class="form-control " placeholder="Diagnosis and/or symptoms" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="form-group ">
                                                    <label class="form-label" for="Ageofonset">Age of onset</label>
                                                    <div class="form-line">
                                                        <input type="text" id={`famHist_AgeofOnset1_${i}`} name="famHist_AgeofOnset[]" class="form-control" placeholder="Age of onset" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-2">
                                                <div class="form-group ">
                                                    <label class="form-label" for="dob1">DOB (MM/DD/YYYY)</label>
                                                    <div class="form-line">
                                                        <DatePicker
                                                            id={`datepicker_${i}`}
                                                            className={"form-control"}
                                                            selected={this.state.dateFields[`dob_${i}`]}
                                                            onChange={(date) => {
                                                                this.onChangeDob1(`dob_${i}`, date, i);
                                                            }}
                                                            peekNextMonth
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                        />

                                                        <input type="hidden" id={`FamilyHistoryForm_dob_${i}`} name="FamilyHistoryForm_dob[]" value={``} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                })
                                }
                            </div>

                        </div>
                    </div>

                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="form-group text-right">
                                <button className={"btn-family-history-add-new"} onClick={() => this.addNewRow()}>+</button>
                            </div>
                        </div>
                    </div>


                    {/* <div class="row clearfix">
                        <div class="col-md-4">
                            <div class="form-group ">
                                <label class="form-label" for="Familymembername2">Family member 2 name</label>
                                <div class="form-line">                                
                                    <input type="text" id="famHist_familyMemName2" class="form-control " placeholder="Family member 2 name"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="form-label" for="Relationtophysician2">Relation to Patient</label>
                                <div class="form-line">                                
                                    <input type="text" id="famHist_RelationPatient2" class="form-control" placeholder="Relation to Patient"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="form-label" for="Geneticsex2">Genetic sex</label>
                                <div class="demo-radio-button">
                                    <input type="radio" id="famHist_GenSexM2" name="famHist_GenSex2" value="male" class="form-control"/>
                                    <label class="form-label" for="famHist_GenSexM2">Male &nbsp; </label> 
                                    <input type="radio" id="famHist_GenSexFe2" name="famHist_GenSex2" value="female" class="form-control"/>
                                    <label class="form-label" for="famHist_GenSexFe2">Female &nbsp; </label> 
                                    <input type="radio" id="famHist_GenSexUnknown2" name="famHist_GenSex2" value="Unknown" class="form-control"/>
                                    <label class="form-label" for="famHist_GenSexUnknown2">Unknown &nbsp; </label>                                                                                                                        
                                </div>
                            </div>
                        </div>
                    </div>                    
                    <div class="row clearfix">
                        <div class="col-md-8">
                            <div class="form-group ">
                                <label class="form-label" for="Diagnosisand_orsymptoms2">Diagnosis and/or symptoms</label>
                                <div class="form-line">                                
                                    <input type="text" id="famHist_DiagSymptoms2" class="form-control " placeholder="Diagnosis and/or symptoms"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group ">
                                <label class="form-label" for="Ageofonset2">Age of onset</label>
                                <div class="form-line">                                
                                    <input type="text" id="famHist_AgeofOnset2" class="form-control" placeholder="Age of onset"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group ">
                                <label class="form-label" for="dob2">DOB (MM/DD/YYYY)</label>
                                <div class="form-line">                                
                                    <DatePicker
                                        className={"form-control"}
                                        showLeadingZeros={true}
                                        calendarIcon={null}
                                        onChange={this.onChangeDob2}
                                        value={this.state.famHist_dob2}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row clearfix">
                        <div class="col-md-4">
                            <div class="form-group ">
                                <label class="form-label" for="Familymembername3">Family member 3 name</label>
                                <div class="form-line">                                
                                    <input type="text" id="famHist_familyMemName3" class="form-control " placeholder="Family member 1 name"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="form-label" for="Relationtophysician3">Relation to Patient</label>
                                <div class="form-line">                                
                                    <input type="text" id="famHist_RelationPatient3" class="form-control" placeholder="Relation to Patient"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label class="form-label" for="Geneticsex3">Genetic sex</label>
                                <div class="demo-radio-button">
                                    <input type="radio" id="famHist_GenSexM3" name="famHist_GenSex3" value="male" class="form-control"/>
                                    <label class="form-label" for="famHist_GenSexM3">Male &nbsp; </label> 
                                    <input type="radio" id="famHist_GenSexFe3" name="famHist_GenSex3" value="female" class="form-control"/>
                                    <label class="form-label" for="famHist_GenSexFe3">Female &nbsp; </label> 
                                    <input type="radio" id="famHist_GenSexUnknown3" name="famHist_GenSex3" value="Unknown" class="form-control"/>
                                    <label class="form-label" for="famHist_GenSexUnknown3">Unknown &nbsp; </label>                                                                                                                        
                                </div>
                            </div>
                        </div>
                    </div>                    
                    <div class="row clearfix">
                        <div class="col-md-8">
                            <div class="form-group ">
                                <label class="form-label" for="Diagnosisand_orsymptoms3">Diagnosis and/or symptoms</label>
                                <div class="form-line">                                
                                    <input type="text" id="famHist_DiagSymptoms3" class="form-control " placeholder="Diagnosis and/or symptoms"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group ">
                                <label class="form-label" for="Ageofonset3">Age of onset</label>
                                <div class="form-line">                                
                                    <input type="text" id="famHist_AgeofOnset3" class="form-control" placeholder="Age of onset"/>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="form-group ">
                                <label class="form-label" for="dob3">DOB (MM/DD/YYYY)</label>
                                <div class="form-line">                                
                                    <DatePicker
                                        className={"form-control"}
                                        showLeadingZeros={true}
                                        calendarIcon={null}
                                        onChange={this.onChangeDob3}
                                        value={this.state.famHist_dob3}
                                    />
                                </div>
                            </div>
                        </div>
                    </div> */}

                    <div class="row clearfix">
                        <div class="col-md-12 text-right">
                            <button id={`btnFamilyHistoryFormReset`} class="btn btn-light">Reset Form</button>
                            <button id="btnFamilyHistoryFormNext" class="btn btn-light">Next</button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
