import React, { PureComponent, useState, useContext, createRef, useEffect } from "react";

import PropTypes from 'prop-types';
import $ from 'jquery';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import PhoneNumberInput from './../portalComponents/phoneNumberInput';

import { AppContext } from "./../context";
import Select from 'react-select';
import helper from "./../utils/helperPortal";
import moment from 'moment';
import { useLocation } from "react-router-dom";


const PatientInformationForm = ({ prefix, phoneNoPrefix, dobFunc, onChangeState, onChangeLastMentrualPeriodDate: onChangeLastMentrualPeriodDateFunc }) => {

    let location = useLocation();
    let hostname = window.location.hostname;
    console.log("location patient:", hostname);

    let patientState = createRef();

    const { addNewPatientDOB } = useContext(AppContext);
    const [patinfo_dob, setPatInfoDOB] = useState(null);
    const [selectedState, setSelectedState] = useState("ALABAMA");
    const [isLab, setIsLab] = useState(false);
    const [clinicinfo_DoLastMentrualPeriod, setClinicinfo_DoLastMentrualPeriod] = useState(null);

    const onChangeDob = date => {
        console.log("date: ", date);
        addNewPatientDOB(date);
        setPatInfoDOB(date);

        if (dobFunc) {
            const dateStr = moment(date).format("YYYY-MM-DD");
            console.log("dateStr: ", dateStr);
            dobFunc(dateStr);
        }
    }

    const otherEthnicityClick = () => {
        var patinfo_Other = document.getElementById(`${prefix}patinfo_Other`);
        if (patinfo_Other.checked == true) {
            $(`#${prefix}PatientContainer #${prefix}patinfo_Othercity_div`).removeClass(" hideFormContent");
            $(`#${prefix}PatientContainer #${prefix}patinfo_Othercity_div`).addClass(" showFormConent");
            $(`#${prefix}PatientContainer #${prefix}patinfo_Othercity`).addClass(" required");
        }
        else {
            $(`#${prefix}PatientContainer #${prefix}patinfo_Othercity_div`).addClass(" hideFormContent");
            $(`#${prefix}PatientContainer #${prefix}patinfo_Othercity_div`).removeClass(" showFormConent");
            $(`#${prefix}PatientContainer #${prefix}patinfo_Othercity`).removeClass(" required");
        }
    }

    const onChangeDoLastMentrualPeriod = date => {
        setClinicinfo_DoLastMentrualPeriod(date);

        // console.log("onChangeDoLastMentrualPeriod->date.toString(): ", date.toString());

        let dateStr = moment(date).format('YYYY-MM-DD');

        $(`#${prefix}clinicinfo_DoLastMentrualPeriod_hidden`).val(dateStr);

        if (onChangeLastMentrualPeriodDateFunc) {
            onChangeLastMentrualPeriodDateFunc(dateStr);
        }
    };

    $(document).ready(() => {
        // $(`#${prefix}patinfo_DOB`).datepicker({
        // });
        $(`#${prefix}btnPatientInfoFormReset`).click(() => {
            $(`#${prefix}PatientContainer`).find(':input').map((i, elem) => {
                const _type = $(elem).attr('type');
                console.log("_type: ", _type);
                helper.resetForm(_type, elem);
            });

            if (patientState) {
                setSelectedState({});
            }

            console.log("patinfo_dob: ", patinfo_dob);
            if (patinfo_dob) {
                setPatInfoDOB(null);
            }
        });

        $(`#${prefix}clinicinfo_lowrisk`).change(() => {
            if ($(`#${prefix}clinicinfo_lowrisk`).is(":checked")) {
                $(`#${prefix}clinicinfo_highrisk`).prop("checked", false);
                $(`#${prefix}clinicinfo_NotDefinitive`).prop("checked", false);
                $(`#${prefix}clinicinfo_NotGiven`).prop("checked", false);
            }
        });

        $(`#${prefix}clinicinfo_highrisk`).change(() => {
            if ($(`#${prefix}clinicinfo_highrisk`).is(":checked")) {
                $(`#${prefix}clinicinfo_lowrisk`).prop("checked", false);
                $(`#${prefix}clinicinfo_NotDefinitive`).prop("checked", false);
                $(`#${prefix}clinicinfo_NotGiven`).prop("checked", false);
            }
        });

        $(`#${prefix}clinicinfo_NotDefinitive`).change(() => {
            if ($(`#${prefix}clinicinfo_NotDefinitive`).is(":checked")) {
                $(`#${prefix}clinicinfo_lowrisk`).prop("checked", false);
                $(`#${prefix}clinicinfo_highrisk`).prop("checked", false);
                $(`#${prefix}clinicinfo_NotGiven`).prop("checked", false);
            }
        });
        $(`#${prefix}clinicinfo_NotGiven`).change(() => {
            if ($(`#${prefix}clinicinfo_NotGiven`).is(":checked")) {
                $(`#${prefix}clinicinfo_lowrisk`).prop("checked", false);
                $(`#${prefix}clinicinfo_highrisk`).prop("checked", false);
                $(`#${prefix}clinicinfo_NotDefinitive`).prop("checked", false);
            }
        });
    })

    useEffect(() => {
        console.log("hostname: ", hostname);
        if (hostname === 'lab.aspirawh.com' || hostname === 'dev.aspirawh.cloud' || hostname === "order.dev-aspiralab.com") {
            setIsLab(true);
        }
    }, [hostname])
    return (
        <>
            <div id={`${prefix}PatientContainer`} class="panel-body">
                <div class="row clearfix">
                    <div class="col-md-6">
                        <div class="form-group required">
                            <label class="form-label" for={`${prefix}patinfo_firstname`}>First Name</label>
                            <div class="form-line">
                                <input type="text" id={`${prefix}patinfo_firstname`} class="form-control required" placeholder="First Name" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="form-group required">
                            <label class="form-label" for={`${prefix}patinfo_lastname`}>Last Name</label>
                            <div class="form-line">
                                <input type="text" id={`${prefix}patinfo_lastname`} class="form-control required" placeholder="Last Name" />
                            </div>
                        </div>
                    </div>
                </div>

                {(!helper.isAccessingFromAspiraLab() || (prefix !== "ova1_" && prefix !== "ovaWatch_")) && <>
                    <div class="row clearfix">
                        <div class="col-md-6">
                            <div class="form-group required">
                                <label class="form-label" for={`${prefix}patinfo_address1`}>Address 1</label>
                                <div class="form-line">
                                    <input type="text" id={`${prefix}patinfo_address1`} class="form-control required" placeholder="Address 1" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label class="form-label" for={`${prefix}patinfo_address2`}>Address 2</label>
                                <div class="form-line">
                                    <input type="text" id={`${prefix}patinfo_address2`} class="form-control" placeholder="Address 2" />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row clearfix">
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for={`${prefix}patinfo_city`}>City</label>
                                <div class="form-line">
                                    <input type="text" id={`${prefix}patinfo_city`} class="form-control required" placeholder="City" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for="state">State</label>
                                <div class="form-line">
                                    <Select
                                        ref={(_ref) => { patientState = _ref }}
                                        id={`${prefix}patientState`}
                                        // defaultValue={"Choose State"}
                                        value={selectedState}
                                        label="State:"
                                        class="form-control"
                                        options={helper.usStates}
                                        styles={helper.colourStyles}
                                        onChange={(val) => {
                                            onChangeState(val.code);
                                            $(`#${prefix}patinfo_state`).val(val.value);
                                            setSelectedState(val);
                                        }}

                                    />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for={`${prefix}patinfo_zipcode`}>Zip Code</label>
                                <div class="form-line">
                                    <input type="text" id={`${prefix}patinfo_zipcode`} class="form-control required" placeholder="Zip Code" />
                                </div>
                            </div>
                        </div>
                    </div>
                </>}

                <div class="row clearfix">
                    <div class="col-md-4">
                        <div class="form-group required">
                            <label class="form-label" for={`${prefix}patinfo_DOB`}>DOB (mm/dd/yyyy)</label>
                            <div class="form-line">

                                <DatePicker
                                    id={`${prefix}patinfo_DOB`}
                                    className={"form-control required"}
                                    selected={patinfo_dob}
                                    onChange={date => onChangeDob(date)}
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                />
                            </div>
                        </div>
                    </div>
                    {/* <div class="col-md-4">
                        <div class="form-group ">
                            <label class="form-label" for="SSN">SSN</label>
                            <div class="form-line">
                                <input type="text" id={`${prefix}patinfo_ssn`} class="form-control " placeholder="SSN" />
                            </div>
                        </div>
                    </div> */}

                    {(!helper.isAccessingFromAspiraLab() || (prefix !== "ova1_" && prefix !== "ovaWatch_")) && <>
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for="PhoneNumber">Phone Number</label>
                                <div class="form-line">
                                    <PhoneNumberInput prefix={`${prefix}patinfo_`} />
                                    {/* <input type="text" id={`${prefix}patinfo_PhoneNumber`} class="form-control required" placeholder="Phone Number" /> */}
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for={`${prefix}patinfo_emailaddress`}>Email Address</label>
                                <div class="form-line">
                                    <input type="email" id={`${prefix}patinfo_emailaddress`} class="form-control required" placeholder="Email Address" />
                                </div>
                            </div>
                        </div>
                    </>}
                </div>

                <div class="row clearfix">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-label" for="Ethnicity">Ethnicity (Check all that apply)</label>
                            <div id={`${prefix}patinfo_ethnicity`} class="demo-radio-button">
                                <input type="radio" data-val="Caucasian" id={`${prefix}patinfo_Caucasian`} name="patinfo_Caucasian_" class="form-control" onClick={() => {
                                    otherEthnicityClick()
                                }} />
                                <label for={`${prefix}patinfo_Caucasian`}>Caucasian &nbsp; &nbsp; </label>
                                <input type="radio" data-val="Ashkenazi Jewish" id={`${prefix}patinfo_AshkenaziJewish`} name="patinfo_Caucasian_" class="form-control" onClick={() => {
                                    otherEthnicityClick()
                                }} />
                                <label for={`${prefix}patinfo_AshkenaziJewish`}>Ashkenazi Jewish &nbsp; &nbsp; </label>
                                <input type="radio" data-val="Sephardic Jewish" id={`${prefix}patinfo_SephardicJewish`} name="patinfo_Caucasian_" class="form-control" onClick={() => {
                                    otherEthnicityClick()
                                }} />
                                <label for={`${prefix}patinfo_SephardicJewish`}>Sephardic Jewish &nbsp; &nbsp; </label>
                                <input type="radio" data-val="Asian" id={`${prefix}patinfo_Asian`} name="patinfo_Caucasian_" class="form-control" onClick={() => {
                                    otherEthnicityClick()
                                }} />
                                <label for={`${prefix}patinfo_Asian`}>Asian &nbsp; &nbsp; </label>
                                <input type="radio" data-val="Hispanic" id={`${prefix}patinfo_HIspanic`} name="patinfo_Caucasian_" class="form-control" onClick={() => {
                                    otherEthnicityClick()
                                }} />
                                <label for={`${prefix}patinfo_HIspanic`}>Hispanic &nbsp; &nbsp; </label>
                                <input type="radio" data-val="Native American" id={`${prefix}patinfo_NativeAmerican`} name="patinfo_Caucasian_" class="form-control" onClick={() => {
                                    otherEthnicityClick()
                                }} />
                                <label for={`${prefix}patinfo_NativeAmerican`}>Native American &nbsp; &nbsp; </label>
                                <input type="radio" data-val="African American" id={`${prefix}patinfo_AfricanAmerican`} name="patinfo_Caucasian_" class="form-control" onClick={() => {
                                    otherEthnicityClick()
                                }} />
                                <label for={`${prefix}patinfo_AfricanAmerican`}>African American &nbsp; &nbsp; </label>
                                <input type="radio" data-val="N/A" id={`${prefix}patinfo_na`} name="patinfo_Caucasian_" class="form-control" onClick={() => {
                                    otherEthnicityClick()
                                }} />
                                <label for={`${prefix}patinfo_na`}>N/A &nbsp; &nbsp; </label>
                                <input type="radio" data-val="Other" id={`${prefix}patinfo_Other`} name="patinfo_Caucasian_" class="form-control" onClick={() => {
                                    otherEthnicityClick()
                                }} />
                                <label for={`${prefix}patinfo_Other`}>Other: &nbsp; &nbsp; </label>
                            </div>
                        </div>
                        <div class="form-group ">
                            {/* <label class="form-label" for="Ethnicity">Ethnicity (Check all that apply)</label>
                            <div class="demo-checkbox">
                                <input type="checkbox" data-val="Caucasian" id={`${prefix}patinfo_Caucasian`} name="patinfo_Caucasian" class="filled-in" />
                                <label for={`${prefix}patinfo_Caucasian`}>Caucasian &nbsp; &nbsp; </label>
                                <input type="checkbox" data-val="Ashkenazi Jewish" id={`${prefix}patinfo_AshkenaziJewish`} name="patinfo_AshkenaziJewish" class="filled-in" />
                                <label for={`${prefix}patinfo_AshkenaziJewish`}>Ashkenazi Jewish &nbsp; &nbsp; </label>
                                <input type="checkbox" data-val="Sephardic Jewish" id={`${prefix}patinfo_SephardicJewish`} name="patinfo_SephardicJewish" class="filled-in" />
                                <label for={`${prefix}patinfo_SephardicJewish`}>Sephardic Jewish &nbsp; &nbsp; </label>
                                <input type="checkbox" data-val="Asian" id={`${prefix}patinfo_Asian`} name="patinfo_Asian" class="filled-in" />
                                <label for={`${prefix}patinfo_Asian`}>Asian &nbsp; &nbsp; </label>
                                <input type="checkbox" data-val="Hispanic" id={`${prefix}patinfo_HIspanic`} name="patinfo_HIspanic" class="filled-in" />
                                <label for={`${prefix}patinfo_HIspanic`}>Hispanic &nbsp; &nbsp; </label>
                                <input type="checkbox" data-val="Native American" id={`${prefix}patinfo_NativeAmerican`} name="patinfo_NativeAmerican" class="filled-in" />
                                <label for={`${prefix}patinfo_NativeAmerican`}>Native American &nbsp; &nbsp; </label>
                                <input type="checkbox" data-val="African American" id={`${prefix}patinfo_AfricanAmerican`} name="patinfo_AfricanAmerican" class="filled-in" />
                                <label for={`${prefix}patinfo_AfricanAmerican`}>African American &nbsp; &nbsp; </label>
                                <input type="checkbox" data-val="Other" id={`${prefix}patinfo_Other`} name="patinfo_Other" class="filled-in" onClick={() => {
                                    otherEthnicityClick()
                                }} />
                                <label for={`${prefix}patinfo_Other`}>Other: &nbsp; &nbsp; </label>
                            </div> */}
                            <div class="form-line hideFormContent" id={`${prefix}patinfo_Othercity_div`}>
                                <input type="text" id={`${prefix}patinfo_OtherEthnicitycity`} name="patinfo_OtherEthnicitycity" class="form-control" placeholder="Other" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="form-group required">
                            <label class="form-label" for={`${prefix}patinfo_gender`}>Gender</label>
                            <div id={`${prefix}patinfo_gender_div`} class="demo-radio-button">

                                <input type="radio" id={`${prefix}patinfo_gender`} name="patinfo_gender_" value="male" class=" form-control" />
                                <label class="form-label gender" for={`${prefix}patinfo_gender`}>Male &nbsp; </label>

                                {isLab && <input checked="true" type="radio" id={`${prefix}patinfo_gender2`} name="patinfo_gender_" value="female" class=" form-control" />}
                                {!isLab && <input type="radio" id={`${prefix}patinfo_gender2`} name="patinfo_gender_" value="female" class=" form-control" />}

                                <label class="form-label gender" for={`${prefix}patinfo_gender2`}>Female &nbsp; </label>
                            </div>
                        </div>
                    </div>
                </div>

                {(helper.isAccessingFromAspiraLab() && (prefix === "ova1_" || prefix === "ovaWatch_")) && <>
                    <div class="row clearfix">
                        <div class="col-md-6">
                            <div class="form-group required">
                                <label class="form-label" for={`${prefix}MenopausalStatus`}>Menopausal Status</label>
                                <div id={`${prefix}ova1_q01`} class="demo-radio-button">
                                    <input type="radio" id={`${prefix}clinicinfo_Menopausal_status`} name="clinicinfo_Menopausal_status_" value="Pre-Menopausal" class=" form-control" />
                                    <label class="form-label menopausalStatus" for={`${prefix}clinicinfo_Menopausal_status`}>Pre-Menopausal &nbsp; </label>
                                    <input type="radio" id={`${prefix}clinicinfo_Menopausal_status2`} name="clinicinfo_Menopausal_status_" value="Post-Menopausal" class=" form-control" />
                                    <label class="form-label menopausalStatus" for={`${prefix}clinicinfo_Menopausal_status2`}>Post-Menopausal &nbsp; </label>
                                    <input type="radio" id={`${prefix}clinicinfo_Menopausal_status3`} name="clinicinfo_Menopausal_status_" value="Unknown" class=" form-control" />
                                    <label class="form-label menopausalStatus" for={`${prefix}clinicinfo_Menopausal_status3`}>Unknown &nbsp; </label>
                                    <input type="radio" id={`${prefix}clinicinfo_Menopausal_status4`} name="clinicinfo_Menopausal_status_" value="Not Given" class=" form-control" />
                                    <label class="form-label menopausalStatus" for={`${prefix}clinicinfo_Menopausal_status4`}>Not Given &nbsp; </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group required">
                                <label class="form-label" for={`${prefix}UltrasoundResults`}>Ultrasound Results</label>
                                <div id={`${prefix}ova1_q02`} class="demo-checkbox">
                                    <input data-val="Low Risk" type="checkbox" id={`${prefix}clinicinfo_lowrisk`} name="clinicinfo_lowrisk" class=" filled-in" />
                                    <label for={`${prefix}clinicinfo_lowrisk`}>Low Risk &nbsp; </label>
                                    <input data-val="High Risk" type="checkbox" id={`${prefix}clinicinfo_highrisk`} name="clinicinfo_highrisk" class=" filled-in" />
                                    <label for={`${prefix}clinicinfo_highrisk`}>High Risk &nbsp; </label>
                                    <input data-val="Not Definitive" type="checkbox" id={`${prefix}clinicinfo_NotDefinitive`} name="clinicinfo_NotDefinitive" class=" filled-in" />
                                    <label for={`${prefix}clinicinfo_NotDefinitive`}>Not Definitive &nbsp; </label>
                                    <input data-val="Not Given" type="checkbox" id={`${prefix}clinicinfo_NotGiven`} name="clinicinfo_NotGiven" class=" filled-in" />
                                    <label for={`${prefix}clinicinfo_NotGiven`}>Not Given &nbsp; </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id={`${prefix}ova1_q03`} class="row clearfix">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="form-label" for={`${prefix}dob`}>Date of last menstrual period </label>
                                <div class="form-line">
                                    <DatePicker
                                        id={`${prefix}clinicinfo_DoLastMentrualPeriod`}
                                        className={"form-control"}
                                        selected={clinicinfo_DoLastMentrualPeriod}
                                        onChange={onChangeDoLastMentrualPeriod}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </>}

                <div class="row clearfix">
                    <div class="col-md-12 text-right">
                        {/* <button type="button" id={`${prefix}btnPatientSubmit" data-loading-text="<img style='height: 30px;' src='/assets/images/Spinner-1s-200px.svg' /> Creating Physician`} class="btn btn-light subPat">Submit</button> */}

                        {/* <button id={`${prefix}btnPatientInfoFormSubmit`} class="btn btn-light">Submit</button> */}
                        <button id={`${prefix}btnPatientInfoFormReset`} class="btn btn-light">Reset Form</button>
                        <button id={`${prefix}btnPatientInformationFormNext`} class="btn btn-light">Next</button>
                    </div>
                </div>

            </div>
        </>
    );

}

export default PatientInformationForm;