import React, { PureComponent, useState, useEffect, useContext } from "react";

import PropTypes from 'prop-types';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//includes all forms here
import PhysicianInformationForm from "./PhysicianInformationForm";
//import PhysicianInformationAntibodyForm from "./PhysicianInformationAntibodyForm";
import PatientInformationForm from "./PatientInformationForm";
//import PatientInformationAntibodyForm from "./PatientInformationAntibodyForm";
//import ClinicalInformationForm from "./ClinicalInformationForm";
import BillingInformationForm from "./BillingInformationForm";

import DiagnosisCodes_ICD10CodesForm from "./DiagnosisCodes_ICD10CodesForm";
// import DiagnosisCodes_ICD10CodesCOVDForm from "./DiagnosisCodes_ICD10CodesCOVDForm";

import NotesCertifyForm from "./NotesCertifyForm";
// import NotesCertifyAntibodyForm from "./NotesCertifyAntibodyForm";
// import KitShippingInfoAntibodyForm from "./KitShippingInfoAntibodyForm";
import KitShippingInfoForm from "./KitShippingInfoForm";

import Toast from './../portalComponents/toast/Toast';
import axios from "axios";
import helper from './../utils/helperPortal';

import { createPhysicianApi } from "./../context/actions/physiciansActions";
import { createPatientApi, createPatients, createOva1OrderApi } from "./../context/actions/patientActions";

import { AppContext } from "./../context";

const api_base_url = helper.getAPIBaseURI();

const AntiSARSCoV19AntibodyForm = () => {

  const covid_prefix = "covid_";
  const covidPhoneNo_prefix = "covid_";
  const physicianAddressContext = useContext(AppContext);
  const { physicianAddresses, selectedPhysicianAddress, physicianPracticeLocation, selectedPhysicianPracticeLocation } = physicianAddressContext;
  const { patientDOB } = useContext(AppContext);

  let toastProperties = {};
  const [toastList, setToastList] = useState([]);
  const [toastMessage, setToastMessage] = useState("");
  const [patientState, setPatientState] = useState("");
  const [patientDOBLocal, setPatientDOBLocal] = useState("");

  //const [phyinfo_no_npiLocal, setphyinfo_no_npiLocal] = useState("");  


  const showToast = (type) => {

    const id = Math.floor((Math.random() * 101) + 1);

    switch (type) {
      case 'success':
        toastProperties = {
          id,
          title: 'Success',
          description: 'This is a success toast component',
          backgroundColor: '#5cb85c',
          icon: "/assets/toast/check.svg"
        }
        break;
      case 'danger':
        toastProperties = {
          id,
          title: 'Danger',
          description: 'This is a error toast component',
          backgroundColor: '#d9534f',
          icon: "/assets/toast/error.svg"
        }
        break;
      case 'info':
        toastProperties = {
          id,
          title: 'Info',
          description: 'This is an info toast component',
          backgroundColor: '#5bc0de',
          icon: "/assets/toast/info.svg"
        }
        break;
      case 'warning':
        toastProperties = {
          id,
          title: 'Warning',
          description: 'This is a warning toast component',
          backgroundColor: '#f0ad4e',
          icon: "/assets/toast/warning.svg"
        }
        break;

      default:
        setToastList([]);
    }

    setToastList([toastProperties]);
  }
  const loadInfo = () => {

  };
  React.useEffect(() => {
    loadInfo();
  });

  $(document).ready(() => {

    //function for Physician Form Validation
    function checkValidityPhysicianForm() {

      const phyinfo_Organization_account = helper.removeWhiteSpaceTrim($(`#${covid_prefix}phyinfo_Organization_account`).val());
      $(`#${covid_prefix}phyinfo_Organization_account`).val(phyinfo_Organization_account);

      const phyinfo_first_name = helper.removeWhiteSpaceTrim($(`#${covid_prefix}phyinfo_first_name`).val());
      $(`#${covid_prefix}phyinfo_first_name`).val(phyinfo_first_name);

      const phyinfo_last_name = helper.removeWhiteSpaceTrim($(`#${covid_prefix}phyinfo_last_name`).val());
      $(`#${covid_prefix}phyinfo_last_name`).val(phyinfo_last_name);

      const phyinfo_npi = helper.removeWhiteSpaceTrim($(`#${covid_prefix}phyinfo_npi`).val());
      $(`#${covid_prefix}phyinfo_npi`).val(phyinfo_npi);

      const phyinfo_email = helper.removeWhiteSpaceTrim($(`#${covid_prefix}phyinfo_email`).val());
      $(`#${covid_prefix}phyinfo_email`).val(phyinfo_email);

      const phyinfo_address = helper.removeWhiteSpaceTrim($(`#${covid_prefix}phyinfoC_address`).val());
      $(`#${covid_prefix}phyinfoC_address`).val(phyinfo_address);

      const phyinfo_practiceLocation = helper.removeWhiteSpaceTrim($(`#${covid_prefix}phyinfoC_practiceLocation`).val());
      $(`#${covid_prefix}phyinfoC_practiceLocation`).val(phyinfo_practiceLocation);

      const phyinfo_city = helper.removeWhiteSpaceTrim($(`#${covid_prefix}phyinfoC_city`).val());
      $(`#${covid_prefix}phyinfoC_city`).val(phyinfo_city);

      // const phyinfo_state = helper.removeWhiteSpaceTrim($(`#${covid_prefix}phyinfoC_state`).val());
      // $(`#${covid_prefix}phyinfoC_state`).val(phyinfo_state);

      const phyinfo_zipCode = helper.removeWhiteSpaceTrim($(`#${covid_prefix}phyinfoC_zipCode`).val());
      $(`#${covid_prefix}phyinfoC_zipCode`).val(phyinfo_zipCode);

      const ph_areacode = helper.removeWhiteSpaceTrim($(`#${covid_prefix}phyinfoC_ph_areacode`).val());
      $(`#${covid_prefix}phyinfoC_ph_areacode`).val(ph_areacode);

      const ph_prefix = helper.removeWhiteSpaceTrim($(`#${covid_prefix}phyinfoC_ph_prefix`).val());
      $(`#${covid_prefix}phyinfoC_ph_prefix`).val(ph_prefix);

      const ph_suffix = helper.removeWhiteSpaceTrim($(`#${covid_prefix}phyinfoC_ph_suffix`).val());
      $(`#${covid_prefix}phyinfoC_ph_suffix`).val(ph_suffix);

      const phyinfo_fax = helper.removeWhiteSpaceTrim($(`#${covid_prefix}phyinfoC_fax`).val());
      $(`#${covid_prefix}phyinfoC_fax`).val(phyinfo_fax);

      const phyinfo_Faxcopyto = helper.removeWhiteSpaceTrim($(`#${covid_prefix}phyinfoC_Faxcopyto`).val());
      $(`#${covid_prefix}phyinfoC_Faxcopyto`).val(phyinfo_Faxcopyto);

      const phyinfo_confirmation = helper.removeWhiteSpaceTrim($(`#${covid_prefix}phyinfoC_confirmation`).val());
      $(`#${covid_prefix}phyinfoC_confirmation`).val(phyinfo_confirmation);

      const phyinfo_no_npi = helper.removeWhiteSpaceTrim($(`#${covid_prefix}phyinfo_no_npi`).val());
      $(`#${covid_prefix}phyinfo_no_npi`).val(phyinfo_no_npi);

      if (phyinfo_first_name.length < 1) {

        setToastMessage(`Physician name is required field.`);
        showToast(`danger`);

        $(`#${covid_prefix}phyinfo_first_name`).focus();
      }
      else if (phyinfo_npi.length < 1) {

        setToastMessage(`Physician npi is required field.`);
        showToast(`danger`);

        $(`#${covid_prefix}phyinfo_npi`).focus();
      }
      else if (phyinfo_email.length < 1) {

        setToastMessage(`Physician email address is required field.`);
        showToast(`danger`);

        $(`#${covid_prefix}phyinfo_email`).focus();
      }
      else if (!helper.isEmail(phyinfo_email)) {
        setToastMessage(`Please enter a valid email address.`);
        showToast(`danger`);

        $(`#${covid_prefix}phyinfo_email`).focus();

      } else if (phyinfo_no_npi === '1' && phyinfo_address.length < 1) {

        setToastMessage(`Physician address is required field.`);
        showToast(`danger`);
        $(`#${covid_prefix}phyinfoC_address`).focus();

      } else if (phyinfo_no_npi === '1' && phyinfo_practiceLocation.length < 1) {

        setToastMessage(`Physician Practice Location is required field.`);
        showToast(`danger`);
        $(`#${covid_prefix}phyinfoC_practiceLocation`).focus();

      } else if (phyinfo_no_npi === '1' && ph_areacode.length < 1) {

        setToastMessage(`Physician phone is required field.`);
        showToast(`danger`);
        $(`#${covid_prefix}phyinfoC_ph_areacode`).focus();

      } else if (phyinfo_no_npi === '1' && ph_prefix.length < 1) {

        setToastMessage(`Physician phone is required field.`);
        showToast(`danger`);
        $(`#${covid_prefix}phyinfoC_ph_prefix`).focus();

      } else if (phyinfo_no_npi === '1' && ph_suffix.length < 1) {

        setToastMessage(`Physician phone is required field.`);
        showToast(`danger`);
        $(`#${covid_prefix}phyinfoC_ph_suffix`).focus();

      } else {
        return true;
      }
    }
    //function for Physician Form Validation end

    //function for Patient Form Validation
    function checkValidityPatientForm() {

      const patinfo_firstname = helper.removeWhiteSpaceTrim($(`#${covid_prefix}patinfo_firstname`).val());
      $(`#${covid_prefix}patinfo_firstname`).val(patinfo_firstname);

      const patinfo_lastname = helper.removeWhiteSpaceTrim($(`#${covid_prefix}patinfo_lastname`).val());
      $(`#${covid_prefix}patinfo_lastname`).val();

      const patinfo_address = helper.removeWhiteSpaceTrim($(`#${covid_prefix}patinfo_address`).val());
      $(`#${covid_prefix}patinfo_address`).val();

      const patinfo_city = helper.removeWhiteSpaceTrim($(`#${covid_prefix}patinfo_city`).val());
      $(`#${covid_prefix}patinfo_city`).val();

      const patinfo_zipcode = helper.removeWhiteSpaceTrim($(`#${covid_prefix}patinfo_zipcode`).val());
      $(`#${covid_prefix}patinfo_zipcode`).val(patinfo_zipcode);

      const ova1_patinfo_ph_areacode = helper.removeWhiteSpaceTrim($(`#${covid_prefix}patinfo_ph_areacode`).val());
      $(`#${covid_prefix}patinfo_ph_areacode`).val(ova1_patinfo_ph_areacode);

      const ova1_patinfo_ph_prefix = helper.removeWhiteSpaceTrim($(`#${covid_prefix}patinfo_ph_prefix`).val());
      $(`#${covid_prefix}patinfo_ph_prefix`).val(ova1_patinfo_ph_prefix);

      const ova1_patinfo_ph_suffix = helper.removeWhiteSpaceTrim($(`#${covid_prefix}patinfo_ph_suffix`).val());
      $(`#${covid_prefix}patinfo_ph_suffix`).val(ova1_patinfo_ph_suffix);

      const patinfo_emailaddress = helper.removeWhiteSpaceTrim($(`#${covid_prefix}patinfo_emailaddress`).val());
      $(`#${covid_prefix}patinfo_emailaddress`).val(patinfo_emailaddress);

      const patinfo_OtherEthnicitycity = helper.removeWhiteSpaceTrim($(`#${covid_prefix}patinfo_OtherEthnicitycity`).val());
      $(`#${covid_prefix}patinfo_OtherEthnicitycity`).val(patinfo_OtherEthnicitycity);

      if (patinfo_firstname.length < 1) {
        setToastMessage(`Patient first name is required.`);
        showToast(`danger`);
        $(`#${covid_prefix}patinfo_firstname`).focus();

      } else if (patinfo_lastname.length < 1) {
        setToastMessage(`Patient last name is required.`);
        showToast(`danger`);
        $(`#${covid_prefix}patinfo_lastname`).focus();

      } else if (patinfo_address.length < 1) {
        setToastMessage(`Patient address is required.`);
        showToast(`danger`);
        $(`#${covid_prefix}patinfo_address`).focus();

      } else if (patinfo_city.length < 1) {
        setToastMessage(`Patient city is required.`);
        showToast(`danger`);
        $(`#${covid_prefix}patinfo_city`).focus();

      } else if (patinfo_zipcode.length < 1) {
        setToastMessage(`Patient zipcode is required.`);
        showToast(`danger`);
        $(`#${covid_prefix}patinfo_zipcode`).focus();

      } else if (ova1_patinfo_ph_areacode.length < 1) {
        setToastMessage(`Patient phone is required.`);
        showToast(`danger`);
        $(`#${covid_prefix}patinfo_ph_areacode`).focus();

      } else if (ova1_patinfo_ph_prefix.length < 1) {
        setToastMessage(`Patient phone is required.`);
        showToast(`danger`);
        $(`#${covid_prefix}patinfo_ph_prefix`).focus();

      } else if (ova1_patinfo_ph_suffix.length < 1) {
        setToastMessage(`Patient phone is required.`);
        showToast(`danger`);
        $(`#${covid_prefix}patinfo_ph_suffix`).focus();

      } else if (patinfo_emailaddress.length < 1) {

        setToastMessage(`Patient email is required.`);
        showToast(`danger`);

        $(`#${covid_prefix}patinfo_emailaddress`).focus();
      }
      else if (!helper.isEmail(patinfo_emailaddress)) {
        setToastMessage(`Please enter a valid email address.`);
        showToast(`danger`);
        $(`#${covid_prefix}patinfo_emailaddress`).focus();

      } else if ($(`#${covid_prefix}patinfo_Caucasian`).is(":not(:checked)")
        && $(`#${covid_prefix}patinfo_AshkenaziJewish`).is(":not(:checked)")
        && $(`#${covid_prefix}patinfo_SephardicJewish`).is(":not(:checked)")
        && $(`#${covid_prefix}patinfo_Asian`).is(":not(:checked)")
        && $(`#${covid_prefix}patinfo_HIspanic`).is(":not(:checked)")
        && $(`#${covid_prefix}patinfo_NativeAmerican`).is(":not(:checked)")
        && $(`#${covid_prefix}patinfo_AfricanAmerican`).is(":not(:checked)")
        && $(`#${covid_prefix}patinfo_Other`).is(":not(:checked)")) {

        setToastMessage(`Please select patient ethnicity.`);
        showToast(`danger`);

      } else if (!($(`#${covid_prefix}patinfo_Other`).is(":not(:checked)")) && (patinfo_OtherEthnicitycity.length < 1)) {

        setToastMessage(`Please fill up all required fields.`);
        showToast(`danger`);
        $(`#${covid_prefix}patinfo_OtherEthnicitycity`).focus();

      } else {
        return true;
      }

    }
    //function for Patient Form Validation end

    //function for Billing Form Validation
    function checkValidityBillingForm() {
      let isGood = true;

      const billinfo_PrimaryInsuranceCarrier = helper.removeWhiteSpaceTrim($(`#${covid_prefix}billinfo_PrimaryInsuranceCarrier`).val());
      $(`#${covid_prefix}billinfo_PrimaryInsuranceCarrier`).val(billinfo_PrimaryInsuranceCarrier);

      const billinfo_planId = helper.removeWhiteSpaceTrim($(`#${covid_prefix}billinfo_planId`).val());
      $(`#${covid_prefix}billinfo_planId`).val(billinfo_planId);

      const billinfo_MemberID = helper.removeWhiteSpaceTrim($(`#${covid_prefix}billinfo_MemberID`).val());
      $(`#${covid_prefix}billinfo_MemberID`).val(billinfo_MemberID);

      const billinfo_GroupID = helper.removeWhiteSpaceTrim($(`#${covid_prefix}billinfo_GroupID`).val());
      $(`#${covid_prefix}billinfo_GroupID`).val(billinfo_GroupID);

      const billinfo_SecondaryInsuranceCarrier = helper.removeWhiteSpaceTrim($(`#${covid_prefix}billinfo_SecondaryInsuranceCarrier`).val());
      $(`#${covid_prefix}billinfo_SecondaryInsuranceCarrier`).val(billinfo_SecondaryInsuranceCarrier);

      const SecondaryplanId = helper.removeWhiteSpaceTrim($(`#${covid_prefix}SecondaryplanId`).val());
      $(`#${covid_prefix}SecondaryplanId`).val(SecondaryplanId);

      const billinfo_SecondaryMemberID = helper.removeWhiteSpaceTrim($(`#${covid_prefix}billinfo_SecondaryMemberID`).val());
      $(`#${covid_prefix}billinfo_SecondaryMemberID`).val(billinfo_SecondaryMemberID);

      const billinfo_SecondaryGroupID = helper.removeWhiteSpaceTrim($(`#${covid_prefix}billinfo_SecondaryGroupID`).val());
      $(`#${covid_prefix}billinfo_SecondaryGroupID`).val(billinfo_SecondaryGroupID);

      const billinfo_NameofInsured = helper.removeWhiteSpaceTrim($(`#${covid_prefix}billinfo_NameofInsured`).val());
      $(`#${covid_prefix}billinfo_NameofInsured`).val(billinfo_NameofInsured);

      const billinfo_RelationshipInsured = helper.removeWhiteSpaceTrim($(`#${covid_prefix}billinfo_RelationshipInsured`).val());
      $(`#${covid_prefix}billinfo_RelationshipInsured`).val(billinfo_RelationshipInsured);

      if ($(`#${covid_prefix}billinfo_PrivateInsurance`).is(":not(:checked)")
        && $(`#${covid_prefix}billinfo_Medicare`).is(":not(:checked)")
        && $(`#${covid_prefix}billinfo_PatientSelfPay`).is(":not(:checked)")
        && $(`#${covid_prefix}billinfo_Medicaid`).is(":not(:checked)")
        && $(`#${covid_prefix}billinfo_OrderingFacility`).is(":not(:checked)")) {

        setToastMessage(`Please select Billing Information.`);
        showToast(`danger`);
        isGood = false;

      } else if( $(`#${covid_prefix}billinfo_PrivateInsurance`).is(":checked") || $(`#${covid_prefix}billinfo_Medicare`).is(":checked") || $(`#${covid_prefix}billinfo_Medicaid`).is(":checked")){

        if (billinfo_PrimaryInsuranceCarrier.length < 1) {

          setToastMessage(`Primary Insurance Carrier is required field.`);
          showToast(`danger`);

          $(`#${covid_prefix}billinfo_PrimaryInsuranceCarrier`).focus();
          isGood = false;
        } else if(billinfo_planId.length < 1) {

          setToastMessage(`Primary Insurance Carrier Plan Id is required field.`);
          showToast(`danger`);

          $(`#${covid_prefix}billinfo_planId`).focus();
          isGood = false;
        } else if(billinfo_MemberID.length < 1) {

          setToastMessage(`Primary Insurance Carrier Member Id is required field.`);
          showToast(`danger`);

          $(`#${covid_prefix}billinfo_MemberID`).focus();
          isGood = false;
        } else if(billinfo_GroupID.length < 1) {

          setToastMessage(`Primary Insurance Carrier Group Id is required field.`);
          showToast(`danger`);

          $(`#${covid_prefix}billinfo_GroupID`).focus();
          isGood = false;
        }
        else if (billinfo_NameofInsured.length < 1) {
          setToastMessage(`Name of Insured is required field.`);
          showToast(`danger`);

          $(`#${covid_prefix}billinfo_NameofInsured`).focus();

          isGood = false;
        }
        else if (billinfo_RelationshipInsured.length < 1) {

          setToastMessage(`Relationship to insured is required field.`);
          showToast(`danger`);

          $(`#${covid_prefix}billinfo_RelationshipInsured`).focus();

          isGood = false;
        }

      }
      else {
        isGood = true;
      }   

      return isGood;
      
    }
    //function for Billing Form Validation end

    //function for DiagnosisCodes_ICD10CodesAntibodyForm Form Validation
    function checkValidityDiagnosisICD10CodesForm() {

      const OtherICD10Codes = helper.removeWhiteSpaceTrim($(`#${covid_prefix}OtherICD10Codes`).val());
      $(`#${covid_prefix}OtherICD10Codes`).val(OtherICD10Codes);

      if ($(`#${covid_prefix}diagnosis_z1159`).is(":not(:checked)")
        && $(`#${covid_prefix}diagnosis_z03818`).is(":not(:checked)")) {

        setToastMessage(`Please select Diagnosis Codes | ICD-10 Codes.`);
        showToast(`danger`);

      } else {
        return true;
      }
    }
    //function for DiagnosisCodes_ICD10CodesAntibodyForm Form Validation end

    //function for Kit Shipping Form Validation 
    function checkValidityKitShippingForm() {

      const kitShip4_PatOthersInfo = helper.removeWhiteSpaceTrim($(`#${covid_prefix}kitShip_PatOthers`).val());
      $(`#${covid_prefix}kitShip_PatOthers`).val(kitShip4_PatOthersInfo);

      if ($(`${covid_prefix}kitShip_PatMobPhlebotomy`).is(":not(:checked)")
        && $(`${covid_prefix}kitShip_PatblooddrawQuest`).is(":not(:checked)")
        && $(`${covid_prefix}kitShip_PatOthers`).is(":not(:checked)")) {

        setToastMessage(`Please select Kit shipping options.`);
        showToast(`danger`);

      } else if ($(`${covid_prefix}kitShip_PatOthers`).is(":checked") && (kitShip4_PatOthersInfo.length < 1)) {

        setToastMessage(`Kit shipping other options is required field.`);
        showToast(`danger`);
        $(`#${covid_prefix}kitShip_PatOthers`).focus();

      }
      else {
        return true;
      }
    }

    //function for Kit Shipping Form Validation end

    //// Notes & Certify Validation 
    function checkValidityNotesCertifyForm() {

      const kitShip_notes = helper.removeWhiteSpaceTrim($('#kitShip4_notes').val());
      $('#kitShip4_notes').val(kitShip_notes);

      if ($("#kitShip4_healthcareProvider").is(":not(:checked)")
        && $("#kitShip4_employeehealthcareProvider").is(":not(:checked)")
        && $("#kitShip4_partnerhealthcareProvider").is(":not(:checked)")) {

        setToastMessage(`Please select certify options.`);
        showToast(`danger`);

      } else {
        return true;
      }
    }
    // Notes & Certify Validation 

    //PhysicianInformationAntibodyForm
    $(`#${covid_prefix}btnPhysicianInformationFormNext`).on('click', function (event) {
      event.preventDefault();

      if (checkValidityPhysicianForm()) { //if true
        $('div#PatientInformationAntibodyForm').addClass(' show')
        $('div#PhysicianInformationAntibodyForm').removeClass(' show')
        $('#PhysicianInformationAntibodyFormCheckMark').removeClass('d-none');
      }

    });

    //PatientInformationAntibodyForm
    $(`#${covid_prefix}btnPatientInformationFormNext`).on('click', function (event) {
      event.preventDefault();

      if (checkValidityPatientForm()) { //if true
        $('div#BillingInformationAntibodyForm').addClass(' show')
        $('div#PatientInformationAntibodyForm').removeClass(' show')
        $('#PatientInformationAntibodyFormCheckMark').removeClass('d-none');
      }

    });

    //BillingInformationAntibodyForm  
    $(`#${covid_prefix}btnBillingInformationFormNext`).on('click', function (event) {
      event.preventDefault();

      if (checkValidityBillingForm()) { //if true

        $('div#DiagnosisCodes_ICD10CodesAntibodyForm').addClass(' show')
        $('div#BillingInformationAntibodyForm').removeClass(' show')
        $('#BillingInformationAntibodyFormCheckMark').removeClass('d-none');
      }

    })

    //DiagnosisCodes_ICD10CodesAntibodyForm
    $(`#${covid_prefix}btnDiagnosisCodes_ICD10CodesFormNext`).on('click', function (event) {

      if (checkValidityDiagnosisICD10CodesForm()) { //if true

        $('#KitShippingInfoAntibodyForm').addClass(' show')
        $('#DiagnosisCodes_ICD10CodesAntibodyForm').removeClass(' show')
        $('#DiagnosisCodes_ICD10CodesAntibodyFormCheckMark').removeClass('d-none');

      }

    });

    $(`#${covid_prefix}btnKitShippingInfoFormNext`).on('click', function (event) {
      event.preventDefault();

      if (checkValidityKitShippingForm()) {

        $('div#NotesCertifyAntibodyForm').addClass(' show')
        $('div#KitShippingInfoAntibodyForm').removeClass(' show')
        $('#KitShippingInfoAntibodyFormCheckMark').removeClass('d-none');

      }

    });

    // btnCovidFormSubmit Covid-19 form Submit
    $(`#${covid_prefix}btnOrderSubmit`).off('click').on('click', async function () {

      var $this = $(this);
      $this.html($this.data('loading-text'));

      if (!checkValidityPhysicianForm()) {

        $('div#NotesCertifyAntibodyForm').removeClass(' show')
        $('div#PhysicianInformationAntibodyForm').addClass(' show')

      } else if (!checkValidityPatientForm()) {

        $('div#NotesCertifyAntibodyForm').removeClass(' show')
        $('div#PatientInformationAntibodyForm').addClass(' show')

      } else if (!checkValidityBillingForm()) {

        $('div#NotesCertifyAntibodyForm').removeClass(' show')
        $('div#BillingInformationAntibodyForm').addClass(' show')

      } else if (!checkValidityDiagnosisICD10CodesForm()) {

        $('div#NotesCertifyAntibodyForm').removeClass(' show')
        $('div#DiagnosisCodes_ICD10CodesAntibodyForm').addClass(' show')

      } else if (!checkValidityKitShippingForm()) {

        $('div#NotesCertifyAntibodyForm').removeClass(' show')
        $('div#KitShippingInfoAntibodyForm').addClass(' show')

      } else if (!checkValidityNotesCertifyForm()) {

        $('div#NotesCertifyAntibodyForm').addClass(' show')
        $('div#KitShippingInfoAntibodyForm').removeClass(' show')
        $('#KitShippingInfoAntibodyFormCheckMark').removeClass('d-none');

      } else { //order submision start 

        const phyinfo_Organization_account = ($(`#${covid_prefix}phyinfo_Organization_account`).val());
        const phyinfo_first_name = ($(`#${covid_prefix}phyinfo_first_name`).val());
        const phyinfo_last_name = ($(`#${covid_prefix}phyinfo_last_name`).val());
        const phyinfo_npi = ($(`#${covid_prefix}phyinfo_npi`).val());
        const phyinfo_email = ($(`#${covid_prefix}phyinfo_email`).val());
        const phyinfo_address = ($(`#${covid_prefix}phyinfoC_address`).val());
        const phyinfo_phone = $(`#${covid_prefix}phyinfoC_ph_areacode`).val() + ' ' + $(`#${covid_prefix}phyinfoC_ph_prefix`).val() + $(`#${covid_prefix}phyinfoC_ph_suffix`).val()
        const phyinfo_fax = ($(`#${covid_prefix}phyinfoC_fax`).val());
        const phyinfo_Faxcopyto = ($(`#${covid_prefix}phyinfoC_Faxcopyto`).val());
        const phyinfo_confirmation = ($(`#${covid_prefix}phyinfoC_confirmation`).val());
        const phyinfo_practiceLocation = ($(`#${covid_prefix}phyinfoC_practiceLocation`).val());

        const addresses = [];
        const practiceLocation = [];

        const licenses = [];

        console.log("physicianAddresses: ", physicianAddresses)
        if (typeof physicianAddresses == "undefined" || physicianAddresses.length < 1) {
          return;
        }

        physicianAddresses.map((a) => {
          addresses.push({
            locationName: "USA",
            address_1: a.address_1,
            address_2: a.address_1,
            addressType: a.address_type,
            city: a.city,
            country: a.country_name,
            state: a.state,
            zipCode: a.postal_code,
            phoneNo: a.telephone_number,
          });
        })

        let data = {
          organizationAccount: phyinfo_Organization_account,
          firstName: phyinfo_first_name,
          lastName: phyinfo_last_name,
          npiNumber: phyinfo_npi,
          email: phyinfo_email,
          addresses: addresses,
          practiceLocation: {
            locationName: "USA",
            address_1: selectedPhysicianPracticeLocation.address_1,
            address_2: selectedPhysicianPracticeLocation.address_1,
            addressType: selectedPhysicianPracticeLocation.address_type,
            city: selectedPhysicianPracticeLocation.city,
            country: selectedPhysicianPracticeLocation.country_name,
            state: selectedPhysicianPracticeLocation.state,
            zipCode: selectedPhysicianPracticeLocation.postal_code,
            phoneNo: selectedPhysicianPracticeLocation.telephone_number,
          },
          licenses: licenses,
          registeredUser: "",
        }

        // console.log("physicianAddresses: ", physicianAddresses);
        console.log("data: ", data);


        let physician = await createPhysicianApi(data);
        console.log("physician: ", physician);

        if (physician.isok) {
          $this.html('Done');
          setToastMessage(`Physician Created Successfully. Creating Patient`);
          showToast(`success`);


          const patinfo_firstname = $(`#${covid_prefix}patinfo_firstname`).val();
          const patinfo_lastname = $(`#${covid_prefix}patinfo_lastname`).val();
          const patinfo_address = $(`#${covid_prefix}patinfo_address`).val();
          const patinfo_city = $(`#${covid_prefix}patinfo_city`).val();
          const patinfo_state = $(`#${covid_prefix}patinfo_state`).val();
          const patinfo_zipcode = $(`#${covid_prefix}patinfo_zipcode`).val();
          const patinfo_PhoneNumber = $(`#${covid_prefix}patinfo_ph_areacode`).val() + $(`#${covid_prefix}patinfo_ph_prefix`).val() + $(`#${covid_prefix}patinfo_ph_suffix`).val()
          const patinfo_emailaddress = $(`#${covid_prefix}patinfo_emailaddress`).val();
          const patinfo_DOB = patientDOBLocal;

          const ethnicity = [];

          if ($(`#${covid_prefix}patinfo_Caucasian`).is(":checked")) {
            ethnicity.push($(`#${covid_prefix}patinfo_Caucasian`).data('val'));
          }
          if ($(`#${covid_prefix}patinfo_AshkenaziJewish`).is(":checked")) {
            ethnicity.push($(`#${covid_prefix}patinfo_AshkenaziJewish`).data('val'));
          }
          if ($(`#${covid_prefix}patinfo_SephardicJewish`).is(":checked")) {
            ethnicity.push($(`#${covid_prefix}patinfo_SephardicJewish`).data('val'));
          }
          if ($(`#${covid_prefix}patinfo_Asian`).is(":checked")) {
            ethnicity.push($(`#${covid_prefix}patinfo_Asian`).data('val'));
          }
          if ($(`#${covid_prefix}patinfo_HIspanic`).is(":checked")) {
            ethnicity.push($(`#${covid_prefix}patinfo_HIspanic`).data('val'));
          }
          if ($(`#${covid_prefix}patinfo_NativeAmerican`).is(":checked")) {
            ethnicity.push($(`#${covid_prefix}patinfo_NativeAmerican`).data('val'));
          }
          if ($(`#${covid_prefix}patinfo_AfricanAmerican`).is(":checked")) {
            ethnicity.push($(`#${covid_prefix}patinfo_AfricanAmerican`).data('val'));
          }
          if ($(`#${covid_prefix}patinfo_Other`).is(":checked")) {
            ethnicity.push($(`#${covid_prefix}patinfo_Other`).data('val'));
            ethnicity.push($(`#${covid_prefix}patinfo_OtherEthnicitycity`).val());
          }

          data = {
            email: patinfo_emailaddress,
            firstName: patinfo_firstname,
            lastName: patinfo_lastname,
            address: {
              locationName: "USA",
              address_1: patinfo_address,
              address_2: "",
              addressType: "",
              city: patinfo_city,
              country: "USA",
              state: patientState,
              zipCode: patinfo_zipcode,
            },
            dob: patinfo_DOB,
            phoneNo: patinfo_PhoneNumber,

            ethnicity: ethnicity.join(","),
            registeredUser: "",

            gender: "",

          }

          console.log("Before Submit data: ", data);

          $this.html("<img style='height: 30px;' src='/assets/images/Spinner-1s-200px.svg' /> Creating Patient")


          let patient = await createPatientApi(data);
          console.log("patient: ", patient);

          if (patient.isok) {

            let billing_info = {
              primaryInsurance: {},
              secondaryInsurance: {},
              insuredInfo: {}
            };

            var sections = $("div#BillingInformationAntibodyForm #billing_info_container").find(":input");

            sections.map((input, i) => {
              const $input = $(sections[input]);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));
              console.log("$input: ", $input);

              let _id = $input.attr('id');

              if (typeof _id == 'undefined' || _id.length < 1) {
                _id = `${covid_prefix}billinfo_DOB`;
              }
              const _type = $input.attr('type');
              let obj_id = _id.replace(`${covid_prefix}billinfo_`, "");
              obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)
              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  billing_info[obj_id] = true;
                }
                else {
                  billing_info[obj_id] = false;
                }
              }
              else {
                billing_info[obj_id] = $input.val();
              }
            });

            sections = $("div#BillingInformationAntibodyForm #primary_insurance_container").find(":input");
            sections.map((input, i) => {
              const $input = $(sections[input]);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));
              console.log("$input: ", $input);

              let _id = $input.attr('id');

              if (typeof _id == 'undefined' || _id.length < 1) {
                _id = `${covid_prefix}billinfo_DOB`;
              }
              const _type = $input.attr('type');
              let obj_id = _id.replace(`${covid_prefix}billinfo_`, "");
              obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)
              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  billing_info['primaryInsurance'][obj_id] = true;
                }
                else {
                  billing_info['primaryInsurance'][obj_id] = false;
                }
              }
              else {
                billing_info['primaryInsurance'][obj_id] = $input.val();
              }

            });

            sections = $("div#BillingInformationAntibodyForm #secondary_insurance_container").find(":input");
            sections.map((input, i) => {
              const $input = $(sections[input]);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));
              console.log("$input: ", $input);

              let _id = $input.attr('id');

              if (typeof _id == 'undefined' || _id.length < 1) {
                _id = `${covid_prefix}billinfo_DOB`;
              }
              const _type = $input.attr('type');
              let obj_id = _id.replace(`${covid_prefix}billinfo_`, "");
              obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)
              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  billing_info['secondaryInsurance'][obj_id] = true;
                }
                else {
                  billing_info['secondaryInsurance'][obj_id] = false;
                }
              }
              else {
                billing_info['secondaryInsurance'][obj_id] = $input.val();
              }

            });

            sections = $("div#BillingInformationAntibodyForm #insured_info").find(":input");
            sections.map((input, i) => {
              const $input = $(sections[input]);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));
              console.log("$input: ", $input);

              let _id = $input.attr('id');

              if (typeof _id == 'undefined' || _id.length < 1) {
                _id = `${covid_prefix}billinfo_DOB`;
              }
              const _type = $input.attr('type');
              let obj_id = _id.replace(`${covid_prefix}billinfo_`, "");
              obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)
              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  billing_info['insuredInfo'][obj_id] = true;
                }
                else {
                  billing_info['insuredInfo'][obj_id] = false;
                }
              }
              else {
                billing_info['insuredInfo'][obj_id] = $input.val();
              }

            });
            console.log("billing_info: ", billing_info);

            let qnA = {
              questionCode: "",
              answer: "",
              subQuestion: []
            };

            let clinicalData = [];

            const icd10Codes = [];
            const icd10CodesOther = [];
            let icd10Code = {
              'codes': [],
              'otherCodes': [],
            };

            sections = $("div#DiagnosisCodes_ICD10CodesAntibodyForm").find(":input");
            sections.map((i, input) => {
              const $input = $(input);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));

              // let obj_id = $input.attr('id');

              const _type = $input.attr('type');
              // obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)

              if (_type == 'checkbox') {
                if ($input.is(':checked')) {
                  icd10Codes.push($input.data('val'));
                }
              }
              else if (_type == 'text') {
                icd10CodesOther.push($input.val());
              }

            });

            icd10Code = {
              'codes': icd10Codes,
              'otherCodes': icd10CodesOther,
            }


            let ova1PlusBiomarkers = {
              GCP: [],
              MCP: []
            };

            let kitInformation = {
              drawType: [],
              otherInfo: "",
            };


            sections = $("div#KitShippingInfoAntibodyForm #draw_type").find(":input");
            sections.map((input, i) => {
              const $input = $(sections[input]);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));

              let obj_id = $input.attr('id');

              const _type = $input.attr('type');
              // obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)

              if (_type == 'checkbox') {
                if ($input.is(':checked')) {

                  kitInformation.drawType.push($input.data('val'));

                }
              }
            });
            kitInformation.otherInfo = $(`#${covid_prefix}kitShip_PatOthersInfo`).val();

            let notes = $(`#NotesCertifyAntibodyForm #${covid_prefix}kitShip_notes`).val();

            let certifyTypes = [];

            sections = $(`#NotesCertifyAntibodyForm #${covid_prefix}certifytype`).find(":input");
            sections.map((i, input) => {
              const $input = $(input);
              console.log("$input.attr('id'): ", $input.attr('id'), ", $input.attr('type'): ", $input.attr('type'));

              let obj_id = $input.attr('id');

              const _type = $input.attr('type');
              // obj_id = obj_id.charAt(0).toLowerCase() + obj_id.slice(1)

              if (_type == 'checkbox') {
                if ($input.is(':checked')) {

                  certifyTypes.push($input.data('val'));

                }
              }
            });

            var tests = [
              {
                testName: "COVD",
                testCode: "COVD",
                icd10Code: icd10Code,
                kitInformation: kitInformation,
                notes: notes,
                ova1PlusBiomarkers: ova1PlusBiomarkers,
                clinicalData: clinicalData
              }
            ];


            data = {
              orderId: Math.random(),
              patientId: patient.patient._id.toString(),
              physicianId: physician.physician._id.toString(),
              billingInfo: billing_info,
              tests: tests,
              certifyTypes: certifyTypes,
            }


            let ret = await createOva1OrderApi(data);
            if (ret.isok) {
              $this.html('Done');
              setToastMessage(`Order Created Successfully.`);
              showToast(`success`);
            }

          } //end of if  patient.isok

        }
        //end if physician.isok

      } //order submision end     

    });
    // btnCovidFormSubmit Covid-19 form Submit end

  });


  const onChangeState = (_state) => {
    console.log("genetixHBOC->_state: ", _state);
    setPatientState(_state);
  }
  const patientDOBFunc = (_dob) => {
    setPatientDOBLocal(_dob);
  };


  return (
    <>
      <div class="body">
        <input type="hidden" id={`${covid_prefix}billinginfo_DOB`} class="form-control required" placeholder="DOB" />
        <div class="row clearfix">
          <div class="col-md-12 col-lg-12">
            <div class="panel-group" id="accordion_105" role="tablist" aria-multiselectable="true">
              <Toast
                toastList={toastList}
                position={"top-center"}
                autoDelete={true}
                dismissTime={3000}
                description={toastMessage}

              />
              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingThirtyEight_10">
                  <h4 class="panel-title">
                    <a id="physicianInfoToggle" role="button" data-toggle="collapse" data-parent="#accordion_105" href="#PhysicianInformationAntibodyForm" aria-expanded="true" aria-controls="PhysicianInformationAntibodyForm">
                      Physician Information <span id="PhysicianInformationAntibodyFormCheckMark" className="d-none">&#10004;</span>
                    </a>
                  </h4>
                </div>
                <div id="PhysicianInformationAntibodyForm" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingThirtyEight_10">
                  <PhysicianInformationForm prefix={`${covid_prefix}`} />
                </div>
              </div>

              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingThirtyNine_10">
                  <h4 class="panel-title">
                    <a id="patientInfoToggle" class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_105" href="#PatientInformationAntibodyForm" aria-expanded="false" aria-controls="PatientInformationAntibodyForm">
                      Patient Information <span id="PatientInformationAntibodyFormCheckMark" className="d-none">&#10004;</span>
                    </a>
                  </h4>
                </div>
                <div id="PatientInformationAntibodyForm" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingThirtyNine_10">
                  {/* <PatientInformationAntibodyForm /> */}
                  <PatientInformationForm prefix={`${covid_prefix}`} phoneNoPrefix={`${covid_prefix}`} dobFunc={patientDOBFunc} onChangeState={onChangeState} />
                </div>
              </div>

              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingFourteen_10">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_105" href="#BillingInformationAntibodyForm" aria-expanded="false" aria-controls="BillingInformationAntibodyForm">
                      Billing Information <span id="BillingInformationAntibodyFormCheckMark" className="d-none">&#10004;</span>
                    </a>
                  </h4>
                </div>
                <div id="BillingInformationAntibodyForm" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFourteen_10">
                  <BillingInformationForm prefix={`${covid_prefix}`} />
                </div>
              </div>

              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingFourtyThree_10">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_10" href="#DiagnosisCodes_ICD10CodesAntibodyForm" aria-expanded="false" aria-controls="collapseTwo_10">
                      Diagnosis Codes | ICD-10 Codes <span id="DiagnosisCodes_ICD10CodesAntibodyFormCheckMark" className="d-none">&#10004;</span>
                    </a>
                  </h4>
                </div>
                <div id="DiagnosisCodes_ICD10CodesAntibodyForm" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFourtyThree_10">
                  {/* <DiagnosisCodes_ICD10CodesCOVDForm prefix={`${covid_prefix}`} /> */}
                  <DiagnosisCodes_ICD10CodesForm prefix={`${covid_prefix}`} />
                </div>
              </div>


              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingFourtyTwo_10">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_105" href="#KitShippingInfoAntibodyForm" aria-expanded="false" aria-controls="KitShippingInfoAntibodyForm">
                      Kit Shipping Information <span id="KitShippingInfoAntibodyFormCheckMark" className="d-none">&#10004;</span>
                    </a>
                  </h4>
                </div>
                <div id="KitShippingInfoAntibodyForm" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFourtyTwo_10">
                  {/* <KitShippingInfoAntibodyForm /> */}
                  <KitShippingInfoForm prefix={covid_prefix} />
                </div>
              </div>

              <div class="panel panel-col-blue-grey">
                <div class="panel-heading" role="tab" id="headingFourtyOne_10">
                  <h4 class="panel-title">
                    <a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion_105" href="#NotesCertifyAntibodyForm" aria-expanded="false" aria-controls="NotesCertifyAntibodyForm">
                      Notes & Certify
                      </a>
                  </h4>
                </div>
                <div id="NotesCertifyAntibodyForm" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingFourtyOne_10">
                  {/* <NotesCertifyAntibodyForm /> */}
                  <NotesCertifyForm prefix={`${covid_prefix}`} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

}

export default AntiSARSCoV19AntibodyForm;

