import React, { PureComponent } from "react";
import axios from "axios";
import Highcharts from 'highcharts';
import drilldown from "highcharts/modules/drilldown";
import HighchartsReact from 'highcharts-react-official';
import helper from "./../utils/helper";
import { protectedResources } from "./../authConfig";
import { MsalContext } from "@azure/msal-react";

const API_BASE_URL = helper.getAPIBaseURI();

drilldown(Highcharts);

export default class OrdersBreakDownPieChartByCategory extends PureComponent {
	static contextType = MsalContext;

	constructor(props) {
		super(props);

		this.state = {
			chartOptions: {
				chart: { type: "pie" },
				title: { text: undefined },
				accessibility: {
					announceNewData: { enabled: true },
					point: { valueSuffix: "%" },
				},
				plotOptions: {
					series: {
						dataLabels: { enabled: true, format: '{point.name}: {point.y:.1f}%', }
					}
				},
				tooltip: {
					headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
					pointFormat: '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>'
				},
				credits: { enabled: false },
			}
		}
	}

	async componentDidMount() {
		const accessToken = await helper.getAccessToken(this.context)

		const { data } = await axios({
			url: `${API_BASE_URL}/orders/ordersBreakDownByCategories`,
			method: "GET",
			headers: {
				"Content-type": "Application/json",
				Authorization: `Bearer ${accessToken}`,
			},
		});

		console.log("\n====");
		console.log("data: ", data);
		console.log("====\n");

		this.prepareAndSetOrdersBreakDownPieChartDataForDisplay(data.ordersBreakDownByCategories);
	}

	getTotalOrdersCount = (ordersBreakDownByCategoriesArr) => {
		return ordersBreakDownByCategoriesArr.reduce((currentTotal, currentOrderCategory) => {
			return currentTotal += currentOrderCategory.totalOrdersInThisCategory;
		}, 0);
	}

	getOrderPercentageFromTotalOrders = (currentOrderCount, totalOrdersCount) => {
		return (100 / totalOrdersCount) * currentOrderCount;
	}

	getSeriesDrillDownObjForCategory = (orderCategory, totalOrdersCount) => {
		const { _id: categoryName, ordersBreakDown } = orderCategory;
		const seriesDrillDownObj = {
			name: categoryName,
			id: categoryName,
			data: [],
		};

		for (let subCategoryBreakDown of ordersBreakDown) {
			const { subCategory: subCategoryName, totalOrdersInThisSubCategory } = subCategoryBreakDown;
			const drillDownDataArrElement = [
				subCategoryName,
				this.getOrderPercentageFromTotalOrders(totalOrdersInThisSubCategory, totalOrdersCount),
			];

			seriesDrillDownObj.data.push(drillDownDataArrElement);
		}

		return seriesDrillDownObj;
	}

	prepareAndSetOrdersBreakDownPieChartDataForDisplay = (ordersBreakDownByCategoriesArr) => {
		const series = [
			{
				name: "General Categories",
				colorByPoint: true,
				data: [],
			}
		];
		const drilldown = { series: [] };

		const totalOrdersCount = this.getTotalOrdersCount(ordersBreakDownByCategoriesArr);

		for (let orderCategory of ordersBreakDownByCategoriesArr) {
			const { _id: categoryName, totalOrdersInThisCategory } = orderCategory;
			const seriesDataObjForChart = {
				name: categoryName,
				y: this.getOrderPercentageFromTotalOrders(totalOrdersInThisCategory, totalOrdersCount),
				drilldown: categoryName,
			};
			series[0].data.push(seriesDataObjForChart);

			const seriesDrillDownObjOfCurrentOrderCategory = this.getSeriesDrillDownObjForCategory(orderCategory, totalOrdersCount);
			drilldown.series.push(seriesDrillDownObjOfCurrentOrderCategory);
		}

		this.setState({ chartOptions: { series, drilldown } });
	}

	// helper method for rendering
	renderChartTitle = () => {
		return (
			<>
				<div className="row graph-title">
					<strong>Order Stats By Category</strong>
				</div>
				<div className="row graph-options-container">
					<p>Click on individual parts to see detail breakdown of that category</p>
				</div>
			</>
		);
	}

	render() {
		return (
			<div style={{ width: "100%", height: "100%" }} >
				{this.renderChartTitle()}
				<HighchartsReact highcharts={Highcharts} options={this.state.chartOptions} />
			</div>
		);
	}
}
