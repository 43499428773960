import helper from "./../../utils/helperPortal";
import axios from "axios";

const url_base = helper.getAPIBaseURI();

export const CREATE_PATIENTS = 'CREATE_PATIENTS'
export const CREATE_PATIENTS_SUCCESS = 'CREATE_PATIENTS_SUCCESS'
export const CREATE_PATIENTS_FAILURE = 'CREATE_PATIENTS_FAILURE'

export const PATIENTS_PRIMARY_ADDRESS = 'PATIENTS_PRIMARY_ADDRESS'

export const createPatients = () => ({ type: CREATE_PATIENTS })
export const createPatientsSuccess = patients => ({
    type: CREATE_PATIENTS_SUCCESS,
    payload: patients,
})
export const createPatientsFailure = () => ({ type: CREATE_PATIENTS_FAILURE })

export async function createPatientApi(data) {
    // return async dispatch => {
    //     dispatch(createPatients())

    try {
        let opt = {
            url: `${url_base}/patients`,
            method: "POST",
            data: data,
            headers: {
                Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFsYW1yZXpvYW51bEBnbWFpbC5jb20iLCJpYXQiOjE1OTIzODQ0NjR9.Vlqn6clmjUMKFdtMd2NOxrJbu8n0PpPSoH_eGXVkSEM`
            }

        };
        console.log("creating patient with url: ", opt);
        let ret = await axios(opt);
        console.log("ret.data: ", ret.data);


        let retdata = ret.data;

        retdata.patient.isok = true;
        return retdata;
        // dispatch(createPatientsSuccess(retdata));
    } catch (error) {
        return error;
        // dispatch(createPatientsFailure());
    }
}

export async function createOva1OrderApi(data) {
    // return async dispatch => {
    //     dispatch(createPatients())

    try {
        let opt = {
            url: `${url_base}/orders`,
            method: "POST",
            data: data,
            headers: {
                Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFsYW1yZXpvYW51bEBnbWFpbC5jb20iLCJpYXQiOjE1OTIzODQ0NjR9.Vlqn6clmjUMKFdtMd2NOxrJbu8n0PpPSoH_eGXVkSEM`
            }

        };
        console.log("creating order with url: ", opt);
        let ret = await axios(opt);
        console.log("ret.data: ", ret.data);


        const retdata = ret.data;

        return retdata;
        // dispatch(createPatientsSuccess(retdata));
    } catch (error) {
        return error;
        // dispatch(createPatientsFailure());
    }
}
// }
// export function fetchPatients() {
//   return async dispatch => {
//     dispatch(createPatients())

//     try {
//       const response = await fetch('https://jsonplaceholder.typicode.com/patients')
//       const data = await response.json()

//       dispatch(createPatientsSuccess(data))
//     } catch (error) {
//       dispatch(createPatientsFailure())
//     }
//   }
// }
