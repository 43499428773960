import React from "react";
import moment from 'moment';
import { useLocation, Link } from 'react-router-dom';


export default class BillingNotes extends React.PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            user: null,
        };

        // this.location = useLocation();
    }

    componentDidMount() {

    }

    getLocalTime(utcTime) {
        const date = new Date(utcTime);
        console.log('utc time: ', utcTime);
        const localTime = date.toLocaleString();
        return moment(localTime).format('MM/DD/YYYY h:mm a');
    }

    render() {

        return (
            <div id="billing-notes" class="billing-notes">
                <div class="row clearfix">
                    <div class="col-md-12">
                        <h4 class="panel-titlepdf">Billing Notes</h4>
                    </div>
                </div>
                <div class="row clearfix">
                    <div class="col-md-12">
                        <div class="form-group">
                            {this.props.order.billingInfo.notes && this.props.order.billingInfo.notes.map((n, i) => {
                                return <p>{i + 1}. {`Note: ${n.note}, Posted by: ${n.noteBy}, Date Time: ${this.getLocalTime(n.noteTime)}`}</p>
                            })

                            }
                        </div>
                    </div>
                </div>

                <div class="row clearfix">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label class="form-label" for={`billing_notes`}>
                                Add Your Note:
                    </label>
                            <div class="form-line">
                                <textarea
                                    id={`billing_notes`}
                                    class="form-control no-resize auto-growth"
                                    placeholder="Billing Notes"
                                    rows="3"
                                    onChange={(event) => {
                                        console.log("event: ", event);
                                        this.props.takeNotes(event.target.value);
                                    }}
                                ></textarea>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}