import React, { PureComponent } from "react";
import { withRouter } from "react-router-dom";

import helper from "./../utils/helper";
import axios from "axios";
import Select from "react-select";

import { message } from "antd";
import $, { error } from 'jquery';
import { protectedResources } from "./../authConfig";
import { MsalContext } from "@azure/msal-react";

const API_BASE_URL = helper.getAPIBaseURI();
class CmtForm extends PureComponent {
  static contextType = MsalContext;

  constructor(props) {
    super(props);

    this.state = {
      user: null,
      allUsers: [],
      selectedUser: {},
      selectedPayor: {},
      allPayors: [],

      toastMessage: "",
      list: [],
      toastList: [],

    };

    // this.location = useLocation();
    this.authorizationNumber = React.createRef();
  }
  showToast(type) {
    const id = Math.floor(Math.random() * 101 + 1);

    switch (type) {
      case "success":
        this.toastProperties = {
          id,
          title: "Success",
          description: "This is a success toast component",
          backgroundColor: "#5cb85c",
          icon: "/assets/assets/check.svg",
        };
        break;
      case "danger":
        this.toastProperties = {
          id,
          title: "Danger",
          description: "This is a error toast component",
          backgroundColor: "#d9534f",
          icon: "/assets/assets/error.svg",
        };
        break;
      case "info":
        this.toastProperties = {
          id,
          title: "Info",
          description: "This is an info toast component",
          backgroundColor: "#5bc0de",
          icon: "/assets/assets/info.svg",
        };
        break;
      case "warning":
        this.toastProperties = {
          id,
          title: "Warning",
          description: "This is a warning toast component",
          backgroundColor: "#f0ad4e",
          icon: "/assets/assets/warning.svg",
        };
        break;

      default:
        this.setState({ toastList: [] });
    }

    // this.setState([...this.state.list, this.toastProperties]);
    this.setState({ list: [this.toastProperties] });
  }
  async btnHandlePriorauthorization() {
    $('#btnNeedsPreauthorization').attr('disabled', true);
    message.loading("Submitting order to CMT for authorization", 3);

    
    const accessToken = await helper.getAccessToken(this.context)

    const urlParams = new URLSearchParams(window.location.search);
    const _id = urlParams.get("id");
    console.log("order id: ", _id);

    let _url = `${API_BASE_URL}/orders/priorauthcmt`;
    let _options = {
      url: _url,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        order_id: _id,
        need_priorauth: true,
      },
    };

    try {
      const response = await axios(_options);
      console.log("CmtForm->response.data: ", response.data);


      await this.props.handleSaveFunc();

      if (!response.data.status) {
        throw new error(response.data.message);
      }
      else {
        message.success("Order successfully submitted to CMT", 2, () => {
          if (process.env.REACT_APP_ENV !== 'DEV') {
            const returnurl = urlParams.get("returnurl");
            window.location.href = returnurl;
          }
        });
      }
    }
    catch (error) {
      console.log("error: ", error);
      message.error(`CMT update status-${error.message}`);
    }
  }

  async btnHandleDoesntPreauthorization() {
    
    const accessToken = await helper.getAccessToken(this.context)

    const urlParams = new URLSearchParams(window.location.search);
    const _id = urlParams.get("id");
    console.log("order id: ", _id);

    let _url = `${API_BASE_URL}/orders/priorauthcmt`;
    let _options = {
      url: _url,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        order_id: _id,
        need_priorauth: false,

      },
    };
    const response = await axios(_options);
    console.log("CmtForm->response.data: ", response.data);

    // await this.props.takeNotesFunc();
    await this.props.handleSaveFunc();

    if (process.env.REACT_APP_ENV !== 'DEV') {
      const returnurl = urlParams.get("returnurl");
      window.location.href = returnurl;
    }
  }
  async btnHandleInternalAuthorization() {
    
    const accessToken = await helper.getAccessToken(this.context)

    const urlParams = new URLSearchParams(window.location.search);
    const _id = urlParams.get("id");
    console.log("order id: ", _id);

    let _url = `${API_BASE_URL}/orders/priorauthcmt`;
    let _options = {
      url: _url,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        order_id: _id,
        need_priorauth: false,
        internal: true,
      },
    };
    const response = await axios(_options);
    console.log("CmtForm->response.data: ", response.data);

    // await this.props.takeNotesFunc();
    await this.props.handleSaveFunc();

    if (process.env.REACT_APP_ENV !== 'DEV') {
      const returnurl = urlParams.get("returnurl");
      window.location.href = returnurl;
    }
  }

  async btnSaveAuthorizationNumberHandler() {
    
    const accessToken = await helper.getAccessToken(this.context)
    const urlParams = new URLSearchParams(window.location.search);
    const _id = urlParams.get("id");
    console.log("order id: ", _id);

    let _url = `${API_BASE_URL}/orders/priorauthcmt`;
    let _options = {
      url: _url,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        order_id: _id,
        need_priorauth: false,
        authorizationNumber: this.authorizationNumber.value,
      },
    };
    const response = await axios(_options);
    console.log("CmtForm->response.data: ", response.data);

    // await this.props.takeNotesFunc();
    await this.props.handleSaveFunc();

    if (process.env.REACT_APP_ENV !== 'DEV') {
      const returnurl = urlParams.get("returnurl");
      window.location.href = returnurl;
    }
  }
  async btnHandleAssignUser() {

    if (this.state.selectedUser.length < 1) {
      message.warn(`Must Select a Benefits Reviewer`);
      return;
    }

    
    const accessToken = await helper.getAccessToken(this.context)

    const urlParams = new URLSearchParams(window.location.search);
    const _id = urlParams.get("id");
    console.log("order id: ", _id);

    // try to assign payorId to this order
    let _url = `${API_BASE_URL}/orders/${_id}/assignPayorId`;
    let _options = {
      url: _url,
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        payorId: this.state.selectedPayor.value,
      }
    };
    // let response = await axios(_options);
    // console.log("CmtForm->btnHandleAssignuser()->payorId response.data: ", response.data);

    // try to assign reviewer to this order
    _url = `${API_BASE_URL}/users/assignreviewer`;
    _options = {
      url: _url,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        orderId: _id,
        userId: this.state.selectedUser.value,
      },
    };

    try {
      let response = await axios(_options);
      console.log("CmtForm->btnHandleAssignUser()->assignreviewer response.data: ", response.data);

      this.setState({
        toastMessage: `Successfully assigned to ${this.state.selectedUser.label}`,
      }, async () => {
        // this.showToast("success");
        message.success(this.state.toastMessage);

        // await this.props.takeNotesFunc();
        await this.props.handleSaveFunc();

        if (process.env.REACT_APP_ENV !== 'DEV') {
          const returnurl = urlParams.get("returnurl");
          window.location.href = returnurl;
        }
      });
    }
    catch (error) {
      message.error(error);
    }
  }

  async componentDidMount() {
    try {
      
    const accessToken = await helper.getAccessToken(this.context)

      let _url = `${API_BASE_URL}/users`;
      let _options = {
        url: _url,
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
        data: {},
      };
      let response = await axios(_options);
      console.log("CmtForm->componentDidMount()->response.data: ", response.data);
      let _allUsers = [];
      let _billingAssigned = "";
      response.data.users.map((u, i) => {
        let fullName = `${u.firstName} ${u.lastName}`;

        if (!_allUsers.some((u) => u.label === fullName)) {
          _allUsers.push({
            label: fullName, value: u._id
          });
        }
        if (this.props.order.billingAssigned && this.props.order.billingAssigned == u._id) {
          _billingAssigned = {
            label: fullName, value: u._id
          }
        }
      })

      this.setState({
        allUsers: _allUsers,
        selectedUser: _billingAssigned,
        // allPayors,
        // selectedPayor: assignedPayor,
      });
    }
    catch (error) {
      console.log("Error on retrieving users: ", error);
    }
  }

  getDefaultPayorIdOptions = () => {
    return this.state.allPayors.slice(0, 20);
  }

  getPayorIdOptionsBySearchText = async (searchText) => {
    let payorIdOptions = this.state.allPayors.filter(payor =>
      payor.label.toLowerCase().includes(searchText.toLowerCase())
    );

    // if the payorIdOptions array contains many elements then the Select element becomes laggy
    // so I'm cutting down the array size if there are too many elements - Khairul
    const numberOfOptions = payorIdOptions.length;

    console.log("\n===");
    console.log("original options count: ", numberOfOptions);

    if (numberOfOptions > 100) {
      payorIdOptions = payorIdOptions.slice(0, 99);
    }

    console.log("Options count after trimming: ", payorIdOptions.length);
    console.log("===\n");

    return payorIdOptions;
  }


  renderAssignUserAndPayorSection() {
    return <>
      <div className="row clearfix">
        <div class="col-sm-12 col-xs-12">
          <div class="col-sm-4 col-xs-12">
            <div class="form-group drop-custum">
              <label class="color-green" htmlFor="role">Select an User to Assign as BENEFITS REVIEWER:</label><small className="color-red">*</small>
              <Select
                value={this.state.selectedUser}
                defaultValue={""}
                label="Select an User:"
                options={this.state.allUsers}
                styles={helper.colourStyles}
                onChange={(val) => this.setState({ selectedUser: val })}
              />

            </div>
          </div>
          {/* <div class="col-sm-4 col-xs-12">
            <div class="form-group drop-custum">
              <label class="color-green" htmlFor="role">Select a Payor:</label><small className="color-red">*</small>
              <AsyncSelect
                value={this.state.selectedPayor}
                defaultValue={""}
                label="Select an Payor:"
                cacheOptions
                defaultOptions={this.getDefaultPayorIdOptions()}
                loadOptions={this.getPayorIdOptionsBySearchText}
                styles={helper.colourStyles}
                onChange={(val) => this.setState({ selectedPayor: val })}
              />

            </div>
          </div> */}
          <div class="col-sm-4 col-xs-12">
            <div class="form-group drop-custum">
              <button
                id={`btnAssignUser`}
                placeholder="Update Billing & Assign User"
                name="Submit"
                className="btn btn-primary text-white"
                onClick={this.btnHandleAssignUser.bind(this)}
              >
                Update Billing & Assign User
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  }

  renderPriorAuthButtons() {
    // medicaid: false
    // medicare: false
    // orderingFacility: false
    // patientSelfPay: false
    // privateInsurance: true

    console.log("CmtForm()->this.props.order: ", this.props.order);
    if (this.props.order.status == "INTERNAL AUTHORIZATION") {
      return <>
        <div class="row clearfix">
          <div class="col-md-4">

            <div class="form-group">
              <label class="form-label" for={`inpAuthNumber`}>
                Internal Authorization Number:
              </label>
              <div className="form-line">
                <input
                  ref={(_ref) => this.authorizationNumber = _ref}
                  id={`inpAuthNumber`}
                  placeholder="Internal Authorization Number"
                  className="form-control"
                  value={this.props.order.internalAuthorizationNumber}
                />
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <button
                id={`btnSaveAuthorizationNumber`}
                name="Submit"
                className="btn btn-primary text-white"
                onClick={this.btnSaveAuthorizationNumberHandler.bind(this)}
              >
                Save & Send to Billing READY
              </button>
            </div>
          </div>
        </div>
      </>
    }
    else if (
      this.props.order.status === "BILLING REVIEW"
      // || this.props.order.status == "WAITING CMT AUTH"
      // || this.props.order.status == "WAITING BILLING"
      // || this.props.order.status == "CS_HOLD"
    ) {
      return this.renderAssignUserAndPayorSection();
    }
    else if (this.props.order.status == "Benefits Review"
      || this.props.order.status == "BENEFITS REVIEW"
    ) {
      return (
        <>
          <div class="row clearfix">
            <div class="col-md-4">
              <div class="form-group">
                <button
                  id={`btnDoesntNeedsPreauthorization`}
                  placeholder="Doesn't Needs Prior authorization"
                  name="Submit"
                  className="btn btn-primary text-white"
                  onClick={this.btnHandleDoesntPreauthorization.bind(this)}
                >
                  Doesn't Needs Authorization
                </button>
              </div>
            </div>
            <div class="col-md-4">

              <div class="form-group">
                <button
                  id={`btnNeedsPreauthorization`}
                  placeholder="Needs Prior authorization"
                  name="Submit"
                  className="btn btn-primary text-white"
                  onClick={this.btnHandlePriorauthorization.bind(this)}
                >
                  Needs CMT Prior Authorization
                </button>
              </div>
            </div>
            <div class="col-md-4">
              <div class="form-group">
                <button
                  id={`btnNeedsInternalauthorization`}
                  placeholder="Needs Internal authorization"
                  name="Submit"
                  className="btn btn-primary text-white"
                  onClick={this.btnHandleInternalAuthorization.bind(this)}
                >
                  Needs Internal Authorization
                </button>
              </div>
            </div>
          </div>

          {this.renderAssignUserAndPayorSection()}

        </>
      );
    }
  }
  render() {
    return (
      <>
        {this.renderPriorAuthButtons()}

        {

          this.props.order.status == "WAITING CMT AUTH" && (
            <>
              <div class="row clearfix">
                <div class="col-md-12">
                  <h5 class="panel-titlepdf">CMT Information</h5>
                </div>
              </div>

              <div class="row clearfix">
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`Status`}>
                      Submitted to CMT Successfully.
                    </label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`CaseId`}>
                      CaseId: {(this.props.order && this.props.order.CMT && this.props.order.CMT.payload) ?
                        this.props.order.CMT.payload.caseId : ""}
                    </label>
                  </div>
                </div>
                {/* <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`Status`}>
                      Status: {this.props.order.CMT.Status}
                    </label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`assignee`}>
                      Assignee: {this.props.order.CMT.assignee}
                    </label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`labId`}>
                      Lab Id: {this.props.order.CMT.labId}
                    </label>
                  </div>
                </div>
              </div>
              <div class="row clearfix">
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`labClinicId`}>
                      Lab Clinic Id: {this.props.order.CMT.labClinicId}
                    </label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`submitted`}>
                      Submitted: {this.props.order.CMT.submitted}
                    </label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`confirmationCode`}>
                      Confirmation Code: {this.props.order.CMT.confirmationCode}
                    </label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`confirmationDate`}>
                      Confirmation Date:{" "}
                      {helper.formatDateReturnStr(
                        this.props.order.CMT.confirmationDate
                      )}
                    </label>
                  </div>
                </div>
              </div>

              <div class="row clearfix">
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`authorizationCode`}>
                      Authorization Code: {this.props.order.CMT.authorizationCode}
                    </label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`authorizationEffectiveDate`}>
                      Authorization Effective Date:{" "}
                      {helper.formatDateReturnStr(
                        this.props.order.CMT.authorizationEffectiveDate
                      )}
                    </label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`authorizationExpirationDate`}>
                      Authorization Expiration Date:{" "}
                      {helper.formatDateReturnStr(
                        this.props.order.CMT.authorizationExpirationDate
                      )}
                    </label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`authorizationDate`}>
                      Authorization Date:{" "}
                      {helper.formatDateReturnStr(
                        this.props.order.CMT.authorizationDate
                      )}
                    </label>
                  </div>
                </div>
              </div>

              <div class="row clearfix">
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`authorizedCptCodes`}>
                      Authorized CptCodes:{" "}
                      {this.props.order.CMT.authorizedCptCodes}
                    </label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`denialReason`}>
                      Denial Reason: {this.props.order.CMT.denialReason}
                    </label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`denialCodes`}>
                      Denial Codes: {this.props.order.CMT.denialCodes}
                    </label>
                  </div>
                </div> */}
              </div>
            </>
          )}
        {this.props.order.status == "BILLING READY" && this.props.order.internalAuthorizationNumber && <>
          <div class="row clearfix">
            <div class="col-md-12">
              <h5>Internal Authorization Number</h5>
              <div class="form-group ">
                <label class="form-label" for={`CaseId`}>
                  {this.props.order.internalAuthorizationNumber}
                </label>
              </div>
            </div>
          </div>
        </>
        }
        {this.props.order.status == "BILLING READY" &&
          this.props.order.CMT && this.props.order.CMT.priorAuthResult &&
          (
            <>
              <div class="row clearfix">
                <div class="col-md-12">
                  <h5>CMT Information</h5>
                </div>
              </div>

              <div class="row clearfix">
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`CaseId`}>
                      CaseId: {this.props.order.CMT.priorAuthResult.caseId}
                    </label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`Status`}>
                      Status: {this.props.order.CMT.priorAuthResult.Status}
                    </label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`assignee`}>
                      Assignee: {this.props.order.CMT.priorAuthResult.assignee}
                    </label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`labId`}>
                      Lab Id: {this.props.order.CMT.priorAuthResult.labId}
                    </label>
                  </div>
                </div>
              </div>
              <div class="row clearfix">
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`labClinicId`}>
                      Lab Clinic Id: {this.props.order.CMT.priorAuthResult.labClinicId}
                    </label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`submitted`}>
                      Submitted: {this.props.order.CMT.priorAuthResult.submitted}
                    </label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`confirmationCode`}>
                      Confirmation Code: {this.props.order.CMT.priorAuthResult.confirmationCode}
                    </label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`confirmationDate`}>
                      Confirmation Date:{" "}
                      {helper.formatDateReturnStr(
                        this.props.order.CMT.priorAuthResult.confirmationDate
                      )}
                    </label>
                  </div>
                </div>
              </div>

              <div class="row clearfix">
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`authorizationCode`}>
                      Authorization Code: {this.props.order.CMT.priorAuthResult.authorizationCode}
                    </label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`authorizationEffectiveDate`}>
                      Authorization Effective Date:{" "}
                      {helper.formatDateReturnStr(
                        this.props.order.CMT.priorAuthResult.authorizationEffectiveDate
                      )}
                    </label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`authorizationExpirationDate`}>
                      Authorization Expiration Date:{" "}
                      {helper.formatDateReturnStr(
                        this.props.order.CMT.priorAuthResult.authorizationExpirationDate
                      )}
                    </label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`authorizationDate`}>
                      Authorization Date:{" "}
                      {helper.formatDateReturnStr(
                        this.props.order.CMT.priorAuthResult.authorizationDate
                      )}
                    </label>
                  </div>
                </div>
              </div>

              <div class="row clearfix">
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`authorizedCptCodes`}>
                      Authorized CptCodes:{" "}
                      {this.props.order.CMT.priorAuthResult.authorizedCptCodes}
                    </label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`denialReason`}>
                      Denial Reason: {this.props.order.CMT.priorAuthResult.denialReason}
                    </label>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group ">
                    <label class="form-label" for={`denialCodes`}>
                      Denial Codes: {this.props.order.CMT.priorAuthResult.denialCodes}
                    </label>
                  </div>
                </div>
              </div>
            </>
          )}
      </>
    );
  }
}
export default withRouter(CmtForm);
