import React, { PureComponent } from "react";
import { BoxLoading } from 'react-loadingg';
import axios from "axios";

import PropTypes from 'prop-types';
import { Redirect, withRouter } from "react-router-dom";
import helper from './utils/helper';

//require("dotenv").config();

const API_BASE_URL = helper.getAPIBaseURI();
var me = null;
class VerifyPatient extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            dangerText: "",
        }

        this.success_verifyPatient = React.createRef();
        this.tempPassword = React.createRef();
        this.newPassword = React.createRef();

        this.handleVerify = this.handleVerify.bind(this);

        me = this;
    }

    componentDidMount() {
        axios.interceptors.response.use(function (response) {
            // Do something with response data
            return response;
        }, function (error) {
            // console.log("error: ", error);

            switch (error.response.status) {
                case 502:
                    // props.history.push('/503') //we will redirect user into 503 page 
                    console.log("interceptors->error: ", error);

                    me.setState({
                        loading: false,
                        dangerText: `Probably the link expired. OR Invalid ID found.`,
                    })
                    break
                case 503:
                    console.log("interceptors->error: ", error);
                    me.setState({
                        loading: false,
                        dangerText: `Temporary Password doesn't match.`,
                    })
                    break;
                default:
                    break
            }
            me.danger_verifyFailed.click();
            // Do something with response error
            return Promise.reject(error);
        });

    }
    handleVerify() {
        console.log("this.props: ", this.props);
        let patient_id = this.props.match.params.patientID;
        let code = this.props.match.params.code;



        let tempPassword = this.tempPassword.value;
        let newPassword = this.newPassword.value;

        this.setState({ loading: true }, () => {
            axios({
                url: `${API_BASE_URL}/patients/verify`,
                method: "POST",
                headers: {
                    "Content-type": "Application/json",
                },
                data: {
                    id: patient_id,
                    code: code,
                    tempPassword: tempPassword,
                    newPassword: newPassword,
                }
            })
                .then(({ data }) => {
                    console.log("data: ", data);
                    // setPatients(data.patients);

                    localStorage.setItem("user", JSON.stringify({
                        token: data.token,
                        email: data.patient.email,
                        firstName: data.patient.firstName,
                        lastName: data.patient.lastName,
                        isAdmin: data.patient.isAdmin,
                    }));
                    window.location.href = '/orders';
                });
        });
    }
    render() {

        if (this.state.loading) {
            return <BoxLoading />;
        }
        return (
            <div class="authentication" id="authentication">

                <div class="container-fluid">
                    <div class="row clearfix">
                        <div class="col-xl-9 col-lg-8 col-md-12 p-l-0 blackybg">
                            <div class="l-detail">
                                <h5 class="position">Welcome</h5>
                                {/* <h1 class="position">Aspira Admin Portal</h1> */}
                                <h3 class="position">Sign in to verify your identity</h3>


                            </div>
                        </div>

                        <div class="col-xl-3 col-lg-4 col-md-12 p-r-0">

                            <div class="card position">
                                {/* <h4 class="l-login">Login</h4> */}
                                {/* <form class="col-md-12" id="sign_in" method="POST"> */}

                                {/* <div class="form-group form-float">
                                        <div class="form-line">
                                            <label htmlFor="useremail" class="color-green">Email:</label>
                                            <input id="useremail" ref={(un) => this.useremail = un} type="text" name="useremail" class="form-control" />
                                        </div>
                                    </div> */}
                                <div class="form-group form-float">
                                    <div class="form-line">
                                        <label htmlFor="userpassword" class="color-green">Temp Password:</label>
                                        <input id="tempPassword" autoSave={false} ref={(up) => this.tempPassword = up} type="password" name="tempPassword" class="form-control" />

                                    </div>
                                </div>
                                <div class="form-group form-float">
                                    <div class="form-line">
                                        <label htmlFor="newPassword" class="color-green">New Password:</label>
                                        <input id="newPassword" autoSave={false} ref={(up) => this.newPassword = up} type="password" name="newPassword" class="form-control" />

                                    </div>
                                </div>

                                <button class="btn btn-raised g-bg-blue waves-effect" onClick={this.handleVerify}>Verify</button> &nbsp; &nbsp;

                                </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'none' }} class="col-lg-12 col-md-12 col-sm-12">
                    <div class="body">
                        <div class="row clearfix jsdemo-notification-button">
                            <button ref={(_success_verifyPatient) => { this.success_verifyPatient = _success_verifyPatient }} type="button" class="btn btn-raised btn-success btn-block waves-effect" data-placement-from="top" data-placement-align="center"
                                data-message-text="Successfully verified Patient." data-animate-enter="" data-animate-exit="" data-color-name="alert-success"> SUCCESS </button>
                        </div>
                    </div>
                </div>

                <div style={{ display: 'none' }} class="col-lg-12 col-md-12 col-sm-12">
                    <div class="body">
                        <div class="row clearfix jsdemo-notification-button">
                            <button ref={(_danger_verifyFailed) => { this.danger_verifyFailed = _danger_verifyFailed }} type="button" class="btn btn-raised btn-danger btn-block waves-effect" data-placement-from="top" data-placement-align="right"
                                data-message-text={this.state.dangerText} data-animate-enter="" data-animate-exit="" data-color-name="alert-danger"> DANGER </button>
                        </div>
                    </div>
                </div>
            </div>


        );
    }
}
export default withRouter(VerifyPatient);
