import React, { PureComponent } from "react";

import PropTypes from 'prop-types';
import $ from 'jquery';
//import DatePicker from 'react-datepicker';
import helper from './../utils/helperPortal';

const DiagnosisCodes_ICD10CodesForm = ({ prefix }) => {
    $(document).ready(() => {

        const icdCodesChangeFunc = () => {
            // if ($(this).is(':checked')) {

            const ova1_checked = $('#product_1').is(':checked');
            const gtcs_checked = $('#product_2').is(':checked');
            const hboc_checked = $('#product_3').is(':checked');
            const covid_checked = $('#product_4').is(':checked');

            console.log("ova1_checked: ", ova1_checked, ", covid_checked: ", covid_checked);
            if (ova1_checked && hboc_checked && covid_checked) {
                $(`#${prefix}DiagICD10Container #ova1_icd_codes`).show();
                $(`#${prefix}DiagICD10Container #covid_icd_codes`).show();
                $(`#${prefix}DiagICD10Container #gtcs_icd_codes`).hide();
                $(`#${prefix}DiagICD10Container #hboc_icd_codes`).show();
            }
            else if (hboc_checked && covid_checked) {
                $(`#${prefix}DiagICD10Container #hboc_icd_codes`).show();
                $(`#${prefix}DiagICD10Container #covid_icd_codes`).show();

                $(`#${prefix}DiagICD10Container #ova1_icd_codes`).hide();
                $(`#${prefix}DiagICD10Container #gtcs_icd_codes`).hide();

            }
            else if (ova1_checked && hboc_checked) {
                $(`#${prefix}DiagICD10Container #ova1_icd_codes`).show();
                $(`#${prefix}DiagICD10Container #covid_icd_codes`).hide();
                $(`#${prefix}DiagICD10Container #gtcs_icd_codes`).hide();
                $(`#${prefix}DiagICD10Container #hboc_icd_codes`).show();
            }
            else if (ova1_checked && covid_checked) {
                $(`#${prefix}DiagICD10Container #ova1_icd_codes`).show();
                $(`#${prefix}DiagICD10Container #covid_icd_codes`).show();
                $(`#${prefix}DiagICD10Container #gtcs_icd_codes`).hide();
                $(`#${prefix}DiagICD10Container #hboc_icd_codes`).hide();
            }
            else if (gtcs_checked && covid_checked) {
                $(`#${prefix}DiagICD10Container #gtcs_icd_codes`).show();
                $(`#${prefix}DiagICD10Container #covid_icd_codes`).show();
                $(`#${prefix}DiagICD10Container #ova1_icd_codes`).hide();
                $(`#${prefix}DiagICD10Container #hboc_icd_codes`).hide();
            }
            else if (ova1_checked) {
                $(`#${prefix}DiagICD10Container #ova1_icd_codes`).show();
                $(`#${prefix}DiagICD10Container #covid_icd_codes`).hide();
                $(`#${prefix}DiagICD10Container #gtcs_icd_codes`).hide();
                $(`#${prefix}DiagICD10Container #hboc_icd_codes`).hide();
            }

            else if (gtcs_checked) {
                $(`#${prefix}DiagICD10Container #gtcs_icd_codes`).show();
                $(`#${prefix}DiagICD10Container #covid_icd_codes`).hide();
                $(`#${prefix}DiagICD10Container #ova1_icd_codes`).hide();
                $(`#${prefix}DiagICD10Container #hboc_icd_codes`).hide();
            }
            else if (hboc_checked) {
                $(`#${prefix}DiagICD10Container #hboc_icd_codes`).show();

                $(`#${prefix}DiagICD10Container #covid_icd_codes`).hide();
                $(`#${prefix}DiagICD10Container #ova1_icd_codes`).hide();
                $(`#${prefix}DiagICD10Container #gtcs_icd_codes`).hide();
            }
            else if (covid_checked) {
                $(`#${prefix}DiagICD10Container #ova1_icd_codes`).hide();
                $(`#${prefix}DiagICD10Container #covid_icd_codes`).show();
                $(`#${prefix}DiagICD10Container #gtcs_icd_codes`).hide();
                $(`#${prefix}DiagICD10Container #hboc_icd_codes`).hide();
            }

        }

        $('#product_1').click(icdCodesChangeFunc);
        $('#product_2').click(icdCodesChangeFunc);
        $('#product_3').click(icdCodesChangeFunc);
        $('#product_4').click(icdCodesChangeFunc);
        icdCodesChangeFunc();

        $(`#${prefix}btnDiagICDCodesFormReset`).click(() => {
            $(`#${prefix}DiagICD10Container`).find(':input').map((i, elem) => {
                const _type = $(elem).attr('type');
                console.log("_type: ", _type);
                helper.resetForm(_type, elem);
            });

        });

        $(`#${prefix}diagnosisHBOC_search`).on("keyup", function () {


            // Retrieve the input field text and reset the count to zero
            var filter = $(this).val(),
                count = 0;

            // Loop through the comment list
            $('.demo-checkbox label').each(function () {


                // If the list item does not contain the text phrase fade it out
                if ($(this).text().search(new RegExp(filter, "i")) < 0) {
                    $(this).hide();

                    // Show the list item if the phrase matches and increase the count by 1
                } else {
                    $(this).show();
                    count++;
                }

            });

        });
    });

    return (
        <>
            <div id={`${prefix}DiagICD10Container`} class="panel-body">
                <div class="row clearfix">
                    <div class="col-md-6">
                    </div>
                    <div class="col-md-6">
                        <div class="form-group">
                            <div class="form-line">
                                <input type="text" id={`${prefix}diagnosisHBOC_search`} class="form-control" placeholder="Search..." />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row clearfix">
                    <div id={`ova1_icd_codes`} class="col-md-12">
                        <div class="form-group ">
                            <label class="form-label" for={`${prefix}DiagnosisCodes`}><b>{(prefix === 'ova1_') ? "OVA1 " : "COVD "} Diagnosis Codes | ICD-10 Codes (check all that apply)</b></label>
                            <div class="demo-checkbox">
                                <div class="row clearfix">
                                    <div class="col-md-4">
                                        <input type="checkbox" data-val="N83.201" id={`${prefix}diagnosis_UnOcyst_rs`} name={`${prefix}diagnosis_UnOcyst_rs`} class="filled-in" />
                                        <label for={`${prefix}diagnosis_UnOcyst_rs`}>N83.201 Unspecified ovarian cyst, right side+ &nbsp; </label>
                                    </div>
                                    <div class="col-md-4">
                                        <input type="checkbox" data-val="R19.00" id={`${prefix}diagnosis_IntraAbdPelSwell`} name={`${prefix}diagnosis_IntraAbdPelSwell`} class="filled-in" />
                                        <label for={`${prefix}diagnosis_IntraAbdPelSwell`}>R19.00 Intra-abdominal and pelvic swelling, mass and lump, unspecified site+ &nbsp; </label>
                                    </div>
                                    <div class="col-md-4">
                                        <input type="checkbox" data-val="R19.05" id={`${prefix}diagnosis_Periumbilicalswell`} name={`${prefix}diagnosis_Periumbilicalswell`} class="filled-in" />
                                        <label for={`${prefix}diagnosis_Periumbilicalswell`}>R19.05 Periumbilical swelling, mass and lump+ &nbsp; </label>
                                    </div>
                                </div>
                            </div>
                            <div class="demo-checkbox">
                                <div class="row clearfix">
                                    <div class="col-md-4">
                                        <input type="checkbox" data-val="N83.202" id={`${prefix}diagnosis_UnOcyst_ls`} name={`${prefix}diagnosis_UnOcyst_ls`} class="filled-in" />
                                        <label for={`${prefix}diagnosis_UnOcyst_ls`}>N83.202 Unspecified ovarian cyst, left side+ &nbsp; </label>
                                    </div>
                                    <div class="col-md-4">
                                        <input type="checkbox" data-val="R19.03" id={`${prefix}diagnosis_lowerquadrantSwell`} name={`${prefix}diagnosis_lowerquadrantSwell`} class="filled-in" />
                                        <label for={`${prefix}diagnosis_lowerquadrantSwell`}>R19.03 Right lower quadrant abdominal swelling, mass and lump &nbsp; </label>
                                    </div>
                                    <div class="col-md-4">
                                        <input type="checkbox" data-val="R19.09" id={`${prefix}diagnosis_intraabdominalpelvicswell`} name={`${prefix}diagnosis_intraabdominalpelvicswell`} class="filled-in" />
                                        <label for={`${prefix}diagnosis_intraabdominalpelvicswell`}>R19.09 Other intra-abdominal pelvic swelling, mass and lump+ &nbsp; </label>
                                    </div>
                                </div>
                            </div>
                            <div class="demo-checkbox">
                                <div class="row clearfix">
                                    <div class="col-md-4">
                                        <input type="checkbox" data-val="N83.209" id={`${prefix}diagnosis_UnOcyst`} name={`${prefix}diagnosis_UnOcyst`} class="filled-in" />
                                        <label for={`${prefix}diagnosis_UnOcyst`}>N83.209 Unspecified ovarian cyst, unspecified side+ &nbsp; </label>
                                    </div>
                                    <div class="col-md-4">
                                        <input type="checkbox" data-val="R19.04" id={`${prefix}diagnosis_LlQabdominalswelling`} name={`${prefix}diagnosis_LlQabdominalswelling`} class="filled-in" />
                                        <label for={`${prefix}diagnosis_LlQabdominalswelling`}>R19.04 Left lower quadrant abdominal swelling, mass and lump &nbsp; </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {prefix === 'ova1_covid_' && */}
                    <div id={`covid_icd_codes`} class="col-md-12">
                        <div class="form-group ">
                            <label class="form-label" for={`${prefix}DiagnosisCodes`}><b>{(prefix === 'ova1_') ? "OVA1 " : "COVD "} Diagnosis Codes | ICD-10 Codes (check all that apply)</b></label>
                            <div class="demo-checkbox">
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="Z11.59" id={`${prefix}diagnosis_z1159`} name={`${prefix}diagnosis_z1159`} class="filled-in" />
                                        <label for={`${prefix}diagnosis_z1159`}>Z11.59, Encounter for screening for other viral diseases &nbsp; </label>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="Z03.818" id={`${prefix}diagnosis_z03818`} name={`${prefix}diagnosis_z03818`} class="filled-in" />
                                        <label for={`${prefix}diagnosis_z03818`}>Z03.818, Encounter for observation for suspected exposure to other biological agents ruled out &nbsp; </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id={`gtcs_icd_codes`} class="col-md-12">
                        <div class="form-group ">
                            <label class="form-label" for="DiagnosisCodes"><b>GCS Diagnosis Codes | ICD-10 Codes (check all that apply)</b></label>
                            <div class="demo-checkbox">
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="Z13.71" id={`${prefix}diagnosisC_z1371EncNProGeDisCaSt`} name={`${prefix}diagnosisC_z1371EncNProGeDisCaSt`} class="filled-in" />
                                        <label for={`${prefix}diagnosisC_z1371EncNProGeDisCaSt`}>Z13.71 Encounter for nonprocreative screening for genetic disease carrier status &nbsp; </label>
                                    </div>                                
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="Z15.81" id={`${prefix}diagnosisC_GenSusMulEndocrineNeopl`} name={`${prefix}diagnosisC_GenSusMulEndocrineNeopl`} class="filled-in" />
                                        <label for={`${prefix}diagnosisC_GenSusMulEndocrineNeopl`}>Z15.81 Genetic susceptibility to multiple endocrine neoplasia [MEN] &nbsp; </label>
                                    </div>
                                </div>
                            </div>
                            <div class="demo-checkbox">
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="Z13.79" id={`${prefix}diagnosisC_z1379EncNProGeChroAnom`} name={`${prefix}diagnosisC_z1379EncNProGeChroAnom`} class="filled-in" />
                                        <label for={`${prefix}diagnosisC_z1379EncNProGeChroAnom`}>Z13.79 Encounter for other screening for genetic and chromosomal anomalies &nbsp; </label>
                                    </div>                                
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="Z15.89" id={`${prefix}diagnosisC_GenSusOtherDis`} name={`${prefix}diagnosisC_GenSusOtherDis`} class="filled-in" />
                                        <label for={`${prefix}diagnosisC_GenSusOtherDis`}>Z15.89 Genetic susceptibility to other disease &nbsp; </label>
                                    </div>
                                </div>
                            </div>
                            <div class="demo-checkbox">
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="Z14.1" id={`${prefix}diagnosisC_z141CysticFibrosisCa`} name={`${prefix}diagnosisC_z141CysticFibrosisCa`} class="filled-in" />
                                        <label for={`${prefix}diagnosisC_z141CysticFibrosisCa`}>Z14.1 Cystic fibrosis carrier &nbsp; </label>
                                    </div>                                
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="Z31.430" id={`${prefix}diagnosisC_z31430FeProcman`} name={`${prefix}diagnosisC_z31430FeProcman`} class="filled-in" />
                                        <label for={`${prefix}diagnosisC_z31430FeProcman`}>Z31.430 Encounter of female for testing for genetic disease carrier status for procreative management &nbsp; </label>
                                    </div>
                                </div>
                            </div>
                            <div class="demo-checkbox">
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="Z14.8" id={`${prefix}diagnosisC_z148Gencarotdis`} name={`${prefix}diagnosisC_z148Gencarotdis`} class="filled-in" />
                                        <label for={`${prefix}diagnosisC_z148Gencarotdis`}>Z14.8 Genetic carrier of other disease &nbsp; </label>
                                    </div>                                
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="Z31.440" id={`${prefix}diagnosisC_z31430MaProcman`} name={`${prefix}diagnosisC_z31430MaProcman`} class="filled-in" />
                                        <label for={`${prefix}diagnosisC_z31430MaProcman`}>Z31.440 Encounter of male for testing for genetic disease carrier status for procreative management &nbsp; </label>
                                    </div>
                                </div>
                            </div>
                            <div class="demo-checkbox">
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="Z15.01" id={`${prefix}diagnosisC_z1501`} name={`${prefix}diagnosisC_z1501`} class="filled-in" />
                                        <label for={`${prefix}diagnosisC_z1501`}>Z15.01 Genetic susceptibility to malignant neoplasm of breast &nbsp; </label>
                                    </div>                                
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="Z31.5" id={`${prefix}diagnosisC_z315`} name={`${prefix}diagnosisC_z315`} class="filled-in" />
                                        <label for={`${prefix}diagnosisC_z315`}>Z31.5 Encounter for procreative genetic counseling &nbsp; </label>
                                    </div>
                                </div>
                            </div>
                            <div class="demo-checkbox">
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="Z15.02" id={`${prefix}diagnosisC_z1502`} name={`${prefix}diagnosisC_z1502`} class="filled-in" />
                                        <label for={`${prefix}diagnosisC_z1502`}>Z15.02 Genetic susceptibility to malignant neoplasm of ovary &nbsp; </label>
                                    </div>                                
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="Z36.0" id={`${prefix}diagnosisC_z360`} name={`${prefix}diagnosisC_z360`} class="filled-in" />
                                        <label for={`${prefix}diagnosisC_z360`}>Z36.0 Encounter for antenatal screening for chromosomal anomalies &nbsp; </label>
                                    </div>
                                </div>
                            </div>
                            <div class="demo-checkbox">
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="Z15.03" id={`${prefix}diagnosisC_z1503`} name={`${prefix}diagnosisC_z1503`} class="filled-in" />
                                        <label for={`${prefix}diagnosisC_z1503`}>Z15.03 Genetic susceptibility to malignant neoplasm of prostate &nbsp; </label>
                                    </div>                                
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="Z36.81" id={`${prefix}diagnosisC_z3681`} name={`${prefix}diagnosisC_z3681`} class="filled-in" />
                                        <label for={`${prefix}diagnosisC_z3681`}>Z36.81 Encounter for antenatal screening for hydrops fetails &nbsp; </label>
                                    </div>
                                </div>
                            </div>
                            <div class="demo-checkbox">
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="Z15.04" id={`${prefix}diagnosisC_z1504`} name={`${prefix}diagnosisC_z1504`} class="filled-in" />
                                        <label for={`${prefix}diagnosisC_z1504`}>Z15.04 Genetic susceptibility to malignant neoplasm of endometrium &nbsp; </label>
                                    </div>                                
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="Z36.89" id={`${prefix}diagnosisC_z3689`} name={`${prefix}diagnosisC_z3689`} class="filled-in" />
                                        <label for={`${prefix}diagnosisC_z3689`}>Z36.89 Encounter for other specified antenatal screening &nbsp; </label>
                                    </div>
                                </div>
                            </div>
                            <div class="demo-checkbox">
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="Z15.09" id={`${prefix}diagnosisC_z1509`} name={`${prefix}diagnosisC_z1509`} class="filled-in" />
                                        <label for={`${prefix}diagnosisC_z1509`}>Z15.09 Genetic susceptibility to other malignant neoplasm &nbsp; </label>
                                    </div>                                
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="Z36.9" id={`${prefix}diagnosisC_z369`} name={`${prefix}diagnosisC_z369`} class="filled-in" />
                                        <label for={`${prefix}diagnosisC_z369`}>Z36.9 Encounter for antenatal screening, unspecified &nbsp; </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* } */}
                    <div id={`hboc_icd_codes`} class="col-md-12">
                        <div class="form-group ">

                            <div class="row clearfix">
                                <div class="col-md-12">
                                    <label class="form-label" for="DiagnosisCodes_GHBOCG"><b>GHBOCG Diagnosis Codes | ICD-10 Codes (check all that apply)</b></label>
                                </div>
                            </div>
                            <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_1">
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="Z15.01" id={`${prefix}hBOCdiagnosis_z1501`} name={`${prefix}hBOCdiagnosis_z1501`} class="filled-in" />
                                        <label for={`${prefix}hBOCdiagnosis_z1501`}>Z15.01 Genetic susceptibility to malignant neoplasm of breast &nbsp; </label>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="C48.1" id={`${prefix}hBOCdiagnosis_c481`} name={`${prefix}hBOCdiagnosis_c481`} class="filled-in" />
                                        <label for={`${prefix}hBOCdiagnosis_c481`}>C48.1 Malignant neoplasm of specified parts of peritoneum &nbsp; </label>
                                    </div>
                                </div>
                            </div>
                            <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_2">
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="Z15.02" id={`${prefix}hBOCdiagnosis_z1502`} name={`${prefix}hBOCdiagnosis_z1502`} class="filled-in" />
                                        <label for={`${prefix}hBOCdiagnosis_z1502`}>Z15.02 Genetic susceptibility to malignant neoplasm of ovary &nbsp; </label>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="C50.0" id={`${prefix}hBOCdiagnosis_c500`} name={`${prefix}hBOCdiagnosis_c500`} class="filled-in" />
                                        <label for={`${prefix}hBOCdiagnosis_c500`}>C50.0 Malignant neoplasm of breast [add anatomic site detail] &nbsp; </label>
                                    </div>
                                </div>
                            </div>
                            <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_3">
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="Z15.03" id={`${prefix}hBOCdiagnosis_z1503`} name={`${prefix}hBOCdiagnosis_z1503`} class="filled-in" />
                                        <label for={`${prefix}hBOCdiagnosis_z1503`}>Z15.03 Genetic susceptibility to malignant neoplasm of prostate &nbsp; </label>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="C56.1" id={`${prefix}hBOCdiagnosis_c561`} name={`${prefix}hBOCdiagnosis_c561`} class="filled-in" />
                                        <label for={`${prefix}hBOCdiagnosis_c561`}>C56.1 Malignant neoplasm of right ovary &nbsp; </label>
                                    </div>
                                </div>
                            </div>
                            <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_4">
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="Z15.04" id={`${prefix}hBOCdiagnosis_z1504`} name={`${prefix}hBOCdiagnosis_z1504`} class="filled-in" />
                                        <label for={`${prefix}hBOCdiagnosis_z1504`}>Z15.04 Genetic susceptibility to malignant neoplasm of endometrium &nbsp; </label>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="C56.2" id={`${prefix}hBOCdiagnosis_c562`} name={`${prefix}hBOCdiagnosis_c562`} class="filled-in" />
                                        <label for={`${prefix}hBOCdiagnosis_c562`}>C56.2 Malignant neoplasm of left ovary &nbsp; </label>
                                    </div>
                                </div>
                            </div>
                            <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_5">
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="Z15.09" id={`${prefix}hBOCdiagnosis_z1509`} name={`${prefix}hBOCdiagnosis_z1509`} class="filled-in" />
                                        <label for={`${prefix}hBOCdiagnosis_z1509`}>Z15.09 Genetic susceptibility to other malignant neoplasm &nbsp; </label>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="C56.9" id={`${prefix}hBOCdiagnosis_c569`} name={`${prefix}hBOCdiagnosis_c569`} class="filled-in" />
                                        <label for={`${prefix}hBOCdiagnosis_c569`}>C56.9 Malignant neoplasm of unspecified ovary &nbsp; </label>
                                    </div>
                                </div>
                            </div>
                            <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_6">
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="Z15.81" id={`${prefix}hBOCdiagnosis_z1581`} name={`${prefix}hBOCdiagnosis_z1581`} class="filled-in" />
                                        <label for={`${prefix}hBOCdiagnosis_z1581`}>Z15.81 Genetic susceptibility to multiple endocrine neoplasia [MEN] &nbsp; </label>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="C57.00" id={`${prefix}hBOCdiagnosis_c5700`} name={`${prefix}hBOCdiagnosis_c5700`} class="filled-in" />
                                        <label for={`${prefix}hBOCdiagnosis_c5700`}>C57.00 Malignant neoplasm of unspecified fallopain tube &nbsp; </label>
                                    </div>
                                </div>
                            </div>
                            <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_7">
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="C25.0" id={`${prefix}hBOCdiagnosis_C250`} name={`${prefix}hBOCdiagnosis_C250`} class="filled-in" />
                                        <label for={`${prefix}hBOCdiagnosis_C250`}>C25.0 Malignant neoplasm of head of pancreas &nbsp; </label>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="C57.01" id={`${prefix}hBOCdiagnosis_c5701`} name={`${prefix}hBOCdiagnosis_c5701`} class="filled-in" />
                                        <label for={`${prefix}hBOCdiagnosis_c5701`}>C57.01 Malignant neoplasm of right fallopain tube &nbsp; </label>
                                    </div>
                                </div>
                            </div>
                            <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_8">
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="C25.1" id={`${prefix}hBOCdiagnosis_C251`} name={`${prefix}hBOCdiagnosis_C251`} class="filled-in" />
                                        <label for={`${prefix}hBOCdiagnosis_C251`}>C25.1 Malignant neoplasm of body of pancreas &nbsp; </label>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="C57.01" id={`${prefix}hBOCdiagnosis_c5702`} name={`${prefix}hBOCdiagnosis_c5702`} class="filled-in" />
                                        <label for={`${prefix}hBOCdiagnosis_c5702`}>C57.01 Malignant neoplasm of left fallopain tube &nbsp; </label>
                                    </div>
                                </div>
                            </div>
                            <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_9">
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="C25.2" id={`${prefix}hBOCdiagnosis_C252`} name={`${prefix}hBOCdiagnosis_C252`} class="filled-in" />
                                        <label for={`${prefix}hBOCdiagnosis_C252`}>C25.2 Malignant neoplasm of tail of pancreas &nbsp; </label>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="C61" id={`${prefix}hBOCdiagnosis_c61`} name={`${prefix}hBOCdiagnosis_c61`} class="filled-in" />
                                        <label for={`${prefix}hBOCdiagnosis_c61`}>C61 Malignant neoplasm of prostate &nbsp; </label>
                                    </div>
                                </div>
                            </div>
                            <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_10">
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="C25.3" id={`${prefix}hBOCdiagnosis_C253`} name={`${prefix}hBOCdiagnosis_C253`} class="filled-in" />
                                        <label for={`${prefix}hBOCdiagnosis_C253`}>C25.3 Malignant neoplasm of pancreatic duct &nbsp; </label>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="D05.11" id={`${prefix}hBOCdiagnosis_D0511`} name={`${prefix}hBOCdiagnosis_D0511`} class="filled-in" />
                                        <label for={`${prefix}hBOCdiagnosis_D0511`}>D05.11 Intraductal carcinoma in situ of right breast &nbsp; </label>
                                    </div>
                                </div>
                            </div>
                            <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_11">
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="C25.4" id={`${prefix}hBOCdiagnosis_C254`} name={`${prefix}hBOCdiagnosis_C254`} class="filled-in" />
                                        <label for={`${prefix}hBOCdiagnosis_C254`}>C25.4 Malignant neoplasm of endocrine pancreas &nbsp; </label>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="D05.12" id={`${prefix}hBOCdiagnosis_D0512`} name={`${prefix}hBOCdiagnosis_D0512`} class="filled-in" />
                                        <label for={`${prefix}hBOCdiagnosis_D0512`}>D05.12 Intraductal carcinoma in situ of left breast &nbsp; </label>
                                    </div>
                                </div>
                            </div>
                            <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_12">
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="C25.7" id={`${prefix}hBOCdiagnosis_C257`} name={`${prefix}hBOCdiagnosis_C257`} class="filled-in" />
                                        <label for={`${prefix}hBOCdiagnosis_C257`}>C25.7 Malignant neoplasm of other parts of pancreas &nbsp; </label>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="Z85.07" id={`${prefix}hBOCdiagnosis_Z8507`} name={`${prefix}hBOCdiagnosis_Z8507`} class="filled-in" />
                                        <label for={`${prefix}hBOCdiagnosis_Z8507`}>Z85.07 Personal history of malignant neoplasm of pancreas &nbsp; </label>
                                    </div>
                                </div>
                            </div>
                            <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_13">
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="C25.8" id={`${prefix}hBOCdiagnosis_C258`} name={`${prefix}hBOCdiagnosis_C258`} class="filled-in" />
                                        <label for={`${prefix}hBOCdiagnosis_C258`}>C25.8 Malignant neoplasm of overlapping sites of pancreas &nbsp; </label>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="Z85.3" id={`${prefix}hBOCdiagnosis_Z853`} name={`${prefix}hBOCdiagnosis_Z853`} class="filled-in" />
                                        <label for={`${prefix}hBOCdiagnosis_Z853`}>Z85.3 Personal history of malignant neoplasm of breast &nbsp; </label>
                                    </div>
                                </div>
                            </div>
                            <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_14">
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="C25.9" id={`${prefix}hBOCdiagnosis_C259`} name={`${prefix}hBOCdiagnosis_C259`} class="filled-in" />
                                        <label for={`${prefix}hBOCdiagnosis_C259`}>C25.9 Malignant neoplasm of pancreas, unspecified &nbsp; </label>
                                    </div>
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="Z85.43" id={`${prefix}hBOCdiagnosis_Z8543`} name={`${prefix}hBOCdiagnosis_Z8543`} class="filled-in" />
                                        <label for={`${prefix}hBOCdiagnosis_Z8543`}>Z85.43 Personal history of malignant neoplasm of ovary &nbsp; </label>
                                    </div>
                                </div>
                            </div>
                            <div class="demo-checkbox" id="DiagnosisCodes_GHBOCG_15">
                                <div class="row clearfix">
                                    <div class="col-md-6">
                                    </div>
                                    <div class="col-md-6">
                                        <input type="checkbox" data-val="Z85.46" id={`${prefix}hBOCdiagnosis_Z8546`} name={`${prefix}hBOCdiagnosis_Z8546`} class="filled-in" />
                                        <label for={`${prefix}hBOCdiagnosis_Z8546`}>Z85.46 Personal history of malignant neoplasm of prostate &nbsp; </label>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="row clearfix">
                    <div class="col-md-12">
                        <div class="form-group ">
                            <label class="form-label" for={`${prefix}address`}>Other ICD-10 Codes</label>
                            <div class="form-line">
                                <input type="text" id={`${prefix}OtherICD10Codes`} class="form-control " placeholder="Other ICD-10 Codes" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row clearfix">
                    <div class="col-md-12">
                        <p>(This is provided for informational purposes only and is not a guarantee of coverage. It is the provider's responsibility to determine  the appropriate codes)</p>
                    </div>
                </div>

                <div class="row clearfix">
                    <div class="col-md-12 text-right">
                        <button id={`${prefix}btnDiagICDCodesFormReset`} class="btn btn-light">Reset Form</button>
                        <button id={`${prefix}btnDiagnosisCodes_ICD10CodesFormNext`} class="btn btn-light">Next</button>
                    </div>
                </div>


            </div>
        </>
    );

}
export default DiagnosisCodes_ICD10CodesForm;