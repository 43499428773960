import React, { PureComponent } from "react";

import PropTypes from 'prop-types';
//import $ from 'jquery';
//import DatePicker from 'react-datepicker';

export default class NotesCertifyHBOC_OVAForm extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {

        }

    }

    //onChangeBillingDoB = date => this.setState({ billinfo_dob: date }); 

    render() {

        return (
            <>
                <div class="panel-body">
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label class="form-label" for="address">Notes</label>
                                <div class="form-line">
                                    <textarea id="kitShipHBOC_OVA_notes" class="form-control no-resize auto-growth" placeholder="Notes" rows="3"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <p><strong>Please note this page is for healthcare professionals. If you are a physician and would like to order one of our tests, please access our physician order portal, or contact your healthcare provider. </strong></p>

                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="form-group required">
                                <label class="form-label" for="address">I certify that I am:</label>
                                <div class="demo-checkbox" id={`${this.props.prefix}certifytype`}>
                                    <input type="checkbox" data-val="A healthcare provider" id="kitShipHBOC_OVA_healthcareProvider" name="kitShipHBOC_OVA_healthcareProvider" class="filled-in" />
                                    <label for="kitShipHBOC_OVA_healthcareProvider">A healthcare provider &nbsp; </label>
                                    <input type="checkbox" data-val="An Aspira employee ordering on behalf of a healthcare provider" id="kitShipHBOC_OVA_employeehealthcareProvider" name="kitShipHBOC_OVA_employeehealthcareProvider" class="filled-in" />
                                    <label for="kitShipHBOC_OVA_employeehealthcareProvider">An Aspira employee ordering on behalf of a healthcare provider &nbsp; </label>
                                    <input type="checkbox" data-val="An Aspira partner ordering on behalf of a healthcare provider" id="kitShipHBOC_OVA_partnerhealthcareProvider" name="kitShipHBOC_OVA_partnerhealthcareProvider" class="filled-in" />
                                    <label for="kitShipHBOC_OVA_partnerhealthcareProvider">An Aspira partner ordering on behalf of a healthcare provider &nbsp; </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="form-group">
                                <button data-loading-text={`<img style='height: 30px;' src='/assets/images/Spinner-1s-200px.svg' /> Creating Physician`} id={`${this.props.prefix}btnOva1GenetixHBOCOrderSubmit`} type="submit" placeholder="Submit" name="Submit" className="btn btn-primary text-white">Submit</button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
