import React, { PureComponent } from "react";

//include template related files
import PageLoader from "./layout/PageLoader";
import Search from "./layout/Search";
import TopBar from "./layout/TopBar";
import LeftSidebar from "./layout/LeftSidebar";
import RightSidebar from "./layout/RightSidebar";
import FooterContent from "./layout/FooterContent";
import HomeContent from "./components/HomeContent";


import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';
import IdleSessionModal from "./mycomponents/modal";
//require("dotenv").config();

let me = null;
export default class Home extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      timeout: process.env.REACT_APP_LOGIN_TIMEOUT,

      userLoggedIn: false,
      isTimedOut: false,
      remainingSeconds: 59,
      showIdleSessionModal: false,
      user: null,
    }

    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)

    this.idleTimer = React.createRef();
    this.elIdleSessionModal = React.createRef();
  }

  
  _onAction(e) {
    console.log('user did something', e)
    this.setState({ isTimedOut: false })
  }

  _onActive(e) {
    console.log('user is active', e)
    this.setState({ isTimedOut: false })
  }

  _onIdle(e) {
    console.log('user is idle', e)
    const isTimedOut = this.state.isTimedOut;
    if (isTimedOut === -1) {
      return;
    }
    if (isTimedOut) {

      this.idleTimer.reset();
      if (this.state.showIdleSessionModal) {

      }
      else {
        this.setState({ showIdleSessionModal: true, isTimedOut: -1 },
          () => {
            let interval = setInterval(() => {
              if (this.state.remainingSeconds === 0) {
                clearInterval(interval);
                localStorage.clear();
                window.location.replace('/');

              }
              else {


                this.setState({ remainingSeconds: this.state.remainingSeconds - 1, })
              }

            }, 1000)
          });
      }


    } else {

      this.idleTimer.reset();
      this.setState({ isTimedOut: true, })
    }

  }

  handleShowWarningModal = e => {
    this.setState({
      showIdleSessionModal: !this.state.showIdleSessionModal
    });
  };

  IdleSessionDashboard() {
    // me.elIdleSessionModal = null;
    // me.idleTimer.reset();
    // me.setState({
    //   isTimedOut: false,
    //   remainingSeconds: 59,
    //   showIdleSessionModal: false,
    // });
    window.location.reload();
  }
  render() {
    return (
      <>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={this.state.timeout}
          timeout={this.state.timeout} />
        <div>
          {/*  Page Loader  */}
          <PageLoader />
          {/*  Overlay For Sidebars  */}
          <div class="overlay"></div>

          {/* Search */}
          <Search />

          {/* #Top Bar   */}
          <TopBar />

          {/* Left Sidebar */}
          {/* {this.state.user && this.state.user.resetPasswordRequired == false && */}
          <LeftSidebar />
          {/* } */}
          {/* Right Sidebar */}
          <RightSidebar />


          {/* Home Content Area */}
          <section class="content home actualHome">
            <div class="container-fluid">
              <HomeContent />
              {/* Footer Content  */}
              <FooterContent />
              <IdleSessionModal ref={(el) => this.elIdleSessionModal = el} onClose={this.handleShowWarningModal} show={this.state.showIdleSessionModal}>
                <div>
                  <span>You will logged out in {this.state.remainingSeconds} Seconds</span>
                </div>
                <div class="IdleSession-buttons">
                  <button class="btn waves-effect btn-delete"
                    onClick={this.IdleSessionDashboard}
                  >
                    I am Here
                            </button>
                </div>
                <div>
                </div>
              </IdleSessionModal>
            </div>{/* container-fluid */}
          </section>
        </div>
      </>
    );
  }
}
Home.propTypes = {
  match: PropTypes.any.isRequired,
  history: PropTypes.func.isRequired
}