import React from "react";
import Select from "react-select";
import helper from "../../utils/helper";

const PhysicianInformation = (props) => {
  const { me } = props;
  console.log("=====================================");
  console.log(props);
  console.log("00000000000000000000000000000000000000");

  const onlyNumberKey = (evt) => {
    // Only ASCII character in that range allowed
    var ASCIICode = evt.which ? evt.which : evt.keyCode;
    if (ASCIICode < 48 || ASCIICode > 57) {
      evt.preventDefault();
      return false;
    }
    return true;
  };

  const validateFax = (evt) => {
    var ASCIICode = evt.which ? evt.which : evt.keyCode;
    if ((ASCIICode < 48 || ASCIICode > 57) && ASCIICode !== 45) {
      evt.preventDefault();
      return false;
    }
    return true;
  };

  //  console.log(props.phyinfoFNHandler);
  return (
    <div>
      <div id="PhysicianInformationForm" className="body card">
        <div class="row clearfix">
          <div class="col-md-12">
            <div class="form-group">
              <label class="form-label" for="ordered_before1">
                Organization/Account #
              </label>
              <div class="form-line">
                <input
                  type="text"
                  // ref={(ref) => (me.phyinfo_Organization_account = ref)}
                  id={`phyinfo_Organization_account`}
                  class="form-control"
                  placeholder="Organization/Account #"
                  value={me.state.phyinfo_Organization_account}
                  onChange={({ target: { value } }) =>
                    me.setState({ phyinfo_Organization_account: value })
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row clearfix">
          <div class="col-md-3">
            <div class="form-group required">
              <label class="form-label" for="ordered_before1">
                First Name
              </label>
              <div class="form-line">
                <input
                  type="text"
                  // ref={(ref) => (me.phyinfo_first_name = ref)}
                  value={props?.state?.phyinfo_first_name}
                  id={`phyinfo_first_name`}
                  class="form-control required"
                  placeholder="First Name"
                  onChange={(val) => {
                    props.me.setState({ phyinfo_first_name: val.target.value });
                  }}
                />
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group required">
              <label class="form-label" for="ordered_before1">
                Last Name{" "}
              </label>
              <div class="form-line">
                <input
                  type="text"
                  // ref={(ref) => (props.state.phyinfo_last_name = ref)}
                  id={`phyinfo_last_name`}
                  class="form-control required"
                  placeholder="Last Name"
                  value={props?.state?.phyinfo_last_name}
                  onChange={(val) => {
                    props.me.setState({ phyinfo_last_name: val.target.value });
                  }}
                />
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group required">
              <label class="form-label" for="ordered_before1">
                Email
              </label>
              <div class="form-line">
                <input
                  type="email"
                  //  ref={(ref) => (me.phyinfo_email = ref)}
                  value={props.state.phyinfo_email}
                  id={`phyinfo_email`}
                  class="form-control required"
                  placeholder="Email"
                  onChange={(val) => {
                    props.me.setState({ phyinfo_email: val.target.value });
                  }}
                />
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group required">
              <label class="form-label" for="ordered_before1">
                NPI
              </label>
              <div class="form-line">
                <input
                  type="number"
                  // ref={(ref) => (props.state.phyinfo_npi = ref)}
                  id={`phyinfo_npi`}
                  class="form-control required"
                  placeholder="NPI"
                  value={props.state.phyinfo_npi}
                  onChange={(val) => {
                    props.me.setState({ phyinfo_npi: val.target.value });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row clearfix">
          <div class="col-md-6">
            <div class="form-group required">
              <label class="form-label" for="ordered_before1">
                Address
              </label>
              <div class="form-line">
                <input
                  type="text"
                  //  ref={(ref) => (props.state.phyinfoC_address = ref)}
                  id={`phyinfoC_address`}
                  class="form-control required"
                  placeholder="Address"
                  value={props.state.phyinfoC_address}
                  onChange={(val) => {
                    props.me.setState({ phyinfoC_address: val.target.value });
                  }}
                />
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="form-group required">
              <label class="form-label" for="ordered_before1">
                Practice Location
              </label>
              <div class="form-line">
                <input
                  type="text"
                  id={`phyinfoC_practiceLocation`}
                  class="form-control required"
                  placeholder="Practice Location"
                  value={props.state.phyinfoC_practiceLocation}
                  onChange={(val) => {
                    props.me.setState({
                      phyinfoC_practiceLocation: val.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row clearfix">
          <div class="col-md-4">
            <div class="form-group ">
              <label class="form-label" for="ordered_before1">
                City{" "}
              </label>
              <div class="form-line">
                <input
                  type="text"
                  //  ref={(ref) => (me.phyinfoC_city = ref)}
                  id={`phyinfoC_city`}
                  class="form-control"
                  placeholder="City"
                  value={props.state.phyinfoC_city}
                  onChange={(val) => {
                    props.me.setState({ phyinfoC_city: val.target.value });
                  }}
                />
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group ">
              <label class="form-label" for="ordered_before1">
                State
              </label>
              <div class="form-line">
                <Select
                  class="form-control"
                  /*  ref={(_ref) => {
                     props.phyinfo_state = _ref;
                   }} */
                  id={`phyinfo_state`}
                  defaultValue={"Choose State"}
                  value={props.state.phyinfo_state}
                  label="State:"
                  options={helper.usStates}
                  styles={helper.colourStyles}
                  onChange={(val) => {
                    props.me.setState({
                      phyinfo_state: val,
                      phyinfo_state_save_value: val.value,
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group ">
              <label class="form-label" for="ordered_before1">
                Zip Code
              </label>
              <div class="form-line">
                <input
                  type="text"
                  id={`phyinfoC_zipCode`}
                  class="form-control"
                  placeholder="Zip Code"
                  value={props.state.phyinfoC_zipCode}
                  onKeyPress={onlyNumberKey}
                  onChange={(val) => {
                    props.me.setState({ phyinfoC_zipCode: val.target.value });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div class="row clearfix">
          <div class="col-md-4">
            <div class="form-group required">
              <label class="form-label" for="ordered_before1">
                Phone{" "}
              </label>
              <div class="form-line">
                {/* <PhoneNumberInput prefix={`phyinfoC_`} /> */}
                <input
                  type="text"
                  // ref={(ref) => (me.phyinfoC_Phone = ref)}
                  id="phyinfoC_Phone"
                  class="form-control required"
                  placeholder="Phone"
                  value={props.state.phyinfoC_Phone}
                  onChange={(val) => {
                    props.me.setState({ phyinfoC_Phone: val.target.value });
                  }}
                />
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="form-label" for="ordered_before1">
                Fax
              </label>
              <div class="form-line">
                <input
                  type="text"
                  onKeyPress={validateFax}
                  id={`phyinfoC_fax`}
                  class="form-control"
                  placeholder="Fax"
                  value={props.state.phyinfoC_fax}
                  onChange={(val) => {
                    props.me.setState({ phyinfoC_fax: val.target.value });
                  }}
                />
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group ">
              <label class="form-label" for="ordered_before1">
                Fax copy to
              </label>
              <div class="form-line">
                <input
                  type="text"
                  onKeyPress={validateFax}
                  id={`phyinfoC_Faxcopyto`}
                  class="form-control required"
                  placeholder="Fax copy to"
                  value={props.state.phyinfoC_Faxcopyto}
                  onChange={(val) => {
                    props.me.setState({ phyinfoC_Faxcopyto: val.target.value });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhysicianInformation;
