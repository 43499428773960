import React from "react";

export default function PageLoader() {
    return (
        <div class="page-loader-wrapper">
            <div class="loader">
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <div class="line"></div>
                <p>Please wait...</p>
            </div>
        </div>
    );
  }
  