import React, { PureComponent, useEffect } from "react";
import { Switch, Route, Link, Redirect, BrowserRouter as Router, NavLink } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "./authConfig";


import Login from "./Login";
import Home from "./Home";
import Dashboard from "./Dashboard";
import ViewUsers from "./ViewUsers";
import ResetPassword from "./ResetPassword";
import ForgotPassword from "./ForgotPassword";
import ForgotPasswordResetPage from "./ForgotPasswordResetPage";
import AddEditUser from "./AddEditUser";
import UserProfile from "./UserProfile";
import Patients from "./Patients";
import AddEditOrderOva1 from "./AddEditOrderOva1";
import AddEditOrderOvaWatch from "./AddEditOrderOvaWatch"
import Orders from "./Orders";
import AddEditPatient from "./AddEditPatient";
import VerifyPatient from "./VerifyPatient";

import AddEditOrderGtCS from "./AddEditOrderGtCS";
import AddEditOrderHBOC from "./AddEditOrderHBOC";
import AddEditOrderOVAHBOC from "./AddEditOrderOVAHBOC";

import ReportSearch from "./ReportSearch";
import ReportReview from "./ReportReview";
import ResultedReport from "./ResultedReport";
import PositiveReport from "./PositiveReport";
import OnHoldReport from "./OnHoldReport";


import QeuedFaxes from "./QeuedFaxes";
import SuccessfulFaxes from "./SuccessfulFaxes";
import FailedFaxes from './FailedFaxes';

import NewClient from "./NewClient";
import TestCircleLoader from "./testCircleLoader";

// import 'antd/dist/antd.css';
import 'antd/dist/antd.min.css';

import "./App.css";
import "./AppPortal.css";
import Audits from "./Audits";
import Clients from "./Clients";
import ResearchOrdersWithFilter from "./ResearchOrdersWithFilter";
import NewAccessionsPDF from './NewAccessionsPDF';
import { InteractionStatus } from "@azure/msal-browser";

import helper from "./utils/helper";
import TRF_ProviderOrderPortalOva1 from "./TRF_ProviderOrderPortalOva1";
import TRF_ProviderOrderPortalOvaWatch from "./TRF_ProviderOrderPortalOvaWatch";
import TRF_ProviderOrderPortalHBOC from "./TRF_ProviderOrderPortalHBOC";
import TRF_ProviderOrderPortalGTCS from "./TRF_ProviderOrderPortalGTCS";
import ProviderOrderPortal from "./ProviderOrderPortal";
import { AppContextProvider } from "./context";
const App = () => {
  const physicianAddresses = [], physicianPracticeLocations = [];

  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress } = useMsal();
  // let { refresh } = useParams();

  if (window.location.pathname === "/provider-order-ova1") {
    return <TRF_ProviderOrderPortalOva1 />;
  } else if (window.location.pathname === "/provider-order-ovawatch") {
    return <TRF_ProviderOrderPortalOvaWatch />;
  } else if (window.location.pathname === "/provider-order-gtcs") {
    return <TRF_ProviderOrderPortalGTCS />;
  } else if (window.location.pathname === "/provider-order-hboc") {
    return <TRF_ProviderOrderPortalHBOC />;
  }

  if (inProgress === InteractionStatus.None && !isAuthenticated && process.env.REACT_APP_ENV !== 'DEV') {
    instance.loginRedirect(loginRequest).then((loginResponse) => {
      console.log("App.js->loginRedirect->loginResponse: ", loginResponse);
    });
  }
  else {
    // console.log("App.js->searchParams: ", searchParams);

    // const data = JSON.parse(localStorage.getItem('user'));
    // const load = localStorage.getItem('load') || true;
    // if (data && load) {
    //   if (data.resetPasswordRequired) {
    //     window.location.href = '/reset-password';
    //   }
    //   else if (data.allRoles.some((x) => x.name === 'Portal_Billing')) {
    //     window.location.href = '/billing-review';
    //   }
    //   else if (data.allRoles.some((x) => x.name === 'Portal_Benefits_Reivewer')) {
    //     window.location.href = '/benefits-review';
    //   }
    //   else if (data.allRoles.some((x) => x.name === 'Portal_Report_Viewer')) {
    //     window.location.href = '/resulted_report';
    //   }
    //   else {
    //     window.location.href = '/orders';
    //   }
    // }
    // else {
    //   // window.location.href = '/dashboard';
    //   window.location.reload();
    // }

    // localStorage.setItem("load", false);

    // window.location.href = '/orders';
  }


  if (process.env.REACT_APP_ENV === 'DEV') {
    return (
      <>
        <AppContextProvider patientDOB={""} physicianAddresses={physicianAddresses} physicianPracticeLocations={physicianPracticeLocations}>
          <Switch>
            <Route path="/provider-order-ova1" exact component={TRF_ProviderOrderPortalOva1} />
            <Route path="/provider-order-ovawatch" exact component={TRF_ProviderOrderPortalOvaWatch} />
            <Route path="/provider-order-gtcs" exact component={TRF_ProviderOrderPortalGTCS} />
            <Route path="/provider-order-hboc" exact component={TRF_ProviderOrderPortalHBOC} />

            <Route path="/provider-order" exact component={ProviderOrderPortal} />

            <Route path="/" exact component={Login} />
            <Route path="/profile" render={() => <h3>This is profile. Go to <Link to="/">Home</Link> or <a href="/dashboard">Dashboard page.</a></h3>} />
            {/* <Route path="/orders/redirect" render={() => window.location.replace("/orders")} /> */}
            {/* <Redirect from="/orders/redirect" to="/orders" /> */}
            {/* <Redirect from="/orders/foo" to="/orders" /> */}

            <Route path="/testCircleLoader" component={TestCircleLoader} />
            <Route path="/verifypatient/:id/:code" component={VerifyPatient} />

            <Route path="/dashboard" component={Dashboard} />
            <Route path="/users" component={ViewUsers} />
            <Route path="/adduser" component={AddEditUser} />
            <Route path="/edituser" component={AddEditUser} />
            <Route path={`/profile-user`} showRole={false} component={AddEditUser} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/forgotPasswordRequest/:userId/:verificationCode" component={ForgotPasswordResetPage} />

            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/patients" component={Patients} />
            <Route path="/addorder" component={AddEditOrderOva1} />
            <Route path="/editorder_ova1" component={AddEditOrderOva1} />
            <Route path="/editorder_OvaWatch" component={AddEditOrderOvaWatch} />
            <Route path="/editorder_OVAsight" component={AddEditOrderOvaWatch} />
            <Route path="/research-orders-filter" component={ResearchOrdersWithFilter} />

            <Route path="/orders" component={Orders} />
            <Route path="/research-orders" component={Orders} />
            <Route path="/billing-ready" component={Orders} />
            <Route path="/waiting-cmt-auth" component={Orders} />
            <Route path="/new-accessions" component={Orders} />
            <Route path="/billing-review" component={Orders} />
            <Route path="/benefits-review" component={Orders} />
            <Route path="/internal-auth" component={Orders} />
            <Route path="/research-orders-only" component={Orders} />
            <Route path="/my-accessions" component={Orders} />

            <Route path="/new-accessions-pdf" component={NewAccessionsPDF} />
            <Route path="/resulted-report" component={ResultedReport} />
            <Route path="/addpatient" component={AddEditPatient} />
            <Route path="/editpatient" component={props => <AddEditPatient {...props} />} />
            <Route path="/audit" component={Audits} />
            <Route path="/editorder_GenetiXCarrierScreening" component={AddEditOrderGtCS} />
            <Route path="/editorder_GenetixHBOC1" component={AddEditOrderHBOC} />
            <Route path="/editorder_Ova1_GenetixHBOC1" component={AddEditOrderOVAHBOC} />

            <Route path="/report_search" component={ReportSearch} />
            <Route path="/report_review" component={ReportReview} />
            <Route path="/resulted_report" component={ResultedReport} />
            <Route path="/positive_report" component={PositiveReport} />
            <Route path="/on_hold_report" component={OnHoldReport} />

            <Route path="/qeued_faxes" component={QeuedFaxes} />
            <Route path="/successfull_faxes" component={SuccessfulFaxes} />
            <Route path="/failed_faxes" component={FailedFaxes} />

            <Route path="/addeditclient" component={NewClient} />
            <Route path="/clientlist" component={Clients} />
          </Switch>
        </AppContextProvider>

      </>
    );
  }
  return (
    <>
      <AppContextProvider patientDOB={""} physicianAddresses={physicianAddresses} physicianPracticeLocations={physicianPracticeLocations}>
        <AuthenticatedTemplate>
          <Switch>
            
            <Route path="/provider-order-ova1" exact component={TRF_ProviderOrderPortalOva1} />
            <Route path="/provider-order-ovawatch" exact component={TRF_ProviderOrderPortalOvaWatch} />
            <Route path="/provider-order-gtcs" exact component={TRF_ProviderOrderPortalGTCS} />
            <Route path="/provider-order-hboc" exact component={TRF_ProviderOrderPortalHBOC} />

            <Route path="/provider-order" exact component={ProviderOrderPortal} />

            <Route path="/" exact render={() => window.location.replace("/dashboard")} />
            <Route path="/profile" render={() => <h3>This is profile. Go to <Link to="/">Home</Link> or <a href="/dashboard">Dashboard page.</a></h3>} />
            
            <Route path="/testCircleLoader" component={TestCircleLoader} />
            <Route path="/verifypatient/:id/:code" component={VerifyPatient} />

            <Route path="/dashboard" component={Dashboard} />
            <Route path="/users" component={ViewUsers} />
            <Route path="/adduser" component={AddEditUser} />
            <Route path="/edituser" component={AddEditUser} />
            <Route path={`/profile-user`} showRole={false} component={AddEditUser} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <Route path="/forgotPasswordRequest/:userId/:verificationCode" component={ForgotPasswordResetPage} />

            <Route path="/reset-password" component={ResetPassword} />
            <Route path="/patients" component={Patients} />
            <Route path="/addorder" component={AddEditOrderOva1} />
            <Route path="/editorder_ova1" component={AddEditOrderOva1} />
            <Route path="/editorder_OvaWatch" component={AddEditOrderOvaWatch} />
            <Route path="/editorder_OVAsight" component={AddEditOrderOvaWatch} />
            <Route path="/research-orders-filter" component={ResearchOrdersWithFilter} />

            <Route path="/orders" component={Orders} />
            <Route path="/research-orders" component={Orders} />
            <Route path="/billing-ready" component={Orders} />
            <Route path="/waiting-cmt-auth" component={Orders} />
            <Route path="/new-accessions" component={Orders} />
            <Route path="/billing-review" component={Orders} />
            <Route path="/benefits-review" component={Orders} />
            <Route path="/internal-auth" component={Orders} />
            <Route path="/research-orders-only" component={Orders} />
            <Route path="/my-accessions" component={Orders} />

            <Route path="/new-accessions-pdf" component={NewAccessionsPDF} />
            <Route path="/resulted-report" component={ResultedReport} />
            <Route path="/addpatient" component={AddEditPatient} />
            <Route path="/editpatient" component={props => <AddEditPatient {...props} />} />
            <Route path="/audit" component={Audits} />
            <Route path="/editorder_GenetiXCarrierScreening" component={AddEditOrderGtCS} />
            <Route path="/editorder_GenetixHBOC1" component={AddEditOrderHBOC} />
            <Route path="/editorder_Ova1_GenetixHBOC1" component={AddEditOrderOVAHBOC} />

            <Route path="/report_search" component={ReportSearch} />
            <Route path="/report_review" component={ReportReview} />
            <Route path="/resulted_report" component={ResultedReport} />
            <Route path="/positive_report" component={PositiveReport} />
            <Route path="/on_hold_report" component={OnHoldReport} />

            <Route path="/qeued_faxes" component={QeuedFaxes} />
            <Route path="/successfull_faxes" component={SuccessfulFaxes} />
            <Route path="/failed_faxes" component={FailedFaxes} />

            <Route path="/addeditclient" component={NewClient} />
            <Route path="/clientlist" component={Clients} />
          </Switch>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          {/* <h1>Trying to sign you in...</h1> */}
          <TestCircleLoader />
        </UnauthenticatedTemplate>
      </AppContextProvider>
    </>
  );
}

export default App;
