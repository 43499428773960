import React, { PureComponent } from "react";

import PropTypes from 'prop-types';
import $ from 'jquery';
//import DatePicker from 'react-datepicker';
import helper from './../utils/helperPortal';

export default class OVA1plusbiomarkersForm extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            assays: []
        }

    }

    //onChangeBillingDoB = date => this.setState({ billinfo_dob: date }); 

    async componentDidMount() {

        $(document).ready(async () => {


            let _assays = await helper.getAssaysByType('OVA_1');
            console.log("Ova1Plus->_assays: ", _assays);



            let content = `
            <div class="col-md-6">
                            <div class="form-group required">
                                <div class="demo-checkbox">
                                    <input data-qbenchid="31" data-val="GCP-100" type="checkbox" id="germCellPanel_GCP100_LDH" name="germCellPanel_GCP100_LDH" class="filled-in" />
                                    <label for="germCellPanel_GCP100_LDH"><b>(GCP-100) Germ Cell Panel</b> (Includes: AFP, bHCG, LDH) &nbsp; </label><br />
                                    <input data-qbenchid="30" data-val="MCP-100" type="checkbox" id="germCellPanel_MCP100_CEA" name="germCellPanel_MCP100_CEA" class="filled-in" />
                                    <label for="germCellPanel_MCP100_CEA"><b>(MCP-100) Mucinous Panel</b> (Includes: CA19.9, CEA) &nbsp; </label>
                                </div>
                            </div>
                        </div>

            `;

            let _gcps = _assays.assays.filter((x) => x.testCode.indexOf("GCP") > -1 && !x.testCode.includes("GCP-100"));
            let _mcps = _assays.assays.filter((x) => x.testCode.indexOf("MCP") > -1 && !x.testCode.includes("MCP-100"));

            let _gcpStr = `<div id="gcp_container1" class="col-md-3">`;
            _gcpStr += `<div class="form-group required"><div class="demo-checkbox">`;
            _gcps.map((a, i) => {
                _gcpStr += `
                    <input data-qbenchid="${a.qBenchId}" data-val="${a.testCode}" type="checkbox" id="${this.props.prefix}_gcp_assay_${i}" class="filled-in" />
                    <label for="${this.props.prefix}_gcp_assay_${i}"><b>${a.name}</b></label><br />                
                `
            });
            _gcpStr += `</div></div></div>`;

            let _mcpStr = `<div id="mcp_container1" class="col-md-3">`;
            _mcpStr += `<div class="form-group required"><div class="demo-checkbox">`;
            _mcps.map((a, i) => {
                _mcpStr += `
                    <input data-qbenchid="${a.qBenchId}" data-val="${a.testCode}" type="checkbox" id="${this.props.prefix}_mcp_assay_${i}" class="filled-in" />
                    <label for="${this.props.prefix}_mcp_assay_${i}"><b>${a.name}</b></label><br />                
                `
            });
            _mcpStr += `</div></div></div>`;

            content += _gcpStr + _mcpStr;


            this.setState({ assays: content }, () => {
                $('#printHere').html(content);
            });

            $('#germCellPanel_GCP100_LDH').change(() => {
                let $gcp = $('#germCellPanel_GCP100_LDH');
                if ($gcp.is(':checked')) {
                    $('#gcp_container1').find('input').map((i, elem) => {
                        $(elem).prop('checked', true);
                        $(elem).prop('disabled', true);
                    })
                }
                else {
                    $('#gcp_container1').find('input').map((i, elem) => {
                        $(elem).prop('checked', false);
                        $(elem).prop('disabled', false);
                    })
                }
            });
            $('#germCellPanel_MCP100_CEA').change(() => {
                let $mcp = $('#germCellPanel_MCP100_CEA');
                if ($mcp.is(':checked')) {
                    $('#mcp_container1').find('input').map((i, elem) => {
                        $(elem).prop('checked', true);
                        $(elem).prop('disabled', true);
                    })
                }
                else {
                    $('#mcp_container1').find('input').map((i, elem) => {
                        $(elem).prop('checked', false);
                        $(elem).prop('disabled', false);
                    })
                }
            });

            const shouldGCP100BeChecked = () => {
                for (let idx in _gcps) {
                    if (!$(`#${this.props.prefix}_gcp_assay_${idx}`).is(":checked")) return false;
                }

                return true;
            }

            const shouldMCP100BeChecked = () => {
                for (let idx in _mcps) {
                    if (!$(`#${this.props.prefix}_mcp_assay_${idx}`).is(":checked")) return false;
                }

                return true;
            }

            _gcps.forEach((a, i) => {
                $(`#${this.props.prefix}_gcp_assay_${i}`).change(() => {
                    if (shouldGCP100BeChecked()) $('#germCellPanel_GCP100_LDH').click();
                });
            });

            _mcps.forEach((a, i) => {
                $(`#${this.props.prefix}_mcp_assay_${i}`).change(() => {
                    if (shouldMCP100BeChecked()) $('#germCellPanel_MCP100_CEA').click();
                });
            });

        });
    }


    render() {

        return (
            <>
                <div id="Ova1plusbiomarkersForm" class="panel-body">
                    <div className="row clearfix">
                        <div className="col-md-12">
                            <input checked data-val="Ova1" data-qbenchid="1" type="checkbox" id={`${this.props.prefix}_Ova1`} name={`${this.props.prefix}_Ova1`} class="filled-in" />
                            <label for={`${this.props.prefix}_Ova1`}>OVA1 &nbsp; </label><br />
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="form-group required">
                                <label class="form-label" for="address">Ova1 Additonal Biomarkers (select panel)</label>
                            </div>
                            <div id="printHere" class="row clearfix">

                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12 text-right">
                            <button id={`${this.props.prefix}btnOva1PlusBioMakersFormReset`} class="btn btn-light">Reset Form</button>
                            <button id={`${this.props.prefix}btnOVA1plusbiomarkersFormNext`} class="btn btn-light">Next</button>
                        </div>
                    </div>
                </div>
            </>
        )


    }
}
