import React, { PureComponent } from "react";

import PropTypes from 'prop-types';
import $ from 'jquery';
import DatePicker from 'react-datepicker';
import helper from "../utils/helperPortal";

export default class ClinicalInformationOVAForm extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {

        }

    }

    componentDidMount() {
        $(document).ready(() => {
            $('#btnClinicalInformationOVAFormReset').click(() => {
                $('#ova1GenetixClinicalInfoFormContainer').find(':input').map((i, elem) => {

                    const _type = $(elem).attr('type');
                    helper.resetForm(_type, elem);
                })

            })
        })
    }
    onChangeDoLastMentrualPeriod2 = date => this.setState({ clinicinfo2_DoLastMentrualPeriod: date });

    render() {

        const ShowPatienAbdomenPainAreaClick2 = () => {

            var clinicinfo2_pain_abdomen_pelvis = document.getElementById("clinicinfo2_pain_abdomen_pelvis");

            if (clinicinfo2_pain_abdomen_pelvis.checked == true) {
                $("div#PatienAbdomenPainArea").addClass(" showPatienAbdomenPainArea");
                $("div#PatienAbdomenPainArea").removeClass(" hidePatienAbdomenPainArea");
            }
        };

        const HidePatienAbdomenPainAreaClick2 = () => {

            var clinicinfo2_pain_abdomen_pelvis2 = document.getElementById("clinicinfo2_pain_abdomen_pelvis2");

            if (clinicinfo2_pain_abdomen_pelvis2.checked == true) {
                $("div#PatienAbdomenPainArea").removeClass(" showPatienAbdomenPainArea");
                $("div#PatienAbdomenPainArea").addClass(" hidePatienAbdomenPainArea");
            }
        };

        const ShowPatienfullQuicklyUnableToeAreaClick2 = () => {

            var clinicinfo2_fullQuickly_unableToe = document.getElementById("clinicinfo2_fullQuickly_unableToe");

            if (clinicinfo2_fullQuickly_unableToe.checked == true) {
                $("div#PatienfullQuicklyUnableToeArea").addClass(" showPatienAbdomenPainArea");
                $("div#PatienfullQuicklyUnableToeArea").removeClass(" hidePatienAbdomenPainArea");
            }
        };

        const HidePatienfullQuicklyUnableToeAreaClick2 = () => {

            var clinicinfo2_fullQuickly_unableToe2 = document.getElementById("clinicinfo2_fullQuickly_unableToe2");

            if (clinicinfo2_fullQuickly_unableToe2.checked == true) {
                $("div#PatienfullQuicklyUnableToeArea").removeClass(" showPatienAbdomenPainArea");
                $("div#PatienfullQuicklyUnableToeArea").addClass(" hidePatienAbdomenPainArea");
            }
        };

        const ShowPatienExpabdominalbloatAreaClick2 = () => {

            var clinicinfo2_Expabdominalbloat_IncrSize = document.getElementById("clinicinfo2_Expabdominalbloat_IncrSize");

            if (clinicinfo2_Expabdominalbloat_IncrSize.checked == true) {
                $("div#PatienExpabdominalbloatArea").addClass(" showPatienAbdomenPainArea");
                $("div#PatienExpabdominalbloatArea").removeClass(" hidePatienAbdomenPainArea");
            }
        };

        const HidePatienExpabdominalbloatAreaClick2 = () => {

            var clinicinfo2_Expabdominalbloat_IncrSize2 = document.getElementById("clinicinfo2_Expabdominalbloat_IncrSize2");

            if (clinicinfo2_Expabdominalbloat_IncrSize2.checked == true) {
                $("div#PatienExpabdominalbloatArea").removeClass(" showPatienAbdomenPainArea");
                $("div#PatienExpabdominalbloatArea").addClass(" hidePatienAbdomenPainArea");
            }
        };







        return (
            <>
                <div id={`ova1GenetixClinicalInfoFormContainer`} class="panel-body">
                    <div class="row clearfix">
                        <div class="col-md-6">
                            <div class="form-group required">
                                <label class="form-label" for="MenopausalStatus">Menopausal Status</label>
                                <div class="demo-radio-button">
                                    <input type="radio" id="clinicinfo2_Menopausal_status" name="clinicinfo2_Menopausal_status_" value="Pre-Menopausal" class="form-control" />
                                    <label class="form-label menopausalStatus" for="clinicinfo2_Menopausal_status">Pre-Menopausal &nbsp; </label>
                                    <input type="radio" id="clinicinfo2_Menopausal_status2" name="clinicinfo2_Menopausal_status_" value="Post-Menopausal" class="form-control" />
                                    <label class="form-label menopausalStatus" for="clinicinfo2_Menopausal_status2">Post-Menopausal &nbsp; </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group required">
                                <label class="form-label" for="UltrasoundResults">Ultrasound Results</label>
                                <div class="demo-checkbox">
                                    <input type="checkbox" id="clinicinfo2_lowrisk" name="clinicinfo2_lowrisk" class="filled-in" />
                                    <label for="clinicinfo2_lowrisk">Low Risk &nbsp; </label>
                                    <input type="checkbox" id="clinicinfo2_highrisk" name="clinicinfo2_highrisk" class="filled-in" />
                                    <label for="clinicinfo2_highrisk">High Risk &nbsp; </label>
                                    <input type="checkbox" id="clinicinfo2_NotDefinitive" name="clinicinfo2_NotDefinitive" class="filled-in" />
                                    <label for="clinicinfo2_NotDefinitive">Not Definitive &nbsp; </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="form-group required">
                                <label class="form-label" for="dob">Date of last menstrual period </label>
                                <div class="form-line">
                                    <DatePicker
                                        id={`clinicinfo2_DoLastMentrualPeriod`}
                                        className={"form-control required"}
                                        selected={this.state.clinicinfo2_DoLastMentrualPeriod}
                                        onChange={this.onChangeDoLastMentrualPeriod2}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-4">
                            <div class="form-group ">
                                <label class="form-label" for="masssize_long">Size of mass (longest dimension) </label>
                                <div class="form-line">
                                    <input type="text" id="clinicinfo2_MassSize_longdime" class="form-control" placeholder=" longest dimension" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group ">
                                <label class="form-label" for="masssize_height">Height </label>
                                <div class="form-line">
                                    <input type="text" id="clinicinfo2_MassSize_Height" class="form-control" placeholder=" Height" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group ">
                                <label class="form-label" for="masssize_weight">Weight </label>
                                <div class="form-line">
                                    <input type="text" id="clinicinfo2_MassSize_Weight" class="form-control" placeholder=" Weight" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="demo-radio-button">
                                <label>Does the physician have pain in the abdomen or pelvis?</label>
                                <input type="radio" id="clinicinfo2_pain_abdomen_pelvis" name="clinicinfo2_pain_abdomen_pelvis_" value="yes" class="form-control" onClick={() => {
                                    ShowPatienAbdomenPainAreaClick2()
                                }} />
                                <label class="form-label" for="clinicinfo2_pain_abdomen_pelvis">Yes</label>
                                <input type="radio" id="clinicinfo2_pain_abdomen_pelvis2" name="clinicinfo2_pain_abdomen_pelvis_" value="no" class="form-control" onClick={() => {
                                    HidePatienAbdomenPainAreaClick2()
                                }} />
                                <label class="form-label" for="clinicinfo2_pain_abdomen_pelvis2">No</label>
                            </div>
                        </div>
                    </div>
                    <div id="PatienAbdomenPainArea" class=" hidePatienAbdomenPainArea">
                        <div class="row clearfix " >
                            <div class="col-md-6">
                                <label class="form-label" for="">How many days per month?</label>
                                <div class="demo-radio-button">
                                    <input type="radio" id="clinicinfo2_painAbdomenPelvis_Day_1" name="clinicinfo2_painAbdomenPelvis_Day_" value="0-5 days" class="form-control" />
                                    <label class="form-label" for="clinicinfo2_painAbdomenPelvis_Day_1">0-5 days &nbsp; </label>
                                    <input type="radio" id="clinicinfo2_painAbdomenPelvis_Day_2" name="clinicinfo2_painAbdomenPelvis_Day_" value="6-12 days" class="form-control" />
                                    <label class="form-label" for="clinicinfo2_painAbdomenPelvis_Day_2">6-12 days &nbsp; </label>
                                    <input type="radio" id="clinicinfo2_painAbdomenPelvis_Day_3" name="clinicinfo2_painAbdomenPelvis_Day_" value="more 12 days" class="form-control" />
                                    <label class="form-label" for="clinicinfo2_painAbdomenPelvis_Day_3">&gt; 12 days &nbsp; </label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="form-label" for="">For how long?</label>
                                <div class="demo-radio-button">
                                    <input type="radio" id="clinicinfo2_painAbdomenPelvis_long_1" name="clinicinfo2_painAbdomenPelvis_long_" value="less 1 month" class="form-control" />
                                    <label class="form-label" for="clinicinfo2_painAbdomenPelvis_long_1">&lt; 1 month &nbsp; </label>
                                    <input type="radio" id="clinicinfo2_painAbdomenPelvis_long_2" name="clinicinfo2_painAbdomenPelvis_long_" value="1-6 months" class="form-control" />
                                    <label class="form-label" for="clinicinfo2_painAbdomenPelvis_long_2">1-6 months &nbsp; </label>
                                    <input type="radio" id="clinicinfo2_painAbdomenPelvis_long_3" name="clinicinfo2_painAbdomenPelvis_long_" value="7-12 months" class="form-control" />
                                    <label class="form-label" for="clinicinfo2_painAbdomenPelvis_long_3">7-12 months &nbsp; </label>
                                    <input type="radio" id="clinicinfo2_painAbdomenPelvis_long_4" name="clinicinfo2_painAbdomenPelvis_long_" value="more 12 months" class="form-control" />
                                    <label class="form-label" for="clinicinfo2_painAbdomenPelvis_long_4">&gt; 12 months &nbsp; </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="demo-radio-button">
                                <label>Does the physician Feel full quickly or unable to eat normally?</label>
                                <input type="radio" id="clinicinfo2_fullQuickly_unableToe" name="clinicinfo2_fullQuickly_unableToe_" value="yes" class="form-control" onClick={() => {
                                    ShowPatienfullQuicklyUnableToeAreaClick2()
                                }} />
                                <label class="form-label" for="clinicinfo2_fullQuickly_unableToe">Yes</label>
                                <input type="radio" id="clinicinfo2_fullQuickly_unableToe2" name="clinicinfo2_fullQuickly_unableToe_" value="no" class="form-control" onClick={() => {
                                    HidePatienfullQuicklyUnableToeAreaClick2()
                                }} />
                                <label class="form-label" for="clinicinfo2_fullQuickly_unableToe2">No</label>
                            </div>
                        </div>
                    </div>
                    <div id="PatienfullQuicklyUnableToeArea" class=" hidePatienAbdomenPainArea">
                        <div class="row clearfix" >
                            <div class="col-md-6">
                                <label class="form-label" for="">How many days per month?</label>
                                <div class="demo-radio-button">
                                    <input type="radio" id="clinicinfo2_fullQuicklyUnableToe_Day_1" name="clinicinfo2_fullQuicklyUnableToe_Day_" value="0-5 days" class="form-control" />
                                    <label class="form-label" for="clinicinfo2_fullQuicklyUnableToe_Day_1">0-5 days &nbsp; </label>
                                    <input type="radio" id="clinicinfo2_fullQuicklyUnableToe_Day_2" name="clinicinfo2_fullQuicklyUnableToe_Day_" value="6-12 days" class="form-control" />
                                    <label class="form-label" for="clinicinfo2_fullQuicklyUnableToe_Day_2">6-12 days &nbsp; </label>
                                    <input type="radio" id="clinicinfo2_fullQuicklyUnableToe_Day_3" name="clinicinfo2_fullQuicklyUnableToe_Day_" value="more 12 days" class="form-control" />
                                    <label class="form-label" for="clinicinfo2_fullQuicklyUnableToe_Day_3">&gt; 12 days &nbsp; </label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="form-label" for="">For how long?</label>
                                <div class="demo-radio-button">
                                    <input type="radio" id="clinicinfo2_fullQuicklyUnableToe_long_1" name="clinicinfo2_fullQuicklyUnableToe_long_" value="less 1 month" class="form-control" />
                                    <label class="form-label" for="clinicinfo2_fullQuicklyUnableToe_long_1">&lt; 1 month &nbsp; </label>
                                    <input type="radio" id="clinicinfo2_fullQuicklyUnableToe_long_2" name="clinicinfo2_fullQuicklyUnableToe_long_" value="1-6 months" class="form-control" />
                                    <label class="form-label" for="clinicinfo2_fullQuicklyUnableToe_long_2">1-6 months &nbsp; </label>
                                    <input type="radio" id="clinicinfo2_fullQuicklyUnableToe_long_3" name="clinicinfo2_fullQuicklyUnableToe_long_" value="7-12 months" class="form-control" />
                                    <label class="form-label" for="clinicinfo2_fullQuicklyUnableToe_long_3">7-12 months &nbsp; </label>
                                    <input type="radio" id="clinicinfo2_fullQuicklyUnableToe_long_4" name="clinicinfo2_fullQuicklyUnableToe_long_" value="more 12 months" class="form-control" />
                                    <label class="form-label" for="clinicinfo2_fullQuicklyUnableToe_long_4">&gt; 12 months &nbsp; </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="demo-radio-button">
                                <label>Does the physician Experience abdominal bloating or an increased abdominal size?</label>
                                <input type="radio" id="clinicinfo2_Expabdominalbloat_IncrSize" name="clinicinfo2_Expabdominalbloat_IncrSize_" value="yes" class="form-control" onClick={() => {
                                    ShowPatienExpabdominalbloatAreaClick2()
                                }} />
                                <label class="form-label" for="clinicinfo2_Expabdominalbloat_IncrSize">Yes</label>
                                <input type="radio" id="clinicinfo2_Expabdominalbloat_IncrSize2" name="clinicinfo2_Expabdominalbloat_IncrSize_" value="no" class="form-control" onClick={() => {
                                    HidePatienExpabdominalbloatAreaClick2()
                                }} />
                                <label class="form-label" for="clinicinfo2_Expabdominalbloat_IncrSize2">No</label>
                            </div>
                        </div>
                    </div>
                    <div id="PatienExpabdominalbloatArea" class=" hidePatienAbdomenPainArea">
                        <div class="row clearfix" >
                            <div class="col-md-6">
                                <label class="form-label" for="">How many days per month?</label>
                                <div class="demo-radio-button">
                                    <input type="radio" id="clinicinfo2_ExpabdominalbloatIncrSize_Day_1" name="clinicinfo2_ExpabdominalbloatIncrSize_Day_" value="0-5 days" class="form-control" />
                                    <label class="form-label" for="clinicinfo2_ExpabdominalbloatIncrSize_Day_1">0-5 days &nbsp; </label>
                                    <input type="radio" id="clinicinfo2_ExpabdominalbloatIncrSize_Day_2" name="clinicinfo2_ExpabdominalbloatIncrSize_Day_" value="6-12 days" class="form-control" />
                                    <label class="form-label" for="clinicinfo2_ExpabdominalbloatIncrSize_Day_2">6-12 days &nbsp; </label>
                                    <input type="radio" id="clinicinfo2_ExpabdominalbloatIncrSize_Day_3" name="clinicinfo2_ExpabdominalbloatIncrSize_Day_" value="more 12 days" class="form-control" />
                                    <label class="form-label" for="clinicinfo2_ExpabdominalbloatIncrSize_Day_3">&gt; 12 days &nbsp; </label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <label class="form-label" for="">For how long?</label>
                                <div class="demo-radio-button">
                                    <input type="radio" id="clinicinfo2_ExpabdominalbloatIncrSize_long_1" name="clinicinfo2_ExpabdominalbloatIncrSize_long_" value="less 1 month" class="form-control" />
                                    <label class="form-label" for="clinicinfo2_ExpabdominalbloatIncrSize_long_1">&lt; 1 month &nbsp; </label>
                                    <input type="radio" id="clinicinfo2_ExpabdominalbloatIncrSize_long_2" name="clinicinfo2_ExpabdominalbloatIncrSize_long_" value="1-6 months" class="form-control" />
                                    <label class="form-label" for="clinicinfo2_ExpabdominalbloatIncrSize_long_2">1-6 months &nbsp; </label>
                                    <input type="radio" id="clinicinfo2_ExpabdominalbloatIncrSize_long_3" name="clinicinfo2_ExpabdominalbloatIncrSize_long_" value="7-12 months" class="form-control" />
                                    <label class="form-label" for="clinicinfo2_ExpabdominalbloatIncrSize_long_3">7-12 months &nbsp; </label>
                                    <input type="radio" id="clinicinfo2_ExpabdominalbloatIncrSize_long_4" name="clinicinfo2_ExpabdominalbloatIncrSize_long_" value="more 12 months" class="form-control" />
                                    <label class="form-label" for="clinicinfo2_ExpabdominalbloatIncrSize_long_4">&gt; 12 months &nbsp; </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12 text-right">
                            <button id={`btnClinicalInformationOVAFormReset`} class="btn btn-light">Reset Form</button>
                            <button id="btnClinicalInformationOVAFormNext" class="btn btn-light">Next</button>
                        </div>
                    </div>

                </div>
            </>
        );
    }
}
