import React, { useState, useEffect } from "react";
//import dotenv from "dotenv";
import axios from "axios";
import { message } from 'antd';
import { protectedResources } from "./../authConfig";
import { MsalContext } from "@azure/msal-react";


import helper from "../utils/helper";
import "datatables.net-dt/css/jquery.dataTables.css";
import "datatables.net-bs/css/dataTables.bootstrap.css";
import moment from "moment";
import Spinner from "../Spinner/Spinner";

import Tabs from './Tabs';
import './Tabs.style.css';

import ReportSearchComponent from "./ReportSearchComponent";

const $ = require("jquery");
$.DataTable = require("datatables.net");
// require( 'datatables.net-bs' )( window, $ );

//dotenv.config();

const API_BASE_URL = helper.getAPIBaseURI();
let me = null;
export default class ReportSearchContent extends React.PureComponent {
  static contextType = MsalContext;

  constructor(props) {
    super(props);

    this.state = {
      orders: [],
      loading: true,
      showLoader: false,

      showOrderModal: false,
      deleteOrderId: null,
      showProgressBar: false,

      startDate: "",
      endDate: "",

      toastMessage: "",
      list: [],
      toastList: [],
      reportType: "Completed",
      activeTab: 'Ova1',
    };

    this.orderDataTable = React.createRef();

    this.handleButtonPress = this.handleButtonPress.bind(this);
    this.handleButtonRelease = this.handleButtonRelease.bind(this);

    me = this;

    this.$el = null;

    this.completed = React.createRef();
    this.amended = React.createRef();
    this.cancelled = React.createRef();
    this.orderIds = React.createRef();
  }
  showToast(type) {
    const id = Math.floor(Math.random() * 101 + 1);

    switch (type) {
      case "success":
        this.toastProperties = {
          id,
          title: "Success",
          description: "This is a success toast component",
          backgroundColor: "#5cb85c",
          icon: "/assets/assets/check.svg",
        };
        break;
      case "danger":
        this.toastProperties = {
          id,
          title: "Danger",
          description: "This is a error toast component",
          backgroundColor: "#d9534f",
          icon: "/assets/assets/error.svg",
        };
        break;
      case "info":
        this.toastProperties = {
          id,
          title: "Info",
          description: "This is an info toast component",
          backgroundColor: "#5bc0de",
          icon: "/assets/assets/info.svg",
        };
        break;
      case "warning":
        this.toastProperties = {
          id,
          title: "Warning",
          description: "This is a warning toast component",
          backgroundColor: "#f0ad4e",
          icon: "/assets/assets/warning.svg",
        };
        break;

      default:
        this.setState({ toastList: [] });
    }

    // this.setState([...this.state.list, this.toastProperties]);
    this.setState({ list: [this.toastProperties] });
  }
  handleButtonPress() {
    me.buttonPressTimer = setTimeout(
      () => me.setState({ showProgressBar: true }),
      1000
    );
  }

  handleButtonRelease() {
    clearTimeout(me.buttonPressTimer);
  }
  callDataTable(datatotables) {
    const me = this;

    const user = JSON.parse(localStorage.getItem("user"));

    // first time, el will be null
    // see: https://reactjs.org/docs/refs-and-the-dom.html#caveats-with-callback-refs

    console.log("me.orderDataTable: ", me.orderDataTable);

    if (!me.orderDataTable) return;

    console.log("datatotables: ", datatotables);

    if (me.$el) {
      me.$el.DataTable().clear().destroy();
    }

    me.$el = $(me.orderDataTable);
    //  console.log(me.$el.DataTable);

    if (me.state.activeTab === 'Ova1') {
      me.$el.DataTable({
        pageLength: 25,
        order: [],
        data: datatotables,
        columns: [
          {
            title: "Order ID",
            data: "orderId",
            name: "orderId",
            // width: "10px",
            sortable: true,
          },
          {
            title: "Patient First Name",
            data: "patientFirstName",
            name: "patientFirstName",
            // width: "10px",
            sortable: true,
          },
          {
            title: "Patient Last Name",
            data: "patientLastName",
            name: "patientLastName",
            // width: "10px",
            sortable: true,
          },
          {
            title: "Client ID",
            data: "legacyClientId",
            name: "legacyClientId",
            // width: "10px",
            sortable: true,
          },
          {
            title: "Report Status",
            data: "reportStatus",
            name: "reportStatus",
            // width: "10px",
            sortable: true,
          },

          {
            title: "",
            name: "",
            data: null,
            sortable: false,
            render: function (data, type) {
              if (type === "display") {
                console.log("uploadDownloadClass->data: ", data);

                return `<td>
                                    <a style="cursor: pointer;" class="retrieveClass" data-orderid="${data.orderId}" data-reportid="${data.reportId}">Retrieve</a>
                                    | <a style="cursor: pointer;" class="sendClass" data-orderid="${data.orderId}" data-reportid="${data.reportId}">Send</a>
                                </td> `;
              }
              return data;
            },
          },
        ],
      });

      me.$el.on("click", "tr a.retrieveClass", function () {
        let custom_formatted_id = $(this).data("orderid");
        let report_id = $(this).data("reportid");

        document.body.classList.add('busy-cursor');

        me.setState(
          { loading: false, showLoader: true, }
          , async () => {

            const accessToken = await helper.getAccessToken(me.context);

            if (report_id) {
              const _payload = {
                orderId: custom_formatted_id,
                reportId: report_id,
                sendReport: false,
              }
              axios({
                url: `${API_BASE_URL}/ova1/retrieveOrSendReport`,
                method: "POST",
                headers: {
                  "Content-type": "Application/json",
                  Authorization: `Bearer ${accessToken}`,
                },
                data: _payload,
              }).then(({ data }) => {
                console.log("data: ", data);
                document.body.classList.remove('busy-cursor');

                me.setState(
                  {
                    loading: false,
                    showLoader: false,
                    toastMessage: data.message,
                  },
                  () => {
                    //me.showToast("success");
                    if (data.status) {
                      message.success(me.state.toastMessage);
                    }
                    else {
                      message.warn(`Error: ${me.state.toastMessage}`);
                    }
                  }
                );
              });
            }
          });
      });
      me.$el.on("click", "tr a.sendClass", function () {
        let custom_formatted_id = $(this).data("orderid");
        let report_id = $(this).data("reportid");

        document.body.classList.add('busy-cursor');

        me.setState(
          { loading: false, showLoader: true, }
          , async () => {

            const accessToken = await helper.getAccessToken(me.context);

            if (report_id) {
              const _payload = {
                orderId: custom_formatted_id,
                reportId: report_id,
                sendReport: true,
              }
              axios({
                url: `${API_BASE_URL}/ova1/retrieveOrSendReport`,
                method: "POST",
                headers: {
                  "Content-type": "Application/json",
                  Authorization: `Bearer ${accessToken}`,
                },
                data: _payload,
              }).then(({ data }) => {
                console.log("data: ", data);
                document.body.classList.remove('busy-cursor');

                me.setState(
                  {
                    loading: false,
                    showLoader: false,
                    toastMessage: data.message,
                  },
                  () => {
                    //me.showToast("success");
                    if (data.status) {
                      message.success(me.state.toastMessage);
                    }
                    else {
                      message.warn(`Error: ${me.state.toastMessage}`);
                    }
                  }
                );
              });
            }
          });
      });
    }
    else if (me.state.activeTab === 'Genetix') {
      me.$el.DataTable({
        pageLength: 25,
        order: [],
        data: datatotables,
        columns: [
          {
            title: "QBench Order ID",
            data: "orderId",
            name: "orderId",
            // width: "10px",
            sortable: true,
          },
          {
            title: "Report ID",
            data: null,
            name: "baylorReportId",
            // width: "10px",
            sortable: true,
            render: function (data, type) {

              if (type === 'display') {

                if (typeof data.galenReportId === 'undefined' || !data.galenReportId) {
                  return `<td>
                                <span>${data.baylorReportId}</td>
                            </td> `;
                }
                else {
                  return `<td>
                                <span>${data.galenReportId}</td>
                            </td> `;
                }
              }

              return data;
            },
          },
          {
            title: "Status",
            data: null,
            name: "status",
            // width: "150px",
            render: function (data, type) {
              if (type == 'display') {
                if (typeof data.status == 'undefined' || !data.status) {
                  return "";
                }
                else {
                  return `<td>
                                <span class="status_font label">${data.status}</td>
                            </td> `;
                }
              }
              return data;
            },
          },
          {
            title: "Baylor Report Status",
            data: "baylorReportStatus",
            name: "baylorReportStatus",
          },

          {
            title: "Baylor Report Conclusion",
            data: null,
            name: "baylorReportConclusion",
            // width: "150px",
            render: function (data, type) {

              if (type == 'display') {
                if (typeof data.baylorReportConclusion == 'undefined' || !data.baylorReportConclusion) {
                  return "";
                }
                else {
                  let imgPath = "";
                  if (data.baylorReportConclusion == 'Positive' || data.baylorReportConclusion == 'positive') {
                    imgPath = `/icons/Positive.png`;
                  }
                  else if (data.baylorReportConclusion == 'VUS') {
                    imgPath = `/icons/VUS.png`;
                  }
                  else if (data.baylorReportConclusion == "Cancelled" ||
                    data.baylorReportConclusion == "cancelled") {
                    imgPath = `/icons/cancelled.png`;
                  }
                  else {
                    imgPath = `/icons/Negative.png`;
                  }
                  return `<td>
                                
                <img style="width: 40; height: 40;" class="positive_negative_image" src="${imgPath}" />
                </td>
            </td>`;

                }
              }
              return data;
            },
          },
          {
            title: "",
            name: "",
            data: null,
            sortable: false,
            render: function (data, type) {
              if (type === "display") {
                console.log("uploadDownloadClass->data: ", data);

                return `<td>
                                    <a style="cursor: pointer;" class="uploadReportToQbenchClass" data-orderid="${data.orderId}" data-reportid="${data.baylorReportId}" data-galenreportid="${data.galenReportId}" data-reportdate="${data.reportCompletionDateString}">Retrieve</a>
                                    | <a style="cursor: pointer;" class="downloadReportClass" data-orderid="${data.orderId}" data-reportid="${data.baylorReportId}" data-galenreportid="${data.galenReportId}">Download</a>
                                </td> `;
              }
              return data;
            },
          },
        ],
      });

      me.$el.on("click", "tr a.uploadReportToQbenchClass", function () {
        let custom_formatted_id = $(this).data("orderid");
        let report_id = $(this).data("reportid");
        let galen_report_id = $(this).data("galenreportid");

        let reportDate = $(this).data("reportdate");

        document.body.classList.add('busy-cursor');

        me.setState(
          { loading: false, showLoader: true, }
          , async () => {

            const accessToken = await helper.getAccessToken(me.context);

            if (report_id) {
              const _payload = {
                orderId: custom_formatted_id,
                reportId: report_id,
                reportCompletionDateString: reportDate
              }
              axios({
                url: `${API_BASE_URL}/baylor/retrieveReport`,
                method: "POST",
                headers: {
                  "Content-type": "Application/json",
                  Authorization: `Bearer ${accessToken}`,
                },
                data: _payload,
              }).then(({ data }) => {
                console.log("data: ", data);
                document.body.classList.remove('busy-cursor');

                me.setState(
                  {
                    loading: false,
                    showLoader: false,
                    toastMessage: `Successfully Retrieved the Report`,
                  },
                  () => {
                    //me.showToast("success");
                    message.success(me.state.toastMessage);
                  }
                );
              });
            }
            else if (galen_report_id) {
              const _payload = {
                orderId: custom_formatted_id,
                reportId: galen_report_id,
                reportCompletionDateString: reportDate
              }
              axios({
                url: `${API_BASE_URL}/galen/retrieveReport`,
                method: "POST",
                headers: {
                  "Content-type": "Application/json",
                  Authorization: `Bearer ${accessToken}`,
                },
                data: _payload,
              }).then(({ data }) => {
                console.log("data: ", data);
                document.body.classList.remove('busy-cursor');

                me.setState(
                  {
                    loading: false,
                    showLoader: false,
                    toastMessage: `Successfully Retrieved the Report`,
                  },
                  () => {
                    //me.showToast("success");
                    message.success(me.state.toastMessage);
                  }
                );
              });
            }
          });
      });
      me.$el.on("click", "tr a.downloadReportClass", function () {
        let custom_formatted_id = $(this).data("orderid");
        let report_id = $(this).data("reportid");

        document.body.classList.add('busy-cursor');

        me.setState(
          { loading: false, showLoader: true, }
          , async () => {
            const accessToken = await helper.getAccessToken(me.context);

            axios({
              url: `${API_BASE_URL}/baylor/reports/${custom_formatted_id}/signedUrl`,
              method: "GET",
              headers: {
                "Content-type": "Application/json",
                Authorization: `Bearer ${accessToken}`,
              },
              // data: _payload,
            }).then(({ data }) => {
              console.log("data: ", data);

              window.open(data, '_blank');

              document.body.classList.remove('busy-cursor');

              me.setState(
                {
                  loading: false,
                  showLoader: false,
                  toastMessage: `Successfully Downloaded the Report`,
                },
                () => {
                  //me.showToast("success");
                  message.success(me.state.toastMessage);
                }
              );
            }).catch((error) => {
              document.body.classList.remove('busy-cursor');

              me.setState(
                {
                  loading: false,
                  showLoader: false,
                  toastMessage: `Failed with error: ${error}`,
                },
                () => {
                  //me.showToast("danger");
                  message.error(me.state.toastMessage);
                }
              );

            })
          });
      });
    }

  }
  componentDidMount() {
    me.setState({
      loading: false,
    }, async () => {

    });
  }

  showModal = (e) => {
    me.setState({
      showOrderModal: !me.state.showOrderModal,
    });
  };

  handleChangeReportType(event) {
    var reportType = event.target.value.trim();

    console.log("handleChangeReportType->reportType: ", reportType)
    me.setState({
      reportType: reportType
    });
  }
  async handleSearch(activeTab) {

    switch (activeTab) {
      case 'Genetix':
        const _reportType = me.state.reportType;
        console.log("1. _reportType: ", _reportType);

        const start_date = moment(me.state.startDate).format("MM/DD/YYYY");
        const end_date = moment(me.state.endDate).format("MM/DD/YYYY");

        if (start_date.length < 1 || start_date == 'Invalid date') {
          message.warn("Start date is required");
          return;
        }

        me.setState({
          loading: true,
        }, async () => {
          const accessToken = await helper.getAccessToken(this.context)

          // const start_date = moment(me.state.startDate).format("MM/DD/YYYY");
          // const end_date = moment(me.state.endDate).format("MM/DD/YYYY");
          let _url = `${API_BASE_URL}/baylor/completedOrders?startCompletedDate=${start_date}`;

          if (end_date.length > 0 && end_date != 'Invalid date') {
            _url = `${_url}&endCompletedDate=${end_date}`;
          }

          _url = `${_url}&reportType=${_reportType}`;

          document.body.classList.add('busy-cursor');

          axios({
            url: _url,
            method: "GET",
            headers: {
              "Content-type": "Application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }).then(({ data }) => {
            console.log("data: ", data);

            me.setState({ loading: false, activeTab: activeTab, }, () => {

              console.log("_reportType: ", _reportType);
              console.log("me.state.reportType: ", me.state.reportType);


              me.callDataTable(data);

              document.body.classList.remove('busy-cursor');
            });
          })
            .catch((error) => {
              me.setState({ loading: false, activeTab: activeTab, }, () => {

                message.info(`No Orders found.`);

                document.body.classList.remove('busy-cursor');
              });

            })

        })
        break;
      default: //Ova1

        const orderIds = me.orderIds.value;

        if (orderIds.length < 1) {
          message.warn("Order Id is required.");
          return;
        }

        me.setState({
          loading: true,
        }, async () => {
          const accessToken = await helper.getAccessToken(this.context)

          let _url = `${API_BASE_URL}/ova1/completedOrders?orderIds=${orderIds}`;

          document.body.classList.add('busy-cursor');

          axios({
            url: _url,
            method: "GET",
            headers: {
              "Content-type": "Application/json",
              Authorization: `Bearer ${accessToken}`,
            },
          }).then(({ data }) => {
            console.log("data: ", data);

            me.setState({ loading: false, activeTab: activeTab, }, () => {

              if (data.length > 0) {
                me.callDataTable(data);
              }
              else {
                message.info(`No Report found.`);
              }
              document.body.classList.remove('busy-cursor');
            });
          })
            .catch((error) => {
              me.setState({ loading: false, activeTab: activeTab, }, () => {

                message.info(`Order not found.`);

                document.body.classList.remove('busy-cursor');
              });

            })

        })
        break;
    }
  }

  onChangeStartDate1(_date) {
    me.setState({ startDate: _date });
  }
  onChangeEndDate2(_date) {
    me.setState({ endDate: _date });
  }
  render() {
    if (!me.state.loading) {
      return (
        <Tabs activeTab={me.state.activeTab}>
          <div label="Ova1">
            <ReportSearchComponent state={me.state} activeTab="Ova1" me={this} />
          </div>
          <div label="Genetix">
            <ReportSearchComponent state={me.state} activeTab="Genetix" me={this} />

          </div>

        </Tabs>
      )

    } else {
      return (
        <div>
          <div class="block-header">
            <h2>Orders</h2>
            <ul class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="/orders">Orders</a>
              </li>
              <li class="breadcrumb-item active">View All Orders</li>
            </ul>
          </div>
          <div class="row clearfix">
            <div class="col-md-12 col-xs-12">
              <div class="card orders-list">
                <div class="body">
                  <Spinner />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
