import axios from "axios";
import React from "react";
import { useLocation, Link } from 'react-router-dom';
import { protectedResources } from "./../authConfig";
import helper from "../utils/helper";
import { MsalContext } from "@azure/msal-react";

const API_BASE_URL = helper.getAPIBaseURI();


export default class LeftSidebar extends React.PureComponent {
    static contextType = MsalContext
    constructor(props) {
        super(props);

        this.state = {
            user: null,
            allRoles: [],
        };

        // this.location = useLocation();
    }

    async componentDidMount() {
        const user = JSON.parse(localStorage.getItem("user"));
        if (user) {
            console.log("LeftSideBar.js->user: ", user);
            this.setState({ allRoles: user.allRoles, user });

        } else {
            await this.loadUserInfo(user);
        }
    }

    async loadUserInfo(userExisting) {

        let token = {};

        if (process.env.REACT_APP_ENV !== 'DEV') {
            token = await this.context.instance.acquireTokenSilent({
                scopes: protectedResources.apiOrders.scopes,
                account: this.context.accounts[0]
            });

            const { data: { user } } = await axios.get(`${API_BASE_URL}/users/current`, {
                headers: {
                    "Authorization": `Bearer ${token.accessToken}`
                }
            });

            localStorage.setItem("user", JSON.stringify(user));
            this.setState({ allRoles: user.allRoles, user });
        }
        else if (userExisting) {
            localStorage.setItem("user", JSON.stringify(userExisting));
            this.setState({ allRoles: userExisting.allRoles, user: userExisting });
        }

    };

    isCurrentUserAnAdmin = () => {
        const user = JSON.parse(localStorage.getItem("user"));

        for (let role of user.allRoles)
            if (role.isAdmin) return true;

        return false;
    }

    getRolesName = (_allRoles) => {

        let retRole = "", i = 0;
        for (; i < _allRoles.length - 1; ++i) {

            retRole += _allRoles[i].name + ',';
        }

        retRole += _allRoles[i].name;

        return retRole;
    }
    render() {
        if (this.state.user === null) {
            return null;
        }
        return (
            <aside id="leftsidebar" class="sidebar">
                <div class="menu">
                    <ul class="list">
                        <li> {/* User Info */}
                            <div class="user-info">
                                <div class="admin-image"><img src="assets/images/logo_aspira_.png" alt="profile img" /></div>
                                <div class="admin-action-info"> <span>Welcome</span>
                                    <h3 style={{ color: "white" }}>{this.state.user.firstName}&nbsp;{this.state.user.lastName}</h3>
                                    <ul>

                                        <li><a data-placement="bottom" title="Go to Profile" href={`/profile-user?id=${this.state.user._id}`}><i class="zmdi zmdi-account"></i></a></li>

                                    </ul>
                                </div>
                            </div>
                        </li> {/* User Info */}
                        <li class="header">MAIN NAVIGATION</li>

                        {this.isCurrentUserAnAdmin() &&
                            <li class={(window.location.pathname === '/dashboard') ? "active open" : ""} >
                                <a href="/dashboard"><i class="zmdi zmdi-home"></i><span>Dashboard</span> </a>
                            </li>
                        }
                        {/* {(this.state.user.email === 'pierre.davidoff@gmail.com' || this.state.user.email === 'alamrezoanul@gmail.com') &&
                            <li class={(
                                window.location.pathname === '/new-accessions' ||
                                window.location.pathname === '/billing-ready'
                                || window.location.pathname === '/billing-review'
                                || window.location.pathname === '/benefits-review'
                                || window.location.pathname === '/waiting-cmt-auth'
                                || window.location.pathname === '/internal-auth'
                                || window.location.pathname === '/resulted-report'
                                || window.location.pathname === '/research-orders-only'
                                || window.location.pathname === '/my-accessions'
                            ) ? "active open" : ""}><a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-account-add"></i><span>Billing</span> </a>
                                <ul class="ml-menu">
                                    <li class={(window.location.pathname === '/new-accessions') ? "active" : ""}> <a href="/new-accessions">New Accessions</a></li>
                                    <li class={(window.location.pathname === '/billing-review') ? "active" : ""}> <a href="/billing-review">Billing Review</a></li>
                                    <li class={(window.location.pathname === '/benefits-review') ? "active" : ""}> <a href="/benefits-review">Benefits Review</a></li>
                                    <li class={(window.location.pathname === '/internal-auth') ? "active" : ""}> <a href="/internal-auth">Internal Authorization</a></li>
                                    <li class={(window.location.pathname === '/billing-ready') ? "active" : ""}> <a href="/billing-ready">Billing Ready</a></li>
                                    <li class={(window.location.pathname === '/waiting-cmt-auth') ? "active" : ""}> <a href="/waiting-cmt-auth">Waiting CMT Auth</a></li>
                                    <li class={(window.location.pathname === '/resulted-report') ? "active" : ""}> <a href="/resulted-report">Resulted Report</a></li>
                                    <li class={(window.location.pathname === '/research-orders-only') ? "active" : ""}> <a href="/research-orders-only">Research Orders Only</a></li>
                                    <li class={(window.location.pathname === '/my-accessions') ? "active" : ""}> <a href="/my-accessions">My Accessions</a></li>
                                </ul>
                            </li>
                        } */}
                        {/* {(this.state.allRoles.some(x => x.name === "Portal_Billing")
                            || this.state.allRoles.some(x => x.name === "Portal_Benefits_Reviewer")
                            || this.isCurrentUserAnAdmin()) &&
                            <li class={(
                                // window.location.pathname === '/new-accessions' ||
                                window.location.pathname === '/billing-ready'
                                || window.location.pathname === '/billing-review'
                                || window.location.pathname === '/benefits-review'
                                || window.location.pathname === '/waiting-cmt-auth'
                                || window.location.pathname === '/internal-auth'
                                || window.location.pathname === '/resulted-report'
                                || window.location.pathname === '/research-orders-only'
                                || window.location.pathname === '/my-accessions'
                            ) ? "active open" : ""}><a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-account-add"></i><span>Billing</span> </a>
                                <ul class="ml-menu">
                                    <li class={(window.location.pathname === '/new-accessions') ? "active" : ""}> <a href="/new-accessions">New Accessions</a></li>
                                    <li class={(window.location.pathname === '/billing-review') ? "active" : ""}> <a href="/billing-review">Billing Review</a></li>
                                    <li class={(window.location.pathname === '/benefits-review') ? "active" : ""}> <a href="/benefits-review">Benefits Review</a></li>
                                    <li class={(window.location.pathname === '/internal-auth') ? "active" : ""}> <a href="/internal-auth">Internal Authorization</a></li>
                                    <li class={(window.location.pathname === '/billing-ready') ? "active" : ""}> <a href="/billing-ready">Billing Ready</a></li>
                                    <li class={(window.location.pathname === '/waiting-cmt-auth') ? "active" : ""}> <a href="/waiting-cmt-auth">Waiting CMT Auth</a></li>
                                    <li class={(window.location.pathname === '/resulted-report') ? "active" : ""}> <a href="/resulted-report">Resulted Report</a></li>
                                    <li class={(window.location.pathname === '/research-orders-only') ? "active" : ""}> <a href="/research-orders-only">Research Orders Only</a></li>
                                    <li class={(window.location.pathname === '/my-accessions') ? "active" : ""}> <a href="/my-accessions">My Accessions</a></li>
                                </ul>
                            </li>
                        } */}
                        {this.isCurrentUserAnAdmin() &&
                            <li class={(window.location.pathname === '/audit' || window.location.pathname === '/addeditclient' || window.location.pathname === '/clientlist') ? "active open" : ""}><a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-account-add"></i><span>Admin</span> </a>
                                <ul class="ml-menu">
                                    <li class={(window.location.pathname === '/clientlist' ? "active" : "")}>
                                        <a href="/clientlist">Client List</a>
                                    </li>
                                    <li class={(window.location.pathname === '/addeditclient' ? "active" : "")}>
                                        <a href="/addeditclient">Add New Client</a>
                                    </li>
                                    <li class={(window.location.pathname === '/audit') ? "active" : ""}> <a href="/audit">Audit</a></li>
                                    {/* <li class={(window.location.pathname === '/adduser') ? "active" : ""}> <a href="/adduser">Add User</a></li>
                                    <li class={(window.location.pathname === '/profile-user') ? "active" : ""}> <a href="/profile-user">User Profile</a></li> */}
                                </ul>
                            </li>
                        }
                        {this.isCurrentUserAnAdmin() &&
                            <li class={(window.location.pathname === '/users' || window.location.pathname === '/adduser' || window.location.pathname === '/profile-user') ? "active open" : ""}><a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-account-add"></i><span>Users</span> </a>
                                <ul class="ml-menu">
                                    <li class={(window.location.pathname === '/users') ? "active" : ""}> <a href="/users">All Users</a></li>
                                    <li class={(window.location.pathname === '/adduser') ? "active" : ""}> <a href="/adduser">Add User</a></li>
                                    <li class={(window.location.pathname === '/profile-user') ? "active" : ""}> <a href={`/profile-user?id=${this.state.user.id}`}>User Profile</a></li>
                                </ul>
                            </li>


                        }

                        {this.isCurrentUserAnAdmin() && <>
                            {/* <li class={(window.location.pathname === '/patients' || window.location.pathname === '/addpatient' || window.location.pathname === '/patient-profile' || window.location.pathname === '/patient-invoice') ? "active open" : ""}><a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-account-o"></i><span>Patients</span> </a>
                                <ul class="ml-menu">
                                    <li class={(window.location.pathname === '/patients') ? "active" : ""}> <a href="/patients">All Patients</a> </li>
                                    <li class={(window.location.pathname === '/addpatient') ? "active" : ""}> <a href="/addpatient">Add Patient</a></li>
                                    <li class={(window.location.pathname === '/patient-profile') ? "active" : ""}> <a href="/patient-profile">Patient Profile</a> </li>
                                    <li class={(window.location.pathname === '/patient-invoice') ? "active" : ""}> <a href="/patient-invoice">Patient Invoice</a> </li>
                                </ul>
                            </li> */}
                            <li class={(window.location.pathname === '/research-orders-filter' || window.location.pathname === '/orders' || window.location.pathname === '/addorder' || window.location.pathname === '/editorder' || window.location.pathname === '/order-invoice') || window.location.pathname === '/research-orders' || window.location.pathname === "/provider-order" ? "active open" : ""}><a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-balance-wallet"></i><span>Orders</span> </a>
                                <ul class="ml-menu">
                                    <li class={(window.location.pathname === '/provider-order') ? "active" : ""}><a href="/provider-order">Add new order</a> </li>
                                    <li class={(window.location.pathname === '/orders') ? "active" : ""}><a href="/orders">All Orders</a> </li>

                                    <li class={(window.location.pathname === "/research-orders") ? "active" : ""}>
                                        <a href="/research-orders">Research Orders</a>
                                    </li>
                                    <li class={(window.location.pathname === "/research-orders-filter") ? "active" : ""}>
                                        <a href="/research-orders-filter">Research Orders With Filter</a>
                                    </li>
                                </ul>
                            </li>
                            <li class={
                                (window.location.pathname === '/report_search'
                                    || window.location.pathname === '/report_review'
                                    || window.location.pathname === '/resulted_report'
                                    || window.location.pathname === '/positive_report'
                                    || window.location.pathname === '/on_hold_report'
                                )
                                    ? "active open" : ""}><a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-balance-wallet"></i><span>Report</span> </a>
                                <ul class="ml-menu">
                                    <li class={(window.location.pathname === '/report_search') ? "active" : ""}><a href="/report_search">Search</a> </li>
                                    <li class={(window.location.pathname === '/report_review') ? "active" : ""}><a href="/report_review">Pending Report Review</a> </li>

                                    <li class={(window.location.pathname === '/resulted_report') ? "active" : ""}><a href="/resulted_report">Resulted Report</a> </li>
                                    <li class={(window.location.pathname === '/positive_report') ? "active" : ""}><a href="/positive_report">Positive Report</a> </li>
                                    <li class={(window.location.pathname === '/on_hold_report') ? "active" : ""}><a href="/on_hold_report">On Hold Reports</a> </li>
                                </ul>
                            </li>
                            <li class={
                                (window.location.pathname === '/successfull_faxes'
                                    || window.location.pathname === '/failed_faxes'
                                    || window.location.pathname === '/qeued_faxes'
                                )
                                    ? "active open" : ""}><a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-balance-wallet"></i><span>Faxing Report</span> </a>
                                <ul class="ml-menu">
                                    <li className={(window.location.pathname === "/qeued_faxes") ? "active" : ""}>
                                        <a href="/qeued_faxes">Qeued Faxes</a>
                                    </li>
                                    <li
                                        class={(window.location.pathname === '/successfull_faxes') ? "active" : ""}
                                    >
                                        <a href="/successfull_faxes">Successfull Faxes</a>
                                    </li>
                                    <li
                                        class={(window.location.pathname === '/failed_faxes') ? "active" : ""}
                                    >
                                        <a href="/failed_faxes">Failed Faxes</a>
                                    </li>
                                </ul>
                            </li>


                        </>
                        }

                        {(this.state.allRoles.some(x => x.name === 'Portal_Report_Viewer') || this.state.allRoles.some(x => x.name === 'Portal_Report_Correction')) && !this.isCurrentUserAnAdmin() && <>
                            <li class={
                                (window.location.pathname === '/resulted_report'
                                    || window.location.pathname === '/positive_report'
                                )
                                    ? "active open" : ""}><a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-balance-wallet"></i><span>Report</span> </a>
                                <ul class="ml-menu">
                                    <li class={(window.location.pathname === '/resulted_report') ? "active" : ""}><a href="/resulted_report">Resulted Report</a> </li>
                                    <li class={(window.location.pathname === '/positive_report') ? "active" : ""}><a href="/positive_report">Positive Report</a> </li>
                                </ul>
                            </li>
                        </>
                        }

                        {!this.isCurrentUserAnAdmin() && (this.state.allRoles.some(x => x.name === 'Portal_Clinical_Reviewer') || this.state.allRoles.some(x => x.name === 'Portal_Lab_Reviewer')) && <>
                            <li class={
                                (window.location.pathname === '/report_review' || window.location.pathname === '/on_hold_report'
                                )
                                    ? "active open" : ""}><a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-balance-wallet"></i><span>Report</span> </a>
                                <ul class="ml-menu">
                                    <li class={(window.location.pathname === '/report_review') ? "active" : ""}><a href="/report_review">Pending Report Review</a> </li>
                                    <li class={(window.location.pathname === '/on_hold_report') ? "active" : ""}><a href="/on_hold_report">On Hold Reports</a> </li>
                                </ul>
                            </li>
                        </>
                        }
                        {this.state.allRoles.some(x => x.name === "Portal_Customer_Service") && !this.isCurrentUserAnAdmin() && <>
                            <li class={(window.location.pathname === '/orders' || window.location.pathname === '/addorder' || window.location.pathname === '/editorder' || window.location.pathname === '/order-invoice') || window.location.pathname === '/research-orders' || window.location.pathname === "/provider-order" ? "active open" : ""}><a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-balance-wallet"></i><span>Orders</span> </a>
                                <ul class="ml-menu">
                                    <li class={(window.location.pathname === '/provider-order') ? "active" : ""}><a href="/provider-order">Add new order</a> </li>
                                    <li class={(window.location.pathname === '/orders') ? "active" : ""}><a href="/orders">All Orders</a> </li>
                                    {/* <li class={(window.location.pathname === '/addorder') ? "active" : ""}><a href="/addorder">Add Order</a> </li> */}
                                    <li class={(window.location.pathname === "/research-orders") ? "active" : ""}>
                                        <a href="/research-orders">Research Orders</a>
                                    </li>
                                </ul>
                            </li>
                            <li class={
                                (window.location.pathname === '/resulted_report'
                                    || window.location.pathname === '/positive_report'
                                )
                                    ? "active open" : ""}><a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-balance-wallet"></i><span>Report</span> </a>
                                <ul class="ml-menu">
                                    <li class={(window.location.pathname === '/resulted_report') ? "active" : ""}><a href="/resulted_report">Resulted Report</a> </li>
                                    <li class={(window.location.pathname === '/positive_report') ? "active" : ""}><a href="/positive_report">Positive Report</a> </li>
                                </ul>
                            </li>
                            <li class={
                                (window.location.pathname === '/successfull_faxes'
                                    || window.location.pathname === '/failed_faxes'
                                    || window.location.pathname === '/qeued_faxes'
                                )
                                    ? "active open" : ""}><a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-balance-wallet"></i><span>Faxing Report</span> </a>
                                <ul class="ml-menu">
                                    <li className={(window.location.pathname === "/qeued_faxes") ? "active" : ""}>
                                        <a href="/qeued_faxes">Qeued Faxes</a>
                                    </li>
                                    <li
                                        class={(window.location.pathname === '/successfull_faxes') ? "active" : ""}
                                    >
                                        <a href="/successfull_faxes">Successfull Faxes</a>
                                    </li>
                                    <li
                                        class={(window.location.pathname === '/failed_faxes') ? "active" : ""}
                                    >
                                        <a href="/failed_faxes">Failed Faxes</a>
                                    </li>
                                </ul>
                            </li>
                        </>
                        }
                        {this.state.allRoles.some(x => x.name === "Portal_Report_Distribution") && !this.isCurrentUserAnAdmin() && <>
                            <li class={(window.location.pathname === '/positive_report') ? "active open" : ""}>
                                <a href="javascript:void(0);" class="menu-toggle"><i class="zmdi zmdi-balance-wallet"></i><span>Report</span> </a>
                                <ul class="ml-menu">
                                    <li class={(window.location.pathname === '/positive_report') ? "active" : ""}><a href="/positive_report">Positive Report</a> </li>
                                </ul>
                            </li>
                        </>
                        }
                    </ul>
                </div>
            </aside >
        );
    }
}
