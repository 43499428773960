import React, { PureComponent } from "react";

import PropTypes from 'prop-types';
import "./AppPortalTheme.css";
import $ from 'jquery';

//includes all 3 forms
import Ova1PlusFormContent from "./portalForm/Ova1PlusFormContent";
import GenetiXCarrierScreeningForm from "./portalForm/GenetiXCarrierScreeningForm";
import GenetiXHBOCForm from "./portalForm/GenetiXHBOCForm";
import Ova1GenetixHBOCForm from "./portalForm/Ova1GenetixHBOCForm";
import AntiSARSCoV19AntibodyForm from "./portalForm/AntiSARSCoV19AntibodyForm";


import { connect } from "react-redux";
import OvaWatchFormContent from "./portalForm/OvaWatchFormContent";
import LeftSidebar from "./layout/LeftSidebar";
import TopBar from "./layout/TopBar";


const ProviderOrderPortal = props => {

  console.log("ProviderOrderPortal->props: ", props);


  function hideOvaFormForProduction() {
    console.log("REACT_APP_ENV: ", process.env.REACT_APP_ENV);
    if (process.env.REACT_APP_ENV === 'DEV' || process.env.REACT_APP_ENV === 'STAGING') {
      $('#product_5').show();
    }
    else {
      $('#product_5').hide();
    }
  }
  hideOvaFormForProduction();

  function showOvaForm() {
    $("#ovaForm").addClass(" showForm");
    $("#ovaForm").removeClass(" hideForm");
  }

  function showGenetiXCarrierScreeningFormForm() {
    $("#GenetiXCarrierScreeningForm").addClass(" showForm");
    $("#GenetiXCarrierScreeningForm").removeClass(" hideForm");
  }

  function showGenetiXHBOCForm() {
    $("#GenetiXHBOCForm").addClass(" showForm");
    $("#GenetiXHBOCForm").removeClass(" hideForm");
  }

  function showGenetiXovaFormForm() {
    $("#GenetiXovaForm").addClass(" showForm");
    $("#GenetiXovaForm").removeClass(" hideForm");
  }

  function showAntiSARSCoV19AntibodyForm() {
    $("#AntiSARSCoV19AntibodyForm").addClass(" showForm");
    $("#AntiSARSCoV19AntibodyForm").removeClass(" hideForm");
  }

  function hideOvaForm() {
    $("#ovaForm").addClass(" hideForm");
    $("#ovaForm").removeClass(" showForm");
  }

  function hideGenetiXCarrierScreeningFormForm() {
    $("#GenetiXCarrierScreeningForm").addClass(" hideForm");
    $("#GenetiXCarrierScreeningForm").removeClass(" showForm");
  }

  function hideGenetiXHBOCForm() {
    $("#GenetiXHBOCForm").addClass(" hideForm");
    $("#GenetiXHBOCForm").removeClass(" showForm");
  }

  function hideGenetiXovaFormForm() {
    $("#GenetiXovaForm").addClass(" hideForm");
    $("#GenetiXovaForm").removeClass(" showForm");
  }

  function hideAntiSARSCoV19AntibodyForm() {
    $("#AntiSARSCoV19AntibodyForm").addClass(" hideForm");
    $("#AntiSARSCoV19AntibodyForm").removeClass(" showForm");
  }

  const showOvaWatchForm = () => {
    $("#ovaWatchForm").removeClass("hideForm");
    $("#ovaWatchForm").addClass("showForm");
  }

  const hideOvaWatchForm = () => {
    $("#ovaWatchForm").removeClass("showForm");
    $("#ovaWatchForm").addClass("hideForm");
  }

  function checkAnyClicked() {

    // var product_1 = document.getElementById("product_1");
    var product_2 = document.getElementById("product_2");
    var product_3 = document.getElementById("product_3");
    var product_4 = document.getElementById("product_4");

    // if (product_3.checked == true && product_1.checked == true) {

    //   hideOvaForm();
    //   hideGenetiXCarrierScreeningFormForm();
    //   hideGenetiXHBOCForm();
    //   showGenetiXovaFormForm();
    //   hideAntiSARSCoV19AntibodyForm();
    //   document.getElementById("product_2").disabled = true;
    //   //document.getElementById("product_4").disabled = true;

    // } else if (product_1.checked == true) {

    //   showOvaForm();
    //   hideGenetiXCarrierScreeningFormForm();
    //   hideGenetiXHBOCForm();
    //   hideGenetiXovaFormForm();
    //   hideAntiSARSCoV19AntibodyForm();

    // } else 
    if (product_2.checked == true) {

      hideOvaForm();
      showGenetiXCarrierScreeningFormForm();
      hideGenetiXHBOCForm();
      hideGenetiXovaFormForm();
      hideAntiSARSCoV19AntibodyForm();

    } else if (product_3.checked == true) {

      hideOvaForm();
      hideGenetiXCarrierScreeningFormForm();
      showGenetiXHBOCForm();
      hideGenetiXovaFormForm();
      hideAntiSARSCoV19AntibodyForm();

    } else if (product_4 && product_4.checked == true) {

      hideOvaForm();
      hideGenetiXCarrierScreeningFormForm();
      hideGenetiXHBOCForm();
      hideGenetiXovaFormForm();
      showAntiSARSCoV19AntibodyForm();
    }

  }


  const product1Click = () => {

    var product_1 = document.getElementById("product_1");
    var product_3 = document.getElementById("product_3");
    document.getElementById("product_2").disabled = false;
    document.getElementById("product_2").checked = false;

    //document.getElementById("product_4").disabled = false;
    //document.getElementById("product_4").checked = false;


    // if (product_3.checked == true && product_1.checked == true) {

    //   hideOvaForm();
    //   hideGenetiXCarrierScreeningFormForm();
    //   hideGenetiXHBOCForm();
    //   showGenetiXovaFormForm();
    //   hideAntiSARSCoV19AntibodyForm();
    //   document.getElementById("product_2").disabled = true;
    //   //document.getElementById("product_4").disabled = true;

    // } else 
    if (product_1.checked == true) {

      showOvaForm();
      hideGenetiXCarrierScreeningFormForm();
      hideGenetiXHBOCForm();
      hideGenetiXovaFormForm();
      hideAntiSARSCoV19AntibodyForm();

    } else {
      hideOvaForm();
      checkAnyClicked();
    }

  };

  const product2Click = () => {
    var product_2 = document.getElementById("product_2");

    //document.getElementById("product_1").checked = false;
    document.getElementById("product_3").checked = false;

    

    if (process.env.REACT_APP_ENV == 'DEV' || process.env.REACT_APP_ENV == 'STAGING') {
      document.getElementById("product_5").disabled = false;
      document.getElementById("product_5").checked = false;

      document.getElementById("product_6").disabled = false;
      document.getElementById("product_6").checked = false;
    }
    //document.getElementById("product_4").checked = false;

    if (product_2.checked == true) {
      hideOvaForm();
      showGenetiXCarrierScreeningFormForm();
      hideGenetiXHBOCForm();
      hideGenetiXovaFormForm();
      hideAntiSARSCoV19AntibodyForm();
      hideOvaWatchForm();
    } else {
      hideGenetiXCarrierScreeningFormForm();
      checkAnyClicked();
    }

  };

  const product3Click = () => {

    var product_3 = document.getElementById("product_3");
    //var product_1 = document.getElementById("product_1");
    document.getElementById("product_2").disabled = false;
    document.getElementById("product_2").checked = false;

    if (process.env.REACT_APP_ENV == 'DEV' || process.env.REACT_APP_ENV == 'STAGING') {
      document.getElementById("product_5").disabled = false;
      document.getElementById("product_5").checked = false;

      document.getElementById("product_6").disabled = false;
      document.getElementById("product_6").checked = false;
    }
    //document.getElementById("product_4").disabled = false;
    //document.getElementById("product_4").checked = false;

    // if (product_3.checked == true && product_1.checked == true) {

    //   hideOvaForm();
    //   hideGenetiXCarrierScreeningFormForm();
    //   hideGenetiXHBOCForm();
    //   showGenetiXovaFormForm();
    //   hideAntiSARSCoV19AntibodyForm();
    //   document.getElementById("product_2").disabled = true;
    //   //document.getElementById("product_4").disabled = true;

    // } else 
    if (product_3.checked == true) {

      hideOvaForm();
      hideGenetiXCarrierScreeningFormForm();
      showGenetiXHBOCForm();
      hideGenetiXovaFormForm();
      hideAntiSARSCoV19AntibodyForm();
      hideOvaWatchForm();
    } else {
      hideGenetiXHBOCForm();
      checkAnyClicked();
    }

  };


  const product4Click = () => {
    var product_4 = document.getElementById("product_4");

    //document.getElementById("product_1").checked = false;
    //document.getElementById("product_2").checked = false;
    //document.getElementById("product_3").checked = false;

    if (product_4 && product_4.checked == true) {
      hideOvaForm();
      hideGenetiXCarrierScreeningFormForm();
      hideGenetiXHBOCForm();
      hideGenetiXovaFormForm();
      showAntiSARSCoV19AntibodyForm();
    } else {
      hideAntiSARSCoV19AntibodyForm();
      checkAnyClicked();
    }

    checkAnyClicked();

  };

  const product5Click = () => {
    var product_5 = document.getElementById("product_5");
    document.getElementById("product_2").disabled = false;
    document.getElementById("product_2").checked = false;
    document.getElementById("product_3").disabled = false;
    document.getElementById("product_3").checked = false;

    document.getElementById("product_6").disabled = false;
    document.getElementById("product_6").checked = false;

    if (product_5.checked == true) {
      showOvaForm();
      hideGenetiXCarrierScreeningFormForm();
      hideGenetiXHBOCForm();
      hideGenetiXovaFormForm();
      hideAntiSARSCoV19AntibodyForm();
      hideOvaWatchForm();
    } else {
      hideOvaForm();
      checkAnyClicked();
    }
  }

  const product6Click = () => {
    const product_6 = document.getElementById("product_6");

    document.getElementById("product_2").disabled = false;
    document.getElementById("product_2").checked = false;

    document.getElementById("product_3").disabled = false;
    document.getElementById("product_3").checked = false;

    document.getElementById("product_5").disabled = false;
    document.getElementById("product_5").checked = false;

    if (product_6.checked) {
      showOvaWatchForm();

      hideGenetiXCarrierScreeningFormForm();
      hideGenetiXHBOCForm();
      hideOvaForm();
      hideAntiSARSCoV19AntibodyForm();
    } else {
      hideOvaWatchForm();
      checkAnyClicked();
    }
  }

  return (
    <>
      {/* Bread Crumb Area */}
      {/* <div className="breadcrumb-area">
        <div class="container-fluid">
          <div class="body">
            <ol class="breadcrumb breadcrumb-col-teal">
              <li><a href="javascript:void(0);"> Home &nbsp;</a></li>
              <li><a href="javascript:void(0);"> Online Orders &nbsp;</a></li>
              <li><a href="javascript:void(0);"> Providers &nbsp;</a></li>
              <li class="active">Order Form</li>
              <p>Provider Order Portal - Order Form</p>
            </ol>
          </div>
        </div>
      </div> */}
      <TopBar />
      <LeftSidebar />
      {/* Content Area */}
      <section class="content home">
        <div class="container-fluid provider_order">
          <h5>Please fill out the form below to request a kit.</h5>

          <p>Select a Product(s)</p>
          <div class="demo-checkbox">
            {/* <input type="checkbox" id="product_1" name="product_1" class="filled-in" onClick={() => {
              product1Click()
            }} />
            <label for="product_1">OVA1 Plus &nbsp; </label> */}
            <input type="checkbox" id="product_2" name="product_2" class="filled-in" onClick={() => {
              product2Click()
            }} />
            <label for="product_2">GenetiX Carrier Screening  &nbsp; </label>
            <input type="checkbox" id="product_3" name="product_3" class="filled-in" onClick={() => {
              product3Click()
            }} />
            <label for="product_3">GenetiX Hereditary Breast and Ovarian Cancer Genetics &nbsp;</label>

            {(process.env.REACT_APP_ENV == 'DEV' || process.env.REACT_APP_ENV == 'STAGING' || window.location.hostname == "lab.aspirawh.com") && <>
              <input type="checkbox" id="product_5" name="product_5" class="filled-in" onClick={() => {
                product5Click();
              }} />
              <label for="product_5">OVA1 &nbsp;</label>
            </>}
            {(process.env.REACT_APP_ENV === "DEV" || process.env.REACT_APP_ENV === "STAGING" || window.location.hostname == "lab.aspirawh.com") &&
              <>
              <input 
                type="checkbox" 
                name="product_6" 
                id="product_6" 
                className="filled-in"
                onClick={product6Click}
              />
              <label htmlFor="product_6">OvaWatch</label>
              </>
            }
            {/* <input type="checkbox" id="product_4" name="product_4" class="filled-in" onClick={() => {
              product4Click()
            }} />
            <label for="product_4">Anti-SARS-CoV-2 (COVID-19 Antibody)</label> */}
          </div>

          {/* form area start */}
          <div class="row clearfix">
            <div class="col-md-12">
              <div class="body">
                <div id="ovaForm" className="formarea hideForm">
                  <Ova1PlusFormContent />
                </div>
                <div id="GenetiXCarrierScreeningForm" className="formarea hideForm">
                  <GenetiXCarrierScreeningForm />
                </div>
                <div id="GenetiXHBOCForm" className="formarea hideForm">
                  <GenetiXHBOCForm />
                </div>
                <div id="GenetiXovaForm" className="formarea hideForm">
                  <Ova1GenetixHBOCForm />
                </div>
                <div id="AntiSARSCoV19AntibodyForm" className="formarea hideForm">
                  <AntiSARSCoV19AntibodyForm />
                </div>
              </div>
              <div id="ovaWatchForm" className="formarea hideForm">
                <OvaWatchFormContent />
              </div>
            </div>
          </div>



        </div>
      </section>
    </>
  );

}

export default ProviderOrderPortal;//connect(mapStateToProps, mapDispatchToProps)(ProviderOrderPortal);