import React, { PureComponent } from "react";

import PropTypes from 'prop-types';
import $ from 'jquery';
import DatePicker from 'react-datepicker';
import moment from 'moment';

export default class BillingInformationHBOCForm extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            billinfoHBOC_dob: "",
        }

    }

    onChangeBillingHBOCDoB = date => {

        this.setState({ billinfoHBOC_dob: date }, () => {
            const dateStr = moment(date).format("YYYY-MM-DD");
            $('#billinfoHBOC_DOB').val(dateStr);

            this.props.billinfoHBOC_dob(dateStr);
        });
    }
    render() {

        return (
            <>
                <div class="panel-body">
                    
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <div class="form-group required">
                                <label class="form-label" for="BillingInformation">Billing Information</label>
                                <div id="billingHBOC_info_container" class="demo-checkbox">
                                    <input type="checkbox" id="billinfoHBOC_PrivateInsurance" name="billinfoHBOC_PrivateInsurance" class="filled-in" />
                                    <label for="billinfoHBOC_PrivateInsurance">Private Insurance &nbsp; </label>
                                    <input type="checkbox" id="billinfoHBOC_Medicare" name="billinfoHBOC_Medicare" class="filled-in" />
                                    <label for="billinfoHBOC_Medicare">Medicare<sup>1</sup> &nbsp; </label>
                                    <input type="checkbox" id="billinfoHBOC_PatientSelfPay" name="billinfoHBOC_PatientSelfPay" class="filled-in" />
                                    <label for="billinfoHBOC_PatientSelfPay">Patient Self-Pay &nbsp; </label>
                                    <input type="checkbox" id="billinfoHBOC_Medicaid" name="billinfoHBOC_Medicaid" class="filled-in" />
                                    <label for="billinfoHBOC_Medicaid">Medicaid &nbsp; </label>
                                    <input type="checkbox" id="billinfoHBOC_OrderingFacility" name="billinfoHBOC_OrderingFacility" class="filled-in" />
                                    <label for="billinfoHBOC_OrderingFacility">Ordering Facility (Client Bill) &nbsp; </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12">
                            <p><sup>1</sup>By ordering this test for your Medicare physician, you are certifying that the physician has met the requirements for use <br />i.e has an ovarian mass, has surgery planned and is over 18 years of age</p>
                            <p>Insurance Information</p>
                        </div>
                    </div>
                    <div id="primaryHBOC_insurance_container" class="row clearfix">
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for="PrimaryInsuranceCarrier">Primary Insurance Carrier </label>
                                <div class="form-line">
                                    <input type="text" id="billinfoHBOC_PrimaryInsuranceCarrier" class="form-control required" placeholder="Primary Insurance Carrier" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for="MemberID">Member ID# </label>
                                <div class="form-line">
                                    <input type="text" id="billinfoHBOC_MemberID" class="form-control required" placeholder="Member ID" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for="GroupID">Group ID# </label>
                                <div class="form-line">
                                    <input type="text" id="billinfoHBOC_GroupID" class="form-control required" placeholder="Group ID#" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="secondaryHBOC_insurance_container" class="row clearfix">
                        <div class="col-md-4">
                            <div class="form-group ">
                                <label class="form-label" for="SecondaryInsuranceCarrier">Secondary Insurance Carrier </label>
                                <div class="form-line">
                                    <input type="text" id="billinfoHBOC_SecondaryInsuranceCarrier" class="form-control required" placeholder="Secondary Insurance Carrier" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group ">
                                <label class="form-label" for="SecondaryMemberID">Member ID# </label>
                                <div class="form-line">
                                    <input type="text" id="billinfoHBOC_SecondaryMemberID" class="form-control required" placeholder="Member ID" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group ">
                                <label class="form-label" for="SecondaryGroupID">Group ID# </label>
                                <div class="form-line">
                                    <input type="text" id="billinfoHBOC_SecondaryGroupID" class="form-control required" placeholder="Group ID#" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="tertiaryHBOC_insurance_container" class="row clearfix">
                        <div class="col-md-4">
                            <div class="form-group ">
                                <label class="form-label" for="TertiaryInsuranceCarrier">Tertiary Insurance Carrier </label>
                                <div class="form-line">
                                    <input type="text" id="billinfoHBOC_TertiaryInsuranceCarrier" class="form-control required" placeholder="Tertiary Insurance Carrier" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group ">
                                <label class="form-label" for="TertiaryMemberID">Member ID# </label>
                                <div class="form-line">
                                    <input type="text" id="billinfoHBOC_TertiaryMemberID" class="form-control required" placeholder="Member ID" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group ">
                                <label class="form-label" for="TertiaryGroupID">Group ID# </label>
                                <div class="form-line">
                                    <input type="text" id="billinfoHBOC_TertiaryGroupID" class="form-control required" placeholder="Group ID#" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="insuredHBOC_info" class="row clearfix">
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for="NameofInsured">Name of Insured </label>
                                <div class="form-line">
                                    <input type="text" id="billinfoHBOC_NameofInsured" class="form-control required" placeholder="Name of Insured" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for="DOB">DOB (mm/dd/yyyy) </label>
                                <div class="form-line">
                                <DatePicker
                                        id={`billinfoHBOC_dob`}
                                        className={"form-control required"}
                                        selected={this.state.billinfoHBOC_dob}
                                        onChange={this.onChangeBillingHBOCDoB}
                                        peekNextMonth
                                        showMonthDropdown
                                        showYearDropdown
                                        dropdownMode="select"
                                    />
                                    
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group required">
                                <label class="form-label" for="Relationshiptoinsured">Relationship to insured</label>
                                <div class="form-line">
                                    <input type="text" id="billinfoHBOC_RelationshipInsured" class="form-control required" placeholder="Relationship to insured" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row clearfix">
                        <div class="col-md-12 text-right">
                            <button id="btnBillingInformationHBOCFormNext" class="btn btn-light">Next</button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

